import React from 'react';
import { Modal, Paper, Typography, Button, withStyles } from '@material-ui/core';
import appleImage from '~/images/imgApple.png';
import googleImage from '~/images/imgGoogle.png';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  qrCodeTitle: {
    color: '#1A173B',
    marginLeft: '25px',
    marginRight: '25px',
  },
  resetCodeModal: {
    height: '300px',
    width: '618px',
    padding: '0',
    position: 'relative',
    margin: 'auto',
    textAlign: 'center',
  },
  continueButtonStyle: {
    marginLeft: '1vw',
    backgroundColor: '#0077FF',
    color: '#FFFFFF',
    textTransform: 'capitalize',
    borderRadius: '4',
  },
  qrCodeModal: {
    height: '100%',
    width: '100%',
    maxWidth: '660px',
    maxHeight: '660px',
    padding: '0',
    position: 'relative',
    margin: 'auto',
    textAlign: 'center',
    paddingBottom: '25px',
  },
  qrCodeModalMobile: {
    width: '500px',
    height: '400px',
    padding: '0',
    position: 'relative',
    margin: 'auto',
    textAlign: 'center',
  },
  qrCodeTitleMobile: {
    color: '#1A173B',
    fontFamily: 'Roboto',
    fontSize: '11px',
    marginLeft: '10px',
    marginRight: '10px',
  },
  qrButton: {
    fontFamily: 'Roboto',
  },
  qrButtonMobile: {
    fontFamily: 'Roboto',
    fontSize: '10px',
  },
});

const QRModal = props => {
  const { classes } = props;

  return (
    <Modal
      id="qrCodeModal"
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={props.openQRCodeModal}
      onClose={() => props.closeQRModal()}
      disableBackdropClick={true}
    >
      <Paper
        elevation={5}
        className={props.isMobileDevice ? classes.qrCodeModalMobile : classes.qrCodeModal}
        style={{ paddingBottom: '25px' }}
      >
        <div>
          <Typography
            variant={props.isMobileDevice ? 'body1' : 'title'}
            gutterBottom
            style={props.isMobileDevice ? { paddingTop: '0.5em' } : { paddingTop: '1.5em' }}
          >
            <b>Multifactor Authentication</b>
          </Typography>
          <Typography
            gutterBottom
            className={props.isMobileDevice ? classes.qrCodeTitleMobile : classes.qrCodeTitle}
          >
            This site uses multi-factor authentication for the login process to comply with the IRS
            Regulatory requirements. If you would like to use an authentication application to
            assist you during login, please download an authentication application (Recommended:
            Google Authenticator) from the{' '}
            <a href="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8">
              Apple
            </a>
            /
            <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_US">
              Google
            </a>{' '}
            store.
          </Typography>
          <div
            style={
              // For laptops with smaller screens hide the play/store images to save space
              !props.isMobileDevice && window.innerHeight < 600
                ? { display: 'none' }
                : { display: 'block' }
            }
          >
            <div
              style={
                props.isMobileDevice
                  ? { paddingTop: '0.2em', paddingBottom: '0.5em' }
                  : { paddingTop: '1.5em', paddingBottom: '1.5em' }
              }
            >
              <a
                href="//itunes.apple.com/us/app/google-authenticator/id388497605?mt=8"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={appleImage}
                  style={props.isMobileDevice ? { width: '100px', height: '30px' } : {}}
                />
                &nbsp;
              </a>
              <a
                href="//play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_US"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={googleImage}
                  style={props.isMobileDevice ? { width: '100px', height: '30px' } : {}}
                />
              </a>
            </div>
          </div>
          <img
            src={'data:image/png;base64,' + props.qrcode}
            style={
              props.isMobileDevice
                ? { width: '120px', height: '120px', marginBottom: '5px' }
                : { width: '200px', height: '200px', marginBottom: '10px' }
            }
          />
          <Typography
            variant="title"
            gutterBottom
            className={props.isMobileDevice ? classes.qrCodeTitleMobile : classes.qrCodeTitle}
          >
            Use your authentication application to scan the above QR code for use during the login
            process.
          </Typography>
          <Typography
            variant="body2"
            gutterBottom
            className={props.isMobileDevice ? classes.qrCodeTitleMobile : classes.qrCodeTitle}
          >
            <i>
              <b>Note:</b> You may choose to skip this process, if you would rather receive your
              multifactor authentication code using your registered email address. You can always
              upgrade to this method at a later date.
            </i>
          </Typography>
          {!props.isMobileDevice && (
            <Link to="/">
              <Button
                id="btnContinueToLoginActivateAcc"
                variant="contained"
                color="primary"
                className={classes.qrButton}
                onClick={() => props.closeQRModal()}
              >
                Continue To Login
              </Button>
            </Link>
          )}
          {props.isMobileDevice && (
            <Button
              id="btnContinueToLoginActivateAcc"
              variant="contained"
              color="primary"
              className={classes.qrButtonMobile}
              onClick={() => props.closeQRModalMobile()}
            >
              Close
            </Button>
          )}
        </div>
      </Paper>
    </Modal>
  );
};

QRModal.propTypes = {
  qrcode: PropTypes.string.isRequired,
  openQRCodeModal: PropTypes.bool.isRequired,
  closeQRModal: PropTypes.func.isRequired,
};

export default withStyles(styles)(QRModal);

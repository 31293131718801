import React, { useEffect, useState } from 'react';
// Internal imports
import { useSetState } from '~/app/Utility/customHooks';
import {
  getChildrenOfRows,
  getColumnTotal,
  getSubIncrement,
  getInputSize,
  getPadding,
} from '~/app/Pages/WizardMode/Mapper/helpers/mapperHelpers.js';
import { YES_NO_DROPDOWN } from '~/app/Pages/WizardMode/wizardModeConstants.js';
import { getBreakpointGrid } from '~/app/Pages/WizardMode/wizardModeHelpers.js';
import infoIcon from '~/images/icons/icons8-info.svg';
// External imports
import { InputLabel, FormControl, Grid, TextField, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
// Styling imports
import { styles } from '~/app/Pages/WizardMode/Mapper/css/mapperStyles.js';
import '~/app/Pages/WizardMode/Mapper/css/mapperStyles.css';

/**
 * Handles ! and & flag types for Dropdowns
 *
 * @param {Object} props check the map helper to see what is passed in
 * @returns JSX dropdown input
 */
const DropdownMap = props => {
  const { classes } = props;
  const [state, setState] = useSetState({
    // default a yes/no choice
    options: [],
    isOpen: false,
    style: getInputSize(parseInt(props.size)),
    fieldPadding: getPadding(props.field, props.preppedUI, props.row, props.size),
  });

  // Returns an Array of inputs/CellItems that are associated to the same row
  // const childrenOfRows = getChildrenOfRows(props.preppedUI, props.row, false); UNUSED- marked for deletion
  // for dynamic fields, add a sub increment to the title
  const dynamicSubIncr = getSubIncrement(props.field.substring(2, 4));
  // determines the size of the input and the column size for material ui grid
  // const column = getColumnTotal(childrenOfRows, props.field); UNUSED- marked for deletion
  // handles determining the breakpoint and also handles checking if a property exists.
  //const columnBreakpoint = getBreakpointGrid(column, true, null); UNUSED- marked for deletion
  // determines the size of the field
  const fieldSize = classes[getInputSize(parseInt(props.size))];
  // disables spouse fields if filing status is 1 or 3
  const disableSpouse = props.singleFLST && props?.tors === 'S';

  // Handles checking if wizardDropdownOptions object has been updated in redux store with 'return_choicelist' response
  useEffect(() => {
    for (const key of Object.keys(props.wizardDropdownOptions)) {
      if (key === props.name) {
        setState({
          options: [...props.wizardDropdownOptions[key]],
        });
      }
    }
  }, [props.wizardDropdownOptions]);

  /**
   * Handles passing data up to parent, to make req for dropdown options
   *
   * @param {string} formName used for determining which form the field is from
   * @param {string} fieldName used for determining which field the dropdowns belong to
   */
  const initGetDropdownOptions = (formName, fieldName) => {
    if (!state.isOpen) {
      setState({ isOpen: true });
      if (props.type === 'Q') {
        setState({
          options: [...YES_NO_DROPDOWN],
        });
        return;
      }
      props.getDropdownOptions(formName, fieldName);
    }
  };

  /** Handles closing dropdown */
  const handleClose = () => {
    setState({ isOpen: false });
  };

  return (
    //<Grid item xs={columnBreakpoint} md={columnBreakpoint} lg={'auto'}></Grid>
    <Grid item lg={state.fieldPadding === null ? 12 : 'auto'}>
      <div className="grid-container-grid-item-div" style={state.fieldPadding}>
        <FormControl>
          <TextField
            select // lets MUI know to render Select component
            SelectProps={{
              open: state.isOpen,
              onOpen: () => initGetDropdownOptions(props.name, props.field),
              onClose: handleClose,
              renderValue: () => props.form[props.name],
            }}
            InputLabelProps={{
              shrink: true,
            }}
            id={props.field}
            name={props.name}
            value={props.form[props.name]}
            onChange={e => props.handleChange(e, true, props.type)}
            className={`MuiList-padding-993 MuiFormHelperText-root-1165 ${fieldSize}`}
            style={state.style}
            aria-describedby={props.prompt}
            disabled={props.flag === '+' || props.getLockedStatus() || disableSpouse}
            label={
              props.indirect === 'TOP' &&
              (props.dsplymode === 'd' ? `${dynamicSubIncr}. ${props.prompt}` : props.prompt)
            }
            variant="outlined"
            required={props.flag === '&'}
            helperText={props.currentInputErrors?.[props.name]}
            error={props.currentInputErrors?.[props.name] !== undefined}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {state.options ? (
              state.options.map((option, index) => (
                <MenuItem value={option} key={`option-${option}-${index}`}>
                  {option}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="">Loading options...</MenuItem>
            )}
          </TextField>
        </FormControl>
        {props.indirect === 'RIGHT' && (
          <>
            <InputLabel htmlFor={props.field} className={classes.sideLabelStyle}>
              {props.dsplymode === 'd' ? `${dynamicSubIncr}. ${props.prompt}` : props.prompt}
              {props.flag?.includes('*') ? ` * ` : ''}
              {/* Info bubble */}
              {props.dsplymode === '?' && (
                <img
                  id={props.field}
                  onClick={() => props.handleModal('isInfoModalOpen', props.lineno, props.prompt)}
                  className="container-item-icon"
                  alt={props.lineno}
                  src={infoIcon}
                />
              )}
            </InputLabel>
          </>
        )}
      </div>
    </Grid>
  );
};

export default withStyles(styles)(DropdownMap);

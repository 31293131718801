import React, { Component } from "react";
import logoplaceholder from "~/images/logoplaceholder.png";
import OfficeRadioList from "#/Auth/OfficeRadioList.jsx";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import WebHelpers from "~/app/webHelpers.js";
import Paper from "@material-ui/core/Paper";

import { actions as appActions } from "~/app/redux/modules/app";
import { store } from "~/app/redux/index";
import AuthAPI from "../../api/authAPI";

const styles = {
  labels: {
    height: "16px",
    width: "95px",
    color: "#354052",
    fontSize: "14px",
    lineHeight: "16px"
  },
  formStyle: {
    marginLeft: "17em",
    marginTop: "3em"
  },
  back: {
    display: "inline",
    height: "16px",
    width: "31px",
    color: "#0077FF",
    fontSize: "14px",
    lineHeight: "16px",
    marginRight: "3em"
  },
  continue: {
    height: "35px",
    width: "102px",
    borderRadius: "4px",
    backgroundColor: "#0077FF"
  },
  topPadding: {
    padding: "1em 1em 3em 1em"
  },
  get headerLabels() {
    return {
      ...this.labels,
      fontWeight: "500",
      fontSize: "21px",
      letterSpacing: "0.14px",
      lineHeight: "24px"
    };
  },
  get smallLabels() {
    return {
      ...this.labels,
      fontSize: "12px",
      lineHeight: "14px",
      letterSpacing: "0.08px",
      color: "#637280"
    };
  }
};

const SelectOffice = ({ onClickContinue }) => {
  let efin_id = undefined;

  let selectEFIN = e => {
    efin_id = e;
  };

  var finalLogo = localStorage.getItem("finalLogo");

  return (
    <div className="container-fluid" id="officeListContainer">
      <div
        className="container"
        style={{ paddingTop: "3em", paddingLeft: "5em" }}
      >
        <div className="container" style={{ textAlign: "center" }}>
          {/* <img src={logoCrosslink} className="logoCrosslink" /> */}
          <img
            src={finalLogo ? finalLogo : logoplaceholder}
            className="logoCrosslink"
          />
        </div>
        <span style={styles.headerLabels}>
          Multiple offices are linked to your login
        </span>
        <div>
          <span style={styles.labels}>Select default office</span>
        </div>
        <OfficeRadioList onOfficeChange={selectEFIN} />
        <FormControl required>
          <div id="selectOfficeButtonDiv" style={styles.formStyle}>
            <Typography
              id="typBackFromOfficeSelect"
              style={styles.back}
              onClick={() => store.dispatch(appActions.onLogout())}
              color="primary"
            >
              Back
            </Typography>
            <Button
              id="btnSubmitOfficeSelection"
              style={styles.continue}
              variant="contained"
              type="submit"
              color="primary"
              onClick={() => {
                let payload = WebHelpers.getJWTPayload();
                let offices = payload.offices;
                let selectedOffice = offices.filter(office => {
                  return office.efin_id === efin_id;
                });
                if (!selectedOffice) {
                  alert("Critical error, cannot find selected office");
                  store.dispatch(appActions.onLogout());
                } else {
                  onClickContinue(selectedOffice.efin_id, payload.season);
                }
              }}
            >
              Continue
            </Button>
          </div>
        </FormControl>
      </div>
    </div>
  );
};

export default SelectOffice;

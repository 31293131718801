export let tabStyleConfig = {
  dragHandle: {
    padding: "0px",
    height: "0px"
  }
};

export const removeStyle = {
  position: "absolute",
  right: "2px",
  top: 0,
  cursor: "pointer"
};

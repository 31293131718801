import React, { useEffect } from 'react';
// External imports
import { useHistory, withRouter } from 'react-router-dom';
import { Button, FormControl, Paper, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
// Internal imports
import { useSetState } from '~/app/Utility/customHooks';
import messageIcon from '~/images/icons/newpost.png';
import phoneIcon from '~/images/icons/touchscreen.png';
import ErrorHelpers from '~/app/errorHelpers.js';
import XlinkAPI from '~/app/api/xlinkAPI';
import Spinner from '#/Common/Spinner.jsx';
import WebHelpers, { statusOK } from '~/app/webHelpers.js';
import SimpleDialog from '#/Common/SimpleDialog.jsx';
import { webSocket } from '~/app/Utility/socketConnection.js';
import { parseEmail } from '~/app/Pages/MFAMethod/mfaMethodHelper.js';
// Redux imports
import { useDispatch, useSelector } from 'react-redux';
import { actions as appActions } from '~/app/redux/modules/app';
import { actions as cobrandActions } from '~/app/redux/cobrand/duck';
// Styling imports
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import appTheme from '~/themes/GenericTheme.jsx';
import { styles } from '~/app/Pages/MFAMethod/css/mfaMethod.js';
import '~/app/Pages/MFAMethod/css/mfaMethod.css';

/**
 * Component that allows the user to choose the method on which to get a code for the
 * 2 factor authentication process.
 *
 * @component
 * @category MFAMethod
 */
const MFAMethod = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { classes } = props;

  const { MFAMethod, logo, isAuthenticated, alert } = useSelector(state => ({
    MFAMethod: state.app.MFA_Method,
    logo: state.cobrand.logo,
    fingerprint: state.app.browserFingerprint,
    isAuthenticated: state.app.isAuthenticated,
    alert: state.app.alert,
  }));

  const [state, setState] = useSetState({
    isCodeRadioBtnActive: true,
    isEmailRadioBtnActive: false,
    logoFromDB: '',
    logoNameFromDB: '',
    logoFileTypeFromDB: '',
    logoDB: '',
    logoPermission: 0,
    userEmail: '',
    loadingSpinner: false,
  });

  useEffect(() => {
    dispatch(cobrandActions.fetchLogo(true));
    dispatch(appActions.setMFAMethod('text'));
    getEmail();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      webSocket.services();
      history.push({ pathname: '/dashboard' });
    }
  }, [isAuthenticated]);

  /** Gets the email from the database to send the two factor authentication code */
  const getEmail = async () => {
    try {
      const res = await XlinkAPI.getEmailForSecondFactor();
      if (statusOK(res)) {
        const { data } = res;

        setState({ userEmail: data });
        sessionStorage.setItem('emailAddress2ndFactor', data);
      }
    } catch (err) {
      ErrorHelpers.handleError('Login Error', err);
    }
  };

  /**
   * Determines which MFA value is selected and sets the state in Redux.
   *
   * @param {Object} e - event which takes place in the DOM.
   */
  const handleRadioChange = e => {
    const { value } = e.target;
    let isEmailSelected = false;
    let isCodeSelected = true;

    if (value === 'email') {
      isEmailSelected = true;
      isCodeSelected = false;
    }

    setState({ isCodeRadioBtnActive: isCodeSelected, isEmailRadioBtnActive: isEmailSelected });

    dispatch(appActions.setMFAMethod(value));
  };

  /**
   * Handles navigating the user to the two factor authentication code page.
   *
   * @param {Object} e - event which takes place in the DOM.
   */
  const handleSubmit = e => {
    e.preventDefault();

    history.push({ pathname: 'mfa-code' });
  };

  /** Clears tokens and navigates user back to the login page. */
  const handleBackBtn = () => {
    WebHelpers.cleanupSession();
    WebHelpers.cleanupLocalStorage();
    history.push({ pathname: '/' });
  };

  return (
    <MuiThemeProvider theme={appTheme}>
      <div className="container-fluid" id="mfaSelectContainer">
        <Paper className={classes.paper}>
          <div className="container mfa-method-content-block">
            <div className="container mfa-method-logo-block">
              {<img src={logo} className="logoCrosslink" />}
            </div>
            <div className="container mfa-method-form-block">
              <form className="verifyAccountForm" onSubmit={handleSubmit}>
                <div className="mfa-method-description">We need to verify your account:</div>
                <div className="mfaRadioSelect mfa-method-radio-btn-block">
                  <div
                    className={`mfa-method-radio-btn ${
                      state.isCodeRadioBtnActive
                        ? 'mfa-method-radio-btn-active'
                        : 'mfa-method-radio-btn-inactive'
                    }`}
                  >
                    <div className="mfa-method-img-block">
                      <img src={phoneIcon} className="mfa-method-img-phone" />
                    </div>
                    <div className="mfa-method-code-block" />
                    <input
                      value={'text'}
                      type="radio"
                      name="rg"
                      id="radR1MFAMethod"
                      className="defaultRadioButton"
                      onChange={handleRadioChange}
                      checked={MFAMethod === 'text' || MFAMethod === 'fingerprinted'}
                    />
                    <label htmlFor="radR1MFAMethod" className="mfa-method-selection">
                      {'Enter a code from an App'}
                    </label>
                  </div>
                  <div
                    className={`mfa-method-radio-btn ${
                      state.isEmailRadioBtnActive
                        ? 'mfa-method-radio-btn-active'
                        : 'mfa-method-radio-btn-inactive'
                    }`}
                  >
                    <div className="mfa-method-img-block mfa-method-img-block-email">
                      <img src={messageIcon} className="mfa-method-img-message" />
                    </div>
                    <div className="mfa-method-email-block">
                      <div className="mfa-method-email-description">Get a code emailed to:</div>
                      <input
                        value={'email'}
                        type="radio"
                        name="rg"
                        id="radR2MFAMethod"
                        className="defaultRadioButton"
                        onChange={handleRadioChange}
                        checked={MFAMethod === 'email'}
                      />
                      <label htmlFor="radR2MFAMethod" className="mfa-method-selection">
                        {parseEmail(state.userEmail)}
                      </label>
                    </div>
                  </div>
                  <FormControl fullWidth>
                    <div className="mfa-method-btn-block">
                      <div className="mfa-method-btn-block-position">
                        <Typography
                          id="typBackToLogInMFAMethod"
                          color="primary"
                          className={classes.typography}
                          onClick={handleBackBtn}
                        >
                          Back
                        </Typography>
                        <Button
                          id="btnContinueMFAMethod"
                          variant="contained"
                          color="primary"
                          size="medium"
                          type="submit"
                          className={classes.button}
                        >
                          Continue
                        </Button>
                      </div>
                    </div>
                  </FormControl>
                </div>
              </form>
            </div>
          </div>
        </Paper>
        {state.loadingSpinner && <Spinner lockActions={true} />}
      </div>
      <SimpleDialog
        open={alert.show}
        onConfirm={() => dispatch(appActions.hideError())}
        dialogTitle={alert.title}
        contentText={alert.body}
        contentTextTwo={alert.bodyTwo}
        confirmText="OK"
        styled
        errorMessage
      />
    </MuiThemeProvider>
  );
};

MFAMethod.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(MFAMethod));

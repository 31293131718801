export const styles = () => ({
  toolbarColor: { backgroundColor: '#444A59' },
  grid: {
    backgroundColor: '#EBEDF8',
    borderRadius: '5px',
    marginTop: '0.5rem',
    marginBottom: '1em',
    maxWidth: '100%',
    maxHeight: '10rem',
    flexBasis: '100%',
    padding: '0.7em',
    overflowY: 'auto',
  },
  gridMobile: {
    padding: '0.7em',
    width: '39rem',
    height: '6rem',
    backgroundColor: '#EBEDF8',
    position: 'absolute',
    left: '50%',
    top: '33%',
    transform: 'translate(-50%, -50%)',
  },
  agreementTitle: {
    display: 'inline',
    marginTop: '1em',
    fontSize: '1.25rem',
    cursor: 'default',
  },
  agreement: {
    display: 'inline',
    marginTop: '1em',
    paddingLeft: '0.3em',
    fontSize: '0.9rem',
    cursor: 'default',
    fontWeight: 'lighter',
    '& b ': {
      fontWeight: '500',
    },
  },
  divider: {
    width: '100%',
    marginLeft: '0',
    marginTop: '1em',
    marginBottom: '1rem',
  },
  clearButton: {
    paddingLeft: '10px',
    paddingRight: '10px',
    minWidth: '0px',
    minHeight: '3.5vh',
    textTransform: 'capitalize',
    fontFamily: 'Roboto',
    fontSize: '14px',
    borderRadius: '4px',
    marginRight: '1.5vw',
  },
  previewButton: {
    paddingLeft: '10px',
    paddingRight: '10px',
    minWidth: '0px',
    minHeight: '3.5vh',
    textTransform: 'capitalize',
    fontFamily: 'Roboto',
    fontSize: '14px',
    borderRadius: '4px',
  },
});

// External impports
import React from 'react';
import { Button, Typography, Paper, Modal, withStyles } from '@material-ui/core';
// Internal imports
import { REQUEST_PDF_MSG } from '~/app/constants.js';
// Styling imports
import { styles } from '~/app/Pages/Returns/components/Document/ArchivedDocuments/css/archivedDocuments.js';

function RequestPDFModal(props) {
  const { classes } = props;
  return (
    <Modal
      id="mdlAddArchiveDocumentModalDocArchive"
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={props.isRequestPDFModalOpen}
      onClose={() => props.toggleModal('isRequestPDFModalOpen')}
    >
      <Paper className={classes.modal}>
        <div className="preview-doc-archive-modal-title">
          <Typography color="inherit" variant="body1">
            Type : {props.currentOpenAttachmentType}
          </Typography>
          <Typography color="inherit" variant="body1">
            Description : {props.currentOpenAttachmentDesc}
          </Typography>
        </div>
        <div className="preview-doc-archive-modal-container">
          <Typography variant="body1" className={classes.alertBody}>
            {REQUEST_PDF_MSG}
          </Typography>
          <div className={classes.alertModalButtonsContainer}>
            <Button
              id="mdlDocumentArchiveDownloadDocCloseBtn"
              variant="contained"
              color="primary"
              className={classes.alertModalButton}
              onClick={() => props.toggleModal('isRequestPDFModalOpen')}
            >
              Cancel
            </Button>
            <Button
              id="mdlDocumentArchiveDownloadDocCloseBtn"
              variant="contained"
              color="primary"
              className={classes.alertModalButton}
              href={props.currentOpenAttachment}
              onClick={() => props.toggleModal('isRequestPDFModalOpen')}
              download="document"
            >
              Ok
            </Button>
          </div>
        </div>
      </Paper>
    </Modal>
  );
}

export default withStyles(styles)(RequestPDFModal);

import React from 'react';
// External imports
import { useHistory } from 'react-router-dom';
import { Button, Paper, Typography } from '@material-ui/core';
// Styling imports
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import appTheme from '~/themes/GenericTheme.jsx';
import { styles } from '~/app/Pages/NotFound/css/notFound.js';

/**
 * Component to display a 404 error when a user goes to route that doesn't exist.
 *
 * @component
 * @category NotFound
 */
const NotFound = props => {
  const history = useHistory();
  const { classes } = props;

  /** Navigates back to the previous page. */
  const goBack = () => {
    history.goBack();
  };

  return (
    <MuiThemeProvider theme={appTheme}>
      <Paper className={classes.paper}>
        <Typography variant="display4">404</Typography>
        <Typography className={classes.description}>
          {`We are sorry but we couldn't find that page`}
        </Typography>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          size="small"
          onClick={goBack}
        >
          Go Back
        </Button>
      </Paper>
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(NotFound);

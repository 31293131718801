export const styles = {
  styleCheckmark: {
    paddingTop: '0px',
    marginTop: '0px',
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  textStyle: {
    display: 'inline-block',
    paddingTop: '0px',
    marginTop: '0px',
    marginLeft: '0.5vw',
    textAlign: 'center',
    verticalAlign: 'middle',
    height: '25px',
  },
  textStyleMobile: {
    display: 'inline-block',
    paddingTop: '0px',
    marginTop: '0px',
    marginLeft: '0.5vw',
    fontFamily: 'Roboto',
    fontSize: '8px',
  },

  floatingBoxDivStyle: {
    textAlign: 'left',
    padding: '0.5em',
  },
  nonMobileCheckmark: {
    fontSize: 25,
    marginRight: 'none',
    color: 'green',
  },
  nonMobileWarningSign: {
    fontSize: 25,
    marginRight: 'none',
    color: '#ffcc00',
  },
  mobileCheckmark: {
    fontSize: 12,
    marginRight: 'none',
    color: 'green',
  },
  mobileWarningSign: {
    fontSize: 12,
    marginRight: 'none',
    color: '#ffcc00',
  },
};

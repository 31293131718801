// External imports
import React, { useEffect, useRef } from 'react';
import { useIdleTimer } from 'react-idle-timer';
// Internal imports
import { useSetState } from '~/app/Utility/customHooks';
import SimpleDialog from '#/Common/SimpleDialog.jsx';
import XlinkAPI from '~/app/api/xlinkAPI';
import { webSocket } from '~/app/Utility/socketConnection.js';
import AuthAPI from '~/app/api/authAPI.js';
import { privateRoutes } from '~/app/Router/Routes.js';
// Redux imports
import { useSelector, useDispatch } from 'react-redux';
import { actions as appActions } from '~/app/redux/modules/app';
import { actions as returnActions } from '~/app/redux/returnProfile/duck';
// Styling imports
import { MuiThemeProvider } from '@material-ui/core/styles';
import appTheme from '~/themes/GenericTheme.jsx';
import { isOnPublicOrPrivateRoute } from '~/app/Utility/general';
import { ACCESS_TOKEN } from '~/app/constants';

const showTimeoutForSeconds = 30; // in seconds
const idleAtMinutes = 10; // in minutes
const debounce = 50; // in ms
const timerTimoutTime = 1000 * 60 * idleAtMinutes; // in ms

/**
 * Idle timer that will automatically log out a user if they have been idle
 * for longer than 10 minutes. If they respond before the 30 second countdown
 * ends then they will not be logged out.
 *
 * @component
 * @category Components
 * @subcategory Common
 */
const CLOIdleTimer = () => {
  const dispatch = useDispatch();
  const timer = useRef();

  const { isAuthenticated, refreshTokenExpireTime } = useSelector(state => ({
    isAuthenticated: state.app.isAuthenticated,
    refreshTokenExpireTime: state.app.refreshTokenExpireTime,
  }));

  const [state, setState] = useSetState({
    isTimeoutModalOpen: false,
    secondsRemaining: showTimeoutForSeconds,
    counter: 0,
  });

  useEffect(() => {
    if (isAuthenticated && state.isTimeoutModalOpen) {
      timer.current =
        state.secondsRemaining > 0 &&
        setInterval(
          () => setState(prevState => ({ secondsRemaining: prevState.secondsRemaining - 1 })),
          1000,
        );

      if (state.secondsRemaining <= 0) {
        if (window?.location?.pathname === '/tax-return') {
          dispatch(returnActions.closeActiveReturn());
        }
        forceLogout();
      }
    }
    return () => {
      clearInterval(timer.current);
    };
  }, [state.secondsRemaining, state.isTimeoutModalOpen, isAuthenticated]);

  useEffect(() => {
    isAuthenticated && start();
  }, [isAuthenticated]);

  useEffect(() => {
    const pathname = window.location.pathname;

    const intervalId = setInterval(() => {
      const currentTime = Math.floor(new Date().getTime() / 1000);
      setState({ counter: prevCounter => prevCounter + 1 });

      // if the token is 60 seconds away from expiring, go ahead and refresh it
      if (refreshTokenExpireTime - currentTime >= 0 && refreshTokenExpireTime - currentTime <= 60) {
        // In formviewer we already have a component to handle this called `RefreshToken.jsx`
        if (pathname !== '/tax-return' && isOnPublicOrPrivateRoute(privateRoutes)) {
          onRefresh();
        }
      }
    }, 15000); // 15000ms = 15 seconds

    // Clean up the interval on unmount
    return () => clearInterval(intervalId);
  }, [state.counter, refreshTokenExpireTime]);

  const onRefresh = async () => {
    const jwt = sessionStorage.getItem(ACCESS_TOKEN);
    if (!jwt) return;

    try {
      await XlinkAPI.validateOrRefresh();
    } catch (error) {
      console.log('error: ', error);
    }
  };

  /**
   * Forces a log out and cleans up session storage variables and
   * closes the web socket connection.
   */
  const forceLogout = () => {
    AuthAPI.logout().finally(() => {
      // If chat widget is open, force close it
      if (document.getElementById('webWidget')) {
        const chatWidgetEle = document.getElementById('webWidget').nextElementSibling;
        if (chatWidgetEle) {
          chatWidgetEle.click();
        }
      }

      // remove chat button
      if (document.getElementById('launcher')) {
        document.getElementById('launcher').style.display = 'none';
      }

      setState({ isTimeoutModalOpen: false });
      webSocket && webSocket.closeSocketConnection();
      dispatch(appActions.onLogout());
    });
  };

  /**
   * Handles restarting the idle timeout and hiding the modal when the user clicks the
   * continue button on the idle timeout modal.
   */
  const hideTimeout = async () => {
    start();

    setState({
      isTimeoutModalOpen: false,
    });

    const jwt = sessionStorage.getItem(ACCESS_TOKEN);
    if (!jwt) return;

    await XlinkAPI.validateOrRefresh();
  };

  /**
   * Handles setting the timeout time and opening the timeout modal.
   */
  const handleOnIdle = () => {
    isAuthenticated &&
      setState({
        secondsRemaining: showTimeoutForSeconds,
        isTimeoutModalOpen: true,
      });
  };

  /**
   * Sets up the idle timer hook and destructures the start function.
   */
  const { start } = useIdleTimer({
    timeout: timerTimoutTime,
    onIdle: handleOnIdle,
    debounce: debounce,
    startManually: true,
    stopOnIdle: true,
  });

  return (
    <>
      <MuiThemeProvider theme={appTheme}>
        <SimpleDialog
          open={state.isTimeoutModalOpen}
          onConfirm={hideTimeout}
          dialogTitle="Are You Still There?"
          contentText={`To keep your account secure, please do not leave your workstation
            unattended. Your account will refresh and you will be automatically logged out in ${state.secondsRemaining} seconds.`}
          confirmText="Continue"
          styled
        />
      </MuiThemeProvider>
    </>
  );
};

export default CLOIdleTimer;

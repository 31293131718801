// External imports
import React, { useRef } from 'react';
import NumberFormat from 'react-number-format';
import { Button, Collapse, IconButton, Input, withStyles } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
// Internal imports
import { useSetState } from '~/app/Utility/customHooks.js';
import xIcon from '~/images/icons/icons8-multiply.png';
// Styling imports
import AccessControl from '#/Auth/AccessControl.jsx';
import { styles } from '~/app/Components/NewAccount/SingleOffice/css/businessBilling.js';

const CustomChanges = props => {
  const { classes } = props;
  const currentNewItemCounter = useRef(0);
  const [state, setState] = useSetState({
    expanded: true,
    hoveredRow: null,
  });

  const updateRowHoverEnter = index => {
    setState({ hoveredRow: index });
  };

  const updateRowHoverLeave = () => {
    setState({ hoveredRow: null });
  };

  const handleExpandClick = () => {
    setState(state => ({ expanded: !state.expanded }));
  };

  const addRow = () => {
    if (props.actionsLocked) {
      alert(
        "You must first add a new billing scheme before continuing. \n\nPlease click the button next to 'Set as Default' to name and save your new Billing scheme.",
      );
      return;
    }
    currentNewItemCounter.current = currentNewItemCounter.current + 1;
    props.addRow(
      {
        new_custom_charge_id: currentNewItemCounter,
        custom_charge_description: '',
        custom_charge_amount: '',
      },
      'tbRowsCustomCharges',
    );
  };

  const customChargesTable = () => {
    return props.tbRowsCustomCharges.map((item, key) => (
      <tr
        key={key}
        onMouseEnter={() => updateRowHoverEnter(key)}
        onMouseLeave={updateRowHoverLeave}
      >
        <td>
          <Input
            style={styles.inputStyle}
            id={`txtChargeDesc${key}`}
            onChange={e =>
              props.handleChangeTable(key, 'custom_charge_description', e.target.value)
            }
            value={item.custom_charge_description}
            disableUnderline
          />
        </td>
        <td>
          <NumberFormat
            style={styles.inputStyleNearButton}
            id={`txtChargeAmt${key}`}
            onChange={e =>
              props.handleChangeTable(
                key,
                'custom_charge_amount',
                e.target.value.replace(/[$,]/g, ''),
              )
            }
            value={item.custom_charge_amount}
            disableUnderline
            prefix="$"
            thousandSeparator={true}
            customInput={Input}
            fixedDecimalScale={true}
            decimalScale={2}
          />
        </td>
        <td hidden={Boolean(state.hoveredRow !== key)}>
          <Button
            disableRipple
            id="btnDeleteRowCustom"
            style={{
              width: '1em',
              paddingLeft: '0em',
              paddingRight: '0em',
              minHeight: '0em',
              minWidth: '0em',
            }}
            onClick={() => {
              const isNewEntry = Object.hasOwn(item, 'new_custom_charge_id');
              props.handleRowDel(
                isNewEntry === true ? item.new_custom_charge_id : item.custom_charge_id,
                'tbRowsCustomCharges',
                isNewEntry === true ? 'new_custom_charge_id' : 'custom_charge_id',
                props.itemType,
              );
              if (isNewEntry) {
                currentNewItemCounter.current = currentNewItemCounter.current - 1;
              }
            }}
            classes={{ root: classes.noOutlineButton }}
          >
            <img id="clearInputField" className="noselect" src={xIcon} />
          </Button>
        </td>
      </tr>
    ));
  };

  return (
    <div className="container-fixed">
      <div style={{ textAlign: 'left', paddingTop: '3vh', paddingBottom: '2vh' }}>
        <div style={{ float: 'left' }}>
          <IconButton
            id="icobtnExpandCustomChanges"
            style={{ width: '1em', height: '1em' }}
            onClick={handleExpandClick}
            aria-expanded={state.expanded}
            classes={{ root: classes.noBorderOnFocus }}
          >
            {state.expanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </div>
        <div>
          <span
            style={{
              fontFamily: 'Roboto',
              fontSize: '21px',
              letterSpacing: '0.14px',
              color: '#1A173B',
              marginLeft: '0.3em',
            }}
          >
            Custom Charges
            <IconButton
              className={classes.iconButton}
              id="icobtnInfoCustomCharges"
              color="primary"
              aria-label="info"
              onClick={() => props.openInfoDialog(props.itemType)}
            >
              <InfoOutlinedIcon />
            </IconButton>
          </span>
        </div>
      </div>
      <Collapse in={state.expanded} timeout="auto" unmountOnExit>
        <AccessControl requiredAction="write" accessLevel="add/edit_billing" disableOnFalse={true}>
          <div>
            <div
              style={{
                marginLeft: '3vw',
                marginRight: '3vw',
                marginTop: '1vh',
              }}
            >
              <div>
                <table
                  style={{
                    marginTop: '0.3em',
                    marginLeft: '1em',
                    marginRight: '5em',
                  }}
                  onMouseLeave={updateRowHoverLeave}
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          paddingRight: '7em',
                          color: '#354052',
                          fontFamily: 'Roboto',
                          fontSize: '14px',
                          fontWeight: 'normal',
                        }}
                      >
                        Charge Description
                      </th>
                      <th
                        style={{
                          color: '#354052',
                          fontFamily: 'Roboto',
                          fontSize: '14px',
                          fontWeight: 'normal',
                        }}
                      >
                        Amount
                      </th>
                      <th
                        style={{
                          paddingRight: '7em',
                          color: '#354052',
                          fontFamily: 'Roboto',
                          fontSize: '14px',
                          fontWeight: 'normal',
                        }}
                      />
                    </tr>
                  </thead>
                  <tbody>
                    {customChargesTable()}
                    <tr>
                      <td>
                        <Button
                          id="btnAddRowCustomChanges"
                          color="primary"
                          classes={{ root: classes.noOutlineButtonBlue }}
                          onClick={addRow}
                        >
                          Add Row +
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <br />
            </div>
          </div>
        </AccessControl>
      </Collapse>
    </div>
  );
};

export default withStyles(styles)(CustomChanges);

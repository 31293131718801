import React, { useEffect, Fragment } from 'react';
// Redux Imports
import { useSelector, useDispatch } from 'react-redux';
import { actions } from '~/app/redux/restrictedFields/duck';
import { actions as appActions } from '~/app/redux/modules/app';

// Internal Imports
import { useSetState } from '~/app/Utility/customHooks';
import ErrorHelpers from '~/app/errorHelpers.js';
import { statusOK } from '~/app/webHelpers.js';
import { COMMUNICATIONS_SETUP_PAGES, EXTENDED_SETUP_PAGES } from '~/app/constants.js';
import XlinkAPI from '~/app/api/xlinkAPI.js';
import { RESTRICTED_FIELD_REG_EXP } from '~/app/constants';
import InfoModal from './InfoModal.jsx';
// External Imports
import {
  Paper,
  Modal,
  Button,
  IconButton,
  Input,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Divider,
  Switch,
  withStyles,
} from '~/node_modules/@material-ui/core';
// Styling Imports
import InfoIcon from '@material-ui/icons/Info';
import '~/app/Pages/Setup/RestrictedFields/css/restrictedFields.css';
import { styles } from './css/restrictedFields.js';

/**
 * Component that handles relevant fields that have been restricted for certain efins.
 *
 * @component
 * @category RestrictedFields
 * @subcategory FieldSettings
 */
const RestrictedFields = props => {
  const dispatch = useDispatch();
  const { classes } = props;

  const [state, setState] = useSetState({
    restrictedFieldInput: '',
    isHovering: false,
    currentHoveredRow: null,
    isInfoModalOpen: false,
  });

  /**
   * @param {Array} restrictedFields is an array of restricted field objects
   */
  const { restrictedFields } = useSelector(state => ({
    restrictedFields: state.restrictedFields.rows,
  }));

  /**
   * Retrieves the relvant restricted fields by efin once upon rendering the component.
   */
  useEffect(() => {
    fetchRestrictedFields();

    return () => {
      dispatch(actions.clearRestrictedFields());
    };
  }, []);

  useEffect(() => {}, [restrictedFields]);

  const fetchRestrictedFields = async () => {
    try {
      const res = await XlinkAPI.getRestrictedFields();
      if (statusOK(res)) {
        dispatch(actions.fetchRestrictedFieldsSuccess(res.data));
      }
    } catch (error) {
      ErrorHelpers.handleError('Error fetching restricted fields', error);
      dispatch(actions.fetchRestrictedFieldsError());
    }
  };

  const showSnackbarError = msg => {
    dispatch(
      appActions.showSnackbarMessage(msg, 'error', 3000, { vertical: 'top', horizontal: 'center' }),
    );
  };

  /**
   * @param {Function} handleAddRow is a function that will insert a new restricted field into the db and also dispatch to our redux store
   * @param {Object} row is an object that stores the field along with metadata about the field
   * @param {string} upperCaseInput is a string that simply forces the input to be uppercase
   * @param {boolean} fieldExists is a boolean that checks if the potential inserted field already exists
   */
  const handleAddRow = async () => {
    const upperCaseInput = state.restrictedFieldInput?.toUpperCase();
    const fieldExists = restrictedFields?.some(row => row.field === upperCaseInput);

    // only 8 alphanumeric characters are allowed
    if (upperCaseInput.length !== 8) {
      showSnackbarError('Length must be exactly 8 characters for field');
    } else if (fieldExists) {
      showSnackbarError('Field already exists');
    } else {
      const row = {
        field: upperCaseInput,
        description: '',
        formID: upperCaseInput.slice(0, 4),
        formTitle: '',
        status: 1,
      };

      try {
        const res = await XlinkAPI.updateRestrictedField(row);
        if (statusOK(res, false)) {
          dispatch(actions.addRestrictedField(row));
          setState({ restrictedFieldInput: '' });
        }
      } catch (error) {
        ErrorHelpers.handleError('Error adding restricted field', error);
      }
    }
  };

  /**
   * @param {Function} handleDeleteRow is a function that will delete a restricted field into the db and also dispatch to our redux store
   * @param {string} deletedField is an string that contains the 8 alphanumeric field key ex: US01FLST
   */
  const handleDeleteRow = async deletedField => {
    try {
      const res = await XlinkAPI.deleteRestrictedField(deletedField);
      if (statusOK(res, false)) {
        dispatch(actions.deleteRestrictedField(deletedField));
      }
    } catch (error) {
      ErrorHelpers.handleError('Error deleting restricted field', error);
    }
  };

  const handleInputChange = value => {
    if (validateInput(value)) {
      setState({ restrictedFieldInput: value });
    } else {
      showSnackbarError('Invalid entry, use correct format');
    }
  };

  const validateInput = value => {
    return RESTRICTED_FIELD_REG_EXP.test(value);
  };

  const renderDeleteButton = (index, hoverFlag) => {
    setState({ isHovering: hoverFlag, currentHoveredRow: index });
  };

  const restrictedFieldsTable = () => {
    return restrictedFields?.map((row, index, rows) => (
      <>
        <TableRow
          key={index}
          hover={true}
          onMouseEnter={() => {
            renderDeleteButton(index, true);
          }}
          onMouseLeave={() => {
            renderDeleteButton(index, false);
          }}
        >
          <TableCell classes={{ root: classes.statusHeadCell }}>{renderSwitch(row)}</TableCell>
          <TableCell classes={{ root: classes.tableHeadCell }}>{row.field}</TableCell>
          <TableCell classes={{ root: classes.tableHeadCell }}>{row.formID}</TableCell>
          <TableCell classes={{ root: classes.tableHeadCell }}>{row.description}</TableCell>
          {state.isHovering && state.currentHoveredRow === index ? (
            <TableCell classes={{ root: classes.tableBodyDeleteButtonCell }}>
              <Button
                variant="text"
                classes={{ root: classes.deleteButton }}
                onClick={() => {
                  handleDeleteRow(row.field);
                }}
              >
                X
              </Button>
            </TableCell>
          ) : (
            <TableCell classes={{ root: classes.tableBodyDeleteButtonCell }}>
              <Button disabled classes={{ root: classes.deleteButton }}></Button>
            </TableCell>
          )}
        </TableRow>
        {
          // Empty <TableRow /> is inserted between different form sections to segment them when viewing the table
          rows[index]?.formID !== rows[index + 1]?.formID && (
            <TableRow classes={{ root: classes.tableFillRow }}></TableRow>
          )
        }
      </>
    ));
  };

  const renderSwitch = selectedRow => {
    return (
      <Switch
        checked={!!selectedRow.status}
        onChange={() => {
          handleSwitch(selectedRow);
        }}
        color="primary"
        name="checkedB"
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
    );
  };

  /**
   * @param {Function} handleSwitch is a function that will update the status value for each field
   * @param {Object} selectedRow is an object that stores the field along with metadata about the field
   * @param {Object} updatedRow is an object that stores the field with the new status
   */
  const handleSwitch = async selectedRow => {
    const updatedRow = { ...selectedRow, status: selectedRow.status ? 0 : 1 };

    try {
      const res = await XlinkAPI.updateRestrictedField(updatedRow);
      if (statusOK(res, false)) {
        dispatch(actions.updateRestrictedField(updatedRow));
      }
    } catch (error) {
      ErrorHelpers.handleError('Error updating restricted field', error);
    }
  };

  const goToPrevious = () => {
    props.loadSetupComponent(EXTENDED_SETUP_PAGES.MOBILE.NAME);
  };

  const gotToNext = () => {
    props.loadSetupComponent(COMMUNICATIONS_SETUP_PAGES.TEXT.NAME);
  };

  const toggleInfoModal = () => {
    setState({
      isInfoModalOpen: !state.isInfoModalOpen,
    });
  };

  return (
    <div className="restricted-fields-page-container">
      <Paper className="restricted-fields-paper">
        <span className="restricted-fields-heading">
          Restricted Fields{' '}
          <IconButton className="restricted-fields-info-button" onClick={toggleInfoModal}>
            <InfoIcon fontSize="small" />
          </IconButton>
        </span>
        <div className="restricted-fields-table-container">
          <div>
            <Input
              value={state.restrictedFieldInput}
              onChange={e => handleInputChange(e.target.value)}
              classes={{ root: classes.inputStyle }}
              disableUnderline
            ></Input>
            <Button
              variant="outlined"
              id="btnAddRowRestrictedFields"
              classes={{ root: classes.addRowButton }}
              onClick={() => handleAddRow()}
            >
              Add Row +
            </Button>
          </div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell classes={{ root: classes.statusHeadCell }}>
                  STATUS <Divider className="restricted-fields-table-header-divider" />
                </TableCell>
                <TableCell classes={{ root: classes.tableHeadCell }}>
                  FIELD <Divider className="restricted-fields-table-header-divider" />
                </TableCell>
                <TableCell classes={{ root: classes.tableHeadCell }}>
                  FORM <Divider className="restricted-fields-table-header-divider" />
                </TableCell>
                <TableCell classes={{ root: classes.tableHeadCell }}>
                  DESCRIPTION <Divider className="restricted-fields-table-header-divider" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{restrictedFieldsTable()}</TableBody>
          </Table>
        </div>
        <Modal
          open={state.isInfoModalOpen}
          onClose={toggleInfoModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <InfoModal toggleInfoModal={toggleInfoModal} />
        </Modal>
        <Divider variant="middle" classes={{ root: classes.bottomDivider }} />
        <div className="setup-nav-btns">
          <Button
            id="btnPreviousMobileApp"
            color="primary"
            onClick={() => {
              goToPrevious();
            }}
          >
            Previous
          </Button>
          <Button
            classes={{ root: classes.auditConfigFormButton }}
            color="primary"
            id="btnNextText"
            onClick={() => gotToNext()}
          >
            Next
          </Button>
        </div>
      </Paper>
    </div>
  );
};

export default withStyles(styles)(RestrictedFields);

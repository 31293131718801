//Appointments duck module
import * as Common from "~/app/redux/commonActions.js";

const UPDATE_SORT_APPOINTMENT_CRITERIA =
  "xlinkonline/appointments/UPDATESORTAPPOINTMENTCRITERIA";
const UPDATE_APPOINTMENT_LIST =
  "xlinkonline/appointments/UPDATEAPPOINTMENTLIST";
const UPDATE_CURRENT_APPOINTMENT =
  "xlinkonline/appointments/UPDATECURRENTAPPOINTMENT";
const ON_DELETE_APPOINTMENT = "xlinkonline/appointments/ONDELETEAPPOINTMENT";

const initialState = {
  appointmentList: [],
  appointment: {},
  sortDropdownOptions: [
    { name: "Sort By Newest", val: "desc" },
    { name: "Sort By Oldest", val: "asc" }
  ],
  sortCriteriaIdx: 0
};

export const actions = {
  updateSortCriteria: idx => ({
    type: UPDATE_SORT_APPOINTMENT_CRITERIA,
    idx
  }),
  updateAppointmentList: appointments => ({
    type: UPDATE_APPOINTMENT_LIST,
    appointments
  }),
  updateCurrentAppointment: appointment => ({
    type: UPDATE_CURRENT_APPOINTMENT,
    appointment
  }),
  onDeleteAppointment: id => ({
    type: ON_DELETE_APPOINTMENT,
    id
  })
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_SORT_APPOINTMENT_CRITERIA:
      return {
        ...state,
        sortCriteria: action.idx
      };
    case UPDATE_APPOINTMENT_LIST:
      return {
        ...state,
        appointmentList: action.appointments
      };
    case UPDATE_CURRENT_APPOINTMENT:
      return {
        ...state,
        appointment: action.appointment
      };
    case ON_DELETE_APPOINTMENT:
      const newList = state.appointmentList.filter(
        appointment => appointment.id != action.id
      );
      const newAppointment =
        state.appointmentList.length == 1 ? {} : state.appointment;

      return {
        ...state,
        appointmentList: newList,
        appointment: newAppointment
      };
    case Common.LOGOUT:
      return {
        ...initialState
      };
  }
  return state;
}

export const styles = {
  button: {
    borderRadius: '4px',
    fontSize: '14px',
    lineHeight: '16px',
    marginLeft: '1em',
    letterSpacing: '0.1px',
    height: '35px',
    width: 'auto',
  },
  nativeSelect: {
    width: '60%',
  },
  labels: {
    height: '16px',
    width: '95px',
    color: '#354052',
    fontSize: '14px',
    lineHeight: '16px',
  },
  input: {
    marginRight: '1rem',
    width: '14rem',
  },
};

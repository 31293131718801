import React from 'react';
// Internal imports
import {
  TextFieldMap,
  CheckboxMap,
  DropdownMap,
  TitleMap,
  HRMap,
  IntegerMap,
  WarningMsgMap,
  ReadOnlyMap,
  DynamicButtonMap,
} from '~/app/Pages/WizardMode/Mapper/components';
import { NUMERIC_FIELD_TYPES } from '~/app/Pages/WizardMode/wizardModeConstants.js';
// Redux imports
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '~/app/redux/modules/wizardEstimator.js';

// Should something like this be added to confluence for dev team to use?
// VAL prop - description
//  C  type - Letters
//  A  type - Letters + Numbers
//  a  type - Letters + Numbers + . + @ (for email)
//  I  type - Integer + Whole Numbers
//  T  type - Phone Number
//  L  type - Integer + Negative Whole Numbers
//  N  type - Integer + Whole numbers + Floats
//  S  type - SSN format XXX-XX-XXXX - max 9 or 10
//  Y  type - DOB format - max 9 or 10
//  X  type - Checkbox
//  Q  type - Yes/No dropdown
//  !  flag - Dropdown - dropdowns have type C + Logic checking for dropdown flag must be done before the case checking for type C
//  &  flag - Dropdown + required
// '*' flag - required
// '+' flag - READ only, used in Results page + assign to disabled inputs
//  X  lineno - READ only indented results input
// 'XX' lineno - READ only bolded results input
// 'd' dsplymode - Indicator that the field is part of a dynamic section
// '?' dsplymode - Indicator that the field has a INFO icon and should have a Info Message in lineno property
// 'box' dsplymode - Indicator that the checkbox field is a box instead of a switch
// 'S' tors- Indicator for is field is for spouse. Used to disable Spouse fields when filing Status is not 2 or 3.
// 'TOP' 'RIGHT' 'LEFT' indirect - Indicator for where the label of an input should appear
// 'LinePrinter' font - indicator for displaying an Add and Remove button for dynamic fields
// 'PageTitle' font - Unindented Header, used as a Overall Header that could contain Sectional Headers
// 'SectionalHeader' font - Indented Header, used as a section header inside of PageTitle
// 'BodyTextBold' font - used as a Warning message inside of a box ui
// 'BodyText' font - used as labels, we do not currently map over this, as we use 'prompt' instead of labels
// row - each field has a row its associated to, this is how desktop builds the layout -> '0006'
// col - each row can have up to 63 columns. Each field increments the number of columns it takes up -> '14'
// max - the max characters an input can have is decided in the fld file -> '12'

/**
 * Takes in a Array of Objects and Iterates over the fields, checking the type or other unique indicators, and creating individual JSX field components
 *
 * @component Mapper
 * @category Mapper
 * @param {Object} preppedUI The Array of sorted objects that are individual fields to be mapped out
 * @param {Function} onChange the wanted onChange listener that can be passed through and passed to the field
 * @param {Function} handleCheckboxChange the wanted onChange listener that can handle checking for 'checked' value
 * @param {Object} form the entire form that the field is associated with. When editing a field we send the entire form and spread it over the same form in returnProfile store
 * @param {Object} wizardDropdownOptions The response from cs 'return_choicelist' array of dropdown options
 * @param {Function} getDropdownOptions Handles making calc server request for 'return_choicelist' from parent
 * @param {Object} wizardDynamicFields used to determine what is a dynamic field, and keeps track of how many should be displayed
 * @param {Function} updateDynamicFields handles updating wizardDynamicFields and how many fields should be displayed
 * @param {Array} wizardDynamicFields current dynamic fields for a page
 * @param {Function} handleModal helper func handles modal logic for a field
 * @param {Object} currentInputErrors an error object used to pass down to the specific fields
 * @param {Function} getLockedStatus func fetches boolean used to disable inputs if return locked
 * @returns {JSX} An individual field built from the object passed in
 */
const Mapper = ({
  preppedUI,
  onBlurCalc,
  handleChange,
  handleCheckboxChange,
  form,
  wizardDropdownOptions,
  getDropdownOptions,
  updateDynamicFields,
  wizardDynamicFields,
  handleModal,
  currentInputErrors,
  getLockedStatus,
  wizardFLST,
}) => {
  const dispatch = useDispatch();
  const { requiredFields } = useSelector(state => ({
    requiredFields: state.wizardEstimator.requiredFields,
  }));
  // current form name - name of form is always the last element in preppedUI
  const formName = preppedUI[preppedUI.length - 1].name;
  // boolean siginfies if taxreturn has single or seperate
  const singleFLST = wizardFLST === 'SINGLE' || wizardFLST === 'HEAD OF HOUSEHOLD';
  // Handles iterating over the preppedUI Array and returning JSX based on the attributes from the object
  // TRY TO AVOID having the order of the if statements be a condition. EXAMPLE 'ReadOnlyMap' is a regular Input Field that should be caught by 'prop.type === N'
  return preppedUI.map((obj, index) => {
    // if field is required, add to required list
    if ((obj.flag === '*' || obj.flag === '&') && !requiredFields[formName].includes(obj.name)) {
      dispatch(actions.setRequiredFields(formName, obj.name));
    }

    // Checking for READONLY UI - lineno is an indicator used on Results page for fields that don't use Inputs
    if (obj.flag === '+' && ['XX', 'X'].includes(obj.lineno)) {
      return (
        <ReadOnlyMap
          {...obj}
          form={form}
          key={`${obj.field}-${index}`}
          index={index}
          preppedUI={preppedUI}
          wizardDynamicFields={wizardDynamicFields}
          handleModal={handleModal}
        />
      );

      // Integer Inputs
    } else if (NUMERIC_FIELD_TYPES.includes(obj.type)) {
      return (
        <IntegerMap
          {...obj}
          handleChange={handleChange}
          onBlurCalc={onBlurCalc}
          form={form}
          key={`${obj.field}-${index}`}
          index={index}
          preppedUI={preppedUI}
          wizardDynamicFields={wizardDynamicFields}
          handleModal={handleModal}
          currentInputErrors={currentInputErrors}
          getLockedStatus={getLockedStatus}
          singleFLST={singleFLST}
        />
      );

      // Switch/Checkbox
    } else if (obj.type === 'X') {
      return (
        <CheckboxMap
          {...obj}
          handleCheckboxChange={handleCheckboxChange}
          form={form}
          key={`${obj.field}-${index}`}
          index={index}
          preppedUI={preppedUI}
          wizardDynamicFields={wizardDynamicFields}
          handleModal={handleModal}
          isCheckbox={obj.dsplymode === 'box'} // there are no indicators coming from response to determine if it should be switch or check - using form name
          currentInputErrors={currentInputErrors}
          getLockedStatus={getLockedStatus}
          isListItem={obj?.lineno === 'listItem'} // new property tag listItem to help style lists
          singleFLST={singleFLST}
        />
      );

      // Dropdown
    } else if (['&', '!'].includes(obj.flag) || obj.type === 'Q') {
      return (
        <DropdownMap
          {...obj}
          handleChange={handleChange}
          form={form}
          key={`${obj.field}-${index}`}
          index={index}
          preppedUI={preppedUI}
          wizardDropdownOptions={wizardDropdownOptions}
          getDropdownOptions={getDropdownOptions}
          wizardDynamicFields={wizardDynamicFields}
          handleModal={handleModal}
          currentInputErrors={currentInputErrors}
          getLockedStatus={getLockedStatus}
          singleFLST={singleFLST}
        />
      );

      // Letters input
    } else if (obj.type === 'C' || obj.type === 'A' || obj.type === 'a') {
      return (
        <TextFieldMap
          {...obj}
          handleChange={handleChange}
          onBlurCalc={onBlurCalc}
          form={form}
          key={`${obj.field}-${index}`}
          index={index}
          preppedUI={preppedUI}
          wizardDynamicFields={wizardDynamicFields}
          handleModal={handleModal}
          currentInputErrors={currentInputErrors}
          getLockedStatus={getLockedStatus}
          singleFLST={singleFLST}
        />
      );

      // If type is not in the object, that means it is not a CellItem or rather Input field.
      // Handles the dynamic 'Add Button'
    } else if (obj.font === 'LinePrinter') {
      return (
        <DynamicButtonMap
          {...obj}
          formName={formName}
          key={`btn-${index}`}
          index={index}
          updateDynamicFields={updateDynamicFields}
          wizardDynamicFields={wizardDynamicFields}
          handleModal={handleModal}
          getLockedStatus={getLockedStatus}
          singleFLST={singleFLST}
        />
      );

      // Page title or Sectional Title
    } else if (obj.font === 'PageTitle' || obj.font === 'SectionalHeading') {
      return <TitleMap {...obj} key={`title-${index}`} handleModal={handleModal} index={index} />;

      // Warning Message
    } else if (obj.font === 'BodyTextBold') {
      return (
        <WarningMsgMap
          {...obj}
          key={`warningMsg-${index}`}
          handleModal={handleModal}
          index={index}
        />
      );

      // Divider
    } else if (obj.style) {
      return <HRMap {...obj} key={`hr-${index}`} handleModal={handleModal} index={index} />;

      // If we do not want the object we are not mapping it
      // we ignore 'BodyText' which is typically labels
    } else {
      return null;
    }
  });
};

export default Mapper;

export const styles = {
  loginPreferencesTitle: {
    color: 'white',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    padding: '1rem 1rem 0',
  },
  loginPreferencesCheckbox: {
    paddingLeft: '0',
    paddingRight: '5px',
  },
  loginPreferencesCheckboxOutlineIcon: {
    fontSize: '1.5rem',
    color: '#8FC3FF',
  },
  loginPreferencesCheckboxIconSize: { fontSize: '1.5rem' },
  loginPreferencesCheckboxItem: {
    padding: 0,
    fontFamily: 'Roboto',
    fontSize: '1rem',
  },
  loginPreferencesSignatureList: {
    marginTop: '0px',
    paddingTop: '0px',
    marginBottom: '0px',
    paddingBottom: '0px',
  },
  loginPreferencesDriverItem: {
    margin: '0 1rem',
  },
  loginPreferencesSignatureItem: {
    paddingRight: '0px',
    marginRight: '0px',
  },
  loginPreferencesSignatureCheckbox: {
    paddingLeft: 0,
    paddingRight: '5px',
  },
  loginPreferencesCheckboxItemText: {
    padding: 0,
    fontFamily: 'Roboto',
    fontSize: '1rem',
    marginRight: '5px',
  },
  loginPreferencesBtn: { marginRight: '1rem', border: 'none' },
};

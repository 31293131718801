export const styles = {
  searchFormControlBtn: {
    marginTop: '0.25rem',
  },
  searchValue: {
    border: 'none',
    background: 'none',
    padding: '0',
  },
  switchModal: {
    content: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      height: '500px',
      width: '618px',
      padding: '0',
    },
  },
  button: {
    marginLeft: '1rem',
    position: 'absolute',
    top: '21%',
  },
  formStyle: {
    marginLeft: '17em',
    marginTop: '3em',
  },
  back: {
    display: 'inline',
    height: '16px',
    width: '31px',
    color: '#0077FF',
    fontSize: '14px',
    lineHeight: '16px',
    marginRight: '3em',
  },
  continue: {
    height: '35px',
    width: '102px',
    borderRadius: '4px',
    backgroundColor: '#0077FF',
  },
  topPadding: {
    padding: '1em 1em 3em 1em',
  },
  labels: {
    height: '16px',
    width: '95px',
    color: '#354052',
    fontSize: '14px',
    lineHeight: '16px',
  },
  get headerLabels() {
    return {
      ...this.labels,
      fontWeight: '500',
      fontSize: '21px',
      letterSpacing: '0.14px',
      lineHeight: '24px',
    };
  },
  get smallLabels() {
    return {
      ...this.labels,
      fontSize: '12px',
      lineHeight: '14px',
      letterSpacing: '0.08px',
      color: '#637280',
    };
  },
};

// External imports
import React, { Fragment } from 'react';
import {
  Button,
  Collapse,
  IconButton,
  Modal,
  Paper,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  withStyles,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
// Internal imports
import { useSetState } from '~/app/Utility/customHooks';
import AccessControl from '#/Auth/AccessControl.jsx';
import ErrorHelpers from '~/app/errorHelpers.js';
import ManageAncillary from '#/Settings/Setup/AncillaryProducts/ManageAncillary.jsx';
import WebHelpers from '~/app/webHelpers.js';
import XlinkAPI from '~/app/api/xlinkAPI';
// Styling imports
import { styles } from '~/app/Components/NewAccount/SingleOffice/css/customSettings.js';

const CustomSettings = props => {
  const { classes } = props;
  const [state, setState] = useSetState({
    isExpanded: true,
    isManageAncillaryOpen: false,
    activePurchaseIDManaging: 0,
    ancillaryProducts: props.ancillaryProducts,
  });

  const handleExpandClick = () => {
    setState(state => ({ isExpanded: !state.isExpanded }));
  };

  const toggleManageAncillaryModal = purchaseID => {
    setState({
      activePurchaseIDManaging: purchaseID,
      isManageAncillaryOpen: !state.isManageAncillaryOpen,
    });
  };

  const saveAncillaryProductForOffices = async (
    purchaseInfo,
    markupFee,
    financials,
    nonFinancials,
  ) => {
    const newState = state.ancillaryProducts.map((item, i) => {
      if (i === state.activePurchaseIDManaging) {
        return purchaseInfo[0];
      }
      return item;
    });
    setState({
      ancillaryProducts: newState,
    });

    try {
      await XlinkAPI.saveAncillaryProductsInfoByLoginID(
        WebHelpers.getJWTPayload().login_id,
        purchaseInfo,
        markupFee,
        financials,
        nonFinancials,
      );
    } catch (error) {
      ErrorHelpers.handleError('Error saving ancillary product info', error);
    }
  };

  const formatBankApprovedStr = str => {
    if (str === '') {
      return 'N/A';
    }
    return str.substring(0, str.length - 1) + ' Approved';
  };

  if (state.ancillaryProducts.length < 1) {
    return <></>;
  }

  return (
    <>
      <div className="container-fixed">
        <div
          style={{
            textAlign: 'left',
            paddingTop: '3vh',
            paddingBottom: '2vh',
          }}
        >
          <div style={{ float: 'left' }}>
            <IconButton
              style={{ width: '1em', height: '1em' }}
              onClick={handleExpandClick}
              aria-expanded={state.isExpanded}
            >
              {state.isExpanded ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </div>
          <div>
            <span
              style={{
                fontFamily: 'Roboto',
                fontSize: '21px',
                letterSpacing: '0.14px',
                color: '#1A173B',
                marginLeft: '0.3em',
              }}
            >
              Custom Settings
            </span>
          </div>
        </div>
        <Collapse in={state.isExpanded} timeout="auto" unmountOnExit>
          <AccessControl
            requiredAction="write"
            accessLevel="add/edit_billing"
            disableOnFalse={true}
          >
            <div>
              <div style={styles.containerDiv}>
                <div>
                  <Table style={styles.tableStyle}>
                    <TableHead>
                      <TableRow>
                        <TableCell classes={{ root: classes.tableHeadCellRoot }}>FORM</TableCell>
                        <TableCell classes={{ root: classes.tableHeadCellRoot }}>
                          AUTO ADD FINANCIALS
                        </TableCell>
                        <TableCell classes={{ root: classes.tableHeadCellRoot }}>
                          AUTO ADD NON-FINANCIALS
                        </TableCell>
                        <TableCell classes={{ root: classes.tableHeadCellRoot }}>
                          ADD ON FEE/MARKUP AMOUNT
                        </TableCell>
                        <TableCell classes={{ root: classes.tableHeadCellRoot }}>
                          BASE FEE
                        </TableCell>
                        {WebHelpers.getJWTPayload().is_office_root ? (
                          <TableCell classes={{ root: classes.tableHeadCellRoot }}>
                            BANK APPROVED
                          </TableCell>
                        ) : (
                          <></>
                        )}
                        {WebHelpers.getJWTPayload().is_office_root ? (
                          <TableCell classes={{ root: classes.tableHeadCellRoot }}>
                            ACTION
                          </TableCell>
                        ) : (
                          <></>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {state.ancillaryProducts.map((product, i) => {
                        return (
                          <TableRow key={i}>
                            <TableCell classes={{ root: classes.tableCellRoot }}>
                              {product.ancillary_product_name}
                            </TableCell>
                            <TableCell classes={{ root: classes.tableCellRoot }}>
                              {product.financials ? 'Included' : 'Not Included'}
                            </TableCell>
                            <TableCell classes={{ root: classes.tableCellRoot }}>
                              {product.non_financials ? 'Included' : 'Not Included'}
                            </TableCell>
                            <TableCell classes={{ root: classes.tableCellRoot }}>
                              ${product.add_on_markup_fee}
                            </TableCell>
                            <TableCell classes={{ root: classes.tableCellRoot }}>
                              ${product.BaseFee}
                            </TableCell>
                            {WebHelpers.getJWTPayload().is_office_root ? (
                              <TableCell classes={{ root: classes.tableCellRoot }}>
                                {formatBankApprovedStr(product.bank_approved)}
                              </TableCell>
                            ) : (
                              <></>
                            )}
                            {WebHelpers.getJWTPayload().is_office_root ? (
                              <TableCell classes={{ root: classes.tableCellRoot }}>
                                <Button onClick={() => toggleManageAncillaryModal(i)}>
                                  Manage
                                </Button>
                              </TableCell>
                            ) : (
                              <></>
                            )}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </div>
                <br />
              </div>
            </div>
          </AccessControl>
        </Collapse>
      </div>
      <Modal
        id="manageAncillaryProductModal"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={state.isManageAncillaryOpen}
        onClose={toggleManageAncillaryModal}
      >
        <Paper elevation={5} classes={{ root: classes.manageAncillaryModal }}>
          <ManageAncillary
            toggleManageAncillary={toggleManageAncillaryModal}
            purchaseInfo={[state.ancillaryProducts[state.activePurchaseIDManaging]]}
            saveAncillaryProductForOffices={saveAncillaryProductForOffices}
            officeList={null}
            formatBankApprovedStr={formatBankApprovedStr}
          />
        </Paper>
      </Modal>
    </>
  );
};

export default withStyles(styles)(CustomSettings);

import moment from 'moment';

/**
 * Retrieves documents from parent comp, and parses through it to create a Sorted array from the documents.
 * This is then used in getArchiveTable that renders the Table jsx to generate the rows
 *
 * @returns {array} Array of sorted documents
 */
export const getRemoteDocumentList = documents => {
  const docs = documents.sort((a, b) => parseFloat(b.document_id) - parseFloat(a.document_id));

  return docs;
};

/**
 * Handles building a preview string to be displayed on a table
 *
 * @param {string} string the string value of a column
 * @returns {string} a preview string to display on a table
 */
export const buildPreviewString = string => {
  return string?.length > 30 ? `${string.slice(0, 30)}...` : string;
};

/**
 * Determines the remaining days until expiration using the update date
 *
 * @param {string} updateDateTmpz update_date timestamp
 * @returns {number} remaining days
 */
export const buildExpireInDate = updateDateTmpz => {
  let remainingDays = 0;
  const currentDate = moment(moment.localeData()).format('YYYY-MM-DD');
  const updatedDate = moment(updateDateTmpz).format('YYYY-MM-DD');

  if (currentDate.substring(0, 7) === updatedDate.substring(0, 7)) {
    const currDay = parseInt(currentDate.substring(8, 10));
    const updDay = parseInt(updatedDate.substring(8, 10));
    if (updDay + 3 > currDay) {
      remainingDays = 3 - (currDay - updDay);
    }
  }

  return remainingDays;
};

/**
 * Convert database timestampz to UTC to local timestamp.
 *
 * @param {timestampz} createDateTmpz
 * @returns create date in customer's local time
 */
export const convertTimestampToLocal = createDateTmpz => {
  const createDate = moment.utc(createDateTmpz);
  return moment(createDate).local().format('MM/DD/YYYY h:mm A');
};

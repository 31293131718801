export const styles = {
  mfaCodePaperContainer: {
    height: '31.1rem',
    width: '35.625rem',
    norderRadius: '4px',
    margin: 'auto',
    position: 'relative',
  },
  mfaConfirmationKeyLabel: { paddingBottom: '14px' },
  mfaCodeFields: { paddingLeft: '15px', height: '2.5rem' },
  mfaIssueTextlabel: { marginBottom: '1rem', fontSize: '14px' },
  mfaMarkPublicFormControlLabel: { fontSize: '14px' },
  mfaMarkPublicCheckbox: {
    width: 17,
    height: 16,
    marginRight: '0.3vw',
  },
  mfaMarkPublicCheckboxOutline: { fontSize: 24, color: '#8FC3FF' },
  mfaMarkPublicCheckboxIcon: { fontSize: 24 },
  mfaBackButton: {
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
    float: 'left',
    paddingRight: '2.25rem',
  },
  mfaContinueButton: {
    padding: '0 3vw',
    float: 'left',
    height: '2.19rem',
    width: '6.375rem',
    borderRadius: '4px',
  },
  checkboxInputProps: {
    height: '1.25rem',
    width: '1.25rem',
  },
};

import React, { useEffect } from 'react';
// Internal imports
import { getBreakpointGrid } from '../wizardModeHelpers';
import XlinkAPI from '~/app/api/xlinkAPI.js';
import ErrorHelpers from '~/app/errorHelpers.js';
// External imports
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, Divider } from '@material-ui/core';
// Redux Imports
import { useDispatch } from 'react-redux';
import { actions as appActions } from '~/app/redux/modules/app.js';
import { actions as returnProfileActions } from '~/app/redux/returnProfile/duck.js';
// Styling imports
import { styles } from '~/app/Pages/WizardMode/components/Forms/css/formStyles.js';
import '~/app/Pages/WizardMode/components/Forms/css/formStyles.css';

/**
 * Handles displaying the footer UI and navigation on each page
 *
 * @returns {JSX} footer UI for each page
 */
const Footer = props => {
  const { classes } = props;
  const dispatch = useDispatch();

  // handles determining the breakpoint and also handles checking if a property exists.
  const nextTextBreakpoint = getBreakpointGrid(null, props.nextText, 'Footer');
  const backTextBreakpoint = getBreakpointGrid(null, props.backText, 'Footer');

  /** Handles deciding logic based on the current step */
  const onNextClick = async () => {
    // If wizard is already completed, just incre step
    if (props.wizardCurrentStep === 8 && !props.wizardCompleted) {
      // If user has not chosen a filing status, do not allow them to proceed.
      if (!props.hasFLST()) {
        props.handleFilingStatusModal(true);
      } else {
        try {
          await XlinkAPI.completeWizard(props.returnID);
          dispatch(returnProfileActions.setWizardCompleted(true));
          props.handleStep(9);
        } catch (error) {
          dispatch(
            appActions.showSnackbarMessage(error, 'error', 3500, {
              vertical: 'top',
              horizontal: 'center',
            }),
          );
          ErrorHelpers.handleError(
            'There was a problem completing questionnare. Please try again',
            error,
          );
        }
      }
    } else {
      props.handleStep(props.wizardCurrentStep + 1);
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <Divider className={classes.dividerStyle} />
      </Grid>
      <Grid container item xs={12} className={`${classes.footerContainerStyle} wizard-footer`}>
        {props.backText && (
          <Grid
            container
            item
            xs={!props.nextText || 12}
            md={nextTextBreakpoint}
            lg={nextTextBreakpoint}
            justify="flex-start"
          >
            <Button
              onClick={() => props.handleStep(props.wizardCurrentStep - 1)}
              className={classes.buttonStyle}
              id="BackBtn"
            >
              {`Back - ${props.backText}`}
            </Button>
          </Grid>
        )}
        {props.nextText && (
          <Grid
            container
            item
            xs={!props.backText || 12}
            md={backTextBreakpoint}
            lg={backTextBreakpoint}
            justify="flex-end"
          >
            <Button
              onClick={() => onNextClick()}
              className={`${classes.wizardNextButton} ${classes.buttonStyle} ${
                !props.enableFooter ? 'wizard-footer-disabled' : ''
              }`}
              id="NextBtn"
            >
              {`${props.wizardCurrentStep !== 8 ? 'Next - ' : ''}${props.nextText}`}
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default withStyles(styles)(Footer);

import React, { useEffect } from 'react';
// Internal imports
import { tabListDefinitions } from '~/app/Pages/Returns/DataModel/FormViewer.js';
import { useSetState } from '~/app/Utility/customHooks.js';
import addFormIcon from '~/images/icons/add_form.png';
import FormActivityList from '~/app/Pages/Returns/components/FormActivityList.jsx';
import XlinkAPI from '~/app/api/xlinkAPI';
import ErrorHelpers from '~/app/errorHelpers.js';
// External imports
import {
  withStyles,
  AppBar,
  Button,
  NativeSelect,
  Tabs,
  Tab,
  Typography,
  Input,
} from '@material-ui/core';
// Redux imports
import { useDispatch, useSelector } from 'react-redux';
import { actions as formViewerActions } from '~/app/redux/modules/formViewer';
// Styling imports
import { styles } from '~/app/Pages/Returns/css/formViewerModals.js';

/**
 * Add Form modal used to add forms to the formviewer sidebar and added to the return
 *
 * @component NewAddFormModal
 * @category FormViewer
 */
const NewAddFormModal = props => {
  const { classes } = props;
  const dispatch = useDispatch();
  const {
    tabSelected,
    addFormList,
    activityList,
    setSelectedForm,
    addFormListFiltered,
    filterOn,
    statePackageSelected,
    formHasSubdialog,
    subdialogFormList,
  } = useSelector(state => ({
    tabSelected: state.formViewer.addFormTabValue,
    addFormList: state.formViewer.addFormList,
    activityList: state.formViewer.activityList,
    setSelectedForm: state.formViewer.setSelectedForm,
    addFormListFiltered: state.formViewer.addFormListFiltered,
    filterOn: state.formViewer.filterOn,
    statePackageSelected: state.formViewer.statePackageSelected,
    formHasSubdialog: state.formViewer.formWithSubdialog,
    subdialogFormList: state.formViewer.setSubDialogList,
  }));

  const [state, setState] = useSetState({
    open: true,
    prefs: {},
    prefsToSend: {},
    checkedPrefs: [],
    allowEmailDoc: false, // Set to true once we add this functionality on calc server
    selectedState: '',
    rowSelected: null,
    formSelected: addFormList[0],
    filterString: '',
    filteredFormList: [],
    subDialogList: [],
    subDialogOpen: false,
  });

  useEffect(() => {
    // Fetch forms
    dispatch(formViewerActions.setFilterStatus(false));
    dispatch(formViewerActions.setFormHasSubdialog(false));
    dispatch(formViewerActions.setAddFormTab(props.activeTab));

    // On load clear the selected value from previous setting
    dispatch(formViewerActions.setStatePackageSelected(''));
  }, []);

  /** Handles the cancel rdx actions when the modal is closed */
  const handleCancelButton = () => {
    dispatch(formViewerActions.setFilterStatus(false));
    dispatch(formViewerActions.setFormHasSubdialog(false));
    dispatch(formViewerActions.setAddFormTab(0));
  };

  /**
   * Handles updating formviwer sidebar form list
   *
   * @param {string} tabNumber used to navigate to a specific tab
   * @param {string} pkg used tp update the form list with a specific package
   */
  const setTabAndFormList = async (tabNumber, pkg) => {
    if (tabNumber !== 3) {
      dispatch(formViewerActions.setStatePackageSelected(''));
    }

    dispatch(formViewerActions.setFormHasSubdialog(false));
    dispatch(formViewerActions.setAddFormTab(tabNumber));

    await props.updateAddFormList(pkg);
    // Reset filter string to ""
    dispatch(formViewerActions.setFilterStatus(false));
    setState({
      formSelected: addFormList[0],
      filterString: '',
    });
  };

  /**
   * Handles navigation between tabs
   *
   * @param {Object} tabObject used to get the package name
   * @param {Number} index used to navigate to a specific tab
   */
  const changeTab = (tabObject, index) => {
    setTabAndFormList(index, tabObject.pkg);
  };

  /**
   * Handles selecting the form to update
   *
   * @param {Object} forms data used to add the form
   */
  const handleUpdateAddFormValue = forms => {
    dispatch(formViewerActions.setSelectedFormToAdd(forms));
  };

  /** Handles setting the form based on the tab that is being navigated to */
  const handleAddFormValueButtonClick = () => {
    if (setSelectedForm.subFlag === '1') {
      event.preventDefault();
      dispatch(formViewerActions.setFormHasSubdialog(true));
      dispatch(formViewerActions.setSubDialogFormList(setSelectedForm.subDlg));
      dispatch(formViewerActions.setSelectedFormToAdd(setSelectedForm.subDlg[0]));
    } else {
      dispatch(formViewerActions.setSelectedFormToAdd(setSelectedForm));
      // Add Form Here (Equivalent to "Add" click)
      if (tabSelected === 4) {
        props.addAsset(setSelectedForm);
      } else if (tabSelected === 1) {
        props.handleAddFormIndex(setSelectedForm);
      } else if (tabSelected === 0 || tabSelected === 2 || tabSelected === 3) {
        props.handleAddForm(setSelectedForm);
      }
    }
  };

  /**
   * Handles adding the form if the row is double clicked
   *
   * @param {Object} forms data used to dispatch what form was clicked
   */
  const handleUpdateAddFormValueDoubleClick = forms => {
    if (
      !props.isSuperOrTechUserReadOnly() &&
      !props.isLocked() &&
      !(props.isFeederOffice && props.readyForReview)
    ) {
      if (forms.subFlag === '1') {
        event.preventDefault();

        dispatch(formViewerActions.setFormHasSubdialog(true));
        dispatch(formViewerActions.setSubDialogFormList(forms.subDlg));
        dispatch(formViewerActions.setSelectedFormToAdd(forms.subDlg[0]));
      } else {
        dispatch(formViewerActions.setSelectedFormToAdd(forms));
      }

      // Add Form Here (Equivalent to "Add" click)
      if (tabSelected === 4 && forms.subFlag !== '1') {
        props.addAsset(setSelectedForm === undefined ? activityList[0] : setSelectedForm);
      } else if (tabSelected === 1 && forms.subFlag !== '1') {
        props.handleAddFormIndex(setSelectedForm === undefined ? addFormList[0] : setSelectedForm);
      } else if (
        forms.subFlag !== '1' &&
        (tabSelected === 0 || tabSelected === 2 || tabSelected === 3)
      ) {
        props.handleAddForm(setSelectedForm === undefined ? addFormList[0] : setSelectedForm);
      }
    }
  };

  /**
   * Handles the selected state from the dropdown
   *
   * @param {Object} e eventDom used to get the value of an input
   */
  const handleSelectedStateChange = e => {
    dispatch(formViewerActions.setStatePackageSelected(e.target.value));
    setTabAndFormList(3, e.target.value);
  };

  /**
   * getAttachedStatesList is a helper function that returns an array of attached states wrapped in <option /> tags.
   *
   * @returns {jsx} used to display options
   */
  const getAttachedStatesList = () => {
    const attachedStates = [];
    props.formList.forEach((category, index) => {
      if (
        category.var !== '00' &&
        category.var !== 'US' &&
        category.var !== 'PF' &&
        category.var !== 'retn37'
      ) {
        attachedStates.push(
          <option
            key={`attachedState-${category}-${index}`}
            value={category.var}
            id={'attachedState-' + index}
          >
            {category.var}
          </option>,
        );
      }
    });
    return attachedStates;
  };

  /**
   * Handles fetching the states that can be selected from a dropdown
   *
   * @returns {jsx} used to display state package names
   */
  const getStateList = () => {
    // Since it's possible there are no states attached, we'll conditionally(If at least one state is attached)
    // wrap the returned array in an optgroup.
    let attachedStates = getAttachedStatesList();
    attachedStates =
      attachedStates.length >= 1 ? (
        <optgroup value={attachedStates[0].value} label="Attached States">
          {attachedStates}
        </optgroup>
      ) : (
        attachedStates
      );
    const pkgList = props.availablePackages;

    return (
      <NativeSelect
        id="stateSelect"
        value={statePackageSelected}
        disableUnderline
        onChange={event => handleSelectedStateChange(event)}
      >
        {<option value="" />}
        {attachedStates}
        <optgroup value="" label="All States">
          {pkgList.map(state => {
            return (
              <option key={state} value={state} id={state}>
                {state}
              </option>
            );
          })}
        </optgroup>
      </NativeSelect>
    );
  };

  /**
   * Handles building the tabs to be displayed as UI
   *
   * @returns {jsx} Tabs that can be selected 'State' 'Index' 'Deprecation'
   */
  const getTabList = () => {
    const subTabs = [];

    tabListDefinitions.forEach((itr, index) => {
      subTabs.push(
        <Tab
          key={`tab-${index}`}
          label={itr.label}
          classes={{
            root: classes.tabRoot,
            selected: classes.tabSelected,
          }}
          onClick={() => changeTab(itr, index)}
        />,
      );
    });

    return (
      <Tabs
        value={tabSelected}
        position="static"
        classes={{
          root: classes.tabsRoot,
          indicator: classes.tabsIndicator,
        }}
      >
        {subTabs}
      </Tabs>
    );
  };

  /**
   * formNameList takes forms filtered by form names from the unfiltered list,
   * formDescList takes forms filtered by descriptions from the unfiltered list,
   * we then concatenate the lists to display both
   *
   * @param {Object} formList used to filter for desc and irsName
   * @param {string} filterString the string to filter by
   */
  const getFilteredElements = (formList, filterString) => {
    let formNameList = formList.filter(form =>
      form.irsName.toLowerCase().includes(filterString.toLowerCase()),
    );
    const formDescList = formList.filter(form =>
      form.desc.toLowerCase().includes(filterString.toLowerCase()),
    );

    formNameList = formNameList.concat(formDescList);

    // remove duplicates
    const uniqueFormList = formNameList.filter((value, index) => {
      const valueStringed = JSON.stringify(value); // stringify the object for comparison
      // findIndex returns the first index of a match
      // duplicates will not have a matching index, we use this to filter them out
      return (
        index ===
        formNameList.findIndex(obj => {
          return JSON.stringify(obj) === valueStringed; // compare the two objects stringified
        })
      );
    });

    dispatch(formViewerActions.setAddFormListFiltered(uniqueFormList));
  };

  /**
   * Handles changing the filter being used
   *
   * @param {Object} e eventDOM used to get the value of an input
   */
  const handleFilterChange = e => {
    const forms = props.availableForms;
    // Set global state to check if user is searching for a particular form
    if (e.target.value.length > 0) {
      dispatch(formViewerActions.setFilterStatus(true));
    } else {
      dispatch(formViewerActions.setFilterStatus(false));
    }
    setState({
      filterString: e.target.value,
      filteredFormList: getFilteredElements(forms, e.target.value),
    });
  };

  return (
    <div>
      <div>
        <Typography id="simple-modal-title" className={classes.modalTitle}>
          <img src={addFormIcon} className={classes.addFormIconStyle} /> All Forms & Schedules
          <Button className={classes.cancelIcon} onClick={props.handleCloseFormModal}>
            X
          </Button>
        </Typography>
      </div>

      {tabSelected === 3 ? (
        <div className="margin-container-15">
          <label htmlFor="txtFilterPackageAddFormModal" className={classes.searchBarLabel}>
            Search
          </label>
          <Input
            classes={{ root: classes.inputRoot }}
            disableUnderline
            id="txtFilterPackageAddFormModal"
            placeholder="Schedule or Form Name"
            onChange={event => handleFilterChange(event)}
            value={state.filterString}
            disabled={formHasSubdialog}
          />
          <label htmlFor="stateSelect" className={classes.packageLabel}>
            Package
          </label>
          {getStateList()}
        </div>
      ) : (
        <div className="container">
          <label htmlFor="txtFilterDefaultAddFormModal" className={classes.searchBarLabel}>
            Search
          </label>
          <Input
            disableUnderline
            id="txtFilterDefaultAddFormModal"
            placeholder="Schedule or Form Name"
            onChange={event => handleFilterChange(event)}
            value={state.filterString}
            disabled={formHasSubdialog}
            className={classes.searchBarInput}
            autoFocus={true}
          />
        </div>
      )}
      <div>
        <AppBar position="static" className={classes.tabListBarStyle}>
          {getTabList()}
          <div className={classes.activityListContainer}>
            <FormActivityList
              formAndActivityList={formHasSubdialog ? subdialogFormList : addFormList}
              activityList={activityList}
              selectedTab={tabSelected}
              setSelectedForm={setSelectedForm}
              handleChangeAddFormValue={handleUpdateAddFormValue}
              haveFilterOn={filterOn}
              filteredFormList={formHasSubdialog ? subdialogFormList : addFormListFiltered}
              currentStatePackage={statePackageSelected}
              handleChangeAddFormValueDoubleClick={handleUpdateAddFormValueDoubleClick}
            />
          </div>
        </AppBar>
        <div className={`container ${classes.footerContainer}`}>
          <Button
            id="btnCancel"
            color="primary"
            size="small"
            className={classes.cancelButtonRoot}
            onClick={() => (formHasSubdialog ? handleCancelButton() : props.handleCloseFormModal())}
          >
            Cancel
          </Button>
          <Button
            id="btnAdd"
            color="primary"
            size="small"
            variant="contained"
            onClick={() => handleAddFormValueButtonClick()}
            disabled={props.isSuperOrTechUserReadOnly() || props.isLocked()} //
          >
            Add
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(NewAddFormModal);

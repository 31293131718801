export const styles = {
  pagedTableHeadStickyHead: {
    borderCollapse: 'separate',
  },
  pagedTableHeadRow: {
    height: '14px',
    letterSpacing: '1px',
  },
  pagedTableHeadCell: {
    color: '#1E1B3F',
    fontFamily: 'Roboto',
    textTransform: 'uppercase',
  },
  pagedTableStickyHeadCell: {
    paddingTop: '10px',
    paddingBottom: '10px',
    backgroundColor: '#fff',
    color: '#1E1B3F',
    fontFamily: 'Roboto',
    position: 'sticky',
    textTransform: 'uppercase',
    top: 0,
    zIndex: 2,
  },
  pagedTableStickyHeadCellBorder: {
    backgroundColor: '#fff',
    color: '#1E1B3F',
    fontFamily: 'Roboto',
    padding: '10px 16px 4px 16px',
    position: 'sticky',
    textTransform: 'uppercase',
    top: 0,
    zIndex: 2,
    minWidth: '225px',
    verticalAlign: 'bottom',
  },
  pagedTableBodyRow: {
    height: '3rem',
    transition: '0.5s',
    cursor: 'pointer',
    '&:selected': {
      backgroundColor: '#EBEDF8',
      opacity: 1,
    },
    '&:hover': {
      backgroundColor: '#EBEDF8',
      opacity: 1,
    },
  },
  pagedTableBodyRowItalicized: {
    height: '3rem',
    transition: '0.5s',
    cursor: 'pointer',
    '&:selected': {
      backgroundColor: '#EBEDF8',
      opacity: 1,
    },
    '&:hover': {
      backgroundColor: '#EBEDF8',
      opacity: 1,
    },
    fontStyle: 'italic',
  },
  pagedTableBodyRowDetails: {
    '&:selected': {
      backgroundColor: '#EBEDF8',
      opacity: 1,
    },
  },
  pagedTableBodyRowDetailsItalicized: {
    fontStyle: 'italic',
    '&:selected': {
      backgroundColor: '#EBEDF8',
      opacity: 1,
    },
  },
  pagedTableBodyCell: {
    borderBottom: 'none',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    lineHeight: '20px',
    color: '#67727C',
    maxWidth: '200px',
    wordBreak: 'break-all',
  },
  pagedTableBodyCellBorder: {
    fontFamily: 'Roboto',
    fontSize: '1rem',
    lineHeight: '20px',
    color: '#67727C',
    borderLeft: '0.1px solid #e3e3e3',
    borderRight: '0.1px solid #e3e3e3',
    borderBottom: '0.1px solid #e3e3e3',
  },
  pagedTableCellIcon: {
    borderBottom: 'none',
    padding: '0 2rem 0 0',
    width: '1rem',
  },
  pagedTableBodyCellContextButton: {
    minHeight: '35px',
    maxHeight: '35px',
    minWidth: '24px',
    maxWidth: '24px',
    padding: '0',
  },
  pagedTableFooterPagPadding: {
    paddingRight: '5rem',
  },
};

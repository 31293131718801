import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
// External imports
import { Paper } from '@material-ui/core';
// Internal imports
import {
  areAgreementsSigned,
  isTechOrAdminLoginFromRole,
  isDemoAccount,
} from '~/app/Utility/general';
import { useSetState } from '~/app/Utility/customHooks';
import WebHelpers from '~/app/webHelpers';
// Style import
import './account-info-banner.css';
// Image imports
import warningIcon from '~/images/icons/warningicon.png';

const AccountInfoBanner = props => {
  const payload = WebHelpers.getJWTPayload();
  const { currentView, agreementsStatus, officeProfile } = useSelector(state => ({
    currentView: state.drilldown.drilldownHistory[state.drilldown.drilldownHistory.length - 1],
    agreementsStatus: state.overview.agreementsStatus,
    officeProfile: state.officeProfile,
  }));

  const [state, setState] = useSetState({
    showMsg: '',
  });

  // Check if we should enable message
  useEffect(() => {
    let msg = '';
    // hide message if super user / tech / CLODMO
    if (isTechOrAdminLoginFromRole(currentView) || isDemoAccount(payload)) {
      setState({ showMsg: msg });
      return;
    }

    if (areAgreementsSigned(agreementsStatus, payload?.season)) {
      // Display Agreements Msg
      msg =
        "Your account owner's agreements have not been completed. Please reach out to your account owner to have them complete all agreements.";
    } else if (officeProfile?.hasHold && officeProfile?.office_id) {
      // Display EFIN Hold
      msg =
        'Your office has been placed under a hold status, please reach out to your account owner or contact support for more information.';
    } else if (
      !officeProfile?.has1040License &&
      !officeProfile?.hasBusinessLicense &&
      !officeProfile?.hasSubsequentLicense &&
      officeProfile?.office_id
    ) {
      // Display Licence Issues
      msg =
        'The office currently does not have a license for the selected tax year. Please purchase a license for the selected tax year to continue to create returns.';
    } else if (
      !officeProfile?.has1040License &&
      !officeProfile?.hasSubsequentLicense &&
      officeProfile?.office_id &&
      (!(props.isInsideReturn === payload?.is_business) || !props.isInsideReturn)
    ) {
      // Display Licence Issues
      msg =
        'The office currently does not have a 1040 license for the selected tax year. Please purchase a license for the selected tax year to continue to create returns.';
    } else if (
      officeProfile.hasBusinessReturns &&
      !officeProfile?.hasBusinessLicense &&
      officeProfile?.office_id &&
      (props.isInsideReturn === payload?.is_business || !props.isInsideReturn) &&
      payload?.season >= 2024
    ) {
      // Display Licence Issues
      msg =
        'The office currently does not have a Business license for the selected tax year. Please purchase a license for the selected tax year to continue to create returns.';
    }

    // Update our display
    setState({ showMsg: msg });
  }, [agreementsStatus, currentView, officeProfile]);

  return (
    <>
      <Paper
        className={`importAlert verticallyCenter filterContainer msg-alert 
        account-info-msg-container ${state.showMsg ? 'show-msg' : ''}`}
        elevation={0}
      >
        <div className="filterContent">
          <div>
            <img src={warningIcon} className="filterIcon" />
            <span className="msg-alert-label">IMPORTANT:</span>
            {state.showMsg}
          </div>
          <div>Functionality may be limited.</div>
        </div>
      </Paper>
    </>
  );
};

export default AccountInfoBanner;

import * as Common from '~/app/redux/commonActions.js';

export const FETCH_OFFICE_PROFILE = 'xlinkonline/office/FETCH_OFFICE_PROFILE';
export const SET_OFFICE_PROFILE = 'xlinkonline/office/SET_OFFICE_PROFILE';

export const initialState = {
  efin_id: 0,
  efin: '',
  office_id: 0,
  firm_name: '',
  fees_id: 0,
  default_pin: '',
  is_feeder_office: false,
  transmission_blocked: false,
  address_contact_id: 0,
  address_name: '',
  address1: '',
  address2: '',
  address3: '',
  city: '',
  state_code: '',
  state_name: '',
  postal_code: '',
  full_address: '',
  licenseInfo: [],
  createdBeforeLicenseFeature: false,
  hasHold: false,
  has1040License: false,
  hasBusinessLicense: false,
  hasBusinessReturns: false,
  oldestLicenseSeason: 0,
  licenseSeasons: [],
  lType: 0,
};

export const actions = {
  // It would be nice if this can be grouped with pulling all office info when the app loads up.
  fetchOfficeProfile: () => ({
    type: FETCH_OFFICE_PROFILE,
  }),
  setOfficeProfile: officeProfile => ({
    type: SET_OFFICE_PROFILE,
    officeProfile,
  }),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_OFFICE_PROFILE:
      return action.officeProfile;
    case Common.LOGOUT:
      return initialState;
  }

  return state;
}

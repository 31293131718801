// Overview Duck Module
import { DASHBOARD_DATA, OV_NOTEBOOK_TABS } from '~/app/constants.js';
import { LOGOUT } from '~/app/redux/commonActions.js';

const SELECT_TAB = 'xlinkonline/overview/SELECTTAB';
const UPDATE_TABS = 'xlinkonline/overview/UPDATETAB';
const UPDATE_ADMIN_TABLE = 'xlinkonline/overview/UPDATEADMINTABLE';
const UPDATE_SSB_TABLE = 'xlinkonline/overview/UPDATESSBTABLE';
const UPDATE_SB_TABLE = 'xlinkonline/overview/UPDATESBTABLE';
const UPDATE_OFFICE_TABLE = 'xlinkonline/overview/UPDATEOFFICETABLE';
const UPDATE_PREPARER_TABLE = 'xlinkonline/preview/UPDATEPREPARERTABLE';
const UPDATE_CLERK_TABLE = 'xlinkonline/overview/UPDATECLERKTABLE';
const SET_NOTEBOOK_TAB_STATUS = 'xlinkonline/overview/SETNOTEBOOKTABSTATUS';
const SET_SB_QUERY_STATUS = 'xlinkonline/overview/SETSBQUERYSTATUS';
const SET_OFFICE_QUERY_STATUS = 'xlinkonline/overview/SETOFFICEQUERYSTATUS';
const SET_ADMIN_QUERY_STATUS = 'xlinkonline/overview/SETADMINQUERYSTATUS';
const SET_CHECK_BADGE_COUNT = 'xlinkonline/overview/SETCHECKBADGECOUNT';
const SET_MESSAGE_BADGE_COUNT = 'xlinkonline/overview/SETMESSAGEBADGECOUNT';
const INCREMENT_MESSAGE_BADGE_COUNT = 'xlinkonline/overview/INCREMENTMESSAGEBADGECOUNT';
const SET_ROW_ID = 'xlinkonline/overview/SET_ROW_ID';
const SET_AGREEMENTS_STATUS = 'xlinkonline/overview/SET_AGREEMENTS_STATUS';

const initialState = {
  tabData: {
    [OV_NOTEBOOK_TABS.TAX_RETURNS]: {
      label: 'Tax Returns',
      firstCount: '',
      firstLabel: 'EF Return',
      secondCount: '',
      secondLabel: 'Paper Return',
    },
    [OV_NOTEBOOK_TABS.ACCEPTED]: {
      label: 'Accepted',
      firstCount: '',
      firstLabel: 'Acknowledged',
      secondCount: '',
      secondLabel: 'Check Ready',
    },
    [OV_NOTEBOOK_TABS.REJECTS]: {
      label: 'Rejected',
      firstCount: '',
      firstLabel: 'Rejected/Refused',
      secondCount: '',
      secondLabel: 'State Rejected/Refused',
    },
    [OV_NOTEBOOK_TABS.EXTENSIONS]: {
      label: 'Extensions ',
      firstCount: '',
      firstLabel: 'Extensions Ack',
      secondCount: '',
      secondLabel: 'Extensions Rejected/Refused',
    },
  },
  selected: OV_NOTEBOOK_TABS.TAX_RETURNS,

  // role-based overview tables
  rowCount: DASHBOARD_DATA.PREVIEWTABLESIZE, // row count for overview tables
  ssbOv: [], // list of sbs for a ssb
  sbOv: [], // list of offices for a sb
  officeOv: [], // list of preparers for an office
  preparerOv: [], // list of returns for a preparer
  clerkOv: [], // list of appointments for a clerk
  adminOv: [], // list of top level accounts

  notebookTabQueryDone: false,
  officeQueryDone: false,
  sbQueryDone: false,
  adminQueryDone: false,
  adminActionsVisible: false,
  checkBadgeCount: 0,
  messageBadgeCount: 0,
  rowID: 0,
  agreementsStatus: 'N', // Default to N and wait on API
};

export const actions = {
  onSelectNotebookTab: selected => ({
    type: SELECT_TAB,
    selected,
  }),
  updateNotebookTabs: returnStatus => ({
    type: UPDATE_TABS,
    returnStatus,
  }),
  updateAdminTable: accounts => ({
    type: UPDATE_ADMIN_TABLE,
    accounts,
  }),
  updateSSBTable: efins => ({
    type: UPDATE_SSB_TABLE,
    efins,
  }),
  updateSBTable: offices => ({
    type: UPDATE_SB_TABLE,
    offices,
  }),
  updateOfficeTable: preparers => ({
    type: UPDATE_OFFICE_TABLE,
    preparers,
  }),
  updatePreparerTable: returns => ({
    type: UPDATE_PREPARER_TABLE,
    returns,
  }),
  updateClerkTable: appts => ({
    type: UPDATE_CLERK_TABLE,
    appts,
  }),
  updateAdminQueryStatus: status => ({
    type: SET_ADMIN_QUERY_STATUS,
    status,
  }),
  updateNotebokTabStatus: status => ({
    type: SET_NOTEBOOK_TAB_STATUS,
    status,
  }),
  updateOfficeQueryStatus: status => ({
    type: SET_OFFICE_QUERY_STATUS,
    status,
  }),
  updateSBQueryStatus: status => ({
    type: SET_SB_QUERY_STATUS,
    status,
  }),
  setCheckBadgeCount: num => ({
    type: SET_CHECK_BADGE_COUNT,
    num,
  }),
  setMessageBadgeCount: num => ({
    type: SET_MESSAGE_BADGE_COUNT,
    num,
  }),
  incrementMessageBadgeCount: () => ({
    type: INCREMENT_MESSAGE_BADGE_COUNT,
  }),
  setRowID: id => ({
    type: SET_ROW_ID,
    id,
  }),
  setAgreementsStatus: status => ({
    type: SET_AGREEMENTS_STATUS,
    status,
  }),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_TAB:
      return {
        ...state,
        selected: action.selected,
      };

    case UPDATE_TABS:
      state.tabData[0] = {
        ...state.tabData[0],
        firstCount: action.returnStatus.ef_return,
        secondCount: action.returnStatus.paper_return,
      };

      state.tabData[1] = {
        ...state.tabData[1],
        firstCount: action.returnStatus.accepted,
        secondCount: action.returnStatus.check_ready,
      };

      state.tabData[2] = {
        ...state.tabData[2],
        firstCount: action.returnStatus.rejections,
        secondCount: action.returnStatus.state_rejections,
      };

      state.tabData[3] = {
        ...state.tabData[3],
        firstCount: action.returnStatus.ext_accepted,
        secondCount: action.returnStatus.ext_rejections,
      };
      return {
        ...state,
        notebookTabQueryDone: true,
      };
    case SET_NOTEBOOK_TAB_STATUS:
      return {
        ...state,
        notebookTabQueryDone: false,
        selected: 0,
      };
    case UPDATE_ADMIN_TABLE:
      return {
        ...state,
        adminOv: action.accounts,
        adminQueryDone: true,
      };
    case UPDATE_SSB_TABLE:
      return {
        ...state,
        ssbOv: action.efins,
      };
    case UPDATE_SB_TABLE:
      return {
        ...state,
        sbOv: action.offices,
        sbQueryDone: true,
      };
    case UPDATE_OFFICE_TABLE:
      return {
        ...state,
        officeOv: action.preparers,
        officeQueryDone: true,
      };
    case UPDATE_PREPARER_TABLE:
      return {
        ...state,
        preparerOv: action.returns,
      };
    case UPDATE_CLERK_TABLE:
      return {
        ...state,
        clerkOv: action.appts,
      };
    case LOGOUT:
      return initialState;
    case SET_OFFICE_QUERY_STATUS:
      return {
        ...state,
        officeQueryDone: action.status,
      };
    case SET_ADMIN_QUERY_STATUS:
      return {
        ...state,
        adminQueryDone: action.status,
      };
    case SET_SB_QUERY_STATUS:
      return {
        ...state,
        sbQueryDone: action.status,
      };
    case SET_CHECK_BADGE_COUNT:
      return {
        ...state,
        checkBadgeCount: action.num,
      };
    case SET_MESSAGE_BADGE_COUNT:
      return {
        ...state,
        messageBadgeCount: action.num,
      };
    case INCREMENT_MESSAGE_BADGE_COUNT:
      return {
        ...state,
        messageBadgeCount: state.messageBadgeCount + 1,
      };
    case SET_ROW_ID:
      return {
        ...state,
        rowID: action.id,
      };
    case SET_AGREEMENTS_STATUS:
      return {
        ...state,
        agreementsStatus: ['N', 'admin', ''].includes(action.status) ? 'N' : 'Y',
      };
  }

  return state;
}

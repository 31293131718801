import React, { useEffect, useState } from 'react';
import {
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Button,
  withStyles,
  Modal,
} from '@material-ui/core';
import XlinkAPI from '~/app/api/xlinkAPI.js';
import ErrorHelpers from '~/app/errorHelpers.js';
import Spinner from '#/Common/Spinner.jsx';
import { styles } from '~/app/Components/Settings/Setup/Logins/css/accessRoles.js';
import AccessLevel from '../Modals/AccessLevel.jsx';
import AccessControl from '~/app/Components/Auth/AccessControl.jsx';
import { useSelector } from 'react-redux';

const emptyRoleDefinition = {
  id: 0,
  access_role_name: '',
  access_friendly_role_name: '',
  access_role_description: '',
  is_custom_role: true,
};

const AccessRolesTable = props => {
  const { classes } = props;
  const [fetchingData, setFetchingData] = useState(false);
  const [accessRoles, setAccessRoles] = useState([]);
  const [roleModal, toggleRoleModal] = useState(false);
  const [roleSelected, setRole] = useState({});
  const { currentView } = useSelector(state => ({
    currentView: state.drilldown.drilldownHistory[state.drilldown.drilldownHistory.length - 1],
  }));

  useEffect(() => {
    // Fetch all account level offices on mount.
    fetchAccessRoles();
  }, []);

  const fetchAccessRoles = () => {
    // If the account is a tech account or superuser we do not want to fetch access roles for these because there are none currently,
    // works for drilldown tech
    if (currentView.role !== 50 && currentView.role !== 1) {
      setFetchingData(true);

      XlinkAPI.fetchAccessRoles()
        .then(res => {
          if (!Array.isArray(res.data)) {
            throw ErrorHelpers.createSimpleError(
              'Error fetching access roles. Return value is not properly formatted',
            );
          }
          let accessLevels = res?.data || [];

          if (currentView.role < 40) {
            // Filters out access roles Non-transmit and Reception as well as Preparer if hierarchy type < 40
            accessLevels = accessLevels.filter(
              accessRoleInfo =>
                accessRoleInfo.id !== 2 &&
                accessRoleInfo.id !== 14 &&
                accessRoleInfo.access_role_name !== 'Preparer',
            );
          }

          setAccessRoles(accessLevels);
          setFetchingData(false);
        })
        .catch(err => {
          setFetchingData(false);
          ErrorHelpers.handleError('Error fetching Access Roles', err);
        });
    }
  };

  const fetchAccessLevelsForRole = role => {
    setRole(role);
    toggleRoleModal(true);
  };

  return (
    <div className="row">
      {fetchingData && (
        <span className="centered-spinner">
          <Spinner size={125} loadingText={'Fetching Data...'} textColor="white" bgColor="grey" />
        </span>
      )}
      <AccessControl
        requiredAction="write"
        accessLevel="create/edit_access_levels"
        disableOnFalse={true}
      >
        <Button
          id="btnAddNewAccessRole"
          onClick={() => fetchAccessLevelsForRole(emptyRoleDefinition)}
          disabled={currentView.role === 50 || currentView.role === 1}
        >
          Add New
        </Button>
      </AccessControl>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell classes={{ root: classes.tableHeadCellRoot }}>ACCESS NAME</TableCell>
            <TableCell align="right" classes={{ root: classes.tableHeadCellRoot }}>
              ACTION
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {accessRoles.map(access => {
            return (
              <TableRow key={access.access_role_name}>
                <TableCell classes={{ root: classes.tableCellRoot }}>
                  {access.access_role_name}
                </TableCell>
                <TableCell align="right" classes={{ root: classes.tableCellRoot }}>
                  <AccessControl
                    requiredAction="write"
                    accessLevel="edit_login"
                    alternateView={
                      <Button onClick={() => fetchAccessLevelsForRole(access)}>{'View'}</Button>
                    }
                  >
                    <Button onClick={() => fetchAccessLevelsForRole(access)}>
                      {access.is_custom_role ? 'Edit' : 'View'}
                    </Button>
                  </AccessControl>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Modal
        id="accessLevelsForRoleModal"
        onClose={() => toggleRoleModal(false)}
        disableAutoFocus={true}
        open={roleModal}
        disableBackdropClick={true}
      >
        <AccessLevel
          role={roleSelected}
          closeModal={() => toggleRoleModal(false)}
          triggerReloadRoles={fetchAccessRoles}
        />
      </Modal>
    </div>
  );
};

export default withStyles(styles)(AccessRolesTable);

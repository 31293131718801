import React from 'react';
// External imports
import { withStyles } from '@material-ui/core/styles';
import { Input, InputLabel, FormControl } from '@material-ui/core';
// Styles imports
import '~app/Components/NewAccount/SingleOffice/css/printingSetup.css';
import { styles } from '~/app/Components/NewAccount/SingleOffice/css/printSetup.js';

/**
 * Component that shows Printer Setting Row
 *
 * @component
 * @category Printing Setup
 * @subcategory Printer Setting Row
 */
const PrinterInputField = props => {
  return (
    <div style={props.style}>
      <FormControl>
        <InputLabel className={props.classes.printLabel} required={false} shrink>
          {props.title}
        </InputLabel>
        <Input
          id={props.field + 'input'}
          name={props.field}
          value={props?.fieldMap?.[props.field]?.configValue}
          onChange={e => {
            props.handleInputChange(e);
          }}
          className="printing-setup-adjustLineIncrementStyle"
          disableUnderline
        />
      </FormControl>
    </div>
  );
};
export default withStyles(styles)(PrinterInputField);

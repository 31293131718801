export const styles = theme => ({
  currentYearTransferTitle: {
    paddingLeft: '1rem',
  },
  currentYearTransferSubTitle: {
    padding: '1rem 0 0 1rem',
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tableRowRoot: {
    height: '30px',
    letterSpacing: '0.1px',
    '&$:hover': {
      color: 'black',
      opacity: 1,
    },
    '&$:focus': {
      color: 'black',
    },
  },
  tableRowSelected: {
    backgroundColor: '#bbb5b5 !important',
  },
  hover: {},
  btnOptionsRoot: {
    marginRight: '1rem',
  },
  btnOptionsNoBorder: {
    border: 'none',
  },
});

// External imports
import React, { useEffect } from 'react';
import moment from 'moment';
import {
  Button,
  Collapse,
  Divider,
  TextField,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core';
// Internal imports
import { useSetState } from '~/app/Utility/customHooks';
import ErrorHelpers from '~/app/errorHelpers.js';
import { statusOK } from '~/app/webHelpers.js';
import Spinner from '#/Common/Spinner.jsx';
import XlinkAPI from '~/app/api/xlinkAPI';
// Style imports
import { styles } from '~/app/Pages/Returns/components/Events/eventLogStyles.js';

const EventLog = props => {
  const { classes } = props;
  const [state, setState] = useSetState({
    isAddingEvent: false,
    events: [],
    event: '',
    details: '',
    isLoading: false,
  });

  useEffect(() => {
    getEventLogs();
  }, []);

  const getEventLogs = async () => {
    setState({ isLoading: true });

    try {
      const res = await XlinkAPI.getEventLogs(props.returnID);
      if (statusOK(res)) {
        setState({
          events: res.data,
          isLoading: false,
        });
      }
    } catch (err) {
      setState({ isLoading: false });
      ErrorHelpers.handleError('Error fetching event logs', err);
    }
  };

  const submitEvent = async () => {
    try {
      const res = await XlinkAPI.insertEventLog(
        props.returnID,
        props.pssn,
        state.event,
        state.details,
      );
      if (statusOK(res, false)) {
        getEventLogs();
        toggleAddEventForm();
      }
    } catch (err) {
      ErrorHelpers.handleError('Error submitting event log', err);
    }
  };

  const handleChange = e => {
    setState({
      [e.target.name]: e.target.value,
    });
  };

  const toggleAddEventForm = () => {
    setState({
      isAddingEvent: !state.isAddingEvent,
      event: '',
      details: '',
    });
  };

  return (
    <div id="divEventLogModal">
      <Typography variant="title" id="simple-modal-title" style={{ paddingLeft: '1em' }}>
        Event Log
      </Typography>
      <div className="eventLogModal">
        <Table>
          <TableHead>
            <TableRow classes={{ root: classes.tableHeadRoot }}>
              <TableCell>Event</TableCell>
              <TableCell>Details</TableCell>
              <TableCell>Login ID</TableCell>
              <TableCell>Timestamp</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.events.length
              ? state.events.map((event, i) => (
                  <TableRow classes={{ root: classes.tableRowRoot }} key={i}>
                    <TableCell
                      className="noselect"
                      component="th"
                      scope="row"
                      classes={{ root: classes.tableCellRoot }}
                    >
                      {event.event}
                    </TableCell>
                    <TableCell className="noselect" classes={{ root: classes.tableCellRoot }}>
                      {event.details}
                    </TableCell>
                    <TableCell className="noselect">{event.loginID}</TableCell>
                    <TableCell className="noselect">
                      {moment.utc(event.date).local().format('MM/DD/YYYY hh:mm:ss A')}
                    </TableCell>
                  </TableRow>
                ))
              : !state.isLoading && (
                  <TableRow classes={{ root: classes.tableRowRoot }}>
                    <TableCell className="noselect" component="th" scope="row">
                      No event logs
                    </TableCell>
                    <TableCell className="noselect" />
                    <TableCell className="noselect" />
                    <TableCell className="noselect" />
                  </TableRow>
                )}
          </TableBody>
        </Table>
        {state.isLoading ? (
          <Spinner
            size={100}
            color="blue"
            loadingText="Loading Events..."
            textColor="white"
            bgColor="grey"
            lockActions={true}
          />
        ) : null}
      </div>
      <Divider />
      <Collapse in={state.isAddingEvent} timeout="auto" unmountOnExit>
        <Grid
          container
          direction="column"
          justify="space-between"
          classes={{ container: classes.containerPadding }}
          spacing={32}
        >
          <Grid item xs={8}>
            <TextField
              id="eventText"
              name="event"
              label="Event"
              value={state.event}
              onChange={handleChange}
              inputProps={{ maxLength: '50' }}
              fullWidth
              variant="outlined"
              placeholder="Return Unlocked..."
              required
            />
          </Grid>

          <Grid item xs>
            <TextField
              id="detailsText"
              name="details"
              label="Details"
              value={state.details}
              onChange={handleChange}
              inputProps={{ maxLength: '300' }}
              fullWidth
              rows={4}
              multiline
              variant="outlined"
              placeholder="This return was unlocked..."
            />
          </Grid>
        </Grid>
      </Collapse>
      <Grid container justify="flex-end" classes={{ container: classes.containerPadding }}>
        <Grid item>
          <Button
            id="btnEventLogCancel"
            color="primary"
            style={{ marginRight: '1rem' }}
            variant="outlined"
            onClick={() => (state.isAddingEvent ? toggleAddEventForm() : props.toggleModal(false))}
          >
            {state.isAddingEvent ? 'Cancel' : 'Close'}
          </Button>
          <Button
            id="btnEventLogAdd"
            color="primary"
            variant="contained"
            onClick={() => (state.isAddingEvent ? submitEvent() : toggleAddEventForm())}
            disabled={
              state.isAddingEvent
                ? props.locked || state.event === ''
                : props.locked || (props.readyForReview && props.isFeederOffice)
            }
          >
            {state.isAddingEvent ? 'Submit' : 'Add'}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(EventLog);

// External imports
import React from 'react';
import {
  withStyles,
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableBody,
  Button,
  Tooltip,
  Checkbox,
  Typography,
  FormControlLabel,
  FormControl,
  TextField,
  Grid,
} from '@material-ui/core';
import CheckedCircle from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/Info';
// Internal imports
import AccessControl from '~/app/Components/Auth/AccessControl.jsx';
import SimpleDialog from '#/Common/SimpleDialog.jsx';
import { useSetState } from '~/app/Utility/customHooks';
import WebHelpers, { moneyFormatter } from '~/app/webHelpers.js';
import { ACCESS_LEVEL } from '~/app/constants.js';
// Redux imports
import { useDispatch } from 'react-redux';
import { actions as appActions } from '~/app/redux/modules/app';
// Styling imports
import { styles } from '~/app/Pages/Returns/components/Efile/css/QueueReturnStyles.js';

const QueueReturn = props => {
  const { classes } = props;
  const dispatch = useDispatch();
  const [state, setState] = useSetState({
    efileIds: [],
    isSubmittingToDiagnostics: false,
    diagnosticComments: '',
    isSubmitForReviewDlgOpen: false,
    areAllEfilePackagesDisabled: false,
    isQueueDlgOpen: false,
    queueDlgMsg: '',
    isTransmitDlgOpen: false,
    transmitDlgMsg: '',
  });

  const updateItemsToTransmit = e => {
    e.preventDefault();
    const payload = WebHelpers.getJWTPayload();
    const accountCode = payload.account_code;

    if (accountCode !== 'CLODMO') {
      let stopTransmit = false;

      // check if transmit list includes multiple return types for a single package
      // show a confirmation message before transmitting if so
      const pkgeList = [];
      state.efileIds.forEach(pkge => {
        const pkgeName = pkge?.efileId?.slice(0, 2);

        // found a second return type for included package, send confirmation message
        if (pkgeList.includes(pkgeName)) {
          const errorMsg =
            'Multiple e-file types selected for ' +
            pkgeName +
            '. Are you sure you want to queue all?';

          setState({ isTransmitDlgOpen: true, transmitDlgMsg: errorMsg });
          stopTransmit = true;
        } else {
          // new package, add to list
          pkgeList.push(pkgeName);
        }
      });
      if (stopTransmit) return;
      transmitQueue();
    } else {
      // Open new modal with message
      props.closeQueueReturnModalDemo();
    }
  };

  const transmitQueue = () => {
    let stopTransmit = false;
    // get fed package
    const fedPkg = props.transmitItemList.find(obj => obj?.efileId === 'USSA');
    const fedAck = fedPkg?.fedAck;

    // check if fed package is being tranmsitted
    const fedTranmitting = state.efileIds.find(obj => obj?.efileId === 'USSA');

    // no fed acknowledgment, and fed isnt include in transmit list. Check if state containts noStateOnly flag
    if (fedAck === '0' && !fedTranmitting) {
      state.efileIds.forEach(pkge => {
        if (pkge?.noStateOnly) {
          const statePkg = props.transmitItemList.find(obj => obj?.efileId === pkge.efileId);
          const errorMsg =
            "Cannot send '" +
            statePkg.package +
            "' without a federal acknowledgment or Transmit Federal is selected";

          setState({ isQueueDlgOpen: true, queueDlgMsg: errorMsg });

          stopTransmit = true;
        }
      });
    }

    if (stopTransmit) return;

    if (state.isSubmittingToDiagnostics) {
      props.updateTransmitList(null, state.diagnosticComments, state.isSubmittingToDiagnostics);
      return;
    }
    if (state.efileIds.length < 1) {
      dispatch(
        appActions.showSnackbarMessage('Nothing Selected for Queue', 'error', 3500, {
          vertical: 'top',
          horizontal: 'center',
        }),
      );

      return;
    }
    props.updateTransmitList(generateEfileIdList(state.efileIds), '', false);
  };

  const handleConfirmSubmitForReview = () => {
    props.submitReturnForReview();
    setState({ isSubmitForReviewDlgOpen: false });
    props.closeQueueReturnModal();
    props.closeReturn();
  };

  const updateefileId = (queueItem, checked) => {
    const itemIndex = state.efileIds.findIndex(item => item === item.efileId);
    const isNoStateOnly = queueItem?.noStateOnly;
    const isPiggyBack = queueItem?.isPiggyBack;

    if (itemIndex === -1) {
      const newQueueItem = {
        efileId: queueItem.efileId,
        noStateOnly: isNoStateOnly,
        piggyBack: isPiggyBack,
      };
      setState({
        efileIds: [...state.efileIds, newQueueItem],
      });
    } else {
      if (!checked) {
        setState({
          efileIds: state.efileIds.filter(item => {
            return item.efileId !== queueItem.efileId;
          }),
        });
      }
    }
  };

  const getTransmitItemsTable = () => {
    checkDisabledEfilePackages();

    return (
      <div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Transmit</TableCell>
              <TableCell>Package</TableCell>
              <TableCell>AGI</TableCell>
              <TableCell>Total Tax</TableCell>
              <TableCell>Refund</TableCell>
              <TableCell>Bal Due</TableCell>
              <TableCell>Electronic Filing</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.transmitItemList.map((item, i) => {
              return (
                <TableRow key={`${i}-EFileID-${item.efileId}`} hover={true}>
                  <TableCell className="noselect">
                    <Checkbox
                      id="tranmsissionChkBox"
                      onChange={e => {
                        updateefileId(item, e.target.checked);
                      }}
                      disabled={item.disabled === '1'}
                    />
                  </TableCell>
                  <TableCell className="noselect">{item.package}</TableCell>
                  <TableCell className="noselect">{item.agi}</TableCell>
                  <TableCell className="noselect">{item.totalTax}</TableCell>
                  <TableCell className="noselect">{item.refund}</TableCell>
                  <TableCell className="noselect">{item.balDue}</TableCell>
                  <TableCell className="noselect">{item.dscr}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  };

  // Display fee list table if it exists in the calc msg
  const getFeeItemTable = () => {
    const walletBalanceText =
      props.walletBalance === 'Not Sufficient' ? 'Insufficient Funds' : 'Available Funds';

    return (
      <div>
        <Table size="small" aria-label="Fee List">
          <TableHead>
            <TableRow>
              <TableCell>Fee Detail</TableCell>
              <TableCell align="right">Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.feeItemList.map((item, i) => {
              return (
                <TableRow key={`${i}-FeeID-${item?.feeId}`} hover={true}>
                  <TableCell className="noselect">{item.description}</TableCell>
                  <TableCell align="right" className="noselect">
                    {moneyFormatter(item.amount, false)}
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow hover={false}>
              <TableCell className="noselect" style={{ textAlign: 'right', border: '0' }}>
                Total:
              </TableCell>
              <TableCell align="right" className="noselect" style={{ width: '15%', border: '0' }}>
                {moneyFormatter(props.feeTotal, false)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        {!!props.walletBalance && (
          <div
            style={{
              margin: '.5rem 0 0 1rem',
            }}
          >
            <Tooltip title={`Wallet Balance: ${walletBalanceText}`}>
              {props.walletBalance === 'Not Sufficient' ? <InfoIcon /> : <CheckedCircle />}
            </Tooltip>
            <span style={{ fontSize: '14px', marginLeft: '.25em' }}>
              Wallet Balance: {walletBalanceText}
            </span>
          </div>
        )}
        {!!props.wndwNote && (
          <div className="pyp-container">
            <span style={{ fontSize: '12px', marginLeft: '.25em' }}>{props.wndwNote}</span>
          </div>
        )}
      </div>
    );
  };

  // checks if all transmit items are disabled so 'transmit' button can be disabled.
  const checkDisabledEfilePackages = () => {
    const disabledPackagesList = props.transmitItemList.filter(item => item.disabled === '1');

    if (
      disabledPackagesList.length === props.transmitItemList.length &&
      !state.areAllEfilePackagesDisabled
    ) {
      setState({
        areAllEfilePackagesDisabled: true,
      });
    }
  };

  // generateEfileIdlist will take efileQueueList and only return the efileIDs.
  const generateEfileIdList = listToTransform => {
    const newList = [];
    for (let i = 0; i < listToTransform.length; i++) {
      newList.push(listToTransform[i].efileId);
    }
    return newList;
  };

  const handleSubmitForDiagnostic = () => {
    setState({
      isSubmittingToDiagnostics: !state.isSubmittingToDiagnostics,
      diagnosticComments: '',
    });
  };

  const alternateSubmitBtn = (
    <Button
      color="primary"
      variant="contained"
      onClick={() => setState({ isSubmitForReviewDlgOpen: true })}
    >
      Submit For Review
    </Button>
  );

  return (
    <>
      <Typography variant="body2" id="simple-modal-title" style={{ paddingLeft: '4.5%' }}>
        Transmit Return
      </Typography>
      <div>
        <div
          style={{
            height: 'auto',
            maxHeight: '350px',
            overflowY: 'auto',
          }}
        >
          {getTransmitItemsTable()}
        </div>
        <div>
          <FormControl
            style={{
              margin: '.5rem 0 0 1rem',
            }}
          >
            <FormControlLabel
              id="diagnosticToggle"
              label="Submit For Diagnostics"
              control={
                <Checkbox
                  onChange={handleSubmitForDiagnostic}
                  value={state.isSubmittingToDiagnostics ? 'true' : 'false'}
                  checked={state.isSubmittingToDiagnostics}
                />
              }
            />
          </FormControl>
          {props.feeItemList?.length > 0 && !state.isSubmittingToDiagnostics && getFeeItemTable()}
        </div>
        {state.isSubmittingToDiagnostics && (
          <div className={classes.inputContainer}>
            <FormControl fullWidth>
              <TextField
                rows={4}
                placeholder="Comments..."
                multiline={true}
                variant="filled"
                value={state.diagnosticComments}
                onChange={e =>
                  setState({
                    diagnosticComments: e.target.value,
                  })
                }
              />
            </FormControl>
          </div>
        )}
        <Grid container justify="flex-end" style={{ padding: '0 1.1rem 1.1rem 0' }}>
          <Button onClick={props.closeQueueReturnModal} style={{ marginRight: '1rem' }}>
            Cancel
          </Button>

          <AccessControl
            requiredAction="write"
            accessLevel={ACCESS_LEVEL.TRANSMIT_RETURN}
            additionalAccessCheck={
              !props.isFeeder || !props.transmissionBlocked // Using OR since we only block transmission on feeders.
            }
            alternateView={alternateSubmitBtn}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={e => updateItemsToTransmit(e)}
              disabled={state.isSubmittingToDiagnostics ? false : state.areAllEfilePackagesDisabled}
            >
              Transmit
            </Button>
          </AccessControl>
        </Grid>
      </div>
      <SimpleDialog
        open={state.isSubmitForReviewDlgOpen}
        onClose={() => setState({ isSubmitForReviewDlgOpen: false })}
        onConfirm={handleConfirmSubmitForReview}
        dialogTitle="Submit Return For Review"
        contentText="Are you sure you want to submit this return for review?"
      />

      <SimpleDialog
        open={state.isQueueDlgOpen}
        onConfirm={() => setState({ isQueueDlgOpen: false })}
        dialogTitle="Return Queue"
        contentText={state.queueDlgMsg}
      />
      <SimpleDialog
        onClose={() => setState({ isTransmitDlgOpen: false })}
        open={state.isTransmitDlgOpen}
        onConfirm={() => {
          setState({ isTransmitDlgOpen: false });
          transmitQueue();
        }}
        confirmText="Yes"
        cancelText="No"
        dialogTitle="Confirm"
        contentText={state.transmitDlgMsg}
      />
    </>
  );
};

export default withStyles(styles)(QueueReturn);

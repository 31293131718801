import XlinkAPI from '~/app/api/xlinkAPI';
import ErrorHelpers from '~/app/errorHelpers.js';

export const getInitialConfigState = async (loginID, setHasTextlinkPlus, setIncludeEFIN) => {
  await XlinkAPI.getTextLinkSetup(loginID)
    .then(res => {
      setHasTextlinkPlus(res.data.hasPlus);
      setIncludeEFIN(res.data.includeEFIN);
    })
    .catch(err => {
      ErrorHelpers.handleError('Failed to load TextLink configuration', err);
    });
};

export const submitTextEmailData = async (loginID, includeEFIN, setShowActivateSuccess) => {
  // Keeping email, password, serverURL, and serverPort for now in case we
  // switch back to letting customers use TextLink again.
  let submitTextEmailData = {
    loginID: loginID,
    config: {
      email: '',
      password: '',
      serverURL: '',
      serverPort: '',
      includeEFIN: includeEFIN,
    },
  };

  await XlinkAPI.post(`${XLINK_API_URL}/account/setup/textlink`, submitTextEmailData)
    .then(() => {
      setShowActivateSuccess(true);
    })
    .catch(err => {
      ErrorHelpers.handleError('Failed to update TextLink configuration', err);
    });
};

export const dialogContent = [
  'TextLink Plus allows you to contact your Customer base through the text messaging feature of their cell phone.',
  'This feature has been expanded to include the capability to text remote signature requests to your customers.',
  'Your customer must have a Text Messaging plan on their cell phone to receive your message(s). Standard Text Messaging rates apply.',
];

import { FINAL_TAX_RETURN_TYPES } from '~/app/constants.js';

export const determineAttachmentName = attachment => {
  const docDesc = FINAL_TAX_RETURN_TYPES[attachment.document_description.trim()];
  return attachment.document_type === 'Full Tax Return' && docDesc
    ? docDesc
    : attachment.document_name;
};

export const isLocked = (lockedBy, lockedStatus) => {
  return lockedBy?.length > 0 || !!lockedStatus;
};

import React from 'react';
// External imports
import {
  Divider,
  Typography,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Paper,
  withStyles,
  Modal,
  Button,
  FormGroup,
} from '@material-ui/core';
// Internal imports
import ManageAncillary from '#/Settings/Setup/AncillaryProducts/ManageAncillary.jsx';
import XlinkAPI from '~/app/api/xlinkAPI';
import ErrorHelpers from '~/app/errorHelpers.js';
import SetupPage from '../SetupPage.jsx';
import SimpleDialog from '#/Common/SimpleDialog.jsx';
import { statusOK } from '~/app/webHelpers.js';
// Styling imports
import { styles } from '~/app/Components/Settings/Setup/AncillaryProducts/css/ancillaryProduct.js';
import '~/app/Components/Settings/Setup/AncillaryProducts/css/ancillaryProduct.css';

class AncillaryProducts extends SetupPage {
  constructor(props) {
    const name = 'Ancillary'; // You need this for save and next to transition
    super(props, name);
    this.state = {
      manageAncillaryOpen: false,
      officeList: [],
      purchasedOfficeList: {},
      activePurchaseIDManaging: 0,
      confirmCancel: false,
    };
  }

  getInitialConfigState() {
    this.setState({
      manageAncillaryOpen: false,
      officeList: [],
      purchasedOfficeList: {},
      activePurchaseIDManaging: 0,
      confirmCancel: false,
    });
  }

  toggleConfirmCancel = toggle => {
    this.setState({
      confirmCancel: toggle,
    });
  };

  componentDidMount = async () => {
    try {
      const res = await XlinkAPI.getAncillaryProductsInfoByLoginID();

      if (statusOK(res)) {
        this.setState({
          officeList: res.data.all_offices,
          purchasedOfficeList: res.data.offices_purchase_details,
        });
      }
    } catch (error) {
      ErrorHelpers.handleError('Failed to get Ancillary Products', error);
      // eslint-disable-next-line no-useless-return
      return;
    }
  };

  toggleManageAncillaryModal = purchaseID => {
    this.setState({
      activePurchaseIDManaging: purchaseID,
      manageAncillaryOpen: !this.state.manageAncillaryOpen,
    });
  };

  getNumOfficesUsingProduct = officesPurchased => {
    let numOfficePurchased = 0;
    // eslint-disable-next-line array-callback-return
    officesPurchased.map(office => {
      if (office.purchased) {
        numOfficePurchased++;
      }
    });
    return numOfficePurchased.toString();
  };

  saveAncillaryProductForOffices = async (purchaseInfo, markupFee, financials, nonFinancials) => {
    const purchaseID = purchaseInfo[0].ancillary_product_id;
    this.setState({
      purchasedOfficeList: {
        ...this.state.purchasedOfficeList,
        [purchaseID]: purchaseInfo,
      },
    });

    try {
      await XlinkAPI.saveAncillaryProductsInfoByLoginID(
        this.props.loginID,
        purchaseInfo,
        markupFee,
        financials,
        nonFinancials,
      );
    } catch (error) {
      ErrorHelpers.handleError('Failed to update Ancillary Products', error);
    }
  };

  formatBankApprovedStr = str => {
    if (str === '') {
      return 'N/A';
    }
    return str.substring(0, str.length - 2);
  };

  render() {
    const { classes } = this.props;

    return (
      <div className="container-fixed" style={{ marginTop: '2.5rem' }}>
        <Paper>
          <Typography classes={{ root: classes.titleRoot }}>Ancillary Products</Typography>
          <FormGroup style={{ marginLeft: '3vw', marginRight: '3vw', marginTop: '1vh' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell classes={{ root: classes.tableHeadCellRoot }}>NAME</TableCell>
                  <TableCell classes={{ root: classes.tableHeadCellRoot }}>BANK APPROVED</TableCell>
                  <TableCell classes={{ root: classes.tableHeadCellRoot }}>
                    SUB ACCOUNT USE
                  </TableCell>
                  <TableCell classes={{ root: classes.tableHeadCellRoot }}>BASE FEE</TableCell>
                  <TableCell classes={{ root: classes.tableHeadCellRoot }}>ACTION</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.purchasedOfficeList &&
                  Object.values(this.state.purchasedOfficeList).map((officePurchase, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          {officePurchase[0].ancillary_product_name}
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          {this.formatBankApprovedStr(officePurchase[0].bank_approved)}
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          {this.state.officeList == null || undefined
                            ? ''
                            : this.getNumOfficesUsingProduct(officePurchase) +
                              ' out of ' +
                              officePurchase.length}
                        </TableCell>
                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          ${officePurchase[0].BaseFee}
                        </TableCell>

                        <TableCell classes={{ root: classes.tableCellRoot }}>
                          <Button
                            onClick={() =>
                              this.toggleManageAncillaryModal(
                                officePurchase[0].ancillary_product_id,
                              )
                            }
                          >
                            Manage
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </FormGroup>
          <Modal
            id="manageAncillaryProductModal"
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={this.state.manageAncillaryOpen}
            onClose={() => this.toggleManageAncillaryModal()}
            style={styles.manageAncillaryModal}
          >
            <Paper elevation={5} classes={{ root: classes.manageAncillaryModal }}>
              <ManageAncillary
                toggleManageAncillary={this.toggleManageAncillaryModal}
                purchaseInfo={
                  this.state.purchasedOfficeList
                    ? this.state.purchasedOfficeList[this.state.activePurchaseIDManaging]
                    : []
                }
                saveAncillaryProductForOffices={this.saveAncillaryProductForOffices}
                officeList={this.state.officeList}
                formatBankApprovedStr={this.formatBankApprovedStr}
              />
            </Paper>
          </Modal>
          <Divider classes={{ root: classes.divider }} />
          <div className="ancillary-product-button-block">
            {this.hasPrev() ? (
              <Button
                id="btnPrevAncillaryProductsSetup"
                color="primary"
                style={{ width: '10vw', marginRight: '1vw' }}
                onClick={() => this.toggleConfirmCancel(true)}
              >
                Previous (cancel)
              </Button>
            ) : null}
            {this.hasNext() ? (
              <Button
                id="btnNextAncillaryProductsSetup"
                color="primary"
                style={{ width: '10vw' }}
                onClick={() => this.getNextPage()}
              >
                Save &amp; Next
              </Button>
            ) : null}
          </div>
          <br />
        </Paper>
        <SimpleDialog
          open={this.state.confirmCancel}
          onClose={() => this.toggleConfirmCancel(false)}
          onConfirm={() => {
            this.toggleConfirmCancel(false);
            this.getInitialConfigState();
            this.getPrevPage();
          }}
          dialogTitle={'Lose Unsaved Changes?'}
          contentText={'Are you sure you want to undo any pending changes?'}
        />
      </div>
    );
  }
}

export default withStyles(styles)(AncillaryProducts);

export const styles = {
  newPreparerModal: {
    height: '90%',
    width: '65%',
    padding: '0',
    position: 'absolute',
    overflowY: 'auto',
    top: '5%',
    left: '16.5%',
  },
  prepRMSModal: {
    height: 'fit-content',
    width: '43.75rem',
    padding: '0',
    position: 'absolute',
    overflowY: 'auto',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  buttonSize: {
    padding: '0',
    marginRight: '1vw',
  },
  captureSignatureButton: {
    padding: '0.5rem',
    marginRight: '1rem',
  },
};

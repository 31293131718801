// External imports
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
// Internal imports
import { HIERARCHY_TYPE, ACCOUNT_TYPES } from '~/app/constants.js';
import AccountForm from '~/app/Pages/NewAccount/components/AccountForm.jsx';
import AccountHeader from '~/app/Pages/NewAccount/components/AccountHeader.jsx';
import AccountTypeSelection from '~/app/Pages/NewAccount/components/AccountTypeSelection.jsx';
import { useSetState } from '~/app/Utility/customHooks';
import AuthAPI from '~/app/api/authAPI.js';
import XlinkAPI from '~/app/api/xlinkAPI.js';
import ErrorHelpers from '~/app/errorHelpers.js';
import SimpleDialog from '#/Common/SimpleDialog.jsx';
import Spinner from '#/Common/Spinner.jsx';
import appTheme from '~/themes/GenericTheme.jsx';
// Redux imports
import { useDispatch, useSelector } from 'react-redux';
import { actions as accountCreationActions } from '~/app/redux/modules/accountCreation';
import { actions as appActions } from '~/app/redux/modules/app';
// Styles import
import { styles } from '~/app/Pages/NewAccount/css/newAccountStyles.js';

/**
 * NewAccount component displays the "/create-new-account"
 * It handles the logic for determining which step the user is on for creating an account
 *
 * @component NewAccount
 * @category NewAccount
 **/
const NewAccount = props => {
  const { classes } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { alert, createAccountState, backFlag, drilldownHistory, currentView } = useSelector(
    state => ({
      alert: state.app.alert,
      createAccountState: state.accountCreation,
      backFlag: state.accountCreation.backFlag,
      drilldownHistory: state.drilldown.drilldownHistory,
      currentView: state.drilldown.drilldownHistory[state.drilldown.drilldownHistory.length - 1],
    }),
  );
  const [state, setState] = useSetState({
    isFormValid: false,
    isLoading: false,
    showAccountSelection: true,
    form: {},
  });

  useEffect(() => {
    if (backFlag !== 0) {
      window.onpopstate = () => onClickCancel();
    }
    return () => {
      // Anything in here is fired on component unmount.
      if (backFlag !== 0) {
        window.onpopstate = () => onClickCancel();
      }
      dispatch(accountCreationActions.resetCreateAccountFull());
    };
  }, []);

  // Skip account selection if user is MO
  useEffect(() => {
    if (currentView?.role === HIERARCHY_TYPE.FRANCHISE) {
      setState({ ...state, showAccountSelection: false });
    }
  }, [currentView]);

  /** This dispatches a redux action to send the user to the next step in creating an account */
  const onClickContinue = () => {
    dispatch(accountCreationActions.incrementCreateAccountStep());
  };

  /** Handles the sending the user vack a step or back to "/dashboard" */
  const onClickCancel = () => {
    // resetting state if user cancels form
    // TODO: set up dialog ui/logic to display confirm cancel***
    setState({ isFormValid: false, form: {} });
    if (createAccountState.step === 1 || !state.showAccountSelection) {
      dispatch(accountCreationActions.resetCreateAccount());
      dispatch(accountCreationActions.setBackFlag(0));
      history.push({ pathname: '/dashboard' });
    } else {
      dispatch(accountCreationActions.decrementCreateAccountStep());
    }
  };

  /** Handles sending req to create a new account */
  const createNewAccount = async () => {
    // Start loader dialog
    setState({ isLoading: true });

    let drilledLoginID = 0;
    const form = state.form;
    // canCobrand is stored as a boolean in redux, but the endpoint expects a string '0' or '1'.
    form.cobrander = form.cobrander ? '1' : '0';

    // In AccountForm, UI/inputs are hidden unless they are a CSR
    // These following inputs should be sent as empty, sense unless they are a CSR they cannot be edited
    if (drilldownHistory[0].role !== 'csr') {
      form.accessLevel = '';
      form.userID = NaN;
      form.accountCode = '';
    }
    if (drilldownHistory?.length > 1) {
      drilledLoginID = drilldownHistory[drilldownHistory.length - 1].loginID;
    }

    try {
      await AuthAPI.registerAccount(state.form, state.form.acctType, drilledLoginID);
      dispatch(accountCreationActions.incrementCreateAccountStep());
      dispatch(accountCreationActions.resetCreateAccount());
      history.push({ pathname: '/dashboard' });

      if (selectedOption === efinType) {
        // decide message based on the EFIN was validated by checking the `efin_validated` column in tblEFIN
        const res = await XlinkAPI.isEFINValidated(state.form?.EFIN);
        const msg =
          res?.data === 1
            ? 'Office was successfully created.'
            : 'Office was successfully created. The EFIN used was not validated, and will need to be updated through Office Setup.';

        dispatch(
          appActions.showSnackbarMessage(msg, 'success', 6000, {
            vertical: 'top',
            horizontal: 'center',
          }),
        );
      }
    } catch (err) {
      ErrorHelpers.handleError('Account Registration Error', err);
      // Stop loader dialog
      setState({ isLoading: false });
    }
  };

  /**
   * Handles passing handler down to child AccountForm to retrieve the data and pass up the form
   *
   * @param {Object} form form passed up from AccountForm and used to create an account
   */
  const getForm = form => {
    setState({ form: form });
  };

  /**
   *
   *
   * @param {boolean} value is sent back up from child to set the Create button to active
   */
  const setIsFormValid = value => {
    setState({
      isFormValid: value,
    });
  };

  const selectedOption = createAccountState.type;
  const efinType = ACCOUNT_TYPES.EFIN;
  const franchiseType = ACCOUNT_TYPES.FRANCHISE;
  const resellerType = ACCOUNT_TYPES.RESELLER;
  const currentStep = createAccountState.step;

  // Retrieves text based on office being created, default loading text if none found
  const getCreateLoadingText = () => {
    if (selectedOption === efinType) {
      return 'Creating New Office ...';
    } else if (selectedOption === franchiseType) {
      return 'Creating New Multi Office ...';
    } else if (selectedOption === resellerType) {
      return 'Creating New Reseller ...';
    }
    return 'Loading ...';
  };

  // Displays Account Type Selection component
  return (
    <MuiThemeProvider theme={appTheme}>
      <AccountHeader
        currentStep={currentStep}
        isFormValid={state.isFormValid}
        handleContinueClick={onClickContinue}
        handleCancelClick={onClickCancel}
        handleCreateClick={createNewAccount}
        handleRedirect={() => {
          dispatch(accountCreationActions.resetCreateAccount());
        }}
      />
      {currentStep === 1 ? (
        // STEP 1
        // Displays cards of different Account Types the user can choose from
        <AccountTypeSelection />
      ) : currentStep === 2 ? (
        // STEP 2
        // Displays  the specific form for what account type the user selected
        selectedOption === efinType ? (
          <AccountForm
            accountType={efinType}
            setIsFormValid={setIsFormValid}
            getForm={getForm}
            onClickCancel={onClickCancel}
          />
        ) : selectedOption === franchiseType ? (
          <AccountForm
            accountType={franchiseType}
            setIsFormValid={setIsFormValid}
            getForm={getForm}
            onClickCancel={onClickCancel}
          />
        ) : selectedOption === resellerType ? (
          <AccountForm
            accountType={ACCOUNT_TYPES.SB_ADMIN}
            setIsFormValid={setIsFormValid}
            getForm={getForm}
            onClickCancel={onClickCancel}
          />
        ) : null
      ) : (
        <div className={classes.spinnerContainer}>
          <Spinner loadingText="Loading..." className={classes.spinner} />
        </div>
      )}
      {/* All Dialogs */}
      <SimpleDialog
        open={alert.show}
        onConfirm={() => dispatch(appActions.hideError())}
        dialogTitle={typeof alert.title === 'object' ? JSON.stringify(alert.title) : alert.title}
        contentText={alert.body}
        confirmText="OK"
        styled={true}
        errorMessage={true}
      />
      {state.isLoading && (
        <Spinner
          size={125}
          loadingText={getCreateLoadingText()}
          lockActions={true}
          textColor="white"
          bgColor="grey"
        />
      )}
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(NewAccount);

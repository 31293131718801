// External imports
import React, { useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  withStyles,
} from '@material-ui/core';
// Internal imports
import { useSetState } from '~/app/Utility/customHooks';
import { MAX_IMAGE_FILE_SIZE_MB, DOCUMENT_TYPES } from '~/app/constants.js';
import defaultAvatar from '~/images/managerDash.png';
// Redux imports
import { useSelector, useDispatch } from 'react-redux';
import { actions as appActions } from '~/app/redux/modules/app';
// Styling imports
import { styles } from '~/app/Components/HeaderBar/components/AddAvatar/css/addAvatar.js';
import '~/app/Components/HeaderBar/components/AddAvatar/css/addAvatar.css';

const AddAvatar = props => {
  const { classes } = props;
  const dispatch = useDispatch();
  const { currentAvatar } = useSelector(state => ({
    currentAvatar: state.app.avatarBase64,
  }));
  const [state, setState] = useSetState({
    selectedImage: '',
    base64String: '',
    existingLogo: false,
    imageErrorDlg: false,
    avatarDlgMsg: '',
    avatarDlgTitle: '',
  });

  useEffect(() => {
    if (currentAvatar !== '') {
      setState({ existingLogo: true, selectedImage: currentAvatar });
    }
  }, []);

  const fileSelectorHandler = (e, selectedImage) => {
    e.preventDefault();

    const filesize = document.getElementById('inputUploadAvatar').files[0].size; // image size in bytes
    if (
      selectedImage.type !== DOCUMENT_TYPES.JPG &&
      selectedImage.type !== DOCUMENT_TYPES.PNG &&
      selectedImage.type !== DOCUMENT_TYPES.JPEG &&
      selectedImage.type !== DOCUMENT_TYPES.GIF
    ) {
      setState({
        imageErrorDlg: true,
        avatarDlgTitle: 'Invalid File Format',
        avatarDlgMsg:
          'The selected file is not a valid format. JPEG, PNG, and GIF are allowed file formats',
      });
      return;
    }
    if (filesize / 1024 / 1024 > MAX_IMAGE_FILE_SIZE_MB) {
      setState({
        imageErrorDlg: true,
        avatarDlgTitle: 'Image Too Large',
        avatarDlgMsg:
          'The selected image is too large. Image size cannot exceed ' +
          MAX_IMAGE_FILE_SIZE_MB +
          ' MB',
      });
      return;
    }
    if (selectedImage) {
      const reader = new FileReader();
      reader.readAsDataURL(selectedImage);

      reader.onload = e => {
        const imageString = e.target.result.toString();

        setState({
          selectedImage: URL.createObjectURL(selectedImage),
          base64String: imageString,
        });
      };
    }
  };

  const onUploadImage = () => {
    if (state.base64String) {
      props.onClickUploadImage(state.base64String);
    }
  };

  return (
    <div className="add-avatar-block">
      <div className="modal-header-bar">
        <div>Choose Your Avatar</div>
      </div>
      <input
        accept="image/x-png,image/gif,image/jpeg"
        className={classes.input}
        id="inputUploadAvatar"
        multiple
        type="file"
        onChange={e => fileSelectorHandler(e, e.target.files[0])}
      />
      <label className="add-avatar-select-image-block" htmlFor="inputUploadAvatar">
        <Button
          component="span"
          style={{
            padding: '0.5em 1em 0.5em 1em',
            border: 'none',
            marginLeft: '2vw',
          }}
          className={classes.button}
        >
          Click Here To Select An Image File
        </Button>
      </label>

      <img
        src={state.selectedImage === '' ? defaultAvatar : state.selectedImage}
        className={classes.avatarPreview}
      />
      <div className={classes.imageCaption}>Note: Image will be displayed as 65px X 65px</div>
      <div className={state.existingLogo ? classes.fullButtonGroup : classes.buttonGroup}>
        {state.existingLogo && (
          <Button onClick={props.onResetToDefault} className={classes.button} color="primary">
            Reset to Default
          </Button>
        )}
        <Button
          onClick={() => dispatch(appActions.toggleAddAvatar())}
          className={classes.button}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={onUploadImage}
          className={classes.button}
          color="primary"
          variant="contained"
          disabled={!state.selectedImage || state.selectedImage === currentAvatar}
        >
          Upload Image
        </Button>
      </div>
      <Dialog
        open={state.imageErrorDlg}
        onClose={() => setState({ imageErrorDlg: false, avatarDlgMsg: '', avatarDlgTitle: '' })}
      >
        <DialogTitle>{state.avatarDlgTitle}</DialogTitle>
        <DialogContent>{state.avatarDlgMsg}</DialogContent>
        <DialogActions>
          <Button
            id="btnAvatarImageTooLargeOK"
            onClick={() => {
              setState({ imageErrorDlg: false, avatarDlgMsg: '', avatarDlgTitle: '' });
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(AddAvatar);

import React, { useEffect } from 'react';
// External imports
import { Link, withRouter, useHistory } from 'react-router-dom';
import {
  Button,
  FormControl,
  CircularProgress,
  FormLabel,
  Input,
  InputLabel,
  Paper,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
// Internal imports
import { useSetState } from '~/app/Utility/customHooks';
import XlinkAPI from '~/app/api/xlinkAPI';
import ErrorHelpers from '~/app/errorHelpers.js';
import { LOGIN_SETTINGS } from '~/app/constants';
import SimpleDialog from '#/Common/SimpleDialog.jsx';
import WebHelpers, { statusOK } from '~/app/webHelpers.js';
import { webSocket } from '~/app/Utility/socketConnection.js';
import { onSubmitOTPT } from '~/app/Pages/MFACode/mfaCodeHelper.js';
// Redux imports
import { useSelector, useDispatch } from 'react-redux';
import { actions as appActions } from '~/app/redux/modules/app';
import { actions as loginSetupActions } from '~/app/redux/loginSetup/duck';
// Styling imports
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import appTheme from '~/themes/GenericTheme.jsx';
import { styles } from '~/app/Pages/MFACode/css/mfaCode.js';
import '~/app/Pages/MFACode/css/mfaCode.css';

/**
 * Component that handles entering the two factor authentication code
 * as well as allowing the user to register their device or mark it is a
 * public computer.
 *
 * @component
 * @category MFACode
 */
const MFACode = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const payload = WebHelpers.getJWTPayload();
  const { classes } = props;

  const { MFAMethod, logo, fingerprint, isAuthenticated, alert, isAuthenticating } = useSelector(
    state => ({
      MFAMethod: state.app.MFA_Method,
      logo: state.cobrand.logo,
      fingerprint: state.app.browserFingerprint,
      isAuthenticated: state.app.isAuthenticated,
      alert: state.app.alert,
      isAuthenticating: state.loginSetup.isAuthenticating,
    }),
  );

  const [state, setState] = useSetState({
    code: '',
    deviceLabel: '',
    contactInfo: '',
    continueDisabled: true,
    isEmailSent: false,
    isEmailConfirmationOpen: false,
    isConfirmationKeyIgnored: false,
  });

  useEffect(() => {
    handleDisablingContinueBtn();
    setMFAMethod();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      webSocket.services();
      history.push({ pathname: '/dashboard' });
    }
  }, [isAuthenticated]);

  useEffect(() => {
    let hasContinueFlag = true;

    if (state.code?.length > 0) {
      hasContinueFlag = false;

      setState({
        continueDisabled: hasContinueFlag,
      });
    }
  }, [state.code]);

  /**
   * Handles disabling the continue button and determins if the confirmation key is ignored
   * depending on the environment.
   */
  const handleDisablingContinueBtn = () => {
    let isConfirmationKeyIgnored = false;
    // CLOTST accounts are used for stress testing
    if (
      ENVIRONMENT === 'local' ||
      ENVIRONMENT === 'development' ||
      ENVIRONMENT === 'QA' ||
      payload.account_code === 'CLOTST'
    ) {
      isConfirmationKeyIgnored = true;
    }

    setState({
      isConfirmationKeyIgnored,
    });
  };

  /** Sets the MFA method and sends the code by email if the email option is selected. */
  const setMFAMethod = () => {
    if (MFAMethod === 'text') {
      const contact = sessionStorage.text;
      setState({ contactInfo: 'xxx-xxx-' + contact.slice(-4) });
    } else {
      const contact = sessionStorage.emailAddress2ndFactor;
      const atPos = contact.indexOf('@');
      setState({
        contactInfo: contact[0] + '*'.repeat(atPos - 2) + contact.substring(atPos - 1),
      });
      // Call XlinkAPI to generate random code and send email to user
      postCodeAndSendEmail();
    }
  };

  /** Sends the two factor authenticaion code by email. */
  const postCodeAndSendEmail = async () => {
    const email = sessionStorage.emailAddress2ndFactor;

    try {
      const res = await XlinkAPI.postCodeAndSendEmail(email);
      if (statusOK(res, false)) {
        setState({
          isEmailSent: true,
          isEmailConfirmationOpen: true,
        });
      }
    } catch (err) {
      ErrorHelpers.handleError('Email not sent', err);
      setState({
        isEmailConfirmationOpen: true,
      });
    }
  };

  /**
   * Sets the input value to state.
   *
   * @param {Object} e - event which takes place in the DOM.
   */
  const handleChange = e => {
    const { name, value } = e.target;

    setState({ [name]: value });
  };

  /**
   * Handles when the user clicks on the submit button. Determines whether the user will be
   * sent to the terms and conditions page, IRS message page or finalize the login process.
   *
   * @param {Object} e - event which takes place in the DOM.
   */
  const handleSubmit = async e => {
    e.preventDefault();

    const onSuccess = () => {
      const showTOC = sessionStorage.getItem(LOGIN_SETTINGS.SHOW_TERMS_AND_CONDITIONS);
      const showMsg = sessionStorage.getItem(LOGIN_SETTINGS.SHOW_IRS_MESSAGE);

      if (showTOC === 'true') {
        history.push({ pathname: '/terms-and-conditions' });
        return;
      }

      if (showMsg === 'true') {
        history.push({ pathname: '/irs-message' });
        return;
      }
      dispatch(loginSetupActions.onFinalizeLogin());
    };

    try {
      const res = await onSubmitOTPT(state.code, fingerprint, MFAMethod, state.deviceLabel);

      if (statusOK(res)) {
        if (Object.prototype.hasOwnProperty.call(res.data, 'deviceRegistrationFail')) {
          // Device registration status is being passed back
          let deviceRegMsg = 'Device Successfully Registered';
          let deviceRegStatus = 'success';

          if (res.data.deviceRegistrationFail) {
            // Display a snackbar letting the user know device registration failed.
            deviceRegMsg = 'Device Registration Failed';
            deviceRegStatus = 'error';
          }

          dispatch(appActions.showSnackbarMessage(deviceRegMsg, deviceRegStatus));
        }
        onSuccess();
      }
    } catch (error) {
      ErrorHelpers.handleError('Login Error', error);
    }
  };

  /** Resends the code to the provided email or opens up Google Authenticator documentation. */
  const handleResendCode = () => {
    if (MFAMethod === 'email') {
      postCodeAndSendEmail();
    } else {
      window.open(
        'https://support.google.com/accounts/answer/1066447?hl=en&ref_topic=2954345&co=GENIE.Platform%3DiOS&oco=0',
      );
    }
  };

  /** Hides the email confirmation modal. */
  const hideEmailConfirmationModal = () => {
    setState({
      isEmailConfirmationOpen: false,
    });
  };

  return (
    <MuiThemeProvider theme={appTheme}>
      <div className="container-fluid" id="mfaEnterCodeContainer">
        <Paper className={classes.mfaCodePaperContainer}>
          <div className="mfa-code-content">
            <div className="mfa-logo-container">{<img src={logo} className="logoCrosslink" />}</div>
            <div className="mfa-code-fields-container">
              <form className="verifyAccountForm" onSubmit={e => handleSubmit(e)}>
                <div className="mfa-enter-code-heading">
                  {MFAMethod === 'email'
                    ? 'Enter the code sent to: ' + state.contactInfo
                    : 'Enter the code from app '}
                </div>
                <FormControl fullWidth>
                  <InputLabel className={classes.mfaConfirmationKeylabel} required={false} shrink>
                    Confirmation Key
                  </InputLabel>
                  <Input
                    className={classes.mfaCodeFields}
                    name="code"
                    onChange={handleChange}
                    required={!state.isConfirmationKeyIgnored}
                    id="txtConfirmKey"
                    disableUnderline
                  />
                </FormControl>
                <FormLabel classes={classes.mfaIssueTextLabel}>
                  {MFAMethod === 'email' ? "Didn't receive it?" : 'Having issues?'}
                  <span className="mfa-code-issue-support-link" onClick={handleResendCode}>
                    {MFAMethod === 'email' ? 'Resend code' : 'Google 2FA Support Page'}
                  </span>
                </FormLabel>
                <FormControl fullWidth>
                  <div className="mfa-back-continue-btn-container">
                    <div className="mfa-back-continue-btn-content">
                      <Link to="mfa-method">
                        <Typography
                          className={classes.mfaBackButton}
                          id="typBackToLoginMFACODE"
                          color="primary"
                          disabled={isAuthenticating}
                        >
                          Back
                        </Typography>
                      </Link>
                      <Button
                        id="btnContinueMFACODE"
                        className={classes.mfaContinueButton}
                        variant="contained"
                        color="primary"
                        size="medium"
                        type="submit"
                        disabled={state.continueDisabled || isAuthenticating}
                      >
                        {isAuthenticating ? (
                          <>
                            <CircularProgress size={14} className="mfa-btn-spinner" /> Validating...
                          </>
                        ) : (
                          'Submit'
                        )}
                      </Button>
                    </div>
                  </div>
                </FormControl>
              </form>
              <div className="mfa-notice">
                ** <span className="mfa-notice-color-red"> IMPORTANT NOTE</span>: We are
                consistently reviewing our security standards. After recent review, we have come to
                a decision the secure device feature does not meet our current security standards.
                For this reason, we have removed the register device feature.
              </div>
            </div>
          </div>
        </Paper>
        <SimpleDialog
          open={state.isEmailConfirmationOpen}
          onConfirm={hideEmailConfirmationModal}
          dialogTitle={`EMAIL ${!state.isEmailSent ? 'NOT' : ''} SENT`}
          contentText={
            !state.isEmailSent
              ? 'Could not send the verification code to the email address we have on file.'
              : 'An email containing your verification code will be sent to you shortly.'
          }
          contentTextTwo={
            !state.isEmailSent ? 'If the problem persists, please contact technical support.' : ''
          }
          confirmText="Done"
          styled
        />
      </div>
      <SimpleDialog
        open={alert.show}
        onConfirm={() => dispatch(appActions.hideError())}
        dialogTitle={alert.title}
        contentText={alert.body}
        contentTextTwo={alert.bodyTwo}
        confirmText="OK"
        styled
        errorMessage
      />
    </MuiThemeProvider>
  );
};

MFACode.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(MFACode));

// External imports
import React, { Fragment, useEffect } from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import {
  Grid,
  Collapse,
  Divider,
  Modal as MaterialModal,
  Typography,
  Paper,
  Button,
  Drawer,
  MenuList,
  MenuItem,
  ListItemText,
  withStyles,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
// Internal imports
import SaveParagraphModal from '~/app/Components/Auth/SaveParagraphModal.jsx';
import PagedTable from '#/Common/PagedTable.jsx';
import AccessControl from '~/app/Components/Auth/AccessControl.jsx';
import ErrorHelpers from '~/app/errorHelpers.js';
import XlinkAPI from '~/app/api/xlinkAPI';
// Styling imports
import '#/Settings/Setup/ClientLetters/Components/css/clientLetterEditor.css';
import { styles } from '#/Settings/Setup/ClientLetters/Components/css/clientLetterEditorStyles.js';
import '~/app/Components/Auth/css/saveParagraphModal.css';

const config = {
  toolbar: [
    'bold',
    '|',
    'alignment:left',
    'alignment:center',
    'alignment:right',
    '|',
    'indent',
    'outdent',
    '|',
    'undo',
    'redo',
  ],
};

const spanishRTFChars = {
  '\\&#39;bf': '¿',
  '\\&#39;a1': '¡',
  '\\&#39;e1': 'á',
  '\\&#39;E1': 'á',
  '\\&#39;c1': 'Á',
  '\\&#39;e9': 'é',
  '\\&#39;E9': 'é',
  '\\&#39;c9': 'É',
  '\\&#39;ed': 'í',
  '\\&#39;Ed': 'í',
  '\\&#39;cd': 'Í',
  '\\&#39;f3': 'ó',
  '\\&#39;d3': 'Ó',
  '\\&#39;fa': 'ú',
  '\\&#39;da': 'Ú',
  '\\&#39;fc': 'ü',
  '\\&#39;dc': 'Ü',
  '\\&#39;f1': 'ñ',
  '\\&#39;d1': 'Ñ',
};

const ClientLetterEditor = props => {
  const [saveParagraph, showSaveParagraph] = React.useState(false);
  const [sectionSave, setSection] = React.useState('');
  const [keySave, setKey] = React.useState('');
  const [editorText, setEditorText] = React.useState('');
  const [changedTab, setChangedTab] = React.useState(false);
  const [edited, setEdited] = React.useState(false);
  const [onLoad, setLoad] = React.useState(false);
  const [centralSiteCode, setCentralSiteCode] = React.useState(
    props.data ? props.data.sectionCode + 'AA' : '',
  );
  const [selectedSection, setSelectedSection] = React.useState({
    name: 'HEADER/ADDRESS',
  });
  const [expandedSections, updateExpandedSections] = React.useState([]);
  const [letterMacros, setMacros] = React.useState({ columns: [], rows: [] });
  const [showMacros, toggleMacroLookup] = React.useState(false);

  useEffect(() => {
    XlinkAPI.getClientLetterMacros()
      .then(res => {
        setMacros(res.data);
      })
      .catch(error => {
        ErrorHelpers.handleError('Unable to fetch macros', error);
      });
  }, []);

  useEffect(() => {
    XlinkAPI.getClientLetterSectionText(
      props.letter.ID,
      centralSiteCode,
      props.letter.custom,
      props.entitycode,
    )
      .then(res => {
        let sectionText = atob(res.data.sectionText);
        sectionText = translateSpanishRTF(sectionText);
        setEditorText(sectionText);
      })
      .catch(error => {
        ErrorHelpers.handleError('Unable to fetch client letter text', error);
      });
  }, [centralSiteCode]);

  /**
   * translateSpanishRTF replaces special spanish rtf characters with a plaintext representation
   * @param {*} text
   */
  const translateSpanishRTF = text => {
    let translated = text;
    Object.entries(spanishRTFChars).forEach(([key, val]) => {
      const regex = new RegExp('\\' + key, 'gm');
      translated = translated.replace(regex, val);
    });
    return translated;
  };

  /**
   * translateSpanishHTML replaces special spanish characters with its rtf representation
   * @param {*} text
   */
  const translateSpanishHTML = text => {
    let translated = text;
    Object.entries(spanishRTFChars).forEach(([key, val]) => {
      const regex = new RegExp(val, 'gm');
      translated = translated.replace(regex, key);
    });
    return translated;
  };
  const showModal = () => {
    if (edited) {
      showSaveParagraph(true); // use saveParagraph as a flag to display modal in render
    }
  };
  const hideModal = () => {
    showSaveParagraph(false);
  };

  const onClickSaveFromSaveParagraphModal = saving => {
    // takes the response from the modal and does the appropriate action
    if (saving) {
      // triggers a save for the current paragraph

      saveEditorText();
    }
    handleWaitPrompt(sectionSave, keySave); // triggers the change of the section with the values we saved earlier
    hideModal(); // modal is done being used, can hide it
  };
  const saveEditorText = async () => {
    let sectionText = translateSpanishHTML(editorText);
    sectionText = btoa(sectionText);
    setEdited(false);
    try {
      const resp = await XlinkAPI.updateClientLetterSectionText(
        props.letter.ID,
        centralSiteCode,
        props.letter.custom,
        sectionText,
        props.entitycode,
      );
      if (resp) {
        /* Extrapolating snackbar contents to parent component so that snackbar can sit on top of client letter,
        making it viewable no matter where the user has scrolled to on the screen */
        const snackbarHolder = {
          visible: true,
          content: 'Client letter section saved.',
        };
        props.dataSnackBar(snackbarHolder);
      }
    } catch (error) {
      ErrorHelpers.handleError('Unable to save client letter text', error);
    }
  };
  const handleEditorChange = editor => {
    // saveModal logic and functions created by Gurjit Chahal 06/22/20
    setEditorText(editor.getData());
    if (onLoad) {
      // onLoad flag is to allow following logic to execute after the initial load of Client Letters
      // this function is called upon loading so we need to block the first instance by using onLoad flag
      if (!changedTab) {
        // if we have not just changed the tab, the only reason we can be in this function is we edited text
        setEdited(true);
      } else {
        // this is the case for when we just changed tabs, can reset flags to false and await actions
        setEdited(false);
        setChangedTab(false);
      }
    }
    setLoad(true);
  };
  /**
   * If sub-heading has sub-section, then we apply a different style
   * which includes bottom border.
   */
  const subSectionHeadingStyle = subSectionHeading => {
    if (subSectionHeading.subsection) {
      return { root: classes.clientLetterSubSectionMenuHeading };
    } else {
      return { root: classes.clientLetterSetupMenuItem };
    }
  };

  /**
   * Checks if we need to indent further base on
   * whether there is a sub-section or not.
   */
  const whichSubSectionStyle = subSectionLevel => {
    if (subSectionLevel === 2) {
      // Sub-sub-section, so we need to indent even further.
      return { root: classes.clientLetterSubSectionLevelTwoSetupMenuItem };
    } else if (subSectionLevel === 1) {
      // Sub-section, so we need to indent further.
      return { root: classes.clientLetterSubSectionSetupMenuItem };
    } else {
      return { root: classes.clientLetterSubSectionItem };
    }
  };

  /**
   * Handles sidebar click action
   * Sections with a subsection should be loaded, otherwise toggle collapse
   */
  const handleSidebarClick = (section, key) => {
    if (props.letter.custom && edited) {
      // if we have a custom letter, we want to show the modal and wait for a response of "save" or "don't save"
      showModal();
      setSection(section); // we store these values so we can use them after we get the response
      setKey(key);
    } else {
      // otherwise, we can just change sections
      if (typeof section.subsection !== 'undefined') {
        handleCollapse(key);
      } else {
        setCentralSiteCode(section.centralSiteCode);
        setSelectedSection(section);
      }
    }
    setEdited(false);
    setChangedTab(true);
  };

  const handleWaitPrompt = (section, key) => {
    if (typeof section.subsection !== 'undefined') {
      handleCollapse(key);
    } else {
      setCentralSiteCode(section.centralSiteCode);
      setSelectedSection(section);
    }
  };

  /**
   * Closes the editor when the back button is clicked
   */
  const handleBackClick = () => {
    props.closeEditor();
  };

  /**
   * Copy a keyword macro to the clipboard
   */
  const copyKeyword = data => {
    navigator.clipboard.writeText(data.macro).then(() => {
      /* Extrapolating snackbar contents to parent component so that snackbar can sit on top of client letter,
        making it viewable no matter where the user has scrolled to on the screen */
      const snackbarHolder = {
        visible: true,
        content: 'Copied to clipboard.',
      };
      props.dataSnackBar(snackbarHolder); // This sends data to parent component to be passed into a function which populates our snackbar
    });
  };

  /**
   * handles a click on a collapsible item
   *
   */
  const handleCollapse = key => {
    const keyIndex = expandedSections.indexOf(key);
    const newexpandedSections = [...expandedSections];

    if (keyIndex === -1) {
      newexpandedSections.push(key);
    } else {
      newexpandedSections.splice(keyIndex, 1);
    }
    updateExpandedSections(newexpandedSections);
  };

  const generateSidebarSubsection = (sections, subSection = false, subSectionLevel = 0) => {
    return (
      <div>
        {Object.keys(sections).map(key => {
          return (
            <Fragment key={key}>
              <MenuItem
                classes={subSectionHeadingStyle(sections[key])}
                selected={sections[key].centralSiteCode == centralSiteCode}
                onClick={() => handleSidebarClick(sections[key], key)}
              >
                <ListItemText
                  classes={whichSubSectionStyle(subSectionLevel)}
                  disableTypography
                  primary={<Typography type="body2">{sections[key].name}</Typography>}
                />
                {sections[key].subsection &&
                  (expandedSections.indexOf(key) !== -1 ? <ExpandLess /> : <ExpandMore />)}
              </MenuItem>
              {sections[key].subsection && (
                <Collapse in={expandedSections.indexOf(key) !== -1} timeout="auto" unmountOnExit>
                  {generateSidebarSubsection(sections[key].subsection, true, subSectionLevel + 1)}
                </Collapse>
              )}
            </Fragment>
          );
        })}
      </div>
    );
  };

  const generateSidebar = sections => {
    return (
      <div>
        {Object.keys(sections).map(key => {
          return (
            <Fragment key={key}>
              <MenuItem
                classes={subSectionHeadingStyle(sections[key])}
                selected={sections[key].centralSiteCode == centralSiteCode}
                onClick={() => handleSidebarClick(sections[key], key)}
              >
                <ListItemText
                  classes={{ root: classes.clientLetterSetupMenuItem }}
                  disableTypography
                  primary={<Typography type="body2">{sections[key].name}</Typography>}
                />
                {sections[key].subsection &&
                  (expandedSections.indexOf(key) !== -1 ? <ExpandLess /> : <ExpandMore />)}
              </MenuItem>
              {sections[key].subsection && (
                <Collapse in={expandedSections.indexOf(key) !== -1} timeout="auto" unmountOnExit>
                  {generateSidebarSubsection(sections[key].subsection)}
                </Collapse>
              )}
            </Fragment>
          );
        })}
      </div>
    );
  };

  const { classes } = props;
  return (
    <div className="client-letter-editor-container">
      <MaterialModal
        className="main-container"
        id="mdlLoginSettings"
        open={saveParagraph}
        onClose={() => showSaveParagraph(false)}
      >
        <Paper elevation={5}>
          <SaveParagraphModal
            onClickContinue={(saving, prompt) => onClickSaveFromSaveParagraphModal(saving)}
          />
        </Paper>
      </MaterialModal>
      <Grid container spacing={0} className={classes.clientLetterEditorHeading}>
        <Grid className="verticallyCenter" item xs={8}>
          <Typography variant="title">Modify Letter - {props.letter.name}</Typography>
        </Grid>
        <Grid item xs={4} className={classes.clientLetterEditorBtns}>
          <Button onClick={() => handleBackClick()}>Back</Button>
        </Grid>
      </Grid>
      <div className={classes.root}>
        <Drawer variant="permanent" position="absolute" classes={{ paper: classes.drawerPaper }}>
          <MenuList component="nav" classes={{ root: classes.editorSideBar }}>
            {generateSidebar(props.data.sections)}
          </MenuList>
        </Drawer>
        <main className={classes.content}>
          <div>
            <Paper classes={{ root: classes.editorPaper }} className="ovNotebook ovListPaper">
              <Grid container spacing={0} className={classes.clientLetterEditorHeading}>
                <Grid className="verticallyCenter" item xs={8}>
                  <h3 className="client-letter-editor-title">
                    {props.data.headerName} - {selectedSection.name}
                  </h3>
                </Grid>
                <Grid item xs={4} className={classes.clientLetterEditorBtns}>
                  <Button onClick={() => handleBackClick()}>Back</Button>
                </Grid>
              </Grid>
              <CKEditor
                editor={DecoupledEditor}
                config={config}
                data={editorText}
                onInit={editor => {
                  editor.ui
                    .getEditableElement()
                    .parentElement.insertBefore(
                      editor.ui.view.toolbar.element,
                      editor.ui.getEditableElement(),
                    );
                }}
                onChange={(event, editor) => handleEditorChange(editor)}
              />
              <Divider />
              <Grid container spacing={0} className={classes.clientLetterEditorFooter}>
                <Grid item xs={8}>
                  <Button size="small" onClick={() => toggleMacroLookup(!showMacros)}>
                    {showMacros ? 'Hide Keyword Lookup' : 'Show Keyword Lookup'}
                  </Button>
                </Grid>
                <Grid item xs={4} className={classes.clientLetterEditorBtns}>
                  {props.letter.custom && (
                    <AccessControl
                      requiredAction="write"
                      accessLevel="edit_client_letter"
                      additionalAccessCheck={props.letter.custom}
                      disableOnFalse={true}
                    >
                      <Button onClick={() => saveEditorText()} disabled={!props.letter.custom}>
                        Save
                      </Button>
                    </AccessControl>
                  )}
                </Grid>
                <Grid item xs={12} className={classes.clientLetterEditorFooterMacroLookup}>
                  <Collapse in={showMacros} timeout="auto" unmountOnExit>
                    <Typography variant="subheading" align="center">
                      Click on a keyword to copy it to your clipboard.
                    </Typography>
                    <Grid
                      container
                      spacing={0}
                      classes={{
                        container: classes.clientLetterEditorFooterMacroLookupTableContainer,
                      }}
                    >
                      <PagedTable
                        columns={['Keyword', 'Description']}
                        rows={letterMacros.rows}
                        rowAction={data => copyKeyword(data)}
                      />
                    </Grid>
                  </Collapse>
                </Grid>
              </Grid>
            </Paper>
          </div>
        </main>
      </div>
    </div>
  );
};

export default withStyles(styles)(ClientLetterEditor);

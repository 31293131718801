export const styles = {
  componentStyle: {
    width: '100%',
    margin: 'auto',
  },
  list: {
    marginRight: '1em',
    paddingRight: '0',
    marginLeft: '1em',
    paddingLeft: '0',
    paddingBottom: '0',
  },
  icon: {
    float: 'left',
    marginRight: '1em',
  },
  link: {
    cursor: 'pointer',
    color: '#0077FF',
  },
  showAllLink: {
    cursor: 'pointer',
    color: '#0077FF',
    marginLeft: '1.5em',
  },
  messageText: {
    float: 'left',
    paddingTop: '0.2em',
  },
  returnLink: {
    float: 'left',
    paddingTop: '0.2em',
    marginLeft: '1em',
    cursor: 'pointer',
    color: '#0077FF',
  },
  closeButton: {
    float: 'right',
    cursor: 'pointer',
    fontSize: '20px',
  },
  listItem: {
    listStyle: 'none',
    borderRadius: '4px',
    margin: '5px',
    height: '2em',
    fontSize: '15px',
    fontWeight: '400',
    paddingLeft: '1em',
    paddingRight: '1em',
  },
};

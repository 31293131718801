// External imports
import React from 'react';

import {
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  Typography,
  FormGroup,
  FormControl,
  withStyles,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
// Internal imports
import { LOGIN_SETTINGS, DOWNLOAD_DRIVER_LINKS } from '~/app/constants.js';
// Styling imports
import { styles } from '~/app/Components/Settings/LoginSettings/css/loginSettings.js';
import '~/app/Components/Settings/LoginSettings/css/loginSettings.css';

const LoginSettings = props => {
  const { classes } = props;

  const setInitialSettings = (settings, getSettingsObj) => {
    const initialSettings = [];
    for (const setting in settings) {
      const obj = getSettingsObj(setting);
      if (obj.value && obj.text) {
        initialSettings.push(setting);
      }
    }
    return initialSettings;
  };

  const getSettingsObject = setting => {
    return typeof props.loginSettings[setting] !== 'object'
      ? JSON.parse(props.loginSettings[setting])
      : props.loginSettings[setting];
  };

  const initialSettings = setInitialSettings(props.loginSettings, getSettingsObject);

  const [checked, setChecked] = React.useState(initialSettings);

  const handleToggle = setting => {
    if (!(setting === 'scriptel' || setting === 'topaz' || setting === 'onScreen')) {
      const settingObj = getSettingsObject(setting);
      const checkedSettings = [...checked];
      const newSettings = props.loginSettings;

      // Only allow one when selecting signature pad type
      if (settingObj.value === false) {
        checkedSettings.push(setting);
        setChecked(checkedSettings);
        settingObj.value = true;
        // If the user changes the signature pad type, we need to remove the other checkboxes
      } else {
        checkedSettings.splice(setting, 1);
        setChecked(checkedSettings);
        settingObj.value = false;
      }

      newSettings[setting] = settingObj;
      props.setLoginSettings(newSettings);
    } else {
      // Always get the three different setting signature pads
      const settingObjOnScreen = getSettingsObject('onScreen');
      const settingObjScriptel = getSettingsObject('scriptel');
      const settingObjTopaz = getSettingsObject('topaz');
      const checkedSettings = [...checked];
      const newSettings = props.loginSettings;

      if (setting === 'onScreen') {
        if (settingObjOnScreen.value === false) {
          // Check the on-Screen option and uncheck the topaz and scriptel options
          // Checking the on-screen option
          checkedSettings.push(setting);
          setChecked(checkedSettings);
          settingObjOnScreen.value = true;
          // Uncheck the other two options
          checkedSettings.splice('scriptel', 1);
          setChecked(checkedSettings);
          settingObjScriptel.value = false;
          checkedSettings.splice('topaz', 1);
          setChecked(checkedSettings);
          settingObjTopaz.value = false;
        } else {
          // Checking the on-screen option
          checkedSettings.push(setting);
          setChecked(checkedSettings);
          settingObjOnScreen.value = false;
        }
      }
      if (setting === 'scriptel') {
        if (settingObjScriptel.value === false) {
          // Check the on-Screen option and uncheck the topaz and scriptel options
          // Checking the on-screen option
          checkedSettings.push(setting);
          setChecked(checkedSettings);
          settingObjScriptel.value = true;
          // Uncheck the other two options
          checkedSettings.splice('onScreen', 1);
          setChecked(checkedSettings);
          settingObjOnScreen.value = false;
          checkedSettings.splice('topaz', 1);
          setChecked(checkedSettings);
          settingObjTopaz.value = false;
        } else {
          // Checking the on-screen option
          checkedSettings.push(setting);
          setChecked(checkedSettings);
          settingObjScriptel.value = false;
        }
      }
      if (setting === 'topaz') {
        if (settingObjTopaz.value === false) {
          // Check the on-Screen option and uncheck the topaz and scriptel options
          // Checking the on-screen option
          checkedSettings.push(setting);
          setChecked(checkedSettings);
          settingObjTopaz.value = true;
          // Uncheck the other two options
          checkedSettings.splice('onScreen', 1);
          setChecked(checkedSettings);
          settingObjOnScreen.value = false;
          checkedSettings.splice('scriptel', 1);
          setChecked(checkedSettings);
          settingObjScriptel.value = false;
        } else {
          // Checking the on-screen option
          checkedSettings.push(setting);
          setChecked(checkedSettings);
          settingObjTopaz.value = false;
        }
      }
      newSettings.onScreen = settingObjOnScreen;
      newSettings.scriptel = settingObjScriptel;
      newSettings.topaz = settingObjTopaz;
      props.setLoginSettings(newSettings);
    }
  };

  // Opens given url
  const downloadDriver = url => {
    window.open(url);
  };

  const setupLoginSettingsOptions = () => {
    return Object.keys(props.loginSettings)
      .filter(
        x =>
          x !== LOGIN_SETTINGS.SHOW_IRS_MESSAGE &&
          x !== LOGIN_SETTINGS.SHOW_ON_SCREEN &&
          x !== LOGIN_SETTINGS.SHOW_SCRIPTEL &&
          x !== LOGIN_SETTINGS.SHOW_TOPAZ,
      )
      .map(setting => {
        const settingObj = getSettingsObject(setting);
        if (typeof settingObj !== 'object') return null; // some elements in settingObj are not meant to be rendered to UI.

        return (
          <ListItem key={setting + 'newChk'}>
            <Checkbox
              id={setting + 'chk'}
              name={setting}
              classes={{ root: classes.loginPreferencesCheckbox }}
              onClick={() => handleToggle(setting)}
              checked={settingObj.value === true}
              icon={
                <CheckBoxOutlineBlankIcon
                  classes={{ root: classes.loginPreferencesCheckboxOutlineIcon }}
                  color="secondary"
                />
              }
              checkedIcon={
                <CheckBoxIcon
                  classes={{ root: classes.loginPreferencesCheckboxIconSize }}
                  color="primary"
                />
              }
            />
            <ListItemText
              classes={{ root: classes.loginPreferencesCheckboxItem }}
              primary={settingObj.text}
              onClick={() => handleToggle(setting)}
            />
          </ListItem>
        );
      });
  };

  const setupLoginSignatureOptions = () => {
    return Object.keys(props.loginSettings)
      .filter(
        x => x !== LOGIN_SETTINGS.SHOW_IRS_MESSAGE && x !== LOGIN_SETTINGS.SHOW_INTERVIEW_MODE,
      )
      .map((setting, idx) => {
        const settingObj = getSettingsObject(setting);
        if (typeof settingObj !== 'object') return null; // some elements in settingObj are not meant to be rendered to UI.

        return (
          <div key={idx} className="login-preferences-signature-options-container">
            <ListItem key={setting} classes={{ root: classes.loginPreferencesSignatureItem }}>
              <Checkbox
                id={setting + 'chk'}
                classes={{ root: classes.loginPreferencesSignatureCheckbox }}
                onClick={() => handleToggle(setting)}
                checked={settingObj.value === true}
                icon={
                  <CheckBoxOutlineBlankIcon
                    classes={{ root: classes.loginPreferencesCheckboxOutlineIcon }}
                    color="secondary"
                  />
                }
                checkedIcon={
                  <CheckBoxIcon
                    classes={{ root: classes.loginPreferencesCheckboxIconSize }}
                    color="primary"
                  />
                }
              />
              <ListItemText
                classes={{ root: classes.loginPreferencesCheckboxItemText }}
                primary={settingObj.text}
                onClick={() => handleToggle(setting)}
              />
            </ListItem>
          </div>
        );
      });
  };

  return (
    <div>
      <div className="modal-header">
        Login Preferences
        <Typography color="inherit" onClick={() => props.handleClose(false, true)}>
          X
        </Typography>
      </div>
      <List>{setupLoginSettingsOptions()}</List>
      <hr className="login-preferences-hr" />
      <div className="login-preferences-section-heading">
        <span>Please Select your Preferred Signature Capture Method</span>
      </div>

      <FormControl required component="fieldset">
        <FormGroup>
          <List classes={{ root: classes.loginPreferencesSignatureList }}>
            {setupLoginSignatureOptions()}
          </List>
        </FormGroup>
      </FormControl>
      <hr className="login-preferences-hr" />
      <div className="login-preferences-section-heading">
        <span>Looking for Drivers?</span>
      </div>
      <div className="login-preferences-drivers-container">
        <div className="login-preferences-driver-content">
          <span>Scriptel: </span>
          <span className="login-preferences-driver-info">
            <Typography
              id="typDownloadScannerDrivers01"
              classes={{ root: classes.loginPreferencesDriverItem }}
              onClick={() => downloadDriver(DOWNLOAD_DRIVER_LINKS.SCRIPTEL_32_OLD)}
              color="primary"
            >
              32 Bit Windows (OLD VERSION)
            </Typography>
          </span>
          <span className="login-preferences-driver-info">
            <Typography
              id="typDownloadScannerDrivers01"
              classes={{ root: classes.loginPreferencesDriverItem }}
              onClick={() => downloadDriver(DOWNLOAD_DRIVER_LINKS.SCRIPTEL_32_NEW)}
              color="primary"
            >
              32 Bit Windows (NEW VERSION)
            </Typography>
          </span>
          <span className="login-preferences-driver-info">
            <Typography
              id="typDownloadScannerDrivers01"
              classes={{ root: classes.loginPreferencesDriverItem }}
              onClick={() => downloadDriver(DOWNLOAD_DRIVER_LINKS.SCRIPTEL_64)}
              color="primary"
            >
              64 Bit Windows
            </Typography>
          </span>
          <span className="login-preferences-driver-info">
            <Typography
              id="typDownloadScannerDrivers01"
              classes={{ root: classes.loginPreferencesDriverItem }}
              onClick={() => downloadDriver(DOWNLOAD_DRIVER_LINKS.SCRIPTEL_LINUX)}
              color="primary"
            >
              Linux
            </Typography>
          </span>
        </div>
        <div className="login-preferences-driver-content">
          <span>Topaz: </span>
          <span className="login-preferences-driver-info">
            <Typography
              id="typDownloadScannerDrivers01"
              classes={{ root: classes.loginPreferencesDriverItem }}
              onClick={() => downloadDriver(DOWNLOAD_DRIVER_LINKS.TOPAZ)}
              color="primary"
            >
              Windows
            </Typography>
          </span>
        </div>
        <div className="login-preferences-driver-content">
          <span>Scanner: </span>
          <span className="login-preferences-driver-info">
            <Typography
              id="typDownloadScannerDrivers01"
              classes={{ root: classes.loginPreferencesDriverItem }}
              onClick={() => downloadDriver(DOWNLOAD_DRIVER_LINKS.SCANNER)}
              color="primary"
            >
              Motorola
            </Typography>
          </span>
        </div>
      </div>
      <div className="login-references-btn-container">
        <Button
          id="btnCancel"
          size="small"
          color="primary"
          classes={{ root: classes.loginPreferencesBtn }}
          onClick={() => props.handleClose(false, true)}
        >
          Cancel
        </Button>
        <Button
          id="btnSave"
          size="small"
          classes={{ root: classes.loginPreferencesBtn }}
          variant="contained"
          color="primary"
          onClick={props.handleSave}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default withStyles(styles)(LoginSettings);

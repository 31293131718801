export const styles = {
  capturePaymentModal: {
    width: '860px',
    padding: '0',
    position: 'relative',
    margin: 'auto',
    top: '5%',
  },
  capturePaymentModalShortScreen: {
    width: '860px',
    padding: '0',
    position: 'relative',
    margin: 'auto',
    top: '0%',
  },
  capturePaymentModalContent: {
    padding: '1em',
  },
  capturePaymentModalDivider: {
    margin: '1em 0 1em 0',
  },
  capturePaymentContentGridItem: {
    textAlign: 'center',
  },
  payJunctionPaymentButtonGrid: {
    textAlign: 'right',
  },
  payJunctionPaymentButton: {
    marginRight: '1em',
  },
  payJunctionPaymentProgressEndGrid: {
    textAlign: 'right',
  },
  payJunctionPaymentConnectButton: {
    marginTop: '.6rem',
  },
  paymentHistoryTable: {
    maxHeight: '450px',
    overflowY: 'auto',
  },
};

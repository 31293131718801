// External imports
import React from 'react';
// Internal imports
import { NOTIFICATION_TYPE, SIDE_TABS, DASHBOARD_TITLE_TABS } from '~/app/constants.js';
import { useSetState } from '~/app/Utility/customHooks';
import AccountInfoBanner from '~/app/Components/Common/AccountInfoBanner/AccountInfoBanner.jsx';
import successIcon from '~/images/icons/icons8-checkmark-48.png';
import warningIcon from '~/images/icons/warningicon.png';
import errorIcon from '~/images/icons/erroricon.png';
import closeIcon from '~/images/icons/closeicon.png';
// Redux imports
import { useSelector, useDispatch } from 'react-redux';
import { actions as notificationActions } from '~/app/redux/notifications/duck';
import { actions as returnProfileActions } from '~/app/redux/returnProfile/duck';
import { actions as appActions } from '~/app/redux/modules/app';
// Styling imports
import { styles } from '~/app/Components/Common/Notifications/css/notifications.js';

const Notifications = ({
  // only formviewer populates these props
  onFormViewer = false,
  onClickViewReturn = undefined,
  removeMargins = false,
}) => {
  const dispatch = useDispatch();
  const { notifications, maxDisplay, returnID } = useSelector(state => ({
    notifications: state.notifications.notifications,
    maxDisplay: state.notifications.maxDisplay,
    returnID: state.returnProfile.returnID,
  }));

  const [state, setState] = useSetState({
    expandAll: false,
  });

  const handleOpenReturn = (returnID, msgID) => {
    if (onFormViewer === undefined) {
      openReturn(returnID, msgID);
    } else {
      dispatch(notificationActions.setSelectedNotfication(msgID, returnID));
      onClickViewReturn();
    }
  };

  const openReturn = (returnID, msgID) => {
    dispatch(appActions.onSelectSidebarTab(SIDE_TABS.DASHBOARD_MAIN));
    dispatch(
      appActions.setTitleTabProperties(
        'Tax Returns',
        true,
        SIDE_TABS.DASHBOARD_MAIN,
        DASHBOARD_TITLE_TABS.LIST_VIEW,
      ),
    );
    dispatch(
      appActions.setTitleTabProperties(
        '',
        false,
        SIDE_TABS.DASHBOARD_MAIN,
        DASHBOARD_TITLE_TABS.WIDGETS,
      ),
    );
    dispatch(
      appActions.setTitleTabProperties(
        '',
        false,
        SIDE_TABS.DASHBOARD_MAIN,
        DASHBOARD_TITLE_TABS.ACTIVE_RETURN,
      ),
    );
    dispatch(notificationActions.requestAckNotification(msgID));
    dispatch(returnProfileActions.fetchReturnProfileByReturnID(returnID));
  };

  const getNotificationList = () => {
    const list = [];

    const numToShow = state.expandAll
      ? notifications.length
      : Math.min(maxDisplay, notifications.length);

    for (let i = 0; i < numToShow; i++) {
      list.push(
        <li key={i} style={getListItemStyle(notifications[i]?.type)}>
          <span style={styles.icon}>
            <img
              src={getIcon(notifications[i]?.type)}
              style={{ height: '18px', width: '18px', marginTop: '6px' }}
            />
          </span>
          <span style={styles.messageText}>{notifications[i].content}</span>
          {notifications[i].returnID !== 0 &&
            notifications[i].returnID !== returnID && ( // don't show link to the return if we are in that return
              <span
                style={styles.returnLink}
                onClick={() => handleOpenReturn(notifications[i].returnID, notifications[i].id)}
              >
                Open This Return
              </span>
            )}
          <span style={styles.closeButton} onClick={() => handleClick(notifications[i].id)}>
            <img src={closeIcon} style={{ height: '18px', width: '18px', marginBottom: '2px' }} />
          </span>
        </li>,
      );
    }
    return list;
  };

  const handleClick = id => {
    dispatch(notificationActions.requestAckNotification(id));
  };

  const handleShowAll = () => {
    setState({ expandAll: true });
  };

  const getListItemStyle = notificationType => {
    let listItemStyle = {};

    switch (notificationType) {
      case NOTIFICATION_TYPE.SUCCESS:
        listItemStyle = { ...styles.listItem, backgroundColor: '#E0F8EA' };
        break;
      case NOTIFICATION_TYPE.WARNING:
        listItemStyle = {
          ...styles.listItem,
          backgroundColor: 'rgba(255,204,17,0.25)',
        };
        break;
      case NOTIFICATION_TYPE.CRITICAL:
        listItemStyle = { ...styles.listItem, backgroundColor: '#F8E0E0' };
        break;
    }
    return removeMargins
      ? Object.assign({}, listItemStyle, { margin: '0px 0px 0px 0px' })
      : listItemStyle;
  };

  const getIcon = notificationType => {
    let icon = null;
    switch (notificationType) {
      case NOTIFICATION_TYPE.SUCCESS:
        icon = successIcon;
        break;
      case NOTIFICATION_TYPE.WARNING:
        icon = warningIcon;
        break;
      case NOTIFICATION_TYPE.CRITICAL:
        icon = errorIcon;
        break;
    }
    return icon;
  };

  return (
    <div style={styles.componentStyle}>
      <AccountInfoBanner />
      <ul
        style={
          removeMargins
            ? Object.assign({}, styles.list, {
                margin: '0px',
                display: 'grid',
                'grid-gap': '5px',
              })
            : styles.list
        }
      >
        {getNotificationList()}
      </ul>
      {notifications !== undefined &&
        notifications.length > 1 &&
        notifications.length - maxDisplay > 0 &&
        !state.expandAll && (
          <div onClick={handleShowAll} style={styles.showAllLink}>
            Expand {notifications.length - maxDisplay} more{' '}
            {notifications.length > maxDisplay + 1 ? 'messages' : 'message'}
          </div>
        )}
    </div>
  );
};

export default Notifications;

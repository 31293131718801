/* eslint-disable no-case-declarations */
import { TECH_SUPPORT_PHONE_NUMBER } from '~/app/constants.js';
import * as Common from '~/app/redux/commonActions.js';
import { formatPhoneNumber } from '~/app/webHelpers.js';
import logoCrosslink from '~/images/logoCrosslink.png';

export const UPLOAD_LOGO = 'xlinkonline/cobrand/UPLOADLOGO';
export const UPLOAD_LOGO_ERROR = 'xlinkonline/cobrand/UPLOADLOGOERROR';

export const SET_COBRAND_STATUS = 'xlinkonline/cobrand/SETCOBRANDSTATUS';

export const GET_LOGO = 'xlinkonline/cobrand/GETLOGO';
export const SET_LOGO = 'xlinkonline/cobrand/SETLOGO';
export const SET_INFO = 'xlinkonline/cobrand/SETINFO';
export const SET_TECH_SUPPORT_NUMBER = 'xlinkonline/cobrand/SETNUM';
export const GET_LOGO_ERROR = 'xlinkonline/cobrand/GETLOGOERROR';

export const GET_COBRAND_STATUS = 'xlinkonline/cobrand/GETCOBRANDSTATUS';
export const GET_COBRAND_STATUS_ERROR = 'xlinkonline/cobrand/GETCOBRANDSTATUSERROR';

export const DISPLAY_LOGO = 'xlinkonline/cobrand/DISPLAYLOGO';
export const HAS_ACCESS_TO_ADD_COBRANDING_SET = 'xlinkonline/app/HASACCESSTOADDCOBRANDINGSET';

export const initialState = {
  logo: '',
  techSupportNumber: TECH_SUPPORT_PHONE_NUMBER,
  canCobrand: false,
  hasPurchasedCobranding: false,
  isLoadingCobrandStatus: false,
  isLoadingLogo: false,
  hasAccessToAddCobranding: false, // Can toggle the can co-brand flag
  hasAccessToPurchaseCobranding: false, // only root level accounts can purchase cobrand, but admins/tech can toggle
};

export const actions = {
  fetchLogo: loginID => ({
    type: GET_LOGO,
    loginID,
  }),
  setInfo: (logo, techSupportNum, hasPurchasedCobranding, canCobrand, supportUrl, waitForLoad) => ({
    type: SET_INFO,
    logo,
    techSupportNum,
    hasPurchasedCobranding,
    canCobrand,
    supportUrl,
    waitForLoad,
  }),
  setLogo: logo => ({
    type: SET_LOGO,
    logo,
  }),
  setTechSupportNumber: techSupportNum => ({
    type: SET_TECH_SUPPORT_NUMBER,
    techSupportNum,
  }),
  fetchLogoError: () => ({
    type: GET_LOGO_ERROR,
  }),
  fetchCobrandingPermission: () => ({
    type: GET_COBRAND_STATUS,
  }),
  fetchCobrandingPermissionsError: () => ({
    type: GET_COBRAND_STATUS_ERROR,
  }),
  setCobrandStatus: status => ({
    type: SET_COBRAND_STATUS,
    status,
  }),
  requestUploadLogo: logo => ({
    type: UPLOAD_LOGO,
    logo,
  }),
  uploadLogoError: () => ({
    type: UPLOAD_LOGO_ERROR,
  }),
  hasAccessToAddCobrandingSet: (
    canToggleCobrand,
    canTogglePurchase,
    canCobrand,
    purchasedCobrand,
  ) => ({
    type: HAS_ACCESS_TO_ADD_COBRANDING_SET,
    canToggleCobrand,
    canTogglePurchase,
    canCobrand,
    purchasedCobrand,
  }),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_LOGO:
      return {
        ...state,
        isLoadingLogo: true,
      };
    case SET_INFO:
      let logoToDisplay;

      if (
        // if an account with cobranding has not uploaded a logo, show nothing
        (action.logo === '' && action.canCobrand === true) ||
        (state.isLoadingLogo && action.waitForLoad)
      ) {
        logoToDisplay = '';
      } else {
        // if no logo found, show default logo
        logoToDisplay = action.logo === '' ? logoCrosslink : action.logo;
      }

      const techSupportNumber =
        action.techSupportNum === ''
          ? TECH_SUPPORT_PHONE_NUMBER
          : formatPhoneNumber(action.techSupportNum); // store number as formatted instead of formatting in each case it's needed

      return {
        ...state,
        logo: logoToDisplay,
        techSupportNumber: techSupportNumber,
        supportUrl: action.supportUrl,
        isLoadingLogo: false,
      };
    case SET_LOGO:
      return {
        ...state,
        logo: action.logo,
        isLoadingLogo: false,
      };
    case SET_TECH_SUPPORT_NUMBER:
      return {
        ...state,
        techSupportNumber: formatPhoneNumber(action.techSupportNum),
      };
    case GET_LOGO_ERROR:
      return {
        ...state,
        isLoadingLogo: false,
      };
    case GET_COBRAND_STATUS:
      return {
        ...state,
        isLoadingCobrandStatus: true,
      };
    case GET_COBRAND_STATUS_ERROR:
      return {
        ...state,
        isLoadingCobrandStatus: false,
      };
    case SET_COBRAND_STATUS:
      return {
        ...state,
        canCobrand: action.status.cobrandStatus,
        hasPurchasedCobranding: action.status.purchasedCobrandingStatus,
        isLoadingCobrandStatus: false,
      };
    case UPLOAD_LOGO_ERROR:
      return {
        ...state,
      };
    case Common.LOGOUT:
      return {
        ...state,
        logo: '',
        canCobrand: false,
        hasPurchasedCobranding: false,
        isLoadingCobrandStatus: false,
      };
    case HAS_ACCESS_TO_ADD_COBRANDING_SET:
      return {
        ...state,
        hasAccessToAddCobranding: action.canToggleCobrand,
        hasAccessToPurchaseCobranding: action.canTogglePurchase,
        canCobrand: action.canCobrand,
        hasPurchasedCobranding: action.purchasedCobrand,
        isLoadingCobrandStatus: false,
      };
  }
  return state;
}

import { SIDE_TABS, TITLE_TAB_NAMES } from '~/app/constants.js';
export const selectors = {
  getMobileReturnState: state => state.returnList.mobileInfo,
  getAvailableMobileReturns: state => {
    return state.returnList.mobileInfo.results.filter(ret => {
      return ret.newFlag !== 0;
    });
  },
  getAvailableMobileReturnCount: state => {
    const availableReturns = state.returnList.mobileInfo.results.filter(ret => {
      return ret.newFlag !== 0;
    });
    return availableReturns?.length;
  },
  getClickThroughReturnFilter: state => {
    const tabIDFound = state.app.tabState.tabMap[SIDE_TABS.DASHBOARD_MAIN].findIndex(tab => {
      return tab.name === TITLE_TAB_NAMES.TAX_RETURNS;
    });
    return state.app.tabState.tabMap[SIDE_TABS.DASHBOARD_MAIN][tabIDFound].filter === undefined
      ? []
      : state.app.tabState.tabMap[SIDE_TABS.DASHBOARD_MAIN][tabIDFound].filter;
  },
  getTaxReturnsListTitleTabID: state => {
    const tabIDFound = state.app.tabState.tabMap[SIDE_TABS.DASHBOARD_MAIN].findIndex(tab => {
      return tab.name === TITLE_TAB_NAMES.TAX_RETURNS;
    });
    return tabIDFound;
  },
};

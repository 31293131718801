// External imports
import React, { useEffect } from 'react';
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Input,
  InputLabel,
  NativeSelect,
  Toolbar,
  withStyles,
} from '@material-ui/core';
// Internal import
import { useSetState } from '~/app/Utility/customHooks.js';
import { buildFormattedName } from '~/app/Utility/general.js';
import WebHelpers from '~/app/webHelpers.js';
// Redux imports
import { useSelector } from 'react-redux';
// styling imports
import { styles } from '~/app/Pages/Returns/css/textLinkSendModal.js';

const rtfToHTML = require('@iarna/rtf-to-html');

const TextLinkSendModal = props => {
  const payload = WebHelpers.getJWTPayload();
  const { textLinkClientInfo } = useSelector(state => ({
    textLinkClientInfo: state.formViewer.textLinkClientInfo,
  }));
  const [state, setState] = useSetState({
    clients: [],
    selectedClientIdx: 0,
    selectedClient: '',
    selectedNumber: '',
    selectedAllowText: '',
    message: '',
    showNoSendDialog: false,
    dlgTitle: '',
    dlgMessage: '',
  });

  useEffect(() => {
    getClientDropdownContent();
    if (props.textLinkModalContent !== '') {
      // Default text body message comes from client letter setup and it only comes with one wrap of brackets "{}", we need to add another wrap of brackets "{{}}" for valid rtf format
      const textLinkContent = '{' + atob(props.textLinkModalContent) + '}';

      // Valid rtf format will start with "{{\rtf" and end with "}}"
      // If it is invalid, there will be no default text body, but they can still send a text.
      if (!textLinkContent.startsWith('{{\\rtf') || !textLinkContent.endsWith('}}')) {
        console.log(
          'Invalid rtf format. The default client letters Text Body will not be used, but you can still send a text message - please notify support: ',
          textLinkContent,
        );
      } else {
        rtfToHTML.fromString(textLinkContent, (err, html) => {
          if (err) {
            console.log('TextLinkSendModal - rtfToHTML - Error: ', err);
            return;
          }

          const el = document.createElement('html');
          el.innerHTML = html;

          // Convert body content to text
          const textBody = $(el.getElementsByTagName('body')).text().trim();

          setState({
            message: textBody,
          });
        });
      }
    }
  }, []);

  const getClientDropdownContent = () => {
    const clientList = [];
    let client = {};
    const firstName = textLinkClientInfo[0]?.firstName;
    const lastName = textLinkClientInfo[0]?.lastName;

    const name =
      payload?.is_business && textLinkClientInfo[0]?.businessName
        ? textLinkClientInfo[0]?.businessName
        : buildFormattedName(firstName, lastName);

    client.name = name;
    client.cellNumber = textLinkClientInfo[0].cellPhone;
    client.allowText = textLinkClientInfo[0].allowText;
    client.carrier = textLinkClientInfo[0].cellCarrier;
    client.carrierDomain = textLinkClientInfo[0].carrierDomain;

    if (client.carrier !== '') {
      clientList.push(client);
    }

    // individual spouse
    if (!payload?.is_business) {
      client = {};
      const firstName = textLinkClientInfo[1]?.firstName;
      const lastName = textLinkClientInfo[1]?.lastName;

      client.name = buildFormattedName(firstName, lastName);
      client.cellNumber = textLinkClientInfo[1].cellPhone;
      client.allowText = textLinkClientInfo[1].allowText;
      client.carrier = textLinkClientInfo[1].cellCarrier;
      client.carrierDomain = textLinkClientInfo[1].carrierDomain;

      if (client.carrier !== '') {
        clientList.push(client);
      }
    }

    if (clientList.length > 0) {
      setState({
        clients: clientList,
        selectedClient: clientList[0].name,
        selectedNumber: clientList[0].cellNumber,
        selectedAllowText: clientList[0].allowText,
      });
    }
  };

  const handleInputChange = e => {
    setState({ [e.target.name]: e.target.value });
  };

  const handleSendText = () => {
    if (state.message === '') {
      setState({
        showNoSendDialog: true,
        dlgTitle: 'Message Body Required',
        dlgMessage: 'A text message with an empty body cannot be sent. Please provide a message.',
      });
    } else if (state.selectedAllowText !== 'X') {
      setState({
        showNoSendDialog: true,
        dlgTitle: 'Unable To Send Text Message',
        dlgMessage: 'The client has not consented to receiving text messages.',
      });
    } else {
      props.handleSendTextLinkMessage(
        state.clients[state.selectedClientIdx].name,
        state.clients[state.selectedClientIdx].cellNumber.replace(/-/g, ''),
        state.clients[state.selectedClientIdx].carrier,
        state.message,
        state.clients[state.selectedClientIdx].carrierDomain,
      );
    }
  };

  const handleNameChange = name => {
    setState({
      selectedClient: name,
    });

    for (let i = 0; i < state.clients.length; i++) {
      if (state.clients[i].name === name) {
        setState({
          selectedNumber: state.clients[i].cellNumber,
          selectedAllowText: state.clients[i].allowText,
        });
      }
    }
  };

  const closeWarningDialog = () => {
    setState({ showNoSendDialog: false });
  };

  const clientNames = state.clients.map((client, i) => {
    return (
      <option id={'TextLinkModalClientName' + client.name} key={client.name} value={client.name}>
        {client.name}
      </option>
    );
  });

  return (
    <div>
      <AppBar position="static">
        <Toolbar style={styles.toolbar}>Send Text Message</Toolbar>
      </AppBar>
      <div style={{ margin: ' 2em' }}>
        <div style={{ float: 'left' }}>
          <FormControl fullWidth>
            <InputLabel style={styles.labelStyles} shrink>
              Recipient
            </InputLabel>
            <NativeSelect
              id="selectTextLinkRecipient"
              value={state.selectedClient}
              onChange={e => handleNameChange(e.target.value)}
              style={styles.inputStyle}
              disabled={payload.is_business}
              disableUnderline
            >
              {clientNames}
            </NativeSelect>
          </FormControl>
        </div>
        <br />
        <div style={{ float: 'left' }}>
          <FormControl fullWidth>
            <InputLabel style={styles.labelStyles} shrink>
              Mobile Phone
            </InputLabel>
            <Input
              id="txtTextLinkCellNumber"
              name="cellNumber"
              value={state.selectedNumber}
              style={styles.inputStyle}
              disabled
              inputProps={{
                maxLength: '25',
              }}
              disableUnderline
            />
          </FormControl>
        </div>
        <br />
        <div>
          <FormControl fullWidth>
            <InputLabel shrink>Message</InputLabel>
            <Input
              id="txtTextLinkMessagBody"
              name="message"
              value={state.message}
              placeholder="Please write your text message here..."
              multiline
              onChange={handleInputChange}
              style={{ height: '10em', width: '35vw' }}
              inputProps={{
                maxLength: '2500',
              }}
              rows={8}
              rowsMax={8}
              disableUnderline
            />
          </FormControl>
        </div>
        <div style={{ textAlign: 'right', marginRight: '2em', marginTop: '1em' }}>
          <Button
            id="btnCancelSendTextLink"
            color="primary"
            onClick={() => props.toggleTextLinkSendModal(false, '')}
          >
            Cancel
          </Button>
          <Button
            id="btnSendTextLink"
            color="primary"
            style={styles.button}
            onClick={handleSendText}
          >
            Send
          </Button>
        </div>
      </div>
      <Dialog open={state.showNoSendDialog} onClose={closeWarningDialog}>
        <DialogTitle>{state.dlgTitle}</DialogTitle>
        <DialogContent>{state.dlgMessage}</DialogContent>
        <DialogActions>
          <Button
            id="dlgTextLinkNoSendDialog"
            onClick={() => {
              setState({ showNoSendDialog: false });
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(TextLinkSendModal);

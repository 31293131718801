export const styles = {
  addReturnPaper: {
    width: '32rem',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    paddingBottom: '1rem',
  },
  addReturnModals:{
    position: 'static',
  }
};

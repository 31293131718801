export const styles = {
  auditConfigFormSubSectionItemCheckbox: {
    width: '20px',
    height: '20px',
    marginRight: '0.5vw',
  },
  auditConfigFormSubSectionItemInput: {
    width: '9vw',
    height: '3vh',
    marginRight: '0.5vw',
  },
  auditConfigFormSubSectionItemLabel: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    color: '#354052',
  },
  auditConfigFormButton: {
    width: '10vw',
    marginRight: '1vw',
  },
};

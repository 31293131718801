import React from 'react';
// Internal imports
import warningIcon from '~/images/icons/icons8-error.svg';
// External imports
import { Typography, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
// Styling imports
import { styles } from '~/app/Pages/WizardMode/Mapper/css/mapperStyles.js';
import '~/app/Pages/WizardMode/Mapper/css/mapperStyles.css';

/**
 * Handles 'BodyTextBold' from font property
 * used on most pages as important information displayed for the user
 *
 * @returns {JSX} WarningMsgMap UI
 */
const WarningMsgMap = props => {
  const { classes } = props;

  return (
    <Grid item xs={12}>
      <div className="grid-container-div-title-warning">
        <img id="WarningIcon" className="container-item-warning-icon" src={warningIcon} />
        <Typography className={classes.labelStyle}>{props.text}</Typography>
      </div>
    </Grid>
  );
};

export default withStyles(styles)(WarningMsgMap);

import { actions, FETCHED_OFFICE_LIST } from "./duck";

import { takeLatest, call, put } from "redux-saga/effects";

import XlinkAPI from "~/app/api/xlinkAPI";
import ErrorHelpers from "~/app/errorHelpers.js";

export function* watchFetchOfficeList() {
  yield takeLatest(FETCHED_OFFICE_LIST, fetchOfficeList);
}

export function* fetchOfficeList(payload) {
  try {
    yield put(actions.requestOfficeList());
    const res = yield call(XlinkAPI.getDashboardList, payload.filterParams);

    yield put(actions.requestOfficeListSuccess(res.data));
  } catch (error) {
    ErrorHelpers.handleError("Error fetching filtered offices", error);
    yield put(actions.requestOfficeListError());
  }
}

export const styles = theme => ({
  termsAndConditions: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30rem',
    height: '17rem',
  },
  acceptBtn: {
    padding: '0 3em',
    margin: '0 2.5rem',
  },
  logOutBtn: { border: '0rem' },
  tooltip: {
    textAlign: 'center',
    fontSize: '12px',
  },
  modalTitle: {
    paddingLeft: theme.spacing.unit * 3,
    color: 'white',
    backgroundColor: '#444A59',
    height: '70px',
    paddingTop: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 2,
  },
  modal: {
    top: '20%',
    left: '50%',
    transform: 'translate(-50%, -20%)',
    position: 'absolute',
    width: '90%',
    height: '95%',
    padding: '0',
    boxShadow: '0 0 20px 0 rgba(0,0,0,0.25)',
  },
  modalSpacing: {
    marginTop: '3.7rem',
  },
});

import React, { useEffect } from 'react';
// Internal imports
import { useSetState } from '~/app/Utility/customHooks';
import { statusOK } from '~/app/webHelpers.js';
import XlinkAPI from '~/app/api/xlinkAPI';
import ErrorHelpers from '~/app/errorHelpers.js';
import { getLoginID, formatCopyCheckboxesLabels } from './CopyOfficeHelper.js';

// External imports
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import {
  AppBar,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Input,
  NativeSelect,
  Paper,
  Toolbar,
  Typography,
  withStyles,
} from '@material-ui/core';
// Redux imports
import { actions as loginSetupActions } from '~/app/redux/loginSetup/duck';
import { actions as appActions } from '~/app/redux/modules/app';
import { useSelector, useDispatch } from 'react-redux';
import { parseInt } from 'lodash';
// Styling imports
import { styles } from '~/app/Pages/Setup/Modals/css/copyOfficeModal.js';

// Image imports
import warningIcon from '~/images/icons/warningicon.png';

/**
 * This Modal allows an office owner to copy settings from one office onto another.
 *
 * @category Setup
 * @component CopyOfficeModal
 */
const CopyOfficeModal = props => {
  const dispatch = useDispatch();
  const { classes } = props;
  const { currentView, drilldownHistory, officeId } = useSelector(state => ({
    currentView: state.drilldown.drilldownHistory[state.drilldown.drilldownHistory.length - 1],
    drilldownHistory: state.drilldown.drilldownHistory,
    officeId: state.officeProfile.office_id,
  }));

  // Used for Copy Over Settings and rendering elements (jsx)
  const copyCheckboxes = {
    AccessLevels: false,
    Audit: false,
    Billing: false,
    BusinessBilling: false,
    ClientLetters: false,
    Defaults: false,
    Efiling: false,
    Printing: false,
  };

  const [state, setState] = useSetState({
    accountList: [],
    form: {
      sourceOffice: '',
      copySettings: { ...copyCheckboxes },
    },
    processingRequest: false,
    isDisabled: false, // Block form from submitting
  });

  useEffect(() => {
    // Fetch offices
    getAccountList();
  }, []);

  // Enable/Disable confirmation button & Clean up
  useEffect(() => {
    const isChecked = Object.values(state.form.copySettings).some(setting => setting);
    const isSourceOfficeEmpty = state.form.sourceOffice === '';

    // Reset checkbox states back to false if no input selected (clean up)
    if (isSourceOfficeEmpty) {
      setState({
        form: { ...state.form, copySettings: { ...copyCheckboxes } },
        isDisabled: !isChecked || isSourceOfficeEmpty,
      });
    } else {
      setState({
        isDisabled: !isChecked || isSourceOfficeEmpty,
      });
    }
  }, [
    state.form.sourceOffice,
    state.form.copySettings.AccessLevels,
    state.form.copySettings.Audit,
    state.form.copySettings.Billing,
    state.form.copySettings.BusinessBilling,
    state.form.copySettings.ClientLetters,
    state.form.copySettings.Defaults,
    state.form.copySettings.Efiling,
    state.form.copySettings.Printing,
  ]);

  /** Fetches access roles using the edited users access_level and sets level of the list and selected role */
  const getAccountList = async () => {
    const filterParams = {
      loginID: getLoginID(drilldownHistory, currentView),
      officeLoginID: 0,
      filterID: 0,
      page: -1,
      perPage: -1,
      filterType: 'all',
      colName: 'company',
      sortOrder: 'asc',
      filterValue1: '',
      filterValue2: '',
      roleGroup: 'office',
    };
    try {
      const res = await XlinkAPI.getDashboardList(filterParams);

      if (statusOK(res, false)) {
        setState({
          accountList: res?.data?.list?.filter(office => office?.office_id !== officeId) || [],
        });
      }
    } catch (err) {
      ErrorHelpers.handleError('Failed to get account lists', err);
    }
  };

  /**
   * Handles changes to a checkbox input
   *
   * @param {event} event event dom from checkbox that the user edited
   */
  const handleCheckbox = e => {
    setState({
      form: {
        ...state.form,
        copySettings: { ...state.form.copySettings, [e.target.name]: e.target.checked },
      },
    });
  };

  /**
   * Handles input change and calling a input val helper
   *
   * @param {Object} e DOMevent used to retrieve the target value and name
   */
  const handleInputChangeValidation = e => {
    const value = e.target.value.toUpperCase();
    const name = e.target.name;

    // If EFIN is greater than 6 do nothing. If logic needs added to EFIN input, add else logic
    if (name === 'EFIN' && value.length > 6) {
      return null;
    }

    setState({
      form: { ...state.form, [name]: value },
    });
  };

  /**
   * Handles displaying a list of options UI for a dropdown
   *
   * @returns {JSX} jsx displays a list of options for a dropdown menu
   */
  const renderAccountDropdown = () => {
    // MenuItem Style
    const accListOptions = state.accountList.map((item, index) => {
      return (
        <option
          id={`cpyAccount ${item.name}-${index}`}
          key={`${item.name}-${index}`}
          value={item.office_id}
          className={classes.menuItemStyle}
        >
          {item.name}
        </option>
      );
    });
    return (
      <NativeSelect
        id="cpyFromAccountAddOffice"
        className={classes.copySelectDropdown}
        value={state.form.sourceOffice ? state.form.sourceOffice : ''}
        onChange={e => handleInputChangeValidation(e)}
        disabled={state.accountList.length === 0}
        input={
          <Input
            name="sourceOffice"
            id="account-selected"
            className={classes.validStateStyle}
            disableUnderline
          />
        }
      >
        {<option value="" className={classes.menuItemStyle} />}
        {accListOptions}
      </NativeSelect>
    );
  };

  /** Submits copy form to the api and displaying success/error dialog */
  const handleCopySave = async () => {
    setState({ processingRequest: true });
    // Creating login
    const req = {
      loginID: getLoginID(drilldownHistory, currentView),
      targetOffice: officeId,
      sourceOffice: parseInt(state.form.sourceOffice),
      copySettings: { ...state.form.copySettings },
    };

    try {
      const res = await XlinkAPI.copyOfficeSettings(req);

      if (statusOK(res, false)) {
        // Trigger refresh of settings page.
        setState({ isDisabled: true });
        dispatch(
          appActions.showSnackbarMessage(
            'Office Settings have been copied successfully',
            'success',
            3500,
            {
              vertical: 'top',
              horizontal: 'center',
            },
          ),
        );
      }
    } catch (error) {
      dispatch(
        appActions.showSnackbarMessage(
          'Issue copying office settings, please try again',
          'error',
          3500,
          {
            vertical: 'top',
            horizontal: 'center',
          },
        ),
      );
      ErrorHelpers.handleError('Failed to copy settings', error);
    }
    setState({ processingRequest: false });
    dispatch(loginSetupActions.onToggleCopyOfficeModal());
  };

  return (
    <Paper elevation={5} className={classes.copyOfficeModalContainer}>
      <AppBar position="static">
        <Toolbar className={classes.toolbarStyle}>
          <Typography className={classes.typographyStyle}>Copy Over Settings</Typography>
          <Typography
            className={classes.typographyStyle}
            onClick={() => dispatch(loginSetupActions.onToggleCopyOfficeModal())}
          >
            X
          </Typography>
        </Toolbar>
      </AppBar>
      <form className={classes.copyOfficeModal}>
        <div>Copy Settings From Office</div>
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <div className={classes.modalAlertBanner}>
              <div>
                <img src={warningIcon} className="filterIcon" />
                Copying over settings will erase all existing settings for the selected areas.
              </div>
            </div>
            <FormControl classes={{ root: classes.formControlRoot }}>
              <InputLabel shrink className={classes.labelWidthStyle} htmlFor="account-selected">
                From Office
              </InputLabel>
              {renderAccountDropdown()}
            </FormControl>

            <span className={classes.subTitleStyle}>Settings:</span>

            <div className={classes.copyCheckBoxContainer}>
              {Object.keys(copyCheckboxes).map((keyName, idx) => {
                if (keyName === 'BusinessBilling' && props.season < 2024) return null; // prevent business for prior years

                return (
                  <div key={idx}>
                    <FormControlLabel
                      label={formatCopyCheckboxesLabels(keyName)}
                      classes={{ root: classes.formControlRoot }}
                      control={
                        <Checkbox
                          color="primary"
                          className={`${classes.checkBoxStyle} ${classes.checkBoxPadding}`}
                          icon={
                            <CheckBoxOutlineBlankIcon
                              className={classes.checkboxOutline}
                              color="secondary"
                            />
                          }
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id={keyName}
                          name={keyName}
                          checked={state.form.copySettings[keyName]}
                          disabled={state.form.sourceOffice === ''}
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                );
              })}
            </div>
          </Grid>
        </Grid>
        <div style={styles.styleButtonsNew}>
          <span>
            <Button
              id="btnCancelAddNewLogin"
              color="primary"
              onClick={() => dispatch(loginSetupActions.onToggleCopyOfficeModal())}
              className={classes.cancelButton}
            >
              Cancel
            </Button>
            <Button
              id="btnAddAddNewLogin"
              color="primary"
              variant="contained"
              className={classes.saveButton}
              onClick={handleCopySave}
              disabled={state.isDisabled || state.processingRequest}
            >
              Save & Close
            </Button>
          </span>
        </div>
        <br />
      </form>
    </Paper>
  );
};

export default withStyles(styles)(CopyOfficeModal);

import React from 'react';
// Internal imports
import multiply from '~/images/icons/icons8-multiply.png';
// External imports
import { Button, Fade } from '@material-ui/core';
// Styling imports
import '~/app/Pages/WizardMode/components/Forms/css/formStyles.css';
import '~/app/Pages/WizardMode/components/Forms/css/modalStyles.css';

/**
 * Handles displaying the Misc Form Modal for wizard mode.
 *
 * @component InfoModal
 * @category WizardMode
 */
const InfoModal = props => {
  return (
    <Fade in={props.isInfoModalOpen} timeout={500}>
      <div>
        <div className="modal-header-bar">
          <div className="row modal-header-row">
            <div>Detailed Information</div>
            <img
              onClick={() => props.handleModal('isInfoModalOpen')}
              src={multiply}
              className="modal-multiply-img"
            />
          </div>
        </div>
        {/* Modal Body */}
        <div className="info-container-body">
          <div>
            <span className="container-body-question">Question: </span>
            {props.infoPrompt}
          </div>
          <div className="container-body-message">{props.infoMessage}</div>
        </div>
        <div className="container modal-bottom-container">
          <Button
            id="close-btn"
            color="primary"
            onClick={() => props.handleModal('isInfoModalOpen')}
            variant="contained"
          >
            Close
          </Button>
        </div>
      </div>
    </Fade>
  );
};

export default InfoModal;

/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-string-refs */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import loadTopaz from '~/app/loadtopaz.js';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  withStyles,
  Typography,
} from '@material-ui/core';
import { toBMPDataURL, sigTypes } from '~/app/bmpConversionHelpers.js';
import { SIGNEE_TOOLBAR_TITLE, DOWNLOAD_DRIVER_LINKS } from '~/app/constants.js';

const styles = theme => ({
  styledHeader: {
    background: '#444C59',
    border: 0,
    color: 'white',
    height: 64,
    padding: '0 30px',
    PaperProps: {
      style: {
        backgroundColor: '#fff',
        color: 'black',
      },
    },
  },
});

class TopazSignatureCapture extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sigStringData: '',
      sigRawData: '',
      disableSignButton: false,
      imgWidth: 0,
      imgHeight: 0,
      topazReady: false,
      sigImageData: '',
      imageLoaded: false,
      tabletConnected: 1,
      errorMessageModalOpened: false,
    };
  }

  componentDidMount() {
    // First thing we need to do in order for the component to work
    loadTopaz(() => {
      if (!IsSigWebInstalled()) {
        this.setState({ errorMessageModalOpened: true });
      } else {
        // Work to do after the library loads.
        this.setState({ topazReady: true });
      }
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
    // Clear the tablet here once we are ready to unmount the component
    this.closingSigWeb();
  }

  closeErrorMessageModal = () => {
    this.setState({ errorMessageModalOpened: false });
  };

  // Close SigWeb
  closingSigWeb = () => {
    const tmr = null;
    // Close and clear the modal
    if (this.state.topazReady) {
      ClearTablet();
      SetTabletState(0, tmr);
    }
  };

  handleClearTablet = () => {
    if (this.state.topazReady) {
      ClearTablet();
    }
  };

  // Handle sign
  onSign = () => {
    try {
      GetTabletState();
      this.driversOK();
    } catch (err) {
      // Show modal stating that the drivers are mising, we will only enter this section when the drivers are not installed
      this.setState({ errorMessageModalOpened: true });
    }
  };

  driversOK = () => {
    let tmr;
    // eslint-disable-next-line react/no-string-refs
    const canvasObj = this.refs.cnv;
    const ctx = canvasObj.getContext('2d');

    SetDisplayXSize(475);
    SetDisplayYSize(96);
    SetTabletState(0, tmr);
    SetJustifyMode(0);
    this.handleClearTablet();

    if (tmr == null) {
      tmr = SetTabletState(1, ctx, 50);
    } else {
      SetTabletState(0, tmr);
      tmr = null;
      tmr = SetTabletState(1, ctx, 50);
    }
  };

  Cancel = e => {
    e.preventDefault();
    // Close and clear the modal
    this.handleClearTablet();
    this.props.handleClose();
  };

  clearSignature = e => {
    e.preventDefault();
    this.handleClearTablet();
  };

  onDone = () => {
    let tmr;
    // Check if signature is not blank
    // If the total number of point is less than 100, then we have a blank signature
    const numOfSignaturePoints = NumberOfTabletPoints();
    if (numOfSignaturePoints < 100) {
      alert('Please sign before continuing');
      // Clear the Topaz Signature Pad
      this.handleClearTablet();
    } else {
      SetTabletState(0, tmr);

      // RETURN TOPAZ-FORMAT SIGSTRING
      SetSigCompressionMode(1);
      const sigString = this.state.sigStringData + GetSigString();

      this.setState({
        sigStringData: sigString,
      });

      // RETURN BMP BYTE ARRAY CONVERTED TO BASE64 STRING
      SetImageXSize(475);
      SetImageYSize(96);
      SetImagePenWidth(1);
      GetSigImageB64(this.SigImageCallback);
    }
  };

  SigImageCallback = str => {
    this.setState({
      sigImageData: str,
    });
    const canvas = this.refs.cnv;
    const topaz = 3;
    if (this.props.convertString === false) {
      // We send back the signature data back
      // PNG is the default
      const pngUrl = canvas.toDataURL();
      this.props.handleSignatureCapture(sigTypes[this.props.currentSig], pngUrl, topaz);
    } else {
      const padType = 'topaz';
      let sigString = toBMPDataURL(canvas, padType);
      sigString = sigString.replace('data:image/bmp;base64,', ''); // strip url src info
      // Close and clear the modal
      // Pass the signatures to calc server
      this.props.handleSignatureCapture(sigTypes[this.props.currentSig], sigString, topaz);
    }
  };

  render() {
    const toolbarColor = { backgroundColor: '#444A59' };
    // 1=>TP, 2=>SP, 3=>PP, 4=>ERO, 5=>TP initial, 6=>SP initial, 8=>TP 2nd, 9=>SP 2nd, A=>PP if amended
    let toolbarTitle = '';
    switch (this.props.currentSig) {
      case '1':
        toolbarTitle = SIGNEE_TOOLBAR_TITLE.TAXPAYER;
        break;
      case '2':
        toolbarTitle = SIGNEE_TOOLBAR_TITLE.SPOUSE;
        break;
      case '3':
        toolbarTitle = SIGNEE_TOOLBAR_TITLE.PREPARER;
        break;
      case '4':
        toolbarTitle = SIGNEE_TOOLBAR_TITLE.ERO;
        break;
      case '5':
        toolbarTitle = SIGNEE_TOOLBAR_TITLE.TAXPAYER_INITIAL;
        break;
      case '6':
        toolbarTitle = SIGNEE_TOOLBAR_TITLE.SPOUSE_INITIAL;
        break;
      case '8':
        toolbarTitle = SIGNEE_TOOLBAR_TITLE.TAXPAYER_SECOND_SIGNATURE;
        break;
      case '9':
        toolbarTitle = SIGNEE_TOOLBAR_TITLE.SPOUSE_SECOND_SIGNATURE;
        break;
      case 'A':
        toolbarTitle = SIGNEE_TOOLBAR_TITLE.AMENDED_PAID_PREPARER_SIGNATURE;
        break;
      default:
        toolbarTitle = SIGNEE_TOOLBAR_TITLE.DEFAULT;
        break;
    }

    return (
      <div className="container-fluid">
        <Paper
          style={{
            minHeight: '350px',
            width: '570px',
            norderRadius: '4px',
            margin: 'auto',
            position: 'relative',
          }}
        >
          <AppBar position="static">
            <Toolbar style={toolbarColor}>
              <span style={{ fontFamily: 'Roboto', fontSize: '18px' }}>{toolbarTitle}</span>
            </Toolbar>
          </AppBar>
          <form style={{ marginLeft: '2vw', marginRight: '3vw', marginTop: '1vh' }}>
            <div style={{ marginTop: '3vh' }}>
              <div>
                <p style={{ fontSize: '15px', textAlign: 'justify' }}>
                  Please press the "Ready to Sign" button below and the sign using your Topaz Pad.
                  Click "OK" once you are done signing.
                </p>
              </div>
              <table border="1" cellPadding="0" width="500">
                <tbody>
                  <tr>
                    <td height="150" width="500">
                      <canvas id="cnv" name="cnv" ref="cnv" width="480" height="100" />
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
            </div>
            <div
              style={{
                textAlign: 'center',
                padding: '0',
                marginLeft: '0vw',
                marginTop: '0.5vh',
              }}
            >
              <Button
                id="btnAcceptSignDoc"
                onClick={this.onSign}
                style={{
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  minWidth: '0px',
                  minHeight: '3.5vh',
                  textTransform: 'capitalize',
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  borderRadius: '4px',
                  marginRight: '1.5vw',
                }}
              >
                Ready to Sign
              </Button>
              <Button
                id="btnAcceptSignDoc"
                onClick={this.onDone}
                style={{
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  minWidth: '0px',
                  minHeight: '3.5vh',
                  textTransform: 'capitalize',
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  borderRadius: '4px',
                  marginRight: '1.5vw',
                }}
              >
                OK
              </Button>
              <Button
                id="btnClearSignDoc"
                onClick={this.clearSignature}
                style={{
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  minWidth: '0px',
                  minHeight: '3.5vh',
                  textTransform: 'capitalize',
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  borderRadius: '4px',
                  marginRight: '1.5vw',
                }}
              >
                Clear
              </Button>
              <Button
                id="btnCancelSignDoc"
                style={{
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  minWidth: '0px',
                  minHeight: '3.5vh',
                  textTransform: 'capitalize',
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  borderRadius: '4px',
                }}
                onClick={this.Cancel}
              >
                Cancel
              </Button>
            </div>
            <br />
          </form>
        </Paper>
        <Dialog
          open={this.state.errorMessageModalOpened}
          onClose={() => this.closeErrorMessageModal()}
          aria-labelledby="confirm-topaz-driver-title"
          aria-describedby="confirm-topaz-driver-desc"
        >
          <DialogTitle id="confirm-topaz-driver-title" className={this.props.classes.styledHeader}>
            <Typography
              style={{
                color: 'white',
                fontFamily: 'Roboto',
                fontSize: '18px',
                marginTop: '23px',
              }}
            >
              {'Install Topaz Signature Pad Driver'}
            </Typography>
          </DialogTitle>
          <DialogContent id="confirm-topaz-driver-desc">
            <p
              style={{
                marginTop: '3vh',
                marginBottom: '1vh',
                fontSize: '15px',
                fontFamily: 'Roboto',
                textAlign: 'justify',
              }}
            >
              The Topaz Signature Pad driver is not installed. Please disconnect your Topaz
              signature pad and click <a href={DOWNLOAD_DRIVER_LINKS.TOPAZ}>here</a> to install the
              driver.
            </p>
            <p>
              Once the driver is installed, please close your browser and reconnect your Topaz
              signature pad.
            </p>
          </DialogContent>
          <DialogActions>
            <Button
              id="btnHideInstallTopazDriverModal"
              style={{ marginRight: '1vw', marginBottom: '1vh' }}
              onClick={() => this.closeErrorMessageModal()}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(TopazSignatureCapture);

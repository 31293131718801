import React, { Fragment, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
// External imports
import { Button, FormControl, Input, InputLabel, Paper, Typography } from '@material-ui/core';
// Internal imports
import { useSetState } from '~/app/Utility/customHooks';
import AuthAPI from '~/app/api/authAPI.js';
import ErrorHelpers from '~/app/errorHelpers.js';
import QRModal from '../../Components/Auth/QRModal.jsx';
import Spinner from '#/Common/Spinner.jsx';
import GenericPasswordField from '#/Common/GenericPasswordField.jsx';
import { SPINNER_DIALOGS } from '~/app/constants.js';
import SimpleDialog from '#/Common/SimpleDialog.jsx';
import { displayLogo, formatUsername } from '~/app/Utility/general';
import { statusOK } from '~/app/webHelpers.js';
// Redux imports
import { useDispatch, useSelector } from 'react-redux';
import { actions as appActions } from '~/app/redux/modules/app';
// Styling imports
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import appTheme from '~/themes/GenericTheme.jsx';
import { styles } from '~/app/Pages/ForgotPassword/css/forgotPassword.js';
import '~/app/Pages/ForgotPassword/css/forgotPassword.css';

/**
 * Component that allows a user to reset their login password by first entering in a
 * secure code that is emailed to them and then they are allowed to change their password.
 *
 * @component
 * @category ForgotPassword
 */
const ForgotPassword = props => {
  const dispatch = useDispatch();
  const { classes } = props;

  const { alert } = useSelector(state => ({
    alert: state.app.alert,
  }));

  const [state, setState] = useSetState({
    username: '',
    usernameError: true,
    code: '',
    codeError: true,
    newPassword: '',
    passwordMeetsRequirements: false,
    retypeMeetsRequirements: false,
    reTypedPassword: '',
    showEmailSentModal: false,
    qrmodalopen: false,
    qrcode: '',
  });

  useEffect(() => {
    const username = localStorage.getItem('username');

    username && setState({ username: formatUsername(username), usernameError: false });
  }, []);

  /**
   * Handles sending the secure code and the new password for the login.
   * If the password is successfully changed then it will prompt a modal to
   * show the new 2 factor authenticaion QR code.
   *
   * @param {Object} e - event which takes place in the DOM.
   */
  const handleSubmit = async e => {
    e.preventDefault();

    setState({
      resettingPassword: true,
    });

    try {
      const res = await AuthAPI.resetPassword(
        state.username.toLowerCase(),
        state.code,
        state.newPassword,
      );

      if (statusOK(res)) {
        setState({
          qrcode: res.data.qrcode,
          qrmodalopen: true,
          resettingPassword: false,
        });
      }
    } catch (err) {
      setState({
        resettingPassword: false,
      });
      ErrorHelpers.handleError('Password reset error', err);
    }
  };

  /** Hides the QR modal and then sends the user back to the login page. */
  const handleSubmitPasswordReset = () => {
    setState({
      qrmodalopen: false,
    });
    dispatch(appActions.onLogout());
  };

  /** Sends a secure code to the username's email */
  const onSendCodeToEmail = async () => {
    try {
      const res = await AuthAPI.sendCodeToEmail(state.username.toLowerCase());
      const { emailVerifyCode } = res.data;

      if (statusOK(res) && emailVerifyCode) {
        window.location.href = '/activate/' + emailVerifyCode;
      } else {
        setState({
          showEmailSentModal: true,
        });
      }
    } catch (err) {
      ErrorHelpers.handleError('Password Reset', err);
    }
  };

  /**
   * Set the password values for the new password entered.
   *
   * @param {string} password - The new password.
   * @param {boolean} passwordMeetsRequirements - The value showing if the new password meets the password requirements.
   */
  const setPasswordState = (password, passwordMeetsRequirements) => {
    setState({
      newPassword: password,
      passwordMeetsRequirements: passwordMeetsRequirements,
    });
  };

  /**
   * Set the password values for the Re-Typed password entered.
   *
   * @param {string} password - The Re-Typed password.
   * @param {boolean} passwordMeetsRequirements - The value showing if the Re-Typed password meets the password requirements.
   */
  const setRetypeState = (password, passwordMeetsRequirements) => {
    setState({
      reTypedPassword: password,
      retypeMeetsRequirements: passwordMeetsRequirements,
    });
  };

  /** Hides the email send confirmation modal. */
  const hideEmailSentModal = () => {
    setState({
      showEmailSentModal: false,
    });
  };

  /**
   * Handles changing the state of the input field and also checks if the value is not empty
   * when field value is required (the border of the input field is red).
   *
   * @param {Object} e - event which takes place in the DOM.
   */
  const handleInputChange = e => {
    const fieldErrorFlag = !(e.target.value.length > 0);
    const name = e.target.name;
    const value = name === 'username' ? formatUsername(e.target.value) : e.target.value;

    setState({
      [name]: value,
      [name + 'Error']: fieldErrorFlag,
    });
  };

  /**
   * Handles disabling the submit button when there is no code present and when the password doesn't
   * meet the neccessary requirements.
   */
  const handleDisablingSubmit = () =>
    !(
      state.code &&
      state.code !== '' &&
      state.passwordMeetsRequirements &&
      state.retypeMeetsRequirements
    );

  return (
    <MuiThemeProvider theme={appTheme}>
      <div className="container-fluid" id="mfaEnterCodeContainer">
        <Paper className={classes.paper}>
          <div className="container forgot-password-content-block">
            <img src={displayLogo()} className="forgot-password-logo" />
            <div className="container">
              <form className="forgotUserPassForm" onSubmit={handleSubmit}>
                <Typography>
                  Enter your username below and a code will be sent to your email.
                </Typography>
                <div className="forgot-password-username-block">
                  <FormControl>
                    <InputLabel htmlFor="txtUsernameFP" shrink>
                      Username
                    </InputLabel>
                    <Input
                      id="txtUsernameFP"
                      name="username"
                      value={state.username}
                      onChange={handleInputChange}
                      className={classes.usernameInput}
                      error={state.usernameError}
                      disableUnderline
                      autoComplete="off"
                    />
                  </FormControl>
                  <Button
                    id="btnSendCodeFP"
                    variant="contained"
                    color="primary"
                    className={classes.sendCodeBtn}
                    onClick={onSendCodeToEmail}
                    disabled={state.username.length < 1}
                  >
                    Send Code
                  </Button>
                </div>
                <br />
                <FormControl fullWidth>
                  <InputLabel htmlFor="txtCodeFP" shrink>
                    Enter Code
                  </InputLabel>
                  <Input
                    id="txtCodeFP"
                    name="code"
                    value={state.code}
                    onChange={handleInputChange}
                    autoComplete="off"
                    error={state.codeError}
                    disableUnderline
                  />
                </FormControl>
                <GenericPasswordField
                  label="New Password"
                  id="txtPasswordFP"
                  name="password"
                  type="password"
                  verticalOffset={1.5}
                  handlePasswordCheck={(password, passwordMeetsRequirements) =>
                    setPasswordState(password, passwordMeetsRequirements)
                  }
                />
                <GenericPasswordField
                  label="Re-Type Password"
                  id="txtRetypePasswordFP"
                  firstvalue={state.newPassword}
                  retype
                  verticalOffset={-2}
                  handlePasswordCheck={(password, passwordMeetsRequirements) =>
                    setRetypeState(password, passwordMeetsRequirements)
                  }
                />
                <FormControl fullWidth>
                  <div id="loginButtonDivFP" className="loginButton">
                    <Link to="/">
                      <Typography
                        id="typBackToLogInFP"
                        className={classes.backToLoginTypography}
                        color="primary"
                      >
                        Back to Log In
                      </Typography>
                    </Link>
                    <Button
                      id="btnSubmitFP"
                      variant="contained"
                      type="submit"
                      color="primary"
                      className={classes.submitBtn}
                      disabled={handleDisablingSubmit()}
                    >
                      Submit
                    </Button>
                  </div>
                </FormControl>
              </form>
            </div>
          </div>
        </Paper>
        <SimpleDialog
          open={state.showEmailSentModal}
          onConfirm={hideEmailSentModal}
          dialogTitle="Email Sent"
          contentText="If the information you provided is correct, an email will be sent to you shortly."
          confirmText="Done"
          styled
          errorMessage
        />
        <QRModal
          closeQRModal={handleSubmitPasswordReset}
          qrcode={state.qrcode}
          openQRCodeModal={state.qrmodalopen}
        />
        {state.resettingPassword ? (
          <div className={classes.spinner}>
            <Spinner
              size={125}
              loadingText={SPINNER_DIALOGS.PROCESS_REQUEST}
              textColor="white"
              bgColor="grey"
            />
          </div>
        ) : (
          <Fragment />
        )}
      </div>
      <SimpleDialog
        open={alert.show}
        onConfirm={() => dispatch(appActions.hideError())}
        dialogTitle={alert.title}
        contentText={alert.body}
        contentTextTwo={alert.bodyTwo}
        confirmText="OK"
        styled
        errorMessage
      />
    </MuiThemeProvider>
  );
};

export default withRouter(withStyles(styles)(ForgotPassword));

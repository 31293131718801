// Internal imports
import XlinkAPI from '~/app/api/xlinkAPI';
import { toBMPDataURL } from '~/app/bmpConversionHelpers.js';
import { SIGNEE, SIGNATURE_PAD_TYPES, SIGNEE_TYPE, SIGNATURE_CATEGORY } from '~/app/constants.js';
import { statusOK } from '~/app/webHelpers.js';
import ErrorHelpers from '~/app/errorHelpers.js';
// Redux imports
import { store } from '~/app/redux/index';
import { actions as formViewerActions } from '~/app/redux/modules/formViewer';

/**
 * @module SignaturesHelper
 * @category Returns
 * @subcategory Signatures
 */

const SIGNATURES = {
  ERO: 'ero_signature',
  PAID_PREPARER: 'preparer_signature',
  AMENDED_PAID_PREPARER: 'preparer_signature',
};

const DEVICE_USED = {
  ERO: 'ero_device_used',
  PAID_PREPARER: 'preparer_device_used',
  AMENDED_PAID_PREPARER: 'preparer_device_used',
};

/**
 * Finds the index of an object in an array.
 *
 * @function
 * @param {Object[]} initialArray The array of objects.
 * @param {string} signeeType The type of signee.
 */
export const findIndex = (initialArray, signeeType) => {
  const index = initialArray.findIndex(item => item.signee === signeeType);

  return index;
};

/**
 * Checks to see which signatures and/or signature requsts are needing to be collected.
 *
 * @function
 * @param {Object} signaturesNeeded The signatures needed on the documents.
 * @param {Object} signaturesCollected The signees with either a signature in the DB or
 * a request for them to sign.
 */
export const remainingSignaturesNeeded = (signaturesNeeded, signaturesCollected) => {
  const diff = Object.keys(signaturesNeeded).reduce((diff, key) => {
    if (signaturesCollected[key] === signaturesNeeded[key]) return diff;
    return {
      ...diff,
      [key]: signaturesNeeded[key],
    };
  }, {});
  return diff;
};

/**
 * Sends a remote signature request via email or text message to the preparer.
 *
 * @param {number} sigDocID The signature document ID from tblTaxReturn_Signature_Documents.
 * @param {number} returnID The return ID of the tax return.
 * @param {Object} signatureRequestData The contact informatiuon of the preparer.
 */
const postPreparerRemoteSignatureAuthenticationData = async (
  sigDocID,
  returnID,
  signatureRequestData,
) => {
  const {
    signeeType,
    contactData: {
      preparer_email: email,
      preparer_cell: cell,
      preparer_carrier: carrier,
      preparer_carrier_domain: carrierDomain,
    },
    contactMethod,
    newEmail,
    cellPhoneCarrier,
  } = signatureRequestData;

  let remoteSignatureOption = '';
  const isInputMethod = contactMethod === 'input';
  const amendedSigneeType = SIGNATURE_CATEGORY.AMENDED_PREPARER;

  remoteSignatureOption = handleRemoteSignatureOption(contactMethod);

  if (isInputMethod) remoteSignatureOption = handleRemoteSignatureOption(newEmail);

  try {
    await XlinkAPI.postRemoteSignatureAuthenticationData(
      sigDocID,
      isInputMethod ? newEmail : email,
      isInputMethod ? newEmail.replace(/[- )(]/g, '') : cell,
      isInputMethod ? cellPhoneCarrier : carrier,
      isInputMethod ? '' : carrierDomain,
      signeeType === SIGNEE_TYPE.AMENDED_PAID_PREPARER_SIGNATURE ? amendedSigneeType : signeeType,
      parseInt(returnID),
      remoteSignatureOption,
      '',
    );
  } catch (e) {
    ErrorHelpers.handleError('Signature Request Error', e);
  }
};

/**
 * Sends a remote signature request via email or text message to the taxpayer or spouse.
 *
 * @param {number} sigDocID The signature document ID from tblTaxReturn_Signature_Documents.
 * @param {number} returnID The return ID of the tax return.
 * @param {Object} signatureRequestData The contact information of the taxpayer or spouse.
 */
const postRemoteSignatureAuthenticationData = async (
  sigDocID,
  returnID,
  signatureRequestData,
  prepRMSEmail,
) => {
  const {
    email,
    cellPhone,
    cellCarrier,
    cellDomain,
    signeeType,
    contactMethod,
    newEmail,
    cellPhoneCarrier,
  } = signatureRequestData;
  let remoteSignatureOption = '';
  const isInputMethod = contactMethod === 'input';

  remoteSignatureOption = handleRemoteSignatureOption(contactMethod);

  if (isInputMethod) remoteSignatureOption = handleRemoteSignatureOption(newEmail);

  try {
    await XlinkAPI.postRemoteSignatureAuthenticationData(
      sigDocID,
      isInputMethod ? newEmail : email,
      isInputMethod ? newEmail.replace(/[- )(]/g, '') : cellPhone,
      isInputMethod ? cellPhoneCarrier : cellCarrier,
      isInputMethod ? '' : cellDomain,
      signeeType,
      parseInt(returnID),
      remoteSignatureOption,
      prepRMSEmail,
    );
  } catch (e) {
    ErrorHelpers.handleError('Signature Request Error', e);
  }
};

/**
 * Determines the function to be called for signature request.
 *
 * @function
 * @param {Object} signatureRequestData The contact information of the taxpayer or spouse.
 * @param {function} handleSigningRemotely Callback function to handle signing a remote signature.
 * @param {string} documentType The type of document being signed.
 * @param {function} handleSigningInOffice Callback function to handle signing in the office.
 * @param {number} sigDocID used to filter for the specific signature document
 */
export const handleSignatureRequestOption = (
  signatureRequestData,
  handleSigningRemotely,
  documentType,
  handleSigningInOffice,
  sigDocID,
) => {
  const hasRemoteSignatureRequest = signatureRequestData.some(
    el => el.signingMethod === 'Remote Signature',
  );

  if (hasRemoteSignatureRequest) {
    handleSigningRemotely(documentType);
  } else {
    // If signing a pending remote request signature IN office
    handleSigningInOffice(sigDocID, 0, documentType);
  }
};

/**
 * Sends remote signature requests as well as handles if in-office signing is chosen along with the remote signature request.
 *
 * @function
 * @param {number} returnID The return ID of the tax return.
 * @param {string} requestedSignatures The signees of the document.
 * @param {string} pdfBlob The PDF Blob of the current document needing signed.
 * @param {string} printGUID The print GUID of the current tax return.
 * @param {string} messageBlob The message blob of the tax return.
 * @param {object} signatureRequestData The data of the signees and their signing methods.
 * @param {object} eroData The contact and signature data of the ERO.
 * @param {object} preparerData The contact and signature data of the Preparer.
 * @param {object} amendedPreparerData  The contact and signature data of the Amended Preparer.
 * @param {function} handleSigningInOffice Callback function to handle in-office signature.
 * @param {string} bankID The ID of the bank attached to the return.
 * @param {nunber} season The tax season of the request.
 * @param {function} stopSpin Callback function to stop the spinner.
 */
export const sendSignatureRequest = async (
  returnID,
  requestedSignatures,
  pdfBlob,
  printGUID,
  messageBlob,
  signatureRequestData,
  eroData,
  preparerData,
  amendedPreparerData,
  handleSigningInOffice,
  bankID,
  signatureDocID,
  season,
  stopSpin,
) => {
  try {
    const sendRMSCopyToPrep = store.getState().formViewer.sendRMSCopyToPrep;
    let prepRMSEmail = '';

    if (sendRMSCopyToPrep) {
      prepRMSEmail = store.getState().remoteSign.preparerData.contactData.preparer_email;
    }

    const res = await XlinkAPI.postRemoteSignatureRecord(
      returnID,
      requestedSignatures,
      pdfBlob,
      printGUID,
      messageBlob,
      bankID,
      signatureDocID,
      season,
    );

    if (statusOK(res)) {
      const sigDocID = res.data;
      let isPreparerSignaturePresent = true;
      let isAmendedPreparerSignaturePresent = true;
      const inOfficeSignatures = [];

      signatureRequestData.forEach(data => {
        // Value only changes once, used in postExistingSignatureData.
        data.signee === SIGNEE.PREPARER && (isPreparerSignaturePresent = false);
        data.signee === SIGNEE.AMENDED_PREPARER && (isAmendedPreparerSignaturePresent = false);

        if (data.signingMethod === 'Remote Signature') {
          // Check if signee type prep
          if ([SIGNEE.PREPARER, SIGNEE.AMENDED_PREPARER].includes(data.signee)) {
            postPreparerRemoteSignatureAuthenticationData(sigDocID, returnID, data, '', true);
          } else {
            // Spouse / Taxpayer
            postRemoteSignatureAuthenticationData(sigDocID, returnID, data, prepRMSEmail);
          }
        } else {
          inOfficeSignatures.push(data.signeeType);

          // If we are taxpayer signing in office and we need to collect secondary, add to list.
          if (
            data.signeeType === SIGNEE_TYPE.TAXPAYER &&
            requestedSignatures.includes(SIGNEE_TYPE.TAXPAYER_SECOND_SIGNATURE)
          ) {
            inOfficeSignatures.push(SIGNEE_TYPE.TAXPAYER_SECOND_SIGNATURE);
          }

          // If we are spouse signing in office and we need to collect secondary, add to list.
          if (
            data.signeeType === SIGNEE_TYPE.SPOUSE &&
            requestedSignatures.includes(SIGNEE_TYPE.SPOUSE_SECOND_SIGNATURE)
          ) {
            inOfficeSignatures.push(SIGNEE_TYPE.SPOUSE_SECOND_SIGNATURE);
          }
        }
      });

      await postExistingSignatureData(
        sigDocID,
        eroData,
        SIGNEE_TYPE.ERO,
        SIGNATURES.ERO,
        DEVICE_USED.ERO,
      );
      requestedSignatures.includes(SIGNEE_TYPE.PREPARER) &&
        isPreparerSignaturePresent &&
        (await postExistingSignatureData(
          sigDocID,
          preparerData,
          SIGNEE_TYPE.PREPARER,
          SIGNATURES.PAID_PREPARER,
          DEVICE_USED.PAID_PREPARER,
        ));
      requestedSignatures.includes(SIGNEE_TYPE.AMENDED_PAID_PREPARER_SIGNATURE) &&
        isAmendedPreparerSignaturePresent &&
        (await postExistingSignatureData(
          sigDocID,
          amendedPreparerData,
          SIGNEE_TYPE.AMENDED_PAID_PREPARER_SIGNATURE,
          SIGNATURES.AMENDED_PAID_PREPARER,
          DEVICE_USED.AMENDED_PAID_PREPARER,
        ));

      const error = store.getState().formViewer.errorDialogOpen;

      if (inOfficeSignatures.length) {
        if (!error) {
          stopSpin();
          handleSigningInOffice(sigDocID, inOfficeSignatures.join(''), '');
        }
      } else {
        !error && store.dispatch(formViewerActions.remoteSignSuccessfulSet(true));
      }
      stopSpin();
    }
  } catch (e) {
    stopSpin();
    ErrorHelpers.handleError('Signature Request Error', e);
  }
};

/**
 * Get the electronic signature for the ERO and Preparer as well as their contact information.
 *
 * @function
 * @param {string} efinNumber The EFIN number of the office.
 * @param {string} preparerID The preparer ID associated on the 1040.
 * @param {string} amendedPreparerID The amended preparer ID on the 1040-X.
 * @param {number} returnID The return ID of the tax return.
 */
export const getRemoteData = async (efinNumber, preparerID, amendedPreparerID, returnID) => {
  const getRemoteSignAuthData = await Promise.all([
    efinNumber && XlinkAPI.getefinDataRemoteSignAuth(efinNumber),
    preparerID && XlinkAPI.getppDataRemoteSignAuth(preparerID),
    amendedPreparerID && XlinkAPI.getppDataRemoteSignAuth(amendedPreparerID),
    efinNumber && XlinkAPI.getefinSignature(efinNumber),
    preparerID && XlinkAPI.getppSignature(preparerID, returnID),
    amendedPreparerID && XlinkAPI.getppSignature(amendedPreparerID, returnID),
  ]).catch(() => {
    store.dispatch(formViewerActions.showErrorDialog('Unable to fetch signature data'));
  });

  return getRemoteSignAuthData;
};

/**
 * Handles the signature drawn on the canvas.
 *
 * @function
 * @param {string} data The base64 signature string.
 * @param {object} ctx The context.
 */
const saveCanvasImage = (data, ctx) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => resolve(ctx.drawImage(image, 0, 0));
    image.onerror = reject;
    image.src = data;
  });
};

/**
 * Sends the existing Preparer and/or ERO signature to go along with the remote signature request.
 *
 * @function
 * @param {number} sigDocID The Signature Document ID which is the primary key in tblTaxReturn_Signature_Documents.
 * @param {Object} data The signature data of the preparer or ERO.
 * @param {string} signatureType The type of signature whether it is ERO, preparer, or amended preparer.
 * @param {string} signature The type of signature whether it is ERO, preparer, or amended preparer.
 * @param {string} deviceUsed The type of device whether it is ERO, preparer, or amended preparer.
 */
const postExistingSignatureData = async (sigDocID, data, signatureType, signature, deviceUsed) => {
  // Convert signature data fetched from db and convert it to a 2d canvas
  const canvas = document.querySelector('canvas');
  const ctx = canvas.getContext('2d');
  let padType = '';

  if (
    data.signatureData[deviceUsed] === SIGNATURE_PAD_TYPES.ON_SCREEN ||
    data.signatureData[deviceUsed] === SIGNATURE_PAD_TYPES.SCRIPTEL
  ) {
    padType = 'screen';
  } else {
    padType = 'topaz';
  }
  // Create a 2D canvas from signature image
  await saveCanvasImage(data.signatureData[signature], ctx);
  // Convert image to a bmp
  const sigString = toBMPDataURL(canvas, padType).replace('data:image/bmp;base64,', '');

  try {
    await XlinkAPI.postPreparerOrEroExistingRemoteSignature(sigDocID, sigString, signatureType);
  } catch (e) {
    ErrorHelpers.handleError('Signature Request Error', e);
  }

  // Clearing the canvas
  ctx.clearRect(0, 0, canvas.width, canvas.height);
};

/**
 * Takes the contact method and checks if option is email or cell.
 *
 * @function
 * @param {string} val The contact method.
 * @returns {string} email/cell
 */
const handleRemoteSignatureOption = val => {
  if (val?.includes('@')) return 'email';
  else return 'cell';
};

// Create new account duck module
import { ACCOUNT_TYPES } from '~/app/constants.js';

const SET_CREATION_TYPE = 'xlinkonline/accountCreation/SETCREATIONTYPE';
const SET_CREATION_STEP = 'xlinkonline/accountCreation/SETCREATIONSTEP';
const INCREMENT_CREATION_STEP = 'xlinkonline/accountCreation/INCREMENTCREATIONSTEP';
const DECREMENT_CREATION_STEP = 'xlinkonline/accountCreation/DECREMENTCREATIONSTEP';
const RESET_CREATION = 'xlinkonline/accountCreation/RESETCREATION';
const SET_BACK_FLAG = 'xlinkonline/accountCreation/SETBACKFLAG';
const RESET_TO_INITIAL = 'xlinkonline/accountCreation/RESETTOINITIAL';

const initialState = {
  step: 1,
  type: ACCOUNT_TYPES.EFIN, // single, multi, reseller
  backFlag: 1, // 0 -> Cancel Account Creation and go back to original screen, 1 -> Decrement account step and go back to tile screen
};

export const actions = {
  setCreateAccountType: officeType => ({
    type: SET_CREATION_TYPE,
    officeType,
  }),
  setBackFlag: flag => ({
    type: SET_BACK_FLAG,
    flag,
  }),
  setCreateAccountStep: step => ({
    type: SET_CREATION_STEP,
    step,
  }),
  incrementCreateAccountStep: () => ({
    type: INCREMENT_CREATION_STEP,
  }),
  decrementCreateAccountStep: () => ({
    type: DECREMENT_CREATION_STEP,
  }),
  resetCreateAccount: () => ({
    type: RESET_CREATION,
  }),
  resetCreateAccountFull: () => ({
    type: RESET_TO_INITIAL,
  }),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_CREATION_TYPE:
      return {
        ...state,
        type: action.officeType,
      };
    case SET_BACK_FLAG:
      return {
        ...state,
        backFlag: action.flag,
      };
    case SET_CREATION_STEP:
      return {
        ...state,
        step: action.step,
      };
    case INCREMENT_CREATION_STEP:
      return {
        ...state,
        step: state.step + 1,
      };
    case DECREMENT_CREATION_STEP:
      return {
        ...state,
        step: state.step - 1,
      };
    case RESET_CREATION:
      return {
        ...state,
        step: initialState.step,
      };
    case RESET_TO_INITIAL:
      return {
        ...initialState,
      };
  }
  return state;
}

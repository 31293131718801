// External imports
import React from 'react';
// Internal imports
import XlinkAPI from '~/app/api/xlinkAPI';
import WebHelpers, { statusOK } from '~/app/webHelpers.js';
import SimpleDialog from '#/Common/SimpleDialog.jsx';
import { NOTIFICATION_TYPE } from '~/app/constants.js';
// Redux imports
import { useSelector, useDispatch } from 'react-redux';
import { actions as appActions } from '~/app/redux/modules/app';

const VerifyEFINModal = ({
  isOpen,
  handleToggle,
  EFIN,
  efinID,
  loginID,
  isFeederOffice,
  onRefresh,
  isLastSectionToComplete,
  addFrontendNotification,
  hasOfficeBeenUpdated,
  onSetupComplete,
  updateOfficeSetupFlag,
}) => {
  const dispatch = useDispatch();
  const payload = WebHelpers.getJWTPayload();
  const { userID } = useSelector(state => ({
    userID: state.app.loggedInBasicInfo.user_id,
  }));

  const handleOfficeCompletion = () => {
    // Both Office and EFIN are done, AND it is the last section to be completed
    if (isLastSectionToComplete() && hasOfficeBeenUpdated) {
      addFrontendNotification({
        id: 0,
        returnID: 0,
        content: 'Required Office Setup Completed Successfully',
        type: NOTIFICATION_TYPE.SUCCESS,
      });
      onSetupComplete();
      updateOfficeSetupFlag('office', true);

      // If both Office and EFIN Validation are done, but its not the last section to be completed.
    } else if (hasOfficeBeenUpdated) {
      updateOfficeSetupFlag('office', true);

      // If EFIN Validation is done and Office Info is not
    } else if (!hasOfficeBeenUpdated) {
      addFrontendNotification({
        id: 0,
        returnID: 0,
        content: 'Your EFIN has been validated, but your Office Info still needs to be saved.',
        type: NOTIFICATION_TYPE.WARNING,
      });
    }
  };

  const submitEFINValidation = async () => {
    try {
      const res = await XlinkAPI.postEFINValidation(
        EFIN,
        efinID,
        userID,
        payload.season,
        parseInt(loginID),
        isFeederOffice,
      );
      if (statusOK(res)) {
        onRefresh();
        handleOfficeCompletion();
        dispatch(
          appActions.showSnackbarMessage(`Successfully validated EFIN '${EFIN}'`, 'success', 3500, {
            vertical: 'top',
            horizontal: 'center',
          }),
        );
      }
    } catch (err) {
      dispatch(
        appActions.showSnackbarMessage(
          `Error Validating EFIN: ${
            err?.response?.data?.error_message ? err?.response?.data?.error_message : err
          }`,
          'warning',
          6000,
          {
            vertical: 'top',
            horizontal: 'center',
          },
        ),
      );
    }
    handleToggle();
  };

  return (
    <SimpleDialog
      open={isOpen}
      onClose={handleToggle}
      onConfirm={submitEFINValidation}
      dialogTitle="WARNING: Send EFIN Verification - This cannot be undone if successful"
      contentText={`This will send '${EFIN}' for an EFIN verfication. If the EFIN is verified, it will save to this Office and disable the EFIN field.`}
    />
  );
};

export default VerifyEFINModal;

// External imports
import React, { Fragment, useEffect } from 'react';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import {
  Button,
  Collapse,
  Paper,
  Slide,
  ListItemText,
  Grid,
  Typography,
  Menu,
  MenuItem,
  ListItemSecondaryAction,
  NativeSelect,
  Snackbar,
  Tabs,
  Tab,
  withStyles,
} from '@material-ui/core';
// Internal imports
import { useSetState } from '~/app/Utility/customHooks';
import SimpleDialog from '#/Common/SimpleDialog.jsx';
import ClientLetterEditor from '#/Settings/Setup/ClientLetters/Components/ClientLetterEditor.jsx';
import NewClientLetter from '#/Settings/Setup/ClientLetters/Components/NewClientLetter.jsx';
import ErrorHelpers from '~/app/errorHelpers.js';
import XlinkAPI from '~/app/api/xlinkAPI';
import menuVertical from '~/images/icons/menu_vertical.png';
import {
  BUSINESS_ENTITY_TYPES,
  REQUIRED_SETUP_PAGES,
  BUSINESS_SETUP_PAGES,
} from '~/app/constants.js';
import AccessControl from '~/app/Components/Auth/AccessControl.jsx';
import WebHelpers from '~/app/webHelpers.js';
// Styling imports
import { styles } from '#/Settings/Setup/ClientLetters/css/clientLetterSetupStyles.js';
import '~/app/Pages/Setup/css/setup.css';
import '#/Settings/Setup/ClientLetters/css/clientLetterSetup.css';

const BusClientLetterSetup = props => {
  const payload = WebHelpers.getJWTPayload();
  const { classes } = props;
  const [state, setState] = useSetState({
    clientLetterList: {
      // Object containing list of client letters and the default letter
      defaultLetter: null,
      letters: [],
    },
    letterIndex: 0, // Index of currently selected letter
    clientLetterItems: {}, // Client letter header/section items
    sections: null, // Currently selected sections for modifcation
    editor: false, // Show text editor for selected section
    contextMenu: {
      // Toggle context action menu
      anchorEl: null,
      visible: false,
    },
    newClientLetter: false, // Toggle new client letter dialog
    showDeleteDialog: false,
    expandedSections: [], // Expanded collapsible sections
    snackbar: {
      visible: false,
      content: '',
    },
    activeTab: 0,
    isFetchingClItems: false,
  });

  useEffect(() => {
    getEntityClItems(state.activeTab);
  }, []);

  // Add a new client letter- new client letter is based on currently selected letter in dropdown
  const addNewClientLetter = input => {
    // Close add client letter dialog
    setState({ newClientLetter: false });
    // Add client letter, switch to newest client letter (newest client letter will always be the last index of the array)
    XlinkAPI.addNewClientLetter(
      state.clientLetterList.letters[state.letterIndex].ID,
      state.clientLetterList.letters[state.letterIndex].custom,
      input,
    )
      .then(res => {
        const letters = res.data.letters;
        setState({
          clientLetterList: res.data,
          letterIndex: letters.length - 1,
        });
      })
      .catch(error => {
        ErrorHelpers.handleError('Unable to add new client letter', error);
      });
  };

  const handleSnackBar = dataFromEditor => {
    // grabbing data from child component and loading into parent state
    setState({
      snackbar: {
        visible: dataFromEditor.visible,
        content: dataFromEditor.content,
      },
    });
  };

  // Delete a client letter - this should only work and be available for custom client letters
  const deleteClientLetter = () => {
    setState({ showDeleteDialog: false });
    // Delete client letter, update letter list and select default letter
    XlinkAPI.deleteClientLetter(
      state.clientLetterList.letters[state.letterIndex].ID,
      state.clientLetterList.letters[state.letterIndex].custom,
    )
      .then(res => {
        const letters = res.data.letters;
        const defaultLetter = res.data.defaultLetter;
        setState({
          clientLetterList: res.data,
          letterIndex: letters.findIndex(
            ltr => ltr.ID === defaultLetter.ID && ltr.custom === defaultLetter.custom,
          ),
        });
      })
      .catch(error => {
        ErrorHelpers.handleError('Unable to delete client letter', error);
      });
  };

  const updateDefaultClientLetter = () => {
    XlinkAPI.updateDefaultClientLetter(
      state.clientLetterList.letters[state.letterIndex].ID,
      state.clientLetterList.letters[state.letterIndex].custom,
    )
      .then(res => {
        setState({ clientLetterList: res.data });
      })
      .catch(error => {
        ErrorHelpers.handleError('Unable to update default client letter', error);
      });
  };

  /**
   * If sub-heading has sub-section, then we apply a different style
   * which includes bottom border.
   */
  const subSectionHeadingStyle = subSectionHeading => {
    if (subSectionHeading.subheader) {
      return { root: classes.clientLetterSubSectionMenuHeading };
    } else {
      return { root: classes.clientLetterSetupMenuItem };
    }
  };

  /**
   * Checks if we need to indent further base on
   * whether there is a sub-section or not.
   */
  const whichSubSectionStyle = subSection => {
    if (subSection) {
      // Sub-section, so we need to indent further.
      return { root: classes.clientLetterSubSectionSetupMenuItem };
    } else {
      return { root: classes.clientLetterSubSectionItem };
    }
  };

  /**
   * Sets up which letter to edit.
   */
  const editLetter = itemsKey => {
    setState({
      sections: {
        sections: itemsKey.section,
        sectionCode: itemsKey.centralSiteCode,
        headerName: itemsKey.name,
      },
      editor: true,
    });
  };

  const handleTabChange = async (_, v) => {
    if (v === state.activeTab) return;
    setState({
      activeTab: v,
      isFetchingClItems: true,
    });
    getEntityClItems(v);
  };

  const getEntityClItems = async v => {
    XlinkAPI.getClientLetterList(BUSINESS_ENTITY_TYPES[v].ENTITYCODE)
      .then(res => {
        const letters = res.data.letters;
        const defaultLetter = res.data.defaultLetter;
        // On initial component mount load the default letter
        setState({
          clientLetterList: res.data,
          letterIndex: letters.findIndex(
            ltr => ltr.ID === defaultLetter.ID && ltr.custom === defaultLetter.custom,
          ),
          isFetchingClItems: false,
        });
      })
      .catch(error => {
        ErrorHelpers.handleError(
          'Unable to fetch ' + BUSINESS_ENTITY_TYPES[v].ENTITY + ' client letter list',
          error,
        );
        setState({ isFetchingClItems: false });
      });
    // Fetch headers, subheaders, sections, and subsections
    XlinkAPI.getClientLetterItems(BUSINESS_ENTITY_TYPES[v].ENTITYCODE)
      .then(res => {
        setState({
          clientLetterItems: res.data,
          isFetchingClItems: false,
        });
      })
      .catch(error => {
        ErrorHelpers.handleError(
          'Unable to fetch ' + BUSINESS_ENTITY_TYPES[v].ENTITY + ' client letter list items',
          error,
        );
        setState({ isFetchingClItems: false });
      });
  };

  // Handles client letter dropdown selection
  const handleDropdownChange = letterIndex => {
    setState({ letterIndex: letterIndex });
  };

  // handleAddNewClick opens the add new client letter dialog and closes the action menu
  const handleAddNewClick = e => {
    e.stopPropagation();
    setState({
      contextMenu: {
        anchorEl: null,
        visible: false,
      },
      newClientLetter: true,
    });
  };

  // handleSetDefaultClick sets the currently selected letter to the default letter and closes the action menu
  const handleSetDefaultClick = e => {
    e.stopPropagation();
    setState({
      contextMenu: {
        anchorEl: null,
        visible: false,
      },
    });
    // toggleContextmenu({ anchorEl: null, visible: false });
    updateDefaultClientLetter();
  };

  // handleDeleteClick opens the confirm letter deletion dialog
  const handleDeleteClick = e => {
    e.stopPropagation();
    setState({
      contextMenu: {
        anchorEl: null,
        visible: false,
      },
      showDeleteDialog: true,
    });
  };

  const handleRowClick = (item, key) => {
    if (typeof item.subheader !== 'undefined') {
      handleCollapse(key);
    }
  };

  /**
   * handles a click on a collapsible item
   *
   */
  const handleCollapse = key => {
    const keyIndex = state.expandedSections.indexOf(key);
    const newexpandedSections = [...state.expandedSections];

    if (keyIndex === -1) {
      newexpandedSections.push(key);
    } else {
      newexpandedSections.splice(keyIndex, 1);
    }
    setState({ expandedSections: newexpandedSections });
  };

  const renderSubheaders = (items, subSection = false) => {
    return (
      // list-unstyled removes bullets from <li> elements rendered by <MenuItem> under the hood
      <div className="list-unstyled">
        {Object.keys(items).map(key => {
          return (
            <Fragment key={key}>
              <MenuItem
                classes={subSectionHeadingStyle(items[key])}
                disableTouchRipple={typeof items[key].subheader === 'undefined'}
                onClick={() => handleRowClick(items[key], key)}
              >
                <ListItemText
                  classes={whichSubSectionStyle(subSection)}
                  disableTypography
                  primary={<Typography variant="body1">{items[key].name}</Typography>}
                />
                {items[key].subheader &&
                  (state.expandedSections.indexOf(key) !== -1 ? <ExpandLess /> : <ExpandMore />)}
                {typeof items[key].section !== 'undefined' && (
                  <ListItemSecondaryAction>
                    <Button onClick={() => editLetter(items[key])}>
                      {state.clientLetterList.letters.length > 0 &&
                        (state.clientLetterList.letters[state.letterIndex].custom
                          ? 'Modify'
                          : 'View')}
                    </Button>
                  </ListItemSecondaryAction>
                )}
              </MenuItem>
              {items[key].subheader && (
                <Collapse
                  in={state.expandedSections.indexOf(key) !== -1}
                  timeout="auto"
                  unmountOnExit
                >
                  {renderSubheaders(items[key].subheader, true)}
                </Collapse>
              )}
            </Fragment>
          );
        })}
      </div>
    );
  };

  const goToPrevious = () => {
    props.loadSetupComponent(REQUIRED_SETUP_PAGES.LICENCING.NAME);
  };

  const gotToNext = () => {
    props.loadSetupComponent(BUSINESS_SETUP_PAGES.BILLING.NAME);
  };

  return (
    <div className="bus-client-letter-setup-container">
      {!state.editor && (
        <Paper className="bus-client-letter-setup-content">
          <Grid container>
            <Grid item xs={12} className="row ovTopRow">
              <Grid container justify="space-between" className="row">
                <Grid item xs={6}>
                  <Tabs
                    value={state.activeTab}
                    onChange={(e, val) => handleTabChange(e, val)}
                    position="static"
                  >
                    {BUSINESS_ENTITY_TYPES.map(t => {
                      if (
                        payload.season >= 2024 &&
                        ENVIRONMENT === 'production' &&
                        !['NAHMIC', 'AMAPRI00', 'CLOTST'].includes(payload.account_code) &&
                        t.INDEX > 2
                      ) {
                        return null;
                      }

                      return (
                        <Tab
                          style={styles.clientLetterEntityTab}
                          label={t.ENTITY}
                          value={t.INDEX}
                          key={t.INDEX}
                          disabled={state.isFetchingClItems}
                        />
                      );
                    }).filter(option => option !== undefined)}
                  </Tabs>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <br />
          <Typography classes={{ root: classes.clientLetterSetupTitle }} variant="title">
            Business Client Letter Setup
          </Typography>
          <NativeSelect
            id="busClientLetterList"
            value={state.letterIndex}
            onChange={e => handleDropdownChange(e.target.value)}
            disableUnderline={true}
            disabled={state.isFetchingClItems || state.clientLetterList.letters.length === 0}
          >
            {state.clientLetterList.letters.map((letter, index) => {
              let isDefault = '';
              if (
                letter.ID === state.clientLetterList.defaultLetter.ID &&
                letter.custom === state.clientLetterList.defaultLetter.custom
              ) {
                isDefault = '(default)';
              }
              return (
                <option key={letter.ID + letter.name} value={index}>
                  {letter.name} {isDefault}
                </option>
              );
            })}
          </NativeSelect>
          <AccessControl requiredAction="write" accessLevel="edit_client_letter">
            <Button
              classes={{ root: classes.clientLetterContextMenu }}
              id="busClientLetterListActions"
              onClick={e => {
                e.stopPropagation();
                setState({
                  contextMenu: {
                    anchorEl: e.currentTarget,
                    visible: true,
                  },
                });
              }}
              disabled={state.isFetchingClItems || state.clientLetterList.letters.length === 0}
            >
              <img src={menuVertical} />
            </Button>
            <Menu
              id={`busClientLetterListActionsMenu`}
              anchorEl={state.contextMenu.anchorEl}
              open={state.contextMenu.visible}
              onClose={e => {
                e.stopPropagation();
                setState({
                  contextMenu: {
                    anchorEl: null,
                    visible: false,
                  },
                });
              }}
            >
              <MenuItem
                className="contextMenuItem"
                id={`busClientLetterListActionsMenuNew`}
                onClick={e => handleAddNewClick(e)}
                disabled={state.isFetchingClItems}
              >
                Add New Letter
              </MenuItem>

              <MenuItem
                className="contextMenuItem"
                id={`busClientLetterListActionsMenuDefault`}
                onClick={e => handleSetDefaultClick(e)}
              >
                Set as Default
              </MenuItem>
              <MenuItem
                className={'contextMenuItem'}
                classes={
                  state.clientLetterList.letters.length > 0 &&
                  !state.clientLetterList.letters[state.letterIndex].custom
                    ? { root: classes.clientLetterContextMenuHidden }
                    : {}
                }
                id={`busClientLetterListActionsMenuDelete`}
                onClick={e => handleDeleteClick(e)}
              >
                Delete Letter
              </MenuItem>
            </Menu>
          </AccessControl>
          {Object.keys(state.clientLetterItems).map(key => {
            return (
              <div className="bus-client-letter-section-content list-unstyled" key={key}>
                <MenuItem
                  classes={{ root: classes.clientLetterSectionTitle }}
                  disableTouchRipple={typeof state.clientLetterItems[key].subheader === 'undefined'}
                  onClick={() => handleRowClick(state.clientLetterItems[key], key)}
                >
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography variant="subheading">
                        {state.clientLetterItems[key].name}
                      </Typography>
                    }
                  />
                  {state.clientLetterItems[key].subheader &&
                    (state.expandedSections.indexOf(key) !== -1 ? <ExpandLess /> : <ExpandMore />)}
                  {typeof state.clientLetterItems[key].section !== 'undefined' && (
                    <ListItemSecondaryAction>
                      <Button size="small" onClick={() => editLetter(state.clientLetterItems[key])}>
                        {state.clientLetterList.letters.length > 0 &&
                          (state.clientLetterList.letters[state.letterIndex].custom
                            ? 'Modify'
                            : 'View')}
                      </Button>
                    </ListItemSecondaryAction>
                  )}
                </MenuItem>
                {state.clientLetterItems[key].subheader && (
                  <Collapse
                    in={state.expandedSections.indexOf(key) !== -1}
                    timeout="auto"
                    unmountOnExit
                    className="bus-client-letter-sub-section-content"
                  >
                    {renderSubheaders(state.clientLetterItems[key].subheader)}
                  </Collapse>
                )}
              </div>
            );
          })}
          <div className="setup-nav-btns">
            <Button
              id="btnPreviousSecurity"
              color="primary"
              onClick={() => {
                goToPrevious();
              }}
            >
              Previous
            </Button>
            <Button
              classes={{ root: classes.auditConfigFormButton }}
              color="primary"
              id="btnNextText"
              onClick={() => gotToNext()}
            >
              Next
            </Button>
          </div>
        </Paper>
      )}
      <Slide direction="up" in={state.editor} mountOnEnter unmountOnExit>
        <Paper>
          <ClientLetterEditor
            letter={state.clientLetterList.letters[state.letterIndex]}
            data={state.sections}
            closeEditor={() => setState({ editor: false })}
            setSections={section => setState({ section: section })}
            dataSnackBar={dataFromEditor => handleSnackBar(dataFromEditor)}
            entitycode={BUSINESS_ENTITY_TYPES[state.activeTab].ENTITYCODE}
          />
        </Paper>
      </Slide>

      <div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={state.snackbar.visible}
          onClose={() =>
            setState({
              snackbar: {
                visible: false,
                content: '',
              },
            })
          }
          autoHideDuration={3000}
          id="snkbarconfsaved"
          message={<span id="success-message">{state.snackbar.content}</span>}
        />
      </div>

      <NewClientLetter
        open={state.newClientLetter}
        closeDialog={() => setState({ newClientLetter: false })}
        addNewClientLetter={input => addNewClientLetter(input)}
      />
      <SimpleDialog
        open={state.showDeleteDialog}
        onClose={() => setState({ showDeleteDialog: false })}
        onConfirm={() => {
          deleteClientLetter();
        }}
        dialogTitle={'Delete Client Letter'}
        contentText={
          'Are you sure you want to delete the current client letter? This cannot be undone.'
        }
      />
    </div>
  );
};

export default withStyles(styles)(BusClientLetterSetup);

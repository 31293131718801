import React, { useEffect } from 'react';
// internal imports
import { useSetState } from '~/app/Utility/customHooks';
import { STATE_LIST } from '~/app/constants.js';
// external imports
import {
  AppBar,
  Toolbar,
  FormControl,
  InputLabel,
  Input,
  Divider,
  Button,
  NativeSelect,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
// redux imports
import { useSelector, useDispatch } from 'react-redux';
import { actions as setupPageActions } from '~/app/redux/setupPages/duck';

const UpsertEduInst = props => {
  const dispatch = useDispatch();

  const { isEditingRecord, currentEditRecord } = useSelector(state => ({
    isEditingRecord: state.setupPageState.isEditingRecord,
    currentEditRecord: state.setupPageState.currentEditRecord,
  }));

  const [state, setState] = useSetState({
    expandedUSAddress: isEditingRecord,
    expandedForeignAddress: isEditingRecord,
    foreignAddress: currentEditRecord.hidden_domestic_ind,
  });

  useEffect(() => {
    if (
      currentEditRecord.hidden_domestic_ind === undefined &&
      currentEditRecord.hidden_foreign_ind === undefined
    ) {
      dispatch(setupPageActions.setHiddenForeignInd());
    }
  }, []);

  const handleInputChange = e => {
    e.persist();
    dispatch(setupPageActions.updateCurrentEditRecord(e));
  };

  const handleUpsert = () => {
    props.handleUserDBUpsert(currentEditRecord);
  };

  const handleDomesticCheckbox = e => {
    setState({ foreignAddress: e.target.checked });
    if (e.target.checked) {
      dispatch(setupPageActions.clearAddressData(false, true));
    } else {
      dispatch(setupPageActions.clearAddressData(true, false));
    }
  };

  const validateFields = () => {
    if (
      currentEditRecord.address1 == null ||
      currentEditRecord.address1 === '' ||
      currentEditRecord.postal_code == null ||
      currentEditRecord.postal_code === '' ||
      currentEditRecord.institution_ein == null ||
      currentEditRecord.institution_ein === '' ||
      currentEditRecord.institution_name == null ||
      currentEditRecord.institution_name === ''
    ) {
      return false;
    }

    // Province may be undef since its not loaded initially
    if (
      (state.foreignAddress &&
        (typeof currentEditRecord.province === 'undefined' || currentEditRecord.province === '')) ||
      (!state.foreignAddress && currentEditRecord.state_code === '')
    ) {
      return false;
    }

    return true;
  };

  const handleStateValueChange = stateID => {
    dispatch(setupPageActions.handleUpdateSelectedState(stateID));
  };

  const stateList = [''].concat(STATE_LIST).map(state => {
    return (
      <option id={'UpsertEduInstoptSelect' + state} key={state} value={state}>
        {state}
      </option>
    );
  });

  return (
    <div>
      <AppBar position="static">
        <Toolbar style={styles.toolbar}>
          {isEditingRecord ? 'Edit ' : 'Add New '}
          Educational Institution
        </Toolbar>
      </AppBar>
      <form styles={styles.form}>
        <div style={{ margin: '2em', textAlign: 'left' }}>
          <div style={styles.sectionHeading}>EIN Information</div>
          <div style={{ float: 'left', marginRight: '1em' }}>
            <FormControl>
              <InputLabel style={styles.labelStyles} required={false} shrink>
                Federal EIN
              </InputLabel>
              <Input
                id="txtEINIDUpsertEduInstEIN"
                name="institution_ein"
                value={currentEditRecord.institution_ein}
                onChange={handleInputChange}
                style={styles.inputStyle}
                error={
                  !currentEditRecord.institution_ein || currentEditRecord.institution_ein === ''
                }
                inputProps={{
                  maxLength: '9',
                }}
                disableUnderline
              />
            </FormControl>
          </div>
          <div style={{ float: 'none', marginRight: '1em' }}>
            <FormControl>
              <InputLabel style={styles.labelStyles} required={false} shrink>
                Institution Name
              </InputLabel>
              <Input
                id="txtNameControlUpsertEduInstName"
                name="institution_name"
                value={currentEditRecord.institution_name}
                onChange={handleInputChange}
                style={styles.inputStyle}
                error={
                  !currentEditRecord.institution_name || currentEditRecord.institution_name === ''
                }
                inputProps={{
                  maxLength: '75',
                }}
                disableUnderline
              />
            </FormControl>
          </div>
        </div>
        <Divider style={{ marginTop: '1em' }} />
        <div
          style={{
            marginLeft: '2em',
            fontSize: '21px',
            color: '#1A173B',
            fontFamily: 'Roboto',
          }}
        >
          {state.foreignAddress ? 'Foreign Address' : 'Domestic Address'}
          {!isEditingRecord && (
            <div style={{ float: 'right' }}>
              <FormControlLabel
                label="Use Foreign Address"
                control={
                  <Checkbox
                    style={{ width: 17, height: 16, textAlign: 'right' }}
                    icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 24 }} color="secondary" />}
                    checkedIcon={<CheckBoxIcon style={{ fontSize: 24 }} color="primary" />}
                    id="chkDomesticAddrEduInst"
                    name="domesticCheckboxEduInst"
                    checked={state.foreignAddress}
                    onChange={handleDomesticCheckbox}
                    inputProps={{ height: '20px', width: '20px' }}
                  />
                }
              />
            </div>
          )}
        </div>

        {(currentEditRecord.hidden_foreign_ind === true || !isEditingRecord) && (
          <div style={{ margin: '2em', textAlign: 'left' }}>
            {!state.foreignAddress && (
              <div>
                <div style={{ float: 'left', marginRight: '1em' }}>
                  <FormControl>
                    <InputLabel style={styles.labelStyles} required={false} shrink>
                      Care of Address
                    </InputLabel>
                    <Input
                      id="txtEINIDUpsertEduInstCOAddress"
                      name="address2"
                      value={currentEditRecord.address2}
                      onChange={handleInputChange}
                      style={styles.inputStyle}
                      inputProps={{
                        maxLength: '75',
                      }}
                      disableUnderline
                    />
                  </FormControl>
                </div>
                <div style={{ float: 'none', marginRight: '1em' }}>
                  <FormControl>
                    <InputLabel style={styles.labelStyles} required={false} shrink>
                      Institution Address
                    </InputLabel>
                    <Input
                      id="txtEINIDUpsertEduInstFirmAddress"
                      name="address1"
                      value={currentEditRecord.address1}
                      onChange={handleInputChange}
                      style={styles.inputStyle}
                      error={!currentEditRecord.address1 || currentEditRecord.address1 === ''}
                      inputProps={{
                        maxLength: '75',
                      }}
                      disableUnderline
                    />
                  </FormControl>
                </div>
                <br />
                <div style={{ float: 'left', marginRight: '1em' }}>
                  <FormControl>
                    <InputLabel style={styles.labelStyles} required={false} shrink>
                      City
                    </InputLabel>
                    <Input
                      id="txtNameControlUpsertEduInstCity"
                      name="city"
                      value={currentEditRecord.city}
                      onChange={handleInputChange}
                      style={styles.inputStyle}
                      error={!currentEditRecord.city || currentEditRecord.city === ''}
                      inputProps={{
                        maxLength: '75',
                      }}
                      disableUnderline
                    />
                  </FormControl>
                </div>
                <div style={{ float: 'none', marginRight: '1em' }}>
                  <FormControl>
                    <InputLabel style={styles.labelStyles} shrink>
                      State
                    </InputLabel>
                    <NativeSelect
                      id="selectDropDownUpsertEduInstState"
                      value={currentEditRecord.state_code || ''}
                      onChange={e => handleStateValueChange(e.target.value)}
                      style={styles.inputStyle}
                      error={!currentEditRecord.state_code || currentEditRecord.state_code === ''}
                      disableUnderline
                    >
                      {stateList}
                    </NativeSelect>
                  </FormControl>
                </div>
                <div style={{ float: 'none', marginRight: '1em' }}>
                  <FormControl>
                    <InputLabel style={styles.labelStyles} required={false} shrink>
                      Zip Code
                    </InputLabel>
                    <Input
                      id="txtNameControlUpsertEduInstZipCode"
                      name="postal_code"
                      value={currentEditRecord.postal_code}
                      onChange={handleInputChange}
                      style={styles.inputStyle}
                      error={!currentEditRecord.postal_code || currentEditRecord.postal_code === ''}
                      inputProps={{
                        maxLength: '9',
                      }}
                      disableUnderline
                    />
                  </FormControl>
                </div>
              </div>
            )}
          </div>
        )}

        {(currentEditRecord.hidden_domestic_ind === true || !isEditingRecord) && (
          <div style={{ margin: '2em', textAlign: 'left' }}>
            {state.foreignAddress && (
              <div>
                <div style={{ float: 'left', marginRight: '1em' }}>
                  <FormControl>
                    <InputLabel style={styles.labelStyles} required={false} shrink>
                      Address 1
                    </InputLabel>
                    <Input
                      id="txtEINIDUpsertEduInstAddress1"
                      name="address1"
                      value={currentEditRecord.address1}
                      onChange={handleInputChange}
                      style={styles.inputStyle}
                      error={!currentEditRecord.address1 || currentEditRecord.address1 === ''}
                      inputProps={{
                        maxLength: '75',
                      }}
                      disableUnderline
                    />
                  </FormControl>
                </div>
                <div style={{ float: 'one', marginRight: '1em' }}>
                  <FormControl>
                    <InputLabel style={styles.labelStyles} required={false} shrink>
                      Address 2
                    </InputLabel>
                    <Input
                      id="txtNameControlUpsertEduInstAddress2"
                      name="address2"
                      value={currentEditRecord.address2}
                      onChange={handleInputChange}
                      style={styles.inputStyle}
                      inputProps={{
                        maxLength: '75',
                      }}
                      disableUnderline
                    />
                  </FormControl>
                </div>

                <div style={{ float: 'left', marginRight: '1em' }}>
                  <FormControl>
                    <InputLabel style={styles.labelStyles} required={false} shrink>
                      City
                    </InputLabel>
                    <Input
                      id="txtNameControlUpsertEduInstCity"
                      name="city"
                      value={currentEditRecord.city}
                      onChange={handleInputChange}
                      style={styles.inputStyle}
                      error={!currentEditRecord.city || currentEditRecord.city === ''}
                      inputProps={{
                        maxLength: '75',
                      }}
                      disableUnderline
                    />
                  </FormControl>
                </div>
                <div style={{ float: 'none', marginRight: '1em' }}>
                  <FormControl>
                    <InputLabel style={styles.labelStyles} required={false} shrink>
                      Province
                    </InputLabel>
                    <Input
                      id="txtNameControlUpsertEduInstProvince"
                      name="province"
                      value={currentEditRecord.province}
                      onChange={handleInputChange}
                      style={styles.inputStyle}
                      error={!currentEditRecord.province || currentEditRecord.province === ''}
                      inputProps={{
                        maxLength: '75',
                      }}
                      disableUnderline
                    />
                  </FormControl>
                </div>
                <br />
                <div style={{ float: 'left', marginRight: '1em' }}>
                  <FormControl>
                    <InputLabel style={styles.labelStyles} required={false} shrink>
                      Postal Code
                    </InputLabel>
                    <Input
                      id="txtNameControlUpsertEduInstPostalCode"
                      name="postal_code"
                      value={currentEditRecord.postal_code}
                      onChange={handleInputChange}
                      style={styles.inputStyle}
                      error={!currentEditRecord.postal_code || currentEditRecord.postal_code === ''}
                      inputProps={{
                        maxLength: '10',
                      }}
                      disableUnderline
                    />
                  </FormControl>
                </div>
                <div style={{ float: 'none', marginRight: '1em' }}>
                  <FormControl>
                    <InputLabel style={styles.labelStyles} required={false} shrink>
                      Country Code
                    </InputLabel>
                    <Input
                      id="txtNameControlUpsertEduInstCountryCode"
                      name="country_code"
                      value={currentEditRecord.country_code}
                      onChange={handleInputChange}
                      style={styles.inputStyle}
                      inputProps={{
                        maxLength: '2',
                      }}
                      disableUnderline
                    />
                  </FormControl>
                </div>
              </div>
            )}
          </div>
        )}
        <div
          style={{
            textAlign: 'right',
            padding: '0',
            marginRight: '2em',
            marginBottom: '2em',
            marginTop: '2em',
          }}
        >
          <Button
            id="btnCancelAddUUpsertEduInst"
            color="primary"
            onClick={() => props.handleCloseUpsertModal()}
            style={styles.button}
          >
            Cancel
          </Button>
          <Button
            id="btnUpsertEduInst"
            color="primary"
            style={styles.button}
            onClick={handleUpsert}
            disabled={!validateFields()}
          >
            {isEditingRecord ? 'Save' : 'Add'}
          </Button>
        </div>
      </form>
    </div>
  );
};

const styles = {
  toolbar: {
    backgroundColor: '#444A59',
  },
  sectionHeading: {
    color: '#1A173B',
    fontFamily: 'Roboto',
    fontSize: '21px',
    marginBottom: '1em',
  },
  buttonGroup: {
    textAlign: 'right',
    marginTop: '2em',
  },
  form: {
    margin: '2em',
  },
  groupDropdown: {
    width: '7em',
    marginRight: '2em',
  },
  dropdown: {
    width: '15em',
    display: 'inlineBlock',
  },
  dropdownText: {
    paddingTop: '0.5em',
    paddingBottom: '0.5em',
    color: '#0077FF',
  },
  subject: {
    width: '24em',
  },
  button: {
    width: '9em',
    fontSize: '14px',
    borderRadius: '4px',
    marginLeft: '2em',
  },
  labelStyles: {
    color: '#354052',
    textAlign: 'left',
    fontFamily: 'Roboto',
  },
  inputStyle: {
    width: '20vw',
  },
  noBorderOnFocus: {
    '&:focus': {
      border: 'none',
      outline: 'none',
    },
  },
};

export default UpsertEduInst;

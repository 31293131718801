// External imports
import React from 'react';
import { withStyles, Paper, AppBar, Toolbar, Typography, Button } from '@material-ui/core';
// Internal imports
import WidgetStoreCard from './WidgetStoreCard.jsx';
import titleBarImage from '~/images/icons/icons8-google-sites-filled.png';
import {
  widgetManagementStyleConfig,
  continueButtonStyle,
  continueButtonStyleWithMargin,
  toolbarColor,
} from './DataModel/widgetMangement.js';
// Redux imports
import { useDispatch, useSelector } from 'react-redux';
import { actions as widgetDashBoardActions } from '~/app/redux/widgets/duck';
// Styling imports
import '~/app/Components/Dashboard/Widgets/css/widgetManagement.css';

const style = theme => widgetManagementStyleConfig(theme);

const WidgetManagement = props => {
  const dispatch = useDispatch();
  const { widgetList } = useSelector(state => ({
    widgetList: state.widgetDashboard.widgetList,
  }));

  const checkIfInstalled = widgetType => {
    for (let i = 0; i < widgetList.length; i++) {
      if (widgetList[i].type === widgetType) {
        return true;
      }
    }
    return false;
  };

  const widgetCards = () => {
    // return an array of widgets
    return props.widgets.map((widget, index) => (
      <WidgetStoreCard
        key={index}
        addWidget={widgetType => dispatch(widgetDashBoardActions.addWidgetRequest(widgetType))}
        cardTitle={widget.storetitle}
        buttonText={widget.price}
        widgetType={widget.widget_id}
        cardImage={widget.storeimage}
        cardDescription={widget.widgetdescription}
        installed={widgetList ? checkIfInstalled(widget.widget_id) : false}
      />
    ));
  };

  const widgetTitleBar = (styleWidgetBar, styleWidgetBarButton, styleLogoWidget) => {
    return (
      <AppBar className="app-bar-container" position="static">
        <Toolbar style={toolbarColor}>
          <img src={titleBarImage} style={styleLogoWidget} />
          <span className="col ovLabel">
            <Typography variant="title" style={styleWidgetBar}>
              Widget Management
            </Typography>
          </span>
          <div>
            <Button style={styleWidgetBarButton} onClick={props.onClose}>
              Close
            </Button>
          </div>
        </Toolbar>
      </AppBar>
    );
  };

  const styleNoMargin = { marginTop: '.8rem' };
  const styleMargin = { marginTop: '1.4rem' };
  const styleWidgetBar = props.isInTrainingMode ? styleMargin : styleNoMargin;
  const styleWidgetBarButton = props.isInTrainingMode
    ? continueButtonStyleWithMargin
    : continueButtonStyle;
  const styleLogoWidget = props.isInTrainingMode
    ? { transform: 'scale(.75,.75)', marginTop: '15px' }
    : { transform: 'scale(.75,.75)' };

  return (
    <div className="paper-container">
      <Paper className="paper-component-container">
        {widgetTitleBar(styleWidgetBar, styleWidgetBarButton, styleLogoWidget)}
        <div className="paper-widget-card-container">{widgetCards()}</div>
      </Paper>
    </div>
  );
};
export default withStyles(style)(WidgetManagement);

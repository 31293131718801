// External imports
import React from 'react';
// Internal imports
import WidgetManagement from './WidgetManagement.jsx';
// Styling imports
import './css/control.css';

const Control = props => {
  return (
    <div className="widget-control-box widget-control-container">
      <WidgetManagement
        onClose={props.onClose}
        widgets={props.widgets}
        isInTrainingMode={props.isInTrainingMode}
      />
    </div>
  );
};

export default Control;

import React, { useEffect, Fragment } from 'react';
// Internal imports
import Spinner from '#/Common/Spinner.jsx';
import { useSetState } from '~/app/Utility/customHooks';
import ErrorHelpers from '~/app/errorHelpers.js';
import XlinkAPI from '~/app/api/xlinkAPI.js';
import '~/app/Components/Settings/Setup/Logins/css/accessLevelModal.css';
import { SPINNER_DIALOGS, ROLE_NAME_VALIDATION } from '~/app/constants.js';
import AccessControl from '~/app/Components/Auth/AccessControl.jsx';
// External imports
import update from 'immutability-helper';
import {
  FormControlLabel,
  Input,
  Paper,
  Button,
  AppBar,
  Toolbar,
  Typography,
  Checkbox,
  Grid,
  withStyles,
} from '@material-ui/core';
// Redux imports
import { useDispatch } from 'react-redux';
import { actions as appActions } from '~/app/redux/modules/app';
// Style imports
import { styles } from '~/app/Components/Settings/Setup/Logins/css/accessLevelModal.js';

/**
 * Access Level modal that displays the current access levels for a selected access role.
 * Handles creating access role with the selected access roles
 *
 * @component AccessLevel
 * @category Settings
 * @subcategory Logins
 */
const AccessLevel = props => {
  const { classes } = props;
  const dispatch = useDispatch();
  const [state, setState] = useSetState({
    isCustom: false,
    roleAccessLevels: [],
    roleName: '',
    fetchingData: false,
    spinnerText: 'Fetching Access Levels...',
    groupHeader: [],
  });

  useEffect(() => {
    setState({ isCustom: props.role.is_custom_role, roleName: props.role.access_role_name });
    // if a 0 is being passed in role.id we know a custom access level is going to be created. the is_custom_role flag should also be set to true.
    fetchAccessLevelsForRole(props.role.id, props.role.is_custom_role);
  }, []);

  /**
   * Fetches Access Levels that are associated to an Access Role
   *
   * @param {number} roleID id of the access role to retrieve its access levels
   * @param {boolean} isCustom determines if it is fetching from the default roles or the custom roles
   */
  const fetchAccessLevelsForRole = async (roleID, isCustom) => {
    try {
      setState({ isFetchingData: true });
      const res = await XlinkAPI.fetchAccessLevelsForRole(roleID, isCustom);
      if (!Array.isArray(res.data)) {
        throw ErrorHelpers.createSimpleError(
          'Error updating Access Role. Return value is not properly formatted',
        );
      }
      setState({ roleAccessLevels: res.data });
    } catch (err) {
      dispatch(
        appActions.showSnackbarMessage(err, 'error', 3500, {
          vertical: 'top',
          horizontal: 'center',
        }),
      );
      ErrorHelpers.handleError('Error updating Access Role', err);
    }
    setState({ isFetchingData: false });
  };

  /**
   * Handles checkbox fields
   *
   * @param {number} idx this is the index of the selected checkbox
   * @param {object} e eventdom used to get the checked value
   */
  const handleCheckBoxChange = (id, e) => {
    const isChecked = e.target.checked;
    if (id === 0) {
      return;
    }
    const idx = state.roleAccessLevels.findIndex(ele => ele.id === id);
    setState({
      roleAccessLevels: update(state.roleAccessLevels, { [idx]: { active: { $set: isChecked } } }),
    });
  };

  const handleReset = () => {
    const blankAccessLevels = state.roleAccessLevels;
    blankAccessLevels.forEach(element => {
      element.active = false;
    });
    setState({
      roleAccessLevels: blankAccessLevels,
    });
  };
  /**
   * Updates all children checkboxes belonging to groupHeader onClick
   *
   * @param {idx} idx this is the index of the selected checkbox
   * @param {Array} access is the array of all access level items belonging to groupHeader
   * @param {object} e eventdom used to get the checked value
   */
  const groupHeaderCascade = (idx, access, e) => {
    const isChecked = e.target.checked;
    const updatedState = state.roleAccessLevels;
    for (const element of access[1]) {
      const idn = state.roleAccessLevels.findIndex(ele => ele.id === element.id);
      updatedState[idn].active = isChecked;
      setState({
        roleAccessLevels: updatedState,
      });
    }
    setState({
      groupHeader: update(state.groupHeader, { [idx]: { active: { $set: isChecked } } }),
    });
  };

  /** Handles Role Name input */
  const handleRoleName = e => {
    const value = e.target.value;
    if (ROLE_NAME_VALIDATION.test(value) && value.length <= 40) {
      setState({ roleName: value });
    }
  };

  /** Handles saving the custom role */
  const handleSave = async () => {
    try {
      setState({ isFetchingData: true });
      setState({ spinnerText: SPINNER_DIALOGS.PROCESS_REQUEST });
      //TEMP: name field must be filled in order to save
      if (state.roleName === '') {
        setState({ isFetchingData: false });
        dispatch(
          appActions.showSnackbarMessage('Name Needed', '', 3500, {
            vertical: 'top',
            horizontal: 'center',
          }),
        );
        return;
      }
      // It's editable we should save it.
      try {
        await XlinkAPI.upsertAccessLevelsForRole(
          props.role.id,
          state.roleAccessLevels,
          state.roleName,
        );
      } catch (err) {
        dispatch(
          appActions.showSnackbarMessage('Access Level name must be unique', '', 3500, {
            vertical: 'top',
            horizontal: 'center',
          }),
        );
        setState({ isFetchingData: false });
        return;
      }
      props.triggerReloadRoles();
      props.closeModal();
    } catch (err) {
      dispatch(
        appActions.showSnackbarMessage(err, 'error', 3500, {
          vertical: 'top',
          horizontal: 'center',
        }),
      );
      ErrorHelpers.handleError('Error updating Access Role', err);
    }
    setState({ isFetchingData: false });
  };
  /**
   * Takes in an array and an existing key inside an obj in the array
   *  returns the array in the form of of {key1: {children}, key2: {children},...}
   * @param {array} data
   * @param {string} key
   * @returns array
   */
  function groupBy(data, key) {
    const temp = data.reduce(function (storage, item) {
      const group = item[key];
      storage[group] = storage[group] || [];
      storage[group].push(item);
      return storage;
    }, {});
    return temp;
  }
  /**
   * Goes through all children of groupHeader, only returns true if all children are checked
   * @param {string} groupName is name of groupHeader
   * @param {Array} arr is array of accesslevels under groupHeading
   * @returns
   */
  const reverseCascade = (groupName, arr) => {
    const obj = {};
    obj['Name'] = groupName;
    obj['active'] = false;
    if (!state.groupHeader.some(e => e.Name === groupName)) {
      setState(prevState => ({
        groupHeader: [...prevState.groupHeader, obj],
      }));
    }
    for (const element of arr) {
      if (element.active === false) {
        return false;
      }
    }
    return true;
  };

  /**
   * Returns checkbox render for individual accessLevels
   * @param {array} access
   * @param {int} idx
   * @returns
   */
  const accessLevelRender = (access, idx) => {
    return access[1].map(element => (
      <>
        <Fragment key={'level' + idx}>
          <Grid item xs={6}>
            <div style={{ paddingLeft: '4.5%' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    id={element.access_level_fname}
                    checked={element.active}
                    onChange={e => handleCheckBoxChange(element.id, e)}
                    disabled={!state.isCustom}
                  />
                }
                label={element.access_level_fname}
              />
            </div>
          </Grid>
        </Fragment>
        <Grid item xs={6}>
          <div style={{ paddingLeft: '4.5%', paddingTop: '3.5%' }}>
            <Typography align="center">{element.access_level_description}</Typography>
          </div>
        </Grid>
      </>
    ));
  };

  /**
   * Returns checkbox render for groupHeader
   * @param {array} access
   * @param {int} idx
   * @returns
   */
  const headerRender = (access, idx) => {
    return (
      <>
        <Fragment key={'level' + idx}>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  id={access[0] + idx}
                  checked={reverseCascade(access[0], access[1], idx)}
                  onChange={e => groupHeaderCascade(idx, access, e)}
                  disabled={!state.isCustom}
                />
              }
              label={access[0]}
            />
          </Grid>
        </Fragment>
        <Grid item xs={6}>
          <Typography align="center"></Typography>
        </Grid>
        {accessLevelRender(access, idx)}
      </>
    );
  };

  return (
    <Paper style={styles.modal}>
      {state.isFetchingData && (
        <span className="centered-spinner">
          <Spinner size={125} loadingText={state.spinnerText} textColor="white" bgColor="grey" />
        </span>
      )}
      <AppBar position="static">
        <Toolbar classes={{ root: classes.toolbarColor }}>
          <Typography classes={{ root: classes.typographyStyle }}>
            {state.isCustom ? props.role.access_role_name : 'Add New Access Level'}
          </Typography>
        </Toolbar>
      </AppBar>
      <AccessControl
        requiredAction="write"
        accessLevel="create/edit_access_levels"
        disableOnFalse={true}
        additionalAccessCheck={state.isCustom}
      >
        <Grid
          container
          spacing={0}
          direction="row"
          justify="center"
          className="access-level-sub-header"
        >
          <Grid item xs={12}>
            <Typography classes={{ root: classes.typographyStyle2 }}>Access Level Name</Typography>
          </Grid>
          <Grid item xs={12}>
            <Input
              id="accLlvNameFld"
              value={state.roleName}
              required
              onChange={e => handleRoleName(e)}
              disabled={!state.isCustom}
            />
          </Grid>
          <Grid item xs={8}>
            <Typography classes={{ root: classes.typographyStyle2 }}>Access Type </Typography>
          </Grid>
          <Grid item xs={4}>
            <div style={{ paddingTop: '5.5%' }}>
              <Typography classes={{ root: classes.typographyStyle2 }}>Description</Typography>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={0}
          direction="row"
          justify="flex-start"
          className="access-level-container"
          //rowSpacing={1}
          columnspacing={{ xs: 1, sm: 1, md: 1 }}
        >
          {Object.entries(groupBy(state.roleAccessLevels, 'access_level_group_name')).map(
            (access, idx) => {
              return headerRender(access, idx);
            },
          )}
        </Grid>
      </AccessControl>
      <Grid
        container
        spacing={0}
        direction="row"
        justify="flex-end"
        alignItems="center"
        className="access-level-footer"
      >
        <Grid item xs={2} style={styles.footerItem}>
          <Button id="btnCloseAddALModal" onClick={props.closeModal}>
            Cancel
          </Button>
        </Grid>
        {state.isCustom && (
          <>
            <Grid item xs={2} style={styles.footerItem}>
              <Button id="btnReset" onClick={() => handleReset()}>
                Reset
              </Button>
            </Grid>
            <Grid item xs={2} style={styles.footerItem}>
              <AccessControl requiredAction="write" accessLevel="create/edit_access_levels">
                <Button id="btnAddNewAL" onClick={() => handleSave()}>
                  Save
                </Button>
              </AccessControl>
            </Grid>
          </>
        )}
      </Grid>
    </Paper>
  );
};

export default withStyles(styles)(AccessLevel);

/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Input from '@material-ui/core/Input';

import choiceListIcon from '~/images/icons/add_form.png'; // TODO: Need to either remove or get a new icon
import { themeStyles } from '~/app/Pages/Returns/components/ChoiceList/css/choiceListModal.js';
import '~/app/Pages/Returns/components/ChoiceList/css/choiceListModal.css';

const compStyles = theme => themeStyles(theme);

const ChoiceListModal = props => {
  const [state, setState] = useState({
    errorMessage: '',
    rowSelected: null,
    cItemFColVal: null,
    cItemEntireRow: null,
    choiceList: [],
    searchValue: '',
    choiceListData: [],
  });

  useEffect(() => {
    bindKeyboardListeners();
    selectFirstRow();
    setState({ ...state, choiceListData: props.choiceListData });
  }, []);

  useEffect(() => {
    bindKeyboardListeners();
    selectFirstRow();
    setState({ ...state, choiceListData: props.choiceListData });
  }, [props.choiceListData]);

  // Reset list on close
  useEffect(() => {
    return () => {
      setState({ ...state, choiceListData: [] });
    };
  }, []);

  const selectFirstRow = () => {
    if (state.choiceListData.length !== 0) {
      const selectedRow = state.choiceListData[0];
      handleRowClick(null, selectedRow.ListItem, 0);
    }
  };

  const bindKeyboardListeners = () => {
    $('#choiceListModal')
      .focus()
      .bind('keydown', function (event) {
        const idx = state.rowSelected;
        const rowList = state.choiceListData;

        switch (event.keyCode) {
          case 38:
            if (idx !== 0) {
              const selectedRow = rowList[idx - 1];
              handleRowClick(null, selectedRow.ListItem, idx - 1);
              $('.choiceListModal').scrollTop(idx * idx - 1);
            }
            break;
          case 40:
            if (idx < rowList.length - 1) {
              const selectedRow = rowList[idx + 1];
              handleRowClick(null, selectedRow.ListItem, idx + 1);
              $('.choiceListModal').scrollTop(idx * idx + 1);
            }
            break;
          case 13:
            event.preventDefault();
            if (idx != null) {
              props.handleChoiceListItem(state.cItemEntireRow, props.choiceListIniField);
            }
            break;
        }
      });
  };

  const handleRowClick = (e, data, index) => {
    setState({
      ...state,
      cItemEntireRow: data,
      cItemFColVal: data?.length > 0 ? data[0] : null,
      rowSelected: index,
    });
  };

  const handleRowDoubleClick = async (e, data, index) => {
    // Wait until this.state.cItemFColVal is not undefined
    handleRowClick(e, data, index);
    // If row is selected, call handleChhoiceListItem function
    if (state.cItemFColVal !== null) {
      props.handleChoiceListItem(state.cItemEntireRow, props.choiceListIniField);
    }
  };

  const handleRowHighlight = (data, index) => {
    if (state.cItemFColVal !== null) {
      if (index === state.rowSelected) {
        return true;
      }
      return false;
    }
    return false;
  };

  const getAddChoiceListTable = () => {
    const { classes } = props;

    return (
      <Table>
        <TableHead>
          <TableRow classes={{ root: classes.tableHeaderRow }}>
            {props.choiceListColumns.map((data, thInd) => {
              if (data != null) {
                return (
                  <TableCell key={thInd} classes={{ root: classes.tableHeaderCell }}>
                    {data}
                  </TableCell>
                );
              }
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {state.choiceListData.map((item, index) => {
            const listItem = item.ListItem;
            if (listItem[0].COL) {
              return (
                <TableRow
                  id={'choiceListRow' + index}
                  classes={{ root: classes.tableRowRoot }}
                  key={index}
                  hover={true}
                  selected={handleRowHighlight(state.choiceListData, index)}
                  onClick={e => handleRowClick(e, listItem, index)}
                  onDoubleClick={e => handleRowDoubleClick(e, listItem, index)}
                >
                  {listItem.slice(0, props.choiceListColumns.length).map((column, index) => {
                    return (
                      <TableCell
                        key={index}
                        className="noselect"
                        classes={{ root: classes.tableCellRoot }}
                      >
                        {column.COL}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            }
          })}
        </TableBody>
      </Table>
    );
  };

  const handleSearchValueChange = e => {
    /*
    Disabling enter key press for now since it brings up
    the Search Results page which hasn't been fully implemented.
    */
    if (e.key === 'Enter') {
      // handle 'enter' in handleEnterKeyPress()
      return;
    }

    setState({ ...state, searchValue: e.target.value.toUpperCase() });

    const searchStr = e.target.value.toUpperCase();

    let filterResults = [];

    if (searchStr) {
      filterResults = props.choiceListData.filter(listItem => {
        const filterListItem = [];

        const matchedListItem = listItem.ListItem.filter(dataItem => {
          const columnValue = dataItem.COL.toUpperCase();
          if (columnValue.includes(searchStr.toUpperCase())) {
            filterListItem.push(listItem);
            return filterListItem;
          }
          return false;
        });

        if (matchedListItem && matchedListItem.length > 0) {
          return matchedListItem;
        }
        return false;
      });
    } else {
      // If searchStr is empty, we populate the list with the original
      // data set.
      filterResults = props.choiceListData;
    }

    setState({ ...state, choiceListData: filterResults, searchValue: searchStr });
  };

  const handleChoiceListSearch = () => {
    let filterResults = [];

    if (state.searchValue) {
      filterResults = props.choiceListData.filter(listItem => {
        const filterListItem = [];

        const matchedListItem = listItem.ListItem.filter(dataItem => {
          if (dataItem.COL.includes(state.searchValue.toUpperCase())) {
            filterListItem.push(listItem);
            return filterListItem;
          }
          return false;
        });

        if (matchedListItem && matchedListItem.length > 0) {
          return matchedListItem;
        }
        return false;
      });
    } else {
      // If searchStr is empty, we populate the list with the original
      // data set.
      filterResults = props.choiceListData;
    }

    setState({ ...state, choiceListData: filterResults });
  };

  const { classes } = props;
  let disabled = true;
  const cancelButton = (
    <Button
      id="btnCancelChoiceList"
      color="primary"
      size="small"
      classes={{ root: classes.cancelButtonRoot }}
      onClick={() => props.handleCloseChoiceModal(false)}
    >
      Cancel
    </Button>
  );
  let confirmButton = (
    <Button id="btnSelectChoiceList" color="primary" disabled={disabled} size="small">
      Select
    </Button>
  );

  if (state.rowSelected !== null) {
    disabled = false;
    confirmButton = (
      <Button
        id="btnConfirmChoiceList"
        color="primary"
        disabled={disabled}
        size="small"
        onClick={choiceItem =>
          props.handleChoiceListItem(state.cItemEntireRow, props.choiceListIniField)
        }
      >
        Select
      </Button>
    );
  }

  return (
    <div id="choiceListModal" className="choicelist-modal-content" tabIndex="0">
      <div className="choicelist-modal-header-container">
        <Typography variant="title" classes={{ root: classes.choicelistHeaderTitle }}>
          <img className="choicelist-header-icon" src={choiceListIcon} />
          {props.choiceListTitle}
        </Typography>
        <div className="choicelist-search-container">
          <Input
            autoFocus
            placeholder="Search"
            disableUnderline
            id="choiceListResultSearch"
            onChange={event => handleSearchValueChange(event)}
            value={state.searchValue}
            autoComplete="off"
            className="choicelist-search-input"
          />
          <Button
            classes={{ root: classes.choicelistSearchBtn }}
            onClick={() => handleChoiceListSearch()}
          >
            Search
          </Button>
        </div>
      </div>
      <div className="choicelist-table-content choiceListModal">{getAddChoiceListTable()}</div>
      <div className="container choicelist-btns-container">
        {cancelButton}
        {confirmButton}
      </div>
    </div>
  );
};

export default withStyles(compStyles)(ChoiceListModal);

import React, { Component } from "react";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Divider from "@material-ui/core/Divider";
import NumberFormat from "react-number-format";
import AccessControl from "#/Auth/AccessControl.jsx";

class GenBillTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  //Handle onChange without validation for input fields
  handleInputChange(e) {
    this.setState({ [e.target.id]: e.target.value });
  }

  //Handle onChange without validation for checkbox
  handleCheckbox = event => {
    this.setState({ [event.target.id]: event.target.checked });
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  render() {
    const priorYearBalanceValue = this.props.billOptsAndRate.priorYearBalance
      ? "true"
      : "false";
      const priorYearOverPaymentValue = this.props.billOptsAndRate.priorYearOverPayment
      ? "true"
      : "false";
    const noBillStandardDeductionValue = this.props.billOptsAndRate
      .noBillStandardDeduction
      ? "true"
      : "false";
    const turnOffDisbursementValue = this.props.billOptsAndRate
      .turnOffDisbursement
      ? "true"
      : "false";
    const preventTransmitValue = this.props.billOptsAndRate.preventTransmit
      ? "true"
      : "false";
    const validatePriorYearBalanceValue = this.props.billOptsAndRate.validatePriorYearBalance
      ? "true"
      : "false";
    const disableInvoicingValue = this.props.billOptsAndRate.disableInvoicing
      ? "true"
      : "false";
    const billOnlyEFValue = this.props.billOptsAndRate.billOnlyEF
      ? "true"
      : "false";
    const taxBillingsValue = this.props.billOptsAndRate.taxBillings
      ? "true"
      : "false";
    const noPriorYearPrepFeeValue = this.props.billOptsAndRate
      .noPriorYearPrepFee
      ? "true"
      : "false";
    const disableInvoiceWarningErrorsValue = this.props.billOptsAndRate
      .disableInvoiceWarningErrors
      ? "true"
      : "false";

    const labelWidthStyle = {
      height: "1.6vh",
      color: "#354052",
      textAlign: "left",
      fontFamily: "Roboto",
      fontSize: "14px"
    };
    const fieldStyle = { width: "12vw", height: "3.5vh", marginRight: "3vw" };
    const labelWidthStyleCheckbox = {
      height: "2vh",
      color: "#354052",
      textAlign: "left",
      fontFamily: "Roboto",
      fontSize: "8px",
      marginTop: "0.8vh",
      marginBottom: "0.8vh",
      paddingLeft: "0px"
    };

    return (
      <AccessControl
        requiredAction="write"
        accessLevel="add/edit_billing"
        disableOnFalse={true}
      >
        <div>
          <div style={{ float: "left" }}>
            <div style={{ marginLeft: "3vw" }}>
              <FormControl required>
                <InputLabel
                  style={labelWidthStyle}
                  required={false}
                  htmlFor="txtStateSalesTaxGenBillTwo"
                  shrink
                >
                  State Sales Tax Rate
                </InputLabel>
                <NumberFormat
                  id="txtStateSalesTaxGenBillTwo"
                  value={this.props.billOptsAndRate.stateSalesTax || ""}
                  onChange={e =>
                    this.props.handleChange(
                      "billOptsAndRate",
                      "stateSalesTax",
                      e.target.value.replace(/[$,%]/g, "")
                    )
                  }
                  style={fieldStyle}
                  disableUnderline
                  customInput={Input}
                  fixedDecimalScale={true}
                  decimalScale={3}
                  suffix="%"
                  isAllowed={values => {
                    const { floatValue } = values;
                    return (
                      (floatValue >= 0.0001 && floatValue <= 99.999) ||
                      values.formattedValue === ""
                    );
                  }}
                />
              </FormControl>
            </div>
            <div style={{ marginLeft: "3vw" }}>
              <FormControl required>
                <InputLabel
                  style={labelWidthStyle}
                  required={false}
                  htmlFor="txtSelfPreparedRateGenBillTwo"
                  shrink
                >
                  Self Prepared Flat Rate
                </InputLabel>
                <NumberFormat
                  id="txtSelfPreparedRateGenBillTwo"
                  value={this.props.billOptsAndRate.selfPreparedRate || ""}
                  onChange={e =>
                    this.props.handleChange(
                      "billOptsAndRate",
                      "selfPreparedRate",
                      e.target.value.replace(/[$,%]/g, "")
                    )
                  }
                  style={fieldStyle}
                  disableUnderline
                  customInput={Input}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  prefix="$"
                  thousandSeparator={true}
                />
              </FormControl>
            </div>
            <div>
              <FormControl>
                <div style={{ marginLeft: "3vw" }}>
                  <FormControlLabel
                    label="No prior year balance on invoice"
                    style={labelWidthStyleCheckbox}
                    control={
                      <Checkbox
                        id="chkPriorYearBalanceGenBillTwo"
                        checked={
                          this.props.billOptsAndRate.priorYearBalance == null
                            ? false
                            : this.props.billOptsAndRate.priorYearBalance
                        }
                        onChange={e =>
                          this.props.handleChange(
                            "billOptsAndRate",
                            "priorYearBalance",
                            e.target.checked
                          )
                        }
                        color="primary"
                        value={priorYearBalanceValue}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fontSize: 24, color: "#8FC3FF" }}
                            color="secondary"
                          />
                        }
                        checkedIcon={
                          <CheckBoxIcon
                            style={{ fontSize: 24 }}
                            color="primary"
                          />
                        }
                      />
                    }
                  />
                </div>
              </FormControl>
            </div>
            {this.props.season > 2022 && (
              <div>
                <FormControl>
                  <div style={{ marginLeft: "3vw" }}>
                    <FormControlLabel
                      label="No prior year overpayment on invoice"
                      style={labelWidthStyleCheckbox}
                      control={
                        <Checkbox
                          id="chkPriorYearOverPaymentGenBillTwo"
                          checked={
                            this.props.billOptsAndRate.priorYearOverPayment == null
                              ? false
                              : this.props.billOptsAndRate.priorYearOverPayment
                          }
                          onChange={e =>
                            this.props.handleChange(
                              "billOptsAndRate",
                              "priorYearOverPayment",
                              e.target.checked
                            )
                          }
                          color="primary"
                          value={priorYearOverPaymentValue}
                          icon={
                            <CheckBoxOutlineBlankIcon
                              style={{ fontSize: 24, color: "#8FC3FF" }}
                              color="secondary"
                            />
                          }
                          checkedIcon={
                            <CheckBoxIcon
                              style={{ fontSize: 24 }}
                              color="primary"
                            />
                          }
                        />
                      }
                    />
                  </div>
                </FormControl>
              </div>
            )}
            <div>
              <FormControl>
                <div style={{ marginLeft: "3vw" }}>
                  <FormControlLabel
                    label="Don't bill for Sch A when using STD deduction"
                    style={labelWidthStyleCheckbox}
                    control={
                      <Checkbox
                        id="chkNoBillStandardDeductionGenBillTwo"
                        checked={
                          this.props.billOptsAndRate.noBillStandardDeduction ==
                            null
                            ? false
                            : this.props.billOptsAndRate.noBillStandardDeduction
                        }
                        onChange={e =>
                          this.props.handleChange(
                            "billOptsAndRate",
                            "noBillStandardDeduction",
                            e.target.checked
                          )
                        }
                        color="primary"
                        value={noBillStandardDeductionValue}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fontSize: 24, color: "#8FC3FF" }}
                            color="secondary"
                          />
                        }
                        checkedIcon={
                          <CheckBoxIcon
                            style={{ fontSize: 24 }}
                            color="primary"
                          />
                        }
                      />
                    }
                  />
                </div>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <div style={{ marginLeft: "3vw" }}>
                  <FormControlLabel
                    label="Turn off default Bank App disbursement options"
                    style={labelWidthStyleCheckbox}
                    control={
                      <Checkbox
                        id="chkTurnOffDisbursementGenBillTwo"
                        checked={
                          this.props.billOptsAndRate.turnOffDisbursement == null
                            ? false
                            : this.props.billOptsAndRate.turnOffDisbursement
                        }
                        onChange={e =>
                          this.props.handleChange(
                            "billOptsAndRate",
                            "turnOffDisbursement",
                            e.target.checked
                          )
                        }
                        color="primary"
                        value={turnOffDisbursementValue}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fontSize: 24, color: "#8FC3FF" }}
                            color="secondary"
                          />
                        }
                        checkedIcon={
                          <CheckBoxIcon
                            style={{ fontSize: 24 }}
                            color="primary"
                          />
                        }
                      />
                    }
                  />
                </div>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <div style={{ marginLeft: "3vw" }}>
                  <FormControlLabel
                    label="Prevent transmit on returns with a balance due fees"
                    style={labelWidthStyleCheckbox}
                    control={
                      <Checkbox
                        id="chkPreventTransmitGenBillTwo"
                        checked={
                          this.props.billOptsAndRate.preventTransmit == null
                            ? false
                            : this.props.billOptsAndRate.preventTransmit
                        }
                        onChange={e =>
                          this.props.handleChange(
                            "billOptsAndRate",
                            "preventTransmit",
                            e.target.checked
                          )
                        }
                        color="primary"
                        value={preventTransmitValue}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fontSize: 24, color: "#8FC3FF" }}
                            color="secondary"
                          />
                        }
                        checkedIcon={
                          <CheckBoxIcon
                            style={{ fontSize: 24 }}
                            color="primary"
                          />
                        }
                      />
                    }
                  />
                </div>
              </FormControl>
            </div>
            
            <div>
              <FormControl>
                <div style={{ marginLeft: "3vw" }}>
                  <FormControlLabel
                    label="Validate the prior year balance"
                    style={labelWidthStyleCheckbox}
                    control={
                      <Checkbox
                        id="chkValidatePriorYearBalance"
                        checked={
                          this.props.billOptsAndRate.validatePriorYearBalance == null
                            ? false
                            : this.props.billOptsAndRate.validatePriorYearBalance
                        }
                        onChange={e =>
                          this.props.handleChange(
                            "billOptsAndRate",
                            "validatePriorYearBalance",
                            e.target.checked
                          )
                        }
                        color="primary"
                        value={validatePriorYearBalanceValue}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fontSize: 24, color: "#8FC3FF" }}
                            color="secondary"
                          />
                        }
                        checkedIcon={
                          <CheckBoxIcon
                            style={{ fontSize: 24 }}
                            color="primary"
                          />
                        }
                      />
                    }
                  />
                </div>
              </FormControl>
            </div>




          </div>

          <div style={{ float: "none", marginLeft: "35vw" }}>
            <div>
              <FormControl required>
                <InputLabel
                  style={labelWidthStyle}
                  required={false}
                  htmlFor="txtTaxDiscountGenBillTwo"
                  shrink
                >
                  Tax Preparation Discount
                </InputLabel>
                <NumberFormat
                  id="txtTaxDiscountGenBillTwo"
                  value={this.props.billOptsAndRate.taxDiscount || ""}
                  onChange={e =>
                    this.props.handleChange(
                      "billOptsAndRate",
                      "taxDiscount",
                      e.target.value.replace(/[$,%]/g, "")
                    )
                  }
                  style={fieldStyle}
                  disableUnderline
                  customInput={Input}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  prefix="$"
                  thousandSeparator={true}
                />
              </FormControl>
            </div>
            <div>
              <FormControl required>
                <InputLabel
                  style={labelWidthStyle}
                  required={false}
                  htmlFor="txtHourlyRateGenBillTwo"
                  shrink
                >
                  Default Hourly Rate
                </InputLabel>
                <NumberFormat
                  id="txtHourlyRateGenBillTwo"
                  value={this.props.billOptsAndRate.hourlyRate || ""}
                  onChange={e =>
                    this.props.handleChange(
                      "billOptsAndRate",
                      "hourlyRate",
                      e.target.value.replace(/[$,%]/g, "")
                    )
                  }
                  style={fieldStyle}
                  disableUnderline
                  customInput={Input}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  prefix="$"
                  thousandSeparator={true}
                />
              </FormControl>
            </div>
            <div>
              <FormControl>
                <div>
                  <FormControlLabel
                    label="Disable invoicing"
                    style={labelWidthStyleCheckbox}
                    control={
                      <Checkbox
                        id="chkDisableInvoicingGenBillTwo"
                        checked={
                          this.props.billOptsAndRate.disableInvoicing == null
                            ? false
                            : this.props.billOptsAndRate.disableInvoicing
                        }
                        onChange={e =>
                          this.props.handleChange(
                            "billOptsAndRate",
                            "disableInvoicing",
                            e.target.checked
                          )
                        }
                        color="primary"
                        value={disableInvoicingValue}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fontSize: 24, color: "#8FC3FF" }}
                            color="secondary"
                          />
                        }
                        checkedIcon={
                          <CheckBoxIcon
                            style={{ fontSize: 24 }}
                            color="primary"
                          />
                        }
                      />
                    }
                  />
                </div>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <div>
                  <FormControlLabel
                    label="Bill for electronically filed forms only"
                    style={labelWidthStyleCheckbox}
                    control={
                      <Checkbox
                        id="chkBillOnlyEFGenBillTwo"
                        checked={
                          this.props.billOptsAndRate.billOnlyEF == null
                            ? false
                            : this.props.billOptsAndRate.billOnlyEF
                        }
                        onChange={e =>
                          this.props.handleChange(
                            "billOptsAndRate",
                            "billOnlyEF",
                            e.target.checked
                          )
                        }
                        color="primary"
                        value={billOnlyEFValue}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fontSize: 24, color: "#8FC3FF" }}
                            color="secondary"
                          />
                        }
                        checkedIcon={
                          <CheckBoxIcon
                            style={{ fontSize: 24 }}
                            color="primary"
                          />
                        }
                      />
                    }
                  />
                </div>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <div>
                  <FormControlLabel
                    label="Collect tax on billings?"
                    style={labelWidthStyleCheckbox}
                    control={
                      <Checkbox
                        id="chkTaxBillingsGenBillTwo"
                        checked={
                          this.props.billOptsAndRate.taxBillings == null
                            ? false
                            : this.props.billOptsAndRate.taxBillings
                        }
                        onChange={e =>
                          this.props.handleChange(
                            "billOptsAndRate",
                            "taxBillings",
                            e.target.checked
                          )
                        }
                        color="primary"
                        value={taxBillingsValue}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fontSize: 24, color: "#8FC3FF" }}
                            color="secondary"
                          />
                        }
                        checkedIcon={
                          <CheckBoxIcon
                            style={{ fontSize: 24 }}
                            color="primary"
                          />
                        }
                      />
                    }
                  />
                </div>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <div>
                  <FormControlLabel
                    label="No prior year preparation fee on invoice"
                    style={labelWidthStyleCheckbox}
                    control={
                      <Checkbox
                        id="chkNoPriorYearPrepFeeGenBillTwo"
                        checked={
                          this.props.billOptsAndRate.noPriorYearPrepFee == null
                            ? false
                            : this.props.billOptsAndRate.noPriorYearPrepFee
                        }
                        onChange={e =>
                          this.props.handleChange(
                            "billOptsAndRate",
                            "noPriorYearPrepFee",
                            e.target.checked
                          )
                        }
                        color="primary"
                        value={noPriorYearPrepFeeValue}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fontSize: 24, color: "#8FC3FF" }}
                            color="secondary"
                          />
                        }
                        checkedIcon={
                          <CheckBoxIcon
                            style={{ fontSize: 24 }}
                            color="primary"
                          />
                        }
                      />
                    }
                  />
                </div>
              </FormControl>
            </div>
            {this.props.season > 2020 && (
              <div>
                <FormControl>
                  <div>
                    <FormControlLabel
                      label="Disable invoice warning errors"
                      style={labelWidthStyleCheckbox}
                      control={
                        <Checkbox
                          id="chkDisableInvoiceWarningErrorsGenBillTwo"
                          checked={
                            this.props.billOptsAndRate.disableInvoiceWarningErrors == null
                              ? false
                              : this.props.billOptsAndRate.disableInvoiceWarningErrors
                          }
                          onChange={e =>
                            this.props.handleChange(
                              "billOptsAndRate",
                              "disableInvoiceWarningErrors",
                              e.target.checked
                            )
                          }
                          color="primary"
                          value={disableInvoiceWarningErrorsValue}
                          icon={
                            <CheckBoxOutlineBlankIcon
                              style={{ fontSize: 24, color: "#8FC3FF" }}
                              color="secondary"
                            />
                          }
                          checkedIcon={
                            <CheckBoxIcon
                              style={{ fontSize: 24 }}
                              color="primary"
                            />
                          }
                        />
                      }
                    />
                  </div>
                </FormControl>
              </div>
            )}
          </div>
          <br />
        </div>
      </AccessControl>
    );
  }
}

export default GenBillTwo;

import React from 'react';
// External imports
import {
  Button,
  Input,
  NativeSelect,
  FormControl,
  FormHelperText,
  withStyles,
  InputAdornment,
  IconButton,
  Modal,
  Paper,
  Slide,
  Typography,
  Grid,
} from '@material-ui/core';
// Internal imports
import Scanner from '../../Scanner/Scanner.jsx';
import { DOCUMENT_TYPES } from '~/app/constants.js';
import attachIconWhite from '~/images/icons/icons8-attach-2x.png';
import xIcon from '~/images/icons/icons8-multiply.png';
import useSetState from '~/node_modules/react-use/lib/useSetState.js';
// Styling imports
import '~/app/Pages/Returns/components/Document/AttachDocumentModal/css/AttachDocumentModal.css';
import { styles } from '~/app/Pages/Returns/components/Document/AttachDocumentModal/css/AttachDocumentModal.js';

const MAX_DOCUMENT_SIZE = 5000000; // 5MB

/**
 * This component renders the AddDocumentModal modal that displays if a button is clicked to add a document.
 * This component is used separately in ArchivedDocuments and AttachedDocuments.
 * deconstruct the props so we are forced to keep the props passed in consistent.
 *
 * @component AddDocumentModal
 * @category ArchivedDocuments
 **/
const AddDocumentModal = ({
  classes,
  handleCloseDocumentModalOk,
  showSnackBarErr,
  isAddDocumentModalOpen,
  toggleAddDocumentModal,
}) => {
  const [state, setState] = useSetState({
    image: null,
    image_name: '',
    image_description: '',
    image_type: 1,
    attachmentType: '',
    scannerPageOpen: false,
    selectedAddedDocument: -1,
    image_size: 0,
  });

  /**
   * Handles Type dropdown and what the user selects
   *
   * @param {string} value determines what type is selected
   */
  const changeDropDown = value => {
    setState({ image_type: value });
  };

  /** Reset the form when it is saved successfully */
  const resetForm = () => {
    setState({
      image: null,
      image_name: '',
      image_description: '',
      image_type: 1,
      attachmentType: '',
      scannerPageOpen: false,
      selectedAddedDocument: -1,
      image_size: 0,
    });
  };

  /**
   * handles creating input and generating the type list dropdown
   *
   * @param {string} width For determining width of dropdown menu
   * @param {string} height For determining height of dropdown menu
   * @returns {jsx} renders dropdown input
   */
  // TODO --
  // Temporary, should get from DB
  const getDocumentTypeList = () => {
    return (
      <FormControl fullWidth={true} classes={{ root: classes.styledAddDocFormControl }}>
        <label className="add-doc-label">Type</label>
        <NativeSelect
          disableUnderline
          classes={{ root: classes.styledNativeSelect }}
          className="attach-doc-div-two-grid-five-dropdown-options"
          value={state.image_type}
          onChange={e => changeDropDown(e.target.value)}
        >
          <option key="doctype1" value={1}>
            Identification
          </option>
          <option key="doctype2" value={2}>
            Income Sources
          </option>
          <option key="doctype3" value={3}>
            Other Items
          </option>
          <option key="doctype4" value={4}>
            Form W-2
          </option>
          <option key="doctype5" value={5}>
            Form 1099
          </option>
          <option key="doctype6" value={6}>
            Form 1098-T
          </option>
          <option key="doctype7" value={7}>
            Mortgage Statements
          </option>
          <option key="doctype8" value={8}>
            Charity
          </option>
          <option key="doctype9" value={9}>
            Medical
          </option>
          <option key="doctype10" value={10}>
            Dependent
          </option>
          <option key="doctype11" value={11}>
            Child Care
          </option>
          <option key="doctype12" value={12}>
            Self Employment
          </option>
          <option key="doctype13" value={13}>
            Direct Deposit
          </option>
          <option key="doctype14" value={14}>
            Stock Transaction
          </option>
        </NativeSelect>
      </FormControl>
    );
  };

  /**
   * handles uploading document to ArchivedDocument's table
   *
   * @param {Object} e For passing into handleCloseDocumentModalOk function
   * @returns {undefined} empty returns
   */
  const handleDocumentUpload = e => {
    if (state.image == null) {
      showSnackBarErr('A document must be uploaded');
      return;
    }
    if (state.image_description === '') {
      showSnackBarErr('A description must be entered.');
      return;
    }
    // Check Document File type
    if (
      state.attachmentType !== DOCUMENT_TYPES.B64JPG &&
      state.attachmentType !== DOCUMENT_TYPES.B64PNG &&
      state.attachmentType !== DOCUMENT_TYPES.B64PDF &&
      state.attachmentType !== DOCUMENT_TYPES.B64JPEG
    ) {
      showSnackBarErr(
        'Uploaded file is not a valid format. JPEG, PNG, and PDF are allowed file formats',
      );
      return;
    }

    /// Check Document Size
    if (state.image_size > MAX_DOCUMENT_SIZE) {
      showSnackBarErr('Document size must be 5MB or less');
      return;
    }
    handleCloseDocumentModalOk(
      e,
      null,
      null,
      null,
      state.image,
      state.image_description,
      state.image_name,
      state.image_type,
      state.attachmentType,
      null,
    );
    toggleAddDocumentModal();
    resetForm();
  };

  /**
   * handles the client selecting a document to be uploaded
   *
   * @param {Object} e event For preventing page from re-rendering
   * @param {file} uploadedImage Document selected from client
   */
  const handleDocumentSelectFromClient = (e, uploadedImage) => {
    e.preventDefault();

    const reader = new FileReader();
    reader.readAsDataURL(uploadedImage);
    reader.onload = function () {
      setState({
        image: reader.result.split(',')[1], // Can use the ',' to split the type from the string. https://tools.ietf.org/html/rfc4648#section-5 -> no commas in base64 string
        attachmentType: reader.result.split(',')[0],
        image_name: uploadedImage.name, // By Default set name to this.
        image_size: uploadedImage.size,
      });
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  };

  /**
   * Handles if user uploads file then wants to clear it
   */
  const clearUploadedDoc = () => {
    setState({
      image_name: '',
      image: null,
      attachmentType: '',
    });
  };

  /**
   * Opens ScanDocumentPage modal for the client to scan in a document
   */
  const openScanDocumentPage = () => {
    setState({
      scannerPageOpen: true,
      image_name: '',
      image: null,
      attachmentType: '',
    });
  };

  /**
   * Handles closing ScanDocumentPage modal
   */
  const closeScanDocumentPage = () => {
    setState({
      scannerPageOpen: false,
      image_name: '',
      image: null,
      attachmentType: '',
    });
  };

  /**
   * Handles saving scanned image and adding it the uploaded Doc to be saved.
   */
  const saveScannerImage = image => {
    setState({
      image: image.split(',')[1], // Can use the ',' to split the type from the string. https://tools.ietf.org/html/rfc4648#section-5 -> no commas in base64 string
      attachmentType: image.split(',')[0],
      scannerPageOpen: false,
    });
  };

  return (
    <Modal
      id="mdlAddDocumentModalFormViewer"
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={isAddDocumentModalOpen}
      onClose={toggleAddDocumentModal}
    >
      <Paper elevation={5} classes={{ root: classes.addDocumentModal }}>
        <div>
          <Slide direction="up" in={state.scannerPageOpen} mountOnEnter unmountOnExit>
            <Scanner
              closeScannerPage={closeScanDocumentPage}
              changeDocumentName={a => setState({ image_name: a })}
              image_name={state.image_name}
              saveScannerImage={saveScannerImage}
            />
          </Slide>
          <Typography
            variant="body2"
            id="simple-modal-title"
            classes={{ root: classes.styledTypography }}
          >
            <img id="clientNameInfo" className="attach-doc-img-one" src={attachIconWhite} />
            <span className="attach-doc-header">Add Document</span>
          </Typography>
          <div className="attach-doc-div-one">
            <div className="attach-doc-div-two">
              <Grid container justify="flex-end">
                <Grid item xs={9} classes={{ item: classes.styledFlexGrid }}>
                  <Typography classes={{ root: classes.docTitle }} variant="title" gutterBottom>
                    Add Document
                  </Typography>
                </Grid>
                <Grid
                  container
                  justify="flex-end"
                  item
                  xs={3}
                  classes={{ item: classes.styledFlexGrid }}
                >
                  <input
                    className="attach-doc-div-two-grid-two-input-file"
                    id="txtDocSelectAddDocModal"
                    type="file"
                    onChange={e => handleDocumentSelectFromClient(e, e.target.files[0])}
                    accept="image/x-png,application/pdf,image/jpeg"
                  />
                  <label htmlFor="txtDocSelectAddDocModal">
                    <Button
                      classes={{ root: classes.addDocumentBtnOne }}
                      component="span"
                      size="small"
                      onClick={() =>
                        setState({
                          selectedAddedDocument: -1,
                          image_name: '',
                        })
                      }
                    >
                      Browse
                    </Button>
                  </label>
                  <label>
                    <Button
                      id="btnScanAddDocModal"
                      classes={{ root: classes.addDocumentBtn }}
                      size="small"
                      onClick={openScanDocumentPage}
                    >
                      Scan
                    </Button>
                  </label>
                </Grid>
                <Grid item xs={12} classes={{ item: classes.styledFlexGrid }}>
                  <FormControl
                    fullWidth={true}
                    classes={{ root: classes.styledFormControlMarginBottom }}
                  >
                    <Input
                      disableUnderline
                      disabled
                      placeholder="Upload Document"
                      classes={{ root: classes.styledInput }}
                      value={state.image_name}
                      onChange={e => setState({ image_name: e.target.value })}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            disableRipple
                            classes={{ root: classes.docIconClearBtnWithMargin }}
                            id="clearInputField"
                            aria-label="clearInput"
                            onClick={clearUploadedDoc}
                          >
                            <img id="clearInputField" className="noselect" src={xIcon} />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <FormHelperText>IRS only allows attachments of PDF file</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={7} classes={{ item: classes.styledFlexGrid }}>
                  <FormControl fullWidth={true} classes={{ root: classes.styledLeftFormControl }}>
                    <label className="add-doc-label">Document Description</label>
                    <Input
                      id="txtDocDescAddDocModal"
                      classes={{ root: classes.styledInputTwo }}
                      disableUnderline
                      className="attach-doc-div-two-grid-four-input-img-desc"
                      value={state.image_description}
                      onChange={e => setState({ image_description: e.target.value })}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={5} classes={{ item: classes.styledFlexGrid }}>
                  {getDocumentTypeList()}
                </Grid>
                <Grid
                  container
                  justify="flex-end"
                  item
                  xs={12}
                  classes={{ item: classes.styledGridWithMargin }}
                >
                  <div className="attach-doc-div-four">
                    <Button
                      classes={{ root: classes.styleButtonMarginRight }}
                      size="small"
                      color="default"
                      onClick={toggleAddDocumentModal}
                    >
                      Cancel
                    </Button>
                    <Button
                      id="btnAttachAddDocModal"
                      size="small"
                      color="primary"
                      variant="contained"
                      onClick={e => handleDocumentUpload(e)}
                    >
                      Add
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </Paper>
    </Modal>
  );
};

export default withStyles(styles)(AddDocumentModal);

// External imports
import React from 'react';
import { Link, Switch, Route } from 'react-router-dom';
import { Drawer, MenuList, MenuItem, Typography, Collapse, ListItemText } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
// Internal imports
import { useSetState } from '~/app/Utility/customHooks';
import CitiesZipList from './pages/CitiesZipList.jsx';
import EmployersPayersList from './pages/EmployersPayersList.jsx';
import CareProvidersList from './pages/CareProvidersList.jsx';
import ReferralList from './pages/ReferralList.jsx';
import DoneeList from './pages/DoneeList.jsx';
import BankRTNList from './pages/BankRTNList.jsx';
import OccupationList from './pages/OccupationList.jsx';
import SiteIdentifierList from './pages/SiteIdentifierList.jsx';
import UserStatusCodeList from './pages/UserStatusCodeList.jsx';
import EducationalInstitutionList from './pages/EducationalInstitutionList.jsx';
import { sidebarItems } from './databaseSetupHelper.js';
// Redux Import
import { useDispatch, useSelector } from 'react-redux';
import { actions as setupPageActions } from '~/app/redux/setupPages/duck';
// Styling imports
import { withStyles } from '@material-ui/core/styles';
import { styles, themeStyles } from '~/app/Pages/Setup/css/setup.js';

const compStyles = theme => themeStyles(theme);
const DatabaseSetup = ({ classes, isLoading }) => {
  const dispatch = useDispatch();

  const { dbSelectedSidebarItem } = useSelector(state => ({
    dbSelectedSidebarItem: state.setupPageState.dbSelectedSidebarItem,
  }));

  const [state, setState] = useSetState({
    expandedHeaders: ['Database List'],
    sidebarList: [
      {
        header: 'Database List',
      },
    ],
  });

  const handleHeaderClick = header => {
    const headerIndex = state.expandedHeaders.indexOf(header);
    const newexpandedHeaders = [...state.expandedHeaders];

    if (headerIndex === -1) {
      newexpandedHeaders.push(header);
    } else {
      newexpandedHeaders.splice(headerIndex, 1);
    }

    setState({
      expandedHeaders: newexpandedHeaders,
    });
  };

  const handleSidebarItemClick = index => {
    dispatch(setupPageActions.onDBSelectSidebar(index));
  };

  const handleSidebarItemHighlight = index => {
    if (dbSelectedSidebarItem === index) {
      return true;
    }
    return false;
  };

  const sidebarItemList = () => {
    return Object.values(sidebarItems).map((item, index) => {
      return (
        <MenuItem
          key={index}
          component={Link}
          to={item.path}
          classes={classes.menuItemRoot}
          id={`dbSidebarItem-${index}`}
          selected={handleSidebarItemHighlight(index)}
          onClick={() => handleSidebarItemClick(index)}
          button
        >
          <ListItemText
            disableTypography
            primary={
              <Typography type="body2" style={styles.formItemStyle}>
                {item.description}
              </Typography>
            }
            classes={{ root: classes.setupNavItemText }}
          />
        </MenuItem>
      );
    });
  };

  const content = (
    <div className={classes.root}>
      <Drawer variant="permanent" classes={{ paper: classes.drawerPaper }}>
        <MenuList style={{ paddingTop: '1.5rem', paddingLeft: '2.25rem' }}>
          <MenuItem onClick={() => handleHeaderClick(state.sidebarList.header)}>
            {state.expandedHeaders.indexOf(state.sidebarList.header) !== -1 ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )}
            <ListItemText
              style={{ padding: 0 }}
              disableTypography
              primary={
                <Typography type="body2" style={styles.formHeaderStyle}>
                  Database List
                </Typography>
              }
            />
          </MenuItem>
          <Collapse
            in={state.expandedHeaders.indexOf(state.sidebarList.header) === -1}
            timeout="auto"
          >
            {sidebarItemList()}
          </Collapse>
        </MenuList>
      </Drawer>
      {/* <span className="centered-spinner">{loadingContent}</span> */}
      <main className={classes.content + ` page-content-scroll`}>
        <Switch>
          <Route exact path={['/database', sidebarItems.citiesAndZipcodes.path]}>
            <CitiesZipList />
          </Route>
          <Route path={sidebarItems.employersAndPayers.path}>
            <EmployersPayersList />
          </Route>
          <Route path={sidebarItems.careProviders.path}>
            <CareProvidersList />
          </Route>
          <Route path={sidebarItems.referrals.path}>
            <ReferralList />
          </Route>
          <Route path={sidebarItems.donees.path}>
            <DoneeList />
          </Route>
          <Route path={sidebarItems.bankRTNs.path}>
            <BankRTNList />
          </Route>
          <Route path={sidebarItems.occupations.path}>
            <OccupationList />
          </Route>
          <Route path={sidebarItems.siteIdentifiers.path}>
            <SiteIdentifierList />
          </Route>
          <Route path={sidebarItems.userStatusCodes.path}>
            <UserStatusCodeList />
          </Route>
          <Route path={sidebarItems.educationalInstitutions.path}>
            <EducationalInstitutionList />
          </Route>
        </Switch>
      </main>
    </div>
  );
  return content;
};

export default withStyles(compStyles)(DatabaseSetup);

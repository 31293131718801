export const styles = theme => ({
  container: {
    marginTop: '30px',
    padding: theme.spacing.unit * 3,
    height: '100%',
    width: '100%',
    minWidth: '600px',
    backgroundColor: 'white',
  },
  noteModal: {
    top: '40%',
    left: '50%',
    boxShadow: '0 0 20px 0 rgba(0,0,0,0.25)',
    height: 'auto',
    width: '400px',
    padding: '0',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
  },
  gridContainer: {
    padding: theme.spacing.unit * 2,
  },
  btnModal: {
    marginLeft: theme.spacing.unit,
  },
});

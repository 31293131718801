export const styles = {
  paper: {
    width: '40%',
    height: '30%',
    textAlign: 'center',
    position: 'absolute',
    left: '30%',
    top: '30%',
    padding: '3.5rem',
  },
  description: {
    paddingTop: '1rem',
    paddingBottom: '2.5rem',
    fontSize: '1.2rem',
  },
  button: {
    padding: '1.2rem 2rem',
  },
};

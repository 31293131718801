import { UNLOCK_ACCOUNT, actions } from "./duck";

import { takeLatest, call, put } from "redux-saga/effects";
import XlinkAPI from "~/app/api/xlinkAPI";
import ErrorHelpers from "~/app/errorHelpers.js";

export function* watchTechSupport() {
  yield takeLatest(UNLOCK_ACCOUNT, unlockAccount);
}

function* unlockAccount(payload) {
  try {
    let endpoint = `${BASE_URL}/authz/unlockaccount`;
    yield call(XlinkAPI.post, endpoint, {
      identifier: payload.identifier
    });
    yield put();
  } catch (error) {
    ErrorHelpers.handleError("Unable to unlock account", error);
  }
}

import React from 'react';
// Internal imports
import { useSetState } from '~/app/Utility/customHooks';
// External imports
import {
  Paper,
  Typography,
  IconButton,
  Grid,
  FormControl,
  Button,
  InputLabel,
  Select,
  Modal,
  withStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
// Styling imports
import { styles } from '~/app/Pages/Setup/ManageLicencing/css/manageLicencing.js';
import { styles as pagedTableStyles } from '~/app/Components/Common/css/pagedTableStyles.jsx';

const AddLicenseModal = props => {
  const [state, setState] = useSetState({
    selectedLicense: 'XLCO', // 1040 default
  });

  return (
    <Modal open={props.isOpen} disableBackdropClick={true}>
      <Paper elevation={5} style={styles.settingsModals}>
        <Typography
          id="simple-modal-title"
          style={{
            color: 'white',
            fontFamily: 'Roboto',
            fontSize: '18px',
            paddingTop: '18px',
            paddingLeft: '5%',
            paddingRight: '15px',
          }}
        >
          Add License
          <IconButton aria-label="Close" style={styles.closeIconModal} onClick={props.onClose}>
            <CloseIcon />
          </IconButton>
        </Typography>

        <Grid container>
          <Grid item xs={12}>
            <Grid container style={styles.gridSubTable}>
              <Grid item xs={12} style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
                <FormControl style={styles.licenseDropdown}>
                  <InputLabel htmlFor="office-select">Select License Type</InputLabel>
                  <Select
                    native
                    value={state.selectedLicense}
                    disableUnderline
                    onChange={event =>
                      setState({
                        selectedLicense: event.target.value,
                      })
                    }
                  >
                    <option value="XLCO" className="option-license-assign">
                      1040 License
                    </option>

                    <option
                      value="COBU"
                      className="option-license-assign"
                      disabled={props.season < 2024}
                    >
                      Business License
                    </option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid container style={styles.footerDivider} alignItems="flex-end" justify="flex-end">
                <Grid item xs={8} style={styles.justifyRight}>
                  {/* Spacer */}
                </Grid>
                <Grid item xs={2} style={styles.justifyRight}>
                  <Button onClick={props.onClose}>Cancel</Button>
                </Grid>
                <Grid item xs={2} style={styles.justifyRight}>
                  <Button
                    onClick={() => {
                      props.onClose();
                      props.addProductLicense(state.selectedLicense);
                    }}
                    color="primary"
                    variant="contained"
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
};

export default withStyles({ ...styles, ...pagedTableStyles })(AddLicenseModal);

// External imports
import React, { useEffect } from 'react';
import {
  Paper,
  Typography,
  IconButton,
  Grid,
  Button,
  Modal,
  withStyles,
  Divider,
} from '@material-ui/core';
import RGL, { WidthProvider } from 'react-grid-layout';
import CloseIcon from '@material-ui/icons/Close';
// Internal imports
import {
  INDIVIDUAL_RETURN_PRINT_ORDER_DEFUALT,
  BUSINESS_RETURN_PRINT_ORDER_DEFUALT,
} from '../Helper/printSetupConstants.js';
import { useSetState } from '~/app/Utility/customHooks';
// Styling imports
import { styles } from '~/app/Components/NewAccount/SingleOffice/css/printSetup.js';
import '~/app/Components/NewAccount/SingleOffice/css/printingSetup.css';

const ReactGridLayout = WidthProvider(RGL);

/**
 * PrintOrderModal
 * Modal to display Print Order and allow for customization
 * @component
 *
 */
const PrintOrderModal = props => {
  const [state, setState] = useSetState({
    layout: [],
    printList: [],
    newOrder: {},
  });

  const generatePrintList = () => {
    const defaultPrintOrder = props.isBusiness
      ? BUSINESS_RETURN_PRINT_ORDER_DEFUALT
      : INDIVIDUAL_RETURN_PRINT_ORDER_DEFUALT;

    const keyPrefix = props.isBusiness ? 'CP' : 'PO';
    return defaultPrintOrder.map((_, idx) => {
      const adjustedIdx = ++idx;
      const keyIndex = adjustedIdx < 10 ? '0' + adjustedIdx : adjustedIdx;
      const key = keyPrefix + keyIndex;

      return (
        <div className="print-order-item" key={key} xs={12} data-grid={{ x: 1, y: 1, w: 1, h: 1 }}>
          <Divider />
          <div className="print-order-item-text">{props?.fieldMap?.[key]?.configValue}</div>
        </div>
      );
    });
  };
  useEffect(() => {
    setState({ printList: generatePrintList() });
  }, [props.fieldMap]);

  const resetPrintList = () => {
    const defaultOrder = {};

    const defaultPrintOrder = props.isBusiness
      ? BUSINESS_RETURN_PRINT_ORDER_DEFUALT
      : INDIVIDUAL_RETURN_PRINT_ORDER_DEFUALT;

    const keyPrefix = props.isBusiness ? 'CP' : 'PO';
    const defaultOrderUI = defaultPrintOrder.map((item, idx) => {
      const adjustedIdx = ++idx;
      const keyIndex = adjustedIdx < 10 ? '0' + adjustedIdx : adjustedIdx;
      const key = keyPrefix + keyIndex;

      const defaultValue = {
        ...props?.fieldMap?.[key],
        configValue: defaultPrintOrder[idx - 1],
      };
      defaultOrder[key] = defaultValue;

      return (
        <div className="print-order-item" key={key} xs={12} data-grid={{ x: 1, y: 1, w: 1, h: 1 }}>
          <Divider />
          <div className="print-order-item-text">{defaultPrintOrder[idx - 1]}</div>
        </div>
      );
    });
    setState({ printList: defaultOrderUI, layout: [], newOrder: {} });
    props.updateFieldMap(defaultOrder);
  };

  const onLayoutChange = layout => {
    const keyPrefix = props.isBusiness ? 'CP' : 'PO';

    const newOrder = {};
    for (let idx = 0; idx < layout.length; idx++) {
      const originalKey = layout[idx].i;

      const newPosition = layout[idx].y + 1;
      const newKeyIndex = newPosition < 10 ? '0' + newPosition : newPosition;
      const key = keyPrefix + newKeyIndex;
      const updatedValue = {
        ...props?.fieldMap?.[originalKey],
        configID: props?.fieldMap?.[key].configID, // maintain original configId
      };
      newOrder[key] = updatedValue;
    }

    setState({
      layout: layout,
      newOrder: newOrder,
    });
  };

  const onSave = async () => {
    props.updateFieldMap(state.newOrder);
    props.saveOrder(false, state.newOrder);
    setState({ layout: [] });
    props.closeModal();
  };

  const onClose = () => {
    setState({ layout: [] });
    props.closeModal();
  };

  return (
    <Modal open={props.isPrintOrderModalOpen} disableBackdropClick={true}>
      <Paper elevation={5} style={styles.settingsModals}>
        <Typography id="simple-modal-title" style={styles.modalHeaderBar}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>Print Order</Grid>
            <Grid item>
              <IconButton aria-label="Close" style={styles.closeIconModal} onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Typography>
        <Grid container>
          <Grid item xs={12} style={styles.gridTableHeader}>
            Drag and and drop print items to change the order:
          </Grid>
          <Grid container xs={12} style={styles.printListHeader}>
            <Grid xs={4} style={styles.headerText}>
              Print Order
            </Grid>
            <Grid item xs={8} style={{ ...styles.justifyRight, ...{ paddingRight: '15px' } }}>
              <Button onClick={resetPrintList}>Reset to Defualt</Button>
            </Grid>
          </Grid>
          <Grid item xs={12} style={styles.printList}>
            <div>
              <ReactGridLayout
                cols={1}
                rowHeight={20}
                isDraggable={true}
                isResizable={false}
                isBounded={true}
                layout={state.layout}
                onLayoutChange={e => onLayoutChange(e)}
              >
                {state.printList}
              </ReactGridLayout>
              <Divider />
            </div>
          </Grid>

          <Grid container style={styles.footerDivider} alignItems="flex-end" justify="flex-end">
            <Grid item xs={8} style={styles.justifyRight}>
              {/* Spacer */}
            </Grid>
            <Grid item xs={2} style={styles.justifyRight}>
              <Button onClick={onClose}>Cancel</Button>
            </Grid>
            <Grid item xs={2} style={styles.justifyRight}>
              <Button id="btnSavePrintingSetup" color="primary" onClick={onSave}>
                Save
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={12}></Grid>
        </Grid>
      </Paper>
    </Modal>
  );
};

export default withStyles({ ...styles })(PrintOrderModal);

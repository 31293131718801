import React, { Component } from 'react';
// External imports
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  FormControl,
  FormControlLabel,
  Input,
  Typography,
  withStyles,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
// Styling imports
import { styles } from '~/app/Pages/Returns/components/Print/css/dialogStyles.js';
import './css/print.css';
class SuggestedPasswordDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      // Save value for password needed to open encrypted pdf document
      updatedPdfPassword: '',
      // Save email addresses
      tpEmail: '',
      spEmail: '',
      repEmail: '',
      // Variables to keep track of the checkboxes selected when emailing PDF
      tpSelected: false,
      spSelected: false,
      repSelected: false,
      // Variables to keep track of the checkboxes selected when emailing password
      tpSelectedEPass: false,
      spSelectedEPass: false,
      repSelectedEPass: false,
      // Allow user to update email subject
      emailSubject: '',
      // Add taxpayer and spouse cellphone number and carrier for texting encrypted PDF password
      tpAllowText: '',
      tpCellPhoneNumber: '',
      tpCellPhoneCarrier: '',
      tpCellPhoneDomain: '',
      spAllowText: '',
      spCellPhoneNumber: '',
      spCellPhoneCarrier: '',
      spCellPhoneDomain: '',
      // Variables to keep track of the checkboxes selected when texting password
      tpSelectedText: false,
      spSelectedText: false,
      repSelectedText: false,
    };
  }

  componentDidMount = () => {
    this.setState({
      updatedPdfPassword: this.props.encryptedPwd.toUpperCase(),
      emailSubject: this.props.encryptedTitle.toUpperCase(),
      tpEmail: this.props.taxpayerEmail.toUpperCase(),
      spEmail: this.props.spouseEmail.toUpperCase(),
      repEmail: this.props.preparerEmail.toUpperCase(),
      // For the "Text Password and Email PDF Document to" section, check if textlink is activated, if not, do not show that option
      tpAllowText: this.props.tpAllowText,
      tpCellPhoneNumber: this.props.tpCellPhoneNumber,
      tpCellPhoneCarrier: this.props.tpCellPhoneCarrier,
      tpCellPhoneDomain: this.props.tpCellPhoneDomain,
      spCellPhoneNumber: this.props.spCellPhoneNumber,
      spCellPhoneCarrier: this.props.spCellPhoneCarrier,
      spCellPhoneDomain: this.props.spCellPhoneDomain,
      spAllowText: this.props.spAllowText,
    });
  };

  // Handle onChange without validation for checkbox
  handleCheckbox = event => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  handlePdfPasswordChange = e => {
    this.setState({
      updatedPdfPassword: e.target.value.trim(),
    });
  };

  handleSubjectChange = e => {
    this.setState({ emailSubject: e.target.value.toUpperCase() });
  };

  handleNewEncryptedPsw = () => {
    this.props.setEncryptedPassword(
      this.state.updatedPdfPassword,
      this.state.emailSubject,
      this.state.tpSelected,
      this.state.spSelected,
      this.state.repSelected,
      this.state.tpSelectedEPass,
      this.state.spSelectedEPass,
      this.state.repSelectedEPass,
      this.state.tpSelectedText,
      this.state.spSelectedText,
      this.state.repSelectedText,
    );
  };

  render() {
    const { classes } = this.props;
    const optionsSelected =
      this.state.tpSelected ||
      this.state.spSelected ||
      this.state.repSelected ||
      this.state.tpSelectedEPass ||
      this.state.spSelectedEPass ||
      this.state.repSelectedEPass ||
      this.state.tpSelectedText ||
      this.state.spSelectedText ||
      this.state.repSelectedText;

    // Flag to tell if we can text the password and email a copy of the encrypted pdf document to taxpayer
    // For true, we need to have an email address, a cell phone number, and a cell phone carrier for taxpayer
    const taxpayerTextOption =
      this.state.tpCellPhoneNumber.length < 1 ||
      this.state.tpCellPhoneCarrier.length < 1 ||
      this.state.tpCellPhoneDomain.length < 1 ||
      this.state.tpAllowText.length < 1;

    // Flag if we can text the password and email a copy of the encrypted pdf to spouse
    // For the flag to be true we need to have an email address for spouse, a cell phone number, and the cell phone carrier for the spouse
    const spouseTextOption =
      this.state.spCellPhoneNumber.length < 1 ||
      this.state.spCellPhoneCarrier.length < 1 ||
      this.state.spCellPhoneDomain.length < 1 ||
      this.state.spAllowText.length < 1;

    return (
      <div>
        {this.state.open && (
          <Dialog
            disableAutoFocus={true}
            PaperProps={{ tabIndex: -1 }}
            open={true}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle
              className={classes.styledHeader}
              id="form-dialog-title"
              disableTypography
              style={{ minWidth: '400px' }}
            >
              <div style={{ display: 'inline-block', width: '92%' }}>
                <Typography
                  style={{
                    color: 'white',
                    fontFamily: 'Roboto',
                    fontSize: '18px',
                    paddingTop: '4%',
                    paddingLeft: '5%',
                  }}
                >
                  Email Encrypted PDF
                </Typography>
              </div>
              <div style={{ display: 'inline-block' }}>
                <Button
                  className={classes.toolbarButtonRoot}
                  style={{
                    minWidth: '0',
                    minHeight: '0',
                    paddingLeft: '5px',
                    paddingRight: '5px',
                  }}
                  onClick={this.props.handleClose}
                >
                  X
                </Button>
              </div>
            </DialogTitle>
            <DialogContent style={{ paddingTop: '15px', paddingBottom: '10px' }}>
              <span id="spnSuggestedPswModal" style={{ paddingRight: '11px' }}>
                <div className="initial-block-encrypted-pdf">PDF Password:</div>
                <div className="encrypted-pdf">
                  <div className="large-text-title-bar">
                    <FormControl required>
                      <Input
                        id="txtUsernameLogin"
                        name="updatedPdfPassword"
                        value={this.state.updatedPdfPassword}
                        onChange={this.handlePdfPasswordChange}
                        className="email-pdf-mod-input"
                        disableUnderline
                      />
                    </FormControl>
                  </div>
                </div>
                <div>
                  <div className="initial-block-encrypted-pdf">Email Subject:</div>
                  <div className="encrypted-pdf">
                    <div className="large-text-title-bar">
                      <FormControl required>
                        <Input
                          id="txtUsernameLogin"
                          name="emailSubject"
                          value={this.state.emailSubject}
                          onChange={this.handleSubjectChange}
                          className="email-pdf-mod-input"
                          disableUnderline
                        />
                      </FormControl>
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: '10px' }}>Email PDF Document to:</div>
                <div className="email-pdf-mod-div-space">
                  <FormControl required component="fieldset">
                    <FormGroup>
                      <div style={{ marginTop: '0vh', marginBottom: '0vh' }}>
                        <div className="email-pdf-mod-div-1">
                          <div className="email-pdf-mod-div-2">
                            <FormControlLabel
                              label="Taxpayer"
                              className={classes.labelWidthStyleCheckbox}
                              control={
                                <Checkbox
                                  className="email-pdf-mod-checkbox"
                                  icon={
                                    <CheckBoxOutlineBlankIcon
                                      style={{ fontSize: 24 }}
                                      color="secondary"
                                    />
                                  }
                                  checkedIcon={
                                    <CheckBoxIcon style={{ fontSize: 24 }} color="primary" />
                                  }
                                  id="chkTpSelectedEncryptedPDF"
                                  name="tpSelected"
                                  checked={this.state.tpSelected}
                                  onChange={this.handleCheckbox}
                                  inputProps={{
                                    height: '20px',
                                    width: '20px',
                                  }}
                                  disabled={this.state.tpEmail === ''}
                                />
                              }
                            />
                          </div>
                        </div>
                        {this.props.showSpouseOption && (
                          <div className="email-pdf-mod-div-3">
                            <div className="email-pdf-mod-div-2">
                              <FormControlLabel
                                label="Spouse"
                                className={classes.labelWidthStyleCheckbox}
                                control={
                                  <Checkbox
                                    className="email-pdf-mod-checkbox"
                                    icon={
                                      <CheckBoxOutlineBlankIcon
                                        style={{ fontSize: 24 }}
                                        color="secondary"
                                      />
                                    }
                                    checkedIcon={
                                      <CheckBoxIcon style={{ fontSize: 24 }} color="primary" />
                                    }
                                    id="chkSpSelectedEncryptedPDF"
                                    name="spSelected"
                                    checked={this.state.spSelected}
                                    onChange={this.handleCheckbox}
                                    inputProps={{
                                      height: '20px',
                                      width: '20px',
                                    }}
                                    disabled={this.state.spEmail === ''}
                                  />
                                }
                              />
                            </div>
                          </div>
                        )}
                        <div className="email-pdf-mod-div-3">
                          {false && this.state.repEmail !== '' && (
                            <div className="email-pdf-mod-div-2">
                              <FormControlLabel
                                label="Representative"
                                className={classes.labelWidthStyleCheckbox}
                                control={
                                  <Checkbox
                                    className="email-pdf-mod-checkbox"
                                    icon={
                                      <CheckBoxOutlineBlankIcon
                                        style={{ fontSize: 24 }}
                                        color="secondary"
                                      />
                                    }
                                    checkedIcon={
                                      <CheckBoxIcon style={{ fontSize: 24 }} color="primary" />
                                    }
                                    id="chkReppSelectedEncryptedPDF"
                                    name="repSelected"
                                    checked={this.state.repSelected}
                                    onChange={this.handleCheckbox}
                                    inputProps={{
                                      height: '20px',
                                      width: '20px',
                                    }}
                                    disabled={this.state.repEmail === ''}
                                  />
                                }
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </FormGroup>
                  </FormControl>
                </div>
                <div style={{ marginTop: '10px' }}>Email Password to:</div>
                <div className="email-pdf-mod-div-space">
                  <FormControl required component="fieldset">
                    <FormGroup>
                      <div style={{ marginTop: '0vh', marginBottom: '0vh' }}>
                        <div className="email-pdf-mod-div-1">
                          <div className="email-pdf-mod-div-2">
                            <FormControlLabel
                              label="Taxpayer"
                              className={classes.labelWidthStyleCheckbox}
                              control={
                                <Checkbox
                                  className="email-pdf-mod-checkbox"
                                  icon={
                                    <CheckBoxOutlineBlankIcon
                                      style={{ fontSize: 24 }}
                                      color="secondary"
                                    />
                                  }
                                  checkedIcon={
                                    <CheckBoxIcon style={{ fontSize: 24 }} color="primary" />
                                  }
                                  id="chkTpSelectedEmailPass"
                                  name="tpSelectedEPass"
                                  checked={this.state.tpSelectedEPass}
                                  onChange={this.handleCheckbox}
                                  inputProps={{
                                    height: '20px',
                                    width: '20px',
                                  }}
                                  disabled={
                                    this.state.tpEmail === '' ||
                                    this.state.updatedPdfPassword === ''
                                  }
                                />
                              }
                            />
                          </div>
                        </div>
                        {this.props.showSpouseOption && (
                          <div className="email-pdf-mod-div-3">
                            <div className="email-pdf-mod-div-2">
                              <FormControlLabel
                                label="Spouse"
                                className={classes.labelWidthStyleCheckbox}
                                control={
                                  <Checkbox
                                    className="email-pdf-mod-checkbox"
                                    icon={
                                      <CheckBoxOutlineBlankIcon
                                        style={{ fontSize: 24 }}
                                        color="secondary"
                                      />
                                    }
                                    checkedIcon={
                                      <CheckBoxIcon style={{ fontSize: 24 }} color="primary" />
                                    }
                                    id="chkSpSelectedEmailPass"
                                    name="spSelectedEPass"
                                    checked={this.state.spSelectedEPass}
                                    onChange={this.handleCheckbox}
                                    inputProps={{
                                      height: '20px',
                                      width: '20px',
                                    }}
                                    disabled={
                                      this.state.spEmail === '' ||
                                      this.state.updatedPdfPassword === ''
                                    }
                                  />
                                }
                              />
                            </div>
                          </div>
                        )}
                        <div className="email-pdf-mod-div-3">
                          {false && this.state.repEmail !== '' && (
                            <div className="email-pdf-mod-div-2">
                              <FormControlLabel
                                label="Representative"
                                className={classes.labelWidthStyleCheckbox}
                                control={
                                  <Checkbox
                                    className="email-pdf-mod-checkbox"
                                    icon={
                                      <CheckBoxOutlineBlankIcon
                                        style={{ fontSize: 24 }}
                                        color="secondary"
                                      />
                                    }
                                    checkedIcon={
                                      <CheckBoxIcon style={{ fontSize: 24 }} color="primary" />
                                    }
                                    id="chkReppSelectedEmailPass"
                                    name="repSelectedEPass"
                                    checked={this.state.repSelectedEPass}
                                    onChange={this.handleCheckbox}
                                    inputProps={{
                                      height: '20px',
                                      width: '20px',
                                    }}
                                    disabled={
                                      this.state.repEmail === '' ||
                                      this.state.updatedPdfPassword === ''
                                    }
                                  />
                                }
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </FormGroup>
                  </FormControl>
                </div>
                {this.props.isTextLinkOn && (
                  <div style={{ marginTop: '10px' }}>Text Password to:</div>
                )}
                {this.props.isTextLinkOn && (
                  <div className="email-pdf-mod-div-space">
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <div style={{ marginTop: '0vh', marginBottom: '0vh' }}>
                          <div className="email-pdf-mod-div-1">
                            <div className="email-pdf-mod-div-2">
                              <FormControlLabel
                                label="Taxpayer"
                                className={classes.labelWidthStyleCheckbox}
                                control={
                                  <Checkbox
                                    className="email-pdf-mod-checkbox"
                                    icon={
                                      <CheckBoxOutlineBlankIcon
                                        style={{ fontSize: 24 }}
                                        color="secondary"
                                      />
                                    }
                                    checkedIcon={
                                      <CheckBoxIcon style={{ fontSize: 24 }} color="primary" />
                                    }
                                    id="chkTpSelectedTextEncryptedPDF"
                                    name="tpSelectedText"
                                    checked={this.state.tpSelectedText}
                                    onChange={this.handleCheckbox}
                                    inputProps={{
                                      height: '20px',
                                      width: '20px',
                                    }}
                                    disabled={
                                      taxpayerTextOption || this.state.updatedPdfPassword === ''
                                    }
                                  />
                                }
                              />
                            </div>
                          </div>
                          {this.props.showSpouseOption && (
                            <div className="email-pdf-mod-div-3">
                              <div className="email-pdf-mod-div-2">
                                <FormControlLabel
                                  label="Spouse"
                                  className={classes.labelWidthStyleCheckbox}
                                  control={
                                    <Checkbox
                                      className="email-pdf-mod-checkbox"
                                      icon={
                                        <CheckBoxOutlineBlankIcon
                                          style={{ fontSize: 24 }}
                                          color="secondary"
                                        />
                                      }
                                      checkedIcon={
                                        <CheckBoxIcon style={{ fontSize: 24 }} color="primary" />
                                      }
                                      id="chkSpSelectedTextEncryptedPDF"
                                      name="spSelectedText"
                                      checked={this.state.spSelectedText}
                                      onChange={this.handleCheckbox}
                                      inputProps={{
                                        height: '20px',
                                        width: '20px',
                                      }}
                                      disabled={
                                        spouseTextOption || this.state.updatedPdfPassword === ''
                                      }
                                    />
                                  }
                                />
                              </div>
                            </div>
                          )}
                          <div className="email-pdf-mod-div-3">
                            {false && this.state.repEmail !== '' && (
                              <div className="email-pdf-mod-div-2">
                                <FormControlLabel
                                  label="Representative"
                                  className={classes.labelWidthStyleCheckbox}
                                  control={
                                    <Checkbox
                                      className="email-pdf-mod-checkbox"
                                      icon={
                                        <CheckBoxOutlineBlankIcon
                                          style={{ fontSize: 24 }}
                                          color="secondary"
                                        />
                                      }
                                      checkedIcon={
                                        <CheckBoxIcon style={{ fontSize: 24 }} color="primary" />
                                      }
                                      id="chkReppSelectedTextEncryptedPDF"
                                      name="repSelectedText"
                                      checked={this.state.repSelectedText}
                                      onChange={this.handleCheckbox}
                                      inputProps={{
                                        height: '20px',
                                        width: '20px',
                                      }}
                                      disabled={
                                        this.state.repEmail === '' ||
                                        this.state.updatedPdfPassword === ''
                                      }
                                    />
                                  }
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </FormGroup>
                    </FormControl>
                  </div>
                )}
                <div
                  style={{
                    paddingLeft: '0px',
                    marginLeft: '0px',
                    border: 'none',
                  }}
                >
                  <Button
                    id="btnErrorModalCloseSuggestedPassword"
                    style={{
                      paddingLeft: '0px',
                      marginLeft: '0px',
                      border: 'none',
                    }}
                    onClick={() => {
                      this.props.handleOptionNotAvailable();
                    }}
                  >
                    Desired Option to Email Attached Document Not Available?
                  </Button>
                </div>
              </span>
            </DialogContent>
            <DialogActions style={{ paddingRight: '0.5vw', paddingBottom: '0.5vh' }}>
              <Button
                onClick={this.props.handleClose}
                id="btnCancelSuggestedPsw"
                color="primary"
                className={classes.cancelButtonRoot}
              >
                Cancel
              </Button>
              <Button
                onClick={this.handleNewEncryptedPsw}
                id="btnOKSuggestedPsw"
                color="primary"
                disabled={!optionsSelected}
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(SuggestedPasswordDialog);

export const styles = {
  toolbarColor: {
    backgroundColor: '#444A59',
  },
  paper: {
    height: '350px',
    width: '570px',
    borderRadius: '4px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  cancelButton: {
    paddingLeft: '10px',
    paddingRight: '10px',
    minWidth: '0px',
    minHeight: '3.5vh',
    textTransform: 'capitalize',
    fontFamily: 'Roboto',
    fontSize: '14px',
    borderRadius: '4px',
    marginRight: '1.5vw',
  },
  updateButton: {
    paddingLeft: '10px',
    paddingRight: '10px',
    minWidth: '0px',
    minHeight: '3.5vh',
    textTransform: 'capitalize',
    fontFamily: 'Roboto',
    fontSize: '14px',
    borderRadius: '4px',
  },
};

import * as Global from '~/app/constants.js';
import * as Common from '~/app/redux/commonActions.js';
import update from 'immutability-helper';

const SELECT_FILTER = 'xlinkonline/officeList/SELECT';
const PAGE_CHANGE = 'xlinkonline/officeList/PAGECHANGE';
const UPDATE_LIST_FILTER = 'xlinkonline/officeList/UPDATELISTFILTER';
const RESET_OFFICE_FILTER = 'xlinkonline/officeList/RESETOFFICEFILTER';
const UPDATE_OFFICE_LIST_SORT_ORDER = 'xlinkonline/officeList/UPDATEOFFICELISTSORTORDER';
const UPDATE_ACTIVE_COL_IDX = 'xlinkonline/officeList/UPDATEACTIVECOLIDX';
const REDIRECT_FROM_OVERVIEW = 'xlinkonline/overview/REDIRECTFROMOVERVIEW';
const RESET_COLUMNS_TO_DEFAULT = 'xlinkonline/officeList/RESETCOLUMNSTODEFAULT';
const RESET_COLUMNS = 'xlinkonline/officeList/RESETCOLUMNS';
const CLEAR_OFFICES_LIST = 'xlinkonline/officeList/CLEAROFFICESLIST';

export const REQUESTED_OFFICE_LIST = 'xlinkonline/officeList/REQUESTEDOFFICELIST';
export const REQUESTED_OFFICE_LIST_FAILED = 'xlinkonline/officeList/REQUESTEDOFFICEFAILED';
export const REQUESTED_OFFICE_LIST_SUCCEEDED = 'xlinkonline/officeList/REQUESTEDOFFICESUCCEEDED';
export const FETCHED_OFFICE_LIST = 'xlinkonline/officeList/FETCHEDOFFICELIST';
export const UPDATE_OFFICE_LIST_VAL = 'xlinkonline/officeList/UPDATE_OFFICE_LIST_VAL';

export const initialState = {
  filterValues: {
    first: '', // 1st filter text box value
    second: '', // 2nd filter text box value (for filtering by date range)
  },
  sortOrder: {
    column: 0, // default sort is by last name
    order: 'asc', // default sort is in ascending order
  },

  // all possible table columns. name is for column title, col is for query params, asc and desc are for sort dropdown options,
  // details is for customize column modal
  allColumns: [
    {
      name: 'Name',
      col: 'name',
      asc: 'Sort By A-Z',
      desc: 'Sort By Z-A',
      details: 'Office Name',
    },
    {
      name: 'Location',
      col: 'location',
      asc: 'Sort By A-Z',
      desc: 'Sort By Z-A',
      details: 'Location',
    },
    {
      name: 'Staff',
      col: 'staff',
      asc: 'Least to Most',
      desc: 'Most to Least',
      details: 'Staff Count',
    },
    {
      name: 'Returns',
      col: 'returns',
      asc: 'Least to Most',
      desc: 'Most to Least',
      details: 'Return Count',
    },
    { name: 'Action', col: 'action', asc: 'Sort By A-Z', desc: 'Sort By Z-A' }, // always display this column
    {
      name: 'User ID',
      col: 'user_id',
      asc: 'Sort By Low to High',
      desc: 'Sort By High to Low',
      details: 'Office user ID',
    },
    {
      name: 'EFIN',
      col: 'efin',
      asc: 'Sort By Low to High',
      desc: 'Sort By High to Low',
      details: 'Tax Return EFIN Number',
    },
    {
      name: 'State',
      col: 'state',
      asc: 'Sort By A-Z',
      desc: 'Sort By Z-A',
      details: 'State',
    },
    {
      name: 'OFFICE TYPE',
      col: 'is_feeder_office',
      asc: 'Sort By Feeder',
      desc: 'Sort By Transmit Office',
      details: 'Office Type',
    },
    {
      name: 'ERO SIGNATURE',
      col: 'signature',
      asc: 'Sort By Signed',
      desc: 'Sort By Unsigned',
      details: 'Signature Status',
    },
  ],

  // active columns, array index is column position in table and value is an index into allColumns
  activeColIndexes: [0, 1, 6, 2, 3, 8, 9, 4], // default column titles

  // all filters, name is displayed in the filter dropdown, filter is for query params
  // the order of these must match the order of OFFICE_LIST_FILTERS in constants.js
  allFilters: [
    { name: 'All Offices', filter: 'all' },
    { name: 'Office Name', filter: 'name' },
  ],

  loading: false,
  error: false,

  // current filter criteria, index into allFilters
  activeFilter: Global.OFFICE_LIST_FILTERS.ALL,

  perPage: Global.DASHBOARD_DATA.DASHBOARDLISTSIZE,
  currentPage: 1,
  offices: [], // a single page of offices matching the search criteria and pagination requirements
  totalOffices: -1, // number of offices meeting the search criteria (used by pagination component)
  initialUnfilteredOffices: -1,
};

export const actions = {
  onSelectOfficeListFilter: choice => ({
    type: SELECT_FILTER,
    choice,
  }),
  onOfficePageChange: page => ({
    type: PAGE_CHANGE,
    page,
  }),
  updateOfficeListFilterValues: (first, second, currentPage) => ({
    type: UPDATE_LIST_FILTER,
    first,
    second,
    currentPage,
  }),
  resetOfficeFilter: () => ({
    type: RESET_OFFICE_FILTER,
  }),
  updateOfficeListSortOrder: (col, order) => ({
    type: UPDATE_OFFICE_LIST_SORT_ORDER,
    col,
    order,
  }),
  updateActiveColIdx: (newVal, colIdx) => ({
    type: UPDATE_ACTIVE_COL_IDX,
    newVal,
    colIdx,
  }),
  redirectFromOverview: () => ({
    type: REDIRECT_FROM_OVERVIEW,
  }),
  resetColumnsToDefault: () => ({
    type: RESET_COLUMNS_TO_DEFAULT,
  }),
  resetColumns: cols => ({
    type: RESET_COLUMNS,
    cols,
  }),
  clearOfficesList: () => ({
    type: CLEAR_OFFICES_LIST,
  }),
  requestOfficeList: () => ({
    type: REQUESTED_OFFICE_LIST,
  }),
  requestOfficeListSuccess: officeListData => ({
    type: REQUESTED_OFFICE_LIST_SUCCEEDED,
    officeListData,
  }),
  requestOfficeListError: () => ({
    type: REQUESTED_OFFICE_LIST_FAILED,
  }),
  fetchOfficeList: filterParams => ({
    type: FETCHED_OFFICE_LIST,
    filterParams,
  }),
  updateOfficeListVal: (index, key, val) => ({
    type: UPDATE_OFFICE_LIST_VAL,
    index,
    key,
    val,
  }),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_FILTER:
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          first: '',
          second: '',
        },
        activeFilter: action.choice,
      };
    case UPDATE_LIST_FILTER:
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          first: action.first,
          second: action.second,
        },
        currentPage: action.currentPage,
      };
    case PAGE_CHANGE:
      return {
        ...state,
        currentPage: action.page,
      };
    case RESET_OFFICE_FILTER:
      return {
        ...state,
        activeFilter: 0,
      };
    case UPDATE_OFFICE_LIST_SORT_ORDER:
      return {
        ...state,
        sortOrder: {
          ...state.sortOrder,
          column: action.col,
          order: action.order,
        },
      };
    case UPDATE_ACTIVE_COL_IDX:
      // eslint-disable-next-line no-case-declarations
      const cols = state.activeColIndexes.map((item, idx) =>
        idx === action.colIdx ? action.newVal : item,
      );
      return {
        ...state,
        activeColIndexes: cols,
      };
    case REDIRECT_FROM_OVERVIEW:
      return {
        ...state,
        activeFilter: Global.OFFICE_LIST_FILTERS.ALL,
      };
    case RESET_COLUMNS_TO_DEFAULT:
      return {
        ...state,
        activeColIndexes: [0, 1, 2, 3, 8, 4],
      };
    case RESET_COLUMNS:
      return {
        ...state,
        activeColIndexes: action.cols,
      };
    case CLEAR_OFFICES_LIST:
      return {
        ...state,
        offices: [],
        currentPage: 1,
        totalOffices: -1,
        unfilteredTotalOffices: -1,
      };
    case Common.LOGOUT:
      return {
        ...state,
        offices: [],
        currentPage: 1,
        totalOffices: -1,
        initialUnfilteredOffices: -1,
      };
    case REQUESTED_OFFICE_LIST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case REQUESTED_OFFICE_LIST_SUCCEEDED:
      return {
        ...state,
        offices: action.officeListData.list,
        totalOffices: action.officeListData.total,
        initialUnfilteredOffices: action.officeListData.unfilteredTotal,
        loading: false,
        error: false,
      };
    case UPDATE_OFFICE_LIST_VAL:
      // eslint-disable-next-line no-case-declarations
      const newOfficeList = update(state.offices, {
        [action.index]: { [action.key]: { $set: action.val } },
      });
      return {
        ...state,
        offices: newOfficeList,
      };
    case REQUESTED_OFFICE_LIST_FAILED:
      return {
        ...state,
        offices: [],
        totalOffices: -1,
        initialUnfilteredOffices: -1,
        loading: false,
        error: true,
      };
  }
  return state;
}

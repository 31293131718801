export const styles = {
  toolbarColor: { backgroundColor: '#444A59' },
  cancelButton: {
    height: '1.875rem',
    border: '0rem',
    backgroundColor: '#FFFFFF',
    textTransform: 'capitalize',
    lineHeight: '0em',
    marginRight: '1em',
  },
  buttonContainer: {
    marginTop: '3.4375rem',
    textAlign: 'right',
    position: 'relative',
    left: '0.9375rem',
    marginBottom: '-1.875rem',
  },
  closeButton: {
    color: '#FFFFFF',
    fontFamily: 'Roboto',
    fontSize: '0.9375rem',
    letterSpacing: '0.00625rem',
    backgroundColor: '#444a59',
    border: 'none',
    borderRadius: 'none',
    marginLeft: '30.9375rem',
    padding: '0.125rem',
    minHeight: '0rem',
    minWidth: '0rem',
  },
  typographyStyle: { color: '#FFFFFF', fontFamily: 'Roboto', fontSize: '15', width: '100%' },
  labels: {
    height: '1rem',
    width: '5.9375rem',
    color: '#354052',
    fontSize: '0.875rem',
    lineHeight: '1rem',
  },
  ddl: {
    height: '1.875rem',
    border: '0.0625rem solid #EBEDF8',
    borderRadius: '0.25rem',
    backgroundColor: '#F3F9FF',
  },
  buttonGroup: {
    marginTop: '2em',
    textAlign: 'right',
  },
  button: {
    width: '6.0625rem',
  },
  businessLabel: {
    position: 'relative',
  },
  noPadding: {
    padding: '0',
  },
  modalBody: { margin: '3em', width: '35em' },
  get leftButton() {
    return {
      ...this.button,
      marginRight: '2em',
    };
  },
  get headerLabels() {
    return {
      ...this.labels,
      fontWeight: '500',
    };
  },
  get smallLabels() {
    return {
      ...this.labels,
      fontSize: '12px',
      lineHeight: '14px',
      letterSpacing: '0.08px',
      color: '#637280',
      marginLeft: '3em',
      marginTop: '-1em',
    };
  },
  activeOffice: {
    color: '#0077FF',
  },
};

// External imports
import React, { useEffect } from 'react';
// Internal imports
import { useSetState } from '~/app/Utility/customHooks';
import ErrorHelpers from '~/app/errorHelpers.js';
import { statusOK } from '~/app/webHelpers.js';
import XlinkAPI from '~/app/api/xlinkAPI';

const ShowHeaders = () => {
  const [state, setState] = useSetState({
    headers: [],
    isLoading: false,
  });

  useEffect(() => {
    getHeaders();
  }, []);

  const getHeaders = async () => {
    try {
      const res = await XlinkAPI.getHeaders();
      if (statusOK(res)) {
        setState({
          headers: res.data,
          isLoading: true,
        });
      }
    } catch (error) {
      ErrorHelpers.handleError('Fetch Error', error);
    }
  };

  return (
    <div>
      {!state.isLoading ? (
        <span>{'Loading...'}</span>
      ) : (
        Object.keys(state.headers).map((header, i) => (
          <div key={i}>
            <span>
              <strong>{header}</strong>
              {`:  ${state.headers[header].join(' ')}`}
            </span>
          </div>
        ))
      )}
    </div>
  );
};

export default ShowHeaders;

export const styles = () => ({
  deleteLoginModal: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    boxShadow: '0 0 20px 0 rgba(0,0,0,0.25)',
    height: 'fit-content',
    width: '30rem',
    padding: '0',
  },
  deleteLoginModalBody: {
    padding: '1rem',
  },
  deleteLoginModalAppBar: {
    paddingLeft: '1.5rem',
  },
  styleButtonMarginRight: {
    marginRight: '.5rem',
  },
});

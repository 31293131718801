import React, { useEffect } from 'react';
// External imports
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
// Internal imports
import Chat from '#/Common/CustomerSupportFab/Chat/chat.jsx';
import WebHelpers from '~/app/webHelpers.js';
import * as Global from '~/app/constants.js';
import { hideChat } from '~/app/Utility/appHelper.js';
import { useSetState } from '~/app/Utility/customHooks';
// Redux imports
import { useSelector } from 'react-redux';
// Styling imports
import '#/Common/CustomerSupportFab/css/body.css';

/**
 * Component that returns either a QuestionAnswerIcon, the chat feature, or a fragment
 * depending on the logged in account specifications. Should take in props of cobrand, accountType, and
 * parent entity role
 * @component
 * @category Chat
 * @returns {Component}
 */

const CustomerSupportFab = () => {
  const { topMostParentRole, isAuthenticated, cobrand } = useSelector(state => ({
    topMostParentRole: state.app.topMostParentRole,
    isAuthenticated: state.app.isAuthenticated,
    loggedInUser: state.app.loggedInUser,
    cobrand: state.cobrand,
  }));
  const [state, setState] = useSetState({
    chatType: null,
    isCTTS: false,
  });
  const payload = WebHelpers.getJWTPayload();

  useEffect(() => {
    // CTTS users should bypass chat logic (CLO-4341)
    // CTTS users remove chat (CLO-4548) - request came from Zach Crites
    setState({ isCTTS: payload?.account_code?.includes('CTTS') });
    renderChat();
  }, []);

  useEffect(() => {
    renderChat();
  }, [cobrand?.canCobrand]);

  /**
   * Cobrand live chat.
   *
   * @returns {JSX} The cobranded live chat icon and mask.
   */
  const renderCobrandChat = () => (
    <div className="customer-support-fab-container">
      <a className="fab fab-support" target="window" href={cobrand?.supportUrl}>
        <QuestionAnswerIcon className="fab-support-icon" />
      </a>
    </div>
  );

  /**
   * Determine which chat if any to display.
   *
   * @returns chat
   */
  const renderChat = () => {
    if (!cobrand?.canCobrand && isAuthenticated && !state.isCTTS) {
      // Only show chat and support URL if account is not a cobrander.
      if (cobrand?.supportUrl && cobrand?.supportUrl.includes('.com')) {
        // We ONLY want to show the customer support FAB when it is a NOT a cobrander.
        setState({ chatType: 'cobrand' });
      }
      // Only show chat for Franchise level and lower
      else if (!cobrand?.canCobrand && topMostParentRole >= Global.HIERARCHY_TYPE.FRANCHISE) {
        setState({ chatType: 'non-cobrand' });
      } else if (!cobrand?.canCobrand) {
        setState({ chatType: 'non-cobrand' });
      } else {
        setState({ chatType: null });
      }
    } else {
      setState({ chatType: null });

      // If chat script was added previously, then we remove the scripts here
      // as a measure to not show chat for cobranders.
      if (document.querySelector('#jsm-chat-script')) {
        document.querySelector('#jsm-chat-script').remove();
      }

      if (document.querySelector('#jsm-chat')) {
        document.querySelector('#jsm-chat').remove();
      }
    }
  };

  if (state.chatType) {
    return (
      <>
        {state.chatType === 'cobrand' && renderCobrandChat()}
        {state.chatType === 'non-cobrand' && <Chat />}
      </>
    );
  } else {
    hideChat();
    return <></>;
  }
};

export default CustomerSupportFab;

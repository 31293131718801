import * as Global from '~/app/constants.js';

/**
 * Handles building the form and formatting inputs that need formatted
 * Sends account form back up to parent CreateAccount
 *
 * @param {Object} form is the state of the account form to create an account
 * @param {Function} getForm is a helper function used to pass the form back up to the parent
 * @returns {Object} account is a an account form used to create an account
 * @module newAccountHelpers
 */
export const buildFormAndSend = (form, getForm) => {
  let cellPhone = form.cellPhone.toString().replace(/[^0-9]/g, '');
  let officePhone = form.officePhone.toString().replace(/[^0-9]/g, '');
  let fax = form.fax.toString().replace(/[^0-9]/g, '');
  cellPhone = cellPhone.replace(/[()-/ /]/g, '');
  officePhone = officePhone.replace(/[()-/ /]/g, '');
  fax = fax.replace(/[()-/ /]/g, '');

  const account = {
    ...form,
    cellPhone,
    officePhone,
    fax,
  };

  const acctType = form.acctType;
  // Is this a single or multi-office add? - This is reseller&topreseller
  if (acctType === Global.ACCOUNT_TYPES.SB_ADMIN || acctType === Global.ACCOUNT_TYPES.SSB_ADMIN) {
    account.acctType = Global.ACCOUNT_TYPES.SB_ADMIN; // Always send a request to make a SSB...
  }
  // Pass form back to parent to send
  getForm(account);
};

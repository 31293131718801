export const styles = theme => ({
  assetListContainer: {
    padding: theme.spacing.unit * 3,
    height: '100%',
    width: '100%',
    minWidth: '600px',
    backgroundColor: 'white',
  },
  assetListHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 6,
    paddingBottom: theme.spacing.unit * 4,
  },
  addDocButton: {
    float: 'right',
    width: '20%',
  },
  tableRowRoot: {
    height: '35px',
    letterSpacing: '0.1px',
  },
  tableHeadRoot: {
    height: '40px',
    letterSpacing: '0.1px',
  },
  previewButton: {
    height: '30px',
    minHeight: '30px',
    width: '60px',
    minWidth: '60px',
    padding: '0px',
    marginRight: '5px',
  },
});

import React from 'react';
import { connect } from 'react-redux';

import { actions as appActions } from './redux/modules/app';
import { actions as returnProfileActions } from '~/app/redux/returnProfile/duck';
import Spinner from '#/Common/Spinner.jsx';
import AuthAPI from '~/app/api/authAPI.js';
import XlinkAPI from '~/app/api/xlinkAPI.js';
import { statusOK } from '~/app/webHelpers.js';
import SimpleDialog from '#/Common/SimpleDialog.jsx';
import ErrorHelpers from '~/app/errorHelpers.js';

const mapStateToProps = state => {
  return {
    returnID: state.returnProfile.returnID,
    appVersionDialog: state.app.appVersionDialog,
    returnOpen: state.app.returnOpen,
    isAuthenticated: state.app.isAuthenticated,
  };
};

const mapDispatchToProps = {
  ...appActions,
  ...returnProfileActions,
};

class AppVersion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updateTextOne: "To use the latest, we'll need to refresh.",
      updateTextTwo: "You'll be logged out, in which you'll need to log in after refresh.",
      isSaving: false,
    };
    this.calcAPI = undefined;
    this.socketWorker = undefined;
  }

  componentDidMount() {
    this.getVersion();

    // Every 30 minutes from when the app first loads for the user on
    // their browser, perform a check against loaded app version
    // for user with latest verion in DB.
    setInterval(() => {
      this.getVersion();
    }, 1000 * 60 * 30);
  }

  /**
   * Get the latest application version from the database.
   * Then we check the latest version from the database against the version
   * the user is currently running on to see if user needs to update.
   */
  getVersion = async () => {
    // Don't get app version if user has not authenticated.
    if (!this.props.isAuthenticated) {
      return;
    }

    try {
      const resp = await AuthAPI.getAppVersion();

      if (resp.status === 200 && resp.data) {
        const latestVersion = resp.data.version;

        // If session storage does not have appVersion set, store latest
        if (!sessionStorage.getItem('appVersion') && latestVersion !== '') {
          sessionStorage.setItem('appVersion', latestVersion);
        }

        // Get current version
        const currentVersion = sessionStorage.getItem('appVersion') || '';
        // If no current version, do not hard refresh.
        const shouldForceRefresh =
          currentVersion !== '' ? this.semverGreaterThan(latestVersion, currentVersion) : false;

        // New app version update available.
        // Setting versionDialogFlag to true will trigger
        // the update dialog message to display.
        if (shouldForceRefresh) {
          this.props.toggleAppVersionDialog(true);

          // We need to update the message for users viewing returns.
          if (this.props.returnOpen) {
            this.setState({
              updateTextOne:
                'Your software is being updated to the current version, your work will be saved and you will be logged out.',
              updateTextTwo: 'Please login back in.',
            });
          }
        }
      } else {
        ErrorHelpers.handleError('Unable to retrieve latest app version. ');
      }
    } catch (error) {
      ErrorHelpers.handleError('Unable to make call to get app version. ', error);
    }
  };

  /**
   * Compare the sema version from current to latest app version to
   * determine if there is a new app version or not.
   */
  semverGreaterThan = (latestVersion, currentVersion) => {
    latestVersion = latestVersion.replace(/[^0-9.]+/g, '').split(/\./g);
    currentVersion = currentVersion.replace(/[^0-9.]+/g, '').split(/\./g);

    while (latestVersion.length || currentVersion.length) {
      const a = Number(latestVersion.shift());
      const b = Number(currentVersion.shift());

      // eslint-disable-next-line no-continue
      if (a === b) continue;
      // eslint-disable-next-line no-restricted-globals
      return a > b || isNaN(b);
    }
    return false;
  };

  /**
   * If user is currently viewing a return, we'll save the return
   * work first. Then we clear local and session storage and then
   * hard reload.
   */
  handleConfirmAppUpdate = async () => {
    // formViewerCompLoaded is session flag set in FormViewer.jsx
    // componentDidMount().  If it's true, we know the user is viewing
    // a return.
    if (this.props.returnOpen) {
      try {
        this.toggleSaving();
        // Call endpoint to call Calc server to save return
        const res = await XlinkAPI.saveReturn(parseInt(this.props.returnID));

        // Should have save return but let's double check to make sure.
        if (statusOK(res) && res.data.command === 'return_save') {
          this.props.toggleAppVersionDialog(false);
          this.toggleSaving();
          this.props.showSnackbarMessage(
            `Return ${this.props.returnID} was successfully saved. Logging out...`,
            'success',
            2500,
            {
              vertical: 'top',
              horizontal: 'center',
            },
          );

          // Letting the user know return was successfully saved before logging out
          setTimeout(() => {
            this.clearStorageAndReload();
          }, 2500);
        }
      } catch (err) {
        this.props.toggleAppVersionDialog(false);
        this.toggleSaving();
        this.props.showSnackbarMessage(
          'Error saving return. Please manually save your return before logging out to update the version.',
          'error',
          4000,
          {
            vertical: 'top',
            horizontal: 'center',
          },
        );
        ErrorHelpers.handleError('Error saving return', err);
      }
    } else {
      this.clearStorageAndReload();
    }
  };

  toggleSaving = () => {
    this.setState({ isSaving: !this.state.isSaving });
  };

  /**
   * Clear local and session storage then hard reload.
   */
  clearStorageAndReload = () => {
    // Clear local and session storage.
    localStorage.clear();
    sessionStorage.clear();

    // Delete browser cache and hard reload.
    window.location.reload(true);
  };

  render() {
    return (
      <div className="app-version-update-container">
        {this.state.isSaving && (
          <Spinner
            size={100}
            color="blue"
            loadingText={'Saving Return...'}
            textColor="white"
            bgColor="grey"
            lockActions={true}
          />
        )}
        <SimpleDialog
          open={this.props.isAuthenticated && this.props.appVersionDialog}
          onConfirm={() => this.handleConfirmAppUpdate()}
          dialogTitle="Application Updates Available"
          contentText={this.state.updateTextOne}
          contentTextTwo={this.state.updateTextTwo}
          confirmText="OK"
          styled={true}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppVersion);

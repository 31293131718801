import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSetState } from '~/app/Utility/customHooks';
// External imports
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import _ from 'lodash';
import moment from 'moment';
// Internal imports
import XlinkAPI from '~/app/api/xlinkAPI';
import MessageList from '~/app/Pages/Messages/MessageList.jsx';
import Message from '~/app/Pages/Messages/Message.jsx';
import ErrorHelpers from '~/app/errorHelpers.js';
import { statusOK } from '~/app/webHelpers.js';

import { MESSAGE_TYPE } from '~/app/constants.js';
// Redux imports
import { actions as formViewerActions } from '~/app/redux/modules/formViewer';
import { actions as overviewActions } from '~/app/redux/modules/overview';
import { actions as messageActions } from '~/app/redux/modules/messages';
// Styling imports
import '~/app/Pages/Returns/components/TextLinkReceivedModal/css/textlinkReceivedModal.css';

const TextLinkReceivedModal = props => {
  const dispatch = useDispatch();

  const {
    messageList,
    messageBadgeCount,
    showAllMessages,
    message,
    messageType,
    senderAvatar,
    returnID,
    loginID,
  } = useSelector(state => ({
    messageList: state.messages.messageList,
    messageBadgeCount: state.overview.messageBadgeCount,
    showAllMessages: state.messages.showAllMessages,
    message: state.messages.message,
    messageType: state.messages.messageType,
    senderAvatar: state.app.avatarBase64,
    returnID: state.returnProfile.returnID,
    loginID: state.app.loggedInUser.userID,
  }));

  const [state, setState] = useSetState({
    confirmDeleteMessage: false,
  });

  useEffect(() => {
    getReturnMessages();
    // Clear message list and current message on unmount
    return () => {
      dispatch(messageActions.updateMessageList([]));
      dispatch(messageActions.updateCurrentMessage({}));
      props.setUnreadTextMessageCount(messageList.filter(m => m.is_read === false).length);
    };
  }, []);

  const handleClose = () => {
    dispatch(formViewerActions.toggleTextLinkReceivedModal(false));
  };

  const getReturnMessages = async (checkForNewMessages = false) => {
    let newUnreadTextMessageCount = 0;
    try {
      const res = await XlinkAPI.getReturnMessagesByReturnID(returnID);

      if (statusOK(res) && res.data.length > 0) {
        const { data } = res;
        // Check if there are new unread text messages against current message list.
        newUnreadTextMessageCount = data.length - messageList.length;

        data.forEach(val => {
          val.date = moment(new Date(val.date)).format('M/DD/YYYY h:mm a');
        });
        // Only update message list when there are new text messages for the
        // current return user has open.
        newUnreadTextMessageCount > 0 && dispatch(messageActions.updateMessageList(data));
        // Update current message if not just checking for new received text messages.
        if (!checkForNewMessages) {
          if (messageType == MESSAGE_TYPE.SENT) {
            dispatch(messageActions.updateMessageList(data[0]));
            getSentMessageByID(data[0].id);
          } else {
            dispatch(messageActions.updateCurrentMessage(data[0]));
            getMessageByID(data[0].id);
          }
        }
      }
      return newUnreadTextMessageCount;
    } catch (e) {
      dispatch(formViewerActions.showErrorDialog('Error fetching messages list'));
    }
  };

  const handleMessageListClick = id => {
    if (messageType == MESSAGE_TYPE.SENT) {
      getSentMessageByID(id);
    } else {
      getMessageByID(id);
    }
  };

  const getSentMessageByID = async messageID => {
    try {
      const res = await XlinkAPI.getSentMessageByID(messageID);

      if (statusOK(res)) {
        res.data.date = moment(new Date(res.data.date)).format('M/DD/YYYY h:mm a');

        dispatch(messageActions.updateCurrentMessage(res.data));
      }
    } catch (e) {
      dispatch(formViewerActions.showErrorDialog('Error fetching sent message by ID'));
    }
  };

  const getMessageByID = async messageID => {
    try {
      const res = await XlinkAPI.getMessageByID(messageID);

      if (statusOK(res)) {
        res.data.date = moment(new Date(res.data.date)).format('M/DD/YYYY h:mm a');

        if (!res.data.is_read) {
          dispatch(
            overviewActions.setMessageBadgeCount(messageBadgeCount > 0 ? messageBadgeCount - 1 : 0),
          );
          res.data.is_read = true;
        }

        dispatch(messageActions.updateCurrentMessage(res.data));
        dispatch(messageActions.updateMessageInMessageList(res.data.id));

        await XlinkAPI.setMessageStatus(loginID, messageID, true);
      }
    } catch (e) {
      dispatch(formViewerActions.showErrorDialog('Error fetching message by ID'));
    }
  };

  const handleDeleteClick = () => {
    setState({ confirmDeleteMessage: true });
  };

  const hideConfirmDeleteMessage = () => {
    setState({ confirmDeleteMessage: false });
  };

  const deleteMessage = async () => {
    if (messageList == null || messageList.length == 0) {
      return;
    }
    let listLen = messageList.length;

    try {
      const res = await XlinkAPI.deleteMessage(message.message_sent_id);

      if (statusOK(res, false)) {
        dispatch(messageActions.onDeleteMessage(message.id));
        if (listLen > 1) {
          getMessageByID(messageList[1].id);
        }
      }
    } catch (e) {
      dispatch(formViewerActions.showErrorDialog('Error deleting message'));
    }
  };

  return (
    <div>
      <AppBar position="static">
        <Toolbar className="textlink-received-modal-toolbar">Received Text Messages</Toolbar>
      </AppBar>
      <Grid container>
        <Grid item xs={3} className="textlink-received-modal-grid">
          <MessageList
            messageList={messageList}
            handleMessageClick={handleMessageListClick}
            getMessageList={getReturnMessages}
            unreadMessageCount={messageBadgeCount}
            showAllMessages={showAllMessages}
            messageType={messageType}
            updateSortCriteria={messageActions.updateSortCriteria}
            inReturn={true}
          />
        </Grid>
        <Grid item xs={9}>
          <div className="textlink-received-modal-msg-container">
            <Message
              message={message}
              handleDeleteClick={handleDeleteClick}
              messageType={messageType}
              senderAvatar={senderAvatar}
              inReturn={true}
            />
          </div>
          <div className="textlink-received-modal-btn-container">
            <Button id="btnCloseSendTextLink" color="primary" onClick={handleClose}>
              Close
            </Button>
          </div>
        </Grid>
      </Grid>
      <Dialog open={state.confirmDeleteMessage} onClose={hideConfirmDeleteMessage}>
        <DialogTitle id="confirm-delete-message">Delete Message</DialogTitle>
        <DialogContent id="confirm-delete-message-desc">
          {'Are you sure you want to delete this message? This cannot be undone.'}
        </DialogContent>
        <DialogActions>
          <Button
            id="btnYesConfirmDeleteMessage"
            onClick={() => {
              hideConfirmDeleteMessage();
              deleteMessage();
            }}
          >
            Yes
          </Button>
          <Button
            id="btnNoConfirmDeleteMessage"
            onClick={() => {
              hideConfirmDeleteMessage();
            }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TextLinkReceivedModal;

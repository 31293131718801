import React from 'react';
import Loadable from 'react-loadable';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as Global from '~/app/constants.js';

// Loadable Bodies for Overview
const tempLoadingComponent = () => <LinearProgress />; // <div>Loading...</div>

const LoadableBusinessOwner = Loadable({
  loader: () => import('../BusinessOwner/BusinessOwner.jsx'),
  loading: tempLoadingComponent,
});

const LoadableManager = Loadable({
  loader: () => import('../Manager.jsx'),
  loading: tempLoadingComponent,
});

const LoadablePreparer = Loadable({
  loader: () => import('../Preparer.jsx'),
  loading: tempLoadingComponent,
});

const LoadableSSB = Loadable({
  loader: () => import('../SSB.jsx'),
  loading: tempLoadingComponent,
});

const LoadableSB = Loadable({
  loader: () => import('../SB.jsx'),
  loading: tempLoadingComponent,
});

const LoadableSuperAdmin = Loadable({
  loader: () => import('../SuperAdmin.jsx'),
  loading: tempLoadingComponent,
});

const LoadableClerk = Loadable({
  loader: () => import('../Clerk.jsx'),
  loading: tempLoadingComponent,
});

export const getBodyComponent = (loginID, roleGroup) => {
  switch (roleGroup) {
    case Global.HIERARCHY_TYPE.SSB:
      return <LoadableSSB key={loginID} />;
    case Global.HIERARCHY_TYPE.SB:
      return <LoadableSB key={loginID} />;
    case Global.HIERARCHY_TYPE.FRANCHISE:
      return <LoadableBusinessOwner key={loginID} />;
    case Global.HIERARCHY_TYPE.EFIN:
      return <LoadableManager key={loginID} />;
    case Global.HIERARCHY_TYPE.PREPARER:
      return <LoadablePreparer key={loginID} />;
    case Global.HIERARCHY_TYPE.CLERK:
      return <LoadableClerk key={loginID} />;
    case Global.HIERARCHY_TYPE.TECH_SUPPORT_SBA:
    case Global.HIERARCHY_TYPE.TECH_SUPPORT:
    case Global.HIERARCHY_TYPE.ADMIN:
      return <LoadableSuperAdmin />;
  }
};

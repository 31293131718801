export const styles = {
  formControlRoot: {
    marginRight: '24px',
  },
  labelWidthStyle: {
    height: '1.6vh',
    color: '#354052',
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontSize: '14px',
  },
  labelWidthStyleShort: {
    width: '9vw',
    height: '1.6vh',
    color: '#354052',
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontSize: '14px',
  },
  xsmallInputStyle: {
    width: '10.5vw',
    height: '3.5vh',
  },
  smallInputStyle: {
    width: '13.5vw',
    height: '3.5vh',
  },
  mediumInputStyle: {
    width: '16vw',
    height: '3.5vh',
  },
  largeInputStyle: {
    width: '28vw',
    height: '3.5vh',
  },
  xlargeInputStyle: {
    width: '40vw',
    height: '3.5vh',
  },
  menuItemStyle: {
    paddingTop: '0px',
    paddingBottom: '0px',
    color: '#0077FF',
    height: '1.5em',
  },
  validStateStyle: {
    width: '12.7vw',
    height: '3.5vh',
    paddingTop: '0px',
    paddingBottom: '0px',
    textAlign: 'left',
  },
  centerInputsContainer: {
    marginLeft: '3vw',
    marginRight: '2vw',
  },
  titleStyle: {
    fontFamily: 'Roboto',
    fontSize: '21px',
    letterSpacing: '0.02rem',
    color: '#1A173B',
  },
  titleContainerStyle: {
    textAlign: 'left',
    paddingTop: '3vh',
    paddingBottom: '1vh',
  },
  subTitleStyle: {
    fontWeight: 500,
    fontSize: '13px',
    marginTop: '1.5vh',
    display: 'block',
  },
  formStyle: {
    marginLeft: '3vw',
    marginTop: '0.5vh',
  },
  appBarStyle: {
    border: '1px solid #EBEDF8',
    height: '80vh',
    marginTop: '40px',
    overflowY: 'auto',
  },
  checkBoxIconStyle: {
    fontSize: '1.2rem',
  },
  checkBoxStyle: {
    width: '1rem',
    height: '1rem',
    marginLeft: '0.8vw',
    marginRight: '0.4vw',
  },
  copyCheckBoxContainer: {
    margin: '1vh 0 2vh 0',
  },
  checkBoxPadding: {
    padding: '0px 12px',
  },
  dividerStyle: {
    marginRight: '3vw',
  },
};

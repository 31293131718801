import React, { useState } from 'react';

// External imports
import { withRouter } from 'react-router-dom';
// Redux imports
import { connect, useSelector } from 'react-redux';

import { actions as appActions } from '~/app/redux/modules/app';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import WebHelpers from '~/app/webHelpers.js';

// Styling imports
import '~/app/Components/Footer/css/footer.css';

const mapDispatchToProps = {
  ...appActions,
};

/**
 *Footer Component showing Tax Year, Version Number and Copyright
 *
 * @component
 * @category Components
 * @subcategory Footer
 */
const Footer = () => {
  const [showCopyright, setShowCopyright] = useState(false);
  const isAuthenticated = useSelector(state => ({
    isAuthenticated: state.app.isAuthenticated,
  }));

  // array of pathnames where we dont want to show copyright
  const excludedComponents = [
    '/account-settings',
    '/create-new-account',
    '/database',
    '/refund-calculator',
    '/wizard-mode',
  ];

  // gets the current season
  const getSeason = () => {
    let season;
    const payload = WebHelpers.getJWTPayload();
    if (typeof payload !== 'undefined') {
      season = WebHelpers.getJWTPayload().season;
    }
    return season;
  };

  // returns a boolean to decide whether to display footer or not
  const displayFooter = () => {
    if (
      isAuthenticated.isAuthenticated &&
      !excludedComponents.some(v => location.pathname.includes(v))
    ) {
      return true;
    }
    return false;
  };

  if (displayFooter()) {
    return (
      <>
        <div className="footer">
          <span>
            <span className="footer-Year">Tax Year</span>
            {getSeason() - 1}
            <span className="footer-Version">Version</span>
            <span className="footer-version-number">
              {sessionStorage.getItem('appVersion') || ''}
            </span>
          </span>
          <span className="footer-Copyright">
            This material is copyrighted. Please click
            <span
              id="spnCopyrightFooter"
              className="footer-Link"
              onClick={() => setShowCopyright(true)}
            >
              here
            </span>
            for further information.
          </span>
        </div>
        <Dialog
          open={showCopyright}
          onClose={() => setShowCopyright(false)}
          aria-labelledby="confirm-delete-title"
          aria-describedby="confirm-delete-desc"
          disableBackdropClick={true}
        >
          <DialogTitle id="confirm-delete-title">{'COPYRIGHT NOTICE'}</DialogTitle>
          <DialogContent id="confirm-delete-desc">
            <p className="textAlignJustify">
              Copyright &copy; {moment().format('YYYY')} CrossLink Professional Tax Solutions, LLC
              (&quot;CPTS&quot;). All Rights Reserved.
            </p>
            <p className="textAlignJustify">
              CPTS Website, Software, Services and Content Unless otherwise noted, all materials
              contained in CPTS&apos; website, software, services and content are copyrighted and
              may not be used except as provided in these terms and conditions or in the copyright
              notice (documents and software) or other proprietary notice provided with the relevant
              materials. All rights are reserved by CPTS, and all materials and content may not be
              reproduced, downloaded, disseminated, published, or transferred in any form or by any
              means, except with the prior written permission of CPTS. Copyright infringement is a
              violation of federal law subject to criminal and civil penalties.
            </p>
          </DialogContent>
          <DialogActions>
            <Button
              id="btnHideCopyright"
              className="btnHideCopyright"
              onClick={() => setShowCopyright(false)}
            >
              Done
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
  return <div />;
};

export default withRouter(connect(null, mapDispatchToProps)(Footer));

// External imports
import React from 'react';
import renderHTML from 'react-render-html';
import Draggable from 'react-draggable';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  withStyles,
} from '@material-ui/core';
// Styling imports
import { styles } from '~/app/Pages/Returns/components/Print/css/dialogStyles.js';

const SuggestedPasswordDoc = props => {
  const { classes } = props;

  return (
    <div>
      {props.isOpen && (
        <Dialog
          disableAutoFocus={true}
          PaperProps={{ tabIndex: -1 }}
          open={props.isOpen}
          onClose={props.handleClose}
          TransitionComponent={Draggable}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle className={classes.styledHeader} id="form-dialog-title" disableTypography>
            <div style={{ display: 'inline-block', width: '94%' }}>
              <Typography
                style={{
                  color: 'white',
                  fontFamily: 'Roboto',
                  fontSize: '18px',
                  paddingTop: '3%',
                  paddingLeft: '5%',
                }}
              >
                Email Encrypted Document Preview
              </Typography>
            </div>
            <div style={{ display: 'inline-block' }}>
              <Button
                className={classes.toolbarButtonRoot}
                style={{
                  minWidth: '0',
                  minHeight: '0',
                  paddingLeft: '5px',
                  paddingRight: '5px',
                }}
                onClick={props.handleClose}
              >
                X
              </Button>
            </div>
          </DialogTitle>
          <DialogContent
            style={{
              marginBottom: '0vh',
              marginTop: '1vh',
              paddingBottom: '0vh',
            }}
          >
            <div
              style={{
                marginTop: '1vh',
                marginBottom: '0vh',
                paddingBottom: '0vh',
              }}
            >
              <table
                style={{
                  marginTop: '1.5vh',
                  marginBottom: '0vh',
                  marginLeft: '1vw',
                  marginRight: '1.5vw',
                }}
              >
                <tbody>
                  <tr>
                    <td style={{ border: '1px solid black', padding: '20px' }}>
                      {renderHTML(props.htmlPreview)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </DialogContent>
          <DialogActions
            style={{
              paddingRight: '3vw',
              paddingBottom: '1vh',
              paddingTop: '2vh',
            }}
          >
            <Button
              onClick={props.handleClose}
              id="btnCloseSuggestedPasswordDocDialog"
              color="primary"
              classes={{ root: classes.cancelButtonRoot }}
            >
              Cancel
            </Button>
            <Button
              onClick={props.handleSaveSuggestedPasswordDoc}
              id="btnSaveSuggestedPasswordDocDialog"
              color="primary"
            >
              Send Document
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default withStyles(styles)(SuggestedPasswordDoc);

import React, { Component, Fragment } from 'react';
import RefundInformation from '#/Common/RefundInformation.jsx';
import AGIInformation from '#/Common/AGIInformation.jsx';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Typography from '@material-ui/core/Typography';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Checkmark from '~/images/icons/icons8-checkmark-48.png';
import menuVertical from '~/images/icons/menu_vertical.png';
import expandArrow from '~/images/icons/icons8-double_right_filled.png';
import { IconButton } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { handleMenuItemHighlight, getFedFormFromProps } from './sidebarFormsHelpers.js';
import { shouldDisableSideBarItem } from '~/app/Utility/general.js';
import './css/SideBarForms.css';
import {
  IconStyle,
  IconStyleNotAttached,
  formItemStyle,
  formItemStyleDisabled,
  formItemStyleDropdown,
  formItemStyleAttached,
  subFormItemStyle,
  selectedSubFormItemStyle,
  formHeaderStyle,
  contextItemStyle,
  sidebarFooterBtn,
} from './css/sidebarForms.js';

class SideBarForms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedHeaders: [],
      modalOpen: false,
      availableForms: [],
      contextMenuAnchor: null,
      contextMenuSelected: null,
      stateListOpen: false,
      collapsibleStateList: false,
      onMobile: false,
      sideBarExpanded: true,
    };
    this.tabFederalDefinition = undefined;
  }

  componentDidMount = () => {
    const initHeaders = [];
    this.props.formList.forEach(category => {
      const keyAndID = category.desc && category.desc !== '' ? category.desc : category.Name;
      initHeaders.push(keyAndID);
    });
    this.setState({
      expandedHeaders: initHeaders,
    });

    // Check screensize
    this.checkScreenSize();

    this.props.tabListDefinitions.forEach((idr, index) => {
      if (idr.label === 'Federal') {
        this.tabFederalDefinition = {
          tabObject: idr,
          index: index,
        };
      }
    });
  };

  // Check screensize and set variables
  checkScreenSize = () => {
    // We assume onMobile for any screens 1024 or smaller in width
    if (window.innerWidth < 1025) {
      this.setState({ onMobile: true, sideBarExpanded: false });
    }
  };

  handleClickOnCollapser = key => {
    const keyIndex = this.state.expandedHeaders.indexOf(key);
    const newexpandedHeaders = [...this.state.expandedHeaders];

    if (keyIndex === -1) {
      newexpandedHeaders.push(key);
    } else {
      newexpandedHeaders.splice(keyIndex, 1);
    }

    this.setState({
      expandedHeaders: newexpandedHeaders,
    });
  };

  handleContextMenuToggle = (event, selected) => {
    this.setState({
      contextMenuAnchor: event.currentTarget,
      contextMenuSelected: selected,
    });
  };

  handleContextMenuClose = () => {
    this.setState({
      contextMenuAnchor: null,
      contextMenuSelected: null,
    });
  };

  handleSubItemClick = (event, form, guid) => {
    if (form.slice(0, 3) !== 'DOX') {
      form = form.slice(0, 6) + form.slice(8, 9);
      if (form.slice(0, 1) === 'Z') {
        this.props.handleSidebarFormClick(event, form, '', '');
      } else {
        this.props.handleSidebarFormClick(event, form, 'wks', '');
      }
    } else {
      this.props.handleSidebarFormClick(event, form, '', guid); // Pass guid as formFile for binary attachment items
    }
  };

  handleDetachDocument = docvar => {
    const docid = this.props.getDocumentIDByVar(docvar);
    this.props.detachDocument(docid, docvar);
  };

  formSubRows = (items, grandParent) => {
    if (typeof items === 'undefined' || items == null) {
      return;
    }
    const subFormList = items.map((sub, index) => {
      let removeItem;
      if (sub.var.slice(0, 3) === 'DOX') {
        removeItem = (
          <MenuItem
            className="contextMenuItem"
            onClick={() => {
              this.handleContextMenuClose();
              this.handleDetachDocument(sub.var);
            }}
            style={contextItemStyle}
            key={'sidemenuitem' + index}
          >
            Detach Document
          </MenuItem>
        );
      } else {
        removeItem = (
          <MenuItem
            className="contextMenuItem"
            onClick={() => {
              this.handleContextMenuClose();
              alert('remove ' + sub.var + index);
            }}
            style={contextItemStyle}
            key={'sidemenuitem' + index}
          >
            Remove Worksheet
          </MenuItem>
        );
      }
      let contextItem = <Fragment />;
      if (grandParent !== '00' && grandParent !== 'PF' && grandParent !== 'retn37') {
        contextItem = (
          <ListItemSecondaryAction className="secondaryMenuItem">
            <Button
              className="contextMenuButton"
              aria-owns={this.state.contextMenuAnchor ? sub.var + index + '-context' : null}
              aria-haspopup="true"
              onClick={e => this.handleContextMenuToggle(e, sub.var + index + '-context')}
              style={{
                minHeight: '1.5rem',
                maxHeight: '1.5rem',
                minWidth: '0.5rem',
                maxWidth: '0.5rem',
                padding: '0px 8px 0px 8px',
                border: '1px solid #0077FF',
              }}
              disabled={this.props.assetMode}
            >
              <img src={menuVertical} style={{ height: `1em` }} />
            </Button>
            <Menu
              id={sub.var + index + '-context'}
              anchorEl={this.state.contextMenuAnchor}
              open={Boolean(this.state.contextMenuSelected === sub.var + index + '-context')}
              onClose={this.handleContextMenuClose}
            >
              <MenuItem
                className="contextMenuItem"
                onClick={() => {
                  this.handleContextMenuClose();
                  this.props.onClickAddForm(
                    this.tabFederalDefinition.tabObject,
                    this.tabFederalDefinition.index,
                  );
                }}
                style={contextItemStyle}
              >
                Add Form
              </MenuItem>
              {removeItem}
              <MenuItem
                className="contextMenuItem"
                onClick={() => {
                  this.handleContextMenuClose();
                  alert('help');
                }}
                style={{ display: 'none' }}
              >
                Help
              </MenuItem>
            </Menu>
          </ListItemSecondaryAction>
        );
      }
      return (
        <MenuItem
          button
          className="menuItem"
          key={sub.var + index}
          selected={handleMenuItemHighlight(
            this.props.activeForm,
            sub.var.slice(0, 6) + sub.var.slice(8, 9),
          )}
          id={'sidebar' + sub.var + index}
          onClick={event => this.handleSubItemClick(event, sub.var, sub.guid)}
          disabled={this.props.assetMode}
        >
          <ListItemText
            style={{ paddingLeft: '25px' }}
            className="sidebarMenuItem"
            disableTypography
            inset
            primary={
              <Typography
                type="body2"
                style={
                  handleMenuItemHighlight(
                    this.props.activeForm,
                    sub.var.slice(0, 6) + sub.var.slice(8, 9),
                  )
                    ? selectedSubFormItemStyle
                    : subFormItemStyle
                }
              >
                {sub.desc}
              </Typography>
            }
          />
          {contextItem}
        </MenuItem>
      );
    });
    return subFormList;
  };

  /**
   * Check if form is currently being viewed, then
   * we don't need to reload the form.
   */
  checkFormNav = (event, form, props) => {
    if (form.var !== this.props.activeForm) {
      this.confirmAddInterviewForms(form);
      props.handleSidebarFormClick(event, form.var, '', form.frmFile);
    }
  };

  confirmAddInterviewForms = (form, addForm = false) => {
    if (form.desc === 'Start' && (form.var === 'Z00501' || form.var === 'Z03001')) {
      this.props.showAddInterviewModal();

      if (!this.props.addInterviewFlag) {
        return;
      }
    }

    if (addForm) {
      this.handleContextMenuClose();
      this.props.onClickAddForm(
        this.tabFederalDefinition.tabObject,
        this.tabFederalDefinition.index,
      );
    }
  };

  formRows = (items, parent) => {
    if (typeof items === 'undefined' || items == null) {
      return;
    }
    const formList = items.map((form, index) => {
      const attachedIcon = <img src={Checkmark} style={{ height: '1rem', width: '1rem' }} />;
      let remDisabled = false;
      let isLower = false;
      let isNum = false;
      if (form.var && (!isNaN(form.var.slice(0, 1)) || !isNaN(form.var.slice(1, 2)))) {
        isNum = true;
      }
      if (
        form.var &&
        isNaN(form.var.slice(0, 1)) &&
        form.var.slice(0, 1) === form.var.slice(0, 1).toLowerCase() &&
        isNaN(form.var.slice(1, 2)) &&
        form.var.slice(1, 2) === form.var.slice(1, 2).toLowerCase()
      ) {
        isLower = true;
      }

      if (
        isLower ||
        isNum ||
        (form.var && (form.var.slice(4) === '' || form.var.slice(6) !== '')) ||
        (form.var === 'DOXUS' && form.desc === 'BinaryAttachments')
      ) {
        remDisabled = true;
      }
      let contextItem = <Fragment />;
      contextItem = (
        <ListItemSecondaryAction className="secondaryMenuItem">
          <Button
            className="contextMenuButton"
            aria-owns={this.state.contextMenuAnchor ? form.var + '-context' : null}
            aria-haspopup="true"
            onClick={e => {
              if (!this.props.locked) {
                this.handleContextMenuToggle(e, form.var + '-context');
              }
            }}
            style={{
              minHeight: '1.5rem',
              maxHeight: '1.5rem',
              minWidth: '0.5rem',
              maxWidth: '0.5rem',
              padding: '0px 8px 0px 8px',
              border: '1px solid #0077FF',
            }}
            disabled={this.props.assetMode}
          >
            <img src={menuVertical} style={{ height: `1em` }} />
          </Button>
          <Menu
            id={form.var + '-context'}
            anchorEl={this.state.contextMenuAnchor}
            open={Boolean(this.state.contextMenuSelected === form.var + '-context')}
            onClose={this.handleContextMenuClose}
          >
            <MenuItem
              className="contextMenuItem"
              onClick={() => {
                this.confirmAddInterviewForms(form, true);
              }}
              style={contextItemStyle}
            >
              Add Form
            </MenuItem>
            <MenuItem
              className="contextMenuItem"
              onClick={() => {
                this.handleContextMenuClose();
                this.props.handleDeleteForm(form, form.desc);
              }}
              disabled={remDisabled}
              style={contextItemStyle}
            >
              Remove Form
            </MenuItem>
            <MenuItem
              className="contextMenuItem"
              onClick={() => {
                this.handleContextMenuClose();
              }}
              style={{ display: 'none' }}
              disabled={true}
            >
              {' '}
              {/* TODO: Select for Print */}
              Select for Print
            </MenuItem>
            <MenuItem
              className="contextMenuItem"
              onClick={() => {
                this.handleContextMenuClose();
              }}
              style={{ display: 'none' }}
            >
              {' '}
              {/* TODO: Help */}
              Help
            </MenuItem>
          </Menu>
        </ListItemSecondaryAction>
      );
      let itemStyle = formItemStyleDropdown;
      if (typeof form.GChildren !== 'undefined') {
        if (form.icon !== '1') {
          // form is not attached
          itemStyle = formItemStyleDropdown;
        }
      } else {
        // form item has no dropdown
        itemStyle = formItemStyleAttached;
        if (form.icon !== '1') {
          itemStyle = formItemStyle;
        }
      }

      return (
        <div key={'list-' + form.var + index}>
          <MenuItem
            button
            className="menuItem"
            // key={form.var}
            selected={handleMenuItemHighlight(this.props.activeForm, form.var)}
            id={'form' + form.var}
            onClick={event => this.checkFormNav(event, form, this.props)}
            disabled={
              this.props.assetMode || shouldDisableSideBarItem(this.props.isDemoAccount, form.var)
            }
            key={'sidemenuitemctx' + index}
          >
            {form.icon === '1' ? attachedIcon : ''}
            {typeof form.GChildren !== 'undefined' ? (
              <IconButton
                id={'itemIcon' + form.var}
                style={form.icon !== '1' ? IconStyleNotAttached : IconStyle}
                onClick={() => this.handleClickOnCollapser('list-' + form.var + index)}
              >
                {this.state.expandedHeaders.indexOf('list-' + form.var + index) !== -1 ? (
                  <ExpandLess
                    id={'lessIcon' + form.var}
                    style={{ fontSize: '16px', paddingRight: '0px' }}
                  />
                ) : (
                  <ExpandMore
                    id={'moreIcon' + form.var}
                    style={{ fontSize: '16px', paddingRight: '0px' }}
                  />
                )}
              </IconButton>
            ) : (
              ''
            )}
            <ListItemText
              className="sidebarMenuItem"
              disableTypography
              inset
              primary={
                <Typography
                  type="body2"
                  style={
                    handleMenuItemHighlight(this.props.activeForm, form.var)
                      ? Object.assign({}, itemStyle, { color: '#1A173B' })
                      : this.props.assetMode
                      ? formItemStyleDisabled
                      : itemStyle
                  }
                >
                  {form.desc && form.desc.length > 29
                    ? form.desc.slice(0, 26) + '...'
                    : form.desc.slice(0, 29)}
                </Typography>
              }
              style={{ paddingLeft: '10px' }}
            />
            {contextItem}
          </MenuItem>
          <Collapse
            in={this.state.expandedHeaders.indexOf('list-' + form.var + index) !== -1}
            timeout="auto"
            unmountOnExit
          >
            {this.formSubRows(form.GChildren, parent)}
          </Collapse>
        </div>
      );
    });

    const result = [];
    for (let i = 0; i < formList.length; i++) {
      result.push(formList[i]);
    }
    return result;
  };

  generateSidebar() {
    const sidebar = this.props.formList.map((category, index) => {
      const keyAndID = category.desc && category.desc !== '' ? category.desc : category.Name;
      let contextItem = <Fragment />;
      if (
        category.var !== '00' &&
        category.var !== 'US' &&
        category.var !== 'PF' &&
        category.var !== 'retn37'
      ) {
        contextItem = (
          <ListItemSecondaryAction className="secondaryMenuItem">
            <Button
              className="contextMenuButton"
              aria-owns={this.state.contextMenuAnchor ? category.desc + '-context' : null}
              aria-haspopup="true"
              onClick={e => {
                if (!this.props.locked) {
                  this.handleContextMenuToggle(e, category.desc + '-context');
                }
              }}
              style={{
                minHeight: '1.5rem',
                maxHeight: '1.5rem',
                minWidth: '0.5rem',
                maxWidth: '0.5rem',
                padding: '0px 8px 0px 8px',
                border: '1px solid #0077FF',
              }}
              disabled={this.props.assetMode}
            >
              <img src={menuVertical} style={{ height: `1em` }} />
            </Button>
            <Menu
              id={category.var + '-context'}
              anchorEl={this.state.contextMenuAnchor}
              open={Boolean(this.state.contextMenuSelected === category.desc + '-context')}
              onClose={this.handleContextMenuClose}
            >
              <MenuItem
                className="contextMenuItem"
                onClick={() => {
                  this.handleContextMenuClose();
                  this.props.handleDeletePkg(category.var, category.desc);
                }}
                style={contextItemStyle}
              >
                Remove State
              </MenuItem>
              <MenuItem
                className="contextMenuItem"
                onClick={() => {
                  this.handleContextMenuClose();
                  alert('help');
                }}
                style={contextItemStyle}
              >
                Help
              </MenuItem>
            </Menu>
          </ListItemSecondaryAction>
        );
      }
      return (
        <div key={keyAndID + index} id={keyAndID}>
          <MenuItem
            id={keyAndID.trim() + 'button'}
            button
            className="menuItem"
            onClick={() => this.handleClickOnCollapser(keyAndID)}
            selected={handleMenuItemHighlight()}
            disabled={this.props.assetMode}
            key={'sidemenuitemctx2' + index}
          >
            {this.state.expandedHeaders.indexOf(keyAndID) !== -1 ? (
              <ExpandLess style={{ fontSize: '16px' }} />
            ) : (
              <ExpandMore style={{ fontSize: '16px' }} />
            )}
            <ListItemText
              style={{ padding: 0 }}
              disableTypography
              inset
              primary={
                <Typography type="body2" style={formHeaderStyle}>
                  {category.desc}
                </Typography>
              }
            />
            {contextItem}
          </MenuItem>
          <Collapse
            in={this.state.expandedHeaders.indexOf(keyAndID) !== -1}
            timeout="auto"
            unmountOnExit
          >
            {this.formRows(category.Children, category.var)}
          </Collapse>
        </div>
      );
    });
    return sidebar;
  }

  getAGIInfo = () => {
    let agiAmount = '0';
    const fedForm = getFedFormFromProps(this.props.formList);
    if (fedForm && fedForm.agi) {
      agiAmount = `${fedForm.agi}`;
    }

    return <AGIInformation agiAmount={agiAmount} agiInfo={'Federal AGI'} />;
  };

  getFedRefund = () => {
    let fedRefund = 0;

    const fedForm = getFedFormFromProps(this.props.formList);
    if (fedForm) {
      if (fedForm.rfnd && fedForm.rfnd !== '') {
        fedRefund = fedForm.rfnd.substring(1);
      } else if (fedForm.bdue && fedForm.bdue !== '') {
        fedRefund = fedForm.bdue.replace('$', '-');
      }
    }

    return <RefundInformation refundAmount={fedRefund} refundInfo={'Federal Refund'} />;
  };

  handleExpandStatelist = async () => {
    await this.setState(prevState => ({
      stateListOpen: !prevState.stateListOpen,
    }));
  };

  getStateRefunds = () => {
    const states = [];
    for (let i = 0; i < this.props.formList.length; i++) {
      if (
        this.props.formList[i].desc &&
        this.props.formList[i].desc.trim() !== 'FEDERAL' &&
        this.props.formList[i].desc !== 'GENERAL' &&
        this.props.formList[i].desc !== 'Refund Calculator' &&
        this.props.formList[i].var !== 'retn37' &&
        this.props.formList[i].var !== 'PF'
      ) {
        let refOrBal;
        if (this.props.formList[i].rfnd && this.props.formList[i].rfnd !== '') {
          refOrBal = this.props.formList[i].rfnd.substring(1);
          states.push(
            <RefundInformation
              refundAmount={refOrBal}
              refundInfo={this.props.formList[i].var + ' State Refund'}
              key={this.props.formList[i].var + i}
            />,
          );
        } else if (this.props.formList[i].bdue && this.props.formList[i].bdue !== '') {
          refOrBal = this.props.formList[i].bdue.replace('$', '-');
          states.push(
            <RefundInformation
              refundAmount={refOrBal}
              refundInfo={this.props.formList[i].var + ' State Refund'}
              key={this.props.formList[i].var + i}
            />,
          );
        } else {
          states.push(
            <RefundInformation
              refundAmount={0}
              refundInfo={this.props.formList[i].var + ' State Refund'}
              key={this.props.formList[i].var + i}
            />,
          );
        }
      }
    }
    // If there is more than one state wrap the other states in a collapse element
    if (states.length > 1) {
      return (
        <div id="sibebar-additional-states-refund">
          {states.shift()}
          <Collapse
            in={this.state.stateListOpen}
            style={{ maxHeight: '20vh', overflowY: 'auto' }}
            timeout="auto"
            unmountOnExit
          >
            {states}
          </Collapse>
        </div>
      );
    }
    return states;
  };

  // Only display this toggle when viewing on iPad
  mobileDisplay = () => {
    if (this.state.onMobile) {
      return (
        <div style={{ position: 'relative' }}>
          <IconButton
            id="btnExpandSideBar"
            color="primary"
            size="small"
            variant="text"
            style={{ width: '35px' }}
            className="form-cellItem"
            onClick={() => {
              this.setState({
                sideBarExpanded: !this.state.sideBarExpanded,
              });
            }}
          >
            {this.state.sideBarExpanded ? (
              <ChevronLeftIcon
                className="sidebarCollapseIcon"
                style={{ color: '#1880e7', height: '35px', width: '35px' }}
              />
            ) : (
              <ChevronRightIcon
                className="sidebarCollapseIcon"
                style={{ color: '#1880e7', height: '35px', width: '35px' }}
              />
            )}
          </IconButton>
        </div>
      );
    }
  };

  render() {
    const states = this.getStateRefunds();

    return (
      <div className="side-bar-forms-content">
        {this.mobileDisplay()}
        {this.state.sideBarExpanded ? (
          <div className="form-nav-container">
            <div id="sidebar-return-refunds-list">
              {this.getFedRefund()}
              {states}
              {this.getAGIInfo()}
              {states.length === undefined ? (
                <div
                  onClick={this.handleExpandStatelist}
                  style={{
                    paddingLeft: '24px',
                    marginLeft: '1em',
                    color: '#0077FF',
                  }}
                  cursor="pointer"
                >
                  {this.state.stateListOpen ? 'Collapse' : 'Expand All'}
                  <img
                    src={expandArrow}
                    className={
                      this.state.stateListOpen ? 'tableExpandArrowDown' : 'tableExpandArrowUp'
                    }
                  />
                </div>
              ) : (
                <Fragment />
              )}
            </div>
            <div id="sidebar-form-list" className="sidebar-form-nav">
              <div className="formList">
                <MenuList component="nav">{this.generateSidebar()}</MenuList>
              </div>
            </div>
            <div className="add-a-form-btn-container">
              <Button
                id="btnAddFormSidebar"
                color="primary"
                size="small"
                onClick={() => {
                  this.props.onClickAddForm(
                    this.tabFederalDefinition.tabObject,
                    this.tabFederalDefinition.index,
                  );
                }}
                style={sidebarFooterBtn}
                disabled={this.props.assetMode}
              >
                Add a Form
              </Button>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default SideBarForms;

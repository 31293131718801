import WebHelpers from '~/app/webHelpers.js';

export default class WebsocketAPI {
  constructor(socketWorker) {
    this.socketWorker = socketWorker;
    this.InitializeSocket();
  }

  constructJSONMessage = (command, jsonObject) => {
    return JSON.stringify(Object.assign(jsonObject, { command: command }));
  };

  InitializeSocket = () => {
    this.socketWorker.postMessage(
      JSON.stringify({
        command: 'persistent_conn_init',
        socketURL: PERSISTENTSOCKURL,
        accessToken: WebHelpers.getJWT(),
      }),
    );
  };

  CloseSocket = () => {
    this.socketWorker.postMessage(
      JSON.stringify({
        command: 'socket_close',
      }),
    );
  };
}

export const styles = () => ({
  paper: {
    height: '33.1rem',
    width: '35.6rem',
    borderRadius: '4px',
    margin: 'auto',
    position: 'relative',
  },
  backToLogInBtn: {
    display: 'inline',
    marginRight: '1.87rem',
  },
  sendEmailBtn: {
    padding: '0 1.87rem',
  },
  btnBlock: {
    marginTop: '50%',
  },
  spinner: {
    position: 'absolute',
    top: '40%',
    left: '50%',
  },
});

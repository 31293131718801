// Redux imports
import { store } from '~/app/redux/index';
import { actions as appActions } from '~/app/redux/modules/app';

/**
 * @module fieldCharSetValidationHelper
 * @category Returns
 * @subcategory helpers
 */

const showSnackbarError = msg => {
  store.dispatch(
    appActions.showSnackbarMessage(msg, 'error', 3500, { vertical: 'top', horizontal: 'center' }),
  );
};

/**
 * Validate field input by field type comparing field value
 * with field charset.
 *
 * @param {Object} e current event object tied to a specific form field
 */
export const fieldCharSetValidation = e => {
  const keyChar = e.key.toUpperCase();
  if (e.target.classList.contains('read-only')) {
    return;
  }

  const ele = document.getElementById(e.target.id);
  const fieldType = e.target.getAttribute('data-field-type');
  let fieldCharSet = ele.getAttribute('data-field-charset');

  if (fieldCharSet && fieldType.toUpperCase() === 'A') {
    fieldCharSet += ' ';
  }

  if (fieldCharSet && !checkActionKeys(e) && !fieldCharSet.toUpperCase().includes(keyChar)) {
    showSnackbarError('Invalid Input: ' + e.key);
    e.preventDefault();
  }

  // This may still be needed, will have QA test first to see what the outcome is.
  if (checkActionKeys(e)) {
    let isValid = true;
    switch (fieldType) {
      case 'I':
        if (!/\d/.test(e.key)) isValid = false; // Not valid if the value is anything other than a digit
        break;
      case 'N':
        if (!/(\d|\.$)/.test(e.key)) isValid = false; // Not valid if the value is anything other than a digit or decimal
        break;
      case 'A':
        break;
      case 'S': // SSN character validation is handled through calc server.
      case 'E': // EIN to be handled like SSN due to deviant input on forms like BC33
        break;
      case 'D':
      case 'M':
      case 'T':
      case 'U':
      case 'Z':
        if (!/\d/.test(e.key)) isValid = false;
        break;
      case 'Y':
        if (!/^[A-Z0-9\s]*$/.test(e.key.toUpperCase())) isValid = false;
        break;
      default:
        break;
    }

    if (!isValid) {
      showSnackbarError('Invalid Input: ' + e.key);
      e.preventDefault();
    }
  }
};

/**
 * checks if field is formatted correctly based on CRY field type
 *
 * @param {string} fieldType single char CRY field type
 * @param {string} fieldValue current field's value
 * @returns {boolean} returns boolean based on if field passes/fails regex
 */
export const isValidCRYFieldFormat = (fieldType, fieldValue, fieldId, fieldCharSet, activeForm) => {
  if (fieldValue.trim() === '' || fieldValue.length === 0) {
    return true;
  }

  const regexp = cryRegex(fieldType, fieldValue);

  let isValidFormat = false;

  // fieldType of X is a checkbox field, all other cases use regex
  if (fieldType === 'X') {
    isValidFormat = fieldValue.toUpperCase() === 'X';
  } else {
    isValidFormat = regexp.test(fieldValue);
  }

  if (checkDeviantInput(activeForm, fieldId, fieldValue)) {
    isValidFormat = true;
  }

  // check for valid charset override. Always check for a charset as it can make the defined type a super or a sub set of the original
  if (fieldCharSet) {
    let areAllCharsValid = true;
    for (const c of fieldValue) if (!fieldCharSet.includes(c)) areAllCharsValid = false;
    isValidFormat ||= areAllCharsValid;
  }

  return isValidFormat;
};

/**
 * Some form fields may have special input cases that do not follow the defined field type. Therefore an exception must be made.
 * Note that some business forms also have exceptions with non standard input for future consideration.
 *
 * @param {*} activeForm form the user is on
 * @param {*} fieldId field number to check on the form
 * @param {*} fieldValue inputed value to check
 */

export const checkDeviantInput = (activeForm, fieldId, fieldValue) => {
  // 1040
  // 8283 Non cash donations. CC, LD table dates columns allow "various"
  if (
    activeForm.substring(0, 4) === 'US55' &&
    (fieldId.substring(0, 2) === 'CC' || fieldId.substring(0, 2) === 'LD') &&
    fieldValue.toUpperCase() === 'VARIOUS'
  ) {
    return true;
  }

  // Date of birth input exceptions. Desktop allows for length 6 input in D/Y 8 fields for auto year prefixing.
  if (
    (activeForm.substring(0, 4) === '0000' || activeForm.substring(0, 4) === 'US01') &&
    (fieldId.substring(0, 2) === 'AY' || fieldId.substring(0, 2) === 'BY') &&
    /^\d{6}$/.test(fieldValue)
  ) {
    // 0000|US01 AY03 - rekeyed
    // 0000 BY03
    return true;
  }
  if (
    (activeForm.substring(0, 4) === '0000' || activeForm.substring(0, 4) === '000b') &&
    (fieldId.substring(0, 4) === '070B' || fieldId.substring(0, 4) === '125B') &&
    /^\d{6}$/.test(fieldValue)
  ) {
    // 0000|000b 070B
    // 0000|000b 125B
    return true;
  }
  if (
    (activeForm.substring(0, 4) === '0000' || activeForm.substring(0, 4) === '000c') &&
    (fieldId.substring(0, 4) === '070C' || fieldId.substring(0, 4) === '125C') &&
    /^\d{6}$/.test(fieldValue)
  ) {
    // 0000|000c 070C
    // 0000|000c 125C
    return true;
  }
  if (
    activeForm.substring(0, 4) === '0000' &&
    (fieldId.substring(0, 4) === 'PDOB' || fieldId.substring(0, 4) === 'SDOB') &&
    /^\d{6}$/.test(fieldValue)
  ) {
    // 0000PDOB, 0000SDOB
    return true;
  }
  if (
    activeForm.substring(0, 4) === 'RECN' &&
    fieldId.substring(0, 4) === '0060' &&
    /^\d{6}$/.test(fieldValue)
  ) {
    // RECN0060
    return true;
  }

  // Corp
  // Form 5472-Information Return of a 25% Foreign-Owned U.S. Corporation or a Foreign Corporation Engaged in a U.S. Trade or Business
  // #CL, #DL, and  #0220 allows for "APPLD FOR" or "FOREIGNUS"
  if (
    activeForm.substring(0, 4) === 'BC33' &&
    // eslint-disable-next-line prettier/prettier
    ((fieldId.substring(0, 2) === 'CL' || fieldId.substring(0, 2) === 'DL') && /\d{2}/.test(fieldId.substring(2, 4)) ||
      fieldId.substring(0, 4) === '0220') &&
    (fieldValue.toUpperCase() === 'APPLD FOR' || fieldValue.toUpperCase() === 'FOREIGNUS')
  ) {
    return true;
  }
  return false;
};

/**
 * cryRegex returns the regex for the passed CRY field type
 *
 * @param {string} fieldType single char CRY field type
 * @param {string} fieldValue current field's value
 * @returns {RegExp} Returns specific regex based on field type
 */
export const cryRegex = (fieldType, fieldValue) => {
  let regexp;
  switch (fieldType) {
    case 'C': // alpha characters
      regexp = /^\D*$/;
      break;
    case 'D': // MMDD, MMDDYY or MMDDYYYY
      regexp = /^(\d{4}|\d{6}|\d{8})$/;
      break;
    case 'Y': // YYYY, MMYYYY or MMDDYYYY
      if (/^\d+$/.test(fieldValue)) {
        regexp = /^(\d{4}|\d{6}|\d{8})$/;
      } else {
        regexp = /^[A-Z0-9\s]*$/;
      }
      break;
    case 'E': // EIN
      regexp = /^\d{2}-?\d{7}$/;
      break;
    case 'I': // Integer
      regexp = /^\d*$/;
      break;
    case 'L': // Number (negative allowed)
      regexp = /^(-?\d+|\d+-?|\d*)(\.\d+)?$/;
      break;
    case 'M': // MMYY or MMYYYY
      regexp = /^(\d{4}|\d{6})$/;
      break;
    case 'N': // Numbers (positive)
      // Calc server will round the value to the nearest integer
      //regexp = /^\d+(\.\d+)?$/; // Valid format if fieldValue is a digit or decimal value

      //update_form calcserver response can prefix spaces and insert comma's into N field(s). When an updated N field gets focused on,
      //then focused out - the previously used regex would cause an invalid value/format error
      //The return will have the prefixed spaces and commas removed in the return blob
      regexp = /^(\s*(\d|\d{2}|\d{3}|\d,\d{4}|\d+))(,\d{3})*(\.\d+)?$/;
      break;
    case 'P': // Percentage
      regexp = /^\d{0,3}\.?\d*$/;
      break;
    case 'R': // Ratio
      regexp = /^\d{0,2}\.?\d*$/;
      break;
    case 'Q': // used as page tag or Yes/No box (The majority are page tags. For e.g. USEA, USTE are not using the pg\d\d CRY +6.4 KeyForm)
      regexp = /^(\w|\s)$/;
      break;
    case 'S': // SSN
      regexp = /^\d{3}-?\d{2}-?\d{4}$/;
      break;
    case 'T': // Telephone #
      regexp = /^\d{10}$/; // let calc server validate NANP phone nomber, but ensure 9 digits are passed to it
      break;
    case 'U': // unsigned numeric.
      regexp = /^\d*$/;
      break;
    case 'Z': // Zip code (US zip code)
      regexp = /^(\d{5}|\d{5}-?\d{4}|\d{5}-?\d{4}-?\d{3})$/; // 5 or 5+4 or 5+4+3 digit zip
      break;
    default:
      regexp = /(?:)/; // empty regex for undefined cases
      break;
  }
  return regexp;
};

/**
 * stripSpecialChars strips special characters given an event. Preserves caret position
 * @param {Object} e current event object tied to a specific form field
 */
export const stripSpecialChars = e => {
  const el = document.getElementById(e.target.id);
  if (el) {
    const ogLen = e.target.value.length;
    const ogStart = el.selectionStart;

    const afterCaret = e.target.value.substring(ogStart, e.target.value.length);

    const newVal = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
    const newLen = newVal.length;

    const offset = afterCaret.length - afterCaret.replace(/[^a-zA-Z0-9]/g, '').length;

    e.target.value = newVal;
    if (el.setSelectionRange) {
      const newPos = ogStart - (ogLen - newLen) + offset;
      el.setSelectionRange(newPos, newPos);
    }
  }
};

/**
 * fixNegatives: For datatype field L if number is negative we keep appropriate formatting.
 * @param {Object} e current event object tied to a specific form field
 */
export const fixNegatives = e => {
  stripSpecialChars(e);
  const pos = e.target.selectionStart + 1;
  e.target.value = '-' + e.target.value;
  e.target.setSelectionRange(pos, pos);
};

/*********************************************************
 * ########## NON-EXPORTABLE FUNCTIONS ##################
 *********************************************************/
/**
 * Check for action keys such as:
 * backspace
 * tab
 * enter
 * alt
 * shift
 * escape
 */
export const checkActionKeys = e => {
  const actionKeys = [
    8, // backspace
    9, // tab
    13, // enter
    16, // shift
    18, // alt
    27, // escape
  ];

  return !actionKeys.includes(e.which);
};

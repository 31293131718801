// External imports
import React from 'react';
import NumberFormat from 'react-number-format';
import {
  Button,
  Collapse,
  Divider,
  IconButton,
  Input,
  Modal,
  Paper,
  withStyles,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
// Internal imports
import BillingItemListModal from '../Setup/BillingItemListModal.jsx';
import { useSetState } from '~/app/Utility/customHooks';
import AccessControl from '#/Auth/AccessControl.jsx';
import xIcon from '~/images/icons/icons8-multiply.png';
// Styling imports
import { styles } from '~/app/Components/NewAccount/SingleOffice/css/formBilling.js';

const FormBilling = props => {
  const { classes } = props;
  const [state, setState] = useSetState({
    addFormModalOpen: false,
    expanded: true,
    selectedRow: '',
    hoveredRow: null,
  });

  const updateRowHoverEnter = index => {
    setState({ hoveredRow: index });
  };

  const updateRowHoverLeave = () => {
    setState({ hoveredRow: null });
  };

  const toggleAddFormList = () => {
    if (!props.defaultSchemeSelected) {
      alert(
        "You must first add a new billing scheme before continuing. \n\nPlease click the button next to 'Set as Default' to name and save your new Billing scheme.",
      );
      return;
    }
    setState({
      addFormModalOpen: !state.addFormModalOpen,
    });
  };

  const handleExpandClick = () => {
    setState(state => ({ expanded: !state.expanded }));
  };

  const rowIsHighlighted = rowID => {
    return state.selectedRow === rowID;
  };

  const buildFormBillingTable = () => {
    // see BillingItemListModal component, buildFormBillingTable, should there be other exceptions
    let filteredList = props.tbRowsFormBilling;
    if (!props.customTags?.includes('104A')) {
      // Desktop hides this for non 104A users (CTTS)
      filteredList = filteredList.filter(
        item => item.form_name !== '1040 Data' && item.form_description !== '1040 Data Entry Form',
      );
    }
    return filteredList.map(
      (item, key) =>
        item.form_type === props.form_type && (
          <tr
            hover="true"
            onMouseEnter={() => updateRowHoverEnter(item.billing_lookup_id)}
            onMouseLeave={updateRowHoverLeave}
            key={item.billing_lookup_id}
            className={rowIsHighlighted(item.billing_lookup_id) ? 'tableRowBoxShadow' : 'tableRow'}
          >
            <td>
              <Input
                classes={{ root: classes.readOnlyField }}
                style={inputStyle}
                id={`txtFormName${key}`}
                value={item.form_name}
                disableUnderline
                readOnly
                disabled
              />
            </td>
            <td>
              <Input
                classes={{ root: classes.readOnlyField }}
                style={inputStyleDesc}
                id={`txtFormDesc${key}`}
                value={item.form_description}
                disableUnderline
                readOnly
                disabled
              />
            </td>
            <td>
              <NumberFormat
                style={inputStyleQty}
                id={`txtFormBaseQty${key}`}
                value={item.baseQty}
                onChange={e =>
                  props.handleChangeTable(item.billing_lookup_id, 'baseQty', e.target.value)
                }
                disableUnderline
                customInput={Input}
              />
            </td>
            <td>
              <NumberFormat
                style={inputStylePrice}
                id={`txtFormBasePrice${key}`}
                value={item.basePrice}
                onChange={e =>
                  props.handleChangeTable(item.billing_lookup_id, 'basePrice', e.target.value)
                }
                disableUnderline
                prefix="$"
                thousandSeparator={true}
                customInput={Input}
                fixedDecimalScale={true}
                decimalScale={2}
              />
            </td>
            <td>
              <NumberFormat
                style={inputStylePrice}
                id={`txtFormPerItem${key}`}
                onChange={e =>
                  props.handleChangeTable(item.billing_lookup_id, 'perItem', e.target.value)
                }
                value={item.perItem}
                disableUnderline
                prefix="$"
                thousandSeparator={true}
                customInput={Input}
                fixedDecimalScale={true}
                decimalScale={2}
              />
            </td>
            <td>
              <Input
                classes={{ root: classes.readOnlyField }}
                style={inputStyle}
                id={`txtEntityName${key}`}
                value={item.entityName}
                disableUnderline
                readOnly
                disabled
              />
            </td>
            <td hidden={Boolean(state.hoveredRow !== item.billing_lookup_id)}>
              <Button
                disableRipple
                style={{
                  width: '1em',
                  paddingLeft: '0em',
                  paddingRight: '0em',
                  minHeight: '0em',
                  minWidth: '0em',
                }}
                onClick={() =>
                  props.handleRowDel(
                    item.billing_lookup_id,
                    'tbRowsFormBilling',
                    'billing_lookup_id',
                    props.itemType,
                  )
                }
                classes={{ root: classes.noOutlineButton }}
              >
                <img id="clearInputField" className="noselect" src={xIcon} />
              </Button>
            </td>
          </tr>
        ),
    );
  };

  const inputStyle = { width: '10em', height: '2em' };
  const inputStyleDesc = { width: '17em', height: '2em' };
  const inputStyleQty = { width: '5em', height: '2em' };
  const inputStylePrice = { width: '6em', height: '2em' };

  return (
    <div className="container-fixed">
      <div
        style={{
          textAlign: 'left',
          paddingTop: '3vh',
          paddingBottom: '2vh',
        }}
      >
        <div style={{ float: 'left' }}>
          <IconButton
            id="icobtnExpandFormBilling"
            style={{ width: '1em', height: '1em' }}
            onClick={handleExpandClick}
            aria-expanded={state.expanded}
            classes={{ root: classes.noBorderOnFocus }}
          >
            {state.expanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </div>
        <div>
          <span
            style={{
              fontFamily: 'Roboto',
              fontSize: '21px',
              letterSpacing: '0.14px',
              color: '#1A173B',
              marginLeft: '0.3em',
            }}
          >
            {props.billingTitleType}
            <IconButton
              className={classes.iconButton}
              id={'icobtnInfo' + props.billingTitleType}
              color="primary"
              aria-label="info"
              onClick={() => props.openInfoDialog(props.form_type)}
            >
              <InfoOutlinedIcon />
            </IconButton>
          </span>
        </div>
      </div>
      <Collapse in={state.expanded} timeout="auto" unmountOnExit>
        <AccessControl requiredAction="write" accessLevel="add/edit_billing" disableOnFalse={true}>
          <div>
            <div
              style={{
                marginLeft: '3vw',
                marginRight: '3vw',
                marginTop: '1vh',
              }}
            >
              <div>
                <table
                  onMouseLeave={() => updateRowHoverLeave()}
                  style={{
                    marginTop: '0.3em',
                    marginLeft: '1em',
                    marginRight: '5em',
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          width: '12%',
                          color: '#1A173B',
                          fontFamily: 'Roboto',
                          fontSize: '12px',
                          fontWeight: 'normal',
                          letterSpacing: '1px',
                        }}
                      >
                        {props.billingTableTabName}
                        <Divider style={{ marginBottom: '1vh' }} />
                      </th>
                      <th
                        style={{
                          width: '12%',
                          color: '#1A173B',
                          fontFamily: 'Roboto',
                          fontSize: '12px',
                          fontWeight: 'normal',
                          letterSpacing: '1px',
                        }}
                      >
                        DESCRIPTION
                        <Divider style={{ marginBottom: '1vh' }} />
                      </th>
                      <th
                        style={{
                          width: '10%',
                          color: '#1A173B',
                          fontFamily: 'Roboto',
                          fontSize: '12px',
                          fontWeight: 'normal',
                          letterSpacing: '1px',
                        }}
                      >
                        BASE QTY
                        <Divider style={{ marginBottom: '1vh' }} />
                      </th>
                      <th
                        style={{
                          width: '10%',
                          color: '#1A173B',
                          fontFamily: 'Roboto',
                          fontSize: '12px',
                          fontWeight: 'normal',
                          letterSpacing: '1px',
                        }}
                      >
                        BASE PRICE
                        <Divider style={{ marginBottom: '1vh' }} />
                      </th>
                      <th
                        style={{
                          width: '10%',
                          color: '#1A173B',
                          fontFamily: 'Roboto',
                          fontSize: '12px',
                          fontWeight: 'normal',
                          letterSpacing: '1px',
                        }}
                      >
                        PER ITEM
                        <Divider style={{ marginBottom: '1vh' }} />
                      </th>
                      <th
                        style={{
                          width: '10%',
                          color: '#1A173B',
                          fontFamily: 'Roboto',
                          fontSize: '12px',
                          fontWeight: 'normal',
                          letterSpacing: '1px',
                        }}
                      >
                        ENTITY NAME
                        <Divider style={{ marginBottom: '1vh' }} />
                      </th>
                      <th
                        style={{
                          width: '3%',
                          color: '#1A173B',
                          fontFamily: 'Roboto',
                          fontSize: '12px',
                          fontWeight: 'normal',
                          letterSpacing: '1px',
                        }}
                      />
                    </tr>
                  </thead>
                  <tbody>
                    {buildFormBillingTable()}
                    <tr>
                      <td>
                        <Button
                          id={('btn' + props.addButtonName).replace(/ /g, '')}
                          classes={{ root: classes.noOutlineButtonBlue }}
                          onClick={toggleAddFormList}
                          disableRipple={true}
                        >
                          {props.addButtonName}
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <br />
            </div>
          </div>
        </AccessControl>
      </Collapse>
      <Modal
        id="addFormModal"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={state.addFormModalOpen}
        onClose={toggleAddFormList}
        style={styles.addFormModal}
      >
        <Paper elevation={5} classes={{ root: classes.addFormModal }}>
          <BillingItemListModal
            billingTitle={props.billingSelectTitle}
            billingSelectMessage={props.billingSelectMessage}
            toggleAddBillingItemList={toggleAddFormList}
            form_type={props.form_type}
            updateBillingState={props.updateAddedBilling}
            tbRowsFormBilling={props.tbRowsFormBilling}
            isBusiness={props.isBusiness}
            customTags={props.customTags}
          />
        </Paper>
      </Modal>
    </div>
  );
};

export default withStyles(styles)(FormBilling);

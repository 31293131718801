import { USERDB } from '~/app/constants.js';

export const getListLabels = dbType => {
  switch (dbType) {
    case USERDB.ZIPCODE:
      return {
        idColHeader: 'Zip Code',
        dataColHeader: 'Location',
        dbListTitle: 'Zip Codes',
      };
    case USERDB.EFIN:
      return {
        idColHeader: 'EFIN',
        dataColHeader: 'Company Name',
        dbListTitle: 'EF Originators',
      };
    case USERDB.EMPLOYER:
      return {
        idColHeader: 'EIN',
        dataColHeader: 'Employer Name',
        dbListTitle: 'Employers/Payers',
      };
    case USERDB.CAREPROVIDER:
      return {
        idColHeader: 'Provider Code',
        dataColHeader: 'Provider Name',
        dbListTitle: 'Care Providers',
      };
    case USERDB.REFERRALS:
      return {
        idColHeader: 'Referral Name',
        dataColHeader: '',
        dbListTitle: 'Referrals',
      };
    case USERDB.DONEE:
      return {
        idColHeader: 'EIN',
        dataColHeader: 'Donee Information',
        dbListTitle: 'Donees',
      };
    case USERDB.BANKRTN:
      return {
        idColHeader: 'Routing Number',
        dataColHeader: 'Bank Name',
        dbListTitle: 'Bank RTNs',
      };
    case USERDB.OCCUPATION:
      return {
        idColHeader: 'Occupation',
        dataColHeader: '',
        dbListTitle: 'Occupations',
      };
    case USERDB.SITEIDENTIFIERS:
      return {
        idColHeader: 'Site Code',
        dataColHeader: 'Site Description',
        dbListTitle: 'Site Identifiers',
      };
    case USERDB.USERSTATUSCODES:
      return {
        idColHeader: 'Status Code',
        dataColHeader: 'Status Code Description',
        dbListTitle: 'User Status Codes',
      };
    case USERDB.EDUINST:
      return {
        idColHeader: 'EIN',
        dataColHeader: 'Institution Name',
        dbListTitle: 'Educational Institutions',
      };
  }
};

export const sidebarItems = {
  citiesAndZipcodes: {
    description: 'Cities/Zip Codes',
    path: '/database/citiesandzip',
  },
  employersAndPayers: {
    description: 'Employers/Payers',
    path: '/database/employersandpayers',
  },
  careProviders: {
    description: 'Care Providers',
    path: '/database/careproviders',
  },
  referrals: {
    description: 'Referrals',
    path: '/database/referrals',
  },
  donees: {
    description: 'Donees',
    path: '/database/donees',
  },
  bankRTNs: {
    description: 'Bank RTNs',
    path: '/database/bankrtns',
  },
  occupations: {
    description: 'Occupations',
    path: '/database/occupations',
  },
  siteIdentifiers: {
    description: 'Site Identifiers',
    path: '/database/siteidentifiers',
  },
  userStatusCodes: {
    description: 'User Status Codes',
    path: '/database/userstatuscodes',
  },
  educationalInstitutions: {
    description: 'Educational Institutions',
    path: '/database/educationalinstitutions',
  },
};

import { LOGOUT } from '~/app/redux/commonActions.js';
// Form Viewer module

const RESET_FORM_VIEWER = 'xlinknonline/formViewer/RESET';

const SET_CHOICE_LIST = 'xlinkonline/formViewer/choiceList/SET';
const TOGGLE_CHOICE_LIST = 'xlinkonline/formViewer/choiceList/TOGGLE';

const SET_REMOTE_INVOICE = 'xlinkonline/formViewer/remoteInvoice/SET';
const TOGGLE_REMOTE_INVOICE = 'xlinkonline/formViewer/remoteInvoice/TOGGLE';

const SET_ADD_FORM_LIST = 'xlinkonline/formViewer/addForm/SET';
const TOGGLE_ADD_FORM_LIST = 'xlinkonline/formViewer/addForm/TOGGLE';

const SET_ACTIVITY_LIST = 'xlinkonline/formViewer/activityList/SET';

const TOGGLE_ADD_ASSET = 'xlinkonline/formViewer/addAsset/TOGGLE';

const SET_DOCUMENTS_ATTACH = 'xlinkonline/formViewer/addDocument/SET';
const CLOSE_DOCUMENT_ATTACH = 'xlinkonline/formViewer/addDocument/CLOSE';
const OPEN_DOCUMENT_ATTACH = 'xlinkonline/formViewer/addDocument/OPEN';

const OPEN_MESSAGE_DIALOG = 'xlinkonline/formViewer/msgDialog/OPEN';
const CLOSE_MESSAGE_DIALOG = 'xlinkonline/formVIewer/msgDialog/CLOSE';

const OPEN_ERROR_REJECTS = 'xlinkonline/formViewer/errorReject/OPEN';
const CLOSE_ERROR_REJECTS = 'xlinkonline/formViewer/errorReject/CLOSE';

const TOGGLE_EVENT_LOG = 'xlinkonline/formViewer/eventLog/TOGGLE';

const TOGGLE_K1_MANAGER = 'xlinkonline/formViewer/k1manager/TOGGLE';

const TOGGLE_PRINT_DIALOG = 'xlinkonline/formViewer/printDialog/TOGGLE';

const TOGGLE_REMOTE_SIGNATURE_DIALOG = 'xlinkonline/formViewer/remoteSignatureDialog/TOGGLE';

const TOGGLE_REMOTE_SIGNATURE_SECOND_DIALOG =
  'xlinkonline/formViewer/remoteSignatureSecondDialog/TOGGLE';

const TOGGLE_SIGNATURE_PAD_TYPE_SELECTION = 'xlinkonline/formViewer/signaturePadTypeDialog/TOGGLE';

const TOGGLE_SIGNATURE_CAPTURE = 'xlinkonline/formViewer/signature/TOGGLE';

const TOGGLE_TOPAZ_SIGNATURE_CAPTURE = 'xlinkonline/formViewer/topazsignature/TOGGLE';

const TOGGLE_SCRIPTEL_SIGNATURE_CAPTURE = 'xlinkonline/formViewer/scriptelsignature/TOGGLE';

const TOGGLE_TEXLINK_SEND_MODAL = 'xlinkonline/formViewer/textLinkSendMessagModal/TOGGLE';

const TOGGLE_TEXLINK_SEND_MODAL_ORGINAL =
  'xlinkonline/formViewer/textLinkSendMessagModal/TOGGLE/ORIGINAL';

const TOGGLE_TEXTLINK_RECEIVED_MODAL =
  'xlinkonline/formViewer/textLinkReceivedMessagesModal/TOGGLE';

const TOGGLE_TEXTLINK_NOT_ACTIVATED_MODAL = 'xlinkonline/formViewer/textlinkNotActivated/TOGGLE';

const SHOW_NO_TEXTLINK_CLIENTS_DLG = 'xlinkonline/formViewershowNoTextlinkClients/TOGGLE';

const TOGGLE_TEXTLINK_CONFIRM_SENT_DLG = 'xlinkonline/toggleTextlinkConfirmSentDlg/TOGGLE';

const SET_TEXTLINK_ACTIVATION_STATUS = 'xlinkonline/setTextLinkActivationStatus';

const OPEN_YTY_DIALOG = 'xlinknonline/formViewer/ytyDialog/OPEN';
const CLOSE_YTY_DIALOG = 'xlinknonline/formViewer/ytyDialog/CLOSE';

const OPEN_CURRENT_YEAR_DIALOG = 'xlinknonline/formViewer/cyDialog/OPEN';
const CLOSE_CURRENT_YEAR_DIALOG = 'xlinknonline/formViewer/cyDialog/CLOSE';
const SET_YTY_RETURNS = 'xlinknonline/formViewer/cyDialog/SETYTYRETURNS';

const SET_STATE_ACKS = 'xlinkonline/formViewer/stateAcks/SET';
const SET_ATTACHED_STATES = 'xlinkonline/formViewer/attachedStates/SET';

const OPEN_ERROR_DIALOG = 'xlinkonline/formViewer/errorDialog/OPEN';
const CLOSE_ERROR_DIALOG = 'xlinkonline/formViewer/errorDialog/CLOSE';

const TOGGLE_VERIFY_SUCCESS_DIALOG = 'xlinkonline/formViewer/errorDialog/TOGGLEVERIFYSUCCESSDIALOG';

const SHOW_VERIFY_MODAL_SUCCESS_DLG =
  'xlinkonline/formViewer/errorDialog/SHOWVERIFYMODALSUCCESSDLG';

const SET_REJECT_TAB = 'xlinkonline/formViewer/REJECTTAB';

const SET_ESTIMATOR_BLOB = 'xlinkonline/formViewer/estimator/BLOBSET';
const SET_ESTIMATOR_SSN = 'xlinkonline/formViewer/estimator/SSNSET';
const SET_ESTIMATOR_DATASTORE = 'xlinkonline/formViewer/estimator/DATASTORESET';
const CLEAR_ESTIMATOR_STATE = 'xlinkonline/formViewer/estimator/CLEAR';

const SET_ADD_FORM_TAB_VALUE = 'xlinkonline/formViewer/addForm/SETTABVALUE';
const SET_ADD_FORM_STATE = 'xlinkonline/formViewer/addForm/SETADDFORMSTATE';
const SET_ADD_FORM_PACKAGE = 'xlinkonline/formViewer/addForm/SETADDFORMPACKAGE';
const SET_ADD_FORM_SELECTED_FORM = 'xlinkonline/formViewer/addForm/SETADDFORMSELECTEDFORM';
const SET_ADD_FORM_SUBDIALOG_FORMS = 'xlinkonline/formViewer/addForm/SETADDSUBDIALOGFORMS';

const SET_ADD_FORM_LIST_FILTERED = 'xlinkonline/formViewer/addForm/SETADDFORMLISTFILTERED';

const SET_ADD_FORM_FILTER = 'xlinkonline/formViewer/addForm/SETADDFORMFILTER';
const SET_STATE_PACKAGE_SELECTED = 'xlinkonline/formViewer/addForm/SETSTATEPACKAGESELECTED';
const SET_ADD_FORM_HAS_SUBDIALOG = 'xlinkonline/formViewer/addForm/SETADDFORMHASSUBDIALOG';

const TOGGLE_SUBMIT_FOR_REVIEW_DIALOG = 'xlinkonline/formViewer/TOGGLESUBMIT_ORREVIEWDIALOG';

// SET_UPDATE_ATTACHMENTS_LIST
const SET_UPDATE_ATTACHMENTS_LIST = 'xlinkonline/formViewer/UPDATEATTACHMENTLIST';

const TOGGLE_SUGGESTED_PASSWORD_MODAL = 'xlinkonline/formViewer/suggestedPasswordDialog/TOGGLE';

const TOGGLE_MISSING_INFO_MODAL = 'xlinkonline/formViewer/missingInfoDialog/TOGGLE';

const TOGGLE_SUGGESTED_PASSWORD_SAVE_MODAL =
  'xlinkonline/formViewer/suggestedPasswordSaveDialog/TOGGLE';

const TOGGLE_ENCRYPTED_PDF_MODAL = 'xlinkonline/formViewer/encryptedPdfDialog/TOGGLE';
const SET_RMS_FLAG = 'xlinkonline/formViewer/SETRMSFLAG';
const SET_ENCRYPTED_PASSWORD = 'xlinkonline/formViewer/SETENCRYPTEDPASSWORD';
const SET_ENCRYPTED_PDF_TITLE = 'xlinkonline/formViewer/SETENCRYPTEDPDFTITLE';
const TAXPAYER_INFORMATION_SET = 'xlinkonline/formViewer/TAXPAYERINFORMATIONSET';
const SPOUSE_INFORMATION_SET = 'xlinkonline/formViewer/SPOUSEINFORMATIONSET';
const SET_TAXPAYER_EMAIL = 'xlinkonline/formViewer/SETTAXPAYEREMAIL';
const SET_SPOUSE_EMAIL = 'xlinkonline/formViewer/SETSPOUSEEMAIL';
const SET_PREPARER_EMAIL = 'xlinkonline/formViewer/SETPREPAREREMAIL';
const SET_PREPARER_ID = 'xlinkonline/formViewer/SETPREPARERID';
const SET_AMENDED_PREPARER_ID = 'xlinkonline/formViewer/SETAMENDEDPREPARERID';
const SET_ENCRYPTED_PDF_REQUESTED = 'xlinkonline/formViewer/SETENCRYPTEDPDF REQUESTED';
const SET_EMAIL_BLOB = 'xlinkonline/formViewer/SETEMAILBLOB';
const SET_ENCRYPTED_DOC_CONTENT = 'xlinkonline/formViewer/SETENCRYPTEDDOCCONTENT';
const SET_TAXPAYER_OPTION_CHECKED = 'xlinkonline/formViewer/SETTAXPAYERCHECKED';
const SET_SPOUSE_OPTION_CHECKED = 'xlinkonline/formViewer/SETSPOUSECHECKED';
const SET_REP_OPTION_CHECKED = 'xlinkonline/formViewer/SETREPCHECKED';
const SET_TAXPAYER_E_PASS_OPTION_CHECKED = 'xlinkonline/formViewer/SETTAXPAYEREPASSCHECKED';
const SET_SPOUSE_E_PASS_OPTION_CHECKED = 'xlinkonline/formViewer/SETSPOUSEEPASSCHECKED';
const SET_REP_E_PASS_OPTION_CHECKED = 'xlinkonline/formViewer/SETREPEPASSCHECKED';
const SET_TAXPAYER_TEXT_CHECKED = 'xlinkonline/formViewer/SETTAXPAYERTEXTCHECKED';
const SET_SPOUSE_TEXT_CHECKED = 'xlinkonline/formViewer/SETSPOUSETEXTCHECKED';
const SET_REP_TEXT_CHECKED = 'xlinkonline/formViewer/SETREPTEXTCHECKED';
const SET_SENT_SUCCESS_TITLE = 'xlinkonline/formViewer/SETSENTSUCCESSTITLE';
const SET_SENT_SUCCESS_MESSAGE = 'xlinkonline/formViewer/SETSENTSUCCESSMSG';
const SET_TAXPAYER_NAME = 'xlinkonline/formViewer/SETTAXPAYERNAME';
const SET_TAXPAYER_ALLOW_TEXT = 'xlinkonline/formViewer/SETTAXPAYERALLOWTEXT';
const SET_TAXPAYER_CELL_PHONE_NUMBER = 'xlinkonline/formViewer/SETTAXPAYERCELLPHONENUMBER';
const SET_TAXPAYER_CELL_PHONE_CARRIER = 'xlinkonline/formViewer/SETTAXPAYERCELLPHONECARRIER';
const SET_TAXPAYER_CELL_PHONE_DOMAIN = 'xlinkonline/formViewer/SETTAXPAYERCELLPHONEDOMAIN';
const SET_SPOUSE_NAME = 'xlinkonline/formViewer/SETSPOUSENAME';
const SET_SPOUSE_ALLOW_TEXT = 'xlinkonline/formViewer/SETSPOUSEALLOWTEXT';
const SET_SPOUSE_CELL_PHONE_NUMBER = 'xlinkonline/formViewer/SETSPOUSECELLPHONENUMBER';
const SET_SPOUSE_CELL_PHONE_CARRIER = 'xlinkonline/formViewer/SETSPOUSECELLPHONECARRIER';
const SET_SPOUSE_CELL_PHONE_DOMAIN = 'xlinkonline/formViewer/SETSPOUSECELLPHONEDOMAIN';
const SET_SPOUSE_INFO_AVAILABLE = 'xlinkonline/formViewer/SETSPOUSEINFOAVAILABLE';
const SET_PDF_DIALOG_SUCCESS_MODAL = 'xlinkonline/formViewer/SETPDFDIALOGSUCCESSMODAL';
const SET_PREFERRED_SIGNATURE_PAD = 'xlinkonline/formViewer/SETPREFERREDSIGNATUREPAD';
const TEXT_LINK_CLIENT_INFO_FETCHED = 'xlinkonline/formviewer/TEXTLINKCLIENTINFOFETCHED';
const NEEDING_VERIFIED_SET = 'xlinkonline/formviewer/NEEDINGVERIFIEDSET';
const REMOTE_SIGN_SUCCESSFUL_SET = 'xlinkonline/formviewer/REMOTESIGNSUCCESSFULSET';
const IN_OFFICE_SIGN_SUCCESSFUL_SET = 'xlinkonline/formviewer/INOFFICESIGNSUCCESSFULSET';
const SIGNATURE_REQUEST_ACTIVE_SET = 'xlinkonline/formviewer/SIGNATUREREQUESTACTIVESET';
const TOGGLE_TAXPASS_MESSAGE_MODAL = 'xlinkonline/formviewer/TOGGLE_TAXPASS_MESSAGE_MODAL';
const RESET_CHOICE_LIST = 'xlinkonline/formviewer/CLEAR_CHOICE_LIST';
const CLOSE_FORM_SUBDIALOG_FORMS = 'xlinkonline/formviewer/CLOSE_FORM_SUBDIALOG_FORMS';
const SET_DOCUMENT_TYPES = 'xlinkonline/formviewer/SETDOCUMENTTYPES';
const TOGGLE_PREP_RMS_COPY = 'xlinkonline/formviewer/TOGGLEPREPRMS';
const SET_IS_SELF_PREPARING = 'xlinkonline/formviewer/SETISSELFPREPARING';

const initialState = {
  addFormModalOpen: false,
  errorRejectModalOpen: false,
  choiceListModalOpen: false,
  addDocumentModalOpen: false,
  isTransferDataModalOpen: false,
  isCurrentYearModalOpen: false,
  isK1ManagerModalOpen: false,
  eventLogModalOpen: false,
  addAssetModalOpen: false,
  documentsToBeAttached: {},
  messageDialogOpen: false,
  messageDialogInfo: {},
  errorDialogOpen: false,
  errorDialogMessage: '',
  errorDialogTitle: '',
  printDialogOpen: false,
  remoteInvoiceModalOpen: false,
  remoteInvoiceData: {},
  remoteSignatureDialogOpen: false,
  remoteSignatureSecondDialogOpen: false,
  signaturePadTypeDialogOpen: false,
  signatureCaptureModalOpen: false,
  topazSignatureCaptureModalOpen: false,
  scriptelSignatureCaptureModalOpen: false,
  textLinkModalOpen: false,
  textLinkModalContent: '',
  textLinkModalContentOriginal: '',
  textLinkReceivedModalOpen: false,
  taxpassMessageModalOpen: false,
  textLinkNoClients: false,
  textlinkSendSuccess: false,
  isTextLinkActivated: false,
  textLinkNotActivatedDlg: false,
  addFormList: [],
  activityList: [],
  choiceListTitle: '',
  choiceListIniField: '',
  choiceListData: [],
  choiceListFields: [],
  choiceListColumns: [],
  pyReturns: [],
  cyReturns: [],
  stateAcks: {},
  attachedStates: {},
  existingReturnType: 0,
  estimatorState: undefined,
  addFormTabValue: 0,
  addFormFirst: false,
  addFormPkg: '',
  setSelectedForm: undefined,
  setSubDialogList: undefined,
  setSubDialogListOpened: false,
  addFormListFiltered: [],
  filterOn: false,
  statePackageSelected: '',
  formWithSubdialog: false,
  showVerifySuccessDlg: false,
  showVerifyModalSuccessDlg: false,
  attachmentsListUpdated: [],
  submitForReviewDlg: false,
  suggestedPasswordModalOpen: false,
  encryptedPassword: '',
  encryptedPdfTitle: '',
  suggestedPasswordSaveModalOpen: false,
  taxpayerInformation: {},
  spouseInformation: {},
  taxpayerEmailForPdf: '',
  spouseEmailForPdf: '',
  preparerEmailForPdf: '',
  preparerID: '',
  amendedPreparerID: '',
  encryptedPdfDialogModalOpen: false,
  encryptedPdfSelected: false,
  emailBlob: '',
  attachmentContent: '',
  taxpayerChecked: false,
  spouseChecked: false,
  representativeChecked: false,
  taxpayerEPassChecked: false,
  spouseEPassChecked: false,
  representativeEPassChecked: false,
  sentSuccessTitle: '',
  sentSuccessMsg: '',
  missingInfoModalOpen: false,
  taxpayerName: '',
  taxpayerAllowText: '',
  taxpayerCellPhoneNumber: '',
  taxpayerCellPhoneCarrier: '',
  taxpayerCellPhoneDomain: '',
  spouseName: '',
  spouseAllowText: '',
  spouseCellPhoneNumber: '',
  spouseCellPhoneCarrier: '',
  spouseCellPhoneDomain: '',
  spouseInfoAvailable: false,
  taxpayerTextChecked: false,
  spouseTextChecked: false,
  representativeTextChecked: false,
  pdfModalSuccess: false,
  preferredSignaturePad: 0,
  textLinkClientInfo: [],
  isNeedingVerified: false,
  remoteSignSuccessful: false,
  isSignatureRequestActive: false,
  inOfficeSignSuccessful: false,
  isRMSSigningMethod: false,
  documentTypes: [],
  activeErrorRejectTab: null,
  sendRMSCopyToPrep: false,
  isSelfPreparing: false,
};

export const actions = {
  setPreferredSignaturePad: signaturePadSelected => ({
    type: SET_PREFERRED_SIGNATURE_PAD,
    signaturePadSelected,
  }),
  setEncryptedPDFSuccessDialog: pdfDialogSuccess => ({
    type: SET_PDF_DIALOG_SUCCESS_MODAL,
    pdfDialogSuccess,
  }),
  setSpouseInfoAvailable: spInfoAvailable => ({
    type: SET_SPOUSE_INFO_AVAILABLE,
    spInfoAvailable,
  }),
  setSpouseName: spouseName => ({
    type: SET_SPOUSE_NAME,
    spouseName,
  }),
  setSpouseAllowText: spAllowText => ({
    type: SET_SPOUSE_ALLOW_TEXT,
    spAllowText,
  }),
  setSpouseCellPhoneNumber: spCellPhoneNumber => ({
    type: SET_SPOUSE_CELL_PHONE_NUMBER,
    spCellPhoneNumber,
  }),
  setSpouseCellPhoneCarrier: spCellPhoneCarrier => ({
    type: SET_SPOUSE_CELL_PHONE_CARRIER,
    spCellPhoneCarrier,
  }),
  setSpouseCellPhoneDomain: spCellPhoneDomain => ({
    type: SET_SPOUSE_CELL_PHONE_DOMAIN,
    spCellPhoneDomain,
  }),
  setTaxpayerName: taxpayerName => ({
    type: SET_TAXPAYER_NAME,
    taxpayerName,
  }),
  setTaxpayerAllowText: tpAllowText => ({
    type: SET_TAXPAYER_ALLOW_TEXT,
    tpAllowText,
  }),
  setTaxpayerCellPhoneNumber: tpCellPhoneNumber => ({
    type: SET_TAXPAYER_CELL_PHONE_NUMBER,
    tpCellPhoneNumber,
  }),
  setTaxpayerCellPhoneCarrier: tpCellPhoneCarrier => ({
    type: SET_TAXPAYER_CELL_PHONE_CARRIER,
    tpCellPhoneCarrier,
  }),
  setTaxpayerCellPhoneDomain: tpCellPhoneDomain => ({
    type: SET_TAXPAYER_CELL_PHONE_DOMAIN,
    tpCellPhoneDomain,
  }),
  setTaxpayerSelected: taxpayerOptionChecked => ({
    type: SET_TAXPAYER_OPTION_CHECKED,
    taxpayerOptionChecked,
  }),
  setSpouseSelected: spouseOptionChecked => ({
    type: SET_SPOUSE_OPTION_CHECKED,
    spouseOptionChecked,
  }),
  representativeSelected: repOptionChecked => ({
    type: SET_REP_OPTION_CHECKED,
    repOptionChecked,
  }),
  setTaxpayerSelectedEPass: taxpayerEPassOptionChecked => ({
    type: SET_TAXPAYER_E_PASS_OPTION_CHECKED,
    taxpayerEPassOptionChecked,
  }),
  setSpouseSelectedEPass: spouseEPassOptionChecked => ({
    type: SET_SPOUSE_E_PASS_OPTION_CHECKED,
    spouseEPassOptionChecked,
  }),
  representativeSelectedEPass: repEPassOptionChecked => ({
    type: SET_REP_E_PASS_OPTION_CHECKED,
    repEPassOptionChecked,
  }),
  setTaxpayerSelectedText: tpTextChecked => ({
    type: SET_TAXPAYER_TEXT_CHECKED,
    tpTextChecked,
  }),
  setSpouseSelectedText: spTextChecked => ({
    type: SET_SPOUSE_TEXT_CHECKED,
    spTextChecked,
  }),
  representativeSelectedText: repTextChecked => ({
    type: SET_REP_TEXT_CHECKED,
    repTextChecked,
  }),
  setSentSuccessTitle: successMsgTitle => ({
    type: SET_SENT_SUCCESS_TITLE,
    successMsgTitle,
  }),
  setSentSuccessMsg: successMsg => ({
    type: SET_SENT_SUCCESS_MESSAGE,
    successMsg,
  }),
  setEncryptedDocumentContent: pdfBlobFromCalcServer => ({
    type: SET_ENCRYPTED_DOC_CONTENT,
    pdfBlobFromCalcServer,
  }),
  setEmailBlob: emailBlobFromCalcServer => ({
    type: SET_EMAIL_BLOB,
    emailBlobFromCalcServer,
  }),
  setUpdatedAttachmentList: attachmentsReturnPrint => ({
    type: SET_UPDATE_ATTACHMENTS_LIST,
    attachmentsReturnPrint,
  }),
  setFormHasSubdialog: hasSubDialogFlag => ({
    type: SET_ADD_FORM_HAS_SUBDIALOG,
    hasSubDialogFlag,
  }),
  setStatePackageSelected: statePackageSel => ({
    type: SET_STATE_PACKAGE_SELECTED,
    statePackageSel,
  }),
  setFilterStatus: filterStatus => ({
    type: SET_ADD_FORM_FILTER,
    filterStatus,
  }),
  setAddFormListFiltered: formsFiltered => ({
    type: SET_ADD_FORM_LIST_FILTERED,
    formsFiltered,
  }),
  setSubDialogFormList: subDialogList => ({
    type: SET_ADD_FORM_SUBDIALOG_FORMS,
    subDialogList,
  }),
  setSelectedFormToAdd: forms => ({
    type: SET_ADD_FORM_SELECTED_FORM,
    forms,
  }),
  setAddFormPackage: addFormPackage => ({
    type: SET_ADD_FORM_PACKAGE,
    addFormPackage,
  }),
  setFirstAddForm: addFormOpened => ({
    type: SET_ADD_FORM_STATE,
    addFormOpened,
  }),
  setAddFormTab: tabValueSelected => ({
    type: SET_ADD_FORM_TAB_VALUE,
    tabValueSelected,
  }),
  setChoiceList: choices => ({
    type: SET_CHOICE_LIST,
    choices,
  }),
  resetChoiceList: () => ({
    type: RESET_CHOICE_LIST,
  }),
  toggleChoiceList: toggle => ({
    type: TOGGLE_CHOICE_LIST,
    toggle,
  }),
  setEstimatorBlob: blob => ({
    type: SET_ESTIMATOR_BLOB,
    blob,
  }),
  setEstimatorSSN: ssn => ({
    type: SET_ESTIMATOR_SSN,
    ssn,
  }),
  setEstimatorDatastore: datastore => ({
    type: SET_ESTIMATOR_DATASTORE,
    datastore,
  }),
  clearEstimatorState: () => ({
    type: CLEAR_ESTIMATOR_STATE,
  }),
  setAddFormList: forms => ({
    type: SET_ADD_FORM_LIST,
    forms,
  }),
  toggleAddFormList: () => ({
    type: TOGGLE_ADD_FORM_LIST,
  }),
  toggleAddAssetModal: toggle => ({
    type: TOGGLE_ADD_ASSET,
    toggle,
  }),
  setActivityList: activities => ({
    type: SET_ACTIVITY_LIST,
    activities,
  }),
  openErrorRejectModal: () => ({
    type: OPEN_ERROR_REJECTS,
  }),
  closeErrorRejectModal: () => ({
    type: CLOSE_ERROR_REJECTS,
  }),
  toggleVerifyDialog: toggle => ({
    type: TOGGLE_VERIFY_SUCCESS_DIALOG,
    toggle,
  }),
  toggleVerifyModalDialog: flag => ({
    type: SHOW_VERIFY_MODAL_SUCCESS_DLG,
    flag,
  }),
  setRejectTab: reject => ({
    type: SET_REJECT_TAB,
    reject,
  }),
  toggleEventLogModal: toggle => ({
    type: TOGGLE_EVENT_LOG,
    toggle,
  }),
  toggleK1ManagerModal: toggle => ({
    type: TOGGLE_K1_MANAGER,
    toggle,
  }),
  setSuggestedPassword: psw => ({
    type: SET_ENCRYPTED_PASSWORD,
    psw,
  }),
  setEncryptedPdfTitle: emailTitle => ({
    type: SET_ENCRYPTED_PDF_TITLE,
    emailTitle,
  }),
  taxpayerInformationSet: taxpayerInformation => ({
    type: TAXPAYER_INFORMATION_SET,
    taxpayerInformation,
  }),
  spouseInformationSet: spouseInformation => ({
    type: SPOUSE_INFORMATION_SET,
    spouseInformation,
  }),
  setTaxpayerEmail: taxpayerEmailFromForm => ({
    type: SET_TAXPAYER_EMAIL,
    taxpayerEmailFromForm,
  }),
  setSpouseEmail: spouseEmailFromForm => ({
    type: SET_SPOUSE_EMAIL,
    spouseEmailFromForm,
  }),
  setPreparerEmail: preparerEmailFromForm => ({
    type: SET_PREPARER_EMAIL,
    preparerEmailFromForm,
  }),
  setPreparerID: preparerID => ({
    type: SET_PREPARER_ID,
    preparerID,
  }),
  setAmendedPreparerID: amendedPreparerID => ({
    type: SET_AMENDED_PREPARER_ID,
    amendedPreparerID,
  }),
  togglePrintDialog: toggle => ({
    type: TOGGLE_PRINT_DIALOG,
    toggle,
  }),
  toggleRemoteSignatureDialog: toggle => ({
    type: TOGGLE_REMOTE_SIGNATURE_DIALOG,
    toggle,
  }),
  toggleRemoteSignatureSecondDialog: toggle => ({
    type: TOGGLE_REMOTE_SIGNATURE_SECOND_DIALOG,
    toggle,
  }),
  toggleSignaturePadType: toggle => ({
    type: TOGGLE_SIGNATURE_PAD_TYPE_SELECTION,
    toggle,
  }),
  toggleMissingInfoModal: toggle => ({
    type: TOGGLE_MISSING_INFO_MODAL,
    toggle,
  }),
  toggleSuggestedPassword: toggle => ({
    type: TOGGLE_SUGGESTED_PASSWORD_MODAL,
    toggle,
  }),
  toggleSaveSuggestedPassword: toggle => ({
    type: TOGGLE_SUGGESTED_PASSWORD_SAVE_MODAL,
    toggle,
  }),
  toggleEncryptedPdf: toggle => ({
    type: TOGGLE_ENCRYPTED_PDF_MODAL,
    toggle,
  }),
  setEncryptedPdfRequest: encryptedPdfRequest => ({
    type: SET_ENCRYPTED_PDF_REQUESTED,
    encryptedPdfRequest,
  }),
  toggleSignatureCaptureModal: toggle => ({
    type: TOGGLE_SIGNATURE_CAPTURE,
    toggle,
  }),
  toggleTopazSignatureCaptureModal: toggle => ({
    type: TOGGLE_TOPAZ_SIGNATURE_CAPTURE,
    toggle,
  }),
  toggleScriptelSignatureCaptureModal: toggle => ({
    type: TOGGLE_SCRIPTEL_SIGNATURE_CAPTURE,
    toggle,
  }),
  toggleTextLinkSendModal: (toggle, content) => ({
    type: TOGGLE_TEXLINK_SEND_MODAL,
    toggle,
    content,
  }),
  toggleTextLinkSendModalOriginal: (toggle, content) => ({
    type: TOGGLE_TEXLINK_SEND_MODAL_ORGINAL,
    toggle,
    content,
  }),
  toggleTextLinkReceivedModal: toggle => ({
    type: TOGGLE_TEXTLINK_RECEIVED_MODAL,
    toggle,
  }),
  toggleTaxpassMessageModal: (toggleVal = null) => ({
    type: TOGGLE_TAXPASS_MESSAGE_MODAL,
    toggleVal,
  }),
  toggleTextLinkNotActivatedDialog: toggle => ({
    type: TOGGLE_TEXTLINK_NOT_ACTIVATED_MODAL,
    toggle,
  }),
  setTextLinkActivationStatus: status => ({
    type: SET_TEXTLINK_ACTIVATION_STATUS,
    status,
  }),
  toggleShowNoTextLinkClientsDlg: toggle => ({
    type: SHOW_NO_TEXTLINK_CLIENTS_DLG,
    toggle,
  }),
  toggleTextlinkConfirmSentDlg: toggle => ({
    type: TOGGLE_TEXTLINK_CONFIRM_SENT_DLG,
    toggle,
  }),
  setDocumentsAttach: docs => ({
    type: SET_DOCUMENTS_ATTACH,
    docs,
  }),
  closeDocumentAttach: () => ({
    type: CLOSE_DOCUMENT_ATTACH,
  }),
  openDocumentAttach: () => ({
    type: OPEN_DOCUMENT_ATTACH,
  }),
  openMsgDialog: msg => ({
    type: OPEN_MESSAGE_DIALOG,
    msg,
  }),
  closeMsgDialog: () => ({
    type: CLOSE_MESSAGE_DIALOG,
  }),
  openYTYDialog: () => ({
    type: OPEN_YTY_DIALOG,
  }),
  closeFormSubDialog: () => ({
    type: CLOSE_FORM_SUBDIALOG_FORMS,
  }),
  openCurrentYearModal: () => ({
    type: OPEN_CURRENT_YEAR_DIALOG,
  }),
  setYTYReturns: (pyReturns, cyReturns) => ({
    type: SET_YTY_RETURNS,
    pyReturns,
    cyReturns,
  }),
  closeCurrentYearModal: () => ({
    type: CLOSE_CURRENT_YEAR_DIALOG,
  }),
  closeYTYDialog: () => ({
    type: CLOSE_YTY_DIALOG,
  }),
  setStateAcks: states => ({
    type: SET_STATE_ACKS,
    states,
  }),
  setAttachedStates: states => ({
    type: SET_ATTACHED_STATES,
    states,
  }),
  showErrorDialog: (message, title) => ({
    type: OPEN_ERROR_DIALOG,
    message,
    title,
  }),
  hideErrorDialog: () => ({
    type: CLOSE_ERROR_DIALOG,
  }),
  toggleSubmitForReviewDlg: flag => ({
    type: TOGGLE_SUBMIT_FOR_REVIEW_DIALOG,
    flag,
  }),
  textLinkClientInfoFetched: clientInfo => ({
    type: TEXT_LINK_CLIENT_INFO_FETCHED,
    clientInfo,
  }),
  needingVerifiedSet: flag => ({
    type: NEEDING_VERIFIED_SET,
    flag,
  }),
  remoteSignSuccessfulSet: toggleMessage => ({
    type: REMOTE_SIGN_SUCCESSFUL_SET,
    toggleMessage,
  }),
  inOfficeSignSuccessfulSet: toggleMessage => ({
    type: IN_OFFICE_SIGN_SUCCESSFUL_SET,
    toggleMessage,
  }),
  signatureRequestActiveSet: toggleActive => ({
    type: SIGNATURE_REQUEST_ACTIVE_SET,
    toggleActive,
  }),
  setRMSflag: isRMSSigningMethod => ({
    type: SET_RMS_FLAG,
    isRMSSigningMethod,
  }),
  setDocumentTypes: documentTypes => ({
    type: SET_DOCUMENT_TYPES,
    documentTypes,
  }),
  setRemoteInvoice: invoice => ({
    type: SET_REMOTE_INVOICE,
    invoice,
  }),
  toggleRemoteInvoiceModal: toggle => ({
    type: TOGGLE_REMOTE_INVOICE,
    toggle,
  }),
  togglePrepRMSCopy: toggle => ({
    type: TOGGLE_PREP_RMS_COPY,
    toggle,
  }),
  setIsSelfPreparingIndicator: isSelfPreparing => ({
    type: SET_IS_SELF_PREPARING,
    isSelfPreparing,
  }),
};
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RESET_FORM_VIEWER:
    case LOGOUT:
      return {
        addFormModalOpen: initialState.addFormModalOpen,
        choiceListModalOpen: initialState.choiceListModalOpen,
        addDocumentModalOpen: initialState.addDocumentModalOpen,
        documentsToBeAttached: initialState.documentsToBeAttached,
        isK1ManagerModalOpen: initialState.isK1ManagerModalOpen,
        eventLogModalOpen: initialState.eventLogModalOpen,
        addAssetModalOpen: initialState.addAssetModalOpen,
        messageDialogOpen: initialState.messageDialogOpen,
        messageDialogInfo: initialState.messageDialogInfo,
        printDialog: initialState.printDialogOpen,
        remoteSignatureDialog: initialState.remoteSignatureDialogOpen,
        remoteSignatureSecondDialog: initialState.remoteSignatureSecondDialogOpen,
        signaturePadTypeDialogOpen: initialState.signaturePadTypeDialogOpen,
        signatureCaptureModalOpen: initialState.signatureCaptureModalOpen,
        topazSignatureCaptureModalOpen: initialState.topazSignatureCaptureModalOpen,
        scriptelSignatureCaptureModalOpen: initialState.scriptelSignatureCaptureModalOpen,
        addFormList: initialState.addFormList,
        choiceListTitle: initialState.choiceListTitle,
        choiceListIniField: initialState.choiceListIniField,
        choiceListData: initialState.choiceListData,
        choiceListFields: initialState.choiceListFields,
        choiceListColumns: initialState.choiceListColumns,
        isTransferDataModalOpen: initialState.isTransferDataModalOpen,
        isCurrentYearModalOpen: initialState.isCurrentYearModalOpen,
        pyReturns: initialState.pyReturns,
        cyReturns: initialState.cyReturns,
        stateAcks: initialState.stateAcks,
        attachedStates: initialState.attachedStates,
        addFormFirst: initialState.addFormFirst,
        addFormPkg: initialState.addFormPkg,
        setSelectedForm: initialState.setSelectedForm,
        subDialogList: initialState.subDialogList,
        addFormListFiltered: initialState.addFormListFiltered,
        statePackageSelected: initialState.statePackageSelected,
        formWithSubdialog: initialState.formWithSubdialog,
        attachmentsListUpdated: initialState.attachmentsListUpdated,
        suggestedPasswordModalOpen: initialState.suggestedPasswordModalOpen,
        encryptedPassword: initialState.encryptedPassword,
        encryptedPdfTitle: initialState.encryptedPdfTitle,
        suggestedPasswordSaveModalOpen: initialState.suggestedPasswordSaveModalOpen,
        taxpayerInformation: initialState.taxpayerInformation,
        spouseInformation: initialState.spouseInformation,
        taxpayerEmailForPdf: initialState.taxpayerEmailForPdf,
        spouseEmailForPdf: initialState.spouseEmailForPdf,
        preparerEmailForPdf: initialState.preparerEmailForPdf,
        preparerID: initialState.preparerID,
        amendedPreparerID: initialState.amendedPreparerID,
        encryptedPdfDialogModalOpen: initialState.encryptedPdfDialogModalOpen,
        encryptedPdfSelected: initialState.encryptedPdfSelected,
        emailBlob: initialState.emailBlob,
        attachmentContent: initialState.attachmentContent,
        taxpayerChecked: initialState.taxpayerChecked,
        spouseChecked: initialState.spouseChecked,
        representativeChecked: initialState.representativeChecked,
        taxpayerEPassChecked: initialState.taxpayerEPassChecked,
        spouseEPassChecked: initialState.spouseEPassChecked,
        representativeEPassChecked: initialState.representativeEPassChecked,
        sentSuccessTitle: initialState.sentSuccessTitle,
        sentSuccessMsg: initialState.sentSuccessMsg,
        missingInfoModalOpen: initialState.missingInfoModalOpen,
        taxpayerCellPhoneNumber: initialState.taxpayerCellPhoneNumber,
        taxpayerCellPhoneCarrier: initialState.taxpayerCellPhoneCarrier,
        spouseCellPhoneNumber: initialState.spouseCellPhoneNumber,
        spouseCellPhoneCarrier: initialState.spouseCellPhoneCarrier,
        spouseInfoAvailable: initialState.spouseInfoAvailable,
        taxpayerTextChecked: initialState.taxpayerTextChecked,
        spouseTextChecked: initialState.spouseTextChecked,
        representativeTextChecked: initialState.representativeTextChecked,
        taxpayerCellPhoneDomain: initialState.taxpayerCellPhoneDomain,
        spouseCellPhoneDomain: initialState.spouseCellPhoneDomain,
        taxpayerAllowText: initialState.taxpayerAllowText,
        spouseAllowText: initialState.spouseAllowText,
        pdfModalSuccess: initialState.pdfModalSuccess,
        preferredSignaturePad: initialState.preferredSignaturePad,
        isNeedingVerified: initialState.isNeedingVerified,
        remoteSignSuccessful: initialState.remoteSignSuccessful,
        isSignatureRequestActive: initialState.isSignatureRequestActive,
        inOfficeSignSuccessful: initialState.inOfficeSignSuccessful,
        documentTypes: initialState.documentTypes,
        remoteInvoiceModalOpen: initialState.remoteInvoiceModalOpen,
        remoteInvoiceData: initialState.remoteInvoiceData,
        isSelfPreparing: initialState.isSelfPreparing,
      };

    case SET_ESTIMATOR_BLOB:
      return {
        ...state,
        estimatorState: {
          ...state.estimatorState,
          blob: action.blob,
        },
      };
    case SET_ESTIMATOR_SSN:
      return {
        ...state,
        estimatorState: {
          ...state.estimatorState,
          ssn: action.ssn,
        },
      };
    case SET_ESTIMATOR_DATASTORE:
      return {
        ...state,
        estimatorState: {
          ...state.estimatorState,
          datastore: action.datastore,
        },
      };
    case CLEAR_ESTIMATOR_STATE:
      return {
        ...state,
        estimatorState: undefined,
      };
    case RESET_CHOICE_LIST:
      return {
        ...state,
        choiceListTitle: initialState.choiceListTitle,
        choiceListIniField: initialState.choiceListIniField,
        choiceListData: initialState.choiceListData,
        choiceListFields: initialState.choiceListFields,
        choiceListColumns: initialState.choiceListColumns,
      };
    case SET_CHOICE_LIST:
      return {
        ...state,
        choiceListTitle: action.choices.Window.Title,
        choiceListIniField: action.choices.InitialField.value,
        choiceListColumns: action.choices.Columns,
        choiceListData: action.choices.Data,
        choiceListFields: action.choices.Fields,
      };
    case TOGGLE_CHOICE_LIST:
      return {
        ...state,
        choiceListModalOpen: action.toggle,
      };
    case SET_REMOTE_INVOICE:
      return {
        ...state,
        remoteInvoiceData: action.invoice,
      };
    case TOGGLE_REMOTE_INVOICE:
      return {
        ...state,
        remoteInvoiceModalOpen: action.toggle,
      };
    case SET_ACTIVITY_LIST:
      return {
        ...state,
        activityList: action.activities,
      };
    case TOGGLE_ADD_ASSET:
      return {
        ...state,
        addAssetModalOpen: action.toggle,
      };
    case SET_ADD_FORM_LIST:
      return {
        ...state,
        addFormList: action.forms,
      };
    case TOGGLE_ADD_FORM_LIST:
      return {
        ...state,
        addFormModalOpen: !state.addFormModalOpen,
      };
    case SET_DOCUMENTS_ATTACH:
      return {
        ...state,
        documentsToBeAttached: action.docs,
      };
    case CLOSE_DOCUMENT_ATTACH:
      return {
        ...state,
        addDocumentModalOpen: false,
        documentsToBeAttached: {},
      };
    case OPEN_DOCUMENT_ATTACH:
      return {
        ...state,
        addDocumentModalOpen: true,
      };
    case OPEN_MESSAGE_DIALOG:
      return {
        ...state,
        messageDialogInfo: action.msg,
        messageDialogOpen: true,
      };
    case CLOSE_MESSAGE_DIALOG:
      return {
        ...state,
        messageDialogOpen: false,
      };
    case OPEN_YTY_DIALOG:
      return {
        ...state,
        isTransferDataModalOpen: true,
      };
    case CLOSE_YTY_DIALOG:
      return {
        ...state,
        isTransferDataModalOpen: false,
      };
    case OPEN_CURRENT_YEAR_DIALOG:
      return {
        ...state,
        isCurrentYearModalOpen: true,
      };
    case SET_YTY_RETURNS:
      return {
        ...state,
        pyReturns: action.pyReturns == null ? initialState.pyReturns : action.pyReturns,
        cyReturns: action.cyReturns == null ? initialState.cyReturns : action.cyReturns,
      };
    case CLOSE_CURRENT_YEAR_DIALOG:
      return {
        ...state,
        isCurrentYearModalOpen: false,
      };
    case OPEN_ERROR_REJECTS:
      return {
        ...state,
        errorRejectModalOpen: true,
      };
    case CLOSE_ERROR_REJECTS:
      return {
        ...state,
        errorRejectModalOpen: false,
      };
    case TOGGLE_EVENT_LOG:
      return {
        ...state,
        eventLogModalOpen: action.toggle,
      };
    case TOGGLE_K1_MANAGER:
      return {
        ...state,
        isK1ManagerModalOpen: action.toggle,
      };
    case TOGGLE_PRINT_DIALOG:
      return {
        ...state,
        printDialogOpen: action.toggle,
      };
    case TOGGLE_REMOTE_SIGNATURE_DIALOG:
      return {
        ...state,
        remoteSignatureDialogOpen: action.toggle,
      };
    case TOGGLE_REMOTE_SIGNATURE_SECOND_DIALOG:
      return {
        ...state,
        remoteSignatureSecondDialogOpen: action.toggle,
      };
    case TOGGLE_SIGNATURE_PAD_TYPE_SELECTION:
      return {
        ...state,
        signaturePadTypeDialogOpen: action.toggle,
      };
    case TOGGLE_SIGNATURE_CAPTURE:
      return {
        ...state,
        signatureCaptureModalOpen: action.toggle,
      };
    case TOGGLE_TOPAZ_SIGNATURE_CAPTURE:
      return {
        ...state,
        topazSignatureCaptureModalOpen: action.toggle,
      };
    case TOGGLE_SCRIPTEL_SIGNATURE_CAPTURE:
      return {
        ...state,
        scriptelSignatureCaptureModalOpen: action.toggle,
      };
    case TOGGLE_TEXLINK_SEND_MODAL:
      return {
        ...state,
        textLinkModalOpen: action.toggle,
        textLinkModalContent: action.content,
      };
    case TOGGLE_TEXLINK_SEND_MODAL_ORGINAL:
      return {
        ...state,
        textLinkModalOpen: action.toggle,
        textLinkModalContent: action.content,
        textLinkModalContentOriginal: action.content,
      };
    case TOGGLE_TAXPASS_MESSAGE_MODAL:
      return {
        ...state,
        taxpassMessageModalOpen: action.toggleVal
          ? action.toggleVal
          : !state.taxpassMessageModalOpen, // If no value is provided just toggle.
      };
    case TOGGLE_TEXTLINK_RECEIVED_MODAL:
      return {
        ...state,
        textLinkReceivedModalOpen: action.toggle,
      };
    case TOGGLE_TEXTLINK_NOT_ACTIVATED_MODAL:
      return {
        ...state,
        textLinkNotActivatedDlg: action.toggle,
      };
    case SET_TEXTLINK_ACTIVATION_STATUS:
      return {
        ...state,
        isTextLinkActivated: action.status,
      };
    case SHOW_NO_TEXTLINK_CLIENTS_DLG:
      return {
        ...state,
        textLinkNoClients: action.toggle,
      };
    case TOGGLE_TEXTLINK_CONFIRM_SENT_DLG:
      return {
        ...state,
        textlinkSendSuccess: action.toggle,
      };
    case SET_STATE_ACKS:
      return {
        ...state,
        stateAcks: action.states,
      };
    case SET_ATTACHED_STATES:
      return {
        ...state,
        attachedStates: action.states,
      };
    case OPEN_ERROR_DIALOG:
      return {
        ...state,
        errorDialogOpen: true,
        errorDialogMessage: action.message,
        errorDialogTitle: action.title ? action.title : 'Error',
      };
    case CLOSE_ERROR_DIALOG:
      return {
        ...state,
        errorDialogOpen: false,
      };
    case SET_ADD_FORM_TAB_VALUE:
      return {
        ...state,
        addFormTabValue: action.tabValueSelected,
        setSelectedForm: undefined,
        subDialogList: undefined,
      };
    case SET_ADD_FORM_STATE:
      return {
        ...state,
        addFormFirst: action.addFormOpened,
      };
    case SET_ADD_FORM_PACKAGE:
      return {
        ...state,
        addFormPkg: action.addFormPackage,
      };
    case SET_ADD_FORM_SELECTED_FORM:
      return {
        ...state,
        setSelectedForm: action.forms,
      };
    case SET_ADD_FORM_SUBDIALOG_FORMS:
      return {
        ...state,
        setSubDialogList: action.subDialogList,
        setSubDialogListOpened: true,
      };
    case CLOSE_FORM_SUBDIALOG_FORMS:
      return {
        ...state,
        setSubDialogListOpened: false,
      };
    case SET_ADD_FORM_LIST_FILTERED:
      return {
        ...state,
        addFormListFiltered: action.formsFiltered,
      };
    case SET_ADD_FORM_FILTER:
      return {
        ...state,
        filterOn: action.filterStatus,
      };
    case SET_STATE_PACKAGE_SELECTED:
      return {
        ...state,
        statePackageSelected: action.statePackageSel,
      };
    case SET_ADD_FORM_HAS_SUBDIALOG:
      return {
        ...state,
        formWithSubdialog: action.hasSubDialogFlag,
      };
    case TOGGLE_VERIFY_SUCCESS_DIALOG:
      return {
        ...state,
        showVerifySuccessDlg: action.toggle,
      };
    case SHOW_VERIFY_MODAL_SUCCESS_DLG:
      return {
        ...state,
        showVerifyModalSuccessDlg: action.flag,
      };
    case SET_REJECT_TAB:
      return {
        ...state,
        activeErrorRejectTab: action.reject,
      };
    case SET_UPDATE_ATTACHMENTS_LIST:
      return {
        ...state,
        attachmentsListUpdated: action.attachmentsReturnPrint,
      };
    case TOGGLE_SUBMIT_FOR_REVIEW_DIALOG:
      return {
        ...state,
        submitForReviewDlg: action.flag,
      };
    case TOGGLE_SUGGESTED_PASSWORD_MODAL:
      return {
        ...state,
        suggestedPasswordModalOpen: action.toggle,
      };
    case TOGGLE_SUGGESTED_PASSWORD_SAVE_MODAL:
      return {
        ...state,
        suggestedPasswordSaveModalOpen: action.toggle,
      };
    case TOGGLE_ENCRYPTED_PDF_MODAL:
      return {
        ...state,
        encryptedPdfDialogModalOpen: action.toggle,
      };
    case SET_ENCRYPTED_PDF_REQUESTED:
      return {
        ...state,
        encryptedPdfSelected: action.encryptedPdfRequest,
      };
    case SET_ENCRYPTED_PASSWORD:
      return {
        ...state,
        encryptedPassword: action.psw,
      };
    case SET_ENCRYPTED_PDF_TITLE:
      return {
        ...state,
        encryptedPdfTitle: action.emailTitle,
      };
    case TAXPAYER_INFORMATION_SET:
      return {
        ...state,
        taxpayerInformation: action.taxpayerInformation,
      };
    case SPOUSE_INFORMATION_SET:
      return {
        ...state,
        spouseInformation: action.spouseInformation,
      };
    case SET_TAXPAYER_EMAIL:
      return {
        ...state,
        taxpayerEmailForPdf: action.taxpayerEmailFromForm,
      };
    case SET_SPOUSE_EMAIL:
      return {
        ...state,
        spouseEmailForPdf: action.spouseEmailFromForm,
      };
    case SET_PREPARER_EMAIL:
      return {
        ...state,
        preparerEmailForPdf: action.preparerEmailFromForm,
      };
    case SET_PREPARER_ID:
      return {
        ...state,
        preparerID: action.preparerID,
      };
    case SET_AMENDED_PREPARER_ID:
      return {
        ...state,
        amendedPreparerID: action.amendedPreparerID,
      };
    case SET_EMAIL_BLOB:
      return {
        ...state,
        emailBlob: action.emailBlobFromCalcServer,
      };
    case SET_ENCRYPTED_DOC_CONTENT:
      return {
        ...state,
        attachmentContent: action.pdfBlobFromCalcServer,
      };
    case SET_TAXPAYER_OPTION_CHECKED:
      return {
        ...state,
        taxpayerChecked: action.taxpayerOptionChecked,
      };
    case SET_SPOUSE_OPTION_CHECKED:
      return {
        ...state,
        spouseChecked: action.spouseOptionChecked,
      };
    case SET_REP_OPTION_CHECKED:
      return {
        ...state,
        representativeChecked: action.repOptionChecked,
      };
    case SET_TAXPAYER_E_PASS_OPTION_CHECKED:
      return {
        ...state,
        taxpayerEPassChecked: action.taxpayerEPassOptionChecked,
      };
    case SET_SPOUSE_E_PASS_OPTION_CHECKED:
      return {
        ...state,
        spouseEPassChecked: action.spouseEPassOptionChecked,
      };
    case SET_REP_E_PASS_OPTION_CHECKED:
      return {
        ...state,
        representativeEPassChecked: action.repEPassOptionChecked,
      };
    case SET_TAXPAYER_TEXT_CHECKED:
      return {
        ...state,
        taxpayerTextChecked: action.tpTextChecked,
      };
    case SET_SPOUSE_TEXT_CHECKED:
      return {
        ...state,
        spouseTextChecked: action.spTextChecked,
      };
    case SET_REP_TEXT_CHECKED:
      return {
        ...state,
        representativeTextChecked: action.repTextChecked,
      };
    case SET_SENT_SUCCESS_TITLE:
      return {
        ...state,
        sentSuccessTitle: action.successMsgTitle,
      };
    case SET_SENT_SUCCESS_MESSAGE:
      return {
        ...state,
        sentSuccessMsg: action.successMsg,
      };
    case TOGGLE_MISSING_INFO_MODAL:
      return {
        ...state,
        missingInfoModalOpen: action.toggle,
      };
    case SET_TAXPAYER_NAME:
      return {
        ...state,
        taxpayerName: action.taxpayerName,
      };
    case SET_TAXPAYER_ALLOW_TEXT:
      return {
        ...state,
        taxpayerAllowText: action.tpAllowText,
      };
    case SET_TAXPAYER_CELL_PHONE_NUMBER:
      return {
        ...state,
        taxpayerCellPhoneNumber: action.tpCellPhoneNumber,
      };
    case SET_TAXPAYER_CELL_PHONE_CARRIER:
      return {
        ...state,
        taxpayerCellPhoneCarrier: action.tpCellPhoneCarrier,
      };
    case SET_TAXPAYER_CELL_PHONE_DOMAIN:
      return {
        ...state,
        taxpayerCellPhoneDomain: action.tpCellPhoneDomain,
      };
    case SET_SPOUSE_CELL_PHONE_NUMBER:
      return {
        ...state,
        spouseCellPhoneNumber: action.spCellPhoneNumber,
      };
    case SET_SPOUSE_CELL_PHONE_CARRIER:
      return {
        ...state,
        spouseCellPhoneCarrier: action.spCellPhoneCarrier,
      };
    case SET_SPOUSE_INFO_AVAILABLE:
      return {
        ...state,
        spouseInfoAvailable: action.spInfoAvailable,
      };
    case SET_SPOUSE_CELL_PHONE_DOMAIN:
      return {
        ...state,
        spouseCellPhoneDomain: action.spCellPhoneDomain,
      };
    case SET_SPOUSE_NAME:
      return {
        ...state,
        spouseName: action.spouseName,
      };
    case SET_SPOUSE_ALLOW_TEXT:
      return {
        ...state,
        spouseAllowText: action.spAllowText,
      };
    case SET_PDF_DIALOG_SUCCESS_MODAL:
      return {
        ...state,
        pdfModalSuccess: action.pdfDialogSuccess,
      };
    case SET_PREFERRED_SIGNATURE_PAD:
      return {
        ...state,
        preferredSignaturePad: action.signaturePadSelected,
      };
    case TEXT_LINK_CLIENT_INFO_FETCHED:
      return {
        ...state,
        textLinkClientInfo: action.clientInfo,
      };
    case NEEDING_VERIFIED_SET:
      return {
        ...state,
        isNeedingVerified: action.flag,
      };
    case REMOTE_SIGN_SUCCESSFUL_SET:
      return {
        ...state,
        remoteSignSuccessful: action.toggleMessage,
      };
    case IN_OFFICE_SIGN_SUCCESSFUL_SET:
      return {
        ...state,
        inOfficeSignSuccessful: action.toggleMessage,
      };
    case SIGNATURE_REQUEST_ACTIVE_SET:
      return {
        ...state,
        isSignatureRequestActive: action.toggleActive,
      };
    case SET_RMS_FLAG:
      return {
        ...state,
        isRMSSigningMethod: action.isRMSSigningMethod,
      };
    case SET_DOCUMENT_TYPES:
      return {
        ...state,
        documentTypes: action.documentTypes,
      };
    case TOGGLE_PREP_RMS_COPY:
      return {
        ...state,
        sendRMSCopyToPrep: action.toggle,
      };
    case SET_IS_SELF_PREPARING:
      return {
        ...state,
        isSelfPreparing: action.isSelfPreparing,
      };
  }

  return state;
}

import ErrorHelpers from '~/app/errorHelpers.js';
import { DYNAMIC_FORMS_LIST } from '~/app/Pages/WizardMode/wizardModeConstants.js';

/**
 * Handles adding form to Calc Server so we can save
 *
 * @param {string} formName name of the file to fetch from calc server -> cle101
 * @param {Object} calcAPI Object with methods such as ReturnFormLoad to make a req to calcserver
 */
export const handleAddForm = async (formName, calcAPI, loadingDynamicForms) => {
  try {
    await calcAPI.ReturnAddForm(formName + '01', '00');
    await calcAPI.ReturnFormLoad(formName + '01', '', '', '');
    // special case for dynamic form
    if (DYNAMIC_FORMS_LIST.includes(formName)) {
      loadingDynamicForms.current = true;
      await calcAPI.ReturnFormLoad(formName + '01A', 'stm', '', '');
    }
  } catch (error) {
    ErrorHelpers.handleError('Error Adding Forms', error);
  }
};

/**
 * Handles building the form state using the response from calc
 *
 * @param {Object} data Calc Response formData
 * @returns newForm is saved to redux to keep track of values and field ID's
 */
export const buildWizardFormFields = jsonData => {
  const newForm = {};
  if (jsonData.CellItem?.length) {
    for (let i = 0; i < jsonData.CellItem.length; i++) {
      // calc server is coming back with values with spaces, unless the backend mapper isn't trimming
      newForm[jsonData.CellItem[i].name] = jsonData.CellItem?.[i]?.value?.trim();
    }
  }
  return newForm;
};

/**
 * Handles getting dynamicField Data
 *
 * @param {Object} jsonData Calc Response retrieved from worksheet formLoad
 * @returns an object containing dynamic fields and values.
 */
export const buildDynamicFields = jsonData => {
  const formName = jsonData.form;
  const formData = jsonData.fields;
  const fieldList = {};
  const additionalRowCounts = {};
  const rowAccounted = [];
  Object.keys(formData).forEach(function (entry) {
    const field = formData[entry];
    const fieldName = field.field;
    // get the fields with values
    if (field.value) {
      fieldList[formName + fieldName] = field.value.trim();

      // if a field is accounted, do not count the fields "twin" in the additionalRowCounts
      // regardless if it has data
      if (!rowAccounted.includes(getTwinField(fieldName))) {
        rowAccounted.push(fieldName);
        const pairText = convertToPair(fieldName);
        if (!(pairText in additionalRowCounts)) {
          additionalRowCounts[pairText] = 0;
        } else {
          additionalRowCounts[pairText] += 1;
        }
      } else {
        rowAccounted.push(fieldName);
      }
    }
  });
  return { fieldList, additionalRowCounts };
};

// gets corresponding twin field i.e: AA05's twin is AB05
// TODO- eventually need to phase out A and B as twins.
const getTwinField = field => {
  if (field.substr(1, 1) === 'A') {
    return field.substring(0, 1) + 'B' + field.substring(2, 4);
  } else {
    return field.substring(0, 1) + 'A' + field.substring(2, 4);
  }
};

// Pair text always follows "_A,_B" where _ is varying.
// Function grabs the pair text
const convertToPair = fieldID => {
  const firstCh = fieldID.substr(0, 1);
  return firstCh + 'A,' + firstCh + 'B';
};

/**
 * Handles parsing the response from cs and builds a dropdown options Array of strings
 *
 * @param {Object} data Array of Objects of dropdown options
 */
export const buildDropdownOptions = data => {
  const options = data.map(option => option.ListItem?.[0]?.COL);

  return options;
};

/**
 * Handles parsing the response from cs and builds a dropdown options Array of strings
 *
 * @param {Object} data Array of Objects of dropdown options
 */
export const buildDropdownOptionsMap = (options, data) => {
  const optionMap = {};

  // create a string representation of Each Row
  const rowString = data.map(option => {
    let tempString = '';
    option?.ListItem.forEach(item => {
      tempString += item?.COL + '\t';
    });
    // last row, remove last two characters "\t"
    tempString = tempString.slice(0, -1);
    return tempString;
  });

  // options array and rowString array will be 1 to 1 so
  // we can safely loop through and build a map where option choice is the key and row is the value.
  for (let i = 0; i < options.length; i++) {
    optionMap[options[i]] = rowString[i];
  }
  return optionMap;
};

/**
 * Handles determining the breakpoints for material ui. If returns 'false' it disables the breakpoint
 *
 * @param {number} column used for determining how many columns the current field should take
 * @param {boolean} isTrue used for components that are not passing a column number, and rather checking for existence
 * @param {string} compName name of component currently being accessed.
 * @returns {number||boolean} returns number or boolean based on which component is being accessed
 */
export const getBreakpointGrid = (column = null, isTrue = false, compName) => {
  if (compName === 'Footer') {
    return isTrue ? 6 : false;
  } else {
    return column === 12 ? 12 : 'auto';
  }
};

/**
 * handles building required fields object
 *
 * @param {Array} fields using initial form_load object to iterate over
 */
export const buildRequiredFields = fields => {
  const requiredFields = [];
  fields.forEach(field => {
    if (field.flag) {
      if (field.flag?.split().includes('*')) {
        requiredFields.push(field.name);
      }
    }
  });

  return requiredFields;
};

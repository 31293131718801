import React from 'react';
import NumberFormat from 'react-number-format';
// internal imports
import { STATE_LIST } from '~/app/constants.js';
// external imports
import {
  AppBar,
  Toolbar,
  FormControl,
  InputLabel,
  Input,
  Divider,
  Button,
  NativeSelect,
} from '@material-ui/core';
// redux imports
import { useSelector, useDispatch } from 'react-redux';
import { actions as setupPageActions } from '~/app/redux/setupPages/duck';
// styling imports
import { withStyles } from '@material-ui/core/styles';
import { style } from './css/upsertCareProviderStyle';
import './css/upsertCareProvider.css';

const UpsertCareProvider = props => {
  const { classes } = props;
  const dispatch = useDispatch();

  const { isEditingRecord, currentEditRecord } = useSelector(state => ({
    isEditingRecord: state.setupPageState.isEditingRecord,
    currentEditRecord: state.setupPageState.currentEditRecord,
  }));

  const handleInputChange = e => {
    e.persist();
    dispatch(setupPageActions.updateCurrentEditRecord(e));
  };

  const handleUpsert = () => {
    currentEditRecord.provider_phone = currentEditRecord.provider_phone.replace(/\D/g, '');
    props.handleUserDBUpsert(currentEditRecord);
  };

  const validateFields = () => {
    if (
      currentEditRecord.address1 == null ||
      currentEditRecord.address1 === '' ||
      currentEditRecord.postal_code == null ||
      currentEditRecord.postal_code === '' ||
      currentEditRecord.provider_code == null ||
      currentEditRecord.provider_code === '' ||
      currentEditRecord.provider_name == null ||
      currentEditRecord.provider_name === '' ||
      currentEditRecord.city == null ||
      currentEditRecord.city === '' ||
      currentEditRecord.state_code == null ||
      currentEditRecord.state_code === ''
    ) {
      return false;
    }
    return true;
  };

  const handleStateValueChange = stateID => {
    dispatch(setupPageActions.handleUpdateSelectedState(stateID));
  };

  const stateList = [''].concat(STATE_LIST).map(state => {
    return (
      <option id={'UpsertCareProvideroptSelect' + state} key={state} value={state}>
        {state}
      </option>
    );
  });

  return (
    <div>
      <AppBar position="static">
        <Toolbar classes={{ root: classes.toolbar }}>
          {isEditingRecord ? 'Edit ' : 'Add New '}
          Care Provider
        </Toolbar>
      </AppBar>
      <form className="form-margin">
        <div className="care-provider-form-control-wrapper-2em">
          <div className="section-heading">Care Provider Information</div>
          <div className="care-provider-form-control-wrapper-1em">
            <FormControl>
              <InputLabel classes={{ root: classes.labelStyles }} required={false} shrink>
                Care Provider ID
              </InputLabel>
              <Input
                id="txtEINIDUpsertCareProviderProvCode"
                name="provider_code"
                value={currentEditRecord.provider_code}
                onChange={handleInputChange}
                classes={{ root: classes.inputStyle }}
                error={!currentEditRecord.provider_code || currentEditRecord.provider_code === ''}
                inputProps={{
                  maxLength: '9',
                }}
                disableUnderline
              />
            </FormControl>
          </div>
          <div className="div-style-float-none">
            <FormControl>
              <InputLabel classes={{ root: classes.labelStyles }} required={false} shrink>
                Provider ID Type
              </InputLabel>
              <NativeSelect
                id="txtNameControlUpsertCareProvider"
                name="provider_type"
                value={
                  !currentEditRecord.provider_type ? 'S' : currentEditRecord.provider_type.trim()
                }
                classes={{ root: classes.inputStyleNativeSelectProvider }}
                onChange={handleInputChange}
                disableUnderline
              >
                <option value={'S'}>SSN</option>
                <option value={'E'}>EIN</option>
              </NativeSelect>
            </FormControl>
          </div>
          <br />
          <div className="care-provider-form-control-wrapper-1em">
            <FormControl>
              <InputLabel classes={{ root: classes.labelStyles }} required={false} shrink>
                Name Control
              </InputLabel>
              <Input
                id="txtNameControlUpsertCareProviderNamecrtl"
                name="provider_name_ctrl"
                value={currentEditRecord.provider_name_ctrl}
                onChange={handleInputChange}
                classes={{ root: classes.inputStyle }}
                inputProps={{
                  maxLength: '4',
                }}
                disableUnderline
              />
            </FormControl>
          </div>
          <div className="div-style-float-none">
            <FormControl>
              <InputLabel classes={{ root: classes.labelStyles }} required={false} shrink>
                Care Provider Name
              </InputLabel>
              <Input
                id="txtNameControlUpsertCareProviderName"
                name="provider_name"
                value={currentEditRecord.provider_name}
                onChange={handleInputChange}
                classes={{ root: classes.inputStyle }}
                error={!currentEditRecord.provider_name || currentEditRecord.provider_name === ''}
                inputProps={{
                  maxLength: '75',
                }}
                disableUnderline
              />
            </FormControl>
          </div>
          <br />
          <div className="care-provider-form-control-wrapper-1em">
            <FormControl>
              <InputLabel classes={{ root: classes.labelStyles }} required={false} shrink>
                Care Provider Phone Number
              </InputLabel>
              <NumberFormat
                id="txtNameControlUpsertCareProviderPhone"
                name="provider_phone"
                value={currentEditRecord.provider_phone}
                onChange={handleInputChange}
                classes={{ root: classes.inputStyle }}
                error={!currentEditRecord.provider_phone || currentEditRecord.provider_phone === ''}
                format="(###) ###-####"
                customInput={Input}
                disableUnderline
              />
            </FormControl>
          </div>
          <br />
        </div>

        <Divider className="divider-style-margin-top-1em" />

        <div className="care-provider-form-control-wrapper-2em">
          <div className="section-heading">Address</div>
          <div className="care-provider-form-control-wrapper-1em">
            <FormControl>
              <InputLabel classes={{ root: classes.labelStyles }} required={false} shrink>
                Address
              </InputLabel>
              <Input
                id="txtEINIDUpsertCareProviderAddress"
                name="address1"
                value={currentEditRecord.address1}
                onChange={handleInputChange}
                classes={{ root: classes.inputStyle }}
                error={!currentEditRecord.address1 || currentEditRecord.address1 === ''}
                inputProps={{
                  maxLength: '75',
                }}
                disableUnderline
              />
            </FormControl>
          </div>
          <div className="care-provider-form-control-wrapper-1em">
            <FormControl>
              <InputLabel classes={{ root: classes.labelStyles }} required={false} shrink>
                City
              </InputLabel>
              <Input
                id="txtNameControlUpsertCareProviderCity"
                name="city"
                value={currentEditRecord.city}
                onChange={handleInputChange}
                classes={{ root: classes.inputStyle }}
                error={!currentEditRecord.city || currentEditRecord.city === ''}
                inputProps={{
                  maxLength: '75',
                }}
                disableUnderline
              />
            </FormControl>
          </div>

          <div className="care-provider-form-control-wrapper-1em">
            <FormControl>
              <InputLabel classes={{ root: classes.labelStyles }} shrink>
                State
              </InputLabel>
              <NativeSelect
                id="selectDropDownUpsertCareProviderState"
                value={currentEditRecord.state_code || ''}
                onChange={e => handleStateValueChange(e.target.value)}
                classes={{ select: classes.inputStyleNativeSelectState }}
                error={!currentEditRecord.state_code || currentEditRecord.state_code === ''}
                disableUnderline
              >
                {stateList}
              </NativeSelect>
            </FormControl>
          </div>
          <br />
          <div className="div-style-float-none">
            <FormControl>
              <InputLabel classes={{ root: classes.labelStyles }} required={false} shrink>
                Zip Code
              </InputLabel>
              <Input
                id="txtNameControlUpsertCareProviderZipCode"
                name="postal_code"
                value={currentEditRecord.postal_code}
                onChange={handleInputChange}
                classes={{ root: classes.inputStyle }}
                error={!currentEditRecord.postal_code || currentEditRecord.postal_code === ''}
                inputProps={{
                  maxLength: '10',
                }}
                disableUnderline
              />
            </FormControl>
          </div>
        </div>
        <div className="care-provider-button-wrapper">
          <Button
            id="btnCancelAddUUpsertCareProvider"
            color="primary"
            onClick={() => props.handleCloseUpsertModal()}
            classes={{ root: classes.button }}
          >
            Cancel
          </Button>
          <Button
            id="btnUpsertCareProvider"
            color="primary"
            classes={{ root: classes.button }}
            onClick={handleUpsert}
            disabled={!validateFields()}
          >
            {isEditingRecord ? 'Save' : 'Add'}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default withStyles(style)(UpsertCareProvider);

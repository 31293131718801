const loadTopaz = callback => {
  const existingScript = document.getElementById("topazScr");

  if (!existingScript) {
    const scriptTopaz = document.createElement("script");
    scriptTopaz.src = "https://www.sigplusweb.com/SigWebTablet.js";
    scriptTopaz.id = "topazScr";
    document.body.appendChild(scriptTopaz);

    scriptTopaz.onload = () => {
      if (callback) callback();
    };
  }

  if (existingScript && callback) callback();
};

export default loadTopaz;

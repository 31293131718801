/**
 * Adjust the form list nav as needed if there are extra states refund
 * or Federal AGI refund.
 */
export const updateFormNavHeight = () => {
  if (
    document.getElementById('sidebar-return-refunds-list') &&
    document.getElementById('sidebar-form-list')
  ) {
    const refundAmtEle = document.getElementById('sidebar-return-refunds-list').offsetHeight;
    let adjustedHeightClass;
    const sidebarEle = document.getElementById('sidebar-form-list');

    sidebarEle.className.replace(/adjusted-height-*/g, '');

    if (refundAmtEle === 160) {
      adjustedHeightClass = 'adjusted-height-14rem';
    } else if (refundAmtEle === 176) {
      adjustedHeightClass = 'adjusted-height-15rem';
    } else if (refundAmtEle === 200) {
      adjustedHeightClass = 'adjusted-height-16rem';
    } else if (refundAmtEle === 204) {
      adjustedHeightClass = 'adjusted-height-17rem';
    } else if (refundAmtEle === 228) {
      adjustedHeightClass = 'adjusted-height-18rem';
    }

    const eleClassNameArr = sidebarEle.className.split(' ');
    if (eleClassNameArr.indexOf(adjustedHeightClass) < 0) {
      sidebarEle.className += ' ' + adjustedHeightClass;
    }
  }
};

export const handleMenuItemHighlight = (activeForm, formName) => {
  // client data and signatures have two separates formNames that navigate to the same form section.
  // In the sidebar it is 'prin17' and '000000'
  const signatureForms = ['prin12', 'prin17', 'prin18'];
  const clientDataForms = ['000000', '000A00'];

  if (clientDataForms.includes(activeForm) && clientDataForms.includes(formName)) return true;
  if (signatureForms.includes(activeForm) && signatureForms.includes(formName)) return true;
  else if (activeForm && activeForm === formName) return true;
  else return false;
};

export const formItemStyle = {
  color: '#1880E7',
  fontFamily: 'Roboto',
  fontSize: '15px',
  lineHeight: '18px',
  paddingLeft: '2rem',
};

export const formItemStyleDropdown = {
  color: '#1880E7',
  fontFamily: 'Roboto',
  fontSize: '15px',
  lineHeight: '18px',
};

export const formItemStyleDisabled = {
  ...formItemStyle,
  color: '#A9A9A9',
};

export const formItemStyleAttached = {
  color: '#1880E7',
  fontFamily: 'Roboto',
  fontSize: '15px',
  lineHeight: '18px',
  paddingLeft: '1rem',
};

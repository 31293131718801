import React from 'react';
// External imports
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
// Styling imports
import { styles } from '~/app/Pages/WizardMode/Mapper/css/mapperStyles.js';
import '~/app/Pages/WizardMode/Mapper/css/mapperStyles.css';

/**
 * Handles "XX" and "X" from "lineno" property - They are used only in results page as disabled input fields
 * lineno X is used for the not embolded results text.
 * lineno XX is used for embolded results text
 *
 * @returns {JSX} ReadOnlyMap UI
 */
const ReadOnlyMap = props => {
  const { classes } = props;

  return props.lineno === 'X' ? (
    <Grid container item xs={12}>
      <Grid item>
        <div className="div-label-item-left-indent">{props.prompt}</div>
      </Grid>
      <Grid item xs>
        <div className="div-leader-line">‎</div>
      </Grid>
      <Grid item>
        <div className="div-label-item-right">{props.form[props.name] || 0}</div>
      </Grid>
    </Grid>
  ) : props.lineno === 'XX' ? (
    <Grid container className={classes.hightlightedReadOnly} item xs={12}>
      <Grid container item xs justify="flex-start">
        <div className="div-label-item-left-bold">{props.prompt}</div>
      </Grid>
      <Grid container item xs justify="flex-end">
        <div className="div-label-item-right-bold">{props.form[props.name] || 0}</div>
      </Grid>
    </Grid>
  ) : null;
};

export default withStyles(styles)(ReadOnlyMap);

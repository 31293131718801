export const styles = {
  typography: {
    color: '#FFFFFF',
    fontFamily: 'Roboto',
    display: 'inline-block',
    fontSize: '18px',
    paddingTop: '2.5%',
    width: '100%',
    cursor: 'default',
  },
  btn: {
    border: 'none',
    display: 'inline-block',
    color: '#FFFFFF',
    minWidth: '0',
    minHeight: '0',
    float: 'right',
    paddingBottom: '0.4rem',
  },
  styledFlexGridDivider: {
    maxWidth: '93%',
    flexBasis: '100%',
  },
  documentDivider: {
    width: '100%',
    marginLeft: '1rem',
    marginTop: '1rem',
  },
};

import React, { useEffect } from 'react';

import {
  Button,
  FormControl,
  Grid,
  Input,
  InputLabel,
  Paper,
  Snackbar,
  Typography,
  withStyles,
} from '@material-ui/core';

import { styles } from '~/app/Pages/Setup/PaymentSetup/css/paymentSetupStyles.js';
import './css/paymentSetup.css';

import XlinkAPI from '~/app/api/xlinkAPI';
import ErrorHelpers from '~/app/errorHelpers.js';

import * as Global from '~/app/constants.js';

import AccessControl from '~/app/Components/Auth/AccessControl.jsx';

const PaymentSetup = props => {
  const [apiLogin, setAPILogin] = React.useState('');
  const [apiPassword, setAPIPassword] = React.useState('');
  const [snackbar, setSnackbar] = React.useState(false);

  useEffect(() => {
    getCredentials();
  }, []);

  const getCredentials = async () => {
    try {
      const response = await XlinkAPI.getPayJunctionCredentials();
      if (response) {
        setAPILogin(response.data.apiLogin);
      }
    } catch (err) {
      ErrorHelpers.handleError(
        'Unable to fetch API Credentials',
        ErrorHelpers.createSimpleError(
          'If this problem persists, please contact technical support',
        ),
      );
    }
  };

  const saveCredentials = async () => {
    const credString = apiLogin + ':' + apiPassword;

    try {
      const response = await XlinkAPI.updatePayJunctionCredentials(btoa(credString));
      if (response) {
        setSnackbar(true);
      }
    } catch (err) {
      ErrorHelpers.handleError(
        'Unable to save API Credentials',
        ErrorHelpers.createSimpleError(
          'We were unable to authenticate the given credentials. Please ensure credentials are correct.',
        ),
      );
    }
  };

  const goToPrevious = () => {
    props.loadSetupComponent(Global.EXTENDED_SETUP_PAGES.CLIENT_LETTERS.NAME);
  };

  const goToNext = () => {
    props.loadSetupComponent(Global.EXTENDED_SETUP_PAGES.MOBILE.NAME);
  };

  const handleSubmit = e => {
    e.preventDefault();
    saveCredentials();
  };

  const { classes } = props;
  return (
    <div className="setup-page-container">
      <Paper classes={{ root: classes.setupPageContainer }}>
        <section className="setup-section-container">
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={snackbar}
            autoHideDuration={2500}
            onClose={() => setSnackbar(false)}
            id="snkbarconfsaved"
            message={<span id="success-message">API Credentials Saved</span>}
          />
          <h2 className="setup-section-heading">Payment Settings</h2>
          <div className="setup-section-content-container">
            <label className="payment-setup-section-label">PayJunction Integration</label>
            <form onSubmit={e => handleSubmit(e)}>
              <Grid
                container
                spacing={8}
                direction="row"
                alignItems="center"
                justify="center"
                classes={{ container: classes.paymentSetupFormContainer }}
              >
                <AccessControl
                  requiredAction="write"
                  accessLevel="edit_payjunction_api_credentials"
                  disableOnFalse={true}
                >
                  <Grid item xs={12}>
                    <Typography variant="body2" gutterBottom>
                      Accept payments anywhere and manage all activity in the cloud.{' '}
                      {
                        <a href="https://www.payjunction.com/payments/" target="_blank">
                          Learn More
                        </a>
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <FormControl fullWidth required>
                      <InputLabel shrink>PayJunction API Login</InputLabel>
                      <Input
                        id="txtPayJunctionAPILogin"
                        disableUnderline
                        value={apiLogin}
                        onChange={e => setAPILogin(e.target.value)}
                        autoComplete="off"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={5}>
                    <FormControl fullWidth required>
                      <InputLabel shrink>PayJunction API Password</InputLabel>
                      <Input
                        id="txtPayJunctionAPIPassword"
                        type="password"
                        disableUnderline
                        placeholder={'Update Password'}
                        value={apiPassword}
                        onChange={e => setAPIPassword(e.target.value)}
                        autoComplete="new-password"
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    classes={{ item: classes.paymentSetupSaveCredentialsButtonGrid }}
                  >
                    <Button id="btnPayJunctionAPISave" color="primary" type="submit">
                      Save
                    </Button>
                  </Grid>
                </AccessControl>
              </Grid>
            </form>
          </div>
        </section>
        <div className="setup-nav-btns">
          <Button
            id="btnPreviousAudit"
            color="primary"
            onClick={() => {
              goToPrevious();
            }}
          >
            Previous
          </Button>
          <Button
            classes={{ root: classes.auditConfigFormButton }}
            color="primary"
            id="btnNextText"
            onClick={() => goToNext()}
          >
            Next
          </Button>
        </div>
      </Paper>
    </div>
  );
};

export default withStyles(styles)(PaymentSetup);

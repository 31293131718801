export const styles = theme => ({
  titleRoot: {
    marginBottom: '1rem',
    marginLeft: '3rem',
    paddingTop: '2rem',
    fontFamily: 'Roboto',
    fontSize: '21px',
    letterSpacing: '0.14px',
    color: '#1A173B',
  },
  manageAncillaryModal: {
    maxHeight: '800px',
    minHeight: '300px',
    height: 'auto',
    width: '908px',
    padding: '0',
    position: 'absolute',
    margin: 'auto',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  tableCellRoot: {
    padding: '0px 20px 0px 20px',
    borderBottom: '0px',
    fontFamily: 'Roboto',
    fontSize: '15px',
    color: '#67727C',
  },
  tableHeadCellRoot: {
    padding: '0px 20px 0px 20px',
    fontFamily: 'Roboto',
    fontSize: '12px',
    color: '#1A173B',
  },
  divider: {
    marginLeft: '3rem',
    marginRight: '3rem',
    marginTop: '1rem',
  },
});

//Office Settings Duck Module

const SET_OFFICE_ADDRESS = "xlinkonline/officeSettings/SETOFFICEADDRESS";
const SET_MY_LOGINS = "xlinkonline/officeSettings/SETMYLOGINS";

export const initialState = {
  officeAddress: {
    city: "",
    fax: "",
    firmAddress: "",
    firmName: "",
    phone: "",
    state: "",
    suiteNum: "",
    zip: ""
  },
  mylogins: []
};

export const actions = {
  setCurrentOfficeAddress: address => ({
    type: SET_OFFICE_ADDRESS,
    address
  }),
  setMyLogins: logins => ({
    type: SET_MY_LOGINS,
    logins
  })
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_OFFICE_ADDRESS:
      return {
        ...state,
        officeAddress: action.address
      };
    case SET_MY_LOGINS:
      return {
        ...state,
        mylogins: action.logins
      };
  }
  return state;
}

/* eslint-disable camelcase */
import { FORM_NAMES } from '~/app/constants';
export const SET_ACTIVE = 'xlinkonline/returnProfile/SETACTIVE';
const CLEAR_ACTIVE = 'xlinkonline/returnProfile/CLEARACTIVE';
export const OPEN_ACTIVE = 'xlinkonline/returnProfile/OPENACTIVE';
const CLOSE_ACTIVE = 'xlinkonline/returnProfile/CLOSEACTIVE';
const SET_FORMS = 'xlinkonline/returnProfile/SETFORMS';
const SET_RETURN_ERRORS = 'xlinkonline/returnProfile/SETRETURNERRORS';
const SET_VERIFIES = 'xlinkonline/returnProfile/SETVERIFIES';
const SET_REJECTS = 'xlinkonline/returnProfile/SETREJECTS';
const SET_IMP_ERRS = 'xlinkonline/returnProfile/SETIMPERRS';
const SET_BANK_REJECTS = 'xlinkonline/returnProfile/SETBANKREJECTS';
const SET_ALERTS = 'xlinkonline/returnProfile/SETALERTS';
const SET_INITIAL = 'xlinkonline/returnProfile/SETINITIAL';
export const SET_CLIENT_DATA = 'xlinkonline/returnProfile/SETCLIENTDATA';
const REMOVE_DOCUMENT = 'xlinkonline/returnProfile/REMOVEDOCUMENT';
const ADD_DOCUMENT = 'xlinkonline/returnProfile/ADDDOCUMENT';
const DETACH_DOCUMENT = 'xlinkonline/returnProfile/DETACHDOCUMENT';
export const SET_READ_ONLY_RETURN = 'xlinkonline/returnProfile/SETREADONLY';
export const LOCK_RETURN = 'xlinkonline/returnProfile/LOCKRETURN';
export const SET_RETURN_TYPE = 'xlinkonline/returnProfile/SET_RETURN_TYPE';
const LOCK_RETURN_RE_PERMISSION = 'xlinkonline/returnProfile/LOCKRETURN2';
const TOGGLE_STATUS_LOCK_RETURN = 'xlinkonline/returnProfile/TOGGLE_STATUS_LOCK_RETURN';
const OPEN_PROFILE = 'xlinkonline/returnProfile/OPENPROFILE';
const CLOSE_PROFILE = 'xlinkonline/returnProfile/CLOSEPROFILE';
const SET_RETURN_ID = 'xlinkonline/returnProfile/SETRETURNID';

export const REQUESTED_RETURN_PROFILE = 'xlinkonline/returnProfile/REQUESTEDRETURNPROFILE';
export const REQUESTED_RETURN_PROFILE_FAILED =
  'xlinkonline/returnProfile/REQUESTEDRETURNPROFILEFAILED';
export const REQUESTED_RETURN_PROFILE_SUCCEEDED =
  'xlinkonline/returnProfile/REQUESTEDRETURNPROFILESUCCEEDED';
export const FETCHED_RETURN_PROFILE = 'xlinkonline/returnProfile/FETCHEDRETURNPROFILE';

export const FETCH_RETURN_PROFILE_BY_RETURN_ID =
  'xlinkonline/returnProfile/REQUESTEDRETURNPROFILEBYRETURNID';

export const SWITCH_YEAR_AND_FETCH_RETURN_PROFILE =
  'xlinkonline/returnProfile/SWITCHYEARANDFETCHRETURNPROFILE';

export const SET_DEMO_VERSION = 'xlinkonline/returnList/SETDEMOVERSION';
export const SET_TAXPASS_RETURN_FLAG = 'xlinkonline/returnProfile/SET_TAXPASS_RETURN_FLAG';

export const SET_WIZARD_COMPLETE = 'xlinkonline/returnProfile/SET_WIZARD_COMPLETE';
// 1040 OR BUSINESS
export const SET_BUSINESS_TYPE = 'xlinkonline/returnProfile/SET_BUSINESS_TYPE';

export const initialState = {
  usingDemoAccount: false,
  open: false, // FormViewer flag
  profileOpen: false, // Return Profile flag
  clientName: '', // 1040 first and last name || business name
  clientFirstName: '',
  clientLastName: '',
  clientFilingStatus: undefined,
  primarySSNEIN: '',
  year: 2017,
  returnID: '',
  friendlyID: '',
  id: '',
  created: '',
  filingStatus: 'Single',
  refundBalanceDue: 0,
  agi: 0,
  notes: [],
  attachments: [],
  formList: [],
  verifyList: [],
  rejectList: [],
  importErrorList: [],
  bankRejectList: [],
  alertList: [],
  errorNumber: 0,
  warningNumber: 0,
  initialForm: FORM_NAMES.CDS,
  initialFile: '',
  isReadOnly: false,
  lockedBy: '',
  lockedByLoginID: 0,
  lockedViaAccessLevel: '',
  isNew: undefined,
  isWizard: undefined,
  wizardCompleted: undefined,
  error: false,
  isLoading: false,
  isBusiness: false,
  lockedStatus: false,
  isTaxPassReturn: false,
  returnStatus: '', // return status is used for determining whether logic should be logic locked, such as 'Return Queued'
  fedStatus: 'In Progress',
  fedFileDate: '—',
};

export const actions = {
  setAccountType: usingAccountType => ({
    type: SET_DEMO_VERSION,
    usingAccountType,
  }),
  setActiveReturn: (client, forms) => ({
    type: SET_ACTIVE,
    client,
    forms,
  }),
  setReadOnlyMode: readOnly => ({
    type: SET_READ_ONLY_RETURN,
    readOnly,
  }),
  // if coming from cs, login id is not included
  lockReturn: (who, lockedByLoginID = 0) => ({
    type: LOCK_RETURN,
    who: who,
    lockedByLoginID: lockedByLoginID || 0,
  }),
  lockReturnByPermissions: () => ({
    type: LOCK_RETURN_RE_PERMISSION,
  }),
  setClientData: client => ({
    type: SET_CLIENT_DATA,
    client,
  }),
  clearActiveReturn: () => ({
    type: CLEAR_ACTIVE,
  }),
  openActiveReturn: () => ({
    type: OPEN_ACTIVE,
  }),
  closeActiveReturn: () => ({
    type: CLOSE_ACTIVE,
  }),
  setActiveReturnFormList: forms => ({
    type: SET_FORMS,
    forms,
  }),
  setIsTaxPassReturnFlag: val => ({
    type: SET_TAXPASS_RETURN_FLAG,
    val,
  }),
  setAllVerifiesAndErrors: errors => ({
    type: SET_RETURN_ERRORS,
    errors,
  }),
  setVerifyList: verifies => ({
    type: SET_VERIFIES,
    verifies,
  }),
  setRejectList: rejects => ({
    type: SET_REJECTS,
    rejects,
  }),
  setImportErrorList: impErrs => ({
    type: SET_IMP_ERRS,
    impErrs,
  }),
  setBankRejectList: bankRejects => ({
    type: SET_BANK_REJECTS,
    bankRejects,
  }),
  setAlertList: alerts => ({
    type: SET_ALERTS,
    alerts,
  }),
  // Sets initially loaded form when first loading up FormViewer
  setInitialForm: (form, file) => ({
    type: SET_INITIAL,
    form,
    file,
  }),
  addDocument: (
    document_id,
    document_type,
    document_name,
    document_description,
    create_date,
    document_status_var,
    document_media_type,
  ) => ({
    type: ADD_DOCUMENT,
    document_id,
    document_type,
    document_name,
    document_description,
    create_date,
    document_status_var,
    document_media_type,
  }),
  detachDocument: (document_id, document_status_var) => ({
    type: DETACH_DOCUMENT,
    document_id,
    document_status_var,
  }),
  openReturnProfile: () => ({
    type: OPEN_PROFILE,
  }),
  closeReturnProfile: () => ({
    type: CLOSE_PROFILE,
  }),
  requestReturnProfile: () => ({
    type: REQUESTED_RETURN_PROFILE,
  }),
  requestReturnProfileSuccess: (forms, notes) => ({
    type: REQUESTED_RETURN_PROFILE_SUCCEEDED,
    forms,
    notes,
  }),
  requestReturnProfileError: () => ({
    type: REQUESTED_RETURN_PROFILE_FAILED,
  }),
  setReturnProfileReturnID: returnID => ({
    type: SET_RETURN_ID,
    returnID,
  }),
  fetchReturnProfile: returnID => ({
    type: FETCHED_RETURN_PROFILE,
    returnID,
  }),
  fetchReturnProfileByReturnID: (
    returnID,
    fromSecuredMessage = false,
    isSecuredBusinessMessage = false,
  ) => ({
    type: FETCH_RETURN_PROFILE_BY_RETURN_ID,
    returnID,
    fromSecuredMessage,
    isSecuredBusinessMessage,
  }),
  toggleReturnLock: () => ({
    type: TOGGLE_STATUS_LOCK_RETURN,
  }),
  switchYearAndFetchReturnProfile: (season, returnID, isSecuredBusinessMessage = false) => ({
    type: SWITCH_YEAR_AND_FETCH_RETURN_PROFILE,
    season,
    returnID,
    isSecuredBusinessMessage,
  }),
  setWizardCompleted: isCompleted => ({
    type: SET_WIZARD_COMPLETE,
    isCompleted,
  }),
  setBusinessType: isBusiness => ({
    type: SET_RETURN_TYPE,
    isBusiness,
  }),
  removeDocument: document_id => ({
    type: REMOVE_DOCUMENT,
    document_id,
  }),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_DEMO_VERSION:
      return {
        ...state,
        usingDemoAccount: action.usingAccountType,
      };
    case SET_CLIENT_DATA:
      return {
        ...state,
        clientName: action.client?.name ? action.client.name : state.clientName,
        clientFirstName: action.client.first_name
          ? action.client.first_name
          : state.clientFirstName,
        clientLastName: action.client.last_name ? action.client.last_name : state.clientLastName,
        clientFilingStatus:
          action.client.filing_status || action.client.filing_status === ''
            ? action.client.filing_status
            : state.clientFilingStatus,
      };
    case SET_READ_ONLY_RETURN:
      return {
        ...state,
        isReadOnly: action.readOnly,
      };
    case LOCK_RETURN:
      return {
        ...state,
        lockedBy: action.who,
        lockedByLoginID: action.lockedByLoginID,
      };
    case LOCK_RETURN_RE_PERMISSION:
      return {
        ...state,
        lockedViaAccessLevel: true,
      };
    case SET_RETURN_ID:
      return {
        ...state,
        returnID: action.returnID,
      };
    case SET_ACTIVE:
      if (action.client.attachments == null) {
        action.client.attachments = [];
      }

      return {
        ...state,
        clientName: action.client.name, // 1040 first and last name || business name
        clientFirstName: action.client.first_name,
        clientLastName: action.client.last_name,
        clientFilingStatus: action.client.filing_status,
        primarySSNEIN: action.client.ssnein,
        year: action.client.year - 1,
        returnID: action.client.returnID,
        friendlyID: action.client.friendlyID,
        id: action.client.ssn?.slice(-4),
        created: action.client.date_created,
        refundBalanceDue: action.client.refund_or_balance_due,
        agi: action.client.fed_agi,
        attachments: action.client.attachments,
        formList: action.forms,
        lockedBy: action.client.lockedBy,
        lockedByLoginID: action.client.lockedByLoginID,
        isNew: action.client.isNew ? action.client.isNew : false,
        isWizard: action.client.wizard_mode,
        wizardCompleted: action.client.wizard_completed,
        lockedStatus: action.client.taxReturnLocked,
        readyForReview: action.client.readyForReview,
        returnStatus: action.client.returnStatus,
        fedFileDate: action.client?.fedFileDate,
        fedStatus: action.client?.fedStatus,
      };
    case CLEAR_ACTIVE:
      return {
        ...state,
        ...initialState,
      };
    case SET_FORMS:
      return {
        ...state,
        formList: action.forms,
      };
    case SET_TAXPASS_RETURN_FLAG:
      return {
        ...state,
        isTaxPassReturn: action.val,
      };
    case OPEN_ACTIVE:
      return {
        ...state,
        open: true,
      };
    case CLOSE_ACTIVE:
      return {
        ...state,
        open: false,
      };
    case SET_RETURN_ERRORS:
      return {
        ...state,
        verifyList: action.errors.verifies,
        errorNumber: action.errors.errors,
        warningNumber: action.errors.warnings,
        rejectList: action.errors.rejects,
        importErrorList: action.errors.impErrs,
        bankRejectList: action.errors.bankRejects,
        alertList: action.errors.alerts,
      };
    case SET_VERIFIES:
      return {
        ...state,
        verifyList: action.verifies.verifies,
        errorNumber: action.verifies.errors,
        warningNumber: action.verifies.warnings,
      };
    case SET_REJECTS:
      return {
        ...state,
        rejectList: action.rejects.rejects,
      };
    case SET_IMP_ERRS:
      return {
        ...state,
        importList: action.impErrs.impErrs,
      };
    case SET_BANK_REJECTS:
      return {
        ...state,
        bankRejectList: action.bankRejects.bankRejects,
      };
    case SET_ALERTS:
      return {
        ...state,
        alertList: action.alerts.alerts,
      };
    case SET_INITIAL:
      return {
        ...state,
        initialForm: action.form,
        initialFile: action.file,
      };
    case REMOVE_DOCUMENT:
      return {
        ...state,
        attachments: [...state.attachments.filter(doc => doc.document_id !== action.document_id)],
      };
    case DETACH_DOCUMENT:
      return {
        ...state,
        attachments: state.attachments.map(attach => {
          const newAttach = Object.assign({}, attach);
          if (Object.prototype.hasOwnProperty.call(attach, 'document_status_var')) {
            if (
              attach.document_id === action.document_id &&
              attach.document_status_var === action.document_status_var
            ) {
              delete newAttach.document_status_var;
            }
          }
          return newAttach;
        }),
      };
    case ADD_DOCUMENT: {
      const attachment = {
        document_id: action.document_id,
        document_type: action.document_type,
        document_name: action.document_name,
        document_description: action.document_description,
        create_date: action.create_date,
        document_status_var: action.document_status_var,
        document_media_type: action.document_media_type,
      };
      return {
        ...state,
        attachments: [...state.attachments, attachment],
      };
    }
    case OPEN_PROFILE:
      return {
        ...state,
        profileOpen: true,
      };
    case CLOSE_PROFILE:
      return {
        ...state,
        profileOpen: false,
      };
    case REQUESTED_RETURN_PROFILE:
      return {
        ...state,
        formList: [],
        isLoading: true,
        error: false,
      };
    case REQUESTED_RETURN_PROFILE_SUCCEEDED:
      if (!action.notes) {
        action.notes = [];
      } else {
        action.notes.sort((a, b) => b.timestamp.localeCompare(a.timestamp));
      }

      return {
        ...state,
        formList: action.forms,
        isLoading: false,
        error: false,
        notes: action.notes,
      };
    case REQUESTED_RETURN_PROFILE_FAILED:
      return {
        ...state,
        formList: [],
        isLoading: false,
        error: true,
      };
    case TOGGLE_STATUS_LOCK_RETURN:
      return {
        ...state,
        lockedStatus: !state.lockedStatus,
      };
    case SET_WIZARD_COMPLETE:
      return {
        ...state,
        wizardCompleted: action.isCompleted,
      };
    case SET_BUSINESS_TYPE:
      return {
        ...state,
        isBusiness: action.isBusiness,
      };
  }
  return state;
}

/* Modified styles from https://material-ui.com/demos/text-fields/ */
export const themeStyles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  cancelButtonRoot: {
    height: "30px",
    width: "97px",
    border: "0px",
    backgroundColor: "#FFFFFF",
    lineHeight: "0em"
  },
  cssLabel: {
    "&$cssFocused": {
      color: "#40a9ff"
    }
  },
  cssFocused: {},
  cssUnderline: {
    "&:after": {
      borderBottomColor: "#40a9ff"
    }
  },
  typography: {
    padding: theme.spacing.unit * 3
  },
  tableRowRoot: {
    height: "35px",
    letterSpacing: "0.1px"
  },
  tableHeadRoot: {
    height: "40px",
    letterSpacing: "0.1px"
  },
  tableCellRoot: {
    border: "0",
    padding: ".5rem 1.5rem"
  },
  tableHeaderRow: {
    height: "auto"
  },
  tableHeaderCell: {
    borderBottom: "0px",
    fontSize: "inherit",
    padding: ".5rem 1.5rem",
    position: "sticky",
    top: "0",
    backgroundColor: "#fff",
    boxShadow: "7px 0 5px 0 #000",
  },
  choicelistHeaderTitle: { color: '#fff', padding: '.5rem 0', width: "50%" },
  choicelistTableWrapper: { backgroundColor: "#fff", color: "#000" },
  choicelistSearchBtn: {
    marginLeft: "1rem",
    color: "#444a59",
    backgroundColor: "#ddd",
    border: '0',
    '&:hover': {
      color: '#fff',
      border: "1px solid #fff"
    }
  }
});
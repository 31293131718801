// Internal imports
import { formatSSNEIN } from '~/app/Utility/general.js';
import {
  INDIVIDUAL_RETURN_TAB,
  BUSINESS_RETURN_TAB,
  HIERARCHY_TYPE,
  EPHEMERAL_LIST_FILTERS,
  REGEX_NUMBERS_ONLY,
} from '~/app/constants.js';

/**
 * @module taxReturnsHelper
 * @category TaxReturns
 */

/**
 * Determine what login type is allow to add a new return.
 * Which should only be an EFIN or preparer login type.
 *
 * THIS should be combined with canOpenTaxReturnHelper
 *
 * @function
 * @param {Object} currentView
 * @returns boolean True if it's an EFIN or preparer login type
 */
export const canAddTaxReturnHelper = currentView => {
  const isEFINorPreparer =
    currentView?.role === HIERARCHY_TYPE.EFIN || currentView?.role === HIERARCHY_TYPE.PREPARER;
  const isLinkedToPreparer = currentView?.preparerID > 0;
  return isEFINorPreparer && isLinkedToPreparer;
};

/**
 * Determine what login type is allow to open a return.
 * Which should only be an EFIN or Preparer
 *
 * THIS should be combined with canAddTaxReturnHelper
 *
 * @function
 * @param {Object} currentView
 * @returns boolean True if it's an EFIN or preparer login type or is Admin/Tech
 */
export const canOpenTaxReturnHelper = currentView => {
  const role = currentView?.role;
  const preparerID = currentView?.preparerID;
  const isTechOrAdmin = [
    HIERARCHY_TYPE.ADMIN,
    HIERARCHY_TYPE.TECH_SUPPORT,
    HIERARCHY_TYPE.TECH_SUPPORT_SBA,
  ].includes(role);
  const isEFINOrPrep = [HIERARCHY_TYPE.EFIN, HIERARCHY_TYPE.PREPARER].includes(role);

  return isTechOrAdmin || (isEFINOrPrep && preparerID);
};

/**
 * Get the filter type base on the filter ID.
 *
 * @function
 * @param {number} filterID
 * @returns - The filter item
 */
export const fetchFilterType = (allFilters, filterID) => {
  if (allFilters[filterID]) {
    return allFilters[filterID].filter;
  } else {
    return EPHEMERAL_LIST_FILTERS[filterID];
  }
};

/**
 * Handles filtering the return list for duplicate tax returns that are caused from
 * a Feeder office submitting a tax return for Review, and pushing it to an EFIN.
 *
 * @param {Array[Object]} returnList list of returns
 * @returns {Array[Object]} used to display in the return list component in Tax Returns
 */
export const filterArrayForDups = returnList => {
  // initialize a Set object
  const uniqueValuesSet = new Set();

  const uniqueReturnList = returnList.filter(ret => {
    const isPresentInSet = uniqueValuesSet.has(ret.returnID);

    // add name property value to Set
    uniqueValuesSet.add(ret.returnID);

    // isPresentInSet variable
    return !isPresentInSet;
  });
  return uniqueReturnList;
};

/**
 * Remove hypens from string
 *
 * @function
 * @param {string} str
 * @returns - string without hypen
 */
export const removeHypen = str => {
  return str.replace(/-/g, '');
};

/**
 * Prevents enter from being used on input
 *
 * @function
 * @param {element} e
 */
export const suppressEnter = e => {
  // keyCode = 13 is ENTER
  if (e.type === 'keydown' && e.keyCode === 13) {
    e.preventDefault();
  }
};

const INDIVIDUAL_COLUMNS = [
  {
    name: 'Return ID',
    col: 'return_id',
    asc: 'Sort By Low to High',
    desc: 'Sort By High to Low',
    details: 'Tax Return ID',
  },
  // TODO (CLO-BUSINESS): update the cols for Taxpayer Name and Social Security Number, use generic types for both fields
  {
    name: 'Taxpayer Name',
    col: 'name',
    asc: 'Sort By A-Z',
    desc: 'Sort By Z-A',
    details: 'Primary Taxpayer Full Name',
  },
  {
    name: 'Social Security Number',
    col: 'ssnein',
    asc: 'Sort By  0000-9999',
    desc: 'Sort By  9999-0000',
    details: 'Primary Taxpayer Social Security Number',
  },
  {
    name: 'Date Created',
    col: 'date_created',
    asc: 'Sort By Oldest First',
    desc: 'Sort By Newest First',
    details: 'Tax Return Created Date',
  },
  {
    name: 'Filing Status',
    col: 'filing_status',
    asc: 'Sort By A-Z',
    desc: 'Sort By Z-A',
    details: 'Tax Return Filing Status',
  },
  {
    name: 'Return Status',
    col: 'return_status',
    asc: 'Sort By A-Z',
    desc: 'Sort By Z-A',
    details: 'Tax Return Status',
  },
  { name: 'Action', col: 'action', asc: 'Sort By A-Z', desc: 'Sort By Z-A' }, // always display this column
  {
    name: 'Refund',
    col: 'refund_or_balance_due',
    asc: 'Sort By Low to High',
    desc: 'Sort By High to Low',
    details: 'Federal Refund Amount',
  },
  {
    name: 'EFIN',
    col: 'efin',
    asc: 'Sort By Low to High',
    desc: 'Sort By High to Low',
    details: 'Tax Return EFIN Number',
  },
];

const BUSINESS_COLUMNS = [
  {
    name: 'Return ID',
    col: 'return_id',
    asc: 'Sort By Low to High',
    desc: 'Sort By High to Low',
    details: 'Tax Return ID',
  },
  // TODO (CLO-BUSINESS): update the cols for business name and ein, use generic types for both fields
  {
    name: 'Business Name',
    col: 'name',
    asc: 'Sort By A-Z',
    desc: 'Sort By Z-A',
    details: 'Name of the Business',
  },
  {
    name: 'EIN',
    col: 'ssnein',
    asc: 'Sort By  0000-9999',
    desc: 'Sort By  9999-0000',
    details: 'Electronic Identification Number',
  },
  {
    name: 'Date Created',
    col: 'date_created',
    asc: 'Sort By Oldest First',
    desc: 'Sort By Newest First',
    details: 'Tax Return Created Date',
  },
  {
    name: 'Entity Type',
    col: 'entity_type',
    asc: 'Sort By Type 0000-9999',
    desc: 'Sort By Type 9999-0000',
    details: 'Business Entity Type',
  },
  {
    name: 'Return Status',
    col: 'return_status',
    asc: 'Sort By A-Z',
    desc: 'Sort By Z-A',
    details: 'Tax Return Status',
  },
  {
    name: 'Action',
    col: 'action',
    asc: 'Sort By A-Z',
    desc: 'Sort By Z-A',
  }, // always display this column
  {
    name: 'Refund',
    col: 'refund_or_balance_due',
    asc: 'Sort By Low to High',
    desc: 'Sort By High to Low',
    details: 'Federal Refund Amount',
  },
  {
    name: 'EFIN',
    col: 'efin',
    asc: 'Sort By Low to High',
    desc: 'Sort By High to Low',
    details: 'Tax Return EFIN Number',
  },
];

const ACTIVE_INDV_COLUMNS = [0, 1, 2, 3, 5, 6];
const ACTIVE_BUSINESS_COLUMNS = [0, 1, 2, 3, 4, 5, 6];

export const TAX_RETURN_COLUMNS = {
  [INDIVIDUAL_RETURN_TAB]: INDIVIDUAL_COLUMNS,
  [BUSINESS_RETURN_TAB]: BUSINESS_COLUMNS,
};

export const TAX_RETURN_ACTIVE_COLUMNS = {
  [INDIVIDUAL_RETURN_TAB]: ACTIVE_INDV_COLUMNS,
  [BUSINESS_RETURN_TAB]: ACTIVE_BUSINESS_COLUMNS,
};

// Handle onChange without validation fon input fields
export const handleBlindInputChange = (e, blindEntryCfg, setState, currentVal, isBusiness) => {
  let currentDigit = '';
  const value = removeHypen(e.target.value);

  const name = e.target.name;
  if (blindEntryCfg === '1') {
    if (value !== '') {
      currentDigit = value.charAt(value.length - 1);
    } else return;

    // do not update value if a number wasn't entered - this also catches backspacing and recieving an 'X'
    if (!REGEX_NUMBERS_ONLY.test(currentDigit)) {
      return;
    }

    setState({
      [name]: formatSSNEIN(currentVal + currentDigit, isBusiness),
      [name + 'Blind']: formatSSNEIN('X'.repeat(value.length), isBusiness),
    });
  } else {
    // do not update value if a number wasn't entered
    if (value !== '' && !REGEX_NUMBERS_ONLY.test(value)) {
      return;
    }
    setState({ [name]: formatSSNEIN(value, isBusiness) });
  }
};

// handles backspace for blind entry inputs -> handleBlindInputChange
export const handleBlindBackSpace = (e, currentVal, setState, isBusiness) => {
  if (e.keyCode === 8 && e.type === 'keydown') {
    const name = e.target.name;

    // remove the last character and remove hyphens
    const updatedSSN = removeHypen(currentVal.substring(0, currentVal.length - 1));

    setState({
      [name]: formatSSNEIN(updatedSSN, isBusiness),
      [name + 'Blind']: formatSSNEIN('X'.repeat(updatedSSN.length), isBusiness),
    });
  }
};

// 2024 and beyond no longer uses extension statuses for the main status - instead we use sub status id.
// This handles converting the extension statuses back to their correlated main statuses. Once we decomission season 2023, this logic and the db lookup table should be refactored.
export const enhancedReturnStatusConversion = lookupID => {
  // the below edgecases are return statuses that have a correlated extension return status - not all main statuses have one
  switch (lookupID) {
    // The following return status lookup id's have correlated extension statuses
    case '24': // Extn Ack
      return { lookupID: '3', subStatusID: 'E' }; // Acked
    case '27': // Extn Rfs
      return { lookupID: '7', subStatusID: 'E' }; // Refused
    case '28': // Extension at IRS
      return { lookupID: '22', subStatusID: 'E' }; // At IRS
    case '29': // Extension Rejected
      return { lookupID: '15', subStatusID: 'E' }; // Rejected
    case '68': // Extn Queued
      return { lookupID: '14', subStatusID: 'E' }; // Queued

    // The following return statuses are extension statuses that do NOT have a correlated main status, but still need their sub status id set
    case '30': // Extension St Only
      return { lookupID: '30', subStatusID: 'E' };
    case '26': // Extension As IRS
      return { lookupID: '26', subStatusID: 'E' };
    case '8': // Extn Rdy
      return { lookupID: '8', subStatusID: 'E' };
    default:
      return { lookupID, subStatusID: '0' };
  }
};

// External imports
import React, { Fragment } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
} from '@material-ui/core';

const AddSchemeDialogContent = props => {
  return (
    <Dialog open={props.openDialog} onClose={props.onClose}>
      <Fragment>
        <DialogTitle id="dlgAddSchemeTitle">Create New Scheme</DialogTitle>
        <DialogContent>
          <DialogContentText>Enter a new billing scheme name</DialogContentText>
          <TextField
            autoFocus={true}
            margin="dense"
            id="txtAddSchemeDlg"
            label="Enter Scheme Name"
            variant="filled"
            value={props.newSchemeName}
            fullWidth
            onChange={e => props.onChange(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <div>
            <Button onClick={props.handleAddSchemeDialogClose} color="primary">
              Cancel
            </Button>
            <Button onClick={props.handleAddNewScheme} color="primary">
              Add
            </Button>
          </div>
        </DialogActions>
      </Fragment>
    </Dialog>
  );
};

export default AddSchemeDialogContent;

import React from 'react';
// Internal imports
import { useSetState } from '~/app/Utility/customHooks';
import { DOCUMENT_TYPE_VALUES } from '~/app/constants.js';
// External imports
import { Grid, FormControl, TextField, Button } from '@material-ui/core';
// Redux imports
import { useDispatch } from 'react-redux';
import { actions as appActions } from '~/app/redux/modules/app.js';
// Style imports
import '~/app/Components/RemoteRequestDocs/css/remoteRequestDocs.css';

const MAX_DOCUMENT_SIZE = 5; // 5MB

/**
 * Handles letting the user save the request file after being verified
 *
 * @component VerifiedRequestDocs
 * @category ArchivedDocuments
 **/
const VerifiedRequestDocs = props => {
  const dispatch = useDispatch();
  const [state, setState] = useSetState({
    fileData: {
      file: '',
      fileType: '',
      fileName: '',
      fileSize: 0,
    },
    description: '',
    isDisabled: true,
  });

  /** Handles listening to file input - here we will do checks for sizes and corrupt files */
  const handleFileChange = e => {
    e.preventDefault();
    const file = e.target.files[0];

    if (file.size / 1024 / 1024 > MAX_DOCUMENT_SIZE) {
      dispatch(
        appActions.showSnackbarMessage(
          `File size must be less than 5 MegaBytes. The Attached file size was ${parseInt(
            file.size / 1024 / 1024,
          )} MegaBytes`,
          'error',
          3500,
          {
            vertical: 'top',
            horizontal: 'center',
          },
        ),
      );
      setState({ isDisabled: true });
    } else if (!DOCUMENT_TYPE_VALUES.includes(file.type)) {
      dispatch(
        appActions.showSnackbarMessage(
          `File type must be the following: JPEG, JPG, PNG, PDF. The Attached file extension was '${file.type}'`,
          'error',
          3500,
          {
            vertical: 'top',
            horizontal: 'center',
          },
        ),
      );
      setState({ isDisabled: true });
    } else {
      // saves file data and blob to state
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = () =>
        setState({
          fileData: {
            ...state.fileData,
            file: reader.result.split(',')[1],
            fileType: file.type,
            fileName: file.name,
            fileSize: file.size,
          },
          isDisabled: false,
        });

      reader.onerror = error => {
        dispatch(
          appActions.showSnackbarMessage(`Error parsing file: ${error}`, 'error', 3500, {
            vertical: 'top',
            horizontal: 'center',
          }),
        );
      };
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <FormControl fullWidth required>
          <label htmlFor="requestedDescriptionInput" className="request-docs-body-input-label">
            File Description
          </label>
          <TextField
            id="requestedDescriptionInput"
            type="text"
            name="description"
            onChange={e => setState({ description: e.target.value })}
            value={state.description}
            variant="outlined"
            required
            placeholder="Start typing here..."
            inputProps={{ maxLength: 100 }}
            multiline
            rows={3}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth required>
          <label htmlFor="requestedFileInput" className="request-docs-body-input-label">
            Max Document Size is 5 MegaBytes
          </label>
          <TextField
            id="requestedFileInput"
            type="file"
            name="fileName"
            onChange={e => handleFileChange(e)}
            variant="outlined"
          />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth>
          <Button
            id="btnSubmitFile"
            onClick={() => props.handleSaveFile(state.fileData, state.description)}
            variant="contained"
            type="submit"
            color="primary"
            disabled={state.isDisabled}
          >
            Submit
          </Button>
        </FormControl>
      </Grid>
    </>
  );
};

export default VerifiedRequestDocs;

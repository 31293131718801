import React, { Component } from "react";

//RefundInfo takes in two parameters, refundAmount and refundInfo. Depending on whether the value is a negative or positive balance, we will display red or green text respectively.
//refundInfo will display where the refund is coming from...i.e -> Federal or CA State.
const RefundInformation = ({ refundAmount, refundInfo }) => {
  let color = "#00C44F"; //Green display text by default
  let refundClass = "refundInfoHeaderPositive";

  if (refundAmount[0] == "-") {
    refundAmount = refundAmount.slice(1);
    color = "#FF304A";
    refundClass = "refundInfoHeaderNegative";
  }
  let refundInfoHeaderStyle = {
    color: color,
    fontSize: "21px",
    fontFamily: "Roboto",
    lineHeight: "24px",
    marginLeft: "30px"
  };

  let refundInfoFooterStyle = {
    color: "#354052",
    fontFamily: "Roboto",
    fontSize: "12px",
    lineHeight: "14px",
    fontWeight: "300",
    marginLeft: "30px"
  };
  return (
    <div className="fed-refund-amt">
      <div style={refundInfoHeaderStyle} className={refundClass}>
        <span>{refundAmount}</span>
      </div>
      <div style={refundInfoFooterStyle} className="refundInfoFooter">
        <span>{refundInfo} /Bal Due</span>
      </div>
    </div>
  );
};

export default RefundInformation;

import React, { useEffect, Fragment } from 'react';
// Internal imports
import RefundInformation from '#/Common/RefundInformation.jsx';
import AGIInformation from '#/Common/AGIInformation.jsx';
import { useSetState } from '~/app/Utility/customHooks';
// External imports
import { Collapse } from '@material-ui/core';
import expandArrow from '~/images/icons/icons8-double_right_filled.png';
// Redux imports
import { useSelector } from 'react-redux';
// Styling imports
import '~/app/Pages/WizardMode/components/SideBarForms/css/refundDisplay.css';

/**
 * Display Return Refund
 *
 * @component RefundDisplay
 * @category WizardMode
 */
const RefundDisplay = props => {
  const [state, setState] = useSetState({
    states: [],
    stateListOpen: false,
  });

  const { returnFormList } = useSelector(state => ({
    returnFormList: state.returnProfile.formList,
  }));

  // update the state list when the forms list changes
  useEffect(() => {
    const stateList = getStateRefunds();
    setState({ states: stateList });
  }, [returnFormList]);

  const getFedForm = formList => {
    let form;
    for (let i = 0; i < formList?.length; i++) {
      if (
        formList?.[i]?.desc &&
        formList?.[i]?.desc.trim() === 'FEDERAL' &&
        formList?.[i]?.var === 'US'
      ) {
        form = formList[i];
      }
    }

    return form;
  };

  const getAGIInfo = () => {
    let agiAmount = '0';
    const fedForm = getFedForm(returnFormList);
    if (fedForm && fedForm?.agi) {
      agiAmount = `${fedForm.agi}`;
    }

    return <AGIInformation agiAmount={agiAmount} agiInfo={'Federal AGI'} />;
  };

  const getFedRefund = () => {
    let fedRefund = 0;

    const fedForm = getFedForm(returnFormList);
    if (fedForm) {
      if (fedForm?.rfnd) {
        fedRefund = fedForm.rfnd.substring(1);
      } else if (fedForm?.bdue) {
        fedRefund = fedForm.bdue.replace('$', '-');
      }
    }

    return <RefundInformation refundAmount={fedRefund} refundInfo={'Federal Refund'} />;
  };

  const handleExpandStatelist = async () => {
    await setState(prevState => ({
      stateListOpen: !prevState.stateListOpen,
    }));
  };

  const getStateRefunds = () => {
    const states = [];
    for (let i = 0; i < returnFormList.length; i++) {
      if (
        returnFormList?.[i].desc &&
        returnFormList[i].desc.trim() !== 'FEDERAL' &&
        returnFormList[i].desc !== 'GENERAL' &&
        returnFormList[i].desc !== 'Refund Calculator' &&
        returnFormList?.[i].var !== 'retn37' &&
        returnFormList[i].var !== 'PF'
      ) {
        let refOrBal;
        if (returnFormList?.[i]?.rfnd && returnFormList?.[i]?.rfnd !== '') {
          refOrBal = returnFormList?.[i]?.rfnd.substring(1);
          states.push(
            <RefundInformation
              refundAmount={refOrBal}
              refundInfo={returnFormList[i].var + ' State Refund'}
              key={returnFormList[i].var + i}
            />,
          );
        } else if (returnFormList?.[i]?.bdue && returnFormList?.[i]?.bdue !== '') {
          refOrBal = returnFormList[i].bdue.replace('$', '-');
          states.push(
            <RefundInformation
              refundAmount={refOrBal}
              refundInfo={returnFormList[i].var + ' State Refund'}
              key={returnFormList[i].var + i}
            />,
          );
        } else {
          states.push(
            <RefundInformation
              refundAmount={0}
              refundInfo={returnFormList[i].var + ' State Refund'}
              key={returnFormList[i].var + i}
            />,
          );
        }
      }
    }
    // If there is more than one state wrap the other states in a collapse element
    if (states.length > 1) {
      return (
        <div id="sibebar-additional-states-refund">
          {states.shift()}
          <Collapse in={state.stateListOpen} className="collapse-size" timeout="auto" unmountOnExit>
            {states}
          </Collapse>
        </div>
      );
    }
    return states;
  };

  return (
    <div id="sidebar-return-refunds-list" className="refund-display">
      {getFedRefund()}
      {state.states}
      {getAGIInfo()}
      {state.states?.length === undefined ? (
        <div onClick={handleExpandStatelist} className="state-display" cursor="pointer">
          {state.stateListOpen ? 'Collapse' : 'Expand All'}
          <img
            src={expandArrow}
            className={state.stateListOpen ? 'tableExpandArrowDown' : 'tableExpandArrowUp'}
          />
        </div>
      ) : (
        <Fragment />
      )}
    </div>
  );
};

export default RefundDisplay;

// External imports
import React from 'react';
import { Button, Typography, Paper, Modal, withStyles } from '@material-ui/core';
import downloadIcon from '~/images/icons/downloadIcon.svg';
// Styling imports
import { styles } from '~/app/Pages/Returns/components/Document/ArchivedDocuments/css/archivedDocuments.js';

const PreviewDocumentModal = props => {
  const { classes } = props;
  // check if document is over 1.5 mb
  const isDocumentLarge = () => {
    // forumla to get approximate size (in bytes) of base64 String
    // n * (3/4) where n is the length base64 String
    const fileSize = props.currentOpenAttachment?.length * 0.75;

    // 1.5 mb = 1500000 bytes
    if (fileSize > 1500000) {
      return true;
    }
    return false;
  };

  const openPDFWindow = () => {
    // Get the starting index of base64 String for slicing.
    // using comma is fine as string always follows pattern 'data:<contentType>;base64,iVBO....'
    const divisionIndex = props.currentOpenAttachment?.lastIndexOf(',');

    const base64String = props.currentOpenAttachment?.slice(divisionIndex + 1);

    // convert base64 string into byte string
    const byteCharacters = window.atob(base64String);

    // sort byte string into array of byte characters
    const byteCharacterArray = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteCharacterArray[i] = byteCharacters.charCodeAt(i);
    }

    // convert character byte array into actual byte array
    const byteArray = new Uint8Array(byteCharacterArray);

    // convert bytes into blob
    const pdfBlob = new Blob([byteArray], { type: 'application/pdf' });

    const blobUrl = URL.createObjectURL(pdfBlob);

    window.open(blobUrl);
  };

  return (
    <Modal
      id="mdlAddArchiveDocumentModalDocArchive"
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={props.isPreviewModalOpen}
      className={classes.modalSpacing}
      onClose={() => props.toggleModal('isPreviewModalOpen')}
    >
      <Paper className={classes.modal}>
        <Typography variant="body1" className={classes.modalTitle}>
          Preview {props.currentOpenAttachmentTitle}
        </Typography>
        <div className="preview-doc-archive-modal-container">
          <table>
            <tbody>
              <tr>
                <td className={classes.modalDesc}>
                  Description : {props.currentOpenAttachmentDesc}
                </td>
                <td className={classes.modalDocLink}>
                  <a
                    className="preview-doc-archive-modal-container-table-td-a"
                    onClick={() => props.toggleModal('isRequestPDFModalOpen')}
                  >
                    <img
                      className="preview-doc-archive-modal-container-table-td-a-img"
                      src={downloadIcon}
                    />
                    <button
                      id="mdlDocumentArchiveDownloadDocBtn"
                      className="preview-doc-archive-modal-container-table-td-a-btn"
                    >
                      Download Document
                    </button>
                  </a>
                </td>
                <td>
                  <Button
                    id="mdlDocumentArchiveDownloadDocCloseBtn"
                    variant="contained"
                    className={classes.previewModalBtn}
                    color="primary"
                    onClick={() => props.toggleModal('isPreviewModalOpen')}
                  >
                    Close
                  </Button>
                </td>
              </tr>
              <tr className={classes.modalSecondaryData}>
                <td>Uploaded : {props.currentOpenAttachmentDateUp}</td>
              </tr>
              <tr>
                <td className={classes.modalSecondaryDataTwo}>
                  Type : {props.currentOpenAttachmentType}
                </td>
              </tr>
            </tbody>
          </table>
          <div>
            <table>
              <tbody>
                <tr>
                  <td>
                    {isDocumentLarge() && (
                      <div>
                        Document is too large to preview. Please open in a new&nbsp;
                        <a href="javascript:;" onClick={() => openPDFWindow()}>
                          window
                        </a>
                      </div>
                    )}
                    {props?.currentOpenAttachment && (
                      <object
                        width="950"
                        height="650"
                        id="downloadArchiveDoc"
                        data={props.currentOpenAttachment}
                      />
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Paper>
    </Modal>
  );
};

export default withStyles(styles)(PreviewDocumentModal);

// External imports
import React from 'react';
import { Input, withStyles } from '@material-ui/core';
import NumberFormat from 'react-number-format';
// Styling imports
import { styles } from '~/app/Components/NewAccount/Setup/css/setupStyles.js';

const InputTableCell = props => {
  const { classes } = props;

  return (
    <NumberFormat
      classes={{ input: classes.inputStylePerItem }}
      disableUnderline
      value={props.value}
      onBlur={e => props.updateValue(props.billid, e, props.form_name, props.form_description)}
      prefix={props.type === 'currency' ? '$' : ''}
      thousandSeparator={true}
      customInput={Input}
      fixedDecimalScale={props.type === 'currency'}
      decimalScale={2}
      id={props.id}
    />
  );
};

export default withStyles(styles)(InputTableCell);

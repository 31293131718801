import React, { useState, useEffect } from 'react';
// Internal imports
import {
  getChildrenOfRows,
  validateInputPattern,
  getColumnTotal,
  getSubIncrement,
  getInputSize,
  getPadding,
} from '~/app/Pages/WizardMode/Mapper/helpers/mapperHelpers.js';
import { getBreakpointGrid } from '~/app/Pages/WizardMode/wizardModeHelpers.js';
import infoIcon from '~/images/icons/icons8-info.svg';
// External imports
import { TextField, InputLabel, FormControl, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
// Styling imports
import { styles } from '~/app/Pages/WizardMode/Mapper/css/mapperStyles.js';
import '~/app/Pages/WizardMode/Mapper/css/mapperStyles.css';
import FreeBreakfastOutlined from '~/node_modules/@material-ui/icons/FreeBreakfastOutlined';

/**
 * Handles I, N, L, Y, S field types for Interger Fields
 *  I  type - Integer + Whole Numbers
 *  N  type - Integer + Whole numbers + Floats
 *  L  type - Integer + Negative Whole Numbers
 *  Y  type - DOB format - max 9 or 10
 *  S  type - SSN format XXX-XX-XXXX - max 9 or 10
 *  T  type - Phone Number
 *
 * @param {Object} props check the map helper to see what is passed in
 * @returns JSX integer input
 */
const IntegerMap = props => {
  const { classes } = props;
  const [style, setStyle] = useState(
    getInputSize(parseInt(props.size), props.currentInputErrors?.[props.name]),
  );
  // field padding for spacing
  const [fieldPadding, setFieldPadding] = useState(
    getPadding(props.field, props.preppedUI, props.row, props.size),
  );
  // Returns an Array of inputs/CellItems that are associated to the same row
  // const childrenOfRows = getChildrenOfRows(props.preppedUI, props.row, false); UNUSED- marked for deletion
  // for dynamic fields, add a sub increment to the title
  const dynamicSubIncr = getSubIncrement(props.field.substring(2, 4));
  // determines the size of the input and the column size for material ui grid
  // const column = getColumnTotal(childrenOfRows, props.field); UNUSED- marked for deletion
  // handles determining the breakpoint and also handles checking if a property exists.
  // const columnBreakpoint = getBreakpointGrid(column, true, null);  UNUSED- marked for deletion
  // determines the size of the field
  const fieldSize =
    classes[getInputSize(parseInt(props.size), props.currentInputErrors?.[props.name])];
  // disables spouse fields if filing status is 1 or 3
  const disableSpouse = props.singleFLST && props?.tors === 'S';

  let formatPattern;
  let thousandOperator;
  let prefix;
  let allowNegative;
  let inputPlaceholder;

  useEffect(() => {
    setStyle(getInputSize(parseInt(props.size), props.currentInputErrors?.[props.name]));
  }, [props.currentInputErrors?.[props.name]]);

  // Logic here will decide the different props to add based on the type
  switch (props.type) {
    case 'Y':
      formatPattern = '##/##/####';
      inputPlaceholder = 'mm/dd/yyyy';
      break;
    case 'S':
      formatPattern = '###-##-####';
      inputPlaceholder = '111-22-3333';
      break;
    case 'N':
      formatPattern = null;
      thousandOperator = false;
      prefix = '$';
      inputPlaceholder = '$';
      break;
    case 'L':
      formatPattern = null;
      thousandOperator = false;
      allowNegative = true;
      prefix = '$';
      inputPlaceholder = '$';
      break;
    case 'T':
      formatPattern = '###-###-####';
      break;
    default:
      break;
  }

  const negativeConversion = value => {
    if (props.type === 'L') {
      const convertedValue = value.replace('(', '-').replace(')', '');
      return convertedValue;
    }

    return value;
  };

  return (
    //<Grid item xs={columnBreakpoint} md={columnBreakpoint} lg={'auto'}></Grid>
    <Grid item lg={fieldPadding === null ? 12 : 'auto'}>
      <div className="grid-container-grid-item-div" style={fieldPadding}>
        <FormControl>
          <NumberFormat
            label={
              props.indirect === 'TOP' &&
              (props.dsplymode === 'd' ? `${dynamicSubIncr}. ${props.prompt}` : props.prompt)
            }
            InputLabelProps={{
              shrink: true,
            }}
            required={props.flag?.includes('*')}
            variant="outlined"
            id={props.field}
            name={props.name}
            value={negativeConversion(props.form[props.name])}
            onChange={e => {
              e.target.value = validateInputPattern(e.target.value, props.type, 0, props.max);
              props.handleChange(e);
            }}
            onBlur={e => props.onBlurCalc(e, true)}
            format={formatPattern}
            className={`MuiFormHelperText-root-1165 ${fieldSize}`}
            style={style}
            prefix={prefix}
            thousandSeparator={thousandOperator}
            customInput={TextField}
            disabled={props.flag === '+' || props.getLockedStatus() || disableSpouse}
            aria-describedby={props.prompt}
            allowNegative={allowNegative}
            placeholder={inputPlaceholder}
            helperText={props.currentInputErrors?.[props.name]}
            error={props.currentInputErrors?.[props.name] !== undefined}
          />
        </FormControl>
        {props.indirect === 'RIGHT' && (
          <>
            <InputLabel htmlFor={props.field} className={classes.sideLabelStyle}>
              {props.dsplymode === 'd' ? `${dynamicSubIncr}. ${props.prompt}` : props.prompt}
              {/* Info bubble */}
              {props.dsplymode === '?' && (
                <img
                  id={props.field}
                  onClick={() => props.handleModal('isInfoModalOpen', props.lineno, props.prompt)}
                  className="container-item-icon"
                  alt={props.lineno}
                  src={infoIcon}
                />
              )}
            </InputLabel>
          </>
        )}
      </div>
    </Grid>
  );
};

export default withStyles(styles)(IntegerMap);

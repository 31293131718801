export const styles = () => ({
  list: {
    paddingTop: '0.31rem',
    paddingBottom: '0.31rem',
  },
  listItem: {
    paddingTop: '0.43rem',
    paddingBottom: '0.43rem',
  },
  listItemTextBlock: {
    marginLeft: '0.5rem',
    padding: '0',
  },
  listItemText: {
    fontFamily: 'Roboto',
    fontSize: '0.88rem',
    lineHeight: '1rem',
    height: '100%',
    maxWidth: '100%',
    cursor: 'pointer',
    color: '#0077ff',
    minWidth: '8rem',
  },
  listItemTextUnread: {
    fontFamily: 'Roboto',
    fontSize: '0.88rem',
    fontWeight: '500',
    lineHeight: '1rem',
    height: '100%',
    maxWidth: '100%',
    cursor: 'pointer',
    color: '#0077ff',
    minWidth: '8rem',
  },
  unreadIcon: {
    color: '#0077FF',
  },
  tooltip: {
    maxWidth: '150px',
    textAlign: 'center',
    fontSize: '12px',
  },
});

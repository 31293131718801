// External imports
import React, { useEffect, Fragment } from 'react';
import update from 'immutability-helper';
import {
  Button,
  Checkbox,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  Input,
  InputLabel,
  Paper,
  Snackbar,
  withStyles,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
// Internal imports
import AccessControl from '#/Auth/AccessControl.jsx';
import ErrorHelpers from '~/app/errorHelpers.js';
import SimpleDialog from '#/Common/SimpleDialog.jsx';
import { useSetState } from '~/app/Utility/customHooks';
import WebHelpers, { statusOK } from '~/app/webHelpers.js';
import XlinkAPI from '~/app/api/xlinkAPI';
import { ACCESS_LEVEL, EXTENDED_SETUP_PAGES } from '~/app/constants.js';
// Styling imports
import { styles } from '~/app/Components/NewAccount/SingleOffice/css/auditStyles.jsx';
import '~/app/Components/NewAccount/SingleOffice/css/audit.css';

// TODO: Rewrite this component, very hard to maintain as is
const AuditSetup = props => {
  const { classes } = props;
  const payload = WebHelpers.getJWTPayload();
  const [state, setState] = useSetState({
    configValues: {},
    expandedHeaders: ['EIC', 'SCHA', 'ADD', 'OPP', 'TAX', 'HOH'],
    snackbarOpen: false,
    confirmCancel: false,
    goToPrev: false,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    getInitialConfigState();
  }, []);

  const getInitialConfigState = async () => {
    try {
      const res = await XlinkAPI.getAuditSetup(props.loginID);
      if (statusOK(res)) {
        setConfigState(res.data.config);
      }
    } catch (error) {
      ErrorHelpers.handleError('Error Loading Audit Configuration', error);
    }
  };

  const setConfigState = config => {
    const configItems = {};
    // Convert returned config object values to true and false for easier checkbox handling
    Object.keys(config).forEach(key => {
      const configItem = config[key];
      switch (configItem.configType) {
        case 3:
          configItems[key] = {
            configID: configItem.configID,
            configType: configItem.configType,
            configValue: configItem.configValue === '1',
          };
          break;
        case 5:
          configItems[key] = {
            configID: configItem.configID,
            configType: configItem.configType,
            configValue: configItem.configValue,
          };
          break;
        default:
          break;
      }
    });
    setState({
      configValues: configItems,
    });
  };

  const handleClickOnCollapse = key => {
    const keyIndex = state.expandedHeaders.indexOf(key);
    const newexpandedHeaders = [...state.expandedHeaders];

    if (keyIndex === -1) {
      newexpandedHeaders.push(key);
    } else {
      newexpandedHeaders.splice(keyIndex, 1);
    }

    setState({
      expandedHeaders: newexpandedHeaders,
    });
  };

  // Handle onChange without validation for checkbox
  const handleCheckbox = e => {
    const updatedConfigState = update(state.configValues, {
      [e.target.name]: { configValue: { $set: e.target.checked } },
    });
    setState({
      configValues: updatedConfigState,
    });
  };

  // Handle onChange without validation
  const handleInputChange = e => {
    const updatedConfigState = update(state.configValues, {
      [e.target.name]: { configValue: { $set: e.target.value.toUpperCase() } },
    });
    setState({
      configValues: updatedConfigState,
    });
  };

  // Select All EIC Checkboxes
  const handleSelectAll = () => {
    const selectAllEIC = update(state.configValues, {
      VC01: { configValue: { $set: true } },
      VC54: { configValue: { $set: true } },
      VC02: { configValue: { $set: true } },
      VC03: { configValue: { $set: true } },
      VC04: { configValue: { $set: true } },
      VC05: { configValue: { $set: true } },
      VC07: { configValue: { $set: true } },
      VC08: { configValue: { $set: true } },
      VC32: { configValue: { $set: true } },
      VC33: { configValue: { $set: true } },
      VC06: { configValue: { $set: true } },
      VC59: { configValue: { $set: true } },
      VC61: { configValue: { $set: true } },
    });
    setState({
      configValues: selectAllEIC,
    });
  };

  // Reset EIC Checkboxes
  const handleReset = () => {
    const resetAllEIC = update(state.configValues, {
      VC01: { configValue: { $set: false } },
      VC54: { configValue: { $set: false } },
      VC02: { configValue: { $set: false } },
      VC03: { configValue: { $set: false } },
      VC04: { configValue: { $set: false } },
      VC05: { configValue: { $set: false } },
      VC07: { configValue: { $set: false } },
      VC08: { configValue: { $set: false } },
      VC32: { configValue: { $set: false } },
      VC33: { configValue: { $set: false } },
      VC06: { configValue: { $set: false } },
      VC59: { configValue: { $set: false } },
      VC61: { configValue: { $set: false } },
      VD07: { configValue: { $set: '' } },
      VD08: { configValue: { $set: '' } },
      VD05: { configValue: { $set: '' } },
      VD59: { configValue: { $set: '' } },
    });
    setState({
      configValues: resetAllEIC,
    });
  };

  // Select All Checkboxes for Schedule A Section
  const handleSelectAllScheduleA = () => {
    let selectAllSchA = update(state.configValues, {
      VC09: { configValue: { $set: true } },
      VC10: { configValue: { $set: true } },
      VC11: { configValue: { $set: true } },
      VC12: { configValue: { $set: true } },
      VC13: { configValue: { $set: true } },
      VC14: { configValue: { $set: true } },
      VC15: { configValue: { $set: true } },
      VC16: { configValue: { $set: true } },
      VC17: { configValue: { $set: true } },
      VC44: { configValue: { $set: true } },
      VC55: { configValue: { $set: true } },
      VC56: { configValue: { $set: true } },
      VC58: { configValue: { $set: true } },
    });

    if (payload.season <= 2022) {
      selectAllSchA = update(selectAllSchA, {
        VC15: { configValue: { $set: true } },
      });
    }

    setState({
      configValues: selectAllSchA,
    });
  };

  // Reset Checkboxes for Schedule A Section
  const handleResetScheduleA = () => {
    let resetAllSchA = update(state.configValues, {
      VC09: { configValue: { $set: false } },
      VC10: { configValue: { $set: false } },
      VC11: { configValue: { $set: false } },
      VC12: { configValue: { $set: false } },
      VC13: { configValue: { $set: false } },
      VC14: { configValue: { $set: false } },
      VC15: { configValue: { $set: false } },
      VC16: { configValue: { $set: false } },
      VC17: { configValue: { $set: false } },
      VC44: { configValue: { $set: false } },
      VC55: { configValue: { $set: false } },
      VC56: { configValue: { $set: false } },
      VC58: { configValue: { $set: false } },
      VD01: { configValue: { $set: '' } },
      VD02: { configValue: { $set: '' } },
      VD03: { configValue: { $set: '' } },
      VD04: { configValue: { $set: '' } },
      VD09: { configValue: { $set: '' } },
    });

    if (payload.season <= 2022) {
      resetAllSchA = update(resetAllSchA, {
        VC15: { configValue: { $set: false } },
        VD02: { configValue: { $set: '' } },
      });
    }

    setState({
      configValues: resetAllSchA,
    });
  };

  // Select All Checkboxes Additional Schedules/Forms
  const handleSelectAllAdditionalSch = () => {
    let selectAllAdditional = update(state.configValues, {
      VC18: { configValue: { $set: true } },
      VC40: { configValue: { $set: true } },
      VC67: { configValue: { $set: true } },
      VC60: { configValue: { $set: true } },
      VC37: { configValue: { $set: true } },
      VC38: { configValue: { $set: true } },
      VC53: { configValue: { $set: true } },
      VC51: { configValue: { $set: true } },
      VC52: { configValue: { $set: true } },
      VC65: { configValue: { $set: true } },
      VC26: { configValue: { $set: true } },
      VC45: { configValue: { $set: true } },
      VC24: { configValue: { $set: true } },
      VC69: { configValue: { $set: true } },
      VC73: { configValue: { $set: true } },
    });

    if (payload.season >= 2019) {
      selectAllAdditional = update(selectAllAdditional, {
        VC69: { configValue: { $set: true } },
      });
    }

    if (payload.season <= 2022) {
      selectAllAdditional = update(selectAllAdditional, {
        VC24: { configValue: { $set: true } },
      });
    }

    switch (payload.season) {
      case 2021:
        selectAllAdditional = update(selectAllAdditional, {
          VC74: { configValue: { $set: true } },
          VC76: { configValue: { $set: true } },
        });
        break;
      default:
        break;
    }
    setState({
      configValues: selectAllAdditional,
    });
  };

  // Reset Checkboxes for Additional Schedules/Forms Section
  const handleResetAdditionalSch = () => {
    let resetAllAdditional = update(state.configValues, {
      VC18: { configValue: { $set: false } },
      VC40: { configValue: { $set: false } },
      VC67: { configValue: { $set: false } },
      VC60: { configValue: { $set: false } },
      VC37: { configValue: { $set: false } },
      VC38: { configValue: { $set: false } },
      VC53: { configValue: { $set: false } },
      VC51: { configValue: { $set: false } },
      VC52: { configValue: { $set: false } },
      VC65: { configValue: { $set: false } },
      VC26: { configValue: { $set: false } },
      VC45: { configValue: { $set: false } },
      VC24: { configValue: { $set: false } },
      VC69: { configValue: { $set: false } },
      VC73: { configValue: { $set: false } },
      VD67: { configValue: { $set: '' } },
      VD10: { configValue: { $set: '' } },
    });

    if (payload.season >= 2019) {
      resetAllAdditional = update(resetAllAdditional, {
        VC69: { configValue: { $set: false } },
      });
    }

    if (payload.season <= 2022) {
      resetAllAdditional = update(resetAllAdditional, {
        VC24: { configValue: { $set: false } },
      });
    }

    switch (payload.season) {
      case 2021:
        resetAllAdditional = update(resetAllAdditional, {
          VC74: { configValue: { $set: false } },
          VC76: { configValue: { $set: false } },
        });
        break;
      default:
        break;
    }

    setState({
      configValues: resetAllAdditional,
    });
  };

  // Select All Checkboxes for American Opportunity Credit Section
  const handleSelectAllOpportunityCredit = () => {
    const selectAllOpportunity = update(state.configValues, {
      VC19: { configValue: { $set: true } },
      VC20: { configValue: { $set: true } },
      VC21: { configValue: { $set: true } },
      VC22: { configValue: { $set: true } },
      VC23: { configValue: { $set: true } },
      VC39: { configValue: { $set: true } },
      VC41: { configValue: { $set: true } },
    });
    setState({
      configValues: selectAllOpportunity,
    });
  };

  // Reset Checkboxes for American Opportunity Credit Section
  const handleResetOpportunityCredit = () => {
    const resetAllOpportunity = update(state.configValues, {
      VC19: { configValue: { $set: false } },
      VC20: { configValue: { $set: false } },
      VC21: { configValue: { $set: false } },
      VC22: { configValue: { $set: false } },
      VC23: { configValue: { $set: false } },
      VC39: { configValue: { $set: false } },
      VC41: { configValue: { $set: false } },
      VC49: { configValue: { $set: '' } },
      VC50: { configValue: { $set: '' } },
    });
    setState({
      configValues: resetAllOpportunity,
    });
  };

  // Select All Checkboxes for tax alerts section
  const handleSelectAllTaxAlerts = () => {
    let selectAllTax = update(state.configValues, {
      VC27: { configValue: { $set: true } },
      VC46: { configValue: { $set: true } },
      VC42: { configValue: { $set: true } },
      VC80: { configValue: { $set: true } },
      VC81: { configValue: { $set: true } },
      VC48: { configValue: { $set: true } },
      VC43: { configValue: { $set: true } },
      INSC: { configValue: { $set: true } },
      INSV: { configValue: { $set: true } },
      RLIX: { configValue: { $set: true } },
      VC62: { configValue: { $set: true } },
      VC63: { configValue: { $set: true } },
      VC64: { configValue: { $set: true } },
      VC68: { configValue: { $set: true } },
    });

    if (payload.season === 2021) {
      selectAllTax = update(selectAllTax, {
        VC75: { configValue: { $set: true } },
      });
    }

    setState({
      configValues: selectAllTax,
    });
  };

  // Reset Checkboxes for tax alerts section
  const handleResetTaxAlerts = () => {
    let resetAllTax = update(state.configValues, {
      VC27: { configValue: { $set: false } },
      VC46: { configValue: { $set: false } },
      VC42: { configValue: { $set: false } },
      VC80: { configValue: { $set: false } },
      VC81: { configValue: { $set: false } },
      VC48: { configValue: { $set: false } },
      VC43: { configValue: { $set: false } },
      INSC: { configValue: { $set: false } },
      INSV: { configValue: { $set: false } },
      RLIX: { configValue: { $set: false } },
      VC62: { configValue: { $set: false } },
      VC63: { configValue: { $set: false } },
      VC64: { configValue: { $set: false } },
      VC68: { configValue: { $set: false } },
      VC28: { configValue: { $set: '' } },
      VC47: { configValue: { $set: '' } },
      RLIM: { configValue: { $set: '' } },
    });

    if (payload.season === 2021) {
      resetAllTax = update(resetAllTax, {
        VC75: { configValue: { $set: false } },
      });
    }

    setState({
      configValues: resetAllTax,
    });
  };

  // Select All Checkboxes for HOH Section
  const handleSelectAllHOH = () => {
    const selectAllHOH = update(state.configValues, {
      VC57: { configValue: { $set: true } },
      VC29: { configValue: { $set: true } },
      VC30: { configValue: { $set: true } },
      VC31: { configValue: { $set: true } },
      VC34: { configValue: { $set: true } },
      VC35: { configValue: { $set: true } },
      VC36: { configValue: { $set: true } },
    });
    setState({
      configValues: selectAllHOH,
    });
  };

  // Reset Checkboxes for HOH Section
  const handleResetHOH = () => {
    const resetAllHOH = update(state.configValues, {
      VC57: { configValue: { $set: false } },
      VC29: { configValue: { $set: false } },
      VC30: { configValue: { $set: false } },
      VC31: { configValue: { $set: false } },
      VC34: { configValue: { $set: false } },
      VC35: { configValue: { $set: false } },
      VC36: { configValue: { $set: false } },
      VD06: { configValue: { $set: '' } },
    });
    setState({
      configValues: resetAllHOH,
    });
  };

  const toggleConfirmCancel = toggle => {
    setState({
      confirmCancel: toggle,
    });
  };

  const handleSubmit = async gotoNext => {
    const newAuditConfig = [];
    const config = state.configValues;
    // Parse config into an array with configValues for checkboxes defined as "0" or "1"
    Object.keys(config).forEach(key => {
      const configItem = config[key];
      switch (configItem.configType) {
        case 3:
          newAuditConfig.push({
            configID: configItem.configID,
            configValue: configItem.configValue ? '1' : '0',
          });
          break;
        case 5:
          newAuditConfig.push({
            configID: configItem.configID,
            configValue: configItem.configValue,
          });
          break;
        default:
          break;
      }
    });
    try {
      await XlinkAPI.updateAuditSetup(newAuditConfig, props.loginID);
      if (gotoNext) {
        props.loadSetupComponent(EXTENDED_SETUP_PAGES.CLIENT_LETTERS.NAME);
      } else {
        toggleSuccessSnackbar(true);
      }
    } catch (error) {
      ErrorHelpers.handleError('Error Updating Audit Configuration', error);
    }
  };

  const toggleSuccessSnackbar = toggle => {
    setState({
      snackbarOpen: toggle,
    });
  };

  const buildHeader = (headerName, handleReset, handleSelectAll) => {
    return (
      <div className="audit-config-form-section-header-buttons">
        {state.expandedHeaders.indexOf(headerName) !== -1 ? (
          <Button
            id={`btnResetAudit-${headerName}`}
            onClick={handleReset}
            color="primary"
            style={{
              border: 'none',
              width: '10vw',
              height: '2vh',
              textTransform: 'capitalize',
              fontFamily: 'Roboto',
              fontSize: '14px',
              borderRadius: '4px',
              padding: '0',
              marginRight: '0.25vw',
            }}
          >
            Reset
          </Button>
        ) : (
          ''
        )}
        {state.expandedHeaders.indexOf(headerName) !== -1 ? (
          <Button
            id={`btnSelectAllAudit-${headerName}`}
            onClick={handleSelectAll}
            color="primary"
            style={{ width: '10vw', height: '2vh', padding: '0' }}
          >
            Select All
          </Button>
        ) : (
          ''
        )}
      </div>
    );
  };

  return (
    <div className="container-fixed" style={{ marginTop: '2.5rem' }}>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={state.snackbarOpen}
        autoHideDuration={2500}
        onClose={() => toggleSuccessSnackbar(false)}
        id="snkbarconfsaved"
        message={<span id="success-message">Configuration Saved</span>}
      />
      <AccessControl
        requiredAction="read"
        accessLevel={ACCESS_LEVEL.EDIT_DUE_DILIGENCE}
        disableOnFalse={true}
      >
        <Paper className="audit-config">
          <AccessControl
            requiredAction="write"
            accessLevel={ACCESS_LEVEL.EDIT_DUE_DILIGENCE}
            disableOnFalse={true}
          >
            <form className="audit-config-form">
              <div className="audit-config-form-content">
                <div style={{ textAlign: 'left', paddingTop: '1vh' }}>
                  <div style={{ float: 'none' }}>
                    <span
                      style={{
                        fontFamily: 'Roboto',
                        fontSize: '21px',
                        letterSpacing: '0.14px',
                        color: '#1A173B',
                        marginLeft: '0.3em',
                      }}
                    >
                      Due Diligence
                    </span>
                  </div>
                  <Collapse
                    in={state.expandedAuditConfigure}
                    timeout="auto"
                    unmountOnExit
                    style={{ display: 'none' }}
                  >
                    <br />
                    <div className="audit-config-form-sub-section-header">Authorizations</div>
                    <FormControl>
                      <div style={{ float: 'left' }}>
                        <InputLabel
                          style={{ marginLeft: '2vw' }}
                          required={false}
                          htmlFor="txtSecureOffsiteStorageAudit"
                          shrink
                        >
                          Secure Offsite Storage
                        </InputLabel>
                        <Input
                          id="txtSecureOffsiteStorageAudit"
                          name="secureOffsiteStorage"
                          value={state.secureOffsiteStorage || ''}
                          onChange={handleInputChange}
                          style={{
                            width: '20vw',
                            height: '3.5vh',
                            marginLeft: '2vw',
                            marginRight: '2vw',
                          }}
                          disableUnderline
                        />
                      </div>
                    </FormControl>
                    <FormControl>
                      <div>
                        <InputLabel
                          style={{ marginLeft: '2vw' }}
                          required={false}
                          htmlFor="txtTextLinkPlusAudit"
                          shrink
                        >
                          TextLink Plus
                        </InputLabel>
                        <Input
                          id="txtTextLinkPlusAudit"
                          name="textLinkPlus"
                          value={state.textLinkPlus || ''}
                          onChange={handleInputChange}
                          style={{
                            width: '20vw',
                            height: '3.5vh',
                            marginLeft: '2vw',
                            marginRight: '2vw',
                          }}
                          disableUnderline
                        />
                      </div>
                    </FormControl>
                  </Collapse>
                  <br />
                  <div className="audit-config-form-divider">
                    <Divider style={{ marginLeft: '2vw', marginRight: '1.5vw' }} />
                  </div>

                  <div className="audit-config-form-section-header">
                    <IconButton
                      id="icobtnExpandEICAudit"
                      onClick={() => handleClickOnCollapse('EIC')}
                      aria-expanded={state.expandedHeaders.indexOf('EIC') !== -1}
                    >
                      {state.expandedHeaders.indexOf('EIC') !== -1 ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )}
                    </IconButton>
                    <span
                      onClick={() => handleClickOnCollapse('EIC')}
                      className="audit-config-form-section-header-label"
                    >
                      Earned Income Credit
                    </span>
                  </div>
                </div>
                {buildHeader('EIC', handleReset, handleSelectAll)}
              </div>
              <br />
              <Collapse
                in={state.expandedHeaders.indexOf('EIC') !== -1}
                timeout="auto"
                unmountOnExit
              >
                <div className="audit-config-form-sub-section-header">Relationship Test</div>
                <FormControl>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="Prove relationship when Earned Income Credit (EIC) has been calculated, and child is not a son or daughter."
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          color="primary"
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkProveRelationshipAudit"
                          name="VC01"
                          checked={
                            state.configValues.VC01 ? state.configValues.VC01.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="Earned Income Credit (EIC) has been calculated and child is a son or daughter. Filing status is either Single, HOH, or MFS.
                                Explain why only taxpayer is claiming the child and why the other parent is not."
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkChildEICAudit"
                          name="VC54"
                          checked={
                            state.configValues.VC54 ? state.configValues.VC54.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                  <div className="audit-config-form-sub-section-header">Age Test</div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="Earned Income Credit (EIC) has been calculated. When son or daughter is between ages 19 and 24
                                at the end of the tax year and a full-time student for at least five months of the year and not
                                permanently and totally disabled at any time during the year, verify document to prove full-time student status."
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkStudentEICAudit"
                          name="VC02"
                          checked={
                            state.configValues.VC02 ? state.configValues.VC02.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="Verify disability if Earned Income Credit (EIC) has been calculated, and child is age 19 or over and permanently and totally disabled at any time during the tax year."
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkDisabilityEICAudit"
                          name="VC03"
                          checked={
                            state.configValues.VC03 ? state.configValues.VC03.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                  <div className="audit-config-form-sub-section-header">Residency Test</div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="Earned Income Credit (EIC) has been calculated. Document that child has lived with taxpayer for more than half of the tax year."
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkResidencyTestAudit"
                          name="VC04"
                          checked={
                            state.configValues.VC04 ? state.configValues.VC04.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                  <div className="audit-config-form-sub-section-header">Other</div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="Earned Income Credit (EIC) has been calculated and child's birth date was not in the current tax year.
                                Child was not present for EIC purposes on last year's return for a returning client.
                                Document why taxpayer is claiming child this year but did not last year."
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkOtherCheckboxOneAudit"
                          name="VC05"
                          checked={
                            state.configValues.VC05 ? state.configValues.VC05.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="Earned Income Credit (EIC) has been calculated, and the age difference between the Taxpayer and the oldest
                                qualifying child is less than 15 years. Explain why the age difference is small."
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkOtherCheckboxTwoAudit"
                          name="VC07"
                          checked={
                            state.configValues.VC07 ? state.configValues.VC07.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="Earned Income Credit (EIC) has been calculated, and the taxpayer status is 'Single' or 'Head of Household'
                                and is under the age of 19. Explain how the taxpayer is not qualifying child of another person."
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkOtherCheckboxThreeAudit"
                          name="VC08"
                          checked={
                            state.configValues.VC08 ? state.configValues.VC08.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="The tax return has calculated over $"
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkOtherCheckboxFourAudit"
                          name="VC32"
                          checked={
                            state.configValues.VC32 ? state.configValues.VC32.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                    <FormControl>
                      <FormControlLabel
                        label="of EIC and the return includes a Schedule C. Substantiate that taxpayer has a valid business."
                        control={
                          <Input
                            classes={{
                              root: classes.auditConfigFormSubSectionItemInput,
                            }}
                            id="txtOtherInputOneAudit"
                            name="VD07"
                            value={
                              typeof state.configValues.VD07 !== 'undefined'
                                ? state.configValues.VD07.configValue
                                : ''
                            }
                            onChange={handleInputChange}
                            inputProps={{ autoComplete: 'off' }}
                            disableUnderline
                          />
                        }
                      />
                    </FormControl>
                  </div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="The tax return has calculated over $"
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkOtherCheckboxFiveAudit"
                          name="VC33"
                          checked={
                            state.configValues.VC33 ? state.configValues.VC33.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                    <FormControlLabel
                      label="of EIC and the return includes a Schedule C. Substantiate the business expenses."
                      control={
                        <Input
                          classes={{
                            root: classes.auditConfigFormSubSectionItemInput,
                          }}
                          id="txtOtherInputTwoAudit"
                          name="VD08"
                          value={state.configValues.VD08 ? state.configValues.VD08.configValue : ''}
                          onChange={handleInputChange}
                          disableUnderline
                        />
                      }
                    />
                  </div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="Earned Income Credit (EIC) is greater than $"
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkOtherCheckboxSixAudit"
                          name="VC06"
                          checked={
                            state.configValues.VC06 ? state.configValues.VC06.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                    <FormControl>
                      <FormControlLabel
                        label="Tax return includes a Schedule C with no expenses. Substantiate there are no expenses."
                        control={
                          <Input
                            classes={{
                              root: classes.auditConfigFormSubSectionItemInput,
                            }}
                            id="chkOtherInputThreeAudit"
                            name="VD05"
                            value={
                              state.configValues.VD05 ? state.configValues.VD05.configValue : ''
                            }
                            onChange={handleInputChange}
                            inputProps={{ autoComplete: 'off' }}
                            disableUnderline
                          />
                        }
                      />
                    </FormControl>
                  </div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="Earned Income Credit (EIC) has calculated over $"
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkOtherCheckboxSevenAudit"
                          name="VC59"
                          checked={
                            state.configValues.VC59 ? state.configValues.VC59.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                    <FormControl>
                      <FormControlLabel
                        label="and a Schedule C loss. Substantiate the expenses that are creating the loss."
                        control={
                          <Input
                            classes={{
                              root: classes.auditConfigFormSubSectionItemInput,
                            }}
                            name="VD59"
                            value={
                              state.configValues.VD59 ? state.configValues.VD59.configValue : ''
                            }
                            onChange={handleInputChange}
                            inputProps={{ autoComplete: 'off' }}
                            disableUnderline
                          />
                        }
                      />
                    </FormControl>
                  </div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="Preparer flagged as self-preparer on return. Verify the preparer is indeed a self-preparer and not a paid preparer,
                                as a self-preparer return will not attach form 8867 (due diligence) for EITC, CTC, and AOTC purposes."
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkOtherCheckboxEightAudit"
                          name="VC61"
                          checked={
                            state.configValues.VC61 ? state.configValues.VC61.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                </FormControl>
              </Collapse>
              <br />
              <div className="audit-config-form-divider">
                <Divider style={{ marginLeft: '2vw', marginRight: '1.5vw' }} />
              </div>

              <div className="audit-config-form-section-header">
                <IconButton
                  id="idobtnExpandScheduleAAudit"
                  onClick={() => handleClickOnCollapse('SCHA')}
                  aria-expanded={state.expandedHeaders.indexOf('SCHA') !== -1}
                >
                  {state.expandedHeaders.indexOf('SCHA') !== -1 ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
                <span
                  onClick={() => handleClickOnCollapse('SCHA')}
                  className="audit-config-form-section-header-label"
                >
                  Schedule A
                </span>
              </div>
              {buildHeader('SCHA', handleResetScheduleA, handleSelectAllScheduleA)}
              <br />
              <Collapse
                in={state.expandedHeaders.indexOf('SCHA') !== -1}
                timeout="auto"
                unmountOnExit
              >
                <div className="audit-config-form-sub-section-header">Monetary Donations</div>
                <FormControl>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="Cash contributions entered on Schedule A"
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkCashContributionsAudit"
                          name="VC09"
                          checked={
                            state.configValues.VC09 ? state.configValues.VC09.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                  <div className="audit-config-form-sub-section-header">Non-Cash Donations</div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="Non-Cash donations entered on Schedule A which are less than $250"
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkNonCashLessThan250Audit"
                          name="VC10"
                          checked={
                            state.configValues.VC10 ? state.configValues.VC10.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="Non-Cash donations entered on Schedule A which total between $250 and $5,000"
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkNonCashLessThan5000Audit"
                          name="VC11"
                          checked={
                            state.configValues.VC11 ? state.configValues.VC11.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="Non-Cash donations entered on Schedule A which total between $5,000 and $500,000"
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkNonCashLessThan500000Audit"
                          name="VC12"
                          checked={
                            state.configValues.VC12 ? state.configValues.VC12.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="Non-Cash donations entered on Schedule A which total more than $500,000"
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkNonCashMoreThan500000Audit"
                          name="VC13"
                          checked={
                            state.configValues.VC13 ? state.configValues.VC13.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                  <div className="audit-config-form-sub-section-header">All Donations</div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      label="Total of donations are more than"
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkAllDonationsAudit"
                          name="VC14"
                          checked={
                            state.configValues.VC14 ? state.configValues.VC14.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                    <FormControl>
                      <FormControlLabel
                        label="percentage of AGI."
                        control={
                          <Input
                            classes={{
                              root: classes.auditConfigFormSubSectionItemInput,
                            }}
                            id="txtDonationPercentageAGIAudit"
                            name="VD01"
                            value={
                              state.configValues.VD01 ? state.configValues.VD01.configValue : ''
                            }
                            onChange={handleInputChange}
                            inputProps={{ autoComplete: 'off' }}
                            disableUnderline
                          />
                        }
                      />
                    </FormControl>
                  </div>
                  {payload.season <= 2022 && (
                    <Fragment>
                      <div className="audit-config-form-sub-section-header">
                        Unreimbursed Employee Expenses
                      </div>
                      <div className="audit-config-form-sub-section-item">
                        <FormControlLabel
                          classes={{
                            root: classes.auditConfigFormSubSectionItemLabel,
                          }}
                          label="Total expenses entered on Schedule A are more than"
                          control={
                            <Checkbox
                              color="primary"
                              classes={{
                                root: classes.auditConfigFormSubSectionItemCheckbox,
                              }}
                              icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                              checkedIcon={<CheckBoxIcon color="primary" />}
                              id="chkUnreimbursedExpensesAudit"
                              name="VC15"
                              checked={
                                state.configValues.VC15
                                  ? state.configValues.VC15.configValue
                                  : false
                              }
                              onChange={handleCheckbox}
                            />
                          }
                        />
                        <FormControl>
                          <FormControlLabel
                            label="percentage of return's AGI."
                            control={
                              <Input
                                classes={{
                                  root: classes.auditConfigFormSubSectionItemInput,
                                }}
                                id="chkExpensesPercentageAGIAudit"
                                name="VD02"
                                value={
                                  state.configValues.VD02 ? state.configValues.VD02.configValue : ''
                                }
                                onChange={handleInputChange}
                                inputProps={{ autoComplete: 'off' }}
                                disableUnderline
                              />
                            }
                          />
                        </FormControl>
                      </div>
                    </Fragment>
                  )}
                  <div className="audit-config-form-sub-section-header">Schedule A Deductions</div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="Total itemized deductions exceed"
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkTotalItemizedExceedPercentageAudit"
                          name="VC16"
                          checked={
                            state.configValues.VC16 ? state.configValues.VC16.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                    <FormControl>
                      <FormControlLabel
                        label="percentage of income."
                        control={
                          <Input
                            classes={{
                              root: classes.auditConfigFormSubSectionItemInput,
                            }}
                            id="txtItemizedDeductionsPercentageAudit"
                            name="VD03"
                            value={
                              state.configValues.VD03 ? state.configValues.VD03.configValue : ''
                            }
                            onChange={handleInputChange}
                            inputProps={{ autoComplete: 'off' }}
                            disableUnderline
                          />
                        }
                      />
                    </FormControl>
                  </div>
                  <div style={{ marginLeft: '4vw', marginBottom: '1vh' }}>
                    <span
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                    >
                      OR
                    </span>
                  </div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="Total itemized deductions exceed $"
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkTotalItemizedExceedAmountAudit"
                          name="VC17"
                          checked={
                            state.configValues.VC17 ? state.configValues.VC17.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                    <FormControl>
                      <FormControlLabel
                        label="of income."
                        control={
                          <Input
                            classes={{
                              root: classes.auditConfigFormSubSectionItemInput,
                            }}
                            id="txtItemizedDeductionsAmountAudit"
                            name="VD04"
                            value={
                              state.configValues.VD04 ? state.configValues.VD04.configValue : ''
                            }
                            onChange={handleInputChange}
                            inputProps={{ autoComplete: 'off' }}
                            disableUnderline
                          />
                        }
                      />
                    </FormControl>
                  </div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="Verify for missing deductions"
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkVerifyMissingDeductionsAudit"
                          name="VC44"
                          checked={
                            state.configValues.VC44 ? state.configValues.VC44.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="Verify the total real estate taxes paid on SCH A when total real estate tax reported is less than real estate tax(es) reported on Form 8829 (home office)
                        plus tax(es) reported on SCH E."
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkVerifyTotalRealStateTaxesAudit"
                          name="VC55"
                          checked={
                            state.configValues.VC55 ? state.configValues.VC55.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="Verify the total mortgage interest paid on SCH A when total mortgage interest reported is less than
                                mortgage interest reported on Form 8829 (home office) plus mortgage interest reported on SCH E."
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkVerifyTotalMortgageAudit"
                          name="VC56"
                          checked={
                            state.configValues.VC56 ? state.configValues.VC56.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      marginLeft: '3vw',
                      marginBottom: '0.5vh',
                      marginTop: '1vh',
                    }}
                  >
                    <FormControlLabel
                      label="Mortgage interest exceeds $"
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkMortgageInterestExceedsAudit"
                          name="VC58"
                          checked={
                            state.configValues.VC58 ? state.configValues.VC58.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                    <FormControl>
                      <Input
                        classes={{
                          root: classes.auditConfigFormSubSectionItemInput,
                        }}
                        id="txtMortgageInterestAmountAudit"
                        name="VD09"
                        value={state.configValues.VD09 ? state.configValues.VD09.configValue : ''}
                        onChange={handleInputChange}
                        inputProps={{ autoComplete: 'off' }}
                        disableUnderline
                      />
                    </FormControl>
                  </div>
                </FormControl>
              </Collapse>
              <br />
              <div className="audit-config-form-divider">
                <Divider style={{ marginLeft: '2vw', marginRight: '1.5vw' }} />
              </div>
              <div className="audit-config-form-section-header">
                <IconButton
                  id="icobtnExpandAdditionalSchAudit"
                  onClick={() => handleClickOnCollapse('ADD')}
                  aria-expanded={state.expandedHeaders.indexOf('ADD') !== -1}
                >
                  {state.expandedHeaders.indexOf('ADD') !== -1 ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
                <span
                  onClick={() => handleClickOnCollapse('ADD')}
                  className="audit-config-form-section-header-label"
                >
                  Additional Schedules/Forms
                </span>
              </div>
              {buildHeader('ADD', handleResetAdditionalSch, handleSelectAllAdditionalSch)}
              <br />
              <Collapse
                in={state.expandedHeaders.indexOf('ADD') !== -1}
                timeout="auto"
                unmountOnExit
              >
                <div className="audit-config-form-sub-section-header">Attached Schedules/Forms</div>
                <FormControl>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="When the return includes a Schedule C, E, F, or Form 4835. describe the documentation for items such as income, expenses mileage, and depreciation."
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkDescribeDocumentationAudit"
                          name="VC18"
                          checked={
                            state.configValues.VC18 ? state.configValues.VC18.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="Print Business Activities Questionnaire used to substantiate amounts reported on Schedule C, E, F and Form 4835."
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkPrintBussinessActivityQuestionsAudit"
                          name="VC40"
                          checked={
                            state.configValues.VC40 ? state.configValues.VC40.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                  {payload.season >= 2019 && (
                    <div className="audit-config-form-sub-section-item">
                      <FormControlLabel
                        label="Schedules 1 through 3 were Auto Added; Verify that schedules may be charged for without getting E-Filed"
                        classes={{
                          root: classes.auditConfigFormSubSectionItemLabel,
                        }}
                        control={
                          <Checkbox
                            color="primary"
                            style={{
                              width: 17,
                              height: 16,
                              marginRight: '0.5vw',
                            }}
                            icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                            checkedIcon={<CheckBoxIcon color="primary" />}
                            id="chkPrintBussinessActivityQuestionsAudit"
                            name="VC69"
                            checked={
                              state.configValues.VC69 ? state.configValues.VC69.configValue : false
                            }
                            onChange={handleCheckbox}
                          />
                        }
                      />
                    </div>
                  )}
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="Enable Schedule C due diligence form"
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="EnableSchCDueDiligenceFormAudit"
                          name="VC73"
                          checked={
                            state.configValues.VC73 ? state.configValues.VC73.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                  <div className="audit-config-form-sub-section-header">Form SSA-1099</div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="If total Medicare premiums reported on Form SSA-1099 is more than"
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkVerifyPremiumAmountAudit"
                          name="VC67"
                          checked={
                            state.configValues.VC67 ? state.configValues.VC67.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                    <FormControl>
                      <FormControlLabel
                        label="percent of net benefits (box 5), verify premium amount is correct."
                        control={
                          <Input
                            classes={{
                              root: classes.auditConfigFormSubSectionItemInput,
                            }}
                            id="txtMedicarePercentAudit"
                            name="VD67"
                            value={
                              state.configValues.VD67 ? state.configValues.VD67.configValue : ''
                            }
                            onChange={handleInputChange}
                            inputProps={{ autoComplete: 'off' }}
                            disableUnderline
                          />
                        }
                      />
                    </FormControl>
                  </div>
                  <div className="audit-config-form-sub-section-header">
                    Form RRB-1099 and Form RRB-1099-R
                  </div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="When the return includes any Form RRB-1099, verify that the data is coming from a Form RRB-1099 rather than a Form RRB-1099-R."
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkVerifyDataAudit"
                          name="VC60"
                          checked={
                            state.configValues.VC60 ? state.configValues.VC60.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                  <div className="audit-config-form-sub-section-header">
                    Form 2441 - Child and Dependent Care Expenses
                  </div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="Child/Dependent care credit has been calculated and taxpayer is filing MFS. Describe the
                                documentation that validates the spouse did not live with the taxpayer for the last 6 months."
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkValidateSpouseStatusAudit"
                          name="VC37"
                          checked={
                            state.configValues.VC37 ? state.configValues.VC37.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="Child/Dependent care credit has been calculated and taxpayer is filing MFS. Describe the documentation that
                                validates the taxpayer provided over half the cost of keeping up the home."
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkTaxpayerProvidedHalfCostAudit01"
                          name="VC38"
                          checked={
                            state.configValues.VC38 ? state.configValues.VC38.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                  <div className="audit-config-form-sub-section-header">Form W2</div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="When State Abbr. (box 15) is populated but State return is missing; Verify if the State Package needs to be added to the return."
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkValidateSpouseStatusAudit"
                          name="VC53"
                          checked={
                            state.configValues.VC53 ? state.configValues.VC53.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="When State Abbr. (box 15) is missing, verify if a State abbr. needs to be reported in box 15."
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkTaxpayerProvidedHalfCostAudit02"
                          name="VC51"
                          checked={
                            state.configValues.VC51 ? state.configValues.VC51.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="When State Withholdings (box 17) has no value but State Abbr. (box 15) is populated; Verify if there are any
                                state withholdings to be reported in box 17."
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkTaxpayerProvidedHalfCostAudit03"
                          name="VC52"
                          checked={
                            state.configValues.VC52 ? state.configValues.VC52.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                  <div className="audit-config-form-sub-section-header">Form 1040ES</div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="Auto Add Form 1040ES when the balance due is greater than"
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkAutoAdd1040ESAudit"
                          name="VC65"
                          checked={
                            state.configValues.VC65 ? state.configValues.VC65.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                    <FormControl>
                      <Input
                        classes={{
                          root: classes.auditConfigFormSubSectionItemInput,
                        }}
                        id="txtBalanceGreaterThanFor1040ESAudit"
                        name="VD10"
                        value={
                          state.configValues.VD10 ? state.configValues.VD10.configValue : false
                        }
                        onChange={handleInputChange}
                        disableUnderline
                      />
                    </FormControl>
                  </div>
                  <div className="audit-config-form-sub-section-header">Form 8880</div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="The return may qualify for the Saver's Credit. Verify with the tax payer/spouse if there were any contributions made to their retirement plans."
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkContributionRetirement8880Audit"
                          name="VC26"
                          checked={
                            state.configValues.VC26 ? state.configValues.VC26.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="Return contains a Saver's Credit. Verify with taxpayer/spouse if there were any withdrawals from a retirement plan over the last 3 years."
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkWithdrawal8880Audit"
                          name="VC45"
                          checked={
                            state.configValues.VC45 ? state.configValues.VC45.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                  {payload.season <= 2022 && (
                    <Fragment>
                      <div className="audit-config-form-sub-section-header">
                        Form 8885 - Health Coverage Tax Credit
                      </div>
                      <div className="audit-config-form-sub-section-item">
                        <FormControlLabel
                          label="Verify that the return has verifiable proof of qualified health insurance for HCTC and proof of premiums paid."
                          classes={{
                            root: classes.auditConfigFormSubSectionItemLabel,
                          }}
                          control={
                            <Checkbox
                              color="primary"
                              classes={{
                                root: classes.auditConfigFormSubSectionItemCheckbox,
                              }}
                              icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                              checkedIcon={<CheckBoxIcon color="primary" />}
                              id="chkHealthInsurance8885Audit"
                              name="VC24"
                              checked={
                                state.configValues.VC24
                                  ? state.configValues.VC24.configValue
                                  : false
                              }
                              onChange={handleCheckbox}
                            />
                          }
                        />
                      </div>
                    </Fragment>
                  )}
                  {payload.season === 2021 && (
                    <Fragment>
                      <div className="audit-config-form-sub-section-header">Form 1099-R</div>
                      <div className="audit-config-form-sub-section-item">
                        <FormControlLabel
                          label="Validate if distribution was related to a COVID-19 or Federal disaster and Box 7 contains Code 1."
                          classes={{
                            root: classes.auditConfigFormSubSectionItemLabel,
                          }}
                          control={
                            <Checkbox
                              color="primary"
                              classes={{
                                root: classes.auditConfigFormSubSectionItemCheckbox,
                              }}
                              icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                              checkedIcon={<CheckBoxIcon color="primary" />}
                              id="chkCovid1099RAudit"
                              name="VC76"
                              checked={
                                state.configValues.VC76
                                  ? state.configValues.VC76.configValue
                                  : false
                              }
                              onChange={handleCheckbox}
                            />
                          }
                        />
                      </div>
                      <div className="audit-config-form-sub-section-header">Form 7202</div>
                      <div className="audit-config-form-sub-section-item">
                        <FormControlLabel
                          label="Verify with taxpayer that Form 7202 (Credits for sick/family leave for self-employed individuals related to COVID-19) is not needed when return has a Schedule C with profits."
                          classes={{
                            root: classes.auditConfigFormSubSectionItemLabel,
                          }}
                          control={
                            <Checkbox
                              color="primary"
                              classes={{
                                root: classes.auditConfigFormSubSectionItemCheckbox,
                              }}
                              icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                              checkedIcon={<CheckBoxIcon color="primary" />}
                              id="chkCovid7202Audit"
                              name="VC74"
                              checked={
                                state.configValues.VC74
                                  ? state.configValues.VC74.configValue
                                  : false
                              }
                              onChange={handleCheckbox}
                            />
                          }
                        />
                      </div>
                    </Fragment>
                  )}
                  <br />
                </FormControl>
              </Collapse>

              <br />
              <div className="audit-config-form-divider">
                <Divider style={{ marginLeft: '2vw', marginRight: '1.5vw' }} />
              </div>

              <div className="audit-config-form-section-header">
                <IconButton
                  id="icobtnExpandAmericanOpportunityAudit"
                  onClick={() => handleClickOnCollapse('OPP')}
                  aria-expanded={state.expandedHeaders.indexOf('OPP') !== -1}
                >
                  {state.expandedHeaders.indexOf('OPP') !== -1 ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
                <span
                  onClick={() => handleClickOnCollapse('OPP')}
                  className="audit-config-form-section-header-label"
                >
                  American Opportunity Credit
                </span>
              </div>
              {buildHeader('OPP', handleResetOpportunityCredit, handleSelectAllOpportunityCredit)}
              <br />
              <Collapse
                in={state.expandedHeaders.indexOf('OPP') !== -1}
                timeout="auto"
                unmountOnExit
              >
                <div className="audit-config-form-sub-section-header">
                  When the return includes the American Opportunity Credit:
                </div>
                <FormControl>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="Verify that all students related to this credit do not have a prior felony drug conviction."
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkNoDrugConvictionAudit"
                          name="VC19"
                          checked={
                            state.configValues.VC19 ? state.configValues.VC19.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="Verify on form 1099-T that student is not a graduate student (box 9 checked)."
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkNoGraduateAudit"
                          name="VC20"
                          checked={
                            state.configValues.VC20 ? state.configValues.VC20.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="Student is under age"
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkStudentAgeAudit"
                          name="VC21"
                          checked={
                            state.configValues.VC21 ? state.configValues.VC21.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                    <FormControl>
                      <FormControlLabel
                        label="or over age"
                        control={
                          <Input
                            classes={{
                              root: classes.auditConfigFormSubSectionItemInput,
                            }}
                            id="txtUnderAgeAudit"
                            name="VC49"
                            value={
                              state.configValues.VC49 ? state.configValues.VC49.configValue : ''
                            }
                            onChange={handleInputChange}
                            inputProps={{ autoComplete: 'off' }}
                            disableUnderline
                          />
                        }
                      />
                    </FormControl>
                    <FormControl>
                      <FormControlLabel
                        label="Verify that the student actually went to school."
                        control={
                          <Input
                            classes={{
                              root: classes.auditConfigFormSubSectionItemInput,
                            }}
                            id="txtOverAgeAudit"
                            name="VC50"
                            value={
                              state.configValues.VC50 ? state.configValues.VC50.configValue : ''
                            }
                            onChange={handleInputChange}
                            inputProps={{ autoComplete: 'off' }}
                            disableUnderline
                          />
                        }
                      />
                    </FormControl>
                  </div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="Verify student went to a qualified educational institution."
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkQualifiedInstitutionAudit"
                          name="VC22"
                          checked={
                            state.configValues.VC22 ? state.configValues.VC22.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="Verify student(s) received a 1098-T."
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkReceived1098TAudit"
                          name="VC23"
                          checked={
                            state.configValues.VC23 ? state.configValues.VC23.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="Verify student went to school at least half time."
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkSchoolHalfTimeAudit"
                          name="VC39"
                          checked={
                            state.configValues.VC39 ? state.configValues.VC39.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="Verify that the dependent student did not claim this credit on another return."
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkClaimMultipleReturnsAudit"
                          name="VC41"
                          checked={
                            state.configValues.VC41 ? state.configValues.VC41.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                </FormControl>
              </Collapse>
              <br />

              <div className="audit-config-form-divider">
                <Divider style={{ marginLeft: '2vw', marginRight: '1.5vw' }} />
              </div>

              <div className="audit-config-form-section-header">
                <IconButton
                  id="icobtnExpandTaxAlertsAudit"
                  onClick={() => handleClickOnCollapse('TAX')}
                  aria-expanded={state.expandedHeaders.indexOf('TAX') !== -1}
                >
                  {state.expandedHeaders.indexOf('TAX') !== -1 ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
                <span
                  onClick={() => handleClickOnCollapse('TAX')}
                  className="audit-config-form-section-header-label"
                >
                  Tax Alerts
                </span>
              </div>
              {buildHeader('TAX', handleResetTaxAlerts, handleSelectAllTaxAlerts)}
              <br />
              <Collapse
                in={state.expandedHeaders.indexOf('TAX') !== -1}
                timeout="auto"
                unmountOnExit
              >
                {(payload.season === 2021 || payload.season === 2022) && (
                  <Fragment>
                    <div className="audit-config-form-sub-section-header">
                      Charitable Cash Donations
                    </div>
                    <div className="audit-config-form-sub-section-item">
                      <FormControlLabel
                        label="Verify with taxpayer if they made charitable cash donations (up to $300) if not itemizing (no Sch. A) and there is taxable income."
                        classes={{
                          root: classes.auditConfigFormSubSectionItemLabel,
                        }}
                        control={
                          <Checkbox
                            color="primary"
                            classes={{
                              root: classes.auditConfigFormSubSectionItemCheckbox,
                            }}
                            icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                            checkedIcon={<CheckBoxIcon color="primary" />}
                            id="chkCashDonationsAudit"
                            name="VC75"
                            checked={
                              state.configValues.VC75 ? state.configValues.VC75.configValue : false
                            }
                            onChange={handleCheckbox}
                          />
                        }
                      />
                    </div>
                  </Fragment>
                )}
                <div className="audit-config-form-sub-section-header">
                  Social Security at Retirement Age
                </div>
                <FormControl>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="Verify with taxpayer/spouse if there were any Social Security benefits received during the year if the taxpayer/spouse is over age:"
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkVerifyBenefitsSSNAudit"
                          name="VC27"
                          checked={
                            state.configValues.VC27 ? state.configValues.VC27.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Input
                            classes={{
                              root: classes.auditConfigFormSubSectionItemInput,
                            }}
                            id="txtRetirementAgeAudit"
                            name="VC28"
                            value={
                              state.configValues.VC28 ? state.configValues.VC28.configValue : ''
                            }
                            onChange={handleInputChange}
                            inputProps={{ autoComplete: 'off' }}
                            disableUnderline
                          />
                        }
                      />
                    </FormControl>
                  </div>
                  <div className="audit-config-form-sub-section-header">Unemployment Benefits</div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="Verify with taxpayer/spouse if there were any unemployment benefits received during the year if under age:"
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkVerifyUnemploymentbenefitsAudit"
                          name="VC46"
                          checked={
                            state.configValues.VC46 ? state.configValues.VC46.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Input
                            classes={{
                              root: classes.auditConfigFormSubSectionItemInput,
                            }}
                            id="txtUnemploymentAgeAudit"
                            name="VC47"
                            value={
                              state.configValues.VC47 ? state.configValues.VC47.configValue : ''
                            }
                            onChange={handleInputChange}
                            inputProps={{ autoComplete: 'off' }}
                            disableUnderline
                          />
                        }
                      />
                    </FormControl>
                  </div>

                  <div className="audit-config-form-sub-section-header">
                    Dependent(s) Existing on Filing Status Single Returns
                  </div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="Verify with taxpayer to see if they could qualify instead for Head of Household filing status"
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkVerifyUnemploymentbenefitsAudit"
                          name="VC42"
                          checked={
                            state.configValues.VC42 ? state.configValues.VC42.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                  <div className="audit-config-form-sub-section-header">Dependent Care Credit</div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="Taxpayer is claiming at least one child under age 13 and did not indicate Dependent Care Expenses paid on the CDS for child(ren). Verify taxpayer does not have any Daycare Expenses."
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="DependentCareCreditAudit"
                          name="VC80"
                          checked={
                            state.configValues.VC80 ? state.configValues.VC80.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                  {payload.season >= 2023 && (
                    <div className="audit-config-form-sub-section-header">
                      Married Filing Separate
                    </div>
                  )}
                  {payload.season >= 2023 && (
                    <div className="audit-config-form-sub-section-item">
                      <FormControlLabel
                        label="When return is filing MFS and Spouse is not indicated as a dependent on the CDS, verify the Spouse is not a dependent on another return."
                        classes={{
                          root: classes.auditConfigFormSubSectionItemLabel,
                        }}
                        control={
                          <Checkbox
                            color="primary"
                            classes={{
                              root: classes.auditConfigFormSubSectionItemCheckbox,
                            }}
                            icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                            checkedIcon={<CheckBoxIcon color="primary" />}
                            id="MarriedFilingSeparateAudit"
                            name="VC81"
                            checked={
                              state.configValues.VC81 ? state.configValues.VC81.configValue : false
                            }
                            onChange={handleCheckbox}
                          />
                        }
                      />
                    </div>
                  )}
                  <div className="audit-config-form-sub-section-header">
                    Current Year Itemizers and Prior Year State Tax Refunds
                  </div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="Taxpayer is filing with a Sch. A, but question regarding the prior year state refund has not been answered.
                                Verify with taxpayer to see if they itemized in the prior year and complete 'taxable state/local refund' worksheet
                                if needed."
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkFilingWithSchAAudit"
                          name="VC48"
                          checked={
                            state.configValues.VC48 ? state.configValues.VC48.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                  <div className="audit-config-form-sub-section-header">
                    First-Time Homebuyer Repayment
                  </div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="Verify with taxpayer's that there is not a repayment obligation."
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkRepaymentAudit"
                          name="VC43"
                          checked={
                            state.configValues.VC43 ? state.configValues.VC43.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                  <div className="audit-config-form-sub-section-header">
                    Income and Info Details Checklist
                  </div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="Auto Attach the Income and Info Details Checklist."
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkAutoAttachIncomeAudit"
                          name="INSC"
                          checked={
                            state.configValues.INSC ? state.configValues.INSC.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="Turn ON Income and Info Details Checklist Verification."
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkRurnOnIncomeAudit"
                          name="INSV"
                          checked={
                            state.configValues.INSV ? state.configValues.INSV.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginLeft: '3vw',
                      height: '2vh',
                      marginBottom: '1vh',
                    }}
                  >
                    <FormControlLabel
                      label="Verify Refund Amounts over $"
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkVerifyRefundAmountsAudit"
                          name="RLIX"
                          checked={
                            state.configValues.RLIX ? state.configValues.RLIX.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                    <FormControl>
                      <FormControl>
                        <Input
                          classes={{
                            root: classes.auditConfigFormSubSectionItemInput,
                          }}
                          id="txtRefundAmountOverAudit"
                          name="RLIM"
                          value={state.configValues.RLIM ? state.configValues.RLIM.configValue : ''}
                          onChange={handleInputChange}
                          inputProps={{ autoComplete: 'off' }}
                          disableUnderline
                        />
                      </FormControl>
                    </FormControl>
                  </div>
                  <br />
                  <div className="audit-config-form-sub-section-header">IRA Validations</div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="At least part of the IRA contribution is calculated as nondeductible. Verify with taxpayer if this is their intention."
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkNonDeductibleIRAAudit"
                          name="VC62"
                          checked={
                            state.configValues.VC62 ? state.configValues.VC62.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                  <div className="audit-config-form-sub-section-header">
                    Taxpayer(s) Information
                  </div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="Verify the taxpayer(s) name change from the prior year."
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkNameChangeAudit"
                          name="VC63"
                          checked={
                            state.configValues.VC63 ? state.configValues.VC63.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="Verify the taxpayer(s) and dependent(s) ITIN is not deactivated."
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkDeactivatedITINAudit"
                          name="VC64"
                          checked={
                            state.configValues.VC64 ? state.configValues.VC64.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="Verify the taxpayer(s) has signed the consent to use form."
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkSignedConsentAudit"
                          name="VC68"
                          checked={
                            state.configValues.VC68 ? state.configValues.VC68.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                </FormControl>
              </Collapse>
              <br />
              <div className="audit-config-form-divider">
                <Divider style={{ marginLeft: '2vw', marginRight: '1.5vw' }} />
              </div>

              <div className="audit-config-form-section-header">
                <IconButton
                  id="icobtnExpandedHOHAudit"
                  onClick={() => handleClickOnCollapse('HOH')}
                  aria-expanded={state.expandedHeaders.indexOf('HOH') !== -1}
                >
                  {state.expandedHeaders.indexOf('HOH') !== -1 ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
                <span
                  onClick={() => handleClickOnCollapse('HOH')}
                  className="audit-config-form-section-header-label"
                >
                  Head of Household
                </span>
              </div>
              {buildHeader('HOH', handleResetHOH, handleSelectAllHOH)}
              <br />
              <Collapse
                in={state.expandedHeaders.indexOf('HOH') !== -1}
                timeout="auto"
                unmountOnExit
              >
                <FormControl>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="Filing status is HOH. Attach the HOH worksheet."
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkFilingHOHAudit"
                          name="VC57"
                          checked={
                            state.configValues.VC57 ? state.configValues.VC57.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                  <div className="audit-config-form-sub-section-header">Household Members Test</div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="Ask the taxpayer if they were any other members in the household."
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkOtherMembersAudit"
                          name="VC29"
                          checked={
                            state.configValues.VC29 ? state.configValues.VC29.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="When no dependent care expenses exist, ask the taxpayer if anyone cared for the child while at work."
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkNoDependentExpenseAudit"
                          name="VC30"
                          checked={
                            state.configValues.VC30 ? state.configValues.VC30.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="Ask where the other parent resided and when during the year."
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkParentResidenceAudit"
                          name="VC31"
                          checked={
                            state.configValues.VC31 ? state.configValues.VC31.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                  <div className="audit-config-form-sub-section-header">Support Test</div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="Filing Status is HOH. Ask what documentation can the taxpayer provide that validates the taxpayer paid over half the cost of keeping up the home."
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkValidatePaidOverHalfAudit"
                          name="VC34"
                          checked={
                            state.configValues.VC34 ? state.configValues.VC34.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="Filing Status is HOH and earned income is less than $"
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkIncomeLessThanHOHAudit"
                          name="VC35"
                          checked={
                            state.configValues.VC35 ? state.configValues.VC35.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                    <FormControl>
                      <FormControlLabel
                        label="Ask the taxpayer what type of public assistance was
                                received that was non-taxable."
                        control={
                          <Input
                            classes={{
                              root: classes.auditConfigFormSubSectionItemInput,
                            }}
                            id="txtAmountHOHAudit"
                            name="VD06"
                            value={
                              state.configValues.VD06 ? state.configValues.VD06.configValue : ''
                            }
                            onChange={handleInputChange}
                            inputProps={{ autoComplete: 'off' }}
                            disableUnderline
                          />
                        }
                      />
                    </FormControl>
                  </div>
                  <div className="audit-config-form-sub-section-header">Status Test</div>
                  <div className="audit-config-form-sub-section-item">
                    <FormControlLabel
                      label="Filing Status is HOH. Ask the taxpayer what their marital status is and ask for some supporting documents if needed."
                      classes={{
                        root: classes.auditConfigFormSubSectionItemLabel,
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          classes={{
                            root: classes.auditConfigFormSubSectionItemCheckbox,
                          }}
                          icon={<CheckBoxOutlineBlankIcon color="secondary" />}
                          checkedIcon={<CheckBoxIcon color="primary" />}
                          id="chkMaritalStatusHOHAudit"
                          name="VC36"
                          checked={
                            state.configValues.VC36 ? state.configValues.VC36.configValue : false
                          }
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </div>
                </FormControl>
              </Collapse>
              <br />
              <div className="audit-config-form-divider">
                <Divider style={{ marginLeft: '2vw', marginRight: '1.5vw' }} />
              </div>
              <div>
                <div className="audit-config-form-button-container">
                  <Button
                    classes={{ root: classes.auditConfigFormButton }}
                    id="btnPreviousAudit"
                    color="primary"
                    onClick={() => {
                      setState({ gotoPrev: true });
                      toggleConfirmCancel(true);
                    }}
                  >
                    Previous (Cancel)
                  </Button>
                  <span>
                    <Button
                      classes={{ root: classes.auditConfigFormButton }}
                      id="btnCancelAudit"
                      color="primary"
                      onClick={() => {
                        setState({ gotoPrev: false });
                        toggleConfirmCancel(true);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      classes={{ root: classes.auditConfigFormButton }}
                      id="btnSaveAudit"
                      color="primary"
                      onClick={() => handleSubmit(false)}
                    >
                      Save
                    </Button>
                  </span>
                  <Button
                    classes={{ root: classes.auditConfigFormButton }}
                    color="primary"
                    id="btnNextSecurity"
                    onClick={() => handleSubmit(true)}
                  >
                    Save &amp; Next
                  </Button>
                </div>
              </div>
              <br />
            </form>
          </AccessControl>
          <SimpleDialog
            open={state.confirmCancel}
            onClose={() => toggleConfirmCancel(false)}
            onConfirm={() => {
              toggleConfirmCancel(false);
              if (state.gotoPrev) {
                props.loadSetupComponent(EXTENDED_SETUP_PAGES.DEFAULTS.NAME);
              } else {
                getInitialConfigState();
              }
            }}
            dialogTitle="Lose Unsaved Changes?"
            contentText="Are you sure you want to undo any pending changes?"
          />
        </Paper>
      </AccessControl>
    </div>
  );
};

export default withStyles(styles)(AuditSetup);

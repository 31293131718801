export const styles = theme => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "white",
    boxShadow: "none",
    borderBottom: "1px solid #ebedf8"
  },
  content: {
    flexGrow: 1,
    backgroundColor: "white",
    padding: theme.spacing.unit * 3,
    minWidth: 0 // So the Typography noWrap works
  },
  drawerPaper: {
    position: "relative",
    width: 240,
    borderRight: "0px",
    backgroundColor: "#FAFAFA",
    height: "100%"
  },
  toolbar: theme.mixins.toolbar,
  sidebarContainerMenuItem: {
    paddingLeft: "0"
  },
  editorPaper: {
    padding: "1rem"
  },
  editorSideBar: {
    paddingTop: "1.5rem"
  },
  clientLetterSectionTitle: {
    borderBottom: "1px solid #ebedf8",
    padding: "0.5rem 1rem",
    height: "1rem"
  },
  clientLetterSetupMenuItem: {
    height: "1rem"
  },
  clientLetterSubSectionMenuHeading: {
    height: "1rem"
  },
  clientLetterSetupTitle: {
    marginBottom: "1rem"
  },
  clientLetterSubSectionItem: {
    marginLeft: "1rem"
  },
  clientLetterSubSectionSetupMenuItem: {
    marginLeft: "2rem"
  },
  clientLetterSubSectionLevelTwoSetupMenuItem: {
    marginLeft: "3rem"
  },
  clientLetterEditorHeading: {
    margin: "0",
    padding: "1rem"
  },
  clientLetterEditorFooter: {
    margin: "0",
    padding: "1rem 1rem 1rem 1rem"
  },
  clientLetterEditorFooterMacroLookup: {
    paddingTop: "1rem"
  },
  clientLetterEditorFooterMacroLookupTableContainer: {
    maxHeight: "450px",
    overflowY: "auto"
  },
  clientLetterEditorBtns: {
    display: "flex",
    justifyContent: "flex-end"
  },
  clientLetterEditorBtnsMacro: {
    marginRight: "1rem"
  }
});

import { NOTIFICATION_TYPE } from "~/app/constants.js";

import * as Common from "~/app/redux/commonActions.js";

export const ADD_NOTIFICATIONS = "xlinkonline/notifications/ADDNOTIFICATION";
export const ADD_NOTIFICATION_ERROR =
  "xlinkonline/notifications/ADDNOTIFICATIONERROR";
export const REQUEST_ADD_NOTIFICATION =
  "xlinkonline/notifications/REQUESTADDNOTIFICATION";

export const ACK_NOTIFICATION = "xlinkonline/notifications/ACKNOTIFICATION";
export const ACK_NOTIFICATION_ERROR =
  "xlinkonline/notifications/ACKNOTIFICATIONERROR";
export const REQUEST_ACK_NOTIFICATION =
  "xlinkonline/notifications/REQUESTACKNOTIFICATION";
export const CLEAR_NOTIFICATIONS =
  "xlinkonline/notifications/CLEARNOTIFICATIONS";
export const SET_SELECTED_NOTIFICATION =
  "xlinkonline/notifications/SETSELECTEDNOTIFICATION";

export const ADD_FRONTEND_NOTIFICATION =
  "xlinkonline/notifications/ADDFRONTENDNOTIFICATION";
export const REMOVE_FRONTEND_NOTIFICATION =
  "xlinkonline/notifications/REMOVEFRONTENDNOTIFICATION";

export const initialState = {
  maxDisplay: 3,
  notifications: [],
  selectedNotificationID: 0,
  selectedReturnID: 0
};

export const actions = {
  requestAddNotification: msg => ({
    type: REQUEST_ADD_NOTIFICATION,
    msg
  }),
  addNotifications: notifications => ({
    type: ADD_NOTIFICATIONS,
    notifications
  }),
  clearNotifications: () => ({
    type: CLEAR_NOTIFICATIONS
  }),
  addNotificationError: () => ({
    type: ADD_NOTIFICATION_ERROR
  }),
  requestAckNotification: id => ({
    type: REQUEST_ACK_NOTIFICATION,
    id
  }),
  ackNotification: id => ({
    type: ACK_NOTIFICATION,
    id
  }),
  ackNotificationError: () => ({
    type: ACK_NOTIFICATION_ERROR
  }),
  setSelectedNotfication: (msgID, returnID) => ({
    type: SET_SELECTED_NOTIFICATION,
    msgID,
    returnID
  }),
  addFrontendNotification: data => ({
    type: ADD_FRONTEND_NOTIFICATION,
    data
  }),
  removeFrontEndNotification: () => ({
    type: REMOVE_FRONTEND_NOTIFICATION
  })
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_NOTIFICATIONS:
      if (action.notifications === null) {
        return {
          ...state
        };
      }

      // order notifications list by newest first
      let newList = [...action.notifications, ...state.notifications].sort(
        (a, b) => b.id - a.id
      );

      // remove duplicates from list which could be caused by conflict between
      // finalize login saga and websocket notification push
      let uniqueList = [];
      uniqueList.push(newList[0]);
      for (let i = 1, j = 1; i < newList.length; i++) {
        if (newList[i - 1].id == newList[i].id) {
          j++;
        } else {
          uniqueList.push(newList[j]);
          j = i + 1;
        }
      }

      // remove invalid notification types that could be pulled in the case of being
      // auto-logged out and relogging in
      uniqueList = uniqueList.filter(v => v.type != NOTIFICATION_TYPE.MESSAGE);

      return {
        ...state,
        notifications: uniqueList
      };
    case ADD_NOTIFICATION_ERROR:
      return {
        ...state
      };
    case ACK_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(n => n.id != action.id)
      };
    case ACK_NOTIFICATION_ERROR:
      return {
        ...state
      };
    case CLEAR_NOTIFICATIONS:
      return {
        ...state,
        notifications: []
      };
    case SET_SELECTED_NOTIFICATION:
      return {
        ...state,
        selectedNotificationID: action.msgID,
        selectedReturnID: action.returnID
      };
    case ADD_FRONTEND_NOTIFICATION:
      let dupe =
        state.notifications.length > 0 && state.notifications[0].id == 0;
      return {
        ...state,
        notifications: dupe
          ? [...state.notifications]
          : [action.data, ...state.notifications]
      };
    case REMOVE_FRONTEND_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(l => l.id != 0)
      };
    case Common.LOGOUT:
      return {
        ...state,
        notifications: []
      };
  }
  return state;
}

// External imports
import React from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  withStyles,
  Typography,
} from '@material-ui/core';
// Styling imports
import './css/MessageDialog.css';
import { styles } from './css/MessageDialog.js';

const MessageDialog = props => {
  const { classes } = props;
  return (
    <Paper>
      <DialogTitle id="alert-dialog-title" classes={{ root: classes.styledHeader }}>
        <Typography classes={{ root: classes.styledTitle }}>
          {props.messageDialogInfo.Title}
        </Typography>
      </DialogTitle>
      <DialogContent classes={{ root: classes.styledContent }}>
        <div id="alert-dialog-description" className="message-dialog-div-wrap">
          {props.messageDialogInfo.Text}
        </div>
      </DialogContent>
      <DialogActions>
        {props.messageDialogInfo.Btns.map(data => {
          return (
            <Button
              id="messageDialogId"
              key={data.Resp}
              color="primary"
              variant="contained"
              classes={{ root: classes.styledButton }}
              onClick={e =>
                props.handleResponseFromDialog === undefined
                  ? props.handleCloseDialog()
                  : props.handleResponseFromDialog(e, data.Resp, props.messageDialogInfo.Var)
              }
            >
              {data.Text}
            </Button>
          );
        })}
      </DialogActions>
    </Paper>
  );
};

export default withStyles(styles)(MessageDialog);

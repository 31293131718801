export const styles = {
  tableHeadCell: {
    width: '12%',
    color: '#1A173B',
    fontFamily: 'Roboto',
    fontSize: '.75rem',
    fontWeight: 'normal',
    letterSpacing: '1px',
    padding: '0 0 0 1em',
    borderBottom: 'none',
  },
  statusHeadCell: {
    width: '4%',
    color: '#1A173B',
    fontFamily: 'Roboto',
    fontSize: '.75rem',
    fontWeight: 'normal',
    letterSpacing: '1px',
    padding: '0 0 0 1em',
    borderBottom: 'none',
  },

  tableBodyDeleteButtonCell: {
    width: '1%',
    color: '#1A173B',
    fontFamily: 'Roboto',
    fontSize: '.75rem',
    fontWeight: 'normal',
    letterSpacing: '1px',
    padding: '0 0 0 1em',
    borderBottom: 'none',
  },
  deleteButton: {
    '&:hover': {
      backgroundColor: 'transparent !important'
    },
    border: '0',
  },

  tableFillRow: {
    height: '1em',
  },

  inputStyle: {
    width: '12em',
    height: '2em',
    marginRight: '0.5em',
  },

  addRowButton: {
    border: 'none',
  },

  deleteRowButton: {
    border: 'none',
    minWidth: '0',
    position: 'fixed',
  },

  bottomDivider: {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  RestrictedFieldsSaveButton: {
    width: '10vw',
    marginRight: '1vw',
  },
};

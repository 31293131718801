import { MENU_TYPE, ACCESS_LEVEL } from '~/app/constants.js';

/**
 * Handles resetting all menu dropdowns
 *
 * @param {Function} handleMenuClose Handles closing the desired menu
 */
export const resetMenuAnchors = handleMenuClose => {
  handleMenuClose('isMainMenuOpen');
  handleMenuClose('isPrintMenuOpen');
};

/**
 * The main menu options and their specific properties and functions
 *
 * @param {Function} handleMenuSelection centralized function to be used to pass up params for what logic to run
 * @param {boolean} notPrepOrEFIN used to disable input if criteria met
 * @param {boolean} isLocked ^
 * @param {boolean} readyForReview ^
 * @param {boolean} isFeederOffice ^
 * @param {boolean} isTrainingMode ^
 * @param {boolean} isTaxPassReturn ^
 * @param {boolean} toggleYearToYearOptions ^
 * @param {boolean} lockedBy ^
 * @param {boolean} isSuperOrTechUserReadOnly ^
 * @param {boolean} usingDemoAccount^
 * @param {boolean} canCopyToLiveReturn^
 * @param {boolean} isRtnAcked^
 * @returns {Array} options to render in the dropdown
 */
export const mainMenuOptions = (
  handleMenuSelection,
  notPrepOrEFIN,
  isLocked,
  readyForReview,
  isFeederOffice,
  isTrainingMode,
  isTaxPassReturn,
  toggleYearToYearOptions,
  notLatestSeason,
  lockedBy,
  isSuperOrTechUserReadOnly,
  usingDemoAccount,
  canCopyToLiveReturn,
  isRtnAcked,
  isBusiness,
  isFeeder,
  transmissionBlocked,
) => {
  // Tax Pass messaging only allowed for current season tax returns
  const hideTaxPassMessages = notPrepOrEFIN || notLatestSeason;
  return [
    {
      type: MENU_TYPE.MENU_ITEM,
      func: () => {
        handleMenuSelection('mainMenu', 'toggleLockReturn');
      },
      text: isLocked ? 'Unlock Return' : 'Lock Return',
      hideMenuItem: isSuperOrTechUserReadOnly,
      disabled: isFeederOffice && readyForReview, // feeder viewing a ready to review return
      render: true,
    },
    {
      type: MENU_TYPE.MENU_ITEM,
      func: () => {
        handleMenuSelection('mainMenu', 'reloadBilling');
      },
      text: 'Reload Billing',
      hideMenuItem: (isFeederOffice && readyForReview) || isLocked,
    },
    {
      type: MENU_TYPE.MENU_ITEM,
      func: () => {
        handleMenuSelection('mainMenu', 'yearToYear');
      },
      text: 'Year to Year',
      disabled: !toggleYearToYearOptions,
    },
    { type: MENU_TYPE.DIVIDER },
    {
      accessChecks: {
        accessLevel: ACCESS_LEVEL.SEND_TEXT_MESSAGE,
        permission: 'write',
        hideOnAccess: false,
      },
      type: MENU_TYPE.MENU_ITEM,
      func: () => {
        handleMenuSelection('mainMenu', 'sendText');
      },
      text: 'Send Text Message',
      hideMenuItem: usingDemoAccount,
      disabled: notPrepOrEFIN,
    },
    {
      accessChecks: {
        accessLevel: ACCESS_LEVEL.SEND_TEXT_MESSAGE,
        permission: 'write',
        hideOnAccess: false,
      },
      type: MENU_TYPE.MENU_ITEM,
      func: () => {
        handleMenuSelection('mainMenu', 'recieveText');
      },
      text: 'Received Text Messages',
      hideMenuItem: usingDemoAccount,
      disabled: notPrepOrEFIN,
    },
    {
      type: MENU_TYPE.MENU_ITEM,
      func: () => {
        handleMenuSelection('mainMenu', 'toggleTaxPassModal');
      },
      text: 'TaxPass Messages',
      hideMenuItem: !isTaxPassReturn,
      disabled: hideTaxPassMessages,
    },

    { type: MENU_TYPE.DIVIDER },
    {
      type: MENU_TYPE.MENU_ITEM,
      func: () => {
        handleMenuSelection('mainMenu', 'submitForReview');
      },
      text: 'Retract From Review',

      // Review option should always available for non transmitters, and only available for transmitters for review retraction
      // functionality wise thier is no point for a transmitter to submit a return for review
      accessChecks: {
        accessLevel: ACCESS_LEVEL.TRANSMIT_RETURN,
        permission: 'write',
        // Reverse Intuition here. This boolean defines when we should HIDE this option for logins WITH transmit access.
        hideOnAccess: !readyForReview && !isFeeder && !transmissionBlocked,

        // Nature of Access Contol Prop means we need this "alternate" menu for feeder/ non-transmitters
        alternateMenu: {
          type: MENU_TYPE.MENU_ITEM,
          func: () => {
            handleMenuSelection('mainMenu', 'submitForReview');
          },
          text: readyForReview ? 'Retract From Review' : 'Submit For Review',
        },
      },
    },
    {
      type: MENU_TYPE.MENU_ITEM,
      func: () => {
        handleMenuSelection('mainMenu', 'deleteReturn');
      },
      text: 'Delete Return',
      accessChecks: {
        accessLevel: ACCESS_LEVEL.DELETE_RETURN,
        permission: 'write',
        hideOnAccess: false,
      },
      hideMenuItem: readyForReview,
      disabled: isLocked || isRtnAcked,
    },

    { type: MENU_TYPE.DIVIDER },
    {
      type: MENU_TYPE.MENU_ITEM,
      func: () => {
        handleMenuSelection('mainMenu', 'copyToLive');
      },
      text: 'Copy To Live',
      disabled: !canCopyToLiveReturn || isBusiness, // disable for business returns
    },
    {
      type: MENU_TYPE.MENU_ITEM,
      func: () => {
        handleMenuSelection('mainMenu', 'copyToTraining');
      },
      text: 'Copy To Training',
      disabled: isTrainingMode || isBusiness, // disable for business returns
    },
    {
      type: MENU_TYPE.MENU_ITEM,
      func: () => {
        handleMenuSelection('mainMenu', 'saveAndClose');
      },
      text: 'Save And Close',
      hideMenuItem: isSuperOrTechUserReadOnly || (isFeederOffice && readyForReview),
      render: true,
    },
    {
      type: MENU_TYPE.MENU_ITEM,
      func: () => {
        handleMenuSelection('mainMenu', 'close');
      },
      text: 'Close',
      disabled: lockedBy || isSuperOrTechUserReadOnly || (isFeederOffice && readyForReview),
      render: true,
    },
  ];
};

/**
 * The main menu options and their specific properties and functions
 *
 * @param {Function} handleMenuSelection centralized function to be used to pass up params for what logic to run
 * @param {boolean} notPrepOrEFIN used to disable input if criteria met
 * @returns {Array} options to render in the dropdown
 */
export const printMenuOptions = (handleMenuSelection, isTrainingMode, isBusiness) => {
  return [
    {
      type: MENU_TYPE.MENU_ITEM,
      func: () => {
        handleMenuSelection('printMenu', 'currentFormOnly');
      },
      text: 'Current Form Only',
    },
    {
      type: MENU_TYPE.MENU_ITEM,
      func: () => {
        handleMenuSelection('printMenu', 'returnComponents');
      },
      text: 'Return Components',
      disabled: isTrainingMode,
      accessChecks: {
        accessLevel: ACCESS_LEVEL.PRINT_RETURN_COMPONENTS,
        permission: 'write',
      },
    },
    {
      type: MENU_TYPE.MENU_ITEM,
      disabled: isTrainingMode,
      func: () => {
        handleMenuSelection('printMenu', 'finalTaxReturn');
      },
      text: 'Final Tax Return',
    },
    {
      type: MENU_TYPE.MENU_ITEM,
      func: () => {
        handleMenuSelection('printMenu', 'bankDocuments');
      },
      disabled: isBusiness,
      text: 'Bank Documents',
    },
    {
      type: MENU_TYPE.MENU_ITEM,
      func: () => {
        handleMenuSelection('printMenu', 'privacyDisclosure');
      },
      text: 'Disclosure/Privacy',
    },
    {
      type: MENU_TYPE.MENU_ITEM,
      func: () => {
        handleMenuSelection('printMenu', 'scheduleK1s');
      },
      disabled: !isBusiness,
      text: 'Schedule K-1s',
    },
    {
      type: MENU_TYPE.MENU_ITEM,
      func: () => {
        handleMenuSelection('printMenu', 'organizer');
      },
      disabled: isBusiness,
      text: 'Organizer/Proforma',
    },
    {
      type: MENU_TYPE.MENU_ITEM,
      func: () => {
        handleMenuSelection('printMenu', 'efDocuments');
      },
      text: isBusiness ? 'EF Documents' : '8879/8453 EF Documents',
    },
    {
      type: MENU_TYPE.MENU_ITEM,
      func: () => {
        handleMenuSelection('printMenu', 'amendedReturn');
      },
      disabled: isBusiness,
      text: 'Amended Return',

      seasons: [2021, 2022, 2023, 2024],
    },
    { type: MENU_TYPE.LINE_BREAK },
    {
      type: MENU_TYPE.MENU_ITEM,
      func: () => {
        handleMenuSelection('printMenu', 'ackLetter');
      },
      text: 'Acknowledge Letter',
    },
    {
      type: MENU_TYPE.MENU_ITEM,
      func: () => {
        handleMenuSelection('printMenu', 'stateAckLetter');
      },
      text: 'State ACK Letter',
    },
    {
      type: MENU_TYPE.MENU_ITEM,
      func: () => {
        handleMenuSelection('printMenu', 'amendedAckLetter');
      },
      disabled: isBusiness,
      text: 'Amended ACK Letter',
    },
    {
      type: MENU_TYPE.MENU_ITEM,
      func: () => {
        handleMenuSelection('printMenu', 'fedClientLetter');
      },
      text: 'Federal Client Letter',
    },
    {
      type: MENU_TYPE.MENU_ITEM,
      func: () => {
        handleMenuSelection('printMenu', 'stateClientLetter');
      },
      text: 'State Client Letter',
    },
    {
      type: MENU_TYPE.MENU_ITEM,
      func: () => {
        handleMenuSelection('printMenu', 'engagementLetter');
      },
      text: 'Engagement Letter',

      seasons: [2019, 2020, 2021, 2022, 2023, 2024],
    },
    {
      type: MENU_TYPE.MENU_ITEM,
      func: () => {
        handleMenuSelection('printMenu', 'paymentVoucher');
      },
      text: 'Payment Voucher',
    },
    {
      type: MENU_TYPE.MENU_ITEM,
      func: () => {
        handleMenuSelection('printMenu', 'extensionRequest');
      },
      text: 'Extension Request',
    },
    {
      type: MENU_TYPE.MENU_ITEM,
      func: () => {
        handleMenuSelection('printMenu', 'extensionAckLetter');
      },
      text: 'Extension ACK Letter',
    },
    {
      type: MENU_TYPE.MENU_ITEM,
      func: () => {
        handleMenuSelection('printMenu', 'referralCoupons');
      },
      disabled: isBusiness,
      text: 'Referral Coupons',
    },
    {
      type: MENU_TYPE.MENU_ITEM,
      func: () => {
        handleMenuSelection('printMenu', 'attachedFormsList');
      },
      text: 'Attached Forms List',
    },
    { type: MENU_TYPE.LINE_BREAK },
    {
      type: MENU_TYPE.MENU_ITEM,
      func: () => {
        handleMenuSelection('printMenu', 'previewReturn');
      },
      text: 'Preview Return',

      accessChecks: {
        accessLevel: ACCESS_LEVEL.PREVIEW_RETURN,
        permission: 'write',
      },
    },
    {
      type: MENU_TYPE.MENU_ITEM,
      func: () => {
        handleMenuSelection('printMenu', 'paperFileReturn');
      },
      disabled: isBusiness,
      text: 'Paper File RT Return',
    },
  ];
};

// External imports
import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
// Internal imports
import App from '~/app/App.js';
import { store, history, persistor } from '~/app/redux/index';

// HANDLING this issue in `CLOIdleTimer` leaving this for a backup option
// Axios interceptor to handle expired tokens
// axios.interceptors.response.use(
//   response => {
//     return Promise.resolve(response);
//   },
//   async error => {
//     const originalRequest = error.config;
//     if (error?.response?.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;

//       try {
//         const isRefreshing = localStorage.getItem('is_validating_and_refreshing_token');
//         console.log('isRefreshing: ', isRefreshing);

//         if (!isRefreshing) {
//           localStorage.setItem('is_validating_and_refreshing_token', true);
//           console.log('ARE WE IN THE REFRESH');
//           const res = await XlinkAPI.validateOrRefresh();

//           if (statusOK(res)) {
//             // set the header with the new token for both axios instances
//             axios.defaults.headers.common.Authorization = 'Bearer ' + res.data?.access_token;
//             originalRequest.headers.Authorization = 'Bearer ' + res.data?.access_token;

//             localStorage.setItem('is_validating_and_refreshing_token', false);
//             return axios(originalRequest); // is there a way to keep track of all the original requests that haven't been sent
//           }
//         }
//       } catch (error) {
//         // validate AND refresh failed, let this error be handled by central error handling
//         localStorage.setItem('is_validating_and_refreshing_token', false);
//         ErrorHelpers.handleError('Session error', error);
//       }
//     }

//     return Promise.reject(error);
//   },
// );

const jqueryScript = document.createElement('script');
jqueryScript.src = 'https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js';
jqueryScript.id = 'jqueryScr';
document.head.appendChild(jqueryScript);

// React App starting point
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <Router>
          <App />
        </Router>
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);

export const styles = {
  smallInputContainer: {
    marginRight: '.5rem',
  },
  smallInput: {
    maxWidth: '30rem',
    width: '100%',
  },
  smallSpinner: {
    marginRight: '.4rem',
  },
};

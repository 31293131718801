import { Component } from 'react';
import * as Global from '~/app/constants.js';
import { store } from '~/app/redux/index';

class SetupPage extends Component {
  name = '';
  isEfinPreparer = false;
  pageMap = Global.REQUIRED_SETUP_PAGES_NEXT;
  constructor(props, name) {
    super(props);
    this.name = name.toUpperCase(); // name of the component
    const hasAccessToAddCobranding = store.getState().cobrand.hasAccessToAddCobranding;
    const hasAccessToPurchaseCobranding = store.getState().cobrand.hasAccessToPurchaseCobranding;
    try {
      // Determine who is logged in
      this.isEfinPreparer =
        props.currentView.role === Global.HIERARCHY_TYPE.PREPARER ||
        props.currentView.role === Global.HIERARCHY_TYPE.EFIN;
    } catch (e) {
      // Role may not be defined for this page default to non efin preparer
    }

    // Switch out the pagemap if its non-efin/preparer setup
    if (
      (hasAccessToAddCobranding || hasAccessToPurchaseCobranding || this.props.canCobrand) &&
      !this.isEfinPreparer
    ) {
      this.pageMap = Global.SB_REQUIRED_SETUP_PAGES_NEXT_PLUS_COBRAND;
    } else if (!this.isEfinPreparer) {
      this.pageMap = Global.SB_REQUIRED_SETUP_PAGES_NEXT;
    }
  }

  // Purpose of function is to get next page when user clicks Save&Next
  getNextPage = () => {
    // Now send them to the correct page
    this.props.loadSetupComponent(
      // Global.EXTENDED_SETUP_PAGES.DEFAULTS.NAME
      this.getNext(),
    );
  };

  getPrevPage = () => {
    // Now send them to the correct page
    this.props.loadSetupComponent(
      // Global.EXTENDED_SETUP_PAGES.DEFAULTS.NAME
      this.getPrev(),
    );
  };

  hasNext() {
    if (!this.doesSetupPageNextExist()) {
      return false;
    }
    return this.pageMap[this.name].NEXT !== 'None';
  }

  hasPrev() {
    if (!this.doesSetupPagePrevExist()) {
      return false;
    }
    return this.pageMap[this.name].PREV !== 'None';
  }

  // This function pulls the next pointers from constants.js
  getPrev() {
    if (!this.doesSetupPagePrevExist()) {
      return;
    }
    return this.pageMap[this.name].PREV;
  }

  // This function pulls the next pointers from constants.js
  getNext() {
    if (!this.doesSetupPageNextExist()) {
      return;
    }
    return this.pageMap[this.name].NEXT;
  }

  doesSetupPagePrevExist = () => {
    if (this.pageMap[this.name] && this.pageMap[this.name].PREV) {
      return true;
    }
    return false;
  };

  doesSetupPageNextExist = () => {
    if (this.pageMap[this.name] && this.pageMap[this.name].NEXT) {
      return true;
    }
    return false;
  };
}

export default SetupPage;

export const styles = () => ({
  paper: {
    width: '35.6rem',
    norderRadius: '4px',
    margin: 'auto',
    position: 'relative',
    paddingBottom: '4vh',
  },
  spinner: {
    position: 'absolute',
    top: '40%',
    left: '50%',
  },
  usernameInput: {
    width: '18.75rem',
  },
  sendCodeBtn: {
    padding: '0 1.875rem',
    marginTop: '1.13rem',
    marginLeft: '20px',
  },
  backToLoginTypography: {
    display: 'inline',
    marginRight: '1.875rem',
  },
  submitBtn: {
    padding: '0 1.875rem',
  },
});

export const mainAppMapStateToPropsConfig = (state, returnProfileSelectors) => {
  return {
    alert: state.app.alert,
    fingerprint: state.app.browserFingerprint,
    tabState: state.app.tabState,
    showNoCaptureSignMessage: state.app.showNoCaptureSignMessage,
    isAccountVisible: state.app.isAccountVisible,
    isTaxyearOfficeSwitchVisible: state.app.isTaxyearOfficeSwitchVisible,
    isSearchBarVisible: state.app.isSearchBarVisible,
    loggedInUser: state.app.loggedInUser,
    openReturn: state.returnProfile.open,
    openEstimator: state.app.openEstimator,
    returnID: state.returnProfile.returnID,
    createAccount: state.app.createAccount,
    setupAccount: state.setupPageState.setupAccount,
    returnProfile: state.returnProfile,
    authStage: state.app.authStage,
    pageState: state.app.pageState,
    sideTab: state.app.sideTab,
    currentNavigation: state.app.currentNavigation,
    selectedSidebarTab: state.app.tabState.selectedSidebarTab,
    drilldownHistory: state.drilldown.drilldownHistory,
    currentView: state.drilldown.drilldownHistory[state.drilldown.drilldownHistory.length - 1],
    showSignaturePad: state.app.showSignaturePad,
    showSignaturePreview: state.app.showSignaturePreview,
    checksBadgeCount: state.overview.checkBadgeCount,
    messageBadgeCount: state.overview.messageBadgeCount,
    avatar: state.app.avatarBase64,
    isTrainingMode: state.loginSetup.isTrainingMode,
    loginSettings: state.loginSetup.loginSettings,
    userIsEfinOwner: state.app.userIsEfinOwner,
    userHasPreparerLink: state.app.userHasPreparerLink,
    basicInfo: state.app.loggedInBasicInfo,
    showOnScreenPad: state.app.showOnScreenPad,
    showTopazPad: state.app.showTopazPad,
    showScriptelPad: state.app.showScriptelPad,
    impersonation: state.app.impersonation,
    MFA_Method: state.app.MFA_Method,
    isFeeder: state.officeProfile.is_feeder_office,
    canCobrand: state.cobrand.canCobrand,
  };
};

export const tabListDefinitions = [
  {
    label: 'Federal',
    pkg: 'US'
  },
  {
    label: 'Index',
    pkg: 'IX'
  },
  {
    label: 'ProForma',
    pkg: 'PF'
  },
  {
    label: 'State',
    pkg: 'ST'
  },
  {
    label: 'Depreciation',
    pkg: 'BA'
  },
];
import React from 'react';
// External imports
import { withRouter } from 'react-router-dom';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
// Redux imports
import { useDispatch, useSelector } from 'react-redux';
import { actions as appActions } from '~/app/redux/modules/app';
// Styling imports
import { withStyles } from '@material-ui/core/styles';

// Small button adjustment
const styles = {
  button: {
    marginRight: '1.5em',
    marginBottom: '1.5em',
    paddingRight: '0.5em',
    paddingLeft: '0.5em',
  },
};

const GenericErrorModal = props => {
  const dispatch = useDispatch();
  const { classes } = props;
  const { alert } = useSelector(state => ({
    alert: state.app.alert,
  }));

  return (
    <Dialog
      open={!!alert?.show}
      onClose={() => dispatch(appActions.hideError())}
      aria-labelledby="generic-error-modal-title"
      aria-describedby="generic-error-modal-body"
    >
      <DialogTitle id="generic-error-modal-title">{alert.title}</DialogTitle>
      <DialogContent id="generic-error-body">{alert.body}</DialogContent>
      <DialogActions>
        <Button className={classes.button} onClick={() => dispatch(appActions.hideError())}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withRouter(withStyles(styles)(GenericErrorModal));

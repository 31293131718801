import { actions, GET_LOGO, GET_COBRAND_STATUS, UPLOAD_LOGO } from './duck';

import { takeLatest, call, put } from 'redux-saga/effects';

import XlinkAPI from '~/app/api/xlinkAPI';
import ErrorHelpers from '~/app/errorHelpers.js';

export function* watchCobranding() {
  yield takeLatest(UPLOAD_LOGO, uploadLogo);
  yield takeLatest(GET_LOGO, fetchLogo);
  yield takeLatest(GET_COBRAND_STATUS, canUploadLogo);
}

export function* uploadLogo(payload) {
  try {
    yield call(XlinkAPI.post, `${BASE_URL}/reg/cobrand/logo`, {
      logo: payload.logo,
    });
    yield put(actions.setLogo(payload.logo));
  } catch (error) {
    ErrorHelpers.handleError('Error uploading logo', error);
    yield put(actions.uploadLogoError());
  }
}

export function* fetchLogo(payload) {
  try {
    const res = yield call(XlinkAPI.simpleGetRequest, `${BASE_URL}/reg/cobrand/info`);

    yield put(
      actions.setInfo(
        res.data.logo,
        res.data.techSupportNum,
        res.data.hasPurchasedCobranding,
        res.data.canCobrand,
        res.data.supportUrl,
        payload.waitForLoad,
      ),
    );
    localStorage.setItem('finalLogo', res.data.logo);
  } catch (error) {
    ErrorHelpers.handleError('Error fetching logo', error);
    yield put(actions.fetchLogoError());
  }
}

export function* canUploadLogo() {
  try {
    const res = yield call(
      XlinkAPI.simpleGetRequest,
      `${BASE_URL}/reg/cobrand/accessToAddCobranding`,
    );

    const { canTogglePurchase, canToggleCobrand, canCobrand, purchasedCobrand } = res?.data;

    yield put(
      actions.hasAccessToAddCobrandingSet(
        canToggleCobrand,
        canTogglePurchase,
        canCobrand,
        purchasedCobrand,
      ),
    );
  } catch (error) {
    ErrorHelpers.handleError('Error fetching cobranding permissions', error);
    yield put(actions.fetchCobrandingPermissionsError());
  }
}

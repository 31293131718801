export const UNLOCK_ACCOUNT = "xlinkonline/techSupport/unlockAccount";

// Initial object properties and values to be override once updated.
export const initialState = {};

export const actions = {
  unlockAccount: identifier => ({
    type: UNLOCK_ACCOUNT,
    identifier
  })
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
  }

  return state;
}

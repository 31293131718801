// External imports
import React from 'react';
import {
  Button,
  Divider,
  Modal,
  Paper,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  withStyles,
} from '@material-ui/core';
// Internal imports
import ActivateCheck from '#/NewAccount/SingleOffice/ActivateCheck.jsx';
import XlinkAPI from '~/app/api/xlinkAPI';
import SetupPage from '~/app/Components/Settings/Setup/SetupPage.jsx';
import SimpleDialog from '#/Common/SimpleDialog.jsx';
import { EXTENDED_SETUP_PAGES } from '~/app/constants.js';
// Redux imports
import { connect } from 'react-redux';
import { actions as setupPageActions } from '~/app/redux/setupPages/duck';
// Styling imports
import '~/app/Pages/Setup/css/setup.css';

const styles = () => ({
  cellRoot: {
    borderBottom: 'none',
    fontSize: '14px',
    color: '#67727C',
  },
  cellHeader: {
    color: '#354052',
    borderBottom: 'none',
    fontSize: '14px',
    textAlign: 'left',
  },
});

const OPTIONAL_FETCH_DELAY_MS = 250; // ms

const mapDispatchToProps = {
  ...setupPageActions,
};

const mapStateToProps = state => {
  return {
    dbList: state.setupPageState.dbList,
    currentView: state.drilldown.drilldownHistory[state.drilldown.drilldownHistory.length - 1],
  };
};

class CheckSetup extends SetupPage {
  constructor(props) {
    const name = 'Checks'; // You need this for save and next to transition
    super(props, name);
    this.state = {
      CheckInventoryTb: [],
      activateCheckModalOpen: false,
      currStartingChkNum: null,
      currEndingChkNum: null,
      currSiteIDKey: null,
      currSite_Code: null,
      currBank: 0,
      currCheck_id: 0,
      confirmCancel: false,
      currLayoutCode: null,
    };
  }

  getInitialConfigState() {
    // we are setting the state back to it's defaults in order to prevent any errors
    this.setState({
      CheckInventoryTb: [],
      activateCheckModalOpen: false,
      currStartingChkNum: null,
      currEndingChkNum: null,
      currSiteIDKey: null,
      currSite_Code: null,
      currBank: 0,
      currCheck_id: 0,
      confirmCancel: false,
      currLayoutCode: null,
    });
  }

  componentDidMount() {
    this.getUserChecks();
    this.fetch();
  }

  fetch = async (delay = false) => {
    const doFetch = () =>
      this.props.fetchUserDBList(
        8,
        this.props.currentView.loginID,
        'Site Code',
        'Site Description',
        'Site Identifiers',
      );
    if (delay) {
      setTimeout(doFetch, OPTIONAL_FETCH_DELAY_MS);
    } else {
      await doFetch();
    }
  };

  getUserChecks = () => {
    XlinkAPI.getUserChecks(this.props.loginID)
      .then(res => {
        if (res.data == null) {
          res.data = [];
        }
        const data = res.data;
        this.setState({ CheckInventoryTb: data });
      })
      .catch(err => {
        console.log(err);
      });
  };

  handleToggleModal = modalName => {
    this.setState({
      [modalName]: !this.state[modalName],
    });
  };

  padZeros = num => {
    let checknum = num + '';
    while (checknum.length < 8) {
      checknum = '0' + checknum;
    }
    return checknum;
  };

  clearCheckState = () => {
    this.setState({
      currStartingChkNum: null,
      currEndingChkNum: null,
      currSiteIDKey: null,
      currSite_Code: null,
      currBank: 0,
      currLayoutCode: null,
    });
  };

  updateCheckTable = check => {
    this.setState({
      ...this.state,
      CheckInventoryTb: [...this.state.CheckInventoryTb, check],
    });
  };

  render() {
    const { classes } = this.props;
    const buttonSize = {
      textTransform: 'capitalize',
      fontFamily: 'Roboto',
      fontSize: '14px',
      borderRadius: '4px',
      padding: '0',
    };

    const CheckTable = this.state.CheckInventoryTb.map(jsonInput => (
      <TableRow key={jsonInput.check_id}>
        <TableCell variant="body" classes={{ root: classes.cellRoot }} component="th" scope="row">
          {this.padZeros(jsonInput.starting_chk_number)}
        </TableCell>
        <TableCell variant="body" classes={{ root: classes.cellRoot }} component="th" scope="row">
          {this.padZeros(jsonInput.ending_chk_number)}
        </TableCell>
        <TableCell variant="body" classes={{ root: classes.cellRoot }} component="th" scope="row">
          {jsonInput.site_code}
        </TableCell>
        <TableCell variant="body" classes={{ root: classes.cellRoot }} component="th" scope="row">
          {jsonInput.bank_name}
        </TableCell>
        <TableCell variant="body" classes={{ root: classes.cellRoot }} component="th" scope="row">
          <Button
            color="primary"
            id="btnEditCheckCheckSetup"
            style={buttonSize}
            onClick={() => {
              this.setState(
                {
                  currStartingChkNum: jsonInput.starting_chk_number,
                  currEndingChkNum: jsonInput.ending_chk_number,
                  currSiteIDKey: jsonInput.SiteIDKey,
                  currSite_Code: jsonInput.site_code,
                  currBank: jsonInput.bank_name,
                  currCheckID: jsonInput.check_id,
                  currLayoutCode: jsonInput.check_layout_version,
                },
                this.handleToggleModal('activateCheckModalOpen'),
              );
            }}
          >
            Edit
          </Button>
        </TableCell>
      </TableRow>
    ));

    return (
      <div className="container-fixed" style={{ marginTop: '2.5rem' }}>
        <Paper>
          <form style={{ marginLeft: '3vw', marginRight: '3vw', marginTop: '1vh' }}>
            <div
              style={{
                textAlign: 'left',
                paddingTop: '3vh',
                paddingBottom: '1vh',
              }}
            >
              <div
                style={{
                  fontFamily: 'Roboto',
                  fontSize: '21px',
                  letterSpacing: '0.14px',
                  color: '#1A173B',
                  float: 'left',
                }}
              >
                Check Inventory
              </div>
              <div
                style={{
                  textAlign: 'right',
                  width: '100%',
                  padding: '0',
                  marginLeft: '0vw',
                  marginRight: '3vw',
                }}
              >
                <Button
                  id="btnActivateCheckCheckSetup"
                  onClick={() => this.handleToggleModal('activateCheckModalOpen')}
                >
                  Activate Check
                </Button>
              </div>
            </div>
            <Table style={{ marginTop: '25px', marginLeft: '2vw', width: '95%' }}>
              <TableHead>
                <TableRow>
                  <TableCell classes={{ root: classes.cellHeader }}>
                    STARTING CHECK NUMBER
                  </TableCell>
                  <TableCell classes={{ root: classes.cellHeader }}>ENDING CHECK NUMBER</TableCell>
                  <TableCell classes={{ root: classes.cellHeader }}>SITE ID</TableCell>
                  <TableCell classes={{ root: classes.cellHeader }}>BANK</TableCell>
                  <TableCell classes={{ root: classes.cellHeader }}>ACTION</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{CheckTable}</TableBody>
            </Table>
            <Divider />
            <div className="setup-nav-btns">
              <Button
                id="btnPrevCheckSetup"
                color="primary"
                onClick={() => this.handleToggleModal('confirmCancel')}
              >
                Previous (cancel)
              </Button>
              <Button
                id="btnNextCheckSetup"
                color="primary"
                onClick={() => this.props.loadSetupComponent(EXTENDED_SETUP_PAGES.LOGINS.NAME)}
              >
                Save &amp; Next
              </Button>
            </div>
          </form>
        </Paper>

        {/* Modals */}
        <SimpleDialog
          open={this.state.confirmCancel}
          onClose={() => this.handleToggleModal('confirmCancel')}
          onConfirm={() => {
            this.handleToggleModal('confirmCancel');
            this.getInitialConfigState();
            this.getPrevPage();
          }}
          dialogTitle="Lose Unsaved Changes?"
          contentText="Are you sure you want to undo any pending changes?"
        />
        <Modal
          id="mdlActivateCheckModalCheckSetup"
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.activateCheckModalOpen}
          onClose={() => this.handleToggleModal('activateCheckModalOpen')}
        >
          <Paper
            elevation={5}
            style={{
              position: 'absolute',
              top: '20%',
              left: '50%',
              transform: 'translate(-50%, -20%)',
              width: '30rem',
            }}
          >
            <ActivateCheck
              handleCloseActivateCheckModal={() => this.handleToggleModal('activateCheckModalOpen')}
              selected_starting_chk_number={this.state.currStartingChkNum}
              selected_ending_chk_number={this.state.currEndingChkNum}
              selectedSiteKeyID={this.state.currSiteIDKey}
              selected_site_code={this.state.currSite_Code}
              selected_check_id={this.state.currCheckID}
              selected_bank={this.state.currBank}
              selected_layoutCode={this.state.currLayoutCode}
              clearCheckState={this.clearCheckState}
              updateCheckTable={this.updateCheckTable}
              getChecks={this.getUserChecks}
              loginID={this.props.loginID}
              dbList={this.props.dbList}
            />
          </Paper>
        </Modal>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CheckSetup));

import { HIERARCHY_TYPE } from '~/app/constants.js';

/**
 * @module loginSettingsModalHelper
 * @category Setup
 */

/**
 * Function to return whether or not the logged in user has the permission to edit the active/inactive login status for logins
 *
 * @param {number} id used to find the current editing users linked preparer
 * @param {Object[]} linkablePreparers is an array of objects(preparers) and is used for finding the Linked preparer to this login
 * @returns {Object} prep is the linked preparer containing "name" and "preparerID" used for state
 */
export const populatePreparerField = (id, linkablePreparers) => {
  const prep = linkablePreparers.find(prep => {
    if (prep.preparerID === id) {
      return prep;
    }
    return null;
  });
  if (prep) {
    return prep;
  } else {
    const defaultVals = { preparerID: 'default', name: 'default' };
    return defaultVals;
  }
};

/**
 * Generates office names for dropdown
 * @param {Array} availableOffices are all the offices linkable to this hierarchy
 * @param {Object} availableOfficesMap used to help map the offices
 * @returns {string} office name for available offices
 */
export const getAssociatedOffices = (availableOffices, availableOfficesMap) => {
  if (availableOffices) {
    return selected =>
      selected.map((val, idx) => {
        let offName =
          availableOffices[availableOfficesMap[val]]?.name +
          '(' +
          availableOffices[availableOfficesMap[val]]?.efin +
          ')';
        if (idx > 0) {
          offName = ', ' + offName;
        }

        // The initial render will always be 'undefined(undefined)'
        // The dropdown will display that in the dropdown for a few seconds - we need to catch that so the undefined will not concern the user
        // renderValue will re-render whenever offices are loaded
        return offName !== 'undefined(undefined)' ? offName : 'Loading Offices...';
      });
  }
};

/**
 * Helper func that Populates the offices that the curret user being edited with, is associated with
 *
 * @param {Object} editableLogin current login information that is being edited for a specific user
 * @param {Object} currentView office view to check hierarchy of user
 * @param {Object} currentOffice current office selected and its data
 * @return {Object[]} returns array of office ID's
 */
export const populateAssociatedOfficesState = (editableLogin, currentView, currentOffice) => {
  const offices = editableLogin.offices;
  if (offices) {
    return offices;
  } else if (
    currentView.role === HIERARCHY_TYPE.PREPARER ||
    currentView.role === HIERARCHY_TYPE.EFIN
  ) {
    return [currentOffice.office_id];
  }
};

// External imports
import React, { useEffect } from 'react';
import { Divider, Button, Paper, Snackbar } from '@material-ui/core';
// Internal imports
import WebHelpers from '~/app/webHelpers.js';
import XlinkAPI from '~/app/api/xlinkAPI';
import ErrorHelpers from '~/app/errorHelpers.js';
import SimpleDialog from '#/Common/SimpleDialog.jsx';
import { EXTENDED_SETUP_PAGES } from '~/app/constants.js';
import {
  INDIVIDUAL_RETURN_PRINT_ORDER_DEFUALT,
  BUSINESS_RETURN_PRINT_ORDER_DEFUALT,
} from './Helper/printSetupConstants.js';
import AccessControl from '../../Auth/AccessControl.jsx';
import { useSetState } from '~/app/Utility/customHooks';
import ReturnPrinting from '~/app/Components/NewAccount/SingleOffice/PrintSetupComponents/returnPrinting.jsx';
import PrintOptions from '~/app/Components/NewAccount/SingleOffice/PrintSetupComponents/printOptions.jsx';
import PrinterSetting from '~/app/Components/NewAccount/SingleOffice/PrintSetupComponents/printerSetting.jsx';
import { canAccessCorp } from '~/app/Utility/general';
// Styles imports
import '~/app/Pages/Setup/css/setup.css';
import '~/app/Components/NewAccount/SingleOffice/css/printingSetup.css';

const PrintingSetup = props => {
  const payload = WebHelpers.getJWTPayload();
  const [state, setState] = useSetState({
    confirmCancel: false,
    gotoPrev: false,
    snackbarOpen: false,
    expandedPrinterSettings: true,
    expandedPrintOptions: true,
    expandedReturnPrinting: true,
    expandedBusinesPrinting: true,
  });

  useEffect(() => {
    getInitialConfigState();
  }, []);

  const getInitialConfigState = () => {
    window.scrollTo(0, 0);
    XlinkAPI.getPrintingSetup(props.loginID)
      .then(res => {
        const data = res.data;
        setConfigState(data);
      })
      .catch(err => {
        ErrorHelpers.handleError('Failed to get Printing Setup', err);
      });
  };

  // Handle onChange without validation for selec/menuitem fields
  const handleChange = event => {
    setState({
      fieldMap: {
        ...state.fieldMap,
        [event.target.name]: {
          ...state.fieldMap[event.target.name],
          configValue: event.target.value[0],
        },
      },
    });
  };

  // Handle onChange without validation fon input fields
  const handleInputChange = (event, dollarValue = false) => {
    let value = event.target.value.toUpperCase();
    if (dollarValue) {
      // remove preceding $, 0's and commas before decimal point
      value = event.target.value.replace(/[$0,]+/, '');

      // finally remove decimal
      value = value.replace(/[.]/g, '');
    }
    setState({
      fieldMap: {
        ...state.fieldMap,
        [event.target.name]: {
          ...state.fieldMap[event.target.name],
          configValue: value,
        },
      },
    });
  };

  // Handle onChange without validation for checkbox
  const handleCheckbox = event => {
    setState({
      fieldMap: {
        ...state.fieldMap,
        [event.target.name]: {
          ...state.fieldMap[event.target.name],
          configValue: event.target.checked ? '1' : '0',
        },
      },
    });
  };

  // TODO: Page needs to be built dynamically, this is not maintainable
  const setConfigState = data => {
    const fieldList = Object.getOwnPropertyNames(data.config);
    let fieldMap = {};
    for (let i = 0; i < fieldList.length; i++) {
      fieldMap = {
        ...fieldMap,
        [fieldList[i]]: {
          configID: data.config[fieldList[i]]?.configID,
          configValue: data.config[fieldList[i]]?.configValue,
        },
      };
    }

    // check if first print order global is empty, if it is we need to set default order values
    if (!fieldMap['PO01'].configValue) {
      setDefaultPrintOrder('PO', INDIVIDUAL_RETURN_PRINT_ORDER_DEFUALT, fieldMap);
      setDefaultPrintOrder('CP', BUSINESS_RETURN_PRINT_ORDER_DEFUALT, fieldMap);
    }

    setState({
      fieldMap: fieldMap,
    });
  };

  // alters fieldMap array by adjusting print order globals to default values
  const setDefaultPrintOrder = (globalPrefix, valueArray, fieldMap) => {
    for (let i = 0; i < valueArray.length; i++) {
      const adjustedIdx = i + 1;
      const keyIndex = adjustedIdx < 10 ? '0' + adjustedIdx : adjustedIdx;
      const globalKey = globalPrefix + keyIndex;
      fieldMap[globalKey].configValue = valueArray[i];
    }
  };

  // general function for updating field map with multiple values at once
  const updateFieldMap = dataObject => {
    setState({
      fieldMap: {
        ...state.fieldMap,
        ...dataObject,
      },
    });
  };

  // Add functin to pass all values through API call
  const submitPrinterSetupData = (gotoNext, printOrder = null) => {
    // convert fieldMap to array
    let fieldMap = state.fieldMap;
    if (printOrder) {
      fieldMap = { ...fieldMap, ...printOrder };
    }

    const fieldMapArray = Object.values(fieldMap);
    // Clean up any disabled values
    const newPrinterData = fieldMapArray.filter(obj => Number.isInteger(obj?.configID));

    newPrinterData.forEach(function (element, index, arr) {
      element.configValue = element.configValue.replace(/[$.,]/g, '');
      arr[index] = element;
    });

    XlinkAPI.updatePrintingSetup(newPrinterData, props.loginID)
      .then(() => {
        if (gotoNext) {
          props.loadSetupComponent(EXTENDED_SETUP_PAGES.CHECKS.NAME);
        } else {
          toggleState('snackbarOpen', true);
        }
      })
      .catch(err => {
        ErrorHelpers.handleError('Print Setup Error', err);
      });
  };

  // toggle a state, given the toggle name
  // if value is given change to that value, otherwise just switch to !state
  const toggleState = (toggleName, value) => {
    if (value) {
      setState({
        [toggleName]: value,
      });
    } else {
      setState({
        [toggleName]: !state[toggleName],
      });
    }
  };

  return (
    <div className="container-fixed" style={{ marginTop: '2.5rem' }}>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={state.snackbarOpen}
        autoHideDuration={2500}
        onClose={() => toggleState('snackbarOpen', false)}
        id="snkbarconfsaved"
        message={<span id="success-message">Configuration Saved</span>}
      />
      <AccessControl requiredAction="read" accessLevel="edit_print_config" disableOnFalse={true}>
        <Paper>
          <form
            style={{
              marginLeft: '3vw',
              marginRight: '3vw',
              marginTop: '1vh',
            }}
            onSubmit={e => {
              e.preventDefault();
            }}
          >
            <AccessControl
              requiredAction="write"
              accessLevel="edit_print_config"
              disableOnFalse={true}
            >
              <PrinterSetting
                fieldMap={state.fieldMap}
                handleInputChange={handleInputChange}
                handleExpansion={() => toggleState('expandedReturnPrinting')}
                expanded={state.expandedPrinterSettings}
              />
              <br />
              <Divider />
              <span>
                <ReturnPrinting
                  fieldMap={state.fieldMap}
                  handleCheckbox={handleCheckbox}
                  handleExpansion={() => toggleState('expandedReturnPrinting')}
                  expanded={state.expandedReturnPrinting}
                  isBusiness={false}
                  updateFieldMap={updateFieldMap}
                  saveOrder={submitPrinterSetupData}
                />
                {canAccessCorp(payload, props.officeProfile, props.currentView) ? (
                  <>
                    <br />
                    <Divider />
                    <span>
                      <ReturnPrinting
                        fieldMap={state.fieldMap}
                        handleCheckbox={handleCheckbox}
                        handleExpansion={() => toggleState('expandedBusinesPrinting')}
                        expanded={state.expandedBusinesPrinting}
                        isBusiness={true}
                        updateFieldMap={updateFieldMap}
                        saveOrder={submitPrinterSetupData}
                      />
                    </span>
                  </>
                ) : null}
                <br />
                <Divider />
                <span>
                  <PrintOptions
                    fieldMap={state.fieldMap}
                    handleCheckbox={handleCheckbox}
                    handleChange={handleChange}
                    handleExpansion={() => toggleState('expandedPrintOptions')}
                    handleInputChange={handleInputChange}
                    expanded={state.expandedPrintOptions}
                    sigBlockOne={state.fieldMap?.SBLW?.configValue}
                    sigBlockTwo={state.fieldMap?.SBLK?.configValue}
                  />
                </span>
              </span>
              <br />
            </AccessControl>
            <Divider />
            <div className="setup-nav-btns">
              <Button
                id="btnPreviousPrintingSetup"
                color="primary"
                onClick={() => {
                  setState({ gotoPrev: true });
                  toggleState('confirmCancel', true);
                }}
              >
                Previous (Cancel)
              </Button>
              <span>
                <Button
                  id="btnCancelPrintingSetup"
                  color="primary"
                  onClick={() => {
                    setState({ gotoPrev: false });
                    toggleState('confirmCancel', true);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  id="btnSavePrintingSetup"
                  color="primary"
                  onClick={() => submitPrinterSetupData(false)}
                >
                  Save
                </Button>
              </span>
              <Button
                id="btnNextPrintingSetup"
                color="primary"
                onClick={() => submitPrinterSetupData(true)}
              >
                Save &amp; Next
              </Button>
            </div>
            <br />
          </form>
        </Paper>
      </AccessControl>
      <SimpleDialog
        open={state.confirmCancel}
        onClose={() => toggleState('confirmCancel', false)}
        onConfirm={() => {
          toggleState('confirmCancel', false);
          if (state.gotoPrev) {
            props.loadSetupComponent(EXTENDED_SETUP_PAGES.EFILING.NAME);
          } else {
            getInitialConfigState();
          }
        }}
        dialogTitle={'Lose Unsaved Changes?'}
        contentText={'Are you sure you want to undo any pending changes?'}
      />
    </div>
  );
};

export default PrintingSetup;

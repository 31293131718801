import React, { useEffect } from 'react';
// internal imports
import UserDBTable from '../components/UserDBTable.jsx';
import { USERDB } from '~/app/constants.js';
import { getListLabels } from '../databaseSetupHelper';
// redux imports
import { useSelector, useDispatch } from 'react-redux';
import { actions as setupPageActions } from '~/app/redux/setupPages/duck';

const OccupationList = () => {
  const dispatch = useDispatch();

  const { currentView } = useSelector(state => ({
    currentView: state.drilldown.drilldownHistory[state.drilldown.drilldownHistory.length - 1],
  }));

  useEffect(() => {
    const listLabels = getListLabels(USERDB.OCCUPATION);
    dispatch(
      setupPageActions.fetchUserDBList(
        USERDB.OCCUPATION,
        currentView.loginID,
        listLabels.idColHeader,
        listLabels.dataColHeader,
        listLabels.dbListTitle,
      ),
    );
  }, []);

  return <UserDBTable getListLabels={getListLabels} />;
};

export default OccupationList;

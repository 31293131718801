export const FETCH_PREPARER_LIST = 'xlinkonline/preparerSetup/FETCHPREPARERLIST';
export const SET_PREPARERS_PAGE = 'xlinkonline/preparerSetup/SETPREPARERSPAGE';
export const ON_PREPARER_LIST_SUCCESS = 'xlinkonline/preparerSetup/ONPREPARERLISTSUCCESS';
export const ON_PREPARER_LIST_ERROR = 'xlinkonline/preparerSetup/ONPREPARERLISTERROR';

export const initialState = {
  preparers: [],
  preparerPage: 0,
  totalPreparers: 0,
  resultsPerPage: 7,
  offset: 0,
};

export const actions = {
  fetchMyPreparers: (loginID, limit, offset, page) => ({
    type: FETCH_PREPARER_LIST,
    loginID,
    limit,
    offset,
    page,
  }),
  setPreparersPage: page => ({
    type: SET_PREPARERS_PAGE,
    page,
  }),
  onFetchPreparersSuccess: (preparerList, total, offset) => ({
    type: ON_PREPARER_LIST_SUCCESS,
    preparerList,
    total,
    offset,
  }),
  onPreparerSetupError: () => ({
    type: ON_PREPARER_LIST_ERROR,
  }),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_PREPARERS_PAGE:
      return {
        ...state,
        preparerPage: action.page,
      };
    case ON_PREPARER_LIST_SUCCESS:
      return {
        ...state,
        preparers: action.preparerList,
        totalPreparers: action.total,
        offset: action.offset + 1,
      };
    case ON_PREPARER_LIST_ERROR:
      return {
        ...state,
      };
  }
  return state;
}

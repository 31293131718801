export const styles = {
  tableHeadCellRoot: {
    padding: "0px 10px 0px 10px",
    fontFamily: "Roboto",
    fontSize: "12px",
    fontWeight: "bold",
    color: "#1A173B"
  },
  tableCellRoot: {
    padding: "0px 200px 0px 10px",
    borderBottom: "none",
    fontSize: "1rem"
  },
  modal: {
    height: "70%",
    width: "45%",
    padding: "0",
    position: "absolute",
    margin: "auto",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  }
};

export const styles = {
  cellRoot: {
    borderBottom: 'none',
    fontSize: '14px',
    color: '#67727C',
  },
  cellTooltipIcon: {
    margin: '0 0 .27rem .2rem',
  },
  cellRootStatus: {
    borderBottom: 'none',
    paddingLeft: '1rem',
  },
  cellHeader: {
    color: '#354052',
    borderBottom: 'none',
    fontSize: '14px',
    textAlign: 'left',
  },
  previewButton: {
    height: '30px',
    minHeight: '30px',
    width: '60px',
    minWidth: '60px',
  },
  tooltip: {
    maxWidth: '150px',
    textAlign: 'center',
    fontSize: '12px',
  },
};

/**
 *  Returns a promise when script loads onto page.
 */
export const signalRScriptInit = src => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.id = 'signalR';
    script.addEventListener('load', () => resolve());
    script.addEventListener('error', e => reject(e));

    document.body.appendChild(script);
  });
};
/**
 *  Rotates x amount of degrees.
 */
export const degrees = deg => {
  return ((2 * Math.PI) / 360) * deg;
};

// External imports
import React from 'react';
import { Grid, Input, InputLabel, FormControl, NativeSelect, withStyles } from '@material-ui/core';
import NumberFormat from 'react-number-format';
// Styling imports
import { taxReturnPaymentStyles } from '~/app/Pages/Payments/PaymentModal/css/taxReturnPayment.js';
import '~/app/Pages/Payments/PaymentModal/css/taxReturnPayment.css';

const styles = theme => taxReturnPaymentStyles(theme);

const AchForm = props => {
  return (
    <>
      <Grid item xs={6}>
        <FormControl fullWidth required>
          <InputLabel shrink>Account Type</InputLabel>
          <NativeSelect
            disableUnderline
            id="selectAccountType"
            onChange={e => props.setState({ achAccountType: e.target.value })}
            label={'Select Account Type'}
            defaultValue={'CHECKING'}
          >
            <option key="achAccountType1" value={'CHECKING'}>
              Checking Account
            </option>

            <option key="achAccountType2" value={'SAVING'}>
              Savings Account
            </option>
          </NativeSelect>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth required>
          <InputLabel htmlFor="routingNumber" shrink>
            Routing Number
          </InputLabel>
          <NumberFormat
            name="routingNumber"
            id="routingNumber"
            value={props.achRoutingNumber}
            disableUnderline
            decimalScale={0}
            customInput={Input}
            onChange={e => props.setState({ achRoutingNumber: e.target.value })}
            allowNegative={false}
            inputProps={{ maxLength: 9 }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth required>
          <InputLabel htmlFor="achAccountNumber" shrink>
            Account Number
          </InputLabel>
          <NumberFormat
            name="accountNumber"
            id="accountNumber"
            value={props.achAccountNumber}
            disableUnderline
            decimalScale={0}
            customInput={Input}
            onChange={e => props.setState({ achAccountNumber: e.target.value })}
            allowNegative={false}
            inputProps={{ maxLength: 17 }}
          />
        </FormControl>
      </Grid>

      <Grid item xs={6}>
        <FormControl fullWidth required>
          <InputLabel shrink>ACH Type</InputLabel>
          <NativeSelect
            disableUnderline
            id="selectAccountType"
            onChange={e => props.setState({ achType: e.target.value })}
            label={'Select ACH Account Type'}
            defaultValue={'PPD'}
          >
            <option key="achType1" value={'PPD'}>
              PPD (Consumer Account)
            </option>

            <option key="achType2" value={'CCD'}>
              CCD (Business Account)
            </option>
          </NativeSelect>
        </FormControl>
      </Grid>
      <Grid item xs={6}></Grid>
      {props.achType === 'PPD' ? (
        <>
          <Grid item xs={6}>
            <FormControl fullWidth required>
              <InputLabel htmlFor="achFirstName" shrink>
                Billing First Name
              </InputLabel>
              <Input
                id="achFirstName"
                disableUnderline
                onChange={e => props.setState({ achBillingFirstName: e.target.value })}
                value={props.achBillingFirstName}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth required>
              <InputLabel htmlFor="achLastName" shrink>
                Billing Last Name
              </InputLabel>
              <Input
                id="achLastName"
                disableUnderline
                onChange={e => props.setState({ achBillingLastName: e.target.value })}
                value={props.achBillingLastName}
              />
            </FormControl>
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={6}>
            <FormControl fullWidth required>
              <InputLabel htmlFor="achCompanyName" shrink>
                Billing Company Name
              </InputLabel>
              <Input
                id="achCompany"
                disableUnderline
                onChange={e => props.setState({ achBillingCompanyName: e.target.value })}
                value={props.achBillingCompanyName}
              />
            </FormControl>
          </Grid>
          <Grid item xs={1}></Grid>
        </>
      )}
    </>
  );
};

export default withStyles(styles)(AchForm);

export const compStyles = theme => {
  return {
    root: {
      flexGrow: 1,
      zIndex: 1,
      overflow: 'hidden',
      position: 'relative',
      display: 'flex',
      textDecoration: 'none',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: 'white',
      boxShadow: 'none',
      borderBottom: '1px solid #ebedf8',
      height: '64px',
    },
    tabsRoot: {
      color: 'black',
      textTransform: 'initial',
      minWidth: 72,
      fontWeight: theme.typography.fontWeightRegular,
      margin: 0,
      marginBottom: 0,
      fontFamily: ['Roboto'].join(','),
      /* '&.tabSelected': {
        color: '#000000',
        fontWeight: theme.typography.fontWeightMedium,
      }, */
      '&:focus': {
        color: 'black',
      },
    },
    tabSelected: {
      color: '#000000',
      fontWeight: theme.typography.fontWeightMedium,
    },
    tabsIndicator: {
      backgroundColor: '#000000',
    },
    tabRoot: {
      borderBottom: '0px solid #EBEDF8',
      textTransform: 'capitalize',
    },
    tabFlex: {
      height: '64px',
    },
    topBorder: {
      top: '1.2em',
    },
    noTopBorder: {
      top: '0em',
    },
    toolbar: theme.mixins.toolbar,
    setupConfirmDeleteBtn: {
      marginRight: '1.5em',
      marginBottom: '1.5em',
      paddingRight: '0.5em',
      paddingLeft: '0.5em',
    },
    setupToolBar: { minHeight: '40px' },
    setupAcctLogoHeading: { fontSize: '1rem', cursor: 'default' },
    setupToolbarCloseBtn: {
      marginRight: '2vw',
      padding: '0 30px',
    },
    backDrop: {
      background: 'rgba(255,255,255, 0.2)',
    },
    helpMenu: {
      top: '4rem',
      right: '0.6rem',
      left: 'auto',
      bottom: 'auto',
      border: '1px solid rgb(204, 204, 204)',
      boxShadow: 'rgb(0 0 0 / 25%) 0px 0px 20px 0px',
      padding: '0',
      maxHeight: '45vh',
      overflow: 'hidden',
    },
    setupNavItemText: { paddingLeft: '20px' },
  };
};

export const themeStyles = theme => {
  return {
    root: {
      flexGrow: 1,
      display: 'flex',
      marginTop: '4rem',
      height: 'calc(100vh + 0rem)',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: 'white',
    },
    drawerPaper: {
      position: 'relative',
      borderRight: '0px',
      backgroundColor: '#FAFBFC',
      height: 'calc(100% - 4rem)',
    },
    content: {
      flexGrow: 1,
      backgroundColor: '#FAFBFC',
      padding: '0px 24px 24px 0px',
      minWidth: 0, // So the Typography noWrap works
    },
    copyOfficeModal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'scroll',
    },
    toolbar: theme.mixins.toolbar,
  };
};

export const styles = {
  formItemStyle: {
    color: '#1880E7',
    fontFamily: 'Roboto',
    fontSize: '15px',
    lineHeight: '18px',
  },
  formHeaderStyle: {
    color: '#1A173B',
    fontFamily: 'Roboto',
    fontSize: '21px',
    lineHeight: '24px',
    letterSpacing: '0.14px',
    padding: '0px',
  },
};

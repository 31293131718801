// External imports
import React, { useEffect } from 'react';
import {
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core';
// Internal imports
import { useSetState } from '~/app/Utility/customHooks.js';
// Styling imports
import { styles } from '~/app/Pages/Returns/components/Depreciation/css/depreciationStyles.js';

const ReassignAsset = props => {
  const { classes } = props;
  const [state, setState] = useSetState({
    selectedActivity: {},
    selectedRow: null,
  });

  useEffect(() => {
    props.fetchActivityList(props.asset.ID);
  }, []);

  const handleRowClick = (activity, index) => {
    if (state.selectedRow === index) {
      setState({
        selectedRow: null,
        selectedActivity: {},
      });
    } else {
      setState({
        selectedRow: index,
        selectedActivity: activity,
      });
    }
  };

  return (
    <div id="divReassignAssetModal">
      <Typography variant="title" id="simple-modal-title" style={{ paddingLeft: '1em' }}>
        Assign Asset
      </Typography>
      <div className="addAssetModal">
        <Typography variant="subtitle2" style={{ paddingLeft: '1em' }}>
          Select an activity to assign asset to:
        </Typography>
        <Divider />
        <div style={{ maxHeight: '25vh', overflowY: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow classes={{ root: classes.tableHeadRoot }}>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.reassignAssetList?.map((activity, i) => {
                return (
                  <TableRow
                    key={i}
                    classes={{ root: classes.tableRowRoot }}
                    selected={i === state.selectedRow}
                    onClick={() => handleRowClick(activity, i)}
                    hover
                  >
                    <TableCell className="noselect" component="th" scope="row">
                      {activity.name}
                    </TableCell>
                    <TableCell className="noselect">{activity.desc}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        <Divider />
      </div>
      <div
        className="container"
        style={{
          marginTop: '15px',
          paddingBottom: '15px',
          textAlign: 'right',
          position: 'relative',
        }}
      >
        <Button
          id="btnCloseAddAssetModal"
          color="primary"
          size="small"
          style={{ marginRight: '1rem', border: '0px' }}
          onClick={() => props.toggleModal(null, false)}
        >
          Close
        </Button>
        <Button
          id="btnAddAsset"
          color="primary"
          size="small"
          disabled={state.selectedRow == null}
          onClick={() => {
            props.reassignAsset(props.asset.ID, state.selectedActivity.ID.slice(-6));
            props.toggleModal(null, false);
          }}
        >
          Assign
        </Button>
      </div>
    </div>
  );
};

export default withStyles(styles)(ReassignAsset);

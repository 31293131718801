export const widgetManagementStyleConfig = theme => {
  return {
    margin: theme.spacing.unit * 2,
    padding: theme.spacing.unit * 3,
  };
};

export const continueButtonStyle = {
  marginLeft: '1vw',
  backgroundColor: '#0077FF',
  color: '#FFFFFF',
  textTransform: 'capitalize',
  borderRadius: '4',
};

export const continueButtonStyleWithMargin = {
  marginLeft: '1vw',
  backgroundColor: '#0077FF',
  color: '#FFFFFF',
  textTransform: 'capitalize',
  borderRadius: '4',
  top: '.8em',
};

export const toolbarColor = { backgroundColor: '#ffffff' };

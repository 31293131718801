// External imports
import React, { useEffect } from 'react';
import {
  Typography,
  Grid,
  Button,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  TableSortLabel,
  Menu,
  MenuItem,
  withStyles,
} from '@material-ui/core';
// Internal imports
import { useSetState } from '~/app/Utility/customHooks';
import WebHelpers, { getDate } from '~/app/webHelpers.js';
import ReturnNotesModal from '~/app/Pages/Returns/components/ReturnNotesModal.jsx';
import menuVertical from '~/images/icons/menu_vertical.png';
// Styling imports
import { styles } from '~/app/Pages/Returns/css/returnNotes.js';

const ReturnNotes = props => {
  const { classes } = props;
  const [state, setState] = useSetState({
    isViewNoteModalOpen: false,
    selectedNote: {},
    sortedNotes: [],
    hoveredRowIndex: null,
    anchorEl: null,
    sortOrder: 'asc',
    isRowMenuOpen: false,
    isEditing: false,
  });

  useEffect(() => {
    setState({
      sortedNotes: handleSortNotes(state.sortOrder, props.notes),
    });
  }, [props.notes]);

  const closeNoteModal = () => {
    setState({
      isViewNoteModalOpen: false,
      selectedNote: {},
      isEditing: false,
    });
  };

  const deleteNote = selectedNote => {
    props.noteUpsert(selectedNote, true);
    closeNoteModal();
  };

  const saveNote = () => {
    const withTimeString = true;
    const selectedNote = {};
    Object.assign(selectedNote, state.selectedNote);
    selectedNote.timestamp = getDate(withTimeString);
    selectedNote.note = state.selectedNote.note;
    selectedNote.owner = WebHelpers.getJWTPayload().full_name;
    props.noteUpsert(selectedNote);
    closeNoteModal();
  };

  const addNote = () => {
    let id = 1;
    // Since calcserver is restricted from having an id that exceeds 99, we search for a usable unique ID within the range of 1-99.
    for (let i = 0; i < state.sortedNotes.length; i++) {
      if (
        state.sortedNotes.find(note => {
          // Cs saves id as '01', '02' etc.
          return parseInt(note.id) === id;
        })
      ) {
        id++;
      } else {
        break;
      }
    }
    const withTimeString = true;
    const newNote = {
      id: id,
      timestamp: getDate(withTimeString),
      note: state.selectedNote.note,
      owner: WebHelpers.getJWTPayload().full_name,
    };
    props.noteUpsert(newNote);
    closeNoteModal();
  };

  const handleNoteChange = e => {
    setState({ selectedNote: { ...state.selectedNote, note: e.target.value } });
  };

  const handleOpenMenu = (e, rowIndex) => {
    e.stopPropagation();
    setState({
      anchorEl: e.currentTarget,
      hoveredRowIndex: rowIndex,
      isRowMenuOpen: true,
    });
  };

  const handleCloseMenu = () => {
    setState({
      anchorEl: null,
      hoveredRowIndex: null,
      isRowMenuOpen: false,
    });
  };

  const rowIsHovered = rowIndex => {
    return state.hoveredRowIndex === rowIndex;
  };

  const handleSortNotes = (sortOrder, notes) => {
    return notes.sort((a, b) => {
      const dateA = new Date(a.timestamp);
      const dateB = new Date(b.timestamp);

      if (sortOrder === 'asc') {
        return dateA - dateB;
      }
      return dateB - dateA;
    });
  };

  const handleSortOrder = () => {
    const newSortOrder = state.sortOrder === 'desc' ? 'asc' : 'desc';
    setState({
      sortOrder: newSortOrder,
      sortedNotes: handleSortNotes(newSortOrder, state.sortedNotes),
    });
  };

  return (
    <div id="taxform" className={classes.container}>
      <Grid container alignItems="center" justify="space-between">
        <Grid item>
          <Typography variant="title">Return Notes</Typography>
        </Grid>
        <Grid item>
          <Button
            disabled={props.locked || (props.readyForReview && props.isFeederOffice)}
            color="primary"
            variant="contained"
            onClick={() => setState({ isViewNoteModalOpen: true })}
          >
            Add Note +
          </Button>
        </Grid>
      </Grid>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell sortDirection={state.sortOrder}>
              <TableSortLabel active={true} direction={state.sortOrder} onClick={handleSortOrder}>
                Timestamp
              </TableSortLabel>
            </TableCell>
            <TableCell>Description</TableCell>
            <TableCell colSpan={2}>Preparer</TableCell>
            {/* empty table cell for menu btn  */}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {state.sortedNotes.map((note, i) => {
            return (
              <TableRow
                key={`note-${i}`}
                hover={rowIsHovered(i)}
                selected={rowIsHovered(i)}
                onMouseEnter={() => setState({ hoveredRowIndex: i })}
                onMouseLeave={() => setState({ hoveredRowIndex: null })}
              >
                <TableCell>{note.timestamp}</TableCell>
                <TableCell>{note.note}</TableCell>
                <TableCell>{note.owner}</TableCell>
                <TableCell>
                  <Button
                    id="menuBtn"
                    className={`${
                      !rowIsHovered(i) ? 'tableVerticalMenu' : 'tableVerticalMenuVisible'
                    } tableActionsButton`}
                    onClick={e => handleOpenMenu(e, i)}
                  >
                    <img src={menuVertical} />
                  </Button>
                  <Menu
                    anchorEl={state.anchorEl}
                    open={state.hoveredRowIndex === i && state.isRowMenuOpen}
                    onClose={() =>
                      setState({
                        anchorEl: null,
                        hoveredRowIndex: null,
                      })
                    }
                  >
                    <MenuItem
                      id="viewBtn"
                      onClick={() => {
                        setState({
                          isViewNoteModalOpen: true,
                          selectedNote: note,
                          isEditing: true,
                        });
                        handleCloseMenu();
                      }}
                      disabled={props.locked || (props.readyForReview && props.isFeederOffice)}
                    >
                      View
                    </MenuItem>
                    <MenuItem
                      id="deleteBtn"
                      onClick={() => {
                        deleteNote(note);
                        handleCloseMenu();
                      }}
                      disabled={props.locked || (props.readyForReview && props.isFeederOffice)}
                    >
                      Delete
                    </MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <ReturnNotesModal
        handleNoteChange={handleNoteChange}
        saveNote={saveNote}
        addNote={addNote}
        deleteNote={deleteNote}
        isViewNoteModalOpen={state.isViewNoteModalOpen}
        closeNoteModal={closeNoteModal}
        isEditing={state.isEditing}
        selectedNote={state.selectedNote}
        readyForReview={props.readyForReview}
        isFeederOffice={props.isFeederOffice}
      />
    </div>
  );
};

export default withStyles(styles)(ReturnNotes);

export const styles = _ => ({
  statePackageGridContainer: {
    height: '18.75rem',
  },
  statePackageCode: {
    minWidth: '2rem',
    display: 'inline-block',
  },
  updateButtonContainer: {
    display: 'flex',
    justifyContent: 'right',
  },
  colorSwitchBase: {
    '&$colorChecked': {
      color: '#81B18C',
      '& + $colorBar': {
        backgroundColor: '#81B18C',
      },
    },
  },
  colorBar: {},
  colorChecked: {},
});

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Paper, Typography, withStyles } from '@material-ui/core';
import moment from 'moment';
import { useSetState } from '~/app/Utility/customHooks';

import { dateStrToUnixTimestamp } from '~/app/Utility/general.js';
import { styles } from '~/app/Components/Common/HeaderMessage/css/headerMessage.js';
import '~/app/Components/Common/HeaderMessage/css/headerMessage.css';

/**
 * Required:
 *   message="message string here"
 *
 * Optional:
 *   heading="Notice"
 *   startDate="YYYY-MM-DD HH:mm:ss"
 *   endDate="YYYY-MM-DD HH:mm:ss"
 */
const HeaderMessage = props => {
  const { classes } = props;
  /**
   * When using this component, minimum parameters
   * required should be:
   *
   * message
   * startDate
   */
  const [state, setState] = useSetState({
    showMsg: false,
  });

  useEffect(() => {
    checkStartEndDate();
  }, []);

  /**
   * Convert the passed in startDate and endDate to
   * unix timestamp. Get the current date in unix timestamp.
   * Then check if current date is between the startDate
   * and endDate to display the header message.
   */
  const checkStartEndDate = () => {
    const currentTimestamp = moment().valueOf();
    // Both startDate and endDate exist so we check if current date
    // is between startDate and endDate.
    if (props.startDate && props.endDate) {
      const startDate = dateStrToUnixTimestamp(props.startDate);
      const endDate = dateStrToUnixTimestamp(props.endDate);
      if (currentTimestamp > startDate && currentTimestamp < endDate) {
        setState({ showMsg: true });
      }
    }
    // Only startDate was provided so we check if current date is on or
    // after startDate.
    else if (props.startDate && !props.endDate) {
      const startDate = dateStrToUnixTimestamp(props.startDate);

      if (currentTimestamp > startDate) {
        setState({ showMsg: true });
      }
    }
    // Only endDate is provided, so we show the message immediately
    // and will hide the message on the end date timestamp.
    else if (!props.startDate && props.endDate) {
      const endDate = dateStrToUnixTimestamp(props.endDate);

      if (currentTimestamp <= endDate) {
        setState({ showMsg: true });
      }
    }
  };

  if (state.showMsg) {
    if (!props.secondMessage) {
      return (
        <div className="header-msg-container">
          <Paper classes={{ root: classes.headerMsgConent }}>
            <span className="header-msg-notice-label">
              {props.heading ? `** ${props.heading} : ` : null}
            </span>
            {props.message}
          </Paper>
        </div>
      );
    } else {
      return (
        <div className="header-msg-container">
          <Paper classes={{ root: classes.headerMsgConent }}>
            <Typography variant="title" align="center" className={classes.firstMessage}>
              {props.message}
            </Typography>
            <Typography variant="subheading" align="center">
              {props.secondMessage}
            </Typography>
          </Paper>
        </div>
      );
    }
  } else {
    return null;
  }
};

HeaderMessage.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  heading: PropTypes.string,
  secondMessage: PropTypes.string,
};

export default withStyles(styles)(HeaderMessage);

import React from 'react';
// External imports
import { Grid, Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
// Styling imports
import { styles } from '~/app/Pages/WizardMode/Mapper/css/mapperStyles.js';
import '~/app/Pages/WizardMode/Mapper/css/mapperStyles.css';

/**
 * Handles displaying HR if "style" property exists
 *
 * @returns {JSX} HRMap UI
 */
const HRMap = props => {
  const { classes } = props;

  return (
    <Grid item xs={12}>
      <Divider className={classes.dividerStyle} />
    </Grid>
  );
};

export default withStyles(styles)(HRMap);

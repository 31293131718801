export const styles = {
  menuItemStyle: {
    display: 'flex',
    height: '1rem',
    color: '#1880E7',
  },
  selectedMenuItemStyle: {
    display: 'flex',
    height: '1rem',
    paddingRight: '117%',
    color: '#000000',
    backgroundColor: '#ffffff',
  },
  menuItemText: {
    height: '1.1rem',
    color: '#1880E7',
    fontFamily: 'Roboto',
    fontSize: '.95rem',
    fontWeight: '500',
    letterSpacing: '0',
    lineHeight: '1.1rem',
  },
  selectedMenuItemText: {
    height: '1.1rem',
    color: '#1A173B',
    fontFamily: 'Roboto',
    fontWeight: '500',
    fontSize: '.95rem',
    letterSpacing: '0',
    lineHeight: '1.1rem',
  },
  sideBarDivider: {
    width: '100%',
    margin: '0 0 1.656rem 0',
    height: '0.188rem',
    color: '#EBEDF8',
    backgroundColor: '#EBEDF8',
  },
  sideBarButton: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0.375rem 3.25rem',
    backgroundColor: '#FFFFFF',
    fontSize: '0.875rem',
  },
  subFormItemStyle: {
    color: '#1880E7',
    fontFamily: 'Roboto',
    fontSize: '.8rem',
    lineHeight: '1.1rem',
    paddingLeft: '1.8rem',
  },
  IconStyle: {
    outline: 'none',
    paddingLeft: '0',
    paddingRight: '0',
  },
  IconStyleNotAttached: {
    outline: 'none',
    marginLeft: '1rem',
    paddingLeft: '0',
    paddingRight: '0',
  },
};

import React, { useEffect } from 'react';
// Internal imports
import { useSetState } from '~/app/Utility/customHooks';
import { DOWNLOAD_DRIVER_LINKS } from '~/app/constants.js';
import Spinner from '#/Common/Spinner.jsx';
import { degrees, signalRScriptInit } from './ScannerHelper.js';
// External imports
import {
  Paper,
  Button,
  withStyles,
  Input,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
} from '@material-ui/core';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import $ from 'jquery'; // TODO: No jQuery unless absolutely necessary
// Styling imports
import './css/scanner.css';
import { compStyles } from './css/scanner.js';

const JPEG = 1;
const styles = theme => compStyles(theme);
const Scanner = props => {
  const { classes } = props;

  const [state, setState] = useSetState({
    image: '',
    hub: null,
    connection: null,
    isDriverInstalled: true,
    cropActivated: false,
    connectingToScanner: true,
    crop: {
      x: null,
      y: null,
      width: null,
      height: null,
    },
    canvas: null,
    scannerID: 0,
    isScannerConnected: false,
    isScannerError: false,
    scannerErrMsg: '',
  });

  useEffect(() => {
    window.jQuery = $;
    // Initialize loading of scanner script and websocket connection.
    signalRScriptInit('https://ajax.aspnetcdn.com/ajax/signalr/jquery.signalr-2.1.2.min.js')
      .then(() => {
        initializeScanner();
      })
      .catch(() => {
        setState({ isDriverInstalled: false, connectingToScanner: false });
      });

    return () => {
      // Cleanup script
      const sig = document.getElementById('signalR');

      if (sig !== null) {
        sig.remove();
      }
    };
  }, []);

  const initializeScanner = () => {
    const docuscanURL = 'https://webdocuscan.petzent.com:9388';
    const connection = $.hubConnection(docuscanURL);

    // Creating hub proxy. Unable to pull generated one from hub url.
    const hub = connection.createHubProxy('scannerservice');

    // Create CallBacks
    hub.on('OnImageCaptured', image => {
      setImageInCanvas(image.DataURL);
    });
    hub.on('OnScannerListChanged', val => {});
    hub.on('OnViewfinderImageData', val => {});
    setState({ hub: hub });
    connection
      .start()
      .done(function (val) {
        console.info('INFO: Connection complete', val);
        setState({
          connection: connection,
          hub: hub,
          connectingToScanner: false,
        });
        setState({ hub: hub });
        // Get the list of scanners hooked up and select the first one
        hub
          .invoke('getScannerList')
          .fail(function () {
            console.error('ERROR: Scanner could not retrieve scanner list', val);
            // This shouldn't fail. We should be able to invoke this function at this point.
            setState({
              isDriverInstalled: false,
              connectingToScanner: false,
            });
          })
          .done(function (scannerList) {
            console.info('INFO: Scanner list', scannerList);
            if (scannerList.length < 1) {
              setState({
                isScannerConnected: false,
              });
              return;
            }
            setState({
              isScannerConnected: true,
              scannerID: scannerList[0],
            });
          });
      })
      .fail(function (err) {
        console.error('ERROR: Connection failed', err);
        setState({ isDriverInstalled: false, connectingToScanner: false });
      });
  };

  const setImageInCanvas = image => {
    const img = new Image();
    img.src = image;
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    setState({ image: image, canvas: canvas });
  };

  const snapImage = () => {
    const hub = state.hub;
    hub.invoke('CaptureImage', state.scannerID, JPEG, false);
    hub.invoke('PullTrigger', state.scannerID);
  };

  const onCropChange = crop => {
    setState({ crop });
  };

  const onScannerError = msg => {
    setState({
      isScannerError: true,
      scannerErrMsg: msg,
    });
  };

  const saveImage = () => {
    if (state.image === '') {
      onScannerError('An image must be snapped before saving and closing');
      return;
    }
    if (props.image_name === '') {
      onScannerError('Document name required');
      return;
    }
    props.saveScannerImage(state.image);
  };

  const cropImage = () => {
    const pixelCrop = state.crop;

    // Create new Image Element
    const img = new Image();
    img.src = state.image;

    // Copy to not mutate
    const canvas = state.canvas;
    canvas.width = (pixelCrop.width / 100) * img.width;
    canvas.height = (pixelCrop.height / 100) * img.height;
    const ctx = canvas.getContext('2d');
    // Library is only returning x,y,width and height in percent.
    ctx.drawImage(
      img,
      (pixelCrop.x / 100) * img.width,
      (pixelCrop.y / 100) * img.height,
      (pixelCrop.width / 100) * img.width,
      (pixelCrop.height / 100) * img.height,
      0,
      0,
      (pixelCrop.width / 100) * img.width,
      (pixelCrop.height / 100) * img.height,
    );
    setState(
      { canvas: canvas, crop: { x: null, y: null, width: null, height: null } },
      setImageInCanvas(canvas.toDataURL('image/jpeg')),
    );
  };

  const rotateImage = () => {
    // Copy to not mutate
    const canvas = state.canvas;

    // Create Image Element from State
    const img = new Image();
    img.src = state.image;

    // Swap the height and width for new canvas
    canvas.height = img.naturalWidth;
    canvas.width = img.naturalHeight;
    const ctx = canvas.getContext('2d');

    // Rotate and draw
    ctx.translate(img.height, 0);
    ctx.rotate(degrees(90));
    ctx.drawImage(img, 0, 0);
    ctx.restore();

    // Set New Canvas and new image
    setState(
      {
        canvas: canvas,
      },
      setImageInCanvas(canvas.toDataURL('image/jpeg')),
    );
  };

  const onCropComplete = crop => {};

  return (
    <Paper classes={{ root: classes.scanDocPage }}>
      {state.connectingToScanner && (
        <span style={{ zIndex: 9999, position: 'fixed', top: '40%', left: '50%' }}>
          <Spinner
            size={125}
            loadingText="Connecting to Scanner..."
            textColor="white"
            bgColor="grey"
          />
        </span>
      )}
      <div className="header-section">
        <div className="button-header-section">
          <Button
            onClick={() => cropImage()}
            disabled={state.image === ''}
            classes={{ root: classes.btnSpacing }}
          >
            Crop
          </Button>
          <Button
            onClick={() => rotateImage()}
            disabled={state.image === ''}
            classes={{ root: classes.btnSpacing }}
          >
            Rotate
          </Button>
        </div>
        {!state.isDriverInstalled && (
          <div className="scanner-header-text">
            <p>
              Motorola Core Scanner driver may not be installed. The Driver can be found{' '}
              <a href={DOWNLOAD_DRIVER_LINKS.SCANNER}>Here!</a>
              <br />
              Once Installed, close your browser and please restart your machine.
            </p>
          </div>
        )}
        {!state.isScannerConnected && state.isDriverInstalled && (
          <div className="scanner-header-text">
            <p>Scanner Not Connected.</p>
          </div>
        )}
      </div>
      <div className="image-content">
        {state.image && (
          <ReactCrop
            src={state.image}
            crop={state.crop}
            onChange={onCropChange}
            onComplete={onCropComplete}
          />
        )}
      </div>
      <div className="footer-section">
        <div className="input-footer-section">
          <div className="footer-document-name">
            {'Document Name:  '}
            <Input
              disableUnderline
              id="user_description"
              value={props.image_name}
              onChange={e => props.changeDocumentName(e.target.value)}
              classes={{ root: classes.descriptionFooterSection }}
              fullWidth={true}
            />
          </div>
        </div>
        <div className="button-footer-section">
          <Button
            color="primary"
            classes={{ root: classes.btnSpacing }}
            onClick={() => saveImage()}
          >
            Save And Close
          </Button>
          <Button
            color="primary"
            classes={{ root: classes.btnSpacing }}
            onClick={() => snapImage()}
          >
            Snap
          </Button>
          <Button color="primary" onClick={() => props.closeScannerPage()}>
            Close
          </Button>
        </div>
      </div>
      <Dialog
        open={state.isScannerError}
        onClose={() => setState({ isScannerError: false })}
        aria-labelledby="confirm-scannererr-title"
        aria-describedby="confirm-scannererr-desc"
        disableBackdropClick
      >
        <DialogTitle id="confirm-scannererr-title">{'Error'}</DialogTitle>
        <DialogContent id="confirm-scannererr-desc">
          <p>{state.scannerErrMsg}</p>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            id="btnCloseScannerErrDlg"
            onClick={() => setState({ isScannerError: false })}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default withStyles(styles)(Scanner);

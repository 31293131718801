// Internal imports
import ErrorHelpers from '~/app/errorHelpers.js';
import XlinkAPI from '~/app/api/xlinkAPI';
// Redux imports
import { takeLatest, call, put } from 'redux-saga/effects';
import { actions, FETCH_BUILD_BREADCRUMBS } from './duck';
import { actions as ddActions } from '~/app/redux/drilldown/duck';

export function* watchFetchBreadcrumbs() {
  yield takeLatest(FETCH_BUILD_BREADCRUMBS, fetchBreadcrumbs);
}

export function* fetchBreadcrumbs(payload) {
  try {
    const res = yield call(
      XlinkAPI.getBreadcrumbPath,
      payload.parentID,
      payload.preparerID,
      payload.leafID,
      payload.leafType,
      payload.leafName,
      payload.efinID,
    );

    if (res.data !== null && res.data.length > 0) {
      yield put(ddActions.addMultipleEntities(res.data));
    }

    yield put(actions.buildBreadcrumbSuccess());
  } catch (error) {
    ErrorHelpers.handleError('Error fetching breadcrumbs', error);
    yield put(actions.buildBreadcrumbFailure());
  }
}

// External imports
import React from 'react';
import {
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  withStyles,
} from '@material-ui/core';
// Internal imports
import { useSetState } from '~/app/Utility/customHooks';
// Styling imports
import { styles } from '~/app/Pages/Returns/components/Print/css/dialogStyles.js';

const PrintStateClientLetters = props => {
  const { classes } = props;
  const [state, setState] = useSetState({
    checkedVal: '',
  });

  const handleToggle = stateCode => {
    if (stateCode === state.checkedVal) {
      setState({
        checkedVal: '',
      });
    } else {
      setState({
        checkedVal: stateCode,
      });
    }
  };

  const disablePrint = state.checkedVal === '';

  return (
    <div>
      <Typography variant="title" id="simple-modal-title" style={{ paddingLeft: '1em' }}>
        Print State Client Letters
      </Typography>
      <div>
        <List>
          {Object.keys(props.states).map(stateCode => {
            return (
              <ListItem key={stateCode} classes={{ root: classes.listItemRoot }}>
                <ListItemText
                  classes={{ root: classes.listItemTextRoot }}
                  primary={
                    <Typography type="body2" classes={{ root: classes.typographyRoot }}>
                      {stateCode}
                    </Typography>
                  }
                />
                <ListItemSecondaryAction>
                  <Checkbox
                    id={stateCode + '-id'}
                    name={stateCode}
                    classes={{ root: classes.secondaryListItemRoot }}
                    onClick={() => handleToggle(stateCode)}
                    checked={state.checkedVal === stateCode}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </div>

      <div
        className="container"
        style={{
          marginTop: '15px',
          paddingBottom: '15px',
          textAlign: 'right',
          position: 'relative',
        }}
      >
        <Button
          id="btnCancel"
          size="small"
          classes={{ root: classes.cancelButtonRoot }}
          onClick={props.handleClose}
        >
          Cancel
        </Button>
        <Button
          id="btnPrint"
          size="small"
          onClick={() => {
            props.handlePrint('11', {}, state.checkedVal);
            props.handleClose();
          }}
          disabled={disablePrint}
        >
          Print
        </Button>
      </div>
    </div>
  );
};

export default withStyles(styles)(PrintStateClientLetters);

// Internal imports
import { HIERARCHY_TYPE } from '~/app/constants.js';

/**
 *  Attempt to pull the franchise/SB/SBB loginID
 */
export const getLoginID = (drilldownHistory, currentView) => {
  // Get index of EFIN view
  const efinIdx = drilldownHistory.findIndex(item => item?.role === HIERARCHY_TYPE?.EFIN);
  // Navigate one before to obtain loginID
  return drilldownHistory?.[efinIdx - 1]?.loginID || currentView?.loginID;
};

/**
 *  Render jsx helper function for checkbox labelssignatu
 */
export const formatCopyCheckboxesLabels = label => {
  return label
    .replace(/([A-Z])/g, ' $1') // add space between upper case
    .replace('Efiling', 'E-Filing') // special case to add hyphen
    .trim(); // remove leading space
};

import {
  actions,
  REQUEST_ADD_NOTIFICATION,
  REQUEST_ACK_NOTIFICATION
} from "./duck";

import { takeLatest, call, put } from "redux-saga/effects";

import ErrorHelpers from "~/app/errorHelpers.js";
import XlinkAPI from "~/app/api/xlinkAPI";

export function* watchNotifications() {
  yield takeLatest(REQUEST_ADD_NOTIFICATION, addNotification);
  yield takeLatest(REQUEST_ACK_NOTIFICATION, ackNotification);
}

export function* addNotification(payload) {
  try {
    let arr = [];
    arr.push(payload.msg);
    yield put(actions.addNotifications(arr));
  } catch (error) {
    ErrorHelpers.handleError("Error adding notification to queue", error);
    yield put(actions.addNotificationError());
  }
}

export function* ackNotification(payload) {
  try {
    yield call(XlinkAPI.dismissNotification, payload.id);

    // remove from notification queue
    yield put(actions.ackNotification(payload.id));
  } catch (error) {
    ErrorHelpers.handleError("Error acknowledging notification", error);
    yield put(actions.ackNotificationError());
  }
}

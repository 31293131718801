export const sigTypes = {
  1: 'tpsign',
  2: 'spsign',
  3: 'ppsign',
  4: 'erosign',
  5: 'tpinitial',
  6: 'spinitial',
  7: 'officersign',
  8: 'tpsign2',
  9: 'spsign2',
  A: 'xsign',
};

export const canvasSize = {
  width: 476,
  height: 100,
};

export const canvasOptions = {
  minWidth: 0.7,
  maxWidth: 1,
  penColor: 'rgb(0, 0, 0)',
};

/*
    Convert html canvas image (png image) to 1 bit bitmap.
    Modified from: https://rephrase.net/box/bitmap/
    BMP Spec Reference: https://d3s.mff.cuni.cz/teaching/principles_of_computers/Zkouska%20Principy%20pocitacu%202017-18%20-%20varianta%2002%20-%20priloha%20-%20format%20BMP%20z%20Wiki.pdf
*/

export const toBMPDataURL = (canvas, padType) => {
  var width = canvas.width, // source canvas width
    height = canvas.height, // source canvas height
    imgData = padType == 'screen' ? newPixelArray(canvas) : newPixelArrayTopaz(canvas); // get new pixel array based on alpha channel
  imgData =
    padType == 'screen'
      ? toMonoPixels(width, height, imgData)
      : toMonoPixelsTopaz(width, height, imgData); // convert pixel array to monochrome + add padding bits

  var dataLength = imgData.length, // bitmap size
    colorTable = ['000000', 'ffffff'], // color palette
    colorTableLength = colorTable.length, // number of colors in color palette
    colorTableSize = colorTableLength * 4, // color table size
    fileSize = 54 + colorTableSize + dataLength, // total size
    pixelOffset = 54 + colorTableSize; // offset to pixel data

  // Build bitmap header
  var data = [
    // DIB Header
    'BM', // BMP bfType
    conv(fileSize), // total length
    '\x00\x00\x00\x00', // unused
    conv(pixelOffset), // iffset to pixel data

    // BMP Header
    '\x28\x00\x00\x00', // Header Size
    conv(width), // bitmap width
    conv(height), // bitmap height
    '\x01\x00', // 1 color plane
    conv(1, 2), // 1 bit per pixel
    conv(0), // Compression Type
    conv(dataLength), // bitmap size
    '\x13\x0b\x00\x00', // pixels/meter h (DPI for printing)
    '\x13\x0b\x00\x00', // pixels/meter w (DPI for printing)
    conv(colorTableLength), // color palette length
    '\x00\x00\x00\x00', // important colors (0 - all equal importance)
  ];

  // Color Table
  for (var i = 0; i < colorTableLength; ++i) {
    data.push(conv(parseInt(colorTable[i], 16)));
  }

  // Pixel Data
  data.push(imgData);

  data = data.join('');

  return 'data:image/bmp;base64,' + btoa(data);

  function conv(val, len) {
    var res = [],
      i = 0,
      len = typeof len == 'undefined' ? 4 : len;
    while (i < len) {
      res.push(String.fromCharCode((val >> (i * 8)) & 0xff));
      ++i;
    }
    return res.join('');
  }
};

// Create new pixel array based on original canvas
export const newPixelArray = canvas => {
  var context = canvas.getContext('2d');
  var width = canvas.width;
  var height = canvas.height;
  var imageData = context.getImageData(0, 0, width, height);
  var data = imageData.data;

  // canvas pixel data is 8-bit clamped view, RGBA
  // 32-bit -> 8 bit using the Alpha data (stored in 4th index)
  var pixels = [];
  for (var i = data.length - 1; i > 0; i -= 4) {
    if (i > 0) {
      if (data[i] > 50) {
        pixels.push('000000');
      } else {
        pixels.push('ffffff');
      }
    }
  }

  // reverse pixels - BMP pixel data is packed bottom to top
  var pixarray = [];
  for (var i = height - 1; i > -1; i--) {
    var row = [];
    for (var j = 0; j < width; j++) {
      row.push(pixels[i * width + j]);
    }
    for (var j in row) {
      pixarray.push(row[j]);
    }
  }

  return pixarray;
};

// Convert pixel array to monochrome (1 bit) and pack in rows based on width
export const toMonoPixels = (width, height, pixelArray) => {
  var pixels = [],
    rowsize = Math.ceil(width / 8),
    rowPadding = 0;

  // Needs to be padded to a multiple of 4 bytes
  if (rowsize % 4 != 0) {
    rowPadding = 4 - (rowsize % 4);
  }

  // 8 bit -> 1 bit plus padding bits
  var j,
    bit = 0,
    currentPixel,
    rem;
  for (var i = 0; i < height; ++i) {
    for (j = 0; j < width; ++j) {
      rem = j % 8;
      currentPixel = pixelArray.pop();
      // On or off
      if (parseInt(currentPixel, 16) != 0) {
        bit = bit | Math.pow(2, 7 - rem);
      }
      if (rem == 7 || j == width - 1) {
        pixels.push(String.fromCharCode(bit));
        bit = 0;
      }
    }
    // Pad pixels
    for (j = 0; j < rowPadding; ++j) {
      pixels.push('\x00');
    }
  }
  return pixels.join('');
};

// Create new pixel array based on original canvas
export const newPixelArrayTopaz = canvas => {
  var context = canvas.getContext('2d');
  var width = canvas.width;
  var height = canvas.height;
  var imageData = context.getImageData(0, 0, width, height);
  var data = imageData.data;

  //https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Pixel_manipulation_with_canvas
  var pixels = [];
  for (var i = 0; i < data.length; i += 4) {
    if (i > 0) {
      var avg = (data[i] + data[i + 1] + data[i + 2]) / 3;
      //Deciding between black and white pixels, example to see how change in alpha channel affects output: https://www.w3schools.com/css/tryit.asp?filename=trycss_color_values
      if (!(avg == 255)) {
        avg = 0;
      }
      data[i + 3] = avg; // setting value for alpha channel
    }
    pixels.push(data[i + 3]);
  }

  //Reverse pixels array to flip image
  var reversedPixelsArray = [];
  reversedPixelsArray = pixels.reverse();

  // reverse pixels - BMP pixel data is packed bottom to top
  var pixarray = [];
  for (var i = height - 1; i > -1; i--) {
    var row = [];
    for (var j = 0; j < width; j++) {
      row.push(reversedPixelsArray[i * width + j]);
    }
    for (var j in row) {
      pixarray.push(row[j]);
    }
  }

  return pixarray;
};

// Convert pixel array to monochrome (1 bit) and pack in rows based on width
export const toMonoPixelsTopaz = (width, height, pixelArray) => {
  var pixels = [],
    rowsize = Math.ceil(width / 8),
    rowPadding = rowsize % 4;

  // 8 bit -> 1 bit plus padding bits
  var j,
    bit = 0,
    currentPixel,
    rem;
  for (var i = 0; i < height; ++i) {
    for (j = 0; j < width; ++j) {
      rem = j % 8;
      currentPixel = pixelArray.pop();
      // On or off
      if (parseInt(currentPixel, 16) != 0) {
        bit = bit | Math.pow(2, 7 - rem);
      }
      if (rem == 7 || j == width - 1) {
        pixels.push(String.fromCharCode(bit));
        bit = 0;
      }
    }
    // Pad pixels
    for (j = 0; j < rowPadding; ++j) {
      pixels.push('\x00');
    }
  }
  return pixels.join('');
};

// Fixes canvas pixel issues on High res devices
export const scaleCanvasPixelRatio = (
  canvas,
  width = canvasSize.width,
  height = canvasSize.height,
) => {
  const context = canvas.getContext('2d');

  // Can we access the window element?
  if (typeof window === 'undefined') return;

  // Actual resultion being displayed
  const ratio = window.devicePixelRatio || 1;

  if (ratio !== 1) {
    // Double the size of the canvas on screen by dpr
    canvas.width = width * ratio;
    canvas.height = height * ratio;

    // apply CSS to make it fit in the container
    canvas.style.width = width + 'px';
    canvas.style.height = height + 'px';
  } else {
    // do nothing, continue with default width/height
    canvas.width = width;
    canvas.height = height;
    canvas.style.width = '';
    canvas.style.height = '';
  }

  context.scale(ratio, ratio);
};

const tempCanvasID = 'resize-canvas-temp';

// Helps to fix larger signature issues with high res devices.
export const resizeCanvas = (canvas, width = canvasSize.width, height = canvasSize.height) => {
  const resizedCanvas = document.createElement('canvas');
  const resizedContext = resizedCanvas.getContext('2d');

  resizedCanvas.height = `${height}`;
  resizedCanvas.width = `${width}`;
  resizedCanvas.style.display = 'none';
  resizedCanvas.id = tempCanvasID;

  resizedContext.drawImage(canvas, 0, 0, width, height);

  return resizedCanvas;
};

// Is our device scaled?
export const isCanvasScaled = () => {
  if (typeof window === 'undefined') return false;

  const dpr = window.devicePixelRatio;

  return dpr > 1 || dpr < 1;
};

export const cleanUpCanvas = () => {
  const canvasElement = document.getElementById(tempCanvasID);
  if (canvasElement === null) return;

  // remove the temp canvas.
  document.getElementById(tempCanvasID).remove();
};

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import XlinkAPI from '~/app/api/xlinkAPI';
import WebHelpers, { statusOK } from '~/app/webHelpers.js';
import ErrorHelpers from '~/app/errorHelpers.js';
import { TableHead, TableRow, TableCell, TableBody, Table, Typography } from '@material-ui/core';
import { FORM_NAMES, EIN_ENTITY_TYPES } from '~/app/constants.js';
import { actions as formViewerActions } from '~/app/redux/modules/formViewer';
import { actions as returnListActions } from '~/app/redux/returnList/duck';
import { actions as returnProfileActions } from '~/app/redux/returnProfile/duck';
import magicWand from '~/images/icons/icons8-magic-wand.svg';
import { styles } from './css/currentYearTransferStyles.js';
import './css/currentYearTransfer.css';

const mapStateToProps = state => {
  return {
    cyReturns: state.formViewer.cyReturns,
  };
};

const DUPLICATE_SSN_MSG =
  'A return with this SSN and the following filing statuses already exists for this tax year. Choose one of the existing returns to open or create a new return.';
const DUPLICATE_EIN_MSG =
  'A return with this EIN and Entity type already exists for this tax year. You may open this return or cancel and try again.';

const mapDispatchToProps = {
  ...formViewerActions,
  ...returnListActions,
  ...returnProfileActions,
};

class CurrentYearTransfer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowSelected: null,
      returnSelected: 0,
      fixReturnDialog: false,
      remainingStatuses: [1, 2, 3, 4, 5],
      createNew: false,
      filingStatus: 0,
      return: {},
      rowClicked: false,
    };
  }

  handleCancelClick = () => {
    this.props.onCancelCurrentYear();
    this.props.setIsWizard(false);
  };

  /**
   * Handles either state whether they click add new return or choose an existing return
   *
   * @param {Number} rtn the returnID used to fetch the return that was selected
   * @param {Number} idx index of return that is listed
   */
  handleAddNewClick = (rtn, idx) => {
    rtn !== 0
      ? this.setState({
          rowSelected: idx,
          filingStatus: idx,
          createNew: false,
          return: rtn,
          rowClicked: true,
        })
      : this.setState({
          rowSelected: null,
          filingStatus: null,
          createNew: true,
          rowClicked: true,
        });
  };

  /** Handles determining whether the user selected a existing return, or create a new return */
  handleYesClick = () => {
    if (this.state.createNew === true) {
      this.props.confirmCreateNewRtn();
    } else {
      this.onOpenReturn(this.state.return);
      this.props.closeAddNewReturnModal();
    }
  };

  onOpenReturn = async ret => {
    const { setActiveReturn, setInitialForm } = this.props;
    try {
      const resp = await XlinkAPI.getReturnProfileByReturnID(ret.returnID);
      if (statusOK(resp)) {
        const arr = [];
        resp.data.attachments === null
          ? (ret.attachments = [])
          : (ret.attachments = resp.data.attachments);
        ret.year = WebHelpers.getJWTPayload().season;
        ret.ssnein = this.props.ssnein;
        ret.returnStatus = ret.return_status;
        ret = { ...ret, ...resp.data };
        setActiveReturn(ret, arr);

        if (ret.wizard_mode) {
          setInitialForm(FORM_NAMES.EST, null);
          this.props.history.push({ pathname: '/wizard-mode' });
        } else {
          setInitialForm(FORM_NAMES.CDS, null);
          this.props.history.push({ pathname: '/tax-return' });
        }
      }
    } catch (error) {
      ErrorHelpers.handleError(
        'Failed to open return',
        ErrorHelpers.createSimpleError(
          'Unable to open this return. If the problem persists, please contact technical support.',
        ),
      );
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Typography
          classes={{ root: classes.currentYearTransferTitle }}
          variant="body2"
          id="simple-modal-title"
        >
          Duplicate {this.props.isBusiness ? 'EIN' : 'SSN'}
        </Typography>
        <Typography classes={{ root: classes.currentYearTransferSubTitle }}>
          {this.props.isBusiness ? DUPLICATE_EIN_MSG : DUPLICATE_SSN_MSG}
        </Typography>
        <div className="current-year-transfer-table-container">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Existing {this.props.isBusiness ? 'Entity Type' : 'Filing Statuses'}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.currentYearReturns.map((rtn, index) => {
                return (
                  <TableRow
                    classes={{
                      root: classes.tableRowRoot,
                      selected: classes.tableRowSelected,
                    }}
                    key={`existingFLST-${index}`}
                    hover={true}
                    selected={this.state.rowSelected === index}
                    onClick={() => this.handleAddNewClick(rtn, index)}
                  >
                    <TableCell>
                      {this.props.isBusiness
                        ? EIN_ENTITY_TYPES?.[rtn.filingStatus]
                        : rtn.filingStatus}
                      {rtn.WizardMode && (
                        <img
                          id="wizardModeReturnIcon"
                          src={magicWand}
                          aria-describedby="This is a Wizard Mode return"
                          className="current-year-transfer-existing-rtn-icon"
                        />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        {this.state.remainingStatuses.length > 0 && !this.props.isBusiness && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Create New Return</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                classes={{
                  root: classes.tableRowRoot,
                  selected: classes.tableRowSelected,
                }}
                key={0}
                hover={true}
                selected={this.state.createNew}
                onClick={() => this.handleAddNewClick(0, 0)}
              >
                <TableCell>New Return</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
        <div className="current-year-transfer-button-container">
          <Button classes={{ root: classes.btnOptionsNoBorder }} onClick={this.handleCancelClick}>
            Cancel
          </Button>
          <Button
            classes={{ root: classes.btnOptionsRoot }}
            onClick={() => this.handleYesClick()}
            disabled={!this.state.rowClicked}
          >
            {this.state.createNew ? 'Create' : 'Open'}
          </Button>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(CurrentYearTransfer)),
);

// App Duck Module
import { AUTH_STAGE, SIDE_TABS } from '~/app/constants.js';
import { LOGOUT } from '~/app/redux/commonActions.js';

export const SET_EFIN_OWNER_FLAG = 'xlinkonline/app/SETEFINOWNERFLAG';
export const SET_OFFICE_LINKED_TO_PREPARER_FLAG = 'xlinkonline/app/SETOFFICELINKEDTOPREPARER';
export const SELECT_TITLE_TAB = 'xlinkonline/app/SELECTTITLE';
const UPDATE_PAGE_STATE = 'xlinkonline/app/UPDATEPAGESTATE';
const UPDATE_SIDE_TAB = 'xlinkonline/app/UPDATESIDETAB';
const SET_NAVIGATION = 'xlinkonline/app/SETNAVIGATION';
const SELECT_SIDEBAR_TAB = 'xlinkonline/app/SELECTSIDEBARTAB';
const TOGGLE_ACCOUNT = 'xlinkonline/app/TOGGLEACCOUNT';
const TOGGLE_VERSIONS = 'xlinkonline/app/TOGGLEVERSIONS';
const TOGGLE_HELP = 'xlinkonline/app/TOGGLEHELP';
const TOGGLE_SEARCH_BAR = 'xlinkonline/app/TOGGLESEARCHBAR';
export const LOGIN = 'xlinkonline/app/LOGIN';
const AWAIT_CREDENTIALS = 'xlinkonline/app/AWAITCREDENTIALS';
const AWAIT_SECOND_FACTOR = 'xlinkonline/app/AWAITSECONDFACTOR';
const AWAIT_REVIEW_DEVICE = 'xlinkonline/app/AWAITREVIEWDEVICE';
const FORGOT_PASSWORD = 'xlinkonline/app/FORGOTPASSWORD';
const FORGOT_USERNAME = 'xlinkonline/app/FORGOT_USERNAME';
const FINISH_SECOND_FACTOR = 'xlinkonline/app/FINISHSECONDFACTOR';
const AWAIT_PICK_OFFICE = 'xlinkonline/app/AWAIT_PICK_OFFICE';
const AWAIT_IRS_MESSAGE = 'xlinkonline/app/AWAIT_IRS_MESSAGE';
const AWAIT_TOC_MESSAGE = 'xlinkonline/app/AWAIT_TOC_MESSAGE';
const ON_ERROR_SHOW = 'xlinkonline/app/SHOW_ERROR';
const ON_ERROR_HIDE = 'xlinkonline/app/HIDE_ERROR';
const TOGGLE_CREATE_ACCOUNT = 'xlinkonline/app/TOGGLECREATEACCOUNT';
const TOGGLE_NO_CAPTURE_SIGNATURE = 'xlinkonline/app/TOGGLENOCAMPTURESIGNATURE';
const TOGGLE_SIGNATURE = 'xlinkonline/app/TOGGLESIGNATURE';
const TOGGLE_SIGNATURE_PREVIEW = 'xlinkonline/app/TOGGLESIGNATUREPREVIEW';
const SET_ONSCREEN_SIGNATURE_STATE = 'xlinkonline/app/SETONSCREENSIGNATURESTATE';
const SET_TOPAZ_SIGNATURE_STATE = 'xlinkonline/app/SETTOPAZSIGNATURESTATE';
const SET_SCRIPTEL_SIGNATURE_STATE = 'xlinkonline/app/SETSCRIPTELSIGNATURESTATE';
const TOGGLE_SWITCH_OFFICE_YEAR = 'xlinkonline/app/TOGGLE_SWITCH_OFFICE_YEAR';
const TOGGLE_ADD_AVATAR = 'xlinkonline/app/TOGGLEADDAVATAR';
const TOGGLE_CREATE_PREPARER = 'xlinkonline/app/TOGGLECREATEPREPARER';
const SET_MFA_METHOD = 'xlinkonline/app/SETMFAMETHOD';
const SET_TITLE_TAB_PROPERTIES = 'xlinkonline/app/SETTITLETABPROPERTIES';
const SET_BROWSER_FINGERPRINT = 'xlinkonline/app/SETBROWSERFINGERPRINT';
const INIT_SCROLL_POS_TRUE = 'xlinkonline/app/INITSCROLLPOSTRUE';
const INIT_SCROLL_POS_FALSE = 'xlinkonline/app/INITSCROLLPOSFALSE';
const OPEN_ESTIMATOR = 'xlinkonline/app/OPENESTIMATOR';
const CLOSE_ESTIMATOR = 'xlinkonline/app/CLOSEESTIMATOR';
export const SET_AVATAR = 'xlinkonline/app/SETAVATAR';
export const TOGGLE_MOBILE_IMPORT_MODAL = 'xlinkonline/app/TOGGLEMOBILEIMPORTMODAL';
const CLOSE_HELP_MODAL = 'xlinkonline/app/CLOSEHELPMODAL';
export const SELECT_TITLE_TAB_WITH_FILTER = 'xlinkonline/app/SELECT_TITLE_TAB_WITH_FILTER';
const CLEAR_TITLE_TAB_FILTER_BY_NAME = 'xlinkonline/app/CLEAR_TITLE_TAB_FILTER_BY_NAME';
const SET_LOGGED_IN_BASIC_INFO = 'xlinkonline/app/SET_LOGGED_IN_BASIC_INFO';
const TOGGLE_IMPERSONATION = 'xlinkonline/app/TOGGLE_IMPERSONATION';
const TOGGLE_APP_VERSION_DIALOG = 'xlinkonline/app/TOGGLE_APP_VERSION_DIALOG';
const SET_RETURN_OPEN = 'xlinkonline/app/SET_RETURN_OPEN';
const ON_SNACKBAR_MESSAGE_SHOW = 'app/ON_SNACKBAR_MESSAGE_SHOW';
const ON_SNACKBAR_MESSAGE_HIDE = 'app/ON_SNACKBAR_MESSAGE_HIDE';
const SET_TOP_MOST_PARENT_ROLE = 'xlinkonline/app/SETTOPMOSTPARENTROLE';
const UPDATE_REFRESH_TIMER = 'xlinkonline/app/UPDATE_REFRESH_TIMER';

export const initialState = {
  tabState: {
    tabMap: {
      [SIDE_TABS.DASHBOARD_MAIN]: [
        {
          name: 'Overview',
          visible: true,
        },
      ],
      [SIDE_TABS.PREPARERS]: [
        {
          name: 'Preparers',
          visible: true,
        },
      ],
      [SIDE_TABS.PACKAGES]: [
        {
          name: 'State Packages',
          visible: true,
        },
      ],
      [SIDE_TABS.SERVER]: [
        {
          name: 'Server',
          visible: true,
        },
      ],
      [SIDE_TABS.USER_LOOKUP]: [
        {
          name: 'User Lookup',
          visible: true,
        },
      ],
      [SIDE_TABS.TECH_LOGINS]: [
        {
          name: 'Tech Logins',
          visible: true,
        },
      ],
      [SIDE_TABS.CALENDAR]: [
        {
          name: 'Calendar',
          visible: true,
        },
      ],
      [SIDE_TABS.MESSAGES]: [
        {
          name: 'Notifications',
          visible: true,
        },
      ],
      [SIDE_TABS.REPORTING]: [
        {
          name: 'Quick Reports',
          visible: true,
        },
        {
          name: 'Custom Reports',
          visible: true,
        },
      ],
      [SIDE_TABS.MONEY_TRANSFER]: [
        {
          name: 'Money Transfer',
          visible: true,
        },
      ],
      [SIDE_TABS.CHECKS]: [
        {
          name: 'Ready To Print',
          visible: true,
        },
        {
          name: 'Checks Register',
          visible: true,
        },
      ],
      [SIDE_TABS.CUSTOMER_LIST]: [
        {
          name: 'Customers',
          visible: true,
        },
      ],
      [SIDE_TABS.COPYRIGHT]: [
        {
          name: 'Copyright',
          visible: false,
        },
      ],
      [SIDE_TABS.USER_PROFILE]: [
        {
          name: 'User Profile',
        },
      ],
      [SIDE_TABS.PAYMENTS]: [
        {
          name: 'Payments',
          visible: true,
        },
      ],
      [SIDE_TABS.MOVE_RETURN]: [
        {
          name: 'Move Return',
          visible: true,
        },
      ],
    },
    selectedSidebarTab: SIDE_TABS.DASHBOARD_MAIN, // key to tabmap
    selectedTitleTab: 0, // index of selected title tab
  },
  isAccountVisible: false,
  isVersions: false,
  isHelpMenuVisible: false,
  showSignaturePad: false,
  showSignaturePreview: false,
  isSearchBarVisible: false,
  isTaxyearOfficeSwitchVisible: false,
  isOfficeSwitchVisible: false,
  isTaxyearSwitchVisible: false,
  isAddAvatarVisible: false,
  loggedInUser: {
    firstName: '',
    lastName: '',
    accessLevel: '',
    userID: -1, // loginID
    displayName: '',
    email: '',
  },
  alert: {
    title: '',
    show: false,
    body: '',
    bodyTwo: '',
  },
  snackbarMessage: {
    show: false,
    message: '',
    severity: '',
    autoHideDuration: 5000, // 5 seconds
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
  },
  createAccount: false,
  createPreparer: false,
  MFA_Method: 'text',
  authStage: AUTH_STAGE.LOGGED_OUT,
  isAuthenticated: false,
  browserFingerprint: '',
  avatarBase64: '',
  openEstimator: false,
  mobileImportModal: false,
  pageState: 0,
  sideTab: 0,
  currentNavigation: 0,
  scrollPositionInit: true,
  displayAccountOptsOnly: false,
  userIsEfinOwner: 0,
  userHasPreparerLink: 0,
  showNoCaptureSignMessage: false,
  loggedInBasicInfo: {},
  impersonation: false,
  appVersionDialog: false,
  returnOpen: false,
  showOnScreenPad: false,
  showTopazPad: false,
  showScriptelPad: false,
  topMostParentRole: 0,
  refreshTokenExpireTime: 0, // the expiration time is included in the access_token - in miliseconds
};

export const actions = {
  updateLinkToPreparerValue: userIsLinkedToPreparer => ({
    type: SET_OFFICE_LINKED_TO_PREPARER_FLAG,
    userIsLinkedToPreparer,
  }),
  updateEfinOwnerValue: loggedAsEfinOwner => ({
    type: SET_EFIN_OWNER_FLAG,
    loggedAsEfinOwner,
  }),
  onSelectTitleTab: tabID => ({
    type: SELECT_TITLE_TAB,
    tabID,
  }),
  updatePageState: page => ({
    type: UPDATE_PAGE_STATE,
    page,
  }),
  updateSideTab: tab => ({
    type: UPDATE_SIDE_TAB,
    tab,
  }),
  setNavigation: route => ({
    type: SET_NAVIGATION,
    route,
  }),
  selectTitleTabByNameFiltered: (tabName, filter) => ({
    type: SELECT_TITLE_TAB_WITH_FILTER,
    tabName,
    filter,
  }),
  clearTitleTabFilterByName: tabName => ({
    type: CLEAR_TITLE_TAB_FILTER_BY_NAME,
    tabName,
  }),
  onSelectSidebarTab: tabID => ({
    type: SELECT_SIDEBAR_TAB,
    tabID,
  }),
  onOpenEstimator: () => ({
    type: OPEN_ESTIMATOR,
  }),
  onCloseEstimator: () => ({
    type: CLOSE_ESTIMATOR,
  }),
  toggleAccountModal: () => ({
    type: TOGGLE_ACCOUNT,
  }),
  toggleVersions: () => ({
    type: TOGGLE_VERSIONS,
  }),
  toggleHelpModal: () => ({
    type: TOGGLE_HELP,
  }),
  toggleSearchBar: flag => ({
    type: TOGGLE_SEARCH_BAR,
    flag,
  }),
  onLogin: user => ({
    type: LOGIN,
    user,
  }),
  setAuthStageAwaitCredentials: () => ({
    type: AWAIT_CREDENTIALS,
  }),
  setAuthStageAwaitSecondFactor: () => ({
    type: AWAIT_SECOND_FACTOR,
  }),
  setAuthStageFinishSecondFactor: () => ({
    type: FINISH_SECOND_FACTOR,
  }),
  setAuthStagePickOffice: () => ({
    type: AWAIT_PICK_OFFICE,
  }),
  setAuthStageForgotPassword: () => ({
    type: FORGOT_PASSWORD,
  }),
  setAuthStageForgotUsername: () => ({
    type: FORGOT_USERNAME,
  }),
  setAuthStageAwaitIRSMessage: () => ({
    type: AWAIT_IRS_MESSAGE,
  }),
  setAuthStageAwaitTermsAndConditions: () => ({
    type: AWAIT_TOC_MESSAGE,
  }),
  setAuthStageAwaitReviewDevice: () => ({
    type: AWAIT_REVIEW_DEVICE,
  }),
  onLogout: user => ({
    type: LOGOUT,
    user,
  }),
  showError: data => ({
    type: ON_ERROR_SHOW,
    data,
  }),
  hideError: () => ({
    type: ON_ERROR_HIDE,
  }),
  showSnackbarMessage: (
    message,
    severity = 'info',
    autoHideDuration = 5000,
    anchorOrigin = { vertical: 'bottom', horizontal: 'center' },
    initialField = null,
  ) => ({
    type: ON_SNACKBAR_MESSAGE_SHOW,
    message,
    severity,
    autoHideDuration,
    anchorOrigin,
    initialField,
  }),
  hideSnackbarMessage: () => ({
    type: ON_SNACKBAR_MESSAGE_HIDE,
  }),
  toggleImpersonation: toggle => ({
    type: TOGGLE_IMPERSONATION,
    toggle,
  }),
  toggleCreateAccount: (displayAccountOptsOnly = false) => ({
    type: TOGGLE_CREATE_ACCOUNT,
    displayAccountOptsOnly,
  }),
  toggleNoCaptureSignature: () => ({
    type: TOGGLE_NO_CAPTURE_SIGNATURE,
  }),
  toggleSignature: () => ({
    type: TOGGLE_SIGNATURE,
  }),
  toggleSignaturePreview: () => ({
    type: TOGGLE_SIGNATURE_PREVIEW,
  }),
  setOnscreenSignatureState: toggle => ({
    type: SET_ONSCREEN_SIGNATURE_STATE,
    toggle,
  }),
  setTopazSignatureState: toggle => ({
    type: SET_TOPAZ_SIGNATURE_STATE,
    toggle,
  }),
  setScriptelSignatureState: toggle => ({
    type: SET_SCRIPTEL_SIGNATURE_STATE,
    toggle,
  }),
  toggleSwitchOfficeYear: (toggleSwitchTaxYearOrOfficeModal = 0) => ({
    type: TOGGLE_SWITCH_OFFICE_YEAR,
    toggleSwitchTaxYearOrOfficeModal,
  }),
  toggleCreatePreparer: () => ({
    type: TOGGLE_CREATE_PREPARER,
  }),
  toggleAddAvatar: () => ({
    type: TOGGLE_ADD_AVATAR,
  }),
  setMFAMethod: method => ({
    type: SET_MFA_METHOD,
    method,
  }),
  setTitleTabProperties: (text, visible, tabMapIdx, btnIdx) => ({
    type: SET_TITLE_TAB_PROPERTIES,
    text,
    visible,
    tabMapIdx,
    btnIdx,
  }),
  setBrowserFingerprint: fp => ({
    type: SET_BROWSER_FINGERPRINT,
    fp,
  }),
  setAvatar: avatar => ({
    type: SET_AVATAR,
    avatar,
  }),
  toggleMobileImportModal: toggle => ({
    type: TOGGLE_MOBILE_IMPORT_MODAL,
    toggle,
  }),
  closeHelpModal: () => ({
    type: CLOSE_HELP_MODAL,
  }),
  setInitScrollPositionTrue: () => ({
    type: INIT_SCROLL_POS_TRUE,
  }),
  setInitScrollPositionFalse: () => ({
    type: INIT_SCROLL_POS_FALSE,
  }),
  setLoggedInBasicInfo: basicInfo => ({
    type: SET_LOGGED_IN_BASIC_INFO,
    basicInfo,
  }),
  toggleAppVersionDialog: toggle => ({
    type: TOGGLE_APP_VERSION_DIALOG,
    toggle,
  }),
  setReturnOpen: flag => ({
    type: SET_RETURN_OPEN,
    flag,
  }),
  setTopMostParentRole: parent => ({
    type: SET_TOP_MOST_PARENT_ROLE,
    parent,
  }),
  updateRefreshTimer: value => ({
    type: UPDATE_REFRESH_TIMER,
    value,
  }),
};

/*
We need to make sure we are copying all levels of our nested data when passing the state back.
https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns
*/
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_ESTIMATOR:
      return {
        ...state,
        openEstimator: true,
      };
    case CLOSE_ESTIMATOR:
      return {
        ...state,
        openEstimator: false,
      };
    case SET_EFIN_OWNER_FLAG:
      return {
        ...state,
        userIsEfinOwner: action.loggedAsEfinOwner,
      };
    case SET_OFFICE_LINKED_TO_PREPARER_FLAG:
      return {
        ...state,
        userHasPreparerLink: action.userIsLinkedToPreparer,
      };
    case SELECT_TITLE_TAB: {
      // There may be a filter applied to one of the tabs. We should remove it when we are going to a new tab.
      const tabObj = Object.assign(
        {},
        state.tabState.tabMap[SIDE_TABS.DASHBOARD_MAIN][action.tabID],
      );
      delete tabObj.filter;
      return {
        ...state,
        tabState: {
          ...state.tabState,
          tabMap: {
            ...state.tabState.tabMap,
            [SIDE_TABS.DASHBOARD_MAIN]: [
              ...state.tabState.tabMap[SIDE_TABS.DASHBOARD_MAIN].slice(0, action.tabID),
              tabObj,

              ...state.tabState.tabMap[SIDE_TABS.DASHBOARD_MAIN].slice(action.tabID + 1),
            ],
          },
          selectedTitleTab: action.tabID,
        },
      };
    }
    case UPDATE_PAGE_STATE:
      return {
        ...state,
        pageState: action.page,
      };
    case UPDATE_SIDE_TAB:
      return {
        ...state,
        sideTab: action.tab,
      };
    case SET_NAVIGATION:
      return {
        ...state,
        currentNavigation: action.route,
      };
    case CLEAR_TITLE_TAB_FILTER_BY_NAME: {
      const tabIDFoundIdx = state.tabState.tabMap[SIDE_TABS.DASHBOARD_MAIN].findIndex(tab => {
        return tab.name === action.tabName;
      });
      const newTab = Object.assign(
        {},
        state.tabState.tabMap[SIDE_TABS.DASHBOARD_MAIN][tabIDFoundIdx],
      );
      delete newTab.filter;
      return {
        ...state,
        tabState: {
          ...state.tabState,
          tabMap: {
            ...state.tabState.tabMap,
            [SIDE_TABS.DASHBOARD_MAIN]: [
              ...state.tabState.tabMap[SIDE_TABS.DASHBOARD_MAIN].slice(0, tabIDFoundIdx),
              newTab,
              ...state.tabState.tabMap[SIDE_TABS.DASHBOARD_MAIN].slice(tabIDFoundIdx + 1),
            ],
          },
          selectedTitleTab: tabIDFoundIdx,
        },
      };
    }
    case SELECT_TITLE_TAB_WITH_FILTER: {
      // Fetch the titleTabID based on the name passed in
      // Title Tabs are nested within the tabMap with the key: SIDE_TABS.DASHBOARD_MAIN
      const tabIDFound = state.tabState.tabMap[SIDE_TABS.DASHBOARD_MAIN].findIndex(tab => {
        return tab.name === action.tabName;
      });
      const newTitleTab = Object.assign(
        {},
        state.tabState.tabMap[SIDE_TABS.DASHBOARD_MAIN][tabIDFound],
      );
      newTitleTab.filter = [action.filter];
      return {
        ...state,
        tabState: {
          ...state.tabState,
          tabMap: {
            ...state.tabState.tabMap,
            [SIDE_TABS.DASHBOARD_MAIN]: [
              ...state.tabState.tabMap[SIDE_TABS.DASHBOARD_MAIN].slice(0, tabIDFound),
              newTitleTab,
              ...state.tabState.tabMap[SIDE_TABS.DASHBOARD_MAIN].slice(tabIDFound + 1),
            ],
          },
          selectedTitleTab: tabIDFound,
        },
      };
    }
    case SET_TITLE_TAB_PROPERTIES:
      return {
        ...state,
        tabState: {
          ...state.tabState,
          tabMap: {
            ...state.tabState.tabMap,
            [action.tabMapIdx]: [
              ...state.tabState.tabMap[action.tabMapIdx].slice(0, action.btnIdx),
              Object.assign({}, state.tabState.tabMap[action.tabMapIdx][action.btnIdx], {
                name: action.text,
                visible: action.visible,
              }),
              ...state.tabState.tabMap[action.tabMapIdx].slice(action.btnIdx + 1),
            ],
          },
        },
      };
    case SELECT_SIDEBAR_TAB:
      return {
        ...state,
        tabState: {
          ...state.tabState,
          selectedSidebarTab: action.tabID,
          selectedTitleTab: 0, // When we switch sidebar context, show the first title tab
        },
      };
    case TOGGLE_ACCOUNT:
      return {
        ...state,
        isAccountVisible: !state.isAccountVisible,
      };
    case TOGGLE_VERSIONS:
      return {
        ...state,
        isVersions: !state.isVersions,
      };
    case TOGGLE_HELP:
      return {
        ...state,
        isHelpMenuVisible: !state.isHelpMenuVisible,
      };
    case TOGGLE_SEARCH_BAR:
      return {
        ...state,
        isSearchBarVisible: action.flag,
      };
    case LOGIN:
      return {
        ...state,
        loggedInUser: {
          ...state.loggedInUser,
          firstName: action.user.first_name,
          lastName: action.user.last_name,
          accessLevel: action.user.title,
          userID: action.user.login_id,
          displayName: action.user.display_name,
          email: action.user.email,
        },
        authStage: AUTH_STAGE.LOGGED_IN,
        isAuthenticated: true,
      };
    case AWAIT_CREDENTIALS:
      return {
        ...state,
        authStage: AUTH_STAGE.AWAIT_CREDENTIALS,
      };
    case AWAIT_SECOND_FACTOR:
      return {
        ...state,
        authStage: AUTH_STAGE.AWAIT_SECOND_FACTOR,
      };
    case AWAIT_IRS_MESSAGE:
      return {
        ...state,
        authStage: AUTH_STAGE.AWAIT_IRS_MESSAGE,
      };
    case AWAIT_TOC_MESSAGE:
      return {
        ...state,
        authStage: AUTH_STAGE.AWAIT_TOC_MESSAGE,
      };
    case FINISH_SECOND_FACTOR:
      return {
        ...state,
        authStage: AUTH_STAGE.FINISH_SECOND_FACTOR,
      };
    case AWAIT_PICK_OFFICE:
      return {
        ...state,
        authStage: AUTH_STAGE.AWAIT_PICK_OFFICE,
      };
    case FORGOT_PASSWORD:
      return {
        ...state,
        authStage: AUTH_STAGE.FORGOT_PASSWORD,
      };
    case FORGOT_USERNAME:
      return {
        ...state,
        authStage: AUTH_STAGE.FORGOT_USERNAME,
      };
    case AWAIT_REVIEW_DEVICE:
      return {
        ...state,
        authStage: AUTH_STAGE.AWAIT_REVIEW_DEVICE,
      };
    case LOGOUT:
      // Remove the AWSALB cookie on logout.
      // How to delete a cookie.
      // https://stackoverflow.com/questions/10593013/delete-cookie-by-name
      document.cookie = 'AWSALB=; expires = Thu, 01 Jan 1970 00:00:00 GMT';
      // cleanup session storage.
      sessionStorage.removeItem('emailAddress2ndFactor');
      sessionStorage.removeItem('text');
      sessionStorage.removeItem('email');
      return {
        ...initialState,
      };
    case ON_ERROR_SHOW:
      return {
        ...state,
        alert: {
          title: action.data.title,
          show: true,
          body: action.data.body,
          bodyTwo: action.data.bodyTwo,
        },
      };
    case ON_ERROR_HIDE:
      return {
        ...state,
        alert: {
          ...state.alert,
          show: false,
        },
      };
    case ON_SNACKBAR_MESSAGE_SHOW:
      return {
        ...state,
        snackbarMessage: {
          show: true,
          message: action.message,
          severity: action.severity,
          autoHideDuration: action.autoHideDuration,
          anchorOrigin: action.anchorOrigin,
          initialField: action.initialField,
        },
      };
    case ON_SNACKBAR_MESSAGE_HIDE:
      return {
        ...state,
        snackbarMessage: {
          ...state.snackbarMessage,
          show: false,
        },
      };
    case TOGGLE_IMPERSONATION:
      return {
        ...state,
        impersonation: action.toggle,
      };
    case TOGGLE_CREATE_ACCOUNT:
      return {
        ...state,
        createAccount: !state.createAccount,
        displayAccountOptsOnly: action.displayAccountOptsOnly,
      };
    case TOGGLE_SWITCH_OFFICE_YEAR:
      if (action.toggleSwitchTaxYearOrOfficeModal === 0) {
        return {
          ...state,
          isOfficeSwitchVisible: false,
          isTaxyearSwitchVisible: false,
          isTaxyearOfficeSwitchVisible: !state.isTaxyearOfficeSwitchVisible,
        };
      } else if (action.toggleSwitchTaxYearOrOfficeModal === 1) {
        // Toggle the tax year switch
        return {
          ...state,
          isOfficeSwitchVisible: false,
          isTaxyearSwitchVisible: true,
          isTaxyearOfficeSwitchVisible: !state.isTaxyearOfficeSwitchVisible,
        };
      } else {
        return {
          ...state,
          isOfficeSwitchVisible: true,
          isTaxyearSwitchVisible: false,
          isTaxyearOfficeSwitchVisible: !state.isTaxyearOfficeSwitchVisible,
        };
      }
    case TOGGLE_NO_CAPTURE_SIGNATURE:
      return {
        ...state,
        showNoCaptureSignMessage: !state.showNoCaptureSignMessage,
      };
    case TOGGLE_SIGNATURE:
      return {
        ...state,
        showSignaturePad: !state.showSignaturePad,
      };
    case SET_ONSCREEN_SIGNATURE_STATE:
      return {
        ...state,
        showOnScreenPad: action.toggle,
      };
    case SET_TOPAZ_SIGNATURE_STATE:
      return {
        ...state,
        showTopazPad: action.toggle,
      };
    case SET_SCRIPTEL_SIGNATURE_STATE:
      return {
        ...state,
        showScriptelPad: action.toggle,
      };
    case TOGGLE_SIGNATURE_PREVIEW:
      return {
        ...state,
        showSignaturePreview: !state.showSignaturePreview,
      };
    case TOGGLE_CREATE_PREPARER:
      return {
        ...state,
        createPreparer: !state.createPreparer,
      };
    case TOGGLE_ADD_AVATAR:
      return {
        ...state,
        isAddAvatarVisible: !state.isAddAvatarVisible,
      };
    case SET_MFA_METHOD:
      return {
        ...state,
        MFA_Method: action.method,
      };
    case SET_BROWSER_FINGERPRINT:
      return {
        ...state,
        browserFingerprint: action.fp,
      };
    case SET_AVATAR:
      return {
        ...state,
        avatarBase64: action.avatar,
      };
    case TOGGLE_MOBILE_IMPORT_MODAL:
      return {
        ...state,
        mobileImportModal: action.toggle,
      };
    case CLOSE_HELP_MODAL:
      return {
        ...state,
        isHelpMenuVisible: false,
      };
    case INIT_SCROLL_POS_TRUE:
      return {
        ...state,
        scrollPositionInit: true,
      };
    case INIT_SCROLL_POS_FALSE:
      return {
        ...state,
        scrollPositionInit: false,
      };
    case SET_LOGGED_IN_BASIC_INFO:
      return {
        ...state,
        loggedInBasicInfo: action.basicInfo,
      };
    case TOGGLE_APP_VERSION_DIALOG:
      return {
        ...state,
        appVersionDialog: action.toggle,
      };
    case SET_RETURN_OPEN:
      return {
        ...state,
        returnOpen: action.flag,
      };
    case SET_TOP_MOST_PARENT_ROLE:
      return {
        ...state,
        topMostParentRole: action.parent,
      };
    case UPDATE_REFRESH_TIMER:
      return {
        ...state,
        refreshTokenExpireTime: action.value,
      };
  }
  return state;
}

import { createMuiTheme } from "@material-ui/core/styles";

//Colors for input
var palettePrimary = { light: "#0077FF", main: "#0077FF", dark: "#0077FF" };
var paletteSecondary = { light: "#5A94DA", main: "#5A94DA", dark: "#5A94DA" };
var inputBackgroundColorDefault = "#F3F9FF";
var inputBackgroundColorDefaultReadOnly = "#EBEDF8";
var inputBorderColorDefault = "1px solid #8FC3FF";
var inputBorderColorDefaultReadOnly = "1px solid #EBEDF8";
var inputBorderColorDefaultFocused = "1px solid #0077FF";
var inputBorderColorDefaultError = "1px solid #ED6C72";
var buttonBorderColorDefault = "2px solid #0077FF";
var colorPrimary = "#0077FF";
var colorSecondary = "#5A94DA";

//TODO: Get color from props, as global?
var color = "basic";

switch (color) {
  case "basic":
    inputBackgroundColorDefault = "#e3ecf5";
    inputBorderColorDefault = "1px solid #8FC3FF";
    buttonBorderColorDefault = "1px solid #0077FF";
    palettePrimary = { light: "#0077FF", main: "#0077FF", dark: "#0077FF" };
    paletteSecondary = { light: "#5A94DA", main: "#5A94DA", dark: "#5A94DA" };
    colorPrimary = "#0077FF";
    colorSecondary = "#5A94DA";
    break;
  case "green":
    inputBackgroundColorDefault = "#F5FFF3";
    inputBorderColorDefault = "1px solid #259300";
    buttonBorderColorDefault = "1px solid #259300";
    palettePrimary = { light: "#259300", main: "#259300", dark: "#259300" };
    paletteSecondary = { light: "#F5FFF3", main: "#F5FFF3", dark: "#F5FFF3" };
    colorPrimary = "#259300";
    colorSecondary = "#F5FFF3";
    break;
  default:
    break;
}

export default createMuiTheme({
  palette: {
    primary: palettePrimary,
    secondary: paletteSecondary,
  },
  typography: {
    useNextVariants: true,
  },

  overrides: {
    MuiInput: {
      // Name of the component
      root: {
        // Name of the rule
        marginBottom: "5px",
        marginTop: "5px",
        borderRadius: 4,
        backgroundColor: inputBackgroundColorDefault,
        border: inputBorderColorDefault,
        fontSize: "14px",
        padding: "10px 12px",
        height: "35px",
        fontFamily: "Roboto",
        color: "#354052",
      },
      focused: {
        border: inputBorderColorDefaultFocused,
        backgroundColor: "white",
      },
      error: {
        border: inputBorderColorDefaultError,
      },
      disabled: {
        border: inputBorderColorDefaultReadOnly,
        backgroundColor: inputBackgroundColorDefaultReadOnly,
      },
      underline: {
        "&:hover": {
          borderBottom: "0px",
        },
        "&:before": {
          borderBottom: "0px",
        },
        "&:after": {
          borderBottom: "0px",
        },
      },
    },

    MuiTooltip: {
      tooltip: {
        fontFamily: "Roboto",
        fontSize: "16px",
      },
    },

    MuiInputLabel: {
      root: {
        color: "#354052",
        fontFamily: "Roboto",
        fontSize: "16px",
      },
    },

    MuiCheckbox: {
      root: {
        backgroundColor: inputBorderColorDefault,
      },
    },

    MuiTypography: {
      root: {
        verticalAlign: "middle",
        cursor: "pointer",
        marginLeft: "0em",
        paddingLeft: "0em",
      },
    },

    MuiButton: {
      // Name of the component
      root: {
        // Name of the rule
        borderRadius: 4,
        height: "35px",
        padding: "0 5px",
        boxShadow: "none",
        outline: "none !important",
        textTransform: "capitalize",
        color: colorPrimary,
        border: buttonBorderColorDefault,
      },
      contained: {
        color: "white",
        outline: "none",
        textTransform: "capitalize",
      },
      disabled: {
        cursor: "not-allowed",
        border: "1px solid #C0C5CF",
        color: "#FFFFFF",
        textTransform: "capitalize",
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: colorPrimary,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: 'white',
      },
    },
    MuiPickersDay: {
      day: {
        color: colorPrimary,
      },
      selected: {
        backgroundColor: colorPrimary,
      },
      current: {
        color: colorSecondary,
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: colorPrimary,
      },
    },

    MuiMenuItem: {
      root: {
        height: ".5rem",
        marginLeft: "0px",
        "&$selected": {
          backgroundColor: "white",
          borderLeft: "1px solid #F0F2FA",
          borderTop: "1px solid #F0F2FA",
          borderBottom: "1px solid #F0F2FA",
        },
        "&:hover": {
          backgroundColor: "#EBEDF8",
        },
      },
      selected: {},
    },
  },
});

import React from 'react';
// External imports
import { Grid, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
// Styling imports
import { styles } from '~/app/Pages/WizardMode/Mapper/css/mapperStyles.js';
import '~/app/Pages/WizardMode/Mapper/css/mapperStyles.css';

/**
 * Handles displaying UI buttons for dynamic fields and handling adding/removing logic
 *
 * @returns {JSX} Dynamic Button UI
 */
const DynamicButtonMap = props => {
  const { classes } = props;

  // the text property contains all the fields that dynamic in a certain section.
  // A string separated by commas -> 'AA,AB'
  const joinedFields = props.text.split(',').join('');
  // If there is only one dynamic field in state ['03'] then do not display remove btn
  const disableRemove = props.wizardDynamicFields?.[joinedFields]?.length > 1;
  // disables spouse fields if filing status is 1 or 3
  const disableSpouse = props.singleFLST && props?.tors === 'S';

  return (
    <>
      <Grid item xs={12}>
        <Button
          onClick={() =>
            props.updateDynamicFields(props.formName, props.text, true, props.index, false)
          }
          id={`addMoreBtn-${props.index}`}
          className={classes.dynamicButtonStyle}
          disabled={props.getLockedStatus() || disableSpouse}
        >
          {'Add More'}
        </Button>
        {disableRemove && (
          <Button
            onClick={() =>
              props.updateDynamicFields(props.formName, props.text, false, props.index)
            }
            id={`removeBtn-${props.index}`}
            className={classes.dynamicButtonStyle}
            disabled={props.getLockedStatus() || disableSpouse}
          >
            {'Remove'}
          </Button>
        )}
      </Grid>
    </>
  );
};

export default withStyles(styles)(DynamicButtonMap);

// matrix used to map 1040 Return Printing
// empty strings represent no checkbox
// disabled marks disabled input
export const INDIVIDUAL_RETURN_PRINT_FIELD_MATRIX = [
  ['LPA0', 'LPB0'], // 8453/8879
  ['CDS1'], // Client Data Screen
  ['LBE1', 'LBE2'], // Bank Fee Estimate
  ['PFBA', 'PFBB'], // Bank Application
  ['LBF1', 'LBF2'], // Filing Options
  ['LPAC', 'LPBC'], // Engagement Letter
  ['LPA1', 'LPB1'], // Federal Client Letter
  ['LPC1', 'LPD1'], // State Client Letters'
  ['LPA2'], // Diagnostics
  ['LPA3', 'LPB3'], // Invoice
  ['LTCA', 'LTCB'], // Tax Comparison
  ['LTSA', 'LTSB'], // Tax Summary
  ['LPA4', 'LPB4'], // Income Summary
  ['LPA5', 'LPB5', '', 'disabled'], // Federal Return
  ['LPA6', 'LPB6', '', '', 'disabled'], // State Return(s)
  ['LPAB', 'LPBB'], // Payment Voucher(s)
  ['LPA7', 'LPB7', '', 'LPC7'], // Federal Asset Detail
  ['LPE7', 'LPF7', '', '', 'LPD7'], // State Asset Detail
  ['LPA8', 'LPB8', '', 'LPC8', 'LPD8'], // Worksheets
  ['LMDA', 'LMDB'], // Overflow Detail(s)
  ['LPTA', 'LPTB'], // Privacy Letter'
  ['', 'LPRB'], // Referral Coupons
  ['LPSA', 'LPSB'], // Appointments Letter
  ['LPCO', 'LPCP'], // Consent Form
  ['', 'LPWB'], // Water Mark
  ['LPA9', 'LPB9', 'LPC9', 'LPD9', 'LPE9'], // Send to Printer/PDF
  ['disabled', 'LPBA', 'LPCA', 'LPDA', 'LPEA'], // Send to Archive
  ['LPAM', 'LPBM', 'LPCM', 'LPDM', 'LPEM'], // Send to Email
];

export const INDIVIDUAL_RETURN_PRINT_FIELD_LIST = [
  '8453/8879',
  'Client Data Screen',
  'Bank Fee Estimate',
  'Bank Application',
  'Filing Options',
  'Engagement Letter',
  'Federal Client Letter',
  'State Client Letters',
  'Diagnostics',
  'Invoice',
  'Tax Comparison',
  'Tax Summary',
  'Income Summary',
  'Federal Return',
  'State Return(s)',
  'Payment Voucher(s)',
  'Federal Asset Detail',
  'State Asset Detail',
  'Worksheets',
  'Overflow Detail(s)',
  'Privacy Letter',
  'Referral Coupons',
  'Appointments Letter',
  'Consent Form',
  'Water Mark',
  'Send to Printer/PDF',
  'Send to Archive',
  'Send to Email',
];

export const BUSINESS_RETURN_PRINT_FIELD_MATRIX = [
  ['CCD1'], // Client Data Screen
  ['CPAC', 'CPBC'], // Engagement Letter
  ['CPA1', 'CPB1'], // Federal Client Letter
  ['CPC1', 'CPD1'], // State Client Letters
  ['CPK1', 'CPK2'], // Schedule K-1 Letters
  ['CPA2'], // Diagnostics
  ['CPA3', 'CPB3'], // Invoice
  ['CPA4', 'CPB4'], // Financial Statements
  ['CPA5', 'CPB5', '', 'disabled'], // Federal Return
  ['CPA6', 'CPB6', '', '', 'disabled'], // State Return(s)
  ['CPA7', 'CPB7', '', 'CPC7', 'CPD7'], // Asset Detail
  ['CPA8', 'CPB8', '', 'CPC8', 'CPD8'], // Worksheets
  ['CMDA', 'CMDB'], // Overflow Detail(s)
  ['CPTA', 'CPTB'], // Privacy Letter
  ['', 'CPWB'], // Water Mark
  ['CPA9', 'CPB9', 'CPC9', 'CPD9', 'CPE9'], // Send to Printer/PDF
  ['disabled', 'CPBA', 'CPCA', 'CPDA', 'CPEA'], // Send to Archive
  ['CPAM', 'CPBM', 'CPCM', 'CPDM', 'CPEM'], // Send to Email
];

export const BUSINESS_RETURN_PRINT_FIELD_LIST = [
  'Client Data Screen',
  'Engagement Letter',
  'Federal Client Letter',
  'State Client Letters',
  'Schedule K-1 Letters',
  'Diagnostics',
  'Invoice',
  'Financial Statements',
  'Federal Return',
  'State Return(s)',
  'Asset Detail',
  'Worksheets',
  'Overflow Detail(s)',
  'Privacy Letter',
  'Water Mark',
  'Send to Printer/PDF',
  'Send to Archive',
  'Send to Email',
];

// when adding an entry here you must also add a corresponding entry to
// PRINT_OPTION_FIELD_LIST at the same index
export const PRINT_OPTION_FIELD_KEY = [
  [
    'LPSC',
    'LSRV',
    'MRKZ',
    'TSBD',
    'PRPN',
    'BDPO',
    'LSES',
    'ITMZ',
    'ISSP',
    'AUTN',
    'INEF',
    'PDP2',
    'MRTN',
    'PFBA',
  ],
  ['LSRD', 'LSRS', 'ESFP', 'LSRO', 'LBAB', 'PMTV'],
  ['LPSN', 'LPSI', 'BOLD', 'LPSP', 'IDUE', 'EXPF', 'INV2', 'MSSN', 'MPTN', 'MEIN', 'LPVA', 'PRVX'],
];

export const PRINT_OPTION_FIELD_LIST = [
  [
    'Print Preparer Copy Only if Verified w/Error',
    'Print Payment Voucher w/Paper Returns',
    'Completed on Print Final for Paper Returns',
    'Print Tax Summary with Bank Documents',
    "Add Printed Preparer's Name to Main Form",
    'Print 8879/8453 with Bank Documents',
    'Do Not Print 1040ES with Final Return',
    'Do Not Print Itemized Form Billing',
    'Disable Income Summary Signatures',
    'Opt-out Completed Return Transfer',
    'Print Invoice w/8879/8453 EF Documents',
    'Print Date on Page 2 of 1040 Return',
    'RTN/DAN Number Masking for EF IRS DD',
    'Print Bank Documents with Final Return',
  ],
  [
    'Asset Detail w/Bus Activities',
    'Always Print Signed Documents',
    'Turn Off E-Signature Form Printing',
    'Overflow Detail(s)',
    'Preparer Copy for Bank App',
    'No Payment Voucher with 8879',
  ],
  [
    'Print Page Numbers',
    'Print Site ID on Each Page',
    'Print Field Values in Bold',
    'Print Copy Separation Pages',
    'No Print on Invoice Due',
    'Exit Tax Return on Print Final',
    'Do Not Print Page 2 of Invoice',
    'SSN Masking for EF',
    'PTIN Masking for EF',
    'EIN Masking for EF',
    '8879 Last Printed Verify',
    'Exclude Privacy Letters',
  ],
];

export const INDIVIDUAL_RETURN_PRINT_ORDER_DEFUALT = [
  'Client Data Screen',
  'Payment Voucher(s)',
  'Diagnostics',
  'Consent Form',
  'Referral Coupons',
  'Invoice',
  'Privacy Letter',
  'ACA Letters',
  'Appointments Letter',
  'Engagement Letter',
  'Federal Client Letters',
  'State Client Letters',
  'Tax Comparison',
  'Tax Summary',
  'Bank Fee Estimate',
  'Filing Options',
  'Bank Application',
  '8453/8879',
  'Federal Return',
  'State Return(s)',
  'Income Summary',
  'Federal Asset Detail',
  'State Asset Detail',
];

export const BUSINESS_RETURN_PRINT_ORDER_DEFUALT = [
  'Client Data Screen',
  'Diagnostics',
  'Invoice',
  'Privacy Letter',
  'Engagement Letter',
  'Federal Client Letters',
  'State Client Letters',
  'Finanacial Statements',
  'Schedule K-1 Letters',
  'Federal Return',
  'State Return(s)',
  'Asset Detail',
];

export const SIG_BLOCK_ONE_LIST = ['Preview', 'Client Copies', 'Always', 'Never'];
export const SIG_BLOCK_TWO_LIST = ['Blank', 'X-Out', 'Black Out'];

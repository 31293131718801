import React, { Component, Fragment } from 'react';
import isEqual from 'lodash.isequal';

class Widget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      triggerReload: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.triggerReload !== nextState.triggerReload) {
      // Refresh option if a widget has polling or needs to be refreshed every so often?
      return true;
    }
    if (!isEqual(this.props.widgetData, nextProps.widgetData)) {
      return true;
    }
    // when using the `User Lookup`, drilling into the a user the role is not initially fetched.
    if (this.props.role !== nextProps.role) {
      return true;
    }

    return false;
  }

  triggerReload = () => {
    this.setState({
      triggerReload: !this.state.triggerReload,
    });
  };

  render() {
    return (
      <Fragment>
        {React.cloneElement(this.props.WidgetToLoad, {
          role: this.props.role,
          triggerReload: this.triggerReload,
          keyPassed: this.props.keyPassed,
          data: this.props.widgetData,
          setMenuButtons: this.props.setMenuButtons,
        })}
      </Fragment>
    );
  }
}

export default Widget;

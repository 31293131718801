import React from 'react';
import { withStyles } from '@material-ui/core';
import { styles } from './css/messages.js';
import '~/app/Pages/Returns/components/Taxpass/css/styles.css';

const Message = props => {
  const lastMsgOfReceiverSender = (isLast, isReceiver) => {
    if (isLast) {
      if (isReceiver) {
        return 'bubble-arrow-right receiver';
      } else {
        return 'bubble-arrow-left sender';
      }
    } else if (isReceiver) {
      return 'receiver';
    } else if (!isReceiver) {
      return 'sender';
    }
    return '';
  };

  const alignDirection = props.isReceiver ? 'left' : 'right';
  return (
    <div className={`bubble-container`}>
      <div className={`bubble-body-container ${alignDirection}`}>
        <div className={`bubble-message-timestamp`}>{props.timestamp}</div>
        <div className={`talk-bubble ${lastMsgOfReceiverSender(props.isLast, props.isReceiver)}`}>
          {props.message}
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(Message);

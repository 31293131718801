// External imports
import Fingerprint2 from 'fingerprintjs2';
// Internal imports
import AuthAPI from '~/app/api/authAPI.js';
import ErrorHelpers from '~/app/errorHelpers.js';
import { statusOK } from '~/app/webHelpers';
import { ACCESS_TOKEN, FINGERPRINT_OPTIONS } from '~/app/constants.js';
import UtilityAPI from '~/app/api/utilityAPI';
// Redux imports
import { store } from '~/app/redux/index';
import { actions as appActions } from '~/app/redux/modules/app';
import { actions as loginSetupActions } from '~/app/redux/loginSetup/duck';

/**
 * @module loginHelper
 * @category Login
 */

/**
 * Get latest app version from DB and store it in session.
 * @function
 */
export const checkAppVersion = async () => {
  try {
    const res = await AuthAPI.getAppVersion();
    if (statusOK(res, true)) {
      const version = res.data.version?.replace(/[^0-9.]+/g, '') || '';

      sessionStorage.setItem('appVersion', version);
    }
  } catch (error) {
    ErrorHelpers.handleError('Unable to call get app version: ', error);
  }
};

/**
 * Generats the fingerprint on app load.
 * @function
 */
export const getFingerprint = async () => {
  return Fingerprint2.get(FINGERPRINT_OPTIONS, components => {
    if (components) {
      const fingerprint = UtilityAPI.hashFingerprint(components);
      store.dispatch(appActions.setBrowserFingerprint(fingerprint));

      const jwt = sessionStorage.getItem(ACCESS_TOKEN);
      if (!jwt) return;

      AuthAPI.validateOrRefresh(fingerprint)
        .then(success => {
          if (success) store.dispatch(loginSetupActions.onFinalizeLogin());
        })
        .catch(() => {
          // Do not bubble up in error modal, refresh tokens not valid
          console.info('INFO: Issue refreshing / validating login token');
          store.dispatch(loginSetupActions.setAuthenticationFlag(false));
        });
    }
  });
};

import React, { Fragment } from 'react';
// Internal imports
import LoginSettingsModal from '~/app/Components/Settings/Setup/Modals/LoginSettingsModal.jsx';
import AddAccessLevelModal from '#/Settings/Setup/Modals/AccessLevel.jsx';
import ErrorHelpers from '~/app/errorHelpers.js';
import menuVertical from '~/images/icons/menu_vertical.png';
import WebHelpers from '~/app/webHelpers.js';
import { HIERARCHY_TYPE } from '~/app/constants.js';
import XlinkAPI from '~/app/api/xlinkAPI';
import AccessControl from '~/app/Components/Auth/AccessControl.jsx';
import SetupPage from '~/app/Components/Settings/Setup/SetupPage.jsx';
import AccessRolesTable from './AccessRolesTable.jsx';
import SimpleDialog from '#/Common/SimpleDialog.jsx';
import lockIcon from '~/images/icons/icons8-lock_2.svg';
import DeleteLoginModal from '../../DeleteLoginModal.jsx';
// External imports
import {
  Menu,
  MenuItem,
  Dialog,
  DialogContent,
  DialogActions,
  Snackbar,
  Typography,
  Paper,
  Divider,
  Button,
  Modal,
  Collapse,
  IconButton,
  Input,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import ReactPaginate from 'react-paginate';
// Redux imports
import { connect } from 'react-redux';
import { actions as appActions } from '~/app/redux/modules/app';
import { actions as loginSetupActions } from '~/app/redux/loginSetup/duck';
import { actions as setupPageActions } from '~/app/redux/setupPages/duck';
// Styling imports
import '~/app/Pages/Setup/css/setup.css';

const mapStateToProps = state => {
  return {
    officeAddress: state.officeSettings.officeAddress,
    currentView: state.drilldown.drilldownHistory[state.drilldown.drilldownHistory.length - 1],
    loginModalOpen: state.loginSetup.loginModalOpen,
    accessLevelModalOpen: state.loginSetup.accessLevelModalOpen,
    expandedLogins: state.loginSetup.expandedLogins,
    expandedAccessLevels: state.loginSetup.expandedAccessLevels,
    logins: state.loginSetup.logins,
    totalAccessGroups: state.loginSetup.totalAccessGroups,
    totalLogins: state.loginSetup.totalLogins,
    resultsPerPage: state.loginSetup.resultsPerPage,
    accessGroupPageOffset: state.loginSetup.accessGroupPageOffset,
    accessGroups: state.loginSetup.accessGroups,
    currentEditLogin: state.loginSetup.currentEditLogin,
    isEditingLogin: state.loginSetup.isEditingLogin,
    loginsPage: state.loginSetup.loginsPage,
    accessLevel: state.app.loggedInUser.accessLevel,
    isDeleteLoginModalOpen: state.setupPageState.isDeleteLoginModalOpen,
    loginToDelete: state.setupPageState.loginToDelete,
  };
};

const mapDispatchToProps = {
  ...appActions,
  ...loginSetupActions,
  ...setupPageActions,
};

class LoginSetup extends SetupPage {
  constructor(props) {
    const name = 'Logins'; // You need this for save and next to transition
    super(props, name);
    this.state = {
      availableOffices: [],
      availableOfficesMap: {},
      hoveredRow: null,
      anchorEl: null,
      selectedRow: null,
      selectedLoginName: '',
      deleteLoginDialogOpen: false,
      deleteLoginTxtConfirmation: '', // Using this to force the user to type the login name they are deleting. Doing this so the user is 100% sure who is being deleted.
      snackbarMsg: '',
      snaccbarOpen: false,
      confirmCancel: false,
    };
  }

  getInitialConfigState() {
    this.setState({
      availableOffices: [],
      availableOfficesMap: {},
      hoveredRow: null,
      anchorEl: null,
      selectedRow: null,
      selectedLoginName: '',
      deleteLoginDialogOpen: false,
      deleteLoginTxtConfirmation: '',
      snackbarMsg: '',
      snaccbarOpen: false,
      confirmCancel: false,
    });
  }

  toggleConfirmCancel = toggle => {
    this.setState({
      confirmCancel: toggle,
    });
  };

  rowIsHovered = rowID => {
    return this.state.hoveredRow === rowID;
  };

  openVoidCTXMenu = (e, id, obj) => {
    e.stopPropagation();
    this.setState({
      anchorEl: e.currentTarget,
      hoveredRow: id,
      selectedRow: obj,
      selectedLoginName: obj.login_name,
    });
  };

  clearCTXMenuFocus = () => {
    this.setState({
      anchorEl: null,
      hoveredRow: null,
    });
  };

  componentDidMount() {
    const payload = WebHelpers.getJWTPayload();
    const role = this.props.currentView?.role;
    // The logged in user must have a hierarchy type of franchise/sb/ssb and be drilled into a
    // efin or prep to fetch
    const canAssociateOffice =
      (role === HIERARCHY_TYPE.PREPARER || role === HIERARCHY_TYPE.EFIN) &&
      payload.hierarchy_type_id <= HIERARCHY_TYPE.FRANCHISE;

    if (canAssociateOffice) {
      XlinkAPI.fetchAvailableOffices()
        .then(res => {
          this.setState({
            availableOffices: res.data.Offices,
            availableOfficesMap: res.data.OfficesMap,
          });
        })
        .catch(err => {
          ErrorHelpers.handleError('Error fetching offices', err);
        });
    }
  }

  componentWillUnmount() {
    // Close the new login modal when component unmounts.
    this.props.onCloseLoginModal();
  }

  styles = {
    tableColumnText: {
      fontFamily: 'Roboto',
      fontSize: '12px',
      color: '#1A173B',
      width: '25%',
      paddingBottom: '0%',
    },
    collapseHeader: {
      fontFamily: 'Roboto',
      fontSize: '21px',
      letterSpacing: '0.14px',
      color: '#1A173B',
      marginLeft: '0.3em',
    },
    collapseButton: {
      width: '1em',
      height: '1em',
      padding: '0',
      outline: 'none',
    },
    tableStyle: { marginTop: '25px', marginLeft: '2vw', width: '90%' },
    addNewButtonDiv: {
      textAlign: 'right',
      width: '100%',
      padding: '0',
      marginLeft: '0vw',
      marginRight: '3vw',
    },
    addNewButton: {
      // width: "10vw",
      // height: "2vh",
      textTransform: 'capitalize',
      fontFamily: 'Roboto',
      fontSize: '14px',
      borderRadius: '4px',
      padding: '0.5em',
    },
    pagedTableStyle: {
      minHeight: `${this.props.resultsPerPage * 2.9 /* magic num */}em`,
    },
    actionPillButton: {
      minHeight: '35px',
      maxHeight: '35px',
      minWidth: '24px',
      maxWidth: '24px',
      padding: '0px 0px 0px 0px',
      border: '2px solid #0077FF',
    },
    redWarningText: {
      color: 'red',
    },
    centerText: {
      textAlign: 'center',
    },
    inputWidth: {
      width: '100%',
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'scroll',
    },
  };

  commonPaginationProps = {
    initialPage: 0,
    marginPagesDisplayed: 2,
    pageRangeDisplayed: 3,
    previousLabel: '<',
    nextLabel: '>',
    breakLabel: '...',
    breakClassName: 'paginate-break',
    pageClassName: 'paginate-page',
    activeClassName: 'paginate-activepage',
    previousClassName: 'paginate-prev',
    nextClassName: 'paginate-next',
    disabledClassName: 'paginate-disabled',
    containerClassName: 'pagination',
    subContainerClassName: 'pages pagination',
  };

  fetchLogins = data => {
    let offset = 0;
    if (data) {
      // page update
      offset = Math.ceil(data.selected * this.props.resultsPerPage);
    }
    this.props.fetchLogins(this.props.currentView?.loginID, this.props.resultsPerPage, offset);
  };

  fetchAccessLevels = data => {
    let offset = 0;
    if (data) {
      // page update
      offset = Math.ceil(data.selected * this.props.resultsPerPage);
    }
    this.props.fetchAccessLevels(
      this.props.currentView?.loginID,
      this.props.resultsPerPage,
      offset,
    );
  };

  // Colapse Handlers
  handleExpandLogins = () => {
    this.setState(state => ({ expandedLogins: !state.expandedLogins }));
  };

  handleExpandAccessLevels = () => {
    this.setState(state => ({
      expandedAccessLevels: !state.expandedAccessLevels,
    }));
  };

  canEditLogin = (offices, isCurrentOfficeView, loginHierarchyTypeId) => {
    // allow only EFIN/Office Access Level to edit all logins under thier office
    // other Access levels with EFIN heiarchy type should not be able to edit logins that
    // are associated multiple offices
    if (this.props.accessLevel === 'EFIN/Office') {
      return true;
    } else if (offices) {
      if (offices.length > 1) {
        const payload = WebHelpers.getJWTPayload();
        return (
          isCurrentOfficeView &&
          payload.hierarchy_type_id <= HIERARCHY_TYPE.FRANCHISE &&
          (loginHierarchyTypeId === HIERARCHY_TYPE.PREPARER ||
            loginHierarchyTypeId === HIERARCHY_TYPE.EFIN)
        );
      }
    }
    return true;
  };

  promptForDelete = login => {
    this.setState({
      deleteLoginDialogOpen: true,
    });
  };

  deleteLogin = () => {
    XlinkAPI.deleteLogin(this.state.selectedRow.login_id)
      .then(res => {
        this.setState({
          snaccbarOpen: true,
          snackbarMsg: 'Login Deleted',
          deleteLoginDialogOpen: false,
          deleteLoginTxtConfirmation: '',
        });
        this.fetchLogins();
      })
      .catch(err => {
        ErrorHelpers.handleError('Error deleting login', err);
      });
  };

  toggleSuccessSnackbar = isOpen => {
    this.setState({
      snaccbarOpen: isOpen,
    });
  };

  render() {
    const payload = WebHelpers.getJWTPayload();
    const totalLoginPages = Math.ceil(this.props.totalLogins / this.props.resultsPerPage);
    let loginsOffset = this.props.loginsPage * this.props.resultsPerPage + 1;
    if (!this.props.logins || this.props.logins.length === 0) {
      loginsOffset = 0;
    }
    const loginsLastRecord = loginsOffset + this.props.logins.length - 1;
    const canAssociateOffice =
      (this.props.currentView?.role === HIERARCHY_TYPE.PREPARER ||
        this.props.currentView?.role === HIERARCHY_TYPE.EFIN) &&
      payload.hierarchy_type_id <= HIERARCHY_TYPE.FRANCHISE;

    const isCurrentOfficeView = this.props.currentView?.role === HIERARCHY_TYPE.EFIN;
    const isEFINOrPrep =
      this.props.currentView?.role === HIERARCHY_TYPE.EFIN ||
      this.props.currentView?.role === HIERARCHY_TYPE.PREPARER;

    const { anchorEl } = this.state;
    return (
      <Fragment>
        <div className="container-fixed" style={{ marginTop: '2.5rem' }}>
          <Paper>
            <Modal
              id="mdlNewLoginModalLoginSetup"
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={this.props.loginModalOpen}
              onClose={() => this.props.onCloseLoginModal()}
              style={this.styles.modal}
              disableBackdropClick={true}
            >
              <Paper
                elevation={5}
                style={{
                  width: '50%',
                  padding: '0',
                  position: 'relative',
                  margin: 'auto',
                }}
              >
                <LoginSettingsModal
                  loadSetupComponent={this.props.loadSetupComponent}
                  availableOffices={this.state.availableOffices}
                  availableOfficesMap={this.state.availableOfficesMap}
                  canAssociateOffice={canAssociateOffice}
                  onCloseLoginModal={this.props.onCloseLoginModal}
                />
              </Paper>
            </Modal>

            <Modal
              id="mdlNewAccessLevelModalLoginSetup"
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={this.props.accessLevelModalOpen}
              onClose={this.props.onToggleNewaccesslevelModal}
              style={{ overflow: 'scroll' }}
            >
              <Paper
                elevation={5}
                style={{
                  width: '65%',
                  padding: '0',
                  position: 'relative',
                  margin: 'auto',
                }}
              >
                <AddAccessLevelModal
                  handleCloseAddAccessLevelModal={() => this.props.onToggleNewaccesslevelModal()}
                />
              </Paper>
            </Modal>

            <form
              style={{
                marginLeft: '3vw',
                marginRight: '3vw',
                marginTop: '1vh',
              }}
            >
              <div
                style={{
                  textAlign: 'left',
                  paddingTop: '3vh',
                  paddingBottom: '1vh',
                }}
              >
                <div style={{ textAlign: 'left', paddingTop: '1vh' }}>
                  <div style={{ float: 'left' }}>
                    <IconButton
                      id="icobtnExpandLoginsLoginSetup"
                      style={this.styles.collapseButton}
                      onClick={this.props.onToggleLoginsCollapse}
                      aria-expanded={this.props.expandedLogins}
                    >
                      {this.props.expandedLogins ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  </div>
                  <div>
                    <span style={this.styles.collapseHeader}>Logins</span>
                  </div>
                </div>
              </div>
              <Collapse in={this.props.expandedLogins} timeout="auto" unmountOnExit>
                <AccessControl
                  requiredAction="write"
                  accessLevel="create_login"
                  disableOnFalse={true}
                >
                  <div style={this.styles.addNewButtonDiv}>
                    <Button
                      id="btnAddNewLoginSetup"
                      onClick={() => this.props.onOpenLoginModal({}, false)}
                      color="primary"
                      size="medium"
                      style={this.styles.addNewButton}
                    >
                      Create New Login
                    </Button>
                  </div>
                </AccessControl>
                <div style={this.styles.pagedTableStyle}>
                  <table style={this.styles.tableStyle}>
                    <thead>
                      <tr>
                        {/* Account used to be here but it was removed due to customer request */}
                        <th style={this.styles.tableColumnText}>
                          USERNAME
                          <Divider style={{ marginTop: '20px', marginBottom: '5px' }} />
                        </th>
                        <th style={this.styles.tableColumnText}>
                          ACCESS LEVEL
                          <Divider style={{ marginTop: '20px', marginBottom: '5px' }} />
                        </th>
                        <th style={this.styles.tableColumnText}>
                          STATUS
                          <Divider style={{ marginTop: '20px', marginBottom: '5px' }} />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.logins.map((login, index) => {
                        return (
                          <tr key={index} className="tableRow">
                            <td>{login.login_name}</td>
                            <td>{login.access_level}</td>
                            <td>
                              {login.status === 1 ? 'Active  ' : 'Inactive'}
                              {login.is_locked_out && (
                                <img
                                  title="Locked Return"
                                  src={lockIcon}
                                  className="setup-lock-icon"
                                />
                              )}
                              <AccessControl
                                requiredAction="write"
                                accessLevel="edit_login"
                                additionalAccessCheck={this.canEditLogin(
                                  login.offices,
                                  isCurrentOfficeView,
                                  login.hierarchy_type_id,
                                )}
                                disableOnFalse={true}
                                tooltip={
                                  !this.canEditLogin(
                                    login.offices,
                                    isCurrentOfficeView,
                                    login.hierarchy_type_id,
                                  )
                                    ? 'This login is associated to multiple offices.You do not have access to edit this login. Please contact your administrator.'
                                    : 'Edit Login access level is required to edit a login'
                                }
                              >
                                <Button
                                  className={
                                    !this.rowIsHovered(index)
                                      ? 'tableVerticalMenu'
                                      : 'tableVerticalMenuVisible'
                                  }
                                  onClick={e => this.openVoidCTXMenu(e, index, login)}
                                  key={'buttonCTXMenu' + index}
                                  id={'buttonCTXMenu' + index}
                                  style={this.styles.actionPillButton}
                                >
                                  <img src={menuVertical} />
                                </Button>
                                <Menu
                                  anchorEl={anchorEl}
                                  open={Boolean(this.state.hoveredRow === index)}
                                  onClose={() =>
                                    this.setState({
                                      anchorEl: null,
                                      hoveredRow: null,
                                    })
                                  }
                                  key={'CTXMenu'}
                                >
                                  <MenuItem
                                    onClick={() => {
                                      this.props.onOpenLoginModal(login, true);
                                      this.clearCTXMenuFocus();
                                    }}
                                    key={'ctxBtnMenuEdit' + index}
                                  >
                                    Edit
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      // Account level logins have a different deleteing process than office level logins
                                      if (isEFINOrPrep) {
                                        this.props.toggleDeleteLoginModal(login);
                                      } else {
                                        this.setState({
                                          deleteLoginDialogOpen: true,
                                        });
                                      }
                                      this.clearCTXMenuFocus();
                                    }}
                                    key={'ctxBtnMenuDelete'}
                                    disabled={this.props.currentView?.loginID === login?.login_id}
                                  >
                                    Delete
                                  </MenuItem>
                                </Menu>
                              </AccessControl>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div
                  style={{
                    width: '100%',
                    padding: '0',
                    marginLeft: '0vw',
                    marginRight: '3vw',
                  }}
                >
                  {this.props.logins.length > 0 && (
                    <div className="row paginate-page-count-container">
                      <span className="col paginate-page-count">
                        Showing <span style={{ color: 'black' }}>{loginsOffset}</span> to{' '}
                        <span style={{ color: 'black' }}>{loginsLastRecord}</span> of{' '}
                        {this.props.totalLogins}
                      </span>
                    </div>
                  )}
                  <ReactPaginate
                    forcePage={this.props.loginsPage}
                    onPageChange={this.fetchLogins}
                    pageCount={totalLoginPages}
                    {...this.commonPaginationProps}
                  />
                </div>
              </Collapse>

              {
                <Fragment>
                  <div style={{ textAlign: 'left', paddingTop: '3vh' }}>
                    <div style={{ float: 'left' }}>
                      <IconButton
                        id="icobtnExpandAccessLevelsLoginSetup"
                        style={this.styles.collapseButton}
                        onClick={this.props.onToggleAccesslevelsCollpase}
                        aria-expanded={this.props.expandedAccessLevels}
                      >
                        {this.props.expandedAccessLevels ? <ExpandLess /> : <ExpandMore />}
                      </IconButton>
                    </div>
                    <div>
                      <span style={this.styles.collapseHeader}>Access Levels</span>
                    </div>
                  </div>
                  <Collapse in={this.props.expandedAccessLevels} timeout="auto" unmountOnExit>
                    <AccessRolesTable />
                  </Collapse>
                </Fragment>
              }
              <Divider />
              <div className="setup-nav-btns">
                {this.hasPrev() ? (
                  <Button
                    id="btnPrevLoginSetup"
                    color="primary"
                    onClick={() => this.toggleConfirmCancel(true)}
                  >
                    Previous (cancel)
                  </Button>
                ) : null}
                {this.hasNext() ? (
                  <Button id="btnNextLoginSetup" color="primary" onClick={() => this.getNextPage()}>
                    Save &amp; Next
                  </Button>
                ) : null}
              </div>
              <br />
            </form>
          </Paper>

          {/* EFIN/PREP ONLY - only deletes logins at office and prep level */}
          {this.props.isDeleteLoginModalOpen && (
            <DeleteLoginModal
              isDeleteLoginModalOpen={this.props.isDeleteLoginModalOpen}
              toggleModal={() => this.props.toggleDeleteLoginModal()}
              isOnLoginsPage={true}
              name={this.props.loginToDelete.login_name}
              preparerID={this.props.loginToDelete.preparer_id}
              loginID={this.props.loginToDelete.login_id}
              refetchList={this.fetchLogins}
            />
          )}

          {/* ACCOUNT LEVEL ONLY - deletes logins for account level logins */}
          <Dialog
            open={this.state.deleteLoginDialogOpen}
            onClose={() => {
              this.setState({
                deleteLoginDialogOpen: false,
                deleteLoginTxtConfirmation: '',
              });
            }}
          >
            <DialogContent>
              <div>
                <Typography variant="h6" style={this.styles.redWarningText}>
                  Warning! You are attempting to delete a login.
                  <br />
                  Once deleted, this login cannot be recovered.
                  <br />
                </Typography>
                <Typography variant="h6" style={this.styles.centerText}>
                  Please type <b>{this.state.selectedLoginName}</b> to delete this login.
                </Typography>
              </div>
              <Input
                id="txtInputDeleteDialog"
                value={this.state.deleteLoginTxtConfirmation}
                onChange={e => this.setState({ deleteLoginTxtConfirmation: e.target.value })}
                disableUnderline
                autoComplete="off"
                style={this.styles.inputWidth}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.deleteLogin();
                }}
                disabled={this.state.deleteLoginTxtConfirmation !== this.state.selectedLoginName}
              >
                Delete
              </Button>
              <Button
                onClick={() => {
                  this.setState({
                    deleteLoginDialogOpen: false,
                    deleteLoginTxtConfirmation: '',
                  });
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          <SimpleDialog
            open={this.state.confirmCancel}
            onClose={() => this.toggleConfirmCancel(false)}
            onConfirm={() => {
              this.toggleConfirmCancel(false);
              this.getInitialConfigState();
              this.getPrevPage();
            }}
            dialogTitle={'Lose Unsaved Changes?'}
            contentText={'Are you sure you want to undo any pending changes?'}
          />
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.snaccbarOpen}
          autoHideDuration={2500}
          onClose={() => this.setState({ snaccbarOpen: false })}
          id="snkbarconfsaved"
          ContentProps={{
            'aria-describedby': 'success-message-login-deleted',
          }}
          message={<span id="success-message">{this.state.snackbarMsg}</span>}
        />
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginSetup);

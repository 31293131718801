// External Imports
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Input, InputLabel, FormControl, Button, Typography } from '@material-ui/core';
// internal imports
import AccessControl from '~/app/Components/Auth/AccessControl.jsx';
// styling imports
import payjunctionCTA from '~/images/payjunction_CTA_Image_wButton.png';
import { styles } from '~/app/Pages/Payments/PaymentModal/css/CapturePaymentStyles.js';

const PayJunctionLogin = props => {
  const { classes } = props;

  return (
    <form
      onSubmit={e => {
        props.handleSubmitLogin(e);
      }}
    >
      <AccessControl
        requiredAction="write"
        accessLevel="edit_payjunction_api_credentials"
        disableOnFalse={true}
      >
        <Grid container spacing={8} direction="row" alignItems="center" justify="center">
          <Grid item xs={5}>
            <FormControl fullWidth required>
              <InputLabel shrink>PayJunction API Login</InputLabel>
              <Input
                id="txtPayJunctionAPILogin"
                disableUnderline
                placeholder={'API Username'}
                value={props.apiLogin}
                onChange={e =>
                  props.setState({
                    apiLogin: e.target.value,
                  })
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={5}>
            <FormControl fullWidth required>
              <InputLabel shrink>PayJunction API Password</InputLabel>
              <Input
                id="txtPayJunctionAPIPassword"
                type="password"
                disableUnderline
                placeholder={'API Password'}
                value={props.apiPassword}
                onChange={e =>
                  props.setState({
                    apiPassword: e.target.value,
                  })
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={1} classes={{ item: classes.payJunctionPaymentConnectButton }}>
            <Button id="btnPreviousAudit" color="primary" type="submit">
              Connect
            </Button>
          </Grid>
          {/* Display error message in the modal */}
          {props.showCredError && (
            <Grid item xs={12}>
              <Typography variant="body1" color="error" gutterBottom>
                We were unable to authenticate the given credentials. Please ensure credentials are
                correct.
              </Typography>
            </Grid>
          )}

          {props.shortScreenRez === false ? ( // Do we have a large enough screen to market PJ?
            <Grid item xs={12} classes={{ item: classes.capturePaymentContentGridItem }}>
              <a href="https://www.payjunction.com/payments/" target="_blank">
                <img src={payjunctionCTA} />
              </a>
            </Grid>
          ) : (
            <a href="https://www.payjunction.com/payments/" target="_blank">
              PayJunction Payments Information
            </a>
          )}
          <Grid classes={{ item: classes.payJunctionPaymentButtonGrid }} item xs={12}>
            <Button
              id="btnCancel"
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => props.onClose()}
              classes={{ root: classes.payJunctionPaymentButton }}
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </AccessControl>
    </form>
  );
};

export default withStyles(styles)(PayJunctionLogin);

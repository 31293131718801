export const styles = {
  root: {
    flexGrow: '1',
  },
  formTitle: {
    height: '1.3rem',
    width: '100%',
    color: '#1A173B',
    fontFamily: 'Roboto',
    fontSize: '1.3rem',
    letterSpacing: '0.01rem',
    lineHeight: '1.5rem',
    margin: '1rem 0',
  },
  formSubTitle: {
    color: '#1A173B',
    fontFamily: 'Roboto',
    fontSize: '1.3rem',
    letterSpacing: '0.1rem',
    lineHeight: '1.5rem',
    margin: '1rem 0',
  },
  formContainer: {
    height: '100%',
    width: '100%',
    paddingBottom: '3rem',
  },
  formBody: {
    width: '100%',
    height: '100%',
    padding: '1rem 0 1.5rem .6rem',
  },
  // input types no longer used, keeping for now but marked for deletion
  // xsmallFormInput: {
  //   width: '3.44rem',
  //   height: '2.19rem',
  // },
  // smallFormInput: {
  //   width: '7.5rem',
  //   height: '2.19rem',
  // },
  // mediumFormInput: {
  //   width: '12.5rem',
  //   height: '2.19rem',
  // },
  // largeFormInput: {
  //   width: '20rem',
  //   height: '2.19rem',
  // },
  centerChildStyle: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  dividerStyle: {
    margin: '1rem 0',
  },
  footerContainerStyle: {
    margin: '.5rem 0 1rem 0',
  },
  labelStyle: {
    margin: '0',
  },
  sideLabelStyle: {
    margin: '0 0 0 2rem',
    cursor: 'pointer',
  },
  buttonStyle: {
    padding: '0 1rem',
  },
  wizardNextButton: {
    marginRight: '9rem',
  },
  formBlueLabel: {
    color: '#1880E7',
    FontSize: '.95rem',
    letterSpacing: '0',
  },
  formBlueBtn: {
    color: '#1880E7',
    FontSize: '.95rem',
    letterSpacing: '0',
    marginRight: '1rem',
  },
};

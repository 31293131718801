export const widgetStoreCardStyleConfig = theme => {
  return {
    card: {
      margin: theme.spacing.unit * 2,
      padding: theme.spacing.unit * 3,
      maxWidth: '350px',
      maxHeight: '350px',
      minWidth: '350px',
      minHeight: '350px',
      border: '1px solid #EBEDF8',
      borderRadius: '4px',
      display: 'inline-block',
      boxShadow: 'none',
      textAlign: 'center',
    },
    cardImage: {
      paddingBottom: theme.spacing.unit * 2,
      maxHeight: '100px',
      minHeight: '100px',
    },
    cardTitle: {
      fontFamily: 'Roboto',
      color: '#354052',
      fontSize: '20px',
      fontWeight: '400',
      letterSpacing: '0.1px',
      lineHeight: '18px',
      textAlign: 'center !important',
      paddingBottom: theme.spacing.unit * 4,
    },
    cardButton: {
      width: '28%',
    },
    widgetDetailsModal: {
      boxShadow: '0 0 20px 0 rgba(0,0,0,0.25)',
      height: 'auto',
      maxHeight: '800px',
      width: '800px',
      padding: '0',
    },
    widgetInstalled: {
      marginTop: theme.spacing.unit * 3 * -1,
      marginRight: theme.spacing.unit * 3.1 * -1,
      float: 'right',
      backgroundColor: '#00C44F',
      color: 'white',
      right: '0',
      top: '0',
      width: '23%',
      borderRadius: '0px 4px 0 4px',
      fontSize: '11px',
      textAlign: 'center',
    },
    detailsModal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };
};

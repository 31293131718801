import React from 'react';
// external imports
import { FormControl, InputLabel, Input, Button, AppBar, Toolbar } from '@material-ui/core';
// redux imports
import { useSelector, useDispatch } from 'react-redux';
import { actions as setupPageActions } from '~/app/redux/setupPages/duck';

const UpsertUserStatus = props => {
  const dispatch = useDispatch();

  const { isEditingRecord, currentEditRecord } = useSelector(state => ({
    isEditingRecord: state.setupPageState.isEditingRecord,
    currentEditRecord: state.setupPageState.currentEditRecord,
  }));

  const handleInputChange = e => {
    e.persist();
    dispatch(setupPageActions.updateCurrentEditRecord(e));
  };

  const handleUpsert = () => {
    currentEditRecord.status_code = currentEditRecord.status_code.trim();
    props.handleUserDBUpsert(currentEditRecord);
  };

  const validateFields = () => {
    if (
      currentEditRecord.status_code == null ||
      currentEditRecord.status_code === '' ||
      currentEditRecord.status_description == null ||
      currentEditRecord.status_description === ''
    ) {
      return false;
    }
    return true;
  };

  return (
    <div>
      <AppBar position="static">
        <Toolbar style={styles.toolbar}>
          {isEditingRecord ? 'Edit ' : 'Add '} User Status Code
        </Toolbar>
      </AppBar>
      <form style={{ margin: ' 2em' }}>
        <div style={{ textAlign: 'left', marginTop: '1em' }}>
          <div style={styles.sectionHeading}>User Status Code Information</div>
          <div style={{ float: 'left', marginRight: '2em' }}>
            <FormControl>
              <InputLabel style={styles.labelStyles} required={false} shrink>
                User Status Code
              </InputLabel>
              <Input
                id="txtUpsertUserStatusCode"
                name="status_code"
                value={currentEditRecord.status_code}
                onChange={handleInputChange}
                style={styles.inputStyle}
                error={!currentEditRecord.status_code || currentEditRecord.status_code === ''}
                inputProps={{
                  maxLength: '7',
                }}
                disableUnderline
              />
            </FormControl>
          </div>
          <div style={{ float: 'none' }}>
            <FormControl>
              <InputLabel style={styles.labelStyles} required={false} shrink>
                Status Description
              </InputLabel>
              <Input
                id="txtUpsertUserStatusCodeDescription"
                name="status_description"
                value={currentEditRecord.status_description}
                onChange={handleInputChange}
                style={styles.inputStyle}
                error={
                  !currentEditRecord.status_description ||
                  currentEditRecord.status_description === ''
                }
                inputProps={{
                  maxLength: '35',
                }}
                disableUnderline
              />
            </FormControl>
          </div>
        </div>

        <div style={{ textAlign: 'right', margin: '2em' }}>
          <Button
            id="btnCancelAddUpsertUserStatusCode"
            color="primary"
            onClick={() => props.handleCloseUpsertModal()}
          >
            Cancel
          </Button>
          <Button
            id="btnUpsertUserStatusCode"
            color="primary"
            style={styles.button}
            onClick={handleUpsert}
            disabled={!validateFields()}
          >
            {isEditingRecord ? 'Save' : 'Add'}
          </Button>
        </div>
      </form>
    </div>
  );
};

const styles = {
  labelStyles: {
    color: '#354052',
    textAlign: 'left',
    fontFamily: 'Roboto',
  },
  button: {
    width: '9em',
    fontSize: '14px',
    borderRadius: '4px',
    marginLeft: '2em',
  },
  toolbar: {
    backgroundColor: '#444A59',
  },
  sectionHeading: {
    color: '#1A173B',
    fontFamily: 'Roboto',
    fontSize: '21px',
    marginBottom: '1em',
  },
  inputStyle: {
    width: '20vw',
    height: '3.5vh',
  },
};

export default UpsertUserStatus;

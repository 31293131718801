export const IconStyle = {
  outline: 'none',
  paddingLeft: '0px',
  paddingRight: '0px',
};

export const IconStyleNotAttached = {
  outline: 'none',
  marginLeft: '1rem',
  paddingLeft: '0px',
  paddingRight: '0px',
};

export const formItemStyle = {
  color: '#1880E7',
  fontFamily: 'Roboto',
  fontSize: '15px',
  lineHeight: '18px',
  paddingLeft: '2rem',
};

export const formItemStyleDisabled = {
  ...formItemStyle,
  color: '#A9A9A9',
};

export const formItemStyleDropdown = {
  color: '#1880E7',
  fontFamily: 'Roboto',
  fontSize: '15px',
  lineHeight: '18px',
};

export const formItemStyleAttached = {
  color: '#1880E7',
  fontFamily: 'Roboto',
  fontSize: '15px',
  lineHeight: '18px',
  paddingLeft: '1rem',
};

export const subFormItemStyle = {
  color: '#1880E7',
  fontFamily: 'Roboto',
  fontSize: '13.5px',
  lineHeight: '18px',
  paddingLeft: '1.8rem',
};

export const selectedSubFormItemStyle = {
  color: '#1A173B',
  fontFamily: 'Roboto',
  fontSize: '13.5px',
  lineHeight: '18px',
  paddingLeft: '1.8rem',
};

export const formHeaderStyle = {
  color: '#1A173B',
  fontFamily: 'Roboto',
  fontSize: '18px',
  lineHeight: '20px',
  letterSpacing: '0.14px',
  padding: '0px',
};

export const contextItemStyle = {
  fontSize: '0.8rem',
};

export const sidebarFooterBtn = {
  width: '12.5rem',
};

export const styles = {
  toolbarColor: {
    backgroundColor: "#444A59"
  },
  paperStyle: {
    maxWidth: "700px",
    paddingBottom: "10%",
    paddingRight: "10%",
    position: "relative",
    marginTop: "10%",
    marginLeft: "25%"
  },
  styledHeader: {
    background: "#444C59",
    border: 0,
    color: "white",
    height: 64,
    padding: "20px 30px",
    minWidth: "350px",
    PaperProps: {
      style: {
        backgroundColor: "#FFFFFF",
        color: "black"
      }
    }
  },
  styledTitle: {
    color: "white",
    fontFamily: "Roboto",
    fontSize: "18px",
    textTransform: "capitalize"
  },
  styledContent: {
    fontFamily: "Roboto",
    fontSize: "14px",
    marginTop: "23px"
  },
  styledButton: {
    marginRight: "1vw",
    marginBottom: "1.5vh"
  }
};

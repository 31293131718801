// External imports
import React from 'react';
import {
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputLabel,
  withStyles,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
// Internal imports
import AccessControl from '#/Auth/AccessControl.jsx';
import { useSetState } from '~/app/Utility/customHooks';
import { formatDollarAmt } from '~/app/Pages/Payments/helpers/paymentHelpers.js';
// Styling imports
import { styles } from '~/app/Components/NewAccount/SingleOffice/css/formBilling.js';

const EfinFees = props => {
  const { classes } = props;
  const [state, setState] = useSetState({
    expanded: true,
    efinfees: props.feesInfo,
  });

  const handleExpandClick = () => {
    setState(state => ({ expanded: !state.expanded }));
  };

  // sbFee and ssbFee are combined
  const sbFee = parseInt(state.efinfees.sbFee.replace(/[$,.]/g, '')) || 0;
  const ssbFee = parseInt(state.efinfees.ssbFee.replace(/[$,.]/g, '')) || 0;
  const combinedSBFees = sbFee + ssbFee;

  return (
    <div className="container-fixed" style={{ paddingBottom: '3vh' }}>
      <div style={{ textAlign: 'left', paddingTop: '3vh', paddingBottom: '2vh' }}>
        <div style={{ float: 'left' }}>
          <IconButton
            id="icobtnExpandCustomChanges"
            style={{ width: '1em', height: '1em' }}
            onClick={handleExpandClick}
            aria-expanded={state.expanded}
            classes={{ root: classes.noBorderOnFocus }}
          >
            {state.expanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </div>
        <div>
          <span
            style={{
              fontFamily: 'Roboto',
              fontSize: '21px',
              letterSpacing: '0.14px',
              color: '#1A173B',
              marginLeft: '0.3em',
            }}
          >
            EFIN Fees
            <IconButton
              className={classes.iconButton}
              id="icobtnInfoEfinInfo"
              color="primary"
              aria-label="info"
              onClick={() => props.openInfoDialog(props.itemType)}
            >
              <InfoOutlinedIcon />
            </IconButton>
          </span>
        </div>
      </div>
      <Collapse in={state.expanded} timeout="auto" unmountOnExit>
        <AccessControl requiredAction="write" accessLevel="add/edit_billing" disableOnFalse={true}>
          <Grid container spacing={8}>
            {/* Left Column */}
            <Grid item xs={6}>
              <Grid container spacing={8}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel
                      classes={{ root: classes.labelWidthStyle }}
                      htmlFor="txtTranBaseFee"
                      shrink
                    >
                      Transmitter Base Fee
                    </InputLabel>
                    <Input
                      id="txtTranBaseFee"
                      name="tranBaseFee"
                      value={state.efinfees.tranBaseFee}
                      style={{ width: '20vw' }}
                      disableUnderline
                      disabled={true}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl>
                    <InputLabel
                      classes={{ root: classes.labelWidthStyle }}
                      htmlFor="txtTranAddOnFee"
                      shrink
                    >
                      Transmitter Add-on Fee
                    </InputLabel>
                    <Input
                      id="txtTranAddOnFee"
                      name="tranAddOnFee"
                      value={state.efinfees.tranAddOnFee}
                      style={{ width: '20vw' }}
                      disableUnderline
                      disabled={true}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl>
                    <InputLabel
                      classes={{ root: classes.labelWidthStyle }}
                      htmlFor="txtSBFee"
                      shrink
                    >
                      Service Bureau Fee
                    </InputLabel>
                    <Input
                      id="txtSBFee"
                      name="sbFee"
                      value={formatDollarAmt(combinedSBFees, true)}
                      style={{ width: '20vw' }}
                      disableUnderline
                      disabled={true}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl>
                    <InputLabel
                      classes={{ root: classes.labelWidthStyle }}
                      htmlFor="txtSBName"
                      shrink
                    >
                      Service Bureau Name
                    </InputLabel>
                    <Input
                      id="txtSBName"
                      name="sbName"
                      value={state.efinfees.sbName}
                      style={{ width: '20vw' }}
                      disableUnderline
                      disabled={true}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl>
                    <InputLabel
                      classes={{ root: classes.labelWidthStyle }}
                      htmlFor="txtSFTechFee"
                      shrink
                    >
                      Software Technology Fee
                    </InputLabel>
                    <Input
                      id="txtSFTechFee"
                      name="sfTechFee"
                      value={state.efinfees.sfTechFee}
                      style={{ width: '20vw' }}
                      disableUnderline
                      disabled={true}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel
                      classes={{ root: classes.labelWidthStyle }}
                      htmlFor="txtSfTechAddonFee"
                      shrink
                    >
                      Software Technology Addon Fee
                    </InputLabel>
                    <Input
                      id="txtSfTechAddonFee"
                      name="sfTechAddonFee"
                      value={state.efinfees.sfTechAddonFee}
                      style={{ width: '20vw' }}
                      disableUnderline
                      disabled={true}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            {/* Right Column */}
            <Grid item xs={6}>
              <Grid container spacing={8}>
                <Grid item xs={12}>
                  <FormControl>
                    <InputLabel
                      classes={{ root: classes.labelWidthStyle }}
                      htmlFor="txtEfileFee"
                      shrink
                    >
                      EF Fee
                    </InputLabel>
                    <Input
                      id="txtEfileFee"
                      name="efileFee"
                      value={state.efinfees.efileFee}
                      style={{ width: '20vw' }}
                      disableUnderline
                      disabled={true}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl>
                    <InputLabel
                      classes={{ root: classes.labelWidthStyle }}
                      htmlFor="txtRACardProgram"
                      shrink
                    >
                      Card Program
                    </InputLabel>
                    <Input
                      id="txtRACardProgram"
                      name="raCardProgram"
                      value={state.efinfees.raCardProgram}
                      style={{ width: '20vw' }}
                      disableUnderline
                      disabled={true}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl>
                    <InputLabel
                      classes={{ root: classes.labelWidthStyle }}
                      htmlFor="txtBankProdIndicator"
                      shrink
                    >
                      Bank Product Indicator
                    </InputLabel>
                    <Input
                      id="txtBankProdIndicator"
                      name="bankProductIndicator"
                      value={state.efinfees.bankProductIndicator}
                      style={{ width: '20vw' }}
                      disableUnderline
                      disabled={true}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl>
                    <InputLabel
                      classes={{ root: classes.labelWidthStyle }}
                      htmlFor="txtPreAckAdvance"
                      shrink
                    >
                      Pre Ack Advance
                    </InputLabel>
                    <Input
                      id="txtPreAckAdvance"
                      name="preAckAdvance"
                      value={state.efinfees.preAckAdvance}
                      style={{ width: '20vw' }}
                      disableUnderline
                      disabled={true}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl>
                    <InputLabel
                      classes={{ root: classes.labelWidthStyle }}
                      htmlFor="txtBank"
                      shrink
                    >
                      Bank
                    </InputLabel>
                    <Input
                      id="txtBank"
                      name="bank"
                      value={state.efinfees.bank}
                      style={{ width: '20vw' }}
                      disableUnderline
                      disabled={true}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormControlLabel
                      label="Service Bureau Fee on all products"
                      disabled
                      control={
                        <Checkbox
                          id="chksbFeeOnAll"
                          checked={state.efinfees.chksbFeeOnAll}
                          color="primary"
                          icon={
                            <CheckBoxOutlineBlankIcon
                              style={{ fontSize: 24, color: '#8FC3FF' }}
                              color="secondary"
                            />
                          }
                          checkedIcon={<CheckBoxIcon style={{ fontSize: 24 }} color="primary" />}
                        />
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccessControl>
      </Collapse>
    </div>
  );
};

export default withStyles(styles)(EfinFees);

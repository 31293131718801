// External Imports
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Paper, Divider, Button, Snackbar, Checkbox, FormControlLabel } from '@material-ui/core';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';
// Internal Imports
import AccessControl from '#/Auth/AccessControl.jsx';
import SimpleDialog from '#/Common/SimpleDialog.jsx';
import { EXTENDED_SETUP_PAGES, ACCESS_LEVEL } from '~/app/constants.js';
import { getInitialConfigState, submitTextEmailData, dialogContent } from './Helper/textHelper.js';
// Redux Imports
import { useSelector } from 'react-redux';
import { selectors as loginSelector } from '~/app/redux/loginSetup/selectors';
// Styling Imports
import './css/text.css';

const textEmailSetup = props => {
  const [hasTextlinkPlus, setHasTextlinkPlus] = useState(false);
  const [includeEFIN, setIncludeEFIN] = useState(false);
  const [confirmCancel, setConfirmCancel] = useState(false);
  const [exitSetupDialog, setExitSetupDialog] = useState(false);
  const [showActivateSuccess, setShowActivateSuccess] = useState(false);

  const activeAccessLevels = useSelector(state => loginSelector.getActiveAccessLevels(state));

  useEffect(() => {
    getInitialConfigState(props.loginID, setHasTextlinkPlus, setIncludeEFIN);
  }, []);

  const handleCheckbox = () => {
    setIncludeEFIN(!includeEFIN);
    submitTextEmailData(props.loginID, !includeEFIN, setShowActivateSuccess);
  };

  const goToPrevious = () => {
    props.loadSetupComponent(
      Object.hasOwn(activeAccessLevels, ACCESS_LEVEL.CREATE_RESTRICTED_FIELDS)
        ? EXTENDED_SETUP_PAGES.RESTRICTED_FIELDS.NAME
        : EXTENDED_SETUP_PAGES.MOBILE.NAME,
    );
  };

  const toggleConfirmCancel = toggle => {
    setConfirmCancel(toggle);
  };

  const toggleExitSetupDialog = toggle => {
    setExitSetupDialog(toggle);
  };

  const handleClickFinish = () => {
    setExitSetupDialog(true);
  };

  return (
    <div className="container-fixed text-container">
      <Paper>
        <AccessControl
          requiredAction="write"
          accessLevel={ACCESS_LEVEL.EDIT_OFFICE_SETUP}
          disableOnFalse={true}
        >
          <form className="text-form">
            <span>
              <div className="text-title-container">
                <span className="text-title-container-content">Text Messaging</span>
                <span className="text-title-container-has-textlink">
                  {hasTextlinkPlus && 'This Office Has TextLink Plus'}
                </span>
              </div>
              <div className="text-body-container">
                <span>{dialogContent[0]}</span>
                <br />
                <span>
                  <i>{dialogContent[1]}</i>
                </span>
                <br />
                <br />
              </div>
              <div className="text-note-container">
                <b>Note: </b>
                {dialogContent[2]}
              </div>
            </span>
            <br />
            <div className="text-form-control">
              <FormControlLabel
                label="Include EFIN number on message"
                control={
                  <Checkbox
                    checked={includeEFIN}
                    color="primary"
                    className="text-form-control-checkbox"
                    onChange={handleCheckbox}
                    inputProps={{ height: '20px', width: '20px' }}
                    icon={
                      <CheckBoxOutlineBlank
                        className="text-form-control-checkbox-outline"
                        color="secondary"
                      />
                    }
                    checkedIcon={
                      <CheckBox className="text-form-control-checkbox-checked" color="primary" />
                    }
                  />
                }
              />
            </div>
            <br />
            <br />
            <Divider />
            <div className="setup-nav-btns">
              <Button
                color="primary"
                id="btnPreviousClient"
                onClick={() => {
                  goToPrevious();
                }}
              >
                Previous
              </Button>
              <span>
                <Button onClick={handleClickFinish} color="primary" id="btnFinishTextEmail">
                  Finish
                </Button>
              </span>
            </div>
            <br />
          </form>
          <SimpleDialog
            open={confirmCancel}
            onClose={() => toggleConfirmCancel(false)}
            onConfirm={() => {
              toggleConfirmCancel(false);
            }}
            dialogTitle={'Lose Unsaved Changes?'}
            contentText={'Are you sure you want to undo any pending changes?'}
          />
          <SimpleDialog
            open={exitSetupDialog}
            onClose={() => toggleExitSetupDialog(false)}
            onConfirm={() => {
              props.history.push({
                pathname: '/dashboard',
              });
              toggleExitSetupDialog(false);
            }}
            dialogTitle={'Exit setup'}
            contentText={'Are you sure you want to exit setup?'}
          />
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={showActivateSuccess}
            autoHideDuration={2500}
            onClose={() => setShowActivateSuccess(false)}
            id="snkbarconfsaved"
            message={<span id="success-message">Configuration Saved</span>}
          />
        </AccessControl>
      </Paper>
    </div>
  );
};

export default withRouter(textEmailSetup);

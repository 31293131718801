import React, { Fragment } from 'react';
import { connect } from 'react-redux';
// External imports
import {
  Button,
  Divider,
  Snackbar,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Paper,
  Checkbox,
  withStyles,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
// Internal imports
import { LOGO_TYPE } from '~/app/constants.js';
import LogoSelector from '~/app/Pages/Setup/AccountSetup/components/LogoSelector/LogoSelector.jsx';
import TechSupportNumber from '~/app/Pages/Setup/AccountSetup/components/TechSupportNumber.jsx';
import SimpleDialog from '#/Common/SimpleDialog.jsx';
import XlinkAPI from '~/app/api/xlinkAPI';
import WebHelpers from '~/app/webHelpers.js';
import ErrorHelpers from '~/app/errorHelpers.js';
import SetupPage from '~/app/Components/Settings/Setup/SetupPage.jsx';
// Redux imports
import { actions as cobrandActions } from '~/app/redux/cobrand/duck';
// Styling imports
import { styles } from '~/app/Pages/Setup/AccountSetup/CobrandingSetup/css/cobrandingSetup.js';
import '~/app/Pages/Setup/AccountSetup/CobrandingSetup/css/cobrandingSetup.css';

const mapStateToProps = state => {
  return {
    isLoading: state.cobrand.isLoadingCobrandStatus,
    logo: state.cobrand.logo,
    canCobrand: state.cobrand.canCobrand,
    hasPurchasedCobranding: state.cobrand.hasPurchasedCobranding,
    techSupportNum: state.cobrand.techSupportNumber,
    hasAccessToAddCobranding: state.cobrand.hasAccessToAddCobranding,
    hasAccessToPurchaseCobranding: state.cobrand.hasAccessToPurchaseCobranding,
  };
};

const mapDispatchToProps = {
  ...cobrandActions,
};

class CobrandingSetup extends SetupPage {
  constructor(props) {
    const name = 'Cobranding'; // You need this for save and next to transition
    super(props, name);
    this.state = {
      confirmCancel: false,
      showSuccess: false,
      hasCobranding: this.props.canCobrand,
      hasPurchasedCobranding: this.props.hasPurchasedCobranding,
    };
    this.payload = WebHelpers.getJWTPayload();
  }

  getInitialConfigState() {
    this.setState({
      confirmCancel: false,
    });
  }

  toggleConfirmCancel = toggle => {
    if (!(this.props.setupTourLinkClicked || this.props.setupTourRestart)) {
      this.setState({
        confirmCancel: toggle,
      });
    }
  };

  // Handle onChange without validation for checkbox
  handleCheckbox = event => {
    if (event.target.name === 'hasPurchasedCobranding' && !event.target.checked) {
      this.setState({ [event.target.name]: event.target.checked, hasCobranding: false });
    } else this.setState({ [event.target.name]: event.target.checked });
  };

  submitCobrandSetupData = async () => {
    try {
      await XlinkAPI.UpdateCobrandingStatuses(
        this.state.hasCobranding ? 1 : 0,
        this.state.hasPurchasedCobranding ? 1 : 0,
      );
      // Retrieve logo settings
      this.props.fetchLogo(true);
    } catch (error) {
      ErrorHelpers.handleError('Failed to update Office setup', error);
    }

    this.props.fetchCobrandingPermission();
  };

  render() {
    const { classes } = this.props;
    return (
      <Paper>
        <form style={{ marginLeft: '3vw', marginRight: '3vw', marginTop: '1vh' }}>
          <div
            style={{
              textAlign: 'left',
              paddingTop: '3vh',
              paddingBottom: '1vh',
            }}
          >
            <span
              style={{
                fontFamily: 'Roboto',
                fontSize: '21px',
                letterSpacing: '0.14px',
                color: '#1A173B',
              }}
            >
              Cobranding
            </span>
            <div
              style={{
                padding: '10px',
              }}
            >
              For assistance with cobranding please contact your software provider. Allowing your
              software provider to assist with logo creation may lend better results.
            </div>
          </div>
          {!this.props.isLoading && this.props.hasAccessToPurchaseCobranding && (
            <FormControl fullWidth>
              <div className="cobranding-setup-checkbox-block">
                <FormControlLabel
                  label="Purchased Co-branding"
                  classes={{ root: classes.FormControlLabel }}
                  control={
                    <Checkbox
                      icon={
                        <CheckBoxOutlineBlankIcon
                          classes={{ root: classes.checkboxOutline }}
                          color="secondary"
                        />
                      }
                      checkedIcon={<CheckBoxIcon color="primary" />}
                      id="chkhasPurchasedCobranding"
                      name="hasPurchasedCobranding"
                      checked={this.state.hasPurchasedCobranding}
                      onChange={this.handleCheckbox}
                    />
                  }
                />
              </div>
              <FormHelperText classes={{ root: classes.FormHelperText }}>
                You are seeing this field because you have proper access level.
              </FormHelperText>
            </FormControl>
          )}
          {!this.props.isLoading &&
            (this.props.hasAccessToAddCobranding ||
              (this.props.hasAccessToPurchaseCobranding && this.state.hasPurchasedCobranding)) && (
              <FormControl fullWidth>
                <div className="office-setup-checkbox-block">
                  <FormControlLabel
                    label="Enable Co-branding"
                    classes={{ root: classes.FormControlLabel }}
                    control={
                      <Checkbox
                        icon={
                          <CheckBoxOutlineBlankIcon
                            classes={{ root: classes.checkboxOutline }}
                            color="secondary"
                          />
                        }
                        checkedIcon={<CheckBoxIcon color="primary" />}
                        id="chkToggleCobranding"
                        name="hasCobranding"
                        checked={this.state.hasCobranding}
                        onChange={this.handleCheckbox}
                      />
                    }
                  />
                </div>
                <FormHelperText classes={{ root: classes.FormHelperText }}>
                  You are seeing this field because you have proper access level.
                </FormHelperText>
              </FormControl>
            )}
          {this.props.canCobrand && this.props.hasPurchasedCobranding && !this.props.isLoading && (
            <Fragment>
              <LogoSelector
                logoType={LOGO_TYPE.ACCOUNT}
                loginID={this.props.loginID}
                hierarchyType={this.props.hierarchyType}
                setLogo={this.props.setLogo}
                logo={this.props.logo}
                hasCobranding={this.props.canCobrand && this.props.hasPurchasedCobranding}
                requestUploadLogo={this.props.requestUploadLogo}
              />
              <TechSupportNumber
                techSupportNum={this.props.techSupportNum}
                showSuccess={() => this.setState({ showSuccess: true })}
              />
            </Fragment>
          )}
          <div>
            <Divider style={{ marginLeft: '1.5vw', marginRight: '1.5vw' }} />
            <br />
            <div className="setup-nav-btns">
              {this.hasPrev() ? (
                <Button
                  id="btnPrevCobrandingSetup"
                  color="primary"
                  style={{ width: '10vw', marginRight: '2vw' }}
                  onClick={() => this.toggleConfirmCancel(true)}
                >
                  Previous (cancel)
                </Button>
              ) : null}
              {this.hasNext() ? (
                <Button
                  id="btnNextCobrandingSetup"
                  color="primary"
                  style={{ width: '10vw' }}
                  onClick={() => {
                    this.submitCobrandSetupData();
                    this.getNextPage();
                  }}
                >
                  Save &amp; Next
                </Button>
              ) : null}
            </div>

            <br />

            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              open={this.state.showSuccess}
              autoHideDuration={2500}
              onClose={() => this.setState({ showSuccess: false })}
              id="snkbarconfsaved"
              message={<span id="success-message">Configuration Saved</span>}
            />

            <SimpleDialog
              open={this.state.confirmCancel}
              onClose={() => this.toggleConfirmCancel(false)}
              onConfirm={() => {
                this.toggleConfirmCancel(false);
                this.getInitialConfigState();
                this.getPrevPage();
              }}
              dialogTitle={'Lose Unsaved Changes?'}
              contentText={'Are you sure you want to undo any pending changes?'}
            />
          </div>
        </form>
      </Paper>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CobrandingSetup));

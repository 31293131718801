// External Imports
import React, { Fragment, useEffect } from 'react';
import SimpleDialog from '#/Common/SimpleDialog.jsx';
import {
  NativeSelect,
  Menu,
  MenuItem,
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
  Button,
  Paper,
  Divider,
  Checkbox,
  FormControl,
  FormControlLabel,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
// Internal imports
import AccessControl from '#/Auth/AccessControl.jsx';
import CustomSettings from './CustomSettings.jsx';
import ErrorHelpers from '~/app/errorHelpers.js';
import FormBilling from './FormBilling.jsx';
import menuVertical from '~/images/icons/menu_vertical.png';
import AddSchemeDialogContent from '~/app/Components/NewAccount/SingleOffice/BusinessBillingComponents/addSchemeDialogContent.jsx';
import {
  ADDRESS_DATA_STRINGS,
  BILLING_FORM_TYPES,
  BILLING_ITEM_TYPE,
  NOTIFICATION_TYPE,
  BILLING_SCHEMA,
  EXTENDED_SETUP_PAGES,
  BUSINESS_SETUP_PAGES,
  DFLT,
  DFL2,
} from '~/app/constants.js';
import BillingDiscounts from './BillingDiscounts.jsx';
import CustomChanges from './CustomChanges.jsx';
import XlinkAPI from '~/app/api/xlinkAPI.js';
import { useSetState } from '~/app/Utility/customHooks.js';
import GenBillingAddress from '~/app/Components/NewAccount/SingleOffice/GenBillingAddress.jsx';
// Redux imports
import { connect, useSelector } from 'react-redux';
import { actions as officeSettingsActions } from '~/app/redux/modules/officeSettings.js';
import { actions as setupPageActions } from '~/app/redux/setupPages/duck';
import { actions as notificationActions } from '~/app/redux/notifications/duck';
// Styling imports
import { styles } from '~/app/Components/NewAccount/SingleOffice/css/businessBilling.js';

const mapDispatchToProps = {
  ...officeSettingsActions,
  ...setupPageActions,
  ...notificationActions,
};

const BusinessBillingSetup = props => {
  const { classes } = props;
  const { officeAddress } = useSelector(state => ({
    officeAddress: state.officeSettings.officeAddress,
  }));
  const [state, setState] = useSetState({
    billingSchemes: props?.billingInfo?.billingSchemes || [],
    tbRowsFormBilling: props?.billingInfo?.billingItems || [],
    selectedScheme: props?.billingInfo?.billing_id || 0,
    isDefault: props.billingInfo.is_default,
    tbRowsDiscounts: props?.billingInfo?.discountItems || [],
    tbRowsCustomCharges: props?.billingInfo?.customItems || [],
    address: props?.billingInfo?.address || {},
    sameAsOffice: props?.billingInfo?.sameAsOffice === 1,
    isLoading: false,
    anchorEl: null,
    openAddNewSchemeDialog: false,
    itemDialogInfoContent: '',
    itemDialogInfoTitle: 'Information',
    isItemDialogInfoOpen: false,
    showConfirmCancel: false,
    isSnackbarOpen: false,
    showDialog: false,
  });

  useEffect(() => {
    // Double check there is a default schema set
    const defaultBillingScheme = state.billingSchemes?.filter(scheme => {
      return scheme.is_default === 1; // There really should be only one entry
    });
    if (defaultBillingScheme?.length < 1) {
      props.addFrontendNotification({
        id: 0,
        returnID: 0,
        content: 'A billing scheme must be set as default to complete the required office setup',
        type: NOTIFICATION_TYPE.WARNING,
      });
    }
  }, []);

  const updateTbRowsFormBilling = data => {
    setState({
      tbRowsFormBilling: data,
    });
  };

  const handleTBFormRowDel = async (index, arrTable, itemKey, itemType) => {
    try {
      await XlinkAPI.handleFormRowDeletion(index, state.selectedScheme, itemType, props.loginID);
      setState({
        [arrTable]: state[arrTable].filter(item => {
          return item[itemKey] !== index;
        }),
      });
    } catch (error) {
      ErrorHelpers.handleError('Row Deletion Error', error);
    }
  };

  const handleChangeTbForms = (id, dataType, value) => {
    if (state.selectedScheme === 0) {
      setState({ showDialog: true });
      return;
    }
    value = value.replace(/[$,]/g, '');
    const newState = state.tbRowsFormBilling.map(item => {
      if (item.billing_lookup_id === id) {
        return { ...item, [dataType]: value };
      }
      return item;
    });
    setState({
      tbRowsFormBilling: newState,
    });
  };

  const upsertBillingScheme = async () => {
    // check for special case, we do not want customers to use reserved custom codes or create empty discount
    for (let i = 0; i < state.tbRowsDiscounts?.length; i++) {
      const errTitle = 'Unable to save billing information';
      // check for reserved codes
      if (
        state.tbRowsDiscounts[i].discount_code?.toUpperCase() === DFLT ||
        state.tbRowsDiscounts[i].discount_code?.toUpperCase() === DFL2
      ) {
        ErrorHelpers.handleError(
          errTitle,
          ErrorHelpers.createSimpleError(
            `Discount code can not be ${DFLT} or ${DFL2}. Please rename your discount and try again`,
          ),
        );
        return;
        // check for empty amounts
      } else if (
        !state.tbRowsDiscounts[i]?.discount_amount ||
        !state.tbRowsDiscounts[i]?.discount_code
      ) {
        ErrorHelpers.handleError(
          errTitle,
          ErrorHelpers.createSimpleError(
            'A discount was created without setting a code, amount or both. Please update or delete this discount to continue',
          ),
        );
        return;
      }
    }

    try {
      await XlinkAPI.upsertBusinessBillingScheme(
        state.tbRowsFormBilling,
        state.tbRowsCustomCharges,
        state.tbRowsDiscounts,
        state.address,
        state.sameAsOffice ? 1 : 0,
        state.selectedScheme,
        props.loginID,
      );
      toggleState('isSnackbarOpen', true);
      // Refetch billing scheme after to get the IDs of the newly added elements.
      getSelectedScheme(state.selectedScheme);
      props.loadSetupComponent(EXTENDED_SETUP_PAGES.EFILING.NAME);
    } catch (error) {
      ErrorHelpers.handleError('Error Updating Billing Scheme', error);
    }
  };

  const setDefaultBillingScheme = async () => {
    if (state.isDefault === 1) {
      return;
    }

    try {
      const res = await XlinkAPI.setDefaultBillingScheme(
        state.selectedScheme,
        props.loginID,
        BILLING_SCHEMA.BUSINESS,
      );
      setState({
        billingSchemes: res.data,
      });
    } catch (error) {
      ErrorHelpers.handleError('Unable to Set Default Billing Scheme', error);
    }
  };

  const generateBillingSchemes = () => {
    return (
      <NativeSelect
        id="selectedSchemeList"
        value={state.selectedScheme}
        onChange={e => handleSelectedSchemeChange(e.target.value)}
        style={{
          borderRight: 'none',
          borderRadius: '4px 0px 0px 4px',
        }}
        disableUnderline={true}
      >
        {state?.billingSchemes?.map(scheme => {
          let isDefault = '';
          if (scheme.is_default) {
            isDefault = '(Default)';
          }
          return (
            <option key={scheme.billing_id} value={scheme.billing_id}>
              {scheme.billing_scheme_name} {isDefault}
            </option>
          );
        })}
      </NativeSelect>
    );
  };

  const getSelectedScheme = async value => {
    try {
      const res = await XlinkAPI.getBillingOfficeInfo(
        value,
        props.loginID,
        BILLING_SCHEMA.BUSINESS,
      ); // Pull the default billing scheme.
      setState({
        tbRowsFormBilling: res.data.billingItems == null ? [] : res.data.billingItems,
        billingSchemes: res.data.billingSchemes == null ? [] : res.data.billingSchemes,
        isDefault: res.data.is_default,
        sameAsOffice: res.data.sameAsOffice === 1,
        isLoading: false,
      });
    } catch (error) {
      ErrorHelpers.handleError('Error Fetching Billing Office Info', error);
    }
  };

  const handleSelectedSchemeChange = value => {
    setState({ selectedScheme: value, isLoading: true }, getSelectedScheme(value));
  };

  const handleContextMenuOpen = event => {
    event.preventDefault();
    setState({
      anchorEl: event.currentTarget,
    });
  };

  const handleContextMenuAddScheme = () => {
    setState({
      openAddNewSchemeDialog: true,
      anchorEl: null,
    });
  };

  const handleAddNewScheme = async () => {
    const row = {
      billing_id: 0,
      billing_scheme_name: state.newSchemeName,
    };

    try {
      const res = await XlinkAPI.addNewBillingScheme(
        state.newSchemeName,
        props.loginID,
        BILLING_SCHEMA.BUSINESS,
      );
      row.billing_id = res.data.billingID;
      row.is_default = res.data.billing.is_default;
      setState({
        billingSchemes: [...state.billingSchemes, row],
        selectedScheme: row.billing_id,
        isDefault: res.data.billing.is_default,
        tbRowsFormBilling:
          res.data.billing.billingItems == null ? [] : res.data.billing.billingItems,
      });
      toggleState('openAddNewSchemeDialog');
    } catch (error) {
      ErrorHelpers.handleError('Error Adding New Scheme', error);
    }
  };

  const handleContextMenuDeleteScheme = async billingid => {
    if (state.isDefault === 1) {
      setState({ anchorEl: null });
      alert(
        'Unable to delete default billing scheme. Please set a different billing scheme as default and try again.',
      );
      return;
    }

    try {
      await XlinkAPI.handleBillingSchemeDeletion(billingid, props.loginID);
      setState({
        billingSchemes: state.billingSchemes.filter(billid => {
          return billid.billing_id !== billingid;
        }),
        anchorEl: null,
      });
      if (state.billingSchemes.length > 0) {
        getSelectedScheme(state.selectedScheme);
      } else {
        clearScheme();
      }
    } catch (error) {
      ErrorHelpers.handleError('Error Deleting Billing Scheme', error);
    }
  };

  const clearScheme = () => {
    setState({
      billingSchemes: [],
      tbRowsFormBilling: [],
      selectedScheme: 0,
      tbRowsDiscounts: [],
      tbRowsCustomCharges: [],
      tbRowsCustomSettings: [],
      address: {},
      billOptsAndRate: {},
      sameAsOffice: false,
    });
  };

  const handleObjectChange = (obj, objKey, value) => {
    if (objKey === ADDRESS_DATA_STRINGS.SUITE_NUM_STRING) {
      // Checks for alphanumeric value or "#", and allows for blank spaces
      value = value.replace(/[^#a-z0-9 ]+/gi, '');
    }
    if (
      objKey === ADDRESS_DATA_STRINGS.FIRM_NAME_STRING ||
      objKey === ADDRESS_DATA_STRINGS.FIRM_ADDRESS_STRING ||
      objKey === ADDRESS_DATA_STRINGS.SUITE_NUM_STRING
    ) {
      value = value.toUpperCase();
    } else if (objKey === ADDRESS_DATA_STRINGS.CITY_STRING) {
      value = value.toUpperCase();
      value = value.replace(/[0-9]/g, ''); // Remove digits[0-9]
    }
    if (state.selectedScheme === 0) {
      setState({ show: true });
      return;
    }
    setState(prevState => ({
      [obj]: {
        ...prevState[obj],
        [objKey]: value,
      },
    }));
  };

  const removeExtraSpaces = (obj, objKey, value) => {
    let valTrim = value.replace(/\s\s+/g, ' '); // Replace tabs, new tabs and multiple spacing with single blank space
    valTrim = valTrim.trim();
    if (state.selectedScheme === 0) {
      setState({ show: true });
      return;
    }
    setState(prevState => ({
      [obj]: {
        ...prevState[obj],
        [objKey]: valTrim,
      },
    }));
  };

  const openInfoDialog = itemType => {
    let itemDialogInfoContent = '';
    const isItemDialogInfoOpen = true;
    switch (itemType) {
      case BILLING_FORM_TYPES.WORKSHEET:
        itemDialogInfoContent =
          'Enter a price for each worksheet, and it will display on the Invoice when the worksheet is added to the tax return.';
        break;
      case BILLING_FORM_TYPES.LINEITEM:
        itemDialogInfoContent =
          'Enter a price on each form line and it will display on the Invoice when the form is added to the tax return.';
        break;
      case BILLING_FORM_TYPES.FORM:
        itemDialogInfoContent =
          'Enter a price on each form line and it will display on the Invoice when the form is added to the tax return.';
        break;
      case BILLING_ITEM_TYPE.CUSTOM:
        itemDialogInfoContent =
          'Enter pre-set charges to display on line 6 under ERO Fees on the Invoice.';
        break;
      case BILLING_ITEM_TYPE.DISCOUNT:
        itemDialogInfoContent =
          'Enter pre-set discounts to display on line 2 under Discounts & Credits on the Invoice.';
        break;
      default:
        itemDialogInfoContent = false;
    }
    setState({
      isItemDialogInfoOpen: isItemDialogInfoOpen,
      itemDialogInfoTitle: 'Information',
      itemDialogInfoContent: itemDialogInfoContent,
    });
  };

  const addRow = (rowObject, table) => {
    setState(prevState => {
      return { [table]: [...prevState[table], rowObject] };
    });
  };

  const handleChangeTableDiscount = (index, dataType, value) => {
    if (state.selectedScheme === 0) {
      setState({ show: true });
      return;
    }
    const newState = state.tbRowsDiscounts.map((item, i) => {
      if (i === index && dataType === 'discounts_type_id') {
        return { ...item, [dataType]: value, discount_amount: '' };
      } else if (i === index) {
        return { ...item, [dataType]: value };
      }
      return item;
    });
    setState({
      tbRowsDiscounts: newState,
    });
  };

  const handleChangeTableCustom = (index, dataType, value) => {
    if (state.selectedScheme === 0) {
      setState({ show: true });
      return;
    }
    const newState = state.tbRowsCustomCharges.map((item, i) => {
      if (i === index) {
        return { ...item, [dataType]: value };
      }
      return item;
    });
    setState({
      tbRowsCustomCharges: newState,
    });
  };

  const getDiscountTypeByKey = key => {
    return state.tbRowsDiscounts[key].discounts_type_id;
  };

  const toggleState = (state, value = false) => {
    setState({
      [state]: value,
    });
  };

  const handleCheckBox = e => {
    if (e.target.name === 'sameAsOffice') {
      setState({
        address: e.target.checked ? officeAddress : {},
        sameAsOffice: e.target.checked,
      });
    }
  };

  return (
    <div className="container-fixed" style={{ marginTop: '2.5rem' }}>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={state.isSnackbarOpen}
        autoHideDuration={2500}
        onClose={() => toggleState('isSnackbarOpen')}
        id="snkbarconfsaved"
        message={<span id="success-message">Configuration Saved</span>}
      />
      <Paper>
        <form className={classes.billingContainer}>
          <div className={classes.billingContainerSecondary}>
            <div className={classes.headerContainer}>
              <div className={classes.headerStyle}>Business Billing</div>
              <div className={classes.setSchemaHeader}>
                <AccessControl
                  requiredAction="write"
                  accessLevel="add/edit_billing"
                  disableOnFalse={true}
                >
                  <Button
                    id="brnSetAsDefaultBillingSetup"
                    color="primary"
                    onClick={() => setDefaultBillingScheme()}
                    className={classes.setDefaultButton}
                  >
                    Set as Default
                  </Button>
                </AccessControl>
                {generateBillingSchemes()}
                <AccessControl
                  requiredAction="write"
                  accessLevel="add/edit_billing"
                  disableOnFalse={true}
                >
                  <Button
                    className={`contextMenuButton ${classes.btnBorderLeftNone}`}
                    aria-haspopup="true"
                    aria-owns={state.anchorEl ? 'simple-menu' : null}
                    onClick={handleContextMenuOpen}
                  >
                    <img src={menuVertical} />
                  </Button>
                </AccessControl>
                <Menu
                  id="menu-button-menuctxSchema"
                  anchorEl={state.anchorEl}
                  open={Boolean(state.anchorEl)}
                  onClose={() => toggleState('anchorEl', null)}
                >
                  <MenuItem id="menu-button-menuctxSchemaAdd" onClick={handleContextMenuAddScheme}>
                    Create New Scheme
                  </MenuItem>
                  <MenuItem
                    id="menu-button-menuctxSchemaDelete"
                    onClick={() => handleContextMenuDeleteScheme(state.selectedScheme)}
                    hidden={!(state?.billingSchemes?.length > 0)}
                  >
                    Remove Current Scheme
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </div>
          <AddSchemeDialogContent
            openDialog={state.openAddNewSchemeDialog}
            onClose={() => toggleState('openAddNewSchemeDialog')}
            onChange={value => setState({ newSchemeName: value })}
            newSchemeName={state.newSchemeName}
            handleAddNewScheme={handleAddNewScheme}
            handleAddSchemeDialogClose={() => toggleState('openAddNewSchemeDialog')}
          />
          <Dialog
            disableAutoFocus={true}
            open={state.isItemDialogInfoOpen}
            onClose={() => toggleState('isItemDialogInfoOpen')}
          >
            <Fragment>
              <DialogTitle id="dlgInfoDialogTitle">{state.itemDialogInfoTitle}</DialogTitle>
              <DialogContent>
                <DialogContentText>{state.itemDialogInfoContent}</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => toggleState('isItemDialogInfoOpen')}>Close</Button>
              </DialogActions>
            </Fragment>
          </Dialog>
          <Divider />
          <div>
            <AccessControl
              requiredAction="write"
              accessLevel="add/edit_billing"
              disableOnFalse={true}
            >
              <FormControl fullWidth>
                <div style={{ marginLeft: '3vw' }}>
                  <FormControlLabel
                    label="Same as Office Info"
                    control={
                      <Checkbox
                        id="chksameInfo"
                        name="sameAsOffice"
                        checked={state.sameAsOffice}
                        onChange={handleCheckBox}
                        color="primary"
                        value={state.sameAsOffice.toString()}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fontSize: 24, color: '#8FC3FF' }}
                            color="secondary"
                          />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fontSize: 24 }} color="primary" />}
                      />
                    }
                  />
                </div>
              </FormControl>
            </AccessControl>
          </div>
          <GenBillingAddress
            sameAsOfficeInfo={state.sameAsOffice}
            officeAddress={state.address}
            handleObjectChange={handleObjectChange}
            handleRemoveExtraSpaces={removeExtraSpaces}
          />
          <Divider />
          <FormBilling
            form_type={BILLING_FORM_TYPES.FORM}
            billingSelectTitle="Add Billing to Form(s)"
            billingSelectMessage="Select Forms for Billing"
            addButtonName="Add Form +"
            billingTitleType="Form Billing"
            billingTableTabName="FORM NAME"
            tbRowsFormBilling={state.tbRowsFormBilling}
            updateAddedBilling={updateTbRowsFormBilling}
            handleChangeTable={handleChangeTbForms}
            handleRowDel={handleTBFormRowDel}
            itemType={BILLING_ITEM_TYPE.FormWkxLineItem}
            openInfoDialog={openInfoDialog}
            defaultSchemeSelected={state.selectedScheme !== 0}
            isBusiness={true}
          />
          <Divider />
          <FormBilling
            form_type={BILLING_FORM_TYPES.WORKSHEET}
            billingSelectTitle="Add Billing to Worksheet(s)"
            billingSelectMessage="Select Worksheets for Billing"
            addButtonName="Add Worksheet +"
            billingTitleType="Worksheet Billing"
            billingTableTabName="WORKSHEET NAME"
            tbRowsFormBilling={state.tbRowsFormBilling}
            updateAddedBilling={updateTbRowsFormBilling}
            handleChangeTable={handleChangeTbForms}
            handleRowDel={handleTBFormRowDel}
            itemType={BILLING_ITEM_TYPE.FormWkxLineItem}
            openInfoDialog={openInfoDialog}
            defaultSchemeSelected={state.selectedScheme !== 0}
            isBusiness={true}
          />
          <FormBilling
            form_type={BILLING_FORM_TYPES.LINEITEM}
            billingSelectTitle="Add Line Items to Worksheet(s)"
            billingSelectMessage="Select Line Items for Billing"
            addButtonName="Add Line Items +"
            billingTitleType="Line Item Billing"
            billingTableTabName="LINE ITEM"
            tbRowsFormBilling={state.tbRowsFormBilling}
            updateAddedBilling={updateTbRowsFormBilling}
            handleChangeTable={handleChangeTbForms}
            handleRowDel={handleTBFormRowDel}
            itemType={BILLING_ITEM_TYPE.FormWkxLineItem}
            openInfoDialog={openInfoDialog}
            defaultSchemeSelected={state.selectedScheme !== 0}
            isBusiness={true}
          />
          <Divider />

          <BillingDiscounts
            tbRows={state.tbRowsDiscounts}
            handleChangeTable={handleChangeTableDiscount}
            addRow={addRow}
            handleRowDel={handleTBFormRowDel}
            itemType={BILLING_ITEM_TYPE.DISCOUNT}
            openInfoDialog={openInfoDialog}
            getDiscountTypeByKey={getDiscountTypeByKey}
            actionsLocked={state.selectedScheme === 0}
          />
          <Divider />
          <CustomChanges
            tbRowsCustomCharges={state.tbRowsCustomCharges}
            handleChangeTable={handleChangeTableCustom}
            addRow={addRow}
            handleRowDel={handleTBFormRowDel}
            itemType={BILLING_ITEM_TYPE.CUSTOM}
            openInfoDialog={openInfoDialog}
            actionsLocked={state.selectedScheme === 0}
          />
          <CustomSettings ancillaryProducts={props.billingInfo?.ancillary_products || []} />
          <div className={classes.buttonContainer}>
            <span>
              <Button
                id="btnCancelGenBillingSetup"
                color="primary"
                className={classes.cancelSetupButton}
                onClick={() => {
                  setState({ gotoPrev: true });
                  toggleState('showConfirmCancel', true);
                }}
              >
                Previous
              </Button>
              <AccessControl
                requiredAction="write"
                accessLevel="add/edit_billing"
                disableOnFalse={true}
              >
                <Button
                  id="btnSaveBillingSetup"
                  color="primary"
                  className={classes.saveSetupButton}
                  onClick={() => upsertBillingScheme(false)}
                >
                  Save &amp; Next
                </Button>
              </AccessControl>
            </span>
          </div>
        </form>
        <br />
      </Paper>
      <SimpleDialog
        open={state.showConfirmCancel}
        onClose={() => toggleState('showConfirmCancel')}
        onConfirm={() => {
          toggleState('showConfirmCancel');
          if (state.gotoPrev) {
            props.loadSetupComponent(BUSINESS_SETUP_PAGES.CLIENT_LETTERS.NAME);
          } else {
            getSelectedScheme(state.selectedScheme);
          }
        }}
        dialogTitle={'Lose Unsaved Changes?'}
        contentText={'Are you sure you want to undo any pending changes?'}
      />
      <Dialog
        open={state.showDialog}
        onClose={() => toggleState('showDialog')}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Billing Scheme Missing'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {
              "You must first add a new billing scheme before continuing. \n\nPlease click the button next to 'Set as Default' to name and save your new Billing scheme."
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => toggleState('showDialog')} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(withStyles(styles)(BusinessBillingSetup));

import {
  actions,
  FETCH_PREPARER_SIGNATURE_CALL_REQUEST,
  FETCH_ERO_SIGNATURE_CALL_REQUEST
} from "./duck";

import { takeLatest, call, put } from "redux-saga/effects";

import XlinkAPI from "~/app/api/xlinkAPI";
import ErrorHelpers from "~/app/errorHelpers.js";

export function* watcherSagaRemoteSignature() {
  yield takeLatest(
    FETCH_PREPARER_SIGNATURE_CALL_REQUEST,
    workerSagaPreparerData
  );
  yield takeLatest(FETCH_ERO_SIGNATURE_CALL_REQUEST, workerSagaEROData);
}

export function* workerSagaPreparerData() {
  try {
    const res = yield call(XlinkAPI.getprepDataRemoteSignAuth);
    yield put(actions.requestPreparerSignatureSuccess(res.data));
  } catch (error) {
    ErrorHelpers.handleError("Error Fetching ", error);
    yield put(actions.requestPreparerSignatureError());
  }
}

export function* workerSagaEROData(payload) {
  console.log("Payload.activeEfin: ", payload.activeEfin);
  try {
    const res = yield call(XlinkAPI.getActiveEfinDataRemoteSignAuth, {
      activeEfin: payload.activeEfin
    });
    yield put(actions.requestEROSignatureSuccess(res.data));
  } catch (error) {
    ErrorHelpers.handleError("Error Fetching ", error);
    yield put(actions.requestEROSignatureError());
  }
}

// External imports
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  withStyles,
  Typography,
  CircularProgress,
} from '@material-ui/core';
// Styling imports
import { styles } from '~/app/Components/Common/css/simpleDialog.js';

/*
Returns a simple confirm/cancel Material UI dialog
Required Props:
    open: open state of the dialog window
    onConfirm: function ran on confirm click
Optional Props:
    onClose: function ran on dialog close/cancel click
    dialogTitle: text for the dialog title
    contentText: text for the dialog -  additional types of content can be passed in as a child of <SimpleDialog> component
    contentTextTwo: Additional text to display after contentText.
    cancelText: Text for the cancel/close button
    confirmTest: Text for the confirm button
*/

const StyledCircularProgress = withStyles({
  root: {
    color: '#ffffff',
  },
})(CircularProgress);

const SimpleDialog = props => {
  const { classes } = props;

  const confirmLoader = () => {
    const confirmText = props.confirmText || 'Confirm';

    return !props.confirmLoader ? (
      confirmText
    ) : props.confirmLoader ? (
      <StyledCircularProgress size={24} />
    ) : (
      confirmText
    );
  };

  return (
    <Dialog
      classes={{ root: classes.styleIndex }}
      disableAutoFocus
      PaperProps={{ tabIndex: -1 }}
      disableBackdropClick
      open={props.open}
      onClose={props.onClose}
      aria-describedby="cancel-confirm-desc"
    >
      {props.dialogTitle ? (
        <DialogTitle className={props.styled ? classes.styledHeader : ''}>
          <Typography className={props.styled ? classes.styledTitle : ''}>
            {props.dialogTitle}
          </Typography>
        </DialogTitle>
      ) : (
        <Fragment />
      )}
      <DialogContent id="confirm-asset-delete-desc">
        {props.errorMessage && (
          <div className={classes.styledErrorDiv}>
            <div>{props.contentText}</div>
          </div>
        )}
        {props.contentText && !props.errorMessage && (
          <DialogContentText className={props.styled ? classes.styledChildren : ''}>
            {props.contentText}
          </DialogContentText>
        )}
        {props.contentTextTwo && (
          <DialogContentText className={props.styled ? classes.styledChildren : ''}>
            {props.contentTextTwo}
          </DialogContentText>
        )}
        {props.contentTextThree && (
          <DialogContentText className={props.styled ? classes.styledChildren : ''}>
            {props.contentTextThree}
          </DialogContentText>
        )}
        {props.children && !props.errorMessage && (
          <div className={props.styled ? classes.styledChildren : ''}>{props.children}</div>
        )}
        {props.extendedBody && !props.errorMessage && (
          <div className={props.styled ? classes.styledChildren : ''}>{props.extendedBody}</div>
        )}
      </DialogContent>
      <DialogActions>
        {props.onClose ? (
          <Button
            id="btnCancelDialog"
            className={props.styled ? classes.styledButtonClose : ''}
            onClick={props.onClose}
          >
            {props.cancelText || 'Cancel'}
          </Button>
        ) : (
          <Fragment />
        )}
        <Button
          color="primary"
          variant="contained"
          id="btnConfirmDialog"
          className={props.styled ? classes.styledButton : ''}
          onClick={props.onConfirm}
        >
          {confirmLoader()}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SimpleDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  dialogTitle: PropTypes.string,
  contentText: PropTypes.string,
  contentTextTwo: PropTypes.string,
  cancelText: PropTypes.string,
  confirmTest: PropTypes.string,
};

export default withStyles(styles)(SimpleDialog);

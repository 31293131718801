export const styles = theme => ({
  archiveDocContainer: {
    padding: theme.spacing.unit * 3,
    height: '100%',
    maxHeight: '98%',
    width: '100%',
    minWidth: '600px',
    backgroundColor: 'white',
    overflowY: 'auto',
  },
  archiveHeader: {
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 6,
    paddingBottom: theme.spacing.unit * 4,
  },
  addDocButton: {
    width: '20%',
    margin: '0 1rem',
    padding: '0 .5rem',
  },
  modal: {
    top: '50%',
    left: '50%',
    boxShadow: '0 0 20px 0 rgba(0,0,0,0.25)',
    maxWidth: '80vw',
    minWidth: '30vw',
    padding: '0',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
  },
  modalSpacing: {
    marginTop: '3.7rem',
  },
  modalTitle: {
    paddingLeft: theme.spacing.unit * 3,
    color: 'white',
    backgroundColor: '#444A59',
    height: '70px',
    paddingTop: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 2,
  },
  modalContent: {
    margin: theme.spacing.unit * 4,
  },
  cellRoot: {
    borderBottom: 'none',
    fontSize: '14px',
    color: '#67727C',
  },
  cellHeader: {
    color: '#354052',
    borderBottom: 'none',
    fontSize: '14px',
    textAlign: 'left',
  },
  previewButton: {
    height: '30px',
    minHeight: '30px',
    width: '60px',
    minWidth: '60px',
  },
  modalDesc: {
    fontFamily: 'Roboto',
    fontSize: '15px',
    lineHeight: '18px',
    color: '#354052',
    width: '72%',
  },
  modalDocLink: {
    width: '22%',
  },
  modalSecondaryData: {
    color: '#67727C',
    fontSize: '12px',
    lineHeight: '14px',
    display: 'inline-flex',
  },
  modalSecondaryDataTwo: {
    color: '#67727C',
    fontSize: '12px',
    lineHeight: '14px',
    display: 'inline-flex',
    marginBottom: theme.spacing.unit * 2,
  },
  modalImageContent: {
    overflow: 'auto',
    textAlign: 'center',
    border: '1px solid black',
  },
  docTranspBtn: {
    color: '#0077FF',
    border: 'none',
  },
  modalButtons: {
    textAlign: 'right',
    marginTop: theme.spacing.unit * 2,
  },
  alertBody: {
    fontSize: '1.3rem',
  },
  alertModalButtonsContainer: {
    textAlign: 'center',
  },
  alertModalButton: {
    fontFamily: 'Roboto',
    fontSize: '1rem',
    margin: '1rem',
  },
  previewModalBtn: {
    fontFamily: 'Roboto',
    fontSize: '15px',
  },
  addDocumentModal: {
    top: '50%',
    left: '50%',
    boxShadow: '0 0 20px 0 rgba(0,0,0,0.25)',
    height: 'auto',
    maxWidth: '860px',
    width: '100%',
    padding: '0',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
  },
  requestDocBody: {
    padding: '1.5rem 1.5rem 0 1.5rem',
  },
  modalHeaderTitleStyle: {
    paddingLeft: '1.5rem',
  },
  styleButtonMarginRight: {
    marginRight: '.5rem',
  },
  archiveHeaderVertDivider: {
    margin: '0 1rem',
    backgroundColor: 'black',
    width: '1px',
    height: '1.6rem',
  },
  selectedHeaderStyle: {
    borderBottom: '2px solid black',
  },
  unselectedHeaderStyle: {
    color: 'grey',
    '&:hover': {
      transition: 'all .3s ease-in',
      color: 'black',
    },
  },
  archiverHeaderTitle: {
    cursor: 'pointer',
  },
  statusIconStyle: {
    height: '1.3rem',
    margin: '0 .3rem .1rem 0',
  },
});

export const fullWidgetDetailsStyles = theme => {
  return {
    imagesContainer: {
      width: '85%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '32px',
      marginBottom: '32px',
    },
    widgetTabContent: {
      paddingLeft: theme.spacing.unit * 4,
      paddingRight: theme.spacing.unit * 4,
      paddingTop: theme.spacing.unit,
      height: '90%',
    },
    previewImageList: {
      padding: 0,
      display: 'inline-block',
      listStyleType: 'none',
      minHeight: '340px',
    },
    cardMainImage: {
      paddingBottom: theme.spacing.unit * 2,
      height: '100%',
      width: '100%',
      border: '1px solid #EBEDF8',
      display: 'flex',
      '& > img': {
        width: '625px',
        height: '322px',
      },
    },
    cardTitle: {
      fontFamily: 'Roboto',
      color: '#354052',
      fontSize: '22px',
      letterSpacing: '0.1px',
      paddingBottom: theme.spacing.unit * 2,
      textAlign: 'left',
    },
    cardDescription: {
      fontFamily: 'Roboto',
      color: '#67727C',
      fontSize: '15px',
      maxHeight: '10%',
      minHeight: '8%',
      lineHeight: '18px',
      marginBottom: theme.spacing.unit * 2,
    },
    cardButton: {
      width: '28%',
    },
    closeButtonStyle: {
      marginLeft: '1vw',
      backgroundColor: '#0077FF',
      color: '#FFFFFF',
      textTransform: 'capitalize',
      borderRadius: '4',
      '&:hover': {
        color: '#0077FF',
        opacity: 1,
      },
      '&:disabled': {
        backgroundColor: '#dddddd',
      },
    },
    modalTitle: {
      paddingLeft: theme.spacing.unit * 4,
      color: 'white',
      backgroundColor: '#444A59',
      height: '70px',
      paddingTop: theme.spacing.unit * 3,
      paddingRight: theme.spacing.unit * 2,
    },
    subimageCard: {
      marginTop: '10px',
      paddingTop: '30px',
      paddingBottom: '30px',
      backgroundColor: 'white',
      '&:focus': {
        border: '1px solid #0077FF',
        backgroundColor: '#F3F4F9',
      },
      '&:first-child': {
        marginTop: '30px',
      },
    },
    buttonFooterSection: {
      width: '100%',
      marginTop: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
      paddingBottom: '36px',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    tab: {
      fontSize: '11px',
      color: '#354052',
      borderBottom: '1px solid #e8e8e8',
    },
    '&$tabSelected': {
      color: '#354052',
      fontWeight: theme.typography.fontWeightMedium,
      border: 'none',
    },
    tabRoot: {
      textTransform: 'initial',
      minWidth: 72,
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing.unit * 4,
      fontFamily: 'Roboto',
      '&:hover': {
        color: '#354052',
        opacity: 1,
      },
      '&$tabSelected': {
        color: '#354052',
        fontWeight: theme.typography.fontWeightMedium,
        border: 'none',
      },
      '&:focus': {
        color: '#354052',
      },
    },
    tabSelected: {},
    typography: {
      padding: theme.spacing.unit * 3,
    },
    titleBarTabroot: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      borderBottom: '1px solid #EBEDF8',
      marginBottom: theme.spacing.unit * 2,
      marginLeft: theme.spacing.unit * 4,
      marginRight: theme.spacing.unit * 4,
    },
  };
};

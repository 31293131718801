const drawerWidth = '15rem';

export const styles = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth})`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    backgroundColor: '#444A59',
    borderRight: '0px',
    marginTop: '67px',
    height: `calc(100% - 67px)`,
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'space-between',
  },
  drawerOpenTraining: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: '#444A59',
    borderRight: '0px',
    marginTop: '80px',
    height: `calc(100% - 80px)`,
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'space-between',
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9 + 1,
    },
    backgroundColor: '#444A59',
    borderRight: '0px',
    marginTop: '67px',
    height: `calc(100% - 67px)`,
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    backgroundColor: '#444A59',
    borderRight: '0px',
  },
  content: {
    flexGrow: 1,
    marginTop: '4em',
    height: 'calc(100vh - 5.5em)',
    overflowY: 'auto',
  },
  contentNoScroll: {
    flexGrow: 1,
    marginTop: '4em',
  },
  sidebarIconBtn: { border: 'none' },
  sidebarListItemLabel: {
    color: 'lightgray',
    textTransform: 'capitalize',
  },
  typography: {
    display: 'inline',
    paddingLeft: '1.6rem',
    color: 'lightgray',
  },
  selected: {
    backgroundColor: '#282c34',
    '& img': {
      filter: 'contrast(200%)',
    },
    '&:hover': {
      backgroundColor: '#282c34',
    },
  },
  selectedSubNav: {
    backgroundColor: '#282c34',
    '&:hover': {
      backgroundColor: '#282c34',
    },
  },
  selectedSubMenu: {
    backgroundColor: 'rgb(7 7 7 / 30%)',
    '& img': {
      filter: 'contrast(200%)',
    },
    paddingLeft: '3.1rem',
    '&:hover': {
      backgroundColor: 'rgb(7 7 7 / 30%)',
    },
  },
  itemBadge: {
    paddingLeft: '12px',
  },
  listItem: {
    marginLeft: '10px',
  },
  subMenuCollapse: {
    backgroundColor: '#3a3d44',
    boxShadow: 'inset -2px 0px 10px #000',
  },
  subMenuList: {
    padding: '0',
  },
  nonSelectedsubMenu: {
    paddingLeft: '3.1rem',
  },
  expand: {
    color: 'lightgrey',
  },
});

import { FORM_NAMES } from '~/app/constants';

export const getFedFormFromProps = formList => {
  var form;
  for (let i = 0; i < formList.length; i++) {
    if (formList[i].desc && formList[i].desc.trim() === 'FEDERAL' && formList[i].var == 'US') {
      form = formList[i];
    }
  }

  return form;
};

export const handleMenuItemHighlight = (activeForm, formName) => {
  if (formName === '000000') {
    if (activeForm && activeForm === FORM_NAMES.CDS) {
      return true;
    }
    return false;
  }
  if (activeForm && activeForm === formName) {
    return true;
  }
  return false;
};

import React, { useEffect } from 'react';
// Internal imports
import XlinkAPI from '../../../api/xlinkAPI';
import ErrorHelpers from '~/app/errorHelpers.js';
import SimpleDialog from '#/Common/SimpleDialog.jsx';
import {
  STATE_LIST_ADDRESS,
  CELL_PHONE_CARRIERS,
  NEW_PREPARER_TYPE,
  NOTIFICATION_TYPE,
  VALID_EMAIL_REGEX,
} from '~/app/constants.js';
import { useSetState } from '~/app/Utility/customHooks';
import { statusOK } from '~/app/webHelpers.js';
// External imports
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  Input,
  Grid,
  Paper,
  NativeSelect,
  Divider,
  Button,
  AppBar,
  Toolbar,
  Typography,
  Checkbox,
  withStyles,
  InputAdornment,
} from '@material-ui/core';
import { CheckBoxOutlineBlank, AccountCircle } from '@material-ui/icons';
import { get } from 'lodash-es';
import NumberFormat from 'react-number-format';
// Redux imports
import { actions as appActions } from '~/app/redux/modules/app';
import { actions as setupPageActions } from '~/app/redux/setupPages/duck';
import { actions as notificationActions } from '~/app/redux/notifications/duck';
import { useSelector, useDispatch } from 'react-redux';
// Styling imports
import '#/NewAccount/SingleOffice/css/newPreparer.css';
import { styles } from '~/app/Components/NewAccount/SingleOffice/css/newPreparer.js';

/**
 * New Preparer Modal that opens in Account Setup when "Creating New Preparer".
 * It allows you create a new Preparer or edit an existing one.
 *
 * @component NewPreparerModal
 * @catergory AccountSetup
 */
const NewPreparerModal = props => {
  const dispatch = useDispatch();
  const { classes } = props;
  const { alert, currentView } = useSelector(state => ({
    alert: state.app.alert,
    currentView: state.drilldown.drilldownHistory[state.drilldown.drilldownHistory.length - 1],
  }));

  const [state, setState] = useSetState({
    npPreparerID: '',
    npThirdPartyPIN: '',
    npCAF: '',
    npSelfEmployed: false,
    npPreparerName: '',
    npPreparerSSN: '',
    npPreparerPTIN: '',
    npPreparerType: '',
    npEmail: '',
    npCellPhone: '',
    npCellPhoneCarrier: '',
    npStateCodeOne: '',
    npStateIDOne: '',
    npStateCodeTwo: '',
    npStateIDTwo: '',
    // Second section: can be same as main office info
    npSameAsMainOffice: false,
    npFirmName: '',
    npEIN: '',
    npBankCode: '',
    npFirmAddress: '',
    npCity: '',
    npState: '',
    npZip: '',
    npOfficePhone: '',
    npElectronicFilingID: '',
    npStatus: 0,
    curPreparerID: 0,
    preparer: [],
    officeEFINID: 0,
    npDefaultPIN: '',
    confirmCancel: false,
  });

  /**
   * handles modal popup to display to user if they are canceling changes
   *
   * @param {boolean} toggle toggle is what we check for to show the 'Lose Changes' modal
   */
  const toggleConfirmCancel = toggle => {
    setState({
      confirmCancel: toggle,
    });
  };

  /** When component mounts, checks to see if preparer exists with ID or clears state */
  useEffect(() => {
    if ((props.selected_preparer_id === 0) | (props.selected_preparer_id == null)) {
      props.clearPreparerState();
    } else {
      getPreparerInfo(props.selected_preparer_id);
    }
  }, []);

  useEffect(() => {
    getSameAsOffice();
  }, [state.npSameAsMainOffice]);

  /**
   * Handle onChange without validation for selec/menuitem fields
   *
   * @param {event} event for retrieving targeted input value
   */
  const handleChange = event => {
    setState({ [event.target.name]: event.target.value });
  };

  /**
   * handles checking inputs that should only contain numeric values
   * begins with a number, contains at least one number, entirely consists of number chars
   *
   * @param {event} e for retrieving targeted input value
   */
  const handleNumericFieldChange = e => {
    const reNums = /^[0-9]+$/;

    if (e.target.value !== '' && !reNums.test(e.target.value)) {
      e.preventDefault();
    } else {
      handleInputChange(e);
    }
  };

  /**
   * Trim leading and trailing spaces
   *
   * @param {event} e for retrieving targeted input value
   */
  const removeExtraSpaces = e => {
    const name = e.target.name;
    let value = e.target.value;
    value = value.replace(/\s\s+/g, ' '); // Replace tabs, new tabs and multiple spacing with single blank space
    value = value.trimStart();
    value = value.trimEnd();
    setState({ [name]: value });
  };

  /**
   * handles onChange without validation for input fields
   *
   * @param {event} e for retrieving targeted input value
   */
  const handleInputChange = e => {
    const name = e.target.name;
    let value = e.target.value;

    // array of input names that use whitespace
    const inputList = ['npPreparerName', 'npFirmName', 'npFirmAddress', 'npCity'];

    // Checks to see if input name is in a array of inputs that need whitespace included in their values, if not trim all whitespace
    if (!inputList.includes(name)) {
      value = value.trim();
    }

    // NOTE: In all regex expressions below The caret (^) character is the negation of the set [...], g for global
    // i for case insensitive(will work for lowercase and uppercase)
    switch (name) {
      case 'npPreparerID':
        // For preparer id/shortcut input field only allow one word
        // Valid charcters are alpha-numeric and underscores(\w)
        value = value.replace(/[^\w]/g, '');
        break;
      case 'npCAF':
        // Check value for CAF, alphanumeric, no blank spaces
        value = value.replace(/[^a-z0-9]+/gi, '');
        break;
      case 'npPreparerName':
        // For preparer name input field we will allow blank spaces, uppercase and lowercase letters and the dash/hyphen for hyphenated last names and ' for last name as well
        // value = value.replace(/[^a-zA-Z-' ]+/g, '') which is equivalent to =>  value = value.replace(/[^a-z- ]+/gi, '')
        value = value.replace(/\s[^a-zA-Z'-]/gi, '');
        break;
      case 'npFirmAddress':
        // For firm address input we will allow blank spaces, numbers, and letters(uppercase and lowercase)
        value = value.replace(/[^a-z0-9 ]+/gi, '');
        break;
      case 'npCity':
        // Need to check that city does not contain either special characters, only letters[a-z] or digits[0-9] allowed
        value = value.replace(/[^a-z ]+/gi, '');
        break;
      default:
        break;
    }

    // Set the state for input field
    setState({
      [name]: value.toUpperCase(), // Using uppercase for input fields
    });
  };

  /**
   * handles onChange without validation for checkbox
   *
   * @param {event} event for retrieving targeted input value
   */
  const handleCheckbox = event => {
    setState({ [event.target.name]: event.target.checked });
  };

  const getSameAsOffice = async () => {
    if (state.npSameAsMainOffice) {
      try {
        const res = await XlinkAPI.getOfficeSetup(currentView.loginID, state.officeEFINID);
        if (statusOK(res)) {
          setState({
            npFirmName: res.data.officeInfo.firmName,
            npFirmAddress: res.data.officeInfo.firmAddress,
            npCity: res.data.officeInfo.city,
            npState: res.data.officeInfo.state,
            npZip: res.data.officeInfo.zip,
            npOfficePhone: res.data.officeInfo.phone,
            npStatus: res.data.officeInfo.status,
            npElectronicFilingID: res.data.EFIN,
          });
        }
      } catch (err) {
        console.log('err: ' + err);
        ErrorHelpers.handleError('Failed to get Office setup', err);
      }
    } else {
      setState({
        npFirmName: '',
        npFirmAddress: '',
        npCity: '',
        npState: '',
        npZip: '',
        npOfficePhone: '',
        npElectronicFilingID: '',
        npStatus: 0,
      });
    }
  };

  /**
   * Adding dynamic menu for state
   *
   * @param {Object} contents object contains number key values, and state abbreviations for values
   * @param {string} fieldName specific input field name
   * @param {boolean} validPreparerState checking for valid state
   * @param {boolean} sameAsOffice pre fills information if true
   * @returns {jsx} renders dropdown with generated options to select
   */
  const renderStateDropdown = (contents, fieldName, validPreparerState, sameAsOffice) => {
    return (
      <NativeSelect
        id={`ddlStateNewPreparer-${fieldName}`}
        value={state[fieldName] ? state[fieldName] : ''}
        onChange={handleChange}
        disabled={sameAsOffice}
        name={fieldName}
        classes={{ root: classes.styleDropdown12 }}
        error={!sameAsOffice && !validPreparerState}
        disableUnderline
      >
        <option value="" classes={{ root: classes.menuItemStyle }} />
        {contents.map(entry => {
          return (
            <option key={entry} value={entry} classes={{ root: classes.menuEntryStyle }}>
              {entry}
            </option>
          );
        })}
      </NativeSelect>
    );
  };

  /**
   * Adding dynamic menu for preparer type
   *
   * @param {Object} contents renders list of preparer types
   * @returns {jsx} renders dropdown with generated options to select
   */
  const renderDropdownPreparerType = contents => {
    return (
      <NativeSelect
        id="ddlPreparerTypeNewPreparerSelect"
        value={state.npPreparerType ? state.npPreparerType : ''}
        name="npPreparerType"
        onChange={handleChange}
        classes={{ root: classes.styleDropdown27 }}
        disableUnderline
      >
        <option value="" classes={{ root: classes.menuItemStyle }} />
        {contents.map(entry => {
          return (
            <option key={entry} value={entry} classes={{ root: classes.menuEntryStyle }}>
              {entry}
            </option>
          );
        })}
      </NativeSelect>
    );
  };

  /**
   * Adding dynamic menu for cell phone carriers
   *
   * @param {Object} contents renders list of cell phone carriers
   * @returns {jsx} renders dropdown with generated options to select
   */
  const renderDropdownCellPhoneCarriers = contents => {
    return (
      <NativeSelect
        id="ddlCellphoneCarrierNewPreparer"
        value={state.npCellPhoneCarrier}
        onChange={handleChange}
        classes={{ root: classes.styleDropdown27 }}
        disableUnderline
        name="npCellPhoneCarrier"
      >
        <option value="" classes={{ root: classes.menuItemStyle }} />
        {contents.map(entry => {
          return (
            <option key={entry} value={entry} classes={{ root: classes.menuEntryStyle }}>
              {entry}
            </option>
          );
        })}
      </NativeSelect>
    );
  };

  /** Adding function that will return object and make call to xlinkAPI */
  const submitNewPreparerData = async () => {
    const newPreparerData = {
      npPreparerID: state.npPreparerID,
      npThirdPartyPIN: state.npThirdPartyPIN.toString(),
      npCAF: state.npCAF.toString(),
      npSelfEmployed: state.npSelfEmployed,
      userName: state.npPreparerName,
      npPreparerSSN: state.npPreparerSSN.toString().padStart(9, '0'),
      npPreparerPTIN: 'P' + state.npPreparerPTIN.toString(), // Adding "P" as prefix for PTIN
      npPreparerType: state.npPreparerType,
      emailAddress: state.npEmail,
      npCellPhone: state.npCellPhone.toString().replace(/[^0-9]/g, ''),
      npCellPhoneCarrier: state.npCellPhoneCarrier,
      npStateCodeOne: state.npStateCodeOne,
      npStateIDOne: state.npStateIDOne,
      npStateCodeTwo: state.npStateCodeTwo,
      npStateIDTwo: state.npStateIDTwo,
      npSameAsMainOffice: state.npSameAsMainOffice,
      npFirmName: state.npFirmName,
      npEIN: state.npEIN.toString().replace('-', '').padStart(9, '0'),
      npBankCode: state.npBankCode,
      npFirmAddress: state.npFirmAddress,
      npCity: state.npCity,
      npState: state.npState,
      npZip: state.npZip,
      npOfficePhone: state.npOfficePhone.toString().replace(/[^0-9]/g, ''),
      npElectronicFilingID: state.npElectronicFilingID,
      addrContactID: state.preparer.address_contact_id,
      npDefaultPIN: state.npDefaultPIN.toString(),
    };
    // Need to check if we are updating or inserting new record.
    if (state.curPreparerID !== 0) {
      try {
        const res = await XlinkAPI.updatePreparer(
          newPreparerData,
          state.curPreparerID,
          currentView.loginID,
        );
        if (statusOK(res, false)) {
          props.onSuccess();
          props.handleClosePreparerModal();
        }
      } catch (err) {
        ErrorHelpers.handleError('Failed to update preparer', err);
      }
    } else {
      // Calling postNewPreparer to post a new preparer
      try {
        const res = await XlinkAPI.postNewPreparer(newPreparerData, currentView.loginID);
        if (statusOK(res, false)) {
          props.onSuccess();
          if (
            props.officeSetupProgressFlags.office === true &&
            props.officeSetupProgressFlags.ero === true &&
            props.officeSetupProgressFlags.preparer === false &&
            props.officeSetupProgressFlags.billing === true
          ) {
            dispatch(
              notificationActions.addFrontendNotification({
                id: 0,
                returnID: 0,
                content: 'Required Office Setup Completed Successfully',
                type: NOTIFICATION_TYPE.SUCCESS,
              }),
            );
            props.onSetupComplete();
          }
          dispatch(setupPageActions.updateOfficeSetupFlag('preparer', true));
          props.clearPreparerState();
          props.handleClosePreparerModal();
        }
      } catch (err) {
        ErrorHelpers.handleError('Failed to create new preparer', err);
      }
    }
  };

  /**
   * Fetches Preparers data by using prop passed in and saves to state
   *
   * @param {number} preparerId used to fetch preparers data using their ID
   */
  const getPreparerInfo = async preparerId => {
    try {
      const res = await XlinkAPI.getPreparerInfo(preparerId);
      if (statusOK(res)) {
        const stateIDOne = res.data.stateIDOne === '0' ? '' : res.data.stateIDOne;
        const stateIDTwo = res.data.stateIDTwo === '0' ? '' : res.data.stateIDTwo;
        const prepSSN = get(res.data, 'preparer_ssn', '0') === '0' ? '' : res.data.preparer_ssn;
        const prepEIN = get(res.data, 'EIN', '0') === '0' ? '' : res.data.EIN;

        setState({
          npPreparerID: res.data.shortcut_id,
          npThirdPartyPIN: res.data.PIN,
          npCAF: res.data.caf,
          npSelfEmployed: res.data.self_employed,
          npPreparerName: res.data.preparer_name,
          npPreparerSSN: prepSSN.toString().padStart(9, '0'), // ssn should be 9 digits, pad with 0s
          npPreparerPTIN: res.data.PTIN.toString().replace('P', ''),
          npPreparerType: res.data.preparer_type,
          npEmail: res.data.prep_email,
          npCellPhone: res.data.prep_cell_phone,
          npCellPhoneCarrier: res.data.prep_carrier,
          npStateCodeOne: res.data.stateCodeOne,
          npStateIDOne: stateIDOne,
          npStateCodeTwo: res.data.stateCodeTwo,
          npStateIDTwo: stateIDTwo,
          npSameAsMainOffice: res.data.same_as_office_info,
          npFirmName: res.data.firm_name,
          npEIN: prepEIN.toString().padStart(9, '0'),
          npBankCode: res.data.bankCode,
          npFirmAddress: res.data.address1,
          npCity: res.data.city,
          npState: res.data.state_code,
          npZip: res.data.postal_code,
          npOfficePhone: res.data.prep_office_phone,
          npElectronicFilingID: res.data.prep_efin_id,
          npStatus: res.data.status,
          curPreparerID: res.data.preparer_id,
          preparer: res.data,
          officeEFINID: res.data.efin_id,
          npDefaultPIN: res.data.default_pin === 0 ? '' : res.data.default_pin,
        });
      }
    } catch (err) {
      ErrorHelpers.handleError('Fetch Error', err);
    }
  };

  // Check if all the required fields are valid before allowing the user to click on "Save" button
  // The required fields to create a new preparer under first section of the new preparer form are:
  // Preparer's ID/Shortcut, Preparer's Name, Preparer's SSN, PTIN, Preparer's Email
  const validPreparerID = state.npPreparerID !== undefined && state.npPreparerID.length > 0;
  const validPreparerName = state.npPreparerName !== undefined && state.npPreparerName.length > 0;
  const validPreparerPTIN = state.npPreparerPTIN !== undefined && state.npPreparerPTIN.length === 8;
  const validPreparerEmail = state.npEmail !== undefined && VALID_EMAIL_REGEX.test(state.npEmail);

  // Under the second section of the new preparer form (This section is only required when the "Same as main office" option is not checked)
  // Office Name, Office Address, City, State, Zip
  const validPrepOfficeName = state.npFirmName !== undefined && state.npFirmName.length > 0;
  const validPrepOfficeAddress =
    state.npFirmAddress !== undefined && state.npFirmAddress.length > 0;
  const validPreparerCity = state.npCity !== undefined && state.npCity.length > 0;
  const validPreparerState = state.npState !== undefined && state.npState.length === 2;
  // For valid zip the regex matches 5 digits(0-9) then matches "-" or blank space followed by \d{4} matches 4 digits(0-9) and ? used as quantifier to see if there is a match in the preceding token
  const validPreparerZip = state.npZip !== undefined && /^\d{5}(?:[-\s]\d{4})?$/.test(state.npZip);

  const sameAsOffice = state.npSameAsMainOffice;
  // Make first section of the new preparer form invalid whenever there is an invalid field within that section
  const disableSubmitFirstSection = !(
    validPreparerID &&
    validPreparerName &&
    validPreparerPTIN &&
    validPreparerEmail
  );
  // Make second section of the new preparer form invalid whenever there is an invalid field within that section
  const disableSubmitSecondSection = !(
    validPrepOfficeName &&
    validPrepOfficeAddress &&
    validPreparerCity &&
    validPreparerState &&
    validPreparerZip
  );
  // If sameAsOffice true, only consider disableSubmitFirstSection to disable submit button,
  // if false need to consider both disableSubmitFirstSection and disableSubmitSecondSection
  let disableNewPreparer = false;
  if (!sameAsOffice) {
    disableNewPreparer = disableSubmitFirstSection || disableSubmitSecondSection;
  } else {
    disableNewPreparer = disableSubmitFirstSection;
  }

  return (
    <div>
      <div>
        <Paper>
          <AppBar position="static" classes={{ root: classes.newPreparerSetupHeader }}>
            <Toolbar classes={{ root: classes.toolbarColor }}>
              <Typography classes={{ root: classes.typographyStyle }}>
                <AccountCircle classes={{ root: classes.accountCircle }} />
                Create New Preparer
              </Typography>
            </Toolbar>
          </AppBar>
          <form className="new-preparer-form">
            <span>
              <div className="new-preparer-section-headings">{`Preparer's Filing Info`}</div>
              <div className={{ root: classes.root }}>
                <Grid container spacing={8}>
                  <Grid item xs={3}>
                    <FormControl required>
                      <InputLabel
                        required={false}
                        classes={{ root: classes.labelWidthStyle }}
                        htmlFor="txtNpPreparerIDNewPreparer"
                        shrink
                      >
                        Preparer{"'"}s ID/Shortcut
                      </InputLabel>
                      <Input
                        id="txtNpPreparerIDNewPreparer"
                        name="npPreparerID"
                        value={state.npPreparerID}
                        onChange={handleInputChange}
                        classes={{ root: classes.width12 }}
                        disableUnderline
                        required
                        autoComplete="off"
                        inputProps={{
                          maxLength: 7,
                        }}
                        error={!validPreparerID}
                        onBlur={removeExtraSpaces}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl>
                      <InputLabel
                        classes={{ root: classes.labelWidthStyle }}
                        required={false}
                        htmlFor="txtNpThirdPartyPINNewPreparer"
                        shrink
                      >
                        Third Party Designee PIN
                      </InputLabel>
                      <Input
                        id="txtNpThirdPartyPINNewPreparer"
                        name="npThirdPartyPIN"
                        value={state.npThirdPartyPIN}
                        onChange={handleNumericFieldChange}
                        classes={{ root: classes.smallStyleTwo }}
                        disableUnderline
                        autoComplete="off"
                        onBlur={removeExtraSpaces}
                        inputProps={{ maxLength: 5 }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl>
                      <InputLabel
                        classes={{ root: classes.labelWidthStyle }}
                        required={false}
                        htmlFor="txtNpCAFNewPreparer"
                        shrink
                      >
                        CAF
                      </InputLabel>
                      <Input
                        id="txtNpCAFNewPreparer"
                        name="npCAF"
                        value={state.npCAF}
                        onChange={handleInputChange}
                        classes={{ root: classes.smallStyle }}
                        disableUnderline
                        autoComplete="off"
                        inputProps={{
                          maxLength: 11,
                        }}
                        onBlur={removeExtraSpaces}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl>
                      <FormControlLabel
                        label="Self-Employed"
                        classes={{ root: classes.labelWidthStyleCheckbox }}
                        control={
                          <Checkbox
                            classes={{ root: classes.checkBoxStyle }}
                            icon={
                              <CheckBoxOutlineBlank
                                classes={{ root: classes.checkBoxOutlineBlankStyle }}
                                color="secondary"
                              />
                            }
                            id="chkNpSelfEmployedNewPreparer"
                            name="npSelfEmployed"
                            checked={state.npSelfEmployed}
                            onChange={handleCheckbox}
                            inputProps={{ height: '20px', width: '20px' }}
                          />
                        }
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={8}>
                  <Grid item xs={6}>
                    <FormControl id="ddlPreparerTypeNewPreparer">
                      <InputLabel
                        classes={{ root: classes.labelWidthStyle }}
                        required={false}
                        htmlFor="npPreparer-Type"
                        shrink
                      >
                        Preparer Type
                      </InputLabel>
                      {renderDropdownPreparerType(NEW_PREPARER_TYPE)}
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl>
                      <InputLabel
                        classes={{ root: classes.labelWidthStyle }}
                        required={false}
                        htmlFor="txtPreparerSSNNewPreparer"
                        shrink
                      >
                        {"Preparer's SSN"}
                      </InputLabel>
                      <NumberFormat
                        name="npPreparerSSN"
                        id="txtPreparerSSNNewPreparer"
                        value={state.npPreparerSSN}
                        onChange={handleInputChange}
                        classes={{ root: classes.width12 }}
                        disableUnderline
                        format="###-##-####"
                        customInput={Input}
                        autoComplete="off"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl>
                      <InputLabel
                        classes={{ root: classes.labelWidthStyle }}
                        required={false}
                        htmlFor="txtEINNewPreparer"
                        shrink
                      >
                        EIN (XX-XXXXXXX)
                      </InputLabel>
                      <NumberFormat
                        name="npEIN"
                        id="txtEINNewPreparer"
                        value={state.npEIN}
                        onChange={handleInputChange}
                        classes={{ root: classes.width12 }}
                        disableUnderline
                        format="##-#######"
                        customInput={Input}
                        autoComplete="off"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={8}>
                  <Grid item xs={3}>
                    <FormControl>
                      <InputLabel
                        classes={{ root: classes.labelWidthStyle }}
                        required={false}
                        htmlFor="txtPreparerPTINNewPreparer"
                        shrink
                      >
                        PTIN
                      </InputLabel>
                      <Input
                        name="npPreparerPTIN"
                        id="txtPreparerPTINNewPreparer"
                        value={state.npPreparerPTIN}
                        onChange={handleNumericFieldChange}
                        classes={{ root: classes.width12 }}
                        disableUnderline
                        autoComplete="off"
                        inputProps={{ maxLength: 8 }}
                        startAdornment={
                          <InputAdornment
                            position="start"
                            classes={{ root: classes.inputAdornmentStyle }}
                          >
                            P
                          </InputAdornment>
                        }
                        error={!validPreparerPTIN}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl>
                      <InputLabel
                        classes={{ root: classes.labelWidthStyle }}
                        required={false}
                        htmlFor="txtPINNewPreparer"
                        shrink
                      >
                        PIN
                      </InputLabel>
                      <Input
                        id="txtPINNewPreparer"
                        name="npDefaultPIN"
                        value={state.npDefaultPIN}
                        onChange={handleNumericFieldChange}
                        classes={{ root: classes.smallStyleTwo }}
                        disableUnderline
                        autoComplete="off"
                        onBlur={removeExtraSpaces}
                        inputProps={{ maxLength: 5 }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl>
                      <InputLabel
                        classes={{ root: classes.labelWidthStyle }}
                        required={false}
                        htmlFor="txtBankCodeNewPreparer"
                        shrink
                      >
                        Bank Code
                      </InputLabel>
                      <Input
                        name="npBankCode"
                        id="txtBankCodeNewPreparer"
                        value={state.npBankCode}
                        onChange={handleInputChange}
                        classes={{ root: classes.width12 }}
                        inputProps={{
                          maxLength: 8,
                        }}
                        customInput={Input}
                        autoComplete="off"
                        disableUnderline
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            </span>
            <br />
            <Divider classes={{ root: classes.dividerStyle }} />
            <span>
              <div className="new-preparer-section-headings">{`Preparer's Contact Info`}</div>
              <span>
                <Grid container spacing={8}>
                  <Grid item xs={6}>
                    <FormControl>
                      <InputLabel
                        classes={{ root: classes.labelWidthStyle }}
                        required={false}
                        htmlFor="txtPreparerNameNewPreparer"
                        shrink
                      >
                        {"Preparer's Name"}
                      </InputLabel>
                      <Input
                        id="txtPreparerNameNewPreparer"
                        name="npPreparerName"
                        value={state.npPreparerName}
                        onChange={handleInputChange}
                        classes={{ root: classes.width27 }}
                        disableUnderline
                        autoComplete="off"
                        inputProps={{
                          maxLength: 150,
                        }}
                        error={!validPreparerName}
                        onBlur={removeExtraSpaces}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl>
                      <InputLabel
                        classes={{ root: classes.labelWidthStyle }}
                        required={false}
                        htmlFor="txtPreparerEmailNewPreparer"
                        shrink
                      >
                        Email
                      </InputLabel>
                      <Input
                        id="txtPreparerEmailNewPreparer"
                        name="npEmail"
                        value={state.npEmail}
                        onChange={handleInputChange}
                        type="email"
                        classes={{ root: classes.width27 }}
                        disableUnderline
                        autoComplete="off"
                        onBlur={removeExtraSpaces}
                        error={!validPreparerEmail}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={8}>
                  <Grid item xs={6}>
                    <FormControl>
                      <InputLabel
                        classes={{ root: classes.labelWidthStyle }}
                        required={false}
                        htmlFor="nfPreparerCellPhoneNewPreparer"
                        shrink
                      >
                        Cell Phone
                      </InputLabel>
                      <NumberFormat
                        name="npCellPhone"
                        id="nfPreparerCellPhoneNewPreparer"
                        value={state.npCellPhone}
                        onChange={handleInputChange}
                        classes={{ root: classes.mediumStyle }}
                        disableUnderline
                        format="(###) ###-####"
                        customInput={Input}
                        autoComplete="off"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl>
                      <InputLabel
                        classes={{ root: classes.labelWidthStyle }}
                        required={false}
                        htmlFor="ddlCellphoneCarrierNewPreparer"
                        shrink
                      >
                        Cell Phone Carrier
                      </InputLabel>
                      {renderDropdownCellPhoneCarriers(CELL_PHONE_CARRIERS)}
                    </FormControl>
                  </Grid>
                </Grid>
              </span>
            </span>
            <br />
            <Divider classes={{ root: classes.dividerStyle }} />
            <span>
              <div className="new-preparer-section-headings">{`Preparer's Office Address`}</div>
              <span>
                <Grid container spacing={8}>
                  <Grid item xs={12}>
                    <FormControl>
                      <FormControlLabel
                        label="Same as main office"
                        classes={{ root: classes.labelWidthStyleCheckbox }}
                        control={
                          <Checkbox
                            classes={{ root: classes.checkBoxStyle }}
                            icon={
                              <CheckBoxOutlineBlank
                                classes={{ root: classes.checkBoxOutlineBlankStyle }}
                                color="secondary"
                              />
                            }
                            id="chkSameAsMainOfficeNewPreparer"
                            name="npSameAsMainOffice"
                            checked={state.npSameAsMainOffice}
                            onChange={handleCheckbox}
                            inputProps={{ height: '20px', width: '20px' }}
                          />
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl>
                      <InputLabel
                        classes={{ root: classes.labelWidthStyle }}
                        required={false}
                        htmlFor="txtPreparerFNameNewPreparer"
                        shrink
                      >
                        Office Name
                      </InputLabel>
                      <Input
                        id="txtPreparerFNameNewPreparer"
                        name="npFirmName"
                        value={state.npFirmName}
                        onChange={handleInputChange}
                        classes={{ root: classes.width27 }}
                        disableUnderline
                        disabled={sameAsOffice}
                        autoComplete="off"
                        inputProps={{
                          maxLength: 150,
                        }}
                        error={!sameAsOffice && !validPrepOfficeName}
                        onBlur={removeExtraSpaces}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={8}>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel
                        classes={{ root: classes.labelWidthStyle }}
                        required={false}
                        htmlFor="txtFirmAddressNewPreparer"
                        shrink
                      >
                        Office Address
                      </InputLabel>
                      <Input
                        id="txtFirmAddressNewPreparer"
                        name="npFirmAddress"
                        value={state.npFirmAddress}
                        onChange={handleInputChange}
                        classes={{ root: classes.width56 }}
                        disableUnderline
                        disabled={sameAsOffice}
                        autoComplete="off"
                        error={!sameAsOffice && !validPrepOfficeAddress}
                        onBlur={removeExtraSpaces}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={8}>
                  <Grid item xs={6}>
                    <FormControl>
                      <InputLabel
                        classes={{ root: classes.labelWidthStyle }}
                        required={false}
                        htmlFor="txtCityNewPreparer"
                        shrink
                      >
                        City
                      </InputLabel>
                      <Input
                        id="txtCityNewPreparer"
                        name="npCity"
                        value={state.npCity}
                        onChange={handleInputChange}
                        classes={{ root: classes.width27 }}
                        disableUnderline
                        disabled={sameAsOffice}
                        autoComplete="off"
                        error={!sameAsOffice && !validPreparerCity}
                        onBlur={removeExtraSpaces}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl>
                      <InputLabel
                        classes={{ root: classes.labelWidthStyle }}
                        htmlFor="np-state"
                        shrink
                      >
                        State
                      </InputLabel>
                      {renderStateDropdown(
                        STATE_LIST_ADDRESS,
                        'npState',
                        validPreparerState,
                        sameAsOffice,
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl>
                      <InputLabel
                        classes={{ root: classes.labelWidthStyle }}
                        required={false}
                        htmlFor="txtZipNewPreparer"
                        shrink
                      >
                        Zip
                      </InputLabel>
                      <NumberFormat
                        name="npZip"
                        id="txtZipNewPreparer"
                        value={state.npZip}
                        onChange={handleInputChange}
                        classes={{ root: classes.width13 }}
                        disableUnderline
                        format="#####"
                        customInput={Input}
                        disabled={sameAsOffice}
                        autoComplete="off"
                        error={!sameAsOffice && !validPreparerZip}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={8}>
                  <Grid item xs={3}>
                    <FormControl>
                      <InputLabel
                        classes={{ root: classes.labelWidthStyle }}
                        required={false}
                        htmlFor="txtOfficePhoneNewPreparer"
                        shrink
                      >
                        Office Phone
                      </InputLabel>
                      <NumberFormat
                        name="npOfficePhone"
                        id="txtOfficePhoneNewPreparer"
                        value={state.npOfficePhone}
                        onChange={handleInputChange}
                        classes={{ root: classes.width27 }}
                        disableUnderline
                        format="(###) ###-####"
                        customInput={Input}
                        disabled={sameAsOffice}
                        autoComplete="off"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </span>
            </span>
            <br />
            <Divider classes={{ root: classes.dividerStyle }} />
            <span>
              <div className="new-preparer-section-headings">{`Preparer's State ID Numbers`}</div>
              <span>
                <Grid container spacing={8}>
                  <Grid item xs={3}>
                    <FormControl>
                      <InputLabel
                        classes={{ root: classes.labelWidthStyle }}
                        htmlFor="npStateCodeOne"
                        required={false}
                        shrink
                      >
                        State 1
                      </InputLabel>
                      {renderStateDropdown(STATE_LIST_ADDRESS, 'npStateCodeOne', true, false)}
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl>
                      <InputLabel
                        classes={{ root: classes.labelWidthStyle }}
                        required={false}
                        htmlFor="nfNewPreparerStateIDOne"
                        shrink
                      >
                        ID 1
                      </InputLabel>
                      <Input
                        name="npStateIDOne"
                        id="nfNewPreparerStateIDOne"
                        value={state.npStateIDOne}
                        onChange={handleInputChange}
                        classes={{ root: classes.width12 }}
                        disableUnderline
                        autoComplete="off"
                        inputProps={{ maxLength: 12 }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl>
                      <InputLabel
                        classes={{ root: classes.labelWidthStyle }}
                        htmlFor="npStateCodeTwo"
                        required={false}
                        shrink
                      >
                        State 2
                      </InputLabel>
                      {renderStateDropdown(STATE_LIST_ADDRESS, 'npStateCodeTwo', true, false)}
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl>
                      <InputLabel
                        classes={{ root: classes.labelWidthStyle }}
                        required={false}
                        htmlFor="nfNewPreparerStateIDTwo"
                        shrink
                      >
                        ID 2
                      </InputLabel>
                      <Input
                        name="npStateIDTwo"
                        id="nfNewPreparerStateIDTwo"
                        value={state.npStateIDTwo}
                        onChange={handleInputChange}
                        classes={{ root: classes.width12 }}
                        disableUnderline
                        autoComplete="off"
                        inputProps={{ maxLength: 12 }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </span>
            </span>
          </form>
          <div className="new-preparer-form-action-btn">
            <span>
              <Button
                id="btnCancelNewPreparer"
                color="primary"
                onClick={() => toggleConfirmCancel(true)}
                classes={{ root: classes.cancelBtn }}
              >
                Cancel
              </Button>
              <Button
                id="btnAddNewPreperarer"
                variant="contained"
                color="primary"
                classes={{ root: classes.saveBtn }}
                onClick={submitNewPreparerData}
                disabled={disableNewPreparer}
              >
                Save
              </Button>
            </span>
          </div>
          <SimpleDialog
            open={state.confirmCancel}
            onClose={() => toggleConfirmCancel(false)}
            onConfirm={() => {
              toggleConfirmCancel(false);
              props.clearPreparerState();
              props.handleClosePreparerModal();
            }}
            dialogTitle={'Lose Unsaved Changes?'}
            contentText={'Are you sure you want to undo any pending changes?'}
          />
          <SimpleDialog
            open={alert.show}
            onConfirm={() => dispatch(appActions.hideError())}
            dialogTitle={
              typeof alert.title === 'object' ? JSON.stringify(alert.title) : alert.title
            }
            contentText={alert.body}
            confirmText="OK"
            styled={true}
            errorMessage={true}
          />
        </Paper>
      </div>
    </div>
  );
};

export default withStyles(styles)(NewPreparerModal);

// External Imports
import React, { useEffect } from 'react';
import {
  FormControl,
  Input,
  MenuItem,
  Select,
  Button,
  Collapse,
  IconButton,
  withStyles,
} from '@material-ui/core';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'; //must be imported separately
import NumberFormat from 'react-number-format';
// Internal imports
import AccessControl from '#/Auth/AccessControl.jsx';
import { useSetState } from '~/app/Utility/customHooks.js';
import { DFLT, DFL2 } from '~/app/constants.js';
// Styling imports
import xIcon from '~/images/icons/icons8-multiply.png';

const styles = theme => ({
  noOutlineButton: {
    border: 'none',
    width: '1em',
    paddingLeft: '0em',
    paddingRight: '0em',
    minHeight: '0em',
    minWidth: '0em',
  },
  noOutlineButtonBlue: {
    color: '#1880E7',
    fontFamily: 'Roboto',
    fontSize: '15px',
    border: 'none',
  },
  noBorderOnFocus: {
    '&:focus': {
      border: 'none',
      outline: 'none',
    },
  },
});

const BillingDiscounts = props => {
  const [state, setState] = useSetState({
    expanded: true,
    hoveredRow: null,
    currentSelectedTypeForRow: [],
    currentNewItemCounter: 0,
    customTags: null,
    DFLT: { discount_code: '', discount_amount: '', discounts_type_id: 2, discounts_id: null }, // Custom Discount for employee flat
    DFL2: { discount_code: '', discount_amount: '', discounts_type_id: 2, discounts_id: null }, // Custom Discount for single-filer flat
  });

  useEffect(() => {
    props.customCodeDiscounts?.forEach(code => {
      if (code.discount_code === DFLT) {
        setState({ DFLT: code });
      } else if (code.discount_code === DFL2) {
        setState({ DFL2: code });
      }
    });
  }, [props.customCodeDiscounts]);

  const updateRowHoverEnter = index => {
    setState({ hoveredRow: index });
  };

  const updateRowHoverLeave = () => {
    setState({ hoveredRow: null });
  };

  const handleExpandClick = () => {
    setState(state => ({ expanded: !state.expanded }));
  };

  const addRow = key => {
    if (props.actionsLocked) {
      alert(
        "You must first add a new billing scheme before continuing. \n\nPlease click the button next to 'Set as Default' to name and save your new Billing scheme.",
      );
      return;
    }
    setState(state => ({ currentNewItemCounter: state.currentNewItemCounter + 1 }));
    props.addRow(
      {
        new_discount_id: state.currentNewItemCounter,
        discount_code: '',
        discount_amount: '',
        discounts_type_id: 2,
      },
      'tbRowsDiscounts',
    );
  };

  const { classes } = props;

  const menuItemStyle = {
    paddingTop: '0px',
    paddingBottom: '0px',
    color: '#0077FF',
    height: '1.5em',
  };

  const inputStyle = { width: '200px', height: '30px' };

  const selectStyle = {
    width: '200px',
    height: '30px',
    marginRight: '.5vw',
    paddingTop: '0px',
    paddingBottom: '0px',
    textAlign: 'left',
  };

  return (
    <div className="container-fixed">
      <div style={{ textAlign: 'left', paddingTop: '3vh', paddingBottom: '2vh' }}>
        <div style={{ float: 'left' }}>
          <IconButton
            id="icobtnExpandBillingDiscounts"
            style={{ width: '1em', height: '1em' }}
            onClick={handleExpandClick}
            aria-expanded={state.expanded}
            classes={{ root: classes.noBorderOnFocus }}
          >
            {state.expanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </div>
        <div>
          <span
            style={{
              fontFamily: 'Roboto',
              fontSize: '21px',
              letterSpacing: '0.14px',
              color: '#1A173B',
              marginLeft: '0.3em',
            }}
          >
            Discounts
            <IconButton
              className={classes.iconButton}
              id="icobtnInfoDiscounts"
              color="primary"
              aria-label="info"
              onClick={() => props.openInfoDialog(props.itemType)}
            >
              <InfoOutlinedIcon />
            </IconButton>
          </span>
        </div>
      </div>
      <Collapse in={state.expanded} timeout="auto" unmountOnExit>
        <AccessControl requiredAction="write" accessLevel="add/edit_billing" disableOnFalse={true}>
          <div>
            <div
              style={{
                marginLeft: '3vw',
                marginRight: '3vw',
                marginTop: '0vh',
              }}
            >
              <div>
                <table
                  style={{
                    marginTop: '0.3em',
                    marginLeft: '1em',
                    marginRight: '5em',
                  }}
                  onMouseLeave={() => updateRowHoverLeave()}
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          paddingRight: '9em',
                          color: '#354052',
                          fontFamily: 'Roboto',
                          fontSize: '14px',
                          fontWeight: 'normal',
                        }}
                      >
                        Discount Code
                      </th>
                      <th
                        style={{
                          paddingRight: '12em',
                          color: '#354052',
                          fontFamily: 'Roboto',
                          fontSize: '14px',
                          fontWeight: 'normal',
                        }}
                      >
                        Type
                      </th>
                      <th
                        style={{
                          color: '#354052',
                          fontFamily: 'Roboto',
                          fontSize: '14px',
                          fontWeight: 'normal',
                        }}
                      >
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.tbRows.map((item, key) => (
                      <tr
                        key={key}
                        onMouseEnter={() => updateRowHoverEnter(key)}
                        onMouseLeave={() => updateRowHoverLeave()}
                      >
                        <td>
                          <Input
                            style={inputStyle}
                            id={`txtDiscountCode${key}`}
                            onChange={e =>
                              props.handleChangeTable(key, 'discount_code', e.target.value)
                            }
                            value={item.discount_code}
                            disableUnderline
                          />
                        </td>
                        <td>
                          <FormControl>
                            <Select
                              value={item.discounts_type_id}
                              onChange={e => {
                                setState({
                                  currentSelectedTypeForRow: { key: key, value: e.target.value },
                                });
                                props.handleChangeTable(key, 'discounts_type_id', e.target.value);
                              }}
                              input={
                                <Input
                                  name="discounts_type_id"
                                  id="EROstate-selected"
                                  style={selectStyle}
                                  disableUnderline
                                />
                              }
                            >
                              <MenuItem style={menuItemStyle} value={1}>
                                Percent
                              </MenuItem>
                              <MenuItem style={menuItemStyle} value={2}>
                                Dollars
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </td>
                        <td>
                          <NumberFormat
                            style={inputStyle}
                            id={`txtDiscountAmt${key.toString()}`}
                            onChange={e =>
                              props.handleChangeTable(
                                key,
                                'discount_amount',
                                e.target.value.replace(/[$,%]/g, ''),
                              )
                            }
                            value={item.discount_amount}
                            disableUnderline
                            customInput={Input}
                            prefix={props.getDiscountTypeByKey(key) === 1 ? '' : '$'}
                            suffix={props.getDiscountTypeByKey(key) === 1 ? '%' : ''}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                            decimalScale={2}
                            isAllowed={values => {
                              const { floatValue } = values;
                              return (
                                (floatValue >= 0.01 && floatValue <= 999.99) ||
                                props.getDiscountTypeByKey(key) === 2
                              );
                            }}
                          />
                        </td>
                        <td hidden={Boolean(state.hoveredRow !== key)}>
                          <Button
                            id="btnDeleteRowDiscount"
                            disableRipple
                            onClick={() => {
                              const isNewEntry = Object.prototype.hasOwnProperty.call(
                                item,
                                'new_discount_id',
                              );
                              props.handleRowDel(
                                isNewEntry === true ? item.new_discount_id : item.discounts_id,
                                'tbRowsDiscounts',
                                isNewEntry === true ? 'new_discount_id' : 'discounts_id',
                                props.itemType,
                              );
                              if (isNewEntry) {
                                setState(state => ({
                                  currentNewItemCounter: state.currentNewItemCounter + 1,
                                }));
                              }
                            }}
                            classes={{ root: classes.noOutlineButton }}
                          >
                            <img id="clearInputField" className="noselect" src={xIcon} />
                          </Button>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td>
                        <Button
                          id="btnAddRowBillingDiscounts"
                          classes={{ root: classes.noOutlineButtonBlue }}
                          onClick={() => addRow(state.currentNewItemCounter)}
                        >
                          Add Row +
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                {props?.customTags?.includes('WFIN') || props?.customTags?.includes('104A') ? (
                  <div>
                    <div>
                      <span style={{ marginLeft: '1em', marginRight: '3em' }}>
                        Overrride Flat Fee - Employer Flat
                      </span>
                      <NumberFormat
                        style={inputStyle}
                        id={`WFINDiscountCode`}
                        onChange={e =>
                          props.handleUpdateCustomCodeDiscount(
                            state.DFLT.discounts_id,
                            e.target.value.replace(/[$,%]/g, ''),
                            DFLT,
                          )
                        }
                        value={state.DFLT.discount_amount}
                        disableUnderline
                        customInput={Input}
                        prefix={'$'}
                        fixedDecimalScale={true}
                        decimalScale={2}
                      />
                    </div>
                    {props?.customTags?.includes('WFIN') ? (
                      <div>
                        <span style={{ marginLeft: '1em', marginRight: '2.5em' }}>
                          Overrride Flat Fee - Single-filer Flat
                        </span>
                        <NumberFormat
                          style={inputStyle}
                          id={`WFINDiscountCode`}
                          onChange={e =>
                            props.handleUpdateCustomCodeDiscount(
                              state.DFL2.discounts_id,
                              e.target.value.replace(/[$,%]/g, ''),
                              DFL2,
                            )
                          }
                          value={state.DFL2.discount_amount}
                          disableUnderline
                          customInput={Input}
                          prefix={'$'}
                          fixedDecimalScale={true}
                          decimalScale={2}
                        />
                      </div>
                    ) : null}
                    <div style={{ marginLeft: '1em' }}>
                      Discount the non-financial product related services / charges on the invoice
                      to a flat fee amount.
                    </div>
                    <div style={{ marginLeft: '1em' }}>
                      The amount of the discount will be determined when this discount has been
                      applied to the invoice.
                    </div>
                  </div>
                ) : null}
              </div>
              <br />
            </div>
          </div>
        </AccessControl>
      </Collapse>
    </div>
  );
};

export default withStyles(styles)(BillingDiscounts);

export const styles = {
  pprChargesCollapseIcon: {
    width: "1rem",
    height: "1rem",
  },
  pprChargesTableHeadCell: {
    color: "#1A173B",
    fontSize: ".75rem",
    fontFamily: "Roboto",
  },
  pprChargesTableBodyCell: {
    color: "#67727C",
    fontSize: ".75rem",
    borderBottom: "0",
    fontFamily: "Roboto",
  },
};

export const selectors = {
  getAttachedStatesCount: state => {
    if (state.returnProfile.formList !== undefined) {
      let attachedStates = state.returnProfile.formList.filter(listItem => {
        return (
          listItem.desc &&
          listItem.desc.trim() !== "FEDERAL" &&
          listItem.desc !== "GENERAL" &&
          listItem.desc !== "Refund Calculator" &&
          listItem.var !== "retn37" &&
          listItem.var !== "PF"
        );
      });
      return attachedStates.length;
    }
    return 0;
  }
};

// External imports
import React, { useRef, useEffect } from 'react';
import { AppBar, Button, Paper, Toolbar, CircularProgress } from '@material-ui/core';
import { isMobileOnly, withOrientationChange } from 'react-device-detect';
// Style imports
import '~/app/Components/RemoteSign/css/remoteSign.css';
import '~/app/Components/RemoteSign/css/remoteSignaturePreview.css';

const RemoteSignPreview = props => {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    if (isMobileOnly) {
      window.addEventListener('deviceorientation', props.isNeedingToRotate);
    }

    return () => {
      isMounted.current = false;

      if (isMobileOnly) {
        window.removeEventListener('deviceorientation', props.isNeedingToRotate);
      }
    };
  }, []);

  // Handle Save Signature Button
  const saveInitialSignature = e => {
    e.preventDefault();

    // Need to call function to post signature data to database
    props.postSignature();
    props.handleCloseSignatureModal();
  };

  // Handle Recapture Remote Signature
  const clickRecapture = e => {
    e.preventDefault();
    // Remove the data saved from signature
    props.handleCloseSignatureModal();
    // Open Signature modal again in order to recapture signature
    props.handleRecapture();
  };

  return (
    <div className={!isMobileOnly ? 'container-fluid' : ''}>
      <Paper className="remote-sign-signature-preview-content">
        <AppBar position="static">
          <Toolbar style={{ backgroundColor: '#444A59' }}>
            <span style={{ fontFamily: 'Roboto', fontSize: '18px' }}>Preview Signature</span>
          </Toolbar>
        </AppBar>
        <form className="remote-sign-signature-preview-form">
          <p style={{ fontSize: '15px', textAlign: 'justify' }}>
            Are you satisfied with your signature?
          </p>
          <div className="remote-sign-signature-preview-img-container">
            <img src={props.remoteSignature} className="remote-sign-signature-preview-img" />
          </div>
          <div className="remote-signature-preview-button-block">
            <Button
              id="btnRecaptureRemoteSignature"
              onClick={clickRecapture}
              style={{
                paddingLeft: '10px',
                paddingRight: '10px',
                minWidth: '0px',
                minHeight: '3.5vh',
                textTransform: 'capitalize',
                fontFamily: 'Roboto',
                fontSize: '14px',
                borderRadius: '4px',
                marginRight: '1.5vw',
              }}
              disabled={props.isLoading}
            >
              Recapture
            </Button>
            <Button
              id="btnSaveAndCloseRemoteSignature"
              style={{
                paddingLeft: '10px',
                paddingRight: '10px',
                minWidth: '0px',
                minHeight: '3.5vh',
                textTransform: 'capitalize',
                fontFamily: 'Roboto',
                fontSize: '14px',
                borderRadius: '4px',
              }}
              onClick={saveInitialSignature}
              disabled={props.isLoading}
            >
              {props.isLoading ? (
                <>
                  <CircularProgress
                    size="1rem"
                    color="inherit"
                    style={{
                      marginRight: '.5rem',
                    }}
                  />
                  Processing...
                </>
              ) : (
                'Accept'
              )}
            </Button>
          </div>
          <br />
        </form>
      </Paper>
    </div>
  );
};

export default withOrientationChange(RemoteSignPreview);

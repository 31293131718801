// external imports
import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
// internal imports
import PayJunctionPayment from './components/PayJunctionPayment.jsx';
import PayJunctionPaymentProgress from './components/PayJunctionPaymentProgress.jsx';
import ManualPayment from './components/ManualPayment.jsx';
import RemoteInvoice from './components/RemoteInvoice.jsx';
import { isShortScreenRez } from '~/app/Utility/browser';
import WebHelpers from '~/app/webHelpers.js';
import { formatDollarAmt } from '~/app/Pages/Payments/helpers/paymentHelpers.js';
// styling imports
import { styles } from '~/app/Pages/Payments/PaymentModal/css/CapturePaymentStyles.js';
import paymentIcon from '~/images/icons/payment_icon_white.svg';

const CapturePayment = props => {
  const { classes } = props;
  const payload = WebHelpers.getJWTPayload();

  const [paymentType, setPaymentType] = React.useState('manual');
  const [paymentStage, setPaymentStage] = React.useState(1);
  const [requestPaymentID, setRequestPaymentID] = React.useState('');
  const [selectedSmartTerminal, setSelectedSmartTerminal] = React.useState('');
  const [season, setSeason] = React.useState(false);
  const [isACHPayment, setIsACHPayment] = React.useState(false);
  const [achTransactionDetails, setAchTransactionDetails] = React.useState({});

  useEffect(() => {
    if (props?.saveReturn) {
      props?.saveReturn();
    }
  }, []);

  useEffect(() => {
    setSeason(props.season);
  }, [props.season]);

  const paymentDetails = () => {
    if (paymentType === 'manual') {
      return (
        <ManualPayment
          onClose={props.onClose}
          returnPaymentInfo={props.returnPaymentInfo}
          processPaymentDetails={props.processPaymentDetails}
          errorHandler={props.showErrorDialog || props.errorHandler}
          shortScreenRez={isShortScreenRez}
          isBusiness={payload?.is_business}
        />
      );
    } else if (paymentType === 'payjunction') {
      return (
        <PayJunctionPayment
          returnPaymentInfo={props.returnPaymentInfo}
          onClose={props.onClose}
          setPaymentStage={setPaymentStage}
          setRequestPaymentID={setRequestPaymentID}
          selectedSmartTerminal={selectedSmartTerminal}
          setSelectedSmartTerminal={setSelectedSmartTerminal}
          returnID={props.returnID}
          shortScreenRez={isShortScreenRez}
          setIsACHPayment={setIsACHPayment}
          setAchTransactionDetails={setAchTransactionDetails}
        />
      );
    } else {
      return (
        <RemoteInvoice
          returnPaymentInfo={props.returnPaymentInfo}
          onClose={props.onClose}
          returnID={props.returnID}
          shortScreenRez={isShortScreenRez}
          sendRemotePayment={props.sendRemotePayment}
          getWalletToken={isRemoteInvoice => props.getWalletToken(isRemoteInvoice)}
          season={props.season}
        />
      );
    }
  };

  const paymentView = () => {
    switch (paymentStage) {
      case 1:
        return capture();
      case 2:
        return (
          <PayJunctionPaymentProgress
            requestPaymentID={requestPaymentID}
            pollTerminalStatus={props.pollTerminalStatus}
            terminalStatus={props.terminalStatus}
            setTerminalStatus={props.setTerminalStatus}
            setPaymentStage={setPaymentStage}
            onClose={props.onClose}
            processPaymentDetails={props.processPaymentDetails}
            returnPaymentInfo={props.returnPaymentInfo}
            selectedSmartTerminal={selectedSmartTerminal}
            isACHPayment={isACHPayment}
            achTransactionDetails={achTransactionDetails}
          />
        );
      default:
        return capture();
    }
  };

  const capture = () => {
    return (
      <Paper elevation={0} classes={{ root: classes.capturePaymentModalContent }}>
        {/* First Section */}
        <Grid
          container
          spacing={8}
          alignContent="flex-start"
          justify="flex-start"
          alignItems="baseline"
        >
          <Grid item xs={12}>
            <Typography variant="title" gutterBottom>
              {payload?.is_business ? 'Company ' : 'Taxpayer '} Details
            </Typography>
          </Grid>
          {!payload?.is_business ? (
            <>
              <Grid item xs={6}>
                <Typography variant="body1" gutterBottom>
                  Taxpayer: {props.returnPaymentInfo.pname}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Taxpayer SSN: {props.returnPaymentInfo.pssn}
                </Typography>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={6}>
                <Typography variant="body1" gutterBottom>
                  Business Name: {props.returnPaymentInfo.businessName}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  EIN: {props.returnPaymentInfo.ein?.replace(/^(\w{2})(.*)/, '$1-$2')}
                </Typography>
              </Grid>
            </>
          )}

          <Grid item xs={3}>
            <Typography variant="body1" gutterBottom>
              Invoice Amount
            </Typography>
            <Typography variant="body1" gutterBottom>
              {formatDollarAmt(props.returnPaymentInfo.netInvoiceAmount, true)}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body1" gutterBottom>
              Amount Due
            </Typography>
            <Typography variant="body1" gutterBottom>
              {formatDollarAmt(props.returnPaymentInfo.due, true)}
            </Typography>
          </Grid>
        </Grid>
        <Divider variant="middle" classes={{ root: classes.capturePaymentModalDivider }} />
        {/* Payment Type Selection */}
        <Grid
          container
          spacing={8}
          alignContent="flex-start"
          justify="flex-start"
          alignItems="baseline"
        >
          <Grid item xs={isShortScreenRez ? 6 : 12}>
            <Typography variant="title" gutterBottom>
              Payment Details
            </Typography>
          </Grid>
          <Grid item xs={isShortScreenRez ? 6 : 12}>
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="paymentType"
                name="paymentType"
                value={paymentType}
                onChange={e => setPaymentType(e.target.value)}
              >
                <FormControlLabel value="manual" control={<Radio />} label="Manual" />

                <FormControlLabel value="payjunction" control={<Radio />} label="PayJunction" />
                {/* remote invoice only available for season 2023 and beyond */}
                {season >= 2023 ? (
                  <FormControlLabel
                    value="remoteinvoice"
                    control={<Radio />}
                    label="Remote Invoice"
                  />
                ) : null}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        {paymentDetails()}
      </Paper>
    );
  };

  return (
    <Paper
      tabIndex={-1}
      elevation={5}
      classes={
        isShortScreenRez
          ? { root: classes.capturePaymentModalShortScreen }
          : { root: classes.capturePaymentModal }
      }
    >
      <Typography variant="title" id="simple-modal-title" style={{ paddingLeft: '1em' }}>
        <img src={paymentIcon} /> Capture Payment
      </Typography>
      {paymentView()}
    </Paper>
  );
};

export default withStyles(styles)(CapturePayment);

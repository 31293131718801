// External imports
import React from 'react';
import NumberFormat from 'react-number-format';
import {
  Collapse,
  FormControl,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
// Internal imports
import PrinterOptionRow from '~/app/Components/NewAccount/SingleOffice/PrintSetupComponents/printerOptionRow.jsx';
import {
  PRINT_OPTION_FIELD_KEY,
  PRINT_OPTION_FIELD_LIST,
  SIG_BLOCK_ONE_LIST,
  SIG_BLOCK_TWO_LIST,
} from '../Helper/printSetupConstants.js';
import InfoIcon from '@material-ui/icons/Info';
// Styling imports
import '~/app/Components/NewAccount/SingleOffice/css/printingSetup.css';
import { styles } from '~/app/Components/NewAccount/SingleOffice/css/printSetup.js';

/**
 * Component that shows Standard Print Options
 *
 * @component
 * @category Printing Setup
 * @subcategory Print Options
 */
const PrintOptions = props => {
  /**
   * Takes the signature block character from the DB and sets the value
   * as the corresponding word.
   *
   * @param {string[]} blockEntries The signature block entry values.
   * @param {string} data The signature block setting.
   */
  const handleSignatureBlockValue = (blockEntries, data) => {
    let value = '';

    blockEntries.forEach(val => {
      if (val[0] === data) {
        return (value = val);
      } else if (data === '') {
        return (value = blockEntries[0]);
      }
    });
    return value;
  };

  // render a dropdown, requires type for styling
  const renderGenericDropdown = (type, contents) => {
    const menuItems = contents.map((entry, index) => {
      return (
        <MenuItem key={entry + index} value={entry} className="printing-setup-menuItemStyle">
          {entry}
        </MenuItem>
      );
    });
    switch (type) {
      case 'blockOne':
        return (
          <Select
            id="ddlBlockOnePrintingSetup"
            name="SBLW"
            value={handleSignatureBlockValue(SIG_BLOCK_ONE_LIST, props.sigBlockOne)}
            onChange={e => props.handleChange(e)}
            className={props.classes.blockStyle}
            input={<Input name="blockOne" id="txtBlockOnePrintingSetup" disableUnderline />}
          >
            {menuItems}
          </Select>
        );
      case 'blockTwo':
        return (
          <Select
            id="ddlBlockTwoPrintingSetup"
            name="SBLK"
            value={handleSignatureBlockValue(SIG_BLOCK_TWO_LIST, props.sigBlockTwo)}
            onChange={e => props.handleChange(e)}
            input={
              <Input
                name="blockTwo"
                id="txtBlockTwoPrintingSetup"
                className={props.classes.blockStyle}
                disableUnderline
              />
            }
          >
            {menuItems}
          </Select>
        );
    }
  };

  return (
    <>
      <div style={{ textAlign: 'left', paddingTop: '3vh' }}>
        <div style={{ float: 'left' }}>
          <IconButton
            className="printing-setup-expand-icon"
            onClick={props.handleExpansion}
            aria-expanded={props.expanded}
          >
            {props.expanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </div>
        <div>
          <span className="printing-setup-header">Print Options</span>
        </div>
      </div>
      <Collapse in={props.expanded} timeout="auto" unmountOnExit>
        <div style={{ float: 'left', marginLeft: '3vw' }}>
          {PRINT_OPTION_FIELD_LIST[0].map((title, index) => {
            return (
              <PrinterOptionRow
                key={title + 'Row'}
                rowTitle={title}
                fieldMap={props.fieldMap}
                field={PRINT_OPTION_FIELD_KEY[0][index]}
                handleCheckbox={props.handleCheckbox}
              />
            );
          })}
          <div style={{ height: '10px' }} />
          <div>
            <span className="printing-setup-labelWidthStyle">
              Apply Signature Block{' '}
              <Tooltip title="The Signature Block is applied upon return creation. Updates to it won't affect previous returns; only future returns will reflect the changes.">
                <InfoIcon fontSize="small" />
              </Tooltip>
            </span>
          </div>
          <div>
            <FormControl>
              <InputLabel htmlFor="blockOne" />
              {renderGenericDropdown('blockOne', SIG_BLOCK_ONE_LIST)}
            </FormControl>
            <FormControl>
              <InputLabel htmlFor="blockTwo" shrink />
              {renderGenericDropdown('blockTwo', SIG_BLOCK_TWO_LIST)}
            </FormControl>
          </div>
          <div style={{ marginTop: '2vh', marginBottom: '2vh' }}>
            <span className="printing-setup-titleStyle">Current Form/Bank App Options</span>
          </div>
          {PRINT_OPTION_FIELD_LIST[1].map((title, index) => {
            return (
              <PrinterOptionRow
                key={title + 'Row'}
                rowTitle={title}
                fieldMap={props.fieldMap}
                field={PRINT_OPTION_FIELD_KEY[1][index]}
                handleCheckbox={props.handleCheckbox}
              />
            );
          })}
        </div>
        <div style={{ float: 'none', marginLeft: '31vw' }}>
          {PRINT_OPTION_FIELD_LIST[2].map((title, index) => {
            return (
              <PrinterOptionRow
                key={title + 'Row'}
                rowTitle={title}
                fieldMap={props.fieldMap}
                field={PRINT_OPTION_FIELD_KEY[2][index]}
                handleCheckbox={props.handleCheckbox}
              />
            );
          })}
          <div style={{ height: '110px' }} />
          <div style={{ marginTop: '2vh', marginBottom: '2vh' }}>
            <div className="printing-setup-titleStylePDF">PDF Printing Options</div>
          </div>
          <div>
            <PrinterOptionRow
              rowTitle="Always Encrypt Generated PDF"
              fieldMap={props.fieldMap}
              field={'ENCP'}
              handleCheckbox={props.handleCheckbox}
            />
          </div>
          <div style={{ marginTop: '2vh', marginBottom: '2vh' }}>
            <div className="printing-setup-titleStyle">Referral Coupons</div>
          </div>
          <div>
            <FormControl>
              <InputLabel
                className="printing-setup-labelWidthStyle"
                required={false}
                htmlFor="txtCouponAmountPrintingSetup"
                shrink
              >
                Coupon Amount
              </InputLabel>

              <NumberFormat
                id="txtCouponAmountPrintingSetup"
                onChange={e => {
                  props.handleInputChange(e, true);
                }}
                value={props.fieldMap?.RFAM?.configValue / 100}
                prefix="$"
                name="RFAM"
                thousandSeparator={true}
                customInput={Input}
                fixedDecimalScale={true}
                decimalScale={2}
                className="printing-setup-couponAmountStyle"
                disableUnderline
              />
            </FormControl>
            <FormControl>
              <InputLabel
                className="printing-setup-labelWidthStyle"
                required={false}
                htmlFor="txtCouponExpireDatePrintingSetup"
                shrink
              >
                Expiration Date
              </InputLabel>
              <Input
                id="txtCouponExpireDatePrintingSetup"
                name="RFEX"
                value={props.fieldMap?.RFEX?.configValue}
                onChange={e => props.handleInputChange(e)}
                className="printing-setup-couponAmountStyle"
                disableUnderline
              />
            </FormControl>
          </div>
          <div style={{ height: '20px' }} />
        </div>
      </Collapse>
    </>
  );
};

export default withStyles(styles)(PrintOptions);

// External imports
import React, { useEffect } from 'react';
import Draggable from 'react-draggable';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  withStyles,
} from '@material-ui/core';
// Internal imports
import { useSetState } from '~/app/Utility/customHooks';
import ErrorHelpers from '~/app/errorHelpers.js';
import SimpleDialog from '#/Common/SimpleDialog.jsx';
import {
  buildMissingSignatureWarning,
  checkDBSignaturesExist,
  doWeHaveSignatures,
} from '~/app/Pages/Returns/pages/Signatures/components/signatureListHelper.js';
import { PRINT_TYPES, PRINT_TYPES_MAP, SIGNATURE_PAD_TYPES } from '~/app/constants';
// Redux imports
import { useSelector, useDispatch } from 'react-redux';
import { actions as appActions } from '~/app/redux/modules/app.js';
// Import styles
import { styles } from '~/app/Pages/Returns/components/Print/css/dialogStyles.js';

const PrintActionsDialog = props => {
  const { classes } = props;
  const dispatch = useDispatch();
  const { preparerID, amendedPreparerID, returnID, isSelfPreparing } = useSelector(state => ({
    preparerID: state.formViewer.preparerID,
    amendedPreparerID: state.formViewer.amendedPreparerID,
    returnID: state.returnProfile.returnID,
    isSelfPreparing: state.formViewer.isSelfPreparing,
  }));
  const [state, setState] = useSetState({
    open: true,
    allowEmailDoc: false, // Set to true once we add this functionality on calc server
    isCaptureSignatureAllowed: false,
    isMissingPreparerSignatureModalOpen: false,
  });

  useEffect(() => {
    let signaturePadType = SIGNATURE_PAD_TYPES.INITIAL_VALUE;
    if (props.onScreen === true) {
      signaturePadType = SIGNATURE_PAD_TYPES.ON_SCREEN;
    }
    if (props.scriptel === true) {
      signaturePadType = SIGNATURE_PAD_TYPES.SCRIPTEL;
    }
    if (props.topaz === true) {
      signaturePadType = SIGNATURE_PAD_TYPES.TOPAZ;
    }
    if (props.onScreen !== true && props.scriptel !== true && props.topaz !== true) {
      signaturePadType = SIGNATURE_PAD_TYPES.ON_SCREEN;
    }
    props.setPreferredSignaturePad(signaturePadType);
    handleShowingCaptureSignature();
  }, []);

  const handlePrint = () => {
    props.handlePrintSelected();
    props.handleClose();
  };

  const handleSignDoc = async () => {
    try {
      const dbSignaturesExist = await checkDBSignaturesExist(
        preparerID,
        amendedPreparerID,
        returnID,
        isSelfPreparing,
      );

      // If this is a self prepared return, we do not require a preparer signature
      // if we are missing preparer signature, supply message to have the user capture it outside of the return
      if (!doWeHaveSignatures(dbSignaturesExist, 1) && !isSelfPreparing) {
        setState({ isMissingPreparerSignatureModalOpen: true });
        return;
      }

      props.handleSigningInOffice();
      props.handleClose();
    } catch (error) {
      dispatch(
        appActions.showSnackbarMessage(error, 'error', 3500, {
          vertical: 'top',
          horizontal: 'center',
        }),
      );
      ErrorHelpers.handleError('Error getting db signatures', error);
    }
  };

  const handleEmailEncryptedPDF = () => {
    props.handleEncryptedPDFSelected();
    props.handleClose();
  };

  const handleShowingCaptureSignature = () => {
    const { pActionEligible: buttonsAllowed } = props;

    if (
      buttonsAllowed.charAt(1) !== '1' &&
      buttonsAllowed !== '0000000' &&
      buttonsAllowed !== '0010000'
    ) {
      setState({ isCaptureSignatureAllowed: true });
    }
  };

  let buttonsAllowed = props.pActionEligible ? props.pActionEligible : '1111111';
  const buttonsAllowedLength = buttonsAllowed.length;

  // Based on email received on 03/11/2019 pActionEligible is returned by calc server and the bits indicate the following:
  // 1  --print    2--SignDoc  3 --Queue   4  --Filter 5 --P&DF    6 --Pre&view    7—RemoteSign

  // Calc server may return a string containing up to seven bits 0/1, if the length is less than 7 we need to insert/pad the response with "1", until we reach 7 bits

  // Check the length of props.pActionEligible, if less than 7 bits returned, concatenate props.pActionEligible + "1"...
  // For the calc server response, a zero means that the action is allowed, and a 1 means an action should be not allowed
  if (buttonsAllowedLength < 7 && props.pType !== PRINT_TYPES.PREVIEW_RETURN) {
    const totalOfOnes = 7 - buttonsAllowedLength;
    const newPaddedString = '1'.repeat(totalOfOnes);
    buttonsAllowed = buttonsAllowed + newPaddedString;
  }

  // We will return a print action to calc server after the user clicks on any of the buttons of this dialog box
  // const printAction = 1     const signDocAction = 2     const remoteSignAction = 3

  // Enable print button when previewing return
  if (props.pType === PRINT_TYPES.PREVIEW_RETURN) {
    buttonsAllowed = '0111111';
  }

  return (
    <div>
      {state.open && (
        <Dialog
          disableAutoFocus={true}
          PaperProps={{ tabIndex: -1 }}
          open={true}
          onClose={props.handleClose}
          TransitionComponent={Draggable}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle className={classes.styledHeader} id="form-dialog-title" disableTypography>
            <div style={{ display: 'inline-block', width: '94%' }}>
              <Typography
                style={{
                  color: 'white',
                  fontFamily: 'Roboto',
                  fontSize: '18px',
                  paddingTop: '3%',
                  paddingLeft: '5%',
                }}
              >
                Print {props.menuText === '' ? PRINT_TYPES_MAP[props.pType] : props.menuText}
              </Typography>
            </div>
            <div style={{ display: 'inline-block' }}>
              <Button
                className={classes.toolbarButtonRoot}
                style={{
                  minWidth: '0',
                  minHeight: '0',
                  paddingLeft: '5px',
                  paddingRight: '5px',
                }}
                onClick={props.handleClose}
              >
                X
              </Button>
            </div>
          </DialogTitle>
          <DialogContent style={{ marginBottom: '0vh', marginTop: '1vh' }}>
            <div style={{ fontWeight: '500' }}>Select Options</div>
            <hr
              style={{
                border: '1px solid #EBEDF8',
                width: '100%',
                paddingLeft: '0px',
                marginLeft: '0px',
              }}
            />
            <div
              style={{
                border: 'none',
                marginTop: '1vh',
                marginBottom: '0vh',
              }}
            >
              <table
                style={{
                  marginTop: '1.5vh',
                  marginBottom: '1.5vh',
                  marginLeft: '1.5vw',
                  marginRight: '1.5vw',
                }}
              >
                <tbody>
                  <tr>
                    <td>
                      Print {props.menuText === '' ? PRINT_TYPES_MAP[props.pType] : props.menuText}
                    </td>
                    <td
                      style={{
                        paddingTop: '1vh',
                        paddingBottom: '1vh',
                        paddingLeft: '1vw',
                      }}
                    >
                      <Button
                        id="printActionsDialogBtnPrint"
                        disabled={buttonsAllowed.charAt(0) === '1'}
                        fullWidth={true}
                        onClick={() => {
                          handlePrint();
                        }}
                      >
                        Print
                      </Button>
                    </td>
                  </tr>
                  {state.isCaptureSignatureAllowed && (
                    <tr>
                      <td>Sign Documents with E-Signature:</td>
                      <td
                        style={{
                          paddingTop: '1vh',
                          paddingBottom: '1vh',
                          paddingLeft: '1vw',
                        }}
                      >
                        <Button
                          id="printActionsDialogBtnSign"
                          fullWidth={true}
                          onClick={() => {
                            handleSignDoc();
                          }}
                        >
                          Sign Doc
                        </Button>
                      </td>
                    </tr>
                  )}
                  {((props.printPreferences.LPBM.configValue !== undefined &&
                    props.printPreferences.LPBM.configValue === '1') ||
                    (props.printPreferences.LPAM.configValue !== undefined &&
                      props.printPreferences.LPAM.configValue === '1')) && (
                    <tr>
                      <td>Email Encrypted PDF Copy of Tax Return:</td>
                      <td
                        style={{
                          paddingTop: '1vh',
                          paddingBottom: '1vh',
                          paddingLeft: '1vw',
                        }}
                      >
                        <Button
                          id="printActionsDialogBtnEmailEncrypted"
                          disabled={buttonsAllowed.charAt(5) === '1'}
                          fullWidth={true}
                          onClick={() => {
                            handleEmailEncryptedPDF();
                          }}
                        >
                          Email
                        </Button>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </DialogContent>
          <DialogActions style={{ paddingRight: '1vw', paddingBottom: '1vh' }}>
            <Button onClick={props.handleClose} id="btnClosePrintActionsDialog" color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <SimpleDialog
        open={props.printPDFWarningMessage === '0'}
        onConfirm={props.handlePrintPDFWarningMessage}
        dialogTitle={'Print PDF'}
        contentText={
          'If the intent of generating this PDF is for use with a third party remote signature service, only your software provider’s remote signature service is allowed  and use of any other would be a violation of the user terms and conditions. Generated PDFs are logged and tracked by your software provider.'
        }
        confirmText="Ok"
        styled={true}
        errorMessage={true}
      />
      <SimpleDialog
        open={state.isMissingPreparerSignatureModalOpen}
        onConfirm={() => {
          setState({ isMissingPreparerSignatureModalOpen: false });
        }}
        confirmText="OK"
        styled={true}
        dialogTitle="Preparer Signature Missing"
        contentText={buildMissingSignatureWarning('Preparer')}
      />
    </div>
  );
};

export default withStyles(styles)(PrintActionsDialog);

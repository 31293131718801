import WebHelpers from '~/app/webHelpers.js';
export const DRILLDOWN = 'xlinkonline/drilldown/DRILLDOWN';
export const DRILLDOWN_ERROR = 'xlinkonline/drilldown/DRILLDOWNERR';
export const SET_CURRENT_VIEW = 'xlinkonline/drilldown/SETCURRENTVIEW';
export const CLEAR_DRILLDOWN = 'xlinkonline/drilldown/CLEARDRILLDOWN';
export const ADD_MULTIPLE_ENTITIES = 'xlinkonline/drilldown/ADDMULTIPLEENTITIES';
export const LOAD_PREV_OVERVIEW = 'xlinkonline/drilldown/LOADPREVOVERVIEW';
export const SET_SETUP_PROGRESS = 'xlinkonline/drilldown/SETSETUPPROGRESS';
export const ON_SETUP_COMPLETE = 'xlinkonline/drilldown/ONSETUPCOMPLETE';
export const RESET_DRILLDOWN = 'xlinkonline/drilldown/RESETDRILLDOWN';
export const MODIFY_CURRENT_VIEW = 'xlinkonline/drilldown/MODIFY_CURRENT_VIEW';

export const initialState = {
  drilldownHistory: [], // a history of {loginID, preparer_id, role, name} used while drilling down
};

// TODO --> Should the API be the one supplying values such as
// setupComplete, setupProgress, textlinkActivated?
export const actions = {
  requestDrilldown: (
    loginID,
    preparerID,
    view,
    name,
    setupComplete,
    setupProgress,
    textlinkActivated,
  ) => {
    return {
      type: DRILLDOWN,
      loginID,
      preparerID,
      view,
      name,
      setupComplete,
      setupProgress,
      textlinkActivated,
    };
  },
  onDrilldownError: () => ({
    type: DRILLDOWN_ERROR,
  }),
  setCurrentView: entityData => ({
    type: SET_CURRENT_VIEW,
    entityData,
  }),
  clearDrilldownHistory: () => {
    return {
      type: CLEAR_DRILLDOWN,
    };
  },
  addMultipleEntities: entityList => ({
    type: ADD_MULTIPLE_ENTITIES,
    entityList,
  }),
  loadPrevOverview: idx => ({
    type: LOAD_PREV_OVERVIEW,
    idx,
  }),
  setSetupProgress: percentage => ({
    type: SET_SETUP_PROGRESS,
    percentage,
  }),
  onSetupComplete: () => ({
    type: SET_SETUP_PROGRESS,
    percentage: 100,
  }),
  resetDrilldown: () => ({
    type: RESET_DRILLDOWN,
  }),
  modifyCurrentView: entityData => ({
    type: MODIFY_CURRENT_VIEW,
    entityData,
  }),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_VIEW:
      return {
        ...state,
        drilldownHistory: [
          ...state.drilldownHistory,
          {
            ...action.entityData,
          },
        ],
      };
    case MODIFY_CURRENT_VIEW: {
      let listMinusLast = state.drilldownHistory.slice(0, state.drilldownHistory.length - 1);
      if (listMinusLast === undefined) listMinusLast = [];
      return {
        ...state,
        drilldownHistory: [...listMinusLast, action.entityData],
      };
    }
    case CLEAR_DRILLDOWN:
      WebHelpers.revokeDrilldownToken(true);
      WebHelpers.cleanupDrilldown();
      return {
        ...state,
        drilldownHistory: [],
      };
    case LOAD_PREV_OVERVIEW:
      if (action.idx === 0) {
        WebHelpers.revokeDrilldownToken(true);
        WebHelpers.cleanupDrilldown();
      } else {
        // the difference is how many DD tokens need revoked
        // sense it is an index we need to add one
        const limit = Math.abs(action.idx + 1 - state.drilldownHistory.length);
        WebHelpers.revokeDrilldownToken(false, limit);
      }
      return {
        ...state,
        drilldownHistory: [...state.drilldownHistory.slice(0, action.idx + 1)],
      };
    case SET_SETUP_PROGRESS: {
      // short circuit if we are impersonating and drilling out as a super/tech user.
      // super/tech users element is not included in the drilldownHistory array
      if (!state.drilldownHistory?.length) return { ...state };

      const currentEntity = state.drilldownHistory[state.drilldownHistory.length - 1];
      currentEntity.setupProgress = action.percentage;
      currentEntity.isOfficeSetupComplete = action.percentage === 100;

      let listMinusLast = state.drilldownHistory.slice(0, state.drilldownHistory.length - 1);
      if (listMinusLast === undefined) listMinusLast = [];
      return {
        ...state,
        drilldownHistory: [...listMinusLast, currentEntity],
      };
    }
    case RESET_DRILLDOWN:
      WebHelpers.cleanupDrilldown(); // if we are setting drilldown to the first element, this is the top level and no DDTOKENs will exist in the DB auth.tbltoken
      if (state.drilldownHistory.length > 0) {
        return {
          ...state,
          drilldownHistory: [state.drilldownHistory[0]],
        };
      }
  }
  return {
    ...state,
  };
}

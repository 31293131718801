// External imports
import React, { useEffect, useState } from 'react';
import { Paper, Button, AppBar, Toolbar, withStyles } from '@material-ui/core';
// Internal imports
import { SIGNEE_TYPE } from '~/app/constants.js';
// Styling imports
import { styles } from '~/app/Components/Common/css/PreviewSignature/previewSignature.js';
import '~/app/Components/Common/css/PreviewSignature/previewSignature.css';

const toolbarTitle = 'Signature Already Exists';

const PreviewSignature = props => {
  const { classes } = props;

  const [signatureToPreview, setSignatureToPreview] = useState('');

  useEffect(() => {
    getSignatureToPreview();
  }, []);

  const cancel = e => {
    e.preventDefault();
    props.handleNewClose();
  };

  const updateSignature = () => {
    props.signatureRecapture();
  };
  // getSignatureToPreview sets the signature depending on which signee type was clicked
  const getSignatureToPreview = () => {
    // 1=>TP, 2=>SP, 3=>PP, 4=>ERO, 5=>TP initial, 6=>SP initial, 8=>TP 2nd, 9=>SP 2nd, A=>PP if amended
    if (props.signeeType === SIGNEE_TYPE.PREPARER) {
      setSignatureToPreview(props.prepSignature);
    }
    if (props.signeeType === SIGNEE_TYPE.ERO) {
      setSignatureToPreview(props.eroSignature);
    }
  };

  return (
    <div className="container-fluid">
      <Paper classes={{ root: classes.paper }}>
        <AppBar position="static">
          <Toolbar classes={{ root: classes.toolbarColor }}>
            <span className="preview-signature-span-one">{toolbarTitle}</span>
          </Toolbar>
        </AppBar>
        <form className="preview-signature-form">
          <div className="preview-signature-div-one">
            <div>
              <p className="preview-signature-paragraph-one">We Already Have Your Signature</p>
            </div>
            <div className="preview-signature-div-two">
              <img className="preview-signature-image" src={signatureToPreview} />
            </div>
          </div>
          <div className="preview-signature-div-three">
            <Button id="btnCancelSignDoc" classes={{ root: classes.cancelButton }} onClick={cancel}>
              No, Cancel
            </Button>
            <Button
              id="btnAcceptSignDoc"
              onClick={updateSignature}
              variant="contained"
              color="primary"
              classes={{ root: classes.updateButton }}
            >
              Yes, Update Current Signature
            </Button>
          </div>
          <br />
        </form>
      </Paper>
    </div>
  );
};

export default withStyles(styles)(PreviewSignature);

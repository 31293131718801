import "regenerator-runtime/runtime";
import { actions, FETCHED_ACCOUNTS_LIST } from "./duck";

import { takeEvery, takeLatest, call, put } from "redux-saga/effects";

import XlinkAPI from "~/app/api/xlinkAPI";
import ErrorHelpers from "~/app/errorHelpers.js";

export function* watchFetchAccountsList() {
  yield takeLatest(FETCHED_ACCOUNTS_LIST, fetchAccountsList);
}

export function* fetchAccountsList(payload) {
  try {
    yield put(actions.requestAccountsList());

    const res = yield call(XlinkAPI.getDashboardList, payload.filterParams);

    yield put(actions.requestAccountsListSuccess(res.data));
  } catch (error) {
    ErrorHelpers.handleError("Error fetching filtered accounts", error);
    yield put(actions.requestAccountsListError());
  }
}

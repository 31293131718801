import { SIGNEE_TYPE, SIGNEE_TOOLBAR_TITLE } from '~/app/constants.js';

export const buildTitleLabel = signeeType => {
  let signeeTypeDesc = '';

  switch (signeeType) {
    case SIGNEE_TYPE.OFFICER:
      signeeTypeDesc = 'Business';
      break;
    case SIGNEE_TYPE.PREPARER:
      signeeTypeDesc = 'Preparer';
      break;
    case SIGNEE_TYPE.ERO:
      signeeTypeDesc = 'ERO';
      break;
    case SIGNEE_TYPE.TAXPAYER:
      signeeTypeDesc = 'Taxpayer';
      break;
    case SIGNEE_TYPE.SPOUSE:
      signeeTypeDesc = 'Spouse';
      break;
  }

  return `Please verify your ${signeeTypeDesc} information`;
};

export const buildToolbarTitle = currentSig => {
  let toolbarTitle = '';
  switch (currentSig + '') {
    case SIGNEE_TYPE.TAXPAYER:
      toolbarTitle = SIGNEE_TOOLBAR_TITLE.TAXPAYER;
      break;
    case SIGNEE_TYPE.SPOUSE:
      toolbarTitle = SIGNEE_TOOLBAR_TITLE.SPOUSE;
      break;
    case SIGNEE_TYPE.PREPARER:
      toolbarTitle = SIGNEE_TOOLBAR_TITLE.PREPARER;
      break;
    case SIGNEE_TYPE.ERO:
      toolbarTitle = SIGNEE_TOOLBAR_TITLE.ERO;
      break;
    case SIGNEE_TYPE.TAXPAYER_INITIAL:
      toolbarTitle = SIGNEE_TOOLBAR_TITLE.TAXPAYER_INITIAL;
      break;
    case SIGNEE_TYPE.SPOUSE_INITIAL:
      toolbarTitle = SIGNEE_TOOLBAR_TITLE.SPOUSE_INITIAL;
      break;
    case SIGNEE_TYPE.TAXPAYER_SECOND_SIGNATURE:
      toolbarTitle = SIGNEE_TOOLBAR_TITLE.TAXPAYER_SECOND_SIGNATURE;
      break;
    case SIGNEE_TYPE.SPOUSE_SECOND_SIGNATURE:
      toolbarTitle = SIGNEE_TOOLBAR_TITLE.SPOUSE_SECOND_SIGNATURE;
      break;
    case SIGNEE_TYPE.AMENDED_PAID_PREPARER_SIGNATURE:
      toolbarTitle = SIGNEE_TOOLBAR_TITLE.AMENDED_PAID_PREPARER_SIGNATURE;
      break;
    case SIGNEE_TYPE.OFFICER:
      toolbarTitle = SIGNEE_TOOLBAR_TITLE.OFFICER;
      break;
    default:
      toolbarTitle = SIGNEE_TOOLBAR_TITLE.DEFAULT;
      break;
  }

  return toolbarTitle;
};

/**
 * Checks each input to see if it is empty.
 *
 * @returns {boolean} used to determine if there is an error and should return true
 */
export const formHasErrors = (fields, handleSetErrors, isTP) => {
  const errors = {};
  for (const field of Object.keys(fields)) {
    const value = fields[field];
    switch (field) {
      case 'name':
        if (!value) errors[field] = `Name Required`;
        break;
      case 'lastFourPrimary':
        if (!value || (value.length >= 0 && value.length < 4))
          errors[field] = 'Four Digits Required';
        break;
      case 'dob':
        // date picker component only populates on the last digit
        if (!value && isTP) errors[field] = `DOB is Required`;
        break;
      case 'zipCode':
        if ((!value || (value.length >= 0 && value.length < 5)) && isTP) {
          errors[field] = 'Five Digits Required';
        }
        break;
    }
  }

  handleSetErrors(errors); // set any errors to the local state
  return Object.keys(errors).length !== 0; // if even one error exists, prevent user from submitting
};

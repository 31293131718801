// External imports
import React, { Component } from 'react';
import SignaturePad from 'react-signature-pad-wrapper';
import {
  AppBar,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Toolbar,
  Typography,
  withStyles,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
// Internal imports
import ErrorHelpers from '~/app/errorHelpers.js';
import WebHelpers from '~/app/webHelpers.js';
import XlinkAPI from '~/app/api/xlinkAPI';
import { HIERARCHY_TYPE } from '~/app/constants.js';

import {
  scaleCanvasPixelRatio,
  canvasSize,
  canvasOptions,
  resizeCanvas,
  isCanvasScaled,
  cleanUpCanvas,
} from '~/app/bmpConversionHelpers.js';

const styles = theme => ({
  styledHeader: {
    background: '#444C59',
    border: 0,
    color: 'white',
    height: 64,
    padding: '0 30px',
    minWidth: '450px',
    PaperProps: {
      style: {
        backgroundColor: '#fff',
        color: 'black',
      },
    },
  },
});

class SignatureModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signatureData: '',
      preparerSignatureData: '',
      preparerUploadedSignature: '',
      errorModalOpened: false,
      // Track the state of the signature pad type selected
      showOptions: false,
      onScreen: true,
      scriptel: false,
      remoteSignStepTwoTopaz: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;

    const payload = WebHelpers.getJWTPayload();
    const roleGroup = payload.hierarchy_type_id;
    if (roleGroup == HIERARCHY_TYPE.PREPARER) {
      // Check if the preparer has a signature
      XlinkAPI.getPreparerUploadedSignature()
        .then(res => {
          this.setState({ preparerUploadedSignature: res.data });
          if (res.data == 1) {
            XlinkAPI.getSignatureForPreparer()
              .then(res => {
                if (res.data == null) {
                  res.data = '';
                }
                const data = res.data;
                this.setState({ preparerSignatureData: data });
              })
              .catch(err => {
                ErrorHelpers.handleError('No preparer signature', err);
              });
          }
        })
        .catch(error => {
          ErrorHelpers.handleError('Preparer signature does not exist', error);
        });
    }

    if (this.signaturePad && this.signaturePad.canvas != null) {
      scaleCanvasPixelRatio(this.signaturePad.canvas);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;

    if (isCanvasScaled) {
      cleanUpCanvas();
    }
  }

  // Handle Save Signature Button
  previewSignature = e => {
    e.preventDefault();

    // Check if user signed or if the signature pad is empty
    const haveSignature = this.signaturePad.isEmpty();

    // Only go to preview page if the user entered a signature on the signature pad
    if (!haveSignature) {
      const signatureDataOne = isCanvasScaled
        ? resizeCanvas(this.signaturePad.canvas).toDataURL()
        : this.signaturePad.toDataURL(); // Getting signature data (base 64)
      // Save signature data to session storage, in order to generate image on signature preview modal
      sessionStorage.setItem('signatureData', signatureDataOne);

      // Close the modal
      this.props.onClose();

      // Open the preview page
      this.props.onPreview();
    } else {
      // TODO show modal when no logo available to upload
      this.setState({ errorModalOpened: true });
      // alert("No signature available to preview");
    }
  };

  // Handle Click Cancel
  clickCancel = e => {
    e.preventDefault();
    // Close the modal
    this.props.onClose();
  };

  // Handle Clear Signature
  clearSignature = e => {
    e.preventDefault();
    this.signaturePad.clear();
  };

  closeErrorModal = () => {
    this.setState({ errorModalOpened: false });
  };

  // handleSignMethod onChange without validation for checkbox, only allow one option/checkbox selected for the signature pad type
  handleSignMethod = event => {
    if ([event.target.name] === 'onScreen') {
      this.setState({
        [event.target.name]: event.target.checked,
        scriptel: false,
        remoteSignStepTwoTopaz: false,
      });
    }
    if ([event.target.name] === 'scriptel') {
      this.setState({
        [event.target.name]: event.target.checked,
        onScreen: false,
        remoteSignStepTwoTopaz: false,
      });
    }
    if ([event.target.name] === 'remoteSignStepTwoTopaz') {
      this.setState({
        [event.target.name]: event.target.checked,
        onScreen: false,
        scriptel: false,
      });
    }
  };

  render() {
    const labelWidthStyleCheckbox = {
      height: '2vh',
      color: '#354052',
      textAlign: 'left',
      fontFamily: 'Roboto',
      fontSize: '14px',
      marginTop: '0.5vh',
      marginBottom: '0.5vh',
    };
    const toolbarColor = { backgroundColor: '#444A59' };
    const modalTitle = 'Capture Signature';
    const signatureInstructions = 'Please sign below';

    return (
      <div>
        {this.props.eroUploadedSignature ? (
          <div>
            <AppBar position="static">
              <Toolbar style={toolbarColor}>
                <span style={{ fontFamily: 'Roboto', fontSize: '18px' }}>Update Signature</span>
              </Toolbar>
            </AppBar>
            <form
              style={{
                marginLeft: '30px',
                marginRight: '30px',
                marginTop: '10px',
              }}
            >
              <div style={{ marginTop: '3vh', marginLeft: '10px' }}>
                <div>
                  <p style={{ fontSize: '15px', textAlign: 'justify' }}>
                    We already have your signature:
                  </p>
                </div>
                <div>
                  <img
                    src={
                      this.state.preparerSignatureData
                        ? this.state.preparerSignatureData
                        : this.props.eroSignatureData
                    }
                    style={{
                      width: '420px',
                      height: '100px',
                      backgroundColor: 'white',
                    }}
                  />
                </div>
                <div>
                  <p style={{ fontSize: '15px', textAlign: 'justify' }}>
                    If you want to update it, please sign below
                  </p>
                </div>
                {this.state.showOptions && (
                  <div
                    style={{
                      border: 'none',
                      marginTop: '0vh',
                      marginBottom: '0vh',
                    }}
                  >
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <div style={{ marginTop: '0vh', marginBottom: '0vh' }}>
                          <div
                            style={{
                              paddingTop: '1vh',
                              paddingBottom: '0vh',
                              paddingLeft: '1vw',
                              display: 'inline-block',
                            }}
                          >
                            <div
                              style={{
                                float: 'none',
                                verticalAlign: 'middle',
                                marginLeft: '1vw',
                                marginBottom: '1vh',
                              }}
                            >
                              <FormControlLabel
                                label="On-Screen"
                                style={labelWidthStyleCheckbox}
                                control={
                                  <Checkbox
                                    style={{
                                      width: 17,
                                      height: 16,
                                      marginRight: '0.3vw',
                                      backgroundColor: '#F3F9FF',
                                    }}
                                    icon={
                                      <CheckBoxOutlineBlankIcon
                                        style={{ fontSize: 24 }}
                                        color="secondary"
                                      />
                                    }
                                    checkedIcon={
                                      <CheckBoxIcon style={{ fontSize: 24 }} color="primary" />
                                    }
                                    id="chkSigCaptureOnScreen"
                                    name="onScreen"
                                    value="sigCaptureOnScreen"
                                    checked={this.state.onScreen}
                                    onChange={this.handleSignMethod}
                                    inputProps={{
                                      height: '20px',
                                      width: '20px',
                                    }}
                                  />
                                }
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              paddingTop: '0vh',
                              paddingBottom: '0vh',
                              paddingLeft: '1vw',
                              display: 'inline-block',
                            }}
                          >
                            <div
                              style={{
                                float: 'none',
                                verticalAlign: 'middle',
                                marginLeft: '1vw',
                                marginBottom: '1vh',
                              }}
                            >
                              <FormControlLabel
                                label="Scriptel"
                                style={labelWidthStyleCheckbox}
                                control={
                                  <Checkbox
                                    style={{
                                      width: 17,
                                      height: 16,
                                      marginRight: '0.3vw',
                                      backgroundColor: '#F3F9FF',
                                    }}
                                    icon={
                                      <CheckBoxOutlineBlankIcon
                                        style={{ fontSize: 24 }}
                                        color="secondary"
                                      />
                                    }
                                    checkedIcon={
                                      <CheckBoxIcon style={{ fontSize: 24 }} color="primary" />
                                    }
                                    id="chkSigCaptureScriptel"
                                    name="scriptel"
                                    value="sigCaptureScriptel"
                                    checked={this.state.scriptel}
                                    onChange={this.handleSignMethod}
                                    inputProps={{
                                      height: '20px',
                                      width: '20px',
                                    }}
                                  />
                                }
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              paddingTop: '0vh',
                              paddingBottom: '0vh',
                              paddingLeft: '1vw',
                              display: 'inline-block',
                            }}
                          >
                            <div
                              style={{
                                float: 'none',
                                verticalAlign: 'middle',
                                marginLeft: '1vw',
                                marginBottom: '1vh',
                              }}
                            >
                              <FormControlLabel
                                label="Topaz"
                                style={labelWidthStyleCheckbox}
                                control={
                                  <Checkbox
                                    style={{
                                      width: 17,
                                      height: 16,
                                      marginRight: '0.3vw',
                                      backgroundColor: '#F3F9FF',
                                    }}
                                    icon={
                                      <CheckBoxOutlineBlankIcon
                                        style={{ fontSize: 24 }}
                                        color="secondary"
                                      />
                                    }
                                    checkedIcon={
                                      <CheckBoxIcon style={{ fontSize: 24 }} color="primary" />
                                    }
                                    id="chkRemoteSignTopaz"
                                    name="remoteSignStepTwoTopaz"
                                    value="remoteSignTopaz"
                                    checked={this.state.remoteSignStepTwoTopaz}
                                    onChange={this.handleSignMethod}
                                    inputProps={{
                                      height: '20px',
                                      width: '20px',
                                    }}
                                  />
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </FormGroup>
                    </FormControl>
                  </div>
                )}
                {this.state.onScreen && (
                  <div
                    style={{
                      textAlign: 'center',
                      width: '420px',
                      height: '100px',
                      backgroundColor: '#DBEBFE',
                    }}
                  >
                    <SignaturePad
                      width={canvasSize.width}
                      height={canvasSize.height}
                      ref={ref => (this.signaturePad = ref)}
                      options={{ ...canvasOptions }}
                    />
                  </div>
                )}
              </div>
              {this.state.onScreen && (
                <div
                  style={{
                    textAlign: 'center',
                    padding: '0',
                    marginLeft: '0vw',
                    marginTop: '3vh',
                  }}
                >
                  <Button
                    id="btnCancelSignature"
                    onClick={this.clickCancel}
                    style={{
                      width: '90px',
                      paddingLeft: '10px',
                      paddingRight: '10px',
                      minWidth: '0px',
                      minHeight: '3.5vh',
                      textTransform: 'capitalize',
                      fontFamily: 'Roboto',
                      fontSize: '14px',
                      borderRadius: '4px',
                      marginRight: '1.5vw',
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    id="btnClearSignature"
                    onClick={this.clearSignature}
                    style={{
                      paddingLeft: '10px',
                      width: '90px',
                      paddingRight: '10px',
                      minWidth: '0px',
                      minHeight: '3.5vh',
                      textTransform: 'capitalize',
                      fontFamily: 'Roboto',
                      fontSize: '14px',
                      borderRadius: '4px',
                      marginRight: '1.5vw',
                    }}
                  >
                    Clear
                  </Button>
                  <Button
                    id="btnPreviewSignature"
                    style={{
                      paddingLeft: '10px',
                      width: '90px',
                      paddingRight: '10px',
                      minWidth: '0px',
                      minHeight: '3.5vh',
                      textTransform: 'capitalize',
                      fontFamily: 'Roboto',
                      fontSize: '14px',
                      borderRadius: '4px',
                    }}
                    onClick={this.previewSignature}
                  >
                    Preview
                  </Button>
                </div>
              )}
              <br />
            </form>
          </div>
        ) : (
          <div>
            <AppBar position="static">
              <Toolbar style={toolbarColor}>
                <span style={{ fontFamily: 'Roboto', fontSize: '18px' }}>{modalTitle}</span>
              </Toolbar>
            </AppBar>
            <form
              style={{
                marginLeft: '30px',
                marginRight: '30px',
                marginTop: '10px',
              }}
            >
              <div style={{ marginTop: '3vh', marginLeft: '10px' }}>
                <div>
                  <p style={{ fontSize: '15px', textAlign: 'justify' }}>{signatureInstructions}</p>
                </div>
                {this.state.showOptions && (
                  <div
                    style={{
                      border: 'none',
                      marginTop: '0vh',
                      marginBottom: '0vh',
                    }}
                  >
                    <FormControl required component="fieldset">
                      <FormGroup>
                        <div style={{ marginTop: '0vh', marginBottom: '0vh' }}>
                          <div
                            style={{
                              paddingTop: '1vh',
                              paddingBottom: '0vh',
                              paddingLeft: '1vw',
                              display: 'inline-block',
                            }}
                          >
                            <div
                              style={{
                                float: 'none',
                                verticalAlign: 'middle',
                                marginLeft: '1vw',
                                marginBottom: '1vh',
                              }}
                            >
                              <FormControlLabel
                                label="On-Screen"
                                style={labelWidthStyleCheckbox}
                                control={
                                  <Checkbox
                                    style={{
                                      width: 17,
                                      height: 16,
                                      marginRight: '0.3vw',
                                      backgroundColor: '#F3F9FF',
                                    }}
                                    icon={
                                      <CheckBoxOutlineBlankIcon
                                        style={{ fontSize: 24 }}
                                        color="secondary"
                                      />
                                    }
                                    checkedIcon={
                                      <CheckBoxIcon style={{ fontSize: 24 }} color="primary" />
                                    }
                                    id="chkSigCaptureOnScreen"
                                    name="onScreen"
                                    value="sigCaptureOnScreen"
                                    checked={this.state.onScreen}
                                    onChange={this.handleSignMethod}
                                    inputProps={{
                                      height: '20px',
                                      width: '20px',
                                    }}
                                  />
                                }
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              paddingTop: '0vh',
                              paddingBottom: '0vh',
                              paddingLeft: '1vw',
                              display: 'inline-block',
                            }}
                          >
                            <div
                              style={{
                                float: 'none',
                                verticalAlign: 'middle',
                                marginLeft: '1vw',
                                marginBottom: '1vh',
                              }}
                            >
                              <FormControlLabel
                                label="Scriptel"
                                style={labelWidthStyleCheckbox}
                                control={
                                  <Checkbox
                                    style={{
                                      width: 17,
                                      height: 16,
                                      marginRight: '0.3vw',
                                      backgroundColor: '#F3F9FF',
                                    }}
                                    icon={
                                      <CheckBoxOutlineBlankIcon
                                        style={{ fontSize: 24 }}
                                        color="secondary"
                                      />
                                    }
                                    checkedIcon={
                                      <CheckBoxIcon style={{ fontSize: 24 }} color="primary" />
                                    }
                                    id="chkSigCaptureScriptel"
                                    name="scriptel"
                                    value="sigCaptureScriptel"
                                    checked={this.state.scriptel}
                                    onChange={this.handleSignMethod}
                                    inputProps={{
                                      height: '20px',
                                      width: '20px',
                                    }}
                                  />
                                }
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              paddingTop: '0vh',
                              paddingBottom: '0vh',
                              paddingLeft: '1vw',
                              display: 'inline-block',
                            }}
                          >
                            <div
                              style={{
                                float: 'none',
                                verticalAlign: 'middle',
                                marginLeft: '1vw',
                                marginBottom: '1vh',
                              }}
                            >
                              <FormControlLabel
                                label="Topaz"
                                style={labelWidthStyleCheckbox}
                                control={
                                  <Checkbox
                                    style={{
                                      width: 17,
                                      height: 16,
                                      marginRight: '0.3vw',
                                      backgroundColor: '#F3F9FF',
                                    }}
                                    icon={
                                      <CheckBoxOutlineBlankIcon
                                        style={{ fontSize: 24 }}
                                        color="secondary"
                                      />
                                    }
                                    checkedIcon={
                                      <CheckBoxIcon style={{ fontSize: 24 }} color="primary" />
                                    }
                                    id="chkRemoteSignTopaz"
                                    name="remoteSignStepTwoTopaz"
                                    value="remoteSignTopaz"
                                    checked={this.state.remoteSignStepTwoTopaz}
                                    onChange={this.handleSignMethod}
                                    inputProps={{
                                      height: '20px',
                                      width: '20px',
                                    }}
                                  />
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </FormGroup>
                    </FormControl>
                  </div>
                )}
                <div />
                {this.state.onScreen && (
                  <div
                    style={{
                      textAlign: 'center',
                      width: '420px',
                      height: '100px',
                      backgroundColor: '#DBEBFE',
                    }}
                  >
                    <SignaturePad
                      width={canvasSize.width}
                      height={canvasSize.height}
                      ref={ref => (this.signaturePad = ref)}
                      options={{ ...canvasOptions }}
                    />
                  </div>
                )}
              </div>
              {this.state.onScreen && (
                <div
                  style={{
                    textAlign: 'center',
                    padding: '0',
                    marginLeft: '0vw',
                    marginTop: '3vh',
                  }}
                >
                  <Button
                    id="btnCancelSignature"
                    onClick={this.clickCancel}
                    style={{
                      marginLeft: '1vw',
                      width: '90px',
                      minWidth: '0vw',
                      minHeight: '3.5vh',
                      textTransform: 'capitalize',
                      fontFamily: 'Roboto',
                      fontSize: '14px',
                      borderRadius: '4px',
                      marginRight: '1.5vw',
                      padding: '0 0',
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    id="btnClearSignature"
                    onClick={this.clearSignature}
                    style={{
                      width: '90px',
                      minHeight: '3.5vh',
                      textTransform: 'capitalize',
                      fontFamily: 'Roboto',
                      fontSize: '14px',
                      borderRadius: '4px',
                      marginRight: '1.5vw',
                      padding: '0 0',
                    }}
                  >
                    Clear
                  </Button>
                  <Button
                    id="btnPreviewSignature"
                    style={{
                      width: '90px',
                      minHeight: '3.5vh',
                      textTransform: 'capitalize',
                      fontFamily: 'Roboto',
                      fontSize: '14px',
                      borderRadius: '4px',
                      padding: '0 0',
                    }}
                    onClick={this.previewSignature}
                  >
                    Preview
                  </Button>
                </div>
              )}
              <br />
            </form>
          </div>
        )}
        <Dialog
          disableAutoFocus={true}
          PaperProps={{ tabIndex: -1 }}
          open={this.state.errorModalOpened}
          onClose={() => this.closeErrorModal()}
          aria-labelledby="confirm-blank-signature-title"
          aria-describedby="confirm-blank-signature-desc"
        >
          <DialogTitle
            id="confirm-blank-signature-title"
            className={this.props.classes.styledHeader}
          >
            <Typography
              style={{
                color: 'white',
                fontFamily: 'Roboto',
                fontSize: '18px',
                marginTop: '23px',
              }}
            >
              {'Signature Missing'}
            </Typography>
          </DialogTitle>
          <DialogContent id="confirm-scriptel-connected-desc">
            <p
              style={{
                marginTop: '3vh',
                marginBottom: '1vh',
                fontSize: '15px',
                fontFamily: 'Roboto',
                textAlign: 'justify',
              }}
            >
              {`No signature captured. Please sign and the click "Preview" button`}
            </p>
          </DialogContent>
          <DialogActions>
            <Button
              id="btnHideScriptelPadConnectedModal"
              style={{ marginRight: '1vw', marginBottom: '1vh' }}
              onClick={() => this.closeErrorModal()}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(SignatureModal);

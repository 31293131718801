export const SET_DEVICES = "xlinkonline/user/setDevices";

// Initial object properties and values to be override once updated.
export const initialState = {
  devices: {
    loginId: "",
    deviceName: "",
    deviceDescription: "",
    deviceFingerprint: "",
    deviceTypeLookupId: "",
    deviceTypeName: "",
    deviceStatus: ""
  }
};

export const actions = {
  setDevices: devices => ({
    type: SET_DEVICES,
    devices
  })
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_DEVICES:
      return { ...state, devices: action.devices };
  }

  return state;
}

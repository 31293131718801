export const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  docTitle: {
    color: '#1A173B',
    paddingTop: '1rem',
    width: '95%',
  },
  docTitleBtn: {
    marginLeft: '1rem',
    marginRight: '0.1rem',
    color: '#0077FF',
  },
  docUploadInp: {
    border: '1px solid #EBEDF8',
    borderRadius: '4px',
    backgroundColor: '#DBEBFE',
    fontSize: '14px',
  },
  docIconClearBtn: {
    background: 'rgba(0,0,0,0.0)',
    '&:hover': {
      background: 'rgba(0,0,0,0.0)',
    },
    '&:focus': {
      border: '0',
      outline: '0',
      boxShadow: 'none',
    },
  },
  importButtonSpinner: {
    position: 'absolute',
    marginTop: '5px',
    marginLeft: '6rem',
  },
  headerTitle: {
    paddingLeft: '1rem',
    fontSize: '1rem',
    fontFamily: 'Roboto Regular',
  },
  documentInputBlock: {
    width: '100%',
    marginBottom: '1rem',
    padding: '0 1rem',
  },
  documentUploadInput: {
    border: '1px solid #EBEDF8',
    borderRadius: '4px',
    backgroundColor: '#DBEBFE',
    fontSize: '14px',
    width: '100%',
  },
  documentUploadInputBtn: {
    marginRight: '-0.2rem',
  },
  spouseCheckboxBlock: {
    width: '18rem',
    paddingLeft: '1rem',
  },
  spouseCheckboxText: {
    padding: 0,
    fontFamily: 'Roboto',
    fontSize: '14px',
  },
  importErrorMessage: {
    display: 'inline-block',
    cursor: 'default',
    marginRight: '1rem',
    color: 'red',
  },
  cancelBtn: {
    marginRight: '1rem',
  },
});

export const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  newPreparerSetupHeader: {
    position: 'sticky',
    top: '0',
    backgroundColor: '#000000',
  },
  menuItemStyle: {
    paddingTop: '0px',
    paddingBottom: '0px',
    color: '#0077FF',
    backgroundColor: '#FFFFFF',
  },
  menuEntryStyle: {
    paddingTop: '0px',
    paddingBottom: '0px',
    color: '#0077FF',
    paddingLeft: '12px',
  },
  styleDropdown12: {
    width: '12vw',
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingRight: '0px',
    textAlign: 'left',
  },
  styleDropdown27: {
    width: '25.2vw',
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingRight: '0px',
    textAlign: 'left',
  },
  width12: {
    width: '12vw',
    marginRight: '2vw',
  },
  width13: {
    width: '13vw',
    marginRight: '2vw',
  },
  width27: {
    width: '27vw',
    marginRight: '2vw',
  },
  width56: {
    width: '56vw',
    marginRight: '2vw',
  },
  toolbarColor: {
    backgroundColor: '#444A59',
  },
  typographyStyle: {
    color: '#FFFFFF',
    fontFamily: 'Roboto',
    fontSize: '15',
  },
  labelWidthStyle: {
    height: '1.6vh',
    color: '#354052',
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontSize: '14px',
  },
  smallStyle: { width: '12vw', marginRight: '2vw' },
  smallStyleTwo: {
    width: '13vw',
    marginRight: '2vw',
  },
  mediumStyle: { width: '27vw', marginRight: '2vw' },
  labelWidthStyleCheckbox: {
    height: '2vh',
    color: '#354052',
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontSize: '14px',
    marginTop: '0.5vh',
    marginBottom: '0.5vh',
  },
  accountCircle: {
    fontSize: '2.5rem',
    verticalAlign: 'middle',
    marginRight: '0.5vw',
  },
  checkBoxStyle: {
    width: 17,
    height: 16,
    marginRight: '0.1vw',
    marginLeft: '0.8rem',
    backgroundColor: '#F3F9FF',
  },
  checkBoxOutlineBlankStyle: {
    fontSize: 24,
    color: '#8FC3FF',
  },
  dividerStyle: {
    marginRight: '2vw',
  },
  inputAdornmentStyle: {
    marginRight: '0px',
    paddingRight: '2px',
    paddingBottom: '2px',
  },
  cancelBtn: {
    minHeight: '3.5vh',
    marginRight: '1.5vw',
    padding: '0 0',
    border: '0px',
  },
  saveBtn: {
    minHeight: '3.5vh',
    padding: '0 1rem',
  },
});

// External imports
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Button,
  Menu,
  MenuItem,
  TableFooter,
  TablePagination,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
// Internal imports
import { TABLE_PAGE_SIZE } from '~/app/constants.js';
import AccessControl from '~/app/Components/Auth/AccessControl.jsx';
import menuVertical from '~/images/icons/menu_vertical.png';
import expandArrow from '~/images/icons/icons8-double_right_filled.png';
// Styling imports
import { styles } from '~/app/Components/Common/css/pagedTableStyles.jsx';

/*

  PagedTable is a generic paged material UI table component. Assumes that the rows passed to be rendered are paged, and the number of rows passed match the number of rows per page.

  Required Props:
    columns: string[]               //array of strings to be used as column headers
    rows: [                         //array of json objects containing row data + column contents, all rows passed will be rendered. Passed rows should correspond to the page to be displayed.
      {
        data: {...},                //json object containing data for that row
        columns: string[]           //array of strings to be used for that row's column contents. render() will truncate indexes higher than the number of columns.
      },
      {...},
      ...
    ],

  Optional Props:
    totalRows: int                  //total number of rows (not the number of rows on a page, but total number)
    getPage: func(pageNum)          //function called on page change
    pageSize: int                   //number of rows per page- defaults to TABLE_PAGE_SIZE if not provided
    page: int                       //current page to display- optional page prop allows for finer control of what page to display, useful when trying to control state of the table from outside of PagedTable
    actionText: string              //action button label. If no label is passed, then action button will not be rendered
    onActionClick: func(row.data)   //function called on button click. function is passed the entire row.data object
    actionAccessLevel: string       //access level to wrap action button. If no access level is provided, access control will not be applied
    onMenuClick: func(row.data)     //function called on click of dropdown menu.
    contextItems: [                 //array of json objects for on-hover context menu
      {
        text: string,               //context menu item label
        func: func(row.data)        //function called on menu item click. function is passed the entire row.data object
      },
      {...},
      ...
    ]
    rowClass: string                // A style class name for adding additional styles or style overrides.

  **** FOR PAGINATION REQUIRED PROPS ARE:
  totalRows: int                  //total number of rows (not the number of rows on a page, but total number)
  getPage: func(pageNum)          //function called on page change

  A optional child component may be passed in to be rendered as an expandable section on row click.
  This section will recieve all data and column text for that row (as "data" and "columns")

  Example Usage:
  <PagedTable
    columns={["Column 1", "Column 2"]}
    rows={
      [
        { data: {id: 1, name: "test" value: 1}, columns: ["Test", "1"] },
        { data: {id: 2, name: "test2" value: 2}, columns: ["Test2", "2"] },
      ]
    }
    getPage={page => this.getPage(page)}
  >
    <ExpandedRow onClick={(data) => this.onDetailClick(data)} />
  </PagedTable>

  See UserLookup.jsx for in-use example

*/

const PagedTable = props => {
  const [pageNum, setPage] = React.useState(0);
  const [selectedRow, setSelected] = React.useState(null);
  const [anchorEl, setContextAnchor] = React.useState(null);
  const [contextRow, setContextRow] = React.useState(null);

  const handleChangePage = (event, newPageNum) => {
    if (typeof props.page === 'undefined') {
      setPage(newPageNum);
    }
    props.getPage(newPageNum);
  };
  const handleClickDropDown = (e, row, isNull) => {
    // e = event
    // row = current row
    // isNull determines to set context to null or not
    e.stopPropagation();
    if (isNull) {
      setContextAnchor(null);
      setContextRow(null);
    } else {
      setContextAnchor(e.currentTarget);
      setContextRow(row);
      if (props.onMenuClick) {
        props.onMenuClick(row.data);
      }
    }
  };
  const handleRowClick = row => {
    if (selectedRow === row) {
      setSelected(null);
    } else {
      setSelected(row);
    }
  };
  const handleClickMenuItem = (e, item, row) => {
    e.stopPropagation();
    item.func(row.data);
    setContextAnchor(null);
    setContextRow(null);
  };
  const handleClickReportsButton = (e, row) => {
    e.stopPropagation();
    props.onActionClick(row.data);
  };
  const checkColumn = column => {
    switch (column) {
      case 'State ACKS':
        return (
          <Tooltip title="This report provides the date that a state return reached the status of Acknowledgement.">
            <InfoIcon fontSize="small" />
          </Tooltip>
        );
      case 'Rejected Returns':
        return (
          <Tooltip title="This report provides a list Returns that were Rejected and the description.">
            <InfoIcon fontSize="small" />
          </Tooltip>
        );
      case 'IRS and State Acknowledgments':
        return (
          <Tooltip title="This report includes returns that require both IRS and State Acknowledgements have been received">
            <InfoIcon fontSize="small" />
          </Tooltip>
        );
      case 'IRS Acks with Phone and Address':
        return (
          <Tooltip title="This report provides a list of all federal Returns acknowledged within a certain date range with phone number and address of the client.">
            <InfoIcon fontSize="small" />
          </Tooltip>
        );
      // eslint-disable-next-line no-duplicate-case
      case 'Rejected Returns':
        return (
          <Tooltip title="This report provides a list Returns that were Rejected and the description.">
            <InfoIcon fontSize="small" />
          </Tooltip>
        );
      case 'RT Fees and Return Status Master Report':
        return (
          <Tooltip title="This report provides a breakdown of the taxpayers who applied for an RT, along with the bank response and dates for preparer’s fees deposited from the IRS to the bank, and then from the bank to the tax preparer.">
            <InfoIcon fontSize="small" />
          </Tooltip>
        );
      // eslint-disable-next-line no-duplicate-case
      case 'Rejected Returns':
        return (
          <Tooltip title="This report provides a list Returns that were Rejected and the description.">
            <InfoIcon fontSize="small" />
          </Tooltip>
        );
      case 'OTC Payments Master Report':
        return (
          <Tooltip title="This report provides a list of clients who have paid their tax preparation fees over the counter (OTC) and method of payment.">
            <InfoIcon fontSize="small" />
          </Tooltip>
        );
      case 'Client Info Report':
        return (
          <Tooltip title="This report provides a list of all client information as entered on a return from the Client Data Screen.">
            <InfoIcon fontSize="small" />
          </Tooltip>
        );
      case 'Client Birth Date List':
        return (
          <Tooltip title="This report provides a list of client birthdays from all returns, along as the information entered on the return.">
            <InfoIcon fontSize="small" />
          </Tooltip>
        );
      default:
        break;
    }
  };
  // Calculate correct column span of expansion row
  const calcColspan = (actionText, children, columns) => {
    let colspan = columns.length;
    if (actionText) {
      colspan++;
    }
    if (children) {
      colspan++;
    }
    return colspan;
  };

  /**
   * Provide the options for using sticky headers styles or not base
   * on whether "getPage" prop exist.
   */
  const tableHeadCellStyleType = classes => {
    if (props.getPage) {
      return { root: classes.pagedTableHeadCell };
    } else if (props.showBorder) {
      return { root: classes.pagedTableStickyHeadCellBorder };
    } else {
      return { root: classes.pagedTableStickyHeadCell };
    }
  };

  /**
   * Provide the options for using sticky headers styles or not base
   * on whether "getPage" prop exist.
   */
  const tableHeadStyleType = classes => {
    if (props.getPage) {
      return null;
    } else {
      return { root: classes.pagedTableHeadStickyHead };
    }
  };

  const { classes } = props;
  const rowClass = props.rowClass ? props.rowClass : '';

  return (
    <Table>
      <TableHead classes={tableHeadStyleType(classes)}>
        <TableRow classes={{ root: classes.pagedTableHeadRow }}>
          {/* Extra table cell for expand/collapse indicator */}
          {props.children && <TableCell classes={{ root: classes.pagedTableCellIcon }} />}
          {props.columns.map((column, index) => {
            return (
              <TableCell classes={tableHeadCellStyleType(classes)} key={'header' + index}>
                {column}
              </TableCell>
            );
          })}
          {/* Extra table cell to account for presence of an action button */}
          {(props.actionText || props.contextMenu) && <TableCell />}
        </TableRow>
      </TableHead>
      {props.rows && (
        <TableBody>
          {/* For each row, iterate through row.columns and render. */}
          {/* eslint-disable-next-line array-callback-return */}
          {props.rows.map((row, rowIdx) => {
            if (row.columns.length !== 0) {
              return (
                <Fragment key={'row' + rowIdx}>
                  <TableRow
                    hover
                    className={'tableRow ' + rowClass}
                    classes={
                      row.data && row.data.italicized
                        ? { root: classes.pagedTableBodyRowItalicized }
                        : { root: classes.pagedTableBodyRow }
                    }
                    selected={row === selectedRow}
                    onClick={
                      props.children
                        ? () => handleRowClick(row)
                        : props.rowAction
                        ? () => props.rowAction(row.data)
                        : null
                    }
                  >
                    {props.children && (
                      <TableCell classes={{ root: classes.pagedTableCellIcon }}>
                        <img
                          src={expandArrow}
                          className={
                            row === selectedRow
                              ? 'tableExpandArrowVisible arrowClicked'
                              : 'tableExpandArrow'
                          }
                        />
                      </TableCell>
                    )}

                    {row.columns
                      .slice(0, props.columns.length) // Truncate items with index higher than number of table columns
                      .map((column, colIdx) => {
                        return (
                          <TableCell
                            classes={
                              props.showBorder
                                ? { root: classes.pagedTableBodyCellBorder }
                                : { root: classes.pagedTableBodyCell }
                            }
                            key={'cell' + colIdx + rowIdx}
                          >
                            {column} {checkColumn(column)}
                          </TableCell>
                        );
                      })}

                    {/* Render action and/or context button if present */}
                    {(props.actionText || props.contextMenu) && (
                      <TableCell align={'right'} classes={{ root: classes.pagedTableBodyCell }}>
                        {props.actionText && props.actionAccessLevel ? (
                          <AccessControl
                            requiredAction="write"
                            accessLevel="impersonate_login"
                            disableOnFalse={true}
                          >
                            <Button
                              id={`btnTableRow${rowIdx}`}
                              onClick={e => {
                                handleClickReportsButton(e, row);
                              }}
                              size="small"
                            >
                              {props.actionText}
                            </Button>
                          </AccessControl>
                        ) : (
                          <Button
                            id={`btnTableRow${rowIdx}`}
                            onClick={e => {
                              handleClickReportsButton(e, row);
                            }}
                            size="small"
                          >
                            {props.actionText}
                          </Button>
                        )}
                        {props.contextMenu && (
                          <Fragment>
                            <Button
                              classes={{
                                root: classes.pagedTableBodyCellContextButton,
                              }}
                              id={`btnTableRowContext${rowIdx}`}
                              className={'tableVerticalMenu'}
                              onClick={e => {
                                handleClickDropDown(e, row, false);
                              }}
                            >
                              <img src={menuVertical} />
                            </Button>

                            <Menu
                              id={`btnTableRowContextMenu${rowIdx}`}
                              anchorEl={anchorEl}
                              open={row === contextRow}
                              onClose={e => {
                                handleClickDropDown(e, row, true);
                              }}
                            >
                              {props.contextMenu.map((item, index) => [
                                <MenuItem
                                  className="contextMenuItem"
                                  id={`btnTableRowContextMenuItem${index}`}
                                  key={index}
                                  onClick={e => {
                                    handleClickMenuItem(e, item, row);
                                  }}
                                >
                                  {item.text}
                                </MenuItem>,
                              ])}
                            </Menu>
                          </Fragment>
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                  {/* Collapsible row rendered from component children */}
                  {props.children && selectedRow === row && (
                    <TableRow
                      classes={
                        row.data.italicized
                          ? {
                              root: classes.pagedTableBodyRowDetailsItalicized,
                            }
                          : { root: classes.pagedTableBodyRowDetails }
                      }
                      selected={true}
                    >
                      <TableCell
                        classes={{ root: classes.pagedTableBodyCell }}
                        colSpan={calcColspan(props.actionText, props.children, props.columns)}
                      >
                        <Collapse in={selectedRow === row} timeout="auto" unmountOnExit>
                          {React.cloneElement(props.children, { ...row })}
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  )}
                </Fragment>
              );
            }
          })}
        </TableBody>
      )}
      {/* Table Pagination - correct behavior is dependent on props passed */}

      {props.getPage && (
        <TableFooter>
          <TableRow>
            <TablePagination
              classes={{
                toolbar: classes.pagedTableFooterPagPadding,
              }}
              count={props.totalRows}
              rowsPerPageOptions={[]}
              rowsPerPage={props.pageSize ? props.pageSize : TABLE_PAGE_SIZE}
              page={typeof props.page === 'undefined' ? pageNum : props.page}
              onChangePage={handleChangePage}
            />
          </TableRow>
        </TableFooter>
      )}
    </Table>
  );
};

PagedTable.propTypes = {
  columns: PropTypes.arrayOf(String).isRequired,
  rows: PropTypes.arrayOf(Object).isRequired,
  totalRows: PropTypes.number,
  getPage: PropTypes.func,
  pageSize: PropTypes.number,
  page: PropTypes.number,
  actionText: PropTypes.string,
  rowAction: PropTypes.func,
  onMenuClick: PropTypes.func,
  onActionClick: PropTypes.func,
  actionAccessLevel: PropTypes.string,
  contextItems: PropTypes.arrayOf(Object),
  italicized: PropTypes.bool,
  showBorder: PropTypes.bool,
};

export default withStyles(styles)(PagedTable);

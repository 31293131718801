export const styles = {
  clientLetterContextMenu: {
    marginLeft: ".5rem",
    minHeight: "35px",
    maxHeight: "35px",
    minWidth: "24px",
    maxWidth: "24px",
    padding: "0"
  },
  clientLetterContextMenuHidden: {
    display: "none"
  },
  clientLetterSectionTitle: {
    borderBottom: "1px solid #ebedf8",
    padding: "0.5rem 1rem",
    height: "1.5rem"
  },
  clientLetterSetupMenuItem: {
    height: "1.5rem"
  },
  clientLetterSubSectionMenuHeading: {
    borderBottom: "1px solid #ebedf8",
    height: "1.5rem"
  },
  clientLetterSetupTitle: {
    marginBottom: "1rem"
  },
  clientLetterSubSectionSetupMenuItem: {
    marginLeft: "2rem"
  },
  clientLetterEntityTab: {
    outline: 'none',
    minWidth: '100px',
    width: '100px',
    fontSize: '20px'
  }
};

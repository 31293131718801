export const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  qrCodeTitle: {
    color: '#1A173B',
    marginLeft: '25px',
    marginRight: '25px',
  },
  qrCodeModal: {
    height: '660px',
    width: '660px',
    padding: '0',
    position: 'relative',
    margin: 'auto',
    textAlign: 'center',
    top: '10%',
  },
  qrCodeModalMobile: {
    width: '500px',
    height: '400px',
    padding: '0',
    position: 'relative',
    margin: 'auto',
    textAlign: 'center',
    top: '0%',
  },
  qrCodeTitleMobile: {
    color: '#1A173B',
    fontFamily: 'Roboto',
    fontSize: '11px',
    marginLeft: '10px',
    marginRight: '10px',
  },
});

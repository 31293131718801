export const styles = {
  tableHeadCellRoot: {
    padding: '0px 20px 0px 20px',
    fontFamily: 'Roboto',
    fontSize: '12px',
    color: '#1A173B',
  },
  tableCellRoot: {
    padding: '0px 20px 0px 20px',
    borderBottom: '0px',
    fontFamily: 'Roboto',
    fontSize: '15px',
    color: '#67727C',
  },
  tableStyle: {
    marginTop: '0.3em',
    marginLeft: '1em',
    marginRight: '5em',
  },
  containerDiv: {
    marginLeft: '3vw',
    marginRight: '3vw',
    marginTop: '0vh',
  },
  manageAncillaryModal: {
    maxHeight: '800px',
    minHeight: '300px',
    height: 'auto',
    width: '908px',
    padding: '0',
    position: 'absolute',
    margin: 'auto',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
};

/**
 * Handles taking in the 'form_load' response from CS and abstracting all the objects into a single array
 * After dumping all data into the Array we follow desktops layout schema and sort by the 'row' property
 *
 * @param {Object} formData is an Array of objects
 * @return entireForm this is the sorted Array of Objects, ready to be mapped into JSX
 */
export const sortObjects = formData => {
  const entireForm = [];

  for (const [key] of Object.entries(formData)) {
    // name comes along with the response - if we spread it will be saved as letters. So as a key/val with name/formName
    if (key === 'name') {
      entireForm.push({ name: formData[key] });
      break;
    } else {
      entireForm.push(...formData[key]);
    }
  }

  // sort the Array of individual objects by their row's -> 0001 0002 0003
  entireForm.sort((a, b) => parseFloat(a.row) - parseFloat(b.row));

  return entireForm;
};

/**
 * filters there to find the given row, and returns ALL the fields that are on the same row -> row="0003"
 *
 * @param {Object} preppedUI This is the entire preppedUI array for a page
 * @param {string} row This is the specific element obj getting passed in to check its row
 * @param {boolean} getAllChildren used to determine if all items of a row should be returns OR only ones with fields
 */
export const getChildrenOfRows = (preppedUI, row, getAllChildren) => {
  // Filtering the objects for the same row attr. If they match they are added to an Array.
  // Further filtering checking to see if it is a Input by checking if a field attr exists
  const numOfChildren = preppedUI.filter(child => {
    if (getAllChildren) {
      return child.row === row ? child : null;
    } else {
      return child.field && child.row === row ? child : null;
    }
  });

  return numOfChildren;
};

/**
 * Handles iterating over the Row of fields to get the individual column size
 *
 * @param {Object} rowOfFields Row of fields
 * @param {string} currentFieldName name of current field we are trying to get the column conversion
 * @returns {number} column number for current field
 */
export const getColumnTotal = (rowOfFields, currentFieldName, fieldType = '') => {
  const max = 63; // desktops max number of columns

  // Create copy of the row of fields, and create a new object containing only the needed data.
  const columnTotals = rowOfFields.map(fieldObj => {
    return { col: parseInt(fieldObj.col), field: fieldObj.field };
  });

  // get material ui column conversion for each field
  for (let i = 0; i < columnTotals.length; i++) {
    const fieldObj = columnTotals[i];

    // desktop layout starts from the left '0' and ends on the right '63' -> '01' '30' -> this means the first fields column is '29', and the second is '33'.
    // checking if we are on the last field iteration, if so we need to use the max variable '63' to get the remainder and assign it.
    fieldObj.col = columnTotals[i + 1]
      ? columnTotals[i + 1].col - fieldObj.col
      : max - fieldObj.col;

    // get percentage of the column from desktops 63 columns, and use that on material UI's 12 column grid.
    fieldObj.col = 12 * (fieldObj.col / max);

    // get value of the percentage and make it whole, and handling rounding up or down.
    // This is crucial for material UI as it needs atleast 12 or less columns exactly.
    fieldObj.col = fieldObj.col < 1 ? Math.ceil(fieldObj.col) : Math.round(fieldObj.col);

    // catch all column breakpoints to keep it within 0-12
    fieldObj.col = fieldObj.col < 1 ? 1 : fieldObj.col > 12 ? 12 : fieldObj.col;

    // If there is only one field in a row - have it take up all columns
    if (columnTotals.length === 1) {
      fieldObj.col = 12;
    }
    // If fieldType is a row of checkboxes and we are at the 4th column
    // we need to force the field column to be 3 instead of 1.
    else if (fieldType === 'checkbox' && i === 3) {
      fieldObj.col = 3;
    }

    // // If it is the current field we are getting the column for, there is no need to continue iterating
    if (fieldObj.field === currentFieldName) {
      return fieldObj.col;
    }
  }
};

/**
 * Generate extra padding for fields so form matches xfm spacing
 *
 * @param {Object} rowOfFields Row of fields
 * @param {string} currentFieldName name of current field we are trying to get the column conversion
 * @returns {object} padding-right style to dictate spacing
 */
export const getPadding = (currentFieldName, preppedUI, row, size) => {
  const rowOfFields = getChildrenOfRows(preppedUI, row, false)
  let paddingMultiplier;

  // Create copy of the row of fields, and create a new object containing only the needed data.
  const rowItems = rowOfFields.map(fieldObj => {
    return { col: parseInt(fieldObj.col), field: fieldObj.field };
  });

  // single line case
  if  (rowItems.length === 1) return null

  for (let i = 0; i < rowItems.length; i++) {
    if (rowItems[i].field === currentFieldName) {
      if (i + 1 === rowItems.length) {
        // we are at the last column, take the rest of the space based on column.
        // greater the column length, the less padding needed
        let padding;
        if (rowItems[i].col >= 50) {
          padding = '10rem';
        } else if (rowItems[i].col >= 25) {
          padding = '20rem';
        } else {
          padding = '40rem';
        }
        return {
          paddingRight: padding,
        };
      } else {
        // first get the difference between the field and its next sibling, this is the width multiplier
        // we then need to subtract the aproximate size of the field, since it is alreay accounted for in the differnce
        // Note- Had to fiddle to find constant numbers that would recreate spacing closest to xfm mappings
        paddingMultiplier = rowItems[i + 1].col - rowItems[i].col;
        const padding = (paddingMultiplier *.85 ) - (size * 0.5 + 7);
        return {
          paddingRight: `${padding}rem`,
        };
      }
    }
  }
  // should never reach this point, but added as failsafe
  return {};
};

/**
 * Handles using the size property to determine what css style to apply to the field
 *
 * @param {number} size used for determining the size of the field
 * @returns {string} string used to fetch the style
 */
export const getInputSize = (size, error = null) => {

  // // 1.9 rem base
  // one character at size 16 font =.5 rem
  const width = size * 0.5 + 4;
  let height = 2.19;

  // if there is an error increase spacing to account for it
  if (error){
    height += 2;
  }

  const style = {
    width: `${width}rem`,
    height: `${height}rem`,
  };
  return style;
};

/**
 * Handles checking the type, correcting pattern, and length modifications to the value
 *
 * @param {string} value e.target.value being passed in to check the length
 * @param {string} type type of the input
 * @param {number} start Index to start on
 * @param {number} max max length wanted for the input
 * @return updated value
 */
export const validateInputPattern = (value, type, start = 0, max = false) => {
  switch (type) {
    case 'Y':
    case 'S':
    case 'I':
    case 'T':
      return doPatternChange(value, /[\D]/g, start, max);
    case 'N':
      return doPatternChange(value, /[^\d.]/g, start, max);
    case 'L':
      return doPatternChange(value, /[^0\d-]/g, start, max);
    case 'C':
      return doPatternChange(value, /[!@#$%^&*()_=+|:'",.?`\d]/g, start, max);
    case 'A':
      return doPatternChange(value, /[^ -\w]/g, start, max);
    case 'a':
      return doPatternChange(value, /[!#$%^&*()=|:'",?`]/g, start, max);
    default:
      console.log('No type catch, check type property');
      break;
  }
};

/**
 * Handles implementing the pattern change
 *
 * @param {string} value e.target.value being passed in to check the length
 * @param {string} pattern regex pattern for updated the value to the wanted criteria
 * @param {number} start Index to start on
 * @param {number} max max length wanted for the input
 * @returns updatedVal inputs value
 */
const doPatternChange = (value, pattern, start, max) => {
  let updatedVal = value.replaceAll(pattern, '');
  if (max) {
    updatedVal = updatedVal.toString().slice(start, max);
  }

  return updatedVal;
};

/**
 * Handles building out the initil dynamic fields to keep track of
 *
 * @param {Object} mappedUI used to get the text property from the dynamic add more button
 * @returns {Array} initialDynamicFields is an Array containing key/vals of the dynamic field sections and their increments
 */
export const buildWizardInitialDynamicFields = mappedUI => {
  const initialDynamicFields = {};
  mappedUI.forEach(obj => {
    if (obj.font === 'LinePrinter') {
      const fields = obj.text.split(',');
      const name = fields.join('');
      initialDynamicFields[name] = ['03'];
    }
  });

  return initialDynamicFields;
};

/**
 * Handles saving the initial dynamic fields tracker to redux
 *
 * @param {Object} initialFields objects of the dynamic initial field objects
 * @returns {Object} dynamicFieldsTracker we send this to redux to save as the initial tracker
 */
export const buildDynamicFieldsTracker = initialFields => {
  const dynamicFieldsTracker = {};

  initialFields.forEach(page => {
    dynamicFieldsTracker[page.name] = {};
    page.forEach(obj => {
      dynamicFieldsTracker[page.name][obj.field.substring(0, 2)] = obj.field.substring(2, 4);
    });
  });
  return dynamicFieldsTracker;
};

/**
 * Handles updating All rows After the given index, to fit in the added fields.
 *
 * @param {Object} form mappedUI used for iterating and update wanted objects
 * @param {string} fieldNames the key for wizardDynamicFields and is a combined string of the wanted field tables
 * @param {number} index used to determine what field rows should be updated
 * @param {string} newIncre the new increment for the wizardDynamicFields -> '04'
 * @returns {Object} form is the updated wizardMappedUI form
 */
export const updateRowProps = (form, fieldNames, index, newIncre) => {
  const names = fieldNames.split(',');

  form.forEach((obj, currentIndex) => {
    // checks to see if we are passed the updated fields, and increment anything below
    if (currentIndex > index) {
      // checking for fields that were just updated, and skipping over, updating the rest
      if (!names.includes(obj.field?.substring(0, 2)) && newIncre !== obj.field?.substring(2, 4)) {
        obj.row = addLeadingZeros(obj.row, 4);
      }
    }
  });
  return form;
};

/**
 * Handles taking in a number/string and adding the leading zero's to follow Desktops layout schema
 *
 * @param {number} num the row property from a field -> '0007'
 * @param {number} size the max length of the return string
 * @returns {string} num is the modified num with leading zeros returned as a string
 */
export const addLeadingZeros = (num, size) => {
  num = parseInt(num) + 1;
  num = num.toString();
  while (num.length < size) num = '0' + num;
  return num;
};

/**
 * Handles getting the index of a given field increment from the wizardDynamicFields
 *
 * @param {string} fieldIncr the field increment in the dynamic fields -> '03', '04'
 * @returns {number} used for the sub increment title
 */
export const getSubIncrement = fieldIncr => {
  // creating an array of strings nums starting at '03' and going up
  const subIncrementConst = [];
  for (let i = 3; i <= 99; i++) {
    subIncrementConst.push(i < 10 ? `0${i}` : `${i}`);
  }

  // passing the dynamic field increment '03' and returning the index to display.
  const subIncrement = subIncrementConst.indexOf(fieldIncr);

  return subIncrement !== -1 ? parseInt(subIncrement) + 1 : '';
};

// External imports
import React from 'react';
import {
  Modal,
  Paper,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Input,
  Button,
  withStyles,
} from '@material-ui/core';
// Styling imports
import { styles } from '~/app/Pages/Returns/css/returnNotes.js';

const ReturnNotesModal = props => {
  const { classes } = props;

  return (
    <Modal open={props.isViewNoteModalOpen} onClose={props.closeNoteModal}>
      <Paper classes={{ root: classes.noteModal }}>
        <Typography variant="title" id="simple-modal-title" style={{ paddingLeft: '1em' }}>
          {props.isEditing ? 'Edit Note' : 'Add Note'}
        </Typography>
        <Paper classes={{ root: classes.gridContainer }}>
          <Grid
            container
            spacing={8}
            alignContent="flex-end"
            justify="flex-end"
            alignItems="baseline"
          >
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel htmlFor="txtBookmarkDescription" shrink>
                  Note
                </InputLabel>
                <Input
                  id="txtBookmarkDescription"
                  disableUnderline
                  multiline={true}
                  style={{ height: '100%' }}
                  rows={8}
                  onChange={props.handleNoteChange}
                  value={props.selectedNote.note}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'right' }}>
              <Button classes={{ root: classes.btnModal }} onClick={props.closeNoteModal}>
                Cancel
              </Button>
              <Button
                classes={{ root: classes.btnModal }}
                onClick={props.isEditing ? props.saveNote : props.addNote}
                disabled={
                  (props.readyForReview && props.isFeederOffice) ||
                  props.selectedNote?.note?.length < 1
                }
              >
                {props.isEditing ? 'Save' : 'Add'}
              </Button>
              {props.isEditing && (
                <Button
                  classes={{ root: classes.btnModal }}
                  onClick={() => props.deleteNote(props.selectedNote)}
                  disabled={props.readyForReview && props.isFeederOffice}
                >
                  Delete
                </Button>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Paper>
    </Modal>
  );
};

export default withStyles(styles)(ReturnNotesModal);

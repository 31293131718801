import React from 'react';
// external imports
import { AppBar, Toolbar, FormControl, InputLabel, Input, Button } from '@material-ui/core';
// redux imports
import { useSelector, useDispatch } from 'react-redux';
import { actions as setupPageActions } from '~/app/redux/setupPages/duck';

const UpsertReferral = props => {
  const dispatch = useDispatch();

  const { isEditingRecord, currentEditRecord } = useSelector(state => ({
    isEditingRecord: state.setupPageState.isEditingRecord,
    currentEditRecord: state.setupPageState.currentEditRecord,
  }));

  const handleInputChange = e => {
    e.persist();
    dispatch(setupPageActions.updateCurrentEditRecord(e));
  };

  const handleUpsert = () => {
    props.handleUserDBUpsert(currentEditRecord);
  };

  return (
    <div>
      <AppBar position="static">
        <Toolbar style={styles.toolbar}>
          {isEditingRecord ? 'Edit ' : 'Add New '}
          Referral
        </Toolbar>
      </AppBar>
      <form styles={styles.form}>
        <div style={{ margin: '2em', textAlign: 'left' }}>
          <div style={styles.sectionHeading}>Referral Information</div>
          <div style={{ marginRight: '1em' }}>
            <FormControl>
              <InputLabel style={styles.labelStyles} shrink>
                Referral Name
              </InputLabel>
              <Input
                id="txtReferralNameUpsertReferralName"
                name="referral"
                value={currentEditRecord.referral}
                onChange={handleInputChange}
                style={styles.inputStyle}
                error={!currentEditRecord.referral || currentEditRecord.referral === ''}
                inputProps={{
                  maxLength: '11',
                }}
                disableUnderline
              />
            </FormControl>
          </div>
        </div>

        <div style={{ textAlign: 'right', margin: '2em' }}>
          <Button
            id="btnCancelAddUUpsertReferral"
            color="primary"
            onClick={() => props.handleCloseUpsertModal()}
            style={styles.button}
          >
            Cancel
          </Button>
          <Button
            id="btnUpsertReferral"
            color="primary"
            style={styles.button}
            onClick={handleUpsert}
            disabled={!(currentEditRecord.referral && currentEditRecord.referral !== '')}
          >
            {isEditingRecord ? 'Save' : 'Add'}
          </Button>
        </div>
      </form>
    </div>
  );
};

const styles = {
  toolbar: {
    backgroundColor: '#444A59',
  },
  sectionHeading: {
    color: '#1A173B',
    fontFamily: 'Roboto',
    fontSize: '21px',
    marginBottom: '1em',
  },
  buttonGroup: {
    textAlign: 'right',
    marginTop: '2em',
  },
  form: {
    margin: '2em',
  },
  groupDropdown: {
    width: '7em',
    marginRight: '2em',
  },
  dropdown: {
    width: '15em',
    display: 'inlineBlock',
  },
  dropdownText: {
    paddingTop: '0.5em',
    paddingBottom: '0.5em',
    color: '#0077FF',
  },
  subject: {
    width: '24em',
  },
  button: {
    width: '9em',
    fontSize: '14px',
    borderRadius: '4px',
    marginLeft: '2em',
  },
  labelStyles: {
    color: '#354052',
    textAlign: 'left',
    fontFamily: 'Roboto',
  },
  inputStyle: {
    width: '20vw',
    height: '3.5vh',
  },
};

export default UpsertReferral;

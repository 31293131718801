// External imports
import React, { Fragment, useEffect, useRef } from 'react';
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  NativeSelect,
  withStyles,
  Grid,
  Tooltip,
} from '@material-ui/core';
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
} from '@material-ui/icons';
import NumberFormat from 'react-number-format';
import InfoIcon from '@material-ui/icons/Info';
// Internal imports
import XlinkAPI from '~/app/api/xlinkAPI';
import { useSetState } from '~/app/Utility/customHooks.js';
import ErrorHelpers from '~/app/errorHelpers.js';
import WebHelpers, { statusOK } from '~/app/webHelpers.js';
import { formatUsername } from '~/app/Utility/general';
import { buildFormAndSend } from '~/app/Pages/NewAccount/helpers/newAccountHelpers.js';
import {
  ACCOUNT_TYPES,
  HIERARCHY_TYPE,
  STATE_LIST_ADDRESS,
  VALID_EMAIL_REGEX,
  VALID_PHONE_NUMBER_REGEX,
  VALID_LOGINID_REGEX,
} from '~/app/constants.js';
// Redux imports
import { useSelector, useDispatch } from 'react-redux';
import { actions as accountCreationActions } from '~/app/redux/modules/accountCreation';
import { actions as appActions } from '~/app/redux/modules/app';
import { actions as cobrandActions } from '~/app/redux/cobrand/duck';
// Styling imports
import { styles } from '../css/accountFormStyles.js';

/**
 * Handles form logic and displaying form UI
 * This form passes its state form back up to CreateAccount
 *
 * @component AccountForm
 * @category CreateAccount
 */
const AccountForm = props => {
  const { classes } = props;
  const dispatch = useDispatch();
  const payload = WebHelpers.getJWTPayload();
  const {
    backFlag,
    drilldownHistory,
    currentView,
    canCobrand,
    newAccountType,
    loginID,
    userID,
  } = useSelector(state => ({
    backFlag: state.accountCreation.backFlag,
    drilldownHistory: state.drilldown.drilldownHistory,
    currentView: state.drilldown.drilldownHistory[state.drilldown.drilldownHistory.length - 1],
    canCobrand: state.cobrand.canCobrand,
    newAccountType: state.accountCreation.type,
    loginID: state.app.loggedInUser.userID,
    userID: state.app.loggedInBasicInfo.user_id,
  }));

  // Display copy office if form is single office
  const isSingleOffice = ACCOUNT_TYPES.EFIN === props.accountType;
  const accountType = props.accountType;

  // Used for Copy Over Settings and rendering elements (jsx)
  const copyCheckboxes = {
    copyAccessLevels: false,
    copyAudit: false,
    copyBilling: false,
    copyBusinessBilling: false,
    copyClientLetters: false,
    copyDefaults: false,
    copyEfiling: false,
    copyPrinting: false,
  };

  const [state, setState] = useSetState({
    form: {
      accountCode: '',
      accessLevel: '',
      acctType: '',
      userID: '',
      cobrander: false,
      cellPhone: '',
      city: '',
      confirmEmail: '',
      EFIN: '',
      emailAddress: '',
      fax: '',
      firmName: '',
      firmAddress: '',
      firstName: '',
      lastName: '',
      loginID: '',
      officePhone: '',
      suiteNum: '',
      stateSelected: '',
      zip: '',
      isFeeder: false,
      accountSelected: '',
      ...copyCheckboxes,
    },
    role: '',
    isAccessLevelValid: false,
    isAccountCodeValid: false,
    isCellPhoneValid: false,
    isEmailValid: false,
    isConfirmEmailValid: false,
    isFirmNameValid: false,
    isEFINValid: false,
    isLoginIDValid: false,
    isZipValid: false,
    accountList: [],
    multiOfficeOptions: [],
    hasFeederOfficePermission: false,
    // Adding variables to hold the screen size values
    windowHeight: undefined,
    windowWidth: undefined,
  });

  const doesEFINExistRef = useRef(false);
  const isFeederRef = useRef(false);

  /** Handles resizing window */
  const handleResize = () =>
    setState({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    });

  // Handles checking if user is currently in a session, if not redirect, otherwise go back a step
  // Handles setting state when component mounts
  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    dispatch(cobrandActions.fetchCobrandingPermission());

    window.onpopstate = () => {
      if (backFlag === 0) {
        props.onClickCancel();
      } else {
        dispatch(accountCreationActions.decrementCreateAccountStep());
      }
    };

    setState({
      form: {
        ...state.form,
        userID: parseInt(loginID, 10),
        cobrander: canCobrand,
        accountCode: payload.account_code,
        accessLevel: accountType ? 'Administrator' : '',
        acctType: accountType,
      },
      role: payload.roles[0].access_role_name,
    });

    // Get account forms
    getAccountList();

    fetchFeederOfficePermission(userID);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.onpopstate = () => {};
    };
  }, []);

  // Handles checking if user is currently in a session, if not redirect, otherwise go back a step
  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    dispatch(cobrandActions.fetchCobrandingPermission());

    window.onpopstate = () => {
      if (backFlag === 0) {
        props.onClickCancel();
      } else {
        dispatch(accountCreationActions.decrementCreateAccountStep());
      }
    };
  }, [backFlag]);

  useEffect(() => {
    // Reset checkbox states back to false if no input selected (clean up)
    if (state.form.accessLevel && !state.form.accountSelected && isSingleOffice) {
      setState({
        form: { ...state.form, ...copyCheckboxes },
      });
    }
  }, [state.form.accountSelected]);

  // Handles building the form and sending it back up to parent
  useEffect(() => {
    // passing current form state, helper func to pass form back to parent
    buildFormAndSend(state.form, props.getForm, drilldownHistory);
  }, [state.form]);

  const fetchIsEFINActive = async EFIN => {
    try {
      const res = await XlinkAPI.getIsEFINActive(EFIN);
      doesEFINExistRef.current = res.data;

      if (res.data === true) {
        dispatch(
          appActions.showSnackbarMessage(`The EFIN '${EFIN}' is already in use`, 'error', 4000, {
            vertical: 'top',
            horizontal: 'center',
          }),
        );
      }

      validateField('', '');
    } catch (err) {
      dispatch(
        appActions.showSnackbarMessage('Failed to fetch EFIN Verification', 'error', 3500, {
          vertical: 'top',
          horizontal: 'center',
        }),
      );
    }
  };

  const fetchFeederOfficePermission = async userID => {
    try {
      const res = await XlinkAPI.getFeederOfficePermission(userID);
      if (statusOK(res, true)) {
        setState({
          hasFeederOfficePermission: res?.data,
        });
      }
    } catch (err) {
      dispatch(
        appActions.showSnackbarMessage(
          'Failed to fetch fetch feeder office permissions',
          'error',
          3500,
          {
            vertical: 'top',
            horizontal: 'center',
          },
        ),
      );
    }
  };

  const getAccountList = async () => {
    const filterParams = {
      loginID: currentView.loginID,
      officeLoginID: 0,
      filterID: 0,
      page: -1,
      perPage: -1,
      filterType: 'all',
      colName: 'company',
      sortOrder: 'asc',
      filterValue1: '',
      filterValue2: '',
      roleGroup: 'office',
    };

    try {
      const res = await XlinkAPI.getDashboardList(filterParams);

      if (statusOK(res, false)) {
        setState({
          accountList: res?.data?.list || [],
        });
      }
    } catch (err) {
      ErrorHelpers.handleError('Failed to get account lists', err);
    }
  };

  /**
   * Handles input change and calling a input val helper
   *
   * @param {Object} e DOMevent used to retrieve the target value and name
   */
  const handleInputChangeValidation = e => {
    const name = e.target.name;
    const value =
      name === 'loginID'
        ? formatUsername(e.target.value) // lowercase and remove special char
        : e.target.value?.toUpperCase();

    // If EFIN is greater than 6 do nothing. If logic needs added to EFIN input, add else logic
    if (name === 'EFIN' && value.length > 6) {
      return null;
    }

    setState({
      form: { ...state.form, [name]: value },
    });
    validateField(name, value);
  };

  /**
   * Handles onChange without validation for checkbox
   *
   * @param {Object} e DOMevent used to retrieve the target value and name
   */
  const handleCheckbox = e => {
    setState({ form: { ...state.form, [e.target.name]: e.target.checked } });
    isFeederRef.current = e.target.checked;
    if (e.target.name === 'isFeeder') {
      validateField('', '');
    }
  };

  /**
   * Function that will be called for checking validation after onChange
   *
   * @param {string} field is the name of the input being changed
   * @param {string} value is the value of an input being changed
   */
  const validateField = (field, value) => {
    let isLoginIDValid = state.isLoginIDValid;
    let isEmailValid = state.isEmailValid;
    let isConfirmEmailValid = state.isConfirmEmailValid;
    let isCellPhoneValid = state.isCellPhoneValid;
    let isFirmNameValid = state.isFirmNameValid;
    let isEFINValid = state.isEFINValid;
    let isAccountCodeValid = state.isAccountCodeValid;
    let isZipValid = state.isZipValid;
    // input is disabled
    const isAccessLevelValid = state.form.accessLevel?.length >= 1;

    switch (field) {
      case 'zip':
        isZipValid = value.trim().toString().length === 5;
        break;
      case 'loginID':
        isLoginIDValid = value.length >= 1 && VALID_LOGINID_REGEX.test(value);
        break;
      case 'emailAddress':
        isEmailValid = VALID_EMAIL_REGEX.test(value);
        break;
      case 'confirmEmail':
        isConfirmEmailValid = VALID_EMAIL_REGEX.test(value) && state.form.emailAddress === value;
        break;
      case 'cellPhone':
        isCellPhoneValid = VALID_PHONE_NUMBER_REGEX.test(value);
        break;
      case 'firmName':
        isFirmNameValid = value.length >= 1;
        break;
      case 'EFIN':
        isEFINValid = value.trim().toString().length === 6;
        break;
      case 'accountCode':
        isAccountCodeValid = value.toString().length >= 1;
        break;
      default:
        break; // No front end validation for field
    }

    setState({
      isAccessLevelValid: isAccessLevelValid,
      isLoginIDValid: isLoginIDValid,
      isEmailValid: isEmailValid,
      isConfirmEmailValid: isConfirmEmailValid,
      isCellPhoneValid: isCellPhoneValid,
      isFirmNameValid: isFirmNameValid,
      isEFINValid: isEFINValid,
      validateAccountCode: isAccountCodeValid,
      isZipValid: isZipValid,
    });

    const isEFINUsingFeeder =
      (doesEFINExistRef.current && isFeederRef.current) ||
      (!doesEFINExistRef.current && !isFeederRef.current);

    // If account type is EFIN, then EFIN input is required, else mo, reseller do not need EFIN
    if (state.form.acctType === ACCOUNT_TYPES.EFIN) {
      if (
        isAccessLevelValid &&
        isLoginIDValid &&
        isEmailValid &&
        isConfirmEmailValid &&
        isCellPhoneValid &&
        isFirmNameValid &&
        isEFINValid &&
        isEFINUsingFeeder
      ) {
        props.setIsFormValid(true);
      } else {
        props.setIsFormValid(false);
      }
    } else if (
      isAccessLevelValid &&
      isLoginIDValid &&
      isEmailValid &&
      isConfirmEmailValid &&
      isFirmNameValid &&
      isCellPhoneValid
    ) {
      props.setIsFormValid(true);
    } else {
      props.setIsFormValid(false);
    }
  };

  /**
   * Handles trimming extra spaces from inputs
   *
   * @param {Object} e DOMevent used to retrieve the target value and name
   */
  const removeExtraSpaces = e => {
    const name = e.target.name;
    let value = e.target.value;
    value = value.replace(/\s\s+/g, ' '); // Replace tabs, new tabs and multiple spacing with single blank space
    let valTrim = value.trim();
    if (name === 'EFIN') {
      for (let i = 0; i < 6 - value.length; i++) {
        valTrim = '0' + valTrim;
      }
    }
    setState({ [name]: valTrim });
  };

  /**
   * Handles displaying a list of options UI fora dropdown
   *
   * @returns {JSX} jsx displays a list of options for a dropdown menu
   */
  const renderStateDropdown = () => {
    // MenuItem Style
    const stateList = STATE_LIST_ADDRESS.map(state => {
      return (
        <option
          id={'optSelect' + state}
          key={state}
          value={state}
          className={classes.menuItemStyle}
        >
          {state}
        </option>
      );
    });

    return (
      <NativeSelect
        id="ddlStateSelectedAddOffice"
        value={state.form.stateSelected ? state.form.stateSelected : ''}
        onChange={e => handleInputChangeValidation(e)}
        input={
          <Input
            name="stateSelected"
            id="state-selected"
            className={classes.validStateStyle}
            disableUnderline
            error={state.form.stateSelected === ''}
          />
        }
      >
        {<option value="" className={classes.menuItemStyle} />}
        {stateList}
      </NativeSelect>
    );
  };

  /**
   * Handles displaying a list of options UI fora dropdown
   *
   * @returns {JSX} jsx displays a list of options for a dropdown menu
   */
  const renderAccountDropdown = () => {
    // MenuItem Style
    const accListOptions = state.accountList.map((item, index) => {
      return (
        <option
          id={`cpyAccount ${item.name}-${index}`}
          key={`${item.name}-${index}`}
          value={item.office_id}
          className={classes.menuItemStyle}
        >
          {item.name}
        </option>
      );
    });
    return (
      <NativeSelect
        id="cpyFromAccountAddOffice"
        value={state.form.accountSelected ? state.form.accountSelected : ''}
        onChange={e => handleInputChangeValidation(e)}
        disabled={state.accountList.length === 0}
        input={
          <Input
            name="accountSelected"
            id="account-selected"
            className={classes.validStateStyle}
            disableUnderline
          />
        }
      >
        {<option value="" className={classes.menuItemStyle} />}
        {accListOptions}
      </NativeSelect>
    );
  };

  // Render jsx helper function for checkbox labels
  const formatCopyCheckboxesLabels = label => {
    return label
      .replace('copy', '') // strip initial copy from label (copyDefaults) => Defaults
      .replace(/([A-Z])/g, ' $1') // add space between upper case
      .replace('Efiling', 'E-Filing') // special case to add hyphen
      .trim(); // remove leading space
  };

  const efinOnBlurHandler = () => {
    if (state.form.EFIN.length === 6) {
      fetchIsEFINActive(state.form.EFIN);

      // anytime we update the EFIN, we need to set the feeder checkbox and ref to false and require the user to re-select
      setState({ form: { ...state.form, isFeeder: false } });
      isFeederRef.current = false;
    }
  };

  return (
    <>
      <Grid container spacing={0} direction="column" alignItems="center" justify="center">
        <Grid item xs={12}>
          <div className={classes.appBarStyle}>
            <form className={classes.formStyle}>
              {/* Need to hide this section if not coming from the Admin panel */}
              {state.role === 'csr' && !drilldownHistory?.length > 1 ? (
                <div id="adminSection">
                  <div className={classes.titleContainerStyle}>
                    <span className={classes.titleStyle}>Administration Info</span>
                  </div>

                  <div className={classes.centerInputsContainer}>
                    <FormControl classes={{ root: classes.formControlRoot }}>
                      <InputLabel
                        className={classes.labelWidthStyle}
                        htmlFor="txtAccountCode"
                        shrink
                      >
                        Account Code
                      </InputLabel>
                      <Input
                        id="txtAccountCode"
                        name="accountCode"
                        value={state.form.accountCode}
                        onChange={e => handleInputChangeValidation(e)}
                        className={classes.xsmallInputStyle}
                        disableUnderline
                        error={state.form.accountCode === ''}
                      />
                    </FormControl>
                    <FormControl classes={{ root: classes.formControlRoot }}>
                      <InputLabel className={classes.labelWidthStyle} htmlFor="txtUserID" shrink>
                        User ID
                      </InputLabel>
                      <NumberFormat
                        id="txtUserID"
                        name="userID"
                        value={state.form.userID}
                        onChange={e => handleInputChangeValidation(e)}
                        className={classes.xsmallInputStyle}
                        disableUnderline
                        customInput={Input}
                        inputProps={{
                          maxLength: '6',
                        }}
                        error={state.form.userID.length !== 6}
                      />
                    </FormControl>
                    <FormControl classes={{ root: classes.formControlRoot }}>
                      <InputLabel
                        className={classes.labelWidthStyleShort}
                        htmlFor="EFIN-selected"
                        shrink
                      >
                        EFIN
                      </InputLabel>
                      <Input
                        id="EFIN-selected"
                        name="EFIN"
                        value={state.form.EFIN}
                        onChange={e => handleInputChangeValidation(e)}
                        className={classes.mediumInputStyle}
                        disableUnderline
                        inputProps={{
                          maxLength: '6',
                        }}
                        customInput={Input}
                        error={
                          !state.isEFINValid || (doesEFINExistRef.current && !state.form.isFeeder)
                        }
                        onBlur={efinOnBlurHandler}
                        type="number"
                      />
                    </FormControl>
                  </div>
                  <div className={classes.centerInputsContainer}>
                    <FormControl fullWidth>
                      <FormControlLabel
                        label="Customer has purchased the option to upload Logo (cobrand)"
                        control={
                          <Checkbox
                            className={classes.checkBoxStyle}
                            icon={
                              <CheckBoxOutlineBlankIcon
                                className={classes.checkBoxIconStyle}
                                color="secondary"
                              />
                            }
                            checkedIcon={
                              <CheckBoxIcon className={classes.checkBoxIconStyle} color="primary" />
                            }
                            id="chkCoBrandSetup"
                            name="cobrander"
                            checked={state.form.cobrander}
                            onChange={handleCheckbox}
                          />
                        }
                      />
                    </FormControl>
                  </div>
                  <br />
                  <Divider className={classes.dividerStyle} />
                </div>
              ) : null}

              <div className={classes.titleContainerStyle}>
                <span className={classes.titleStyle}>Administrator Credentials</span>
              </div>
              <div className={classes.centerInputsContainer}>
                <FormControl classes={{ root: classes.formControlRoot }}>
                  <InputLabel
                    className={classes.labelWidthStyle}
                    required={false}
                    htmlFor="txtLoginIDAddOffice"
                    shrink
                  >
                    Username
                  </InputLabel>
                  <Input
                    id="txtLoginIDAddOffice"
                    name="loginID"
                    value={state.form.loginID}
                    onChange={e => handleInputChangeValidation(e)}
                    className={classes.largeInputStyle}
                    disableUnderline
                    error={!state.isLoginIDValid}
                    onBlur={removeExtraSpaces}
                  />
                </FormControl>
                <FormControl required classes={{ root: classes.formControlRoot }}>
                  <InputLabel
                    className={classes.labelWidthStyle}
                    required={false}
                    htmlFor="txtFirstnameAddOffice"
                    shrink
                  >
                    First Name
                  </InputLabel>
                  <Input
                    id="txtFirstnameAddOffice"
                    name="firstName"
                    value={state.form.firstName}
                    onChange={e => handleInputChangeValidation(e)}
                    className={classes.smallInputStyle}
                    disableUnderline
                    error={state.form.firstName === ''}
                    onBlur={removeExtraSpaces}
                  />
                </FormControl>
                <FormControl required classes={{ root: classes.formControlRoot }}>
                  <InputLabel
                    className={classes.labelWidthStyle}
                    required={false}
                    htmlFor="txtLastnameAddOffice"
                    shrink
                  >
                    Last Name
                  </InputLabel>
                  <Input
                    id="txtLastnameAddOffice"
                    name="lastName"
                    value={state.form.lastName}
                    onChange={e => handleInputChangeValidation(e)}
                    className={classes.smallInputStyle}
                    disableUnderline
                    error={state.form.lastName === ''}
                    onBlur={removeExtraSpaces}
                  />
                </FormControl>
              </div>
              <div className={classes.centerInputsContainer}>
                <FormControl classes={{ root: classes.formControlRoot }}>
                  <InputLabel
                    className={classes.labelWidthStyle}
                    htmlFor="txtEmailAddressAddOffice"
                    shrink
                  >
                    Email
                  </InputLabel>
                  <Input
                    id="txtEmailAddressAddOffice"
                    name="emailAddress"
                    value={state.form.emailAddress}
                    onChange={e => handleInputChangeValidation(e)}
                    className={classes.largeInputStyle}
                    disableUnderline
                    error={!state.isEmailValid}
                  />
                </FormControl>

                <FormControl classes={{ root: classes.formControlRoot }}>
                  <InputLabel
                    className={classes.labelWidthStyle}
                    htmlFor="txtConfirmEmailAddOffice"
                    shrink
                  >
                    Confirm Email
                  </InputLabel>
                  <Input
                    id="txtConfirmEmailAddOffice"
                    name="confirmEmail"
                    value={state.form.confirmEmail}
                    onChange={e => handleInputChangeValidation(e)}
                    className={classes.largeInputStyle}
                    disableUnderline
                    error={!state.isConfirmEmailValid}
                  />
                </FormControl>
              </div>
              <div className={classes.centerInputsContainer}>
                <FormControl classes={{ root: classes.formControlRoot }}>
                  <InputLabel
                    className={classes.labelWidthStyle}
                    required={false}
                    htmlFor="txtCellPhoneAddOffice"
                    shrink
                  >
                    Cell Phone
                  </InputLabel>
                  <NumberFormat
                    id="txtCellPhoneAddOffice"
                    name="cellPhone"
                    value={state.form.cellPhone}
                    onChange={e => handleInputChangeValidation(e)}
                    className={classes.largeInputStyle}
                    format="(###) ###-####"
                    mask="_"
                    customInput={Input}
                    disableUnderline
                    error={!state.isCellPhoneValid}
                  />
                </FormControl>
              </div>
              {/* If cobranding is an option and user is authorized, display UI */}
              {canCobrand &&
              (newAccountType === ACCOUNT_TYPES.RESELLER ||
                newAccountType === ACCOUNT_TYPES.FRANCHISE) &&
              (currentView.role === HIERARCHY_TYPE.SSB ||
                currentView.role === HIERARCHY_TYPE.SB) ? (
                <div>
                  <FormControl fullWidth>
                    <FormControlLabel
                      label="Customer has purchased the option to upload Logo (cobrand)"
                      control={
                        <Checkbox
                          className={classes.checkBoxStyle}
                          icon={
                            <CheckBoxOutlineBlankIcon
                              className={classes.checkBoxIconStyle}
                              color="secondary"
                            />
                          }
                          checkedIcon={
                            <CheckBoxIcon className={classes.checkBoxIconStyle} color="primary" />
                          }
                          id="chkCoBrandSetupReseller"
                          name="cobrander"
                          checked={state.form.cobrander}
                          onChange={handleCheckbox}
                        />
                      }
                    />
                  </FormControl>
                </div>
              ) : null}

              <br />
              <Divider className={classes.dividerStyle} />
              <div className={classes.titleContainerStyle}>
                <span className={classes.titleStyle}>Office Info</span>
              </div>
              <div className={classes.centerInputsContainer}>
                <FormControl classes={{ root: classes.formControlRoot }}>
                  <InputLabel
                    className={classes.labelWidthStyle}
                    htmlFor="txtFirmNameAddOffice"
                    shrink
                  >
                    Office Name
                  </InputLabel>
                  <Input
                    id="txtFirmNameAddOffice"
                    name="firmName"
                    value={state.form.firmName}
                    onChange={e => handleInputChangeValidation(e)}
                    className={classes.xlargeInputStyle}
                    disableUnderline
                    error={!state.isFirmNameValid}
                    onBlur={removeExtraSpaces}
                    inputProps={{ maxLength: 50 }}
                  />
                </FormControl>
                {props.accountType === ACCOUNT_TYPES.EFIN ? (
                  <Fragment>
                    <FormControl classes={{ root: classes.formControlRoot }}>
                      <InputLabel
                        className={classes.labelWidthStyleShort}
                        htmlFor="EFIN-selected"
                        shrink
                      >
                        EFIN
                      </InputLabel>
                      <Input
                        id="EFIN-selected"
                        name="EFIN"
                        value={state.form.EFIN}
                        onChange={e => handleInputChangeValidation(e)}
                        className={classes.mediumInputStyle}
                        disableUnderline
                        inputProps={{
                          maxLength: '6',
                        }}
                        customInput={Input}
                        error={
                          !state.isEFINValid || (doesEFINExistRef.current && !state.form.isFeeder)
                        }
                        onBlur={efinOnBlurHandler}
                        type="number"
                      />
                    </FormControl>
                    {state.hasFeederOfficePermission && (
                      <FormControlLabel
                        classes={{ root: classes.formControlRoot }}
                        control={
                          <Checkbox
                            id="isFeeder"
                            name="isFeeder"
                            checked={state.form.isFeeder}
                            onChange={e => handleCheckbox(e)}
                            disabled={!doesEFINExistRef.current}
                          />
                        }
                        label={'Feeder Office'}
                      />
                    )}
                  </Fragment>
                ) : (
                  <Fragment />
                )}
              </div>
              <div className={classes.centerInputsContainer}>
                <FormControl classes={{ root: classes.formControlRoot }}>
                  <InputLabel
                    className={classes.labelWidthStyle}
                    htmlFor="txtFirmAddressAddOffice"
                    shrink
                  >
                    Office Address
                  </InputLabel>
                  <Input
                    id="txtFirmAddressAddOffice"
                    name="firmAddress"
                    value={state.form.firmAddress}
                    onChange={e => handleInputChangeValidation(e)}
                    className={classes.xlargeInputStyle}
                    disableUnderline
                    error={state.form.firmAddress === ''}
                    onBlur={removeExtraSpaces}
                  />
                </FormControl>
                <FormControl classes={{ root: classes.formControlRoot }}>
                  <InputLabel
                    className={classes.labelWidthStyleShort}
                    htmlFor="txtSuiteNumAddOffice"
                    shrink
                  >
                    Suite #
                  </InputLabel>
                  <Input
                    id="txtSuiteNumAddOffice"
                    name="suiteNum"
                    value={state.form.suiteNum}
                    onChange={e => handleInputChangeValidation(e)}
                    className={classes.mediumInputStyle}
                    disableUnderline
                    customInput={Input}
                    onBlur={removeExtraSpaces}
                  />
                </FormControl>
              </div>
              <div className={classes.centerInputsContainer}>
                <FormControl classes={{ root: classes.formControlRoot }}>
                  <InputLabel className={classes.labelWidthStyle} htmlFor="txtCityAddOffice" shrink>
                    City
                  </InputLabel>
                  <Input
                    id="txtCityAddOffice"
                    name="city"
                    value={state.form.city}
                    onChange={e => handleInputChangeValidation(e)}
                    className={classes.largeInputStyle}
                    disableUnderline
                    error={state.form.city === ''}
                    onBlur={removeExtraSpaces}
                  />
                </FormControl>

                <FormControl classes={{ root: classes.formControlRoot }}>
                  <InputLabel
                    className={classes.labelWidthStyleShort}
                    htmlFor="state-selected"
                    shrink
                  >
                    State
                  </InputLabel>
                  {renderStateDropdown()}
                </FormControl>
                <FormControl classes={{ root: classes.formControlRoot }}>
                  <InputLabel
                    className={classes.labelWidthStyleShort}
                    htmlFor="txtZipcodeAddOffice"
                    shrink
                  >
                    Zip
                  </InputLabel>
                  <NumberFormat
                    id="txtZipcodeAddOffice"
                    name="zip"
                    value={state.form.zip}
                    onChange={e => handleInputChangeValidation(e)}
                    className={classes.smallInputStyle}
                    disableUnderline
                    customInput={Input}
                    error={!state.isZipValid}
                    format="#####"
                  />
                </FormControl>
              </div>
              <div className={classes.centerInputsContainer}>
                <FormControl classes={{ root: classes.formControlRoot }}>
                  <InputLabel
                    className={classes.labelWidthStyle}
                    htmlFor="txtOfficePhoneAddOffice"
                    shrink
                  >
                    Office Phone
                  </InputLabel>
                  <NumberFormat
                    id="txtOfficePhoneAddOffice"
                    name="officePhone"
                    value={state.form.officePhone}
                    onChange={e => handleInputChangeValidation(e)}
                    className={classes.largeInputStyle}
                    format="(###) ###-####"
                    mask="_"
                    disableUnderline
                    customInput={Input}
                  />
                </FormControl>
                <FormControl classes={{ root: classes.formControlRoot }}>
                  <InputLabel
                    className={classes.labelWidthStyle}
                    htmlFor="txtFalNumberAddOffice"
                    shrink
                  >
                    Fax
                  </InputLabel>
                  <NumberFormat
                    id="txtFalNumberAddOffice"
                    name="fax"
                    value={state.form.fax}
                    onChange={e => handleInputChangeValidation(e)}
                    className={classes.largeInputStyle}
                    format="(###) ###-####"
                    mask="_"
                    customInput={Input}
                    disableUnderline
                  />
                </FormControl>
              </div>
              <br />
              {isSingleOffice && (
                <>
                  <Divider className={classes.dividerStyle} />
                  <div className={classes.titleContainerStyle}>
                    <span className={classes.titleStyle}>Copy Over Settings</span>
                    <Tooltip title="Enables existing office configuration to be applied onto the office to be created.">
                      <InfoIcon fontSize="small" />
                    </Tooltip>
                  </div>
                  <div className={classes.centerInputsContainer}>
                    <FormControl classes={{ root: classes.formControlRoot }}>
                      <InputLabel className={classes.labelWidthStyle} htmlFor="account-selected">
                        From Office
                      </InputLabel>
                      {renderAccountDropdown()}
                    </FormControl>
                  </div>
                  <div className={classes.centerInputsContainer}>
                    <span className={classes.subTitleStyle}>Copy Over To Setup Settings:</span>
                  </div>
                  <div className={classes.copyCheckBoxContainer}>
                    {Object.keys(copyCheckboxes).map((keyName, idx) => {
                      if (keyName === 'copyBusinessBilling' && payload.season < 2024) return null;

                      return (
                        <div key={idx} className={classes.centerInputsContainer}>
                          <FormControlLabel
                            label={formatCopyCheckboxesLabels(keyName)}
                            classes={{ root: classes.formControlRoot }}
                            control={
                              <Checkbox
                                color="primary"
                                className={`${classes.checkBoxStyle} ${classes.checkBoxPadding}`}
                                icon={
                                  <CheckBoxOutlineBlankIcon
                                    className={classes.checkboxOutline}
                                    color="secondary"
                                  />
                                }
                                checkedIcon={<CheckBoxIcon color="primary" />}
                                id={keyName}
                                name={keyName}
                                checked={state.form[keyName]}
                                disabled={state.form.accountSelected === ''}
                                onChange={handleCheckbox}
                              />
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </form>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(styles)(AccountForm);

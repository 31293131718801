/**
 * @module mfaMethodHelper
 * @category MFAMethod
 */

/**
 * Handles parsing the email on file and only displaying the first and last letter
 * of the email as well as the domain.
 *
 * @returns {string} The parse email address.
 */
export const parseEmail = userEmail => {
  const email = userEmail || sessionStorage.email;
  // Assuming all users have an email
  const atPos = email.indexOf('@');

  return `${email[0]} ${'*'.repeat(atPos - 2)} ${email.substring(atPos - 1)}`;
};

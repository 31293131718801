import React from 'react';
// Internal imports
import PrinterInputField from '~/app/Components/NewAccount/SingleOffice/PrintSetupComponents/printerInputField.jsx';
// External imports
import { Collapse, IconButton } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess'; // not part of core
import ExpandMore from '@material-ui/icons/ExpandMore';
// Styles imports
import '~/app/Pages/Setup/css/setup.css';
import '~/app/Components/NewAccount/SingleOffice/css/printingSetup.css';

/**
 * Printer Settings Component
 *
 * @component
 * @category Printing Setup
 * @subcategory Printer Settings
 */
const PrinterSetting = props => {
  return (
    <>
      <div style={{ textAlign: 'left', paddingTop: '3vh' }}>
        <div style={{ float: 'left' }}>
          <IconButton
            id="icobtnExpandPrinterSettingsPrintingSetup"
            className="printing-setup-expand-icon"
            onClick={props.handleExpansion}
            aria-expanded={props.expanded}
          >
            {props.expanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </div>
        <div>
          <span className="printing-setup-header">Printer Settings</span>
        </div>
      </div>

      <Collapse in={props.expanded} timeout="auto" unmountOnExit>
        <div style={{ float: 'left', marginLeft: '3vw' }}>
          <div>
            <div className="printing-setup-titleStyleCheckPrinting">Check Printing</div>
          </div>
          <PrinterInputField
            Title={'Adjustment in 1/10th Line Increments'}
            fieldMap={props.fieldMap}
            field={'CHKT'}
            handleInputChange={props.handleInputChange}
          />
          <div style={{ marginTop: '2vh' }}>
            <span className="printing-setup-titleStyleTaxEstimator">
              Tax Estimator-Number of Copies to Print
            </span>
          </div>
          <PrinterInputField
            title="Tax Estimation"
            fieldMap={props.fieldMap}
            field="TEP1"
            handleInputChange={props.handleInputChange}
            style={{ float: 'left' }}
          />
          <PrinterInputField
            title="EF Declaration"
            fieldMap={props.fieldMap}
            field="TEP2"
            handleInputChange={props.handleInputChange}
            style={{ float: 'none', paddingLeft: '14vw' }}
          />
          <PrinterInputField
            title="Bank Application"
            fieldMap={props.fieldMap}
            field="TEP3"
            handleInputChange={props.handleInputChange}
            style={{ float: 'left' }}
          />
          <PrinterInputField
            title="Privacy Letter"
            fieldMap={props.fieldMap}
            field="TEP4"
            handleInputChange={props.handleInputChange}
            style={{ float: 'none', paddingLeft: '14vw' }}
          />
        </div>
      </Collapse>
    </>
  );
};

export default PrinterSetting;

import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { MESSAGE_SENDER_TYPE, MESSAGE_TYPE } from '~/app/constants.js';
import defaultAvatar from '~/images/userProfile.png';
import { Link, withRouter } from 'react-router-dom';
import WebHelpers from '~/app/webHelpers.js';
import SimpleDialog from '#/Common/SimpleDialog.jsx';

import '~/app/Pages/Messages/css/message.css';
import { styles } from '~/app/Pages/Messages/css/message.js';

const Message = React.memo(props => {
  const [showSeasonDialog, setShowSeasonDialog] = useState(false);

  if ((props.message == null || props.message.body == null) && !props.inReturn) {
    return <div style={{ textAlign: 'center' }}>No messages found</div>;
  } else if ((props.message == null || props.message.body == null) && props.inReturn) {
    return <div className="message-none">No messages found</div>;
  }

  const getMessageAvatar = () => {
    if (props.messageType === MESSAGE_TYPE.RECEIVED) {
      return props.message.avatar === '' ? defaultAvatar : props.message.avatar;
    } else {
      return props.senderAvatar === '' ? defaultAvatar : props.senderAvatar;
    }
  };

  const setupMessageHeading = () => {
    if (props.messageType === MESSAGE_TYPE.RECEIVED) {
      return (
        <div>
          <span
            className={
              props.message.message_type_id === MESSAGE_SENDER_TYPE.TEXTLINK
                ? 'message-text-linkLink'
                : 'message-small-message-text'
            }
          >
            {props.inReturn !== true &&
              (props.message.message_type_id === MESSAGE_SENDER_TYPE.TEXTLINK ? (
                payloadSeason === props.message.season ? (
                  <Link classes={{ root: classes.msgReturnProfile }} to="tax-return-profile">
                    <div
                      className="link-text"
                      onClick={() =>
                        props.openReturn(
                          props.message.returnID,
                          props.message.season,
                          props.message.is_business,
                        )
                      }
                    >
                      {seasonDlgMessage}
                    </div>
                  </Link>
                ) : (
                  <div className="link-text" onClick={() => setShowSeasonDialog(true)}>
                    {seasonDlgMessage}
                  </div>
                )
              ) : (
                'From: '
              ))}
          </span>
          <span className="message-sender">
            {props.message.sender
              ? props.message.full_name + ' (' + props.message.sender + ')'
              : props.message.full_name}
          </span>
        </div>
      );
    } else {
      return (
        <div>
          <span className="message-small-message-text">
            {props.message.message_type_id === MESSAGE_SENDER_TYPE.TECH_SUPPORT ||
            props.message.full_name === ''
              ? ''
              : 'To:'}{' '}
          </span>
          <span className="message-sender">
            {props.message.message_type_id === MESSAGE_SENDER_TYPE.TECH_SUPPORT
              ? ''
              : props.message.full_name}
          </span>
        </div>
      );
    }
  };

  let payloadSeason;
  const payload = WebHelpers.getJWTPayload();
  if (typeof payload !== 'undefined') {
    payloadSeason = WebHelpers.getJWTPayload().season;
  }

  const seasonDlgMessage = 'Click here to view the return associated with this message';
  const { classes } = props;

  return (
    <div className={'message-container ' + (props.inReturn ? 'sm-min-height' : '')}>
      <div className="message-content-row row">
        <div className="message-avatar">
          <img src={getMessageAvatar()} className="avatar" />
        </div>
        <span className="message-heading col">
          {setupMessageHeading()}
          <div className="message-title">{props.message.subject}</div>
          <div className="message-small-message-text">{props.message.date}</div>
        </span>
        {props.messageType === MESSAGE_TYPE.RECEIVED && (
          <IconButton
            id="btnDeleteMessage2"
            onClick={() => props.handleDeleteClick()}
            aria-label="delete"
            classes={{ root: classes.msgDeleteBtn }}
          >
            <DeleteIcon />
          </IconButton>
        )}

        {props.message.message_type_id !== MESSAGE_SENDER_TYPE.TECH_SUPPORT &&
          props.message.message_type_id !== MESSAGE_SENDER_TYPE.TEXTLINK &&
          // eslint-disable-next-line eqeqeq
          props.messageType == MESSAGE_TYPE.RECEIVED && (
            <Button
              id="btnReplyToMessage"
              size="small"
              onClick={() => props.handleReplyClick()}
              classes={{ root: classes.messageReplyBtn }}
            >
              Reply
            </Button>
          )}
      </div>
      <div className="message-body row">{props.message.body}</div>
      <SimpleDialog
        open={showSeasonDialog}
        onClose={() => setShowSeasonDialog(false)}
        onConfirm={() => {
          setShowSeasonDialog(false);
          props.switchYearAndFetchReturnProfile(
            props.message.season,
            props.message.returnID,
            props.message.is_business,
          );
          props.history.push({
            pathname: 'tax-return-profile',
          });
        }}
        dialogTitle={'Switch Tax Year?'}
        contentText={
          'The return you are attempting to open was filed for a different tax year than the one you are currently ' +
          ' viewing. Do you want to switch the tax year and view the return?'
        }
      />
    </div>
  );
});

Message.displayName = 'Message';
export default withRouter(withStyles(styles)(Message));

export const styles = {
  gridColumnHeader: {
    width: "12%",
    color: "#1A173B",
    fontFamily: "Roboto",
    fontSize: "12px",
    fontWeight: "normal",
    letterSpacing: "1px",
  },
  gridColumnData: {
    width: "12%",
    color: "#1A173B",
    fontFamily: "Roboto",
    fontSize: "12px",
    fontWeight: "normal",
    letterSpacing: "1px",
    paddingBottom: "5px",
    paddingTop: "5px",
  },
  gridTableHeader: {
    paddingTop: "25px",
    paddingBottom: "15px",
  },
  paperTitle: {
    fontFamily: "Roboto",
    fontSize: "21px",
    letterSpacing: "0.14px",
    color: "#1A173B",
  },
  gridDivider: {
    paddingTop: "10px",
  },
  gridSubTable: {
    marginLeft: "15px",
  },
  parentGridContainer: {
    marginBottom: "100px",
  },
  footerGridContainer: {
    marginTop: "50px",
  },
  tableDivider: {
    paddingBottom: "10px",
  },
  footerDivider: {
    paddingBottom: "10px",
  },
  activeBubbleText: {
    borderRadius: "25px",
    background: "#A6EBC2",
    paddingTop: "3px",
    paddingBottom: "3px",
    paddingLeft: "7px",
    paddingRight: "7px",
    opacity: "35%",
  },
  inActiveBubbleText: {
    borderRadius: "25px",
    background: "#EBEDF8",
    paddingTop: "3px",
    paddingBottom: "3px",
    paddingLeft: "7px",
    paddingRight: "7px",
    opacity: "35%",
  },
};

export const styles = () => ({
  paper: { boxShadow: 'none', backgroundColor: '#FAFBFC' },
  msgContent: {
    textAlign: 'center',
    boxShadow: 'none',
    backgroundColor: '#FAFBFC',
    paddingTop: '2rem',
    width: '100%',
    position: 'absolute',
    left: '50%',
    top: '45%',
    transform: 'translate(-50%, -50%)',
  },
  dialogContent: {
    padding: '0',
  },
});

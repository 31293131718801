import { FETCH_SERVER_STATUS, actions } from "./duck";

import { takeLatest, call, put } from "redux-saga/effects";
import XlinkAPI from "~/app/api/xlinkAPI";
import ErrorHelpers from "~/app/errorHelpers.js";

export function* watchServerStatus() {
  yield takeLatest(FETCH_SERVER_STATUS, fetchServerStatus);
}

export function* fetchServerStatus() {
  try {
    let res = yield call(
      XlinkAPI.simpleGetRequest,
      `${XLINK_PUB_URL}/healthcheck`
    );

    yield put(actions.updatedServerStatus(res.data));
  } catch (error) {
    ErrorHelpers.handleError("Error fetching filtered returns", error);
  }
}

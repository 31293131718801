export const styles = theme => ({
  archiveDocContainer: {
    padding: theme.spacing.unit * 3,
    height: '100%',
    width: '100%',
    minWidth: '600px',
    backgroundColor: 'white',
  },
  archiveHeder: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 6,
    paddingBottom: theme.spacing.unit * 4,
  },
  addDocButton: {
    float: 'right',
    width: '20%',
  },
  modal: {
    top: '50%',
    left: '50%',
    boxShadow: '0 0 20px 0 rgba(0,0,0,0.25)',
    maxWidth: '80vw',
    minHeight: '40vh',
    minWidth: '30vw',
    padding: '0',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
  },
  modalTitle: {
    paddingLeft: theme.spacing.unit * 3,
    color: 'white',
    backgroundColor: '#444A59',
    height: '70px',
    paddingTop: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 2,
  },
  modalContent: {
    margin: theme.spacing.unit * 4,
  },
  cellRoot: {
    borderBottom: 'none',
    fontSize: '14px',
    color: '#67727C',
  },
  cellHeader: {
    color: '#354052',
    borderBottom: 'none',
    fontSize: '14px',
    textAlign: 'left',
  },
  previewButton: {
    height: '30px',
    minHeight: '30px',
    width: '60px',
    minWidth: '60px',
  },
  modalDesc: {
    fontFamily: 'Roboto',
    fontSize: '15px',
    lineHeight: '18px',
    color: '#354052',
  },
  modalSecondaryData: {
    color: '#67727C',
    fontSize: '12px',
    lineHeight: '14px',
    display: 'inline-flex',
  },
  modalSecondaryDataTwo: {
    color: '#67727C',
    fontSize: '12px',
    lineHeight: '14px',
    display: 'inline-flex',
    marginBottom: theme.spacing.unit * 2,
  },
  modalImageContent: {
    overflow: 'auto',
    textAlign: 'center',
    border: '1px solid black',
  },
  docTranspBtn: {
    color: '#0077FF',
    border: 'none',
  },
  modalButtons: {
    textAlign: 'right',
    marginTop: theme.spacing.unit * 2,
  },
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    marginRight: 0,
  },
  toolbarButtonRoot: {
    border: 'none',
    color: '#FFFFFF',
    borderRadius: '4px',
    textTransform: 'capitalize',
    boxShadow: 'none',
    outline: 'none',
  },
  typography: {
    padding: theme.spacing.unit * 3,
  },
  signatureModal: {
    position: 'absolute',
    top: '10%',
    height: '60em',
  },
  styledHeader: {
    background: '#444C59',
    border: 0,
    color: 'white',
    height: 60,
    padding: '0 0px',
    PaperProps: {
      style: {
        backgroundColor: '#fff',
        color: 'black',
      },
    },
  },
  table: {
    minWidth: 500,
  },
});

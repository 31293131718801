// External imports
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { get } from 'lodash';
// Internal imports
import { ACCESS_LEVEL } from '~/app/constants.js';
// Redux imports
import { connect } from 'react-redux';
import { selectors as loginSelector } from '~/app/redux/loginSetup/selectors';

const mapStateToProps = state => {
  return {
    activeAccessLevels: loginSelector.getActiveAccessLevels(state),
  };
};

// NOTE: Use the 'tooltip' property with caution. In order to render the tooltip on false we need to return a single element rather than an array. An easy fix is to wrap the array of
// elements in a div, but this may mess up formatting for some. Be sure to check if the formatting for the element if using the tooltip.

// TODO: Lazy load the tooltip

// Handling one permission and action at a time for now. Can expand to take more than one permission.
// If the parameter, disableOnFalse, is passed a value of true, AccessControl will ignore the alternate view element and apply a disabled attribute to all fields.
const AccessControl = ({
  activeAccessLevels,
  accessLevel,
  requiredAction,
  additionalAccessCheck,
  disableOnFalse,
  children,
  alternateView,
  mustHaveOne,
  tooltip,
}) => {
  const hasAccess = checkUserPermissions(
    activeAccessLevels,
    accessLevel,
    requiredAction,
    additionalAccessCheck,
    mustHaveOne,
  );
  let element = alternateView;
  if (disableOnFalse && !hasAccess) {
    element = renderChildren(children, true);
    if (tooltip.length > 0) {
      return (
        <Tooltip title={tooltip}>
          <span>{element}</span>
        </Tooltip>
      );
    }
  }
  if (hasAccess) {
    element = renderChildren(children, false);
  }

  return element;
};

const renderChildren = (children, disableChildrenOnFalse) => {
  return React.Children.map(children, child => {
    if (!React.isValidElement(child)) {
      // Text embedded within JSX or closing/opening tags.
      return child;
    }
    if (child.props.children) {
      // Clone element while applying the disabled field.
      if (!disableChildrenOnFalse) {
        child = React.cloneElement(child, {
          children: renderChildren(child.props.children, disableChildrenOnFalse),
        });
      } else {
        child = React.cloneElement(child, {
          children: renderChildren(child.props.children, disableChildrenOnFalse),
          disabled: disableChildrenOnFalse,
        });
      }
    } else {
      // No children just return with the element being disabled if applicable
      // If element already has a disabled attribute and it's set to true, skip adding disabled to it.
      if (!get(child, 'props.disabled', false)) {
        child = React.cloneElement(child, { disabled: disableChildrenOnFalse });
      }
    }

    return child;
  });
};

const checkUserPermissions = (
  activeAccessLevels,
  accessLevel,
  requiredAction,
  additionalAccessCheck,
  mustHaveOne,
) => {
  // Check permissions
  if (Array.isArray(accessLevel)) {
    if (mustHaveOne) {
      // Only one access level is required
      return accessLevel.some(val => {
        return accessLevelChecker(activeAccessLevels, val, requiredAction, additionalAccessCheck);
      });
    } else {
      return accessLevel.every(val => {
        return accessLevelChecker(
          // User must have all access levels
          activeAccessLevels,
          val,
          requiredAction,
          additionalAccessCheck,
        );
      });
    }
  } else {
    return accessLevelChecker(
      activeAccessLevels,
      accessLevel,
      requiredAction,
      additionalAccessCheck,
    );
  }
};

const accessLevelChecker = (
  activeAccessLevels,
  accessLevel,
  requiredAction,
  additionalAccessCheck,
) => {
  if (Object.hasOwn(activeAccessLevels, accessLevel)) {
    // Check to see if the requested permission is in the user's active permission list
    if (
      activeAccessLevels[accessLevel].includes(requiredAction) && // Check to see if they have the required action on the permission (e.g Read/Write)
      additionalAccessCheck // Additonal Permission check provided
    ) {
      return true;
    }
  }
  return false;
};

const isValidAccessLevel = (props, propName, componentName) => {
  if (typeof props[propName] !== 'string' && !Array.isArray(props[propName])) {
    return new Error(
      `Invalid prop '${propName}' passed to '${componentName}'. Expected a string or an array of strings.`,
    );
  }
  if (Array.isArray(props[propName])) {
    props[propName].some(val => {
      if (Object.hasOwn(!ACCESS_LEVEL, val.toUpperCase().replace('/', '_'))) {
        return new Error(
          `Invalid prop '${val}' passed to '${componentName}'. '${val}' is not a valid Access Level.`,
        );
      }
      return null;
    });
  } else if (Object.hasOwn(!ACCESS_LEVEL, props[propName].toUpperCase().replace('/', '_'))) {
    return new Error(
      `Invalid prop '${propName}' passed to '${componentName}'. '${props[propName]}' is not a valid Access Level.`,
    );
  }
  return null;
};

AccessControl.propTypes = {
  accessLevel: isValidAccessLevel,
  requiredAction: PropTypes.oneOf(['read', 'write']),
  additionalAccessCheck: PropTypes.bool,
  disableOnFalse: PropTypes.bool,
  mustHaveOne: PropTypes.bool,
  tooltip: PropTypes.string,
  alternateView: PropTypes.element,
};

AccessControl.defaultProps = {
  requiredAction: 'read',
  additionalAccessCheck: true,
  disableOnFalse: false,
  mustHaveOne: false,
  tooltip: '',
  alternateView: <Fragment />,
};

export default connect(mapStateToProps, null)(AccessControl);

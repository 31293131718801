export const styles = () => ({
  paper: {
    height: 'auto',
    boxSizing: 'unset',
    width: 'auto',
    margin: '2rem auto',
    padding: '3rem 5%',
  },
  checkboxInputProps: {
    height: '2rem',
    width: '2rem',
  },
  checkBoxOutlineBlankIcon: {
    fontSize: 24,
    color: '#8FC3FF',
  },
  checkBoxIcon: {
    fontSize: 24,
  },
});

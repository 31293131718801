// External Imports
import React, { useEffect } from 'react';
import {
  Grid,
  Input,
  InputLabel,
  FormControl,
  Button,
  NativeSelect,
  Typography,
  CircularProgress,
  withStyles,
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
// internal imports
import AchForm from './AchForm.jsx';
import PayjunctionLogin from './PayJunctionLogin.jsx';
import XlinkAPI from '~/app/api/xlinkAPI.js';
import ErrorHelpers from '~/app/errorHelpers.js';
import { useSetState } from '~/app/Utility/customHooks';
import { formatDollarAmt } from '~/app/Pages/Payments/helpers/paymentHelpers.js';
// styling imports
import { styles } from '~/app/Pages/Payments/PaymentModal/css/CapturePaymentStyles.js';

const PayJunctionPayment = props => {
  const { classes } = props;
  const [state, setState] = useSetState({
    terminals: [], // list of terminals
    smartTerminals: [], // list of smart terminals
    selectedTerminal: 0, // currently selected terminal- selected smart terminal is in parent component to allow for terminal reset
    terminalType: '', // currently selected terminal- selected smart terminal is in parent component to allow for terminal reset
    apiLogin: '', // API Login
    apiPassword: '', // API Password
    loading: false, // toggle loading spinner
    achRoutingNumber: null, // routing number
    achAccountNumber: null, // Check account Number max length 17
    achAccountType: 'CHECKING', // SAVINGS or CHECKING
    achType: 'PPD', // PPD (Consumer) or (CCD) Business
    achBillingFirstName: '', // REQUIRED for PPD ach Type
    achBillingLastName: '', // REQUIRED for PPD ach Type
    achBillingCompanyName: '', // Required for CCD achType

    // toggles whether or not PayJunction payment options are shown.
    // false: shows payjunction login/connect prompt, true: shows payjunction payment detail fields
    details: false,
    showCredError: false, // toggles an error message for invalid credentials
  });

  // array of errors returned from a payment service error.
  // see payjunction.APIError{} in xlinkcloud, or https://developer.payjunction.com/hc/en-us/articles/218165658-Error-Handling
  const [requestErrors, setRequestErrors] = React.useState([]);

  // amount field value for payjunction payment options. Defaults to the amount due on the return (if present)
  const [amount, setAmount] = React.useState(
    formatDollarAmt(props.returnPaymentInfo.due || '0.00', true),
  );

  // terminal type list
  const terminalTypeList = {
    ach: 'ACH',
    card: 'CARD',
  };

  const getTerminals = async () => {
    try {
      setState({
        loading: true,
      });
      const res = await XlinkAPI.getPayJunctionTerminals();
      if (res) {
        const resp = res.data;
        setState({
          terminals: resp.terminals ? resp.terminals : [],
          smartTerminals: resp.smartTerminals ? resp.smartTerminals : [],
          details: true,
          loading: false,
        });
      }
    } catch (error) {
      setState({
        loading: false,
      });
      if (ErrorHelpers.isStructuredError(error)) {
        if (error.response.status === 401) {
          setState({
            details: false, // Unauthorized, ensure login prompt is shown
          });
        }
      }
    }
  };

  const sendPaymentRequest = async () => {
    try {
      setState({
        loading: true,
      });

      if (amount === '') {
        const errMsg = 'Amount is required';
        setRequestErrors([{ message: errMsg }]);
        setState({
          loading: false,
        });
        return;
      }

      // $15,600.55 -> 1560055
      const amountBase = parseInt(amount.replace(/[$,.]/g, ''));

      if (amountBase < -999999 || amountBase > 999999) {
        const errMsg = 'Amount must be between -$9,999.99 and $9,999.99';
        setRequestErrors([{ message: errMsg }]);
        setState({
          loading: false,
        });
        return;
      }

      let res;

      if (state.terminalType === terminalTypeList.ach) {
        const achTransmission = {
          achRoutingNumber: state.achRoutingNumber,
          achAccountNumber: state.achAccountNumber,
          achAccountType: state.achAccountType,
          achType: state.achType,
          achBillingFirstName: state.achBillingFirstName,
          achBillingLastName: state.achBillingLastName,
          achBillingCompanyName: state.achBillingCompanyName,
        };
        res = await XlinkAPI.requestACHPayment(
          state.selectedTerminal,
          achTransmission,
          amountBase.toString(),
          props.returnID,
          props.returnPaymentInfo.paymentGUID,
        );

        setState({
          loading: false,
        });

        const resp = res.data;
        const paymentResponse = resp.response;
        if (!paymentResponse?.approved) {
          const errMsg =
            'Transaction Failed (' + paymentResponse.code + '): ' + paymentResponse.message;
          setRequestErrors([{ message: errMsg }]);
          return;
        }

        props.setAchTransactionDetails(resp);
        props.setPaymentStage(2);
        props.setIsACHPayment(true);
      } else {
        res = await XlinkAPI.requestSmartTerminalPayment(
          state.selectedTerminal,
          props.selectedSmartTerminal,
          amountBase.toString(),
          props.returnID,
          props.returnPaymentInfo.paymentGUID,
        );

        setState({
          loading: true,
        });
        const resp = res.data;
        props.setRequestPaymentID(resp.requestPaymentId);
        props.setPaymentStage(2);
      }
    } catch (error) {
      setState({
        loading: false,
      });
      if (ErrorHelpers.isStructuredError(error)) {
        console.log(error.response.data);
        setRequestErrors(error.response.data.errors);
      }
    }
  };

  const saveCredentials = async () => {
    setState({
      showCredError: false,
    });
    const credString = state.apiLogin + ':' + state.apiPassword;

    try {
      const response = await XlinkAPI.updatePayJunctionCredentials(btoa(credString));
      if (response) {
        getTerminals();
      }
    } catch (err) {
      setState({
        showCredError: true,
      });
    }
  };

  const handleSubmitPayment = async e => {
    e.preventDefault();
    setRequestErrors([]);
    sendPaymentRequest();
  };

  const handleSubmitLogin = async e => {
    e.preventDefault();
    saveCredentials();
  };

  useEffect(() => {
    getTerminals();
  }, []);

  // rednerErrorMessages renders error messages.
  // See payjunction.APIError{} in xlinkcloud.
  const renderErrorMessages = () => {
    if (requestErrors?.length > 0) {
      return (
        <Grid item xs={12}>
          {requestErrors.map((err, index) => {
            return (
              <Typography variant="body1" color="error" key={'error:' + index} gutterBottom>
                {err.message}
              </Typography>
            );
          })}
        </Grid>
      );
    }
  };

  const renderTerminalList = () => {
    const terminalList = state.terminals.map((terminal, index) => {
      return (
        <option
          className="terminalMenuItem"
          id={`terminalMenuItem${index}`}
          value={index}
          key={terminal.nickName + index}
        >
          {terminal.nickName}
        </option>
      );
    });
    return terminalList;
  };

  const renderSmartTerminalList = () => {
    return state.smartTerminals.map((smartTerminal, index) => {
      return (
        <option
          className="smartTerminalMenuItem"
          id={`smartTerminalMenuItem${index}`}
          value={smartTerminal.smartTerminalId}
          key={smartTerminal.nickName + index}
        >
          {smartTerminal.nickName}
        </option>
      );
    });
  };

  return state.loading ? (
    <Grid container spacing={8} direction="row" alignItems="center" justify="center">
      <Grid item xs={1}>
        <CircularProgress />
      </Grid>
    </Grid>
  ) : state.details ? (
    <form
      onSubmit={e => {
        handleSubmitPayment(e);
      }}
    >
      <Grid
        container
        spacing={8}
        alignContent="flex-start"
        justify="flex-start"
        alignItems="baseline"
      >
        <Grid item xs={6}>
          <FormControl fullWidth required>
            <InputLabel htmlFor="terminal-list" shrink>
              Select Terminal
            </InputLabel>
            <NativeSelect
              id="terminal-list"
              onChange={e => {
                setState({
                  selectedTerminal: state.terminals[e.target.value].terminalId,
                  terminalType: state.terminals[e.target.value].type,
                });
              }}
              disableUnderline={true}
            >
              <option value="" />
              {renderTerminalList()}
            </NativeSelect>
          </FormControl>
        </Grid>
        {state.terminalType === terminalTypeList.ach ? (
          <AchForm
            setState={setState}
            achAccountNumber={state.achAccountNumber}
            achRoutingNumber={state.achRoutingNumber}
            achType={state.achType}
            achBillingFirstName={state.achBillingFirstName}
            achBillingLastName={state.achBillingLastName}
            achBillingCompanyName={state.achBillingCompanyName}
          />
        ) : (
          <Grid item xs={6}>
            <FormControl fullWidth required>
              <InputLabel htmlFor="smart-terminal-list" shrink>
                Select Smart Terminal
              </InputLabel>
              <NativeSelect
                id="smart-terminal-list"
                onChange={e => props.setSelectedSmartTerminal(e.target.value)}
                disableUnderline={true}
              >
                <option value="" />
                {renderSmartTerminalList()}
              </NativeSelect>
            </FormControl>
          </Grid>
        )}

        <Grid item xs={6}>
          <FormControl fullWidth required>
            <InputLabel htmlFor="txttaxamount" shrink>
              Amount
            </InputLabel>
            <NumberFormat
              name="taxamount"
              id="txttaxamount"
              value={amount}
              disableUnderline
              prefix="$"
              fixedDecimalScale={true}
              decimalScale={2}
              customInput={Input}
              onChange={e => setAmount(e.target.value)}
              thousandSeparator={true}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth required>
            <InputLabel shrink>Serial Number</InputLabel>
            <Input
              id="serial-number-input"
              disableUnderline
              disabled
              value={props.returnPaymentInfo.paymentGUID}
            />
          </FormControl>
        </Grid>
        {renderErrorMessages()}
        <Grid classes={{ item: classes.payJunctionPaymentButtonGrid }} item xs={12}>
          <Button
            id="btnCancel"
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => props.onClose()}
            classes={{ root: classes.payJunctionPaymentButton }}
          >
            Cancel
          </Button>
          <Button id="btnContinue" variant="outlined" color="primary" size="small" type="submit">
            Process Payment
          </Button>
        </Grid>
      </Grid>
    </form>
  ) : (
    <PayjunctionLogin
      showCredError={state.showCredError}
      apiLogin={state.apiLogin}
      apiPassword={state.apiPassword}
      handleSubmitLogin={handleSubmitLogin}
      setState={setState}
      onClose={props.onClose}
      shortScreenRez={props.shortScreenRez}
    />
  );
};

export default withStyles(styles)(PayJunctionPayment);

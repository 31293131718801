export const FETCH_SERVER_STATUS = "xlinkonline/serverStatus/serverStatus";
export const UPDATED_SERVER_STATUS =
  "xlinkonline/serverStatus/updatedServerStatus";

// Initial object properties and values to be override once updated.
export const initialState = {
  status: {
    xlinkonline_db: "",
    transmission_db: "",
    mem_usage_mb: {
      alloc: "",
      sys: ""
    }
  }
};

export const actions = {
  updatedServerStatus: status => ({
    type: UPDATED_SERVER_STATUS,
    status
  }),
  fetchServerStatus: () => ({
    type: FETCH_SERVER_STATUS
  })
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case UPDATED_SERVER_STATUS:
      return { ...state, status: action.status };
  }

  return state;
}

import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer, createTransform } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import createRootReducer from './reducers/index';

import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas/index';

import { initialState as RETURN_LIST_STATE } from './returnList/duck';

import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

import { DD_TOKEN } from '~/app/constants';

const history = createBrowserHistory();
const NOT_PERSISTED = 'IntentionallyNotPersisted';

const sagaMiddleware = createSagaMiddleware();

// mutate persisted/rehydrated nested state objects as necessary
const excludeTransform = createTransform(
  (stateToPersist, key) => {
    if (key !== 'overview') {
      return stateToPersist;
    } else {
      return {
        ...stateToPersist,
        drilldownHistory: NOT_PERSISTED,
      };
    }
  },
  (stateToRehydrate, key) => {
    return {
      ...stateToRehydrate,
      drilldownHistory: [],
    };
  },
  {
    whitelist: 'overview',
  },
);

const persistConfig = {
  key: 'root',
  storage,
  transforms: [excludeTransform],
  whitelist: ['overview'], // only overview will be persisted
};

const mockHistoryMiddleware = store => next => action => {
  // if you press back, page reloads without refresh and another history state is pushed
  //  End effect is that the browsers back button "does nothing"
  // TODO: replace when/if routes are used
  // NOTE: iOS 9.1+ has a limit of 100 history.pushState calls per 30seconds so we limit to only location change actions... This can break iPads and has before.
  // if (action.type === "@@router/LOCATION_CHANGE") {
  //   window.history.pushState({}, "#", "#");
  // }
  next(action);
};

// This is not ideal to trigger for EVERY action as the LOGOUT action cleans up the sessionStorage, but then this will repopulate it
const storeLatestDrilldownToken = store => next => action => {
  const ddState = store.getState()?.drilldown;
  if (ddState && ddState.drilldownHistory.length > 1) {
    sessionStorage.setItem(
      DD_TOKEN,
      ddState.drilldownHistory[ddState.drilldownHistory.length - 1].dd_token,
    );
  }
  next(action);
};

const enhancers = [];
const middleware = [
  mockHistoryMiddleware,
  routerMiddleware(history),
  sagaMiddleware,
  storeLatestDrilldownToken,
];

const persistedReducer = persistReducer(persistConfig, createRootReducer(history), {
  returnList: checkTaxReturnsState(),
});

if (ENVIRONMENT !== 'production') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

function checkTaxReturnsState() {
  const savedTaxReturns = JSON.parse(localStorage.getItem('taxReturnListState'));
  return savedTaxReturns !== null ? savedTaxReturns : RETURN_LIST_STATE;
}

/*
    https://redux.js.org/recipes/structuring-reducers/initializing-state
*/
const store = createStore(persistedReducer, compose(applyMiddleware(...middleware), ...enhancers));
const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistor, history };

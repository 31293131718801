/* Modified styles from https://material-ui.com/demos/text-fields/ */
export const themeStyles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  cancelButtonRoot: {
    height: "30px",
    width: "97px",
    border: "0px",
    backgroundColor: "#FFFFFF",
    lineHeight: "0em"
  },
  cssFocused: {},
  cssUnderline: {
    "&:after": {
      borderBottomColor: "#40a9ff"
    }
  },
  typography: {
    padding: theme.spacing.unit * 3
  },
  tableHeadRoot: {
    height: "40px",
    letterSpacing: "0.1px"
  },
  tableHeaderCell: {
    borderBottom: "0px",
    fontSize: "inherit",
    padding: ".5rem 1.5rem",
    top: "0",
    backgroundColor: "#fff",
  },
  remoteinvoiceHeaderTitle: { color: '#fff', padding: '.5rem 0', width: "50%" },

});
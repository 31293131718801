export const styles = {
  setupPageContainer: {
    padding: '1vw 3vw',
  },
  paymentSetupFormContainer: {
    marginLeft: '3rem',
  },
  paymentSetupSaveCredentialsButtonGrid: {
    textAlign: 'left',
    marginTop: '.6rem',
  },
};

import React, { Component } from 'react';
import { connect } from 'react-redux';
// External imports
import {
  AppBar,
  Button,
  Collapse,
  Divider,
  Tabs,
  Tab,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  withStyles,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
// Internal imports
import WebHelpers from '~/app/webHelpers.js';
import errorIcon from '~/images/icons/error_reject.png';
// Redux imports
import { actions as formViewerActions } from '~/app/redux/modules/formViewer.js';
import { actions as remoteSignActions } from '~/app/redux/remoteSign/duck.js';

/* Modified styles from https://material-ui.com/demos/text-fields/ */
const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  buttonRoot: {
    height: '30px',
    width: '97px',
    border: '1px solid #0077FF',
    color: '#0077FF',
    borderRadius: '4px',
    backgroundColor: '#FFFFFF',
    textTransform: 'capitalize',
    lineHeight: '0em',
  },
  cancelButtonRoot: {
    height: '30px',
    width: '97px',
    border: '0px',
    color: '#0077FF',
    borderRadius: '4px',
    backgroundColor: '#FFFFFF',
    textTransform: 'capitalize',
    lineHeight: '0em',
  },
  cssLabel: {
    '&$cssFocused': {
      color: '#40a9ff',
    },
  },
  cssFocused: {},
  cssUnderline: {
    '&:after': {
      borderBottomColor: '#40a9ff',
    },
  },
  tabsRoot: {
    borderBottom: '2px solid #EBEDF8',
  },
  tabsIndicator: {
    backgroundColor: '#1890ff',
  },
  tabRoot: {
    textTransform: 'initial',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    margin: 0,
    fontFamily: ['Roboto'].join(','),
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$tabSelected': {
      color: '#1890ff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  tabSelected: {},
  typography: {
    padding: theme.spacing.unit * 3,
  },
  tableRowRoot: {
    height: '35px',
    letterSpacing: '0.1px',
  },
  tableHeadRoot: {
    height: '40px',
    letterSpacing: '0.1px',
  },
});

const mapDispatchToProps = { ...formViewerActions, ...remoteSignActions };

class ErrorRejectModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDetails: false,
      errorMessage: '',
      rowSelected: null,
      dataSelected: null,
      tabValue: 0,
      rowIndex: 0,
      rowList: [], // List used for keyboard navigation
    };
  }

  componentDidMount() {
    this.setState({
      tabValue: this.props.activeTab,
    });
    this.handleTabChange(null, this.props.activeTab);
    this.bindKeyboardListeners();
  }

  bindKeyboardListeners = () => {
    const _ = this;
    $('#divErrorRejectModal')
      .focus()
      .bind('keydown', function (event) {
        const idx = _.state.rowIndex;
        const rowList = _.state.rowList;
        switch (event.keyCode) {
          case 38:
            if (idx !== 0) {
              const newIdx = idx - 1;
              const selectedRow = rowList[newIdx];
              _.handleRowClick(newIdx, selectedRow);
              $('.modalTabContents').scrollTop((newIdx + 15) * newIdx);
            }
            break;
          case 40:
            if (idx < rowList.length - 1) {
              const selectedRow = rowList[idx + 1];
              _.handleRowClick(idx + 1, selectedRow);
              $('.modalTabContents').scrollTop((idx + 15) * idx);
            }
            break;
          case 13:
            event.preventDefault();
            if (idx !== null) {
              _.props.handleVerify(
                _.state.dataSelected.form + _.state.dataSelected.occur,
                _.state.dataSelected.stmCode,
                _.state.dataSelected.frmFile,
                _.state.dataSelected.field + '00',
                _.state.dataSelected.vfyerror,
                _.state.tabValue,
              );
            }
            break;
        }
      });
  };

  handleRowClick = (index, data) => {
    this.setState({
      rowSelected: data.form + data.occur + data.field + data.vfycode,
      dataSelected: data,
      rowIndex: index,
    });
  };

  handleRowDoubleClick = async (index, data) => {
    await this.handleRowClick(index, data);
    if (this.state.dataSelected !== null) {
      this.props.handleVerify(
        this.state.dataSelected.form + this.state.dataSelected.occur,
        this.state.dataSelected.stmCode,
        this.state.dataSelected.frmFile,
        this.state.dataSelected.field + '00',
        this.state.dataSelected.vfyerror,
        this.state.tabValue,
      );
    }
  };

  handleRowHighlight = data => {
    if (data === this.state.rowSelected) {
      return true;
    }
    return false;
  };

  selectFirstRow = errorList => {
    if (errorList.length !== 0) {
      const firstRow = errorList[0];
      this.setState({
        dataSelected: firstRow,
        rowSelected: firstRow.form + firstRow.occur + firstRow.field + firstRow.vfycode,
        rowList: errorList,
      });
    } else {
      this.setState({
        dataSelected: null,
        rowSelected: null,
        rowList: errorList,
      });
    }
  };

  handleTabChange = (event, value) => {
    switch (value) {
      case 1:
        this.selectFirstRow(this.props.verifyList);
        break;
      case 2:
        this.selectFirstRow(this.props.rejectList);
        break;
      case 0:
        this.selectFirstRow(this.props.importErrorList);
        break;
      case 3:
        this.selectFirstRow(this.props.bankRejectList);
        break;
      case 4:
        this.selectFirstRow(this.props.alertList);
        break;
    }
    this.setState({
      tabValue: value,
    });
  };

  getRejectTable = () => {
    const { classes } = this.props;

    let rejects = this.state.rowList;
    if (rejects == null || rejects === null) {
      rejects = [];
    }

    return (
      <div>
        <Table>
          <TableHead>
            <TableRow classes={{ root: classes.tableHeadRoot }}>
              <TableCell>Package</TableCell>
              <TableCell>Form</TableCell>
              <TableCell>Message</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rejects.map((data, index) => {
              return (
                <TableRow
                  classes={{ root: classes.tableRowRoot }}
                  key={data.form + data.occur + data.field + data.vfycode}
                  hover={true}
                  selected={this.handleRowHighlight(
                    data.form + data.occur + data.field + data.vfycode,
                  )}
                  onClick={() => this.handleRowClick(index, data)}
                  onDoubleClick={() => this.handleRowDoubleClick(index, data)}
                >
                  <TableCell className="noselect" component="th" scope="row">
                    {data.packageName}
                  </TableCell>
                  <TableCell className="noselect">{data.formName}</TableCell>
                  <TableCell className="noselect">{data.value}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  };

  handleSignButton = async () => {
    const payload = WebHelpers.getJWTPayload();
    let printFormName = 'prin17';
    if (payload?.is_business) {
      printFormName = 'prin12';
    } else if (this.props.season >= 2020) {
      printFormName = 'prin18';
    }
    this.props.signatureRequestActiveSet(true);
    this.props.handleCloseFormModal();
    this.props.needingVerifiedSet(false);
    this.props.loadSignaturePage(printFormName);
    this.props.sendSignatureModalSet(true);
    await this.props.handlePrint('03', {}, '');
  };

  getVerifyTable = () => {
    const { classes } = this.props;

    let verifies = this.props.verifyList;
    if (verifies == null || verifies === null) {
      verifies = [];
    }

    return (
      <div>
        <Table>
          <TableHead>
            <TableRow classes={{ root: classes.tableHeadRoot }}>
              <TableCell>Type</TableCell>
              <TableCell>Package</TableCell>
              <TableCell>Form</TableCell>
              <TableCell>Message</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {verifies.map((data, index) => {
              let type = <WarningIcon style={{ color: 'yellow' }} />;
              if (data.fatal) {
                type = <ErrorIcon style={{ color: 'red' }} />;
              }
              return (
                <TableRow
                  classes={{ root: classes.tableRowRoot }}
                  key={data.form + data.occur + data.field + data.vfycode}
                  hover={true}
                  selected={this.handleRowHighlight(
                    data.form + data.occur + data.field + data.vfycode,
                  )}
                  onClick={() => this.handleRowClick(index, data)}
                  onDoubleClick={() => this.handleRowDoubleClick(index, data)}
                >
                  <TableCell className="noselect">{type}</TableCell>
                  <TableCell className="noselect" component="th" scope="row">
                    {data.packageName}
                  </TableCell>
                  <TableCell className="noselect">{data.formName}</TableCell>
                  <TableCell className="noselect">{data.value}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  };

  toggleShowDetails = expand => {
    this.setState({
      showDetails: expand,
    });
  };

  // Handles resetting any state on close for a specific form
  handleClose = verifyType => {
    // always close form modal
    this.props.handleCloseFormModal();

    // any extra info that may need reset based on the current verify type being verified.
    switch (verifyType) {
      case 'requestDocs':
        this.props.resetRMDFormFields();
        this.props.setIsEditingRMD(false);
        this.props.setCurrentRequestStep(0);
        break;
    }
  };

  render() {
    const { classes } = this.props;

    let disabled = true;
    if (this.state.dataSelected !== null) {
      disabled = false;
    }
    let submitButton = <div />;

    // retrieve all fatal errors into an array
    const fatalErrors = this.state.rowList.filter(row => row.fatal === true);
    // If the Print Bank docs error is the only one, allow the user to bypass
    const isOnlyPrintBankDocError =
      fatalErrors.length === 1 && fatalErrors[0].vfycode === '9USX014';

    switch (this.props.verifyType) {
      case 'transmit':
        submitButton = (
          <Button
            id="btnSubmitErrorRejectModal"
            size="small"
            color="primary"
            style={{ marginLeft: '0.5em' }}
            onClick={() => alert('Send Return')}
          >
            Send to IRS
          </Button>
        );
        break;
      case 'printFinal':
        submitButton = (
          <Button
            id="btnSubmitErrorRejectModal"
            size="small"
            color="primary"
            style={{ marginLeft: '0.5em' }}
            onClick={async () => {
              this.props.handleCloseFormModal();
              await this.props.handlePrint('03', {}, '');
            }}
            disabled={this.props.errors > 0}
          >
            Print
          </Button>
        );
        break;
      case 'signReturn':
        submitButton = (
          <Button
            id="btnSubmitErrorRejectModal"
            size="small"
            color="primary"
            style={{ marginLeft: '0.5em' }}
            onClick={() => {
              this.handleSignButton();
            }}
            disabled={this.props.errors > 0 && !isOnlyPrintBankDocError}
          >
            Sign
          </Button>
        );
        break;
      case 'queueReturn':
        submitButton = (
          <Button
            id="btnSubmitErrorRejectModal"
            size="small"
            color="primary"
            style={{ marginLeft: '0.5em' }}
            onClick={() => {
              this.props.handleCloseFormModal();
              this.props.queueReturn();
            }}
          >
            {`Next >>`}
          </Button>
        );
        break;
      default:
        break;
    }
    const detailText =
      typeof this.state.dataSelected !== 'undefined' && this.state.dataSelected !== null
        ? this.state.dataSelected.details
        : '';

    // Escape any double quotes
    detailText.replace('"', "'");

    return (
      <div id="divErrorRejectModal" tabIndex="0">
        <Typography variant="title" id="simple-modal-title" style={{ paddingLeft: '1em' }}>
          <img src={errorIcon} /> Return Errors and Rejects
        </Typography>
        <div>
          <AppBar position="static" style={{ backgroundColor: 'white', color: 'black' }}>
            <Tabs
              value={this.state.tabValue}
              onChange={this.handleTabChange}
              position="static"
              classes={{
                root: classes.tabsRoot,
                indicator: classes.tabsIndicator,
              }}
            >
              <Tab
                label="Import"
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
              />
              <Tab
                label="Verify"
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
              />
              <Tab
                label="Rejects"
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
              />
              <Tab
                label="Bank Rejects"
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
              />
              <Tab
                label="Alerts"
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
              />
            </Tabs>
            {this.state.tabValue === 0 && (
              <div className="modalTabContents">{this.getRejectTable()}</div>
            )}
            {this.state.tabValue === 1 && (
              <div>
                <Typography
                  variant="title"
                  style={{
                    paddingLeft: '1em',
                    paddingTop: '0.5em',
                    paddingBottom: '0.5em',
                  }}
                >
                  {this.props.errors} Errors and {this.props.warnings} Warnings have been found.
                </Typography>
                <div className="modalTabContents">{this.getVerifyTable()}</div>
              </div>
            )}
            {this.state.tabValue === 2 && (
              <div className="modalTabContents">{this.getRejectTable()}</div>
            )}
            {this.state.tabValue === 3 && (
              <div className="modalTabContents">{this.getRejectTable()}</div>
            )}
            {this.state.tabValue === 4 && (
              <div className="modalTabContents">{this.getRejectTable()}</div>
            )}
          </AppBar>
        </div>

        <Collapse in={this.state.showDetails} timeout="auto" unmountOnExit>
          <Typography variant="body2" style={{ paddingLeft: '1em', paddingTop: '.5em' }}>
            Details
          </Typography>
          <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
          <div
            style={{
              paddingLeft: '2rem',
              whiteSpace: 'pre-line',
              maxHeight: '20vh',
              overflowY: 'auto',
            }}
          >
            {typeof this.state.dataSelected !== 'undefined' && this.state.dataSelected !== null
              ? this.state.dataSelected.details
              : ''}
          </div>
        </Collapse>

        <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />

        <div
          className="container"
          style={{
            marginTop: '10px',
            marginRight: '0px',
            textAlign: 'right',
            position: 'relative',
            paddingBottom: '0.5em',
          }}
        >
          <Button
            id="btnCloseErrorRejectModal"
            classes={{ root: classes.cancelButtonRoot }}
            variant="outlined"
            size="small"
            color="primary"
            style={{ marginRight: '0.5em' }}
            onClick={() => this.handleClose(this.props.verifyType)}
          >
            Close
          </Button>
          <Button
            id="btnDetailsErrorRejectModal"
            size="small"
            color="primary"
            style={{ marginRight: '0.5em' }}
            onClick={() => {
              this.state.showDetails ? this.toggleShowDetails(false) : this.toggleShowDetails(true);
            }}
          >
            {this.state.showDetails ? 'Collapse' : 'Details'}
          </Button>
          <Button
            id="btnViewAndFixErrorRejectModal"
            disabled={disabled}
            size="small"
            color="primary"
            onClick={() =>
              this.props.handleVerify(
                this.state.dataSelected.form + this.state.dataSelected.occur,
                this.state.dataSelected.stmCode,
                this.state.dataSelected.frmFile,
                this.state.dataSelected.field + '00',
                this.state.dataSelected.vfyerror,
                this.state.tabValue,
              )
            }
          >
            View &amp; Fix
          </Button>
          {submitButton}
        </div>
        <Dialog
          open={this.props.showVerifyModalSuccessDlg && this.props.verifyType === 'queueReturn'}
          onClose={() => {
            this.props.toggleVerifyModalDialog(false);
          }}
        >
          <DialogTitle>Verify Success</DialogTitle>
          <DialogContent>{'The return has been verified successfully.'}</DialogContent>
          <DialogActions>
            <Button
              id="btnErrorRejectVerifyOK"
              onClick={() => {
                this.props.toggleVerifyModalDialog(false);
              }}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(ErrorRejectModal));

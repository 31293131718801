export const styles = {
  FormControlLabel: {
    marginBottom: '-0.5rem',
  },
  FormHelperText: {
    margin: '0 0 1rem 4.1rem',
  },
  checkboxOutline: {
    color: '#8FC3FF',
  },
};

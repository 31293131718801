import React from 'react';
// Internal imports
import PrintOrderModal from '~/app/Components/NewAccount/SingleOffice/PrintSetupComponents/printOrderModal.jsx';
import PrinterRow from '~/app/Components/NewAccount/SingleOffice/PrintSetupComponents/printerRow.jsx';
import {
  INDIVIDUAL_RETURN_PRINT_FIELD_MATRIX,
  INDIVIDUAL_RETURN_PRINT_FIELD_LIST,
  BUSINESS_RETURN_PRINT_FIELD_MATRIX,
  BUSINESS_RETURN_PRINT_FIELD_LIST,
} from '../Helper/printSetupConstants.js';
import { useSetState } from '~/app/Utility/customHooks';
// External imports
import { Collapse, IconButton, Button } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess'; // not part of core
import ExpandMore from '@material-ui/icons/ExpandMore';
// Styles imports
import '~/app/Components/NewAccount/SingleOffice/css/printingSetup.css';

/**
 * Component that shows Return Printer Setting
 *
 * @component
 * @category Printing Setup
 * @subcategory Return Printer Setting
 */
const ReturnPrinting = props => {
  const [state, setState] = useSetState({
    isPrintOrderModalOpen: false,
  });

  const closePrintOrderModal = () => {
    setState({
      isPrintOrderModalOpen: false,
    });
  };

  return (
    <>
      <div className="printing-header">
        <div style={{ float: 'left' }}>
          <IconButton
            className="printing-setup-expand-icon"
            onClick={props.handleExpansion}
            aria-expanded={props.expanded}
          >
            {props.expanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </div>

        <div className="printing-setup-header">
          {props.isBusiness ? 'Business Return Printing' : '1040 Return Printing'}
        </div>

        <div className="print-order-button">
          <Button
            id={props.isBusiness ? 'bsnPrintOrderBtn' : 'indPrintOrderBtn'}
            color="primary"
            onClick={() => {
              setState({
                isPrintOrderModalOpen: true,
              });
            }}
          >
            Print Order
          </Button>
        </div>
      </div>
      <Collapse in={props.expanded} timeout="auto" unmountOnExit>
        <table className="printing-setup-return-table">
          <thead>
            <tr>
              <th className="printing-setup-row-header">Current Form/Bank App Options</th>
              <th className="printing-setup-row-header">Preparer</th>
              <th className="printing-setup-row-header">Client</th>
              <th className="printing-setup-row-header">E-Filed</th>
              <th className="printing-setup-row-header-center">
                Federal <br />
                (Not E-Filed)
              </th>
              <th className="printing-setup-row-header-center">
                State <br />
                (Not E-Filed)
              </th>
            </tr>
          </thead>
          <tbody>
            {props.isBusiness
              ? BUSINESS_RETURN_PRINT_FIELD_LIST.map((title, index) => {
                  return (
                    <PrinterRow
                      key={title + 'Row'}
                      rowTitle={title}
                      fieldMap={props.fieldMap}
                      fieldList={BUSINESS_RETURN_PRINT_FIELD_MATRIX[index]}
                      handleCheckbox={props.handleCheckbox}
                    />
                  );
                })
              : INDIVIDUAL_RETURN_PRINT_FIELD_LIST.map((title, index) => {
                  return (
                    <PrinterRow
                      key={title + 'Row'}
                      rowTitle={title}
                      fieldMap={props.fieldMap}
                      fieldList={INDIVIDUAL_RETURN_PRINT_FIELD_MATRIX[index]}
                      handleCheckbox={props.handleCheckbox}
                    />
                  );
                })}
          </tbody>
        </table>
        <PrintOrderModal
          isPrintOrderModalOpen={state.isPrintOrderModalOpen}
          closeModal={closePrintOrderModal}
          fieldMap={props.fieldMap}
          isBusiness={props.isBusiness}
          updateFieldMap={props.updateFieldMap}
          saveOrder={props.saveOrder}
        />
      </Collapse>
    </>
  );
};

export default ReturnPrinting;

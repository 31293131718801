import { FETCH_OFFICE_PROFILE, actions } from './duck';

import { takeLatest, call, put } from 'redux-saga/effects';
import XlinkAPI from '~/app/api/xlinkAPI';
import ErrorHelpers from '~/app/errorHelpers.js';

export function* watchOfficeProfile() {
  yield takeLatest(FETCH_OFFICE_PROFILE, fetchOfficeProfile);
}

function* fetchOfficeProfile(payload) {
  try {
    // There is another function in XlinkAPI that makes the same request but without using redux.
    // endpoint is expecting a login id, but default 0 will continue with regular logic
    const res = yield call(XlinkAPI.simpleGetRequest, `${XLINK_API_URL}/office/0`);
    yield put(actions.setOfficeProfile(res.data));
  } catch (error) {
    ErrorHelpers.handleError('Unable to fetch office info', error);
  }
}

export const styles = {
  spinnerContainer: {
    position: 'absolute',
    top: '40%',
    left: '50%',
  },
  spinner: {
    height: '12rem',
    width: '12rem',
    textColor: '#fffff',
    backgroundColor: '#808080',
  },
};

export const headerBarStyles = theme => {
  return {
    textItemStyles: {
      fontSize: '0.8rem',
      paddingTop: '.5rem',
      paddingBottom: '.5rem',
      paddingLeft: '.25rem',
    },
    MenuButton: {
      display: 'flex',
      whiteSpace: 'nowrap',
      margin: theme.spacing.unit,
    },
  };
};

export const styles = {
  headerBarAppContainer: {
    borderBottom: '2px solid #EBEDF8',
    transition: 'box-shadow 0.35s',
  },
  headerBarFormsToolbar: {
    backgroundColor: '#ffffff',
    height: '3.4rem',
  },
  headerBarFormsDivider: {
    marginLeft: '1em',
    marginRight: '1em',
  },
  headerBarFormsPrintArrowDropDown: {
    paddingRight: '0em',
    paddingLeft: '0em',
    color: '#0077FF',
  },
  headerBarFormsHelpNavBtn: {
    marginRight: '0.1em',
    minHeight: '0px',
    borderTopRightRadius: '0',
    borderBottomRightRadius: '0',
  },
  headerBarFormsReturnDropDownNav: {
    borderRadius: 4,
    border: 0,
    height: '2.2rem',
    padding: '0 0px',
    minHeight: '0px',
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
  },
  headerBarFormsReturnMenuList: {
    marginTop: '2rem',
  },
  isShortScreenRezStyle: {
    height: '.01rem',
  },
  backDrop: {
    background: 'rgba(255,255,255, 0.2)',
  },
  helpMenu: {
    top: '4rem',
    right: '0.6rem',
    left: 'auto',
    bottom: 'auto',
    border: '1px solid rgb(204, 204, 204)',
    boxShadow: 'rgb(0 0 0 / 25%) 0px 0px 20px 0px',
    padding: '0',
    maxHeight: '45vh',
    overflow: 'hidden',
  },
};

import React, { Fragment, useEffect } from 'react';
// Internal imports
import { useSetState } from '~/app/Utility/customHooks';
import { tabListDefinitions } from '~app/Pages/Returns/DataModel/FormViewer';
import {
  updateFormNavHeight,
  handleMenuItemHighlight,
  formItemStyleAttached,
  formItemStyle,
  formItemStyleDropdown,
} from './sidebarFormsHelpers.js';
import { VERIFY_MODAL_TABS } from '~/app/constants.js';
import Checkmark from '~/images/icons/icons8-checkmark-48.png';
import menuVertical from '~/images/icons/menu_vertical.png';
import * as Global from '~/app/constants';
// External imports
import {
  Button,
  IconButton,
  Collapse,
  ListItemText,
  ListItemSecondaryAction,
  Menu,
  MenuItem,
  MenuList,
  Typography,
  withStyles,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
// Redux imports
import { useSelector, useDispatch } from 'react-redux';
import { actions as formViewerActions } from '~/app/redux/modules/formViewer.js';
import { actions as returnProfileActions } from '~/app/redux/returnProfile/duck.js';
// Styling imports
import { styles } from '~/app/Pages/WizardMode/components/SideBarForms/css/wizardSidebarForms.js';
import '~/app/Pages/WizardMode/components/SideBarForms/css/wizardSideBarForms.css';

/**
 * Handles building the form list from the return and allowing user to add forms
 *
 * @returns {jsx} returns the form list and add form button
 */
const ReturnFormList = props => {
  const { classes } = props;
  const dispatch = useDispatch();
  const { formList, initialForm } = useSelector(state => ({
    formList: state.returnProfile.formList,
    initialForm: state.returnProfile.initialForm,
  }));

  const [state, setState] = useSetState({
    expandedHeaders: [],
    modalOpen: false,
    contextMenuAnchor: null,
    contextMenuSelected: null,
    onMobile: false,
    sideBarExpanded: true,
    tabFederalDefinition: {},
    activeForm: undefined,
  });

  useEffect(() => {
    // Check screensize
    checkScreenSize();

    tabListDefinitions.forEach((idr, index) => {
      if (idr.label === 'Federal') {
        setState({
          tabFederalDefinition: {
            tabObject: idr,
            index: index,
          },
        });
      }
    });
    updateFormNavHeight();

    // Reset the initalForm to client data when we unmount, this part of code is necessary for use case
    // where user navigates to a tax form directly from a wizard form
    return () => {
      dispatch(returnProfileActions.setInitialForm(Global.FORM_NAMES.CDS, ''));
    };
  }, []);

  useEffect(() => {
    if (props.wizardCurrentStep !== 0 && props.wizardCurrentStep < 9) {
      setState({ activeForm: null });
      dispatch(returnProfileActions.setInitialForm('', ''));
    }
    // If the user has not selected from the sidebar, BUT the user is on step 9, automatically open 'General' for 'Client Data'
    if (props.wizardCurrentStep === 9 && !state.expandedHeaders.length) {
      handleClickOnCollapser('GENERAL');
    }
  }, [props.wizardCurrentStep]);

  // If their is a button that navigates to one of the sidebar forms
  useEffect(() => {
    setState({ activeForm: initialForm });
  }, [initialForm]);

  /** Check screensize and set variables */
  const checkScreenSize = () => {
    // We assume onMobile for any screens 1024 or smaller in width
    if (window.innerWidth < 1025) {
      setState({ onMobile: true, sideBarExpanded: false });
    }
  };

  /**
   * Handles expanding the selected form
   *
   * @param {Number} key index used to determine which form was clicked
   */
  const handleClickOnCollapser = key => {
    const keyIndex = state.expandedHeaders.indexOf(key);
    const newexpandedHeaders = [...state.expandedHeaders];

    if (keyIndex === -1) {
      newexpandedHeaders.push(key);
    } else {
      newexpandedHeaders.splice(keyIndex, 1);
    }

    setState({
      expandedHeaders: newexpandedHeaders,
    });
  };

  /**
   * Handles the context menu being opened and closed
   *
   * @param {Object} e eventDOM current dom element being targeted
   * @param {Object} selected the selected menu
   */
  const handleContextMenuToggle = (e, selected) => {
    setState({
      contextMenuAnchor: e.currentTarget,
      contextMenuSelected: selected,
    });
  };

  /** Handles closing the context menu and setting local state to null */
  const handleContextMenuClose = () => {
    setState({
      contextMenuAnchor: null,
      contextMenuSelected: null,
    });
  };

  /**
   * Handles sending logic based on the form name
   *
   * @param {Object} e eventDOM used to get data of the selected DOM element
   * @param {string} form name of the form selected
   * @param {string} guid Pass guid as formFile for binary attachment items
   */
  const handleSubItemClick = (e, form, guid) => {
    if (form.slice(0, 3) !== 'DOX') {
      form = form.slice(0, 6) + form.slice(8, 9);
      if (form.slice(0, 1) === 'Z') {
        handleSidebarFormClick(e, form, '', '');
      } else {
        handleSidebarFormClick(e, form, 'wks', '');
      }
    } else {
      handleSidebarFormClick(e, form, '', guid);
    }
  };

  /**
   * handles fetching detached document
   *
   * @param {string} docvar used to get document ID
   */
  const handleDetachDocument = docvar => {
    const docid = props.getDocumentIDByVar(docvar);
    props.detachDocument(docid, docvar);
  };

  /**
   * Handles displaying sub form row UI
   *
   * @param {Object} items forms under the parent form
   * @param {Object} grandParent parent form
   * @returns {jsx} sub form row UI
   */
  const formSubRows = (items, grandParent) => {
    if (typeof items === 'undefined' || items == null) {
      return;
    }
    const subFormList = items.map((sub, index) => {
      let removeItem;
      if (sub.var.slice(0, 3) === 'DOX') {
        removeItem = (
          <MenuItem
            onClick={() => {
              handleContextMenuClose();
              handleDetachDocument(sub.var);
            }}
            className={classes.contextItemStyle}
            key={'sidemenuitem' + index}
          >
            Detach Document
          </MenuItem>
        );
      } else {
        removeItem = (
          <MenuItem
            onClick={() => {
              handleContextMenuClose();
              alert('remove ' + sub.var + index);
            }}
            className={classes.contextItemStyle}
            key={'sidemenuitem' + index}
          >
            Remove Worksheet
          </MenuItem>
        );
      }
      let contextItem = <Fragment />;
      if (grandParent !== '00' && grandParent !== 'PF' && grandParent !== 'retn37') {
        contextItem = (
          <ListItemSecondaryAction className="secondaryMenuItem">
            <Button
              aria-owns={state.contextMenuAnchor ? sub.var + index + '-context' : null}
              aria-haspopup="true"
              onClick={e => handleContextMenuToggle(e, sub.var + index + '-context')}
              style={{
                minHeight: '1.5rem',
                maxHeight: '1.5rem',
                minWidth: '0.5rem',
                maxWidth: '0.5rem',
                padding: '0px 8px 0px 8px',
                border: '1px solid #0077FF',
              }}
              disabled={props.assetMode}
            >
              <img src={menuVertical} style={{ height: `1em` }} />
            </Button>
            <Menu
              id={sub.var + index + '-context'}
              anchorEl={state.contextMenuAnchor}
              open={Boolean(state.contextMenuSelected === sub.var + index + '-context')}
              onClose={handleContextMenuClose}
            >
              <MenuItem
                onClick={() => {
                  handleContextMenuClose();
                  props.openAddFormList(
                    state.tabFederalDefinition.tabObject,
                    state.tabFederalDefinition.index,
                  );
                }}
                className={classes.contextItemStyle}
              >
                Add Form
              </MenuItem>
              {removeItem}
            </Menu>
          </ListItemSecondaryAction>
        );
      }
      return (
        <MenuItem
          button
          key={sub.var + index}
          selected={handleMenuItemHighlight(
            state.activeForm,
            sub.var.slice(0, 6) + sub.var.slice(8, 9),
          )}
          id={'sidebar' + sub.var + index}
          onClick={event => handleSubItemClick(event, sub.var, sub.guid)}
          disabled={props.assetMode}
        >
          <ListItemText
            style={{ paddingLeft: '25px' }}
            className="sidebarMenuItem"
            disableTypography
            inset
            primary={
              <Typography
                type="body2"
                className={
                  handleMenuItemHighlight(
                    state.activeForm,
                    sub.var.slice(0, 6) + sub.var.slice(8, 9),
                  )
                    ? classes.selectedSubFormItemStyle
                    : classes.subFormItemStyle
                }
              >
                {sub.desc}
              </Typography>
            }
          />
          {contextItem}
        </MenuItem>
      );
    });
    return subFormList;
  };

  /**
   * Change Sidemenu Step to 9, to display formviewer.
   * Check if form is currently being viewed, if it is then
   * we don't need to reload the form.
   */
  const formNav = (event, form) => {
    props.handleStep(9);
    // don't handleclick if we are on the same form, unless we are opening the reject model.
    if (form.var !== state.activeForm || form.var === Global.FORM_TYPE_VAR.VERIFY_MODAL) {
      handleSidebarFormClick(event, form.var, '', form.frmFile);
    }
  };

  /**
   * Checks if called from expand/collapse listitemicon click, prevents attempting getTaxForm for invalid form names
   *
   * @param {Object} e eventDOM used to get the targeted element's data
   * @param {string} name used to fetch the form
   * @param {string} stmcode ''
   * @param {string} file ''
   */
  const handleSidebarFormClick = (e, name, stmcode, file) => {
    dispatch(returnProfileActions.setInitialForm(name, ''));
    if (e.target.id.includes('Icon') || name.length < 6) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      setState({ activeForm: name });
      switch (name) {
        case 'retn04':
        case 'retn09':
          props.verifyReturn(VERIFY_MODAL_TABS.REJECTS);
          break;
        case 'retn25':
          dispatch(formViewerActions.toggleEventLogModal(true));
          break;
        case 'retn28':
          dispatch(formViewerActions.toggleTaxpassMessageModal(true));
          break;
        case 'form12':
          getTaxForm(name, stmcode, file, '');
          break;
        case 'prin12':
        case 'prin17':
        case 'prin18':
          getTaxForm(name, stmcode, file, '');
          break;
        default:
          getTaxForm(name, stmcode, file, '');
      }
    }
  };

  /**
   * Handles fetching tax form
   *
   * @param {string} name name of the form file
   * @param {string} stmType ''
   * @param {string} frmFile ''
   * @param {string} field the current field being updated
   * @param {boolean} updateFormStack used to determine whether the formstack should be updated
   */
  const getTaxForm = async (name, stmType = '', frmFile = '', field = '') => {
    if (props?.calcAPI) {
      props.calcAPI.ReturnFormLoad(name, stmType, frmFile, field.substring(0, 4));
    }
  };

  /**
   * Handles displaying form row ui
   *
   * @param {Object} items child forms of the parent form
   * @param {Object} parent parent form
   * @returns {jsx} form row UI
   */
  const formRows = (items, parent) => {
    if (typeof items === 'undefined' || items == null) {
      return;
    }
    const formList = items.map((form, index) => {
      const attachedIcon = <img src={Checkmark} style={{ height: '1rem', width: '1rem' }} />;
      let remDisabled = false;
      let isLower = false;
      let isNum = false;
      if (form.var && (!isNaN(form.var.slice(0, 1)) || !isNaN(form.var.slice(1, 2)))) {
        isNum = true;
      }
      if (
        form.var &&
        isNaN(form.var.slice(0, 1)) &&
        form.var.slice(0, 1) === form.var.slice(0, 1).toLowerCase() &&
        isNaN(form.var.slice(1, 2)) &&
        form.var.slice(1, 2) === form.var.slice(1, 2).toLowerCase()
      ) {
        isLower = true;
      }

      if (
        isLower ||
        isNum ||
        (form.var && (form.var.slice(4) === '' || form.var.slice(6) !== ''))
      ) {
        remDisabled = true;
      }
      const contextItem = (
        <ListItemSecondaryAction className="secondaryMenuItem">
          <Button
            aria-owns={state.contextMenuAnchor ? form.var + '-context' : null}
            aria-haspopup="true"
            onClick={e => {
              if (!props.getLockedStatus()) {
                handleContextMenuToggle(e, form.var + '-context');
              }
            }}
            style={{
              minHeight: '1.5rem',
              maxHeight: '1.5rem',
              minWidth: '0.5rem',
              maxWidth: '0.5rem',
              padding: '0px 8px 0px 8px',
              border: '1px solid #0077FF',
            }}
            disabled={props.assetMode}
          >
            <img src={menuVertical} style={{ height: `1em` }} />
          </Button>
          <Menu
            id={form.var + '-context'}
            anchorEl={state.contextMenuAnchor}
            open={Boolean(state.contextMenuSelected === form.var + '-context')}
            onClose={handleContextMenuClose}
          >
            <MenuItem
              onClick={() => {
                if (!props.getLockedStatus()) {
                  handleContextMenuClose();
                  props.openAddFormList(
                    state.tabFederalDefinition.tabObject,
                    state.tabFederalDefinition.index,
                  );
                }
              }}
              classes={classes.contextItemStyle}
            >
              Add Form
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleContextMenuClose();
                props.toggleDeleteDialog(form, 'isDeleteDialogOpen');
              }}
              disabled={remDisabled}
              classes={classes.contextItemStyle}
            >
              Remove Form
            </MenuItem>
            <MenuItem
              className="contextMenuItem"
              onClick={() => {
                handleContextMenuClose();
              }}
              style={{ display: 'none' }}
              disabled={true}
            >
              {' '}
              Select for Print
            </MenuItem>
            <MenuItem
              className="contextMenuItem"
              onClick={() => {
                handleContextMenuClose();
              }}
              style={{ display: 'none' }}
            >
              {' '}
              Help
            </MenuItem>
          </Menu>
        </ListItemSecondaryAction>
      );
      let itemStyle = formItemStyleDropdown;
      if (typeof form.GChildren !== 'undefined') {
        if (form.icon !== '1') {
          // form is not attached
          itemStyle = formItemStyleDropdown;
        }
      } else {
        // form item has no dropdown
        itemStyle = formItemStyleAttached;
        if (form.icon !== '1') {
          itemStyle = formItemStyle;
        }
      }
      return (
        <div key={'list-' + form.var + index}>
          <MenuItem
            button
            className="menuItem"
            key={form.var}
            selected={handleMenuItemHighlight(state.activeForm, form.var)}
            id={'form' + form.var}
            onClick={event => formNav(event, form, props)}
            disabled={props.assetMode}
          >
            {form.icon === '1' ? attachedIcon : ''}
            {typeof form.GChildren !== 'undefined' ? (
              <IconButton
                id={'itemIcon' + form.var}
                className={form.icon !== '1' ? classes.IconStyleNotAttached : classes.IconStyle}
                onClick={() => handleClickOnCollapser('list-' + form.var + index)}
              >
                {state.expandedHeaders.indexOf('list-' + form.var + index) !== -1 ? (
                  <ExpandLess
                    id={'lessIcon' + form.var}
                    style={{ fontSize: '16px', paddingRight: '0px' }}
                  />
                ) : (
                  <ExpandMore
                    id={'moreIcon' + form.var}
                    style={{ fontSize: '16px', paddingRight: '0px' }}
                  />
                )}
              </IconButton>
            ) : (
              ''
            )}
            <ListItemText
              className="sidebarMenuItem"
              disableTypography
              inset
              primary={
                <Typography
                  type="body2"
                  style={
                    handleMenuItemHighlight(state.activeForm, form.var)
                      ? Object.assign({}, itemStyle, { color: '#1A173B' })
                      : itemStyle
                  }
                  className="wizard-sidebar-menu-item"
                >
                  {form.desc}
                </Typography>
              }
              style={{ paddingLeft: '10px' }}
            />
            {contextItem}
          </MenuItem>
          <Collapse
            in={state.expandedHeaders.indexOf('list-' + form.var + index) !== -1}
            timeout="auto"
            unmountOnExit
          >
            {formSubRows(form.GChildren, parent)}
          </Collapse>
        </div>
      );
    });

    const result = [];
    for (let i = 0; i < formList.length; i++) {
      result.push(formList[i]);
    }
    return result;
  };

  /**
   * Handles generating the sidebar UI
   *
   * @returns {jsx} sidebar UI
   */
  const generateSidebar = () => {
    const sidebar = formList.map((category, index) => {
      const keyAndID = category.desc && category.desc !== '' ? category.desc : category.Name;
      let contextItem = <Fragment />;
      if (
        category.var !== '00' &&
        category.var !== 'US' &&
        category.var !== 'PF' &&
        category.var !== 'retn37'
      ) {
        contextItem = (
          <ListItemSecondaryAction className="secondaryMenuItem">
            <Button
              aria-owns={state.contextMenuAnchor ? category.desc + '-context' : null}
              aria-haspopup="true"
              onClick={e => {
                if (!props.getLockedStatus()) {
                  handleContextMenuToggle(e, category.desc + '-context');
                }
              }}
              style={{
                minHeight: '1.5rem',
                maxHeight: '1.5rem',
                minWidth: '0.5rem',
                maxWidth: '0.5rem',
                padding: '0px 8px 0px 8px',
                border: '1px solid #0077FF',
              }}
              disabled={props.assetMode || props.getLockedStatus()}
            >
              <img src={menuVertical} style={{ height: `1em` }} />
            </Button>
            <Menu
              id={category.var + '-context'}
              anchorEl={state.contextMenuAnchor}
              open={Boolean(state.contextMenuSelected === category.desc + '-context')}
              onClose={handleContextMenuClose}
              disabled={props.assetMode || props.getLockedStatus()}
            >
              <MenuItem
                onClick={() => {
                  handleContextMenuClose();
                  props.toggleDeleteDialog(category, 'isPkgDeleteModalOpen');
                }}
                className={classes.contextItemStyle}
              >
                Remove State
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleContextMenuClose();
                  alert('help');
                }}
                className={classes.contextItemStyle}
              >
                Help
              </MenuItem>
            </Menu>
          </ListItemSecondaryAction>
        );
      }
      return (
        <div key={keyAndID + index} id={keyAndID}>
          <MenuItem
            button
            className="menuItem"
            onClick={() => handleClickOnCollapser(keyAndID)}
            selected={handleMenuItemHighlight()}
            disabled={props.assetMode}
            key={'sidemenuitemctx2' + index}
          >
            {state.expandedHeaders.indexOf(keyAndID) !== -1 ? (
              <ExpandLess style={{ fontSize: '16px' }} />
            ) : (
              <ExpandMore style={{ fontSize: '16px' }} />
            )}
            <ListItemText
              style={{ padding: 0 }}
              disableTypography
              inset
              primary={
                <Typography type="body2" className={classes.formHeaderStyle}>
                  {category.desc}
                </Typography>
              }
            />
            {contextItem}
          </MenuItem>
          <Collapse
            in={state.expandedHeaders.indexOf(keyAndID) !== -1}
            timeout="auto"
            unmountOnExit
          >
            {formRows(category.Children, category.var)}
          </Collapse>
        </div>
      );
    });
    return sidebar;
  };

  return (
    <div className="side-bar-forms-content">
      <div className="formList">
        <MenuList component="nav">{generateSidebar()}</MenuList>
      </div>
    </div>
  );
};

export default withStyles(styles)(ReturnFormList);

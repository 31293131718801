import React, { useState } from 'react';
// Internal imports
import WebHelpers from '~/app/webHelpers.js';
// Redux Import
import { useSelector } from 'react-redux';
// External imports
import { Radio, FormControlLabel, RadioGroup, withStyles, Typography } from '@material-ui/core';
// Styling imports
import { styles } from '~/app/Components/Auth/css/switchOfficeYear.js';

/**
 * Handles displaying Office Radio List jsx
 *
 * @Component OfficeRadioList
 */
const OfficeRadioList = props => {
  const { classes } = props;
  const payload = WebHelpers.getJWTPayload();
  const { currentEfin } = useSelector(state => ({
    currentEfin: state.app.loggedInBasicInfo.efin_id,
  }));

  const [efinID, setEfinID] = useState(currentEfin.toString());

  /**
   * Handles office selection change
   *
   * @param {Object} e eventDOM use to get the value of an input
   */
  const handleChange = e => {
    setEfinID(e.target.value);
    props.onOfficeChange(e.target.value);
  };

  /**
   * Handles creating label for specific radio button
   *
   * @param {Object} office info used to create label
   * @returns {string} label for radio button
   */
  const getOfficeLabel = office => {
    return (
      <>
        <div>
          <Typography inline variant="subtitle2">
            {'Office Name: '}
          </Typography>
          {office.company_name}
        </div>
        <div>
          <Typography inline variant="subtitle2">
            {'EFIN: '}
          </Typography>
          {office.efin}
        </div>
        <div>
          <Typography inline variant="subtitle2">
            {'Address: '}
          </Typography>
          {office.office_address ? office.office_address : '(No Address on file)'}
        </div>
      </>
    );
  };

  /** Handles creating  radio button for each office */
  const getRadioButtons = () =>
    payload.offices.map(office => {
      const officeIDString = office.efin_id.toString();
      return (
        <FormControlLabel
          key={office.efin_id}
          value={officeIDString}
          onChange={handleChange}
          control={<Radio checked={officeIDString === efinID} />}
          className={classes.label}
          label={getOfficeLabel(office)}
        />
      );
    });

  /** Handles setting Active or Inactive office depending on the active office */
  const getStatusLabels = () =>
    payload.offices.map(office => {
      const isActiveOffice = office.efin_id === currentEfin;
      if (isActiveOffice) {
        return (
          <label
            key={`label-${office.efin_id}`}
            className={`${isActiveOffice && classes.activeOffice} ${classes.smallLabels}`}
          >
            Active Account
          </label>
        );
      }
      return null;
    });

  const statusLabels = getStatusLabels();

  return (
    <RadioGroup value={efinID}>
      {getRadioButtons().map((rb, idx) => {
        return [rb, statusLabels[idx]];
      })}
    </RadioGroup>
  );
};

export default withStyles(styles)(OfficeRadioList);

import axios from 'axios';
import { ACCESS_TOKEN, REFRESH_TOKEN, DD_TOKEN } from '~/app/constants';

/**
 * @module WebHelpers
 */

export default class WebHelpers {
  static getAuthHeaders = () => {
    return WebHelpers.getAuthHeadersFor(undefined);
  };

  static getAuthHeadersFor = token => {
    if (token === undefined) {
      token = sessionStorage.getItem(ACCESS_TOKEN); // try to get it again
    }
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    const ddToken = sessionStorage.getItem(DD_TOKEN);
    if (ddToken && ddToken !== '') {
      headers = {
        ...headers,
        Drilldown: `${ddToken}`,
      };
    }
    return {
      headers: headers,
    };
  };

  static cleanupSession = () => {
    sessionStorage.removeItem(DD_TOKEN);
    sessionStorage.removeItem(ACCESS_TOKEN);
  };

  static cleanupLocalStorage = () => {
    localStorage.removeItem(REFRESH_TOKEN);
  };

  static cleanupDrilldown = () => {
    sessionStorage.removeItem(DD_TOKEN);
  };

  static revokeDrilldownToken = (revokeAll = false, limit = 1) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/revoke/drilldown`,
          { revokeAll: revokeAll, limit: limit },
          WebHelpers.getAuthHeaders(),
        )
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  };

  static getXlinkCloudVersion = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${XLINK_PUB_URL}/version`)
        .then(v => {
          return resolve(v.data);
        })
        .catch(err => {
          return reject(err);
        });
    });
  };

  static getJWTPayload = jwt => {
    if (!jwt) {
      jwt = sessionStorage.getItem(ACCESS_TOKEN);
    }
    if (!jwt) return undefined;
    const tokenParts = jwt.split('.');
    if (tokenParts.length === 3) {
      // ensure token can be decoded
      const middle = tokenParts[1].replace(/-/g, '+').replace(/_/g, '/');
      const payload = JSON.parse(atob(middle));
      let isSuperuser = false;
      if (payload.roles !== undefined && payload.hierarchy_type_id !== undefined) {
        isSuperuser =
          payload.roles[0].access_role_name === 'superuser' && payload.hierarchy_type_id === 1;
      }
      payload.is_tech_support_and_impersonating = false; // Hacky way of determining if tech support.
      payload.is_super_user = isSuperuser;
      if (payload.impersonation_info) {
        payload.impersonation_info.season = payload.season;
        payload.impersonation_info.impersonating = true;
        payload.impersonation_info.is_tech_support_and_impersonating =
          payload.hierarchy_type_id === 50 || payload.hierarchy_type_id === 60; // 50 Is the tech support hierarchy type. 60 is temp tech support;
        payload.impersonation_info.is_super_user = payload.is_super_user;
        return payload.impersonation_info;
      } else {
        return payload;
      }
    }
    return undefined;
  };

  static getDrilldownToken = () => {
    const token = sessionStorage.getItem(DD_TOKEN);
    if (token !== null) {
      return token;
    }
    return undefined;
  };

  static getJWT = () => {
    return sessionStorage.getItem(ACCESS_TOKEN);
  };

  static jsonContentType = () => {
    return { 'Content-Type': 'application/json;charset=UTF-8' };
  };

  static urlEncodedContentType = () => {
    return { 'Content-Type': 'application/x-www-form-urlencoded' };
  };
}

export function isValidDate(dateString) {
  // First check for the pattern
  if (!/^\d{4}-\d{1,2}-\d{1,2}$/.test(dateString)) return false;
  // Parse the date parts to integers
  const parts = dateString.split('-');
  const day = parseInt(parts[2], 10);
  const month = parseInt(parts[1], 10);
  const year = parseInt(parts[0], 10);

  // Check the ranges of month and year
  if (year < 1000 || year > 3000 || month === 0 || month > 12) return false;

  const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) monthLength[1] = 29;

  // Check the range of the day
  return day > 0 && day <= monthLength[month - 1];
}

export function isValidCalcServerDate(dateString) {
  // First check for the pattern
  if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) return false;
  // Parse the date parts to integers
  const parts = dateString.split('/');
  const day = parseInt(parts[1], 10);
  const month = parseInt(parts[0], 10);
  const year = parseInt(parts[2], 10);

  // Check the ranges of month and year
  if (year < 1000 || year > 3000 || month === 0 || month > 12) return false;

  const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) monthLength[1] = 29;

  // Check the range of the day
  return day > 0 && day <= monthLength[month - 1];
}
export function getDate(withTimeString = false) {
  const d = new Date();
  let month = d.getMonth() + 1; // getMonth() returns 0-11. 0 = january
  let day = d.getDate();
  const year = d.getFullYear();
  let formattedDate = '';
  if (month.toString().length === 1) {
    month = '0' + month.toString();
  }
  if (day.toString().length === 1) {
    day = '0' + day.toString();
  }
  formattedDate = month + '/' + day + '/' + year;
  return withTimeString ? formattedDate + ' ' + d.toTimeString().substring(0, 8) : formattedDate;
}

export const uuid4 = a => {
  return a
    ? (a ^ ((Math.random() * 16) >> (a / 4))).toString(16)
    : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, uuid4);
};

// TODO --> Maybe pull this out of an iframe and put it in a modal instead. Like Document Archive.
export function displayPrint(
  data,
  downloadIcon,
  type = 'application/pdf',
  title = 'PDF Document',
  prompt = 'Download Document',
  filename = 'document',
) {
  const myWindow = window.open('', 'MsgWindow', 'width=1000, height=900, toolbar=0, titlebar=no');
  if (!myWindow || myWindow.closed || typeof myWindow.closed === 'undefined') {
    // The popup has been blocked. We should return until it's enabled.
    return;
  }

  const docURIEncoded = encodeURIComponent(data);
  myWindow.addEventListener(
    'load',
    myWindow.document.write(`
  <html>
  <body>
      <div style="text-align: right; margin-left:0px; margin-right:30px; height:70px; background-color:#FFFFFF;">
          <button
              style="outline:none; display:inline-block; padding: 0; border-radius:4px; height:35px; width:auto; background-color:#FFFFFF; font-size:18px; color:#1A163C; margin-left:0px; margin-right:570px; margin-top:12px; border:none;">
              ${title}
          </button>
          <a style="text-decoration: none; box-shadow:none; border:none;" href="data:${type};base64,${docURIEncoded}"
              download=${filename}><img style="display:inline-block; vertical-align:-5px; height:20px; width:20px; margin-top:5px;"
                  src=${downloadIcon}>
          </a>
          <a style="text-decoration: none; box-shadow:none; border:none;" href="data:${type};base64,${docURIEncoded}"
              download=${filename}>
              <button id="pdfDocViewerDownloadBtn" style="display:inline-block; padding: 0; border-radius:4px; height:35px; width:130px; background-color:#FFFFFF; color:#0077FF; margin-left:6px; margin-top:12px; border:none; outline:none;">
                      ${prompt}
              </button>
          </a>
          <button id="pdfDocViewerCloseBtn" style="outline:none; padding:0em 1em 0em 1em; display:inline-block; width:auto; height:35px; border-radius:4px; color:#FFFFFF; background-color:#0077FF; font-family:"
              Roboto"; font-size:15px;" onclick="window.close();">Close
          </button>
      </div>
      <div style="text-align: center">
          <table>
              <tr>
                  <td><object width="950" height="750" id="pdf" data="data:${type};base64,${docURIEncoded}"
                          type="${type}"></object></td>
              </tr>
          </table>
      </div>
  </body>
  </html>`),
  );
}

// formatPhoneNumber formats a string of len 10 to format (###) ###-####
export function formatPhoneNumber(unfPhoneNum) {
  const phoneNumberFormat = /^(\d{3})(\d{3})(\d{4})$/;
  return unfPhoneNum.replace(phoneNumberFormat, '($1) $2-$3');
}

export function moneyFormatter(amt, isFromCalcServer = false) {
  // Calcserver feeds us monetary values in 'decimal format' i.e. -> 2301 = $23.01
  if (isFromCalcServer) {
    amt = (amt / 100).toString();
  }

  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amt);
}

export function customTooltip(tooltipModel) {
  // Tooltip Element
  let tooltipEl = document.getElementById('barchart-horizontal-tooltip');
  let hideTooltip = false;
  // Create element on first render
  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.id = 'barchart-horizontal-tooltip';
    tooltipEl.innerHTML = '<table></table>';
    document.body.appendChild(tooltipEl);
  }

  // Hide if no tooltip
  if (tooltipModel.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }
  function getBody(bodyItem) {
    return bodyItem.lines;
  }

  // apply logic to specific tooltips
  // if (tooltipModel.body) {
  //   tooltipModel.body.map(getBody).forEach(function (body, i) {
  //     if (body === 'Total Returns') {
  //     }
  //   });
  // }

  // Set caret Position
  tooltipEl.classList.remove('above', 'below', 'no-transform', 'hide-this-tooltip-please');
  if (tooltipModel.yAlign) {
    tooltipEl.classList.add(tooltipModel.yAlign);
  } else {
    tooltipEl.classList.add('no-transform');
  }

  tooltipEl.classList.add('arrow_box');

  // Set Text
  if (tooltipModel.body) {
    const titleLines = tooltipModel.title || [];
    const bodyLines = tooltipModel.body.map(getBody);
    let innerHtml = `<thead style="font-size: 20px; font-family: Roboto;" >`;
    titleLines.forEach(function (title) {
      innerHtml += '<tr><th>' + title + '</th></tr>';
    });
    innerHtml += `</thead><tbody style="font-size: 14px; font-family: Roboto; color: #8A96A0; ">`;

    bodyLines.forEach(function (body, i) {
      if (body === 'Total Returns') {
        hideTooltip = true;
      }
      const colors = tooltipModel.labelColors[i];
      let style = 'background:' + colors.backgroundColor;
      style += '; border-color:' + colors.borderColor;
      style += '; border-width: 2px';
      const span = '<span style="' + style + '"></span>';
      innerHtml += '<tr><td>' + span + body + '</td></tr>';
    });
    innerHtml += '</tbody>';

    const tableRoot = tooltipEl.querySelector('table');
    tableRoot.innerHTML = innerHtml;
    if (hideTooltip) {
      tooltipEl.classList.add('hide-this-tooltip-please');
    }
  }

  // `this` will be the overall tooltip
  const position = this._chart.canvas.getBoundingClientRect();
  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.position = 'absolute';
  tooltipEl.style.left =
    position.x + window.pageXOffset + tooltipModel.caretX - tooltipModel.width / 2 - 5 + 'px';
  tooltipEl.style.top =
    position.top + window.pageYOffset + tooltipModel.y + tooltipModel.height - 25 + 'px';

  // tooltipEl.style.fontFamily = TOOLTIP_FONT_FAMILY;
  // tooltipEl.style.fontSize = TOOLTIP_FONT_SIZE;
  tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
  tooltipEl.style.padding = '10px';
  tooltipEl.style.pointerEvents = 'none';
  tooltipEl.style.textAlign = 'center';
}

export function PluginRoundedBarChart(isSingle = false) {
  // eslint-disable-next-line no-undef
  Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
    draw() {
      const { ctx } = this._chart;
      const vm = this._view;
      const idx = this._datasetIndex;
      const MAX_IDX = this._chart.config.data.datasets.length - 1;
      const START_IDX = 0;
      let { borderWidth } = vm;
      let left;
      let right;
      let top;
      let bottom;
      let signX;
      let signY;
      let borderSkipped;
      let radius = 0;

      // If radius is less than 0 or is large enough to cause drawing errors a max
      //      radius is imposed. If cornerRadius is not defined set it to 0.

      let { cornerRadius } = this._chart.config.options;
      if (cornerRadius < 0 || (idx !== MAX_IDX && idx !== START_IDX)) {
        cornerRadius = 0;
      }

      if (typeof cornerRadius === 'undefined') {
        cornerRadius = 0;
      }

      if (!vm.horizontal) {
        // bar
        left = vm.x - vm.width / 2;
        right = vm.x + vm.width / 2;
        top = vm.y;
        bottom = vm.base;
        signX = 1;
        signY = bottom > top ? 1 : -1;
        borderSkipped = vm.borderSkipped || 'bottom';
      } else {
        // horizontal bar
        left = vm.base;
        right = vm.x;
        top = vm.y - vm.height / 2;
        bottom = vm.y + vm.height / 2;
        signX = right > left ? 1 : -1;
        signY = 1;
        borderSkipped = vm.borderSkipped || 'left';
      }

      // Canvas doesn't allow us to stroke inside the width so we can
      // adjust the sizes to fit if we're setting a stroke on the line
      if (borderWidth) {
        // borderWidth shold be less than bar width and bar height.
        const barSize = Math.min(Math.abs(left - right), Math.abs(top - bottom));
        borderWidth = borderWidth > barSize ? barSize : borderWidth;
        const halfStroke = borderWidth / 2;
        // Adjust borderWidth when bar top position is near vm.base(zero).
        const borderLeft = left + (borderSkipped !== 'left' ? halfStroke * signX : 0);
        const borderRight = right + (borderSkipped !== 'right' ? -halfStroke * signX : 0);
        const borderTop = top + (borderSkipped !== 'top' ? halfStroke * signY : 0);
        const borderBottom = bottom + (borderSkipped !== 'bottom' ? -halfStroke * signY : 0);
        // not become a vertical line?
        if (borderLeft !== borderRight) {
          top = borderTop;
          bottom = borderBottom;
        }
        // not become a horizontal line?
        if (borderTop !== borderBottom) {
          left = borderLeft;
          right = borderRight;
        }
      }

      ctx.beginPath();
      ctx.fillStyle = vm.backgroundColor;
      ctx.strokeStyle = vm.borderColor;
      ctx.lineWidth = borderWidth;

      // Corner points, from bottom-left to bottom-right clockwise
      // | 1 2 |
      // | 0 3 |
      const corners = [
        [left, bottom],
        [left, top],
        [right, top],
        [right, bottom],
      ];

      // Find first (starting) corner with fallback to 'bottom'
      const borders = ['bottom', 'left', 'top', 'right'];
      let startCorner = borders.indexOf(borderSkipped, 0);
      if (startCorner === -1) {
        startCorner = 0;
      }

      function cornerAt(index) {
        return corners[(startCorner + index) % 4];
      }

      // Draw rectangle from 'startCorner'
      let corner = cornerAt(0);
      ctx.moveTo(corner[0], corner[1]);

      for (let i = 1; i < 4; i += 1) {
        corner = cornerAt(i);
        let nextCornerId = i + 1;
        if (nextCornerId === 4) {
          nextCornerId = 0;
        }

        const width = corners[2][0] - corners[1][0];
        const height = corners[0][1] - corners[1][1];
        const x = corners[1][0];
        const y = corners[1][1];

        radius = cornerRadius;

        // Fix radius being too large
        if (radius > Math.abs(height) / 2) {
          radius = Math.floor(Math.abs(height) / 2);
        }
        if (radius > Math.abs(width) / 2) {
          radius = Math.floor(Math.abs(width) / 2);
        }

        if (height < 0) {
          // Negative values in a standard bar chart
          const xTl = x;
          const xTr = x + width;
          const yTl = y + height;
          const yTr = y + height;

          const xBl = x;
          const xBr = x + width;
          const yBl = y;
          const yBr = y;

          // Draw
          ctx.moveTo(xBl + radius, yBl);
          ctx.lineTo(xBr - radius, yBr);
          ctx.quadraticCurveTo(xBr, yBr, xBr, yBr - radius);
          ctx.lineTo(xTr, yTr + radius);
          ctx.quadraticCurveTo(xTr, yTr, xTr - radius, yTr);
          ctx.lineTo(xTl + radius, yTl);
          ctx.quadraticCurveTo(xTl, yTl, xTl, yTl + radius);
          ctx.lineTo(xBl, yBl - radius);
          ctx.quadraticCurveTo(xBl, yBl, xBl + radius, yBl);
        } else if (width < 0) {
          // Negative values in a horizontal bar chart
          const xTl = x + width;
          const xTr = x;
          const yTl = y;
          const yTr = y;

          const xBl = x + width;
          const xBr = x;
          const yBl = y + height;
          const yBr = y + height;

          // Draw
          ctx.moveTo(xBl + radius, yBl);
          ctx.lineTo(xBr - radius, yBr);
          ctx.quadraticCurveTo(xBr, yBr, xBr, yBr - radius);
          ctx.lineTo(xTr, yTr + radius);
          ctx.quadraticCurveTo(xTr, yTr, xTr - radius, yTr);
          ctx.lineTo(xTl + radius, yTl);
          ctx.quadraticCurveTo(xTl, yTl, xTl, yTl + radius);
          ctx.lineTo(xBl, yBl - radius);
          ctx.quadraticCurveTo(xBl, yBl, xBl + radius, yBl);
        } else {
          let rightRadius = 0;
          let leftRadius = 0;
          if (START_IDX === MAX_IDX) {
            leftRadius = radius;
            rightRadius = radius;
          } else if (idx === MAX_IDX) {
            rightRadius = radius;
          } else if (idx === START_IDX) {
            leftRadius = radius;
          }
          // Positive Value

          // Right top corner
          ctx.lineTo(x + width - rightRadius, y);
          ctx.quadraticCurveTo(x + width, y, x + width, y + rightRadius);
          // Right bottom corner
          ctx.lineTo(x + width, y + height - rightRadius);
          ctx.quadraticCurveTo(x + width, y + height, x + width - rightRadius, y + height);
          // Left bottom corner
          ctx.lineTo(x + leftRadius, y + height);
          ctx.quadraticCurveTo(x, y + height, x, y + height - leftRadius);
          // Left top corner
          ctx.lineTo(x, y + leftRadius);
          ctx.quadraticCurveTo(x, y, x + leftRadius, y + 1);
        }
      }

      ctx.fill();
      if (borderWidth) {
        ctx.stroke();
      }
    },
  });
}

export function handleSuddenCloseWindow(e) {
  if (ENVIRONMENT !== 'local' && ENVIRONMENT !== 'development') {
    e.preventDefault();
    // Browser: IE
    const confirmationMessage = '';
    // Browser: Chrome versions 34+
    e.returnValue = confirmationMessage;
    // Browser: Chrome versions <34
    return confirmationMessage;
  }
}

/**
 * Checks if the response status is 200 and that data is defined if response shouldReturnData === true
 *
 * Or checks if the response status is 200 if response shouldReturnData === false
 *
 * Set shouldReturnData to false if you know the response will not be returning data
 * Example: statusOK(response, false)
 *
 * If response should return data there is no need to set shouldReturnData
 * Example: statusOK(response)
 *
 * @function
 * @param {Object} response API Response.
 * @param {boolean} shouldReturnData Check to see if response should return a value.
 * @returns {boolean} true / false
 */
export const statusOK = (response, shouldReturnData = true) => {
  const { status, data } = { ...response };

  if (response && data && shouldReturnData && status === 200 && typeof data !== 'undefined') {
    return true;
  } else if (!shouldReturnData && status === 200) {
    return true;
  } else return false;
};

export const getPortalURL = (season, append = '') => {
  const pseason = season % 100;
  const domain = window.location.href.includes('myonlinetaxoffice')
    ? 'mytaxofficeportal'
    : 'portal.crosslinktax';

  let URL = `${domain}.com/portal${pseason}`;

  switch (ENVIRONMENT) {
    case 'local':
      URL = `local` + URL;
      break;
    case 'development':
      URL = `dev` + URL;
      break;
    case 'QA':
      URL = `qa` + URL;
      break;
    default:
      break;
  }

  return `https://` + URL + append;
};

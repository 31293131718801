import { actions, FETCH_DEFAULTS_CALL_REQUEST } from './duck';

import { takeLatest, call, put } from 'redux-saga/effects';

import XlinkAPI from '~/app/api/xlinkAPI';
import ErrorHelpers from '~/app/errorHelpers.js';

export function* watcherSagaDefaultsPage() {
  yield takeLatest(FETCH_DEFAULTS_CALL_REQUEST, workerSagaDefaultsPage);
}

export function* workerSagaDefaultsPage(payload) {
  try {
    const res = yield call(XlinkAPI.getDefaultsSetup, payload.loginID);

    yield put(actions.requestDefaultsConfigSuccess(res.data.config, payload.season));
  } catch (error) {
    ErrorHelpers.handleError('Error Fetching Defaults Configuration', error);
    yield put(actions.requestDefaultsConfigError());
  }
}

// External imports
import React, { useEffect } from 'react';
import { AccountCircle } from '@material-ui/icons';
import {
  AppBar,
  Button,
  Fade,
  FormControl,
  FormControlLabel,
  Grid,
  Switch,
  CircularProgress,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
// Internal imports
import ErrorHelpers from '~/app/errorHelpers.js';
import XlinkAPI from '~/app/api/xlinkAPI';
import { useSetState } from '~/app/Utility/customHooks';
import { statusOK } from '~/app/webHelpers';
import { VALID_EMAIL_REGEX } from '~/app/constants.js';
// Redux imports
import { useDispatch } from 'react-redux';
import { actions as appActions } from '~/app/redux/modules/app';
// Styling imports
import '#/NewAccount/SingleOffice/css/newPreparer.css';
import '#/NewAccount/SingleOffice/css/prepRMS.css';
import { styles } from '~/app/Components/NewAccount/SingleOffice/css/newPreparer.js';

/**
 * Modal that handles sending a remote signature request to the preparer
 *
 * @component SendPreparerRMSModal
 * @catergory AccountSetup
 */
const SendPreparerRMSModal = props => {
  const { classes } = props;
  const dispatch = useDispatch();
  const [state, setState] = useSetState({
    newEmail: '',
    existingEmail: '',
    isUsingExistingEmail: false,
    isValidEmail: true,
    isLoading: false,
  });

  useEffect(() => {
    fetchPrepEmail();
  }, []);

  const fetchPrepEmail = async () => {
    try {
      const res = await XlinkAPI.getPreparerEmail(props.currentPreparerID);

      if (statusOK(res)) {
        setState({
          existingEmail: res.data?.trim(),
        });
      }
    } catch (error) {
      ErrorHelpers.handleError('Error fetching Preparer Email', error);
      dispatch(
        appActions.showSnackbarMessage('Error fetching Preparer Email', 'error', 3500, {
          vertical: 'top',
          horizontal: 'center',
        }),
      );
    }
  };

  const handleSendEmail = async () => {
    const selectedEmail = state.isUsingExistingEmail ? state.existingEmail : state.newEmail;

    try {
      setState({ isLoading: true });
      const res = await XlinkAPI.sendPreparerRMS(
        props.currentPreparerID,
        selectedEmail,
        props.currentPreparerName,
        props.officeName,
      );

      if (statusOK(res, false)) {
        dispatch(
          appActions.showSnackbarMessage(`Email was sent to '${selectedEmail}'`, 'success', 3500, {
            vertical: 'top',
            horizontal: 'center',
          }),
        );
        handleClose();
      }
    } catch (error) {
      dispatch(
        appActions.showSnackbarMessage(
          'Error sending the remote signature request',
          'error',
          3500,
          {
            vertical: 'top',
            horizontal: 'center',
          },
        ),
      );
      ErrorHelpers.handleError('Error sending the remote signature request', error);
      setState({ isLoading: false });
    }
  };

  const resetState = () => {
    setState({
      existingEmail: '',
      newEmail: '',
      isUsingExistingEmail: false,
      isValidEmail: true,
      isLoading: false,
    });
  };

  const handleChange = (name, value) => {
    setState({
      [name]: value,
    });
  };

  const onBlur = e => {
    setState({
      isValidEmail: VALID_EMAIL_REGEX.test(e.target.value),
    });
  };

  const handleClose = () => {
    props.onClose();
    resetState();
  };

  return (
    <>
      <AppBar position="static" classes={{ root: classes.newPreparerSetupHeader }}>
        <Toolbar classes={{ root: classes.toolbarColor }}>
          <Typography classes={{ root: classes.typographyStyle }}>
            <AccountCircle classes={{ root: classes.accountCircle }} />
            Preparer Remote Signature Request
          </Typography>
        </Toolbar>
      </AppBar>
      <form className="new-preparer-form">
        <Grid container>
          <Grid item xl>
            <p className="modal-subtitle">
              Please use an email you would like to send the Remote Signature Request to.
            </p>
          </Grid>
        </Grid>
        <Grid container spacing={16}>
          <Grid item xl={8}>
            <TextField
              label="Email"
              InputLabelProps={{
                shrink: true,
              }}
              required={true}
              variant="outlined"
              id="email-input-1"
              name="newEmail"
              type="email"
              fullWidth={true}
              placeholder={'johndoe@outlook.com...'}
              value={state.isUsingExistingEmail ? state.existingEmail : state.newEmail}
              onChange={e => handleChange(e.target.name, e.target.value)}
              onBlur={onBlur}
              aria-describedby="preparer email input"
              disabled={state.isUsingExistingEmail}
              helperText={!state.isValidEmail && !state.isUsingExistingEmail ? 'Invalid email' : ''}
              error={!state.isValidEmail && !state.isUsingExistingEmail}
            />
          </Grid>
          <Grid item md={4}>
            <Tooltip
              title={`${
                state.existingEmail ? '' : 'The selected preparer does not have an existing email.'
              }`}
              TransitionComponent={Fade}
              enterDelay={100}
              leaveDelay={200}
            >
              <FormControl>
                <FormControlLabel
                  label="Use An Existing Email"
                  classes={{ root: classes.labelWidthStyleCheckbox }}
                  control={
                    <Switch
                      checked={state.isUsingExistingEmail}
                      onChange={e => handleChange(e.target.name, e.target.checked)}
                      color="primary"
                      name="isUsingExistingEmail"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                      disabled={state.existingEmail === '' || !props.canRequestPreparerSig}
                    />
                  }
                />
              </FormControl>
            </Tooltip>
          </Grid>
        </Grid>
      </form>
      <div className="modal-footer">
        <Grid container>
          <Grid item xl>
            <div className="btn-container">
              <Button
                id="btnCancelRMS"
                color="primary"
                onClick={handleClose}
                classes={{ root: classes.cancelBtn }}
                disabled={state.isLoading}
              >
                Cancel
              </Button>
              <Button
                id="btnSendRMS"
                variant="contained"
                color="primary"
                classes={{ root: classes.saveBtn }}
                onClick={handleSendEmail}
                disabled={
                  !props.canRequestPreparerSig ||
                  state.isLoading ||
                  (!state.isValidEmail && !state.isUsingExistingEmail)
                }
              >
                {state.isLoading ? (
                  <>
                    <CircularProgress
                      size="1rem"
                      color="inherit"
                      style={{
                        marginRight: '.5rem',
                      }}
                    />
                    {'Processing...'}
                  </>
                ) : (
                  'Send'
                )}
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default withStyles(styles)(SendPreparerRMSModal);

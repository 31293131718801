export const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  toolbarButtonRoot: {
    border: 'none',
    color: '#FFFFFF',
    borderRadius: '4px',
    textTransform: 'capitalize',
    boxShadow: 'none',
    outline: 'none',
  },
  cancelButtonRoot: {
    height: '30px',
    width: '97px',
    border: '0px',
    color: '#0077FF',
    borderRadius: '4px',
    backgroundColor: '#FFFFFF',
    textTransform: 'capitalize',
    lineHeight: '0em',
    boxShadow: 'none',
    outline: 'none',
  },
  styledHeader: {
    background: '#444C59',
    border: 0,
    color: 'white',
    height: 60,
    padding: '0 0px',
    PaperProps: {
      style: {
        backgroundColor: '#fff',
        color: 'black',
      },
    },
  },
  listItemRoot: {
    paddingTop: '.5rem',
    paddingBottom: '0',
  },
  secondaryListItemRoot: {
    paddingTop: '.5rem',
    paddingBottom: '0',
  },
  typographyRoot: {
    fontFamily: 'Roboto',
    fontSize: '.85rem',
    lineHeight: '18px',
  },
  typography: {
    padding: theme.spacing.unit * 3,
  },
  labelWidthStyleCheckbox: {
    height: '2vh',
    color: '#354052',
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontSize: '14px',
    marginTop: '0.5vh',
    marginBottom: '0.5vh',
    marginRight: '1.5vw',
  },
});

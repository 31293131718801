//Messages duck module
import * as Common from '~/app/redux/commonActions.js';
import { MESSAGE_TYPE } from '~/app/constants.js';

const UPDATE_SORT_CRITERIA = 'xlinkonline/messages/UPDATESORTCRITERIA';
const UPDATE_MESSAGE_LIST = 'xlinkonline/messages/UPDATEMESSAGELIST';
const UPDATE_CURRENT_MESSAGE = 'xlinkonline/messages/UPDATECURRENTMESSAGE';
const SET_REPLY_STATUS = 'xlinkonline/messages/SETREPLYSTATUS';
const ON_DELETE_MESSAGE = 'xlinkonline/messages/ONDELETEMESSAGE';
const SET_PARENT_ENTITY = 'xlinkonline/messages/SETPARENTENTITY';
const SET_SHOW_ALL_FLAG = 'xlinkonline/messages/SETSHOWALLFLAG';
const SET_MESSAGE_TYPE = 'xlinkonline/messages/SETMESSAGETYPE';
const UPDATE_MESSAGE_IN_MESSAGE_LIST = 'xlinkonline/messages/UPDATEMESSAGEINMESSAGELIST';

const initialState = {
  messageList: [],
  message: {},
  sortDropdownOptions: [
    { name: 'Sort By Newest', val: 'desc' },
    { name: 'Sort By Oldest', val: 'asc' },
  ],
  sortCriteriaIdx: 0,
  reply: false,
  parentEntity: {},
  sendTologinID: 0,
  showAllMessages: true,
  messageType: MESSAGE_TYPE.RECEIVED,
};

export const actions = {
  updateSortCriteria: idx => ({
    type: UPDATE_SORT_CRITERIA,
    idx,
  }),
  updateMessageList: messages => ({
    type: UPDATE_MESSAGE_LIST,
    messages,
  }),
  updateCurrentMessage: message => ({
    type: UPDATE_CURRENT_MESSAGE,
    message,
  }),
  setReplyStatus: status => ({
    type: SET_REPLY_STATUS,
    status,
  }),
  onDeleteMessage: id => ({
    type: ON_DELETE_MESSAGE,
    id,
  }),
  setParentEntity: parent => ({
    type: SET_PARENT_ENTITY,
    parent,
  }),
  updateShowAllFlag: flag => ({
    type: SET_SHOW_ALL_FLAG,
    flag,
  }),
  setMessageType: msgType => ({
    type: SET_MESSAGE_TYPE,
    msgType,
  }),
  updateMessageInMessageList: msgID => ({
    type: UPDATE_MESSAGE_IN_MESSAGE_LIST,
    msgID,
  }),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_SORT_CRITERIA:
      return {
        ...state,
        sortCriteriaIdx: action.idx,
      };
    case UPDATE_MESSAGE_LIST:
      return {
        ...state,
        messageList: action.messages,
      };
    case UPDATE_CURRENT_MESSAGE:
      return {
        ...state,
        message: action.message,
      };
    case SET_REPLY_STATUS:
      if (state.message) {
        return {
          ...state,
          reply: action.status,
          sendTologinID: state.message.sent_from_login_id,
        };
      }
    case ON_DELETE_MESSAGE:
      if (state.messageList) {
        const newList = state.messageList.filter(message => message.id != action.id);
        const newMessage = state.messageList.length == 1 ? {} : state.message;

        return {
          ...state,
          messageList: newList,
          message: newMessage,
        };
      }
    case SET_PARENT_ENTITY:
      return {
        ...state,
        parentEntity: action.parent,
      };
    case SET_SHOW_ALL_FLAG:
      return {
        ...state,
        showAllMessages: action.flag,
      };
    case Common.LOGOUT:
      return {
        ...initialState,
      };
    case SET_MESSAGE_TYPE:
      return {
        ...state,
        messageType: action.msgType,
      };
    case UPDATE_MESSAGE_IN_MESSAGE_LIST:
      let newMessageList = state.messageList.map(m => {
        if (m.id == action.msgID) {
          m.is_read = true;
        }
        return m;
      });
      return {
        ...state,
        messageList: newMessageList,
      };
  }
  return state;
}

export const styles = {
  productDetailInputText: {
    color: '#67727C',
    fontFamily: 'Roboto',
    fontSize: '14px',
    lineHeight: '11px',
    paddingTop: '0px',
    marginLeft: '0px',
  },
  labelCheckBoxSpacing: {
    height: '2vh',
    textAlign: 'left',
    marginTop: '0.8vh',
    marginBottom: '0.8vh',
  },
  fin_nonfin_checkbox: {
    height: '1vh',
    textAlign: 'left',
    marginTop: '0.3vh',
    marginBottom: '0.3vh',
  },
  buttonRoot: {
    width: '100px',
  },
  cancelButton: {
    width: '100px',
    marginRight: '20px',
  },
  gridItem: {
    paddingTop: '3px',
    paddingBottom: '3px',
  },
  gridContainer: {
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
    paddingBottom: '1rem',
    minHeight: '3rem',
    maxHeight: '7rem',
    overflowY: 'auto',
    width: '50rem',
  },
  inputStyleQty: {
    width: '5em',
    height: '20px',
    fontSize: '14px',
  },
  gridPadding: {
    paddingLeft: '4.5%',
  },
  labelInputBox: {
    marginLeft: '5px',
    color: '#67727C',
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontSize: '14px',
  },
};

export const styles = {
  btnBorderLeftNone: {
    borderLeft: 'none',
    borderRadius: '0px 4px 4px 0px',
    backgroundColor: '#e3ecf5',
    minWidth: '0.5rem',
    maxWidth: '1.0rem',
    height: '30px',
    border: '1px solid #8FC3FF',
    marginTop: '5px',
    marginBottom: '5px',
    minHeight: '35px',
  },
  noBorderOnFocus: {
    '&:focus': {
      border: 'none',
      outline: 'none',
    },
  },
  setDefaultButton: {
    border: 'none',
    width: '10vw',
    padding: '0',
    marginTop: '5px',
    marginBottom: '5px',
    marginRight: '0.25vw',
    minHeight: '35px',
  },
  headerStyle: {
    fontFamily: 'Roboto',
    fontSize: '21px',
    letterSpacing: '0.14px',
    color: '#1A173B',
    marginLeft: '0.3em',
  },
  buttonContainer: {
    textAlign: 'right',
    width: '100%',
    padding: '0',
    marginLeft: '0vw',
    marginRight: '3vw',
    marginTop: '3vh',
  },
  cancelSetupButton: {
    width: '10vw',
    color: '#0077FF',
    textTransform: 'capitalize',
    fontFamily: 'Roboto',
    fontSize: '14px',
    border: '1px solid #0077FF',
    borderRadius: '4px',
    marginRight: '1vw',
  },
  saveSetupButton: {
    width: '10vw',
    textTransform: 'capitalize',
    fontFamily: 'Roboto',
    fontSize: '14px',
    borderRadius: '4px',
    marginRight: '1vw',
  },
  setSchemaHeader: {
    float: 'right',
    height: '35px',
    display: 'flex',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  billingContainer: {
    marginLeft: '3vw',
    marginRight: '3vw',
    marginTop: '1vh',
  },
  billingContainerSecondary: {
    textAlign: 'left',
    paddingTop: '3vh',
    paddingBottom: '1vh',
  },
  noOutlineButton: {
    border: 'none',
  },
  noOutlineButtonBlue: {
    color: '#1880E7',
    fontFamily: 'Roboto',
    fontSize: '15px',
    border: 'none',
  },
  inputStyle: {
    width: '200px',
    height: '30px',
  },
  inputStyleNearButton: {
    width: '200px',
    height: '30px',
    marginRight: '.5vw',
  },
};

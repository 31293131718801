// External imports
import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Paper,
  withStyles,
  Modal,
  Snackbar,
  TableRow,
  Table,
  TableHead,
  TableBody,
  TableCell,
  Menu,
  MenuItem,
  Collapse,
  Divider,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
// Internal imports
import { useSetState } from '~/app/Utility/customHooks';
import ReassignAsset from './ReassignAsset.jsx';
import menuVertical from '~/images/icons/menu_vertical.png';
// Styling imports
import { styles } from '~/app/Pages/Returns/components/Depreciation/css/depreciationStyles.js';

const AssetList = props => {
  const { classes } = props;
  const [state, setState] = useSetState({
    expandedHeaders: [],
    isReassignModalOpen: false,
    isDeleteDialogOpen: false,
    contextMenuAnchor: null,
    contextMenuSelected: null,
    selectedAsset: null,
  });

  const handleClickOnCollapser = key => {
    const keyIndex = state.expandedHeaders.indexOf(key);
    const newexpandedHeaders = [...state.expandedHeaders];

    if (keyIndex === -1) {
      newexpandedHeaders.push(key);
    } else {
      newexpandedHeaders.splice(keyIndex, 1);
    }

    setState({
      expandedHeaders: newexpandedHeaders,
    });
  };

  const handleContextMenuToggle = (event, selected) => {
    event.preventDefault();
    setState({
      contextMenuAnchor: event.currentTarget,
      contextMenuSelected: selected,
    });
  };

  const toggleAssetDeleteDialog = (asset, toggle) => {
    setState({
      selectedAsset: asset,
      isDeleteDialogOpen: toggle,
    });
  };

  const toggleReassignModal = (asset, toggle) => {
    setState({
      selectedAsset: asset,
      isReassignModalOpen: toggle,
    });
  };

  const getAssetTable = () => {
    if (typeof props.activityList === 'undefined') {
      return;
    }

    const assets = props.activityList.map((activity, index) => {
      const keyAndID = activity.desc + index;
      return (
        <div key={keyAndID}>
          <div style={{ display: 'inline-flex' }}>
            {state.expandedHeaders.indexOf(keyAndID) !== -1 ? (
              <ExpandLess onClick={() => handleClickOnCollapser(keyAndID)} />
            ) : (
              <ExpandMore onClick={() => handleClickOnCollapser(keyAndID)} />
            )}
            <Typography variant="title" onClick={() => handleClickOnCollapser(keyAndID)}>
              {activity.name} - {activity.desc}
            </Typography>
          </div>
          <Divider />
          <Collapse
            in={state.expandedHeaders.indexOf(keyAndID) !== -1}
            timeout="auto"
            style={{ paddingBottom: '20px' }}
            unmountOnExit
          >
            <Table padding={'dense'}>
              <TableHead>
                <TableRow classes={{ root: classes.tableHeadRoot }}>
                  <TableCell>ID</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Service Date</TableCell>
                  <TableCell>Basis</TableCell>
                  <TableCell>Depreciation</TableCell>
                  <TableCell>Sell Date</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell style={{ borderBottom: '0px' }} />
                </TableRow>
              </TableHead>
              {activity.assets.map((asset, index) => {
                if (Object.keys(asset).length === 0 && asset.constructor === Object) {
                  return null;
                }
                const keyAndID = asset.desc + index;
                return (
                  <TableBody key={keyAndID}>
                    <TableRow classes={{ root: classes.tableRowRoot }}>
                      <TableCell>{asset.dispID}</TableCell>
                      <TableCell>{asset.desc}</TableCell>
                      <TableCell>{asset.srvDate}</TableCell>
                      <TableCell>{asset.basis}</TableCell>
                      <TableCell>{asset.depre}</TableCell>
                      <TableCell>{asset.sellDate}</TableCell>
                      <TableCell>{asset.price}</TableCell>
                      <TableCell
                        style={{
                          borderBottom: '0px',
                          paddingLeft: '0px',
                          display: 'flex',
                        }}
                      >
                        <Button
                          size="small"
                          classes={{ root: classes.previewButton }}
                          onClick={() => props.viewAsset(asset.ID)}
                          id="btnView"
                          disabled={props.readyForReview && props.isFeederOffice}
                        >
                          View
                        </Button>
                        <Button
                          id="btnViewOptions"
                          aria-owns={state.contextMenuAnchor ? asset.ID : null}
                          aria-haspopup="true"
                          onClick={e => handleContextMenuToggle(e, asset.ID)}
                          style={{
                            minHeight: '30px',
                            maxHeight: '30px',
                            minWidth: '0.5rem',
                            maxWidth: '0.5rem',
                            padding: '0px 8px 0px 8px',
                            border: '2px solid #0077FF',
                          }}
                        >
                          <img src={menuVertical} style={{ height: `1em` }} />
                        </Button>
                        <Menu
                          id={asset.ID + '-context'}
                          anchorEl={state.contextMenuAnchor}
                          open={Boolean(state.contextMenuSelected === asset.ID)}
                          onClose={e => handleContextMenuToggle(e, null)}
                        >
                          <MenuItem
                            className="contextMenuItem"
                            onClick={e => {
                              handleContextMenuToggle(e, null);
                              toggleAssetDeleteDialog(asset, true);
                            }}
                            disabled={props.readyForReview && props.isFeederOffice}
                          >
                            Delete
                          </MenuItem>
                          <MenuItem
                            className="contextMenuItem"
                            onClick={e => {
                              handleContextMenuToggle(e, null);
                              toggleReassignModal(asset, true);
                            }}
                            disabled={props.readyForReview && props.isFeederOffice}
                          >
                            Reassign
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                );
              })}
            </Table>
          </Collapse>
        </div>
      );
    });

    return assets;
  };

  const openDepreciation = () => {
    props.tabListDefinitions.forEach(
      (itr, index) => itr.label === 'Depreciation' && props.openAddFormList(itr, index),
    );
  };

  return (
    <div id="taxform" className={classes.assetListContainer}>
      <div className={classes.assetListHeader}>
        <Typography variant="body2">Added Assets</Typography>

        <Button
          id="btnOpenAttachModalDocArchive"
          className={classes.addDocButton}
          size="small"
          color="primary"
          onClick={openDepreciation}
          disabled={props.locked || (props.readyForReview && props.isFeederOffice)}
        >
          Add Asset
        </Button>
      </div>
      <div>{getAssetTable()}</div>
      <Dialog
        open={state.isDeleteDialogOpen}
        onClose={() => toggleAssetDeleteDialog(null, false)}
        aria-labelledby="confirm-asset-delete-title"
        aria-describedby="confirm-asset-delete-desc"
      >
        <DialogTitle id="confirm-asset-delete-title">
          Delete Asset: {state.selectedAsset === null ? '' : state.selectedAsset.desc}
        </DialogTitle>
        <DialogContent id="confirm-asset-delete-desc">
          Preparing to delete asset {state.selectedAsset === null ? '' : state.selectedAsset.dispID}
          . This cannot be undone. Are you sure?
        </DialogContent>
        <DialogActions>
          <Button
            id="btnCancelDeleteAssetFormViewer"
            onClick={() => toggleAssetDeleteDialog(null, false)}
          >
            Cancel
          </Button>
          <Button
            id="btnDoDeleteAssetFormViewer"
            onClick={() => {
              props.deleteAsset(state.selectedAsset.ID);
              toggleAssetDeleteDialog(null, false);
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        id="mdlReassignAsset"
        open={state.isReassignModalOpen}
        onClose={() => toggleReassignModal(null, false)}
      >
        <Paper elevation={5} className="reassignAsset">
          <ReassignAsset
            asset={state.selectedAsset}
            toggleModal={(asset, toggle) => toggleReassignModal(asset, toggle)}
            reassignAssetList={props.reassignAssetList}
            fetchActivityList={id => props.fetchReassignAssetList(id)}
            reassignAsset={(astID, actvID) => props.reassignAsset(astID, actvID)}
          />
        </Paper>
      </Modal>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={props.showSnackbar}
        autoHideDuration={2500}
        onClose={() => props.toggleSnackbar('', false)}
        id="snackbarAssetList"
        message={<span id="success-message">{props.snackbarMessage}</span>}
      />
    </div>
  );
};

export default withStyles(styles)(AssetList);

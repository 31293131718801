const PAGE_CHANGE = 'xlinkonline/reporting/PAGECHANGE';
const UPDATE_ACTIVE_COL_IDX = 'xlinkonline/reporting/UPDATEACTIVECOLIDX';

const RESET_COLUMNS_TO_DEFAULT = 'xlinkonline/reporting/RESETCOLUMNSTODEFAULT';
const RESET_COLUMNS = 'xlinkonline/reporting/RESETCOLUMNS';
const SET_PREV_PARAMS = 'xlinkonline/reporting/SET_PREV_PARAMS';
const SET_PREV_COLUMNS = 'xlinkonline/reporting/SET_PREV_COLUMNS';
const SET_PREV_FIELDS = 'xlinkonline/reporting/SET_PREV_FIELDS';
const SET_PREV_QUERY = 'xlinkonline/reporting/SET_PREV_QUERY';
const SET_LAST_REPORT = 'xlinkonline/reporting/SET_LAST_REPORT';
const SET_REPORT_TITLE = 'xlinkonline/reporting/SET_REPORT_TITLE';
const SET_LAST_REPORT_QUERY = 'xlinkonline/reporting/SET_LAST_REPORT_QUERY';

export const initialState = {
  sortOrder: {
    column: 0,
    order: 'asc', // default sort is in ascending order
  },

  // all possible table columns. name is for column title, col is for query params, asc and desc are for sort dropdown options,
  // details is for customize column modal
  allColumns: [
    {
      name: 'Report Name',
      col: 'name',
      asc: 'Sort By A-Z',
      desc: 'Sort By Z-A',
      details: 'Report Name',
    },
    {
      name: 'Report Type',
      details: 'Type of report',
    },
    {
      name: 'Date Created',
      details: 'Date Query was created',
    },
  ],

  // active columns, array index is column position in table and value is an index into allColumns
  activeColIndexes: [0, 1, 2], // default column titles
  prevColumns: [],
  prevParams: [],
  prevFields: [],
  prevAndQuery: [],
  lastReport: [],
  reportTitle: undefined,

  prevReportQuery: {
    columns: [],
    field: [],
    value: [],
    rmsFilter: null,
  },
};

export const actions = {
  onPageChange: page => ({
    type: PAGE_CHANGE,
    page,
  }),
  updateActiveColIdx: (newVal, colIdx) => ({
    type: UPDATE_ACTIVE_COL_IDX,
    newVal,
    colIdx,
  }),
  resetColumnsToDefault: () => ({
    type: RESET_COLUMNS_TO_DEFAULT,
  }),
  resetColumns: cols => ({
    type: RESET_COLUMNS,
    cols,
  }),
  setPrevParams: params => ({
    type: SET_PREV_PARAMS,
    params,
  }),
  setPrevColumns: columns => ({
    type: SET_PREV_COLUMNS,
    columns,
  }),
  setPrevFields: fields => ({
    type: SET_PREV_FIELDS,
    fields,
  }),
  setPrevQuery: value => ({
    type: SET_PREV_QUERY,
    value,
  }),
  setLastReport: report => ({
    type: SET_LAST_REPORT,
    report,
  }),
  setReportTitle: title => ({
    type: SET_REPORT_TITLE,
    title,
  }),
  setLastReportQuery: query => ({
    type: SET_LAST_REPORT_QUERY,
    query,
  }),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case PAGE_CHANGE:
      return {
        ...state,
        currentPage: action.page,
      };
    case UPDATE_ACTIVE_COL_IDX:
      const cols = state.activeColIndexes.map((item, idx) =>
        idx == action.colIdx ? action.newVal : item,
      );
      return {
        ...state,
        activeColIndexes: cols,
      };
    case RESET_COLUMNS_TO_DEFAULT:
      return {
        ...state,
        activeColIndexes: [0, 1, 2],
      };
    case RESET_COLUMNS:
      return {
        ...state,
        activeColIndexes: action.cols,
      };
    case SET_PREV_PARAMS:
      return {
        ...state,
        prevParams: action.params,
      };
    case SET_PREV_COLUMNS:
      return {
        ...state,
        prevColumns: action.columns,
      };
    case SET_PREV_FIELDS:
      return {
        ...state,
        prevFields: action.fields,
      };
    case SET_PREV_QUERY:
      return {
        ...state,
        prevAndQuery: action.value,
      };
    case SET_LAST_REPORT:
      return {
        ...state,
        lastReport: action.report,
      };
    case SET_REPORT_TITLE:
      return {
        ...state,
        reportTitle: action.title,
      };
    case SET_LAST_REPORT_QUERY:
      return {
        ...state,
        prevReportQuery: action.query,
      };
  }
  return state;
}

// Internal imports
import SocketWorker from '~/socket.worker.js';
import WebsocketAPI from '~/app/api/websocketAPI';
// Redux imports
import { store } from '~/app/redux/index';
import { actions as overviewActions } from '~/app/redux/modules/overview';
import { actions as notificationActions } from '~/app/redux/notifications/duck';

/**
 * SocketConnection initializes and handles a global websocket connection.
 * This websocket can then be imported into other files to be managed.
 *
 * @class
 * @category Utility
 */
class SocketConnection {
  constructor() {
    this.socketWorker = null;
    this.websocketAPI = null;
  }

  /**
   * Initializes a new websocket connection.
   */
  services() {
    this.socketWorker = new SocketWorker();
    this.socketWorker.onmessage = this.onPersistentSocketResponse;
    this.websocketAPI = new WebsocketAPI(this.socketWorker);
  }

  /**
   * Handles new messages from API.
   *
   * @param {Object} msg - Message object from the API
   * @returns {undefined} To break out of switch statement
   */
  onPersistentSocketResponse = async msg => {
    switch (msg.data) {
      case 'SOCKET_OPEN':
        // Do stuff
        return;
      default:
        break;
    }
    const message = JSON.parse(msg.data);
    switch (message.command) {
      case 'inc_messsge_badge':
        store.dispatch(overviewActions.incrementMessageBadgeCount());
        break;
      case 'visible_notification':
        store.dispatch(notificationActions.requestAddNotification(message));
        break;
      case 'register_login_id':
        console.log(message.content);
        break;
      default:
        console.log('Unknown notification type');
    }
  };

  /**
   * Closes the websocket connection.
   */
  closeSocketConnection() {
    this.websocketAPI.CloseSocket();
  }
}

/**
 * Global websocket connection that can be imported into other files.
 *
 * @category Utility
 * @example
 * webSocket.services() // Initialize new websocket
 * webSocket.closeSocketConnection() // Close websocket
 * // Send message to API
 * webSocket.websocketAPI.constructJSONMessage(command, jsonObject);
 */
export const webSocket = new SocketConnection();

import React from 'react';
// External imports
import { Typography, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import infoIcon from '~/images/icons/icons8-info.svg';
// Styling imports
import { styles } from '~/app/Pages/WizardMode/Mapper/css/mapperStyles.js';
import '~/app/Pages/WizardMode/Mapper/css/mapperStyles.css';

/**
 * Handles displaying 'PageTitle' and 'SectionalHeading' from font property
 * 'PageTitle' is unindented and used for heading multiple sections
 * 'SectionalHeading' is indented and used for heading sections
 *
 * @returns {JSX} TitleMap UI
 */
const TitleMap = props => {
  const { classes } = props;

  return props.font === 'PageTitle' ? (
    <Grid item xs={12}>
      <Typography id="formTitle" className={classes.formTitle}>
        {props.text || null}
        {props.dsplymode === '?' && (
          <img
            id={props.text}
            onClick={() => props.handleModal('isInfoModalOpen', props.lineno, props.text)}
            className="container-item-icon"
            alt={props.lineno}
            src={infoIcon}
          />
        )}
      </Typography>
    </Grid>
  ) : props.font === 'SectionalHeading' ? (
    <Grid item xs={12}>
      <Typography id="sectionalHeading" className={classes.formSubTitle}>
        {props.text || null}
        {props.dsplymode === '?' && (
          <img
            id={props.text}
            onClick={() => props.handleModal('isInfoModalOpen', props.lineno, props.text)}
            className="container-item-icon"
            alt={props.lineno}
            src={infoIcon}
          />
        )}
      </Typography>
    </Grid>
  ) : null;
};

export default withStyles(styles)(TitleMap);

// Archived Documents Table
export const COLUMN_HEADERS = [
  'FILE NAME',
  'DOCUMENT DESCRIPTION',
  'DOCUMENT TYPE',
  'TIME',
  'ACTION',
];

// Remote Document Requests Table
export const REMOTE_COLUMN_HEADERS = [
  'REQUESTEE TYPE',
  'SUBJECT',
  'DOCUMENT TYPE',
  'LAST UPDATED',
  'STATUS',
  'ACTION',
];

// Requestee types - Preparer and ERO are currently not in use. Still deciding whether that is wanted.
export const REQUESTEE_TYPE = {
  1: 'Taxpayer',
  2: 'Spouse',
  3: 'Preparer',
  4: 'ERO',
};

// determines what status color to display based on the document_status property - retrieve from color matching the icons in rms
export const REMOTE_DOC_STATUS_LABELS = {
  0: 'Expired',
  1: 'Pending',
  2: 'Completed',
};

// Doc Types not available for deletion 
export const UNDELETABLE_DOC_TYPES = [
  'Bank Documents',
  'Full Tax Return',
  'Full Tax Return/Bank Docs',
];
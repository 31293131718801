import React, { Fragment, useEffect } from 'react';
// External imports
import { NavLink, Link } from 'react-router-dom';
import { Drawer, List, ListItem, ListItemText, Typography, withStyles } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import classNames from 'classnames';
// Internal imports
import { useSetState } from '~/app/Utility/customHooks';
import SidebarButton from '~/app/Components/Common/Sidebar/components/SidebarButton/SidebarButton.jsx';
import {
  ROLE_TYPE,
  HIERARCHY_TYPE,
  SIDEBAR_PATHNAME,
  SELECTED_PATHNAME,
  SIDE_TABS,
  TOP_ACCESS_LOGINS,
} from '~/app/constants.js';
import {
  excludedComponents,
  screenWidth,
  url,
} from '~/app/Components/Common/Sidebar/helpers/constants.js';
import { checkOfficeLicense } from '~/app/Utility/general';
import AccessControl from '#/Auth/AccessControl.jsx';
import toggleOpenIcon from '~/images/icons/toggleSideMenuIconOpen.svg';
import toggleCloseIcon from '~/images/icons/toggleSideMenuIconClose.svg';
import accountSettingsIcon from '~/images/icons/accountSettings.svg';
import WebHelpers from '~/app/webHelpers.js';
import appTheme from '~/themes/GenericTheme.jsx';
// Redux imports
import { useDispatch, useSelector } from 'react-redux';
import { actions as officeProfileAction } from '~/app/redux/officeProfile/duck';
import { actions as setupPageActions } from '~/app/redux/setupPages/duck';
// Styling imports
import { styles } from '~/app/Components/Common/Sidebar/css/sidebar.js';
import '~/app/Components/Common/Sidebar/css/sidebar.css';

const Sidebar = props => {
  const dispatch = useDispatch();
  const payload = WebHelpers.getJWTPayload();
  const { classes } = props;
  const {
    SUPERUSER,
    RESELLER,
    MULTIOFFICE,
    EFIN,
    PREPARER,
    TECH_SUPPORT,
    TECH_SUPPORT_SBA,
  } = ROLE_TYPE;
  const {
    officeProfile,
    currentView,
    checkBadgeCount,
    messageBadgeCount,
    drilldownHistory,
    isAuthenticated,
    loggedInUser,
  } = useSelector(state => ({
    officeProfile: state.officeProfile,
    currentView: state.drilldown.drilldownHistory[state.drilldown.drilldownHistory.length - 1],
    checkBadgeCount: state.overview.checkBadgeCount,
    messageBadgeCount: state.overview.messageBadgeCount,
    drilldownHistory: state.drilldown.drilldownHistory,
    isAuthenticated: state.app.isAuthenticated,
    loggedInUser: state.app.loggedInUser,
  }));

  const [state, setState] = useSetState({
    isDrawerOpen: !(window.screen.width <= screenWidth),
  });

  useEffect(() => {
    if (isAuthenticated) {
      const licenseObj = checkOfficeLicense(officeProfile);
      officeProfile.has1040License = licenseObj.hasLicense;
      officeProfile.oldestLicenseSeason = licenseObj.oldestLicenseSeason;
      officeProfile.licenseSeasons = licenseObj.licenseSeasons;

      dispatch(officeProfileAction.setOfficeProfile(officeProfile));
    }
  }, [isAuthenticated]);

  /**
   * Handles displaying the sidebar navitation button.
   *
   * @param {number} i - Sidebar title index.
   * @returns {JSX} The sidebar navigation button.
   */
  const renderButton = i => {
    return (
      <NavLink
        className="sidebar-link-container"
        to={`${SIDEBAR_PATHNAME[i]}`}
        key={SIDEBAR_PATHNAME[i]}
      >
        <SidebarButton
          id={i}
          key={i}
          isSelected={i === SELECTED_PATHNAME[`${location.pathname}`]}
          messageBadgeCount={messageBadgeCount}
          badgeCount={checkBadgeCount}
          role={currentView === undefined ? '' : currentView?.role}
          isDrawerOpen={state.isDrawerOpen}
        />
      </NavLink>
    );
  };

  /**
   * Handles displaying the title navigation buttons depening on the current role
   * of the logged in user.
   *
   * @returns {undefined | JSX} The title navigation button.
   */
  const handleNavigationButtons = () => {
    const buttons = [];
    Object.keys(SIDEBAR_PATHNAME).forEach((_, i) => {
      if (
        currentView?.role === !HIERARCHY_TYPE.PREPARER &&
        drilldownHistory.length === 1 &&
        i === SIDE_TABS.CHECKS &&
        ((drilldownHistory.length > 1 &&
          location.pathname !== '/dashboard' &&
          location.pathname !== '/checks/ready-to-print' &&
          location.pathname !== '/checks/checks-register') ||
          (drilldownHistory.length === 1 &&
            drilldownHistory?.[0]?.role &&
            drilldownHistory?.[0]?.role !== HIERARCHY_TYPE.EFIN &&
            drilldownHistory?.[0]?.role !== HIERARCHY_TYPE.PREPARER))
      ) {
        return;
      }

      const { role } = currentView;
      switch (i) {
        case SIDE_TABS.DASHBOARD_MAIN:
          buttons.push(renderButton(i));
          break;
        case SIDE_TABS.PREPARERS:
          role === EFIN && buttons.push(renderButton(i));
          break;
        case SIDE_TABS.ACCOUNTS:
          role === RESELLER && buttons.push(renderButton(i));
          break;
        case SIDE_TABS.OFFICES:
          (role === RESELLER || role === MULTIOFFICE) && buttons.push(renderButton(i));
          break;
        case SIDE_TABS.TAX_RETURNS:
          role !== TECH_SUPPORT_SBA && buttons.push(renderButton(i)); // SBA is one of our customers, hide the tax returns link from this view
          break;
        case SIDE_TABS.SERVER:
          role === SUPERUSER && buttons.push(renderButton(i));
          break;
        case SIDE_TABS.PACKAGES:
          if (role === SUPERUSER) {
            buttons.push(renderButton(i));
          }
          break;
        case SIDE_TABS.CALENDAR:
          (role === RESELLER || role === MULTIOFFICE || role === EFIN || role === PREPARER) &&
            buttons.push(
              <AccessControl key={i} requiredAction="read" accessLevel="view/create_appointments">
                {renderButton(i)}
              </AccessControl>,
            );
          break;
        case SIDE_TABS.MESSAGES:
          (role === RESELLER || role === MULTIOFFICE || role === EFIN || role === PREPARER) &&
            buttons.push(
              <AccessControl key={i} requiredAction="write" accessLevel="access_secure_messages">
                {renderButton(i)}
              </AccessControl>,
            );
          break;
        case SIDE_TABS.USER_LOOKUP:
          (role === SUPERUSER || role === TECH_SUPPORT || role === TECH_SUPPORT_SBA) &&
            buttons.push(
              <AccessControl key={i} requiredAction="read" accessLevel="user_list">
                {renderButton(i)}
              </AccessControl>,
            );
          break;
        case SIDE_TABS.CHECKS:
          (role === EFIN || role === PREPARER) &&
            buttons.push(
              <AccessControl
                key={i}
                requiredAction="read"
                accessLevel={['view_checks', 'print_checks']}
                mustHaveOne={true}
              >
                {renderButton(i)}
              </AccessControl>,
            );
          break;
        case SIDE_TABS.RFD_CALC:
          if (
            (officeProfile?.has1040License || officeProfile?.hasSubsequentLicense) &&
            !officeProfile?.hasHold
          ) {
            (role === EFIN || role === PREPARER) &&
              buttons.push(
                <AccessControl key={i} requiredAction="write" accessLevel="add_refund_calculator">
                  {renderButton(i)}
                </AccessControl>,
              );
          }
          break;
        case SIDE_TABS.REPORTING:
          (role === EFIN || role === PREPARER) &&
            !payload.is_business && // business not currently supported for reporting
            buttons.push(
              <AccessControl key={i} requiredAction="read" accessLevel="view_reports">
                {renderButton(i)}
              </AccessControl>,
            );
          break;
        case SIDE_TABS.PAYMENTS:
          (role === EFIN || role === PREPARER) && buttons.push(renderButton(i));
          break;
        case SIDE_TABS.MOVE_RETURN:
          (role === SUPERUSER || role === TECH_SUPPORT) &&
            buttons.push(
              <AccessControl key={i} requiredAction="write" accessLevel="move_return">
                {renderButton(i)}
              </AccessControl>,
            );
          break;
        case SIDE_TABS.TECH_LOGINS:
          // In production this should only work on these two login IDs or on superuser
          if (
            ENVIRONMENT !== 'production' ||
            (ENVIRONMENT === 'production' &&
              (role === SUPERUSER || TOP_ACCESS_LOGINS.includes(loggedInUser.userID)))
          ) {
            (role === TECH_SUPPORT || role === SUPERUSER) &&
              buttons.push(
                <AccessControl key={i} requiredAction="read" accessLevel="manage_tech_temp_login">
                  {renderButton(i)}
                </AccessControl>,
              );
          }
          break;
        default:
          buttons.push(renderButton(i));
      }
    });

    return buttons;
  };

  /** Handles toggling the sidebar drawer. */
  const handleDrawerToggle = () => {
    setState(prev => ({ isDrawerOpen: !prev.isDrawerOpen }));
  };

  /** Determines when to show the sidebar. */
  const handleDisplayingSidebar = () => {
    if (
      isAuthenticated &&
      location.pathname !== '/tax-return' &&
      !excludedComponents.some(v => location.pathname.includes(v))
    )
      return true;
  };

  const settingsNavLabel = () => {
    let navLabel = 'Settings';

    if (currentView) {
      const { role } = currentView;
      if (role === RESELLER || role === MULTIOFFICE) {
        navLabel = 'Account Settings';
      } else if (role === EFIN) {
        navLabel = 'Office Settings';
      }
    }

    return navLabel;
  };

  const isDrilldown =
    drilldownHistory !== undefined &&
    drilldownHistory.length > 1 &&
    url.includes(location.pathname);

  const topStyle = isDrilldown ? { top: '1.2em !important' } : { top: '0' };

  if (handleDisplayingSidebar()) {
    return (
      <MuiThemeProvider theme={appTheme}>
        <Drawer
          style={{ topStyle }}
          variant="permanent"
          className={classNames(classes.drawer, {
            [classes.drawerOpenTraining]: state.isDrawerOpen,
            [classes.drawerClose]: !state.isDrawerOpen,
          })}
          classes={{
            paper: classNames({
              [classes.drawerOpen]: state.isDrawerOpen,
              [classes.drawerClose]: !state.isDrawerOpen,
            }),
          }}
          open={state.isDrawerOpen}
        >
          <List>{currentView?.role && handleNavigationButtons()}</List>
          <List>
            {currentView?.role &&
            ![TECH_SUPPORT, SUPERUSER, TECH_SUPPORT_SBA].includes(currentView?.role) ? (
              <Link to="/account-settings">
                <ListItem
                  id="btnSideBarAccountSettings"
                  button
                  onClick={() => {
                    dispatch(setupPageActions.toggleSetupAccount({ setupTabID: 1 }));
                  }}
                >
                  <ListItemText>
                    <img src={accountSettingsIcon} className="sidebar-icon" />
                    <Typography className={classes.typography}>{settingsNavLabel()}</Typography>
                  </ListItemText>
                </ListItem>
              </Link>
            ) : null}
            <div className="maincontainer-sidebar-tour">
              <ListItem id="btnSideBarCollapse" button onClick={handleDrawerToggle}>
                <ListItemText>
                  <img
                    src={!state.isDrawerOpen ? toggleOpenIcon : toggleCloseIcon}
                    className="sidebar-icon"
                  />
                  <Typography className={classes.typography}>Collapse</Typography>
                </ListItemText>
              </ListItem>
            </div>
          </List>
        </Drawer>
      </MuiThemeProvider>
    );
  } else return <Fragment />;
};

export default withStyles(styles)(Sidebar);

import { useEffect } from 'react';
import XlinkAPI from '~/app/api/xlinkAPI';
import { useSetState } from '~/app/Utility/customHooks';
import { ACCESS_TOKEN } from '~/app/constants';
import { useSelector } from 'react-redux';

const RefreshToken = props => {
  const [state, setState] = useSetState({
    counter: 0,
    previousAccessToken: sessionStorage.getItem(ACCESS_TOKEN), // keep track of the previous access token
  });

  const { refreshTokenExpireTime } = useSelector(state => ({
    refreshTokenExpireTime: state.app.refreshTokenExpireTime,
  }));

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentTime = Math.floor(new Date().getTime() / 1000);
      setState({ counter: prevCounter => prevCounter + 1 });
      // if the token is 60 seconds away from expiring, go ahead and refresh it
      if (refreshTokenExpireTime - currentTime >= 0 && refreshTokenExpireTime - currentTime <= 60) {
        onRefresh();
      }
    }, 15000); // 15000ms = 15 seconds

    // Clean up the interval on unmount
    return () => clearInterval(intervalId);
  }, [state.counter, refreshTokenExpireTime]);

  useEffect(() => {
    const currentAccessToken = sessionStorage.getItem(ACCESS_TOKEN);

    // if the websockets access token has not been updated due to being closed, send a socket message to update its current access token state with the refreshed token
    if (state.previousAccessToken !== currentAccessToken) {
      setState({ previousAccessToken: currentAccessToken });
      props.socketWorker.postMessage(
        JSON.stringify({
          command: 'refreshed_access_token',
          accessToken: currentAccessToken,
        }),
      );
    }
  }, [sessionStorage.getItem(ACCESS_TOKEN)]);

  const onRefresh = async () => {
    const jwt = sessionStorage.getItem(ACCESS_TOKEN);
    if (!jwt) return;

    try {
      await XlinkAPI.validateOrRefresh();
    } catch (error) {
      console.log('error: ', error);
    }
  };

  return null;
};

export default RefreshToken;

// External imports
import React, { useEffect } from 'react';
import {
  Paper,
  Typography,
  Grid,
  Input,
  Button,
  InputLabel,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
// Internal imports
import ButtonSpinner from '#/Common/ButtonSpinner.jsx';
import XlinkAPI from '~/app/api/xlinkAPI';
import ErrorHelpers from '~/app/errorHelpers.js';
// Styling imports
import { styles } from '~/app/Pages/Setup/ManageLicencing/css/manageLicencing.js';
import '~/app/Pages/Setup/ManageLicencing/css/manageLicencing.css';

const MultiOfficeManagement = props => {
  const [licensesAbleToGenerate, setLicensesAbleToGenerate] = React.useState(0);
  const [spinning, setSpinning] = React.useState(false);

  useEffect(() => {
    setLicensesAbleToGenerate(props.multiOffice.licenses_able_to_generate);
  }, []);

  const setLicensesAbleToGenerateField = e => {
    if (!isNaN(e.target.value)) {
      setLicensesAbleToGenerate(e.target.value);
    }
  };
  const upsertlicensesAbleToGenerate = async () => {
    setSpinning(true);
    // The amount of licenses available to generate shouldn't be less than the
    // amount applied to offices under the multioffice
    if (licensesAbleToGenerate < props.multiOffice.licenses_consumed) {
      ErrorHelpers.handleError(
        `Unable to save number of licenses available to generate`,
        ErrorHelpers.createSimpleError(
          `The number of licenses available to generate can't be less than the number of 
          licenses applied to offices. Unassign licenses from offices under the 
          Multi Office if you wish to decrease the number of generatable licenses`,
        ),
      );
      setSpinning(false);
      return;
    }

    try {
      const res = await XlinkAPI.applyMOGeneratableLicenses(
        props.multiOffice.user_id,
        licensesAbleToGenerate,
        props.isBusinessManagement,
      );
      if (res) {
        // Reload the other page.
        props.reloadLicensing();
      }
    } catch (error) {
      ErrorHelpers.handleError('Unable to save number of licenses available to generate', error);
    }
    setSpinning(false);
    props.closeModal();
  };

  return (
    <Paper elevation={5} style={styles.multiOfficeManagementModal}>
      <div className="modal-header">
        Managed Multi Office Licenses
        <Typography color="inherit" onClick={props.closeModal}>
          X
        </Typography>
      </div>
      <Grid container style={styles.moContainer}>
        <Grid item xs={12}>
          <FormControl style={styles.hundredWidth}>
            <InputLabel htmlFor="inputLicenseNumberGen">Licenses Able to Generate</InputLabel>
            <Input
              value={licensesAbleToGenerate}
              onChange={e => {
                setLicensesAbleToGenerateField(e);
              }}
              id="inputLicenseNumberGen"
              disableUnderline
              aria-describedby="licenseNumberGenHelpText"
            />
            <FormHelperText id="licenseNumberGenHelpText">
              Maximum number of licenses the multi office can generate
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container style={styles.footerDividerMO} alignItems="flex-end" justify="flex-end">
        <Grid item xs={7} style={styles.justifyRight}>
          {/* Spacer */}
        </Grid>
        <Grid item xs={3} style={styles.justifyRight}>
          <Button onClick={props.closeModal}>Cancel</Button>
        </Grid>
        <Grid item xs={2} style={styles.justifyRight}>
          <ButtonSpinner
            buttonText={spinning ? 'Saving' : 'Save'}
            isLoading={spinning}
            onClick={upsertlicensesAbleToGenerate}
            disabled={spinning}
            spinnerColor="#ffffff"
            color="primary"
            variant="contained"
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default MultiOfficeManagement;

// External imports
import React, { useEffect, Fragment } from 'react';
import { Link, Route, Switch, withRouter, useHistory } from 'react-router-dom';
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from '@material-ui/core';
// Internal imports
import { useSetState } from '~/app/Utility/customHooks';
import AccountSetup from '~/app/Pages/Setup/AccountSetup/AccountSetup.jsx';
import DatabaseSetup from '~/app/Pages/Setup/DatabaseSetup/DatabaseSetup.jsx';
import AccessControl from '~/app/Components/Auth/AccessControl.jsx';
import accountSettingsIcon from '~/images/accountSettings.png';
import { HIERARCHY_TYPE, SIDE_TABS } from '~/app/constants.js';
import helpIcon from '~/images/icons/ask_question_blue.png';
import copyIcon from '~/images/icons/copy_settings.png';
import Breadcrumbs from '#/Common/Breadcrumbs.jsx';
import WebHelpers from '~/app/webHelpers.js';
import Spinner from '#/Common/Spinner.jsx';
import HelpMenu from '~/app/Components/HeaderBar/components/HelpMenu/HelpMenu.jsx';
// Redux imports
import { useDispatch, useSelector } from 'react-redux';
import { actions as appActions } from '~/app/redux/modules/app';
import { actions as loginSetupActions } from '~/app/redux/loginSetup/duck';
import { actions as setupPageActions } from '~/app/redux/setupPages/duck';
// Styling imports
import { withStyles } from '@material-ui/core/styles';
import { compStyles } from '~/app/Pages/Setup/css/setup.js';

// Need to make sure we forward the  Doesn't work when we just wrap it with AccessControl.
const ControlledTab = ({ label, id, requiredAction, accessLevel, ...props }) => (
  <AccessControl requiredAction={requiredAction} accessLevel={accessLevel}>
    <Tab id={id} disableRipple label={label} {...props} />
  </AccessControl>
);

const Settings = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const payload = WebHelpers.getJWTPayload();
  const { classes } = props;

  const {
    alert,
    currentView,
    drilldownHistory,
    selectedSidebarTab,
    isTrainingMode,
    impersonation,
    displayName,
    firmName,
    role,
    isHelpMenuVisible,
  } = useSelector(state => ({
    alert: state.app.alert,
    currentView: state.drilldown.drilldownHistory[state.drilldown.drilldownHistory.length - 1],
    drilldownHistory: state.drilldown.drilldownHistory,
    selectedSidebarTab: state.app.tabState.selectedSidebarTab,
    isTrainingMode: state.loginSetup.isTrainingMode,
    impersonation: state.app.impersonation,
    displayName: state.app.loggedInUser.displayName,
    techSupportNum: state.cobrand.techSupportNumber,
    firmName: state.officeSettings.officeAddress?.firmName,
    role: state.drilldown.drilldownHistory?.[0]?.role,
    isHelpMenuVisible: state.app.isHelpMenuVisible,
  }));

  const canCopySettings = [
    HIERARCHY_TYPE.FRANCHISE,
    HIERARCHY_TYPE.SB,
    HIERARCHY_TYPE.SSB,
  ].includes(role);
  const isEFINView = HIERARCHY_TYPE.EFIN === currentView?.role;

  const [state, setState] = useSetState({
    isLoadingFlag: false,
    currentPath: false,
    accountSettingsPath: '/account-settings',
    databaseSettingsPath: '/database',
    isHelpDialogOpen: false,
  });

  useEffect(() => {
    // get the base pathname 'database' or 'account-settings'
    const currentPath = '/' + history.location.pathname.split('/')[1];
    if ([state.accountSettingsPath, state.databaseSettingsPath].includes(currentPath)) {
      setState({ currentPath });
    }
  }, [history.location.pathname]);

  const isLoading = async isLoadingFlag => {
    await setState({ isLoadingFlag });
  };

  const handleCopyOffice = () => {
    dispatch(loginSetupActions.onToggleCopyOfficeModal());
  };

  const helpMenuClose = () => {
    dispatch(appActions.toggleHelpModal());
  };

  const handleTabChange = () => {
    dispatch(setupPageActions.onDBSelectSidebar(0));
  };

  const breadcrumbSetup = () => {
    if (selectedSidebarTab !== SIDE_TABS.RFD_CALC) {
      let breadcrumbsClassName = 'breadcrumb-list';

      if (isTrainingMode) {
        breadcrumbsClassName = 'training-breadcrumb-list';
      }

      // Add header for demo accounts
      let demoAccountFlag = false;
      // Its possible for payload to be undefined during app timeout CLO-978
      if (typeof payload !== 'undefined') {
        const accountCode = payload.account_code;
        if (accountCode === 'CLODMO') {
          demoAccountFlag = true;
        }
      }

      return (
        <Breadcrumbs
          fields={drilldownHistory}
          showFieldAsLinks={false}
          passClassName={breadcrumbsClassName}
          isInTrainingMode={isTrainingMode}
          demoAccountFlag={demoAccountFlag}
          impersonation={impersonation}
          displayName={displayName}
        />
      );
    } else {
      return <Fragment />;
    }
  };

  const topNavSetup = () => {
    let databaseTabVisibility = { display: 'none' };
    if (
      currentView?.role === HIERARCHY_TYPE.EFIN ||
      currentView?.role === HIERARCHY_TYPE.PREPARER
    ) {
      databaseTabVisibility = { outline: 'none', textDecoration: 'none' };
    }

    const isDrilldown =
      drilldownHistory !== undefined &&
      drilldownHistory.length > 1 &&
      selectedSidebarTab !== SIDE_TABS.RFD_CALC;
    const topStyle = isDrilldown || isTrainingMode ? { top: '1em' } : { top: '0' };

    const renderOfficeName = () => {
      let officeName = '';
      if (currentView?.role === HIERARCHY_TYPE.PREPARER) {
        officeName = firmName;
      } else {
        officeName = currentView?.name;
      }

      return officeName;
    };

    return (
      <AppBar position="fixed" classes={{ root: classes.appBar }} style={topStyle}>
        <Toolbar disableGutters={true} classes={{ root: classes.setupToolbar }}>
          <img src={accountSettingsIcon} className="setup-acct-logo" />
          <span>
            <Typography variant="title" classes={{ root: classes.setupAcctLogoHeading }}>
              {state.currentPath === state.accountSettingsPath
                ? 'Account'
                : state.currentPath === state.databaseSettingsPath
                ? 'Database'
                : ''}{' '}
              Settings - {renderOfficeName()}
            </Typography>
          </span>
          <span className="col">
            <Tabs
              value={state.currentPath}
              onChange={handleTabChange}
              classes={{
                root: classes.tabsRoot,
                indicator: classes.tabsIndicator,
                flexContainer: classes.tabFlex,
              }}
            >
              <Tab
                component={Link}
                to={state.accountSettingsPath}
                value={state.accountSettingsPath}
                id="tabAccountSettingsProfile"
                disableRipple
                label="Account"
                classes={{
                  root: classes.tabRoot,
                }}
                style={{ outline: 'none', textDecoration: 'none' }}
              />
              <ControlledTab
                component={Link}
                to={state.databaseSettingsPath}
                value={state.databaseSettingsPath}
                id="tabDatabaseSettingsProfile"
                disableRipple
                label="Database"
                classes={{
                  root: classes.tabRoot,
                }}
                style={databaseTabVisibility}
                requiredAction="write"
                accessLevel="view_databases"
              />
            </Tabs>
          </span>
          <div className="setup-toolbar-help-nav-wrapper" onClick={helpMenuClose}>
            <img
              id="imgHelpTitleBarSettingsProfile"
              src={helpIcon}
              className="noselect helpIcon setup-toolbar-help-nav-logo"
            />
            <label
              htmlFor="imgHelpTitleBarSettingsProfile"
              className="noselect label setup-toolbar-help-nav-label"
            >
              Help
            </label>
            <Modal
              className={classes.helpMenu}
              open={isHelpMenuVisible}
              BackdropProps={{
                classes: {
                  root: classes.backDrop,
                },
              }}
            >
              <div>
                <HelpMenu
                  account_code={payload?.account_code}
                  hasRepublicBankApproval={state.hasRepublicBankApproval}
                />
              </div>
            </Modal>
          </div>
          {canCopySettings && isEFINView && (
            <div className="setup-toolbar-copy-nav-wrapper" onClick={handleCopyOffice}>
              <img
                id="imgHelpTitleBarSettingsProfile"
                src={copyIcon}
                className="noselect copyIcon setup-toolbar-help-nav-logo"
              />
              <label
                htmlFor="imgHelpTitleBarSettingsProfile"
                className="noselect label setup-toolbar-help-nav-label"
              >
                Copy Over Settings
              </label>
            </div>
          )}
          <div>
            <Link to="/dashboard">
              <Button
                id="btnSaveAndCloseSettingsProfile"
                variant="contained"
                color="primary"
                classes={{ root: classes.setupToolbarCloseBtn }}
              >
                Close
              </Button>
            </Link>
          </div>
        </Toolbar>
      </AppBar>
    );
  };

  return (
    <div className={classes.root}>
      {breadcrumbSetup()}
      {topNavSetup()}
      <Switch>
        <Route path={state.databaseSettingsPath}>
          <DatabaseSetup isLoading={isLoadingFlag => isLoading(isLoadingFlag)} />
        </Route>
        <Route path={state.accountSettingsPath}>
          <AccountSetup
            isLoadingFlag={state.isLoadingFlag}
            isLoading={isLoadingFlag => isLoading(isLoadingFlag)}
          />
        </Route>
      </Switch>
      <Dialog
        open={!!alert?.show}
        onClose={() => dispatch(appActions.hideError())}
        aria-labelledby="confirm-delete-title"
        aria-describedby="confirm-delete-desc"
      >
        <DialogTitle id="confirm-delete-title">{alert.title}</DialogTitle>
        <DialogContent id="confirm-delete-desc">{alert.body}</DialogContent>
        <DialogActions>
          <Button
            id="btnHideSettingsError"
            className={classes.setupConfirmDeleteBtn}
            onClick={() => dispatch(appActions.hideError())}
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
      {state.isLoadingFlag && <Spinner textColor="#fff" lockActions={true} />}
    </div>
  );
};

export default withRouter(withStyles(compStyles)(Settings));

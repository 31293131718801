// External Imports
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Input, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
// Internal Imports
import WebHelpers from '~/app/webHelpers.js';
import { INVALID_EIN_PREFIX, EIN_ENTITY_TYPES } from '~/app/constants.js';
import {
  removeHypen,
  suppressEnter,
  handleBlindInputChange,
  handleBlindBackSpace,
} from '~/app/Pages/TaxReturns/taxReturnsHelper.js';
import { useSetState } from '~/app/Utility/customHooks';
// Styling imports
import '~app/Pages/TaxReturns/components/AddNewReturn/css/addNewReturn.css';

const INVALID = 'Invalid EIN';
const NO_MATCH = 'The EIN Numbers entered do not match';
const ADD_BUTTON_NORMAL = 'Create Return';
const ADD_BUTTON_WORKING = 'Checking...';

const BusinessReturn = props => {
  const payload = WebHelpers.getJWTPayload();

  const [state, setState] = useSetState({
    ein: '',
    einConfirm: '',
    einBlind: '',
    einConfirmBlind: '',
    errorMessage: '',
    readyToSubmit: false,
    addButtonText: ADD_BUTTON_NORMAL,
    entityType: 0,
    return: [],
  });

  useEffect(() => {
    if (state.ein !== '' && state.einConfirm !== '' && state.entityType !== 0) {
      validate();
    }
  }, [state.ein, state.einConfirm, state.entityType]);

  const validate = () => {
    setState({
      errorMessage: '',
    });

    const ein = removeHypen(state.ein);
    const einConfirm = removeHypen(state.einConfirm);
    let isContentValid = true;
    let isReady = false;

    // only validate EINs clientside in prod
    if (ENVIRONMENT === 'production' && payload.training_returns_only === false) {
      const prefix = ein.substring(0, 2);

      if (INVALID_EIN_PREFIX.includes(prefix)) {
        isContentValid = false;
      }

      if (!isContentValid) {
        setState({
          errorMessage: INVALID,
        });
        return;
      }
    }

    if (einConfirm.length === 9) {
      const einSub = ein.substring(0, einConfirm.length);
      if (einConfirm !== einSub) {
        setState({
          errorMessage: NO_MATCH,
        });
      } else if (einSub.length === 9 && state.entityType !== 0) {
        // SSN confirm has been fully entered and is not mismatched
        isReady = true;
      }
    }

    setState({
      readyToSubmit: isReady,
    });
  };

  const handleAdd = e => {
    const isEnterPress = e.type === 'keydown' && e.keyCode === 13;
    if (isEnterPress) {
      // enter key should do nothing if we're not readyToSubmit
      e.preventDefault();
    }

    if (state.readyToSubmit && (e.type === 'click' || isEnterPress)) {
      e.preventDefault();
      const ein = removeHypen(state.einConfirm);
      setState({
        addButtonText: ADD_BUTTON_WORKING,
        readyToSubmit: false,
      });

      props.onAddNewReturn(removeHypen(ein), true, state.entityType);
    }
  };

  /** Handles creating radio buttons */
  const getEntityButtons = () =>
    Object.entries(EIN_ENTITY_TYPES).map(([key, val]) => {
      // Disallow New business in production environment for all accounts except Testing Accounts
      // TODO: Once officially released new business entities should only appear in  Season 2025 or greater.
      if (
        payload.season >= 2024 &&
        key > 3 &&
        ENVIRONMENT === 'production' &&
        !['NAHMIC', 'AMAPRI00', 'CLOTST'].includes(payload.account_code)
      ) {
        return <></>;
      }

      return (
        <FormControlLabel
          key={key}
          value={key}
          onChange={handleEntityChange}
          control={<Radio id={`${val}-${key}`} checked={parseInt(key) === state.entityType} />}
          className={'label'}
          label={val}
        />
      );
    });

  const handleEntityChange = e => {
    setState({
      entityType: parseInt(e.target.value),
    });

    validate();
  };

  return (
    <>
      <div className="new-return-modal-heading">Double entry of the EIN helps ensure accuracy.</div>
      <form
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <div className="container">
          <div className="row">
            <div style={{ paddingTop: '10px' }} className="col-md-4 new-return-modal-label">
              EIN
            </div>
            <div className="col-md-8">
              <Input
                name="ein"
                value={props.blindEntryNewReturn === '1' ? state.einBlind : state.ein}
                style={{ paddingLeft: '0.5vw' }}
                disableUnderline
                autoFocus
                className="new-return-modal-form-field"
                inputProps={{ maxLength: 10 }}
                placeholder="00-0000000"
                onChange={e =>
                  handleBlindInputChange(
                    e,
                    props.blindEntryNewReturn,
                    setState,
                    state[e.target.name],
                    true,
                  )
                }
                onKeyDown={e => {
                  if (props.blindEntryNewReturn === '1') {
                    handleBlindBackSpace(e, state[e.target.name], setState, true);
                  }
                  suppressEnter(e);
                }}
              />
            </div>
          </div>
          <div className="row">
            <div style={{ paddingTop: '10px' }} className="col-md-4 new-return-modal-label">
              Confirm Entry
            </div>
            <div className="col-md-8">
              <Input
                name="einConfirm"
                value={props.blindEntryNewReturn === '1' ? state.einConfirmBlind : state.einConfirm}
                style={{ paddingLeft: '0.5vw' }}
                disableUnderline
                className="new-return-modal-form-field"
                inputProps={{ maxLength: 10 }}
                placeholder="00-0000000"
                onChange={e =>
                  handleBlindInputChange(
                    e,
                    props.blindEntryNewReturn,
                    setState,
                    state[e.target.name],
                    true,
                  )
                }
                onKeyDown={e => {
                  if (props.blindEntryNewReturn === '1') {
                    handleBlindBackSpace(e, state[e.target.name], setState, true);
                  }
                  handleAdd(e);
                }}
              />
            </div>
          </div>
          <div className="row">
            <div style={{ paddingTop: '10px' }} className="col-md-4 text-right">
              Entity Type
            </div>
            <div className="col-md-8 mt-3">
              <RadioGroup value={`${state.entityType}`}>
                {getEntityButtons().map((rb, _) => {
                  return [rb];
                })}
              </RadioGroup>
            </div>
          </div>
        </div>
        <div className="new-return-modal-error-message">{state.errorMessage}</div>
        <div className="container mt-3 mb-2 text-right">
          <Button
            id="createBusinessReturnBtn"
            type="submit"
            disabled={!state.readyToSubmit}
            onClick={e => handleAdd(e)}
          >
            {state.addButtonText}
          </Button>
        </div>
      </form>
    </>
  );
};

export default withRouter(BusinessReturn);

import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import XlinkAPI from '../../../api/xlinkAPI';
import { SIGNATURE_PAD_TYPES } from '~/app/constants';

class SignaturePreviewModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signatureData: '',
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
    // Remove the signature data saved to sessionStorage
    sessionStorage.removeItem('signatureData');
  }

  // Handle Save Signature Button
  saveSignature = e => {
    e.preventDefault();

    // Need to call function to post signature data to database
    const signatureToPost = sessionStorage.getItem('signatureData');
    // console.log(signatureToPost);

    // For now only allowing on-screen signature
    const signaturePadType = SIGNATURE_PAD_TYPES.ON_SCREEN;

    // Call XlinkAPI endpoint to post signature to db
    XlinkAPI.postSignature(signatureToPost, signaturePadType);

    // Close the modal
    this.props.onClose();
  };

  // Handle Tour Checkbox
  clickCancel = e => {
    e.preventDefault();
    // Close the modal
    this.props.onClose();
  };

  // Handle Tour Checkbox
  clickRecapture = e => {
    e.preventDefault();
    // Close the modal
    this.props.onClose();
    // Open Signature modal again in order to recapture signature
    this.props.onRecapture();
  };

  render() {
    const toolbarColor = { backgroundColor: '#444A59' };
    const modalTitle = 'Preview Signature';
    // var signatureInstructions = firstName + ' ' + 'please sign below.'
    const signatureInstructions = 'Are you satisfied with your signature?';
    const signatureToPreview = sessionStorage.getItem('signatureData');
    console.log(signatureToPreview);

    return (
      <div>
        <AppBar position="static">
          <Toolbar style={toolbarColor}>
            <span style={{ fontFamily: 'Roboto', fontSize: '18px' }}>{modalTitle}</span>
          </Toolbar>
        </AppBar>
        <form style={{ marginLeft: '3vw', marginRight: '3vw', marginTop: '1vh' }}>
          <div style={{ marginTop: '3vh' }}>
            <div>
              <p style={{ fontSize: '15px', textAlign: 'justify' }}>{signatureInstructions}</p>
            </div>
            <div />
            <div style={{ textAlign: 'center', width: '420px', height: '100px' }}>
              <img
                src={signatureToPreview}
                style={{
                  width: '420px',
                  height: '100px',
                  backgroundColor: 'white',
                }}
              />
            </div>
          </div>
          <div
            style={{
              textAlign: 'center',
              padding: '0',
              marginLeft: '0vw',
              marginTop: '4vh',
            }}
          >
            <Button
              id="btnCancelPreviewSignature"
              onClick={this.clickCancel}
              style={{
                paddingLeft: '10px',
                paddingRight: '10px',
                minWidth: '0px',
                minHeight: '3.5vh',
                textTransform: 'capitalize',
                fontFamily: 'Roboto',
                fontSize: '14px',
                borderRadius: '4px',
                marginRight: '1.5vw',
              }}
            >
              Cancel
            </Button>
            <Button
              id="btnRecaptureSignature"
              onClick={this.clickRecapture}
              style={{
                paddingLeft: '10px',
                paddingRight: '10px',
                minWidth: '0px',
                minHeight: '3.5vh',
                textTransform: 'capitalize',
                fontFamily: 'Roboto',
                fontSize: '14px',
                borderRadius: '4px',
                marginRight: '1.5vw',
              }}
            >
              Recapture
            </Button>
            <Button
              id="btnSaveAndCloseSignature"
              style={{
                paddingLeft: '10px',
                paddingRight: '10px',
                minWidth: '0px',
                minHeight: '3.5vh',
                textTransform: 'capitalize',
                fontFamily: 'Roboto',
                fontSize: '14px',
                borderRadius: '4px',
              }}
              onClick={this.saveSignature}
            >
              Accept
            </Button>
          </div>
          <br />
        </form>
      </div>
    );
  }
}

export default SignaturePreviewModal;

import React, { useState } from 'react';
// Internal imports
import {
  getChildrenOfRows,
  validateInputPattern,
  getColumnTotal,
  getSubIncrement,
  getInputSize,
  getPadding,
} from '~/app/Pages/WizardMode/Mapper/helpers/mapperHelpers.js';
import { getBreakpointGrid } from '~/app/Pages/WizardMode/wizardModeHelpers.js';
import infoIcon from '~/images/icons/icons8-info.svg';
// External imports
import { TextField, InputLabel, FormControl, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
// Styling imports
import { styles } from '~/app/Pages/WizardMode/Mapper/css/mapperStyles.js';
import '~/app/Pages/WizardMode/Mapper/css/mapperStyles.css';

/**
 * Handles A and C field types for Text Fields
 * A type - Letters + Numbers
 * C type - Letters
 * a type - Letters + Numbers + symbols
 *
 * @param {Object} props check the map helper to see what is passed in
 * @returns JSX Character input
 */
const TextFieldMap = props => {
  const { classes } = props;
  const [style, setStyle] = useState(getInputSize(parseInt(props.size)));
  // field padding for spacing
  const [fieldPadding, setFieldPadding] = useState(
    getPadding(props.field, props.preppedUI, props.row, props.size),
  );
  // Returns an Array of inputs/CellItems that are associated to the same row
  //const childrenOfRows = getChildrenOfRows(props.preppedUI, props.row, false); UNUSED- marked for deletion
  // for dynamic fields, add a sub increment to the title
  const dynamicSubIncr = getSubIncrement(props.field.substring(2, 4));
  // determines the size of the input and the column size for material ui grid
  //const column = getColumnTotal(childrenOfRows, props.field); UNUSED- marked for deletion
  // handles determining the breakpoint and also handles checking if a property exists.
  //const columnBreakpoint = getBreakpointGrid(column, true, null); UNUSED- marked for deletion
  // determines the size of the field
  const fieldSize = classes[getInputSize(parseInt(props.size))];
  // disables spouse fields if filing status is 1 or 3
  const disableSpouse = props.singleFLST && props?.tors === 'S';

  return (
    //<Grid item xs={columnBreakpoint} md={columnBreakpoint} lg={'auto'}></Grid>
    <Grid item lg={fieldPadding === null ? 12 : 'auto'}>
      <div className="grid-container-grid-item-div" style={fieldPadding}>
        <FormControl>
          <TextField
            label={
              props.indirect === 'TOP' &&
              (props.dsplymode === 'd' ? `${dynamicSubIncr}. ${props.prompt}` : props.prompt)
            }
            InputLabelProps={{
              shrink: true,
            }}
            required={props.flag?.includes('*')}
            variant="outlined"
            id={props.field}
            name={props.name}
            type="text"
            value={props.form[props.name]}
            onChange={e => {
              e.target.value = validateInputPattern(e.target.value, props.type, 0, props.max);
              props.handleChange(e);
            }}
            onBlur={e => props.onBlurCalc(e)}
            className={`MuiFormHelperText-root-1165 ${fieldSize}`}
            style={style}
            aria-describedby={props.prompt}
            disabled={props.flag === '+' || props.getLockedStatus() || disableSpouse}
            helperText={props.currentInputErrors?.[props.name]}
            error={props.currentInputErrors?.[props.name] !== undefined}
          />
        </FormControl>
        {props.indirect === 'RIGHT' && (
          <>
            <InputLabel htmlFor={props.field} className={classes.sideLabelStyle}>
              {props.dsplymode === 'd' ? `${dynamicSubIncr}. ${props.prompt}` : props.prompt}
              {/* Info bubble */}
              {props.dsplymode === '?' && (
                <img
                  id={props.field}
                  onClick={() => props.handleModal('isInfoModalOpen', props.lineno, props.prompt)}
                  className="container-item-icon"
                  alt={props.lineno}
                  src={infoIcon}
                />
              )}
            </InputLabel>
          </>
        )}
      </div>
    </Grid>
  );
};

export default withStyles(styles)(TextFieldMap);

export const styles = {
  content: {
    top: '25%',
    left: '27%',
    right: 'auto',
    bottom: 'auto',
    height: '366px',
    width: '618px',
    padding: '0',
    boxShadow: '0 0 20px 0 rgba(0,0,0,0.25)',
  },
  lockedMessage: {
    color: 'red',
    display: 'block',
    paddingBottom: '1em',
    textAlign: 'center',
  },
  spaceLeft: {
    marginLeft: '46px',
  },
  spaceBottom: {
    marginBottom: '.5rem',
  },
};

import React from 'react';
// Internal imports
import multiply from '~/images/icons/icons8-multiply.png';
import { MISC_FORMS_LIST, MISC_ABBV_LIST } from '~/app/Pages/WizardMode/wizardModeConstants.js';
// External imports
import { Divider, FormControlLabel, Checkbox, Button, Fade } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
// Styling imports
import { styles } from '~/app/Pages/WizardMode/components/Forms/css/formStyles.js';
import '~/app/Pages/WizardMode/components/Forms/css/formStyles.css';
import '~/app/Pages/WizardMode/components/Forms/css/modalStyles.css';

/**
 * Handles displaying the Misc Form Modal for wizard mode.
 *
 * @component AddMiscForms
 * @category WizardMode
 */
const AddMiscForms = props => {
  const { classes } = props;

  /**
   * Handles rendering Miscaleaneous forms list
   */
  const displayMiscForms = () => {
    const miscFormsList = MISC_ABBV_LIST.map((formName, index) => {
      return (
        <div className="misc-form-row" key={`${formName}`}>
          <FormControlLabel
            labelPlacement="end"
            label={MISC_FORMS_LIST[index]}
            control={
              <Checkbox
                id={formName}
                name={formName}
                checked={props.form[formName]?.value}
                onChange={e => props.handleCheckboxChange(e, 'misc')}
              />
            }
          />
        </div>
      );
    });
    return miscFormsList;
  };

  return (
    <Fade in={props.isMiscModalOpen} timeout={500}>
      <div>
        <div className="modal-header-bar">
          <div className="row modal-header-row">
            <div>Add Miscellaneous Forms</div>
            <img
              onClick={() => props.handleModal('isMiscModalOpen')}
              src={multiply}
              className="modal-multiply-img"
            />
          </div>
        </div>
        {/* Modal Body */}
        <div className="form-list-container">{displayMiscForms()}</div>
        <Divider className={classes.dividerStyle} />
        <div className="container modal-bottom-container">
          <Button
            id="btnCancel"
            onClick={() => props.handleModal('isMiscModalOpen')}
            className="addMiscForm-cancel-button"
          >
            Cancel
          </Button>
          <div className="button-spacer"></div>
          <Button
            id="btnAdd"
            color="primary"
            variant="contained"
            className="addMiscForm-add-button"
            onClick={e => alert('Added Misc Forms')}
          >
            Add
          </Button>
        </div>
      </div>
    </Fade>
  );
};

export default withStyles(styles)(AddMiscForms);

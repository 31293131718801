export const inputReqsMet = {
  paddingTop: "0px",
  paddingBottom: "0px",
  textAlign: "left"
};

export const inputReqsNotMet = {
  paddingTop: "0px",
  paddingBottom: "0px",
  textAlign: "left",
  border: '1px solid #eb888f',
};

// External imports
import React, { useEffect, useRef } from 'react';
import Modal from 'react-modal';
import { Paper } from '@material-ui/core';
// Internal imports
import { useSetState } from '~/app/Utility/customHooks';
import { withRouter } from 'react-router-dom';
import ErrorHelpers from '~/app/errorHelpers.js';
import SimpleDialog from '#/Common/SimpleDialog.jsx';
import {
  CURRENT_FILE_STEPS,
  FOOTER_INFO,
  COMPLETE_SECTION_QUERY,
  COMPLETE_SECTION_NAMES,
} from '~/app/Pages/WizardMode/wizardModeConstants.js';
import WizardSideBarForms from '~/app/Pages/WizardMode/components/SideBarForms/WizardSideBarForms.jsx';
import Spinner from '#/Common/Spinner.jsx';
import SocketWorker from '~/socket.worker.js';
import CalcAPI from '~/app/api/calcAPI';
import XlinkAPI from '~/app/api/xlinkAPI';
import lockIcon from '~/images/icons/icons8-lock_2.svg';
import { SPINNER_DIALOGS, VERIFY_MODAL_TABS } from '~/app/constants';
import { trimNull } from '~/app/Utility/general.js';
import WizardForm from '~/app/Pages/WizardMode/components/Forms/WizardForm.jsx';
import FormViewerContainer from '~/app/Pages/WizardMode/components/Forms/FormViewerContainer.jsx';
import { InfoModal } from '~/app/Pages/WizardMode/components/Forms/modals';
import AddFormModal from '~app/Pages/Returns/components/AddFormModal.jsx';
import {
  buildWizardFormFields,
  buildDropdownOptions,
  buildDropdownOptionsMap,
  buildRequiredFields,
  handleAddForm,
  buildDynamicFields,
} from '~/app/Pages/WizardMode/wizardModeHelpers.js';
import {
  sortObjects,
  buildWizardInitialDynamicFields,
  updateRowProps,
  addLeadingZeros,
} from '~/app/Pages/WizardMode/Mapper/helpers/mapperHelpers.js';
import { FORM_NAMES } from '~/app/constants.js';
// Redux imports
import { useSelector, useDispatch } from 'react-redux';
import { actions as wizardEstimatorActions } from '~/app/redux/modules/wizardEstimator.js';
import { actions as returnProfileActions } from '~/app/redux/returnProfile/duck.js';
import { actions as formViewerActions } from '~/app/redux/modules/formViewer.js';
import { actions as appActions } from '~/app/redux/modules/app.js';
// Styling imports
import '~/app/Pages/WizardMode/css/wizardMode.css';

/**
 * This Component handles displaying the Wizard Mode page as an option to do your tax returns
 * It is used to improve UX by making the process easy to use and user friendly
 *
 * @component WizardMode
 * @category WizardMode
 */
const WizardMode = () => {
  const dispatch = useDispatch();
  const {
    returnID,
    taxYear,
    formList,
    ddh,
    loginID,
    lockedBy,
    lockedViaAccessLevel,
    lockedStatus,
    snackbarMessage,
    addFormList,
    activityList,
    addFormModalOpen,
    wizardEstimator,
    wizardFormFields,
    wizardMappedUI,
    wizardDropdownOptions,
    wizardDropdownMap,
    wizardCurrentStep,
    wizardDynamicFields,
    wizardCompleted,
    isReadOnly,
    requiredFields,
  } = useSelector(state => ({
    returnID: state.returnProfile.returnID,
    taxYear: state.returnProfile.year,
    formList: state.returnProfile.formList,
    ddh: state.drilldown.drilldownHistory,
    loginID: state.app.loggedInUser.userID,
    lockedBy: state.returnProfile.lockedBy,
    lockedViaAccessLevel: state.returnProfile.lockedViaAccessLevel,
    lockedStatus: state.returnProfile.lockedStatus,
    isTrainingMode: state.loginSetup.isTrainingMode,
    usingDemoAccount: state.returnProfile.usingDemoAccount,
    snackbarMessage: state.app.snackbarMessage,
    addFormList: state.formViewer.addFormList,
    activityList: state.formViewer.activityList,
    addFormModalOpen: state.formViewer.addFormModalOpen,
    wizardEstimator: state.wizardEstimator,
    wizardFormFields: state.wizardEstimator.wizardFormFields,
    wizardMappedUI: state.wizardEstimator.wizardMappedUI,
    wizardDropdownOptions: state.wizardEstimator.wizardDropdownOptions,
    wizardDropdownMap: state.wizardEstimator.wizardDropdownMap,
    wizardCurrentStep: state.wizardEstimator.wizardCurrentStep,
    wizardDynamicFields: state.wizardEstimator.wizardDynamicFields,
    wizardCompleted: state.returnProfile.wizardCompleted,
    isReadOnly: state.returnProfile.isReadOnly,
    requiredFields: state.wizardEstimator.requiredFields,
  }));

  const [state, setState] = useSetState({
    requiredFields: {}, // helper func scans and adds each input for required indicator, then adds their fieldID
    // remove this when required fields is done
    validatedForms: {
      isTaxPayerInfoValid: false,
      isChildrenAndDependentsValid: false,
      isIncomeValid: false,
      isDeductionsValid: false,
      isCreditsValid: false,
      isStateIncomeTaxValid: false,
      isMiscFormsValid: false,
      isResultsValid: false,
    },
    hasFormChanged: false, // If form has changed, the save button will become available and the user will not see dialogs
    isLocked: false,

    // Modal state
    isFLSTRequiredOpen: false, // this pops up like a required field error. Surely there will be more, refactoer soon
    isInfoModalOpen: false,
    isPkgDeleteModalOpen: false,
    isDeleteDialogOpen: false,
    isAssetDeleteDialogOpen: false,
    rqdFieldDlgOpen: false, // Error dialogue box for required fields
    infoMessage: '',
    infoPrompt: '',
    removeForm: '',

    // asset logic
    selectedAsset: '',

    // Calc Server state
    returnInitialized: false, // Grab this from redux - or calcServer?
    isLoading: true, // handles spinner and waiting for calcserver response
    initialSpecialFormLoad: false,
    shouldLockActions: true, // locks actions while spinner is active
    formViewerCalcAPI: undefined, // this needs to be passed to the ReturnFormList in the sidebar
    formViewerHeader: undefined,
    assetMode: false, // this needs to be updated from the add form requests
    currentInputErrors: {}, // works with snackbarMessage store to use the initialField as the key and the designated message as the value
    currentFormNameString: '',
    footerTansparent: false,
  });

  const socketWorker = useRef();
  const calcAPI = useRef();
  const refWizardEstimator = useRef(wizardEstimator); // issue with web hooks - only grabbing initial state - this saves the ref of last redux save
  const refWizardDynamicFields = useRef(wizardDynamicFields);
  const refWizardMappedUI = useRef(wizardMappedUI);
  const refWizardCurrentStep = useRef(wizardCurrentStep);
  const loadingDynamicForms = useRef(false);
  const mainDivRef = useRef();

  // onmount return setup - unmount Close Websocket and reset wizard redux
  useEffect(() => {
    newSocketInit();
    getLockedStatus();

    dispatch(returnProfileActions.setInitialForm(FORM_NAMES.EST, ''));
    // set the initial load, if they completed questionarre start on the return list
    if (wizardCompleted) {
      dispatch(wizardEstimatorActions.updateWizardCurrentStep(9));
      // form viewer has its own spinner, set isLoading to false
      setState({ isLoading: false });
    } else {
      setState({ currentFormNameString: CURRENT_FILE_STEPS[0] });
      dispatch(wizardEstimatorActions.updateWizardCurrentStep(0));
    }
    return () => {
      dispatch(wizardEstimatorActions.resetWizardEstimator());
      calcAPI.current && calcAPI.current.CloseSession();
    };
  }, []);

  // Handles checking incoming calc responses, and applies the message to the current input
  useEffect(() => {
    // if error is coming from a field calc then in the return_form_error response there will be an initialField
    if (
      snackbarMessage.message !== '' &&
      snackbarMessage.severity === 'error' &&
      snackbarMessage.initialField !== null
    ) {
      setState({
        currentInputErrors: {
          ...state.currentInputErrors,
          // concat formname and the field with the error -> US1WPDOB
          [state.currentFormNameString + snackbarMessage?.initialField]: snackbarMessage?.message,
        },
      });
    }
  }, [snackbarMessage]);

  const setFooterbyScrollHeight = () => {
    if (mainDivRef.current) {
      const { clientHeight, scrollHeight } = mainDivRef.current;
      if (scrollHeight <= clientHeight) {
        setState({ footerTansparent: true });
      } else {
        setState({ footerTansparent: false });
      }
    }
  };

  useEffect(() => {
    setFooterbyScrollHeight();
  }, [mainDivRef.current?.scrollHeight]);

  useEffect(() => {
    // upon change forms, reset the Wizard Body to Scroll to Top
    document.getElementById('wizardBody')?.scroll(0, 0);
    setFooterbyScrollHeight();

    const prevStep = refWizardCurrentStep.current;
    refWizardCurrentStep.current = wizardCurrentStep;
    // when switching wizard forms, call form_load again to account for any waterfall calculations
    // calcAPI is not rendered yet, when wizardCurrentStep changes, and was silently failing in ErrorHelpers on initial render
    if (wizardCurrentStep !== 9 && wizardCurrentStep !== null && calcAPI.current) {
      saveReturnOnStep(prevStep);
      setState({
        currentFormNameString: CURRENT_FILE_STEPS[wizardCurrentStep],
      });
      // TODO: calcAPI.ReturnAddForm(), called via handleAddform, currently does nothing when form
      // is already present in return, but it would be preferable not to call again in the first place
      // Currently there is no calc_command to check if a form is attached.
      startSpin('Loading...', true);
      handleAddForm(CURRENT_FILE_STEPS[wizardCurrentStep], calcAPI.current, loadingDynamicForms);
    }
  }, [wizardCurrentStep]);

  // Handles assigning any change in the wizardEstimator redux store, and saving it as a reference to use
  useEffect(() => {
    refWizardEstimator.current = wizardEstimator;
  }, [wizardEstimator]);

  useEffect(() => {
    refWizardDynamicFields.current = wizardDynamicFields;
  }, [wizardDynamicFields]);

  useEffect(() => {
    refWizardMappedUI.current = wizardMappedUI;
  }, [wizardMappedUI]);

  /**
   * Handles creating the initial web socket connection to Calc Server
   *
   * @param {number} rtnID used to find the return ID of the return
   */
  const newSocketInit = async (rtnID = 0) => {
    if (rtnID === 0) {
      rtnID = returnID;
    }

    // Async websocket manager
    socketWorker.current = new SocketWorker();
    socketWorker.current.onmessage = onSocketResponse;
    calcAPI.current = await new CalcAPI(socketWorker.current, parseInt(rtnID), ddh, true);
  };

  /**
   * Handles receiving responses from calc server and determining the logic to apply with the response
   *
   * @param {Object} evt response back from Calc Server
   */
  const onSocketResponse = async evt => {
    if (!evt && !evt.data) {
      return;
    }

    switch (evt.data) {
      case 'SOCKET_OPEN':
        await calcAPI.current.ReturnInit(loginID, false, null);
        break;
      case 'REFRESH':
        startSpin('Reconnecting. Please Wait...', true);
        await calcAPI.current.CloseSocket();
        newSocketInit(returnID);
        break;
      case 'CRITICAL':
        await calcAPI.current.CloseSocket();
        console.log(
          'CRITICAL state from socket worker, all messages dropped. Attempting to reopen websocket connection...',
        );
        newSocketInit(returnID);
        break;
      default:
        console.log(`No main case commands hit, data passed through. Ignoring...`);
        break;
    }

    // checking if data property is JSON or a cs command - if cs command, short circuit to avoid parsing error
    try {
      JSON.parse(trimNull(evt.data));
    } catch (e) {
      return;
    }

    const wsCommand = JSON.parse(trimNull(evt.data));
    console.log('received ', wsCommand.command, ' with guid ', wsCommand.msgguid);
    let formData = {};
    let wizardFields;
    let mappedUI;
    let initialFields;
    let errorObj;
    let requiredFields;

    try {
      switch (wsCommand.command) {
        case 'form_load':
          // All forms are loaded in formData as an Array of object of the pages/forms
          formData = JSON.parse(trimNull(wsCommand.formdata));
          // having an arg means we loaded a worksheet. Grab the data for dynamic forms, but skip the mapping
          if (formData?.arg) {
            loadingDynamicForms.current = false;
            wizardFields = buildDynamicFields(formData.arg);
            updateDynamicFieldsInitial(formData.arg.form, wizardFields.additionalRowCounts);
            dispatch(
              wizardEstimatorActions.setWizardFormFieldandCounters(
                formData.arg.form,
                wizardFields.fieldList,
                wizardFields.additionalRowCounts,
              ),
            );
            break;
          }
          // Takes in the formData to
          wizardFields = buildWizardFormFields(formData);
          // All fields for a form sorted into a single array
          mappedUI = sortObjects(formData);
          // sending formName to determine where to save, and the Field/Values
          dispatch(wizardEstimatorActions.setWizardFormFields(formData.name, wizardFields));
          // sending formName to determine where to save, and the formData
          dispatch(wizardEstimatorActions.setWizardMappedUI(formData.name, mappedUI));

          // saving the initial dynamic fields, page by page
          initialFields = buildWizardInitialDynamicFields(mappedUI);
          dispatch(
            wizardEstimatorActions.setWizardInitialDynamicFields(formData.name, initialFields),
          );

          // handles adding required fields state
          requiredFields = buildRequiredFields(mappedUI);
          setState({
            requiredFields: requiredFields,
          });

          break;
        case 'return_init': {
          // only call return_init once
          // Since we're receiving a return_init from the API, we can safely trigger a form_load
          if (!state.returnInitialized) {
            // need to add form USTE, this is the base form of all wizard forms
            await calcAPI.current.ReturnAddForm(FORM_NAMES.EST, '00');

            // return_init is ran once, if wizard is not completed allow form_load - if websocket closes and needs re-init, check for current step, at this point it will not be nul
            if (!wizardCompleted || refWizardCurrentStep.current < 9) {
              // handles making return_addform cmd request then a form_load request - return_init means it shouldbe step 0
              handleAddForm(CURRENT_FILE_STEPS[0], calcAPI.current);
            }
            const returnInit = JSON.parse(trimNull(wsCommand.data));

            const calcResponse = JSON.parse(trimNull(returnInit.calcResponse));
            dispatch(returnProfileActions.setActiveReturnFormList(calcResponse.arg.NavPanelItems));

            if (Object.prototype.hasOwnProperty.call(returnInit, 'locked_by')) {
              dispatch(
                returnProfileActions.lockReturn(returnInit.locked_by, returnInit.lockedByLoginID),
              );
            }

            setState({ returnInitialized: true });
            await calcAPI.current.SetReturnInitStatus(true);
          }

          break;
        }
        case 'return_choicelist': {
          const dropdownData = JSON.parse(trimNull(wsCommand.data)).arg?.ExplicitType;

          // Accessing most recent current step state from the return profile ref
          const { wizardCurrentStep } = refWizardEstimator.current;

          // Parses response to build Array of strings
          const options = buildDropdownOptions(dropdownData?.Data);
          const optionsMap = buildDropdownOptionsMap(options, dropdownData?.Data);

          // building form name format based on what page they are currently on -> US1W + 0010 -> US1W0010
          const currentForm =
            CURRENT_FILE_STEPS[wizardCurrentStep].substring(0, 4).toUpperCase() +
            dropdownData.InitialField.value.substring(0, 4);

          // sends the built options Array and the field name to be saved as a key, so we know which field it goes to
          dispatch(wizardEstimatorActions.updateWizardDropdownOptions(options, currentForm));
          // store options map, values in map are what we will actully send to calcServer
          dispatch(wizardEstimatorActions.updateWizardDropdownMap(optionsMap));
          break;
        }
        case 'update_form': {
          const form = JSON.parse(wsCommand.data);

          const currentForm = CURRENT_FILE_STEPS[refWizardCurrentStep.current];
          UpdateWizardForm(currentForm, form.arg.fields);
          // Successful response from 'calc' that calc server has received/accepted updated value
          // setting the formHasChanged logic to true - letting the user save
          setState({ hasFormChanged: true });
          if (form?.arg?.NavPanelItems) {
            dispatch(returnProfileActions.setActiveReturnFormList(form.arg.NavPanelItems));
          }
          break;
        }
        case 'error':
          stopSpin();
          dispatch(
            appActions.showSnackbarMessage('Error with action', 'error', 3500, {
              vertical: 'top',
              horizontal: 'center',
            }),
          );
          break;
        case 'invalid_request':
          console.log('Invalid Request with data: ' + wsCommand.data);
          break;
        case 'return_close':
          stopSpin();
          await calcAPI.current.CloseSession();
          break;
        case 'return_addform':
          formData = JSON.parse(trimNull(wsCommand.data));
          break;
        // This is where the blueprint of how we should send is.
        case 'return_saveclose':
          stopSpin();
          await calcAPI.current.CloseSession();
          break;

        // This is where the blueprint of how we should send is.
        case 'return_save':
          // when the form is saved, saving UI reset the logic
          setState({ hasFormChanged: false });
          stopSpin();
          dispatch(appActions.hideSnackbarMessage());
          break;
        case 'start_spin':
          startSpin(SPINNER_DIALOGS.PROCESS_REQUEST);
          break;
        case 'stop_spin':
          if (loadingDynamicForms.current) {
            break;
          }
          stopSpin();
          break;
        case 'field_calc':
          // TEMP - we do not need to listen to this cmd
          formData = JSON.parse(trimNull(wsCommand.data));
          break;
        case 'return_form_error':
          errorObj = JSON.parse(trimNull(wsCommand.data));
          dispatch(
            appActions.showSnackbarMessage(
              errorObj.error || 'Error saving last input action.',
              'error',
              3500,
              {
                vertical: 'top',
                horizontal: 'center',
              },
              errorObj.initialField,
            ),
          );
          break;
        case 'NOTIFY_USER': // Use this to notify the user of any hangups/connection issues with the websocket. TODO: This should be refactored to be cleaner.
          switch (wsCommand.promptType) {
            case 'SOCKET_START_SPINNER':
              startSpin(wsCommand.message, true);
              break;
            case 'SOCKET_STOP_SPINNER':
              stopSpin();
              break;

            case 'SOCKET_SNACKBAR_ERROR':
              dispatch(
                appActions.showSnackbarMessage(wsCommand.message, 'success', 3500, {
                  vertical: 'top',
                  horizontal: 'center',
                }),
              );
              break;
          }
          break;

        default:
          console.log(`${evt.data} received. Ignoring...`);
          stopSpin();
          break;
      }
    } catch (err) {
      console.log('Error:', err);
    }
  };

  /**
   * Handles updating the dynamic field that keeps track of how many should be displayed
   * Handles modifiying wizardFormFields and wizardMappedUI depending on if user adds or removes
   *
   * @param {string} formName name of the form the dynamic fields belong to -> US1W, US2W
   * @param {string} fieldNames all individual field names combined to one string separated by commas, every two characters is a field -> AA,AB
   * @param {boolean} isAdding adding and removing logic
   * @param {number} index index of the 'Add More' button inside mapped UI
   */
  const updateDynamicFields = (formName, fieldNames, isAdding, index, initialLoad) => {
    const modifiedFieldNames = fieldNames.split(',').join(''); // AA,AB -> AAAB
    const currentForm = wizardMappedUI[formName];
    // access to dynamic fields rdx state and the most recent field created

    const currentFields = wizardDynamicFields[formName][modifiedFieldNames];
    const recentField = currentFields[currentFields.length - 1];

    if (isAdding) {
      // get the last incre and add one to get the next incre
      const fieldIncrement = parseInt(recentField) + 1;
      const newIncr = (fieldIncrement < 10 ? `0${fieldIncrement}` : fieldIncrement).toString();
      // adds new field to the current dynamic fields state. // -> AAAB: ['03', '04']
      currentFields.push(newIncr);

      // handles adding fieldID's to the wizardFormFields and Objects to wizardMappedUI
      fieldNames.split(',').forEach((fieldName, currentIndex) => {
        const newField = `${fieldName}${newIncr}`;
        // field definitions logic
        const fieldID = `${formName}${newField}`;

        if (!initialLoad) {
          dispatch(wizardEstimatorActions.updateWizardFormFields(formName, fieldID, ''));
        }

        const initialFieldName = `${fieldName}${recentField}`;
        // get copy of initial field object - apply new properties to the new copy object
        const getInitialField = currentForm.find(obj => obj.field === initialFieldName);
        const initialField = Object.assign({}, getInitialField); // ran into an issue using shallow copies

        // apply updated properties to the blueprint
        if (initialField) {
          initialField.field = newField;
          initialField.name = fieldID;
          initialField.row = addLeadingZeros(initialField.row, 4);
        }
        // using index of the button to insert the new fields above the Add button
        currentForm.splice(index + currentIndex, 0, initialField);
        // update ALL objects 'row' property by +1, layout is determined by row and we need to scoot the rest of the ui down.
        updateRowProps(currentForm, fieldNames, index, newIncr);

        // update wizardMappedUI with added fields
        dispatch(wizardEstimatorActions.updateWizardMappedUI(formName, currentForm));
      });

      // remove field logic
    } else {
      // delete last field iteration of currentFields
      currentFields.pop();
      let updatedMappedUI = wizardMappedUI[formName];

      // handles iterating over each field in fieldNames and uses fieldID's to remove wizardFormFields and wizardMappedUI
      fieldNames.split(',').forEach(fieldName => {
        const field = `${fieldName}${recentField}`;

        // field definitions logic
        const fieldID = `${formName}${field}`;
        dispatch(wizardEstimatorActions.removeWizardFormField(formName, fieldID));

        // filtering and leaving out any fields that match
        updatedMappedUI = updatedMappedUI.filter(fieldObj => fieldObj.field !== field);
        // send calc command to calcserver to empty fields
        const formNameOcc = formName + '01';
        updateWizardField(formNameOcc, '', field, '', '1', false);
      });

      // pass in the new updated mapped UI with the wanted fields deleted
      dispatch(wizardEstimatorActions.updateWizardMappedUI(formName, updatedMappedUI));
    }
    // update wizardDynamicFields to keep track of the incre of fields
    dispatch(
      wizardEstimatorActions.updateWizardDynamicFields(formName, modifiedFieldNames, currentFields),
    );
  };

  /**
   * Handles updating the dynamic field that keeps track of how many should be displayed
   * Handles modifiying wizardFormFields and wizardMappedUI depending on if user adds or removes
   *
   * @param {string} formName name of the form the dynamic fields belong to -> US1W, US2W
   * @param {string} fieldNamesList object containing count of how many additioanl fields must be created for each dynamic field pair
   */
  const updateDynamicFieldsInitial = (formName, fieldNamesList) => {
    const modifiedDynamicList = refWizardDynamicFields.current[formName];

    // get a copy of the current form
    const currentForm = refWizardMappedUI.current[formName].map(a => {
      return { ...a };
    });

    for (const fieldNames in fieldNamesList) {
      const modifiedFieldNames = fieldNames.split(',').join('');
      const additionalRowCount = fieldNamesList[fieldNames];
      // we will aways display the initial row of '03'
      const currentFields = ['03'];

      let placementIndex;
      // in the currentForm look for the the object containing the fieldNames and retrive index
      // this is where we place the next field
      currentForm.some((obj, index) => {
        if (obj.font === 'LinePrinter' && obj.text === fieldNames) {
          placementIndex = index;
          return true;
        }
        return false;
      });

      for (let i = 0; i < additionalRowCount; i++) {
        const recentField = currentFields[currentFields.length - 1];
        // get the last incre and add one to get the next incre: 03 ->04
        const fieldIncrement = parseInt(recentField) + 1;
        const newIncr = (fieldIncrement < 10 ? `0${fieldIncrement}` : fieldIncrement).toString();
        currentFields.push(newIncr);

        // adds new field to the current dynamic fields state. // -> AAAB: ['03', '04']
        fieldNames.split(',').forEach((fieldName, currentIndex) => {
          const newField = `${fieldName}${newIncr}`;
          // field definitions logic
          const fieldID = `${formName}${newField}`;

          const initialFieldName = `${fieldName}${recentField}`;

          // get copy of initial field object - apply new properties to the new copy object
          const getInitialField = currentForm.find(obj => obj.field === initialFieldName);
          const initialField = Object.assign({}, getInitialField); // ran into an issue using shallow copies

          if (initialField) {
            initialField.field = newField;
            initialField.name = fieldID;
            initialField.row = addLeadingZeros(initialField.row, 4);
          }

          // place the field into the specified index of form and update the index of the rows after
          currentForm.splice(placementIndex + currentIndex, 0, initialField);
          updateRowProps(currentForm, fieldNames, placementIndex, newIncr);
        });

        // increment the placementIndex for the next row
        placementIndex += 2;
      }
      modifiedDynamicList[modifiedFieldNames] = currentFields;
    }
    // update wizardMappedUI with added fields and the dynamic Fiels list
    dispatch(wizardEstimatorActions.updateWizardMappedUI(formName, currentForm));
    dispatch(wizardEstimatorActions.updateWizardDynamicFieldsList(formName, modifiedDynamicList));
  };

  /**
   * Handles getting dropdown options onClick
   *
   * @param {string} formName is used to determine what form the field is coming from
   * @param {string} fieldName is used to determine what field is needing the dropdown options
   */
  const getDropdownOptions = async (formName, fieldName) => {
    await calcAPI.current.ReturnChoiceList(formName.substring(0, 4), fieldName);
  };

  /**
   * Starts Spinner component
   *
   * @param {string} loadingText text displayed in Spinner component
   * @param {boolean} lockAction boolean state to decide whether to lock UI actions
   */
  const startSpin = (loadingText, lockAction = true) => {
    setState({
      isLoading: true,
      loadingText: loadingText || state.loadingText,
      shouldLockActions: lockAction,
    });
  };

  /**
   * Stops Spinner component
   *
   * @param {boolean} lockAction boolean state to decide whether to lock UI actions
   */
  const stopSpin = (lockAction = false) => {
    setState({
      isLoading: false,
      shouldLockActions: lockAction,
    });
  };

  /**
   * This handles updating state for another helper to use to display a specific component.
   * Step typically will use next/back functionality and only incre/decre by 1
   * However, if the user is able to click on the sidebar to nav, if will pass the targeted components step.
   *
   * @param {number} step is a number to determine what step the user is on
   */
  const handleStep = step => {
    if (requiredFieldsComplete()) {
      dispatch(wizardEstimatorActions.updateWizardCurrentStep(step));
    } else {
      // display error message stating not all required fields have been entered
      setState({ rqdFieldDlgOpen: true });
    }
  };

  /**
   * Checks if a current form has all required fields filled.
   *
   * return boolean representing if form has all required fields completed
   */
  const requiredFieldsComplete = () => {
    // Inside actual tax return, return true
    if (refWizardCurrentStep.current === 9) return true;

    // the current form i.e USW1
    const currentForm = CURRENT_FILE_STEPS[refWizardCurrentStep.current];
    // required field List
    const fieldList = requiredFields[currentForm];
    for (let i = 0; i < fieldList.length; i++) {
      // field represents a required field we are currently checking contains data
      const field = fieldList[i];
      if (!wizardFormFields[currentForm][field]) return false;
    }

    return true;
  };

  /** Updates a modal's open state state */
  const handleModal = (activeModal, infoMessage = '', infoPrompt = '') => {
    setState({
      [activeModal]: !state[activeModal],
      infoMessage: infoMessage,
      infoPrompt: infoPrompt,
    });
  };

  /**
   * Handles calc req onChange when user loses focus of input. Reduces requests
   *
   * @param {e} e eventDOM used to get value and name properties from the current input
   */
  const onBlurCalc = (e, numeric = false) => {
    let value = e.target.value.toUpperCase();
    const name = e.target.name;
    const formName = name.substring(0, 4);

    // If the field is numeric, trim out the $ prefix
    if (numeric) {
      value = value.replace('$', '');
    }

    // updates calc servers form
    const field = name.substring(4);
    const formCode = formName + '01';
    updateWizardField(formCode, '', field, value, '1', false);
  };

  /**
   * Handles updating form to redux
   *
   * @param {Object} e EventDOM used to get value and name
   * @param {Object} form We need to send the entire form to update the current state of redux and then sending to calc
   */
  const handleChange = (e, isDropdown = false, fieldType = '') => {
    const value = e.target.value.toUpperCase();
    const name = e.target.name;
    const formName = name.substring(0, 4);

    // updates form in redux
    dispatch(wizardEstimatorActions.updateWizardFormFields(formName, name, value));

    // the onBlur handler does not work with dropdowns, as the dropdown list is not apart of the input and will lose focus.
    if (isDropdown) {
      // updates calc servers form
      const field = name.substring(4);
      const formCode = formName + '01';
      // get all choice row values via the dropdown map, send this to calcserver
      let fieldValue;
      if (fieldType === 'Q') {
        fieldValue = value === 'YES' ? 'Y' : 'N';
      } else {
        // get all choice row values via the dropdown map, send this to calcserver
        fieldValue = wizardDropdownMap[value];
      }
      updateWizardField(formCode, '', field, fieldValue, '1', isDropdown);
    }

    // Leave the UI error Until the user edits the input again
    if (state.currentInputErrors[name]) {
      const temp = state.currentInputErrors;
      delete temp[name];
      setState({
        currentInputErrors: { ...temp },
      });
    }
  };

  /**
   * Handles all form's CHECKBOX'S/SWITCH'S on change by checking the param formName to determine which form/step the user is currently on
   * And only updating that specfic form
   *
   * @param {Object} e DOMevent used to get the target name and value of an input
   * @param {Object} form We need to send the entire form to update the current state of redux and then sending to calc
   */
  const handleCheckboxChange = e => {
    const value = e.target.checked ? 'X' : '';
    const name = e.target.name;
    // 'name' is the full fieldID we just need the form name US1WAA03 -> US1W
    const formName = name.substring(0, 4);

    // updates form in redux
    dispatch(wizardEstimatorActions.updateWizardFormFields(formName, name, value));

    // updates calc servers form
    const field = name.substring(4);
    const formCode = formName + '01';
    updateWizardField(formCode, '', field, value, '1', false);
  };

  /** Handles toggling the Filing status require modal */
  const handleFilingStatusModal = boolVal => {
    setState({ isFLSTRequiredOpen: boolVal });
  };

  /** Handles saving the return on current step change */
  const saveReturnOnStep = async prevStep => {
    try {
      // If on the State Income Tax page, silently save the return to add/remove state package
      if (prevStep !== 9 && prevStep !== 8) {
        await XlinkAPI.completeWizardSection(returnID, COMPLETE_SECTION_QUERY[prevStep]);
        dispatch(wizardEstimatorActions.updateWizardFormStatus(COMPLETE_SECTION_NAMES[prevStep]));
      }
      await calcAPI.current.ReturnSave(getReturnMetadata(), isReadOnly, true);
      // silent save
      // dispatch(
      //   appActions.showSnackbarMessage(`Return Saved`, 'success', 3500, {
      //     vertical: 'top',
      //     horizontal: 'center',
      //   }),
      // );
    } catch (error) {
      dispatch(
        appActions.showSnackbarMessage(`Error Saving Return`, 'error', 3500, {
          vertical: 'top',
          horizontal: 'center',
        }),
      );
    }
  };

  /**
   * Handles fetching the metadata to save
   *
   * @returns {Array} return metadata used for saving the return
   */
  const getReturnMetadata = () => {
    const metaData = [];

    for (let i = 1; i < formList.length; i++) {
      if (formList[i].desc && formList[i].desc.trim() === 'FEDERAL') {
        let fedRefundOrBalDue;
        if (formList[i].rfnd) {
          fedRefundOrBalDue = parseInt(formList[i].rfnd.substring(1).replace(/,/g, ''), 0);
        } else if (formList[i].bdue && formList[i].bdue !== '') {
          fedRefundOrBalDue = parseInt(formList[i].bdue.replace('$', '-').replace(/,/g, ''), 0);
        }
        metaData.push({
          stateName: 'FEDERAL',
          refundOrBalDue: fedRefundOrBalDue,
          season: taxYear + 1, // temp until we find where season is stored
        });
      } else if (formList[i].var?.length <= 2) {
        // exclude interview mode forms
        const stateName = formList[i].desc;
        let stateRefundOrBalDue;
        if (formList[i].rfnd) {
          stateRefundOrBalDue = parseInt(formList[i].rfnd.substring(1).replace(/,/g, ''), 0);
        } else if (formList[i].bdue) {
          stateRefundOrBalDue = parseInt(formList[i].bdue.replace('$', '-').replace(/,/g, ''), 0);
        }
        metaData.push({
          stateName: stateName.trim(),
          refundOrBalDue: stateRefundOrBalDue,
          season: taxYear + 1, // temp until we find where season is stored
        });
      }
    }

    return metaData;
  };

  /**
   * Handles opening the verify return modal
   *
   * @param {string} activeTab used to determine which tab the user should start on
   */
  const verifyReturn = async (activeTab = VERIFY_MODAL_TABS.VERIFY) => {
    try {
      await state.formViewerCalcAPI.ReturnVerify();
      dispatch(formViewerActions.setRejectTab(activeTab));
    } catch (error) {
      ErrorHelpers.handleError('Error opening verify return: ', error);
    }
  };

  /**
   * Handles sending the request to calc server to update a field
   *
   * @param {string} formName name of the current form being edited -> cle1
   * @param {string} AstCalc this should always be empty quotes, backend has a comment not to use
   * @param {string} fieldID complete name of the field -> cle101AA01
   * @param {string} val the state of the current input
   * @param {string} returnID the id of the return
   * @param {boolean} isChoiceList flag if field is ChoiceList
   */
  const updateWizardField = async (
    formName,
    AstCalc,
    fieldID,
    val,
    returnID,
    isChoicelist = false,
  ) => {
    try {
      if (isChoicelist) {
        await calcAPI.current.ReturnChoiceListCalc(formName.toUpperCase(), fieldID, val);
      } else {
        await calcAPI.current.ReturnCalc(formName.toUpperCase(), AstCalc, fieldID, val, returnID);
      }
    } catch (error) {
      ErrorHelpers.handleError('Error updating wizard return', error);
    }
  };

  /** checks to if return has a filing status entered on the CDS screen, if not notify user */
  const hasFLST = () => {
    // how will we know if filingstatus is always the same field id
    const filingStatus = wizardFormFields.US1W?.US1W0010;
    if (!filingStatus || filingStatus === '0') {
      return false;
    }
    return true;
  };

  /**
   * Handles locked logic for UI
   *
   * @returns {boolean} used for checking if the return should be locked
   */
  const getLockedStatus = () => {
    if (lockedBy || lockedViaAccessLevel || lockedStatus) {
      return true;
    } else {
      return false;
    }
  };

  /** Helper Function to get formViewer's CalcAPI */
  const setFormViewerCalcAPI = calcAPI => {
    setState({ formViewerCalcAPI: calcAPI });
  };

  /** Helper Function to get formViewer's Header */
  const setFormViewerHeader = formViewerHeader => {
    setState({ formViewerHeader: formViewerHeader });
  };

  const UpdateWizardForm = (form, updateList) => {
    const updatedForm = {};
    for (const field in updateList) {
      updatedForm[form + updateList[field].field] = updateList[field].value;
    }
    dispatch(wizardEstimatorActions.setWizardFormFields(form, updatedForm));
  };

  /**
   * Determine return locked messaged base on locked by user,
   * locked via access level, or locked status.
   */
  const getLockedMessage = () => {
    let msg;
    let lockedUser = '';

    if (lockedBy) {
      msg = 'Return is being edited by ';
      lockedUser = <p className="secondary-sub-container-message-user">{lockedBy.toUpperCase()}</p>;
    } else if (lockedViaAccessLevel) {
      msg = 'Access levels do not permit editing returns';
    } else if (lockedStatus) {
      msg = 'Return is in read only mode and has been locked';
    }

    return (
      <div>
        <span className="secondary-sub-container-message-text">{'NOTICE: ' + msg}</span>
        {lockedUser}
      </div>
    );
  };

  /**
   *
   * @param {Object} form form details object
   * @param {string} stateName dialog local state used to determine which one is open
   * @param {string} type form type being deleted, this is used to determine which api req to send
   */
  const handleDeleteForm = async (form, stateName, type) => {
    try {
      setState({
        [stateName]: false,
      });
      if (state.formViewerCalcAPI) {
        if (type === 'form') {
          await state.formViewerCalcAPI.ReturnRemoveForm(form.var);
        } else {
          await state.formViewerCalcAPI.ReturnRemovePackage(form.var);
        }
      }
    } catch (error) {
      ErrorHelpers.handleError('Error deleting form: ', error);
    }
  };

  /**
   *
   * @param {Object} form form details object
   * @param {string} stateName dialog local state used to determine which one is open
   */
  const toggleDeleteDialog = (form = null, stateName) => {
    setState({
      removeForm: form,
      [stateName]: !state[stateName],
    });
  };

  const handleAddAsset = activity => {
    setState({
      selectedAsset: activity,
    });
    dispatch(formViewerActions.toggleAddFormList());
    dispatch(formViewerActions.toggleAddAssetModal(true));
  };

  /**
   * Handles adding a form from the index tab
   *
   * @param {Object} form form to add to the formlist
   */
  const handleAddFormIndex = async form => {
    if (typeof form.MSGBOX === 'undefined') {
      try {
        await state.formViewerCalcAPI.ReturnAddForm(form.formOccur, form.TORS);
        dispatch(formViewerActions.toggleAddFormList());
      } catch (error) {
        ErrorHelpers.handleError('Error adding form: ', error);
      }
      return;
    }
    dispatch(formViewerActions.openMsgDialog(form.MSGBOX));
  };

  /**
   * Handles updating form list with added packaged
   * TODO: Need to optimize, seems to be lag when re-rendering on update
   *
   * @param {Object} pkg state package to add to the formlist
   */
  const updateAddFormList = async pkg => {
    // Setting the package that is being used for getting the forms list
    dispatch(formViewerActions.setAddFormPackage(pkg));
    if (pkg === 'ST') {
      const emptyFormList = [];
      dispatch(formViewerActions.setAddFormList(emptyFormList));
    } else {
      try {
        await state.formViewerCalcAPI.ReturnFormList(pkg);
      } catch (error) {
        ErrorHelpers.handleError('Error adding state package: ', error);
      }
    }
  };

  /**
   * Handles opening the add form modal with the desired tab
   *
   * @param {Object} tab the data for the tab selected
   * @param {Number} index of the tab selected
   */
  const openAddFormList = async (tab, index) => {
    const tabLabel = tab?.label === 'Deprecation' ? 'BA' : 'US';
    try {
      setState({
        activeAddFormTab: index,
      });
      // Setting the package that si being used for getting the forms list
      dispatch(formViewerActions.setAddFormPackage(tabLabel));
      // Need flag to know if the function calcAPI.ReturnFormList is being called for the first time, which means we need to open the add form modal
      dispatch(formViewerActions.setFirstAddForm(true));
      // Getting forms list from calc server
      await state.formViewerCalcAPI.ReturnFormList(tabLabel);
    } catch (error) {
      ErrorHelpers.handleError('Error fetching return list: ', error);
    }
  };

  const handleScroll = () => {
    if (mainDivRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = mainDivRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 2 && scrollTop > 0) {
        setState({ footerTansparent: true });
      } else {
        setState({ footerTansparent: false });
      }
    }
  };

  return (
    // Extra check on the pathname for when the user hits back on the browser
    window.location.pathname === '/wizard-mode' && (
      <>
        <div className="mainContainer">
          {/* RENDERS formviewer's header component directly */}
          {state?.formViewerHeader}
          <div className="secondary-container">
            <div id="sideBarFormsContainer">
              <WizardSideBarForms
                handleStep={handleStep}
                wizardCurrentStep={refWizardCurrentStep.current}
                className="sidebarForms"
                handleModal={handleModal}
                formViewerCalcAPI={state.formViewerCalcAPI}
                verifyReturn={verifyReturn}
                toggleDeleteDialog={toggleDeleteDialog}
                openAddFormList={openAddFormList}
                getLockedStatus={getLockedStatus}
                assetMode={state.assetMode}
              />
            </div>
            <div
              className={`secondary-sub-container ${
                refWizardCurrentStep.current === 9 ? 'wizard-formviewer-container' : ''
              }`}
            >
              {getLockedStatus() && (
                <span className="secondary-sub-container-message">
                  <img src={lockIcon} className="header-bar-forms-return-icon" />
                  {getLockedMessage()}
                </span>
              )}

              {/* Handles the switch condition and displaying the currentForm based on the step */}
              {refWizardCurrentStep.current < 9 ? (
                <div
                  className="maincontainer-div-body"
                  ref={mainDivRef}
                  onScroll={() => handleScroll()}
                  id="wizardBody"
                >
                  <WizardForm
                    form={wizardFormFields?.[state.currentFormNameString]}
                    preppedUI={wizardMappedUI?.[state.currentFormNameString]}
                    onBlurCalc={onBlurCalc}
                    handleChange={handleChange}
                    handleStep={handleStep}
                    handleCheckboxChange={handleCheckboxChange}
                    wizardDropdownOptions={wizardDropdownOptions}
                    getDropdownOptions={getDropdownOptions}
                    updateDynamicFields={updateDynamicFields}
                    wizardDynamicFields={wizardDynamicFields?.[state.currentFormNameString]}
                    wizardCurrentStep={refWizardCurrentStep.current}
                    wizardCompleted={wizardCompleted}
                    handleModal={handleModal}
                    footerInfo={FOOTER_INFO[refWizardCurrentStep.current]}
                    currentInputErrors={state.currentInputErrors}
                    returnID={returnID}
                    handleFilingStatusModal={handleFilingStatusModal}
                    hasFLST={hasFLST}
                    getLockedStatus={getLockedStatus}
                    enableFooter={state.footerTansparent}
                    wizardFLST={wizardFormFields.US1W?.US1W0010}
                  />
                </div>
              ) : null}
              {/* This renders Formviewer, and allows it to req its logic, which we then can use. It does not request a form until step 9 */}
              <FormViewerContainer
                setFormViewerCalcAPI={setFormViewerCalcAPI}
                setFormViewerHeader={setFormViewerHeader}
                wizardCurrentStep={refWizardCurrentStep.current}
                handleStep={handleStep}
                wizardHasFLST={wizardFormFields.US1W?.US1W0010}
              />
            </div>
          </div>
          {state.isLoading ? (
            <Spinner
              size={100}
              color="blue"
              loadingText={state.loadingText}
              textColor="white"
              bgColor="grey"
              lockActions={state.shouldLockActions}
            />
          ) : null}
          <Modal
            className="modal-style"
            isOpen={state.isInfoModalOpen}
            shouldCloseOnOverlayClick={false}
          >
            <Paper className="info-modal-container">
              <InfoModal
                handleModal={handleModal}
                infoMessage={state.infoMessage}
                infoPrompt={state.infoPrompt}
                isInfoModalOpen={state.isInfoModalOpen}
              />
            </Paper>
          </Modal>
          <Modal
            id="mdlAddFormModalFormViewer"
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={addFormModalOpen}
            onClose={() => dispatch(formViewerActions.toggleAddFormList())}
            disableBackdropClick={true}
          >
            <Paper elevation={5}>
              <AddFormModal
                activeTab={state.activeAddFormTab}
                availableForms={addFormList}
                availableActivities={activityList}
                openAddFormList={(tab, index) => openAddFormList(tab, index)}
                updateAddFormList={pkg => updateAddFormList(pkg)}
                handleAddForm={form => handleAddForm(form)}
                handleAddFormIndex={form => handleAddFormIndex(form)}
                addAsset={asset => handleAddAsset(asset)}
                handleCloseFormModal={() => dispatch(formViewerActions.toggleAddFormList())}
                formList={formList}
                tabListDefinitions={state.tabListDefinitions}
              />
            </Paper>
          </Modal>
          <SimpleDialog
            open={state.isFLSTRequiredOpen}
            onConfirm={() => setState({ isFLSTRequiredOpen: false })}
            dialogTitle={'Filing Status is required'}
            contentText={
              'ATTENTION: Filing status is required to save the return. Please choose an option.'
            }
            confirmText="OK"
            styled={true}
          />
          <SimpleDialog
            open={state.rqdFieldDlgOpen}
            onConfirm={() => setState({ rqdFieldDlgOpen: false })}
            dialogTitle={'Missing Required Fields'}
            contentText={
              'Not all Required Information has been entered. Please enter information on fields with asterisk (*) symbol.'
            }
            confirmText="OK"
            styled={true}
          />
          <SimpleDialog
            open={state.isPkgDeleteModalOpen}
            disableBackdropClick={true}
            onClose={() => toggleDeleteDialog(null, 'isPkgDeleteModalOpen')}
            onConfirm={() => handleDeleteForm(state.removeForm, 'isPkgDeleteModalOpen', 'pkg')}
            confirmText="Delete"
            styled={true}
            dialogTitle="Remove State"
            contentText={`${state.removeForm?.desc} has been selected for removal. This cannot be undone.`}
          />
          <SimpleDialog
            open={state.isDeleteDialogOpen}
            disableBackdropClick={true}
            aria-labelledby="confirm-delete-title"
            aria-describedby="confirm-delete-desc"
            onClose={() => toggleDeleteDialog(null, 'isDeleteDialogOpen')}
            onConfirm={() => handleDeleteForm(state.removeForm, 'isDeleteDialogOpen', 'form')}
            confirmText="Delete"
            styled={true}
            dialogTitle={`Remove ${state.removeForm?.desc}`}
            contentText={`${state.removeForm?.desc} has been selected for removal. This cannot be undone.`}
          />
          <SimpleDialog
            open={state.isAssetDeleteDialogOpen}
            disableBackdropClick={true}
            aria-labelledby="confirm-delete-title"
            aria-describedby="confirm-delete-desc"
            onClose={() => toggleDeleteDialog(null, 'isAssetDeleteDialogOpen')}
            onConfirm={() => handleDeleteForm(state.removeForm, 'isAssetDeleteDialogOpen', 'form')}
            confirmText="Delete"
            styled={true}
            dialogTitle={`Delete Activity Item: ${state.removeForm?.desc}`}
            contentText={`Preparing to delete ALL assets and related forms associated with this activity. This
        cannot be undone.`}
          />
        </div>
      </>
    )
  );
};

export default withRouter(WizardMode);

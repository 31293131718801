import React from 'react';
// external imports
import { FormControl, InputLabel, Input, Button, AppBar, Toolbar } from '@material-ui/core';
// redux imports
import { useSelector, useDispatch } from 'react-redux';
import { actions as setupPageActions } from '~/app/redux/setupPages/duck';

const UpsertZipcode = props => {
  const dispatch = useDispatch();

  const { isEditingRecord, currentEditRecord } = useSelector(state => ({
    isEditingRecord: state.setupPageState.isEditingRecord,
    currentEditRecord: state.setupPageState.currentEditRecord,
  }));

  const handleInputChange = e => {
    e.persist();
    dispatch(setupPageActions.updateCurrentEditRecord(e));
  };

  const handleUpsert = () => {
    props.handleUserDBUpsert(currentEditRecord);
  };

  const validateFields = () => {
    if (
      currentEditRecord.zip_code == null ||
      currentEditRecord.zip_code === '' ||
      currentEditRecord.city == null ||
      currentEditRecord.city === '' ||
      currentEditRecord.state_code == null ||
      currentEditRecord.state_code === ''
    ) {
      return false;
    }
    return true;
  };

  return (
    <div>
      <AppBar position="static">
        <Toolbar style={styles.toolbar}>{isEditingRecord ? 'Edit ' : 'Add '} Zip Code</Toolbar>
      </AppBar>
      <form style={{ margin: ' 2em' }}>
        <div style={{ textAlign: 'left', marginTop: '1em' }}>
          <div style={styles.sectionHeading}>Zip Code Information</div>
          <div style={{ margin: '0.5em' }}>
            <FormControl>
              <InputLabel style={styles.labelStyles} required={false} shrink>
                Zip Code
              </InputLabel>
              <Input
                id="txtZipCodeUpsertZipCode"
                name="zip_code"
                value={currentEditRecord.zip_code == null ? '' : currentEditRecord.zip_code.trim()}
                onChange={handleInputChange}
                style={styles.inputStyle}
                error={!currentEditRecord.zip_code || currentEditRecord.zip_code === ''}
                inputProps={{
                  maxLength: '9',
                }}
                disableUnderline
              />
            </FormControl>
          </div>
          <div style={{ margin: '0.5em' }}>
            <FormControl>
              <InputLabel style={styles.labelStyles} required={false} shrink>
                City
              </InputLabel>
              <Input
                id="txtZipCodeUpsertZipCity"
                name="city"
                value={currentEditRecord.city}
                onChange={handleInputChange}
                style={styles.inputStyle}
                error={!currentEditRecord.city || currentEditRecord.city === ''}
                inputProps={{
                  maxLength: '50',
                }}
                disableUnderline
              />
            </FormControl>
          </div>
          <div style={{ margin: '0.5em' }}>
            <FormControl>
              <InputLabel style={styles.labelStyles} required={false} shrink>
                State Code
              </InputLabel>
              <Input
                id="txtZipCodeUpsertZipState"
                name="state_code"
                value={currentEditRecord.state_code}
                onChange={handleInputChange}
                style={styles.inputStyle}
                error={!currentEditRecord.state_code || currentEditRecord.state_code === ''}
                inputProps={{
                  maxLength: '2',
                }}
                disableUnderline
              />
            </FormControl>
          </div>
          <div style={{ margin: '0.5em' }}>
            <FormControl>
              <InputLabel style={styles.labelStyles} required={false} shrink>
                District
              </InputLabel>
              <Input
                id="txtZipCodeUpsertZipDistrict"
                name="district"
                value={currentEditRecord.district}
                onChange={handleInputChange}
                style={styles.inputStyle}
                inputProps={{
                  maxLength: '1',
                }}
                disableUnderline
              />
            </FormControl>
          </div>
        </div>
        <div style={{ textAlign: 'right', margin: '2em' }}>
          <Button
            id="btnCancelAddUpsertZipCode"
            color="primary"
            onClick={() => props.handleCloseUpsertModal()}
          >
            Cancel
          </Button>
          <Button
            id="btnUpsertZipCode"
            color="primary"
            style={styles.button}
            onClick={handleUpsert}
            disabled={!validateFields()}
          >
            {isEditingRecord ? 'Save' : 'Add'}
          </Button>
        </div>
      </form>
    </div>
  );
};

const styles = {
  labelStyles: {
    color: '#354052',
    textAlign: 'left',
    fontFamily: 'Roboto',
  },
  button: {
    width: '9em',
    fontSize: '14px',
    borderRadius: '4px',
    marginLeft: '2em',
  },
  toolbar: {
    backgroundColor: '#444A59',
  },
  sectionHeading: {
    color: '#1A173B',
    fontFamily: 'Roboto',
    fontSize: '21px',
    marginBottom: '1em',
  },
  inputStyle: {
    width: '40vw',
    height: '3.5vh',
  },
};

export default UpsertZipcode;

// Setting action types
export const FETCH_DEFAULTS_CALL_REQUEST =
  'xlinkonline/setupPages/defaultsPage/FETCHDEFAULTSREQUEST';
export const FETCH_DEFAULTS_CALL_SUCCESS =
  'xlinkonline/setupPages/defaultsPage/FETCHDEFAULTSSUCCESS';
export const FETCH_DEFAULTS_CALL_FAILURE =
  'xlinkonline/setupPages/defaultsPage/FETCHDEFAULTSFAILED';
export const HANDLE_INPUT = 'xlinkonline/setupPages/defaultsPage/HANDLEINPUT';
export const HANDLE_CHECKBOX = 'xlinkonline/setupPages/defaultsPage/HANDLECHECKBOX';
export const SET_PRINT_PDF_WARNING_MESSAGE =
  'xlinkonline/setupPages/defaultsPage/SETPRINTPDFWARNINGMESSAGE';

// Moving local state to initialState
export const initialState = {
  fetching: false,
  finishedFetching: false,
  error: null,
  // First Column
  jumpCursor: '0',
  jumpCursorID: 1,
  enableReturnOwnerLocking: '0',
  enableReturnOwnerLockingID: 1,
  autoAddFillingOpt: '0',
  autoAddFillingOptID: 1,
  enableDoubleEntry: '0',
  enableDoubleEntryID: 1,
  lockFinishedPaperReturns: '0',
  lockFinishedPaperReturnsID: 1,
  blindEntryNewReturn: '0',
  blindEntryNewReturnID: 1,
  defaultStateRAC: '0',
  defaultStateRACID: 1,
  autoSaveAfterMins: '2',
  autoSaveAfterMinsID: 1,
  autoSaveEnabled: '1',
  autoSaveEnabledID: 1,
  // Second Column
  silentlySaveReturnOnClose: '0',
  silentlySaveReturnOnCloseID: 1,
  requireAutoAdd1040Sch123: '0',
  requireAutoAdd1040Sch123ID: 1,
  // Default "Disable RT Lite to selected"
  disableRTLite: '0', // TODO: Not selectable in xlink18 => Do not allow for checkbox to change true/false?
  maskSSNEIN: '0',
  maskSSNEINID: 1,
  // Year to Year
  excludeForms: '0',
  excludeFormsID: 1,
  excludeDocs: '0',
  excludeDocsID: 1,
  includePreparerInfo: '0',
  includePreparerInfoID: 1,
  includeBankInfo: '0',
  includeBankInfoID: 1,
  // Return Level
  requireThirdPartyDesignee: '0',
  requireThirdPartyDesigneeID: 1,
  requireUserStatus: '0',
  requireUserStatusID: 1,
  disableEIC: '0',
  disableEICID: 1,
  requireReferral: '0',
  requireReferralID: 1,
  requireCellPhoneCarrierInfo: '0',
  requireCellPhoneCarrierInfoID: 1,
  requireEFPMTBankInfoPresent: '0',
  requireEFPMTBankInfoPresentID: 1,
  defaultPreparerInfo8879: '0',
  defaultPreparerInfo8879ID: 1,
  // Verification Level
  dspWarningTypeErrors: '0',
  dspWarningTypeErrorsID: 1,
  dspOverridesErrors: '0',
  dspOverridesErrorsID: 1,
  dspNoVerifyOnUnattachedForms: '0',
  dspNoVerifyOnUnattachedFormsID: 1,
  // PDF Print warning message
  printPDFWarningMessage: '0',
  printPDFWarningMessageID: 1,
  // Misc
  defaultsConfig: {},
};

// Actions for updating input fields and checkboxes
export const actions = {
  // Handle onChange without validation for checkbox
  handleCheckbox: (checked, name) => ({
    type: HANDLE_CHECKBOX,
    checked,
    name,
  }),
  // Handle onChange without validation for input fields
  handleInputChange: (value, name) => ({
    type: HANDLE_INPUT,
    value,
    name,
  }),
  fetchDefaultsConfig: (loginID, season) => ({
    type: FETCH_DEFAULTS_CALL_REQUEST,
    loginID,
    season,
  }),
  requestDefaultsConfigSuccess: (defaultsConfig, season) => ({
    type: FETCH_DEFAULTS_CALL_SUCCESS,
    defaultsConfig,
    season,
  }),
  requestDefaultsConfigError: error => ({
    type: FETCH_DEFAULTS_CALL_FAILURE,
    error,
  }),
  setPrintPDFWarningMessage: printPDFWarningMessage => ({
    type: SET_PRINT_PDF_WARNING_MESSAGE,
    printPDFWarningMessage,
  }),
};

// Reducer with initial state
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DEFAULTS_CALL_REQUEST:
      return {
        ...state,
        fetching: true,
        error: null,
      };
    case FETCH_DEFAULTS_CALL_SUCCESS:
      return {
        ...state,
        fetching: false,
        finishedFetching: true,
        defaultsConfig: action.defaultsConfig,
        jumpCursor: action.defaultsConfig.ZIPF.configValue,
        jumpCursorID: action.defaultsConfig.ZIPF.configID,
        enableReturnOwnerLocking: action.defaultsConfig.LOKF.configValue,
        enableReturnOwnerLockingID: action.defaultsConfig.LOKF.configID,
        autoAddFillingOpt: action.defaultsConfig.AAFO.configValue,
        autoAddFillingOptID: action.defaultsConfig.AAFO.configID,
        enableDoubleEntry: action.defaultsConfig.DBRE.configValue,
        enableDoubleEntryID: action.defaultsConfig.DBRE.configID,
        lockFinishedPaperReturns: action.defaultsConfig.WLOK.configValue,
        lockFinishedPaperReturnsID: action.defaultsConfig.WLOK.configID,
        blindEntryNewReturn: action.defaultsConfig.MSKA.configValue,
        blindEntryNewReturnID: action.defaultsConfig.MSKA.configID,
        defaultStateRAC: action.defaultsConfig.SRDQ.configValue,
        defaultStateRACID: action.defaultsConfig.SRDQ.configID,
        autoSaveAfterMins: action.defaultsConfig.ASAV.configValue,
        autoSaveAfterMinsID: action.defaultsConfig.ASAV.configID,
        autoSaveEnabled: action.defaultsConfig.ASEN.configValue,
        autoSaveEnabledID: action.defaultsConfig.ASEN.configID,
        silentlySaveReturnOnClose: action.defaultsConfig.AUTS.configValue,
        silentlySaveReturnOnCloseID: action.defaultsConfig.AUTS.configID,
        requireAutoAdd1040Sch123: action.season > 2018 && action.defaultsConfig.AASH.configValue,
        requireAutoAdd1040Sch123ID: action.season > 2018 && action.defaultsConfig.AASH.configID,
        disableRTLite: true, // TODO: Not selectable in xlink18 => Check if we should allow for checkbox to change true/false?
        maskSSNEIN: action.defaultsConfig.MKID.configValue,
        maskSSNEINID: action.defaultsConfig.MKID.configID,
        excludeForms: action.defaultsConfig.YTYF.configValue,
        excludeFormsID: action.defaultsConfig.YTYF.configID,
        excludeDocs: action.defaultsConfig.YTYS.configValue,
        excludeDocsID: action.defaultsConfig.YTYS.configID,
        includePreparerInfo: action.defaultsConfig.YTYP.configValue,
        includePreparerInfoID: action.defaultsConfig.YTYP.configID,
        includeBankInfo: action.defaultsConfig.YTYB.configValue,
        includeBankInfoID: action.defaultsConfig.YTYB.configID,
        // Return Level
        requireThirdPartyDesignee: action.defaultsConfig.RTPD.configValue,
        requireThirdPartyDesigneeID: action.defaultsConfig.RTPD.configID,
        disableEIC: action.defaultsConfig.EICX.configValue,
        disableEICID: action.defaultsConfig.EICX.configID,
        requireReferral: action.defaultsConfig.REFV.configValue,
        requireReferralID: action.defaultsConfig.REFV.configID,
        requireCellPhoneCarrierInfo: action.defaultsConfig.CPCI.configValue,
        requireCellPhoneCarrierInfoID: action.defaultsConfig.CPCI.configID,
        requireEFPMTBankInfoPresent: action.defaultsConfig.EPMT.configValue,
        requireEFPMTBankInfoPresentID: action.defaultsConfig.EPMT.configID,
        defaultPreparerInfo8879: action.season > 2020 && action.defaultsConfig.EROP.configValue,
        defaultPreparerInfo8879ID: action.season > 2020 && action.defaultsConfig.EROP.configID,
        // Verification Level
        dspWarningTypeErrors: action.defaultsConfig.ERRW.configValue,
        dspWarningTypeErrorsID: action.defaultsConfig.ERRW.configID,
        dspOverridesErrors: action.defaultsConfig.ERRO.configValue,
        dspOverridesErrorsID: action.defaultsConfig.ERRO.configID,
        dspNoVerifyOnUnattachedForms: action.defaultsConfig.VFYA.configValue,
        dspNoVerifyOnUnattachedFormsID: action.defaultsConfig.VFYA.configID,
        // PDF print warning message
        printPDFWarningMessage: action.defaultsConfig.PDWX.configValue,
        printPDFWarningMessageID: action.defaultsConfig.PDWX.configID,
      };
    case FETCH_DEFAULTS_CALL_FAILURE:
      return {
        ...state,
        fetching: false,
        defaultsConfig: null,
        error: action.error,
      };
    case HANDLE_INPUT:
      return {
        ...state,
        [action.name]: action.value,
      };
    case HANDLE_CHECKBOX:
      return {
        ...state,
        [action.name]: action.checked ? '1' : '0',
      };
    case SET_PRINT_PDF_WARNING_MESSAGE:
      return {
        ...state,
        printPDFWarningMessage: action.printPDFWarningMessage,
      };
  }
  return state;
}

/**
 * @module restrcitedFieldsHelper
 * @category RestrictedFields
 * @subcategory helpers
 */

/**
   * Applies a read-only status to an html element if the data object
   * has a restricted status of true and will be removed for false.
   * This function is ignored if the field was already set read-only due to desktop
   *
   * @function
`  * @param {Array} fields array of objects that contain a fields's value and metadata
   * @param {html} element contains an html element if the fieldID exists
   * @param {boolean} restricted holds a true/false value sent by calcserver
   */
export const updateReadOnlyFieldStatus = fields => {
  for (const field in fields) {
    const elementID = field;
    const element = $(`[id="${elementID}"]`)[0];
    const restricted = fields[field]?.restricted;

    if (element && !element.classList.contains('read-only')) {
      if (restricted) {
        element.classList.add('restricted');
        element.setAttribute('readonly', '');
        element.setAttribute('restricted', true);
      } else {
        element.classList.remove('restricted');
        element.removeAttribute('readonly', '');
        element.setAttribute('restricted', false);
      }
    }
  }
};

/**
 * @param {Function} toggleRestrictedField is a function that will either insert a new field if it does not exist in our DB
 *  or update an existing field to either locked/unlocked depending on it's current status
 * @param {Object} row is an object that stores the field along with metadata about the field
 * @param {string} formOccur is a string of the form + occurence, needed to update field through calcAPI
 * @param {Object} calcAPI calcAPI object needed for websocket messages
 */
export const toggleRestrictedField = (row, formOccur, calcAPI) => {
  calcAPI.UpdateRestrictedFieldStatus(row, formOccur);
};

/**
 * @param {Function} fieldID is a function that creates an 6 digit field form + occurence
 * @param {string/object} activeForm is a string that contains the current form and occurence (ex: US0101)
 * @param {HTML} currentEl is an html element where we access the 'id' attribute to get the current field value (ex: FLST)
 */
export const fieldID = (activeForm, currentEl) => {
  // Some response is changing activeForm to an Object that contains a 'calcResponse' and 'form' property
  if (currentEl && activeForm) {
    if (activeForm?.form) {
      return activeForm.form.slice(0, 4) + currentEl.getAttribute('id').slice(0, -2);
    } else if (activeForm) {
      return activeForm.slice(0, 4) + currentEl.getAttribute('id').slice(0, -2);
    }
  } else {
    return null;
  }
};

// External imports
import React, { Fragment, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import {
  Button,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  List,
  ListItem,
  Paper,
  Typography,
  withStyles,
} from '@material-ui/core';
// Interal imports
import WebHelpers, { displayPrint, statusOK } from '~/app/webHelpers.js';
import Spinner from '#/Common/Spinner.jsx';
import Notifications from '~/app/Components/Common/Notifications/Notifications.jsx';
import AccessControl from '~/app/Components/Auth/AccessControl.jsx';
import {
  shouldDisableSideBarItem,
  isSuperOrTechSupport,
  formatMaskSSNEIN,
} from '~/app/Utility/general.js';
import XlinkAPI from '~/app/api/xlinkAPI';
import { FORM_NAMES, SPINNER_DIALOGS } from '~/app/constants.js';
import ErrorHelpers from '~/app/errorHelpers.js';
import {
  determineAttachmentName,
  isLocked,
} from '~/app/Pages/ReturnProfile/helpers/returnProfileHelpers.js';
// Redux imports
import { useDispatch, useSelector } from 'react-redux';
import { actions as returnProfileActions } from '~/app/redux/returnProfile/duck';
import { actions as appActions } from '~/app/redux/modules/app';
import { actions as wizardEstimatorActions } from '~/app/redux/modules/wizardEstimator.js';
// Image imports
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SubdirectoryArrowLeft from '@material-ui/icons/SubdirectoryArrowLeft';
import downloadIcon from '~/images/icons/downloadIcon.svg';
// Style imports
import './css/returnProfile.css';
import { styles } from './css/returnProfile.js';

/**
 * This displays a high level info of a return such as refund amount,
 * return ID, SSN, tax payer name, and links to forms currently attach
 * to the return.
 *
 * @component ReturnProfile
 * @category Returns
 */
const ReturnProfile = () => {
  const payload = WebHelpers.getJWTPayload();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    clientName,
    fedStatus,
    fedFileDate,
    year,
    primarySSNEIN,
    returnID,
    friendlyID,
    refundBalanceDue,
    notes,
    attachments,
    forms,
    lockedBy,
    currentEntity,
    isLoading,
    lockedStatus,
    lockedByLoginID,
    usingDemoAccount,
    isWizard,
    maskSSNEIN,
    has1040License,
    hasSubsequentLicense,
    hasBusinessLicense,
  } = useSelector(state => ({
    clientName: state.returnProfile.clientName,
    fedStatus: state.returnProfile?.fedStatus,
    fedFileDate: state.returnProfile?.fedFileDate,
    year: state.returnProfile.year,
    primarySSNEIN: state.returnProfile.primarySSNEIN,
    returnID: state.returnProfile.returnID,
    friendlyID: state.returnProfile.friendlyID,
    refundBalanceDue: state.returnProfile.refundBalanceDue,
    notes: state.returnProfile.notes,
    attachments: state.returnProfile.attachments,
    forms: state.returnProfile.formList,
    lockedBy: state.returnProfile.lockedBy,
    currentEntity: state.drilldown.drilldownHistory[state.drilldown.drilldownHistory.length - 1],
    isLoading: state.returnProfile.isLoading,
    lockedStatus: state.returnProfile.lockedStatus,
    lockedByLoginID: state.returnProfile.lockedByLoginID,
    usingDemoAccount: state.returnProfile.usingDemoAccount,
    isWizard: state.returnProfile.isWizard,
    maskSSNEIN: state.defaultsPageState.maskSSNEIN,
    has1040License: state.officeProfile.has1040License,
    hasSubsequentLicense: state.officeProfile.hasSubsequentLicense,
    hasBusinessLicense: state.officeProfile.hasBusinessLicense,
  }));

  useEffect(() => {
    dispatch(returnProfileActions.setAccountType(payload.account_code === 'CLODMO'));
    if (!returnID) {
      return;
    }

    dispatch(returnProfileActions.fetchReturnProfile(returnID));
    window.addEventListener('popstate', () => {
      dispatch(returnProfileActions.closeReturnProfile());
    });

    return () => {
      dispatch(returnProfileActions.closeReturnProfile());
      window.addEventListener('popstate', () => {
        dispatch(returnProfileActions.closeReturnProfile());
      });
    };
  }, [returnID]);

  const shouldReturnProfileLock = () => {
    return (
      lockedBy ||
      lockedByLoginID === currentEntity?.loginID ||
      isLoading ||
      friendlyID?.length === 0
    );
  };

  const onOverviewTableClick = async (form, file) => {
    if (form === '000000') {
      // regular return
      form = FORM_NAMES.CDS;
    } else if (form === 'USTE01') {
      // guided estimator return
      form = FORM_NAMES.EST;
    }

    dispatch(returnProfileActions.setInitialForm(form, file));
    // prevent business mode guided estimnator
    if (form === FORM_NAMES.EST && isWizard && !payload?.is_business) {
      try {
        const res = await XlinkAPI.getWizardFormStatus(returnID);
        if (statusOK(res)) {
          dispatch(wizardEstimatorActions.setWizardFormStatus(res.data));
          history.push({ pathname: '/wizard-mode' });
        }
      } catch (err) {
        ErrorHelpers.handleError('Failed to fetch wizard form status', err);
      }
    } else {
      history.push({ pathname: '/tax-return' });
    }
  };

  const getFederalTable = () => {
    const federalColumns = forms?.length > 0 ? forms[1]?.Children : false;

    if (federalColumns) {
      const disabledMsg = isWizard ? 'This link is disabled for Guided Estimator tax returns' : '';
      const federalInfo = federalColumns?.map((res, index) => {
        if (res.desc !== 'BinaryAttachments') {
          return (
            <tr key={res.desc + index}>
              <td className="forms-link-col forms-link-desc-col">{res.desc}</td>
              <td className="forms-link-col">
                {hasLicense && (
                  <AccessControl
                    requiredAction="write"
                    accessLevel={['add_return', 'preview_return', 'edit_login']}
                    mustHaveOne={true}
                    disableOnFalse={true}
                    additionalAccessCheck={!isWizard}
                    tooltip={disabledMsg}
                  >
                    <Button
                      id={`btnOpenReturnAtFederalVar${res.var}`}
                      size="small"
                      onClick={() => onOverviewTableClick(res.var, res.frmFile)}
                      disabled={shouldReturnProfileLock() || isWizard}
                    >
                      Go
                    </Button>
                  </AccessControl>
                )}
              </td>
            </tr>
          );
        } else {
          return null;
        }
      });

      return federalInfo;
    }
  };

  const getGeneralTable = () => {
    if (forms?.[0]?.Children) {
      const disabledMsg = isWizard ? 'This link is disabled for Guided Estimator tax returns' : '';
      const generalColumns = forms[0].Children;
      const generalInfo = generalColumns.map((res, index) => {
        return (
          <tr key={res.desc + index}>
            <td className="forms-link-col forms-link-desc-col">{res.desc}</td>
            <td className="forms-link-col">
              {hasLicense && (
                <AccessControl
                  requiredAction="write"
                  accessLevel={['add_return', 'preview_return', 'edit_login']}
                  mustHaveOne={true}
                  disableOnFalse={true}
                  additionalAccessCheck={!isWizard}
                  tooltip={disabledMsg}
                >
                  <Button
                    id={`btnOpenReturnAtGeneralVar${res.var}`}
                    size="small"
                    onClick={() => onOverviewTableClick(res.var, res.frmFile)}
                    disabled={
                      shouldReturnProfileLock() ||
                      shouldDisableSideBarItem(usingDemoAccount, res.var) ||
                      isWizard
                    }
                  >
                    Go
                  </Button>
                </AccessControl>
              )}
            </td>
          </tr>
        );
      });
      return generalInfo;
    }
  };

  const getNotes = () => {
    if (!notes?.length) {
      return <Typography>No Notes</Typography>;
    }

    const noteList = notes.map((note, index) => {
      return (
        <ExpansionPanel key={index}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Grid container direction="row" justify="space-between">
              <Grid item>
                <Typography variant="body2">Owner: {note.owner}</Typography>
              </Grid>
              <Grid item>
                <Typography color="textSecondary">Added {note.timestamp}</Typography>
              </Grid>
            </Grid>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography variant="body2">{note.note}</Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    });

    return noteList;
  };

  const openAttachment = async (rtnid, docid) => {
    if (!rtnid) {
      return;
    }

    try {
      const res = await XlinkAPI.getReturnAttachmentByRIDDocId(rtnid, docid);
      if (statusOK(res)) {
        const applicationType = res.data.split(',')[0].match(/:(.*)(?=;)/g); // Positive lookbehind can make this cleaner, but not supported in all browsers(...Edge)
        const matches = applicationType[0].split(':');
        const appType = matches[1];
        const data = res.data.split(',')[1];
        displayPrint(data, downloadIcon, appType, 'Document');
      }
    } catch (error) {
      ErrorHelpers.handleError('Error opening attachment', error);
      dispatch(
        appActions.showSnackbarMessage('Error opening attachment', 'error', 3500, {
          vertical: 'top',
          horizontal: 'center',
        }),
      );
    }
  };

  const getAttachments = () => {
    if (!attachments || !attachments.length) {
      return <Typography>No Attachments</Typography>;
    }
    return (
      <List style={{ maxHeight: '300px', overflow: 'auto' }} subheader={<li />}>
        {attachments.map(attachment => {
          if (attachment?.document_attachment_id) return <></>;

          return (
            <ListItem
              button
              id={`btnOpenAttachmentID${attachment.document_id}`}
              key={attachment.document_id}
              style={{
                display: 'flex',
                marginBottom: '20px',
                cursor: 'pointer',
                justifyContent: 'space-between',
              }}
              onClick={() => openAttachment(returnID, attachment.document_id)}
            >
              <Typography color="primary" noWrap={true}>
                {determineAttachmentName(attachment)}
              </Typography>
              <Typography color="textSecondary" noWrap={true}>
                Added {attachment.create_date.substring(0, 10)}
              </Typography>
            </ListItem>
          );
        })}
      </List>
    );
  };

  // TODO: Should pull all info from calcserver/endpoint
  const getPackageInfo = () => {
    let refundColor = '#00C44F';
    let refundText = 'Federal Refund';

    if (refundBalanceDue < 0) {
      refundText = 'Bal Due';
      refundColor = '#FF304A';
    }

    return (
      <Grid container direction="column" style={styles.spaceLeft}>
        <Grid item>
          <Typography component="h2" variant="headline" style={{ color: refundColor }}>
            {refundBalanceDue < 0
              ? refundBalanceDue.toLocaleString('en').replace('-', '-$')
              : '$' + refundBalanceDue.toLocaleString('en')}
          </Typography>
        </Grid>
        <Grid item style={styles.spaceBottom}>
          <Typography variant="caption">{refundText}</Typography>
        </Grid>
        <Grid item>
          <span className="boldText">Fed Status: </span>
          <span className="subText">{fedStatus}</span>
        </Grid>
        <Grid item>
          <span className="boldText">Filing Date: </span>
          <span className="subText">{fedFileDate}</span>
        </Grid>
      </Grid>
    );
  };

  /**
   * Determine return locked messaged base on locked by user,
   * locked via access level, or locked status.
   *
   * @function
   */
  const getLockedMessage = () => {
    let lockedMsg = '';

    if (lockedBy) {
      if (lockedBy === 'SYSTEM') {
        lockedMsg = 'NOTICE: Return is currently locked by the Crosslink App and is not available';
      } else {
        lockedMsg = `NOTICE: Return is currently being viewed by ${lockedBy.toUpperCase()} and is not available`;
      }
    } else if (lockedStatus && !lockedBy) {
      lockedMsg = 'NOTICE: Return is in read only mode and has been locked';
    }
    return (
      <span className="tableTypicalRowData" style={styles.lockedMessage}>
        <strong>{lockedMsg}</strong>
      </span>
    );
  };

  const hasLicense = payload?.is_business
    ? hasBusinessLicense
    : has1040License || hasSubsequentLicense;

  return (
    <div className="body-container">
      <div className="body-main-block">
        <div className="body-content">
          <div className="container-fixed">
            <Notifications />
            {isLoading ? (
              <div style={{ position: 'absolute', top: '40%', left: '50%' }}>
                <Spinner
                  size={125}
                  loadingText={SPINNER_DIALOGS.FETCHING_RETURN}
                  textColor="white"
                  bgColor="grey"
                />
              </div>
            ) : (
              <Fragment />
            )}
            <div
              style={{
                color: '#0077FF',
                marginLeft: '0em',
                marginTop: '2em',
                marginBottom: '0em',
              }}
            >
              <div>
                <SubdirectoryArrowLeft
                  id="allReturns"
                  style={{
                    height: '18px',
                    width: '18px',
                    color: '#0077FF',
                    transform: 'rotate(90deg)',
                    cursor: 'pointer',
                  }}
                  onClick={() => dispatch(returnProfileActions.clearActiveReturn())}
                />
                <label
                  id="lblShowAllTaxReturnsReturnProfile"
                  htmlFor="allReturns"
                  className="noselect"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    dispatch(returnProfileActions.clearActiveReturn());
                    history.push({ pathname: '/tax-returns' });
                  }}
                >
                  All Tax Returns
                </label>
              </div>
            </div>
            <div
              className="row ovTopRow"
              style={{ marginLeft: '0em', marginTop: '0em', marginRight: '0em' }}
            >
              <span className="ovLabel" style={{ marginTop: '.3em', fontWeight: '500' }}>
                {clientName}
              </span>
              <span className="subTitle">ID</span>
              <span className="tableTypicalRowData subBody">{friendlyID}</span>
              <span className="subTitle">{payload?.is_business ? 'EIN' : 'SSN'}</span>
              <span className="tableTypicalRowData subBody">
                {formatMaskSSNEIN(primarySSNEIN, maskSSNEIN, payload?.is_business)}
              </span>
              <span className="subTitle">Tax Year</span>
              <span className="tableTypicalRowData subBody">{year}</span>
              <div className="col clientActions">
                <AccessControl
                  requiredAction="write"
                  accessLevel={['add_return', 'preview_return', 'edit_login']}
                  mustHaveOne={true}
                  disableOnFalse={true}
                  additionalAccessCheck={hasLicense || isSuperOrTechSupport(payload)}
                  tooltip="Office setup must be complete and you must have the appropriate access level(s)"
                >
                  <Button
                    id="btnContinueReturnProfile"
                    className="actionButton"
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={() => onOverviewTableClick(isWizard ? FORM_NAMES.EST : '000000')}
                    disabled={shouldReturnProfileLock()}
                  >
                    Continue
                  </Button>
                </AccessControl>
              </div>
            </div>
            <div>{isLocked(lockedBy, lockedStatus) ? getLockedMessage() : ''}</div>
            <Paper className="ovNotebook" elevation={5}>
              <div className="container-fixed" style={{ topmargin: '0em', fontSize: '15px' }}>
                {getPackageInfo()}
              </div>
              <hr />
              <div className="container-fixed">
                <div className="row" style={{ height: '24px', fontSize: '21px' }}>
                  <div className="col-6">General</div>
                  <div className="col-6">Federal</div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <table className="table">
                    <tbody>{getGeneralTable()}</tbody>
                  </table>
                </div>
                <div className="col-6">
                  <table className="table">
                    <tbody>{getFederalTable()}</tbody>
                  </table>
                </div>
              </div>
              <hr />
              <div className="container-fixed">
                <div className="row ovTopRow">
                  <div className="col-6 ovLabel">
                    <span>Notes</span>
                  </div>
                  <div className="col-6 ovLabel">
                    <span>Attachments</span>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-6"
                    style={{
                      maxHeight: '300px',
                      overflow: 'auto',
                    }}
                  >
                    {getNotes()}
                  </div>
                  <div className="col-6">{getAttachments()}</div>
                </div>
              </div>
            </Paper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(withStyles(styles)(ReturnProfile));

// External imports
import React, { useEffect } from 'react';
import { Paper, Typography, withStyles } from '@material-ui/core';
import { withRouter } from 'react-router';
// Internal imports
import { useSetState } from '~/app/Utility/customHooks';
// Styling imports
import { styles } from '~/app/Components/NewAccount/css/QRModalMobileDone.js';

const QRModalMobileDone = props => {
  const { classes } = props;
  const [state, setState] = useSetState({
    windowHeight: '',
    windowWidth: '',
  });

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
  }, []);

  // Adding function that will handle resizing
  const handleResize = () => {
    setState({
      windowHeight: window.innerHeight - 200,
      windowWidth: window.innerWidth * 0.59,
    });
  };

  const height = state.windowHeight;
  const width = state.windowWidth;
  const isMobile = width <= 500; // We have a mobile device
  const orientationPortrait = width < height; // We have a mobile device on portrait mode

  if (isMobile && orientationPortrait) {
    return (
      <div style={{ textAlign: 'center', marginTop: '2vh' }}>
        <b>Please Rotate Your Device to Proceed</b>
      </div>
    );
  } else {
    return (
      <Paper
        elevation={5}
        className={props.isMobileDevice ? classes.qrCodeModalMobile : classes.qrCodeModal}
      >
        <div>
          <Typography
            variant={props.isMobileDevice ? 'body1' : 'title'}
            gutterBottom
            style={props.isMobileDevice ? { paddingTop: '0.5em' } : { paddingTop: '1.5em' }}
          >
            <b>Activation Process Complete</b>
          </Typography>
          <Typography
            gutterBottom
            className={props.isMobileDevice ? classes.qrCodeTitleMobile : classes.qrCodeTitle}
          >
            The activation process has been completed. Please close this page.
          </Typography>
        </div>
      </Paper>
    );
  }
};

export default withRouter(withStyles(styles)(QRModalMobileDone));

import React from 'react';
// Internal imports
import WebHelpers from '~/app/webHelpers.js';
// External imports
import { Checkbox, FormControl, FormControlLabel } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

// Styles imports
import '~app/Components/NewAccount/SingleOffice/css/printingSetup.css';

/**
 * Component that shows Printer Setting Row
 *
 * @component
 * @category Printing Setup
 * @subcategory Printer Setting Row
 */
const PrinterOptionRow = props => {
  const payload = WebHelpers.getJWTPayload();
  if (payload.season < 2020 && props.field === 'PFBA') {
    return <></>;
  } else {
    return (
      <div style={{ marginLeft: '1vw' }}>
        <FormControl>
          <FormControlLabel
            label={props.rowTitle}
            className="printing-setup-labelWidthStyleCheckbox"
            control={
              <Checkbox
                className="printing-setup-print-row-options-Checkbox"
                icon={
                  <CheckBoxOutlineBlankIcon
                    style={{ fontSize: 24, color: '#8FC3FF' }}
                    color="secondary"
                  />
                }
                checkedIcon={<CheckBoxIcon style={{ fontSize: 24 }} color="primary" />}
                label={props.rowTitle}
                name={props.field}
                checked={props?.fieldMap?.[props.field]?.configValue === '1'}
                onChange={e => {
                  props.handleCheckbox(e);
                }}
                inputProps={{ height: '20px', width: '20px' }}
              />
            }
          />
        </FormControl>
      </div>
    );
  }
};

export default PrinterOptionRow;

// Internal imports
import AuthAPI from '~/app/api/authAPI.js';
import XlinkAPI from '~/app/api/xlinkAPI';
import WebHelpers from './webHelpers';
// Redux imports
import { store } from '~/app/redux/index';
import { actions as appActions } from '~/app/redux/modules/app';
import { actions as formViewerActions } from '~/app/redux/modules/formViewer';

/*
    WARNING: XOError will be automatically generated in the future from lookup tables

    Feel free to extend, but you must extend xoerror.XOError in xlinkcloud as well - ideally comitted in the same timeframe.
    In Phase 2, we'll generate .js and .go source files for all lookup types
*/
export class XOError {
  static simple = 0;
  static jwtError = 1;
  static deviceMismatch = 2;
  static TokenExpired = 3;
  static tokenMalformed = 4;
  static badRefresh = 5;
  static accessDenied = 6;
  static formViewer = 7;
}

class XlinkcloudError extends Error {
  constructor(message) {
    super(message);
    this.name = 'XlinkcloudError';
    this.response = { data: { error_message: message, error_code: XOError.simple } };
  }
}

export default class ErrorHelpers {
  // createSimpleError creates a simple object that handleError() can consume. Use when throwing exceptions from frontend
  static createSimpleError = errorMsg => {
    return {
      response: {
        data: { error_message: errorMsg, error_code: XOError.simple },
      },
    };
  };

  static createXlinkcloudError = errorMsg => {
    return new XlinkcloudError(errorMsg);
  };

  static isStructuredError = rawError => {
    return typeof rawError === 'object' && rawError.response && rawError.response.data;
  };

  static forceLogout = (message, messageDetails = '') => {
    AuthAPI.logout().finally(() => {
      // If chat widget is open, force close it
      if (document.getElementById('webWidget')) {
        const chatWidgetEle = document.getElementById('webWidget').nextElementSibling;
        if (chatWidgetEle) {
          chatWidgetEle.click();
        }
      }

      // remove chat button
      if (document.getElementById('launcher')) {
        document.getElementById('launcher').style.display = 'none';
      }

      store.dispatch(appActions.onLogout());
      store.dispatch(
        appActions.showError({
          title: "You've been logged out.",
          body: message,
          bodyTwo: messageDetails,
        }),
      );

      // clear access_token and refresh_token
      WebHelpers.cleanupSession();
      WebHelpers.cleanupLocalStorage();
    });
  };

  static onUnhandledError = async (title, rawError, errorCode) => {
    // send the unknown unhandled error to xlinkcloud so that we can log it to AWS for us to view and then update `handleError` to expect the error
    try {
      await XlinkAPI.logErrorMessage(title, rawError, errorCode);
    } catch (error) {
      // If we have issues logging the error to xlinkcloud - We should go ahead and atleast console log the unknown error
      console.log(
        `Got non-object error, Title=${title}; Error=${rawError}; ErrorCode=${errorCode}`,
      );
    }

    if (ENVIRONMENT !== 'production') {
      store.dispatch(
        appActions.showError({
          title: 'NON-PROD HELPER ERROR: improper error received',
          body: 'Check console and update xlinkcloud error handling for your endpoint',
        }),
      );
      console.log(
        `Got non-object error, Title=${title}; Error=${rawError}; ErrorCode=${errorCode}`,
      );
    } else {
      store.dispatch(
        appActions.showError({
          title: 'Unknown Error',
          body: 'Please contact Technical Support',
        }),
      );
    }
  };

  static handleError = (title, rawError) => {
    if (ErrorHelpers.isStructuredError(rawError)) {
      const err = rawError.response.data;
      if (err.error_message) {
        // eslint-disable-next-line no-useless-escape
        const charactersNotAcceptedInErrMessage = /<br\s*[\/]?>/gi;
        err.error_message = err.error_message.replace(charactersNotAcceptedInErrMessage, '');
        // fully defined, process the error
        switch (err.error_code) {
          case XOError.jwtError:
          case XOError.TokenExpired:
          case XOError.tokenMalformed:
          case XOError.badRefresh:
            ErrorHelpers.forceLogout(
              'Your login session has ended.',
              'Possible reasons why your session ended: login is shared or session timed out.',
            );
            break;
          case XOError.deviceMismatch:
            ErrorHelpers.forceLogout(
              "We've detected an unusual change in device/location.  Please reauthenticate.",
            );
            break;
          case XOError.accessDenied:
            if (ENVIRONMENT !== 'production') {
              // show useful error in local/dev/QA
              store.dispatch(
                appActions.showError({
                  title: title,
                  body: err.error_message,
                }),
              );
            } else {
              // cryptic error in prod
              store.dispatch(
                appActions.showError({
                  title: title,
                  body: 'Insufficient permissions',
                }),
              );
            }
            break;
          case XOError.simple:
            store.dispatch(
              appActions.showError({
                title: title,
                body: err.error_message,
              }),
            );
            break;
          case XOError.formViewer:
            store.dispatch(formViewerActions.showErrorDialog(err.error_message, title));
            break;
          default:
            ErrorHelpers.onUnhandledError(title, rawError, err?.error_code);
        }
        return;
      } else if (err?.Desc) {
        store.dispatch(
          appActions.showError({
            title: title,
            body: err?.Desc,
          }),
        );
        return;
      }
    }
    // error not fully defined, should define in xlinkcloud
    ErrorHelpers.onUnhandledError(title, rawError);
  };
}

import PropTypes from 'prop-types';
import React from 'react';
import { Button } from '@material-ui/core';

/**
 * returns a simple toggled button
 * @params
 * selected: true/false value
 * toggleSelection: function to perform on click
 * option1 : string value to display on one click
 * option2 : string value to display on second click
 */

function ToggleButton(props) {
  const { selected, toggleSelected, option1, option2 } = props;
  return (
    <Button size="small" onClick={toggleSelected}>
      {selected ? option1 : option2}
    </Button>
  );
}

ToggleButton.propTypes = {
  selected: PropTypes.bool.isRequired,
  toggleSelected: PropTypes.func.isRequired,
  option1: PropTypes.string.isRequired,
  option2: PropTypes.string.isRequired,
};

export default ToggleButton;

import { LOGOUT } from '~/app/redux/commonActions.js';
import { IMPERSONATE_LOGIN } from '~/app/redux/loginSetup/duck.js';
// Dashboard Duck Module
export const DASHBOARD_CLEAR = 'xlinkonline/dashboard/CLEAR';
export const CHANGE_LAYOUT = 'xlinkonline/dashboard/CHANGELAYOUT';
export const TOGGLE_POLAR_WIDGET = 'xlinkonline/dashboard/TOGGLE_POLAR_WIDGET';
export const TOGGLE_ELEMENTS = 'xlinkonline/dashboard/TOGGLE';
export const ADD_WIDGET_WITH_CONFIG = 'xlinkonline/dashboard/ADDWIDGETCONFIG';
export const OPEN_CLOSE_WIDGET_CONTROL = 'xlinkonline/dashboard/OPENCLOSEWIDGETCONTROL';
export const REMOVE_WIDGET = 'xlinkonline/dashboard/REMOVEWIDGET';

export const SET_WIDGET_CONFIG = 'xlinkonline/dashboard/SETWIDGETCONFIG';
export const TOGGLE_WIDGET_STATIC = 'xlinkonline/dashboard/TOGGLE_WIDGET_STATIC';
export const FETCH_DOUGHNUT = 'xlinkonline/dashboard/widget/FETCH_DOUGHNUT';
export const UPDATE_WIDGET_DATA = 'xlinkonline/dashboard/UPDATE_WIDGET_DATA';
export const SET_RETURN_TYPE = 'xlinkonline/dashboard/SET_RETURN_TYPE';
export const SET_SORT_TYPE = 'xlinkonline/dashboard/SET_SORT_TYPE';
export const FETCH_NOTICATION_WIDGET_DATA = 'xlinkonline/dashboard/FETCH_NOTIFICATION_WIDGET_DATA';
export const LOAD_WIDGET_CONFIG = 'xlinkonline/dashboard/LOAD_WIDGET_CONFIG';
export const ADD_WIDGET_REQUEST = 'xlinkonline/dashboard/ADD_WIDGET_REQUEST';
export const SET_WIDGET_CONFIG_BY_KEY = 'xlinkonline/dashboard/SET_WIDGET_CONFIG_BY_KEY';
export const FETCH_BARCHART = 'xlinkonline/dashboard/widget/FETCH_BARCHART';
export const FETCH_FEES_WIDGET = 'xlinkonline/dashboard/widget/FETCH_FEES_WIDGET';
export const SET_READY_FOR_REVIEW = 'xlinkonline/dashboard/widget/SET_READY_FOR_REVIEW';

export const initialState = {
  tables: [],
  widgetList: [],
  layout: [],
  locked: false,
  data: {},
  widgetControlOpen: false,
  widgetDataStore: {},
  selectedPolarWidget: false,
  returnType: null,
  sortType: null,
  readyForReview: false,
};
export const actions = {
  onClear: () => ({
    type: DASHBOARD_CLEAR,
  }),
  onLayoutChange: (layout, widgetList, loginID) => ({
    type: CHANGE_LAYOUT,
    layout,
    widgetList,
    loginID,
  }),
  togglePolarWidget: data => ({
    type: TOGGLE_POLAR_WIDGET,
    data,
  }),
  toggleElementsStatic: () => ({
    type: TOGGLE_ELEMENTS,
  }),
  AddWidgetWithConfig: (widgetLayout, widgetType) => ({
    type: ADD_WIDGET_WITH_CONFIG,
    widgetLayout,
    widgetType,
  }),
  RemoveWidget: keyToPass => ({
    type: REMOVE_WIDGET,
    keyToPass,
  }),

  openCloseWidgetControl: () => ({
    type: OPEN_CLOSE_WIDGET_CONTROL,
  }),
  setWidgetConfig: (widgetLayout, widgetList) => ({
    type: SET_WIDGET_CONFIG,
    widgetLayout,
    widgetList,
  }),
  toggleWidgetStatic: key => ({
    type: TOGGLE_WIDGET_STATIC,
    key,
  }),
  fetchDoughnutWidget: (key, filter, body) => ({
    type: FETCH_DOUGHNUT,
    key,
    filter,
    body,
  }),
  fetchBarchartWidget: (key, filter, body) => ({
    type: FETCH_BARCHART,
    key,
    filter,
    body,
  }),
  fetchFeesWidget: (key, filter, body) => ({
    type: FETCH_FEES_WIDGET,
    key,
    filter,
    body,
  }),
  fetchNotificationWidget: key => ({
    type: FETCH_NOTICATION_WIDGET_DATA,
    key,
  }),
  updateWidgetData: (key, widgetData) => ({
    type: UPDATE_WIDGET_DATA,
    key,
    widgetData,
  }),
  setReturnType: returnType => ({
    type: SET_RETURN_TYPE,
    returnType,
  }),
  setSortType: sortType => ({
    type: SET_SORT_TYPE,
    sortType,
  }),
  loadWidgetConfiguration: loginID => ({
    type: LOAD_WIDGET_CONFIG,
    loginID,
  }),
  addWidgetRequest: widgetType => ({
    type: ADD_WIDGET_REQUEST,
    widgetType,
  }),
  setWidgetConfigByKey: (key, config) => ({
    type: SET_WIDGET_CONFIG_BY_KEY,
    key,
    config,
  }),
  setReadyForReview: val => ({
    type: SET_READY_FOR_REVIEW,
    val,
  }),
};

export default function reducer(state = initialState, action) {
  let widgetList;
  let newLayout1;
  let newWidgetDataStore;
  switch (action.type) {
    case DASHBOARD_CLEAR:
      return {
        ...state,
        tables: [],
        layout: [],
      };
    case CHANGE_LAYOUT:
      return {
        ...state,
        layout: action.layout,
      };
    case TOGGLE_POLAR_WIDGET:
      return {
        ...state,
        selectedPolarWidget: action.data,
      };
    case TOGGLE_ELEMENTS:
      return {
        ...state,
        layout: state.layout.map(lay => {
          const temp = Object.assign({}, lay);
          temp.static = !state.locked;
          return temp;
        }),
        locked: !state.locked,
      };
    case OPEN_CLOSE_WIDGET_CONTROL:
      return {
        ...state,
        widgetControlOpen: !state.widgetControlOpen,
      };
    case ADD_WIDGET_WITH_CONFIG:
      widgetList = {
        widgetFName: action.widgetLayout?.widgetFName,
        i: action.widgetLayout.i,
        type: action.widgetType,
        config: {},
      };
      newLayout1 = {
        i: action.widgetLayout.i,
        x: 0,
        y: Infinity,
        w: action.widgetLayout.w,
        h: action.widgetLayout.h,
        minW: action.widgetLayout.minW,
        minH: action.widgetLayout.minH,
        maxW: action.widgetLayout.maxW,
        maxH: action.widgetLayout.maxH,
        static: action.widgetLayout.static,
      };
      return {
        ...state,
        widgetList: [...state.widgetList, widgetList],
        layout: [...state.layout, newLayout1],
      };
    case REMOVE_WIDGET:
      return {
        ...state,
        widgetList: [...state.widgetList.filter(widget => widget.i !== action.keyToPass)],
        layout: [...state.layout.filter(widget => widget.i !== action.keyToPass)],
      };
    case SET_WIDGET_CONFIG:
      return {
        ...state,
        layout: action.widgetLayout,
        widgetList: action.widgetList,
      };
    case TOGGLE_WIDGET_STATIC:
      return {
        ...state,
        layout: [
          ...state.layout.map(widgetLay => {
            if ((widgetLay.i = action.key)) {
              const widg = Object.assign({}, widgetLay);
              widg.static = !widg.static;
              return widg;
            } else {
              return widgetLay;
            }
          }),
        ],
      };
    case LOGOUT:
      return {
        ...initialState,
      };
    case UPDATE_WIDGET_DATA:
      newWidgetDataStore = Object.assign({}, state.widgetDataStore);
      newWidgetDataStore[action.key] = action.widgetData;
      return {
        ...state,
        widgetDataStore: newWidgetDataStore,
      };
    case SET_RETURN_TYPE:
      return {
        ...state,
        returnType: action.returnType,
      };
    case SET_SORT_TYPE:
      return {
        ...state,
        sortType: action.sortType,
      };
    case IMPERSONATE_LOGIN:
      return {
        ...initialState,
      };
    case ADD_WIDGET_REQUEST:
    case SET_WIDGET_CONFIG_BY_KEY:
    case SET_READY_FOR_REVIEW:
      return {
        ...state,
        readyForReview: action.val,
      };
  }
  return state;
}

// External imports
import React, { useEffect } from 'react';
import { Typography, withStyles, Modal, DialogContent } from '@material-ui/core';
import { isEqual, isEmpty, pickBy } from 'lodash-es';
// Internal imports
import { useSetState } from '~/app/Utility/customHooks';
import {
  findIndex,
  remainingSignaturesNeeded,
  getRemoteData,
  handleSignatureRequestOption,
} from '~/app/Pages/Returns/pages/Signatures/signaturesHelper.js';
import { SIGNEE, SIGNEE_TYPE } from '~/app/constants.js';
import SignatureList from '~/app/Pages/Returns/pages/Signatures/components/SignatureList.jsx';
import SendSignature from '~/app/Pages/Returns/pages/Signatures/components/SendSignature.jsx';
import XlinkAPI from '~/app/api/xlinkAPI.js';
import { statusOK } from '~/app/webHelpers.js';
import ErrorHelpers from '~/app/errorHelpers.js';
// Redux imports
import { useSelector, useDispatch } from 'react-redux';
import { actions } from '~/app/redux/remoteSign/duck';
import { actions as appActions } from '~/app/redux/modules/app';
import { actions as formViewerActions } from '~/app/redux/modules/formViewer.js';
// Styling imports
import '~/app/Pages/Returns/pages/Signatures/css/signatures.css';
import { styles } from '~/app/Pages/Returns/pages/Signatures/css/signatures.js';

/**
 * Parent Component for SignatureList and SendSignature.
 *
 * @component
 * @category Returns
 * @subcategory Signatures
 */
const Signatures = props => {
  const { classes } = props;
  const {
    isSendSignatureModalActive,
    signaturesNeeded,
    amendedPreparerData,
    preparerID,
    amendedPreparerID,
    returnID,
    taxpayerInformation,
    spouseInformation,
    initialSigneeData,
  } = useSelector(state => ({
    isSendSignatureModalActive: state.remoteSign.isSendSignatureModalActive,
    signaturesNeeded: state.remoteSign.signaturesNeeded,
    amendedPreparerData: state.remoteSign.amendedPreparerData,
    preparerID: state.formViewer.preparerID,
    amendedPreparerID: state.formViewer.amendedPreparerID,
    returnID: state.returnProfile.returnID,
    taxpayerInformation: state.formViewer.taxpayerInformation,
    spouseInformation: state.formViewer.spouseInformation,
    initialSigneeData: state.remoteSign.initialSigneeData,
  }));

  const [state, setState] = useSetState({
    documentSignee: initialSigneeData.signee,
    signeeData: {},
    signaturesCollected: {},
    hasError: false,
    documentType: '',
    isAllSignaturesCollected: false,
    isLoading: true,
    returnSaved: props.returnSaved,
    canUseCellTP: false,
    canUseCellSP: false,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    handleSigneeData(initialSigneeData.signee);
  }, [taxpayerInformation, spouseInformation, initialSigneeData, amendedPreparerID]);

  useEffect(() => {
    handleGetRemoteData();
  }, [props.efinNumber, preparerID, amendedPreparerID]);

  useEffect(() => {
    setState({ returnSaved: props.returnSaved });
  }, [props.returnSaved]);

  useEffect(() => {
    fetchCellPhoneData();
    return () => {
      setState({
        signaturesCollected: {},
      });
    };
  }, []);

  const fetchCellPhoneData = async () => {
    try {
      const res = await XlinkAPI.getTextMessageClientInfo(returnID);

      if (statusOK(res)) {
        // check for taxpayer cell info
        if (hasValidTextLinkFields(res.data?.[0])) {
          setState({ canUseCellTP: true });
          dispatch(formViewerActions.textLinkClientInfoFetched(res.data));
        } else {
          setState({ canUseCellTP: false });
        }

        // check for spouse cell info
        if (res.data?.[1] && hasValidTextLinkFields(res.data?.[1])) {
          setState({ canUseCellSP: true });
          dispatch(formViewerActions.textLinkClientInfoFetched(res.data));
        } else {
          setState({ canUseCellSP: false });
        }
      }
    } catch (error) {
      dispatch(
        appActions.showSnackbarMessage(error, 'error', 3500, {
          vertical: 'top',
          horizontal: 'center',
        }),
      );
      ErrorHelpers.handleError('Error fetching message info', error);
    }
  };

  const hasValidTextLinkFields = returnInfo => {
    return returnInfo.cellPhone?.length > 8 && returnInfo.cellCarrier && returnInfo.carrierDomain;
  };

  /** Get the electronic signature for the ERO and Preparer as well as their contact information. */
  const handleGetRemoteData = async () => {
    // Remote data returns an Array[] of Objects and also empty strings ''
    const remoteData = await getRemoteData(
      props.efinNumber,
      preparerID,
      amendedPreparerID,
      returnID,
    );

    !remoteData && setState({ hasError: true });

    remoteData[0]?.status === 200 &&
      remoteData[3]?.status === 200 &&
      dispatch(
        actions.eroDataSet({
          contactData: remoteData[0]?.data,
          signatureData: remoteData[3]?.data,
        }),
      );

    remoteData[1]?.status === 200 &&
      remoteData[4]?.status === 200 &&
      dispatch(
        actions.preparerDataSet({
          signeeType: SIGNEE_TYPE.PREPARER,
          contactData: remoteData[1]?.data,
          signatureData: remoteData[4]?.data,
        }),
      );

    if (amendedPreparerID)
      remoteData[2]?.status === 200 &&
        remoteData[5]?.status === 200 &&
        dispatch(
          actions.amendedPreparerDataSet({
            signeeType: SIGNEE_TYPE.AMENDED_PAID_PREPARER_SIGNATURE,
            contactData: remoteData[2]?.data,
            signatureData: remoteData[5]?.data,
          }),
        );
  };

  /**
   * Handles the data that is displayed on the send signature modal
   *
   * @param {string} documentSignee The person who needs to sign the document.
   */
  const handleSigneeData = documentSignee => {
    // CLO-BUSINESS TODO: TP object needs to be updated to be generic to handle both 1040/corp
    const {
      firstName: taxpayerFirstName,
      lastName: taxpayerLastName,
      cellPhone: taxpayerCellPhone,
      email: taxpayerEmail,
    } = { ...taxpayerInformation };
    const {
      firstName: spouseFirstName,
      lastName: spouseLastName,
      cellPhone: spouseCellPhone,
      email: spouseEmail,
    } = { ...spouseInformation };

    const { TAXPAYER, TAXPAYER_SECOND, SPOUSE, SPOUSE_SECOND, AMENDED_PREPARER, OFFICER } = SIGNEE;
    let signeeType, signee, cell, email;

    switch (documentSignee) {
      case TAXPAYER:
      case TAXPAYER_SECOND:
        signeeType = documentSignee === TAXPAYER ? TAXPAYER : TAXPAYER_SECOND;
        signee = `Taxpayer ${
          documentSignee === TAXPAYER_SECOND ? 'Second' : ''
        } (${taxpayerFirstName} ${taxpayerLastName})`;
        cell = taxpayerCellPhone;
        email = taxpayerEmail;
        break;
      case SPOUSE:
      case SPOUSE_SECOND:
        signeeType = documentSignee === SPOUSE ? SPOUSE : SPOUSE_SECOND;
        signee = `Spouse ${
          documentSignee === SPOUSE_SECOND ? 'Second' : ''
        }  (${spouseFirstName} ${spouseLastName})`;
        cell = spouseCellPhone;
        email = spouseEmail;
        break;
      case AMENDED_PREPARER:
        signeeType = AMENDED_PREPARER;
        signee = `Amended Preparer (${
          amendedPreparerData?.contactData?.preparer_name || 'Unknown'
        })`;
        cell = amendedPreparerData?.contactData?.preparer_cell;
        email = amendedPreparerData?.contactData?.preparer_email;
        break;
      case OFFICER:
        signeeType = OFFICER;
        signee = `Business (${taxpayerInformation?.BusinessName || 'Unknown'})`;
        cell = taxpayerCellPhone;
        email = taxpayerEmail;
        break;
    }
    setState({
      signeeData: {
        signeeType: signeeType,
        signee: signee,
        cell: cell,
        email: email,
      },
    });
  };

  /** Handles retrieving the selected signature doc id from signature list */
  const getSignatureDocID = sigDocID => {
    setState({ currentSigDoc: sigDocID });
  };

  /** Checks if all the signatures needed have a signature request initiated. */
  const allSignaturesCollected = () => {
    // Filter out false signature obj values to skip Capture Signature modal (redundant secondary signature)
    const isAllSignaturesCollected = isEqual(state.signaturesCollected, pickBy(signaturesNeeded));

    setState({
      signeeData: { ...state.signeeData, isAllSignaturesCollected: isAllSignaturesCollected },
    });
  };

  /** Determines the next signature request data to be displayed on the send signature modal. */
  const handleNextSignatureNeeded = () => {
    let nextSignatureNeeded;
    if (!state.signeeData.isAllSignaturesCollected) {
      nextSignatureNeeded = Object.keys(
        remainingSignaturesNeeded(signaturesNeeded, state.signaturesCollected),
      ).map(val => {
        return val;
      });
      handleSigneeData(nextSignatureNeeded[0]);
    }
  };

  /**
   * Adds signee's information to request data as well signing method and contact method
   *
   * @param {Object} data The information about the person signing the document such as
   * signing method, contact information, and personal information.
   * @param {string} signeeType The person who needs to sign the document.
   */
  const handleAddingRequestData = (data, signeeType) => {
    const index = findIndex(props.signatureRequestData, signeeType);
    let signeeInformation = {};

    if (data.signingMethod === 'Remote Signature') {
      dispatch(formViewerActions.setRMSflag(true));
    }

    switch (signeeType) {
      case SIGNEE.TAXPAYER:
        signeeInformation = {
          ...taxpayerInformation,
          signeeType: SIGNEE_TYPE.TAXPAYER,
        };
        break;
      case SIGNEE.TAXPAYER_SECOND:
        signeeInformation = {
          ...taxpayerInformation,
          signeeType: SIGNEE_TYPE.TAXPAYER_SECOND_SIGNATURE,
        };
        break;
      case SIGNEE.SPOUSE:
        signeeInformation = {
          ...spouseInformation,
          signeeType: SIGNEE_TYPE.SPOUSE,
        };
        break;
      case SIGNEE.SPOUSE_SECOND:
        signeeInformation = {
          ...spouseInformation,
          signeeType: SIGNEE_TYPE.TAXPAYER_SECOND_SIGNATURE,
        };
        break;
      case SIGNEE.AMENDED_PREPARER:
        signeeInformation = {
          ...amendedPreparerData,
          signeeType: SIGNEE_TYPE.AMENDED_PAID_PREPARER_SIGNATURE,
        };
        break;
      case SIGNEE.OFFICER:
        signeeInformation = {
          ...taxpayerInformation,
          signeeType: SIGNEE_TYPE.OFFICER,
        };
        break;
    }
    // Checks if the signature request data already exists
    const hasMatchingValue = props.signatureRequestData.some(el => el.signee === data.signee);
    // If data exists, update the current data otherwise insert new data
    hasMatchingValue
      ? props.signatureRequestData.splice(index, 1, Object.assign(data, signeeInformation))
      : props.signatureRequestData.push(Object.assign(data, signeeInformation));
  };

  /** Clears any previous signature request data. */
  const clearRequestedSignatureData = () => {
    props.signatureRequestData.splice(0, props.signatureRequestData.length);
  };

  const checkSendSigModal = () => {
    return (
      state.returnSaved &&
      !state.isLoading &&
      isSendSignatureModalActive &&
      (!isEmpty(taxpayerInformation) || !isEmpty(spouseInformation))
    );
  };

  return (
    <div id="taxform" className={classes.archiveDocContainer}>
      <div className={classes.archiveHeder}>
        <Typography className="signatures-typography" variant="title">
          Signatures
        </Typography>
      </div>
      <div className="signatures-signature-list-block">
        <SignatureList
          openSendSignatureModal={() => dispatch(actions.sendSignatureModalSet(true))}
          clearRequestedSignatureData={clearRequestedSignatureData}
          verifyReturn={props.verifyReturn}
          updateSignaturesCollected={(data, bool) => (state.signaturesCollected[data] = bool)}
          efinNumber={props.efinNumber}
          hasError={state.hasError}
          isLocked={props.isLocked}
          handlePrint={props.handlePrint}
          doneLoading={() => setState({ isLoading: false })}
          saveReturn={props.saveReturn}
          readyForReview={props.readyForReview}
          printPrefs={props.printPrefs}
          isFeederOffice={props.isFeederOffice}
          getSignatureDocID={getSignatureDocID}
          bankID={props.bankID}
          returnSaved={state.returnSaved}
        />
      </div>
      <Modal
        id="sendSignatureModal"
        className={classes.signatureModal}
        open={checkSendSigModal()}
        disableBackdropClick={true}
      >
        <DialogContent>
          <SendSignature
            handleNextSignatureNeeded={handleNextSignatureNeeded}
            updateModalContent={handleSigneeData}
            addSignatureRequestData={handleAddingRequestData}
            signatureRequestData={props.signatureRequestData}
            updateSignaturesCollected={(data, bool) => (state.signaturesCollected[data] = bool)}
            allSignaturesCollected={allSignaturesCollected}
            signeeData={state.signeeData}
            documentType={initialSigneeData.document}
            isLoading={props.isLoading}
            returnPrintQueryLoading={props.returnPrintQueryLoading}
            sendRequest={() =>
              handleSignatureRequestOption(
                props.signatureRequestData,
                props.handleSigningRemotely,
                initialSigneeData.document,
                props.handleSigningInOffice,
                state.currentSigDoc,
              )
            }
            canUseCellTP={state.canUseCellTP}
            canUseCellSP={state.canUseCellSP}
          />
        </DialogContent>
      </Modal>
    </div>
  );
};

export default withStyles(styles)(Signatures);

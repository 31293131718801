import React, { useEffect, useState } from 'react';
// Internal imports
import Mapper from '~/app/Pages/WizardMode/Mapper/Mapper.jsx';
import Footer from '~/app/Pages/WizardMode/components/Footer.jsx';
// External imports
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
// Styling imports
import { styles } from '~/app/Pages/WizardMode/components/Forms/css/formStyles.js';
import '~/app/Pages/WizardMode/components/Forms/css/formStyles.css';

/**
 * Handles displaying a form based on what step the user is on in the WIzardMode form process.
 *
 * @component WizardForm
 * @category WizardMode
 */
const WizardForm = props => {
  const { classes } = props;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (props.preppedUI?.length) {
      setIsLoading(false);
    }
  }, [props.preppedUI]);

  return (
    !isLoading && (
      <div id="formContainer" className={classes.formContainer}>
        <div id="formBody" className={classes.formBody}>
          <Grid container spacing={16} justify="flex-start" wrap="wrap" direction="row">
            {!!props.preppedUI?.length && (
              <>
                <Mapper
                  preppedUI={props.preppedUI}
                  onBlurCalc={props.onBlurCalc}
                  handleChange={props.handleChange}
                  handleCheckboxChange={props.handleCheckboxChange}
                  form={props.form}
                  wizardDropdownOptions={props.wizardDropdownOptions}
                  getDropdownOptions={props.getDropdownOptions}
                  updateDynamicFields={props.updateDynamicFields}
                  wizardDynamicFields={props.wizardDynamicFields}
                  handleModal={props.handleModal}
                  currentInputErrors={props.currentInputErrors}
                  getLockedStatus={props.getLockedStatus}
                  wizardFLST={props.wizardFLST}
                />
                <Footer
                  handleStep={props.handleStep}
                  wizardCurrentStep={props.wizardCurrentStep}
                  wizardCompleted={props.wizardCompleted}
                  backText={props.footerInfo?.back}
                  nextText={props.footerInfo?.next}
                  returnID={props.returnID}
                  handleFilingStatusModal={props.handleFilingStatusModal}
                  hasFLST={props.hasFLST}
                  enableFooter={props.enableFooter}
                />
              </>
            )}
          </Grid>
        </div>
      </div>
    )
  );
};

export default withStyles(styles)(WizardForm);

// External imports
import React, { useEffect, useRef } from 'react';
import { CircularProgress, Typography, withStyles } from '@material-ui/core';
// Internal imports
import { useSetState } from '~/app/Utility/customHooks';
// Styling imports
import { styles } from '~/app/Components/Common/css/spinner.js';
import '~/app/Components/Common/css/spinner.css';

const Spinner = ({
  classes,
  size = 75,
  loadingText = 'Loading ...',
  textColor = '#1A173B',
  lockActions = true, // by default we should be locking actions during spin
  bgColor = 'transparent',
  spinnerColor = '#0077FF',
}) => {
  const timer = useRef(0);
  const [state, setState] = useSetState({
    showSpinner: false,
  });

  useEffect(() => {
    timer.current = setTimeout(() => setState({ showSpinner: true }), 999);

    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  if (state.showSpinner) {
    return (
      <div className={lockActions ? 'spinner-container' : ''}>
        <div className={lockActions ? 'spinner-content' : ''}>
          {lockActions && (
            <div
              aria-hidden="true"
              style={{
                top: '0',
                left: '0',
                right: '0',
                bottom: '0',
                zIndex: '9999',
                touchAction: 'none',
                WebkitTapHighlightColor: 'transparent',
                position: 'fixed',
              }}
            />
          )}
          <div>
            <CircularProgress
              className={classes.spinner}
              size={size}
              style={{ color: spinnerColor }}
            />
            <Typography
              variant="body2"
              gutterBottom
              style={{
                fontFamily: 'Roboto',
                color: textColor,
                backgroundColor: bgColor,
                borderRadius: '13px',
                textAlign: 'center',
                padding: '0.5em',
              }}
            >
              {loadingText}
            </Typography>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default withStyles(styles)(Spinner);

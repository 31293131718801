export const styles = {
  root: {
    flexGrow: 1,
    padding: '0.31rem',
  },
  toolbarColor: { backgroundColor: '#444A59' },
  typographyStyle: {
    color: '#FFFFFF',
    fontFamily: 'Roboto',
    fontSize: '0.93rem',
  },
  typographyStyle2: {
    color: '#000000',
    fontFamily: 'Roboto',
    fontSize: '1.25rem',
  },
  modal: {
    height: '75%',
    width: '50%',
    maxWidth: '50rem',
    maxHeight: '62.5rem',
    minWidth: '23rem',
    minHeight: '37.5rem',
    padding: '0',
    position: 'absolute',
    margin: 'auto',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  footerItem: {
    marginRight: '1rem',
  },
};

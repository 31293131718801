import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import {
  NativeSelect,
  FormControl,
  FormControlLabel,
  InputLabel,
  Input,
  Paper,
  Divider,
  Checkbox,
  Button,
  Snackbar,
  withStyles,
} from '@material-ui/core';
import { statusOK } from '~/app/webHelpers.js';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

import SimpleDialog from '#/Common/SimpleDialog.jsx';

import ErrorHelpers from '~/app/errorHelpers.js';
import XlinkAPI from '~/app/api/xlinkAPI';
import * as Global from '~/app/constants.js';
import AccessControl from '#/Auth/AccessControl.jsx';
import { STATE_LIST_ADDRESS } from '~/app/constants.js';
// Styling Imports
import '~/app/Pages/Setup/css/setup.css';
import '~/app/Components/NewAccount/SingleOffice/css/eroSetup.css';
import { styles } from '~/app/Components/NewAccount/SingleOffice/css/eroSetup.js';

/**
 * Class component - Render ERO information
 * @category NewAccount
 * @subcategory SingleOffice
 * @param {Object} props
 * @param {number} props.sameOfficeInfo
 * @param {string} props.eroSetup.eroInfo.eroName Name of ERO
 * @param {string} props.eroSetup.eroInfo.eroEFIN EFIN of ERO
 * @param {string} props.eroSetup.eroInfo.eroAddress Address of ERO
 * @param {string} props.eroSetup.eroInfo.erosuiteNum Suite/Apartment number of Address
 * @param {string} props.eroSetup.eroInfo.erocity City
 * @param {string} props.eroSetup.eroInfo.erostateSelected State
 * @param {string} props.eroSetup.eroInfo.erozip Zipcode
 * @param {string} props.eroSetup.eroInfo.eroPhone Phone number
 * @param {string} props.eroSetup.eroInfo.eroPin ERO pin
 * @param {string} props.eroSetup.eroInfo.eroEIN ERO ein
 * @param {string} props.eroSetup.eroInfo.eroPTIN ERO ptin
 * @param {string} props.eroSetup.eroInfo.SBIN ERO sbin
 * @param {number} props.eroSetup.sameAsERO Indicator - override information is the same as ERO info section
 * @param {string} props.eroSetup.eroOverrideInfo.eroName Name of ERO - displayed over default
 * @param {string} props.eroSetup.eroOverrideInfo.eroAddress Address of ERO - displayed over default
 * @param {string} props.eroSetup.eroOverrideInfo.erosuiteNum Suite/Apartment number of Address - displayed over default
 * @param {string} props.eroSetup.eroOverrideInfo.erocity City - displayed over default
 * @param {string} props.eroSetup.eroOverrideInfo.erostateSelected State - displayed over default
 * @param {string} props.eroSetup.eroOverrideInfo.erozip Zipcode - displayed over default
 * @param {string} props.eroSetup.eroOverrideInfo.eroPhone Phone number - displayed over default
 * @param {string} props.eroSetup.eroOverrideInfo.SBIN ERO sbin - displayed over default
 * @param {string} props.eroSetup.eroOverrideInfo.eroEIN ERO ein - displayed over default
 * @param {number} props.eroSetup.selfEmployed Indicator - ero is self employed
 */
class EROSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sameOfficeInfo: this.props.eroSetup.sameAsOffice === 1,
      EROName: this.props.eroSetup.eroInfo.eroName,
      EROEFIN: this.props.eroSetup.eroInfo.eroEFIN === 0 ? '' : this.props.eroSetup.eroInfo.eroEFIN,
      EROAddress: this.props.eroSetup.eroInfo.eroAddress,
      EROsuiteNum: this.props.eroSetup.eroInfo.erosuiteNum,
      EROcity: this.props.eroSetup.eroInfo.erocity,
      EROstateSelected: this.props.eroSetup.eroInfo.erostateSelected,
      EROzip: this.props.eroSetup.eroInfo.erozip,
      EROPhone: this.props.eroSetup.eroInfo.eroPhone,
      EROpin: this.props.eroSetup.eroInfo.eroPin,
      EROein: this.props.eroSetup.eroInfo.eroEIN,
      EROptin:
        this.props.eroSetup.eroInfo.eroPTIN === 0
          ? ''
          : this.props.eroSetup.eroInfo.eroPTIN.toString().replace('P', ''),
      SBIN: this.props.eroSetup.eroInfo.SBIN,
      sameInfo: this.props.eroSetup.sameAsERO === 1,
      overrideEROName: this.props.eroSetup.eroOverrideInfo.eroName,
      overrideEROEFIN: this.props.eroSetup.eroInfo.eroEFIN,
      overrideEROAddress: this.props.eroSetup.eroOverrideInfo.eroAddress,
      overrideEROsuiteNum: this.props.eroSetup.eroOverrideInfo.erosuiteNum,
      overrideEROcity: this.props.eroSetup.eroOverrideInfo.erocity,
      overrideEROstateSelected: this.props.eroSetup.eroOverrideInfo.erostateSelected,
      overrideEROzip: this.props.eroSetup.eroOverrideInfo.erozip,
      overrideEROPhone: this.props.eroSetup.eroOverrideInfo.eroPhone,
      overrideSBIN: this.props.eroSetup.eroOverrideInfo.SBIN,
      overrideEROEIN: this.props.eroSetup.eroOverrideInfo.eroEIN,
      selfEmployed: this.props.eroSetup.selfEmployed === 1,
      validEROName: false,
      validEROEFIN: false,
      validEROEIN: false,
      validEROPTIN: false,
      validEROAddress: false,
      validEROSuiteNum: false,
      validEROCity: false,
      validEROState: false,
      validEROZip: false,
      validEROStateSelected: false,
      valSBIN: false,
      formComplete: false,
      value: { value: null },
      confirmCancel: false,
      gotoPrev: false,
      snackbarOpen: false,
    };
  }

  componentDidMount() {
    if (this.state.sameOfficeInfo) {
      XlinkAPI.getEROSetup(true, this.props.loginID)
        .then(res => {
          const data = res.data;
          this.setState({
            EROName: data.eroInfo.eroName,
            EROEFIN: data.eroInfo.eroEFIN,
            EROAddress: data.eroInfo.eroAddress,
            EROsuiteNum: data.eroInfo.erosuiteNum,
            EROcity: data.eroInfo.erocity,
            EROstateSelected: data.eroInfo.erostateSelected,
            EROzip: data.eroInfo.erozip,
            EROPhone: data.eroInfo.eroPhone,
          });
        })
        .catch(err => {
          ErrorHelpers.handleError('Failed to get ERO Setup', err);
        });
    }
  }

  getInitialConfigState() {
    XlinkAPI.getEROSetup(false, this.props.loginID)
      .then(res => {
        const data = res.data;
        this.setState({
          EROName: data.eroInfo.eroName,
          EROEFIN: data.eroInfo.eroEFIN,
          EROAddress: data.eroInfo.eroAddress,
          EROsuiteNum: data.eroInfo.erosuiteNum,
          EROcity: data.eroInfo.erocity,
          EROstateSelected: data.eroInfo.erostateSelected,
          EROzip: data.eroInfo.erozip,
          EROPhone: data.eroInfo.eroPhone,
          EROPin: data.eroInfo.eroPin,
          EROein: data.erofInfo.eroEIN,
          EROptin: data.eroInfo.eroPTIN.toString().replace('P', ''),
          sameInfo: data.eroInfo.sameAsERO === 1,
          overrideEROName: data.eroOverrideInfo.eroName,
          overrideEROEFIN: data.eroOverrideInfo.eroEFIN,
          overrideEROAddress: data.eroOverrideInfo.eroAddress,
          overrideEROsuiteNum: data.eroOverrideInfo.erosuiteNum,
          overrideEROcity: data.eroOverrideInfo.erocity,
          overrideEROstateSelected: data.eroOverrideInfo.erostateSelected,
          overrideEROzip: data.eroOverrideInfo.erozip,
          overrideEROPhone: data.eroOverrideInfo.eroPhone,
          overrideSBIN: data.eroOverrideInfo.SBIN,
          overrideEROEIN: this.props.eroSetup.eroInfo.eroEIN,
          selfEmployed: data.selfEmployed === 1,
        });
      })
      .catch(err => {
        ErrorHelpers.handleError('Failed to get ERO Setup', err);
      });
  }

  toggleSuccessSnackbar = toggle => {
    this.setState({
      snackbarOpen: toggle,
    });
  };

  // toggles the confirm cancellation dialog.
  // toggle - hide/show cancellation window
  toggleConfirmCancel = toggle => {
    this.setState({
      confirmCancel: toggle,
    });
  };

  // For dropdown
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  // Handle onChange without validation for checkbox
  handleCheckbox = event => {
    const name = event.target.name;
    const checked = event.target.checked;
    this.setState({ [name]: checked });

    if (name === 'sameOfficeInfo') {
      this.getSameAsOffice(checked);
    }
  };

  // Handle onChange without validation
  handleInputChangeNoValidation = e => {
    let val = e.target.value;
    if (typeof val === 'string') {
      val = val.toUpperCase();
    }

    this.setState({ [e.target.name]: val });
  };

  // Trim leading and trailing spaces
  removeExtraSpaces = e => {
    const name = e.target.name;
    let value = e.target.value;
    value = value.replace(/\s\s+/g, ' '); // Replace tabs, new tabs and multiple spacing with single blank space
    const valTrim = value.trim();
    this.setState({ [name]: valTrim });
  };

  // Handle onChange without validation for input fields
  handleInputValidation = e => {
    const name = e.target.name;
    let value = e.target.value;

    // NOTE: In all regex expressions below The caret (^) character is the negation of the set [...], g for global
    // i for case insensitive(will work for lowercase and uppercase)
    switch (name) {
      case 'EROName':
      case 'overrideEROName':
        // The regex below matches any character that is not a word character. Allows for alphanumeric, underscore, single blank space, ', and hyphen and replaces it with a single space
        value = value.replace(/[^a-zA-Z-' ]+/g, '');
        break;
      case 'EROsuiteNum':
      case 'overrideEROsuiteNum':
        // Checks for alphanumeric value or "#", and allows for blank spaces
        value = value.replace(/[^#a-z0-9 ]+/gi, '');
        break;
      case 'EROcity':
      case 'overrideEROcity':
        value = value.replace(/[\W_\s+]+/g, ' '); // Remove special characters
        value = value.replace(/[0-9]/g, ''); // Remove digits[0-9]
        break;
      default:
        value = value.replace(/[\W_\s+]+/g, ' '); // Remove special characters
        break;
    }

    // Set the state for input field
    this.setState({
      [name]: value.toUpperCase(), // Using uppercase for input fields
    });
  };

  renderStateDropdown = (contents, validEROState, style) => {
    return (
      <NativeSelect
        id="ddlStateDropdownOfficeSetup"
        value={this.state.EROstateSelected ? this.state.EROstateSelected : ''}
        onChange={this.handleChange}
        disabled={this.state.sameOfficeInfo}
        name="EROstateSelected"
        className={style}
        error={!validEROState}
        disableUnderline
      >
        <option value="" className="ero-setup-menu-item" />
        {contents.map(entry => {
          return (
            <option key={entry} value={entry} className="ero-setup-menu-item ero-setup-menu-entry">
              {entry}
            </option>
          );
        })}
      </NativeSelect>
    );
  };

  renderStateDropdownPartTwo = (contents, style) => {
    return (
      <NativeSelect
        id="overrideEROstate-selected"
        value={
          this.state.sameInfo
            ? this.state.EROstateSelected
              ? this.state.EROstateSelected
              : ''
            : this.state.overrideEROstateSelected
            ? this.state.overrideEROstateSelected
            : ''
        }
        onChange={this.handleChange}
        disabled={this.state.sameInfo}
        name="overrideEROstateSelected"
        className={style}
        disableUnderline
      >
        <option value="" className="ero-setup-menu-item" />

        {contents.map(entry => {
          return (
            <option key={entry} value={entry} className="ero-setup-menu-item ero-setup-menu-entry">
              {entry}
            </option>
          );
        })}
      </NativeSelect>
    );
  };

  submitEROData = gotoNext => {
    const submitSameOfficeInfo = this.state.sameOfficeInfo ? '1' : '0';
    const submitEROName = this.state.EROName;
    const submitEROEFIN = this.state.EROEFIN;
    const submitEROAddress = this.state.EROAddress;
    const submitEROsuiteNum = this.state.EROsuiteNum;
    const submitEROcity = this.state.EROcity;
    const submitEROstateSelected = this.state.EROstateSelected;
    const submitEROzip = this.state.EROzip;
    const submitEROPhone = this.state.EROPhone.toString().replace(/[^0-9]/g, '');
    const submitSBIN = this.state.SBIN;
    const submitEROEIN = this.state.EROein.toString().replace('-', '');
    const submitEROPTIN = this.state.EROptin ? 'P' + this.state.EROptin.toString() : '';
    const submitSameInfo = this.state.sameInfo ? '1' : '0';
    const submitOverrideEROName = this.state.sameInfo
      ? this.state.EROName
      : this.state.overrideEROName;
    const submitOverrideEROEFIN = this.state.sameInfo ? this.state.EROEFIN : this.state.EROEFIN;
    const submitOverrideEROAddress = this.state.sameInfo
      ? this.state.EROAddress
      : this.state.overrideEROAddress;
    const submitOverrideEROsuiteNum = this.state.sameInfo
      ? this.state.EROsuiteNum
      : this.state.overrideEROsuiteNum;
    const submitOverrideEROcity = this.state.sameInfo
      ? this.state.EROcity
      : this.state.overrideEROcity;
    const submitOverrideEROstateSelected = this.state.sameInfo
      ? this.state.EROstateSelected
      : this.state.overrideEROstateSelected;
    const submitOverrideEROzip = this.state.sameInfo
      ? this.state.EROzip
      : this.state.overrideEROzip;
    const submitOverrideEROPhone = this.state.sameInfo
      ? this.state.toString().replace(/[^0-9]/g, '')
      : this.state.overrideEROPhone.replace(/[^0-9]/g, '');
    const submitOverrideSBIN = this.state.sameInfo ? this.state.SBIN : this.state.overrideSBIN;
    const submitSelfEmployed = this.state.selfEmployed ? '1' : '0';
    const submitEROPin = this.state.EROpin ? this.state.EROpin : '';
    const submitOverrideEROEIN = this.state.sameInfo
      ? this.state.EROein.toString().replace('-', '')
      : this.state.overrideEROEIN.toString().replace('-', '');
    // Creating object that will be submitted, which will be composed of all field values
    const submitEROData = {
      submitSameOfficeInfo,
      submitEROName,
      submitEROEFIN,
      submitEROAddress,
      submitEROsuiteNum,
      submitEROcity,
      submitEROstateSelected,
      submitEROzip,
      submitEROPhone,
      submitEROEIN,
      submitEROPTIN,
      submitSBIN,
      submitSameInfo,
      submitOverrideEROName,
      submitOverrideEROEFIN,
      submitOverrideEROAddress,
      submitOverrideEROsuiteNum,
      submitOverrideEROcity,
      submitOverrideEROstateSelected,
      submitOverrideEROzip,
      submitOverrideEROPhone,
      submitOverrideSBIN,
      submitSelfEmployed,
      submitEROPin,
      submitOverrideEROEIN,
    };
    XlinkAPI.postEROSetup(submitEROData, this.props.loginID)
      .then(res => {
        if (
          this.props.officeSetupProgressFlags.office === true &&
          this.props.officeSetupProgressFlags.ero === false &&
          this.props.officeSetupProgressFlags.preparer === true &&
          this.props.officeSetupProgressFlags.billing === true
        ) {
          this.props.addFrontendNotification({
            id: 0,
            returnID: 0,
            content: 'Required Office Setup Completed Successfully',
            type: Global.NOTIFICATION_TYPE.SUCCESS,
          });
          this.props.onSetupComplete();
        }
        this.props.updateOfficeSetupFlag('ero', true);
        if (gotoNext) {
          this.props.loadSetupComponent(Global.REQUIRED_SETUP_PAGES.PREPARER.NAME);
        } else {
          this.toggleSuccessSnackbar(true);
        }
      })
      .catch(err => {
        ErrorHelpers.handleError('Failed to Update ERO Information', err);
      });
  };

  getSameAsOffice = async shouldFetchOffice => {
    if (shouldFetchOffice) {
      try {
        const res = await XlinkAPI.getEROSetup(true, this.props.loginID);
        if (statusOK(res)) {
          const data = res.data;
          this.setState({
            EROName: data.eroInfo.eroName,
            EROEFIN: data.eroInfo.eroEFIN,
            EROAddress: data.eroInfo.eroAddress,
            EROsuiteNum: data.eroInfo.erosuiteNum,
            EROcity: data.eroInfo.erocity,
            EROstateSelected: data.eroInfo.erostateSelected,
            EROzip: data.eroInfo.erozip,
            EROPhone: data.eroInfo.eroPhone,
          });
        }
      } catch (error) {
        ErrorHelpers.handleError('Error in ERO setup', error);
      }
    } else {
      try {
        const res = await XlinkAPI.getEROSetup(false, this.props.loginID);
        if (statusOK(res)) {
          const data = res.data;
          this.setState({
            EROName: data.eroInfo.eroName,
            EROEFIN: data.eroInfo.eroEFIN,
            EROAddress: data.eroInfo.eroAddress,
            EROsuiteNum: data.eroInfo.erosuiteNum,
            EROcity: data.eroInfo.erocity,
            EROstateSelected: data.eroInfo.erostateSelected,
            EROzip: data.eroInfo.erozip,
            EROPhone: data.eroInfo.eroPhone,
            EROein: data.eroInfo.eroEIN,
            EROptin: data.eroInfo.eroPTIN.toString().replace('P', ''),
          });
        }
      } catch (error) {
        ErrorHelpers.handleError('Error in ERO setup', error);
      }
    }
  };

  render() {
    const { classes } = this.props;

    const addSameOfficeInfo = this.state.sameOfficeInfo;

    // Check if all the required fields are valid before allowing the user to click on save or save and next button
    // The required fields for ERO setup are the following ERO Name, ERO Address, City, State, Zip, SBIN, and phone number
    const validEROName = this.state.EROName !== undefined && this.state.EROName.length > 0;
    const validEROAddress = this.state.EROAddress !== undefined && this.state.EROAddress.length > 0;
    const validEROCity = this.state.EROcity !== undefined && this.state.EROcity.length > 0;
    const validEROState =
      this.state.EROstateSelected !== undefined && this.state.EROstateSelected.length === 2;
    // For valid ERO zip the regex matches 5 digits(0-9) then matches "-" or blank space followed by \d{4} matches 4 digits(0-9) and ? used as quantifier to see if there is a match in the preceding token
    const validEROZip =
      this.state.EROzip !== undefined && /^\d{5}(?:[-\s]\d{4})?$/.test(this.state.EROzip);

    const validEROEINlength = this.state.EROein?.replace(/(\s|-)/g, '')?.length   //component is adding spaces and dash, so length is always 10 w/o replacing
    const validEROEIN = this.state.EROein !== undefined && (validEROEINlength == 0 || validEROEINlength == 9);

    // Check if all required values are OK before the submit or submit and save button is enabled
    const disableSubmitERO = !(
      validEROName &&
      validEROAddress &&
      validEROCity &&
      validEROState &&
      validEROZip &&
      validEROEIN
    );

    return (
      <div className="container-fixed ero-setup-container">
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.snackbarOpen}
          autoHideDuration={2500}
          onClose={() => this.toggleSuccessSnackbar(false)}
          id="snkbarconfsaved"
          message={<span id="success-message">Configuration Saved</span>}
        />
        <Paper>
          <form className="ero-setup-form">
            <AccessControl
              requiredAction="write"
              accessLevel="edit_office_setup"
              disableOnFalse={true}
            >
              <div>
                <div className="ero-setup-heading">
                  <span className="ero-setup-heading-span">ERO Info</span>
                </div>
                <div>
                  <FormControl>
                    <div className="row ero-setup-checkbox-label">
                      <FormControlLabel
                        label="Same as Office Info"
                        control={
                          <Checkbox
                            color="primary"
                            className={classes.checkbox}
                            icon={
                              <CheckBoxOutlineBlankIcon
                                className={classes.checkboxOutline}
                                color="secondary"
                              />
                            }
                            checkedIcon={
                              <CheckBoxIcon className={classes.checkboxIcon} color="primary" />
                            }
                            id="chkSameOfficeInfoEROSETUP"
                            checked={this.state.sameOfficeInfo}
                            name="sameOfficeInfo"
                            onChange={this.handleCheckbox}
                            inputProps={{ height: '20px', width: '20px' }}
                          />
                        }
                      />
                    </div>
                  </FormControl>
                </div>
                <div className="ero-setup-form-container">
                  <div className="ero-setup-input-container">
                    <FormControl required>
                      <InputLabel
                        className={classes.labelWidth}
                        required={false}
                        htmlFor="txtERONameEROSETUP"
                        shrink
                      >
                        ERO Name
                      </InputLabel>
                      <Input
                        id="txtERONameEROSETUP"
                        name="EROName"
                        value={this.state.EROName}
                        onChange={this.handleInputValidation}
                        onBlur={this.removeExtraSpaces}
                        className={classes.input}
                        disableUnderline
                        disabled={this.state.sameOfficeInfo}
                        error={!addSameOfficeInfo && !validEROName}
                      />
                    </FormControl>
                  </div>
                  <div className="ero-setup-input-display-none">
                    <FormControl required>
                      <InputLabel
                        className={classes.labelWidth}
                        required={false}
                        htmlFor="txtEROEFINEROSETUP"
                        shrink
                      >
                        EFIN
                      </InputLabel>
                      <Input
                        id="txtEROEFINEROSETUP"
                        name="EROEFIN"
                        value={this.state.EROEFIN}
                        onChange={this.handleInputValidation}
                        className={classes.inputEROEFIN}
                        disableUnderline
                        disabled={true}
                      />
                    </FormControl>
                  </div>
                  <div className="ero-setup-input-container">
                    <FormControl required>
                      <InputLabel
                        className={classes.labelWidth}
                        required={false}
                        htmlFor="txtEROAddressEROSETUP"
                        shrink
                      >
                        ERO Address
                      </InputLabel>
                      <Input
                        id="txtEROAddressEROSETUP"
                        name="EROAddress"
                        value={this.state.EROAddress}
                        onChange={this.handleInputValidation}
                        onBlur={this.removeExtraSpaces}
                        className={classes.inputWidth40}
                        disableUnderline
                        disabled={this.state.sameOfficeInfo}
                        error={!addSameOfficeInfo && !validEROAddress}
                      />
                    </FormControl>
                  </div>
                  <div>
                    <FormControl>
                      <InputLabel
                        className={classes.labelWidth}
                        required={false}
                        htmlFor="txtEROsuiteNumEROSETUP"
                        shrink
                      >
                        Suite #
                      </InputLabel>
                      <Input
                        id="txtEROsuiteNumEROSETUP"
                        name="EROsuiteNum"
                        value={this.state.EROsuiteNum}
                        onChange={this.handleInputValidation}
                        onBlur={this.removeExtraSpaces}
                        className={classes.inputWidth12}
                        disableUnderline
                        disabled={this.state.sameOfficeInfo}
                      />
                    </FormControl>
                  </div>
                  <div className="ero-setup-input-container">
                    <FormControl>
                      <InputLabel
                        className={classes.labelWidth}
                        required={false}
                        htmlFor="txtEROcityEROSETUP"
                        shrink
                      >
                        City
                      </InputLabel>
                      <Input
                        id="txtEROcityEROSETUP"
                        name="EROcity"
                        value={this.state.EROcity}
                        onChange={this.handleInputValidation}
                        onBlur={this.removeExtraSpaces}
                        className={classes.inputWidth25}
                        disableUnderline
                        disabled={this.state.sameOfficeInfo}
                        error={!addSameOfficeInfo && !validEROCity}
                      />
                    </FormControl>
                  </div>
                  <div className="ero-setup-input-container-left ">
                    <FormControl>
                      <InputLabel
                        className={classes.labelWidthShort}
                        htmlFor="EROstate-selected"
                        shrink
                      >
                        State
                      </InputLabel>
                      {this.renderStateDropdown(
                        STATE_LIST_ADDRESS,
                        validEROState,
                        classes.nativeSelectState,
                      )}
                    </FormControl>
                  </div>
                  <div>
                    <FormControl>
                      <InputLabel
                        className={classes.labelWidthShort}
                        required={false}
                        htmlFor="nfEROzipEROSETUP"
                        shrink
                      >
                        Zip
                      </InputLabel>
                      <NumberFormat
                        ref={ref => (this.EROzip = ref)}
                        name="EROzip"
                        id="nfEROzipEROSETUP"
                        value={this.state.EROzip}
                        onChange={this.handleInputChangeNoValidation}
                        onBlur={this.removeExtraSpaces}
                        className={classes.inputWidth12}
                        disableUnderline
                        format="#####"
                        customInput={Input}
                        disabled={this.state.sameOfficeInfo}
                        error={!validEROZip}
                      />
                    </FormControl>
                  </div>
                  <div className="ero-setup-input-container">
                    <FormControl>
                      <InputLabel className={classes.labelWidth} htmlFor="txtSBINEROSEtup" shrink>
                        SBIN
                      </InputLabel>
                      <Input
                        id="txtSBINEROSEtup"
                        name="SBIN"
                        value={this.state.SBIN}
                        onChange={this.handleInputChangeNoValidation}
                        onBlur={this.removeExtraSpaces}
                        className={classes.inputWidth25}
                        disableUnderline
                        disabled={this.state.sameOfficeInfo}
                      />
                    </FormControl>
                  </div>
                  <div className="ero-setup-input-container-left">
                    <FormControl>
                      <InputLabel
                        className={classes.labelWidth}
                        htmlFor="nfEROPhoneEROSetup"
                        shrink
                      >
                        Phone Number
                      </InputLabel>
                      <NumberFormat
                        ref={ref => (this.EROPhone = ref)}
                        name="EROPhone"
                        id="nfEROPhoneEROSetup"
                        value={this.state.EROPhone}
                        onChange={this.handleInputChangeNoValidation}
                        onBlur={this.removeExtraSpaces}
                        className={classes.inputWidth12}
                        disableUnderline
                        format="(###) ###-####"
                        customInput={Input}
                        disabled={this.state.sameOfficeInfo}
                      />
                    </FormControl>
                  </div>
                  <div>
                    <FormControl>
                      <InputLabel
                        className={classes.labelWidthShort}
                        required={false}
                        htmlFor="nfEROpinEROSETUP"
                        shrink
                      >
                        ERO Pin
                      </InputLabel>
                      <NumberFormat
                        ref={ref => (this.EROpin = ref)}
                        name="EROpin"
                        id="nfEROpinEROSETUP"
                        value={this.state.EROpin}
                        onChange={this.handleInputChangeNoValidation}
                        onBlur={this.removeExtraSpaces}
                        className={classes.inputWidth12}
                        disableUnderline
                        format="#####"
                        customInput={Input}
                      />
                    </FormControl>
                  </div>
                  <div className="ero-setup-input-container">
                    <FormControl>
                      <InputLabel
                        className={classes.labelWidthShort}
                        required={false}
                        htmlFor="nfEROeinEROSETUP"
                        shrink
                      >
                        ERO EIN
                      </InputLabel>
                      <NumberFormat
                        ref={ref => (this.EROein = ref)}
                        name="EROein"
                        id="nfEROeinEROSETUP"
                        value={this.state.EROein}
                        onChange={this.handleInputChangeNoValidation}
                        onBlur={this.removeExtraSpaces}
                        className={classes.inputWidth12}
                        disableUnderline
                        format="##-#######"
                        customInput={Input}
                        autoComplete="off"
                        error={!validEROEIN}
                      />
                    </FormControl>
                  </div>
                  <div>
                    <FormControl>
                      <InputLabel
                        className={classes.labelWidthShort}
                        required={false}
                        htmlFor="nfEROptinEROSETUP"
                        shrink
                      >
                        ERO PTIN
                      </InputLabel>
                      <NumberFormat
                        ref={ref => (this.EROptin = ref)}
                        name="EROptin"
                        id="nfEROptinEROSETUP"
                        value={this.state.EROptin}
                        onChange={this.handleInputChangeNoValidation}
                        onBlur={this.removeExtraSpaces}
                        className={classes.inputWidth12}
                        disableUnderline
                        format="########"
                        customInput={Input}
                        autoComplete="off"
                      />
                    </FormControl>
                  </div>
                </div>
              </div>
              <br />
              <Divider />
              <div>
                <div className="ero-setup-heading">
                  <span className="ero-setup-heading-span">ERO Overrides</span>
                </div>
                <div className="ero-setup-heading-content">
                  <span className="ero-setup-heading-span ero-setup-heading-span-note">
                    <b>Note: </b>
                    This information is optional, and if provided will always appear in Forms 1040
                    and/or 8879 and cannot be altered.
                  </span>
                </div>
                <FormControl fullWidth>
                  <div className="row ero-setup-checkbox-label">
                    <FormControlLabel
                      label="Same as ERO Info"
                      control={
                        <Checkbox
                          className={classes.checkbox}
                          icon={
                            <CheckBoxOutlineBlankIcon
                              className={classes.checkboxOutline}
                              color="secondary"
                            />
                          }
                          checkedIcon={
                            <CheckBoxIcon className={classes.checkboxIcon} color="primary" />
                          }
                          id="chkSameInfoEROSETUP"
                          name="sameInfo"
                          checked={this.state.sameInfo}
                          onChange={this.handleCheckbox}
                          inputProps={{ height: '20px', width: '20px' }}
                        />
                      }
                    />
                  </div>
                </FormControl>
                <div className="ero-setup-form-container">
                  <div className="ero-setup-input-container">
                    <FormControl required>
                      <InputLabel
                        className={classes.labelWidth}
                        required={false}
                        htmlFor="txtOverrideEROName"
                        shrink
                      >
                        ERO&apos;s Name
                      </InputLabel>
                      <Input
                        id="txtOverrideEROName"
                        name="overrideEROName"
                        value={
                          this.state.sameInfo ? this.state.EROName : this.state.overrideEROName
                        }
                        onChange={this.handleInputValidation}
                        onBlur={this.removeExtraSpaces}
                        className={classes.input}
                        disableUnderline
                        disabled={this.state.sameInfo}
                      />
                    </FormControl>
                  </div>
                  <div className="ero-setup-input-display-none">
                    <FormControl required>
                      <InputLabel
                        className={classes.labelWidth}
                        required={false}
                        htmlFor="txtOverrideEROEFIN"
                        shrink
                      >
                        EFIN
                      </InputLabel>
                      <Input
                        id="txtOverrideEROEFIN"
                        name="overrideEROEFIN"
                        value={this.state.EROEFIN}
                        onChange={this.handleInputChangeNoValidation}
                        className={classes.inputEROEFIN}
                        disableUnderline
                        disabled={true}
                      />
                    </FormControl>
                  </div>
                  <div className="ero-setup-input-container">
                    <FormControl required>
                      <InputLabel
                        className={classes.labelWidth}
                        required={false}
                        htmlFor="txtOverrideEROAddress"
                        shrink
                      >
                        ERO Address
                      </InputLabel>
                      <Input
                        id="txtOverrideEROAddress"
                        name="overrideEROAddress"
                        value={
                          this.state.sameInfo
                            ? this.state.EROAddress
                            : this.state.overrideEROAddress
                        }
                        onChange={this.handleInputValidation}
                        onBlur={this.removeExtraSpaces}
                        className={classes.inputWidth40}
                        disableUnderline
                        disabled={this.state.sameInfo}
                      />
                    </FormControl>
                  </div>
                  <div>
                    <FormControl required>
                      <InputLabel
                        className={classes.labelWidthShort}
                        required={false}
                        htmlFor="txtOverrideEROsuiteNum"
                        shrink
                      >
                        Suite #
                      </InputLabel>
                      <Input
                        id="txtOverrideEROsuiteNum"
                        name="overrideEROsuiteNum"
                        value={
                          this.state.sameInfo
                            ? this.state.EROsuiteNum
                            : this.state.overrideEROsuiteNum
                        }
                        onChange={this.handleInputChangeNoValidation}
                        onBlur={this.removeExtraSpaces}
                        className={classes.inputWidth12}
                        disableUnderline
                        disabled={this.state.sameInfo}
                      />
                    </FormControl>
                  </div>
                  <div className="ero-setup-input-container">
                    <FormControl>
                      <InputLabel
                        className={classes.labelWidth}
                        required={false}
                        htmlFor="txtOverrideEROcity"
                        shrink
                      >
                        City
                      </InputLabel>
                      <Input
                        id="txtOverrideEROcity"
                        name="overrideEROcity"
                        value={
                          this.state.sameInfo ? this.state.EROcity : this.state.overrideEROcity
                        }
                        onChange={this.handleInputValidation}
                        onBlur={this.removeExtraSpaces}
                        className={classes.inputWidth25}
                        disableUnderline
                        disabled={this.state.sameInfo}
                      />
                    </FormControl>
                  </div>
                  <div className="ero-setup-input-container-left">
                    <FormControl>
                      <InputLabel
                        className={classes.labelWidthShort}
                        htmlFor="overrideEROstate-selected"
                        shrink
                      >
                        State
                      </InputLabel>
                      {this.renderStateDropdownPartTwo(
                        STATE_LIST_ADDRESS,
                        classes.nativeSelectState,
                      )}
                    </FormControl>
                  </div>
                  <div>
                    <FormControl>
                      <InputLabel
                        className={classes.labelWidthShort}
                        required={false}
                        htmlFor="overrideEROzip"
                        shrink
                      >
                        Zip
                      </InputLabel>
                      <NumberFormat
                        ref={ref => (this.overrideEROzip = ref)}
                        name="overrideEROzip"
                        id="overrideEROzip"
                        value={this.state.sameInfo ? this.state.EROzip : this.state.overrideEROzip}
                        onChange={this.handleInputChangeNoValidation}
                        onBlur={this.removeExtraSpaces}
                        className={classes.inputWidth12}
                        disableUnderline
                        disabled={this.state.sameInfo}
                        format="#####"
                        customInput={Input}
                      />
                    </FormControl>
                  </div>
                  <div className="ero-setup-input-container">
                    <FormControl>
                      <InputLabel className={classes.labelWidth} htmlFor="txtOverrideSBIN" shrink>
                        SBIN
                      </InputLabel>
                      <Input
                        id="txtOverrideSBIN"
                        name="overrideSBIN"
                        value={this.state.sameInfo ? this.state.SBIN : this.state.overrideSBIN}
                        onChange={this.handleInputChangeNoValidation}
                        onBlur={this.removeExtraSpaces}
                        className={classes.inputWidth25}
                        disableUnderline
                        disabled={this.state.sameInfo}
                      />
                    </FormControl>
                  </div>
                  <div className="ero-setup-input-container-left">
                    <FormControl>
                      <InputLabel
                        className={classes.labelWidthShort}
                        required={false}
                        htmlFor="txtOverrideEROEIN"
                        shrink
                      >
                        ERO EIN
                      </InputLabel>
                      <NumberFormat
                        ref={ref => (this.overrideEROein = ref)}
                        id="txtOverrideEROEIN"
                        name="overrideEROEIN"
                        value={this.state.sameInfo ? this.state.EROein : this.state.overrideEROEIN}
                        onChange={this.handleInputChangeNoValidation}
                        onBlur={this.removeExtraSpaces}
                        className={classes.inputWidth12}
                        disableUnderline
                        format="##-#######"
                        customInput={Input}
                        autoComplete="off"
                        disabled={this.state.sameInfo}
                      />
                    </FormControl>
                  </div>
                </div>
                <div>
                  <FormControl fullWidth>
                    <div className="row ero-setup-checkbox-label">
                      <FormControlLabel
                        label="Self-Employed"
                        control={
                          <Checkbox
                            className={classes.checkbox}
                            icon={
                              <CheckBoxOutlineBlankIcon
                                className={classes.checkboxOutline}
                                color="secondary"
                              />
                            }
                            checkedIcon={
                              <CheckBoxIcon className={classes.checkboxIcon} color="primary" />
                            }
                            id="selfEmployed"
                            name="selfEmployed"
                            checked={this.state.selfEmployed}
                            onChange={this.handleCheckbox}
                            inputProps={{ height: '20px', width: '20px' }}
                          />
                        }
                      />
                    </div>
                  </FormControl>
                </div>
              </div>
              <Divider />
            </AccessControl>
            <div className="setup-nav-btns">
              <Button
                id="btnPreviousEROSETUP"
                color="primary"
                onClick={() => {
                  this.setState({ gotoPrev: true });
                  this.toggleConfirmCancel(true);
                }}
              >
                Previous (cancel)
              </Button>
              <span>
                <Button
                  id="btnCancelEROSetup"
                  color="primary"
                  onClick={() => {
                    this.setState({ gotoPrev: false });
                    this.toggleConfirmCancel(true);
                  }}
                >
                  Cancel
                </Button>
                <AccessControl
                  requiredAction="write"
                  accessLevel="edit_office_setup"
                  disableOnFalse={true}
                >
                  <Button
                    id="btnSaveEROSetup"
                    color="primary"
                    onClick={() => this.submitEROData(false)}
                    disabled={disableSubmitERO}
                  >
                    Save
                  </Button>
                </AccessControl>
              </span>
              <AccessControl
                requiredAction="write"
                accessLevel="edit_office_setup"
                disableOnFalse={true}
              >
                <Button
                  id="btnNextEROSETUP"
                  color="primary"
                  onClick={() => this.submitEROData(true)}
                  disabled={disableSubmitERO}
                >
                  Save &amp; Next
                </Button>
              </AccessControl>
            </div>
            <br />
          </form>
        </Paper>

        <SimpleDialog
          open={this.state.confirmCancel}
          onClose={() => this.toggleConfirmCancel(false)}
          onConfirm={() => {
            this.toggleConfirmCancel(false);
            if (this.state.gotoPrev) {
              this.props.loadSetupComponent(Global.REQUIRED_SETUP_PAGES.OFFICE.NAME);
            } else {
              this.getInitialConfigState();
            }
          }}
          dialogTitle={'Lose Unsaved Changes?'}
          contentText={'Are you sure you want to undo any pending changes?'}
        />
      </div>
    );
  }
}

export default withStyles(styles)(EROSetup);

import React from 'react';
// Internal imports
import {
  getChildrenOfRows,
  getColumnTotal,
  getSubIncrement,
} from '~/app/Pages/WizardMode/Mapper/helpers/mapperHelpers.js';
import { getBreakpointGrid } from '~/app/Pages/WizardMode/wizardModeHelpers.js';
import infoIcon from '~/images/icons/icons8-info.svg';
// External imports
import { InputLabel, FormControl, Grid, Switch, Checkbox } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
// Styling imports
import { styles } from '~/app/Pages/WizardMode/Mapper/css/mapperStyles.js';
import '~/app/Pages/WizardMode/Mapper/css/mapperStyles.css';

/**
 * Handles X field type for Checkbox Fields
 *
 * @param {Object} props check the map helper to see what is passed in
 * @returns JSX Checkbox input
 */
const CheckboxMap = props => {
  const { classes } = props;
  // Returns an Array of inputs/CellItems that are associated to the same row
  const childrenOfRows = getChildrenOfRows(props.preppedUI, props.row, false);
  // for dynamic fields, add a sub increment to the title
  const dynamicSubIncr = getSubIncrement(props.field.substring(2, 4));
  // determines the size of the input and the column size for material ui grid
  const column = getColumnTotal(childrenOfRows, props.field, 'checkbox');
  // handles determining the breakpoint and also handles checking if a property exists.
  const columnBreakpoint = getBreakpointGrid(column, true, null);
  // disables spouse fields if filing status is 1 or 3
  const disableSpouse = props.singleFLST && props?.tors === 'S';

  return (
    <Grid item xs={columnBreakpoint} md={columnBreakpoint} lg={column || 'auto'}>
      <div
        className={`${
          props.isListItem
            ? 'grid-container-grid-item-div-checkbox-no-margin'
            : props.isCheckbox
            ? 'checkbox-item-div'
            : 'grid-container-grid-item-div'
        }`}
      >
        <FormControl required={props.flag?.includes('*')}>
          {props.isCheckbox ? (
            <Checkbox
              id={props.field}
              name={props.name}
              value={props.form[props.name]}
              checked={props.form[props.name] === 'X'}
              onChange={e => props.handleCheckboxChange(e)}
              color="primary"
              disabled={props.flag === '+' || props.getLockedStatus() || disableSpouse}
              aria-describedby={props.prompt}
            />
          ) : (
            <Switch
              id={props.field}
              name={props.name}
              value={props.form[props.name]}
              checked={props.form[props.name]}
              onChange={e => props.handleCheckboxChange(e, props.form)}
              disabled={props.flag === '+' || props.getLockedStatus() || disableSpouse}
              aria-describedby={props.prompt}
            />
          )}
        </FormControl>
        <InputLabel htmlFor={props.field} className={classes.labelStyle}>
          {props.dsplymode === 'd' ? `${dynamicSubIncr}. ${props.prompt}` : props.prompt}
          {/* Info bubble */}
        </InputLabel>
        {props.dsplymode === '?' && (
          <img
            id={props.field}
            onClick={() => props.handleModal('isInfoModalOpen', props.lineno, props.prompt)}
            className="container-item-icon"
            alt={props.lineno}
            src={infoIcon}
          />
        )}
      </div>
    </Grid>
  );
};

export default withStyles(styles)(CheckboxMap);

import React, { useEffect } from 'react';
// External imports
import { useHistory, withRouter } from 'react-router-dom';
import { Button, Checkbox, FormControlLabel, Paper, withStyles } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
// Internal imports
import { useSetState } from '~/app/Utility/customHooks';
import { LOGIN_SETTINGS } from '~/app/constants';
import { webSocket } from '~/app/Utility/socketConnection.js';
// Redux imports
import { useDispatch, useSelector } from 'react-redux';
import { actions as loginSetupActions } from '~/app/redux/loginSetup/duck';
// Styling imports
import { MuiThemeProvider } from '@material-ui/core/styles';
import { styles } from '~/app/Pages/IRSMessage/css/irsMessage.js';
import appTheme from '~/themes/GenericTheme.jsx';
import '~/app/Pages/IRSMessage/css/irsMessage.css';

/**
 * Component that displays the IRS Message and allows the user to no longer see the
 * message on log in.
 *
 * @component
 * @category IRSMessage
 */
const IRSMessage = props => {
  const { classes } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const { isAuthenticated } = useSelector(state => ({
    isAuthenticated: state.app.isAuthenticated,
  }));

  const [state, setState] = useSetState({
    isChecked: false,
  });

  /** Navigate the user to the dashboard page once they are authenticated. */
  useEffect(() => {
    if (isAuthenticated) {
      webSocket.services();
      history.push({ pathname: '/dashboard' });
    }
  }, [isAuthenticated]);

  /** Handles finalzing the login process once the user clicks continue. */
  const onClickContinue = () => {
    if (state.isChecked) {
      // Pass in false to disable the IRS message from popping up on next log in
      dispatch(loginSetupActions.fetchLoginSettings(!state.isChecked));
      sessionStorage.setItem(LOGIN_SETTINGS.SHOW_IRS_MESSAGE, 'false');
    }
    dispatch(loginSetupActions.onFinalizeLogin());
  };

  return (
    <MuiThemeProvider theme={appTheme}>
      <div className="scroll-y irs-message-block">
        <Paper className={classes.paper}>
          <div className="irs-message-heading">
            <h2>Protect Your Clients; Protect Yourself</h2>
          </div>
          <div className="irs-message-paragraph-section">
            <p className="irs-message-paragraph-spacing">
              It takes all of us working together to protect taxpayers’ data. The Data Security
              Resource Guide for Tax Professionals can help. Download it today at{' '}
              <a target="_blank" rel="noopener noreferrer" href="//IRS.gov/ProtectYourClients">
                IRS.gov/ProtectYourClients
              </a>
              .
            </p>
          </div>
          <div className="irs-message-paragraph-section">
            <h4>Data Security Tips for Tax Professionals</h4>
          </div>
          <div className="irs-message-paragraph-section">
            <p className="irs-message-paragraph-spacing">
              Go to{' '}
              <a target="_blank" rel="noopener noreferrer" href="//www.IRS.gov/ProtectYourClients">
                www.IRS.gov/ProtectYourClients
              </a>{' '}
              to download the complete Data Security Resource Guide for Tax Professionals. See what
              steps you can take to betterprotect your clients and your business.
            </p>
          </div>
          <div className="irs-message-paragraph-section">
            <h4>Here’s a sampling from the Data Security Resource Guide:</h4>
          </div>
          <div className="irs-message-paragraph-section">
            <ul className="irs-message-list-spacing">
              <li>
                <p className="irs-message-paragraph-spacing">
                  Learn to recognize phishing emails, especially those pretending to be from the
                  IRS, e-Services, a tax software provider or cloud storage provider. Never open a
                  link or any attachment from a suspicious email.
                </p>
                <p className="irs-message-paragraph-spacing">
                  Remember: The IRS never initiates email contact with tax pros about returns,
                  refunds or requests for sensitive financial or password information.
                </p>
              </li>
              <li>
                <p className="irs-message-paragraph-spacing">
                  Create a data security plan using IRS Publication 4557, Safeguarding Taxpayer
                  Data, and Small Business Information Security – The Fundamentals, by the National
                  Institute of Standards andTechnology.&quot;
                </p>
              </li>
              <li>
                <p className="irs-message-paragraph-spacing">Review internal controls</p>
              </li>
              <li>
                <p className="irs-message-paragraph-spacing">
                  Report any data theft or data loss to the appropriate IRS Stakeholder Liaison.
                </p>
              </li>
              <li>
                <p className="irs-message-paragraph-spacing">
                  Stay connected to the IRS through subscriptions to e-News for Tax Professionals,
                  QuickAlerts and Social Media.
                </p>
              </li>
            </ul>
          </div>
          <div className="irs-message-paragraph-section">
            <p className="irs-message-paragraph-spacing">
              The Data Security Resource Guide for Tax Professionals also details the signs of data
              thefts, explains how to report thefts to IRS and provides links to important data
              theft resources on IRS.gov.Download your guide today.&quot;
            </p>
          </div>
          <div className="irs-message-paragraph-section">
            <p className="irs-message-paragraph-spacing">
              Publication 5294 (EN-SP) (6-2018) Catalog Number 71263T Department of the Treasury
              Internal Revenue Service{' '}
              <a target="_blank" rel="noopener noreferrer" href="//www.irs.gov">
                www.irs.gov
              </a>
            </p>
          </div>
          <div className="irs-message-paragraph-section">
            <p className="irs-message-paragraph-spacing">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="//www.irs.gov/pub/irs-pdf/p5294esp.pdf"
              >
                Link to Pub 5294 (EN-SP)
              </a>
            </p>
          </div>
          <div className="irs-message-paragraph-section">
            <p className="irs-message-paragraph-bold">
              By using a computer system and software to prepare and transmit my client’s return, or
              business return, electronically, I consent to the disclosure of all information
              pertaining to my use of the system and software to create my client’s return, or
              business return, and to the electronic transmission of my client’s tax return, or
              business return, to the IRS and State Agencies, as applicable by law.
            </p>
          </div>
          <FormControlLabel
            control={
              <Checkbox
                id="chkDoNotSHowIRSMessage"
                checked={state.isChecked}
                onChange={() => {
                  setState({
                    isChecked: !state.isChecked,
                  });
                }}
                value="checkedB"
                inputProps={styles.checkboxInputProps}
                icon={
                  <CheckBoxOutlineBlankIcon
                    className={classes.checkBoxOutlineBlankIcon}
                    color="primary"
                  />
                }
                checkedIcon={<CheckBoxIcon className={classes.checkBoxIcon} color="primary" />}
              />
            }
            label="Do not show this message again"
          />
          <Button
            id="btnContinueIRSMessage"
            variant="contained"
            color="primary"
            size="medium"
            type="submit"
            onClick={onClickContinue}
          >
            Continue
          </Button>
        </Paper>
      </div>
    </MuiThemeProvider>
  );
};

export default withRouter(withStyles(styles)(IRSMessage));

// External imports
import React from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  withStyles,
  TableHead,
  Button,
  Menu,
  MenuItem,
} from '@material-ui/core';
// Internal imports
import { useSetState } from '~/app/Utility/customHooks';
import menuVertical from '~/images/icons/menu_vertical.png';
// Styling imports
import { styles } from '~/app/Pages/Returns/css/bookmarkStyles.js';

// Bookmarks and BookmarkField + cs transaction return_bookmark_query needs refactored
const Bookmarks = props => {
  const { classes } = props;
  const [state, setState] = useSetState({
    rowSelected: null, // Index of row selected of map
    selectedBookmark: {},
    hoveredBookmark: null,
    anchorEl: null,
  });

  const rowIsHovered = rowID => {
    return state.hoveredBookmark === rowID;
  };

  const openVoidCTXMenu = (e, id, bookmark) => {
    e.stopPropagation();
    setState({
      anchorEl: e.currentTarget,
      hoveredBookmark: id,
      selectedBookmark: bookmark,
    });
  };

  const deleteBookmark = bookmark => {
    if (bookmark.field.length < 10) {
      props.showErrorDialog("Error fetching bookmark. FieldID isn't valid.");
      return;
    }

    clearCTXMenuFocus();
    const formOccur = bookmark.field.substring(0, 6);
    const field = bookmark.field.substring(6, 10);
    const deleteBookmarkFlag = true;
    props.setBookmark(formOccur, field, '', '', deleteBookmarkFlag);
    props.deleteBookmark(bookmark.field);
    props.snackbarToggle('Bookmark Deleted');
  };

  const viewBookmark = bookmark => {
    if (bookmark.field.length < 10) {
      props.showErrorDialog("Error fetching bookmark. FieldID isn't valid.");
      return;
    }

    clearCTXMenuFocus();
    props.viewBookmark(bookmark.field.substring(0, 6), bookmark.field.substring(6, 10));
  };

  const goToField = bookmark => {
    if (bookmark.field.length < 10) {
      props.showErrorDialog("Error fetching bookmark. FieldID isn't valid.");
      return;
    }

    const formOccur = bookmark.field.substring(0, 6);
    const field = bookmark.field.substring(6, 10) + '00';
    props.toggleFromBookmark(true, field);
    props.getTaxForm(formOccur, '', '', field).catch(error => {
      console.log('Fetching Tax Form - Error: ', error);
    });
  };

  const clearCTXMenuFocus = () => {
    setState({
      anchorEl: null,
      hoveredBookmark: null,
    });
  };

  return (
    <div id="taxform" className={classes.bookmarkContainer}>
      <div className={classes.bookmarkHeader}>
        <Typography variant="body2">Bookmarks</Typography>
      </div>
      {props.bookmarks != null && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell classes={{ root: classes.cellHeader }}>Form</TableCell>
              <TableCell classes={{ root: classes.cellHeader }}>Field</TableCell>
              <TableCell classes={{ root: classes.cellHeader }}>Description</TableCell>
              <TableCell classes={{ root: classes.cellHeader }}>Timestamp</TableCell>
              <TableCell classes={{ root: classes.cellHeader }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {props.bookmarks.map((bookmark, i) => {
              const description =
                bookmark.desc?.length > 30 ? bookmark.desc.substring(0, 30) + '...' : bookmark.desc;

              return (
                <TableRow
                  key={i}
                  hover={true}
                  selected={state.rowSelected === i}
                  className={rowIsHovered(i) ? 'tableRowBoxShadow' : 'tableRow'}
                >
                  <TableCell classes={{ root: classes.cellRoot }}>
                    {bookmark.formName === '' ? 'Client Data' : bookmark.formName}
                  </TableCell>
                  <TableCell classes={{ root: classes.cellRoot }}>
                    {bookmark.field?.substring(6, bookmark.field?.length)}
                  </TableCell>
                  <TableCell classes={{ root: classes.cellRoot }}>{description}</TableCell>
                  <TableCell classes={{ root: classes.cellRoot }}>{bookmark.timestamp}</TableCell>
                  <TableCell classes={{ root: classes.cellRoot }}>
                    <Button onClick={() => goToField(bookmark)}>Jump To</Button>
                    <Button
                      className={
                        !rowIsHovered(i) ? 'tableVerticalMenu' : 'tableVerticalMenuVisible'
                      }
                      classes={{ root: classes.actionButton }}
                      onClick={e => openVoidCTXMenu(e, i, bookmark)}
                    >
                      <img src={menuVertical} />
                    </Button>
                    <Menu
                      anchorEl={state.anchorEl}
                      open={state.hoveredBookmark === i}
                      onClose={() =>
                        setState({
                          anchorEl: null,
                          hoveredBookmark: null,
                        })
                      }
                    >
                      <MenuItem
                        style={props.locked ? { backgroundColor: 'lightgrey' } : {}}
                        onClick={() => (!props.locked ? deleteBookmark(bookmark) : {})}
                      >
                        Delete
                      </MenuItem>
                      <MenuItem onClick={() => viewBookmark(bookmark)}>View</MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
    </div>
  );
};

export default withStyles(styles)(Bookmarks);

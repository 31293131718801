//Customer Profile Duck

const SET_ACTIVE_CUSTOMER = "xlinkonline/customerProfile/SET";
const CLEAR_ACTIVE_CUSTOMER = "xlinkonline/customerProfile/CLEAR";

const initialState = {
  clientName: "",
  year: 2017,
  id: "",
  created: "",
  filingStatus: "Single",
  refundBalanceDue: 0,
  agi: 0,
  notes: [],
  attachments: []
};

export const actions = {
  setActiveCustomer: client => ({
    type: SET_ACTIVE_CUSTOMER,
    client
  }),
  clearActiveCustomer: () => ({
    type: CLEAR_ACTIVE_CUSTOMER
  })
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_ACTIVE_CUSTOMER:
      return {
        ...state,
        clientName: action.client.first_name + " " + action.client.last_name,
        id: action.client.ssn.slice(-4),
        created: action.client.date_started,
        refundBalanceDue: action.client.refund_or_balance_due,
        agi: action.client.fed_agi
      };
    case CLEAR_ACTIVE_CUSTOMER:
      return {
        ...state,
        clientName: initialState.clientName,
        id: initialState.id,
        created: initialState.created,
        filingStatus: initialState.filingStatus,
        refundBalanceDue: initialState.refundBalanceDue,
        agi: initialState.agi,
        notes: initialState.notes,
        attachments: initialState.attachments
      };
  }
  return state;
}

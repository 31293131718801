import React from 'react';
// external imports
import { AppBar, Toolbar, FormControl, InputLabel, Input, Button } from '@material-ui/core';
// redux imports
import { useDispatch, useSelector } from 'react-redux';
import { actions as setupPageActions } from '~/app/redux/setupPages/duck';
// styling imports
import './css/upsertSiteIdentifier.css';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './css/upsertSiteIdentifier.js';

const UpsertSiteIdentifier = props => {
  const dispatch = useDispatch();

  const { isEditingRecord, currentEditRecord } = useSelector(state => ({
    isEditingRecord: state.setupPageState.isEditingRecord,
    currentEditRecord: state.setupPageState.currentEditRecord,
  }));

  const handleInputChange = e => {
    e.persist();
    dispatch(setupPageActions.updateCurrentEditRecord(e));
  };

  const handleUpsert = () => {
    props.handleUserDBUpsert(currentEditRecord);
  };
  const { classes } = props;
  return (
    <div>
      <AppBar position="static">
        <Toolbar className="upsert-site-id-toolbar">
          {isEditingRecord ? 'Edit ' : 'Add New '}
          Site Identifier
        </Toolbar>
      </AppBar>
      <form>
        <div className="upsert-site-id-content">
          <div className="upsert-site-id-section-heading">Site Identifier</div>
          <div className="upsert-site-id-content-site-code">
            <FormControl>
              <InputLabel className="upsert-site-id-label" shrink>
                Site Code
              </InputLabel>
              <Input
                id="txtsiteCodeUpsertSiteIdentifier"
                name="site_code"
                value={currentEditRecord.site_code === undefined ? '' : currentEditRecord.site_code}
                onChange={handleInputChange}
                className="upsert-site-id-input"
                error={!currentEditRecord.referral || currentEditRecord.referral === ''}
                inputProps={{
                  maxLength: '7',
                }}
                disableUnderline
              />
            </FormControl>
          </div>
          <div className="upsert-site-id-content-site-description">
            <FormControl>
              <InputLabel className="upsert-site-id-label" shrink>
                Site Description
              </InputLabel>
              <Input
                id="txtsiteDescriptionUpsertSiteIdentifier"
                name="site_description"
                value={
                  currentEditRecord.site_description === undefined
                    ? ''
                    : currentEditRecord.site_description
                }
                onChange={handleInputChange}
                className="upsert-site-id-input"
                error={!currentEditRecord.referral || currentEditRecord.referral === ''}
                inputProps={{
                  maxLength: '11',
                }}
                disableUnderline
              />
            </FormControl>
          </div>
        </div>

        <div className="upsert-site-id-button-block">
          <Button
            id="btnCancelAddUUpsertReferral"
            color="primary"
            onClick={() => props.handleCloseUpsertModal()}
            classes={{ root: classes.mfaButton }}
          >
            Cancel
          </Button>
          <Button
            id="btnUpsertReferral"
            color="primary"
            classes={{ root: classes.mfaButton }}
            onClick={handleUpsert}
            disabled={
              !currentEditRecord.site_code ||
              (currentEditRecord.site_code && currentEditRecord.site_code.length < 0) ||
              !currentEditRecord.site_description ||
              (currentEditRecord.site_description && currentEditRecord.site_description.length < 0)
            }
          >
            {isEditingRecord ? 'Save' : 'Add'}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default withStyles({ ...styles })(UpsertSiteIdentifier);

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash-es/get';

import { useSetState } from '~/app/Utility/customHooks';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import {
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import NumberFormat from 'react-number-format';

import './css/pprCharges.css';
import { styles } from './css/pprChargesStyles.js';

const PPRCharges = props => {
  const [state, setState] = useSetState({
    expanded: true,
  });

  const handleExpandClick = () => {
    setState({ expanded: !state.expanded });
  };

  const { classes } = props;
  return (
    <Fragment>
      <div className="pprChargesContainer">
        <div className="pprChargesCollapseIconContainer">
          <IconButton
            classes={{ root: classes.pprChargesCollapseIcon }}
            onClick={handleExpandClick}
            aria-expanded={state.expanded}
          >
            {state.expanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </div>
        <div>
          <span className="pprChargesCollapseHeader">PPR Charges</span>
        </div>
        <Collapse in={state.expanded} timeout="auto" unmountOnExit>
          <div className="pprChargesTableContainer">
            <Table aria-label="PPR Charges">
              <TableHead>
                <TableRow>
                  <TableCell classes={{ root: classes.pprChargesTableHeadCell }}>
                    Fee Item
                  </TableCell>
                  <TableCell classes={{ root: classes.pprChargesTableHeadCell }}>
                    Individual Returns
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell classes={{ root: classes.pprChargesTableBodyCell }}>
                    Federal Return
                  </TableCell>
                  <TableCell classes={{ root: classes.pprChargesTableBodyCell }}>
                    <NumberFormat
                      displayType="text"
                      value={get(props.pprInfo['PPIF'], 'value', 4000) / 100}
                      prefix={'$'}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                      decimalScale={2}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell classes={{ root: classes.pprChargesTableBodyCell }}>
                    State Return
                  </TableCell>
                  <TableCell classes={{ root: classes.pprChargesTableBodyCell }}>
                    <NumberFormat
                      displayType="text"
                      value={get(props.pprInfo['PPIS'], 'value', 3500) / 100}
                      prefix={'$'}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                      decimalScale={2}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </Collapse>
      </div>
    </Fragment>
  );
};

PPRCharges.propTypes = {
  pprInfo: PropTypes.object,
};

export default withStyles(styles)(PPRCharges);

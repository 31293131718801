import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// internal imports
import AccessControl from '~/app/Components/Auth/AccessControl.jsx';
import UpsertUserDBModal from './UpsertUserDBModal.jsx';
import { useSetState } from '~/app/Utility/customHooks';
import { USERDB, SortOrder } from '~/app/constants.js';
// external imports
import {
  Paper,
  Button,
  Menu,
  MenuItem,
  Input,
  Modal,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import menuVertical from '~/images/icons/menu_vertical.png';
// redux imports
import { actions as appActions } from '~/app/redux/modules/app';
import { actions as setupPageActions } from '~/app/redux/setupPages/duck';
// styling imports
import './css/userDBTable.css';

const OPTIONAL_FETCH_DELAY_MS = 250; // ms
const withDelay = true;

const editDBALtooltip = 'Edit Databases Access Level Required';

const UserDBTable = props => {
  const dispatch = useDispatch();

  const {
    dbList,
    currentEditRecord,
    addNewRecordModalOpen,
    currentView,
    dbType,
    dbListTitle,
    idColHeader,
    dataColHeader,
    currentDBPage,
    perPage,
  } = useSelector(state => ({
    dbList: state.setupPageState.dbList,
    currentEditRecord: state.setupPageState.currentEditRecord,
    addNewRecordModalOpen: state.setupPageState.addNewRecordModalOpen,
    currentView: state.drilldown.drilldownHistory[state.drilldown.drilldownHistory.length - 1],
    dbType: state.setupPageState.dbType,
    dbListTitle: state.setupPageState.dbListTitle,
    idColHeader: state.setupPageState.idColHeader,
    dataColHeader: state.setupPageState.dataColHeader,
    currentDBPage: state.setupPageState.currentDBPage,
    perPage: state.setupPageState.perPage,
  }));

  const [state, setState] = useSetState({
    popupActions: ['Delete'],
    showDeleteRecordModal: false,
    selectedRow: null,
    delteConfirm: false,
    expandedRow: null,
    idColSortOrder: SortOrder.ASC,
    dataColSortOrder: SortOrder.DEFAULT,
    localDbType: dbType,
    filterString: '',
    clickedIdx: 0,
  });

  useEffect(() => {
    if (state.localDbType !== dbType) {
      setState({
        localDbType: dbType,
        idColSortOrder: SortOrder.ASC,
        dataColSortOrder: SortOrder.DEFAULT,
        filterString: '',
      });
    }
  });

  const fetch = (delay = false) => {
    const listLabels = props.getListLabels(dbType);
    const doFetch = () =>
      dispatch(
        setupPageActions.fetchUserDBList(
          dbType,
          currentView.loginID,
          listLabels.idColHeader,
          listLabels.dataColHeader,
          listLabels.dbListTitle,
        ),
      );
    if (delay) {
      setTimeout(doFetch, OPTIONAL_FETCH_DELAY_MS);
    } else {
      doFetch();
    }
  };

  const cycleSort = current => {
    switch (current) {
      case SortOrder.DEFAULT:
        return SortOrder.ASC;
      case SortOrder.ASC:
        return SortOrder.DESC;
      case SortOrder.DESC:
        return SortOrder.ASC;
    }
    return SortOrder.DEFAULT;
  };

  const getSortGlyph = current => {
    switch (current) {
      case SortOrder.DEFAULT:
        return '\u3000'; // blank space with width
      case SortOrder.ASC:
        return '\u25B2';
      case SortOrder.DESC:
        return '\u25BC';
    }
    return '';
  };

  const onIDColClick = () => {
    setState({
      idColSortOrder: cycleSort(state.idColSortOrder),
      dataColSortOrder: SortOrder.DEFAULT,
    });
  };

  const onDataColClick = () => {
    setState({
      idColSortOrder: SortOrder.DEFAULT,
      dataColSortOrder: cycleSort(state.dataColSortOrder),
    });
  };

  const openAddNewRecordModal = (currentEditRecord, isEditingRecord) => {
    dispatch(setupPageActions.onOpenUserDBAddModal(currentEditRecord, isEditingRecord));
  };

  const closeUpsertDBModal = () => {
    dispatch(setupPageActions.onCloseUpsertUserDBModal());
    fetch(withDelay);
  };

  const handleUserDBUpsert = () => {
    dispatch(
      setupPageActions.requestUpsertUserDBRecord(dbType, currentView.loginID, currentEditRecord),
    );
    closeUpsertDBModal();
  };

  const onOpenActionModal = (domEvent, row, idx) => {
    domEvent.stopPropagation();
    setState({
      anchorEl: domEvent.currentTarget,
      selectedRow: row,
      clickedIdx: idx,
    });
  };

  const onCloseActionModal = () => {
    setState({ anchorEl: null, selectedRow: null });
    fetch(withDelay);
  };

  const onRowActionClick = (domEvent, row, actionText) => {
    domEvent.stopPropagation();
    onCloseActionModal();

    if (actionText === 'Delete') {
      domEvent.preventDefault();

      setState({ showDeleteRecordModal: true, selectedRow: row });
      if (state.delteConfirm === true) {
        dispatch(setupPageActions.deleteUserDBRecord(dbType, currentView.loginID, row.key));
      }
    } else {
      onCloseActionModal();
      dispatch(
        appActions.showError({
          title: 'Unknown row action: ' + actionText,
          body: 'Not sure how to handle action',
        }),
      );
    }
  };

  const closeDeleteRecordModal = () => {
    setState({ showDeleteRecordModal: false });
  };

  const handleDeleteRecord = () => {
    setState({ deleteConfirm: false, showDeleteRecordModal: false });
    dispatch(
      setupPageActions.requestDeleteUserDBRecord(dbType, currentView.loginID, state.selectedRow.id),
    );
    fetch(withDelay);
  };

  const rowIsOpen = retID => {
    return retID === state.expandedRow;
  };

  const updateFilter = e => {
    setState({
      filterString: e.target.value,
    });
  };

  const setupDBcontent = () => {
    if (dbList != null && dbList.length > 0) {
      const { anchorEl } = state;

      let offset = (currentDBPage - 1) * perPage + 1;
      if (dbList == null || dbList.length === 0) {
        offset = 0;
      }
      let lastRecord = offset + perPage - 1;
      if (dbList.length < lastRecord) {
        lastRecord = dbList.length;
      }

      let isEditable = true;
      if (dbType === USERDB.EFIN) {
        isEditable = false;
      }

      const filterDBList = dbList.filter(element => {
        if (
          element.key.toLowerCase().includes(state.filterString.toLowerCase()) ||
          element.desc.toLowerCase().includes(state.filterString.toLowerCase())
        ) {
          return true;
        }
        return false;
      });

      const sortFilterDBList = filterDBList.sort((a, b) => {
        let sortKey;
        let sortCol;
        if (state.idColSortOrder !== SortOrder.DEFAULT) {
          sortKey = 'key';
          sortCol = state.idColSortOrder;
        } else {
          sortKey = 'desc';
          sortCol = state.dataColSortOrder;
        }
        let trueA = a[sortKey];
        let trueB = b[sortKey];
        if (typeof trueA === 'string') trueA = trueA.toLowerCase();
        if (typeof trueB === 'string') trueB = trueB.toLowerCase();
        switch (sortCol) {
          case SortOrder.DEFAULT:
          case SortOrder.ASC:
            if (trueA < trueB) return -1;
            if (trueA > trueB) return 1;
            return 0;
          case SortOrder.DESC:
            if (trueA > trueB) return -1;
            if (trueA < trueB) return 1;
            return 0;
        }
        return 0;
      });

      return sortFilterDBList.map((row, i) => {
        return (
          <tr key={i} className={rowIsOpen(row.id) ? 'tableRowBoxShadow' : 'tableRow'}>
            <td style={styles.datawidth}>{row.key.toUpperCase()}</td>
            {row.desc !== '' ? <td>{row.desc}</td> : <td>{''}</td>}
            {isEditable && (
              <td style={{ paddingLeft: '10vw' }}>
                <AccessControl
                  requiredAction="write"
                  accessLevel="edit_databases"
                  disableOnFalse={true}
                  tooltip={editDBALtooltip}
                >
                  <Button onClick={() => openAddNewRecordModal(row, true)} id="btnEditUserDBTable">
                    Edit
                  </Button>
                </AccessControl>
                <Button
                  id="btnDeleteUserDBTable"
                  className="tableVerticalMenu"
                  onClick={e => onOpenActionModal(e, row, i)}
                  style={{
                    minHeight: '35px',
                    maxHeight: '35px',
                    minWidth: '24px',
                    maxWidth: '24px',
                    padding: '0px 0px 0px 0px',
                    border: '2px solid #0077FF',
                  }}
                >
                  <img src={menuVertical} />
                </Button>
                {i === state.clickedIdx && (
                  <Menu
                    id="action-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={onCloseActionModal}
                  >
                    <AccessControl
                      requiredAction="write"
                      accessLevel="edit_databases"
                      disableOnFalse={true}
                      tooltip={editDBALtooltip}
                    >
                      <MenuItem
                        onClick={e => onRowActionClick(e, state.selectedRow, 'Delete')}
                        id="anchorDeleteButton"
                      >
                        Delete
                      </MenuItem>
                    </AccessControl>
                  </Menu>
                )}
              </td>
            )}
          </tr>
        );
      });
    }
  };

  let buttonVis;
  if (dbType === USERDB.EFIN) {
    buttonVis = { visibility: 'hidden' };
  }
  return (
    <div className="container-fixed database-content">
      <form
        className="database-form"
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <div className="container-fixed database-form-top-content">
          <div className="database-left-content">
            <span style={styles.dbListTitle}>{dbListTitle}</span>
            <Input
              id="txtUserDBFilterString"
              name="filterString"
              value={state.filterString}
              placeholder="Search"
              onChange={updateFilter}
              style={styles.filterInput}
              disableUnderline
            />
          </div>
          <div className="database-form-top-add-new-btn">
            <AccessControl
              requiredAction="write"
              accessLevel="edit_databases"
              disableOnFalse={true}
              tooltip={editDBALtooltip}
            >
              <Button
                id="btnAddNewRecordUserDB"
                onClick={() => openAddNewRecordModal({}, false)}
                color="primary"
                style={buttonVis}
              >
                Add New
              </Button>
            </AccessControl>
          </div>
        </div>
        <table className="database-form-table-content">
          <thead className="database-form-table-header">
            <tr className="database-form-table-header-row">
              <th style={styles.tableHeader} onClick={() => onIDColClick()}>
                {`${idColHeader.toUpperCase()}${getSortGlyph(state.idColSortOrder)}`}
              </th>
              <th style={styles.tableHeader} onClick={() => onDataColClick()}>
                {`${dataColHeader.toUpperCase()}${getSortGlyph(state.dataColSortOrder)}`}
              </th>
              <th className="database-form-table-header-column" />
            </tr>
          </thead>
          <tbody className="database-form-table-body">{setupDBcontent()}</tbody>
        </table>
      </form>
      {state.selectedRow && (
        <Dialog
          open={state.showDeleteRecordModal}
          onClose={() => closeDeleteRecordModal()}
          aria-labelledby="confirm-delete-rec-title"
          aria-describedby="confirm-delete-rec-desc"
        >
          <DialogTitle id="confirm-delete-rec-title">{`Delete ${
            state.selectedRow.desc === '' ? state.selectedRow.key : state.selectedRow.desc
          }`}</DialogTitle>
          <DialogContent id="confirm-delete-rec-desc">
            Are you sure you want to delete{' '}
            {state.selectedRow.desc === '' ? state.selectedRow.key : state.selectedRow.desc}?
            <div
              style={{
                marginTop: '1rem',
                fontWeight: 'bold',
                fontSize: '14px',
              }}
            >
              {dbType === 8 &&
                ` Deleting this Site ID will also permanently remove the Site ID associated to any check stack in Check
                  Inventory. This cannot be undone.`}
            </div>
          </DialogContent>
          <DialogActions>
            <Button id="btnCancelDeleteFormViewer" onClick={() => closeDeleteRecordModal()}>
              Cancel
            </Button>
            <Button id="btnDoDeleteFormViewer" onClick={() => handleDeleteRecord()}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Modal
        id="mdlNewRecordModalUserDBTable"
        open={addNewRecordModalOpen}
        onClose={closeUpsertDBModal}
        style={{ overflow: 'scroll' }}
        disableBackdropClick={true}
      >
        <Paper
          elevation={5}
          style={{
            width: '50%',
            padding: '0',
            position: 'relative',
            margin: 'auto',
            overflow: 'auto',
          }}
        >
          <UpsertUserDBModal
            dbType={dbType}
            handleCloseUpsertModal={closeUpsertDBModal}
            handleUserDBUpsert={handleUserDBUpsert}
          />
        </Paper>
      </Modal>
    </div>
  );
};

// position the modals
const styles = {
  upsertDBModal: {
    content: {
      overflow: 'scroll',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      height: '350px',
      width: '300px',
      padding: '0',
      boxShadow: '0 0 20px 0 rgba(0,0,0,0.25)',
    },
  },
  dbListTitle: {
    fontFamily: 'Roboto',
    fontSize: '21px',
    letterSpacing: '0.14px',
    color: '#1A173B',
    marginLeft: '0.3em',
  },
  tableHeader: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    color: '#1A173B',
    letterSpacing: '1px',
    lineHeight: '14px',
    height: '14px',
    paddingBottom: '0%',
    cursor: 'pointer',
    userSelect: 'none',
    width: '20vw',
  },
  datawidth: {
    width: '20vw',
  },
  filterInput: {
    marginLeft: '3em',
    height: '35px',
    width: '200px',
    border: '1px solid #EBEDF8',
    borderRadius: '4px',
    backgroundColor: '#F3F9FF',
  },
  trSpacer: {
    height: '20px',
    width: '100%',
  },
};

export default UserDBTable;

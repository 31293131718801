export const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  addFormModal: {
    maxHeight: '50rem',
    minHeight: '31.25rem',
    height: 'auto',
    width: '59.375rem',
    padding: '0',
    position: 'absolute',
    margin: 'auto',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  readOnlyField: {
    border: 'none',
    backgroundColor: 'transparent',
  },
  noOutlineButton: {
    border: 'none',
  },
  noOutlineButtonBlue: {
    color: '#1880E7',
    fontFamily: 'Roboto',
    fontSize: '15px',
    border: 'none',
  },
  iconButton: {
    margin: theme.spacing.unit,
  },
  noBorderOnFocus: {
    '&:focus': {
      border: 'none',
      outline: 'none',
    },
  },
  tableRowRoot: {
    height: '30px',
    letterSpacing: '0.1px',
  },
  tableHeadRoot: {
    height: '40px',
    letterSpacing: '0.1px',
    color: '#1A173B',
    fontSize: '12px',
    fontFamily: 'Roboto',
    borderBottom: 'none',
  },
  tableCellRoot: {
    padding: '0px 24px 0px 24px',
    borderBottom: '0px',
  },
  cancelButtonRoot: {
    height: '30px',
    width: '97px',
    border: '0px',
    color: '#0077FF',
    borderRadius: '4px',
    backgroundColor: '#FFFFFF',
    textTransform: 'capitalize',
    lineHeight: '0em',
    marginRight: theme.spacing.unit * 2,
  },
  labelWidthStyle: {
    height: '1.6vh',
    color: '#354052',
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontSize: '14px',
  },
});

import React from 'react';
// Internal imports
import WebHelpers from '~/app/webHelpers.js';
// External imports
import { Checkbox, FormControl, FormControlLabel } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

// Styles imports
import '~app/Components/NewAccount/SingleOffice/css/printingSetup.css';

/**
 * Component that shows Printer Setting Row
 *
 * @component
 * @category Printing Setup
 * @subcategory Printer Setting Row
 */
const PrinterRow = props => {
  const payload = WebHelpers.getJWTPayload();
  return (
    <>
      <tr>
        <td className="printing-setup-tableRowStyle">{props.rowTitle}</td>
        {props.fieldList.map((field, index) => {
          if (field === 'disabled') {
            return (
              <td align="center" key={field + index}>
                <FormControl>
                  <FormControlLabel
                    label=""
                    className="printing-setup-labelWidthStyleCheckbox"
                    control={
                      <Checkbox
                        className="printing-setup-print-row-Checkbox"
                        icon={
                          <CheckBoxOutlineBlankIcon style={{ fontSize: 24, color: '#8FC3FF' }} />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fontSize: 24 }} />}
                        checked={true}
                        disabled={true}
                        inputProps={{ height: '20px', width: '20px' }}
                      />
                    }
                  />
                </FormControl>
              </td>
            );
          } else if (field.length && !(payload.season < 2020 && field === 'LPB0')) {
            return (
              <td align="center" key={field + index}>
                <FormControl>
                  <FormControlLabel
                    label=""
                    className="printing-setup-labelWidthStyleCheckbox"
                    control={
                      <Checkbox
                        className="printing-setup-print-row-Checkbox"
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{
                              fontSize: 24,
                              color: '#8FC3FF',
                            }}
                            color="secondary"
                          />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fontSize: 24 }} color="primary" />}
                        id={`${field}PrintingSetup`}
                        name={field}
                        checked={props?.fieldMap?.[field]?.configValue === '1'}
                        onChange={e => {
                          props.handleCheckbox(e);
                        }}
                        inputProps={{
                          height: '20px',
                          width: '20px',
                        }}
                      />
                    }
                  />
                </FormControl>
              </td>
            );
          } else {
            return <td align="center" key={field + index} />;
          }
        })}
      </tr>
    </>
  );
};

export default PrinterRow;

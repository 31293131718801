// External imports
import React, { useRef, useEffect } from 'react';
import {
  withStyles,
  Typography,
  Paper,
  Button,
  Tab,
  Tabs,
  List,
  MenuItem,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
// Internal imports
import DefaultWidgetImage from '~/images/DefaultWidgetImage.png';
import { fullWidgetDetailsStyles } from '~/app/Components/Dashboard/Widgets/DataModel/fullWidgetDetails';
import { useSetState } from '~/app/Utility/customHooks';
import { MAX_NUM_IMAGES_IN_PREVIEW } from '~/app/constants';
// Styling imports
import './css/fullWidgetDetails.css';

const styles = theme => fullWidgetDetailsStyles(theme);

const FullWidgetDetails = props => {
  const { classes } = props;
  const imageNextCounterRef = useRef(
    Math.floor(props.imageList.length / MAX_NUM_IMAGES_IN_PREVIEW),
  );
  const [state, setState] = useSetState({
    currentImageView: props.imageList.length > 0 ? props.imageList[0] : '',
    currentTab: 0,
    currentImageIndex: 0,
    currentImageSetIndex: 1,
    imageList: [],
  });

  useEffect(() => {
    renderInitialList();
  }, []);

  const renderInitialList = () => {
    let images = [];
    const imageList = [];
    if (props.imageList.length > 4) {
      images = props.imageList.slice(0, 4);
    } else {
      images = Object.assign(images, props.imageList);
    }
    images.forEach((image, index) => {
      imageList.push(renderImagePreviewCard(image, index));
    });
    setState({
      imageList,
    });
  };

  const handleTabChange = e => {
    setState({ currentTab: e.target.value });
  };

  const handleImagePreviewChange = (e, image, index) => {
    e.preventDefault();
    setState({
      currentImageView: image,
      currentImageIndex: index,
    });
  };

  const installWidget = e => {
    e.preventDefault();
    props.addWidget(props.widgetType);
    props.closeFullWidgetDetails();
  };

  const renderImagePreviewList = () => {
    return (
      <>
        {props.imageList.length > MAX_NUM_IMAGES_IN_PREVIEW && (
          <MenuItem
            className="image-preview-list-menu-item-container"
            disabled={state.currentImageSetIndex === 1}
            onClick={() => setNextImgList(false)}
          >
            <ExpandLess />
          </MenuItem>
        )}
        <List id="listItemPreviewImages" className={classes.previewImageList}>
          {state.imageList}
        </List>
        {props.imageList.length > MAX_NUM_IMAGES_IN_PREVIEW && (
          <MenuItem
            className="image-preview-list-menu-item-container"
            disabled={state.currentImageSetIndex > imageNextCounterRef.current}
            onClick={setNextImgList}
          >
            <ExpandMore />
          </MenuItem>
        )}
      </>
    );
  };

  const setNextImgList = (moveToNextImageSet = true) => {
    const imgSetIdx = moveToNextImageSet
      ? state.currentImageSetIndex + 1
      : state.currentImageSetIndex - 1;
    let newImages = [];
    const newImagesList = [];
    const endIdx = imgSetIdx * MAX_NUM_IMAGES_IN_PREVIEW;
    const startIdx = endIdx - MAX_NUM_IMAGES_IN_PREVIEW;
    newImages = props.imageList.slice(startIdx, endIdx);
    newImages.forEach((image, index) => {
      newImagesList.push(renderImagePreviewCard(image, index));
    });
    setState({
      currentImageSetIndex: imgSetIdx,
      imageList: newImagesList,
    });
  };

  const renderImagePreviewCard = (image, keyNum) => {
    const key = 'previewImage' + keyNum;
    return (
      <MenuItem
        key={key}
        className="image-preview-card-menu-item image-preview-list-menu-item-container"
        selected={state.selected === keyNum}
        classes={{
          root: classes.subimageCard,
          selected: classes.subimageCardSelected,
        }}
        onClick={e => handleImagePreviewChange(e, image, keyNum)}
      >
        <img src={image} height="80" width="105" />
      </MenuItem>
    );
  };

  const renderTitleTabBar = () => {
    return (
      <div className={classes.titleBarTabroot}>
        <Tabs
          value={state.currentTab}
          onChange={handleTabChange}
          classes={{ root: classes.tabRoot, indicator: classes.tabsIndicator }}
        >
          <Tab
            value={0}
            disableRipple
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
            label="Details"
          />
        </Tabs>
      </div>
    );
  };

  const renderPricingTab = () => {
    return <div className={classes.widgetTabContent}>Pricing</div>;
  };

  const renderDetailsTab = () => {
    return (
      <div className={classes.widgetTabContent}>
        <div className={classes.cardTitle}>{props.cardTitle}</div>
        <p className={classes.cardDescription}>{props.cardDescription}</p>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div className={classes.imagesContainer}>
            <div className={classes.cardMainImage}>
              <img
                className="noselect"
                src={!state.currentImageView ? DefaultWidgetImage : state.currentImageView}
              />
            </div>
          </div>
          <div className="image-preview-list-container">{renderImagePreviewList()}</div>
        </div>
        <div className={classes.buttonFooterSection}>
          <Button
            style={{ border: 'none' }}
            size="small"
            color="primary"
            onClick={props.closeFullWidgetDetails}
          >
            Close
          </Button>
          <Button
            className={classes.closeButtonStyle}
            size="small"
            color="primary"
            onClick={e => installWidget(e)}
            disabled={!!props.installed}
          >
            {props.buttonText === '0' ? 'Free' : props.buttonText} - Install
          </Button>
        </div>
      </div>
    );
  };
  return (
    <Paper className="paper-container">
      <Typography variant="body2" className={classes.modalTitle}>
        {props.cardTitle}
      </Typography>
      {renderTitleTabBar()}
      {state.currentTab === 0 && <div className="tab-container">{renderDetailsTab()}</div>}
      {state.currentTab === 1 && <div className="tab-container">{renderPricingTab()}</div>}
    </Paper>
  );
};

export default withStyles(styles)(FullWidgetDetails);

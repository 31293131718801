import { actions, FETCH_PREPARER_LIST } from './duck';

import { takeLatest, call, put } from 'redux-saga/effects';

import XlinkAPI from '~/app/api/xlinkAPI';
import ErrorHelpers from '~/app/errorHelpers.js';

export function* watchPreparerSetup() {
  yield takeLatest(FETCH_PREPARER_LIST, fetchSetupPreparers);
}

export function* fetchSetupPreparers(payload) {
  try {
    if (payload.endpoint === undefined) {
      payload.endpoint = `${XLINK_API_URL}/preparers`;
    }
    if (payload.limit === undefined || payload.offset === undefined) {
      throw 'Missing page parameters';
    }

    const res = yield call(
      XlinkAPI.simpleNoBodyPagedRequest,
      payload.endpoint,
      payload.limit,
      payload.offset,
    );
    let page = 0;
    if (payload.offset !== 0) {
      page = payload.offset / payload.limit;
    }

    yield put(actions.setPreparersPage(page));
    yield put(actions.onFetchPreparersSuccess(res.data.preparers, res.data.total, payload.offset));
  } catch (error) {
    yield put(actions.onPreparerSetupError(error));
    ErrorHelpers.handleError('Error fetching preparers', error);
  }
}

export const styles = {
  checkbox: {
    width: 17,
    height: 16,
    marginRight: '0.3vw',
  },
  checkboxIcon: {
    fontSize: 24,
  },
  checkboxOutline: {
    fontSize: 24,
    color: '#8FC3FF',
  },
  input: {
    width: '60vw',
    marginRight: '3vw',
  },
  inputWidth12: {
    width: '12vw',
    marginRight: '3vw',
  },
  inputWidth25: {
    width: '25vw',
    marginRight: '3vw',
  },
  inputWidth40: {
    width: '40vw',
    marginRight: '3vw',
  },
  labelWidth: {
    height: '1.6vh',
    color: '#354052',
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontSize: '0.875rem',
  },
  labelWidthShort: {
    width: '5vw',
    height: '1.6vh',
    color: '#354052',
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontSize: '0.875rem',
  },
  inputEROEFIN: {
    width: '12vw',
    marginRight: '3vw',
    paddingTop: '0rem',
    paddingBottom: '0rem',
    textAlign: 'left',
  },
  nativeSelectState: {
    width: '12vw',
    marginRight: '3vw',
    paddingTop: '0rem',
    paddingBottom: '0rem',
    paddingRight: '0rem',
    textAlign: 'left',
  },
};

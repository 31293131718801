// External imports
import React, { Fragment } from 'react';
import {
  Modal,
  Paper,
  Button,
  withStyles,
  FormControl,
  InputLabel,
  Input,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from '@material-ui/core';
// Internal imports
import AuthAPI from '~/app/api/authAPI.js';
import QRModal from './QRModal.jsx';
import Spinner from '#/Common/Spinner.jsx';
import ErrorHelpers from '~/app/errorHelpers.js';
import { SPINNER_DIALOGS } from '~/app/constants.js';
import GenericPasswordField from '#/Common/GenericPasswordField.jsx';
import { inputReqsMet, inputReqsNotMet } from '~/app/commonStyles';
import SimpleDialog from '#/Common/SimpleDialog.jsx';
import { useSetState } from '~/app/Utility/customHooks';
// Redux imports
import { useSelector } from 'react-redux';
// styling imports
import { styles } from '~/app/Components/Auth/css/ManageAuth.js';
import '~/app/Components/Auth/css/manageAuth.css';

const ManageAuth = props => {
  const { classes } = props;
  const { alert } = useSelector(state => ({
    alert: state.app.alert,
  }));

  const [state, setState] = useSetState({
    password: '',
    password2: '',
    passwordOld: '',
    processingRequest: false,
    qrModalOpen: false,
    qrcode: '',
    showPassChangeSuccess: false,
    spinnerDialog: '',
    passwordMeetsRequirements: false,
    retypeMeetsRequirements: false,
    showError: false,
  });

  const isResetPassMode = props.isResetPassMode;
  const labelPassword = isResetPassMode ? 'New Password' : 'Password';

  const setInitialState = () => {
    setState({
      password: '',
      password2: '',
      passwordOld: '',
      processingRequest: false,
      qrModalOpen: false,
      qrcode: '',
      showPassChangeSuccess: false,
      spinnerDialog: '',
      passwordMeetsRequirements: false,
      retypeMeetsRequirements: false,
    });
  };

  const onSuccessChange = () => {
    setState({ showPassChangeSuccess: false });
    props.onLogout();
  };

  const handlePasswordOldChange = e => {
    setState({ passwordOld: e.target.value });
  };

  const closeQRModal = () => {
    setState({ qrModalOpen: false });
    props.onLogout();
  };

  const getNewQRCode = () => {
    if (state.password !== state.password2) {
      ErrorHelpers.handleError('Unable to Generate New QR Code', {
        response: {
          data: { error_message: 'Passwords do not match', error_code: 0 },
        },
      });
      return;
    }
    setState({
      processingRequest: true,
      spinnerDialog: SPINNER_DIALOGS.FETCHING_QR,
    });
    AuthAPI.generateNewQRCode(state.password)
      .then(res => {
        props.closeAuthModal();
        setState({
          qrcode: res.data.qrcode,
          qrModalOpen: true,
          processingRequest: false,
        });
      })
      .catch(error => {
        setState({ processingRequest: false });
        ErrorHelpers.handleError('Unable to Generate New QR Code', error);
      });
  };

  const setNewPassword = () => {
    setState({
      processingRequest: true,
      spinnerDialog: SPINNER_DIALOGS.PROCESS_REQUEST,
    });
    AuthAPI.changePassword(state.passwordOld, state.password)
      .then(res => {
        props.closeAuthModal();
        setState({
          showPassChangeSuccess: true,
          processingRequest: false,
        });
      })
      .catch(error => {
        setState({ processingRequest: false });
        ErrorHelpers.handleError('Error Resetting Password', error);
        setState({ showError: true });
      });
  };

  const onExit = () => {
    setInitialState();
    props.closeAuthModal();
  };

  const setPasswordState = (password, passwordMeetsRequirements) => {
    setState({
      password,
      passwordMeetsRequirements,
    });
  };

  const setRetypeState = (pw, flag) => {
    setState({
      password2: pw,
      retypeMeetsRequirements: flag,
    });
  };

  return (
    <Fragment>
      <Modal
        id="qrCodeModal"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disableBackdropClick={true}
        open={props.openAuthModal}
        onClose={onExit}
      >
        <Paper elevation={5} style={{ width: '570px' }} classes={{ root: classes.qrCodeModal }}>
          <div className="modal-header">
            {isResetPassMode ? 'Change Password' : 'Generate NEW 2FA QR Code'}
            <Typography color="inherit" onClick={onExit}>
              X
            </Typography>
          </div>
          <div style={{ margin: '1.875rem' }}>
            {isResetPassMode ? (
              <FormControl fullWidth>
                <InputLabel htmlFor="txtOldPasswordMA" shrink>
                  Current Password
                </InputLabel>
                <Input
                  id="txtOldPasswordMA"
                  style={
                    state.passwordOld && state.passwordOld !== '' ? inputReqsMet : inputReqsNotMet
                  }
                  value={state.passwordOld}
                  onChange={handlePasswordOldChange}
                  disableUnderline
                  type="password"
                  autoComplete="off"
                />
              </FormControl>
            ) : (
              <Fragment />
            )}
            <GenericPasswordField
              label={labelPassword}
              id="txtNewPasswordMA"
              name="password"
              verticalOffset={1.5}
              handlePasswordCheck={(password, passwordMeetsRequirements) =>
                setPasswordState(password, passwordMeetsRequirements)
              }
            />
            <GenericPasswordField
              label="Re-Type Password"
              id="txtRetypePasswordMA"
              name="password2"
              firstvalue={state.password}
              retype={true}
              verticalOffset={-2}
              handlePasswordCheck={(password, passwordMeetsRequirements) =>
                setRetypeState(password, passwordMeetsRequirements)
              }
            />
            <Grid container justify="flex-end" spacing={32}>
              <Grid item>
                <Button
                  id="btnContinueToLoginActivateAcc"
                  variant="outlined"
                  style={{ border: 'none' }}
                  color="primary"
                  onClick={() => onExit()}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  id="btnContinueToLoginActivateAcc"
                  variant="contained"
                  color="primary"
                  onClick={isResetPassMode ? setNewPassword : getNewQRCode}
                  disabled={
                    isResetPassMode
                      ? !state.passwordMeetsRequirements ||
                        !state.retypeMeetsRequirements ||
                        !state.passwordOld ||
                        state.passwordOld === ''
                      : false
                  }
                >
                  {isResetPassMode ? 'Save' : 'Generate Code'}
                </Button>
              </Grid>
            </Grid>
          </div>

          {state.processingRequest ? (
            <div className={classes.spinner}>
              <Spinner
                size={125}
                loadingText={state.spinnerDialog}
                textColor="white"
                bgColor="grey"
              />
            </div>
          ) : (
            <Fragment />
          )}
        </Paper>
      </Modal>
      <QRModal
        closeQRModal={() => closeQRModal()}
        qrcode={state.qrcode}
        openQRCodeModal={state.qrModalOpen}
      />
      <Dialog
        open={state.showPassChangeSuccess}
        onClose={() => setState({ showPassChangeSuccess: false })}
        aria-labelledby="confirm-delete-title"
        aria-describedby="confirm-delete-desc"
        disableBackdropClick={true}
      >
        <DialogTitle id="confirm-delete-title">{'PASSWORD CHANGED'}</DialogTitle>
        <DialogContent id="confirm-delete-desc">
          <p>Password has been successfully changed. Please Login Again.</p>
        </DialogContent>
        <DialogActions>
          <Button autoFocus id="btnHideEmailSentModal" onClick={() => onSuccessChange()}>
            Continue To Login
          </Button>
        </DialogActions>
      </Dialog>
      <SimpleDialog
        open={state.showError}
        onConfirm={() => setState({ showError: false })}
        dialogTitle={alert.title}
        contentText={alert.body}
        contentTextTwo={alert.bodyTwo}
        confirmText="OK"
        styled
        errorMessage
      />
    </Fragment>
  );
};

export default withStyles(styles)(ManageAuth);

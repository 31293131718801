import React from 'react';
// Internal imports
import AccessControl from '~/app/Components/Auth/AccessControl.jsx';
// External imports
import { Card, CardContent, ButtonBase, withStyles } from '@material-ui/core';
import CheckedCircle from '@material-ui/icons/CheckCircle';
// Styling imports
import { styles } from '../../css/accountTypeSelectionStyles';

/**
 * Displays UI for a Account Type Card
 *
 * @component AccountTypeCard
 * @category AccountTypeSelection
 **/
const AccountTypeCard = props => {
  const {
    classes,
    accountType,
    accountTitle,
    accessLevel,
    selectedOption,
    image,
    setAccountType,
  } = props;
  // card and checkmark highlighting logic
  const highlightSelected =
    accountType === selectedOption ? classes.selectedOption : classes.unSelectedOption;
  const checkedCircleStyle =
    accountType === selectedOption ? classes.blueCheckStyle : classes.transparentCheckStyle;
  // Select button text logic
  const selectButton = accountType === selectedOption ? 'Selected' : 'Select';

  return (
    <AccessControl requiredAction="write" accessLevel={accessLevel}>
      <Card className={classes.cardStyle}>
        <ButtonBase
          id="btnbaseCreateEfinAddNew"
          className={highlightSelected}
          onClick={() => setAccountType(accountType)}
        >
          <CardContent>
            <div className={classes.alignLeftStyle}>
              <CheckedCircle className={checkedCircleStyle} />
            </div>
            <div>
              <img src={image} className={classes.imageDimensions} />
            </div>
            <div className={classes.titleStyle}>{accountTitle}</div>
            {props.authorities.map((authority, i) => (
              <div key={'authority-' + i} className={classes.optionStyle}>
                {authority}
              </div>
            ))}
            <div className={classes.styleButton}>{selectButton}</div>
          </CardContent>
        </ButtonBase>
      </Card>
    </AccessControl>
  );
};

export default withStyles(styles)(AccountTypeCard);

// External imports
import React, { useEffect } from 'react';
import { withStyles, InputLabel, Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
// Internal imports
import { HIERARCHY_TYPE, MAX_IMAGE_FILE_SIZE_MB } from '~/app/constants.js';
import { useSetState } from '~/app/Utility/customHooks.js';
import ErrorHelpers from '~/app/errorHelpers.js';
// Styling imports
import './css/logoSelector.css';
import { compStyles as logoCompStyles } from './css/logoSelector.js';

const styles = theme => logoCompStyles(theme);

const LogoSelector = props => {
  const { classes } = props;
  const [state, setState] = useSetState({
    baseString: '',
    showNoLogoSelected: false,
    showLogoSaveConfirm: false,
    imgChanged: false,
    imageTooLargeDlg: false,
  });

  useEffect(() => {
    if (props.logo !== undefined) {
      setState({ baseString: props.logo });
    }
  }, []);

  useEffect(() => {
    if (props.logo) {
      setState({ baseString: props.logo });
    }
  }, [props.logo]);

  const uploadLogo = () => {
    if (state.baseString === '' || !state.imgChanged) {
      setState({ showNoLogoSelected: true });
      return;
    }

    props.requestUploadLogo(state.baseString);
  };

  const fileSelectorHandler = (event, selectedImage) => {
    event.preventDefault();

    const filesize = document.getElementById('inputUploadLogo').files[0].size; // image size in bytes
    if (filesize / 1024 / 1024 > MAX_IMAGE_FILE_SIZE_MB) {
      setState({ imageTooLargeDlg: true });
      return;
    }

    const fileTypes = ['jpg', 'jpeg', 'png'];
    if (selectedImage) {
      const fileType = selectedImage.name.split('.').pop().toLowerCase();
      const fileExtension = selectedImage.name.split('.').pop().toLowerCase();
      const successful = fileTypes.indexOf(fileExtension) > -1;
      if (successful) {
        const reader = new FileReader();
        reader.readAsDataURL(selectedImage);
        reader.onload = event => {
          const logoToPost = event.target.result.toString();
          const logoLength = logoToPost.length;
          const subLogo = logoToPost.substring(logoToPost.indexOf(';base64,') + 8, logoLength);
          setState({
            fileSelected: selectedImage,
            imageName: selectedImage.name,
            selectedImage: URL.createObjectURL(selectedImage),
            baseString: logoToPost,
            imageString: subLogo,
            imageFileType: fileType,
            imgChanged: true,
          });
        };
        reader.onerror = error => {
          ErrorHelpers.handleError('Error selecting logo', error);
        };
      } else {
        ErrorHelpers.handleError('Error selecting logo');
      }
    }
  };

  const allowUpload = () => {
    if (props.hierarchyType === HIERARCHY_TYPE.PREPARER) {
      return false;
    }

    // If hasCobranding is null, it is not required in the component it is being used by
    if (props.hasCobranding !== null && !props.hasCobranding) {
      return false;
    }

    return !props.disabled;
  };

  const logo = state.baseString ? state.baseString : null;

  return (
    <div>
      <div className="logo-container">
        <InputLabel classes={{ root: classes.labelWidthStyle }} required={false} shrink>
          {props.logoType} Logo
        </InputLabel>
        <input
          accept="image/*"
          className={classes.input}
          id="inputUploadLogo"
          multiple
          type="file"
          onChange={e => fileSelectorHandler(e, e.target.files[0])}
        />

        {allowUpload() && (
          <label htmlFor="inputUploadLogo">
            <Button component="span" className={classes.button}>
              Select {props.logoType} Logo
            </Button>
          </label>
        )}
        <div className="logo-display-container">
          <img className="logo-display-img" src={logo} />
        </div>
        <div className="logo-img-size-description">Logo Size Max Height 50px & Max Width 300px</div>
        {allowUpload() && (
          <div className="logo-save-btn">
            <Button onClick={uploadLogo} disabled={!state.imgChanged}>
              Save and Upload Logo
            </Button>
          </div>
        )}
      </div>
      <Dialog
        open={state.showLogoSaveConfirm}
        onClose={() =>
          setState({
            showLogoSaveConfirm: false,
            baseString: null,
          })
        }
      >
        <DialogTitle>Logo Saved</DialogTitle>
        <DialogContent>
          <p>The logo was saved successfully.</p>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            id="btnLogoSelectorSuccess"
            onClick={() =>
              setState({
                showLogoSaveConfirm: false,
              })
            }
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={state.showNoLogoSelected}
        onClose={() => setState({ showNoLogoSelected: false })}
      >
        <DialogTitle>No Logo Selected</DialogTitle>
        <DialogContent>
          <p>Select a logo.</p>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            id="btnLogoSelectorNoLogoClose"
            onClick={() =>
              setState({
                showNoLogoSelected: false,
              })
            }
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={state.imageTooLargeDlg} onClose={() => setState({ imageTooLargeDlg: false })}>
        <DialogTitle>Image Too Large</DialogTitle>
        <DialogContent>
          The selected image is too large. Image size cannot exceed {MAX_IMAGE_FILE_SIZE_MB} MB.
        </DialogContent>
        <DialogActions>
          <Button
            id="btnCobrandLogoTooLargeOK"
            onClick={() => {
              setState({ imageTooLargeDlg: false });
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(LogoSelector);

import React from 'react';
// internal imports
import UpsertEmployer from './UpsertEmployer.jsx';
import UpsertCareProvider from './UpsertCareProvider.jsx';
import UpsertDonee from './UpsertDonee.jsx';
import UpsertEduInst from './UpsertEduInst.jsx';
import UpsertOccupation from './UpsertOccupation.jsx';
import UpsertSiteIdentifier from './UpsertSiteIdentifier.jsx';
import UpsertBankRTN from './UpsertBankRTN.jsx';
import UpsertZipCode from './UpsertZipcode.jsx';
import UpsertReferral from './UpsertReferral.jsx';
import UpsertUserStatus from './UpsertUserStatus.jsx';

import { USERDB } from '~/app/constants.js';

const UpsertUserDBModal = ({ dbType, handleCloseUpsertModal, handleUserDBUpsert }) => {
  const getUpsertModal = () => {
    switch (dbType) {
      case USERDB.ZIPCODE:
        return (
          <UpsertZipCode
            handleCloseUpsertModal={handleCloseUpsertModal}
            handleUserDBUpsert={handleUserDBUpsert}
          />
        );
      case USERDB.EMPLOYER:
        return (
          <UpsertEmployer
            handleCloseUpsertModal={handleCloseUpsertModal}
            handleUserDBUpsert={handleUserDBUpsert}
          />
        );
      case USERDB.CAREPROVIDER:
        return (
          <UpsertCareProvider
            handleCloseUpsertModal={handleCloseUpsertModal}
            handleUserDBUpsert={handleUserDBUpsert}
          />
        );
      case USERDB.DONEE:
        return (
          <UpsertDonee
            handleCloseUpsertModal={handleCloseUpsertModal}
            handleUserDBUpsert={handleUserDBUpsert}
          />
        );
      case USERDB.BANKRTN:
        return (
          <UpsertBankRTN
            handleCloseUpsertModal={handleCloseUpsertModal}
            handleUserDBUpsert={handleUserDBUpsert}
          />
        );
      case USERDB.OCCUPATION:
        return (
          <UpsertOccupation
            handleCloseUpsertModal={handleCloseUpsertModal}
            handleUserDBUpsert={handleUserDBUpsert}
          />
        );
      case USERDB.SITEIDENTIFIERS:
        return (
          <UpsertSiteIdentifier
            handleCloseUpsertModal={handleCloseUpsertModal}
            handleUserDBUpsert={handleUserDBUpsert}
          />
        );
      case USERDB.EDUINST:
        return (
          <UpsertEduInst
            handleCloseUpsertModal={handleCloseUpsertModal}
            handleUserDBUpsert={handleUserDBUpsert}
          />
        );
      case USERDB.REFERRALS:
        return (
          <UpsertReferral
            handleCloseUpsertModal={handleCloseUpsertModal}
            handleUserDBUpsert={handleUserDBUpsert}
          />
        );
      case USERDB.USERSTATUSCODES:
        return (
          <UpsertUserStatus
            handleCloseUpsertModal={handleCloseUpsertModal}
            handleUserDBUpsert={handleUserDBUpsert}
          />
        );
      default:
        return <div />;
    }
  };

  return <div>{getUpsertModal()}</div>;
};

export default UpsertUserDBModal;

import React from 'react';
import { useDispatch } from 'react-redux';

import { actions } from '~/app/redux/cobrand/duck.js';

import { withStyles } from '@material-ui/core/styles';

import { Input, InputLabel, FormControl, Button } from '@material-ui/core';

import NumberFormat from 'react-number-format';

import { styles } from '~/app/Pages/Setup/AccountSetup/css/AccountSetupStyles.js';
import '~/app/Pages/Setup/AccountSetup/css/accountSetup.css';

import XlinkAPI from '~/app/api/xlinkAPI.js';
import ErrorHelpers from '~/app/errorHelpers.js';

const TechSupportNumber = props => {
  const dispatch = useDispatch();

  const [techSupportNum, setTextSupportNum] = React.useState(
    props.techSupportNum.replace(/\D/g, ''),
  );

  const handleSaveTechSupportNumber = async e => {
    e.preventDefault();
    try {
      const response = await XlinkAPI.updateCobrandTechSupportNum(techSupportNum);
      if (response) {
        dispatch(actions.setTechSupportNumber(techSupportNum));
        props.showSuccess();
      }
    } catch (err) {
      ErrorHelpers.handleError('Unable to save cobranding tech support number', err);
    }
  };

  const stripNonNumeric = num => {
    num = num.replace(/\D/g, '');
    setTextSupportNum(num);
  };

  const { classes } = props;
  return (
    <div className="tech-support-number-content">
      <FormControl>
        <InputLabel htmlFor="techSupportCobrandSetup" shrink>
          Tech Support Number
        </InputLabel>
        <NumberFormat
          name="techSupportNumber"
          id="techSupportCobrandSetup"
          value={techSupportNum}
          onChange={e => stripNonNumeric(e.target.value)}
          disableUnderline
          format="(###) ###-####"
          customInput={Input}
        />
      </FormControl>
      <Button
        id="btnTechSupportSave"
        variant="outlined"
        color="primary"
        size="small"
        type="submit"
        classes={{ root: classes.contentSaveButton }}
        disabled={techSupportNum.length < 10}
        onClick={e => handleSaveTechSupportNumber(e)}
      >
        Save Tech Support Number
      </Button>
    </div>
  );
};

export default withStyles(styles)(TechSupportNumber);

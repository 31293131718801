export const compStyles = theme => {
  return {
    scanDocPage: {
      transform: "translate(-50%,-50%) !important",
      backgroundColor: "#f2f3f5",
      width: "100vw",
      height: "90vh",
      position: "fixed",
      left: "50%",
      top: "50%",
      zIndex: "3",
      padding: theme.spacing.unit * 3
    },
    docUploadInp: {
      border: "1px solid #EBEDF8",
      borderRadius: "4px",
      backgroundColor: "#DBEBFE",
      fontSize: "14px"
    },
    btnSpacing: {
      marginRight: theme.spacing.unit * 2
    }
  }
};
// Internal imports
import XlinkAPI from '~/app/api/xlinkAPI';
import { statusOK } from '~/app/webHelpers.js';
// Redux imports
import { store } from '~/app/redux/index';
import { actions as formViewerActions } from '~/app/redux/modules/formViewer';

/**
 * @module SignatureListHelper
 * @category Returns
 * @subcategory Signatures
 */

export const SIGNATURE_DOCUMENT_DESC = {
  FINAL_RETURN: 'Final Tax Return',
  BANK: 'Bank Documents',
  FNBA: 'Final Tax Return/Bank Docs',
  AMENDED_RETURN: 'Amended Return',
};

export const buildMissingSignatureWarning = signeeType => {
  return `Signature feature will remain disabled until the ${signeeType} who created the return saves their signature. Kindly request the ${signeeType} to log in to the software and use the 'Capture ${signeeType} Signature' link located in the Profile dropdown menu to capture their signature.`;
};

/**
 * Checks if an EFIN, preparer, or amended preparer exists in the DB.
 *
 * @function
 * @param {number} preparerID The preparer ID associated on the 1040.
 * @param {number} amendedPreparerID The amended preparer ID on the 1040-X.
 * @param {number} returnID The return ID of the tax return.
 * @param {boolean} isSelfPreparing an indicator for whether the return is being self preparered
 */
export const checkDBSignaturesExist = async (
  preparerID,
  amendedPreparerID,
  returnID,
  isSelfPreparing = false,
) => {
  // If this is a self prepared return, we do not require a prep signature
  const shouldSkipPrep = isSelfPreparing
    ? { status: 200, data: 1 } // mimic a success response so that it passes validation
    : preparerID && XlinkAPI.getppSignatureExists(preparerID, returnID);

  try {
    const checkDBSignaturesExist = await Promise.all([
      XlinkAPI.getefinSignatureExists(),
      shouldSkipPrep,
      amendedPreparerID && XlinkAPI.getppSignatureExists(amendedPreparerID, returnID),
    ]);

    return checkDBSignaturesExist;
  } catch (error) {
    store.dispatch(formViewerActions.showErrorDialog('Error checking signatures existence'));
  }
};

/**
 * Fetches the signature document list.
 *
 * @function
 * @param {number} returnID The return ID of the tax return.
 */
export const getDocumentList = async returnID => {
  try {
    const res = await XlinkAPI.getRemoteSignatureDocumentsList(returnID);
    return statusOK(res) && res.data;
  } catch (e) {
    store.dispatch(formViewerActions.showErrorDialog('Error Fetching Signature Documents List'));
  }
};

/**
 * Checks if the given document type is either Remote Signature or E-Signature.
 *
 * @function
 * @param {Object} object The document list.
 * @param {sting} value The type of document such as 0000, BANK, or AMENDED RETURN.
 */
export const findValueInObject = (object, value) => {
  const foundObject =
    object !== false &&
    object?.find(data => {
      if (
        data.documentName.includes(value) &&
        (data.documentDescription === 'Remote Signature ' ||
          data.documentDescription === 'E-Signature ')
      )
        return true;
      return false;
    });

  return foundObject;
};

/**
 * Fetches the details of the signature document.
 *
 * @function
 * @param {number} documentID The ID of the document.
 */
export const getSignatureDetails = async documentID => {
  try {
    const res = await XlinkAPI.getRemoteSignatureDetails(documentID);
    return statusOK(res) && res.data;
  } catch (e) {
    store.dispatch(formViewerActions.showErrorDialog('Error Fetching Signature Details List'));
  }
};

/**
 * Checks the status of the current document of whether it's signed, pending, or missing.
 *
 * @function
 * @param {string} documentDescription The description of the document.
 * @param {number} documentID The ID of the document.
 */
export const signatureStatus = async (documentDescription, documentID) => {
  let details, signatureDetails;
  if (documentDescription === 'Remote Signature ') {
    details = await getSignatureDetails(documentID);
    signatureDetails = details[0];
  } else if (documentDescription === 'E-Signature ') signatureDetails = true;
  else signatureDetails = false;

  return signatureDetails;
};

/**
 * Checks if we have a given signature.
 *
 * @function
 * @param {Object[]} dbSignaturesExist Array of the signatures that exists.
 * @param {number} index The index of the signature we are checking exists.
 */
export const doWeHaveSignatures = (dbSignaturesExist, index) => {
  return (
    dbSignaturesExist?.[index] &&
    dbSignaturesExist?.[index]?.status === 200 &&
    dbSignaturesExist?.[index]?.data === 1
  );
};

// External imports
import React, { Fragment, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import {
  Typography,
  withStyles,
  Button,
  Divider,
  Grid,
  Checkbox,
  FormControl,
  FormControlLabel,
  Input,
} from '@material-ui/core';
// Internal imports
import { useSetState } from '~/app/Utility/customHooks';
// Styling imports
import { styles } from '~/app/Components/Settings/Setup/AncillaryProducts/css/manageAncillary.js';
import '~/app/Components/Settings/Setup/AncillaryProducts/css/manageAncillary.css';

const ManageAncillary = props => {
  const { classes } = props;
  const [state, setState] = useSetState({
    purchaseInfo: props.purchaseInfo,
    allOfficesToggled: false,
    markupFee: props.purchaseInfo[0].add_on_markup_fee,
    financials: props.purchaseInfo[0].financials,
    non_financials: props.purchaseInfo[0].non_financials,
    isRemoteSignature: props.purchaseInfo[0].central_site_code === 'RMS',
  });

  useEffect(() => {
    checkIfAllOfficesChecked();
  }, []);

  const checkIfAllOfficesChecked = () => {
    let allPurchased = true;
    state.purchaseInfo.forEach(officeItem => {
      if (!officeItem.purchased) {
        allPurchased = false;
      }
    });
    setState({
      allOfficesToggled: allPurchased,
    });
  };

  const toggleOfficePurchaseChange = index => {
    setState(
      {
        purchaseInfo: state.purchaseInfo.map((officeItem, i) => {
          if (i === index) {
            return { ...officeItem, purchased: !officeItem.purchased };
          }
          return officeItem;
        }),
      },
      checkIfAllOfficesChecked,
    );
  };

  const toggleAllOfficePurchase = () => {
    setState({
      purchaseInfo: state.purchaseInfo.map((officeItem, i) => {
        return { ...officeItem, purchased: !state.allOfficesToggled };
      }),
      allOfficesToggled: !state.allOfficesToggled,
    });
  };

  const saveAncillaryProductForOffices = () => {
    props.toggleManageAncillary();
    const purchase = state.purchaseInfo[0];
    if (purchase.central_site_code !== 'RMS') {
      props.saveAncillaryProductForOffices(
        state.purchaseInfo,
        state.purchaseInfo[0].add_on_markup_fee.replace(/[$,]/g, ''),
        state.purchaseInfo[0].financials === true ? 'true' : 'false',
        state.purchaseInfo[0].non_financials === true ? 'true' : 'false',
      );
    }
  };

  const handleMarkUpFeeChange = e => {
    setState({
      purchaseInfo: state.purchaseInfo.map((officeItem, i) => {
        if (i === 0) {
          return { ...officeItem, add_on_markup_fee: e.target.value };
        }
        return officeItem;
      }),
    });
  };

  const toggleCheckBox = prod => {
    setState({
      purchaseInfo: state.purchaseInfo.map((officeItem, i) => {
        if (i === 0) {
          return { ...officeItem, [prod]: !officeItem[prod] };
        }
        return officeItem;
      }),
    });
  };

  const formatBankApprovedStr = str => {
    return props.formatBankApprovedStr(str);
  };

  const purchase = state.purchaseInfo[0];

  // This is here in case there is a data issue from Central Site
  // inserting the RMS data into the CLO DB.
  //
  // Central Site Code RMS is Remote Signature
  // We force the ability to edit to false as a secondary measure.
  if (purchase.central_site_code === 'RMS') {
    purchase.edit_add_on_markup_fee = false;
  }

  return (
    <>
      <Typography variant="body2" id="simple-modal-title" classes={{ root: classes.gridPadding }}>
        Ancillary Product
      </Typography>
      <div
        style={{
          display: 'inline-flex',
          paddingTop: '1.2em',
          paddingLeft: '4.5%',
        }}
      >
        <div className="manage-ancillary-section-title">
          {state.purchaseInfo[0].ancillary_product_name}
        </div>
      </div>
      <Grid container spacing={24} classes={{ container: classes.gridPadding }}>
        <Grid item xs={4}>
          <div className="manage-ancillary-product-detail-title">BASE FEE</div>
          <div className="manage-ancillary-product-detail-text">${purchase.BaseFee} Per Return</div>
        </Grid>
        <Grid item xs={4}>
          <div className="manage-ancillary-product-detail-title">ADD ON FEE/MARK UP AMOUNT</div>
          <FormControl>
            <FormControlLabel
              label=" Per Return"
              classes={{
                root: classes.productDetailInputText,
                label: classes.labelInputBox,
              }}
              control={
                <NumberFormat
                  classes={{ input: classes.inputStyleQty }}
                  id="markfeerequireid"
                  value={purchase.add_on_markup_fee}
                  disableUnderline
                  customInput={Input}
                  prefix="$"
                  thousandSeparator={true}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  onChange={handleMarkUpFeeChange}
                  disabled={!purchase.edit_add_on_markup_fee}
                />
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <div className="manage-ancillary-product-detail-title">AUTO ADD NON-FINANCIALS</div>
          <div className="manage-ancillary-product-detail-text">
            <FormControl>
              <FormControlLabel
                label={purchase.non_financials ? 'Included' : 'Not Included'}
                classes={{ root: classes.fin_nonfin_checkbox }}
                control={
                  <Checkbox
                    id="chkNonFinancialsIncluded"
                    color="primary"
                    onChange={() => toggleCheckBox('non_financials')}
                    value={state.purchaseInfo[0].non_financials ? 'true' : 'false'}
                    checked={state.purchaseInfo[0].non_financials}
                    disabled={!purchase.edit_non_financials}
                  />
                }
              />
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="manage-ancillary-product-detail-title">AUTO ADD FINANCIALS</div>
          <div className="manage-ancillary-product-detail-text">
            <FormControl>
              <FormControlLabel
                label={purchase.financials ? 'Included' : 'Not Included'}
                classes={{ root: classes.fin_nonfin_checkbox }}
                control={
                  <Checkbox
                    id="chkFinancialsIncluded"
                    color="primary"
                    onChange={() => toggleCheckBox('financials')}
                    value={state.purchaseInfo[0].financials ? 'true' : 'false'}
                    checked={state.purchaseInfo[0].financials}
                    disabled={!purchase.edit_financials}
                  />
                }
              />
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="manage-ancillary-product-detail-title">BANK APPROVED</div>
          <div className="manage-ancillary-product-detail-text">
            {formatBankApprovedStr(purchase.bank_approved)}
          </div>
        </Grid>
      </Grid>
      {props.officeList ? (
        <>
          <Divider
            style={{
              marginLeft: '4.5%',
              marginRight: '4.5%',
              marginTop: '3%',
              marginBottom: '3%',
            }}
          />
          <div style={{ display: 'inline-flex', paddingLeft: '4.5%' }}>
            <div className="manage-ancillary-section-title">Applied to Offices</div>
          </div>
          <div style={{ paddingLeft: '4.5%' }}>
            <FormControl>
              <FormControlLabel
                label="All Offices"
                classes={{ root: classes.labelCheckBoxSpacing }}
                control={
                  <Checkbox
                    id="chkAllAccountsForPurchased"
                    color="primary"
                    onChange={() => toggleAllOfficePurchase()}
                    value={state.allOfficesToggled ? 'true' : 'false'}
                    checked={state.allOfficesToggled || state.isRemoteSignature}
                    disabled={state.isRemoteSignature}
                  />
                }
              />
            </FormControl>
            <Grid container spacing={24} classes={{ container: classes.gridContainer }}>
              {state.purchaseInfo.map((office, i) => {
                return (
                  <Fragment key={i}>
                    {i % 2 === 0 ? <Grid item xs={1} /> : <></>}
                    <Grid item xs={i % 2 === 0 ? 6 : 5} style={styles.gridItem}>
                      <FormControl>
                        <FormControlLabel
                          label={office.firm_name + '(' + office.efin + ')'}
                          classes={{ root: classes.labelCheckBoxSpacing }}
                          control={
                            <Checkbox
                              id={'office' + office.office_id}
                              color="primary"
                              value={state.purchaseInfo[i].purchased ? 'true' : 'false'}
                              checked={state.purchaseInfo[i].purchased || state.isRemoteSignature}
                              onChange={() => toggleOfficePurchaseChange(i)}
                              disabled={state.isRemoteSignature}
                            />
                          }
                          disabled={state.isRemoteSignature}
                        />
                      </FormControl>
                    </Grid>
                  </Fragment>
                );
              })}
            </Grid>
          </div>
        </>
      ) : (
        <></>
      )}
      <div className="buttonFooterSection manage-ancillary-product-button-block">
        {purchase.central_site_code !== 'RMS' && (
          <Button
            id="btnToggleAddListCancel"
            onClick={props.toggleManageAncillary}
            classes={{ root: classes.cancelButton }}
          >
            Cancel
          </Button>
        )}
        <Button
          id="btnUpdateBillingState"
          onClick={saveAncillaryProductForOffices}
          classes={{ root: classes.buttonRoot }}
        >
          {purchase.central_site_code === 'RMS' ? 'Close' : 'Save'}
        </Button>
      </div>
    </>
  );
};

export default withStyles(styles)(ManageAncillary);

import React from 'react';
// Internal imports
import FormViewer from '~app/Pages/Returns/FormViewer.jsx';
// External imports
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
// Styling imports
import { styles } from '~/app/Pages/WizardMode/components/Forms/css/formStyles.js';
import '~/app/Pages/WizardMode/components/Forms/css/formStyles.css';

/**
 * Handles displaying FormViewer component
 *
 * @component FormViewerContainer
 * @category WizardMode
 */
const FormViewerContainer = props => {
  const { classes } = props;

  return (
    // hidden unless on step 8
    <div
      id="formContainer"
      className={classes.formContainer}
      style={{ display: props.wizardCurrentStep !== 9 ? 'none' : 'inline' }}
    >
      <div id="formBody" className={classes.formBody}>
        <Grid
          container
          spacing={16}
          justify="flex-start"
          wrap="wrap"
          direction="row"
          className="formviewer-container-grid"
        >
          <FormViewer
            setFormViewerCalcAPI={props.setFormViewerCalcAPI}
            setFormViewerHeader={props.setFormViewerHeader}
            handleStep={props.handleStep}
            wizardHasFLST={props.wizardHasFLST}
          />
        </Grid>
      </div>
    </div>
  );
};

export default withStyles(styles)(FormViewerContainer);

import React from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
} from '@material-ui/core';

const letterNameRegExp = new RegExp(/(^[A-Za-z0-9 \s]+$)|(^$)/);

const ClientLetterEditor = props => {
  const [input, updateInput] = React.useState('');

  const handleTextChange = e => {
    let valid = letterNameRegExp.test(e.target.value);
    if (valid) {
      updateInput(e.target.value);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    props.addNewClientLetter(input);
    updateInput('');
  };

  return (
    <Dialog
      disableAutoFocus={true}
      open={props.open}
      onClose={() => props.closeDialog()}
      keepMounted={false}
    >
      <DialogTitle>Create New Letter</DialogTitle>
      <DialogContent>
        <DialogContentText>Enter a new client letter name (Alphanumeric only)</DialogContentText>
        <form onSubmit={e => handleSubmit(e)}>
          <TextField
            autoFocus={true}
            margin="dense"
            id="newClientLetterInput"
            label="Client Letter Name"
            variant="filled"
            value={input}
            inputProps={{ maxLength: 10 }}
            fullWidth
            // onChange={e => updateInput(e.target.value)}
            onChange={e => handleTextChange(e)}
            autoComplete="off"
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.closeDialog()} color="primary">
          Cancel
        </Button>
        <Button onClick={e => handleSubmit(e)} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ClientLetterEditor;

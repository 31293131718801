// External Imports
import Fingerprint2 from 'fingerprintjs2';
import '@babel/polyfill';
// Internal Imports
import XlinkAPI from './xlinkAPI';
import { HIERARCHY_TYPE } from '~/app/constants.js';

export default class UtilityAPI {
  static getLastDrilledOfficeEfinID = async ddh => {
    if (!ddh) return undefined;
    for (let i = ddh.length - 1; i >= 0; i--) {
      if (ddh[i].role === HIERARCHY_TYPE.EFIN) {
        // office
        return XlinkAPI.getBasicOfficeInfo().then(res => {
          return res.data.efin_id;
        });
      }
    }
    return undefined;
  };

  /**
   * Note: ddh is the drill down history it will contain a array of login info including (loginID)
   * to get the last drilldown grab the last item in the array.
   */
  static getBasicOfficeInfo = async ddh => {
    if (!ddh) return undefined;
    for (let i = ddh.length - 1; i >= 0; i--) {
      if (
        ddh[i].role === HIERARCHY_TYPE.EFIN ||
        ddh[i].role === HIERARCHY_TYPE.PREPARER ||
        ddh[i].role === HIERARCHY_TYPE.FRANCHISE ||
        ddh[i].role === HIERARCHY_TYPE.SSB
      ) {
        return XlinkAPI.getBasicOfficeInfo().then(res => {
          return res.data;
        });
      }
    }
    return undefined;
  };

  static getLastDrilledOfficeLoginID = async ddh => {
    for (let i = ddh.length - 1; i >= 0; i--) {
      if (ddh[i].role === HIERARCHY_TYPE.EFIN) {
        // office
        return ddh[i].loginID;
      }
    }
    return undefined;
  };

  static hashFingerprint = components => {
    const values = components.map(c => c.value);
    return Fingerprint2.x64hash128(values.join(''), 31);
  };

  static trimNull = aStr => {
    if (!aStr || aStr === '') return aStr;
    const nullByte = '\u0000';
    if (aStr.includes(nullByte)) {
      return aStr.replace(/\u0000/g, '');
    }
    return aStr;
  };
}

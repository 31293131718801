import React, { useEffect } from 'react';

/**
 * Setup the chat JS scripts to be added to the body elements.
 *
 * @returns Empty element
 */
const Chat = () => {
  useEffect(() => {
    if (CHAT_ID && ENVIRONMENT !== 'local') {
      const chatScript = document.getElementById('jsm-chat-script');
      const chatBtnEle = document.getElementById('jsm-chat');

      if (!chatScript || !chatBtnEle) {
        if (!chatScript) {
          const script = document.createElement('script');
          script.src = 'https://chat-api.spartez-software.com/chat-widget.js';
          script.setAttribute('id', 'jsm-chat-script');
          document.body.appendChild(script);
        }

        if (!chatBtnEle) {
          const chatEle = document.createElement('chat-widget');
          chatEle.setAttribute('id', 'jsm-chat');
          chatEle.setAttribute('jira-id', '412e4739-2d2d-3b63-8b0a-deb8cba5ebe9');
          chatEle.setAttribute('service-desk-id', CHAT_ID);
          document.body.appendChild(chatEle);
        }
      }

      if (chatBtnEle && chatBtnEle.style.display === 'none') {
        chatBtnEle.style.display = 'flex';
      }
    } else {
      if (ENVIRONMENT === 'local') {
        console.log('Chat Support is not available for the local environment');
      }
    }
  }, []);

  return <></>;
};

export default React.memo(Chat);

// External imports
import React, { useEffect } from 'react';
import {
  Paper,
  Grid,
  Button,
  withStyles,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch,
} from '@material-ui/core';
// Internal imports
import WebHelpers, { statusOK } from '~/app/webHelpers.js';
import XlinkAPI from '~/app/api/xlinkAPI.js';
import { useSetState } from '~/app/Utility/customHooks';
import SimpleDialog from '#/Common/SimpleDialog.jsx';
import ErrorHelpers from '~/app/errorHelpers.js';
// Styling imports
import { styles } from '~/app/Pages/Packages/css/statePackage.js';

const StatePackages = props => {
  const { classes } = props;
  const payload = WebHelpers.getJWTPayload();
  const [state, setState] = useSetState({
    individualPackages: [],
    businessPackages: [],
    isConfirmDialogOpen: false,
    isSuccessDialogOpen: false,
  });

  useEffect(() => {
    getAllPackages();
  }, [payload.season]);

  const getAllPackages = async () => {
    try {
      const res = await XlinkAPI.getStatePackageStatus();

      if (statusOK(res) && res.data) {
        if (payload.season < 2024) {
          setState({
            individualPackages: packageSort(res.data?.individualPackages),
          });
        } else {
          setState({
            individualPackages: packageSort(res.data?.individualPackages),
            businessPackages: packageSort(res.data?.businessPackages),
          });
        }
      }
    } catch (error) {
      ErrorHelpers.handleError('Fetch Error', error);
    }
  };

  const updateStatePackages = async () => {
    setState({ isConfirmDialogOpen: false });
    try {
      await XlinkAPI.updateStatePackageStatus(state.individualPackages, state.businessPackages);
      setState({ isSuccessDialogOpen: true });
    } catch (error) {
      ErrorHelpers.handleError('Error Updating Pacakge Status', error);
    }
  };

  // sorts an array of packages by name, for display puposes
  const packageSort = pkgList => {
    return pkgList.sort((a, b) => a.code.localeCompare(b.code));
  };

  const handleChange = (e, isBusiness) => {
    const updatedPackage = {
      code: e.target.name,
      status: e.target.checked ? 1 : 0,
      IsBusiness: isBusiness,
    };

    let updatedList = state.individualPackages;
    if (isBusiness) {
      updatedList = state.businessPackages;
    }
    updatedList[e.target.id] = updatedPackage;

    if (isBusiness) {
      setState({
        businessPackages: updatedList,
      });
    } else {
      setState({
        individualPackages: updatedList,
      });
    }
  };

  const renderStatePackages = (packageList, isBusiness) => {
    return packageList.map((pkg, i) => (
      <Grid item key={pkg.code + i}>
        <span className={classes.statePackageCode}>{pkg.code}</span>
        <Switch
          id={`${i}`}
          name={pkg.code}
          onChange={e => handleChange(e, isBusiness)}
          checked={!!pkg.status}
          classes={{
            switchBase: classes.colorSwitchBase,
            checked: classes.colorChecked,
            bar: classes.colorBar,
          }}
        />
      </Grid>
    ));
  };

  return (
    <div className="body-container">
      <div className="body-main-block">
        <div className="body-content">
          <div className="server-container">
            <h1 className="ovLabel tableLabel">State Packages</h1>
            <Paper className="ovNotebook ovListPaper" elevation={5}>
              <div>1040 State Packages</div>
              <Divider />
              <Grid
                container
                direction="column"
                classes={{ container: classes.statePackageGridContainer }}
              >
                {renderStatePackages(state.individualPackages, 0)}
              </Grid>
              {payload.season > 2023 && (
                <>
                  <div>Business State Packages</div>
                  <Divider />
                  <Grid
                    container
                    direction="column"
                    classes={{ container: classes.statePackageGridContainer }}
                  >
                    {renderStatePackages(state.businessPackages, 1)}
                  </Grid>
                </>
              )}

              <div className={classes.updateButtonContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  onClick={() => setState({ isConfirmDialogOpen: true })}
                >
                  Update Available Packages
                </Button>
              </div>
              <SimpleDialog
                open={state.isConfirmDialogOpen}
                onConfirm={updateStatePackages}
                onClose={() => setState({ isConfirmDialogOpen: false })}
                dialogTitle="Update State Packages"
                contentText={`WARNING: Saving changes will affect available state packages for All users, please Confirm.`}
                styled={true}
              />

              <Dialog
                open={state.isSuccessDialogOpen}
                onClose={() => setState({ isSuccessDialogOpen: false })}
              >
                <DialogTitle>{`Update State Packages`}</DialogTitle>
                <DialogContent>
                  Available State Packages have been successfully updated
                </DialogContent>
                <DialogActions>
                  <Button
                    id="btnTextMessageSucceed"
                    onClick={() => setState({ isSuccessDialogOpen: false })}
                  >
                    OK
                  </Button>
                </DialogActions>
              </Dialog>
            </Paper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(StatePackages);

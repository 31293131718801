import { actions, FETCHED_PREPARER_LIST } from "./duck";

import { takeLatest, call, put } from "redux-saga/effects";

import XlinkAPI from "~/app/api/xlinkAPI";
import UtilityAPI from "~/app/api/utilityAPI";
import ErrorHelpers from "~/app/errorHelpers.js";

export function* watchFetchPreparerList() {
  yield takeLatest(FETCHED_PREPARER_LIST, fetchPreparerList);
}

export function* fetchPreparerList(payload) {
  try {
    yield put(actions.requestPreparerList());

    if (payload.filterParams.preparerID == null) {
      payload.filterParams.preparerID = 0;
    }

    let officeLoginID = yield call(
      UtilityAPI.getLastDrilledOfficeLoginID,
      payload.ddh
    );

    if (!officeLoginID) {
      officeLoginID = 0;
    }
    payload.filterParams.officeLoginID = officeLoginID;

    const res = yield call(XlinkAPI.getDashboardList, payload.filterParams);

    yield put(actions.requestPreparerListSuccess(res.data));
  } catch (error) {
    ErrorHelpers.handleError("Error fetching filtered preparers", error);
    yield put(actions.requestPreparerListError());
  }
}

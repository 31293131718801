// External imports
import React from 'react';
import { withStyles, Paper, Button, Modal } from '@material-ui/core';
import PropTypes from 'prop-types';
import defaultImage from '~/images/icons/icons8-default-widget.png';
// Internal imports
import FullWidgetDetails from './FullWidgetDetails.jsx';
import XlinkAPI from '~/app/api/xlinkAPI';
import { widgetStoreCardStyleConfig } from './DataModel/widgetStoreCard.js';
import { useSetState } from '~/app/Utility/customHooks.js';
import { statusOK } from '~/app/webHelpers.js';
import ErrorHelpers from '~/app/errorHelpers.js';

const styles = theme => widgetStoreCardStyleConfig(theme);

const WidgetStoreCard = props => {
  const { classes } = props;
  const [state, setState] = useSetState({
    openWidgetDetails: false,
    imageList: [],
  });

  const openFullWidgetDetails = async () => {
    try {
      const res = await XlinkAPI.getFullWidgetDetails(props.widgetType);

      if (statusOK(res)) {
        setState({
          imageList: res.data.imageList,
          openWidgetDetails: true,
        });
      }
    } catch (error) {
      ErrorHelpers.handleError('Error getting widget details', error);
    }
  };

  const closeFullWidgetDetails = () => {
    setState({ openWidgetDetails: false });
  };

  return (
    <Paper className={classes.card}>
      {props.installed && <div className={classes.widgetInstalled}> {'\u2713'} Installed</div>}
      <div className={classes.cardImage}>
        <img
          src={props.cardImage}
          className="noselect"
          style={{ transform: 'scale(1,1)' }}
          width="70"
          height="70"
        />
      </div>
      <div className={classes.cardTitle} style={{ textAlign: 'left' }}>
        {props.cardTitle}
      </div>
      <p className="store-description">{props.cardDescription}</p>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={state.openWidgetDetails}
        onClose={closeFullWidgetDetails}
        disableEnforceFocus={true}
        className={classes.detailsModal}
      >
        <Paper className={classes.widgetDetailsModal}>
          <FullWidgetDetails
            cardTitle={props.cardTitle}
            cardImage={props.cardImage}
            cardDescription={props.cardDescription}
            buttonText={props.buttonText}
            widgetType={props.widgetType}
            closeFullWidgetDetails={closeFullWidgetDetails}
            imageList={state.imageList}
            addWidget={props.addWidget}
            installed={props.installed}
          />
        </Paper>
      </Modal>
      <Button
        id="btnOpenWidgetDetailsWidgetStoreCard"
        size="small"
        color="primary"
        className={classes.cardButton}
        onClick={openFullWidgetDetails}
      >
        {props.buttonText === '0' ? 'Free' : props.buttonText}
      </Button>
    </Paper>
  );
};

WidgetStoreCard.propTypes = {
  cardTitle: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  widgetType: PropTypes.number.isRequired,
};

WidgetStoreCard.defaultProps = {
  cardImage: defaultImage,
  cardDescription: 'No Description',
};

export default withStyles(styles)(WidgetStoreCard);

export const taxReturnPaymentStyles = theme => {
  return {
    paymentsModal: {
      top: '50%',
      left: '50%',
      boxShadow: '0 0 20px 0 rgba(0,0,0,0.25)',
      height: '85%',
      width: '60%',
      overflow: 'hidden',
      padding: '0',
      transform: 'translate(-50%, -50%)',
      position: 'absolute',
    },
    gridContainer: {
      height: '75%',
      overflowY: 'auto',
      padding: theme.spacing.unit * 2,
    },
    payInpt: {
      width: '90%',
    },
    payBtn: {
      marginRight: theme.spacing.unit,
    },
    disabledInp: {
      backgroundColor: '#d7dce2',
    },
    tabSelected: {
      disabled: true,
      borderColor: 'rgba(0,0,0,0)',
    },
    tabNotSelected: {
      borderColor: 'rgba(0,0,0,0)',
    },
    table: {
      maxHeight: 150,
      overflow: 'auto',
    },
    tablePaper: {
      marginTop: theme.spacing.unit,
      width: '100%',
      maxHeight: 250,
      overflowY: 'auto',
      overflowX: 'none',
      marginBottom: theme.spacing.unit,
    },
    tableCell: {
      padding: '.25rem .75rem',
      textAlign: 'center',
    },
    'tableCell:last-child': {
      borderBottom: 0,
    },
    tableReceivedCell: {
      width: '13%',
    },
    paymentDetailsTabNavItem: {
      borderRadius: 0,
    },
    paymentSummaryContent: {
      padding: theme.spacing.unit * 2,
      boxShadow: 'none',
      height: '80%',
      overflow: 'auto',
    },
    manualPaymentButtonGrid: {
      textAlign: 'right',
    },
  };
};

// External imports
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ListItem, ListItemText, List, Typography, withStyles, Collapse } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
// Internal imports
import { getObjectKeyByValue } from '~/app/Utility/general';
import { icons } from '~/app/Components/Common/Sidebar/helpers/constants.js';
import { SIDEBAR_TAB, SIDEBAR_TAB_SUB_MENU, SIDEBAR_PATHNAME_SUB_MENU } from '~/app/constants.js';
// Redux imports
import { useSelector } from 'react-redux';
// Styling imports
import { styles } from '~/app/Components/Common/Sidebar/css/sidebar.js';
import '~/app/Components/Common/Sidebar/css/sidebar.css';

/**
 * Displays a navigation button for the sidebar.
 *
 * @component
 * @category SidebarButton
 * @subcategory Sidebar
 */
const SidebarButton = props => {
  const { classes } = props;
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const { messageBadgeCount } = useSelector(state => ({
    messageBadgeCount: state.overview.messageBadgeCount,
  }));

  const { id, badgeCount } = props;
  const hasSubMenu = SIDEBAR_TAB_SUB_MENU[`${id}`]?.length > 0;
  const hasBadge =
    (id === SIDEBAR_TAB.CHECKS && badgeCount > 0) ||
    (id === SIDEBAR_TAB.SECURE_MESSAGES && messageBadgeCount > 0);

  useEffect(() => {
    if (!props.isSelected || !props.isDrawerOpen) {
      setIsSubMenuOpen(false);
    }
  }, [props.isSelected, props.isDrawerOpen]);

  /**
   * Handles displaying the sidebar sub menu.
   *
   * @param {number} id - Sidebar title index.
   * @returns {JSX} The sidebar sub menu.
   */
  const sidebarNavTextSubMenu = id => {
    const subMenu = SIDEBAR_TAB_SUB_MENU[`${id}`];

    if (subMenu) {
      return (
        <Collapse
          in={isSubMenuOpen}
          className={classes.subMenuCollapse}
          timeout={500}
          orientation="vertical"
          unmountOnExit
        >
          {subMenu.map((title, idx) => (
            <Link key={idx} className="body-router-link" to={`${SIDEBAR_PATHNAME_SUB_MENU[title]}`}>
              <List className={classes.subMenuList}>
                <ListItem
                  button
                  className={
                    SIDEBAR_PATHNAME_SUB_MENU[title] === location.pathname
                      ? classes.selectedSubMenu
                      : classes.nonSelectedsubMenu
                  }
                >
                  <ListItemText>
                    <Typography className={classes.sidebarListItemLabel}>{title}</Typography>
                  </ListItemText>
                </ListItem>
              </List>
            </Link>
          ))}
        </Collapse>
      );
    } else {
      return <></>;
    }
  };

  /**
   * Determines which style depending on sidebar selection.
   *
   * @returns {string} The sidebar style.
   */
  const handleSelectedSidebarStyle = () => {
    let style = '';

    if (props.isSelected && hasSubMenu) {
      style = classes.selectedSubNav;
    } else if (isSubMenuOpen || props.isSelected) {
      style = classes.selected;
    } else {
      style = classes.closeDrawer;
    }

    return style;
  };

  /** Handles opening and closing sub menu's */
  const handleSubMenu = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  /**
   * Gets the sidebar title text.
   *
   * @param {number} id - Sidebar title index.
   * @returns {string} Sidebar title.
   */
  const sidebarNavText = id => {
    let sidebarTitle = getObjectKeyByValue(SIDEBAR_TAB, id);

    sidebarTitle = sidebarTitle.toLowerCase().replace('_', ' ');

    return sidebarTitle;
  };

  return (
    <>
      <ListItem button href="#" className={handleSelectedSidebarStyle()} id={'btnSideBar' + id}>
        {
          /* add the stored icon, or a '?' if we don't have an icon for the slot yet */
          icons[id] !== undefined ? <img src={icons[id]} className="sidebar-icon" /> : '?'
        }
        {
          /* add the badge if we have a badge count, badge-adjust must be added above */
          id === SIDEBAR_TAB.CHECKS && badgeCount > 0 ? (
            <span className="message-badge badge badge-light">{badgeCount}</span>
          ) : (
            <></>
          )
        }

        {
          /* add message badge */
          id === SIDEBAR_TAB.SECURE_MESSAGES && messageBadgeCount > 0 ? (
            <span className="message-badge badge badge-light">{messageBadgeCount}</span>
          ) : (
            <></>
          )
        }
        <ListItemText className={hasBadge ? classes.itemBadge : classes.listItem}>
          <Typography className={classes.sidebarListItemLabel}>{sidebarNavText(id)}</Typography>
        </ListItemText>
        {hasSubMenu &&
          (isSubMenuOpen ? (
            <ExpandLess onClick={handleSubMenu} className={classes.expand} />
          ) : (
            <ExpandMore onClick={handleSubMenu} className={classes.expand} />
          ))}
      </ListItem>
      {sidebarNavTextSubMenu(id)}
    </>
  );
};

export default withStyles(styles)(SidebarButton);

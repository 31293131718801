import React, { useEffect } from 'react';
import { useSetState } from '~/app/Utility/customHooks';
// External imports
import { List, ListItem, ListItemIcon, ListItemText, withStyles } from '@material-ui/core';
import TextsmsIcon from '@material-ui/icons/Textsms';
import ForumIcon from '@material-ui/icons/Forum';
import { usePrevious } from 'react-use';
// Internal imports
import { MESSAGE_TYPE, MESSAGE_TYPE_ID } from '~/app/constants.js';
// Styling imports
import { styles } from '~/app/Pages/Messages/css/messageList.js';
import '~/app/Pages/Messages/css/messageList.css';

const MessageList = ({
  messageList,
  handleMessageClick,
  getMessageList,
  unreadMessageCount,
  showAllMessages,
  messageType,
  updateSortCriteria,
  inReturn,
  classes,
}) => {
  const [state, setState] = useSetState({
    selectedIndex: 0,
  });

  const prevCount = usePrevious(unreadMessageCount);

  useEffect(() => {
    getNewMessages();
  }, [unreadMessageCount]);

  const getNewMessages = async () => {
    // Only update the message list if there are new text messages in a return.
    if (inReturn && (unreadMessageCount > prevCount || !prevCount)) {
      let newUnreadTextMessageCount = await getMessageList(true);
      // Keep the current selected message position when new text messages arrive.
      newUnreadTextMessageCount > 0 &&
        setState({ selectedIndex: newUnreadTextMessageCount + state.selectedIndex });
    }
  };

  const handleClick = (id, idx) => {
    setState({ selectedIndex: idx });
    handleMessageClick(id);
  };

  const renderMessageList = () => {
    return showAllMessages
      ? getMessages(messageList)
      : getMessages(messageList.filter(m => m.is_read === false));
  };

  const getMessages = list => {
    return list.map((item, idx) => {
      return (
        <List key={item.id} component="nav" aria-label="messages" classes={{ root: classes.list }}>
          <ListItem
            button
            selected={state.selectedIndex === idx}
            onClick={() => handleClick(item.id, idx)}
            classes={{ root: classes.listItem }}
          >
            {item.message_type_id === MESSAGE_TYPE_ID.TEXTLINK ? (
              <ListItemIcon>
                <TextsmsIcon classes={{ root: !item.is_read && classes.unreadIcon }} />
              </ListItemIcon>
            ) : (
              <ListItemIcon>
                <ForumIcon
                  classes={{
                    root: !item.is_read && !messageType === MESSAGE_TYPE.SENT && classes.unreadIcon,
                  }}
                />
              </ListItemIcon>
            )}
            <ListItemText
              classes={{
                root: classes.listItemTextBlock,
                primary:
                  !item.is_read && !messageType === MESSAGE_TYPE.SENT
                    ? classes.listItemTextUnread
                    : classes.listItemText,
              }}
              primary={item.subject}
              secondary={item.date}
            />
          </ListItem>
        </List>
      );
    });
  };

  const onShowNewMessagesClick = () => {
    updateSortCriteria(0);
    getMessageList();
  };

  const newMessagesExist = () => {
    // Do not show since new text messages in a return
    // will be updated in real-time in message list.
    if (inReturn) {
      return false;
    }
    if (messageList == null && unreadMessageCount > 0 && messageType == MESSAGE_TYPE.RECEIVED) {
      return true;
    }

    if (messageList == null || messageType == MESSAGE_TYPE.SENT) {
      return false;
    }

    return unreadMessageCount > messageList.filter(m => m.is_read === false).length;
  };

  const showList = () => {
    if (messageList == null || messageType == undefined) {
      return false;
    }
    if (messageType == MESSAGE_TYPE.RECEIVED) {
      if (showAllMessages && messageList.length > 0) {
        return true;
      }
      let unread = messageList.filter(m => m.is_read === false);
      if (unread.length == 0) {
        return false;
      }
      return true;
    }
    return true;
  };

  return (
    <div className={!inReturn ? 'message-list-secure-block' : 'message-list-return-block'}>
      {newMessagesExist() && (
        <div className="message-list-new-messages" onClick={onShowNewMessagesClick}>
          Show new messages
        </div>
      )}
      <ul className={`message-list-secure-list ${inReturn && 'message-list-return-list'}`}>
        {showList() ? renderMessageList() : !inReturn && <div>No New Messages</div>}
      </ul>
    </div>
  );
};

export default withStyles(styles)(MessageList);

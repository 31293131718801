export const compStyles = theme => {
  return {
    button: {
      margin: theme.spacing.unit,
    },
    input: {
      display: 'none',
    },
    labelWidthStyle: {
      fontSize: '1rem',
      marginLeft: '1vw',
      transform: 'none',
    },
  };
};

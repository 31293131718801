// External imports
import React, { Fragment } from 'react';
// Internal imports
import { Button, Grid, withStyles } from '@material-ui/core';
import { PAYJUNCTION_TERMINAL_STATUSES } from '~/app/constants.js';
import { CancelTransaction } from '~/app/Pages/Payments/PaymentModal/api/PaymentModalAPI.js';
// Styling imports
import { styles } from '~/app/Pages/Payments/PaymentModal/css/CapturePaymentStyles.js';

// ProgressButtons returns a <Grid> item or items containing the necessary buttons for a given terminal status
const ProgressButtons = props => {
  const cancelButton = disabled => {
    return (
      <Button
        id="btnCancelTransaction"
        variant="outlined"
        color="primary"
        size="small"
        disabled={disabled}
        onClick={() => CancelTransaction(props.selectedSmartTerminal)}
      >
        Cancel Transaction
      </Button>
    );
  };

  const closeButton = () => {
    return (
      <Button
        id="btnCloseTransaction"
        variant="outlined"
        color="primary"
        size="small"
        onClick={() => props.onClose()}
      >
        Close
      </Button>
    );
  };

  const tryAgainButton = () => {
    return (
      <Button
        id="btnretry"
        variant="outlined"
        color="primary"
        size="small"
        onClick={() => {
          props.handleTryAgain();
        }}
      >
        Try Again
      </Button>
    );
  };

  const receiptButton = () => {
    return (
      <Button
        id="btnPrint"
        variant="outlined"
        color="primary"
        size="small"
        onClick={() => {
          props.handleViewReceipt();
        }}
      >
        Receipt
      </Button>
    );
  };

  const generateCompletionButtons = () => {
    return (
      <Fragment>
        <Grid item xs={6} classes={{ item: classes.payJunctionPaymentButtonGrid }}>
          {receiptButton()}
        </Grid>
        <Grid item xs={6} classes={{ item: classes.payJunctionPaymentButtonGrid }}>
          {closeButton()}
        </Grid>
      </Fragment>
    );
  };

  const actionButtons = () => {
    const terminalStatus = props.terminalStatus;
    if (props.isACHPayment) {
      return generateCompletionButtons();
    }
    if (!terminalStatus) {
      return (
        <Grid item xs={12} classes={{ item: classes.capturePaymentContentGridItem }}>
          {cancelButton(true)}
        </Grid>
      );
    }
    switch (terminalStatus.status) {
      case PAYJUNCTION_TERMINAL_STATUSES.CONNECTING:
      case PAYJUNCTION_TERMINAL_STATUSES.IN_PROGRESS:
        return (
          <Grid item xs={12} classes={{ item: classes.capturePaymentContentGridItem }}>
            {cancelButton(terminalStatus.transactionId !== 0)}
          </Grid>
        );
      case PAYJUNCTION_TERMINAL_STATUSES.NO_CONNECTION:
      case PAYJUNCTION_TERMINAL_STATUSES.BUSY:
      case PAYJUNCTION_TERMINAL_STATUSES.COMPLETE:
        // Transaction ID returned and status is successful, no need to prompt for a retry
        if (
          terminalStatus.transactionId !== 0 &&
          terminalStatus.transactionDetails.status !== PAYJUNCTION_TERMINAL_STATUSES.DECLINED
        ) {
          return generateCompletionButtons();
        }
        return (
          <Fragment>
            <Grid item xs={6}>
              {tryAgainButton()}
            </Grid>
            <Grid item xs={6} classes={{ item: classes.payJunctionPaymentButtonGrid }}>
              {closeButton()}
            </Grid>
          </Fragment>
        );
    }
  };

  const { classes } = props;

  return actionButtons();
};

export default withStyles(styles)(ProgressButtons);

export const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  cancelButtonRoot: {
    height: '30px',
    border: '0px',
    backgroundColor: '#FFFFFF',
    textTransform: 'capitalize',
    lineHeight: '0em',
    marginRight: '1em',
  },
  cssLabel: {
    '&$cssFocused': {
      color: '#40a9ff',
    },
  },
  cssFocused: {},
  cssUnderline: {
    '&:after': {
      borderBottomColor: '#40a9ff',
    },
  },
  selectRoot: {
    borderBottom: '0px',
  },
  tabsRoot: {
    borderBottom: '2px solid #EBEDF8',
  },
  tabsIndicator: {
    backgroundColor: '#1890ff',
  },
  tabRoot: {
    textTransform: 'initial',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    margin: 0,
    fontFamily: ['Roboto'].join(','),
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$tabSelected': {
      color: '#1890ff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  tabSelected: {},
  typography: {
    padding: theme.spacing.unit * 3,
  },
  tableRowRoot: {
    height: '35px',
    letterSpacing: '0.1px',
  },
  tableHeadRoot: {
    height: '40px',
    letterSpacing: '0.1px',
  },
  tableCellRoot: {
    padding: '4px 24px 4px 24px',
  },
  inputRoot: {
    width: 'calc(60%)',
    marginRight: '1em',
  },

  appointmentList: {
    paddingTop: '0em',
    marginTop: '0em',
    paddingLeft: '0.1em',
    height: '350px',
    overflowY: 'auto',
  },
  activeListItem: {
    color: '#1A173B',
    cursor: 'default',
    listStyle: 'none',
    marginTop: '1em',
  },
  listItem: {
    color: '#1A173B',
    cursor: 'pointer',
    listStyle: 'none',
    marginTop: '1em',
  },
  listItemSubject: {
    color: '#1A163C',
    width: '600px',
    fontSize: '15px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    marginBottom: '0.1em',
    fontWeight: '500',
  },
  listItemSubjectTwo: {
    width: '600px',
    fontSize: '15px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  activeListItemSubject: {
    fontSize: '15px',
  },
  listItemDate: {
    color: '#67727C',
    fontSize: '12px',
  },
  menuOptions: {
    color: '#1A173B',
    fontSize: '15px',
    lineHeight: '18px',
    fontFamily: 'Roboto',
  },
  columnHeaders: {
    height: '14px',
    color: '#1A173B',
    fontSize: '15px',
    lineHeight: '14px',
    fontFamily: 'Roboto',
    letterSpacing: '1.2px',
  },
  description: {
    height: '18px',
    color: '#67727C',
    fontFamily: 'Roboto',
    letterSpacing: '0.1px',
    lineHeight: '18px',
    fontSize: '15px',
  },
  bodyLoadingText: {
    marginTop: '15px',
    marginLeft: '10px',
  },
  noBottomBorder: {
    borderBottom: '0px',
  }
});

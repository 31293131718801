import RGL, { WidthProvider } from 'react-grid-layout';

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Loadable from 'react-loadable';
import Spinner from '#/Common/Spinner.jsx';
import DragHandle from '@material-ui/icons/DragHandle';
import { actions as widgetDashBoardActions } from '~/app/redux/widgets/duck';
import Widget from './Widget.jsx';
import { withStyles, Menu, MenuItem } from '@material-ui/core';
import { tabStyleConfig, removeStyle } from './DataModel/grid.js';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import WebHelpers from '~/app/webHelpers.js';
import { isTechOrAdminLogin } from '~/app/Utility/general.js';

const ReactGridLayout = WidthProvider(RGL);

const mapStateToProps = state => {
  return {
    widgetDataStore: state.widgetDashboard.widgetDataStore,
    drilldown: state.drilldown.drilldownHistory,
    loginID:
      state.drilldown?.drilldownHistory[state.drilldown.drilldownHistory.length - 1]?.loginID,
  };
};

const mapDispatchToProps = {
  ...widgetDashBoardActions,
};

const styles = theme => tabStyleConfig;

class WidgetGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hoveredKey: '',
      anchorEl: null,
      menuOpen: false,
      currentDraggedWidget: '',
      buttonMap: {},
    };
    this.payload = WebHelpers.getJWTPayload();
  }

  // The data being passed down should be an object in which any fields that need to be populated will be contained within that object.

  buildWidgetList = () => {
    const widgets = [];
    for (let i = 0; i < this.props.layout.length; i++) {
      if (this.props.widgetList[i]?.widgetFName === 'Widget_PolarChart') {
        continue;
      }
      const key = this.props.layout[i].i;
      const keyToPass = { keyToPass: key };
      const WidgetToLoad = Loadable({
        loader: () => import('./ActualWidgets/' + this.props.widgetList[i]?.widgetFName + '.jsx'),
        loading: () => <Spinner />,
      });
      widgets.push(
        <div
          className="data-grid widgetCard"
          key={key}
          onMouseEnter={() => {
            if (!isTechOrAdminLogin(this.payload)) {
              const widg = document.getElementById('widgetDrag' + key);
              widg.classList.add('widgetHover');
              widg.classList.remove('widgetNoHover');
            }
          }}
          onMouseLeave={() => {
            if (!isTechOrAdminLogin(this.payload)) {
              const widg = document.getElementById('widgetDrag' + key);
              widg.classList.add('widgetNoHover');
              widg.classList.remove('widgetHover');
            }
          }}
        >
          {!isTechOrAdminLogin(this.payload) && (
            <div id={'widgetDrag' + key} className="widgetNoHover widgetDragHandle">
              <DragHandle
                className="widgetDragButton"
                onMouseUpCapture={() => {
                  this.setState({
                    currentDraggedWidget: key,
                  });
                }}
              />
              <WidgetDeleteOption
                // locked={this.props.locked} // We should always allow the widget to be deleted in case it doesn't load correctly or gets stuck loading
                keyToPass={keyToPass}
                openWidgetMenu={e => this.openWidgetMenu(e, key)}
              />
            </div>
          )}
          <Widget
            WidgetToLoad={<WidgetToLoad />}
            keyPassed={key}
            role={this.props.accountType}
            widgetData={this.props.widgetDataStore[key]}
            setMenuButtons={this.setMenuButtons}
            loginID={this.props.loginID}
            closeParentMenu={this.closeWidgetMenu}
          />
        </div>,
      );
    }
    return widgets;
  };

  openWidgetMenu = (e, key) => {
    e.stopPropagation();
    this.setState({
      anchorEl: e.currentTarget,
      hoveredKey: key,
      menuOpen: true,
    });
  };

  setMenuButtons = (buttons, keyPassed) => {
    const newButtonMap = Object.assign({}, this.state.buttonMap);

    newButtonMap[keyPassed] = buttons;
    // Concat the props with this one
    this.setState({
      buttonMap: newButtonMap,
    });
  };

  closeWidgetMenu = () => {
    this.setState({
      anchorEl: null,
      menuOpen: false,
    });
  };

  /**
   * We want to call onLayoutChange only if it is the actual login user
   * and not the drilldown user.
   *
   * This is to prevent saving changes from higher level login to
   * lower level login or vice versa overriding the login's widget
   * config.
   *
   * @param {*} e layout from react grid layout component
   */
  saveOnLayoutChange = e => {
    if (
      !isTechOrAdminLogin(this.payload) &&
      !this.payload.is_super_user &&
      this.payload.login_id === this.props.loginID
    ) {
      this.props.onLayoutChange(e, this.props.widgetList);
    }
  };

  render() {
    const buttonMap =
      this.state.buttonMap[this.state.hoveredKey] === undefined
        ? []
        : this.state.buttonMap[this.state.hoveredKey];
    return (
      <Fragment>
        <div className="col-md-12">
          <ReactGridLayout
            className="layout"
            layout={this.props.layout}
            cols={30}
            rowHeight={30}
            onLayoutChange={e => this.saveOnLayoutChange(e)}
            draggableHandle=".widgetDragHandle"
            isDraggable={true}
            isResizable={false}
          >
            {this.buildWidgetList()}
          </ReactGridLayout>
        </div>
        <Menu
          anchorEl={this.state.anchorEl}
          open={this.state.menuOpen}
          onClose={this.closeWidgetMenu}
          key={'WidgetMenu'}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'center',
          }}
          getContentAnchorEl={null}
        >
          {buttonMap.map((item, index) => {
            return (
              <MenuItem
                onClick={() => {
                  item.func();
                  this.closeWidgetMenu();
                }}
                key={'widgetDropDownMenu' + index}
              >
                {item.name}
              </MenuItem>
            );
          })}
          <MenuItem
            key={'widgetDropDownMenuDeleteWidget'}
            onClick={() => {
              this.props.onRemoveWidget(this.state.hoveredKey);
              this.closeWidgetMenu();
            }}
          >
            Delete Widget
          </MenuItem>
        </Menu>
      </Fragment>
    );
  }
}

const WidgetDeleteOption = ({ keyToPass, openWidgetMenu }) => {
  return (
    <span
      className="widgetDropDownArrow"
      style={removeStyle}
      onClick={e => openWidgetMenu(e, keyToPass)}
    >
      <ExpandMoreIcon />
    </span>
  );
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(WidgetGrid));

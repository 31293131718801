// External imports
import React, { useEffect, useRef } from 'react';
import { AppBar, Button, Paper, Toolbar, Grid, withStyles } from '@material-ui/core';
import SignaturePad from 'react-signature-pad-wrapper';
// Internal imports
import {
  toBMPDataURL,
  sigTypes,
  scaleCanvasPixelRatio,
  canvasSize,
  canvasOptions,
  resizeCanvas,
  isCanvasScaled,
  cleanUpCanvas,
} from '~/app/bmpConversionHelpers.js';
import { getSigneeTitle } from '~/app/Pages/Returns/components/helpers/modalHelpers.js';
// Redux imports
import { useDispatch } from 'react-redux';
import { actions as appActions } from '~/app/redux/modules/app';
import { Typography } from '~/node_modules/@material-ui/core/index';
// Styling imports
import { styles } from '~/app/Pages/Returns/css/formViewerModals.js';

const SignatureCapture = props => {
  const { classes } = props;
  const dispatch = useDispatch();
  const signaturePad = useRef();
  useEffect(() => {
    if (signaturePad.current && signaturePad.current.canvas != null) {
      scaleCanvasPixelRatio(signaturePad.current.canvas);
    }

    return () => {
      if (isCanvasScaled) {
        cleanUpCanvas();
      }
    };
  }, []);

  const handleCancel = e => {
    e.preventDefault();
    // Close and clear the modal
    signaturePad.current.clear();
    props.handleClose();
  };

  const clearSignature = e => {
    e.preventDefault();
    signaturePad.current.clear();
  };

  const acceptSignature = e => {
    e.preventDefault();
    const haveSignature = signaturePad.current.isEmpty();
    const onScreen = 1;

    if (!haveSignature) {
      if (props.convertString === false) {
        const signatureDataOne = isCanvasScaled
          ? resizeCanvas(signaturePad.current.canvas).toDataURL()
          : signaturePad.current.toDataURL(); // Getting signature data (base 64)
        // Close and clear the modal
        signaturePad.current.clear();
        props.handleSignatureCapture(sigTypes[props.currentSig], signatureDataOne, onScreen);
      } else {
        const signaturePadCanvas = isCanvasScaled
          ? resizeCanvas(signaturePad.current.canvas)
          : signaturePad.current.canvas;
        const padType = 'screen';
        let sigString = toBMPDataURL(signaturePadCanvas, padType);
        sigString = sigString.replace('data:image/bmp;base64,', ''); // strip url src info
        // Close and clear the modal
        signaturePad.current.clear();
        props.handleSignatureCapture(sigTypes[props.currentSig], sigString, onScreen);
      }
    } else {
      dispatch(
        appActions.showSnackbarMessage(
          "No signature captured. Please sign and then click on the 'Accept' button",
          'error',
          3500,
          {
            vertical: 'top',
            horizontal: 'center',
          },
        ),
      );
    }
  };

  const toolbarTitle = getSigneeTitle(props.currentSig);

  return (
    <Paper className={classes.signatureCaptureContainer}>
      <AppBar position="static">
        <Toolbar className={classes.toolBar}>
          <Typography className={classes.toolBarTitle}>{toolbarTitle}</Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.signatureCaptureForm}>
        <Grid container spacing={16} justify="flex-start">
          <Grid item xs={12}>
            <Typography variant="body1">Please sign below</Typography>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.signatureCapturePad}>
              <SignaturePad
                width={canvasSize.width}
                height={canvasSize.height}
                ref={ref => (signaturePad.current = ref)}
                options={{ ...canvasOptions }}
              />
            </div>
          </Grid>
          <Grid item xs={12} className={classes.signatureCaptureFooter}>
            <Button
              className={classes.marginRight}
              variant="outlined"
              id="btnCancelSignDoc"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              className={classes.marginRight}
              variant="outlined"
              id="btnClearSignDoc"
              onClick={clearSignature}
            >
              Clear
            </Button>
            <Button
              variant="contained"
              color="primary"
              id="btnAcceptSignDoc"
              onClick={acceptSignature}
            >
              Accept
            </Button>
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
};

export default withStyles(styles)(SignatureCapture);

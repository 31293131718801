// External imports
import React, { useEffect } from 'react';
import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  Modal,
  Paper,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
// Internal imports
import { useSetState } from '~/app/Utility/customHooks';
import ErrorHelpers from '~/app/errorHelpers.js';
import XlinkAPI from '~/app/api/xlinkAPI.js';
import { statusOK } from '~/app/webHelpers.js';
import Spinner from '#/Common/Spinner.jsx';
// Redux imports
import { useDispatch } from 'react-redux';
import { actions as appActions } from '~/app/redux/modules/app';
// Styling imports
import { styles } from '~/app/Components/Settings/css/deleteLoginModal.js';
import '~/app/Components/Settings/css/deleteLoginModal.css';

const initialState = {
  selectedPreparerID: 0,
  confirmedName: '',
  preparers: [],
  offices: {},
  currentAssociatedPrep: {},
  currentAssociatedLogin: {},
  hasMultipleLogins: false,
  hasNoPreparers: false,
  isNotLinkedToPrep: false,
  isLoading: false,
};

/**
 * This component is used on both the Logins and Preparers page for deleting a login and their linked preparer and
 * vice versa depending on the page
 *
 * There are two edgecasesthat should prevent deleting sense this modal is used on the logins and preparers apge
 * 1. If a preparer is linked to multiple logins
 * 2. If we are deleting a preparer, there needs to be another active prep to transfer returns to
 *
 * @param {Boolean} isDeleteLoginModalOpen // determines if the modal is open or not
 * @param {Function} toggleModal // toggles the modal
 * @param {Boolean} isOnLoginsPage // determines if we are on the login page or preparers page
 * @param {String} name // either the login name or preparer name based on which page we are one
 * @param {Number} preparerID // the preparer's ID that we are deleting
 * @param {Number} loginID // the login's ID that we are deleting
 * @param {Function} refetchList // refetches prep/login page after submittion
 * @component DeleteLoginModal
 * @subcategory Logins/Preparers page
 * @category Settings
 */
const DeleteLoginModal = props => {
  const { classes } = props;
  const dispatch = useDispatch();
  const [state, setState] = useSetState({
    selectedPreparerID: 0,
    confirmedName: '',
    preparers: [],
    offices: {}, // preps can be associated to other EFINs, use the officeID as the key to the logins
    currentAssociatedPrep: {},
    currentAssociatedLogin: {}, // used only on preparers page
    hasMultipleLogins: false,
    hasNoPreparers: false,
    isNotLinkedToPrep: false, // Logins are required to be linked to a prep on creation. If they are not already linked to one, allow them to delete login only
    isLoading: false,
  });

  // We cannot allow to delete the Office Owner Login from the preparers page, just like the logins page
  const isDeletingOfficeOwnerLogin =
    state.currentAssociatedLogin?.loginID === state.currentAssociatedLogin?.officeOwnerLoginID &&
    state.currentAssociatedLogin?.loginID !== undefined &&
    !props.isOnLoginsPage;

  const canConfirmName =
    !(state.hasMultipleLogins || state.hasNoPreparers || isDeletingOfficeOwnerLogin) ||
    state.isNotLinkedToPrep;

  // handles login and preps page submittion validation
  const canSubmit = props.isOnLoginsPage
    ? props.name === state.confirmedName && (state.selectedPreparerID || state.isNotLinkedToPrep)
    : props.name === state.confirmedName &&
      state.selectedPreparerID &&
      !(state.hasMultipleLogins || state.hasNoPreparers) &&
      !isDeletingOfficeOwnerLogin;

  const canTransferPrepReturns =
    !state.hasMultipleLogins &&
    !state.hasNoPreparers &&
    !state.isNotLinkedToPrep &&
    !isDeletingOfficeOwnerLogin;

  const type = props.isOnLoginsPage ? 'Login' : 'Preparer';
  const title = `Deleting ${type} - ${props.name}`;
  const currentAssociatedName = props.isOnLoginsPage
    ? state.currentAssociatedPrep?.preparerName
    : state.currentAssociatedLogin?.loginName;

  useEffect(() => {
    // If there is no preparer id linked to the login, allow them to delete the login, otherwise, fetch all office preps
    if (props.preparerID > 0) {
      // fetch preparers list
      fetchOfficePreparers(props.preparerID);

      // fetch logins associated to preparer
      fetchLoginsAssociatedToPreparer(props.preparerID);
    } else {
      setState({
        isNotLinkedToPrep: true,
      });
    }

    return () => {
      setState(initialState);
    };
  }, []);

  const fetchOfficePreparers = async preparerID => {
    setState({ isLoading: true });

    try {
      const res = await XlinkAPI.fetchOfficePreparers();
      if (statusOK(res)) {
        let currentAssociatedPrep = {};
        const filteredPreps = [];

        // Filter out the preparer that is already associated to the login
        res.data?.forEach(prep => {
          if (prep.preparerID !== preparerID) {
            filteredPreps.push(prep);
          } else {
            currentAssociatedPrep = prep;
          }
        });

        setState({
          preparers: filteredPreps,
          currentAssociatedPrep,
          hasNoPreparers: filteredPreps.length < 1,
        });
      }
    } catch (error) {
      ErrorHelpers.handleError('Error fetching preparers', error);
    }

    setState({ isLoading: false });
  };

  const fetchLoginsAssociatedToPreparer = async preparerID => {
    setState({ isLoading: true });

    try {
      const res = await XlinkAPI.fetchLoginsAssociatedToPreparer(preparerID);
      if (statusOK(res)) {
        // preparers can be associated to logins from various offices. We need to indicate which office the logins come from.
        const offices = {};

        // use the office ID as a key, and sort each login to their designated office
        res.data?.forEach(login => {
          if (offices[login.officeID]) {
            offices[login.officeID]?.logins?.push(login);
          } else {
            offices[login.officeID] = {
              firmName: login.firmName,
              logins: [login],
            };
          }
        });

        setState({
          offices,
          hasMultipleLogins: res.data?.length > 1,
        });

        // Only for the Preparers page, if there is only one login associated to the Preparer, then delete the login as well
        if (res.data?.length === 1 && !props.isOnLoginsPage) {
          setState({
            currentAssociatedLogin: res.data[0],
          });
        }
      }
    } catch (error) {
      ErrorHelpers.handleError('Error fetching logins associated to the preparer', error);
    }

    setState({ isLoading: false });
  };

  const handleSubmit = async () => {
    setState({ isLoading: true });

    try {
      const loginID = props.isOnLoginsPage ? props.loginID : state.currentAssociatedLogin?.loginID;

      const res = await XlinkAPI.deleteOfficeLogin(
        props.preparerID, // this can be zero if deleting a login not linked to a preparer
        state.selectedPreparerID, // this can be zero if deleting a login that is not linked to a preparer
        loginID, // this can be zero if we are deleting a prep that is not associated to a login
      );

      if (statusOK(res, false)) {
        dispatch(
          appActions.showSnackbarMessage(`Successfully deleted ${type}`, 'success', 3500, {
            vertical: 'top',
            horizontal: 'center',
          }),
        );
      }
    } catch (error) {
      const msg = `Failed to delete ${type}`;
      ErrorHelpers.handleError(msg, error);
      dispatch(
        appActions.showSnackbarMessage(msg, 'error', 3500, {
          vertical: 'top',
          horizontal: 'center',
        }),
      );
    }

    // Regardless of whether the delete was successful or failed, we should refetch the prep/login list and have them start over.
    props.toggleModal();
    props.refetchList();
  };

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;

    setState({
      [name]: value,
    });
  };

  return (
    <Modal
      id="DeleteLoginModalID"
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={props.isDeleteLoginModalOpen}
      disableBackdropClick={true}
    >
      <Paper elevation={5} className={classes.deleteLoginModal}>
        <Typography
          variant="body2"
          id="simple-modal-title"
          className={classes.deleteLoginModalAppBar}
        >
          {title}
        </Typography>
        <Grid container spacing={32} className={classes.deleteLoginModalBody}>
          {/* if either do not exist, display nothing */}
          {currentAssociatedName ? (
            <Grid item xs={12}>
              <Typography variant="body1">
                {`Deleting this ${type} will also delete the linked ${
                  props.isOnLoginsPage ? 'Preparer' : 'Login' // opposite of type
                }:`}
                <br />
                <b>{currentAssociatedName}</b>
              </Typography>
            </Grid>
          ) : null}

          {isDeletingOfficeOwnerLogin && (
            <Grid item xs={12}>
              <Typography variant="body1">
                <b>TODO: </b>
                The login <b>{state.currentAssociatedLogin?.loginName}</b> associated with this
                preparer that we are trying to delete is an Office Owner Login, which cannot be
                deleted. Please update the Office Owner Login to be associated with a different
                preparer before we can delete this Preparer.
              </Typography>
            </Grid>
          )}

          {/* Display a list of logins that are associated to a preparer */}
          {state.hasMultipleLogins && (
            <Grid item xs={12}>
              <Typography variant="body1">
                <b>TODO: </b>
                {props.isOnLoginsPage
                  ? 'More than one login is associated with the preparer linked to this login. To delete the preparer along with the selected login, you must manually update the other logins to be associated with a different preparer.'
                  : 'This preparer is associated with multiple logins. To delete this preparer, you must manually update the following logins to be associated with a different preparer.'}
              </Typography>
              <br />
              <Typography variant="body1">
                <b>
                  Logins associated with the preparer{' '}
                  {`'${state.currentAssociatedPrep?.preparerName}'`}:
                </b>
              </Typography>
              {Object.entries(state.offices)?.map((office, i) => (
                <div key={i} className="spaceLeft">
                  <b>{office[1].firmName}:</b>

                  {office[1]?.logins?.map((login, i) => {
                    return (
                      <div key={i} className="spaceLeft">
                        {login.loginName}
                      </div>
                    );
                  })}
                </div>
              ))}
            </Grid>
          )}

          {/* If there are no preparers to transfer the returns to, they need to create a new one */}
          {state.hasNoPreparers && !state.isNotLinkedToPrep ? (
            <Grid item xs={12}>
              <Typography variant="body1">
                <b>TODO: </b>
                To delete this login and the associated preparer, you must have another preparer to
                reassign the returns. Please create a new preparer.
              </Typography>
            </Grid>
          ) : null}

          {/* List of preparers to transfer returns to */}
          {canTransferPrepReturns ? (
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  select
                  id="selectedPreparerIDFieldID"
                  name="selectedPreparerID"
                  value={state.selectedPreparerID}
                  onChange={e => handleChange(e)}
                  disabled={state.isLoading}
                  label="Select a Preparer to Transfer returns to"
                  variant="outlined"
                  required
                >
                  {state.preparers?.map((item, i) => {
                    return (
                      <MenuItem key={i} id={`option-${i}`} value={item.preparerID}>
                        {item.preparerName}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </FormControl>
            </Grid>
          ) : null}

          {canConfirmName ? (
            <>
              {/* Confirm the login name to be able to delete it */}
              <Grid item xs={12}>
                <Typography variant="body1">
                  <b>{`Warning! You are attempting to delete a ${type}.`}</b>
                  <br />
                  Once deleted, this {`${type}`} cannot be recovered.
                  <br />
                  Please type
                  <b>{` ${props.name} `}</b>
                  to delete this {`${type}`}.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    id="confirmedNameFieldID"
                    name="confirmedName"
                    value={state.confirmedName}
                    onChange={e => handleChange(e)}
                    disabled={state.isLoading}
                    label={`Confirm ${type} Name`}
                    variant="outlined"
                    required
                    error={props.name !== state.confirmedName && canSubmit}
                  />
                </FormControl>
              </Grid>
            </>
          ) : null}

          <Grid container item xs={12} justify="flex-end">
            <Button
              id="btnAddAnotherDoc"
              onClick={props.toggleModal}
              className={classes.styleButtonMarginRight}
              color="default"
              size="small"
            >
              Cancel
            </Button>

            <Button
              id="btnAddAnotherDoc"
              size="small"
              onClick={handleSubmit}
              disabled={!canSubmit || state.isLoading}
              variant="contained"
              color="primary"
            >
              Confirm
            </Button>
          </Grid>
        </Grid>

        {state.isLoading ? (
          <Spinner
            size={100}
            color="blue"
            loadingText="Loading..."
            textColor="white"
            bgColor="grey"
            lockActions={true}
          />
        ) : null}
      </Paper>
    </Modal>
  );
};

export default withStyles(styles)(DeleteLoginModal);

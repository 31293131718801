import React from 'react';
// internal imports
import { STATE_LIST } from '~/app/constants.js';
// external imports
import {
  AppBar,
  Toolbar,
  FormControl,
  InputLabel,
  Input,
  Divider,
  Button,
  NativeSelect,
} from '@material-ui/core';
// redux imports
import { useSelector, useDispatch } from 'react-redux';
import { actions as setupPageActions } from '~/app/redux/setupPages/duck';

const UpsertDonee = props => {
  const dispatch = useDispatch();

  const { isEditingRecord, currentEditRecord } = useSelector(state => ({
    isEditingRecord: state.setupPageState.isEditingRecord,
    currentEditRecord: state.setupPageState.currentEditRecord,
  }));

  const handleInputChange = e => {
    e.persist();
    dispatch(setupPageActions.updateCurrentEditRecord(e));
  };

  const handleUpsert = () => {
    props.handleUserDBUpsert(currentEditRecord);
  };

  const validateFields = () => {
    if (
      currentEditRecord.address1 == null ||
      currentEditRecord.address1 === '' ||
      currentEditRecord.postal_code == null ||
      currentEditRecord.postal_code === '' ||
      currentEditRecord.donee_name == null ||
      currentEditRecord.donee_name === '' ||
      currentEditRecord.city == null ||
      currentEditRecord.city === '' ||
      currentEditRecord.state_code == null ||
      currentEditRecord.state_code === ''
    ) {
      return false;
    }
    return true;
  };

  const handleStateValueChange = stateID => {
    dispatch(setupPageActions.handleUpdateSelectedState(stateID));
  };

  const stateList = [''].concat(STATE_LIST).map(state => {
    return (
      <option id={'UpsertDoneeoptSelect' + state} key={state} value={state}>
        {state}
      </option>
    );
  });

  return (
    <div>
      <AppBar position="static">
        <Toolbar style={styles.toolbar}>
          {isEditingRecord ? 'Edit ' : 'Add New '}
          Donee
        </Toolbar>
      </AppBar>
      <form styles={styles.form}>
        <div style={{ margin: '2em', textAlign: 'left' }}>
          <div style={styles.sectionHeading}>Donee Information</div>
          <div style={{ float: 'left', marginRight: '1em' }}>
            <FormControl>
              <InputLabel style={styles.labelStyles} shrink>
                Donee EIN
              </InputLabel>
              <Input
                id="txtEINIDUpsertDoneeEIN"
                name="ein"
                value={currentEditRecord.ein}
                onChange={handleInputChange}
                style={styles.inputStyle}
                inputProps={{
                  maxLength: '9',
                }}
                disableUnderline
              />
            </FormControl>
          </div>
          <div style={{ float: 'none', marginRight: '1em' }}>
            <FormControl>
              <InputLabel style={styles.labelStyles} required={false} shrink>
                Donee name
              </InputLabel>
              <Input
                id="txtNameControlUpsertDoneeName"
                name="donee_name"
                value={currentEditRecord.donee_name}
                onChange={handleInputChange}
                style={styles.inputStyle}
                error={!currentEditRecord.donee_name || currentEditRecord.donee_name === ''}
                inputProps={{
                  maxLength: '75',
                }}
                disableUnderline
              />
            </FormControl>
          </div>
        </div>

        <Divider style={{ marginTop: '1em' }} />

        <div style={{ margin: '2em', textAlign: 'left' }}>
          <div style={styles.sectionHeading}>Address</div>
          <div style={{ float: 'left', marginRight: '1em' }}>
            <FormControl>
              <InputLabel style={styles.labelStyles} required={false} shrink>
                Address
              </InputLabel>
              <Input
                id="txtEINIDUpsertDoneeAddress"
                name="address1"
                value={currentEditRecord.address1}
                onChange={handleInputChange}
                style={styles.inputStyle}
                error={!currentEditRecord.address1 || currentEditRecord.address1 === ''}
                inputProps={{
                  maxLength: '75',
                }}
                disableUnderline
              />
            </FormControl>
          </div>
          <div style={{ float: 'none', marginRight: '1em' }}>
            <FormControl>
              <InputLabel style={styles.labelStyles} required={false} shrink>
                City
              </InputLabel>
              <Input
                id="txtNameControlUpsertDoneeCity"
                name="city"
                value={currentEditRecord.city}
                onChange={handleInputChange}
                style={styles.inputStyle}
                error={!currentEditRecord.city || currentEditRecord.city === ''}
                inputProps={{
                  maxLength: '75',
                }}
                disableUnderline
              />
            </FormControl>
          </div>
          <div style={{ float: 'left', marginRight: '1em' }}>
            <FormControl>
              <InputLabel style={styles.labelStyles} shrink>
                State
              </InputLabel>
              <NativeSelect
                id="selectDropDownUpsertDoneeState"
                value={currentEditRecord.state_code || ''}
                onChange={e => handleStateValueChange(e.target.value)}
                style={styles.inputStyle}
                error={!currentEditRecord.state_code || currentEditRecord.state_code === ''}
                disableUnderline
              >
                {stateList}
              </NativeSelect>
            </FormControl>
          </div>
          <div style={{ float: 'none', marginRight: '1em' }}>
            <FormControl>
              <InputLabel style={styles.labelStyles} required={false} shrink>
                Zip Code
              </InputLabel>
              <Input
                id="txtNameControlUpsertDoneeZipCode"
                name="postal_code"
                value={currentEditRecord.postal_code}
                onChange={handleInputChange}
                style={styles.inputStyle}
                error={!currentEditRecord.postal_code || currentEditRecord.postal_code === ''}
                inputProps={{
                  maxLength: '10',
                }}
                disableUnderline
              />
            </FormControl>
          </div>
        </div>
        <div
          style={{
            textAlign: 'right',
            padding: '0',
            marginRight: '2em',
            marginBottom: '2em',
            marginTop: '2em',
          }}
        >
          <Button
            id="btnCancelAddUUpsertDonee"
            color="primary"
            onClick={() => props.handleCloseUpsertModal()}
            style={styles.button}
          >
            Cancel
          </Button>
          <Button
            id="btnUpsertDonee"
            color="primary"
            style={styles.button}
            onClick={handleUpsert}
            disabled={!validateFields()}
          >
            {isEditingRecord ? 'Save' : 'Add'}
          </Button>
        </div>
      </form>
    </div>
  );
};

const styles = {
  toolbar: {
    backgroundColor: '#444A59',
  },
  sectionHeading: {
    color: '#1A173B',
    fontFamily: 'Roboto',
    fontSize: '21px',
    marginBottom: '1em',
  },
  buttonGroup: {
    textAlign: 'right',
    marginTop: '2em',
  },
  form: {
    margin: '2em',
  },
  groupDropdown: {
    width: '7em',
    marginRight: '2em',
  },
  dropdown: {
    width: '15em',
    display: 'inlineBlock',
  },
  dropdownText: {
    paddingTop: '0.5em',
    paddingBottom: '0.5em',
    color: '#0077FF',
  },
  subject: {
    width: '24em',
  },
  button: {
    width: '9em',
    fontSize: '14px',
    borderRadius: '4px',
    marginLeft: '2em',
  },
  labelStyles: {
    color: '#354052',
    textAlign: 'left',
    fontFamily: 'Roboto',
  },
  inputStyle: {
    width: '20vw',
    height: '3.5vh',
  },
};

export default UpsertDonee;

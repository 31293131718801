// External imports
import React, { useEffect } from 'react';
import {
  withStyles,
  Modal,
  Paper,
  Grid,
  Typography,
  FormControl,
  TextField,
  Button,
} from '@material-ui/core';
// Internal imports
import { getDate } from '~/app/webHelpers.js';
import { useSetState } from '~/app/Utility/customHooks';
// Styling imports
import { styles } from '~/app/Pages/Returns/css/bookmarkStyles.js';

// Bookmarks and BookmarkField + cs transaction return_bookmark_query needs refactored
const BookmarkField = props => {
  const { classes } = props;
  const [state, setState] = useSetState({
    desc: props.desc,
    bookmarkField: props.fieldID,
    timestamp: props.timestamp,
  });

  useEffect(() => {
    setState({
      desc: props.desc,
      bookmarkField: props.fieldID,
      timestamp: props.timestamp,
    });
  }, [props.desc, props.fieldID, props.timestamp]);
  const setBookmarkOnField = (e, deleteBookmark) => {
    const withTimestring = true;

    // replace newlines with a space - this breaks the timestamp column and doesn't save correctly
    const updatedDesc = state.desc.replace(/(\r\n|\n|\r)/gm, ' ');
    // Insert/Update/Delete if only there is a change
    if (props.desc !== state.desc || deleteBookmark) {
      props.setBookmark(updatedDesc, getDate(withTimestring), deleteBookmark);
      props.snackbarToggle(deleteBookmark ? 'Bookmark Deleted' : 'Bookmark Saved');
      return;
    }
    props.setBookmarkFldEditor(false);
  };

  const isNewEntry = state.timestamp === '';

  return (
    <Modal
      open={props.bookmarks.bookmarkFldEditor}
      onClose={() => props.setBookmarkFldEditor(false)}
    >
      <Paper classes={{ root: classes.bookmarkModal }}>
        <Typography variant="title" id="simple-modal-title" style={{ paddingLeft: '1em' }}>
          Bookmark Field
        </Typography>
        <Paper classes={{ root: classes.gridContainer }}>
          <Grid
            container
            spacing={8}
            alignContent="flex-end"
            justify="flex-end"
            alignItems="baseline"
          >
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  id="txtBookmarkDescription"
                  name="desc"
                  value={state.desc}
                  label="Description"
                  placeholder="This bookmark is for...."
                  onKeyPress={e => {
                    if (e.key === 'Enter') e.preventDefault();
                  }}
                  onChange={e =>
                    setState({
                      desc: e.target.value,
                    })
                  }
                  inputProps={{ maxLength: 160 }}
                  multiline
                  rows={5}
                  variant="outlined"
                  autoComplete="off"
                  disabled={!props.isEditable}
                />
              </FormControl>
            </Grid>
            {!isNewEntry && (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    id="txtBookmarkTimestamp"
                    label="Timestamp"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled
                    value={state.timestamp}
                  />
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12} style={{ textAlign: 'right' }}>
              <Button
                classes={{ root: classes.button }}
                onClick={() => props.setBookmarkFldEditor(false)}
              >
                Cancel
              </Button>
              {props.isEditable && (
                <Button
                  classes={{ root: classes.button }}
                  onClick={e => setBookmarkOnField(e, false)}
                >
                  Save
                </Button>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Paper>
    </Modal>
  );
};

export default withStyles(styles)(BookmarkField);

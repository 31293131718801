export const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
    border: '0',
  },
  input: {
    display: 'none',
  },
  buttonGroup: {
    marginLeft: '11em',
    marginTop: '1em',
  },
  fullButtonGroup: {
    marginLeft: '2em',
    marginTop: '1em',
  },
  avatarPreview: {
    width: '65px',
    height: '65px',
    borderWidth: '1px',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: '50%',
  },
  imageCaption: {
    marginTop: '1rem',
    marginLeft: '2vw',
    fontFamily: 'Roboto',
    fontWeight: '300',
    fontSize: '14px',
    color: '#354052',
    display: 'grid',
    placeItems: 'center',
  },
  fileSelect: {
    border: 'none',
    marginLeft: '2vw',
  },
});

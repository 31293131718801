import { actions, DRILLDOWN, ADD_MULTIPLE_ENTITIES, LOAD_PREV_OVERVIEW } from './duck';

import { actions as cobrandActions } from '../cobrand/duck';
import { actions as loginSetupActions } from '~/app/redux/loginSetup/duck.js';

import { takeLatest, call, put } from 'redux-saga/effects';

import XlinkAPI from '~/app/api/xlinkAPI';
import ErrorHelpers from '~/app/errorHelpers.js';
import { HIERARCHY_TYPE, DD_TOKEN } from '../../constants';

export function* watchDrilldown() {
  yield takeLatest(DRILLDOWN, doDrilldown);
  yield takeLatest(ADD_MULTIPLE_ENTITIES, doMultiDrilldown);
  yield takeLatest(LOAD_PREV_OVERVIEW, doPrevDrilldown);
}

export function* doPrevDrilldown(payload) {
  try {
    // Navigate to dashboard page on a prev drill up.
    yield put(loginSetupActions.setFinalizeLogin(true));
  } catch (error) {
    // drilldown previous denied
    ErrorHelpers.handleError('Prev Drilldown not possible', error);
  }
}

export function* doDrilldown(payload) {
  try {
    if (payload.endpoint === undefined) {
      payload.endpoint = `${BASE_URL}/authz/drilldown`;
    }

    const res = yield call(XlinkAPI.post, payload.endpoint, {
      login_id: payload.loginID,
      preparer_id: payload.preparerID,
    });

    yield put(
      actions.setCurrentView({
        loginID: payload.loginID,
        preparerID: res.data?.prep_ID ? res.data.prep_ID : payload.preparerID,
        role: payload.view, // TODO: naming consistency view vs. role
        name: payload.name,
        isOfficeSetupComplete: payload.setupComplete,
        dd_token: res.data.dd_token,
        setupProgress: payload.setupProgress,
        textlinkActivated: payload.textlinkActivated,
      }),
    );

    if (payload.view !== HIERARCHY_TYPE.PREPARER) {
      yield put(cobrandActions.fetchLogo());
    }
  } catch (error) {
    // drilldown denied
    ErrorHelpers.handleError('Drilldown not possible', error);
    yield put(actions.onDrilldownError());
  }
}

export function* doMultiDrilldown(payload) {
  try {
    if (payload.endpoint === undefined) {
      payload.endpoint = `${BASE_URL}/authz/drilldown`;
    }
    for (let i = 0; i < payload.entityList.length; i++) {
      const entityStep = payload.entityList[i];

      const res = yield call(XlinkAPI.post, payload.endpoint, {
        login_id: entityStep.loginID,
        preparer_id: entityStep.preparerID,
      });
      let setupRes;
      if (entityStep.role === HIERARCHY_TYPE.EFIN) {
        const setupEndpoint = `${XLINK_API_URL}/account/office/setup/progress`;
        sessionStorage[DD_TOKEN] = res.data.dd_token; // must store ddtoken for this intermediate call
        setupRes = yield call(XlinkAPI.simpleGetRequest, setupEndpoint);
      }
      const isOfficeSetupComplete = () => {
        if (setupRes) {
          const data = Object.values(setupRes.data);
          return data.length > 1 && data.every(v => v === true);
        }
        return undefined;
      };
      const getSetupProgress = () => {
        if (setupRes) {
          const data = Object.values(setupRes.data);
          const numTrue = data.reduce((n, setupStep) => n + (setupStep === true), 0);
          return (numTrue / data.length) * 100;
        }
        return undefined;
      };

      yield put(
        actions.setCurrentView({
          loginID: entityStep.loginID,
          preparerID: res.data?.prep_ID ? res.data.prep_ID : entityStep.preparerID,
          role: entityStep.role,
          name: entityStep.name,
          isOfficeSetupComplete: isOfficeSetupComplete(),
          dd_token: res.data.dd_token,
          setupProgress: getSetupProgress(),
          textlinkActivated: entityStep.textlinkActivated,
        }),
      );
    }

    if (payload.view !== HIERARCHY_TYPE.PREPARER) {
      yield put(cobrandActions.fetchLogo());
    }
  } catch (error) {
    // drilldown denied
    ErrorHelpers.handleError('Drilldown not possible', error);
    yield put(actions.onDrilldownError());
  }
}

import React from 'react';
// External imports
import { withStyles, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
// Styling imports
import { styles } from '~/app/Pages/Returns/css/formActivityListStyles.js';
import { Typography } from '~/node_modules/@material-ui/core/index';

/**
 * Handles displaying the table of forms for the AddNewFormModal
 *
 * @component FormActivityList
 * @param {Object} formAndActivityList forms currently being displayed in the table based on the filters
 * @param {Object} activityList used to display the Assets to add
 * @param {Number} selectedTab the currented tab the user is on
 * @param {Object} setSelectedForm the form selected to be added
 * @param {Boolean} haveFilterOn used to determine if a filter is being used
 * @param {Object} filteredFormList the filtered forms as a result of the filter
 * @param {Object} currentStatePackage the current state package selected from the dropdown
 * @param {Function} handleChangeAddFormValue used to add the selected form in to state
 * @param {Function} handleChangeAddFormValueDoubleClick used to add the form if double clicked
 * @param {Object} classes sense the props were being deconstructed, we need to deconstruct classes here
 * @category FormViewer
 */
const FormActivityList = ({
  formAndActivityList,
  activityList,
  selectedTab,
  setSelectedForm,
  haveFilterOn,
  filteredFormList,
  currentStatePackage,
  handleChangeAddFormValue,
  handleChangeAddFormValueDoubleClick,
  classes, // I need to deconstruct the classes props to get access to it this way
}) => {
  /**
   * Handles building the table with the passed in props and generating the jsx
   *
   * @returns {jsx} used to display the table
   */
  const getFormActivityTable = () => {
    return (
      <div>
        {(selectedTab === 0 ||
          selectedTab === 1 ||
          selectedTab === 2 ||
          (selectedTab === 3 && currentStatePackage !== '')) && (
          <Table>
            <TableHead>
              <TableRow className={classes.tableHeadRoot}>
                <TableCell className={classes.noBottomBorder} />
                <TableCell className={classes.columnHeaders}>NAME</TableCell>
                <TableCell className={classes.columnHeaders}>DESCRIPTION</TableCell>
              </TableRow>
            </TableHead>
            {haveFilterOn === false && (
              <TableBody>
                {formAndActivityList.map((forms, index) => {
                  return (
                    <TableRow
                      key={`${forms.formId}-${index}`}
                      id={forms.formId}
                      hover={true}
                      onClick={() => handleChangeAddFormValue(forms)}
                      onDoubleClick={() => handleChangeAddFormValueDoubleClick(forms)}
                      selected={forms === setSelectedForm}
                      className={classes.tableHeadRoot}
                    >
                      <TableCell className={`noselect ${classes.noBottomBorder}`}>
                        {forms.cntAttached}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={`noselect ${classes.menuOptions}`}
                      >
                        {forms.irsName}
                      </TableCell>
                      <TableCell className={`noselect ${classes.description}`}>
                        {forms.desc}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
            {haveFilterOn === true &&
              filteredFormList !== undefined &&
              filteredFormList.length > 0 && (
                <TableBody>
                  {filteredFormList.map((forms, index) => {
                    return (
                      <TableRow
                        key={`${forms.formId}-${index}`}
                        id={forms.formId}
                        hover={true}
                        onClick={() => handleChangeAddFormValue(forms)}
                        onDoubleClick={() => handleChangeAddFormValueDoubleClick(forms)}
                        selected={
                          setSelectedForm === undefined && index === 0
                            ? true
                            : forms === setSelectedForm
                        }
                        className={classes.tableHeadRoot}
                      >
                        <TableCell className={`noselect ${classes.noBottomBorder}`}>
                          {forms.cntAttached}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          className={`noselect ${classes.menuOptions}`}
                        >
                          {forms.irsName}
                        </TableCell>
                        <TableCell className={`noselect ${classes.description}`}>
                          {forms.desc}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}
          </Table>
        )}
        {selectedTab === 4 && (
          <Table>
            <TableHead>
              <TableRow className={classes.tableHeadRoot}>
                <TableCell className={classes.columnHeaders}>NAME</TableCell>
                <TableCell className={classes.columnHeaders}>DESCRIPTION</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {activityList.map((actv, index) => {
                return (
                  <TableRow
                    key={`${actv.name}-${index}`}
                    hover={true}
                    onClick={() => handleChangeAddFormValue(actv)}
                    onDoubleClick={() => handleChangeAddFormValueDoubleClick(actv)}
                    selected={
                      setSelectedForm === undefined && index === 0 ? true : actv === setSelectedForm
                    }
                    className={classes.tableHeadRoot}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className={`noselect ${classes.menuOptions}`}
                    >
                      {actv.name}
                    </TableCell>
                    <TableCell className={`noselect ${classes.description}`}>{actv.desc}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
        {selectedTab === 3 && currentStatePackage === '' && (
          <Typography className={classes.bodyLoadingText}>
            {`Please select a state from the "Package" dropdown above.`}
          </Typography>
        )}
      </div>
    );
  };

  return (
    <div>
      <ul className={classes.appointmentList}>
        {!(formAndActivityList === null) || !(formAndActivityList === undefined) ? (
          getFormActivityTable()
        ) : (
          <Typography className={classes.bodyLoadingText}>No Forms Found.</Typography>
        )}
      </ul>
    </div>
  );
};

export default withStyles(styles)(FormActivityList);

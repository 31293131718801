const PAGE_CHANGE = 'xlinkonline/searchResults/PAGECHANGE';

export const FETCH_BUILD_BREADCRUMBS = 'xlinkonline/returnProfile/FETCHBUILDBREADCRUMBS';
export const BUILD_BREADCRUMB_SUCCESS = 'xlinkonline/returnProfile/BUILDBREADCRUMBSUCCESS';
export const BUILD_BREADCRUMB_FAILURE = 'xlinkonline/returnProfile/BUILDBREADCRUMBSFAILURE';

export const initialState = {
  currentPage: 1,
};

export const actions = {
  onPageChange: page => ({
    type: PAGE_CHANGE,
    page,
  }),
  fetchBreadcrumbs: (parentID, preparerID, leafID, leafType, leafName, efinID) => ({
    type: FETCH_BUILD_BREADCRUMBS,
    parentID,
    preparerID,
    leafID,
    leafType,
    leafName,
    efinID,
  }),
  buildBreadcrumbSuccess: () => ({
    type: BUILD_BREADCRUMB_SUCCESS,
  }),
  buildBreadcrumbFailure: () => ({
    type: BUILD_BREADCRUMB_FAILURE,
  }),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case PAGE_CHANGE:
      return {
        ...state,
        currentPage: action.page,
      };
    case BUILD_BREADCRUMB_SUCCESS:
      return {
        ...state,
      };
    case BUILD_BREADCRUMB_FAILURE:
      return {
        ...state,
      };
  }
  return state;
}

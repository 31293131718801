// Internal imports
import XlinkAPI from '~/app/api/xlinkAPI';
import ErrorHelpers from '~/app/errorHelpers.js';
// Redux imports
import { takeLatest, call, put, takeEvery, fork, select } from 'redux-saga/effects';
import {
  actions,
  CHANGE_LAYOUT,
  FETCH_DOUGHNUT,
  FETCH_NOTICATION_WIDGET_DATA,
  LOAD_WIDGET_CONFIG,
  ADD_WIDGET_REQUEST,
  FETCH_BARCHART,
  FETCH_FEES_WIDGET,
} from './duck';

export const getWidgetList = state => state.widgetDashboard.widgetList;
export const getWidgetLayout = state => state.widgetDashboard.layout;
export const getLoggedInLoginID = state =>
  state.drilldown.drilldownHistory[state.drilldown.drilldownHistory.length - 1].loginID;
export function* watchWidgetDashboard() {
  yield takeLatest(CHANGE_LAYOUT, layoutChange);
  yield takeEvery(FETCH_DOUGHNUT, fetchDataForDoughnutWidget);
  yield takeEvery(FETCH_BARCHART, fetchBarChartWidgetData);
  yield takeEvery(FETCH_FEES_WIDGET, fetchFeesWidgetData);
  yield takeEvery(FETCH_NOTICATION_WIDGET_DATA, fetchNotifcationWidgetData);
  yield takeLatest(LOAD_WIDGET_CONFIG, loadWidgetConfiguration);
  yield takeEvery(ADD_WIDGET_REQUEST, addWidgetRequest);
}

export function* layoutChange(payload) {
  try {
    yield call(XlinkAPI.saveWidgetConfig, payload.layout, payload.widgetList);
  } catch (error) {
    ErrorHelpers.handleError('Error saving widget dashboard layout', error);
  }
}

function fetchConfigParams(widgetList, key, body) {
  let configParams = {};
  for (let i = 0; i < widgetList.length; i++) {
    if (widgetList[i].i === key) {
      if (body !== undefined) {
        widgetList[i].config = body;
      }
      configParams = widgetList[i].config;
      break;
    }
  }
  return configParams;
}

export function* loadWidgetConfiguration() {
  try {
    const res = yield call(XlinkAPI.loadWidgetConfig);

    if (
      res.status === 200 &&
      res.data &&
      res.data.dashboardlayout.length &&
      res.data.widgetlist.length
    ) {
      yield put(actions.setWidgetConfig(res.data.dashboardlayout, res.data.widgetlist));

      const widgetList = res.data.widgetlist;
      for (let i = 0; i < widgetList.length; i++) {
        const widget = widgetList[i];
        switch (widget.type) {
          case 1:
            yield call(fetchDataForDoughnutWidget, {
              filter: 'year',
              key: widget.i,
            });
            break;
          case 2:
            yield call(fetchDataForDoughnutWidget, {
              filter: 'year',
              key: widget.i,
            });
            break;
          case 3:
            break;
          case 4:
            yield call(fetchNotifcationWidgetData, {
              filter: '',
              key: widget.i,
            });
            break;
          case 5:
            yield call(fetchBarChartWidgetData, {
              filter: '',
              key: widget.i,
            });
            break;
          case 6:
            yield call(fetchFeesWidgetData, {
              filter: '',
              key: widget.i,
            });
            break;
        }
      }
    } else {
      console.log('User has no widget config');
    }
  } catch (error) {
    // If it is an insufficient permissions error do not display err modal
    if (error?.response.status === 401) {
      console.log('Insufficient Permissions Error: ', error?.response?.data.details);
    } else {
      ErrorHelpers.handleError('Error Fetching Widget', error);
    }
  }
}

export function* addWidgetRequest(payload) {
  try {
    const res = yield call(XlinkAPI.onAddWidgetFromConfig, payload.widgetType);
    switch (payload.widgetType) {
      case 1:
        yield fork(fetchDataForDoughnutWidget, {
          filter: 'year',
          key: res.data.layout.i,
        });
        break;
      case 6:
        yield fork(fetchFeesWidgetData, {
          filter: '',
          key: res.data.layout.i,
        });
        break;
    }
    yield put(actions.AddWidgetWithConfig(res.data.layout, payload.widgetType));
  } catch (error) {
    ErrorHelpers.handleError('Error Fetching Widget', error);
  }
}

// export function* navigateToTabWithFilter(payload){
//  try {
//    payload.titleTab =
//    //querying against a different entity(different office, bank product returns, or ancillary products). Update the config in redux
//
//    yield put(appActions.selectTitleTabByNameFiltered(payload.key, res.data));
//  } catch (error) {
//    console.log(error);
//    ErrorHelpers.handleError("Error Fetching Widget Doughnut", error);
//  }
// }

export function* fetchDataForDoughnutWidget(payload) {
  try {
    // querying against a different entity(different office, bank product returns, or ancillary products). Update the config in redux
    const widgetList = yield Object.assign({}, select(getWidgetList));
    let customConfigParams = {}; // {}(empty) when there is no config for the widget
    const timeFilter = payload.filter === '' ? 'year' : payload.filter;
    if (payload.body !== undefined) {
      customConfigParams = payload.body;
      customConfigParams = fetchConfigParams(widgetList, payload.key, payload.body);
    } else {
      // Check to see if there is any configuration saved for our widget
      customConfigParams = fetchConfigParams(widgetList, payload.key);
    }

    const res = yield call(XlinkAPI.getDoughnutWidgetData, timeFilter, customConfigParams);

    yield put(actions.updateWidgetData(payload.key, res.data));
  } catch (error) {
    console.log(error);
    ErrorHelpers.handleError('Error Fetching Widget Doughnut', error);
  }
}

export function* fetchNotifcationWidgetData(payload) {
  try {
    const res = yield call(
      XlinkAPI.simpleGetRequest,
      `${XLINK_API_URL}/widget/fetch/notifications`,
    );
    yield put(actions.updateWidgetData(payload.key, res.data));
  } catch (error) {
    console.log(error);
  }
}

export function* fetchBarChartWidgetData(payload) {
  try {
    const res = yield call(XlinkAPI.simpleGetRequest, `${XLINK_API_URL}/widget/fetch/barchart`);
    yield put(actions.updateWidgetData(payload.key, res.data));
  } catch (error) {
    console.log(error);
  }
}

export function* fetchFeesWidgetData(payload) {
  try {
    const widgetList = yield Object.assign({}, select(getWidgetList));
    let customConfigParams = {};
    if (payload.body !== undefined) {
      customConfigParams = payload.body;
      customConfigParams = fetchConfigParams(widgetList, payload.key, payload.body);
    } else {
      // Check to see if there is any configuration saved for our widget
      customConfigParams = fetchConfigParams(widgetList, payload.key);
    }
    const res = yield call(
      XlinkAPI.post,
      `${XLINK_API_URL}/widget/fetch/feeswidget`,
      customConfigParams,
    );
    yield put(actions.updateWidgetData(payload.key, res.data));
  } catch (error) {
    console.log(error);
  }
}

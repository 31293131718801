//Customer List Duck
import * as Global from "~/app/constants.js";
import * as Common from "~/app/redux/commonActions.js";
const UPDATE_PAGE = "xlinkonline/customerList/UPDATE";
const SELECT_FILTER = "xlinkonline/customerList/SELECTLIST";
const SELECT_PAGE = "xlinkonline/customerList/SELECTPAGE";
const UPDATE_FILTER = "xlinkonline/customerList/UPDATEFILTER";
const RESET_CUSTOMER_FILTER = "xlinkonline/customerList/RESETCUSTOMERFILTER";
const UPDATE_CUSTOMER_SORT_ORDER =
  "xlinkonline/customerList/UPDATECUSTOMERSORTORDER";

const initialState = {
  filterValues: {
    first: "",
    second: ""
  },
  sortOrder: {
    column: 0, // default sort is by last name
    order: "asc" // default sort is in ascending order
  },

  // all possible table columns. name is for column title, col is for query params
  allColumns: [
    { name: "Name", col: "lname", asc: "Sort By A-Z", desc: "Sort By Z-A" },
    {
      name: "Social Security Number",
      col: "ssn",
      asc: "Sort By  0000-9999",
      desc: "Sort By  9999-0000"
    },
    {
      name: "Last Activity",
      col: "last_activity",
      asc: "Sort By Oldest First",
      desc: "Sort By Newest First"
    },
    { name: "Metric", col: "metric", asc: "Sort By A-Z", desc: "Sort By Z-A" },
    { name: "", col: "", asc: "", desc: "" },
    { name: "EFIN", col: "efin", asc: "Sort By 0-9", desc: "Sort By 0-9" },
    {
      name: "Return ID",
      col: "return_id",
      asc: "Sort By 0-9",
      desc: "Sort By 9-0"
    }
  ],

  // active columns, array index is column position in table and value is an index into allColumns
  activeColIndexes: [0, 1, 2, 3, 4],

  // all filters, name is displayed in the filter dropdown, filter is for query params
  // the order of these must match the order of TAX_RETURN_LIST_FILTERS in constants.js
  allFilters: [
    { name: "All Customers", filter: "all" },
    { name: "Last Activity", filter: "date_created" },
    { name: "EFIN", filter: "efin" },
    { name: "Last Name", filter: "lname" },
    { name: "State", filter: "state" },
    { name: "Status", filter: "status" },
    { name: "SSN (Last 4)", filter: "ssn" }
  ],

  // current filter criteria, index into allFilters
  activeFilter: Global.CUSTOMER_LIST_FILTERS.ALL,

  perPage: Global.DASHBOARD_DATA.DASHBOARDLISTSIZE,
  currentPage: 1,
  customers: [],
  totalCustomers: 0
};

export const actions = {
  updateCustomerListPage: (total, customers) => ({
    type: UPDATE_PAGE,
    total,
    customers
  }),
  onSelectCustomerListFilter: choice => ({
    type: SELECT_FILTER,
    choice
  }),
  onCustomerPageChange: page => ({
    type: SELECT_PAGE,
    page
  }),
  updateCustomerListFilterValues: (first, second, currentPage) => ({
    type: UPDATE_FILTER,
    first,
    second,
    currentPage
  }),
  resetCustomerListFilter: () => ({
    type: RESET_CUSTOMER_FILTER
  }),
  updateCustomerListSortOrder: () => ({
    type: UPDATE_CUSTOMER_SORT_ORDER
  })
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_PAGE:
      return {
        ...state,
        customers: action.customers,
        totalCustomers: action.total
      };
    case SELECT_FILTER:
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          first: "",
          second: ""
        },
        activeFilter: action.choice
      };
    case SELECT_PAGE:
      return {
        ...state,
        currentPage: action.page
      };
    case UPDATE_FILTER:
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          first: action.first,
          second: action.second
        },
        currentPage: action.currentPage
      };
    case RESET_CUSTOMER_FILTER:
      return {
        ...state,
        selected: 0
      };
    case UPDATE_CUSTOMER_SORT_ORDER:
      return {
        ...state,
        sortOrder: {
          ...state.sortOrder,
          column: action.col,
          order: action.order
        }
      };
    case Common.LOGOUT:
      return {
        ...state,
        customers: [],
        currentPage: 1,
        totalCustomers: 0
      };
  }
  return state;
}

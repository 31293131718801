export const styles = theme => ({
  bookmarkContainer: {
    marginTop: '30px',
    padding: theme.spacing.unit * 3,
    height: '100%',
    width: '100%',
    minWidth: '600px',
    backgroundColor: 'white',
  },
  actionButton: {
    minHeight: '35px',
    maxHeight: '35px',
    minWidth: '24px',
    maxWidth: '24px',
    padding: '0px 0px 0px 0px',
    border: '2px solid #0077FF',
  },
  cellHeader: {
    color: '#354052',
    borderBottom: 'none',
    fontSize: '14px',
    textAlign: 'left',
  },
  cellRoot: {
    borderBottom: 'none',
    fontSize: '14px',
    color: '#67727C',
  },
  bookmarkModal: {
    top: '40%',
    left: '50%',
    boxShadow: '0 0 20px 0 rgba(0,0,0,0.25)',
    height: 'auto',
    width: '400px',
    padding: '0',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
  },
  gridContainer: {
    padding: theme.spacing.unit * 2,
  },
  button: {
    marginLeft: theme.spacing.unit,
  },
});

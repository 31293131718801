import {
  actions,
  FETCHED_RETURN_LIST,
  REQUEST_ADD_NEW_RETURN,
  FETCH_RETURN_FOR_DOWNLOAD,
  FETCH_PREPARER_INFO,
  MOVE_RETURN,
  FETCH_MOBILE_RETURNS,
  IMPORT_MOBILE_RETURN,
} from './duck';
import { actions as returnProfileActions } from '../returnProfile/duck';
import { actions as appActions } from '../modules/app';

import { selectors as returnListSelectors } from './selectors';

import { takeLatest, call, put, select } from 'redux-saga/effects';

import XlinkAPI from '~/app/api/xlinkAPI';
import UtilityAPI from '~/app/api/utilityAPI';
import WebHelpers from '~/app/webHelpers.js';
import ErrorHelpers from '~/app/errorHelpers.js';
import isEqual from 'lodash.isequal';

import { LATEST_TAXPASS_SEASON, HIERARCHY_TYPE, DASHBOARD_TITLE_TABS } from '~/app/constants.js';

export function* watchFetchReturnList() {
  yield takeLatest(FETCHED_RETURN_LIST, fetchReturnList);
  yield takeLatest(FETCH_MOBILE_RETURNS, fetchMobileReturnList);
}

export function* fetchReturnList(payload) {
  try {
    const endpoint = `${XLINK_API_URL}/returns/list`;

    yield put(actions.requestReturnList());

    if (payload.filterParams.preparerID == null) {
      payload.filterParams.preparerID = 0;
    }

    const officeLoginID = yield call(UtilityAPI.getLastDrilledOfficeLoginID, payload.ddh);

    if (officeLoginID) {
      payload.filterParams.officeLoginID = officeLoginID;
    }

    const res = yield call(XlinkAPI.post, endpoint, payload.filterParams);

    yield put(actions.requestReturnListSuccess(res.data));
  } catch (error) {
    ErrorHelpers.handleError('Error fetching filtered returns', error);
    yield put(actions.requestReturnListError());
  }
}

export function* fetchMobileReturnList(payload) {
  try {
    const endpoint = `${XLINK_API_URL}/mobile/import/list`;
    const res = yield call(XlinkAPI.simpleGetRequest, endpoint, payload?.axiosCancelSource);
    if (res.data !== null) {
      if (res.data.results === null) {
        res.data.results = [];
      }

      // Remove items that have not yet been submitted from displaying
      res.data.results = res.data.results?.filter(item => item?.dateSubmitted);

      const mobileInfo = yield select(returnListSelectors.getMobileReturnState);

      if (!isEqual(res.data, mobileInfo)) {
        yield put(actions.updateMobileReturnList(res.data));
        yield put(actions.toggleMobileImportNotification(true));
      }
    }
  } catch (error) {
    ErrorHelpers.handleError('Unable to fetch TaxPass Site return list', error);
  }
}

export function* watchImportMobileReturn() {
  yield takeLatest(IMPORT_MOBILE_RETURN, importMobileReturn);
}

export function* importMobileReturn(payload) {
  try {
    // Will only support latest season imports
    if (payload?.jwtPayload?.season < LATEST_TAXPASS_SEASON) {
      return;
    }

    yield put(actions.toggleMobileImportSpinner(true));
    const endpoint = `${XLINK_API_URL}/mobile/import`;

    // if we're a preparer, use my active EFIN to create the return
    //  otherwise, use the drilldownHierarchy to determine which office to file under
    let activeEfinID;
    if (payload?.jwtPayload?.hierarchy_type_id === HIERARCHY_TYPE.PREPARER) {
      activeEfinID = payload?.jwtPayload?.preparer_info?.active_efin_id;
    } else {
      activeEfinID = yield call(UtilityAPI.getLastDrilledOfficeEfinID, payload.ddh);
      if (!activeEfinID) {
        throw ErrorHelpers.createSimpleError(
          'Failed to find active EFIN, is office setup complete?',
        );
      }
    }

    const res = yield call(XlinkAPI.post, endpoint, {
      ssn: payload.ssn,
      filingStatus: payload.filingStatus,
      loginID: payload.loginID,
      preparerID: payload.preparerID,
      efinID: activeEfinID,
      isNew: Boolean(payload.isNew),
      isAlternateRtnImport: Boolean(payload.isAlternateRtnImport),
      dateImported: payload.dateImported,
      docsOnly: payload.docsOnly,
    });

    if (res.data !== null) {
      if (res.data === 0) {
        yield put(actions.toggleMobileImportSpinner(false));
        yield put(actions.toggleMobileImportMessage(true));
      } else {
        yield put(returnProfileActions.fetchReturnProfileByReturnID(res.data));
        yield put(appActions.toggleMobileImportModal(false));
        yield put(actions.toggleMobileImportSpinner(false));
      }
    }
  } catch (error) {
    console.log('my err', error);
    yield put(actions.toggleMobileImportSpinner(false));
    ErrorHelpers.handleError('Unable to import mobile return', error);
  }
}

export function* watchAddNewReturn() {
  yield takeLatest(REQUEST_ADD_NEW_RETURN, addNewReturn);
}

export function* addNewReturn(payload) {
  try {
    // if we're a preparer, use my active EFIN to create the return
    //  otherwise, use the drilldownHierarchy to determine which office to file under
    let activeEfinID;
    if (payload.jwtPayload.hierarchy_type_id === HIERARCHY_TYPE.PREPARER) {
      activeEfinID = payload.jwtPayload.preparer_info.active_efin_id;
    } else {
      activeEfinID = yield call(UtilityAPI.getLastDrilledOfficeEfinID, payload.ddh);
      if (!activeEfinID) {
        throw ErrorHelpers.createSimpleError(
          'Failed to find active EFIN, is office setup complete?',
        );
      }
    }

    console.log('Attempting to add new return with EFIN: ', activeEfinID);

    const newReturn = yield call(
      XlinkAPI.addNewReturn,
      payload.loginID,
      payload.preparerID,
      payload.identifier,
      activeEfinID,
      payload.dupCheck,
      payload.wizardReturn,
      payload.entityType,
    );

    console.log('New Return: ', newReturn);

    // Check for duplicate current year returns
    if (newReturn.data.cyReturns) {
      yield put(actions.setExistingSSNEIN(true, newReturn.data.cyReturns));
      return;
    }

    yield call(XlinkAPI.lockReturnByReturnID, newReturn.data.returnID);

    const profile = yield call(XlinkAPI.getReturnProfileByReturnID, newReturn.data.returnID);

    const arr = [];
    Object.keys(profile.data.forms).forEach(function (key) {
      arr.push(profile.data.forms[key]);
    });
    // TODO this structure should be received from the server
    let attachments = profile.data.attachments;
    if (attachments == null) {
      attachments = [];
    }

    yield put(actions.closeAddNewReturnModal());

    yield put(
      returnProfileActions.setActiveReturn(
        {
          firstName: '',
          lastName: '',
          returnID: newReturn.data.returnID,
          friendlyID: newReturn.data?.friendlyID,
          ssnein: payload.identifier,
          date_created: profile.data.date_created,
          days_active: 0,
          status: '',
          year: payload.jwtPayload.season,
          refund_or_balance_due: 0,
          fed_agi: 0,
          fed_status: profile.data.fed_status,
          fed_filing_date: '',
          attachments,
          state_returns: [],
          isNew: true,
          wizard_mode: payload.wizardReturn,
        },
        arr,
      ),
    );

    yield put(returnProfileActions.openActiveReturn());
    yield put(appActions.onSelectTitleTab(DASHBOARD_TITLE_TABS.LIST_VIEW));

    yield put(actions.addNewReturnSuccess());
  } catch (error) {
    ErrorHelpers.handleError('Error creating new return', error);
    yield put(actions.addNewReturnError());
    yield put(actions.closeAddNewReturnModal());
  }
}

export function* watchDownloadReturn() {
  yield takeLatest(FETCH_RETURN_FOR_DOWNLOAD, fetchReturnForDownload);
}

export function* fetchReturnForDownload(payload) {
  try {
    const res = yield call(XlinkAPI.downloadReturn, payload.row.returnID);

    const calcRes = JSON.parse(res.data.return_blob);

    if (calcRes.Error) {
      yield put(actions.downloadReturnFailure());
      ErrorHelpers.handleError('Error downloading return', {
        response: { data: { error_message: calcRes.Error, error_code: 0 } },
      });
      return; // TODO: throw this error object and handle all errors from the single catch
    }
    dlUtils.downloadReturn(calcRes.arg.return_blob, payload.row, payload.season);

    yield put(actions.downloadReturnSuccess());
  } catch (error) {
    ErrorHelpers.handleError('Error downloading return', error);
    yield put(actions.downloadReturnFailure());
  }
}

export class dlUtils {
  static downloadReturn = (returnBlob, row, season) => {
    const payload = WebHelpers.getJWTPayload();
    // Create BlobURL From b64
    const blob = dlUtils.b64toBlob(returnBlob, 'application/octet-stream');
    const blobURL = URL.createObjectURL(blob);

    // Create anchor element to trigger a download once function is called.
    const dlAnchor = document.createElement('a');
    if (row.name === '----') {
      row.name = 'no_name';
    }
    // 1040 name format is 'last, first'
    const fName = row.name.replace(',', '') + '_' + row.returnID;

    let ext = '.xf';

    // set correct extension type if business
    if (payload.is_business) {
      ext = '.xb';
    }

    // Set extension tax year
    const taxYearSingleDigit = season[season.length - 1];
    if (taxYearSingleDigit === '0') {
      ext = ext + '9';
    } else {
      ext = ext + (taxYearSingleDigit - 1);
    }

    dlAnchor.href = blobURL;
    dlAnchor.download = fName + ext;
    dlAnchor.click();
  };

  static b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };
}

export function* watchFetchPreparerInfo() {
  yield takeLatest(FETCH_PREPARER_INFO, fetchPreparerInfo);
}

export function* fetchPreparerInfo(payload) {
  try {
    const preparers = yield call(XlinkAPI.getAllPreparersAtAnOffice, payload.returnID);
    const preparer = yield call(XlinkAPI.getPreparerOfReturn, payload.returnID);

    if (preparers.data !== null && preparers.data?.length > 0 && preparer.data !== null) {
      // exclude the preparer who owns the return from the list of available preparers
      const preplist = preparers.data.filter(p => p.preparer_id !== preparer.data.preparer_id);

      yield put(actions.onGetPreparerInfo(preparer.data, preplist, preplist[0]));
    }

    yield put(actions.requestPreparerInfoSuccess());
  } catch (error) {
    ErrorHelpers.handleError('Failed to get the preparer list', error);
    yield put(actions.requestPreparerInfoFailure());
  }
}

export function* watchMoveReturn() {
  yield takeLatest(MOVE_RETURN, moveReturn);
}

export function* moveReturn(payload) {
  try {
    yield put(actions.requestMoveReturn());
    if (payload.returnID == null || payload.preparerID == null) {
      // eslint-disable-next-line no-throw-literal
      throw 'Missing input parameter';
    }
    yield call(XlinkAPI.assignPreparerToReturn, payload.returnID, payload.preparerID);
    yield put(actions.showMoveReturnModal(false));
    yield put(actions.moveReturnSuccess());
  } catch (error) {
    ErrorHelpers.handleError('Error moving a return', error);
    yield put(actions.showMoveReturnModal(false));
    yield put(actions.moveReturnFailure());
  }
}

export const styles = {
  labelStyles: {
    color: '#354052',
    textAlign: 'left',
    fontFamily: 'Roboto',
  },
  button: {
    width: '9em',
    fontSize: '14px',
    borderRadius: '4px',
    marginLeft: '2em',
  },
  toolbar: {
    backgroundColor: '#444A59',
  },
  inputStyle: {
    width: '35vw',
    height: '3.5vh',
  },
};

export const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  cancelButtonRoot: {
    height: '30px',
    border: '0px',
    backgroundColor: '#FFFFFF',
    textTransform: 'capitalize',
    lineHeight: '0em',
    marginRight: '1em',
  },
  cssLabel: {
    '&$cssFocused': {
      color: '#40a9ff',
    },
  },
  cssFocused: {},
  cssUnderline: {
    '&:after': {
      borderBottomColor: '#444A59',
    },
  },
  selectRoot: {
    borderBottom: '0px',
  },
  tabsRoot: {
    borderBottom: '1px solid #EBEDF8',
  },
  tabsIndicator: {
    backgroundColor: '#444A59',
  },
  tabRoot: {
    textTransform: 'initial',
    minWidth: 92,
    fontSize: '15px',
    margin: 0,
    fontFamily: ['Roboto'].join(','),
    '&:hover': {
      color: '#354052',
      opacity: 1,
    },
    '&$tabSelected': {
      color: '#354052',
      fontWeight: theme.typography.fontWeightMedium,
      backgroundColor: 'transparent',
      outline: 'none !important',
      boxShadow: 'none !important',
    },
    '&:focus': {
      color: '#354052',
      backgroundColor: 'transparent',
      outline: 'none !important',
      boxShadow: 'none !important',
    },
  },
  tabSelected: {},
  typography: {
    padding: theme.spacing.unit * 3,
  },
  tableRowRoot: {
    height: '35px',
    letterSpacing: '0.1px',
  },
  tableHeadRoot: {
    height: '40px',
    letterSpacing: '0.1px',
  },
  tableCellRoot: {
    padding: '4px 24px 4px 24px',
  },
  inputRoot: {
    width: 'calc(60%)',
    marginRight: '1em',
  },
  toolbarButtonRoot: {
    border: 'none',
    color: '#FFFFFF',
    borderRadius: '4px',
    textTransform: 'capitalize',
    boxShadow: 'none',
    outline: 'none',
  },
  modalTitle: {
    color: '#FFFFFF',
    fontFamily: 'Roboto',
    fontSize: '15px',
    letterSpacing: '0.1px',
    paddingLeft: '1em',
    backgroundColor: '#444a59',
  },
  cancelIcon: {
    color: '#FFFFFF',
    fontFamily: 'Roboto',
    fontSize: '15px',
    letterSpacing: '0.1px',
    backgroundColor: '#444a59',
    border: 'none',
    borderRadius: 'none',
    marginLeft: '620px',
    padding: '2px',
    minHeight: '0px',
    minWidth: '0px',
  },
  searchBarLabel: {
    marginRight: '16px',
    marginTop: '20px',
    marginBottom: '20px',
    marginLeft: '5px',
    fontFamily: 'Roboto',
    fontSize: '14px',
    height: '16px',
    lineHeight: '16px',
  },
  searchBarInput: {
    width: '89%',
  },
  footerContainer: {
    marginTop: '15px',
    paddingBottom: '15px',
    textAlign: 'right',
    position: 'relative',
  },
  packageLabel: {
    marginRight: '5px',
  },
  addFormIconStyle: {
    marginRight: '.5em',
  },
  tabListBarStyle: {
    backgroundColor: 'white',
    color: 'black',
  },
  activityListContainer: {
    paddingLeft: '0px',
    marginLeft: '0px',
  },
  k1Btn: {
    float: 'right',
    marginRight: '0.5em',
    marginTop: '1.0em',
  },
  marginRight: {
    marginRight: '1rem',
  },
  signatureCaptureContainer: {
    height: '300px',
    width: '570px',
    borderRadius: '4px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  toolBar: {
    backgroundColor: '#444A59',
  },
  toolBarTitle: {
    fontFamily: 'Roboto',
    fontSize: '18px',
    color: 'white',
  },
  signatureCaptureForm: {
    margin: '1rem 3rem',
  },
  signatureCapturePad: {
    width: '476px',
    height: '100px',
    backgroundColor: '#DBEBFE',
  },
  signatureCaptureFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '.5rem',
  },
});

import React, { useEffect, useState } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import '~/app/Components/Auth/css/saveParagraphModal.css';

const SaveParagraphModal = ({ onClickContinue }) => {
  return (
    <div className="save-container">
      <div className="save-modal-toolbar">
        <Toolbar>
          <Typography />
        </Toolbar>
      </div>
      <div className="save-modal-body">
        <span className="buffer-text">
          <div className="prompt-text">Paragraph is not saved. Do you want to save?</div>
        </span>
        <div className="Btn">
          <div className="saveBtn">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                onClickContinue(true);
              }}
            >
              Save
            </Button>
          </div>

          <Button
            className="notSavingBtn"
            variant="contained"
            color="primary"
            onClick={() => {
              onClickContinue(false);
            }}
          >
            Don't Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SaveParagraphModal;

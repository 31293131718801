// External imports
import React, { Component } from 'react';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Input,
  Paper,
  Divider,
  Checkbox,
  Button,
  NativeSelect,
  Snackbar,
  withStyles,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
// Internal imports
import XlinkAPI from '~/app/api/xlinkAPI';
import ErrorHelpers from '~/app/errorHelpers.js';
import {
  STATE_LIST_ADDRESS,
  ADDRESS_DATA_STRINGS,
  LOGO_TYPE,
  HIERARCHY_TYPE,
  NOTIFICATION_TYPE,
  REQUIRED_SETUP_PAGES,
} from '~/app/constants.js';
import AccessControl from '#/Auth/AccessControl.jsx';
import WebHelpers from '~/app/webHelpers.js';
import LogoSelector from '~/app/Pages/Setup/AccountSetup/components/LogoSelector/LogoSelector.jsx';
import SimpleDialog from '#/Common/SimpleDialog.jsx';
import VerifyEFINModal from '~/app/Components/NewAccount/SingleOffice/VerifyEFINModal.jsx';
import Checkmark from '~/images/icons/icons8-checkmark-48.png';
// Redux imports
import { actions as notificationActions } from '~/app/redux/notifications/duck';
import { actions as officeSettingsActions } from '~/app/redux/modules/officeSettings.js';
import { actions as setupPageActions } from '~/app/redux/setupPages/duck';
import { actions as cobrandActions } from '~/app/redux/cobrand/duck';
// Styling imports
import { styles } from '~/app/Components/NewAccount/SingleOffice/css/officeSetup.js';
import '~/app/Components/NewAccount/SingleOffice/css/officeSetup.css';
import '~/app/Pages/Setup/css/setup.css';

const mapDispatchToProps = {
  ...officeSettingsActions,
  ...setupPageActions,
  ...notificationActions,
  ...cobrandActions,
};
const mapStateToProps = state => {
  return {
    officeAddress: state.officeSettings.officeAddress,
    drilldownHistory: state.drilldown.drilldownHistory,
    loginID: state.drilldown.drilldownHistory[state.drilldown.drilldownHistory.length - 1].loginID,
    hierarchyType:
      state.drilldown.drilldownHistory[state.drilldown.drilldownHistory.length - 1].role,
    canCobrand: state.cobrand.canCobrand,
    hasPurchasedCobranding: state.cobrand.hasPurchasedCobranding,
    logo: state.cobrand.logo,
    isLoading: state.cobrand.isLoadingCobrandStatus,
    hasAccessToAddCobranding: state.cobrand.hasAccessToAddCobranding,
    hasAccessToPurchaseCobranding: state.cobrand.hasAccessToPurchaseCobranding,
    officeProfile: state.officeProfile,
  };
};
class OfficeSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firmName: this.props.officeSetup.officeInfo.firmName,
      EFIN: this.props.officeSetup.EFIN === 0 ? '' : this.props.officeSetup.EFIN,
      firmAddress: this.props.officeSetup.officeInfo.firmAddress,
      suiteNum: this.props.officeSetup.officeInfo.suiteNum,
      city: this.props.officeSetup.officeInfo.city,
      stateSelected: this.props.officeSetup.officeInfo.state.trim(),
      zip: this.props.officeSetup.officeInfo.zip,
      officePhone: this.props.officeSetup.officeInfo.phone,
      fax: this.props.officeSetup.officeInfo.fax,
      sameInfo: this.props.officeSetup.sameAsOffice === 1,
      overridefirmName: this.props.officeSetup.overrideOfficeInfo.firmName,
      overridefirmAddress: this.props.officeSetup.overrideOfficeInfo.firmAddress,
      overridesuiteNum: this.props.officeSetup.overrideOfficeInfo.suiteNum,
      overridecity: this.props.officeSetup.overrideOfficeInfo.city,
      overridestateSelected: this.props.officeSetup.overrideOfficeInfo.state,
      overridezip: this.props.officeSetup.overrideOfficeInfo.zip,
      overrideofficePhone: this.props.officeSetup.overrideOfficeInfo.phone,
      overridefax: this.props.officeSetup.overrideOfficeInfo.fax,
      selfEmployed: this.props.officeSetup.selfEmployed === 1,
      confirmCancel: false,
      isTempEfin: this.props.officeSetup.EFIN >= 970000, // Efins over 970000 are used as temporary place holders
      hasCobranding: false,
      hasPurchasedCobranding: this.props.hasPurchasedCobranding,
      isVerifyEFINModalOpen: false,
      verifyEFINField: this.props.officeSetup.EFIN === 0 ? '' : this.props.officeSetup.EFIN,
      isFeederOffice: this.props.officeProfile.is_feeder_office,
      efinID: this.props.officeProfile.efin_id,
    };
    this.payload = WebHelpers.getJWTPayload();
  }

  componentDidMount() {
    this.props.fetchCobrandingPermission();
    this.GetCobrandingStatuses();
  }

  GetCobrandingStatuses = async () => {
    try {
      const res = await XlinkAPI.GetCobrandingStatuses();
      this.setState({ hasCobranding: res?.data?.cobrandStatus });
    } catch (error) {
      ErrorHelpers.handleError('Failed to retrieve cobrand status', error);
    }
  };

  // Get initial config state of the page
  getInitialConfigState() {
    this.setState({
      firmName: this.props.officeSetup.officeInfo.firmName,
      EFIN: this.props.officeSetup.EFIN === 0 ? '' : this.props.officeSetup.EFIN,
      firmAddress: this.props.officeSetup.officeInfo.firmAddress,
      suiteNum: this.props.officeSetup.officeInfo.suiteNum,
      city: this.props.officeSetup.officeInfo.city,
      stateSelected: this.props.officeSetup.officeInfo.state,
      zip: this.props.officeSetup.officeInfo.zip,
      officePhone: this.props.officeSetup.officeInfo.phone,
      fax: this.props.officeSetup.officeInfo.fax,
      sameInfo: this.props.officeSetup.sameAsOffice === 1,
      overridefirmName: this.props.officeSetup.overrideOfficeInfo.firmName,
      overridefirmAddress: this.props.officeSetup.overrideOfficeInfo.firmAddress,
      overridesuiteNum: this.props.officeSetup.overrideOfficeInfo.suiteNum,
      overridecity: this.props.officeSetup.overrideOfficeInfo.city,
      overridestateSelected: this.props.officeSetup.overrideOfficeInfo.state,
      overridezip: this.props.officeSetup.overrideOfficeInfo.zip,
      overrideofficePhone: this.props.officeSetup.overrideOfficeInfo.phone,
      overridefax: this.props.officeSetup.overrideOfficeInfo.fax,
      selfEmployed: this.props.officeSetup.selfEmployed === 1,
      hasCobranding: false,
      hasPurchasedCobranding: this.props.hasPurchasedCobranding,
    });
  }

  toggleSuccessSnackbar = toggle => {
    this.setState({
      snackbarOpen: toggle,
    });
  };

  toggleConfirmCancel = toggle => {
    this.setState({
      confirmCancel: toggle,
    });
  };

  // Handle onChange without validation for checkbox
  handleCheckbox = event => {
    if (event.target.name === 'hasPurchasedCobranding' && !event.target.checked) {
      this.setState({ [event.target.name]: event.target.checked, hasCobranding: false });
    } else this.setState({ [event.target.name]: event.target.checked });
  };

  // Handle onChange without validation
  handleInputChangeNoValidation = e => {
    this.setState({ [e.target.name]: e.target.value.toUpperCase() });
  };

  // Trim leading and trailing spaces
  removeExtraSpaces = e => {
    const name = e.target.name;
    let value = e.target.value;
    value = value.replace(/\s\s+/g, ' '); // Replace tabs, new tabs and multiple spacing with single blank space
    const valTrim = value.trim();
    this.setState({ [name]: valTrim });
  };

  // Handle onChange with validation
  handleInputValidationNoSpecialChars = e => {
    let value = e.target.value;
    const name = e.target.name;
    if (name === ADDRESS_DATA_STRINGS.SUITE_NUM_STRING || name === 'overridesuiteNum') {
      // Checks for alphanumeric value or "#", and allows for blank spaces
      value = value.replace(/[^#a-z0-9 ]+/gi, '');

      // Checks for ONLY numbers
    } else if (name === 'verifyEFINField') {
      value.replace(/[^0-9]/g, '');
    } else {
      // the regex below matches any character that is not a word character (alphanumeric & underscore) and replaces it with a single space
      // Equivalent to [^A-Za-z0-9_], it also checks for multiple blank spaces and replaces them with a single space
      value = value.replace(/[\W_\s+]+/g, ' '); // Remove special characters
      if (
        name === ADDRESS_DATA_STRINGS.CITY_STRING ||
        name === ADDRESS_DATA_STRINGS.OVERRIDE_CITY_STRING
      ) {
        value = value.replace(/[0-9]/g, ''); // Remove digits[0-9]
      }
    }
    this.setState({ [e.target.name]: value.toUpperCase() });
  };

  renderStateDropdown = (contents, validState) => {
    const menuItemStyle = {
      paddingTop: '0px',
      paddingBottom: '0px',
      color: '#0077FF',
      backgroundColor: '#FFFFFF',
    };
    const menuEntryStyle = {
      paddingTop: '0px',
      paddingBottom: '0px',
      color: '#0077FF',
      paddingLeft: '12px',
      backgroundColor: '#FFFFFF',
    };
    return (
      <NativeSelect
        id="ddlStateDropdownOfficeSetup"
        value={this.state.stateSelected ? this.state.stateSelected : ''}
        onChange={this.handleInputChangeNoValidation}
        name="stateSelected"
        style={{
          width: '12vw',
          marginRight: '3vw',
          paddingTop: '0px',
          paddingBottom: '0px',
          paddingRight: '0px',
          textAlign: 'left',
        }}
        error={!validState}
        disableUnderline
      >
        <option value="" style={menuItemStyle} />
        {contents.map(entry => {
          return (
            <option key={entry} value={entry} style={menuEntryStyle}>
              {entry}
            </option>
          );
        })}
      </NativeSelect>
    );
  };

  renderStateDropdownSecondPart = contents => {
    const menuItemStyle = {
      paddingTop: '0px',
      paddingBottom: '0px',
      color: '#0077FF',
      backgroundColor: '#FFFFFF',
    };
    const menuEntryStyle = {
      paddingTop: '0px',
      paddingBottom: '0px',
      color: '#0077FF',
      paddingLeft: '12px',
      backgroundColor: '#FFFFFF',
    };
    return (
      <NativeSelect
        id="ddlStateDropdown02OfficeSetup"
        value={this.state.sameInfo ? this.state.stateSelected : this.state.overridestateSelected}
        onChange={this.handleInputChangeNoValidation}
        name="overridestateSelected"
        style={{
          width: '12vw',
          marginRight: '3vw',
          paddingTop: '0px',
          paddingBottom: '0px',
          textAlign: 'left',
          paddingRight: '0px',
        }}
        disabled={this.state.sameInfo}
        disableUnderline
      >
        <option value="" style={menuItemStyle} />
        {contents.map(entry => {
          return (
            <option key={entry} value={entry} style={menuEntryStyle}>
              {entry}
            </option>
          );
        })}
      </NativeSelect>
    );
  };

  isLastSectionToComplete = () => {
    return (
      !this.props.officeSetupProgressFlags.office &&
      this.props.officeSetupProgressFlags.ero &&
      this.props.officeSetupProgressFlags.preparer &&
      this.props.officeSetupProgressFlags.billing
    );
  };

  handleOfficeCompletion = () => {
    // Both Office and EFIN are done, AND it is the last section to be completed
    if (this.isLastSectionToComplete() && this.props.officeSetup?.EFINValidated) {
      this.props.addFrontendNotification({
        id: 0,
        returnID: 0,
        content: 'Required Office Setup Completed Successfully',
        type: NOTIFICATION_TYPE.SUCCESS,
      });
      this.props.onSetupComplete();
      this.props.updateOfficeSetupFlag('office', true);

      // If both Office and EFIN Validation are done, but its not the last section to be completed.
    } else if (this.props.officeSetup?.EFINValidated) {
      this.props.updateOfficeSetupFlag('office', true);

      // If office is done and EFIN Validation is not
    } else if (!this.props.officeSetup?.EFINValidated) {
      this.props.addFrontendNotification({
        id: 0,
        returnID: 0,
        content:
          'Your required Office Setup fields have been filled out, but your EFIN still needs to be validated.',
        type: NOTIFICATION_TYPE.WARNING,
      });
    }
  };

  submitOfficeSetupData = async gotoNext => {
    const sendFirmName = this.state.firmName;
    const sendEFIN = this.state.EFIN;
    const sendFirmAddress = this.state.firmAddress;
    const sendSuiteNum = this.state.suiteNum;
    const sendCity = this.state.city;
    const sendStateSelected = this.state.stateSelected;
    const sendZip = this.state.zip;
    const sendOfficePhone = this.state.officePhone.toString().replace(/[^0-9]/g, '');
    const sendFax = this.state.fax.toString().replace(/[^0-9]/g, '');
    const sendSameInfo = this.state.sameInfo ? '1' : '0';
    const sendOverridefirmName = this.state.sameInfo
      ? this.state.firmName
      : this.state.overridefirmName;
    const sendOverridefirmAddress = this.state.sameInfo
      ? this.state.firmAddress
      : this.state.overridefirmAddress;
    const sendOverridesuiteNum = this.state.sameInfo
      ? this.state.suiteNum
      : this.state.overridesuiteNum;
    const sendOverridecity = this.state.sameInfo ? this.state.city : this.state.overridecity;
    const sendOverridestateSelected = this.state.sameInfo
      ? this.state.stateSelected
      : this.state.overridestateSelected;
    const sendOverridezip = this.state.sameInfo ? this.state.zip : this.state.overridezip;
    const sendOverrideofficePhone = this.state.sameInfo
      ? this.state.officePhone.toString().replace(/[^0-9]/g, '')
      : this.state.overrideofficePhone.toString().replace(/[^0-9]/g, '');
    const sendOverridefax = this.state.sameInfo
      ? this.state.fax.toString().replace(/[^0-9]/g, '')
      : this.state.overridefax.toString().replace(/[^0-9]/g, '');
    // If we want to return blank whenever the user selects the sameInfo checkbox, ex : let sendOverridefax = this.state.overridefax;
    const sendSelfEmployed = this.state.selfEmployed ? '1' : '0';
    const sendObjectOfficeSetup = {
      sendFirmName,
      sendEFIN,
      sendFirmAddress,
      sendSuiteNum,
      sendCity,
      sendStateSelected,
      sendZip,
      sendOfficePhone,
      sendFax,
      sendSameInfo,
      sendOverridefirmName,
      sendOverridefirmAddress,
      sendOverridesuiteNum,
      sendOverridecity,
      sendOverridestateSelected,
      sendOverridezip,
      sendOverrideofficePhone,
      sendOverridefax,
      sendSelfEmployed,
    };
    const updatedOfficeInfo = {
      firmName: sendFirmName,
      firmAddress: sendFirmAddress,
      suiteNum: sendSuiteNum,
      city: sendCity,
      state: sendStateSelected,
      zip: sendZip,
      phone: sendOfficePhone,
      fax: sendFax,
    };

    try {
      await XlinkAPI.UpdateCobrandingStatuses(
        this.state.hasCobranding ? 1 : 0,
        this.state.hasPurchasedCobranding ? 1 : 0,
      );
    } catch (error) {
      ErrorHelpers.handleError('Failed to update Office setup', error);
    }

    this.props.setCurrentOfficeAddress(updatedOfficeInfo);
    XlinkAPI.postOfficeSetup(sendObjectOfficeSetup, this.props.loginID)
      .then(() => {
        this.handleOfficeCompletion();
        if (gotoNext) {
          this.props.loadSetupComponent(REQUIRED_SETUP_PAGES.ERO.NAME);
        } else {
          this.toggleSuccessSnackbar(true);
        }
      })
      .catch(err => {
        ErrorHelpers.handleError('Failed to update Office setup', err);
      });

    this.props.fetchCobrandingPermission();
  };

  render() {
    const { classes } = this.props;
    const labelWidthStyle = {
      height: '1.6vh',
      color: '#354052',
      textAlign: 'left',
      fontFamily: 'Roboto',
      fontSize: '14px',
    };

    const labelWidthStyleShort = {
      width: '5vw',
      height: '1.6vh',
      color: '#354052',
      textAlign: 'left',
      fontFamily: 'Roboto',
      fontSize: '14px',
    };

    // Required values (red border), disable save/save and next buttons when any of the required fields are missing
    // For this Office Setup Page, the required values are: Office Name, Office Address, City, State, Zip, Office Phone
    const validOfficeName = this.state.firmName !== undefined && this.state.firmName.length > 0;
    const validOfficeAddress =
      this.state.firmAddress !== undefined && this.state.firmAddress.length > 0;
    const validCity = this.state.city !== undefined && this.state.city.length > 0;
    const validState =
      this.state.stateSelected !== undefined && this.state.stateSelected.length === 2;
    // For valid zip the regex used \d{5} matches 5 digits(0-9) then matches "-" or blank space followed by \d{4} matches 4 digits(0-9) and ? used as quantifier to see if there is a match in the preceding token
    const validZip = this.state.zip !== undefined && /^\d{5}(?:[-\s]\d{4})?$/.test(this.state.zip);

    // Check if all required values are OK before the submit or submit and save button is enabled
    const disableSubmit = !(
      validOfficeName &&
      validOfficeAddress &&
      validCity &&
      validState &&
      validZip
    );

    return (
      <div className="container-fixed" style={{ marginTop: '2.5rem' }}>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.snackbarOpen}
          autoHideDuration={2500}
          onClose={() => this.toggleSuccessSnackbar(false)}
          id="snkbarconfsaved"
          message={<span id="success-message">Configuration Saved</span>}
        />

        <Paper>
          <form
            style={{
              marginLeft: '3vw',
              marginRight: '3vw',
              marginTop: '1vh',
            }}
          >
            <AccessControl
              requiredAction="write"
              accessLevel="edit_office_setup"
              disableOnFalse={true}
            >
              <span>
                <div
                  style={{
                    textAlign: 'left',
                    paddingTop: '3vh',
                    paddingBottom: '1vh',
                  }}
                >
                  <span
                    style={{
                      fontFamily: 'Roboto',
                      fontSize: '21px',
                      letterSpacing: '0.14px',
                      color: '#1A173B',
                    }}
                  >
                    Office Info
                  </span>
                </div>
                {this.props.hasAccessToPurchaseCobranding && (
                  <FormControl fullWidth>
                    <div className="office-setup-checkbox-block">
                      <FormControlLabel
                        label="Purchased Co-branding"
                        classes={{ root: classes.FormControlLabel }}
                        control={
                          <Checkbox
                            icon={
                              <CheckBoxOutlineBlankIcon
                                classes={{ root: classes.checkboxOutline }}
                                color="secondary"
                              />
                            }
                            checkedIcon={<CheckBoxIcon color="primary" />}
                            id="chkhasPurchasedCobranding"
                            name="hasPurchasedCobranding"
                            checked={this.state.hasPurchasedCobranding}
                            onChange={this.handleCheckbox}
                          />
                        }
                      />
                    </div>
                    <FormHelperText classes={{ root: classes.FormHelperText }}>
                      You are seeing this field because you have proper access level.
                    </FormHelperText>
                  </FormControl>
                )}
                {this.props.hasAccessToAddCobranding && (
                  <FormControl fullWidth>
                    <div className="office-setup-checkbox-block">
                      <FormControlLabel
                        label="Enable Co-branding"
                        classes={{ root: classes.FormControlLabel }}
                        control={
                          <Checkbox
                            icon={
                              <CheckBoxOutlineBlankIcon
                                classes={{ root: classes.checkboxOutline }}
                                color="secondary"
                              />
                            }
                            checkedIcon={<CheckBoxIcon color="primary" />}
                            id="chkToggleCobranding"
                            name="hasCobranding"
                            checked={this.state.hasCobranding}
                            onChange={this.handleCheckbox}
                          />
                        }
                      />
                    </div>
                    <FormHelperText classes={{ root: classes.FormHelperText }}>
                      You are seeing this field because you have proper access level.
                    </FormHelperText>
                  </FormControl>
                )}

                {this.state.hasCobranding &&
                  !this.props.isLoading &&
                  this.props.hierarchyType !== HIERARCHY_TYPE.PREPARER && (
                    <LogoSelector
                      logoType={LOGO_TYPE.OFFICE}
                      loginID={this.props.loginID}
                      hierarchyType={this.props.hierarchyType}
                      setLogo={this.props.setLogo}
                      logo={this.props.logo}
                      requestUploadLogo={this.props.requestUploadLogo}
                      hasCobranding={this.state.hasCobranding}
                    />
                  )}
                <div>
                  <div style={{ float: 'left', marginLeft: '2vw' }}>
                    <FormControl required>
                      <InputLabel
                        style={labelWidthStyle}
                        required={false}
                        htmlFor="txtFirmNameOfficeSetup"
                        shrink
                      >
                        Office Name
                      </InputLabel>
                      <Input
                        id="txtFirmNameOfficeSetup"
                        name={ADDRESS_DATA_STRINGS.FIRM_NAME_STRING}
                        value={this.state.firmName}
                        onChange={this.handleInputChangeNoValidation}
                        onBlur={this.removeExtraSpaces}
                        style={{
                          width: '40vw',
                          marginRight: '3vw',
                        }}
                        error={!validOfficeName}
                        disableUnderline
                      />
                    </FormControl>
                  </div>
                  <div className="office-setup-field" style={{ marginLeft: '2vw' }}>
                    <FormControl required>
                      <InputLabel
                        style={labelWidthStyle}
                        required={false}
                        htmlFor="txtEFINOfficeSetup"
                        shrink
                      >
                        EFIN
                        {this.state.isTempEfin && <span>*</span>}
                        {this.props.officeSetup?.EFINValidated ? (
                          <img src={Checkmark} style={styles.labelCheckMark} />
                        ) : null}
                      </InputLabel>
                      <Input
                        id="txtEFINOfficeSetup"
                        name="verifyEFINField"
                        value={this.state.verifyEFINField}
                        onChange={this.handleInputValidationNoSpecialChars}
                        inputProps={{ maxLength: 6 }}
                        onBlur={this.removeExtraSpaces}
                        style={{
                          width: '12vw',
                          marginRight: '1vw',
                        }}
                        disableUnderline
                        disabled={
                          this.props.officeSetup?.EFINValidated === 1 || this.state.isFeederOffice
                        }
                      />
                    </FormControl>
                    {!this.props.officeSetup?.EFINValidated ? (
                      <Button
                        onClick={() => this.setState({ isVerifyEFINModalOpen: true })}
                        style={{ marginTop: '.65rem' }}
                        disabled={
                          this.props.officeSetup?.EFINValidated === 1 || this.state.isFeederOffice
                        }
                      >
                        Verify EFIN
                      </Button>
                    ) : null}
                  </div>
                  <div style={{ float: 'left', marginLeft: '2vw' }}>
                    <FormControl required>
                      <InputLabel
                        style={labelWidthStyle}
                        required={false}
                        htmlFor="txtfirmAddressOfficeSetup"
                        shrink
                      >
                        Office Address
                      </InputLabel>
                      <Input
                        id="txtfirmAddressOfficeSetup"
                        name="firmAddress"
                        value={this.state.firmAddress}
                        onChange={this.handleInputValidationNoSpecialChars}
                        onBlur={this.removeExtraSpaces}
                        style={{
                          width: '40vw',
                          marginRight: '3vw',
                        }}
                        disableUnderline
                        error={!validOfficeAddress}
                      />
                    </FormControl>
                  </div>
                  <div>
                    <FormControl>
                      <InputLabel
                        style={labelWidthStyleShort}
                        required={false}
                        htmlFor="txtSuiteNumOfficeSetup"
                        shrink
                      >
                        Suite #
                      </InputLabel>
                      <Input
                        id="txtSuiteNumOfficeSetup"
                        name="suiteNum"
                        value={this.state.suiteNum}
                        onChange={this.handleInputValidationNoSpecialChars}
                        onBlur={this.removeExtraSpaces}
                        style={{
                          width: '12vw',
                          marginRight: '3vw',
                        }}
                        disableUnderline
                      />
                    </FormControl>
                  </div>
                  <div style={{ float: 'left', marginLeft: '2vw' }}>
                    <FormControl>
                      <InputLabel
                        style={labelWidthStyle}
                        required={false}
                        htmlFor="txtCityOfficeSetup"
                        shrink
                      >
                        City
                      </InputLabel>
                      <Input
                        id="txtCityOfficeSetup"
                        name={ADDRESS_DATA_STRINGS.CITY_STRING}
                        value={this.state.city}
                        onChange={this.handleInputValidationNoSpecialChars}
                        onBlur={this.removeExtraSpaces}
                        style={{
                          width: '25vw',
                          marginRight: '3vw',
                        }}
                        error={!validCity}
                        disableUnderline
                      />
                    </FormControl>
                  </div>
                  <div style={{ float: 'left' }}>
                    <FormControl>
                      <InputLabel style={labelWidthStyleShort} htmlFor="state-selected" shrink>
                        State
                      </InputLabel>
                      {this.renderStateDropdown(STATE_LIST_ADDRESS, validState)}
                    </FormControl>
                  </div>
                  <div>
                    <FormControl>
                      <InputLabel
                        style={labelWidthStyleShort}
                        required={false}
                        htmlFor="nfZipOfficeSetup"
                        shrink
                      >
                        Zip
                      </InputLabel>
                      <NumberFormat
                        ref={ADDRESS_DATA_STRINGS.ZIP_STRING}
                        name={ADDRESS_DATA_STRINGS.ZIP_STRING}
                        id="nfZipOfficeSetup"
                        value={this.state.zip}
                        onChange={this.handleInputChangeNoValidation}
                        onBlur={this.removeExtraSpaces}
                        style={{
                          width: '12vw',
                          marginRight: '3vw',
                        }}
                        error={!validZip}
                        disableUnderline
                        format="#####"
                        customInput={Input}
                      />
                    </FormControl>
                  </div>
                  <div style={{ float: 'left', marginLeft: '2vw' }}>
                    <FormControl>
                      <InputLabel style={labelWidthStyle} htmlFor="nfOfficePhoneOfficeSetup" shrink>
                        Office Phone
                      </InputLabel>
                      <NumberFormat
                        name="officePhone"
                        id="nfOfficePhoneOfficeSetup"
                        value={this.state.officePhone}
                        onChange={this.handleInputChangeNoValidation}
                        onBlur={this.removeExtraSpaces}
                        style={{
                          width: '22vw',
                          marginRight: '3vw',
                        }}
                        disableUnderline
                        format="(###) ###-####"
                        customInput={Input}
                      />
                    </FormControl>
                  </div>
                  <div>
                    <FormControl>
                      <InputLabel style={labelWidthStyle} htmlFor="nfFaxOfficeSetup" shrink>
                        Fax
                      </InputLabel>
                      <NumberFormat
                        name="fax"
                        id="nfFaxOfficeSetup"
                        value={this.state.fax}
                        onChange={this.handleInputChangeNoValidation}
                        onBlur={this.removeExtraSpaces}
                        style={{
                          width: '22vw',
                          marginRight: '3vw',
                        }}
                        disableUnderline
                        format="(###) ###-####"
                        customInput={Input}
                      />
                    </FormControl>
                  </div>
                  {this.state.isTempEfin && !this.props.officeSetup?.EFINValidated ? (
                    <div style={{ padding: '10px' }}>
                      *You have been assigned a temporary EFIN for new account setup. Please
                      register your official EFIN and contact Technical Support
                    </div>
                  ) : null}
                </div>
              </span>
              <br />
              <div>
                <Divider />
              </div>
              <div>
                <span>
                  <div
                    style={{
                      textAlign: 'left',
                      paddingTop: '3vh',
                      paddingBottom: '1vh',
                    }}
                  >
                    <span
                      style={{
                        fontFamily: 'Roboto',
                        fontSize: '21px',
                        letterSpacing: '0.14px',
                        color: '#1A173B',
                      }}
                    >
                      Return Overrides
                    </span>
                  </div>
                  <div style={{ marginLeft: '1vw' }}>
                    <span
                      style={{
                        fontFamily: 'Roboto',
                        fontSize: '16px',
                        letterSpacing: '0.14px',
                        color: '#1A173B',
                      }}
                    >
                      <b>Note: </b>
                      This information is optional, and if provided will always appear in Forms 1040
                      and/or 8879 and cannot be altered.
                    </span>
                  </div>
                  <FormControl fullWidth>
                    <div className="row" style={{ marginLeft: '3vw', marginBottom: '1vh' }}>
                      <FormControlLabel
                        label="Same as Office Info"
                        control={
                          <Checkbox
                            style={{
                              width: 17,
                              height: 16,
                              marginLeft: '0.2vw',
                              marginRight: '0.4vw',
                            }}
                            icon={
                              <CheckBoxOutlineBlankIcon
                                style={{ fontSize: 24, color: '#8FC3FF' }}
                                color="secondary"
                              />
                            }
                            checkedIcon={<CheckBoxIcon style={{ fontSize: 24 }} color="primary" />}
                            id="chkSameInfoOfficeSetup"
                            name="sameInfo"
                            checked={this.state.sameInfo}
                            onChange={this.handleCheckbox}
                            inputProps={{ height: '20px', width: '20px' }}
                          />
                        }
                      />
                    </div>
                  </FormControl>
                  <div style={{ float: 'left', marginLeft: '2vw' }}>
                    <FormControl required>
                      <InputLabel
                        style={labelWidthStyle}
                        required={false}
                        htmlFor="txtOverridefirmNameOfficeSetup"
                        shrink
                      >
                        Office Name
                      </InputLabel>
                      <Input
                        id="txtOverridefirmNameOfficeSetup"
                        name="overridefirmName"
                        value={
                          this.state.sameInfo ? this.state.firmName : this.state.overridefirmName
                        }
                        onChange={this.handleInputChangeNoValidation}
                        onBlur={this.removeExtraSpaces}
                        style={{
                          width: '40vw',
                          marginRight: '3vw',
                        }}
                        disableUnderline
                        disabled={this.state.sameInfo}
                      />
                    </FormControl>
                  </div>
                  <div style={{ float: 'left', marginLeft: '2vw' }}>
                    <FormControl required>
                      <InputLabel
                        style={labelWidthStyle}
                        required={false}
                        htmlFor="txtOverridefirmAddressOfficeSetup"
                        shrink
                      >
                        Office Address
                      </InputLabel>
                      <Input
                        id="txtOverridefirmAddressOfficeSetup"
                        name="overridefirmAddress"
                        value={
                          this.state.sameInfo
                            ? this.state.firmAddress
                            : this.state.overridefirmAddress
                        }
                        onChange={this.handleInputValidationNoSpecialChars}
                        onBlur={this.removeExtraSpaces}
                        style={{
                          width: '40vw',
                          marginRight: '3vw',
                        }}
                        disableUnderline
                        disabled={this.state.sameInfo}
                      />
                    </FormControl>
                  </div>
                  <div style={{ float: 'left' }}>
                    <FormControl>
                      <InputLabel
                        style={labelWidthStyleShort}
                        required={false}
                        htmlFor="txtOverridesuiteNumOfficeSetup"
                        shrink
                      >
                        Suite #
                      </InputLabel>
                      <Input
                        id="txtOverridesuiteNumOfficeSetup"
                        name="overridesuiteNum"
                        value={
                          this.state.sameInfo ? this.state.suiteNum : this.state.overridesuiteNum
                        }
                        onChange={this.handleInputValidationNoSpecialChars}
                        onBlur={this.removeExtraSpaces}
                        style={{
                          width: '12vw',
                          marginRight: '3vw',
                        }}
                        disableUnderline
                        disabled={this.state.sameInfo}
                      />
                    </FormControl>
                  </div>
                  <div style={{ float: 'left', marginLeft: '2vw' }}>
                    <FormControl>
                      <InputLabel
                        style={labelWidthStyle}
                        required={false}
                        htmlFor="txtOverridecityOfficeSetup"
                        shrink
                      >
                        City
                      </InputLabel>
                      <Input
                        id="txtOverridecityOfficeSetup"
                        name={ADDRESS_DATA_STRINGS.OVERRIDE_CITY_STRING}
                        value={this.state.sameInfo ? this.state.city : this.state.overridecity}
                        onChange={this.handleInputValidationNoSpecialChars}
                        onBlur={this.removeExtraSpaces}
                        style={{
                          width: '25vw',
                          marginRight: '3vw',
                        }}
                        disableUnderline
                        disabled={this.state.sameInfo}
                      />
                    </FormControl>
                  </div>
                  <div style={{ float: 'left' }}>
                    <FormControl>
                      <InputLabel
                        style={labelWidthStyleShort}
                        htmlFor="overridestate-selected"
                        shrink
                      >
                        State
                      </InputLabel>
                      {this.renderStateDropdownSecondPart(STATE_LIST_ADDRESS)}
                    </FormControl>
                  </div>
                  <div style={{ float: 'left' }}>
                    <FormControl>
                      <InputLabel
                        style={labelWidthStyleShort}
                        required={false}
                        htmlFor="nfOverridezipOfficeSetup"
                        shrink
                      >
                        Zip
                      </InputLabel>
                      <NumberFormat
                        name="overridezip"
                        id="nfOverridezipOfficeSetup"
                        value={this.state.sameInfo ? this.state.zip : this.state.overridezip}
                        onChange={this.handleInputChangeNoValidation}
                        onBlur={this.removeExtraSpaces}
                        style={{
                          width: '12vw',
                          marginRight: '3vw',
                        }}
                        disableUnderline
                        disabled={this.state.sameInfo}
                        format="#####"
                        customInput={Input}
                      />
                    </FormControl>
                  </div>
                  <div style={{ float: 'left', marginLeft: '2vw' }}>
                    <FormControl>
                      <InputLabel
                        style={labelWidthStyle}
                        htmlFor="nfOverrideofficePhoneOfficeSetup"
                        shrink
                      >
                        Office Phone
                      </InputLabel>
                      <NumberFormat
                        name="overrideofficePhone"
                        id="nfOverrideofficePhoneOfficeSetup"
                        value={
                          this.state.sameInfo
                            ? this.state.officePhone
                            : this.state.overrideofficePhone
                        }
                        onChange={this.handleInputChangeNoValidation}
                        onBlur={this.removeExtraSpaces}
                        style={{
                          width: '22vw',
                          marginRight: '3vw',
                        }}
                        disableUnderline
                        format="(###) ###-####"
                        customInput={Input}
                        disabled={this.state.sameInfo}
                      />
                    </FormControl>
                  </div>
                  <div style={{ float: 'left' }}>
                    <FormControl>
                      <InputLabel style={labelWidthStyle} htmlFor="nfOverridefaxOfficeSetup" shrink>
                        Fax
                      </InputLabel>
                      <NumberFormat
                        name="overridefax"
                        id="nfOverridefaxOfficeSetup"
                        value={this.state.sameInfo ? this.state.fax : this.state.overridefax}
                        onChange={this.handleInputChangeNoValidation}
                        onBlur={this.removeExtraSpaces}
                        style={{
                          width: '22vw',
                          marginRight: '3vw',
                        }}
                        disableUnderline
                        format="(###) ###-####"
                        customInput={Input}
                        disabled={this.state.sameInfo}
                      />
                    </FormControl>
                  </div>
                  <div>
                    <FormControl fullWidth>
                      <div className="row" style={{ marginLeft: '3vw', marginBottom: '1vh' }}>
                        <FormControlLabel
                          label="Self-Employed"
                          control={
                            <Checkbox
                              style={{
                                width: 17,
                                height: 16,
                                marginLeft: '0.2vw',
                                marginRight: '0.4vw',
                              }}
                              icon={
                                <CheckBoxOutlineBlankIcon
                                  style={{ fontSize: 24, color: '#8FC3FF' }}
                                  color="secondary"
                                />
                              }
                              checkedIcon={
                                <CheckBoxIcon style={{ fontSize: 24 }} color="primary" />
                              }
                              id="chkSelfEmployedOfficeSetup"
                              name="selfEmployed"
                              checked={this.state.selfEmployed}
                              onChange={this.handleCheckbox}
                              inputProps={{ height: '20px', width: '20px' }}
                            />
                          }
                        />
                      </div>
                    </FormControl>
                  </div>
                </span>
              </div>
            </AccessControl>
            <div className="setup-nav-btns">
              <span>
                <Button
                  id="btnPreviousEFileSetup"
                  onClick={() => this.toggleConfirmCancel(true)}
                  color="primary"
                >
                  Cancel
                </Button>
                <AccessControl
                  requiredAction="write"
                  accessLevel="edit_office_setup"
                  disableOnFalse={true}
                >
                  <Button
                    id="btnSaveOfficeSetup"
                    color="primary"
                    onClick={() => this.submitOfficeSetupData(false)}
                    disabled={disableSubmit}
                  >
                    Save
                  </Button>
                </AccessControl>
              </span>
              <AccessControl
                requiredAction="write"
                accessLevel="edit_office_setup"
                disableOnFalse={true}
              >
                <Button
                  id="btnNextOfficeSetup"
                  color="primary"
                  onClick={() => this.submitOfficeSetupData(true)}
                  disabled={disableSubmit}
                >
                  Save &amp; Next
                </Button>
              </AccessControl>
            </div>
            <br />
          </form>
        </Paper>

        {this.state.isVerifyEFINModalOpen && (
          <VerifyEFINModal
            isOpen={this.state.isVerifyEFINModalOpen}
            handleToggle={() =>
              this.setState({ isVerifyEFINModalOpen: !this.state.isVerifyEFINModalOpen })
            }
            EFIN={this.state.verifyEFINField}
            efinID={this.state.efinID}
            loginID={this.props.loginID}
            isFeederOffice={this.state.isFeederOffice}
            onRefresh={() => this.props.loadSetupComponent(REQUIRED_SETUP_PAGES.OFFICE.NAME)}
            isLastSectionToComplete={this.isLastSectionToComplete}
            addFrontendNotification={this.props.addFrontendNotification}
            EFINValidated={this.props.officeSetup?.EFINValidated}
            hasOfficeBeenUpdated={this.props.officeSetupProgressFlags.hasOfficeBeenUpdated}
            onSetupComplete={this.props.onSetupComplete}
            updateOfficeSetupFlag={this.props.updateOfficeSetupFlag}
          />
        )}

        <SimpleDialog
          open={this.state.confirmCancel}
          onClose={() => this.toggleConfirmCancel(false)}
          onConfirm={() => {
            this.toggleConfirmCancel(false);
            this.getInitialConfigState();
          }}
          dialogTitle="Lose Unsaved Changes?"
          contentText="Are you sure you want to undo any pending changes?"
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OfficeSetup));

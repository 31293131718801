export const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  qrCodeModal: {
    height: 'auto',
    width: '660px',
    padding: '0',
    position: 'relative',
    margin: 'auto',
    textAlign: 'center',
    top: '20%',
  },
  resetCodeModal: {
    height: '300px',
    width: '618px',
    padding: '0',
    position: 'relative',
    margin: 'auto',
    textAlign: 'center',
  },
  continueButtonStyle: {
    marginLeft: '1vw',
    backgroundColor: '#0077FF',
    color: '#FFFFFF',
    textTransform: 'capitalize',
    borderRadius: '4',
  },
  spinner: {
    position: 'absolute',
    top: '40%',
    left: '50%',
  },
  inputField: {
    width: '75%',
  },
});

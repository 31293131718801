import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Typography,
  Button,
  Paper,
} from '@material-ui/core';
import { FILING_STATUS } from '~/app/constants.js';

// import styles
import './css/TransferData.css';
import { styles } from './css/TransferData.js';

/**
 * Component that allows a user to transfer prior year tax return data from the previous year to current year.
 *
 * @component
 * @category Returns
 * @subcategory TransferData
 */
class TransferData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowSelected: null,
      returnSelected: 0,
      fixReturnDialog: false,
      remainingStatuses: [1, 2, 3, 4, 5],
    };
  }

  componentDidMount = () => {
    if (this.props.pyReturns.length > 0) {
      this.setState({
        returnSelected: this.props.pyReturns[0].tax_return_id,
        rowSelected: 0,
      });
    } else {
      // Close the modal since we have no prior year returns.
      this.props.onCancelTransferData();
      // If there are any duplicate returns for the current year, we should prompt the user.
      if (this.props.cyReturns != null) {
        this.props.openCurrentYearModal();
      }
    }
  };

  /**
   * Closes the import prior year modal.
   *
   * @function
   * */
  handleCancelClick = () => {
    this.props.onCancelTransferData();
  };

  handleYesClick = () => {
    if (
      this.state.returnSelected.filing_status === 0 ||
      this.state.returnSelected.filing_status === null
    ) {
      // prompt to fix filing status
    }

    this.props.onYesTransfer(this.state.returnSelected);
  };

  handleYesOptionClick = option => {
    this.props.onYesTransferOption(this.state.returnSelected, option);
  };

  handleRowClick = (rtn, index) => {
    this.setState({
      returnSelected: rtn.tax_return_id,
      filingStatus: rtn.filing_status,
      rowSelected: index,
    });
  };

  mapFlstStatus = flst => {
    switch (flst) {
      case 1:
        return FILING_STATUS.SING;
      case 2:
        return FILING_STATUS.MFJ;
      case 3:
        return FILING_STATUS.MFS;
      case 4:
        return FILING_STATUS.HOH;
      case 5:
        return FILING_STATUS.WID;
      default:
        return 'No filing status';
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Paper elevation={5}>
        <Typography variant="body2" id="simple-modal-title" style={{ paddingLeft: '4.5%' }}>
          {this.props.toggleYearToYearOptions ? 'Year-To-Year Data Transfer Options' : 'Import the prior year return?'}
        </Typography>
        <Typography style={{ padding: '4.5%' }}>
          {(() => {
            if (this.props.toggleYearToYearOptions) {
              return (
                <Fragment>
                    Do you want to replace the existing return?<br/>
                    You can: <br/>
                    -Clear the current return and re-transfer,<br/>
                    -Keep the current return and re-load ONLY the state data<br/>
                    -Exit without making any changes.
                </Fragment>
                );
            } else {
                return (
                  <Fragment>
                    The Social Security Number requested exists in a prior year. Import the prior year return?
                  </Fragment>
                );
              }
          })()}
        </Typography>
        <div className="transferdata-scrollbar ">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Filing Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props?.pyReturns?.length &&
                this.props.pyReturns.map((rtn, index) => {
                  return (
                    <TableRow
                      classes={{
                        root: classes.tableRowRoot,
                        selected: classes.tableRowSelected,
                      }}
                      key={index}
                      hover={true}
                      selected={this.state.rowSelected === index}
                      onClick={() => this.handleRowClick(rtn, index)}
                    >
                      <TableCell>{this.mapFlstStatus(rtn.filing_status)}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>

        {(() => {
          if (this.props.toggleYearToYearOptions) {
            return (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  id="btnOKImportReload"
                  classes={{ root: classes.btnOptionsRoot }}
                  variant="contained"
                  color="primary"
                  onClick={() => this.handleYesOptionClick("Reload")}
                >
                  Reload
                </Button>
                <Button
                  id="btnOKImportStateOnly"
                  classes={{ root: classes.btnOptionsRoot }}
                  variant="contained"
                  color="primary"
                  onClick={() => this.handleYesOptionClick("StateOnly")}
                >
                  State Only
                </Button>
                <Button
                  id="btnCancelImport"
                  classes={{ root: classes.btnOptionsNoBorder }}
                  onClick={this.handleCancelClick}
                >
                  Cancel
                </Button>
              </div>
            );
          } else {
            return (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  id="btnCancelImport"
                  classes={{ root: classes.btnOptionsNoBorder }}
                  onClick={this.handleCancelClick}
                >
                  Cancel
                </Button>
                <Button
                  id="btnOKImport"
                  classes={{ root: classes.btnOptionsRoot }}
                  variant="contained"
                  color="primary"
                  onClick={() => this.handleYesClick()}
                >
                  OK
                </Button>
              </div>
            );
          }
        })()}
      </Paper>
    );
  }
}
export default withStyles(styles)(TransferData);

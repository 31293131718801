export const styles = {
  menuItemStyle: {
    paddingTop: '0px',
    paddingBottom: '0px',
    color: '#0077FF',
    backgroundColor: '#FFFFFF',
  },
  menuEntryStyle: {
    paddingTop: '0px',
    paddingBottom: '0px',
    color: '#0077FF',
    paddingLeft: '12px',
    backgroundColor: '#FFFFFF',
  },
  menuEntryDisabled: {
    color: '#b3b3b3',
  },
  nativeSelect: {
    width: '20vw',
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingRight: '0px',
    textAlign: 'left',
  },
  toolbarStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#444A59',
  },
  typographyStyle: {
    color: '#FFFFFF',
    fontFamily: 'Roboto',
    fontSize: '15',
  },
  labelWidthStyle: {
    height: '1.6vh',
    color: '#354052',
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontSize: '14px',
  },
  styleEditLogin: {
    minWidth: '0',
    minHeight: '0',
    height: '15px',
    width: '15px',
    paddingLeft: '10px',
    paddingRight: '10px',
    border: 'none',
    display: 'inline',
    color: '#FFFFFF',
    textAlign: 'right',
  },
  styleNewLogin: {
    minWidth: '0',
    minHeight: '0',
    height: 'auto',
    width: 'auto',
    padding: '.5rem 1rem',
    border: 'none',
    display: 'inline',
  },
  styleButtonsNew: {
    textAlign: 'right',
    padding: '0',
    marginLeft: '0vw',
    marginTop: '1vh',
  },
  styleButtonsEdit: {
    textAlign: 'right',
    padding: '0',
    marginLeft: '0vw',
    marginTop: '10vh',
  },
  inputStyle: {
    width: '20vw',
  },
  inline: {
    width: '20vw',
    display: 'inline',
  },
  checkboxIcon: {
    fontSize: 24,
  },
  styleCheckbox: {
    marginLeft: '0.7rem',
    width: '1.2rem',
    height: '1.2rem',
  },
  styleButton: {
    paddingLeft: '0em',
    paddingRight: '0em',
    minHeight: '0em',
    minWidth: '0em',
    paddingTop: '1em',
    border: 'none',
  },
  updateEmailButton: {
    width: '16vw',
    minHeight: '3.5vh',
    marginRight: '1.5vw',
    padding: '0 0',
    float: 'left',
  },
  cancelButton: {
    width: '9vw',
    minHeight: '3.5vh',
    marginRight: '1.5vw',
    padding: '0 0',
  },
  saveButton: {
    width: '9vw',
    minHeight: '3.5vh',
    padding: '0 0',
  },
  largeItemPadding: {
    padding: '1.1rem',
  },
  smallItemPadding: {
    padding: '0.5rem',
  },
};

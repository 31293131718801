import React, { useEffect } from 'react';
import moment from 'moment';

import HeaderMessage from '~/app/Components/Common/HeaderMessage/HeaderMessage.jsx';
import { useSetState } from '~/app/Utility/customHooks';

/**
 * This maintenance routine component is schedule automatically
 * weekly to show on every Friday
 */
const MaintenanceRoutineMsg = () => {
  const sundayDate = moment().day(7 + 0);
  const maintenceDayString = '2023-11-04';
  const maintenanceDate = moment(maintenceDayString, 'YYYY-MM-DD');

  const [state, setState] = useSetState({
    formatMsgEndDate: sundayDate.format('YYYY-MM-DD 20:30:00'),
    msg: `A routine system maintenance build is scheduled for this ${sundayDate.format(
      'dddd MMMM Do',
    )}, from 1:00 a.m. to 7:00 a.m. Eastern. (** NOT an application release Update.)`,
    isLoaded: false,
  });

  useEffect(() => {
    // Run a check for Maintenance Date.
    const currentTimestamp = moment().valueOf();

    if (currentTimestamp < maintenanceDate.valueOf()) {
      setState({
        formatMsgEndDate: maintenanceDate.format('YYYY-MM-DD 20:00:00'),
        msg: `System Maintenance is scheduled for Saturday, November 4th, from 8pm to 10pm EST. Access to the application will not be available during this time.`,
      });
    }
  }, []);

  return (
    <HeaderMessage
      heading="Scheduled Maintenance"
      message={state.msg}
      endDate={state.formatMsgEndDate}
    />
  );
};

export default MaintenanceRoutineMsg;

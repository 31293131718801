// Remote Document Requests Settings Duck Module
const SET_IS_EDITING_RMD = 'xlinkonline/remoteDocRequests/SETISEDITINGRMD';
const SET_SELECTED_REMOTE_DOC = 'xlinkonline/remoteDocRequests/SETSELECTEDREMOTEDOC';
const RESET_RMD_FORM_FIELDS = 'xlinkonline/remoteDocRequests/RESETRMDFORMFIELDS';
const SET_REMOTE_DOCUMENT_REQUESTS = 'xlinkonline/remoteDocRequests/SETREMOTEDOCUMENTREQUESTS';
const SET_CURRENT_REQUEST_STEP = 'xlinkonline/remoteDocRequests/SETCURRENTREQUESTSTEP';
const SET_CURRENT_PAGE = 'xlinkonline/remoteDocRequests/SETCURRENTPAGE';
const SET_REMOTE_REQUESTS_COUNT = 'xlinkonline/remoteDocRequests/SETREMOTEREQUESTSCOUNT';
const SET_ONCHANGE_RMD_FIELD = 'xlinkonline/remoteDocRequests/SETONCHANGERMDFIELD';
const DELETE_RMD_REQUEST = 'xlinkonline/remoteDocRequests/DELETERMDREQUEST';
const RESET_RMD_STATE = 'xlinkonline/remoteDocRequests/RESETRMDSTATE';

export const initialState = {
  rmdFormFields: {
    subject: '',
    description: '',
    email: '',
    documentTypeID: 0,
    requesteeType: 0, // 1 - taxpayer, 2 - spouse
    remoteDocumentID: 0,
    documentStatus: 1, // 0 - rejected/expired, 1 - Pending, 2 - Completed
    updateDate: '',
    otherEmailCheck: false,
  },
  remoteDocRequestsList: [],
  totalRequestsCount: 0,
  isEditingRMD: false,
  currentRequestStep: 0,
  currentPage: 1,
  isDeleting: false,
};

export const actions = {
  setIsEditingRMD: isEditing => ({
    type: SET_IS_EDITING_RMD,
    isEditing,
  }),
  setSelectedRemoteDoc: fields => ({
    type: SET_SELECTED_REMOTE_DOC,
    fields,
  }),
  setRemoteDocumentRequests: requests => ({
    type: SET_REMOTE_DOCUMENT_REQUESTS,
    requests,
  }),
  resetRMDFormFields: () => ({
    type: RESET_RMD_FORM_FIELDS,
  }),
  setCurrentRequestStep: step => ({
    type: SET_CURRENT_REQUEST_STEP,
    step,
  }),
  setCurrentPage: page => ({
    type: SET_CURRENT_PAGE,
    page,
  }),
  setRemoteRequestsCount: count => ({
    type: SET_REMOTE_REQUESTS_COUNT,
    count,
  }),
  setOnChangeRMDField: (name, value) => ({
    type: SET_ONCHANGE_RMD_FIELD,
    name,
    value,
  }),
  deleteRMDRequest: remoteDocumentID => ({
    type: DELETE_RMD_REQUEST,
    remoteDocumentID,
  }),
  resetRMDRequests: () => ({
    type: RESET_RMD_STATE,
  }),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_IS_EDITING_RMD:
      return {
        ...state,
        isEditingRMD: action.isEditing,
      };
    case SET_SELECTED_REMOTE_DOC:
      return {
        ...state,
        rmdFormFields: {
          subject: action.fields.subject,
          description: action.fields.description,
          email: action.fields.email,
          documentTypeID: action.fields.document_type_id,
          requesteeType: action.fields.requestee_type,
          remoteDocumentID: action.fields.remote_document_id,
          documentStatus: action.fields.document_status,
          updateDate: action.fields.update_date,
        },
      };
    case SET_REMOTE_DOCUMENT_REQUESTS:
      return {
        ...state,
        remoteDocRequestsList: [...action.requests],
      };
    case RESET_RMD_FORM_FIELDS:
      return {
        ...state,
        rmdFormFields: { ...initialState.rmdFormFields },
      };
    case RESET_RMD_STATE:
      return {
        ...initialState,
      };
    case SET_CURRENT_REQUEST_STEP:
      return {
        ...state,
        currentRequestStep: action.step,
      };
    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.page,
      };
    case SET_REMOTE_REQUESTS_COUNT:
      return {
        ...state,
        totalRequestsCount: action.count,
      };
    case SET_ONCHANGE_RMD_FIELD:
      return {
        ...state,
        rmdFormFields: {
          ...state.rmdFormFields,
          [action.name]: action.value,
        },
      };
    case DELETE_RMD_REQUEST:
      return {
        ...state,
        remoteDocRequestsList: [
          ...state.remoteDocRequestsList.filter(
            req => req.remote_document_id !== action.remoteDocumentID,
          ),
        ],
      };
  }
  return state;
}

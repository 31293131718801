import React from 'react';

import { Paper, Button } from '@material-ui/core';
import { styles } from './css/subFormViewer.js';
import { withStyles } from '@material-ui/core/styles';

const SubformViewer = ({ getTaxForm, activeForm, activeFile, classes }) => {
  return (
    <div>
      <Paper classes={{ root: classes.subFormViewerPaper }}>
        <Button
          id="closeSubformButton"
          onClick={() => {
            const parentForm = activeFile.slice(3,6) === 'ovl' ? activeForm : activeForm.slice(0, -1);
            const parentFile = activeFile.slice(3,6) === 'ovl' ? activeFile : activeFile.slice(0, -1);
            const wks = 'wks';
            const fld = '';

            if (parentForm.charAt(0).toUpperCase() === 'Z') {
              getTaxForm(parentForm, '', '', '', false);
            } else {
              getTaxForm(parentForm, wks, parentFile, fld, false);
            }
          }}
          classes={{ root: classes.backToParentFormButton }}
          size="small"
          color="primary"
        >
          {'< Back to Parent Form'}
        </Button>
        <div id="subform" />
      </Paper>
    </div>
  );
};

export default withStyles(styles)(SubformViewer);

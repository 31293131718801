// Internal imports
import AuthAPI from '~/app/api/authAPI.js';

/**
 * @module mfaCodeHelper
 * @category MFACode
 */

/**
 * Submits the one-time password token to the Xlinkcloud.
 *
 * @function
 * @param {string} token - The 2FA token when first registering device.
 * @param {string} fingerprint - The fingerprint of the device.
 * @param {string} mfaMethod - The method of receiving the 2FA token.
 * @param {string} deviceLabel - A label depicting current device being use.
 * @returns {Object} The access token, refresh token and device string.
 */
export const onSubmitOTPT = (
  token,
  fingerprint = undefined,
  mfaMethod = 'text',
  deviceLabel = '',
) => {
  // If a device label is passed in the MFA method for 2FA is to register a device while also authenticating.
  mfaMethod =
    deviceLabel !== '' && mfaMethod !== 'email' && mfaMethod !== 'text'
      ? 'fingerprinted'
      : mfaMethod;
  return AuthAPI.submitOTPToken(token, fingerprint, mfaMethod);
};

// External imports
import React, { useEffect } from 'react';
import {
  Button,
  Divider,
  Grid,
  Input,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core';
import moment from 'moment';
// Internal imports
import { useSetState } from '~/app/Utility/customHooks';
import XlinkAPI from '~/app/api/xlinkAPI';
import ErrorHelpers from '~/app/errorHelpers.js';
// Styling imports
import { styles } from '~/app/Pages/Returns/components/Depreciation/css/depreciationStyles.js';
import { statusOK } from '~/app/webHelpers';

const AddAsset = props => {
  const { classes } = props;
  const [state, setState] = useSetState({
    assetTypes: [],
    assetID: props.assetDetails.newID.slice(-3),
    assetDesc: props.assetDetails.newDesc,
    assetDate:
      props.assetDetails.newSrvDate.substring(4, 6) +
      '/' +
      props.assetDetails.newSrvDate.substring(6, 8) +
      '/' +
      props.assetDetails.newSrvDate.substring(0, 4),
    selectedAsset: {},
    selectedRow: null,
  });

  useEffect(() => {
    handleFetchNewAssetList();
  }, []);

  const handleFetchNewAssetList = async () => {
    try {
      const res = await XlinkAPI.fetchNewAssetList(props.assetDetails.lstFile + '.lst');

      if (statusOK(res)) {
        setState({
          assetTypes: res.data,
        });
      }
    } catch (error) {
      ErrorHelpers.handleError('Unable to fetch assets: ', error);
    }
  };

  const handleTextChange = e => {
    setState({
      [e.target.name]: e.target.value,
    });
  };

  const handleDateChange = e => {
    let newDate = e.target.value;
    newDate = newDate.replace(/[A-Za-z]/, '');
    if (
      newDate.length === 2 &&
      newDate.slice(-1) !== '/' &&
      newDate.length > state.assetDate.length
    ) {
      newDate = newDate + '/';
    }
    if (
      newDate.length === 5 &&
      newDate.slice(-1) !== '/' &&
      newDate.length > state.assetDate.length
    ) {
      newDate = newDate + '/';
    }
    setState({
      assetDate: newDate,
    });
  };

  const handleRowClick = index => {
    if (state.selectedRow === index) {
      setState({
        selectedRow: null,
        selectedAsset: {},
      });
    } else {
      setState({
        selectedRow: index,
        selectedAsset: state.assetTypes[index],
      });
    }
  };

  const addNewAssetClick = (id, type, desc, assetCode) => {
    const calcDate = moment(state.assetDate).format('MMDDYYYY');
    props.addNewAsset(id, type, desc, calcDate, assetCode);
    props.toggleModal(false);
  };

  return (
    <div id="divAddAssetModal">
      <Typography variant="title" id="simple-modal-title" style={{ paddingLeft: '1em' }}>
        Add Asset
      </Typography>
      <div className="addAssetModal">
        <Grid container spacing={8} style={{ margin: '0px' }}>
          <Grid item xs={2} className="addAssetHeader">
            <div className="addAssetLabel">ID</div>
            <Input
              id="addAssetID"
              name="assetID"
              value={state.assetID}
              onChange={handleTextChange}
              style={{ width: '100%', marginLeft: '10px' }}
              disableUnderline
            />
          </Grid>
          <Grid item xs={6} className="addAssetHeader">
            <div className="addAssetLabel">Desc</div>
            <Input
              id="addAssetDesc"
              name="assetDesc"
              value={state.assetDesc}
              onChange={handleTextChange}
              style={{ width: '100%', marginLeft: '10px' }}
              disableUnderline
            />
          </Grid>
          <Grid item xs={4} className="addAssetHeader">
            <div className="addAssetLabel">Date placed in Service</div>
            <Input
              id="addAssetDate"
              name="assetDate"
              inputProps={{ maxLength: 10, pattern: '[0-9/]+' }}
              value={state.assetDate}
              onChange={handleDateChange}
              style={{ marginLeft: '10px', width: '35%' }}
              disableUnderline
            />
          </Grid>
        </Grid>
        <Divider />
        <div style={{ maxHeight: '35vh', overflowY: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow classes={{ root: classes.tableHeadRoot }}>
                <TableCell>Code</TableCell>
                <TableCell>GDS</TableCell>
                <TableCell>ADS</TableCell>
                <TableCell>Mth</TableCell>
                <TableCell>Cnv</TableCell>
                <TableCell>Business Asset Class Codes</TableCell>
                <TableCell>Listed Property</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {state.assetTypes.map((row, i) => {
                return (
                  <TableRow
                    hover={true}
                    classes={{ root: classes.tableRowRoot }}
                    key={i}
                    selected={i === state.selectedRow}
                    onClick={() => handleRowClick(i)}
                  >
                    <TableCell className="noselect" component="th" scope="row">
                      {row.assetCode}
                    </TableCell>
                    <TableCell className="noselect">{row.gds}</TableCell>
                    <TableCell className="noselect">{row.ads}</TableCell>
                    <TableCell className="noselect">{row.mth}</TableCell>
                    <TableCell className="noselect">{row.cnv}</TableCell>
                    <TableCell className="noselect">{row.classCode}</TableCell>
                    <TableCell className="noselect">{row.listed}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        <Divider />
      </div>
      <div
        className="container"
        style={{
          marginTop: '15px',
          paddingBottom: '15px',
          textAlign: 'right',
          position: 'relative',
        }}
      >
        <Button
          id="btnCloseAddAssetModal"
          color="primary"
          size="small"
          style={{ marginRight: '1rem', border: '0px' }}
          onClick={() => props.toggleModal(false)}
        >
          Close
        </Button>
        <Button
          id="btnAddAsset"
          color="primary"
          size="small"
          disabled={state.selectedRow === null}
          onClick={() =>
            addNewAssetClick(
              state.assetID,
              props.assetDetails.newID.substring(4, 7),
              state.assetDesc,
              state.selectedAsset.assetCode,
            )
          }
        >
          Add
        </Button>
      </div>
    </div>
  );
};

export default withStyles(styles)(AddAsset);

export const styles = theme => ({
  root: {
    flexGrow: '1',
  },
  formTitle: {
    position: 'relative',
    left: '-3rem',
    color: '#1A173B',
    fontFamily: 'Roboto',
    fontSize: '1.3rem',
    letterSpacing: '0.01rem',
    marginBottom: '1rem',
  },
  formSubTitle: {
    height: '1.3rem',
    color: '#1A173B',
    fontFamily: 'Roboto',
    fontSize: '1.3rem',
    letterSpacing: '0.01rem',
    lineHeight: '1.5rem',
    margin: '1rem 0',
  },
  formContainer: {
    height: '100%',
    width: '100%',
    paddingBottom: '3rem',
  },
  formBody: {
    width: '100%',
    padding: '1.5rem',
  },
  xsmallFormInput: {
    width: '3.44rem',
    height: '2.19rem',
  },
  smallFormInput: {
    width: '7.5rem',
    height: '2.19rem',
  },
  mediumFormInput: {
    width: '12.5rem',
    height: '2.19rem',
  },
  largeFormInput: {
    width: '20rem',
    height: '2.19rem',
  },
  centerChildStyle: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  dividerStyle: {
    margin: '1rem 0',
  },
  footerContainerStyle: {
    margin: '.5rem 0 1rem 0',
  },
  labelStyle: {
    margin: '0',
    cursor: 'pointer',
  },
  sideLabelStyle: {
    margin: '1rem 0 0 2rem',
    cursor: 'pointer',
  },
  buttonStyle: {
    padding: '0 1rem',
  },
  formBlueLabel: {
    color: '#1880E7',
    FontSize: '.95rem',
    letterSpacing: '0',
  },
  formBlueBtn: {
    color: '#1880E7',
    FontSize: '.95rem',
    letterSpacing: '0',
    marginRight: '1rem',
  },
  hightlightedReadOnly: {
    background: '#f1f3f5',
    padding: '0.5rem',
    margin: '1rem 0',
  },
  dynamicButtonStyle: {
    color: '#1880E7',
    padding: '0.5rem',
    marginBottom: '1rem',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    letterSpacing: '0',
    cursor: 'pointer',
    border: 'none',
  },
});

/* eslint-disable no-unneeded-ternary */
import React, { Component } from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Divider from '@material-ui/core/Divider';
import NumberFormat from 'react-number-format';
import { NativeSelect } from '@material-ui/core';
import { STATE_LIST_ADDRESS, ADDRESS_DATA_STRINGS } from '~/app/constants.js';
import AccessControl from '#/Auth/AccessControl.jsx';

class GenBillingAddress extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  handleSelectedStateChange = value => {
    this.setState({ selectedState: value });
  };

  render() {
    const menuEntryStyle = {
      paddingTop: '0px',
      paddingBottom: '0px',
      color: '#0077FF',
      paddingLeft: '12px',
      backgroundColor: '#FFFFFF',
    };
    const stateList = STATE_LIST_ADDRESS.map(state => {
      return (
        <option id={'optSelect' + state} key={state} value={state} style={menuEntryStyle}>
          {state}
        </option>
      );
    });
    const labelWidthStyle = {
      height: '1.6vh',
      color: '#354052',
      textAlign: 'left',
      fontFamily: 'Roboto',
      fontSize: '14px',
    };
    const labelWidthStyleShort = {
      width: '5vw',
      height: '1.6vh',
      color: '#354052',
      textAlign: 'left',
      fontFamily: 'Roboto',
      fontSize: '14px',
    };
    const overridefirmNameStyle = {
      width: '55vw',
    };
    const overridefirmAddressStyle = {
      width: '43vw',
      marginRight: '2vw',
    };
    const overridesuiteNumStyle = {
      width: '10vw',
      marginRight: '2vw',
    };
    const overridezipStyle = {
      width: '10vw',
      marginRight: '2vw',
    };
    const overridecityStyle = {
      width: '31vw',
      marginRight: '2vw',
    };
    const overridevalidStateStyle = {
      width: '10vw',
      maxWidth: 'auto',
      marginRight: '2vw',
      paddingTop: '0px',
      paddingBottom: '0px',
      paddingRight: '0px',
      textAlign: 'left',
    };
    const overrideofficePhoneStyle = {
      width: '22vw',
      marginRight: '2vw',
    };

    const sameAsOfficeInfo = this.props.sameAsOfficeInfo;
    const firmName =
      this.props.officeAddress.firmName === undefined || this.props.officeAddress.firmName === null
        ? ''
        : this.props.officeAddress.firmName;
    const firmAddress =
      this.props.officeAddress.firmAddress === undefined ||
      this.props.officeAddress.firmAddress === null
        ? ''
        : this.props.officeAddress.firmAddress;
    const suiteNum =
      this.props.officeAddress.suiteNum === undefined || this.props.officeAddress.suiteNum === null
        ? ''
        : this.props.officeAddress.suiteNum;
    const city =
      this.props.officeAddress.city === undefined || this.props.officeAddress.city === null
        ? ''
        : this.props.officeAddress.city;
    const state =
      this.props.officeAddress.state === undefined || this.props.officeAddress.state === null
        ? ''
        : this.props.officeAddress.state;
    const phone =
      this.props.officeAddress.phone === undefined || this.props.officeAddress.phone === null
        ? ''
        : this.props.officeAddress.phone;
    const zipNumber =
      this.props.officeAddress.zip === undefined || this.props.officeAddress.zip === null
        ? ''
        : this.props.officeAddress.zip;

    return (
      <AccessControl requiredAction="write" accessLevel="add/edit_billing" disableOnFalse={true}>
        <div>
          <div
            style={{
              marginLeft: '3vw',
              marginRight: '3vw',
              marginTop: '0.5vh',
            }}
          >
            <div style={{ float: 'none' }}>
              <FormControl>
                <InputLabel style={labelWidthStyle} shrink>
                  Office Name
                </InputLabel>
                <Input
                  id="txtFirmNameGenBillingSame"
                  value={firmName}
                  error={firmName === '' ? true : false}
                  style={overridefirmNameStyle}
                  disableUnderline
                  disabled={sameAsOfficeInfo}
                  onChange={e =>
                    this.props.handleObjectChange(
                      'address',
                      ADDRESS_DATA_STRINGS.FIRM_NAME_STRING,
                      e.target.value,
                    )
                  }
                  onBlur={e =>
                    this.props.handleRemoveExtraSpaces(
                      'address',
                      ADDRESS_DATA_STRINGS.FIRM_NAME_STRING,
                      e.target.value,
                    )
                  }
                />
              </FormControl>
            </div>
            <div style={{ float: 'left' }}>
              <FormControl>
                <InputLabel style={labelWidthStyle} shrink>
                  Office Address
                </InputLabel>
                <Input
                  id="txtFirmAddressGenBillingSame"
                  value={firmAddress}
                  error={firmAddress === '' ? true : false}
                  style={overridefirmAddressStyle}
                  disableUnderline
                  disabled={sameAsOfficeInfo}
                  onChange={e =>
                    this.props.handleObjectChange(
                      'address',
                      ADDRESS_DATA_STRINGS.FIRM_ADDRESS_STRING,
                      e.target.value,
                    )
                  }
                  onBlur={e =>
                    this.props.handleRemoveExtraSpaces(
                      'address',
                      ADDRESS_DATA_STRINGS.FIRM_ADDRESS_STRING,
                      e.target.value,
                    )
                  }
                />
              </FormControl>
            </div>
            <div style={{ float: 'none' }}>
              <FormControl>
                <InputLabel
                  style={labelWidthStyleShort}
                  required={false}
                  htmlFor="txtSuiteNumOfficeSetup"
                  shrink
                >
                  Suite #
                </InputLabel>
                <Input
                  id="txtSuiteNumOfficeSetup"
                  value={suiteNum}
                  onChange={e =>
                    this.props.handleObjectChange(
                      'address',
                      ADDRESS_DATA_STRINGS.SUITE_NUM_STRING,
                      e.target.value,
                    )
                  }
                  onBlur={e =>
                    this.props.handleRemoveExtraSpaces(
                      'address',
                      ADDRESS_DATA_STRINGS.SUITE_NUM_STRING,
                      e.target.value,
                    )
                  }
                  style={overridesuiteNumStyle}
                  disableUnderline
                  disabled={sameAsOfficeInfo}
                />
              </FormControl>
            </div>
            <div style={{ float: 'left' }}>
              <FormControl>
                <InputLabel style={labelWidthStyle} shrink>
                  City
                </InputLabel>
                <Input
                  id="txtCityGenBillingSame"
                  value={city}
                  error={city === '' ? true : false}
                  style={overridecityStyle}
                  disableUnderline
                  disabled={sameAsOfficeInfo}
                  onChange={e =>
                    this.props.handleObjectChange(
                      'address',
                      ADDRESS_DATA_STRINGS.CITY_STRING,
                      e.target.value,
                    )
                  }
                  onBlur={e =>
                    this.props.handleRemoveExtraSpaces(
                      'address',
                      ADDRESS_DATA_STRINGS.CITY_STRING,
                      e.target.value,
                    )
                  }
                />
              </FormControl>
            </div>
            <div style={{ float: 'left' }}>
              <FormControl>
                <InputLabel style={labelWidthStyleShort} shrink>
                  State
                </InputLabel>
                <NativeSelect
                  id="selectDropDownState"
                  value={state}
                  error={state === '' ? true : false}
                  onChange={e =>
                    this.props.handleObjectChange(
                      'address',
                      ADDRESS_DATA_STRINGS.STATE_STRING,
                      e.target.value,
                    )
                  }
                  style={overridevalidStateStyle}
                  disableUnderline
                  disabled={sameAsOfficeInfo}
                >
                  <option value="" />
                  {stateList}
                </NativeSelect>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <InputLabel style={labelWidthStyleShort} shrink>
                  Zip
                </InputLabel>
                <NumberFormat
                  id="txtZipGenBillingSame"
                  value={zipNumber}
                  error={/^\d{5}(?:[-\s]\d{4})?$/.test(this.props.officeAddress.zip) ? false : true} // \d{5} matches 5 digits(0-9) then matches "-" or blank space followed by \d{4} matches 4 digits(0-9) and ? used as quantifier to see if there is a match in the preceding token
                  style={overridezipStyle}
                  format="#####"
                  disableUnderline
                  disabled={sameAsOfficeInfo}
                  onChange={e =>
                    this.props.handleObjectChange(
                      'address',
                      ADDRESS_DATA_STRINGS.ZIP_STRING,
                      e.target.value,
                    )
                  }
                  customInput={Input}
                />
              </FormControl>
            </div>
            <div style={{ float: 'none' }}>
              <FormControl>
                <InputLabel style={labelWidthStyle} shrink>
                  Office Phone
                </InputLabel>
                <NumberFormat
                  id="txtOfficePhoneGenBillingSame"
                  value={phone}
                  style={overrideofficePhoneStyle}
                  disableUnderline
                  disabled={sameAsOfficeInfo}
                  onChange={e =>
                    this.props.handleObjectChange(
                      'address',
                      ADDRESS_DATA_STRINGS.PHONE_STRING,
                      e.target.value,
                    )
                  }
                  format="(###) ###-####"
                  customInput={Input}
                />
              </FormControl>
            </div>
            <br />
            <Divider />
            <br />
          </div>
        </div>
      </AccessControl>
    );
  }
}

export default GenBillingAddress;

import React, { Component } from "react";

/*
Component's purpose is to render AGI information to the dom. 
Initially used for displaying AGI next to FED bal/ref on the tag
return left side navigation
*/

const AGIInformation = ({ agiAmount, agiInfo }) => {
  let color = "#00C44F"; //Green display text by default

  //Calc server will send negatives in parans
  if (agiAmount) {
    if (agiAmount.length > 1 && agiAmount[1] == "(") {
      color = "#FF304A";
    }
    if (!agiAmount.includes("$")) {
      agiAmount = "$" + agiAmount;
    }
  } else {
    agiAmount = "$0";
  }

  let agiInfoHeaderStyle = {
    color: color,
    fontSize: "21px",
    fontFamily: "Roboto",
    lineHeight: "24px",
    marginLeft: "30px"
  };

  let agiInfoFooterStyle = {
    color: "#354052",
    fontFamily: "Roboto",
    fontSize: "12px",
    lineHeight: "14px",
    fontWeight: "300",
    marginLeft: "30px"
  };

  return (
    <div className="agiInformation">
      <div style={agiInfoHeaderStyle}>
        <span>{agiAmount}</span>
      </div>
      <div style={agiInfoFooterStyle} className="">
        <span>{agiInfo}</span>
      </div>
    </div>
  );
};

export default AGIInformation;

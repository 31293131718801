import React from 'react';
// External imports
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Input,
  InputAdornment,
  IconButton,
  Typography,
  withStyles,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
// Internal imports
import xIcon from '~/images/icons/icons8-multiply.png';
import importIcon from '~/images/icons/importIcon.png';
// Styling imports
import { styles } from '~/app/Pages/Returns/components/ImportStockTransactions/css/importStockTransactions.js';
import '~/app/Pages/Returns/components/ImportStockTransactions/css/importStockTransactions.css';

/* 
  Even though we only accept a .csv file we are checking to make 
  sure the MIME type is a valid CSV type as well. 
*/
const validCSVMIMETypes = [
  'text/csv',
  'text/plain',
  'application/csv',
  'application/excel',
  'application/vnd.msexcel',
  'application/vnd.ms-excel',
  'text/comma-separated-values',
];

/**
 * Parent Component for SignatureList and SendSignature.
 *
 * @component
 * @category Returns
 * @subcategory Modals
 */
const ImportStockTransactions = props => {
  const { classes } = props;

  const [file, setFile] = React.useState(null);
  const [fileName, setFileName] = React.useState('');
  const [fileType, setType] = React.useState('');
  const [checked, setChecked] = React.useState(false);

  /**
   * Handles parsing the file and setting the file, file type, and file name into state.
   *
   * @param {Object} e - event which takes place in the DOM.
   * @param {Object} file - The CSV file.
   */
  const handleClientSelectFile = (e, file) => {
    e.preventDefault();
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = function () {
      setFile(reader.result.split(',')[1]);
      setType(file.type);
      setFileName(file.name);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
    if (props.importStockMsg !== '') {
      props.showImportMsg('');
    }
  };

  /** Clears the selected file from the input. */
  const clearSelectedFile = () => {
    setFile(null);
    setFileName('');
    setType('');
    if (props.importStockMsg !== '') {
      props.showImportMsg('');
    }
  };

  /**
   * Handles validating to make sure a file has been uploaded and checks to make sure
   * it is a valid CSV file from the the MIME type. If those checks pass then it will
   * uploaded the CSV file to the tax return.
   */
  const handleUpload = () => {
    props.toggleBtnSpinner(true);

    if (file == null) {
      props.showImportMsg('Select a file for upload');
      clearSelectedFile();
      props.toggleBtnSpinner(false);
    } else if (!validCSVMIMETypes.includes(fileType)) {
      props.showImportMsg('File must be of type .csv');
      clearSelectedFile();
      props.toggleBtnSpinner(false);
    } else {
      props.uploadCSV(file, checked ? 'X' : '');
      clearSelectedFile();
    }
  };

  return (
    <>
      <Typography id="simple-modal-title" variant="body2" className={classes.headerTitle}>
        <img id="importIcon" className="import-stock-transactions-icon" src={importIcon} />
        <span className="import-stock-transactions-header">Import Stock Transactions</span>
      </Typography>
      <div className="import-stock-transactions-body-block">
        <div className="import-stock-transactions-body-title">
          <Typography className={classes.docTitle} variant="title" gutterBottom>
            Import CSV
          </Typography>
          <div className="import-stock-transactions-body-title-btn-block">
            <Button
              id="btnBrowseCSV"
              className={classes.docTitleBtn}
              component="label"
              size="small"
            >
              Browse
              <input
                hidden
                multiple
                type="file"
                accept=".csv"
                onChange={e => handleClientSelectFile(e, e.target.files[0])}
              />
            </Button>
          </div>
        </div>
        <FormControl className={classes.documentInputBlock}>
          <Input
            disableUnderline
            disabled
            placeholder="Upload Document"
            className={classes.documentUploadInput}
            value={fileName}
            onChange={e => this.setState({ image_name: e.target.value })}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  disableRipple
                  classes={{ root: classes.docIconClearBtn }}
                  id="clearInputField"
                  aria-label="clearInput"
                  className={classes.documentUploadInputBtn}
                  onClick={() => clearSelectedFile()}
                >
                  <img id="clearInputField" className="noselect" src={xIcon} />
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText>Upload information as a .csv file</FormHelperText>
        </FormControl>
        {props.menuCommandResp.FLST === '2' && (
          <Grid className={classes.spouseCheckboxBlock}>
            <FormControlLabel
              label="Transactions belong to spouse"
              className={classes.spouseCheckboxText}
              control={
                <Checkbox
                  id="isSpouse"
                  color="secondary"
                  icon={
                    <CheckBoxOutlineBlankIcon
                      className={classes.checkboxOutlineBlankIcon}
                      color="primary"
                    />
                  }
                  checkedIcon={<CheckBoxIcon className={classes.checkboxIcon} color="primary" />}
                  className={classes.secondaryListItemRoot}
                  onClick={() => setChecked(!checked)}
                  checked={checked}
                />
              }
            />
          </Grid>
        )}
        <div className="import-stock-transactions-bottom-block">
          <div className="verticallyCenter import-stock-transactions-message-block">
            {props.importStockMsg !== '' && (
              <Typography variant="body2" className={classes.importErrorMessage}>
                {props.importStockMsg}
              </Typography>
            )}
          </div>
          <div className="import-stock-transactions-btn-block">
            <Button
              className={classes.cancelBtn}
              size="small"
              color="default"
              onClick={() => {
                props.showImportMsg('');
                props.closeModal();
              }}
            >
              Cancel
            </Button>
            <Button
              id="btnImportCSV"
              size="small"
              variant="contained"
              color="primary"
              onClick={e => handleUpload(e)}
              disabled={props.btnClickSpinner}
            >
              Import
            </Button>
            {props.btnClickSpinner && (
              <CircularProgress size={24} className={classes.importButtonSpinner} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

ImportStockTransactions.propTypes = {
  classes: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  showImportMsg: PropTypes.func.isRequired,
  uploadCSV: PropTypes.func.isRequired,
  menuCommandResp: PropTypes.object.isRequired,
  importStockMsg: PropTypes.string.isRequired,
  toggleBtnSpinner: PropTypes.func.isRequired,
  btnClickSpinner: PropTypes.bool.isRequired,
};

export default withStyles(styles)(ImportStockTransactions);

export const styles = {
  blockStyle: {
    width: '7rem',
    height: '3.5vh',
    marginRight: '3vw',
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingLeft: '5px',
  },
  printLabel: {
    height: '1.6vh',
    width: '30vw',
    color: '#354052',
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontSize: '14px',
    marginLeft: '6vw',
    marginTop: '3vh',
  },
  modalHeaderBar: {
    color: 'white',
    fontFamily: 'Roboto',
    fontSize: '18px',
    paddingTop: '2%',
    paddingLeft: '4%',
  },
  headerText: {
    alignSelf: 'flex-end',
  },
  closeIconModal: {
    left: '20px',
    color: '#FFFFFF',
  },
  footerDivider: {
    paddingBottom: '10px',
  },
  settingsModals: {
    width: '600px',
    padding: '0',
    position: 'relative',
    margin: 'auto',
    top: '20%',
  },
  gridTableHeader: {
    fontFamily: 'Roboto',
    fontSize: '15px',
    paddingTop: '25px',
    paddingBottom: '15px',
    marginLeft: '15px',
  },
  justifyRight: {
    textAlign: 'right',
    paddingRight: '10px',
  },
  printListHeader: {
    fontFamily: 'Roboto',
    fontSize: '15px',
    marginLeft: '15px',
  },
  printList: {
    maxHeight: '300px',
    overflowY: 'auto',
    overflowX: 'hidden',
    fontFamily: 'Roboto',
    paddingBottom: '15px',
  },
};

import React, { useEffect } from 'react';
// Internal imports
import { useSetState } from '~/app/Utility/customHooks.js';
import SimpleDialog from '#/Common/SimpleDialog.jsx';
// External imports
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
// Styling imports
import { styles } from '~/app/Pages/Returns/css/formViewerModals.js';


/**
 * K-1 Manager modal used to view, add, delete, email K-1s for applicable business returns
 *
 * @component K-1 Manager
 * @category FormViewer
 */

const K1ManagerModal = props => {
  const { classes } = props;
  const [state, setState] = useSetState({
    pkgSelected: '00', // Federal is always selected on open
    ocrSelected: null, // Occurrence selected
    hideK1Flag: props.hideK1Flag,
    isEmailBtnDisabled: true,
    isDeleteK1ModalOpen: false,

    // delete confirmation modal
    selectedId: '',
    selectedOwner: '',
  });

  useEffect(() => {
    // Get initial Fed K-1 list. '00' is the Fed param just like in desktop code for :MGRFILL
    props.handleListK1s('00');
  }, []);

  const handleHighlight = (selected, type) => {
    let isHighlighted = false;
    switch(type) {
    case 'k1':
      if (selected === state.ocrSelected)
        isHighlighted = true;
      break;
    case 'pkg':
      if (selected === state.pkgSelected)
        isHighlighted = true;
      break;
    }
    return isHighlighted;
  };

  const handleK1RowClick = (selected, emailable, id, owner) => {
    setState({
      ocrSelected: selected,
      isEmailBtnDisabled: emailable !== 'X',
      selectedId: id,
      selectedOwner: owner,
    });
  };

  const handlePkgRowClick = selected => {
    if (state.pkgSelected !== selected) {
      // Prevent reloading of pkg thats already selected
      setState({
        pkgSelected: selected,
        ocrSelected: null,
        isEmailBtnDisabled: true,
      });
      props.handleListK1s(selected); // Use selected pkg to regen the list. Synch's with desktop code to interface with :MGRFILL
    }
  };

  const handleRowDoubleClick = async ocr => {
    props.navToK1(ocr);
    props.toggleModal(false);
  };

  const handleAddK1 = () => {
    props.handleAddK1(state.pkgSelected);
    props.toggleModal(false); // following desktop behavior
  };

  const handleDeleteK1 = () => {
    if (state.ocrSelected) {
      props.handleDeleteK1(state.ocrSelected);
    }
  };

  const toggleDeleteK1Modal = toggle => {
    setState({
      isDeleteK1ModalOpen: toggle,
    });
  };

  const handleEmailK1 = () => {
    if (state.ocrSelected) {
      props.handleEmailK1(state.ocrSelected);
      props.toggleModal(false); // following desktop behavior
    }
  };

  return (
    <div>
      <Paper elevation={5} style={styles.k1ManagerModal}>
        <SimpleDialog
          styled
          open={state.isDeleteK1ModalOpen}
          onConfirm={() => {
            toggleDeleteK1Modal(false);
            handleDeleteK1();
          }}
          onClose={() => toggleDeleteK1Modal(false)}
          dialogTitle="Delete K-1 form?"
          contentText={`You are about to delete the following K-1: ID: ${state.selectedId} Owner: ${state.selectedOwner} `}
          confirmText="Ok"
        />
      </Paper>
      <div>
        <Typography id="simple-modal-title" className={classes.modalTitle}>
          K-1 Manager
        </Typography>
      </div>
      <div className="rowC" style={{ height: '350px', 'overflowY': 'auto' }}>
        <div className="pkgList" style={{ 'overflowY': 'scroll' }}>
          <Table style={{ tableLayout: 'fixed' }}>
            <TableBody>
              {props.k1pkgList.map((pkg, index) => {
                return (
                  <TableRow
                    key={index}
                    selected={handleHighlight(pkg.state.stateCode, 'pkg')}
                    onClick={() => handlePkgRowClick(pkg.state.stateCode)}
                    style={{ height: '48px' }}
                  >
                    <TableCell>{pkg.state.name}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        <Table style={{ height: 'fit-content' }}>
          <TableHead>
            <TableRow className={classes.tableHeadRoot}>
              <TableCell className={classes.columnHeaders}>ID</TableCell>
              <TableCell className={classes.columnHeaders}>Owner</TableCell>
              <TableCell className={classes.columnHeaders}>SSN/EIN</TableCell>
              <TableCell className={classes.columnHeaders}>Round</TableCell>
              <TableCell className={classes.columnHeaders}>Emailable</TableCell>
              <TableCell className={classes.columnHeaders}>Emailed</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.k1List.length === 0 ? (
              <TableRow key={'noresult'}>
                <TableCell component="th" scope="row" colSpan="6" style={{ textAlign: 'center' }}>
                  No Results
                </TableCell>
              </TableRow>
            ) : (
              props.k1List.map((formlist, index) => {
                return (
                  <TableRow
                    key={formlist.k1.lviId}
                    selected={handleHighlight(formlist.k1.lviId, 'k1')}
                    onClick={() =>
                      handleK1RowClick(
                        formlist.k1.lviId,
                        formlist.k1.emailable,
                        formlist.k1.id,
                        formlist.k1.owner,
                      )
                    }
                    onDoubleClick={() => handleRowDoubleClick(formlist.k1.lviId)}
                    style={{ height: '38px' }}
                  >
                    <TableCell component="th" scope="row" size="small">
                      {formlist.k1.id}
                    </TableCell>
                    <TableCell size="small">{formlist.k1.owner}</TableCell>
                    <TableCell size="small">{formlist.k1.ssnein}</TableCell>
                    <TableCell size="small">{formlist.k1.round}</TableCell>
                    <TableCell size="small">{formlist.k1.emailable}</TableCell>
                    <TableCell size="small">{formlist.k1.emailed}</TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </div>
      <div className={`rowC container ${classes.footerContainer}`} style={{ marginTop: '0px' }}>
        <Grid item xs={6}>
          <FormControl style={{ float: 'left' }}>
            <FormControlLabel
              label="Hide K-1's in the attached forms list"
              style={{ float: 'left' }}
              control={
                <Checkbox
                  icon={
                    <CheckBoxOutlineBlankIcon
                      style={{
                        fontSize: 24,
                        color: '#8FC3FF',
                      }}
                      color="secondary"
                    />
                  }
                  checkedIcon={<CheckBoxIcon style={{ fontSize: 24 }} color="primary" />}
                  id="hidek1sCheckBox"
                  checked={props.hideK1Flag}
                  onClick={() => props.handleChangeHideK1s(!props.hideK1Flag)}
                  inputProps={{ height: '20px', width: '20px' }}
                />
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Button
            id="btnClose"
            color="primary"
            size="small"
            className={classes.k1Btn}
            onClick={() => props.toggleModal(false)}
          >
            Close
          </Button>
          <Button
            id="btnAdd"
            color="primary"
            size="small"
            variant="contained"
            className={classes.k1Btn}
            onClick={() => toggleDeleteK1Modal(true)}
          >
            Delete
          </Button>
          <Button
            id="btnAdd"
            color="primary"
            size="small"
            variant="contained"
            className={classes.k1Btn}
            onClick={() => handleAddK1()}
          >
            Add
          </Button>
          <Button
            id="btnEmail"
            color="primary"
            size="small"
            variant="contained"
            className={classes.k1Btn}
            onClick={() => handleEmailK1()}
            disabled={state.isEmailBtnDisabled}
          >
            E-mail
          </Button>
        </Grid>
      </div>
    </div>
  );
};

export default withStyles(styles)(K1ManagerModal);

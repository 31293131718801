export const styles = {
  menuItemStyle: {
    paddingTop: '0px',
    paddingBottom: '0px',
    color: '#0077FF',
    backgroundColor: '#FFFFFF',
  },
  toolbarStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#444A59',
  },
  typographyStyle: {
    color: '#FFFFFF',
    fontFamily: 'Roboto',
    fontSize: '15',
  },
  labelWidthStyle: {
    height: '1.6vh',
    color: '#354052',
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontSize: '14px',
  },
  styleButtonsNew: {
    textAlign: 'right',
    padding: '0',
    marginLeft: '0vw',
    marginTop: '1vh',
  },
  cancelButton: {
    width: '9vw',
    minHeight: '3.5vh',
    marginRight: '1.5vw',
    padding: '0 0',
  },
  saveButton: {
    width: '9vw',
    minHeight: '3.5vh',
    padding: '0 0',
  },
  checkBoxStyle: {
    width: '1rem',
    height: '1rem',
    marginLeft: '0.8vw',
    marginRight: '0.4vw',
  },
  copyCheckBoxContainer: {
    margin: '1vh 0 2vh 0',
  },
  checkBoxPadding: {
    padding: '0px 12px',
  },
  subTitleStyle: {
    fontWeight: 500,
    fontSize: '13px',
    marginTop: '1.5vh',
    display: 'block',
  },
  formControlRoot: {
    marginRight: '24px',
  },
  copySelectDropdown: {
    minWidth: '300px',
  },
  copyOfficeModal: {
    marginLeft: '3vw',
    marginRight: '3vw',
    marginTop: '2vh',
  },
  modalAlertBanner: {
    padding: '15px 20px',
    borderRadius: '5px',
    backgroundColor: 'rgba(255, 204, 17, 0.25)',
    margin: '15px 0',
  },
  copyOfficeModalContainer: {
    width: '50rem',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
};

// eslint-disable-next-line no-unused-vars
import React from 'react';
import { HIERARCHY_TYPE } from '~/app/constants.js';
// eslint-disable-next-line no-unused-vars
import { NavLink } from 'react-router-dom';

import impersonationIcon from '~/images/icons/impersonate-icon.png';

const BreadCrumbs = ({
  handleClick,
  fields,
  showFieldAsLinks,
  passClassName,
  onClickTrainingMode,
  isInTrainingMode,
  demoAccountFlag,
  displayName,
  impersonation,
}) => {
  const handleBreadcrumbClick = idx => {
    if (showFieldAsLinks) {
      handleClick(idx);
    }
  };

  const getBreadcrumbLabel = field => {
    switch (field.role) {
      case HIERARCHY_TYPE.ADMIN:
        return 'Admin';
      case HIERARCHY_TYPE.SSB:
        return `Reseller ${field.name}`;
      case HIERARCHY_TYPE.SB:
        return `SB ${field.name}`;
      case HIERARCHY_TYPE.FRANCHISE:
        return `Multi-Office ${field.name}`;
      case HIERARCHY_TYPE.EFIN:
        return `Office ${field.name}`;
      case HIERARCHY_TYPE.PREPARER:
        return `Preparer ${field.name}`;
      case HIERARCHY_TYPE.CLERK:
        return `Clerk ${field.name}`;
      case HIERARCHY_TYPE.TECH_SUPPORT:
        return `Tech-Support ${field.name}`;
      default:
        return `??? ${field.name}`;
    }
  };

  const getBaseBreadcrumbLabel = () => {
    if (impersonation && fields.length === 1) {
      return 'Log out of ' + displayName;
    } else if (
      (demoAccountFlag && fields.length >= 2) ||
      (isInTrainingMode && fields.length >= 2)
    ) {
      return 'Exit To My Dashboard';
    } else if (isInTrainingMode || demoAccountFlag) {
      return;
    }
    return 'Exit To My Dashboard';
  };

  const fieldList = ((fields != null && fields.length > 1) ||
    (fields != null && impersonation) ||
    isInTrainingMode) && (
    <ul className={passClassName}>
      {impersonation && (
        <li className="breadcrumb-list-item">
          <img height="20" width="20" src={impersonationIcon} />
        </li>
      )}
      {fields &&
        fields.map((field, i) => (
          <li
            id={`breadcrumbLink${i}`}
            key={i}
            onClick={() => handleBreadcrumbClick(i)}
            className="breadcrumb-list-item"
          >
            {!isInTrainingMode && (
              <a to="dashboard" className="breadcrumb-demo-white">
                {showFieldAsLinks ? (
                  <span
                    className={
                      i === fields.length - 1 && !impersonation
                        ? 'breadcrumb-nolink-nounderline'
                        : 'breadcrumb-link'
                    }
                  >
                    {i === 0 ? getBaseBreadcrumbLabel() : getBreadcrumbLabel(field)}
                  </span>
                ) : (
                  <span className={'breadcrumb-nolink-nounderline'}>
                    {getBreadcrumbLabel(field)}
                  </span>
                )}
              </a>
            )}
            {isInTrainingMode && (
              <a to="dashboard" className="breadcrumb-demo-black">
                {showFieldAsLinks ? (
                  <span
                    className={
                      i === fields.length - 1 && !impersonation
                        ? 'breadcrumb-nolink-nounderline'
                        : 'breadcrumb-link'
                    }
                  >
                    {i === 0 ? getBaseBreadcrumbLabel() : getBreadcrumbLabel(field)}
                  </span>
                ) : (
                  <span className={'breadcrumb-nolink-nounderline'}>
                    {getBreadcrumbLabel(field)}
                  </span>
                )}
              </a>
            )}
            {i !== fields.length - 1 && <span> / </span>}
          </li>
        ))}
      {isInTrainingMode && (
        <div className="breadcrumb-div">
          {`${demoAccountFlag ? 'DEMO VERSION |' : ''} Training Mode | Limited Functionality |`}
          <span onClick={onClickTrainingMode} className="breadcrumb-cursor">
            <span id="breadcrumbExit" className="breadcrumb-link">
              Exit
            </span>{' '}
            x
          </span>
        </div>
      )}
    </ul>
  );

  return <div className="breadcrumbs">{fieldList}</div>;
};

export default BreadCrumbs;

import React from 'react';
import { AppBar, Button, Toolbar, Typography } from '@material-ui/core';

const GenericTitleBar = ({
  headerImage,
  headerText,
  cancelText,
  confirmText,
  cancelButtonAction,
  confirmButtonAction,
  enableCreateButton,
  isDrilledDown,
  demoAccountFlag,
}) => {
  const toolbarColor = { backgroundColor: '#ffffff' };
  const cancelButtonStyle = { color: '#0077FF', textTransform: 'capitalize' };
  const continueButtonStyle = {
    marginLeft: '1vw',
    // backgroundColor: "#0077FF",
    // color: "#FFFFFF",
    textTransform: 'capitalize',
    borderRadius: '4',
  };
  const topStyle = isDrilledDown || demoAccountFlag ? { paddingTop: '1rem', color: '#0077FF' } : {};

  return (
    <AppBar position="static" style={topStyle}>
      <Toolbar style={toolbarColor}>
        <img src={headerImage} />
        <span className="col ovLabel">
          <Typography variant="title" style={{ marginTop: '.8rem' }}>
            {headerText}
          </Typography>
        </span>
        <div>
          <Button style={cancelButtonStyle} onClick={() => cancelButtonAction()}>
            {cancelText}
          </Button>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            style={continueButtonStyle}
            onClick={() => confirmButtonAction()}
            disabled={!enableCreateButton && confirmText === 'Create'}
          >
            {confirmText}
          </Button>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default GenericTitleBar;

// External Imports
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
// Internal Imports
import { useSetState } from '~/app/Utility/customHooks';
import WebHelpers from '~/app/webHelpers.js';
import CurrentYearTransfer from '~/app/Pages/TaxReturns/components/CurrentYearTransfer/CurrentYearTransfer.jsx';
import TenFourtyReturn from '~app/Pages/TaxReturns/components/AddNewReturn/1040Return/1040NewReturn.jsx';
import BusinessReturn from '~app/Pages/TaxReturns/components/AddNewReturn/BusinessReturn/BusinessNewReturn.jsx';
import multiply from '~/images/icons/icons8-multiply.png';
// Redux Imports
import { useDispatch, useSelector } from 'react-redux';
import { actions as returnListActions } from '~/app/redux/returnList/duck';
// Styling imports
import '~app/Pages/TaxReturns/components/AddNewReturn/css/addNewReturn.css';

const ADD_BUTTON_NORMAL = 'Create Regular Return';
const ADD_BUTTON_WORKING = 'Checking...';

const AddNewReturn = props => {
  const dispatch = useDispatch();
  const payload = WebHelpers.getJWTPayload();

  const { existingIdentifier, cyReturns, blindEntryNewReturn } = useSelector(state => ({
    existingIdentifier: state.returnList.existingIdentifier,
    cyReturns: state.returnList.cyReturns,
    blindEntryNewReturn: state.defaultsPageState.blindEntryNewReturn,
  }));

  const [state, setState] = useSetState({
    ssn: '',
    ssnConfirm: '',
    errorMessage: '',
    readyToSubmit: false,
    addButtonText: ADD_BUTTON_NORMAL,
    return: [],
    isWizard: false,
    isBusiness: false,
  });

  useEffect(() => {
    return () => {
      dispatch(returnListActions.setExistingSSNEIN(false));
    };
  }, []);

  useEffect(() => {
    setState({
      isBusiness: payload?.is_business,
    });
  }, [payload?.is_business]);

  const cleanSSN = ssn => {
    return ssn.replace(/-/g, '');
  };

  /**
   * Handles setting the wizard return indicator boolean state
   */
  const setIsWizard = flag => {
    setState({
      isWizard: flag,
    });
  };

  const setSSNConfirm = ssn => {
    setState({
      ssnConfirm: ssn,
    });
  };

  const onCancelCurrentYear = () => {
    props.onCloseAddNewReturn();
    dispatch(returnListActions.setExistingSSNEIN(false));
    setIsWizard(false);
  };

  /** Handles the confirming creating a return if the users SSN has other returns for that year. */
  const confirmCreateNewRtn = () => {
    const ssn = cleanSSN(state.ssnConfirm);
    setState({
      addButtonText: ADD_BUTTON_WORKING,
      readyToSubmit: false,
    });
    props.onAddNewReturn(cleanSSN(ssn), false, state.isWizard); // Do not check for dupe returns if they are confirming to continue from the current year transfer modal
  };

  const handleCancelClick = e => {
    e.preventDefault();
    props.onCloseAddNewReturn();
  };

  if (existingIdentifier !== true) {
    return (
      <div className="new-return-modal">
        <div className="modal-header-bar">
          <div className="row addNewReturn-header-row">
            <div>Create New Return</div>
            <img onClick={handleCancelClick} src={multiply} className="addNewReturn-multiply-img" />
          </div>
        </div>
        {state.isBusiness ? (
          <BusinessReturn
            onAddNewReturn={(ein, dupCheck, entityType) =>
              props.onAddNewReturn(ein, dupCheck, false, entityType)
            }
            isFormViewerLite={props.isFormViewerLite}
            blindEntryNewReturn={blindEntryNewReturn}
          />
        ) : (
          <TenFourtyReturn
            onAddNewReturn={(ssn, dupCheck, wizReturn) =>
              props.onAddNewReturn(ssn, dupCheck, wizReturn)
            }
            setSSNConfirm={setSSNConfirm}
            isFormViewerLite={props.isFormViewerLite}
            blindEntryNewReturn={blindEntryNewReturn}
          />
        )}
      </div>
    );
  } else {
    return (
      <CurrentYearTransfer
        onCancelCurrentYear={onCancelCurrentYear}
        confirmCreateNewRtn={confirmCreateNewRtn}
        isWizard={state.isWizard}
        setIsWizard={setIsWizard}
        currentYearReturns={cyReturns}
        isBusiness={state.isBusiness}
        ssn={cleanSSN(state.ssnConfirm)}
      />
    );
  }
};

export default withRouter(AddNewReturn);

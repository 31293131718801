export const FETCH_RESTRICTED_FIELDS_SUCCESS =
  'xlinkonline/restrictedfields/FETCH_RESTRICTED_FIELDS_SUCCESS';
export const FETCH_RESTRICTED_FIELDS_FAILED =
  'xlinkonline/restrictedfields/FETCH_RESTRICTED_FIELDS_FAILED';
export const CLEAR_RESTRICTED_FIELDS = 'xlinkonline/restrictedfields/CLEAR_RESTRICTED_FIELDS';
export const ADD_RESTRICTED_FIELD = 'xlinkonline/restrictedfields/ADD_RESTRICTED_FIELD';
export const DELETE_RESTRICTED_FIELD = 'xlinkonline/restrictedfields/DELETE_RESTRICTED_FIELD';
export const UPDATE_RESTRICTED_FIELD = 'xlinkonline/restrictedfields/UPDATE_RESTRICTED_FIELD';

export const initialState = {
  rows: [],
};

export const actions = {
  fetchRestrictedFieldsSuccess: rows => ({
    type: FETCH_RESTRICTED_FIELDS_SUCCESS,
    rows,
  }),
  fetchRestrictedFieldsError: () => ({
    type: FETCH_RESTRICTED_FIELDS_FAILED,
  }),
  clearRestrictedFields: () => ({
    type: CLEAR_RESTRICTED_FIELDS,
  }),
  addRestrictedField: row => ({
    type: ADD_RESTRICTED_FIELD,
    row,
  }),
  deleteRestrictedField: field => ({
    type: DELETE_RESTRICTED_FIELD,
    field,
  }),
  updateRestrictedField: row => ({
    type: UPDATE_RESTRICTED_FIELD,
    row,
  }),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_RESTRICTED_FIELDS_SUCCESS:
      return {
        ...state,
        rows: action.rows,
      };
    case FETCH_RESTRICTED_FIELDS_FAILED:
      return {
        ...state,
        rows: [],
      };
    case CLEAR_RESTRICTED_FIELDS:
      return {
        ...state,
        rows: [],
      };
    case ADD_RESTRICTED_FIELD:
      return {
        ...state,
        rows: [...state.rows, action.row],
      };
    case DELETE_RESTRICTED_FIELD:
      return {
        ...state,
        rows: state.rows.filter(row => {
          return row.field !== action.field;
        }),
      };
    case UPDATE_RESTRICTED_FIELD:
      return {
        ...state,
        rows: [
          ...state.rows.map(row => {
            if (row?.field === action?.row?.field) {
              return action.row;
            }
            return row;
          }),
        ],
      };
  }
  return state;
}

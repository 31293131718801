// External imports
import React, { useEffect } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { Button, Fade, Tooltip, Paper, withStyles } from '@material-ui/core';
// Internal imports
import { useSetState } from '~/app/Utility/customHooks';
import PreviewTermsAndConditions from '~/app/Pages/TermsAndConditions/PreviewTermsAndConditions.jsx';
import AuthAPI from '~/app/api/authAPI.js';
import XlinkAPI from '~/app/api/xlinkAPI';
import { statusOK } from '~/app/webHelpers.js';
import { LOGIN_SETTINGS } from '~/app/constants';
import ErrorHelpers from '~/app/errorHelpers.js';
import { webSocket } from '~/app/Utility/socketConnection.js';
// Redux imports
import { useDispatch, useSelector } from 'react-redux';
import { actions as appActions } from '~/app/redux/modules/app';
import { actions as loginSetupActions } from '~/app/redux/loginSetup/duck';
// Styling imports
import '~/app/Pages/TermsAndConditions/css/termsAndConditions.css';
import { styles } from '~/app/Pages/TermsAndConditions/css/termsAndConditions.js';

/**
 * Component that displays the Terms and Conditions as a scrollable PDF document
 * which the user will need to accept to login.
 *
 * @component
 * @category TermsAndConditions
 */
const TermsAndConditions = props => {
  const { classes } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { isAuthenticated, logo } = useSelector(state => ({
    isAuthenticated: state.app.isAuthenticated,
    logo: state.cobrand.logo,
  }));
  const [state, setState] = useSetState({
    hasRead: false,
    isPDFOpen: false,
  });

  useEffect(() => {
    if (isAuthenticated) {
      webSocket.services();
      history.push({ pathname: '/dashboard' });
    }
  }, [isAuthenticated]);

  const onAcceptTermsAndConditions = async () => {
    // Make a call out to record acceptance
    try {
      const res = await XlinkAPI.acceptTermsAndConditions();
      if (statusOK(res, false)) {
        // Update our session variable (allows refresh of auth token in authAPI)
        sessionStorage.setItem(LOGIN_SETTINGS.SHOW_TERMS_AND_CONDITIONS, false);

        const showMsg = sessionStorage.getItem(LOGIN_SETTINGS.SHOW_IRS_MESSAGE);
        if (showMsg === 'true') {
          history.push({ pathname: '/irs-message' });
        } else {
          dispatch(loginSetupActions.onFinalizeLogin());
        }
      }
    } catch (err) {
      ErrorHelpers.handleError('Login Error', err);
    }
  };

  const onClickAccountLogOutLink = () => {
    // If chat window is open, inform user to close chat window first.
    if (document.getElementById('lpChat')) {
      setState({ chatWindowExist: true });
    } else {
      // Once chat window is close, then we can log out.
      AuthAPI.logout().finally(() => {
        dispatch(appActions.onLogout());
        props.history.push({
          pathname: '/',
        });
      });
    }
  };

  const handleTooltipTitle = () =>
    !state.hasRead ? 'You must click the link and view the Terms and Conditions to continue' : '';

  return (
    <div className="terms-and-conditions-container">
      <Paper className={classes.termsAndConditions}>
        <div className="terms-and-conditions-body">
          <img src={logo} className="logoCrosslink" />
          <div>Click the link below to view the Terms and Conditions</div>
          <div className="terms-and-conditions-link" onClick={() => setState({ isPDFOpen: true })}>
            Terms and Conditions
          </div>
        </div>
        <div className="terms-and-conditions-btn-block">
          <Button
            id="btnContinueTermsAndConditions"
            size="medium"
            color="primary"
            type="submit"
            className={classes.logOutBtn}
            onClick={onClickAccountLogOutLink}
          >
            Log Out
          </Button>
          <Tooltip
            title={handleTooltipTitle()}
            classes={{ tooltip: classes.tooltip }}
            TransitionComponent={Fade}
            enterDelay={300}
            leaveDelay={200}
          >
            <span className="terms-and-conditions-accept-btn-span">
              <Button
                variant="contained"
                color="primary"
                size="medium"
                type="submit"
                className={classes.acceptBtn}
                onClick={onAcceptTermsAndConditions}
                disabled={!state.hasRead}
              >
                Accept Terms of Service
              </Button>
            </span>
          </Tooltip>
        </div>
        <PreviewTermsAndConditions
          isPDFOpen={state.isPDFOpen}
          toggleModal={() => setState({ isPDFOpen: !state.isPDFOpen, hasRead: true })}
        />
      </Paper>
    </div>
  );
};

export default withRouter(withStyles(styles)(TermsAndConditions));

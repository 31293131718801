import React, { Fragment } from 'react';
import { Link, useHistory, withRouter } from 'react-router-dom';
// External imports
import { Button, FormControl, Input, InputLabel, Paper, Typography } from '@material-ui/core';
// Internal imports
import AuthAPI from '~/app/api/authAPI.js';
import ErrorHelpers from '~/app/errorHelpers.js';
import Spinner from '#/Common/Spinner.jsx';
import { SPINNER_DIALOGS } from '~/app/constants.js';
import { useSetState } from '~/app/Utility/customHooks';
import SimpleDialog from '#/Common/SimpleDialog.jsx';
import { displayLogo } from '~/app/Utility/general';
// Redux imports
import { useDispatch, useSelector } from 'react-redux';
import { actions as appActions } from '~/app/redux/modules/app';
// Styling imports
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import appTheme from '~/themes/GenericTheme.jsx';
import { styles } from '~/app/Pages/ForgotUsername/css/forgotUsername.js';
import '~/app/Pages/ForgotUsername/css/forgotUsername.css';

/**
 * Component that sends a user the username(s) that is/are attached to the entered email address.
 *
 * @component
 * @category ForgotUsername
 */
const ForgotUsername = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { classes } = props;

  const { alert } = useSelector(state => ({
    alert: state.app.alert,
  }));

  const [state, setState] = useSetState({
    email: '',
    showConfirmModal: false,
    sendingUsername: false,
  });

  /**
   * Sets the state for the email entered by the user.
   *
   * @param {Object} e - event which takes place in the DOM.
   */
  const handleEmailChange = e => {
    setState({ email: e.target.value });
  };

  /**
   * Sends the username(s) by email that is/are attached to the entered email address.
   *
   * @param {Object} e - event which takes place in the DOM.
   * @returns {undefined} If there is no email present.
   */
  const handleSubmit = async e => {
    e.preventDefault();

    if (state.email === '') {
      return;
    }

    try {
      setState({ sendingUsername: true });
      await AuthAPI.sendUsernameToEmail(state.email);
    } catch (err) {
      // Not showing if unauthorized error since we don't want the user to know the email doesn't exist
      err.response.status !== 401 && ErrorHelpers.handleError('Login Error', err);
    }

    setState({ sendingUsername: false, showConfirmModal: true });
  };

  /** Disables the Send Email button if input is not a valid email address. */
  const handleDisablingSendEmail = () =>
    !/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([A-Za-z]{2,6}(?:\.[A-Za-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/.test(
      state.email,
    );

  return (
    <MuiThemeProvider theme={appTheme}>
      <div className="container-fluid" id="mfaEnterCodeContainer">
        <Paper className={classes.paper}>
          <div className="container forgot-username-content-block">
            <img className="forgot-username-logo" src={displayLogo()} />
            <div className="container">
              <form className="forgotUserPassForm forgot-password-form" onSubmit={handleSubmit}>
                <Typography>
                  Click Send Email to send your Username to your Email address.
                </Typography>
                <div className="forgot-password-email-block">
                  <FormControl fullWidth>
                    <InputLabel htmlFor="txtUsernameFUN" required={false} shrink>
                      Email Address
                    </InputLabel>
                    <Input
                      required
                      id="txtUsernameFUN"
                      value={state.email}
                      onChange={handleEmailChange}
                      disableUnderline
                      autoComplete="off"
                      error={handleDisablingSendEmail()}
                    />
                  </FormControl>
                </div>
                <FormControl fullWidth required className={classes.btnBlock}>
                  <div id="loginButtonDivFUN" className="loginButton">
                    <Link to="/">
                      <Typography
                        id="typBackToLogInFUN"
                        className={classes.backToLogInBtn}
                        color="primary"
                      >
                        Back to Log In
                      </Typography>
                    </Link>
                    <Button
                      id="btnSubmitFUN"
                      variant="contained"
                      type="submit"
                      color="primary"
                      className={classes.sendEmailBtn}
                      disabled={handleDisablingSendEmail()}
                    >
                      Send Email
                    </Button>
                  </div>
                </FormControl>
              </form>
            </div>
          </div>
        </Paper>
        <SimpleDialog
          open={state.showConfirmModal}
          onConfirm={() => {
            history.push({ pathname: '/' });
          }}
          dialogTitle="EMAIL SENT"
          contentText="If the information you provided is correct, an email will be sent to you shortly"
          confirmText="Done"
          styled
        />
        {state.sendingUsername ? (
          <div className={classes.spinner}>
            <Spinner
              size={125}
              loadingText={SPINNER_DIALOGS.PROCESS_REQUEST}
              textColor="white"
              bgColor="grey"
            />
          </div>
        ) : (
          <Fragment />
        )}
      </div>
      <SimpleDialog
        open={alert.show}
        onConfirm={() => dispatch(appActions.hideError())}
        dialogTitle={alert.title}
        contentText={alert.body}
        contentTextTwo={alert.bodyTwo}
        confirmText="OK"
        styled
        errorMessage
      />
    </MuiThemeProvider>
  );
};

export default withRouter(withStyles(styles)(ForgotUsername));

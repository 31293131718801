/* eslint-disable no-case-declarations */
import {
  DASHBOARD_DATA,
  TAX_RETURN_LIST_FILTERS,
  OV_NOTEBOOK_ROWS,
  OV_NOTEBOOK_TABS,
  INDIVIDUAL_RETURN_TAB,
} from '~/app/constants.js';
import {
  TAX_RETURN_COLUMNS,
  TAX_RETURN_ACTIVE_COLUMNS,
} from '~/app/Pages/TaxReturns/taxReturnsHelper.js';
import { LOGOUT } from '~/app/redux/commonActions.js';

export const SELECT_FILTER = 'xlinkonline/returnList/SELECT';
export const PAGE_CHANGE = 'xlinkonline/returnList/PAGECHANGE';
export const UPDATE_LIST_FILTER = 'xlinkonline/returnList/UPDATELISTFILTER';
export const OPEN_ADD = 'xlinkonline/returnList/OPENADD';
export const CLOSE_ADD = 'xlinkonline/returnList/CLOSEADD';
export const OPEN_TRANSFER = 'xlinkonline/returnList/OPENTRANSFER';
export const CLOSE_TRANSFER = 'xlinkonline/returnList/CLOSETRANSFER';
export const SET_CLIENT = 'xlinkonline/returnList/SETCLIENT';
export const SET_EXISTING_SSNEIN = 'xlinkonline/returnList/SETEXISTINGSSNEIN,';
export const RESET_TAX_RETURN_FILTER = 'xlinkonline/returnList/RESETTAXRETURNFILTER';
export const UPDATE_RETURN_LIST_SORT_ORDER = 'xlinkonline/returnList/UPDATERETURNLISTSORTORDER';
export const UPDATE_ACTIVE_COL_IDX = 'xlinkonline/returnList/UPDATEACTIVECOLIDX';
export const REDIRECT_FROM_OVERVIEW = 'xlinkonline/overview/REDIRECTFROMOVERVIEW';
export const RESET_COLUMNS_TO_DEFAULT = 'xlinkonline/returnList/RESETCOLUMNSTODEFAULT';
export const RESET_COLUMNS = 'xlinkonline/returnList/RESETCOLUMNS';
export const SET_COLUMN_LIST = 'xlinkonline/returnList/SETCOLUMNLIST';
export const CLEAR_RETURNS_LIST = 'xlinkonline/returnList/CLEARRETURNSLIST';
export const SET_SELECTED_RETURN = 'xlinkonline/returnList/SETSELECTEDRETURN';
export const SET_SELECTED_PREPARER = 'xlinkonline/returnList/SETSELECTEDPREPARER';
export const ON_GET_PREPARER_INFO = 'xlinkonline/returnList/ONGETPREPARERINFO';
export const MOVE_RETURN = 'xlinkonline/returnList/MOVE_RETURN';
export const SHOW_MOVE_RETURN_MODAL = 'xlinkonline/returnList/SHOWMOVERETURNMODAL';
export const RESET_TRANSFER_PREPARER_DATA = 'xlinkonline/returnList/RESETTRANSFERPREPARERDATA';

export const REQUESTED_RETURN_LIST = 'xlinkonline/returnList/REQUESTEDRETURNLIST';
export const REQUESTED_RETURN_LIST_FAILED = 'xlinkonline/returnList/REQUESTEDRETURNFAILED';
export const REQUESTED_RETURN_LIST_SUCCEEDED = 'xlinkonline/returnList/REQUESTEDRETURNSUCCEEDED';
export const FETCHED_RETURN_LIST = 'xlinkonline/returnList/FETCHEDRETURNLIST';

export const REQUEST_ADD_NEW_RETURN = 'xlinkonline/returnList/REQUESTADDNEWRETURN';
export const ADD_NEW_RETURN_FAILURE = 'xlinkonline/returnList/ADDNEWRETURNFAILURE';
export const ADD_NEW_RETURN_SUCCESS = 'xlinkonline/returnList/ADDNEWRETURNSUCCESS';

export const FETCH_RETURN_FOR_DOWNLOAD = 'xlinkonline/returnList/FETCHRETURNFORDOWNLOAD';
export const DOWNLOAD_RETURN_FAILURE = 'xlinkonline/returnList/DOWNLOADRETURNFAILURE';
export const DOWNLOAD_RETURN_SUCCESS = 'xlinkonline/returnList/DOWNLOADRETURNSUCCESS';

export const FETCH_PREPARER_INFO = 'xlinkonline/returnList/FETCHPREPARERINFO';
export const REQUESTED_PREPARER_INFO_FAILURE =
  'xlinkonline/returnList/REQUESTEDPREPARERINFOFAILURE';
export const REQUESTED_PREPARER_INFO_SUCCESS =
  'xlinkonline/returnList/REQUESTEDPREPARERINFOSUCCESS';

export const MOVE_RETURN_FAILURE = 'xlinkonline/returnList/MOVERETURNSFAILURE';
export const REQUEST_MOVE_RETURN = 'xlinkonline/returnList/REQUESTMOVERETURN';
export const MOVE_RETURN_SUCCESS = 'xlinkonline/returnList/MOVERETURNSSUCCESS';
export const TOGGLE_MOVE_RETURN_SUCCESS = 'xlinkonline/returnList/TOGGLEMOVERETURNSSUCCESS';

export const FETCH_MOBILE_RETURNS = 'xlinkonline/returnList/FETCHMOBILERETURNS';
export const UPDATE_MOBILE_RETURNS = 'xlinkonline/returnList/UPDATEMOBILERETURNS';
export const IMPORT_MOBILE_RETURN = 'xlinkonline/returnList/IMPORTMOBILERETURN';
export const TOGGLE_MOBILE_IMPORT_SPINNER = 'xlinkonline/returnList/TOGGLEMOBILEIMPORTSPINNER';
export const TOGGLE_MOBILE_IMPORT_MESSAGE = 'xlinkonline/returnList/TOGGLEMOBILEIMPORTMESSAGE';
export const TOGGLE_MOBILE_IMPORT_NOTIFICATION =
  'xlinkonline/returnList/TOGGLEMOBILEIMPORTNOTIFICATION';

export const SET_READY_FOR_REVIEW_FILTER = 'xlinkonline/returnList/SETREADYFORREVIEWFILTER';

export const TOGGLE_REVIEW_NOTIFICATION = 'xlinkonline/returnList/TOGGLEREVIEWNOTIFICATION';

export const TOGGLE_READY_FOR_REVIEW_FILTER = 'xlinkonline/returnList/TOGGLEREADYFORREVIEWFILTER';
export const SET_RETURN_STATUSES = 'xlinkonline/returnList/SETRETURNSTATUSES';

export const initialState = {
  isAddNewReturnModalOpen: false,
  isTransferDataModalOpen: false,
  existingIdentifier: false,
  selectedReturn: {},
  rowID: 0,

  // for transfer data from last year modal
  clientData: {
    IDNumberType: '',
    IDNumber: '',
    income: '',
    tax: '',
    refund: '',
    lastYearOnPin: '',
    status: '',
  },

  filterValues: {
    first: '', // 1st filter text box value
    second: '', // 2nd filter text box value (for filtering by date range)
  },
  sortOrder: {
    column: 0, // default sort is by return_id or initial col
    order: 'desc', // default sort is in decending order
  },

  // all possible table columns. name is for column title, col is for query params, asc and desc are for sort dropdown options,
  // details is for customize column modal
  allColumns: TAX_RETURN_COLUMNS[INDIVIDUAL_RETURN_TAB], // initial should be 1040

  loading: false,
  error: false,

  // active columns, array index is column position in table and value is an index into allColumns
  activeColIndexes: TAX_RETURN_ACTIVE_COLUMNS[INDIVIDUAL_RETURN_TAB], // default column titles

  // all filters, name is displayed in the filter dropdown, filter is for query params
  // the order of these must match the order of TAX_RETURN_LIST_FILTERS in constants.js
  allFilters: [
    { name: 'All Returns', filter: 'all' },
    { name: 'Return ID', filter: 'return_id' },
    { name: 'Date Created', filter: 'date_created' },
    { name: 'EFIN', filter: 'efin' },
    { name: 'Name', filter: 'name' },
    { name: 'State', filter: 'state' },
    { name: 'Return Status', filter: 'return_status' },
    { name: 'SSN/EIN (Last 4)', filter: 'ssnein' },
    { name: 'Pending Remote Signatures', filter: 'signatureStatus' },
    { name: 'Completed Remote Signatures', filter: 'signatureStatus' },
    { name: 'Deleted Returns', filter: 'deleted_returns' },
  ],
  returnStatuses: [],

  // current filter criteria, index into allFilters
  activeFilter: TAX_RETURN_LIST_FILTERS.ALL,
  perPage: DASHBOARD_DATA.DASHBOARDLISTSIZE,
  currentPage: 1,
  returns: [], // a single page of returns matching the search criteria and pagination requirements
  totalReturns: -1, // number of returns meeting the search criteria (used by pagination component)

  // move return preparer info
  preparers: [],
  selectedPreparer: {},
  currentPreparer: {},
  isLoadingPreparerData: true,
  isAssignPreparerOpen: false,
  movingReturn: false,
  showTransferSuccessDlg: false,

  // Mobile App Integration
  mobileInfo: {
    results: [],
    totalCount: 0,
    shouldAlternateRtnImport: false,
  },
  mobileImportSpinner: false,
  mobileImportMessage: false,
  mobileImportNotification: true,
  reviewNotification: true,
  readyForReviewFilter: false,
  returnsForReviewCount: 0,
  unfilteredTotalReturns: -1,

  cyReturns: [],
};

export const actions = {
  openAddNewReturnModal: () => ({
    type: OPEN_ADD,
  }),
  closeAddNewReturnModal: () => ({
    type: CLOSE_ADD,
  }),
  openTransferDataModal: () => ({
    type: OPEN_TRANSFER,
  }),
  closeTransferDataModal: () => ({
    type: CLOSE_TRANSFER,
  }),
  setClientData: client => ({
    type: SET_CLIENT,
    client,
  }),
  setExistingSSNEIN: (exists, cyReturns = []) => ({
    type: SET_EXISTING_SSNEIN,
    exists,
    cyReturns,
  }),
  onSelectReturnListFilter: choice => ({
    type: SELECT_FILTER,
    choice,
  }),
  onPageChange: page => ({
    type: PAGE_CHANGE,
    page,
  }),
  updateReturnListFilterValues: (first, second, currentPage) => ({
    type: UPDATE_LIST_FILTER,
    first,
    second,
    currentPage,
  }),
  resetTaxReturnFilter: () => ({
    type: RESET_TAX_RETURN_FILTER,
  }),
  updateReturnListSortOrder: (col, order) => ({
    type: UPDATE_RETURN_LIST_SORT_ORDER,
    col,
    order,
  }),
  updateActiveColIdx: (newVal, colIdx) => ({
    type: UPDATE_ACTIVE_COL_IDX,
    newVal,
    colIdx,
  }),
  redirectFromOverview: (filter, rowID) => ({
    type: REDIRECT_FROM_OVERVIEW,
    filter,
    rowID,
  }),
  resetColumnsToDefault: () => ({
    type: RESET_COLUMNS_TO_DEFAULT,
  }),
  resetColumns: cols => ({
    type: RESET_COLUMNS,
    cols,
  }),
  setColumnList: returnType => ({
    type: SET_COLUMN_LIST,
    returnType,
  }),
  clearReturnsList: () => ({
    type: CLEAR_RETURNS_LIST,
  }),
  setSelectedReturn: rtn => ({
    type: SET_SELECTED_RETURN,
    rtn,
  }),
  setSelectedPreparer: preparer => ({
    type: SET_SELECTED_PREPARER,
    preparer,
  }),
  moveReturn: (returnID, preparerID) => ({
    type: MOVE_RETURN,
    returnID,
    preparerID,
  }),
  requestMoveReturn: () => ({
    type: REQUEST_MOVE_RETURN,
  }),
  moveReturnSuccess: () => ({
    type: MOVE_RETURN_SUCCESS,
  }),
  toggleMoveSuccessDlg: toggle => ({
    type: TOGGLE_MOVE_RETURN_SUCCESS,
    toggle,
  }),
  moveReturnFailure: () => ({
    type: MOVE_RETURN_FAILURE,
  }),
  onGetPreparerInfo: (currentPreparer, preparers, selectedPreparer) => ({
    type: ON_GET_PREPARER_INFO,
    currentPreparer,
    preparers,
    selectedPreparer,
  }),
  showMoveReturnModal: status => ({
    type: SHOW_MOVE_RETURN_MODAL,
    status,
  }),
  requestReturnList: () => ({
    type: REQUESTED_RETURN_LIST,
  }),
  requestReturnListSuccess: returnListData => ({
    type: REQUESTED_RETURN_LIST_SUCCEEDED,
    returnListData,
  }),
  requestReturnListError: () => ({
    type: REQUESTED_RETURN_LIST_FAILED,
  }),
  fetchReturnList: (filterParams, ddh) => ({
    type: FETCHED_RETURN_LIST,
    filterParams,
    ddh,
  }),
  addNewReturnError: () => ({
    type: ADD_NEW_RETURN_FAILURE,
  }),
  addNewReturnSuccess: () => ({
    type: ADD_NEW_RETURN_SUCCESS,
  }),
  requestAddNewReturn: (
    identifier,
    loginID,
    preparerID,
    jwtPayload,
    ddh,
    dupCheck = false,
    wizardReturn = false,
    entityType = '', // used for business entity
  ) => ({
    type: REQUEST_ADD_NEW_RETURN,
    identifier,
    loginID,
    preparerID,
    jwtPayload,
    ddh,
    dupCheck,
    wizardReturn,
    entityType,
  }),
  fetchReturnForDownload: (row, season) => ({
    type: FETCH_RETURN_FOR_DOWNLOAD,
    row,
    season,
  }),
  downloadReturnFailure: () => ({
    type: DOWNLOAD_RETURN_FAILURE,
  }),
  downloadReturnSuccess: () => ({
    type: DOWNLOAD_RETURN_SUCCESS,
  }),
  fetchPreparerInfo: returnID => ({
    type: FETCH_PREPARER_INFO,
    returnID,
  }),
  requestPreparerInfoSuccess: () => ({
    type: REQUESTED_PREPARER_INFO_SUCCESS,
  }),
  requestPreparerInfoFailure: () => ({
    type: REQUESTED_PREPARER_INFO_FAILURE,
  }),
  fetchMobileReturnList: axiosCancelSource => ({
    type: FETCH_MOBILE_RETURNS,
    axiosCancelSource,
  }),
  updateMobileReturnList: mobileInfo => ({
    type: UPDATE_MOBILE_RETURNS,
    mobileInfo,
  }),
  importMobileReturn: (
    ssn,
    filingStatus,
    loginID,
    preparerID,
    jwtPayload,
    ddh,
    isNew,
    isAlternateRtnImport,
    dateImported,
    docsOnly,
  ) => ({
    type: IMPORT_MOBILE_RETURN,
    ssn,
    filingStatus,
    loginID,
    preparerID,
    jwtPayload,
    ddh,
    isNew,
    isAlternateRtnImport,
    dateImported,
    docsOnly,
  }),
  toggleMobileImportSpinner: toggle => ({
    type: TOGGLE_MOBILE_IMPORT_SPINNER,
    toggle,
  }),
  toggleMobileImportMessage: toggle => ({
    type: TOGGLE_MOBILE_IMPORT_MESSAGE,
    toggle,
  }),
  toggleMobileImportNotification: toggle => ({
    type: TOGGLE_MOBILE_IMPORT_NOTIFICATION,
    toggle,
  }),
  setReadyForReviewFilter: toggle => ({
    type: SET_READY_FOR_REVIEW_FILTER,
    toggle,
  }),
  toggleReviewNotification: toggle => ({
    type: TOGGLE_REVIEW_NOTIFICATION,
    toggle,
  }),
  toggleReadyForReviewFilter: () => ({
    type: TOGGLE_READY_FOR_REVIEW_FILTER,
  }),
  setReturnStatuses: statuses => ({
    type: SET_RETURN_STATUSES,
    statuses,
  }),
  resetTransferPreparerData: () => ({
    type: RESET_TRANSFER_PREPARER_DATA,
  }),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_ADD:
      return {
        ...state,
        isAddNewReturnModalOpen: true,
      };
    case CLOSE_ADD:
      return {
        ...state,
        isAddNewReturnModalOpen: false,
      };
    case OPEN_TRANSFER:
      return {
        ...state,
        isTransferDataModalOpen: true,
      };
    case CLOSE_TRANSFER:
      return {
        ...state,
        isTransferDataModalOpen: false,
      };
    case SET_CLIENT:
      return {
        ...state,
        clientData: {
          ssn: action.client.ssn,
          income: action.client.income,
          tax: action.client.tax,
          refund: action.client.refund,
          lastYearOnPin: action.client.last_year_on_pin,
          status: action.client.status,
        },
      };
    case SET_EXISTING_SSNEIN:
      return {
        ...state,
        existingIdentifier: action.exists,
        cyReturns: action.cyReturns,
      };
    case SELECT_FILTER:
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          first: '',
          second: '',
        },
        activeFilter: action.choice,
      };
    case UPDATE_LIST_FILTER:
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          first: action.first,
          second: action.second,
        },
        currentPage: action.currentPage,
      };
    case PAGE_CHANGE:
      return {
        ...state,
        currentPage: action.page,
      };
    case RESET_TAX_RETURN_FILTER:
      return {
        ...state,
        activeFilter: 0,
      };
    case UPDATE_RETURN_LIST_SORT_ORDER:
      return {
        ...state,
        sortOrder: {
          ...state.sortOrder,
          column: action.col,
          order: action.order,
        },
      };
    case UPDATE_ACTIVE_COL_IDX:
      const cols = state.activeColIndexes.map((item, idx) =>
        idx === action.colIdx ? action.newVal : item,
      );
      return {
        ...state,
        activeColIndexes: cols,
      };
    case REDIRECT_FROM_OVERVIEW:
      const { ROW_ONE } = OV_NOTEBOOK_ROWS;
      let filterCriteria;
      switch (action.filter) {
        case OV_NOTEBOOK_TABS.TAX_RETURNS:
          action.rowID === ROW_ONE ? (filterCriteria = 'EF Return') : (filterCriteria = 'PaperRet');
          break;
        case OV_NOTEBOOK_TABS.EXTENSIONS:
          action.rowID === ROW_ONE ? (filterCriteria = 'Extn Ack') : (filterCriteria = 'Extn Rfs');
          break;
        case OV_NOTEBOOK_TABS.REJECTS:
          action.rowID === ROW_ONE
            ? (filterCriteria = 'Rejected_Refused')
            : (filterCriteria = 'StReject');
          break;
        case OV_NOTEBOOK_TABS.ACCEPTED:
          action.rowID === ROW_ONE ? (filterCriteria = 'Acked') : (filterCriteria = 'Chk Rdy');
          break;
      }

      return {
        ...state,
        activeFilter: TAX_RETURN_LIST_FILTERS.STATUS,
        filterValues: {
          ...state.filterValues,
          first: filterCriteria,
        },
      };
    case RESET_COLUMNS_TO_DEFAULT:
      return {
        ...state,
        activeColIndexes: [...state.activeColIndexes],
      };
    case RESET_COLUMNS:
      return {
        ...state,
        activeColIndexes: action.cols,
      };
    case SET_COLUMN_LIST:
      return {
        ...state,
        allColumns: TAX_RETURN_COLUMNS[action.returnType], // 0 or 1 -> 1040 or Business columns
        activeColIndexes: TAX_RETURN_ACTIVE_COLUMNS[action.returnType],
      };
    case CLEAR_RETURNS_LIST:
      return {
        ...state,
        returns: [],
        currentPage: 1,
        totalReturns: -1,
        unfilteredTotalReturns: -1,
        sortOrder: {
          column: 0,
          order: 'desc',
        },
      };
    case SET_SELECTED_PREPARER:
      return {
        ...state,
        selectedPreparer: action.preparer,
      };
    case ON_GET_PREPARER_INFO:
      return {
        ...state,
        currentPreparer: action.currentPreparer,
        preparers: action.preparers,
        selectedPreparer: action.selectedPreparer,
        isLoadingPreparerData: false,
      };
    case SHOW_MOVE_RETURN_MODAL:
      return {
        ...state,
        isAssignPreparerOpen: action.status,
      };
    case LOGOUT:
      return {
        ...state,
        returns: [],
        currentPage: 1,
        totalReturns: -1,
        unfilteredTotalReturns: -1,
        activeFilter: 0,
        filterValues: {},
        sortOrder: {
          column: 0,
          order: 'desc',
        },
        reviewNotification: true,
        readyForReviewFilter: false,
        mobileImportNotification: true,
        isTransferDataModalOpen: false,
      };
    case SET_SELECTED_RETURN:
      return {
        ...state,
        selectedReturn: action.rtn,
        isAssignPreparerOpen: true,
      };
    case REQUESTED_RETURN_LIST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case REQUESTED_RETURN_LIST_SUCCEEDED:
      return {
        ...state,
        returns: action.returnListData.list ? action.returnListData.list : [],
        totalReturns: action.returnListData.total,
        unfilteredTotalReturns: action.returnListData.unfilteredTotal,
        returnsForReviewCount: action.returnListData.readyToReviewCount,
        loading: false,
        error: false,
      };
    case REQUESTED_RETURN_LIST_FAILED:
      return {
        ...state,
        returns: [],
        totalReturns: -1,
        loading: false,
        error: true,
      };
    case ADD_NEW_RETURN_SUCCESS:
      return {
        ...state,
      };
    case ADD_NEW_RETURN_FAILURE:
      return {
        ...state,
      };
    case DOWNLOAD_RETURN_SUCCESS:
      return {
        ...state,
      };
    case DOWNLOAD_RETURN_FAILURE:
      return {
        ...state,
      };
    case REQUESTED_PREPARER_INFO_SUCCESS:
      return {
        ...state,
      };
    case REQUESTED_PREPARER_INFO_FAILURE:
      return {
        ...state,
      };
    case REQUEST_MOVE_RETURN:
      return {
        ...state,
        movingReturn: true,
      };
    case MOVE_RETURN_SUCCESS:
      return {
        ...state,
        movingReturn: false,
        showTransferSuccessDlg: true,
      };
    case TOGGLE_MOVE_RETURN_SUCCESS:
      return {
        ...state,
        showTransferSuccessDlg: action.toggle,
      };
    case MOVE_RETURN_FAILURE:
      return {
        ...state,
        movingReturn: false,
      };
    case RESET_TRANSFER_PREPARER_DATA:
      return {
        ...state,
        currentPreparer: {},
        preparers: [],
        selectedPreparer: {},
        isLoadingPreparerData: true,
      };
    case UPDATE_MOBILE_RETURNS:
      return {
        ...state,
        mobileInfo: action.mobileInfo || initialState.mobileInfo,
      };
    case TOGGLE_MOBILE_IMPORT_SPINNER:
      return {
        ...state,
        mobileImportSpinner: action.toggle,
      };
    case TOGGLE_MOBILE_IMPORT_MESSAGE:
      return {
        ...state,
        mobileImportMessage: action.toggle,
      };
    case TOGGLE_MOBILE_IMPORT_NOTIFICATION:
      return {
        ...state,
        mobileImportNotification: action.toggle,
      };
    case SET_READY_FOR_REVIEW_FILTER:
      return {
        ...state,
        readyForReviewFilter: action.toggle,
      };
    case TOGGLE_REVIEW_NOTIFICATION:
      return {
        ...state,
        reviewNotification: action.toggle,
      };
    case TOGGLE_READY_FOR_REVIEW_FILTER:
      return {
        ...state,
        readyForReviewFilter: !state.readyForReviewFilter,
        currentPage: 1,
      };
    case SET_RETURN_STATUSES:
      return {
        ...state,
        returnStatuses: action.statuses,
      };
  }
  return state;
}

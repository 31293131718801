// External imports
import React, { useEffect } from 'react';
import {
  Button,
  TextField,
  MenuItem,
  FormControl,
  withStyles,
  Modal,
  Paper,
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
// Internal imports
import useSetState from '~/node_modules/react-use/lib/useSetState.js';
import {
  FILING_STATUS_NUMBERS,
  REMOTE_DOCUMENT_REQUESTEE_TYPE,
  VALID_EMAIL_REGEX,
} from '~/app/constants.js';
import { buildExpireInDate } from '~/app/Pages/Returns/components/Document/ArchivedDocuments/archivedDocumentsHelpers.js';
import emailIconWhite from '~/images/icons/emailIconWhite.png';
// Redux imports
import { useDispatch } from 'react-redux';
import { actions as remoteDocActions } from '~/app/redux/modules/remoteDocRequests.js';
// Styling imports
import '~/app/Pages/Returns/components/Document/ArchivedDocuments/css/archivedDocuments';
import { styles } from '~/app/Pages/Returns/components/Document/ArchivedDocuments/css/archivedDocuments.js';

/**
 * This modal handles making a request to collect a document from a user, via remote email
 *
 * @component RequestDocumentModal
 * @category ArchivedDocuments
 **/
const RequestDocumentModal = props => {
  const { classes } = props;
  const dispatch = useDispatch();
  const [state, setState] = useSetState({
    hasFormSubmitted: false,
  });

  const remainingDays = buildExpireInDate(props.rmdFormFields.updateDate);
  const taxpayerOnly = [FILING_STATUS_NUMBERS.SING, FILING_STATUS_NUMBERS.MFS].includes(
    parseInt(props.clientFilingStatus),
  );

  useEffect(() => {
    if (props.isEditingRMD) {
      dispatch(remoteDocActions.setOnChangeRMDField('otherEmailCheck', true));
    }
  }, [props.isEditingRMD]);

  useEffect(() => {
    if (props.isBusiness) {
      dispatch(
        remoteDocActions.setOnChangeRMDField(
          'requesteeType',
          REMOTE_DOCUMENT_REQUESTEE_TYPE.BUSINESS,
        ),
      ); // Auto default req type
    }
  }, [props.isBusiness]);

  useEffect(() => {
    if (taxpayerOnly) {
      dispatch(
        remoteDocActions.setOnChangeRMDField(
          'requesteeType',
          REMOTE_DOCUMENT_REQUESTEE_TYPE.TAXPAYER,
        ),
      );
    }
  }, [taxpayerOnly]);

  /** Handles rmd form change */
  const handleChange = e => {
    const name = e.target.name;
    const value = name === 'otherEmailCheck' ? e.target.checked : e.target.value;
    dispatch(remoteDocActions.setOnChangeRMDField(name, value));
    // reset email whenever 'otherEmailCheck' is clicked.
    name === 'otherEmailCheck' && dispatch(remoteDocActions.setOnChangeRMDField('email', ''));
  };

  /**
   * Checks each input to see if it is empty.
   *
   * @param {Object} fields input fields from the form
   * @returns {boolean} used to determine if there is an error and should return true
   */
  const formHasErrors = fields => {
    const errors = [];
    const requiredFields = ['subject', 'description', 'email', 'documentTypeID', 'requesteeType'];

    for (const fieldName of requiredFields) {
      if (
        (fieldName === 'email' && !VALID_EMAIL_REGEX.test(props.rmdFormFields.email)) ||
        !fields[fieldName]
      ) {
        errors.push(true);
      }
    }

    return errors.length !== 0;
  };

  /** Handles saving request to DB then the API should handle sending a template request through email */
  const handleRequestDocument = e => {
    e.preventDefault();
    setState({ hasFormSubmitted: true });

    if (!formHasErrors(props.rmdFormFields)) {
      props.sendDocumentRequest(props.rmdFormFields);
    }
  };

  /**
   * Handles deciding whether the user will continue request documents, or close the modal
   *
   * @param {boolean} isEditing if editing a document, we do not let them re-request a document
   */
  const onCloseReqModal = (isContinuing = false) => {
    dispatch(remoteDocActions.resetRMDFormFields());
    setState({
      hasFormSubmitted: false,
    });

    // If user is editing the remote document, they do not have the option of 're-submitting'
    if (props.isEditingRMD) {
      dispatch(remoteDocActions.setIsEditingRMD(false));
    }

    // if a user created a new request, they have the option of requesting another
    if (!isContinuing) {
      props.toggleRequestDocsModal();
    }
    dispatch(remoteDocActions.setCurrentRequestStep(0));
  };

  return (
    <Modal
      id="mdlRequestDocumentModalFormViewer"
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={props.isRequestDocumentModalOpen}
      disableBackdropClick={true}
    >
      <Paper elevation={5} className={classes.addDocumentModal}>
        <div>
          <Typography
            variant="body2"
            id="simple-modal-title"
            className={classes.modalHeaderTitleStyle}
          >
            <img id="clientNameInfo" className="attach-doc-img-one" src={emailIconWhite} />
            <span className="attach-doc-header">
              {`Request Documents${
                props.isEditingRMD
                  ? ` - Email Authentication Expires in ${remainingDays} Day(s)`
                  : ''
              }`}
            </span>
          </Typography>

          <Grid container spacing={32} className={classes.requestDocBody}>
            {props.currentRequestStep === 0 ? (
              <>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <FormControlLabel
                      label="Other Email"
                      control={
                        <Checkbox
                          id="otherEmailCheckInput"
                          name="otherEmailCheck"
                          color="primary"
                          checked={props.rmdFormFields.otherEmailCheck}
                          onChange={e => handleChange(e)}
                        />
                      }
                    />
                  </FormControl>
                </Grid>
                {!props.rmdFormFields.otherEmailCheck ? (
                  <Grid item xs={9}>
                    <FormControl fullWidth>
                      <TextField
                        select
                        id="emailDropdown"
                        name="email"
                        value={props.rmdFormFields.email}
                        onChange={e => handleChange(e)}
                        label={`${
                          !(props.taxpayerEmail || props.spouseEmail)
                            ? 'No Emails have been saved in the Client Data section.'
                            : 'Select an Email'
                        }`}
                        variant="outlined"
                        disabled={!(props.taxpayerEmail || props.spouseEmail)}
                        required
                        error={!props.rmdFormFields.email && state.hasFormSubmitted}
                      >
                        {props.taxpayerEmail && (
                          <MenuItem id="taxpayerEmailOption" value={props.taxpayerEmail}>
                            Taxpayer: {props.taxpayerEmail}
                          </MenuItem>
                        )}
                        {props.spouseEmail && (
                          <MenuItem id="spouseEmailOption" value={props.spouseEmail}>
                            Spouse: {props.spouseEmail}
                          </MenuItem>
                        )}
                      </TextField>
                    </FormControl>
                  </Grid>
                ) : (
                  <Grid item xs={9}>
                    <FormControl fullWidth>
                      <TextField
                        required
                        type="email"
                        id="otherEmailInput"
                        variant="outlined"
                        label="Other Email"
                        name="email"
                        inputProps={{ maxLength: 120 }}
                        placeholder="JohnDoe@example.com"
                        value={props.rmdFormFields.email}
                        onChange={e => handleChange(e)}
                        error={
                          (!props.rmdFormFields.email ||
                            !VALID_EMAIL_REGEX.test(props.rmdFormFields.email)) &&
                          state.hasFormSubmitted
                        }
                      />
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      required
                      id="subjectInput"
                      variant="outlined"
                      label="Subject"
                      placeholder="Requesting document..."
                      inputProps={{ maxLength: 60 }}
                      name="subject"
                      value={props.rmdFormFields.subject}
                      onChange={e => handleChange(e)}
                      error={!props.rmdFormFields.subject && state.hasFormSubmitted}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      required
                      id="descriptionInput"
                      variant="outlined"
                      label="Document Description"
                      placeholder="Start typing here..."
                      inputProps={{ maxLength: 300 }}
                      multiline
                      rows={5}
                      name="description"
                      value={props.rmdFormFields.description}
                      onChange={e => handleChange(e)}
                      error={!props.rmdFormFields.description && state.hasFormSubmitted}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <TextField
                      select
                      id="requesteeTypeDropdown"
                      name="requesteeType"
                      value={
                        props.isBusiness
                          ? REMOTE_DOCUMENT_REQUESTEE_TYPE.BUSINESS
                          : taxpayerOnly
                          ? REMOTE_DOCUMENT_REQUESTEE_TYPE.TAXPAYER
                          : props.rmdFormFields.requesteeType
                      }
                      onChange={e => handleChange(e)}
                      disabled={props.isBusiness || taxpayerOnly}
                      label="Requestee Type"
                      variant="outlined"
                      required
                      error={!props.rmdFormFields.requesteeType && state.hasFormSubmitted}
                    >
                      <MenuItem id="taxpayerOption" value={REMOTE_DOCUMENT_REQUESTEE_TYPE.TAXPAYER}>
                        Taxpayer
                      </MenuItem>
                      {!taxpayerOnly && (
                        <MenuItem id="spouseOption" value={REMOTE_DOCUMENT_REQUESTEE_TYPE.SPOUSE}>
                          Spouse
                        </MenuItem>
                      )}
                      {props.isBusiness && (
                        <MenuItem
                          id="businessOption"
                          value={REMOTE_DOCUMENT_REQUESTEE_TYPE.BUSINESS}
                        >
                          Business
                        </MenuItem>
                      )}
                    </TextField>
                  </FormControl>
                </Grid>
                {/* Should documentRequest Key be a dropdown of known files, OR we just let them add a regular string key like '1040' */}
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <TextField
                      select
                      id="documentTypeIDInput"
                      name="documentTypeID"
                      value={props.rmdFormFields.documentTypeID}
                      onChange={e => handleChange(e)}
                      label="Document Type"
                      variant="outlined"
                      required
                      error={!props.rmdFormFields.documentTypeID && state.hasFormSubmitted}
                    >
                      {props.documentTypes?.map(item => {
                        return (
                          <MenuItem
                            key={`${item.document_type_id}-${item.document_type}`}
                            id="documentTypeIDInput"
                            value={item.document_type_id}
                          >
                            {item.document_type}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid
                  container
                  justify="flex-end"
                  item
                  xs={12}
                  className={classes.styledGridWithMargin}
                >
                  <div className="attach-doc-div-four">
                    {props.isEditingRMD && (
                      <Button
                        id="btnDeleteDoc"
                        onClick={() => props.toggleModal('isConfirmDeleteModalOpen')}
                        className={classes.styleButtonMarginRight}
                        size="small"
                      >
                        Delete
                      </Button>
                    )}
                    <Button
                      id="btnCancelRequestDocuments"
                      className={classes.styleButtonMarginRight}
                      size="small"
                      color="default"
                      onClick={() => onCloseReqModal(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      id="btnRequestDocuments"
                      size="small"
                      color="primary"
                      variant="contained"
                      onClick={e => handleRequestDocument(e)}
                    >
                      Request
                    </Button>
                  </div>
                </Grid>
              </>
            ) : props.currentRequestStep === 1 ? (
              <>
                <Grid item xs={12}>
                  <Typography variant="body1" align="center">
                    {`The requested file process has been completed and sent to ${props.rmdFormFields.email}`}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" align="center">
                    Please close this window.
                  </Typography>
                </Grid>
                {!props.isEditingRMD && (
                  <Grid item xs={12}>
                    <Typography variant="body1" align="center">
                      Otherwise, click below to request another document.
                    </Typography>
                  </Grid>
                )}

                <Grid
                  container
                  justify="flex-end"
                  item
                  xs={12}
                  className={classes.styledGridWithMargin}
                >
                  <div className="attach-doc-div-four">
                    <Button
                      id="btnAddAnotherDoc"
                      onClick={() => onCloseReqModal(false)}
                      className={classes.styleButtonMarginRight}
                      color="default"
                      size="small"
                    >
                      Exit
                    </Button>
                    {!props.isEditingRMD && (
                      <Button
                        id="btnAddAnotherDoc"
                        size="small"
                        onClick={() => onCloseReqModal(true)}
                        variant="contained"
                        color="primary"
                      >
                        Request
                      </Button>
                    )}
                  </div>
                </Grid>
              </>
            ) : null}
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
};

export default withStyles(styles)(RequestDocumentModal);

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import app from '~/app/redux/modules/app';
import widgetDashboard from '~/app/redux/widgets/duck';
import accountCreation from '~/app/redux/modules/accountCreation';
import returnList from '~/app/redux/returnList/duck';
import overview from '~/app/redux/modules/overview';
import customerProfile from '~/app/redux/modules/customerProfile';
import customerList from '~/app/redux/modules/customerList';
import accountList from '~/app/redux/accountList/duck';
import officeList from '~/app/redux/officeList/duck';
import preparerList from '~/app/redux/preparerList/duck';
import reporting from '~/app/redux/reporting/duck';
import returnProfile from '~/app/redux/returnProfile/duck';
import messages from '~/app/redux/modules/messages';
import appointments from '~/app/redux/modules/appointments';
import formViewer from '~/app/redux/modules/formViewer';
import wizardEstimator from '~/app/redux/modules/wizardEstimator';
import officeSettings from '~/app/redux/modules/officeSettings';
import searchResults from '~/app/redux/search/duck';
import setupPageState from '~/app/redux/setupPages/duck';
import loginSetup from '~/app/redux/loginSetup/duck';
import preparerSetup from '~/app/redux/preparerSetup/duck';
import drilldown from '~/app/redux/drilldown/duck';
import defaultsPageState from '~/app/redux/setupPages/defaultsPage/duck';
import notifications from '~/app/redux/notifications/duck';
import cobrand from '~/app/redux/cobrand/duck';
import remoteSign from '~/app/redux/remoteSign/duck';
import remoteDocRequests from '~/app/redux/modules/remoteDocRequests';
import serverStatus from '~/app/redux/serverStatus/duck';
import userProfile from '~/app/redux/userProfile/duck';
import techSupport from '~/app/redux/techSupport/duck';
import officeProfile from '~/app/redux/officeProfile/duck';
import restrictedFields from '~/app/redux/restrictedFields/duck';

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    app,
    overview,
    widgetDashboard,
    customerProfile,
    reporting,
    returnProfile,
    customerList,
    accountList,
    loginSetup,
    preparerSetup,
    officeList,
    preparerList,
    returnList,
    messages,
    appointments,
    accountCreation,
    formViewer,
    wizardEstimator,
    officeSettings,
    searchResults,
    setupPageState,
    drilldown,
    defaultsPageState,
    notifications,
    cobrand,
    remoteSign,
    remoteDocRequests,
    serverStatus,
    userProfile,
    techSupport,
    officeProfile,
    restrictedFields,
  });

export default createRootReducer;

// External imports
import axios from 'axios';
import Fingerprint2 from 'fingerprintjs2';
import get from 'lodash-es/get';
// Internal imports
import WebHelpers from '~/app/webHelpers.js';
import { FINGERPRINT_OPTIONS, TAX_RETURN_LIST_FILTERS } from '~/app/constants.js';
import ErrorHelpers from '~/app/errorHelpers.js';
import AuthAPI from '~/app/api/authAPI.js';
import UtilityAPI from '~/app/api/utilityAPI';
import { HIERARCHY_TYPE, BILLING_SCHEMA } from '~/app/constants';

const CancelToken = axios.CancelToken;
// each request will generate it's own cancel function
let cancelFunctions = [];
let fingerprint;

export default class XlinkAPI {
  static validateOrRefresh = async () => {
    if (!fingerprint) {
      await Fingerprint2.get(FINGERPRINT_OPTIONS, componenets => {
        fingerprint = UtilityAPI.hashFingerprint(componenets);
      });
    }

    return new Promise((resolve, reject) => {
      AuthAPI.validateOrRefresh(fingerprint)
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          // validate AND refresh failed, let this error be handled by central error handling
          ErrorHelpers.handleError('Session error', error);
          return reject(error);
        });
    });
  };

  static getHeaders = async () => {
    return XlinkAPI.simpleGetRequest(`${BASE_URL}/authn/headers`);
  };

  static getUserProfile = loginID => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/user/profile/${loginID}`);
  };

  static getEntityName = () => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/user/identity`);
  };

  static getAccountList = () => {
    return XlinkAPI.simplePostRequest(`${XLINK_API_URL}/user/list`);
  };

  // checkEROSignatureExistsFlag checks to see if the ERO has signed beofre allowing users to select anything from the print menu
  static checkEROSignatureExistsFlag = async paramLoginID => {
    const body = `paramLoginID=${paramLoginID}`;

    return new Promise((resolve, reject) => {
      axios
        .post(`${XLINK_API_URL}/efin/signatureexistsflagbyefinid`, body, {
          ...WebHelpers.urlEncodedContentType(),
          ...WebHelpers.getAuthHeaders(),
        })
        .then(res => resolve(res))
        .catch(error => reject(error));
    });
  };

  // Get ppSignature gets the signature data for preparer given a preparer shortcut id
  static getppSignature = (preparerShortcutID, returnID) => {
    return XlinkAPI.simpleGetRequest(
      `${XLINK_API_URL}/preparer/signaturebyshortcutid/${preparerShortcutID}/${returnID}`,
    );
  };

  // getefinSignature gets the signature data for an ERO, given an efin number
  static getefinSignature = () => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/efin/signaturebyefin`);
  };

  // getPreparerUploadedSignature checks if a preparer has already uploaded a signature
  static getPreparerUploadedSignature = () => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/user/signature`);
  };

  // preparer overview page list
  static getPreparerOverview = async (ddh, loginID, preparerID, roleGroup, count) => {
    if (loginID === undefined) {
      loginID = 0;
    }

    let officeLoginID = await UtilityAPI.getLastDrilledOfficeLoginID(ddh);
    if (!officeLoginID) officeLoginID = 0;
    const body = `loginID=${loginID}&preparerID=${preparerID}&count=${count}&roleGroup=${roleGroup}&officeLoginID=${officeLoginID}`;
    return new Promise((resolve, reject) => {
      axios
        .post(`${XLINK_API_URL}/user/prepareroverview`, body, {
          ...WebHelpers.urlEncodedContentType(),
          ...WebHelpers.getAuthHeaders(),
        })
        .then(res => resolve(res))
        .catch(error => reject(error));
    });
  };

  static getPreparerOfReturn = returnID => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/hierarchy/preparerofreturn/${returnID}`);
  };

  // preparer overview return status counts
  static queryNotebookTabStats = async (loginID, preparerID, ddh) => {
    if (loginID === undefined) {
      loginID = 0;
    }

    let officeLoginID = await UtilityAPI.getLastDrilledOfficeLoginID(ddh);
    if (!officeLoginID) officeLoginID = 0;

    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/user/return/statuscounts`,
          {
            loginID,
            preparerID,
            officeLoginID,
          },
          {
            ...WebHelpers.urlEncodedContentType(),
            ...WebHelpers.getAuthHeaders(),
          },
        )
        .then(res => resolve(res))
        .catch(error => reject(error));
    });
  };

  static getGenericOverview = (loginID, count, roleGroup) => {
    return XlinkAPI.getOverview(`loginID=${loginID}&count=${count}&efinID=0`);
  };

  static getOfficeOverview = (loginID, count, roleGroup, efinID) => {
    return XlinkAPI.getOverview(
      `loginID=${loginID}&count=${count}&roleGroup=${roleGroup}&efinID=${efinID}`,
    );
  };

  static fetchAvailableOffices = () => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/office/list/siblings`);
  };

  // overview page lists
  static getOverview = body => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${XLINK_API_URL}/user/overview`, body, {
          ...WebHelpers.urlEncodedContentType(),
          ...WebHelpers.getAuthHeaders(),
        })
        .then(res => resolve(res))
        .catch(error => reject(error));
    });
  };

  static getDashboardList = filterParams => {
    if (filterParams.preparerID == null) {
      filterParams.preparerID = 0;
    }

    const body = XlinkAPI.getURLEncodedFilterParams(filterParams);
    const endpoint = XlinkAPI.getFilterRouteQuery(filterParams);

    return axios.post(`${XLINK_API_URL}/user/${endpoint}`, body, {
      ...WebHelpers.urlEncodedContentType(),
      ...WebHelpers.getAuthHeaders(),
    });
  };

  static getFeederOfficePermission = userID => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${XLINK_API_URL}/user/${userID}/feeder-office-permission`,
          WebHelpers.getAuthHeaders(),
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static getIsEFINActive = EFIN => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${XLINK_API_URL}/user/is-efin-active/${EFIN}`, WebHelpers.getAuthHeaders())
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  // assignPreparerToReturn assigns a preparer to a return and updates prepID on the CDS in the taxreturn
  static assignPreparerToReturn = async (returnID, preparerID) => {
    return await axios.post(
      `${XLINK_API_URL}/taxprep/taxreturn/${returnID}/assignReturn/${preparerID}`,
      {},
      {
        ...WebHelpers.getAuthHeaders(),
        cancelToken: new CancelToken(fn => {
          cancelFunctions.push(fn);
        }),
      },
    );
  };

  static getPreparerIDbyLoginID = loginID => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/taxprep/preparer/prepexists/${loginID}`);
  };

  static getAllPreparersAtAnOffice = returnID => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/hierarchy/preparer/allatoffice/${returnID}`);
  };

  static getStateNames = () => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/states`);
  };

  static getReturnStatusList = () => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/returnstatuses`);
  };

  static getBasicOfficeInfo = () => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/hierarchy/officeInfo`);
  };

  static downloadReturn = returnID => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/taxprep/return/download/${returnID}`);
  };

  static ssnExistsWithinOffice = async (ssn, ddh) => {
    const payload = WebHelpers.getJWTPayload();
    let activeEfinID;
    if (payload.hierarchy_type_id === HIERARCHY_TYPE.PREPARER) {
      activeEfinID = payload.preparer_info.active_efin_id;
    } else {
      activeEfinID = await UtilityAPI.getLastDrilledOfficeEfinID(ddh);
    }

    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/taxprep/ssnexists`,
          {
            ssn,
            active_efin_id: activeEfinID,
          },
          {
            ...WebHelpers.getAuthHeaders(),
            cancelToken: new CancelToken(fn => {
              cancelFunctions.push(fn);
            }),
          },
        )
        .then(resp => {
          return resolve(resp.data.exists);
        })
        .catch(err => {
          console.log(err);
          return reject(err);
        });
    });
  };

  /*
  static addShellReturnForEstimator = (loginID, preparerID, efinID) => {
    /*
    let newReturn = yield call(
      XlinkAPI.addNewReturn,
      payload.loginID,
      payload.preparerID,
      payload.ssn,
      active_efin_id
    );
    
    const endpoint = `${XLINK_API_URL}/taxprep/newEstimator`;
    const body = {
      login_id: loginID,
      preparer_id: preparerID,
      primary_ssn: '500000001', // dummy ssn
      efin_id: efinID,
    };
    return post(endpoint, body);
  };
*/
  static addNewReturn = async (
    loginID,
    preparerID,
    ssn,
    efinID,
    dupCheck = false,
    wizardReturn = false,
    entityType = 0,
  ) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/taxprep/new`,
          {
            login_id: loginID,
            preparer_id: preparerID,
            primary_ssn: ssn,
            efin_id: efinID,
            dupCheck,
            wizardReturn,
            entityType,
          },
          {
            ...WebHelpers.getAuthHeaders(),
            cancelToken: new CancelToken(fn => {
              cancelFunctions.push(fn);
            }),
          },
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static rfndCalcSSNDupeCheck = async (ssn, efinID) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/taxprep/new/duplicateCheck`,
          {
            primary_ssn: ssn,
            efin_id: efinID,
          },
          {
            ...WebHelpers.getAuthHeaders(),
            cancelToken: new CancelToken(fn => {
              cancelFunctions.push(fn);
            }),
          },
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static getReturnRowDetailByReturnID = async rid => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/taxreturn/row/${rid}/detail`);
  };

  static restoreTaxReturnByReturnID = async rid => {
    return XlinkAPI.simplePostRequest(`${XLINK_API_URL}/taxreturn/restore`, {
      returnID: rid,
    });
  };

  static copyReturnInfoToLive = async rid => {
    return XlinkAPI.simplePostRequest(`${XLINK_API_URL}/taxreturn/copy`, {
      returnID: rid,
    });
  };

  static getReturnProfileByReturnID = async rid => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/taxprep/get/${rid}`);
  };

  static getWizardFormStatus = async rid => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/taxprep/wizard/get/${rid}`);
  };

  static getReturnAttachmentByRIDDocId = (rid, docID) => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/taxreturn/${rid}/document/${docID}`);
  };

  static lockReturnByReturnID = async rid => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/taxprep/lock/${rid}`);
  };

  static unlockReturnByReturnID = async rid => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/taxprep/unlock/${rid}`);
  };

  static setReturnLockStatus = async (rid, lockedStatus) => {
    return XlinkAPI.simplePostRequest(`${XLINK_API_URL}/taxprep/lock-status`, {
      returnID: rid,
      lockedStatus,
    });
  };

  static getRemoteSignatureDocumentsList = returnID => {
    return XlinkAPI.simpleGetRequest(
      `${XLINK_API_URL}/taxprep/remotesignature/documents/list/${returnID}`,
    );
  };

  static getRemoteSignatureDetails = sigDocID => {
    return XlinkAPI.simpleGetRequest(
      `${XLINK_API_URL}/taxprep/remotesignature/details/list/${sigDocID}`,
    );
  };

  static whoHasReturnLock = async rid => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/taxprep/whohaslock/${rid}`);
  };

  static deleteDocumentFromTaxRtn = docID => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/taxprep/removedoc`,
          {
            docID: parseInt(docID),
          },
          WebHelpers.getAuthHeaders(),
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };
  // FormViewer

  static getAllFormsToAdd = () => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/forms/get`);
  };

  static addForm = (rid, form) => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/taxprep/${rid}/add/${form}`);
  };

  static deleteForm = (rid, form) => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/taxprep/${rid}/remove/${form}`);
  };

  static getTaxForm = name => {
    name = 'frm' + name;
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/forms/${name}`);
  };

  static discardReturn = rid => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/taxprep/discard/${rid}`);
  };

  static postEFINValidation = (EFIN, efinID, userID, season, loginID, isFeederOffice) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/account/setup/office/efin-validation`,
          {
            EFIN,
            efinID,
            userID,
            season,
            loginID,
            isFeederOffice,
          },
          WebHelpers.getAuthHeaders(),
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static getOfficeSetup = (curLoginID, efinID) => {
    return XlinkAPI.simpleGetRequest(
      `${XLINK_API_URL}/account/setup/office/${curLoginID}/${efinID}`,
    );
  };

  static getOfficeLicenseInfoByLoginID = curLoginID => {
    return XlinkAPI.simplePostRequest(
      `${XLINK_API_URL}/license/getOfficeLicenseInfoByLoginID`,
      `{
            "LoginID": ${curLoginID}
          }`,
    );
  };

  static getAccountLicenseInfoByLoginID = selectedSeason => {
    return XlinkAPI.simplePostRequest(
      `${XLINK_API_URL}/license/getAllLicensesByLoginID/season/${selectedSeason}`,
    );
  };

  static getAccountOfficesByLoginID = selectedSeason => {
    return XlinkAPI.simplePostRequest(
      `${XLINK_API_URL}/license/getAccountOfficesByLoginID/season/${selectedSeason}`,
    );
  };

  static getMOManagedLicenseInfoByLoginID = selectedSeason => {
    return XlinkAPI.simplePostRequest(
      `${XLINK_API_URL}/license/getMOManagedLicenseByLoginID/season/${selectedSeason}`,
    );
  };

  static addProductLicense = (licenseGuid, licenseProductCode, season) => {
    return XlinkAPI.simplePostRequest(`${XLINK_API_URL}/license/addProductLicense`, {
      licenseGuid,
      licenseProductCode,
      season,
    });
  };

  static applyOfficeLicense = async (officeID, licenseID, selectedSeason) => {
    return await axios.post(
      `${XLINK_API_URL}/license/applyOfficeLicense`,
      {
        officeID: parseInt(officeID),
        licenseID,
        season: selectedSeason,
      },
      {
        ...WebHelpers.getAuthHeaders(),
        cancelToken: new CancelToken(fn => {
          cancelFunctions.push(fn);
        }),
      },
    );
  };

  static unapplyOfficeLicense = async (officeID, licenseID, selectedSeason) => {
    return await axios.post(
      `${XLINK_API_URL}/license/unapplyOfficeLicense`,
      {
        officeID: parseInt(officeID),
        licenseID,
        season: selectedSeason,
      },
      {
        ...WebHelpers.getAuthHeaders(),
        cancelToken: new CancelToken(fn => {
          cancelFunctions.push(fn);
        }),
      },
    );
  };

  static applyMOGeneratableLicenses = async (userID, numLicenses, isBusinessLicense = false) => {
    return await axios.post(
      `${XLINK_API_URL}/license/${userID}/addMOGeneratableLicenses`,
      {
        num_licenses: parseInt(numLicenses),
        is_business: isBusinessLicense,
      },
      {
        ...WebHelpers.getAuthHeaders(),
        cancelToken: new CancelToken(fn => {
          cancelFunctions.push(fn);
        }),
      },
    );
  };

  static getTextLinkSetup = loginID => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/account/setup/textlink/${loginID}`);
  };

  static isTextLinkActivated = () => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/account/setup/textlink/setup/isactivated`);
  };

  static postOfficeSetup = async (sendObjectOfficeSetup, curLoginID) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/account/setup/office`,
          {
            office_setup: sendObjectOfficeSetup,
            curLoginID,
          },
          {
            ...WebHelpers.getAuthHeaders(),
            cancelToken: new CancelToken(fn => {
              cancelFunctions.push(fn);
            }),
          },
        )
        .then(res => {
          console.log(res);
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static isEFINValidated = efin => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${XLINK_API_URL}/is-efin-validated/${efin}`, WebHelpers.getAuthHeaders())
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static getEROSetup = (sameAsOffice, curLoginID) => {
    if (sameAsOffice) {
      return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/account/setup/ero/office/${curLoginID}`);
    } else {
      return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/account/setup/ero/${curLoginID}`);
    }
  };

  // ERO Setup
  static postEROSetup = async submitEROData => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/account/setup/ero`,
          {
            ero_setup: submitEROData,
          },
          {
            ...WebHelpers.getAuthHeaders(),
            cancelToken: new CancelToken(fn => {
              cancelFunctions.push(fn);
            }),
          },
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  /// /////////////////////////////////////////////////////////////////////
  // Posting New Preparer Setup 12/02/2018
  // Include shortcutID 01/11/19 -SH
  // postNewPreparer
  static postNewPreparer = (newPreparerData, currLoginID) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/account/setup/upload/newPreparer/${currLoginID}`,
          {
            shortcutID: newPreparerData.npPreparerID,
            thirdPartyPIN: newPreparerData.npThirdPartyPIN,
            CAF: newPreparerData.npCAF,
            selfEmployed: newPreparerData.npSelfEmployed,
            userName: newPreparerData.userName,
            SSN: newPreparerData.npPreparerSSN,
            PTIN: newPreparerData.npPreparerPTIN,
            preparerType: newPreparerData.npPreparerType,
            emailAddress: newPreparerData.emailAddress,
            confirmEmailAddress: newPreparerData.confirmEmail,
            cellPhone: newPreparerData.npCellPhone,
            cellPhoneCarrier: newPreparerData.npCellPhoneCarrier,
            stateCodeOne: newPreparerData.npStateCodeOne,
            stateIDOne: newPreparerData.npStateIDOne,
            stateCodeTwo: newPreparerData.npStateCodeTwo,
            stateIDTwo: newPreparerData.npStateIDTwo,
            sameAsOffice: newPreparerData.npSameAsMainOffice,
            firmName: newPreparerData.npFirmName,
            EIN: newPreparerData.npEIN,
            bankCode: newPreparerData.npBankCode,
            firmAddress: newPreparerData.npFirmAddress,
            city: newPreparerData.npCity,
            state: newPreparerData.npState,
            zip: newPreparerData.npZip,
            officePhone: newPreparerData.npOfficePhone,
            electronicFilingID: newPreparerData.npElectronicFilingID,
            defaultPIN: newPreparerData.npDefaultPIN,
          },
          {
            ...WebHelpers.getAuthHeaders(),
            cancelToken: new CancelToken(fn => {
              cancelFunctions.push(fn);
            }),
          },
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  // Getting all preparers for a particular office
  static getAllPreparersList = loginID => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/account/preparers/${loginID}`);
  };

  // Getting setup for preparer based on login ID
  static getPreparerSetup = () => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/account/setup/get/preparer`);
    // return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/account/setup/office/52`)
  };

  static getPreparerInfo = preparerID => {
    return XlinkAPI.simpleGetRequest(
      `${XLINK_API_URL}/account/setup/get/preparerDetail/${preparerID}`,
    );
  };

  static updatePreparer = (newPreparerData, preparerID, currLoginID) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/account/setup/upload/updatePreparer/${preparerID}/${currLoginID}`,
          {
            shortcutID: newPreparerData.npPreparerID,
            thirdPartyPIN: newPreparerData.npThirdPartyPIN,
            CAF: newPreparerData.npCAF,
            selfEmployed: newPreparerData.npSelfEmployed,
            userName: newPreparerData.userName,
            SSN: newPreparerData.npPreparerSSN,
            PTIN: newPreparerData.npPreparerPTIN,
            preparerType: newPreparerData.npPreparerType,
            emailAddress: newPreparerData.emailAddress,
            confirmEmailAddress: newPreparerData.confirmEmail,
            cellPhone: newPreparerData.npCellPhone,
            cellPhoneCarrier: newPreparerData.npCellPhoneCarrier,
            stateCodeOne: newPreparerData.npStateCodeOne,
            stateIDOne: newPreparerData.npStateIDOne,
            stateCodeTwo: newPreparerData.npStateCodeTwo,
            stateIDTwo: newPreparerData.npStateIDTwo,
            sameAsOffice: newPreparerData.npSameAsMainOffice,
            firmName: newPreparerData.npFirmName,
            EIN: newPreparerData.npEIN,
            bankCode: newPreparerData.npBankCode,
            firmAddress: newPreparerData.npFirmAddress,
            city: newPreparerData.npCity,
            state: newPreparerData.npState,
            zip: newPreparerData.npZip,
            officePhone: newPreparerData.npOfficePhone,
            electronicFilingID: newPreparerData.npElectronicFilingID,
            addressContactID: newPreparerData.addrContactID,
            defaultPIN: newPreparerData.npDefaultPIN,
          },
          {
            ...WebHelpers.getAuthHeaders(),
            cancelToken: new CancelToken(fn => {
              cancelFunctions.push(fn);
            }),
          },
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  /// ///////////////////////////////////////////////////////////////////////

  // General Billing Office Info
  static getBillingOfficeInfo = (billingID, loginID, schemaType = BILLING_SCHEMA.INDIVIDUAL) => {
    return XlinkAPI.simpleGetRequest(
      `${XLINK_API_URL}/account/setup/billing/info/${loginID}/${billingID}/${schemaType}`,
    );
  };

  static handleFormRowDeletion = (itemID, billid, itemType, loginID) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/account/setup/billing/scheme/delete/${billid}/${itemID}/${itemType}/${loginID}`,
          {},
          {
            ...WebHelpers.getAuthHeaders(),
            cancelToken: new CancelToken(fn => {
              cancelFunctions.push(fn);
            }),
          },
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static handleBillingSchemeDeletion = (billid, loginID) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/account/setup/billing/scheme/delete/${billid}/${loginID}`,
          {},
          {
            ...WebHelpers.getAuthHeaders(),
            cancelToken: new CancelToken(fn => {
              cancelFunctions.push(fn);
            }),
          },
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static upsertBusinessBillingScheme = async (
    formBilling,
    customItems,
    discountItems,
    address,
    sameAsOffice,
    selectedScheme,
    loginID,
  ) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/account/setup/billing/scheme/update/business/${loginID}`,
          {
            billingItems: formBilling,
            customBillingItems: customItems,
            discountBillingItems: discountItems,
            address,
            sameAsOffice,
            billing_id: parseInt(selectedScheme),
          },
          {
            ...WebHelpers.getAuthHeaders(),
            cancelToken: new CancelToken(fn => {
              cancelFunctions.push(fn);
            }),
          },
        )
        .then(res => {
          console.log(res);
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static upsertBillingScheme = async (
    formBilling,
    discountsBilling,
    customChargesBilling,
    addressInfo,
    billOptsAndRate,
    sameInfo,
    selectedScheme,
    loginID,
    customCodeDiscounts = null,
  ) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/account/setup/billing/scheme/update/${loginID}`,
          {
            billingItems: formBilling,
            discountItems: discountsBilling,
            customItems: customChargesBilling,
            billing_id: parseInt(selectedScheme),
            address: addressInfo,
            billOptsAndRate,
            sameAsOffice: sameInfo === true ? 1 : 0,
            discountCustomItems: customCodeDiscounts,
          },
          {
            ...WebHelpers.getAuthHeaders(),
            cancelToken: new CancelToken(fn => {
              cancelFunctions.push(fn);
            }),
          },
        )
        .then(res => {
          console.log(res);
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  // General billing office info setup POST
  static postBillingOfficeInfo = async billingDataObj => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/account/setup/billing/info`,
          {
            billingDataObj,
          },
          {
            ...WebHelpers.getAuthHeaders(),
            cancelToken: new CancelToken(fn => {
              cancelFunctions.push(fn);
            }),
          },
        )
        .then(res => {
          console.log(res);
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static addNewBillingScheme = async (
    billingSchemaName,
    loginID,
    schemaType = BILLING_SCHEMA.INDIVIDUAL,
  ) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/account/setup/billing/scheme/new/${loginID}/${schemaType}`,
          {
            newSchemeName: billingSchemaName,
          },
          {
            ...WebHelpers.getAuthHeaders(),
            cancelToken: new CancelToken(fn => {
              cancelFunctions.push(fn);
            }),
          },
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  // General Billing Office Info
  static setDefaultBillingScheme = async (
    billingID,
    loginID,
    schemaType = BILLING_SCHEMA.INDIVIDUAL,
  ) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/account/setup/billing/scheme/default/${billingID}/${loginID}/${schemaType}`,
          {},
          {
            ...WebHelpers.getAuthHeaders(),
            cancelToken: new CancelToken(fn => {
              cancelFunctions.push(fn);
            }),
          },
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static getEFileSetup = curLoginID => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/account/setup/efile/${curLoginID}`);
  };

  static updateEFileSetup = async (eFileConfig, curLoginID) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/account/setup/efile`,
          {
            config: eFileConfig,
            curLoginID,
          },
          {
            ...WebHelpers.getAuthHeaders(),
          },
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static getSignatureSetup = curLoginID => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/account/setup/Signature/${curLoginID}`);
  };

  static updateSignatureeSetup = async (signatureConfig, curLoginID) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/account/setup/Signature`,
          {
            config: signatureConfig,
            curLoginID,
          },
          {
            ...WebHelpers.getAuthHeaders(),
          },
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static getPrintingSetup = curLoginID => {
    // console.log("getPrintingSetup() called " + `${XLINK_API_URL}/account/setup/printing/${loginID}`)
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/account/setup/printing/${curLoginID}`);
  };

  static getBillingDetails = (entityID, billingType, state) => {
    return XlinkAPI.simpleGetRequest(
      `${XLINK_API_URL}/account/setup/billing/${entityID}/${billingType}/${state}`,
    );
  };

  static updatePrintingSetup = async (printConfig, curLoginID) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/account/setup/printing`,
          {
            config: printConfig,
            curLoginID,
          },
          {
            ...WebHelpers.getAuthHeaders(),
          },
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static getAuditSetup = curLoginID => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/account/setup/auditing/${curLoginID}`); // TODO: Hardcoded Season
  };

  static updateAuditSetup = async (auditConfig, curLoginID) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/account/setup/auditing`,
          {
            config: auditConfig,
            curLoginID,
          },
          {
            ...WebHelpers.getAuthHeaders(),
            cancelToken: new CancelToken(fn => {
              cancelFunctions.push(fn);
            }),
          },
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static getDefaultsSetup = curLoginID => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/account/setup/defaults/${curLoginID}`);
  };

  static updateDefaultsSetup = async (defaultsConfig, curLoginID) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/account/setup/defaults`,
          {
            config: defaultsConfig,
            curLoginID,
          },
          {
            ...WebHelpers.getAuthHeaders(),
            cancelToken: new CancelToken(fn => {
              cancelFunctions.push(fn);
            }),
          },
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static addNewLogin = req => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${BASE_URL}/authz/addlogin`, req, {
          ...WebHelpers.urlEncodedContentType(),
          ...WebHelpers.getAuthHeaders(),
        })
        .then(res => resolve(res))
        .catch(error => reject(error));
    });
  };

  static fetchAccessID = () => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/techsupport/accessroleID`);
  };

  static upsertUserDB = (dbType, loginID, dbData) => {
    return axios.post(
      `${XLINK_API_URL}/userdb/upsert`,
      {
        db_type: dbType,
        loginID,
        table_data: dbData,
      },
      {
        ...WebHelpers.urlEncodedContentType(),
        ...WebHelpers.getAuthHeaders(),
        cancelToken: new CancelToken(fn => {
          cancelFunctions.push(fn);
        }),
      },
    );
  };

  static deleteUserDBRecord = (dbType, loginID, key) => {
    return axios.post(
      `${XLINK_API_URL}/userdb/deleterecord`,
      {
        db_type: dbType,
        loginID,
        key,
      },
      {
        ...WebHelpers.urlEncodedContentType(),
        ...WebHelpers.getAuthHeaders(),
        cancelToken: new CancelToken(fn => {
          cancelFunctions.push(fn);
        }),
      },
    );
  };

  static editLogin = req => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${BASE_URL}/authz/editlogin`, req, {
          ...WebHelpers.urlEncodedContentType(),
          ...WebHelpers.getAuthHeaders(),
        })
        .then(res => resolve(res))
        .catch(error => reject(error));
    });
  };

  static updateEmailAddress = req => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${BASE_URL}/api/update/emailaddress`, req, {
          ...WebHelpers.urlEncodedContentType(),
          ...WebHelpers.getAuthHeaders(),
        })
        .then(res => resolve(res))
        .catch(error => reject(error));
    });
  };

  static updateStatusOfLogin = (loginID, status) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/techsupport/updatestatus`,
          {
            loginID,
            status,
          },
          {
            ...WebHelpers.urlEncodedContentType(),
            ...WebHelpers.getAuthHeaders(),
          },
        )
        .then(res => resolve(res))
        .catch(error => reject(error));
    });
  };

  static deleteLogin = loginID => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${BASE_URL}/api/logins/login/${loginID}`, {
          ...WebHelpers.urlEncodedContentType(),
          ...WebHelpers.getAuthHeaders(),
        })
        .then(res => resolve(res))
        .catch(error => reject(error));
    });
  };

  // Adding Preparer Setup 09/07/2018
  /*
    static getPreparerSetup = () => {
        let payload = WebHelpers.getJWTPayload();
        return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/account/setup/ero/${payload.login_id}`)
        //return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/account/setup/preparer/52`)
    }

    //Preparer Setup
    static addPreparerSetup = (submitPreparerData) => {
        console.log(submitPreparerData.submitLockFinishedPaper)
        return new Promise((resolve, reject) => {
            axios.post(`${XLINK_API_URL}/account/newPreparerSetup`, {
                submitPreparerData: submitPreparerData
            }, {
                    ...WebHelpers.getAuthHeaders(),
                    cancelToken: new CancelToken((fn) => {
                        cancelFunctions.push(fn)
                    })
                })
                .then((res) => {
                    console.log(res)
                    return resolve(res)
                })
                .catch((error) => {
                    return reject(error)
                })
        })
    }

    //New Preparer Setup (Modal)
    static addNewPreparerSetup = (newPreparerData) => {
        console.log(newPreparerData.submitnpPreparerID)
        return new Promise((resolve, reject) => {
            axios.post(`${XLINK_API_URL}/account/addPreparerSetupModal`, {
                newPreparerData: newPreparerData
            }, {
                    ...WebHelpers.getAuthHeaders(),
                    cancelToken: new CancelToken((fn) => {
                        cancelFunctions.push(fn)
                    })
                })
                .then((res) => {
                    console.log(res)
                    return resolve(res)
                })
                .catch((error) => {
                    return reject(error)
                })
        })
    }
    */
  /// //////////////////////////////////////////////////////////////////////////////////////////
  /// ///////////////////////////////////////////////////////////////////////////////////////

  /// ////////////////////////////////////////////////////////////////////////
  /// /////////// New Preparer Setup (Modal) Now called
  /*
    static getNewPreparerSetup = () => {
        let payload = WebHelpers.getJWTPayload();
        return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/account/setup/newPreparer/${payload.login_id}`)
    }
    */

  /*
    static postNewPreparerSetup = (newPreparerData) => {
        let payload = WebHelpers.getJWTPayload();
        return new Promise((resolve, reject) => {
            axios.post(`${XLINK_API_URL}/account/setup/newPreparer`, {
                login_id: payload.login_id,
                newpreparer_setup: newPreparerData
            }, WebHelpers.getAuthHeaders())
                .then((res) => {
                    console.log(res)
                    return resolve(res)
                })
                .catch((error) => {
                    return reject(error)
                })
        })
    }
    */

  /// ////////////////////////////////////////////////////////////////////////
  /// /////////// New Preparer Setup (Modal) Now called
  /*
    static getNewPreparerSetup = () => {
        let payload = WebHelpers.getJWTPayload();
        return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/account/setup/newPreparer/${payload.login_id}`)
    }
    */

  /*
    static postNewPreparerSetup = (newPreparerData) => {
        let payload = WebHelpers.getJWTPayload();
        return new Promise((resolve, reject) => {
            axios.post(`${XLINK_API_URL}/account/setup/newPreparer`, {
                login_id: payload.login_id,
                newpreparer_setup: newPreparerData
            }, WebHelpers.getAuthHeaders())
                .then((res) => {
                    console.log(res)
                    return resolve(res)
                })
                .catch((error) => {
                    return reject(error)
                })
        })
    }
    */

  // Check Setup
  static addUserCheckRangeSetup = async (check, loginID) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/account/newCheck/${loginID}`,
          {
            starting_chk_number: check.startingCheckNum,
            ending_chk_number: check.endingCheckNum,
            SiteIDKey: check.siteIDKey,
            site_code: check.siteCode,
            bank_id: check.bankId,
            check_layout_version: check.checkLayoutCode,
          },
          {
            ...WebHelpers.getAuthHeaders(),
            cancelToken: new CancelToken(fn => {
              cancelFunctions.push(fn);
            }),
          },
        )
        .then(res => {
          console.log(res);
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static updateUserCheck = (check, checkid, loginID) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/account/checks/update/${loginID}`,
          {
            starting_chk_number: check.startingCheckNum,
            ending_chk_number: check.endingCheckNum,
            SiteIDKey: check.siteIDKey,
            site_code: check.siteCode,
            bank_id: check.bankId,
            check_id: checkid,
            check_layout_version: check.checkLayoutCode,
          },
          {
            ...WebHelpers.getAuthHeaders(),
          },
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static getReport = async (currLoginID, report, filterParams, rmsCompletionFilter) => {
    return axios.post(
      `${XLINK_API_URL}/reports/reportquery/${currLoginID}`,
      {
        report,
        filters: filterParams,
        rmsCompleteFilter: rmsCompletionFilter,
      },

      {
        ...WebHelpers.urlEncodedContentType(),
        ...WebHelpers.getAuthHeaders(),
      },
    );
  };

  static getReturnIDs = currLoginID => {
    return axios.post(
      `${XLINK_API_URL}/reports/returnids`,
      currLoginID,

      {
        ...WebHelpers.urlEncodedContentType(),
        ...WebHelpers.getAuthHeaders(),
      },
    );
  };

  static saveReport = async (reportName, reportBlob) => {
    return axios.post(
      `${XLINK_API_URL}/reports/saveReport`,
      {
        reportName,
        reportBlob,
      },

      {
        ...WebHelpers.urlEncodedContentType(),
        ...WebHelpers.getAuthHeaders(),
      },
    );
  };

  static deleteReport = async reportName => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${XLINK_API_URL}/reports/deletereport/${reportName}`, {
          ...WebHelpers.getAuthHeaders(),
        })
        .then(resp => resolve(resp))
        .catch(error => reject(error));
    });
  };

  static getSavedReports = loginID => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${XLINK_API_URL}/reports/getSavedReports/${loginID}`, {
          ...WebHelpers.getAuthHeaders(),
          cancelToken: new CancelToken(fn => {
            cancelFunctions.push(fn);
          }),
        })
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static getQuickReports = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${XLINK_API_URL}/reports/getQuickReports`, {
          ...WebHelpers.getAuthHeaders(),
          cancelToken: new CancelToken(fn => {
            cancelFunctions.push(fn);
          }),
        })
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static printCheckByCheckID = async (
    loginID,
    checkIDS,
    checkStartingNumber,
    isTestPrint,
    bankId,
  ) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/account/checks/print/${loginID}/${checkStartingNumber}`,
          {
            is_test_print: isTestPrint,
            bank_id: bankId,
            check_ids: checkIDS,
          },
          {
            ...WebHelpers.getAuthHeaders(),
            cancelToken: new CancelToken(fn => {
              cancelFunctions.push(fn);
            }),
          },
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static voidCheckRegistryNumber = async (voidNotes, registryId, checkMetaDataId, checkNumber) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/account/checks/registry/void`,
          {
            void_notes: voidNotes,
            registry_id: registryId,
            check_meta_data_id: checkMetaDataId,
            check_number: parseInt(checkNumber),
          },
          {
            ...WebHelpers.getAuthHeaders(),
            cancelToken: new CancelToken(fn => {
              cancelFunctions.push(fn);
            }),
          },
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static registerCheckPrintingStatus = async (
    loginID,
    checkMetaId,
    checkStatus,
    checkRegisterId,
  ) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/account/checks/print/registerprinted/${loginID}`,
          {
            check_meta_id: checkMetaId,
            check_status: checkStatus,
            check_register_id: checkRegisterId,
          },
          {
            ...WebHelpers.getAuthHeaders(),
            cancelToken: new CancelToken(fn => {
              cancelFunctions.push(fn);
            }),
          },
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static queueItemsForCheckReprint = async reprintList => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/account/checks/registry/reprint`,
          {
            registry_reprint_list: reprintList,
          },
          {
            ...WebHelpers.getAuthHeaders(),
            cancelToken: new CancelToken(fn => {
              cancelFunctions.push(fn);
            }),
          },
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static getUserChecks = curLoginID => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/account/checks/${curLoginID}`);
  };

  static getChecksReadyToPrint = (loginID, bankID) => {
    return XlinkAPI.simpleGetRequest(
      `${XLINK_API_URL}/account/checks/print/list/${loginID}/${bankID}`,
    );
  };

  static deleteReadyToPrintCheck = async checkMetaDataID => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/account/checks/delete-office-check/${checkMetaDataID}`,
          {},
          {
            ...WebHelpers.getAuthHeaders(),
            cancelToken: new CancelToken(fn => {
              cancelFunctions.push(fn);
            }),
          },
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static getCheckBadgeCount = loginID => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/account/checks/count/${loginID}`);
  };

  static getChecksRegistry = (loginID, ssn, name, beforeDate, afterDate) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/account/checks/registry`,
          {
            check_ssn_filter: ssn,
            check_name_filter: name,
            check_before_date_filter: beforeDate,
            check_after_date_filter: afterDate,
            login_id: loginID,
          },
          {
            ...WebHelpers.getAuthHeaders(),
          },
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static getAncillaryProductsInfoByLoginID = () => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/account/ancillaryproducts/purchased`);
  };

  static saveAncillaryProductsInfoByLoginID = (
    loginID,
    purchaseInfo,
    markupFee,
    financials,
    nonFinancials,
  ) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/account/ancillaryProducts/purchased`,
          {
            purchaseInfo,
            add_on_markup_fee: markupFee,
            financials: financials === 'true',
            non_financials: nonFinancials === 'true',
          },
          {
            ...WebHelpers.getAuthHeaders(),
          },
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static checkIfCheckNumIsInUser = async (loginID, checkStartingNumber, numChecks, bank) => {
    const res = await XlinkAPI.simpleGetRequest(
      `${XLINK_API_URL}/account/checks/print/chknuminuse/${loginID}/${checkStartingNumber}/${numChecks}/${bank}`,
    );
    if (get(res, 'data.check_numbers_in_use', 0) !== 0) {
      return res;
    } else {
      throw ErrorHelpers.createXlinkcloudError('Response is not in a valid format');
    }
  };

  // Search

  static search = (id, value, roleGroup, criteria) => {
    // id is loginID or perparerID
    return XlinkAPI.simpleGetRequest(
      `${XLINK_API_URL}/search/${roleGroup}/${id}/${value}/${criteria}`,
    );
  };

  static getBreadcrumbPath = (
    parentLoginID,
    parentPreparerID,
    leafId,
    leafType,
    leafName,
    leafEfinID,
  ) => {
    return axios.post(
      `${XLINK_API_URL}/hierarchy/entitypath`,
      {
        parent_id: parentLoginID,
        parent_preparer_id: parentPreparerID,
        leaf_id: leafId,
        leaf_type: leafType,
        leaf_name: leafName,
        leaf_efin_id: leafEfinID,
      },
      {
        ...WebHelpers.getAuthHeaders(),
        cancelToken: new CancelToken(fn => {
          cancelFunctions.push(fn);
        }),
      },
    );
  };

  // Utility

  static simpleGetRequest = async (endpoint, axiosCancelSource = null) => {
    return new Promise((resolve, reject) => {
      return axios
        .get(endpoint, {
          ...WebHelpers.getAuthHeaders(),
          cancelToken: axiosCancelSource
            ? axiosCancelSource?.token // handles canceling axios request on unmount
            : new CancelToken(c => {
                cancelFunctions.push(c);
              }),
        })
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          // request was cancelled, ignore
          if (axios.isCancel(error)) {
            console.log(error?.message);
            return;
          }

          return reject(error);
        });
    });
  };

  static simplePostRequest = async (endpoint, body = {}) => {
    return new Promise((resolve, reject) => {
      axios
        .post(endpoint, body, {
          ...WebHelpers.getAuthHeaders(),
          cancelToken: new CancelToken(c => {
            cancelFunctions.push(c);
          }),
        })
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          if (axios.isCancel(error)) {
            // request was cancelled, ignore
            return;
          }
          return reject(error);
        });
    });
  };

  static post = async (endpoint, body = {}) => {
    return axios.post(endpoint, body, {
      ...WebHelpers.urlEncodedContentType(),
      ...WebHelpers.getAuthHeaders(),
    });
  };

  static simplePagedRequest = async (endpoint, limit, offset, body = {}) => {
    return axios.post(
      endpoint,
      {
        ...body,
        limit,
        offset,
      },
      {
        ...WebHelpers.urlEncodedContentType(),
        ...WebHelpers.getAuthHeaders(),
      },
    );
  };

  static simpleNoBodyPagedRequest = (endpoint, limit, offset) => {
    const params = `limit=${limit}&offset=${offset}`;

    return axios.post(endpoint, params, {
      ...WebHelpers.urlEncodedContentType(),
      ...WebHelpers.getAuthHeaders(),
    });
  };

  static getURLEncodedFilterParams = filterParams => {
    const officeLoginID = filterParams.officeLoginID ? filterParams.officeLoginID : 0;
    const officeLoginIDFilter = filterParams.officeLoginIDFilter
      ? filterParams.officeLoginIDFilter
      : 0;
    return `loginID=${filterParams.loginID}&preparerID=${filterParams.preparerID}&officeLoginID=${officeLoginID}&filterID=${filterParams.filterID}&page=${filterParams.page}&perPage=${filterParams.perPage}&filterType=${filterParams.filterType}&colName=${filterParams.colName}&sortOrder=${filterParams.sortOrder}&filterValue1=${filterParams.filterValue1}&filterValue2=${filterParams.filterValue2}&roleGroup=${filterParams.roleGroup}&officeLoginIDFilter=${officeLoginIDFilter}`;
  };

  static getFilterRouteQuery = filterParams => {
    if (filterParams.filterID === TAX_RETURN_LIST_FILTERS.ALL) {
      return 'list';
    } else if (filterParams.filterValue2 !== undefined && filterParams.filterValue2 !== '') {
      return 'datefilteredList';
    } else {
      return 'listfiltered';
    }
    // switch(filterParams.filterID){
    //     case 0:
    //         return "list"
    //     case TAX_RETURN_LIST_FILTERS.DATE:
    //         return "datefilteredList"
    //     default:
    //         return "listfiltered"
    // }
  };

  static getFilterRoute = filterParams => {
    const {
      loginID,
      preparerID,
      page,
      perPage,
      colName,
      sortOrder,
      filterType,
      filterValue1,
      filterValue2,
      roleGroup,
    } = filterParams;
    let endpoint = '';

    if (filterParams.filterID === TAX_RETURN_LIST_FILTERS.ALL) {
      endpoint += `${roleGroup}/${loginID}/${preparerID}/${page}/${perPage}/${colName}/${sortOrder}`;
    } else if (filterParams.filterValue2 == null || filterParams.filterValue2 === '') {
      endpoint += `${roleGroup}/${loginID}/${preparerID}/${page}/${perPage}/${filterType}/${filterValue1}/${filterValue2}/${colName}/${sortOrder}`;
    } else {
      endpoint += `${roleGroup}/${loginID}/${preparerID}/${page}/${perPage}/${filterType}/${filterValue1}/${colName}/${sortOrder}`;
    }

    // switch (filterID) {
    //     case 0:
    //         endpoint += `${roleGroup}/${loginID}/${page}/${perPage}/${colName}/${sortOrder}`
    //         break
    //     case TAX_RETURN_LIST_FILTERS.DATE:
    //         endpoint += `${roleGroup}/${loginID}/${page}/${perPage}/${filterType}/${filterValue1}/${filterValue2}/${colName}/${sortOrder}`
    //         break
    //     default:
    //         endpoint += `${roleGroup}/${loginID}/${page}/${perPage}/${filterType}/${filterValue1}/${colName}/${sortOrder}`
    //         break
    // }
    return endpoint;
  };

  static sendMessage = messageData => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/message/send`,
          {
            from_login_id: messageData.from_login_id,
            from_role: messageData.from_role,
            to_login_id: messageData.to_login_id,
            broadcast_flag: messageData.broadcast_flag,
            broadcast_to_role: messageData.broadcast_to_role,
            subject: messageData.subject,
            body: messageData.body,
            reply: messageData.reply,
          },
          {
            ...WebHelpers.getAuthHeaders(),
            cancelToken: new CancelToken(fn => {
              cancelFunctions.push(fn);
            }),
          },
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static deleteMessage = messageSentID => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/message/delete`,
          {
            message_sent_id: messageSentID,
          },
          {
            ...WebHelpers.getAuthHeaders(),
            cancelToken: new CancelToken(fn => {
              cancelFunctions.push(fn);
            }),
          },
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static dismissNotification = id => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/notification/dismiss/${id}`);
  };

  static getAllNotifications = () => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/notification/get`);
  };

  static getUnreadMessagesCount = () => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/messagecount/getunreadcount`);
  };

  static getMessageList = (sortOrder, msgType) => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/message/${sortOrder}/${msgType}`);
  };

  static getMessageByID = messageID => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/message/${messageID}`);
  };

  static getReturnMessagesByReturnID = returnID => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/returnmessages/${returnID}`);
  };

  static getUnreadReturnMessagesExistByReturnID = returnID => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/unreadmessageexist/${returnID}`);
  };

  static getSentMessageByID = messageID => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/sentmessage/${messageID}`);
  };

  static getCustomerList = (loginID, entityType) => {
    return XlinkAPI.simpleGetRequest(
      `${XLINK_API_URL}/hierarchy/customer/list/${loginID}/${entityType}`,
    );
  };

  static getPreparerList = loginID => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/hierarchy/preparer/list/${loginID}`);
  };

  static getLinkablePreparerList = loginID => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/hierarchy/preparer/linkable/${loginID}`);
  };

  static getMyLogins = () => {
    return XlinkAPI.post(`${BASE_URL}/api/logins`);
  };

  static getMyAccessLevels = (limit, offset = 0) => {
    return XlinkAPI.simplePagedRequest(`${BASE_URL}/authz/accessGroups`, limit, offset);
  };

  static fetchAccessRoles = () => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/accessroles`);
  };

  static fetchAccessLevelsForRole = (roleID, isCustom) => {
    return axios.post(
      `${XLINK_API_URL}/fetch/accessrole`,
      {
        roleID: parseInt(roleID),
        isCustom,
      },
      {
        ...WebHelpers.getAuthHeaders(),
        cancelToken: new CancelToken(fn => {
          cancelFunctions.push(fn);
        }),
      },
    );
  };

  static fetchAccessLevelsByRoleID = (roleID, isCustom) => {
    return axios.get(`${XLINK_API_URL}/fetch/access-levels/${isCustom}/${roleID}`, {
      ...WebHelpers.getAuthHeaders(),
      cancelToken: new CancelToken(fn => {
        cancelFunctions.push(fn);
      }),
    });
  };

  static upsertAccessLevelsForRole = (roleID, accessLevelsForRole, roleName) => {
    return axios.post(
      `${XLINK_API_URL}/upsert/accesslevels`,
      {
        roleID: parseInt(roleID),
        accessLevels: accessLevelsForRole,
        roleName,
      },
      {
        ...WebHelpers.getAuthHeaders(),
        cancelToken: new CancelToken(fn => {
          cancelFunctions.push(fn);
        }),
      },
    );
  };

  static getParentEntity = () => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/hierarchy/parent`);
  };

  static getOfficesForPreparer = preparerID => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/hierarchy/preparer/offices/${preparerID}`);
  };

  static setMessageStatus = (loginID, messageID, status) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/message/status`,
          {
            message_id: messageID,
            status,
          },
          {
            ...WebHelpers.getAuthHeaders(),
            cancelToken: new CancelToken(fn => {
              cancelFunctions.push(fn);
            }),
          },
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  // Gets theme from db
  static getCurrentTheme = (userID, themeName) => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/theme/WhatGoesHere/${userID}/${themeName}`);
  };

  // Update Theme
  static updateTheme = async (userID, themeName) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/theme/WhatGoesHere`,
          {
            user_id: userID,
            theme_name: themeName,
          },
          {
            ...WebHelpers.getAuthHeaders(),
            cancelToken: new CancelToken(fn => {
              cancelFunctions.push(fn);
            }),
          },
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  /// ////////////////////////////////////
  // Appointments

  // upsert appointment
  static upsertAppointment = appointmentData => {
    const payload = WebHelpers.getJWTPayload();
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/appointment/upsert`,
          {
            userID: payload.login_id,
            appointmentID: appointmentData.appointmentID,
            firstName: appointmentData.firstName,
            lastName: appointmentData.lastName,
            startTime: appointmentData.startTime,
            endTime: appointmentData.endTime,
            subject: appointmentData.subject,
            comments: appointmentData.comments,
            phone: appointmentData.phoneNumber,
          },
          {
            ...WebHelpers.getAuthHeaders(),
            cancelToken: new CancelToken(fn => {
              cancelFunctions.push(fn);
            }),
          },
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static checkForSchedulingConflict = (loginID, startTime, endTime) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/appointment/checkconflict`,
          {
            loginID,
            startDateTime: startTime,
            endDateTime: endTime,
          },
          {
            ...WebHelpers.getAuthHeaders(),
          },
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  // Checking to see if the logged in user is an EFIN owner
  static getEfinOwner = () => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/taxprep/getEfinOwner`);
  };

  // Fetch the document type list from the archive document loopup types table
  static getDocumentTypeList = () => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/taxprep/getDocumentTypeList`);
  };

  static getTextMessageClientInfo = returnID => {
    return XlinkAPI.simpleGetRequest(
      `${XLINK_API_URL}/taxprep/getTextMessageClientInfo/${returnID}`,
    );
  };

  // Check if office is linked to preparer
  static getAccountLinkedToPreparer = () => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/taxprep/getOfficeLinkedToPreparer`);
  };

  // Getting existing appointments for user based on user key
  static getCurrentAppointments = () => {
    const payload = WebHelpers.getJWTPayload();
    return XlinkAPI.simpleGetRequest(
      `${XLINK_API_URL}/appointment/getAppointments/${payload.login_id}`,
    );
  };

  // Getting "Today" existing appointments for user based on user key
  static getTodayCurrentAppointments = () => {
    const payload = WebHelpers.getJWTPayload();
    return XlinkAPI.simpleGetRequest(
      `${XLINK_API_URL}/appointment/getTodayAppointments/${payload.login_id}`,
    );
  };

  // Getting "Week" existing appointments for user based on user key for "Week" option
  static getThisWeekCurrentAppointments = () => {
    const payload = WebHelpers.getJWTPayload();
    return XlinkAPI.simpleGetRequest(
      `${XLINK_API_URL}/appointment/getThisWeekAppointments/${payload.login_id}`,
    );
  };

  // Getting "Upcoming" existing appointments for user based on user key
  static getUpcomingAppointments = () => {
    const payload = WebHelpers.getJWTPayload();
    return XlinkAPI.simpleGetRequest(
      `${XLINK_API_URL}/appointment/getUpcomingAppointments/${payload.login_id}`,
    );
  };

  // Getting "Past" existing appointments for user based on user key
  static getPastAppointments = () => {
    const payload = WebHelpers.getJWTPayload();
    return XlinkAPI.simpleGetRequest(
      `${XLINK_API_URL}/appointment/getPastAppointments/${payload.login_id}`,
    );
  };

  // deleteAppointmentByID deletes a specific appointment
  static deleteAppointmentByID = appointmentDeleteID => {
    const payload = WebHelpers.getJWTPayload();
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/appointment/delete`,
          {
            userID: payload.login_id,
            appointmentID: appointmentDeleteID,
          },
          {
            ...WebHelpers.getAuthHeaders(),
            cancelToken: new CancelToken(fn => {
              cancelFunctions.push(fn);
            }),
          },
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static getPreparerEmail = prepID => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${XLINK_API_URL}/account/preparer-email/${prepID}`, {
          ...WebHelpers.getAuthHeaders(),
          cancelToken: new CancelToken(fn => {
            cancelFunctions.push(fn);
          }),
        })
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static sendPreparerRMS = (prepID, email, prepName, officeName) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/account/preparer/send-rms/${prepID}`,
          {
            email,
            prepName,
            officeName,
          },
          {
            ...WebHelpers.getAuthHeaders(),
            cancelToken: new CancelToken(fn => {
              cancelFunctions.push(fn);
            }),
          },
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static getAccountLevelOffices = filter => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/admin/list/offices`,
          { filter: parseInt(filter) },
          {
            ...WebHelpers.getAuthHeaders(),
            cancelToken: new CancelToken(fn => {
              cancelFunctions.push(fn);
            }),
          },
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  /// ////
  // Second Factor email
  static getEmailForSecondFactor = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${BASE_URL}/reg/get/email`, {
          ...WebHelpers.getAuthHeaders(),
          cancelToken: new CancelToken(fn => {
            cancelFunctions.push(fn);
          }),
        })
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  /// ////
  // Second Factor email Mandrill Key for Cobranded Templates
  static getMandrillKeyForCobranded = () => {
    console.log('Called getMandrillKeyForCobranded on XLINKAPI');
    return new Promise((resolve, reject) => {
      axios
        .get(`${BASE_URL}/reg/get/mandrillkey`, {
          ...WebHelpers.getAuthHeaders(),
          cancelToken: new CancelToken(fn => {
            cancelFunctions.push(fn);
          }),
        })
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  // Get signature for preparer
  static getSignatureForPreparer = () => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/preparer/signature`);
  };

  // Get signature for ERO
  static getSignatureForERO = async () => {
    return await axios.get(`${XLINK_API_URL}/ero/signature`, {
      ...WebHelpers.getAuthHeaders(),
      cancelToken: new CancelToken(c => {
        cancelFunctions.push(c);
      }),
    });
  };

  /// ////////////////////////////////////
  // Office Logo Upload
  // officeLogoString is a string containing base 64 data
  static postOfficeLogo = (officeLogoString, imageName, imageType) => {
    console.log('Successful Call to postOfficeLogo');
    console.log(officeLogoString);
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${BASE_URL}/reg/logo`,
          {
            officeLogoString,
            imageName,
            imageType,
          },
          {
            ...WebHelpers.getAuthHeaders(),
            cancelToken: new CancelToken(fn => {
              cancelFunctions.push(fn);
            }),
          },
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  /// ////////////////////////////////////
  // postCodeAndSendEmail
  static postCodeAndSendEmail = email => {
    // console.log("Successful Call to postCodeAndSendEmail")
    // console.log(email);
    const payload = WebHelpers.getJWTPayload();
    const loginID = payload.login_id;
    let activeEfinID;
    if (payload.hierarchy_type_id === HIERARCHY_TYPE.PREPARER) {
      activeEfinID = payload.preparer_info.active_efin_id;
    }
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${BASE_URL}/reg/postcode/sendemail`,
          {
            loginID,
            email,
            activeEfinID,
          },
          {
            ...WebHelpers.getAuthHeaders(),
            cancelToken: new CancelToken(fn => {
              cancelFunctions.push(fn);
            }),
          },
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static fetchReturnProfile = returnID => {
    return axios.post(
      `${XLINK_API_URL}/fetch/returnprofile`,
      { returnID },
      {
        ...WebHelpers.getAuthHeaders(),
        cancelToken: new CancelToken(fn => {
          cancelFunctions.push(fn);
        }),
      },
    );
  };

  static fetchCrosslinkVersions = () => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/crosslinkversions`);
  };

  static saveReturn = returnID => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/taxprep/savereturn`,
          { returnID },
          {
            ...WebHelpers.getAuthHeaders(),
            cancelToken: new CancelToken(fn => {
              cancelFunctions.push(fn);
            }),
          },
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static fetchNewAssetList = async lstFile => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/fetch/assetlist`,
          {
            lstFile,
          },
          {
            ...WebHelpers.getAuthHeaders(),
            cancelToken: new CancelToken(fn => {
              cancelFunctions.push(fn);
            }),
          },
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  // WidgetStore

  static getFullWidgetDetails = async widgetType => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${XLINK_API_URL}/widget/widgetDetails/${widgetType}`, {
          ...WebHelpers.getAuthHeaders(),
          cancelToken: new CancelToken(fn => {
            cancelFunctions.push(fn);
          }),
        })
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  // TODO -- SHouldn't need to pass role. passing until payload can be extracted from auth header for xlinkcloud.
  static getWidgetStore = async () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${XLINK_API_URL}/widget/widgetStore`, {
          ...WebHelpers.getAuthHeaders(),
          cancelToken: new CancelToken(fn => {
            cancelFunctions.push(fn);
          }),
        })
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static onAddWidgetFromConfig = async widgetType => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/widget/addWidget`,
          {
            widgetType,
            role: WebHelpers.getJWTPayload().hierarchy_type_id, // TODO -- Pull payload in header in API.
          },
          {
            ...WebHelpers.getAuthHeaders(),
            cancelToken: new CancelToken(fn => {
              cancelFunctions.push(fn);
            }),
          },
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static saveWidgetConfig = async (layout, widgetList) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/widget/config`,
          {
            layout,
            widgetList,
          },
          {
            ...WebHelpers.getAuthHeaders(),
            cancelToken: new CancelToken(fn => {
              cancelFunctions.push(fn);
            }),
          },
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static loadWidgetConfig = async () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${XLINK_API_URL}/widget/config`, {
          ...WebHelpers.getAuthHeaders(),
          cancelToken: new CancelToken(fn => {
            cancelFunctions.push(fn);
          }),
        })
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static getDoughnutWidgetData = (filter, body) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${XLINK_API_URL}/widget/fetch/doughnut/${filter}`, body, {
          ...WebHelpers.urlEncodedContentType(),
          ...WebHelpers.getAuthHeaders(),
          cancelToken: new CancelToken(fn => {
            cancelFunctions.push(fn);
          }),
        })
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static acceptTermsAndConditions = () => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${BASE_URL}/authz/settc`,
          {
            optin: 1,
          },
          {
            ...WebHelpers.getAuthHeaders(),
            cancelToken: new CancelToken(fn => {
              cancelFunctions.push(fn);
            }),
          },
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static cancelAllRequests = async () => {
    cancelFunctions.forEach(cancel => {
      cancel();
    });
    cancelFunctions = [];
  };

  static clearMyLocks = async () => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/taxprep/clearmylocks`);
  };

  /// ////////////////////////////////////
  // Post Signature
  // signatureString is a string containing base 64 data
  static postSignature = async (signatureString, signaturePadType, signeeType) => {
    return await axios.post(
      `${XLINK_API_URL}/post/signature`,
      {
        signatureString,
        signaturePadType,
        signeeType: parseInt(signeeType),
      },
      {
        ...WebHelpers.getAuthHeaders(),
        cancelToken: new CancelToken(c => {
          cancelFunctions.push(c);
        }),
      },
    );
  };

  // ADMIN FUNCTIONS
  static getOrganization = accountID => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/user/account/summary/${accountID}`);
  };

  // Update Account Status
  static updateAccountStatusByAccountID = (accountID, statusID) => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/user/org/status/${accountID}/${statusID}`);
  };

  static getEventLogs = async returnID => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/taxprep/eventLog/${returnID}`);
  };

  static getSetupProgressIndicators = () => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/account/office/setup/progress`);
  };

  static getAccountCode = loginID => {
    return XlinkAPI.simpleGetRequest(
      `${XLINK_API_URL}/account/office/setup/accountCode/${loginID}`,
    );
  };

  static insertEventLog = async (returnID, pssn, event, eventDetails) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/taxprep/eventLog/new`,
          {
            rtnID: parseInt(returnID),
            pssn,
            event,
            eventDetails,
          },
          {
            ...WebHelpers.getAuthHeaders(),
            cancelToken: new CancelToken(fn => {
              cancelFunctions.push(fn);
            }),
          },
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static canEditReturn = async () => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/canEditReturn`);
  };

  static getPreparerSignature = async prepID => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/preparer/signatureByPrepID/${prepID}`);
  };

  static logUnhandledError = error => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${XLINK_API_URL}/logfrontenderror`, `error=${JSON.stringify(error.stack)}`, {
          ...WebHelpers.urlEncodedContentType(),
          ...WebHelpers.getAuthHeaders(),
          cancelToken: new CancelToken(fn => {
            cancelFunctions.push(fn);
          }),
        })
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  // Get ppSignatureExists checks if the signature data for preparer given a preparer shortcut id exists on db
  static getppSignatureExists = (preparerShortcutID, returnID) => {
    return XlinkAPI.simpleGetRequest(
      `${XLINK_API_URL}/preparer/exists/signaturebyshortcutid/${preparerShortcutID}/${returnID}`,
    );
  };

  // getefinSignatureExists checks if the signature data for an ERO exists on db, given an efin number
  static getefinSignatureExists = () => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/efin/exists/signaturebyefin`);
  };

  // getPreparerEmailForEncryptedPDF by preparer ID
  static getPreparerEmailForEncryptedPDF = () => {
    let prepID = 0;
    const payload = WebHelpers.getJWTPayload();
    // Handler for preparer
    if (payload.hierarchy_type_id === HIERARCHY_TYPE.PREPARER) {
      prepID = payload.preparer_info.preparer_id;
    } else {
      // If the user is not a preparer, that means they are drilled down
      const drilldownPayload = WebHelpers.getDrilldownToken();
      if (drilldownPayload !== undefined) {
        const tokenParts = drilldownPayload.split('.');
        const info = tokenParts[0];
        const decodedInfo = atob(info);
        const decodedObject = JSON.parse(decodedInfo);
        const drilledPreparer = decodedObject.drilled_preparer_id;
        prepID = drilledPreparer;
      }
    }

    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/prep/emailInfoByPrepID/${prepID}`);
  };

  // getppDataRemoteSignAuth gets the necessary preparer data for the remote signature authentication page given the preparer shortcut id
  static getppDataRemoteSignAuth = prepID => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/prep/remoteSign/authInfo/${prepID}`);
  };

  // Getting Data by preparer ID
  // getprepDataRemoteSignAuth gets the necessary preparer data for the remote signature authentication page given the preparer id
  static getprepDataRemoteSignAuth = () => {
    let prepID = 0;
    const payload = WebHelpers.getJWTPayload();
    // Handler for preparer
    if (payload.hierarchy_type_id === HIERARCHY_TYPE.PREPARER) {
      prepID = payload.preparer_info.preparer_id;
    } else {
      // If the user is not a preparer, that means they are drilled down
      const drilldownPayload = WebHelpers.getDrilldownToken();
      if (drilldownPayload !== undefined) {
        const tokenParts = drilldownPayload.split('.');
        const info = tokenParts[0];
        const decodedInfo = atob(info);
        const decodedObject = JSON.parse(decodedInfo);
        const drilledPreparer = decodedObject.drilled_preparer_id;
        prepID = drilledPreparer;
      }
    }

    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/prep/remoteSign/authInfoByPrepID/${prepID}`);
  };

  // getefinDataRemoteSignAuth gets the necessary ERO data for the remote signature authentication page, given the efin number
  static getefinDataRemoteSignAuth = efin => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/efin/remoteSign/authInfo/${efin}`);
  };

  // Getting Data by EFIN ID
  // getActiveEfinDataRemoteSignAuth gets the necessary ERO data for the remote signature authentication page, given the efin ID
  static getActiveEfinDataRemoteSignAuth = activeEfin => {
    let efinIDValue = 0;
    const payload = WebHelpers.getJWTPayload();
    // Handler for preparer
    if (payload.hierarchy_type_id === HIERARCHY_TYPE.PREPARER) {
      efinIDValue = payload.preparer_info.active_efin_id;
    } else {
      // If the user is not a preparer, that means they are drilled down
      if (activeEfin !== undefined || activeEfin !== '') {
        efinIDValue = activeEfin.activeEfin;
      }
    }
    return XlinkAPI.simpleGetRequest(
      `${XLINK_API_URL}/efin/remoteSign/authInfoByEfinID/${efinIDValue}`,
    );
  };

  static postSignatureByPrepID = async (sigValue, signaturePadType, prepID) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/post/setup/preparersignature`,
          {
            sigValue,
            signaturePadType,
            prepID,
          },
          {
            ...WebHelpers.getAuthHeaders(),
            cancelToken: new CancelToken(fn => {
              cancelFunctions.push(fn);
            }),
          },
        )
        .then(() => resolve())
        .catch(error => {
          return reject(error);
        });
    });
  };

  static getRemoteRequestsCount = async returnID => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${XLINK_API_URL}/taxprep/remotedocrequests/GetRemoteDocumentRequestsCount/${returnID}`,
          {
            ...WebHelpers.getAuthHeaders(),
            cancelToken: new CancelToken(fn => {
              cancelFunctions.push(fn);
            }),
          },
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static getRemoteDocumentRequestsList = async (returnID, pageNumber) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${XLINK_API_URL}/taxprep/remotedocrequests/list/${returnID}/${pageNumber}`, {
          ...WebHelpers.getAuthHeaders(),
          cancelToken: new CancelToken(fn => {
            cancelFunctions.push(fn);
          }),
        })
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static postRemoteDocumentAuthenticationData = async (
    returnID,
    requesteeType,
    email,
    subject,
    description,
    documentTypeID,
    remoteDocumentID,
    firmName,
  ) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/taxprep/remotedocrequests/RemoteDocAuthenticationRecord`,
          {
            returnID,
            requesteeType,
            email,
            subject,
            description,
            documentTypeID,
            remoteDocumentID,
            firmName,
          },
          {
            ...WebHelpers.getAuthHeaders(),
            cancelToken: new CancelToken(fn => {
              cancelFunctions.push(fn);
            }),
          },
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static deleteRemoteDocumentRequest = async (returnID, remoteDocumentID) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/taxprep/remotedocrequests/DeleteRemoteDocument`,
          {
            returnID,
            remoteDocumentID,
          },
          {
            ...WebHelpers.getAuthHeaders(),
            cancelToken: new CancelToken(fn => {
              cancelFunctions.push(fn);
            }),
          },
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static postRemoteSignatureRecord = async (
    returnID,
    csRequiredSignatures,
    pdfBlob,
    printGUID,
    messageBlob,
    bankID,
    signatureDocID,
    season,
  ) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/taxprep/remoteSign/InitialRecord`,
          {
            returnID: parseInt(returnID),
            csRequiredSignatures,
            pdfBlob,
            printGUID,
            messageBlob,
            bankID: parseInt(bankID),
            signatureDocID: parseInt(signatureDocID),
            season,
          },
          {
            ...WebHelpers.getAuthHeaders(),
            cancelToken: new CancelToken(fn => {
              cancelFunctions.push(fn);
            }),
          },
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static postRemoteSignatureAuthenticationData = async (
    sigDocID,
    email,
    cellPhone,
    cellCarrier,
    cellDomain,
    personSigningType,
    returnID,
    remoteSignatureOption,
    preparerEmail,
  ) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/taxprep/remoteSign/AuthenticationRecord`,
          {
            sigDocID: parseInt(sigDocID),
            email,
            cellPhone,
            cellCarrier,
            cellDomain,
            personSigningType,
            returnID,
            remoteSignatureOption,
            preparerEmail,
          },
          {
            ...WebHelpers.getAuthHeaders(),
            cancelToken: new CancelToken(fn => {
              cancelFunctions.push(fn);
            }),
          },
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  // postPreparerOrEroExistingRemoteSignature posts a preparer signature to the remote signature db
  static postPreparerOrEroExistingRemoteSignature = async (
    sigDocID,
    sigString,
    existingSignatureType,
  ) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/taxprep/remoteSign/PostExistingPreparerOrEroSignature`,
          {
            sigDocID: parseInt(sigDocID),
            sigString,
            existingSignatureType,
          },
          {
            ...WebHelpers.getAuthHeaders(),
            cancelToken: new CancelToken(fn => {
              cancelFunctions.push(fn);
            }),
          },
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  // postInOfficeSignatures
  static postInOfficeSignatures = async (sigDocID, sigValue, signatureType, returnID) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/taxprep/remoteSign/PostInOfficeSignature`,
          {
            sigDocID: parseInt(sigDocID),
            sigValue,
            signatureType,
            returnID,
          },
          {
            ...WebHelpers.getAuthHeaders(),
            cancelToken: new CancelToken(fn => {
              cancelFunctions.push(fn);
            }),
          },
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static invalidateRemoteSignature = async returnID => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/taxprep/remoteSign/invalidateRemoteSignature`,
          {
            returnID,
          },
          {
            ...WebHelpers.getAuthHeaders(),
          },
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  // emailEncryptedDoc
  static emailEncryptedDoc = async (
    tpEmail,
    spEmail,
    repEmail,
    emailSubject,
    password,
    emailBody,
    attachmentContent,
    preparerID,
    tpEmailChecked,
    spEmailChecked,
    repEmailChecked,
    tpEmailPassChecked,
    spEmailPassChecked,
    repEmailPassChecked,
  ) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/taxprep/emailEncryptedDocPassword`,
          {
            tpEmail,
            spEmail,
            repEmail,
            emailSubject,
            password,
            emailBody,
            attachmentContent,
            preparerID,
            tpEmailChecked,
            spEmailChecked,
            repEmailChecked,
            tpEmailPassChecked,
            spEmailPassChecked,
            repEmailPassChecked,
          },
          {
            ...WebHelpers.getAuthHeaders(),
            cancelToken: new CancelToken(fn => {
              cancelFunctions.push(fn);
            }),
          },
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static getDevices = async () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${BASE_URL}/api/getdevices`, {
          ...WebHelpers.getAuthHeaders(),
        })
        .then(resp => resolve(resp))
        .catch(error => reject(error));
    });
  };

  static getClientLetterList = async () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${XLINK_API_URL}/account/office/setup/clientletter/list`, {
          ...WebHelpers.getAuthHeaders(),
        })
        .then(resp => resolve(resp))
        .catch(error => reject(error));
    });
  };

  static updateDefaultClientLetter = async (letterID, custom) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/account/office/setup/clientletter/default`,
          { ID: letterID, custom },
          {
            ...WebHelpers.getAuthHeaders(),
          },
        )
        .then(resp => resolve(resp))
        .catch(error => reject(error));
    });
  };

  static addNewClientLetter = async (letterID, custom, name) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/account/office/setup/clientletter`,
          {
            baseID: letterID,
            custom,
            name,
          },
          {
            ...WebHelpers.getAuthHeaders(),
          },
        )
        .then(resp => resolve(resp))
        .catch(error => reject(error));
    });
  };

  static deleteClientLetter = async (letterID, custom) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${XLINK_API_URL}/account/office/setup/clientletter/${letterID}/${custom}`, {
          ...WebHelpers.getAuthHeaders(),
        })
        .then(resp => resolve(resp))
        .catch(error => reject(error));
    });
  };

  static getClientLetterMacros = () => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/account/office/setup/clientletter/macros`);
  };

  static getClientLetterItems = async entityCode => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${XLINK_API_URL}/account/office/setup/clientletter/headers/${entityCode}`, {
          ...WebHelpers.getAuthHeaders(),
        })
        .then(resp => resolve(resp))
        .catch(error => reject(error));
    });
  };

  static getClientLetterSectionText = async (letterID, siteCode, custom, entityID) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${XLINK_API_URL}/account/office/setup/clientletter/${letterID}/${custom}/${siteCode}/${entityID}`,
          {
            ...WebHelpers.getAuthHeaders(),
          },
        )
        .then(resp => resolve(resp))
        .catch(error => reject(error));
    });
  };

  static updateClientLetterSectionText = async (
    letterID,
    siteCode,
    custom,
    sectionText,
    entityID,
  ) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/account/office/setup/clientletter/${letterID}/${custom}/${siteCode}/${entityID}`,
          { sectionText },
          { ...WebHelpers.getAuthHeaders() },
        )
        .then(resp => resolve(resp))
        .catch(error => reject(error));
    });
  };

  static changeReturnOwner = async (returnID, loginID) => {
    return await axios.post(
      `${XLINK_API_URL}/taxprep/return/${returnID}/changeOwner/${loginID}`,
      {},
      {
        ...WebHelpers.getAuthHeaders(),
        cancelToken: new CancelToken(fn => {
          cancelFunctions.push(fn);
        }),
      },
    );
  };

  // getWallet requests a CPTS Wallet for the currently logged in user, or for the return (if returnID is given)
  static getWallet = async (
    efinID = 0,
    returnID = 0,
    forRemoteInvoice = false,
    publicCall = false,
  ) => {
    let url;
    if (publicCall) {
      url = `${XLINK_API_URL}/payments/wallet/public`;
    } else {
      url = `${XLINK_API_URL}/payments/wallet`;
    }
    let params = {};
    if (returnID !== 0) {
      params = {
        returnID,
      };
    }

    if (efinID !== 0) {
      params = { ...params, efinID };
    }
    params = { ...params, efinID, forRemoteInvoice };
    return await axios.get(url, {
      params,
      ...WebHelpers.getAuthHeaders(),
      cancelToken: new CancelToken(c => {
        cancelFunctions.push(c);
      }),
    });
  };

  // get returnGUID using returnID
  static getReturnGUID = async (returnID = 0) => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/payments/returnGUID/${returnID}`);
  };

  static getPaymentHistoryByID = async returnID => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/payments/history/${returnID}`);
  };

  // getPayJunctionCredentials fetches payjunction credentials for a given user
  static getPayJunctionCredentials = async () => {
    return await axios.get(`${XLINK_API_URL}/payments/credentials`, {
      ...WebHelpers.getAuthHeaders(),
      cancelToken: new CancelToken(fn => {
        cancelFunctions.push(fn);
      }),
    });
  };

  // updatePayJunctionCredentials sends a request to update the credentials for a given user
  static updatePayJunctionCredentials = async credentials => {
    return await axios.post(
      `${XLINK_API_URL}/payments/credentials`,
      { credentials },
      {
        ...WebHelpers.getAuthHeaders(),
        cancelToken: new CancelToken(fn => {
          cancelFunctions.push(fn);
        }),
      },
    );
  };

  static getPayJunctionTerminals = async () => {
    return await axios.get(`${XLINK_API_URL}/payments/terminals`, {
      ...WebHelpers.getAuthHeaders(),
      cancelToken: new CancelToken(c => {
        cancelFunctions.push(c);
      }),
    });
  };

  static requestSmartTerminalPayment = async (
    terminalID,
    smartTerminalID,
    amountBase,
    returnID,
    GUID,
  ) => {
    const terminalIDint = parseInt(terminalID);
    return await axios.post(
      `${XLINK_API_URL}/payments/request`,
      {
        smartTerminalID,
        terminalID: terminalIDint,
        amountBase,
        returnID,
        serialNumber: GUID,
        requestType: 'smartTerminal',
      },
      {
        ...WebHelpers.getAuthHeaders(),
        cancelToken: new CancelToken(c => {
          cancelFunctions.push(c);
        }),
      },
    );
  };

  static completeSmartTerminalPayment = async (reqPaymentID, transactionID) => {
    await axios.post(
      `${XLINK_API_URL}/payments/complete`,
      {
        reqPaymentID,
        transactionID,
      },
      {
        ...WebHelpers.getAuthHeaders(),
        cancelToken: new CancelToken(c => {
          cancelFunctions.push(c);
        }),
      },
    );
  };

  static requestACHPayment = async (terminalID, achRequest, amountBase, returnID, GUID) => {
    const terminalIDint = parseInt(terminalID);
    return await axios.post(
      `${XLINK_API_URL}/payments/request`,
      {
        ...achRequest,
        terminalID: terminalIDint,
        amountBase,
        returnID,
        serialNumber: GUID,
        requestType: 'ACH',
      },
      {
        ...WebHelpers.getAuthHeaders(),
        cancelToken: new CancelToken(c => {
          cancelFunctions.push(c);
        }),
      },
    );
  };

  static requestSmartTerminalReset = async smartTerminalID => {
    return await axios.post(
      `${XLINK_API_URL}/payments/${smartTerminalID}/main`,
      {},
      {
        ...WebHelpers.getAuthHeaders(),
        cancelToken: new CancelToken(c => {
          cancelFunctions.push(c);
        }),
      },
    );
  };

  static getPayments = async (season, page, pageSize, refresh, filter = '', value = '') => {
    return await axios.get(
      `${XLINK_API_URL}/payments/transactions?season=${season}&page=${page}&size=${pageSize}&refresh=${refresh}&filter=${filter}&value=${value}`,
      {
        ...WebHelpers.getAuthHeaders(),
        cancelToken: new CancelToken(c => {
          cancelFunctions.push(c);
        }),
      },
    );
  };

  // get invoice-guids, we will then call the remote invoice guid for the additional details
  static getRemotePayments = async (season, page, pageSize, refresh, filter = '', value = '') => {
    return await axios.get(
      `${XLINK_API_URL}/payments/transactions/remote?season=${season}&page=${page}&size=${pageSize}&refresh=${refresh}&filter=${filter}&value=${value}`,
      {
        ...WebHelpers.getAuthHeaders(),
        cancelToken: new CancelToken(c => {
          cancelFunctions.push(c);
        }),
      },
    );
  };

  // getTransaction returns transaction info of a payjunction specific payment
  static getTransaction = async transactionID => {
    return await axios.get(`${XLINK_API_URL}/payments/transactions/${transactionID}`, {
      ...WebHelpers.getAuthHeaders(),
      cancelToken: new CancelToken(c => {
        cancelFunctions.push(c);
      }),
    });
  };

  // getTransactionReceipt returns a fullpage html receipt for a given payjunction transcation ID
  static getTransactionReceipt = async transactionID => {
    return await axios.get(`${XLINK_API_URL}/payments/transactions/${transactionID}/receipt`, {
      ...WebHelpers.getAuthHeaders(),
      cancelToken: new CancelToken(c => {
        cancelFunctions.push(c);
      }),
    });
  };

  // updateCobrandTechSupportNum sends a request to update the tech support cobranding number for a given login/userID
  static updateCobrandTechSupportNum = async techSupportNum => {
    return await axios.post(
      `${XLINK_API_URL}/cobrand/techsupport`,
      {
        techSupportNum,
      },
      {
        ...WebHelpers.getAuthHeaders(),
        cancelToken: new CancelToken(c => {
          cancelFunctions.push(c);
        }),
      },
    );
  };

  static moveReturnToAnotherEfin = async (fromEfin, toEfin, parentUserID, returnID) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/techsupport/moveReturn`,
          {
            fromEfin,
            toEfin,
            parentUserID: parseInt(parentUserID),
            returnID,
          },
          {
            ...WebHelpers.getAuthHeaders(),
          },
        )
        .then(resp => resolve(resp))
        .catch(error => reject(error));
    });
  };

  // UpdateCobrandingStatuses sends a request to update the status to allow cobranding and cobranding purchase
  static UpdateCobrandingStatuses = async (allowStatus, purchasedStatus) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/cobrand/status`,
          {
            allowCobrandingStatus: allowStatus,
            purchasedCobrandingStatus: purchasedStatus,
          },
          {
            ...WebHelpers.getAuthHeaders(),
          },
        )
        .then(resp => resolve(resp))
        .catch(error => reject(error));
    });
  };

  static GetCobrandingStatuses = async () => {
    return await XlinkAPI.simpleGetRequest(`${BASE_URL}/reg/cobrand/status`);
  };

  // getHasAccessToAddCobranding returns a boolean if the loginID has access to add cobranding
  static getHasAccessToAddCobranding = async () => {
    return await axios.get(`${BASE_URL}/reg/cobrand/accessToAddCobranding`, {
      ...WebHelpers.getAuthHeaders(),
      cancelToken: new CancelToken(c => {
        cancelFunctions.push(c);
      }),
    });
  };

  static getTaxpassMessagesByReturnID = async returnID => {
    const res = await XlinkAPI.simpleGetRequest(
      `${XLINK_API_URL}/taxreturn/${returnID}/taxpass/messages`,
    );
    if (res && res.data) {
      return res;
    } else {
      throw ErrorHelpers.createXlinkcloudError('Response is not in a valid format');
    }
  };

  static getMobileAppIntegration = async () => {
    return await axios.get(`${XLINK_API_URL}/mobile/integration`, {
      ...WebHelpers.getAuthHeaders(),
      cancelToken: new CancelToken(c => {
        cancelFunctions.push(c);
      }),
    });
  };

  static generateMobileAppID = async () => {
    return await axios.post(
      `${XLINK_API_URL}/mobile/integration/id`,
      {},
      {
        ...WebHelpers.getAuthHeaders(),
        cancelToken: new CancelToken(c => {
          cancelFunctions.push(c);
        }),
      },
    );
  };

  static updateMobileAppEmail = async email => {
    return await axios.post(
      `${XLINK_API_URL}/mobile/integration/email`,
      {
        email,
      },
      {
        ...WebHelpers.getAuthHeaders(),
        cancelToken: new CancelToken(c => {
          cancelFunctions.push(c);
        }),
      },
    );
  };

  static updateMobileAppLogo = async logo => {
    return await axios.post(
      `${XLINK_API_URL}/mobile/integration/logo`,
      {
        logo,
      },
      {
        ...WebHelpers.getAuthHeaders(),
        cancelToken: new CancelToken(c => {
          cancelFunctions.push(c);
        }),
      },
    );
  };

  // mark appropriate flag in db when user completes a section in wizard mode
  static completeWizardSection = async (rtnID, queryName) => {
    return await axios.post(
      `${XLINK_API_URL}/taxprep/completeWizardSection`,
      {
        rtnID,
        queryName,
      },
      {
        ...WebHelpers.getAuthHeaders(),
        cancelToken: new CancelToken(c => {
          cancelFunctions.push(c);
        }),
      },
    );
  };

  // when user completes wizard mode questionarre flag return as wizard_completed
  static completeWizard = async rtnID => {
    return await axios.post(
      `${XLINK_API_URL}/taxprep/completeWizard`,
      {
        rtnID,
      },
      {
        ...WebHelpers.getAuthHeaders(),
        cancelToken: new CancelToken(c => {
          cancelFunctions.push(c);
        }),
      },
    );
  };

  // insert remote invoice
  static insertRemoteInvoice = async (rtnID, season, invoiceGuid) => {
    return await axios.post(
      `${XLINK_API_URL}/taxprep/insertRemoteInvoice`,
      {
        rtnID,
        invoiceGuid,
        season,
      },
      {
        ...WebHelpers.getAuthHeaders(),
        cancelToken: new CancelToken(c => {
          cancelFunctions.push(c);
        }),
      },
    );
  };

  static getInvoicesByReturnID = async returnID => {
    return await axios.get(`${XLINK_API_URL}/taxprep/getInvoices/${returnID}`, {
      ...WebHelpers.getAuthHeaders(),
      cancelToken: new CancelToken(c => {
        cancelFunctions.push(c);
      }),
    });
  };

  static getRestrictedFields = async () => {
    return await axios.get(`${XLINK_API_URL}/account/settings/getrestrictedfields`, {
      ...WebHelpers.getAuthHeaders(),
      cancelToken: new CancelToken(c => {
        cancelFunctions.push(c);
      }),
    });
  };

  static deleteRestrictedField = async deletedField => {
    return await axios.delete(
      `${XLINK_API_URL}/account/settings/deleterestrictedfield/${deletedField}`,
      {
        ...WebHelpers.getAuthHeaders(),
        cancelToken: new CancelToken(c => {
          cancelFunctions.push(c);
        }),
      },
    );
  };

  static updateRestrictedField = async updatedField => {
    return await axios.post(
      `${XLINK_API_URL}/account/settings/updaterestrictedfield`,
      { ...updatedField },
      {
        ...WebHelpers.getAuthHeaders(),
        cancelToken: new CancelToken(c => {
          cancelFunctions.push(c);
        }),
      },
    );
  };

  static copyOfficeSettings = async req => {
    return await axios.post(
      `${XLINK_API_URL}/account/settings/setup/copyoffice`,
      { ...req },
      {
        ...WebHelpers.getAuthHeaders(),
        cancelToken: new CancelToken(c => {
          cancelFunctions.push(c);
        }),
      },
    );
  };

  static getStatePackageStatus = async () => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/packages/status`);
  };

  static getStatePackages = async isBusiness => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/packages/status/${isBusiness}`);
  };

  static updateStatePackageStatus = async (individualPackages, businessPackages) => {
    return await axios.post(
      `${XLINK_API_URL}/packages/status/update`,
      { individualPackages, businessPackages },
      {
        ...WebHelpers.getAuthHeaders(),
        cancelToken: new CancelToken(c => {
          cancelFunctions.push(c);
        }),
      },
    );
  };

  /**
   * Handles fetching office profile using office/efin login id
   *
   * @param {number} officeLoginID optional Office Login ID that is used to fetch the officeProfile
   */
  static fetchOfficeProfile = async (officeLoginID = 0) => {
    return await axios.get(`${XLINK_API_URL}/office/${officeLoginID}`, {
      ...WebHelpers.getAuthHeaders(),
      cancelToken: new CancelToken(c => {
        cancelFunctions.push(c);
      }),
    });
  };

  // get account custom tags
  static getUserCustomTags = () => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/user/customtags`);
  };

  static getBankList = (efinID, season) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${XLINK_API_URL}/office/banks`,
          {
            efinID,
            season,
          },
          {
            ...WebHelpers.getAuthHeaders(),
            cancelToken: new CancelToken(fn => {
              cancelFunctions.push(fn);
            }),
          },
        )
        .then(res => {
          return resolve(res);
        })
        .catch(error => {
          return reject(error);
        });
    });
  };

  static getIsSelfPreparingIndicator = returnID => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/taxprep/is-self-preparing/${returnID}`);
  };

  static logErrorMessage = (title, rawError, errorCode) => {
    return XlinkAPI.simplePostRequest(`${BASE_URL}/authn/log-error-message`, {
      title,
      error: rawError,
      errorCode: errorCode?.toString(),
    });
  };

  static fetchOfficePreparers = () => {
    return XlinkAPI.simpleGetRequest(`${XLINK_API_URL}/account/office/setup/get-preparers`);
  };

  static fetchLoginsAssociatedToPreparer = preparerID => {
    return XlinkAPI.simpleGetRequest(
      `${XLINK_API_URL}/account/office/setup/get-associated-logins/${preparerID}`,
    );
  };

  static deleteOfficeLogin = (oldPreparerID, newPreparerID, loginID) => {
    return XlinkAPI.simplePostRequest(`${XLINK_API_URL}/account/office/setup/delete-office-login`, {
      oldPreparerID,
      newPreparerID,
      loginID,
    });
  };
}

import React from 'react';
// External imports
import { Redirect, Route, useLocation } from 'react-router-dom';
// Redux imports
import { useSelector } from 'react-redux';
// Internal imports
import { AUTH_STAGE } from '~/app/constants';

/**
 * Protected Route that is only accessible when a user in logged in or
 * they are going through the two factor authentication process.
 *
 * @category Components
 * @subcategory Auth
 * @param {function} Component - The page needing to be displayed.
 * @returns {function} A component.
 */
const PrivateRoute = ({ render: Component, ...rest }) => {
  const location = useLocation();

  const { authStage, isAuthenticated } = useSelector(state => ({
    authStage: state.app.authStage,
    isAuthenticated: state.app.isAuthenticated,
  }));

  return (
    <Route {...rest}>
      {authStage === AUTH_STAGE.AWAIT_SECOND_FACTOR || isAuthenticated ? (
        <Component />
      ) : (
        <Redirect to={{ pathname: '/', state: { prevLocation: location } }} />
      )}
    </Route>
  );
};

export default PrivateRoute;

export const styles = theme => ({
  paper: {
    height: '31.1rem',
    width: '35.625rem',
    norderRadius: '4px',
    margin: 'auto',
    position: 'relative',
  },
  button: {
    padding: '0 3vw',
    float: 'left',
    height: '2.19rem',
    width: '6.375rem',
    borderRadius: '4px',
  },
  typography: {
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
    float: 'left',
    paddingRight: '2.25rem',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing.unit,
  },
});

export const SPIN_SAVE_MSG = 'Saving Tax Return...';

export const SPIN_LOCKED_CLOSE = 'Closing Tax Return...';

export const STATE_LIST = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

export const NON_RES_STATE_LIST = [
  'nonResAlabama',
  'nonResAlaska',
  'nonResArizona',
  'nonResArkansas',
  'nonResCalifornia',
  'nonResColorado',
  'nonResConnecticut',
  'nonResDelaware',
  'nonResFlorida',
  'nonResGeorgia',
  'nonResHawaii',
  'nonResIdaho',
  'nonResIllinois',
  'nonResIndiana',
  'nonResIowa',
  'nonResKansas',
  'nonResKentucky',
  'nonResLouisiana',
  'nonResMaine',
  'nonResMaryland',
  'nonResMassachusetts',
  'nonResMichigan',
  'nonResMinnesota',
  'nonResMississippi',
  'nonResMissouri',
  'nonResMontana',
  'nonResNebraska',
  'nonResNevada',
  'nonResNewHampshire',
  'nonResNewJersey',
  'nonResNewMexico',
  'nonResNewYork',
  'nonResNorthCarolina',
  'nonResNorthDakota',
  'nonResOhio',
  'nonResOklahoma',
  'nonResOregon',
  'nonResPennsylvania',
  'nonResRhodeIsland',
  'nonResSouthCarolina',
  'nonResSouthDakota',
  'nonResTennessee',
  'nonResTexas',
  'nonResUtah',
  'nonResVermont',
  'nonResVirginia',
  'nonResWashington',
  'nonResWestVirginia',
  'nonResWisconsin',
  'nonResWyoming',
];

export const MISC_FORMS_LIST = [
  'Use 2020 earned income for calculating EIC/ACTC',
  'Schedule B - Seller Financed Mortgages and/or Foreign Financial Accounts',
  'Schedule D - Capital Gains and Losses',
  'Schedule E - Supplemental Income and Loss',
  'Schedule F - Profit or Loss from Farming',
  'Schedule J - Farm Income Averaging',
  'Form 982 - Exclusion of 1099-C Income related to Foreclosed Home or Insolvency',
  'Form 1099-MISC - Miscellaneous Income',
  'Form 1099-NEC - Nonemployee Compensation',
  'Form 1099-Q - Payments from Qualified Education Programs',
  'Form 1116 - Foreign Tax Credit More Info',
  'Form 1310 - Person Claiming Refund Due Deceased',
  'Form 2106 Employee Business Expenses for Certain Professions (For any profession for certain State returns)',
  'Form 2555 - Foreign Income Exclusion',
  'Form 3903 - Moving Expenses',
  'Form 4136 - Non-taxable use of gasoline, gasohol, or diesel fuel',
  'Form 4137 - Tax on Tips',
  'Form 4852 - Substitute Form W-2 and 1099-R',
  'Form 5405 - Repayment of Homebuyer Credit',
  'Form 5695 - Residential Energy Credits',
  'Form 8379 - Injured Spouse Claim and Allocation',
  'Form 8396 - Mortgage Interest Credit',
  'Form 8839 - Adoption Expenses',
  'Form 8853 - Archer MSA & Long-Term Insurance Contracts',
  'Form 8885 - Health Coverage Tax Credit',
  'Form 8889 - Health Savings Account',
  'Form 8936 - Electric Vehicle Credit',
  'Form W-7/W-7 (COA) - (Application for ITIN and Certificate of Accuracy)',
  "Schedule K-1 (1041) - (Beneficiary's share of income, deductions, etc..)",
  'Schedule K-1 (1065) - US Return of Partnership',
  "Schedule K-1 (1120S) - Shareholder's Share",
  'Home Sale Worksheet - Report sale of personal residence',
];

export const MISC_ABBV_LIST = [
  'formEIC',
  'schB',
  'schD',
  'schE',
  'schF',
  'schJ',
  'form982',
  'form1099MISC',
  'form1099NEC',
  'form1099Q',
  'form1116',
  'form1310',
  'form2106',
  'form2555',
  'form3903',
  'form4136',
  'form4137',
  'form4852',
  'form5405',
  'form5695',
  'form8379',
  'form8396',
  'form8839',
  'form8853',
  'form8885',
  'form8889',
  'form8936',
  'formW7',
  'schK1_1041',
  'schK1_1065',
  'schK1_1120S',
  'homeSaleWks',
];

// These are the Wizard Estimator form codes we need to send to backend. They are in order of index and filename numbered iteration.
// Depending on what step the user is currently on, can be passed in here to find the current file associated with that step.
export const CURRENT_FILE_STEPS = [
  'US1W', // Step 1 - TaxPayer Information
  'US9W', // Step 2 - Contact Information
  'US2W', // Step 3 - Children & Dependents
  'US3W', // Step 4 - Income
  'US4W', // Step 5 - Deductions
  'US5W', // Step 6 - Credits
  'US6W', // Step 7 - State Income Tax
  'US7W', // Step 8 - Misc
  'US8W', // Step 9 - Results
];

// an array that keeps track of which wizard forms have dynamic fields, add forms as necesarry.
export const DYNAMIC_FORMS_LIST = ['US3W'];

// follows CURRENT_FILE_STEPS schema
export const FOOTER_INFO = [
  { next: 'Contact Information' }, // Step 1 - Taxpayer Info
  { back: 'Taxpayer Info', next: 'Children & Dependents' }, // Step 2 - Taxpayer Info
  { back: 'Contact Information', next: 'Income' }, // Step 3 - Children & Dependents
  { back: 'Children & Dependents', next: 'Deductions' }, // Step 4 - Income
  { back: 'Income', next: 'Credits' }, // Step 5 - Deductions
  { back: 'Deductions', next: 'State Income Tax' }, // Step 6 - Credits
  { back: 'Credits', next: 'Misc Forms' }, // Step 7 - State Income Tax
  { back: 'State Income Tax', next: 'Results' }, // Step 8 - Misc Forms
  { back: 'Misc Forms', next: 'Complete Guided Estimator' }, // Step 9 - Results
];

export const COMPLETE_SECTION_QUERY = [
  'complete-wizard-taxpayer',
  'complete-wizard-contact',
  'complete-wizard-children',
  'complete-wizard-income',
  'complete-wizard-deductions',
  'complete-wizard-credits',
  'complete-wizard-state',
  'complete-wizard-miscellaneous',
];

export const COMPLETE_SECTION_NAMES = [
  'taxpayerCompleted',
  'contactCompleted',
  'childrenCompleted',
  'incomeCompleted',
  'deductionsCompleted',
  'creditsCompleted',
  'stateCompleted',
  'miscellaneousCompleted',
];

export const YES_NO_DROPDOWN = ['Yes', 'No'];

export const NUMERIC_FIELD_TYPES = ['I', 'N', 'L', 'S', 'Y','T'];

// Internal imports
import XlinkAPI from '~/app/api/xlinkAPI';
import AuthAPI from '~/app/api/authAPI';
import ErrorHelpers from '~/app/errorHelpers.js';
import WebHelpers from '~/app/webHelpers.js';
import { DASHBOARD_TITLE_TABS, SIDE_TABS } from '~/app/constants.js';
// Redux imports
import { takeLatest, call, put } from 'redux-saga/effects';
import {
  actions,
  FETCHED_RETURN_PROFILE,
  FETCH_RETURN_PROFILE_BY_RETURN_ID,
  SWITCH_YEAR_AND_FETCH_RETURN_PROFILE,
} from './duck';
import { actions as appActions } from '../modules/app';

export function* watchFetchReturnProfile() {
  yield takeLatest(FETCHED_RETURN_PROFILE, fetchReturnProfileAsync);
  yield takeLatest(SWITCH_YEAR_AND_FETCH_RETURN_PROFILE, switchYearAndFetchReturnProfile);
}

export function* fetchReturnProfileAsync(payload) {
  try {
    yield put(actions.requestReturnProfile());

    const res = yield call(XlinkAPI.fetchReturnProfile, payload.returnID);

    const lockOwner = yield call(XlinkAPI.whoHasReturnLock, payload.returnID);

    if (
      lockOwner.data &&
      lockOwner.data.identifier &&
      lockOwner.data.identifier !== '' &&
      lockOwner.data.lockedByLoginID > 0
    ) {
      yield put(actions.lockReturn(lockOwner.data.identifier, lockOwner.data.lockedByLoginID));
    }

    yield put(actions.requestReturnProfileSuccess(res.data.arg.NavPanelItems, res.data.arg.notes));

    yield put(
      actions.setClientData({
        filing_status: res.data.filingStatus,
      }),
    );
  } catch (error) {
    ErrorHelpers.handleError('Error fetching return profile', error);
    yield put(actions.requestReturnProfileError());
  }
}

export function* watchFetchReturnProfileByReturnID() {
  yield takeLatest(FETCH_RETURN_PROFILE_BY_RETURN_ID, fetchReturnProfileByReturnID);
}

export function* fetchReturnProfileByReturnID(payload) {
  try {
    if (!payload?.returnID) {
      return;
    }

    yield put(actions.requestReturnProfile());

    let jwtPayload = WebHelpers.getJWTPayload();

    if (payload.isSecuredBusinessMessage !== jwtPayload.is_business && payload.fromSecuredMessage) {
      yield call(AuthAPI.toggleBusinessMode);
    }

    const ret = yield call(XlinkAPI.getReturnProfileByReturnID, payload.returnID);

    const lockOwner = yield call(XlinkAPI.whoHasReturnLock, payload.returnID);

    if (
      lockOwner.data &&
      lockOwner.data.identifier &&
      lockOwner.data.identifier !== '' &&
      lockOwner.data.lockedByLoginID > 0
    ) {
      yield put(actions.lockReturn(lockOwner.data.identifier, lockOwner.data.lockedByLoginID));
    }

    jwtPayload = WebHelpers.getJWTPayload(); // reload payload with current data

    ret.data.year = jwtPayload.season;
    ret.data.name = jwtPayload?.is_business
      ? ret.data.businessName
      : ret.data.first_name + ' ' + ret.data.last_name;

    yield put(actions.setActiveReturn(ret.data, []));
    yield put(actions.openReturnProfile());
    yield put(appActions.onSelectTitleTab(DASHBOARD_TITLE_TABS.LIST_VIEW));
  } catch (error) {
    ErrorHelpers.handleError('Error fetching return profile', error);
    yield put(actions.requestReturnProfileError());
  }
}

export function* switchYearAndFetchReturnProfile(payload) {
  try {
    let JWTpayload = WebHelpers.getJWTPayload();
    const efinID = JWTpayload?.preparer_info.active_efin_id;
    yield call(AuthAPI.selectAnOfficeYear, efinID, payload.season);

    if (payload.isSecuredBusinessMessage !== JWTpayload.is_business) {
      yield call(AuthAPI.toggleBusinessMode);
    }

    yield put(actions.requestReturnProfile());

    yield put(appActions.onSelectSidebarTab(SIDE_TABS.DASHBOARD_MAIN));

    const ret = yield call(XlinkAPI.getReturnProfileByReturnID, payload.returnID);
    const lockOwner = yield call(XlinkAPI.whoHasReturnLock, payload.returnID);

    if (
      lockOwner.data &&
      lockOwner.data.identifier &&
      lockOwner.data.identifier !== '' &&
      lockOwner.data.lockedByLoginID > 0
    ) {
      yield put(actions.lockReturn(lockOwner.data.identifier, lockOwner.data.lockedByLoginID));
    }

    JWTpayload = WebHelpers.getJWTPayload(); // reload payload with current data
    ret.data.year = JWTpayload.season;

    ret.data.name = JWTpayload?.is_business
      ? ret.data.businessName
      : ret.data.first_name + ' ' + ret.data.last_name;

    yield put(actions.setActiveReturn(ret.data, []));
    yield put(actions.openReturnProfile());
    yield put(appActions.onSelectTitleTab(DASHBOARD_TITLE_TABS.LIST_VIEW));
  } catch (error) {
    ErrorHelpers.handleError('Error switching year and fetching return profile', error);
    yield put(actions.requestReturnProfileError());
  }
}

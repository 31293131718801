import React from "react";
import { CircularProgress, Button, withStyles } from "@material-ui/core";

const ButtonSpinner = ({ buttonText, isLoading, spinnerColor, ...params }) => {
  const StyledCircularProgress = withStyles({
    root: {
      color: spinnerColor !== "undefined" ? spinnerColor : "#0077ff",
    },
  })(CircularProgress);

  return (
    <Button disabled={isLoading} {...params}>
      {isLoading ? <StyledCircularProgress size={24} /> : buttonText}
    </Button>
  );
};

export default ButtonSpinner;

import * as Common from "~/app/redux/commonActions.js";
import * as Global from "~/app/constants.js";

export const SELECT_FILTER = "xlinkonline/accountList/SELECT";
export const PAGE_CHANGE = "xlinkonline/accountList/PAGECHANGE";
export const UPDATE_LIST_FILTER = "xlinkonline/accountList/UPDATELISTFILTER";
export const RESET_ACCOUNT_FILTER =
  "xlinkonline/accountList/RESETACCOUNTFILTER";
export const UPDATE_ACCOUNT_LIST_SORT_ORDER =
  "xlinkonline/accountList/UPDATEACCOUNTLISTSORTORDER";
export const UPDATE_ACTIVE_COL_IDX =
  "xlinkonline/accountList/UPDATEACTIVECOLIDX";
export const REDIRECT_FROM_OVERVIEW =
  "xlinkonline/overview/REDIRECTFROMOVERVIEW";
export const RESET_COLUMNS_TO_DEFAULT =
  "xlinkonline/accountList/RESETCOLUMNSTODEFAULT";
export const RESET_COLUMNS = "xlinkonline/accountList/RESETCOLUMNS";
export const CLEAR_ACCOUNTS_LIST = "xlinkonline/accountList/CLEARACCOUNTSLIST";

export const REQUESTED_ACCOUNTS_LIST =
  "xlinkonline/accountList/REQUESTEDACCOUNTSLIST";
export const REQUESTED_ACCOUNTS_LIST_FAILED =
  "xlinkonline/accountList/REQUESTEDACCOUNTSFAILED";
export const REQUESTED_ACCOUNTS_LIST_SUCCEEDED =
  "xlinkonline/accountList/REQUESTEDACCOUNTSUCCEEDED";
export const FETCHED_ACCOUNTS_LIST =
  "xlinkonline/accountList/FETCHEDACCOUNTSLIST";

export const initialState = {
  filterValues: {
    first: "", // 1st filter text box value
    second: "" // 2nd filter text box value (for filtering by date range)
  },
  sortOrder: {
    column: 0, // default sort is by last name
    order: "asc" // default sort is in ascending order
  },

  // all possible table columns. name is for column title, col is for query params, asc and desc are for sort dropdown options,
  // details is for customize column modal
  allColumns: [
    {
      name: "Name",
      col: "company",
      asc: "Sort By A-Z",
      desc: "Sort By Z-A",
      details: "Account Name"
    },
    {
      name: "Account Status",
      col: "account_status",
      asc: "Sort By A-Z",
      desc: "Sort By Z-A",
      details: "Account Status"
    },
    {
      name: "Last Activity",
      col: "last_activity",
      asc: "Sort By Oldest First",
      desc: "Sort By Newest First",
      details: "Last Activity"
    },
    {
      name: "Offices",
      col: "offices",
      asc: "Sort By A-Z",
      desc: "Sort By Z-A",
      details: "Offices"
    },
    { name: "Action", col: "action", asc: "Sort By A-Z", desc: "Sort By Z-A" }, // always display this column
    {
      name: "User ID",
      col: "user_id",
      asc: "Sort By Low to High",
      desc: "Sort By High to Low",
      details: "Account user ID"
    },
    {
      name: "State",
      col: "name",
      asc: "Sort By A-Z",
      desc: "Sort By Z-A",
      details: "State"
    },
    {
      name: "EFIN",
      col: "efin",
      asc: "Sort By Low to High",
      desc: "Sort By High to Low",
      details: "Tax Return EFIN Number"
    }
  ],

  // active columns, array index is column position in table and value is an index into allColumns
  activeColIndexes: [0, 1, 5, 2, 3, 4], // default column titles

  // all filters, name is displayed in the filter dropdown, filter is for query params
  // the order of these must match the order of ACCOUNT_LIST_FILTERS in constants.js
  allFilters: [
    { name: "All Accounts", filter: "all" },
    { name: "Account Name", filter: "company" }
    // { name: "State", filter: "state" },
    // { name: "Status", filter: "account_status" },
  ],

  loading: false,
  error: false,

  // current filter criteria, index into allFilters
  activeFilter: Global.ACCOUNT_LIST_FILTERS.ALL,

  perPage: Global.DASHBOARD_DATA.DASHBOARDLISTSIZE,
  currentPage: 1,
  accounts: [], // a single page of accounts matching the search criteria and pagination requirements
  totalAccounts: -1, // number of accounts meeting the search criteria (used by pagination component)
  initialUnfilteredAccounts: -1
};

export const actions = {
  onSelectAccountListFilter: choice => ({
    type: SELECT_FILTER,
    choice
  }),
  onPageChange: page => ({
    type: PAGE_CHANGE,
    page
  }),
  updateAccountListFilterValues: (first, second, currentPage) => ({
    type: UPDATE_LIST_FILTER,
    first,
    second,
    currentPage
  }),
  resetAccountFilter: () => ({
    type: RESET_ACCOUNT_FILTER
  }),
  updateAccountListSortOrder: (col, order) => ({
    type: UPDATE_ACCOUNT_LIST_SORT_ORDER,
    col,
    order
  }),
  updateActiveColIdx: (newVal, colIdx) => ({
    type: UPDATE_ACTIVE_COL_IDX,
    newVal,
    colIdx
  }),
  redirectFromOverview: () => ({
    type: REDIRECT_FROM_OVERVIEW
  }),
  resetColumnsToDefault: () => ({
    type: RESET_COLUMNS_TO_DEFAULT
  }),
  resetColumns: cols => ({
    type: RESET_COLUMNS,
    cols
  }),
  clearAccountsList: () => ({
    type: CLEAR_ACCOUNTS_LIST
  }),
  requestAccountsList: () => ({
    type: REQUESTED_ACCOUNTS_LIST
  }),
  requestAccountsListSuccess: accountListData => ({
    type: REQUESTED_ACCOUNTS_LIST_SUCCEEDED,
    accountListData
  }),
  requestAccountsListError: () => ({
    type: REQUESTED_ACCOUNTS_LIST_FAILED
  }),
  fetchAccountsList: filterParams => ({
    type: FETCHED_ACCOUNTS_LIST,
    filterParams
  })
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_FILTER:
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          first: "",
          second: ""
        },
        activeFilter: action.choice
      };
    case UPDATE_LIST_FILTER:
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          first: action.first,
          second: action.second
        },
        currentPage: action.currentPage
      };
    case PAGE_CHANGE:
      return {
        ...state,
        currentPage: action.page
      };
    case RESET_ACCOUNT_FILTER:
      return {
        ...state,
        activeFilter: 0
      };
    case UPDATE_ACCOUNT_LIST_SORT_ORDER:
      return {
        ...state,
        sortOrder: {
          ...state.sortOrder,
          column: action.col,
          order: action.order
        }
      };
    case UPDATE_ACTIVE_COL_IDX:
      const cols = state.activeColIndexes.map((item, idx) =>
        idx == action.colIdx ? action.newVal : item
      );
      return {
        ...state,
        activeColIndexes: cols
      };
    case REDIRECT_FROM_OVERVIEW:
      return {
        ...state,
        activeFilter: Global.ACCOUNT_LIST_FILTERS.ALL
      };
    case RESET_COLUMNS_TO_DEFAULT:
      return {
        ...state,
        activeColIndexes: [0, 1, 2, 3, 4]
      };
    case RESET_COLUMNS:
      return {
        ...state,
        activeColIndexes: action.cols
      };
    case CLEAR_ACCOUNTS_LIST:
      return {
        ...state,
        accounts: [],
        currentPage: 1,
        totalAccounts: -1,
        initialUnfilteredAccounts: -1
      };
    case Common.LOGOUT:
      return {
        ...state,
        accounts: [],
        currentPage: 1,
        totalAccounts: -1,
        initialUnfilteredAccounts: -1
      };
    case REQUESTED_ACCOUNTS_LIST:
      return {
        ...state,
        loading: true,
        error: false
      };
    case REQUESTED_ACCOUNTS_LIST_SUCCEEDED:
      return {
        ...state,
        accounts: action.accountListData.list,
        totalAccounts: action.accountListData.total,
        initialUnfilteredAccounts: action.accountListData.unfilteredTotal,
        loading: false,
        error: false
      };
    case REQUESTED_ACCOUNTS_LIST_FAILED:
      return {
        ...state,
        accounts: [],
        totalAccounts: -1,
        initialUnfilteredAccounts: -1,
        loading: false,
        error: true
      };
  }
  return state;
}

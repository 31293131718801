import { isMobileOnly } from 'react-device-detect';

export const styles = () => ({
  styledHeader: {
    background: '#444C59',
    border: 0,
    color: 'white',
    height: 64,
    padding: '0 30px',
    minWidth: isMobileOnly ? '0' : '350px',
    PaperProps: {
      style: {
        backgroundColor: '#FFFFFF',
        color: 'black',
      },
    },
  },
  styledTitle: {
    color: 'white',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    marginTop: '23px',
    textTransform: 'capitalize',
  },
  styledContent: {
    fontFamily: 'Roboto',
    fontSize: '1rem',
    marginTop: '23px',
  },
  styledChildren: {
    fontFamily: 'Roboto',
    fontSize: '1rem',
    marginTop: '15px',
    marginBottom: '15px',
    color: '#000000',
  },
  styledButton: {
    marginRight: '1vw',
    marginBottom: '1.5vh',
  },
  styledButtonClose: {
    marginRight: '1vw',
    marginBottom: '1.5vh',
    border: '0rem',
  },
  styledImage: {
    width: '60px',
    height: '60px',
    marginBottom: '2vh',
  },
  styledErrorDiv: {
    textAlign: 'center',
    marginTop: '2vh',
  },
  styleIndex: {
    zIndex: '999999',
  },
});

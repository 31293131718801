import XlinkAPI from '~/app/api/xlinkAPI.js';

// cancelTransaction sends a request to reset the terminal to its initial state.
// This will fail if not sent before the transcation is processing (anytime during or after payment info has been provided to the terminal).
export const CancelTransaction = async selectedSmartTerminal => {
  try {
    // Request a terminal reset; do nothing upon response, terminal status will update accordingly
    await XlinkAPI.requestSmartTerminalReset(selectedSmartTerminal);
  } catch (error) {
    // Do nothing on error; terminal status will update accordningly
  }
};

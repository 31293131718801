// External imports
import React, { useEffect } from 'react';
import {
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  withStyles,
} from '@material-ui/core';
// Internal imports
import { PRINT_COMPONENT_PREFS_CORP, PRINT_COMPONENT_PREFS_1040 } from '~/app/constants.js';
import { useSetState } from '~/app/Utility/customHooks.js';
// Styling imports
import { styles } from '~/app/Pages/Returns/components/Print/css/dialogStyles.js';
import './css/print.css';

const PrintComponents = props => {
  const { classes } = props;
  const [state, setState] = useSetState({
    prefs: {},
    prefsToSend: {},
    checkedPrefs: [],
    returnComponentPrefs: props.isBusiness
      ? PRINT_COMPONENT_PREFS_CORP
      : PRINT_COMPONENT_PREFS_1040,
  });

  useEffect(() => {
    setState({
      prefs: props.prefs,
    });
    setCheckedPrefs(props.prefs);
  }, []);

  const handleToggle = pref => {
    const prefIndex = state.checkedPrefs.indexOf(pref);
    const newCheckedPrefs = [...state.checkedPrefs];
    const newPrefsToSend = state.prefsToSend;

    if (prefIndex === -1) {
      newCheckedPrefs.push(pref);
      newPrefsToSend[pref] = 'X';
    } else {
      newCheckedPrefs.splice(prefIndex, 1);
      newPrefsToSend[pref] = '';
    }

    setState({
      checkedPrefs: newCheckedPrefs,
      prefsToSend: newPrefsToSend,
    });
  };

  const setCheckedPrefs = prefs => {
    const newCheckedPrefs = [...state.checkedPrefs];
    const newPrefsToSend = state.prefsToSend;
    for (const pref in prefs) {
      if (state.returnComponentPrefs.includes(pref)) {
        newPrefsToSend[pref] = prefs[pref].configValue === '1' ? 'X' : '';
        if (prefs[pref].configValue === '1') {
          newCheckedPrefs.push(pref);
        }
      }
    }
    setState({
      checkedPrefs: newCheckedPrefs,
      prefsToSend: newPrefsToSend,
    });
  };

  const handleToggleAll = hasSelectedAll => {
    const newCheckedPrefs = [...state.checkedPrefs];
    const newPrefsToSend = state.prefsToSend;

    Object.keys(state.prefs).forEach(pref => {
      if (state.returnComponentPrefs.includes(pref)) {
        const prefIndex = state.checkedPrefs.indexOf(pref);

        if (hasSelectedAll) {
          if (prefIndex === -1) {
            newCheckedPrefs.push(pref);
            newPrefsToSend[pref] = 'X';
          }
        } else {
          newCheckedPrefs.pop();
          newPrefsToSend[pref] = '';
        }
      }

      setState({
        checkedPrefs: newCheckedPrefs,
        prefsToSend: newPrefsToSend,
      });
    });
  };

  return (
    <div>
      <Typography variant="title" id="simple-modal-title" style={{ paddingLeft: '1em' }}>
        Print Return Components
      </Typography>
      <div>
        <List>
          {Object.keys(state.prefs).map(pref => {
            if (state.returnComponentPrefs.includes(pref)) {
              let configName = state.prefs[pref].configName;
              if (state.prefs[pref].configName.includes('- Preparer')) {
                configName = state.prefs[pref].configName.slice(0, -10);
              }
              return (
                <ListItem key={pref} classes={{ root: classes.listItemRoot }}>
                  <ListItemText
                    classes={{ root: classes.listItemTextRoot }}
                    primary={
                      <Typography type="body2" classes={{ root: classes.typographyRoot }}>
                        {configName}
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Checkbox
                      id={pref}
                      classes={{ root: classes.secondaryListItemRoot }}
                      onClick={() => handleToggle(pref)}
                      checked={state.checkedPrefs.indexOf(pref) !== -1}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              );
            }
            return null;
          })}
        </List>
      </div>

      <div className="container print-components-padding">
        <div className="print-left-buttons-container">
          <Button
            id="btnSelectAll"
            size="small"
            classes={{ root: classes.noBorderButtonRoot }}
            onClick={() => handleToggleAll(true)}
          >
            Select All
          </Button>
          <Button
            id="btnDeselectAll"
            size="small"
            classes={{ root: classes.noBorderButtonRoot }}
            onClick={() => handleToggleAll(false)}
          >
            Deselect All
          </Button>
        </div>
        <div className="print-right-buttons-container">
          <Button
            id="btnCancel"
            size="small"
            classes={{ root: classes.cancelButtonRoot }}
            onClick={props.handleClose}
          >
            Cancel
          </Button>
          <Button
            id="btnPrint"
            size="small"
            onClick={() => {
              props.handlePrint('02', state.prefsToSend, '');
              props.handleClose();
            }}
          >
            Print
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(PrintComponents);

export const styles = {
  cardStyle: {
    width: '25vw',
    height: '62vh',
    float: 'left',
    marginRight: '4vw',
    marginLeft: '3vw',
    marginTop: '3.5vh',
  },
  titleStyle: {
    color: '#354052',
    fontSize: '21px',
    fontFamily: 'Roboto',
    letterSpacing: '0.14px',
    marginTop: '2vh',
    textAlign: 'center',
  },
  optionStyle: {
    color: '#354052',
    fontSize: '15px',
    fontFamily: 'Roboto',
    letterSpacing: '0.1px',
    marginTop: '2vh',
    textAlign: 'center',
  },
  styleButton: {
    margin: '3vh auto',
    borderRadius: 4,
    border: '1px solid #0077FF',
    color: '#0077FF',
    fontSize: '14px',
    fontFamily: 'Roboto',
    width: '10vw',
    height: '3.2vh',
    lineHeight: '3.2vh',
    letterSpacing: '0.1px',
    textAlign: 'center',
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  alignLeftStyle: {
    textAlign: 'left',
  },
  imageDimensions: {
    width: '45px',
    height: '45px',
  },
  selectedOption: {
    width: '25vw',
    height: '62vh',
    float: 'left',
    backgroundColor: '#F2F8FF',
    borderRadius: 4,
    border: '3px solid #0077FF',
  },
  unSelectedOption: {
    width: '25vw',
    height: '62vh',
    float: 'left',
  },
  blueCheckStyle: {
    color: '#0077FF',
  },
  transparentCheckStyle: {
    color: 'transparent',
  },
};

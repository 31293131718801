import { all, fork } from 'redux-saga/effects';

import { watchFetchAccountsList } from '../accountList/accountList.sagas';
import { watchFetchOfficeList } from '../officeList/officeList.sagas';
import { watchFetchPreparerList } from '../preparerList/preparerList.sagas';
import {
  watchFetchReturnList,
  watchAddNewReturn,
  watchDownloadReturn,
  watchFetchPreparerInfo,
  watchMoveReturn,
  watchImportMobileReturn,
} from '~/app/redux/returnList/returnList.sagas';
import {
  watchFetchReturnProfile,
  watchFetchReturnProfileByReturnID,
} from '~/app/redux/returnProfile/returnProfile.sagas';
import { watchLoginSetup } from '~/app/redux/loginSetup/loginSetup.sagas';
import { watchPreparerSetup } from '~/app/redux/preparerSetup/preparerSetup.sagas';
import { watchFetchBreadcrumbs } from '../search/search.sagas';
import { watchSetupPages } from '../setupPages/setupPages.sagas';
import { watchWidgetDashboard } from '../widgets/widgets.sagas';
import { watchDrilldown } from '../drilldown/drilldown.sagas';
import { watcherSagaDefaultsPage } from '~/app/redux/setupPages/defaultsPage/defaultsSetup.sagas';
import { watchNotifications } from '~/app/redux/notifications/notifications.sagas';
import { watchCobranding } from '~/app/redux/cobrand/cobrand.sagas';
import { watcherSagaRemoteSignature } from '~/app/redux/remoteSign/remoteSign.sagas';
import { watchServerStatus } from '~/app/redux/serverStatus/serverStatus.sagas';
import { watchTechSupport } from '~/app/redux/techSupport/techSupport.sagas';
import { watchOfficeProfile } from '~/app/redux/officeProfile/officeProfile.sagas';

export default function* rootSaga() {
  yield all([
    yield fork(watchFetchAccountsList),
    yield fork(watchFetchOfficeList),
    yield fork(watchFetchPreparerList),
    yield fork(watchFetchReturnList),
    yield fork(watchFetchReturnProfile),
    yield fork(watchImportMobileReturn),
    yield fork(watchLoginSetup),
    yield fork(watchPreparerSetup),
    yield fork(watchAddNewReturn),
    yield fork(watchDownloadReturn),
    yield fork(watchFetchPreparerInfo),
    yield fork(watchMoveReturn),
    yield fork(watchFetchBreadcrumbs),
    yield fork(watchFetchReturnProfileByReturnID),
    yield fork(watchSetupPages),
    yield fork(watchDrilldown),
    yield fork(watchWidgetDashboard),
    yield fork(watcherSagaDefaultsPage),
    yield fork(watchNotifications),
    yield fork(watchCobranding),
    yield fork(watcherSagaRemoteSignature),
    yield fork(watchServerStatus),
    yield fork(watchTechSupport),
    yield fork(watchOfficeProfile),
  ]);
}

import { blue } from '@material-ui/core/colors';

export const styles = {
  fieldInfoBtn: {
    backgroundColor: '#0077FF',
    height: '4rem',
    width: '2.5rem',
    minHeight: '0',
    minWidth: '0',
    borderRadius: '1rem 0 0 1rem',
    border: '0',
    position: 'absolute',
    top: 'calc(50% - 2rem)',
    marginLeft: '-3.1rem',
    zIndex: '99',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
    '&:hover': {
      backgroundColor: '#0077FF',
    },
  },
  fieldInfoBtnIcon: {
    fontSize: '2.5rem',
    color: '#fff',
  },
  worksheetBtn: {
    width: '100%',
    letterSpacing: '1px',
  },
  overflowBtn: {
    marginTop: '1rem',
    width: '100%',
    letterSpacing: '1px',
  },
  formLinkList: {
    padding: '0 .5rem',
  },
  formLinkListItem: {
    borderBottom: '1px solid #ddd',
    padding: '.5rem 0',
    margin: '0',
    '&:last-child': {
      borderBottom: '0',
    },
  },
  formLinkListItemTextColor: {
    color: 'blue',
  },
  formLinkListItemTextSecondary: {
    textTransform: 'uppercase',
  },
};

export const LOGINSETUP_GENERAL_ERROR = 'xlinkonline/loginSetup/LOGINSETUPGENERALERROR';

export const FETCH_LOGINS_LIST = 'xlinkonline/loginSetup/FETCHLOGINSLIST';
export const RESET_LOGINS_LIST = 'xlinkonline/loginSetup/RESETLOGINSLIST';
export const FETCH_ACCESSLEVEL_LIST = 'xlinkonline/loginSetup/FETCHACCESSLEVELLIST';
export const FETCH_ACCESSROLE_LIST = 'xlinkonline/loginSetup/FETCHACCESSROLELIST';
export const FETCH_LINKABLE_PREPARERS = 'xlinkonline/loginSetup/FETCHLINKABLEPREPARERS';
export const UPDATE_LOGINS_LIST = 'xlinkonline/loginSetup/UPDATELOGINSLIST';
export const UPDATE_ACCESSLEVEL_LIST = 'xlinkonline/loginSetup/UPDATEACCESSLEVELLIST';
export const UPDATE_ACCESSROLE_LIST = 'xlinkonline/loginSetup/UPDATEACCESSROLELIST';
export const UPDATE_LINKABLE_PREPARERS = 'xlinkonline/loginSetup/UPDATELINKABLEPREPARERS';

export const SET_LOGINS_PAGE = 'xlinkonline/loginSetup/SETLOGINSPAGE';

export const OPEN_LOGIN_MODAL = 'xlinkonline/loginSetup/OPENLOGINMODAL';
export const CLOSE_LOGIN_MODAL = 'xlinkonline/loginSetup/CLOSELOGINMODAL';
export const OPEN_AL_MODAL = 'xlinkonline/loginSetup/OPENALMODAL';
export const CLOSE_AL_MODAL = 'xlinkonline/loginSetup/CLOSEALMODAL';
export const TOGGLE_CO_MODAL = 'xlinkonline/loginSetup/TOGGLECOMODAL';

export const TOGGLE_ACCESSLEVEL_MODAL = 'xlinkonline/loginSetup/TOGGLEACCESSLEVELMODAL';
export const TOGGLE_LOGINS_COLLAPSE = 'xlinkonline/loginSetup/TOGGLELOGINSCOLLAPSE';
export const TOGGLE_ACCESSLEVLES_COLLPASE = 'xlinkonline/loginSetup/TOGGLEACCESSLEVLESCOLLPASE';

export const LOGINMANAGEMENT_TOGGLE_TRAINING_MODE =
  'xlinkonline/loginManagement/TOGGLE_TRAINING_MODE';
export const LOGINMANAGEMENT_SET_TRAINING_MODE = 'xlinkonline/loginManagement/SET_TRAINING_MODE';

export const FETCH_LOGIN_SETTINGS = 'xlinkonline/loginSettings/FETCHLOGINSETTINGS';
export const UPDATE_LOGIN_SETTINGS = 'xlinkonline/loginSettings/UPDATELOGINSETTINGS';
export const SET_LOGIN_SETTINGS = 'xlinkonline/loginSettings/SETLOGINSETTINGS';

export const IMPERSONATE_LOGIN = 'xlinkonline/loginSettings/IMPERSONATELOGIN';

export const SET_AUTHENTICATION_FLAG = 'xlinkonline/loginSetup/SETAUTHENTICATIONFLAG';

export const FETCH_MOBILE_APP_INTEGRATION = 'xlinkonline/loginSettings/FETCHMOBILEAPPINTEGRATION';
export const SET_MOBILE_APP_INTEGRATION = 'xlinkonline/loginSettings/SETMOBILEAPPINTEGRATION';
export const GENERATE_MOBILE_APP_ID = 'xlinkonline/loginSettings/GENERATEMOBILEAPPID';
export const SET_MOBILE_APP_ID = 'xlinkonline/loginSettings/SETMOBILEAPPID';
export const SET_ACTIVE_ACCESSLEVELS = 'xlinkonline/loginSettings/SET_ACTIVE_ACCESSLEVELS';
export const SET_ACCESSLEVEL_ROLE = 'xlinkonline/loginSettings/SET_ACCESSLEVEL_ROLE';
export const SET_FINALIZE_LOGIN = 'xlinkonline/loginSetup/SETFINALIZELOGIN';
export const ON_FINALIZE_LOGIN = 'xlinkonline/loginSetup/FINALIZELOGIN';

export const initialState = {
  loginModalOpen: false,
  accessLevelModalOpen: false,
  isCopyOfficeSettingModalOpen: false,
  expandedLogins: true,
  expandedAccessLevels: false,
  linkablePreparers: [],
  logins: [],
  loginsPage: 0,
  totalAccessGroups: 0,
  resultsPerPage: 7,
  totalLogins: 0,
  accessGroupPageOffset: 0,
  accessGroups: {},
  currentEditLogin: {},
  isEditingLogin: false,
  rolesForAccessGroup: [],
  error: '',
  isTrainingMode: false,
  loginSettings: {},
  mobileAppID: '',
  mobileAppQRCode: '',
  mobileAppEmail: '',
  activeAccessLevels: new Map(),
  finalizeLogin: false,
  allAccessRoles: {},
  isAuthenticating: false,
};

export const actions = {
  generalError: error => ({
    type: LOGINSETUP_GENERAL_ERROR,
    error,
  }),
  fetchLogins: (loginID, limit = initialState.resultsPerPage, offset = 0) => ({
    type: FETCH_LOGINS_LIST,
    loginID,
    limit,
    offset,
  }),
  resetLoginsList: () => ({
    type: RESET_LOGINS_LIST,
  }),
  onFetchLoginsSuccess: result => ({
    type: UPDATE_LOGINS_LIST,
    result,
  }),
  setLoginsPage: page => ({
    type: SET_LOGINS_PAGE,
    page,
  }),
  fetchAccessLevels: (loginID, limit, offset) => ({
    type: FETCH_ACCESSLEVEL_LIST,
    loginID,
    limit,
    offset,
  }),
  onFetchAccessLevelsSuccess: result => ({
    type: UPDATE_ACCESSLEVEL_LIST,
    result,
  }),
  fetchAccessRoles: loginID => ({
    type: FETCH_ACCESSROLE_LIST,
    loginID,
  }),
  onFetchAccessRolesSuccess: result => ({
    type: UPDATE_ACCESSROLE_LIST,
    result,
  }),
  fetchLinkablePreparers: loginID => ({
    type: FETCH_LINKABLE_PREPARERS,
    loginID,
  }),
  onFetchLinkablePreparersSuccess: preps => ({
    type: UPDATE_LINKABLE_PREPARERS,
    preps,
  }),
  onOpenLoginModal: (currentEditLogin, isEditing) => ({
    type: OPEN_LOGIN_MODAL,
    currentEditLogin,
    isEditing,
  }),
  onOpenAccessLevelModal: currentGroup => ({
    type: OPEN_AL_MODAL,
    currentGroup,
  }),
  onCloseLoginModal: () => ({
    type: CLOSE_LOGIN_MODAL,
  }),
  onCloseAccessLevelModal: () => ({
    type: CLOSE_AL_MODAL,
  }),
  onToggleCopyOfficeModal: () => ({
    type: TOGGLE_CO_MODAL,
  }),
  onToggleLoginsCollapse: () => ({
    type: TOGGLE_LOGINS_COLLAPSE,
  }),
  onToggleAccesslevelsCollpase: () => ({
    type: TOGGLE_ACCESSLEVLES_COLLPASE,
  }),
  toggleTrainingMode: () => ({
    type: LOGINMANAGEMENT_TOGGLE_TRAINING_MODE,
  }),
  setTrainingMode: mode => ({
    type: LOGINMANAGEMENT_SET_TRAINING_MODE,
    mode,
  }),
  fetchLoginSettings: IRSFlag => ({
    type: FETCH_LOGIN_SETTINGS,
    IRSFlag,
  }),
  setLoginSettings: settings => ({
    type: SET_LOGIN_SETTINGS,
    settings,
  }),
  updateLoginSettings: settings => ({
    type: UPDATE_LOGIN_SETTINGS,
    settings,
  }),
  impersonateLogin: identifier => ({
    type: IMPERSONATE_LOGIN,
    identifier,
  }),
  onFinalizeLogin: () => ({
    type: ON_FINALIZE_LOGIN,
  }),
  setFinalizeLogin: isFinalized => ({
    type: SET_FINALIZE_LOGIN,
    isFinalized,
  }),
  fetchMobileAppIntegration: () => ({
    type: FETCH_MOBILE_APP_INTEGRATION,
  }),
  setMobileAppIntegration: (mobileAppID, mobileAppQRCode, mobileAppEmail) => ({
    type: SET_MOBILE_APP_INTEGRATION,
    mobileAppID,
    mobileAppQRCode,
    mobileAppEmail,
  }),
  generateMobileAppID: () => ({
    type: GENERATE_MOBILE_APP_ID,
  }),
  setMobileAppID: (mobileAppID, mobileAppQRCode) => ({
    type: SET_MOBILE_APP_ID,
    mobileAppID,
    mobileAppQRCode,
  }),
  setActiveAccessLevels: accessLevels => ({
    type: SET_ACTIVE_ACCESSLEVELS,
    accessLevels,
  }),
  setAccessLevelRole: (accessRoleName, accessRoleData) => ({
    type: SET_ACCESSLEVEL_ROLE,
    accessRoleName,
    accessRoleData,
  }),
  setAuthenticationFlag: isAuthenticating => ({
    type: SET_AUTHENTICATION_FLAG,
    isAuthenticating,
  }),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOGINSETUP_GENERAL_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case UPDATE_LOGINS_LIST:
      return {
        ...state,
        logins: action.result.logins,
        totalLogins: action.result.total,
      };
    case SET_LOGINS_PAGE:
      return {
        ...state,
        loginsPage: action.page,
      };
    case RESET_LOGINS_LIST:
      return {
        ...state,
        logins: [...initialState.logins],
      };
    case UPDATE_ACCESSLEVEL_LIST:
      return {
        ...state,
        accessGroups: action.result,
        totalAccessGroups: 0,
      };
    case UPDATE_ACCESSROLE_LIST:
      return {
        ...state,
        rolesForAccessGroup: action.result,
      };
    case UPDATE_LINKABLE_PREPARERS:
      return {
        ...state,
        linkablePreparers: action.preps,
      };
    case OPEN_LOGIN_MODAL:
      return {
        ...state,
        currentEditLogin: action.currentEditLogin,
        loginModalOpen: true,
        isEditingLogin: action.isEditing,
      };
    case CLOSE_LOGIN_MODAL:
      return {
        ...state,
        loginModalOpen: false,
      };
    case OPEN_AL_MODAL:
      return {
        ...state,
        accessLevelModalOpen: true,
      };
    case CLOSE_AL_MODAL:
      return {
        ...state,
        accessLevelModalOpen: false,
        rolesForAccessGroup: [],
      };
    case TOGGLE_CO_MODAL:
      return {
        ...state,
        isCopyOfficeSettingModalOpen: !state.isCopyOfficeSettingModalOpen,
      };
    case TOGGLE_LOGINS_COLLAPSE:
      return {
        ...state,
        expandedLogins: !state.expandedLogins,
      };
    case TOGGLE_ACCESSLEVLES_COLLPASE:
      return {
        ...state,
        expandedAccessLevels: !state.expandedAccessLevels,
      };
    case LOGINMANAGEMENT_SET_TRAINING_MODE:
      return {
        ...state,
        isTrainingMode: action.mode,
      };
    case SET_AUTHENTICATION_FLAG:
      return {
        ...state,
        isAuthenticating: action.isAuthenticating,
      };
    case SET_LOGIN_SETTINGS:
      // CLO-3072
      // Login Settings have their JSON values set as strings when they are initially set. This Code block of iterating over the keys can be removed
      // when all login settings for all users have been fixed. Newly seeded login settings as of CLO-3072 should be fixed.
      Object.keys(action.settings).forEach(settingskey => {
        if (
          typeof action.settings[settingskey] === 'string' &&
          action.settings[settingskey].charAt(0) === '{'
        ) {
          action.settings[settingskey] = JSON.parse(action.settings[settingskey]);
        }
      });
      return {
        ...state,
        loginSettings: action.settings,
      };
    case SET_MOBILE_APP_INTEGRATION:
      return {
        ...state,
        mobileAppID: action.mobileAppID,
        mobileAppQRCode: action.mobileAppQRCode,
        mobileAppEmail: action.mobileAppEmail,
      };
    case SET_MOBILE_APP_ID:
      return {
        ...state,
        mobileAppID: action.mobileAppID,
        mobileAppQRCode: action.mobileAppQRCode,
      };
    case SET_ACTIVE_ACCESSLEVELS:
      return {
        ...state,
        activeAccessLevels: action.accessLevels,
      };
    case SET_FINALIZE_LOGIN:
      return {
        ...state,
        finalizeLogin: action.isFinalized,
      };
    case SET_ACCESSLEVEL_ROLE:
      return {
        ...state,
        allAccessRoles: {
          ...state.allAccessRoles,
          [action.accessRoleName]: action.accessRoleData,
        },
      };
  }
  return state;
}

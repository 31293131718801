export const style = {
  toolbar: {
    backgroundColor: '#444A59',
  },
  buttonGroup: {
    textAlign: 'right',
    marginTop: '2em',
  },
  groupDropdown: {
    width: '7em',
    marginRight: '2em',
  },
  dropdown: {
    width: '15em',
    display: 'inlineBlock',
  },
  dropdownText: {
    paddingTop: '0.5em',
    paddingBottom: '0.5em',
    color: '#0077FF',
  },
  subject: {
    width: '24em',
  },
  button: {
    width: '9em',
    fontSize: '14px',
    borderRadius: '4px',
    marginLeft: '2em',
  },
  labelStyles: {
    color: '#354052',
    textAlign: 'left',
    fontFamily: 'Roboto',
  },
  inputStyle: {
    width: '20vw',
    height: '3.5vh',
  },
  inputStyleNativeSelectProvider: {
    width: '19vw',
    height: '3.5vh',
  },
  inputStyleNativeSelectState: {
    width: '17vw',
    height: '3.5vh',
  }
};

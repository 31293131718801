// External imports
import { jsPDF as JSPDF } from 'jspdf';
import get from 'lodash-es/get';
// Internal imports
import XlinkAPI from '~/app/api/xlinkAPI';
import ErrorHelpers from '~/app/errorHelpers.js';
import { displayPrint, statusOK } from '~/app/webHelpers.js';
// Image imports
import downloadIcon from '~/images/icons/downloadIcon.svg';

// formatDollarAmt formats values into a dollar-cents format.
// Calc Server sends negative values as -10000 = -100.00
// We wrap our negative numbers in parenths rather than using a dash (10000) or ($10000) = -10000
export const formatDollarAmt = (num, hasPrefix = false) => {
  num = num?.toString(); // cannot check length of an int
  if (num !== undefined) {
    if (num.length < 100 && num.length > 0) {
      const isNegative = num.includes('-');
      if (isNegative) {
        num = num.substring(1, num.length);
      }
      return `${isNegative ? '(' : ''}${hasPrefix ? '$' : ''}${(num / 100).toPrecision(
        num.length,
      )}${isNegative ? ')' : ''}`;
    }
  }
  return '';
};

export const generatePrintManualPaymentForm = (payDetails, isBusiness) => {
  const doc = new JSPDF();

  doc.text('Tax Return Payment Details', 15, 20).setFont('times', 'bold').setFontSize('11');

  if (isBusiness) {
    // Row 1
    doc.text('Company EIN: ', 20, 35).setFont('times', 'normal');
    doc.text(payDetails.ein, 49, 35).setFont('times', 'bold');
    doc.text('Business Name: ', 100, 35).setFont('times', 'normal');
    doc.text(payDetails.businessName, 133, 35).setFont('times', 'bold');
  } else {
    // Row 1
    doc.text('Taxpayer SSN: ', 20, 35).setFont('times', 'normal');
    doc.text(payDetails.pssn, 49, 35).setFont('times', 'bold');
    doc.text('Name: ', 100, 35).setFont('times', 'normal');
    doc.text(payDetails.pname, 114, 35).setFont('times', 'bold');

    // Row 1.2
    doc.text('Spouse SSN: ', 20, 45).setFont('times', 'normal');
    doc.text(get(payDetails, 'sssn', ''), 49, 45).setFont('times', 'bold');
    doc.text('Name: ', 100, 45).setFont('times', 'normal');
    doc.text(get(payDetails, 'sname', ''), 114, 45).setFont('times', 'bold');
  }

  // Row 2
  if (get(payDetails, 'netInvoiceAmount', 0) !== 0) {
    doc.text('Net Invoice Amount: ', 25, 55).setFont('times', 'normal');
    doc.text(payDetails.netInvoiceAmount, 65, 55).setFont('times', 'bold');
  }
  if (get(payDetails, 'due', 0) !== 0) {
    doc.text('Due: ', 90, 55).setFont('times', 'normal');
    doc.text(payDetails.due, 100, 55).setFont('times', 'bold');
  }

  // Row 4
  doc.text('Date Received: ', 25, 65).setFont('times', 'normal');
  doc.text(payDetails.dateReceived, 65, 65).setFont('times', 'bold');
  doc.text('Received From: ', 110, 65).setFont('times', 'normal');
  doc.text(payDetails.receivedFrom, 140, 65).setFont('times', 'bold');
  // Row 5
  doc.text('Payment Method: ', 25, 75).setFont('times', 'normal');
  doc.text(payDetails.paymentMethod, 65, 75).setFont('times', 'bold');
  doc.text('Amount: ', 110, 75).setFont('times', 'normal');
  doc.text(payDetails.amount, 140, 75).setFont('times', 'bold');
  // Row 6
  doc.text('Reference Number: ', 25, 85).setFont('times', 'normal');
  doc.text(payDetails.referenceNum, 65, 85).setFont('times', 'bold');
  doc.text('Received By: ', 110, 85).setFont('times', 'normal');
  doc.text(payDetails.receivedBy, 140, 85).setFont('times', 'bold');
  // Row 6
  doc.text('Serial Number: ', 25, 95).setFont('times', 'normal');
  doc.text(payDetails.paymentGUID, 65, 95).setFont('times', 'bold');
  // Row 7
  doc.text('Memo: ', 25, 105).setFont('times', 'normal');
  doc.text(payDetails.memo, 65, 105).setFont('times', 'bold');

  const pdfURI = doc.output('datauristring');
  const pdfString = pdfURI.replace('data:application/pdf;filename=generated.pdf;base64,', '');

  displayPrint(pdfString, downloadIcon);
};

// getWallet calls xlinkcloud for the wallet tokens, then opens the wallet using the given response
export const getWallet = async (season, isDrilldown, efinID = 0, returnID = 0) => {
  try {
    const resp = await XlinkAPI.getWallet(efinID, returnID, false, false);

    if (statusOK(resp) && resp?.data) {
      return resp.data;
    }
  } catch (err) {
    throw ErrorHelpers.createSimpleError(
      'Unable to connect to wallet, if the problem persists please contact technical support.',
    );
  }
};

// openWallet opens the CPTS wallet in a new tab for the given token and security token.
export const openWallet = (token, securityToken, season, isDrilldown = false) => {
  const walletWindow = window.open();

  if (season > 2021) {
    season = `${season}/`;
  } else {
    season = '';
  }

  walletWindow.document.write(
    `<html>
      <head> </head>
      <body>
        Redirecting...
      </body>

    <!-- The following script will run and post right as the page loads -->
    <script type="text/javascript">
      // Generate our dynamic form
      const form = document.createElement("form");
      form.method = "POST";
      form.action =
      "${WALLET_URL}/auth/login/${token}/${season}?dd=${isDrilldown}";

      // Add our security token to the form
      const hiddenField = document.createElement("input");
      hiddenField.type = "hidden";
      hiddenField.name = "secToken";
      hiddenField.value = "${securityToken}";
      form.appendChild(hiddenField);

      document.body.appendChild(form);
      form.submit();
    </script>
  </html>`,
  );
};

import React, { Fragment, useState, useEffect } from 'react';
import { actions as formViewerActions } from '~/app/redux/modules/formViewer';
import ErrorHelpers from '~/app/errorHelpers.js';
import Spinner from '#/Common/Spinner.jsx';
// Redux Imports
import { useSelector, useDispatch } from 'react-redux';
// Extretnal Imports
import { Grid, Input, InputLabel, FormControl } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import NumberFormat from 'react-number-format';
import { themeStyles } from '~/app/Pages/Returns/components/RemoteInvoice/css/remoteInvoiceModal.js';
import '~app/Pages/Returns/components/RemoteInvoice/css/remoteInvoiceModall.css';

const compStyles = theme => themeStyles(theme);

const RemoteInvoiceModal = props => {
  const { classes } = props;
  const dispatch = useDispatch();

  const { invoiceFees, remoteInvoiceData } = useSelector(state => ({
    invoiceFees: state.formViewer?.remoteInvoiceData?.invoiceFees,
    remoteInvoiceData: state.formViewer?.remoteInvoiceData,
  }));

  const [conveinceFee, setConvenience] = useState(0);

  useEffect(() => {
    invoiceFees?.forEach(fee => {
      // fee type 70 is the convenience fee
      // we display this seperately from the other fees
      if (fee?.feetype === 70) {
        setConvenience(fee?.feeTotal);
      }
    });
  }, []);

  const renderFeeRows = () => {
    return invoiceFees?.map((fee, index) => {
      if (fee?.feetype === 70) {
        return null;
      }
      return (
        <TableRow id={'riRow' + index} key={index}>
          <TableCell className="noselect">{fee.feeName}</TableCell>
          <TableCell />
          <TableCell className="noselect" colSpan={2}>
            {fee?.feeTotal ? fee?.feeTotal?.toFixed(2) : '0.00'}
          </TableCell>
        </TableRow>
      );
    });
  };

  const renderTotals = () => {
    return (
      <>
        <TableRow>
          <TableCell rowSpan={3}></TableCell>
          <TableCell align="left">Subtotal</TableCell>
          <TableCell align="right">{remoteInvoiceData?.subTotal?.toFixed(2)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Convenience Fee</TableCell>
          <TableCell align="right">{conveinceFee?.toFixed(2)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Total</TableCell>
          <TableCell align="right">{remoteInvoiceData?.amount?.toFixed(2)}</TableCell>
        </TableRow>
      </>
    );
  };

  // currenty unused, invoice to email now handled by Remote Invoice service itself
  // const sendInvoice = async () => {
  //   try {
  //     const invoice = {
  //       walletToken: remoteInvoiceData.walletToken,
  //       secToken: remoteInvoiceData.secToken,
  //       customerMsg: remoteInvoiceData.customerMsg,
  //       urlGuid: remoteInvoiceData.urlGuid,
  //       email: remoteInvoiceData.email,
  //     };
  //     await InvoiceAPI.sendRemoteInvoice(invoice);
  //     props.sendRemotePayment();
  //     dispatch(formViewerActions.toggleRemoteInvoiceModal(false));
  //   } catch (error) {
  //     ErrorHelpers.handleError('Error sending invoice: ', error);
  //   }
  // };

  return Object.entries(remoteInvoiceData).length > 0 ? (
    <div id="remoteInvoiceModal" className="remoteinvoice-modal-content" tabIndex="0">
      <div className="remoteinvoice-modal-header-container">
        <Typography variant="title" classes={{ root: classes.remoteinvoiceHeaderTitle }}>
          Remote Invoice
        </Typography>
      </div>
      <div className="remoteinvoice-table-content remoteInvoiceModal">
        {/* display message if invoice was cancelled before getting sent */}
        {remoteInvoiceData?.urlGuid ? (
          <Fragment>
            <Grid
              container
              spacing={8}
              alignContent="flex-start"
              justify="flex-start"
              alignItems="baseline"
              className="remoteinvoice-fragment"
            >
              <Grid item xs={8}>
                <FormControl fullWidth>
                  <InputLabel shrink>Title</InputLabel>
                  <Input
                    id="txtrcvdFrom"
                    disableUnderline
                    disabled={true}
                    value={remoteInvoiceData?.title}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel shrink>Creation Time</InputLabel>
                  <Input
                    id="taxDate"
                    disableUnderline
                    disabled={true}
                    value={remoteInvoiceData?.statusDate}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="txtEFIN" shrink>
                    EFIN
                  </InputLabel>
                  <NumberFormat
                    name="taxEFIN"
                    id="txtEFIN"
                    value={remoteInvoiceData?.efin}
                    customInput={Input}
                    disableUnderline
                    disabled={true}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={7}>
                <FormControl fullWidth>
                  <InputLabel shrink>Email Address</InputLabel>
                  <Input
                    id="eAddress"
                    disableUnderline
                    disabled={true}
                    value={remoteInvoiceData?.email}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={11}>
                <FormControl fullWidth>
                  <InputLabel shrink>Invoice Status</InputLabel>
                  <Input
                    id="estatus"
                    disableUnderline
                    disabled={true}
                    value={remoteInvoiceData?.invoiceStatus}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell classes={{ root: classes.tableHeaderCell }}>Fee</TableCell>
                  <TableCell classes={{ root: classes.tableHeaderCell }} />
                  <TableCell classes={{ root: classes.tableHeaderCell }}>Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {renderFeeRows()}
                {renderTotals()}
              </TableBody>
            </Table>
          </Fragment>
        ) : (
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h6"
            id="tableTitle"
            className="remoteinvoice-fragment"
          >
            The selected invoice had been cancelled before getting sent to the Taxpayer.
          </Typography>
        )}
      </div>
      <div className="container remoteinvoice-btns-container">
        <Button
          id="btnCancelremoteinvoice"
          color="primary"
          size="small"
          classes={{ root: classes.cancelButtonRoot }}
          onClick={props.onClose}
        >
          Close
        </Button>
      </div>
    </div>
  ) : (
    <span className="centered-spinner">
      <Spinner
        size={100}
        color="blue"
        loadingText={'Loading...'}
        textColor="white"
        bgColor="grey"
        lockActions={true}
      />
    </span>
  );
};

export default withStyles(compStyles)(RemoteInvoiceModal);

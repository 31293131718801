export const styles = {
  modal: {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1300,
    height: 500,
    boxShadow: 24,
    p: 4,
    backgroundColor: '#fafbfc',
  },
  toolbarStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#444A59',
  },
  typographyStyle: {
    color: '#FFFFFF',
    fontFamily: 'Roboto',
    fontSize: '15px',
  },
  infoModalTitle: {
    pointerEvents: 'none',
  },
  typographyInfoModal: {
    paddingTop: '1.5rem',
    paddingRight: '3rem',
    paddingLeft: '3rem',
    fontSize: '15px',
    color: '#354052',
  },
  worksheetBtn: {
    marginTop: '1rem',
    width: '100%',
    letterSpacing: '1px',
  },
  fieldInfoBtn: {
    backgroundColor: '#0077FF',
    height: '4rem',
    width: '2.5rem',
    minHeight: '0',
    minWidth: '0',
    marginTop: '1rem',
    marginLeft: '10rem',
    marginBottom: '1.5rem',
    borderRadius: '1rem 0 0 1rem',
    border: '0',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
    '&:hover': {
      backgroundColor: '#0077FF',
    },
  },
  fieldInfoBtnIcon: {
    fontSize: '2.5rem',
    color: '#fff',
  },
};
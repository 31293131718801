import axios from 'axios';
import WebHelpers from '~/app/webHelpers.js';
import ErrorHelpers from '~/app/errorHelpers.js';

const CancelToken = axios.CancelToken;
const cancelFunctions = [];
export default class InvoiceAPI {
  /// api/invoice/new/
  static createRemoteInvoice = async invoice => {
    return await axios
      .post(
        `${PAYMENT_URL}/api/invoice/new/`,
        {
          title: invoice.title /* REQUIRED: name for the invoice */,
          returnGUID: invoice.returnGUID /* REQUIRED */,
          reqType:
            invoice.reqType /* OPTIONAL: defaults to "I".  Permitted values are "I" (individual) and "i" (business) */,
          walletToken: invoice.walletToken /* REQUIRED - wallet token */,
          efin: invoice.efin /* REQUIRED: string, efin for the ERO tax preparer */,
          invoiceFees: invoice.invoiceFees,
          sendInvoice: false, // do not send invoice on creation
          emailAddress:
            invoice.emailAddress /* required if sendInvoice is true - email address to send the invoice to */,
          customerMsg:
            invoice.customerMsg /* optional - additional message sent to customer. only used if sendInvoice is true */,
          firstNameOverride: invoice.firstNameOverride,
          lastNameOverride: invoice.lastNameOverride,
        },
        {
          headers: {
            secToken: invoice.secToken,
          },
          cancelToken: new CancelToken(fn => {
            cancelFunctions.push(fn);
          }),
        },
      )
      .catch(function (error) {
        ErrorHelpers.handleError('Unable to generate Remote Invoice', error);
      });
  };

  static getRemoteInvoice = async (invoiceGUID, walletToken, secToken) => {
    return await axios.post(
      `${PAYMENT_URL}/api/invoice/${invoiceGUID}/`,
      {
        walletToken: walletToken /* REQUIRED - wallet token */,
      },
      {
        headers: {
          secToken: secToken,
        },
        cancelToken: new CancelToken(fn => {
          cancelFunctions.push(fn);
        }),
      },
    );
  };

  // now currently unused as RemoteInvoice handles the send portion.
  // keeping endpoint if we decide to handle the send on our end
  static sendRemoteInvoice = async invoice => {
    return await axios.post(
      `${PAYMENT_URL}/api/invoice/send/`,
      {
        urlGuid: invoice.urlGuid /* REQUIRED - the remote invoice GUID */,
        emailAddress: invoice.email /* REQUIRED - email to send the invoice to */,
        customerMsg: invoice.customerMsg /* client message added to email with invoice */,
        walletToken: invoice.walletToken /* REQUIRED - wallet token */,
      },
      {
        headers: {
          secToken: invoice.secToken,
        },
        ...WebHelpers.getAuthHeaders(),
        cancelToken: new CancelToken(fn => {
          cancelFunctions.push(fn);
        }),
      },
    );
  };
}

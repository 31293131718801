// External imports
import React from 'react';
import { withStyles } from '@material-ui/core';
// Internal imports
import { SIDE_TABS, ACCOUNT_TYPES, HIERARCHY_TYPE } from '~/app/constants.js';
import Breadcrumbs from '#/Common/Breadcrumbs.jsx';
import GenericTitleBar from '#/Common/FullScreen/GenericTitleBar.jsx';
import WebHelpers from '~/app/webHelpers.js';
import addAccount from '~/images/addAccount.png';
// Redux imports
import { useSelector } from 'react-redux';
// Styles imports
import { styles } from '../css/accountTypeSelectionStyles';

/**
 * AccountHeader is used to display a dynamic Title based on which account is selected or currentStep
 * It also handles the step through and back logic
 *
 * @component AccountHeader
 * @category CreateAccount
 **/
const AccountHeader = props => {
  const {
    drilldownHistory,
    selectedSidebarTab,
    isTrainingMode,
    impersonation,
    displayName,
    newAccountType,
    currentView,
  } = useSelector(state => ({
    drilldownHistory: state.drilldown.drilldownHistory,
    selectedSidebarTab: state.app.tabState.selectedSidebarTab,
    isTrainingMode: state.loginSetup.isTrainingMode,
    impersonation: state.app.impersonation,
    displayName: state.app.loggedInUser.displayName,
    newAccountType: state.accountCreation.type,
    currentView: state.drilldown.drilldownHistory[state.drilldown.drilldownHistory.length - 1],
  }));

  const isInTrainingMode = isTrainingMode;

  const isDrilldown =
    drilldownHistory !== undefined &&
    drilldownHistory.length > 1 &&
    selectedSidebarTab === SIDE_TABS.DASHBOARD_MAIN;

  // Add header for demo accounts
  let demoAccountFlag = false;
  const payload = WebHelpers.getJWTPayload();
  const accountCode = payload.account_code;
  if (accountCode === 'CLODMO') {
    demoAccountFlag = true;
  }

  /**
   * This handles dispatching redux action to set the account type.
   *
   * @param {number} accountType This number represents the users Account Type
   */

  let BreadcrumbsClassName = 'breadcrumb-list';
  if (isInTrainingMode) {
    BreadcrumbsClassName = 'training-breadcrumb-list';
  } else {
    BreadcrumbsClassName = 'breadcrumb-list';
  }

  const efinType = ACCOUNT_TYPES.EFIN;
  const franchiseType = ACCOUNT_TYPES.FRANCHISE;
  const headerText =
    newAccountType === efinType
      ? 'Create New Single Office'
      : newAccountType === franchiseType
      ? 'Create New Multi Office Account'
      : 'Create New Reseller Account';

  return (
    <div className="body-content">
      {selectedSidebarTab === SIDE_TABS.DASHBOARD_MAIN && (
        <Breadcrumbs
          fields={drilldownHistory}
          showFieldAsLinks={false}
          passClassName={BreadcrumbsClassName}
          isInTrainingMode={isInTrainingMode}
          demoAccountFlag={demoAccountFlag}
          impersonation={impersonation}
          displayName={displayName}
        />
      )}
      {props.currentStep === 1 ? (
        <GenericTitleBar
          headerImage={addAccount}
          headerText={'Create New Account'}
          cancelText={'Cancel'}
          confirmText={'Continue'}
          cancelButtonAction={props.handleCancelClick}
          confirmButtonAction={props.handleContinueClick}
          enableCreateButton={props.isFormValid}
          isDrilledDown={isDrilldown}
          demoAccountFlag={demoAccountFlag}
        />
      ) : (
        <GenericTitleBar
          headerImage={addAccount}
          headerText={headerText}
          cancelText={currentView?.role === HIERARCHY_TYPE.FRANCHISE ? 'Cancel' : 'Previous'}
          confirmText={'Create'}
          cancelButtonAction={props.handleCancelClick}
          confirmButtonAction={() => props.handleCreateClick()}
          enableCreateButton={props.isFormValid}
          isDrilledDown={isDrilldown}
          demoAccountFlag={demoAccountFlag}
        />
      )}
    </div>
  );
};

export default withStyles(styles)(AccountHeader);

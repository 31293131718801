/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-string-refs */
import React, { Component, Fragment } from 'react';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  withStyles,
  Typography,
} from '@material-ui/core';
import { toBMPDataURL, sigTypes } from '~/app/bmpConversionHelpers.js';

import * as Global from '~/app/constants.js';
import { SIGNEE_TOOLBAR_TITLE } from '~/app/constants.js';

// https://www.socketloop.com/tutorials/golang-serving-http-and-websocket-from-different-ports-in-a-program-example
// https://stackoverflow.com/questions/38122068/how-react-js-acts-as-a-websocket-client
// https://scriptel.com/OmniScriptAPI/tutorial-Connecting.html

const styles = theme => ({
  styledHeader: {
    background: '#444C59',
    border: 0,
    color: 'white',
    height: 64,
    padding: '0 30px',
    PaperProps: {
      style: {
        backgroundColor: '#fff',
        color: 'black',
      },
    },
  },
});

class ScriptelSignatureCapture extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sigStringData: '',
      sigRawData: '',
      disableSignButton: false,
      imgWidth: 0,
      imgHeight: 0,
      topazReady: false,
      sigImageData: '',
      imageLoaded: false,
      tabletConnected: 1,
      scriptelErrorMessageModalOpened: false,
      connected: false,
      scriptelMessages: [],
      scriptelSignature: [],
      showSciptelDrivers: false,
      scriptelCertificateMessageModalOpened: false,
      blankSignature: true,
      deviceConnected: false,
      scriptelBlankSignatureModalOpened: false,
    };
  }

  componentDidMount() {
    let lastPoint = null;
    this.setState({ blankSignature: true });

    // eslint-disable-next-line react/no-string-refs
    const canvas = this.refs.cnv;
    const ctx = canvas.getContext('2d');

    let portConnection;

    try {
      if (window.location.href.indexOf('https') >= 0) {
        portConnection = 'wss://localhost:8443';
      } else {
        portConnection = 'ws://localhost:8080';
      }
    } catch (err) {
      // Open modal stating that user needs to add security certificate
      this.openCertificateModal();
      if (err.name === 'SecurityError')
        alert("Please add this site's domain to your local intranet zones in Internet Options.");
      return;
    }

    // Variable to hold new Websocket
    // eslint-disable-next-line prettier/prettier
    const ws = (this.ws = new WebSocket(portConnection));

    // Websocket onerror
    ws.onerror = () => {
      alert(
        'Please make sure that the Scriptel Drivers are installed and your Scriptel pad is connected.',
      );
    };

    // Websocket onopen
    ws.onopen = () => {
      // console.log("Web Socket Opened");
    };

    // Websocket onclose
    ws.onclose = () => {
      // console.log("Web Socket Closed");
    };

    ws.onmessage = evt => {
      const msg = JSON.parse(evt.data);
      // const hasBlankSignature = this.state.blankSignature;

      if (msg._class === 'ConnectionOpen') {
        // console.log("We've just connected to the server");
        const totalDevices = msg.serverInfo.devices.length;
        // console.log("totalDevices: ", totalDevices);
        if (totalDevices === 0) {
          // console.log("No Scriptel Pad Connected")
          this.showScriptelNotConnected();
          // this.setState({scriptelErrorMessageModalOpened : true});
        } else {
          // set value for device connected
          this.setState({ deviceConnected: true });
          const device = msg.serverInfo.devices[0];
          // console.log("Opening device", device.uuid, ": ", device.manufacturer, device.product);
          const obj = { _class: 'DeviceOpenRequest', uuid: device.uuid };
          ws.send(JSON.stringify(obj));
        }
      } else if (msg._class === 'DeviceOpenResponse') {
        // We've just connected to the device, lets send our preferred
        // rendering settings
        // console.log("Successfully opened device!");
        const obj = {
          _class: 'RenderSettingsUpdateRequest',
          renderSettings: {
            _class: 'RenderSettings',
            type: 'image/png',
            scale: 2,
          },
        };
        ws.send(JSON.stringify(obj));
        canvas.width = msg.device.displayInfo.width * 2;
        canvas.height = msg.device.displayInfo.height * 2;
        canvas.style.display = 'inline';

        // console.log("Set canvas size to ", canvas.width, "x", canvas.height);
      } else if (msg._class === 'PenDown') {
        lastPoint = msg;
        // Set blankSignature to false if msg._class is equal to PenDown
        this.setState({ blankSignature: false });
      } else if (msg._class === 'PenUp' || msg._class === 'PenMove') {
        ctx.beginPath();
        ctx.moveTo(lastPoint.x * 2, lastPoint.y * 2);
        ctx.lineTo(msg.x * 2, msg.y * 2);
        ctx.lineWidth = 3;
        ctx.strokeStyle = '#000000';
        ctx.stroke();

        lastPoint = msg;
      } else if (msg._class === 'ButtonDown' || msg._class === 'ButtonPress') {
        // Check for the OK and Cancel Button labels
        if (msg.label === 'OK') {
          // OK to send signature data to calc server
        } else if (msg.label === 'Cancel') {
          // Clear the screen
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          this.setState({ blankSignature: true });
        }
        // Check for the _class === RenderedImage
      } else if (msg._class === 'RenderedImage') {
        // Only execute this part of the code when bankSignature is false
        if (this.state.blankSignature === false) {
          const padType = 'screen';
          const scriptel = 2;
          // Check to see if we need to convert string
          if (this.props.convertString === false) {
            // We send back the signature data back
            // PNG is the default
            const pngUrl = canvas.toDataURL();

            // We are done here, close the websocket
            ws.close();

            // Close ScriptelSignatureCapture.jsx modal
            this.props.handleClose();

            this.props.handleSignatureCapture(sigTypes[this.props.currentSig], pngUrl, scriptel);
          } else {
            // We can actually call the function to convert the data into the 1 pixel bmp file expected by calcserver here
            let sigString = toBMPDataURL(canvas, padType);
            sigString = sigString.replace('data:image/bmp;base64,', ''); // strip url src info

            // We are done here, close the websocket
            ws.close();

            // Close ScriptelSignatureCapture.jsx modal
            this.props.handleClose();

            // Send signature data to calcserver and insert signatures on pdf
            this.props.handleSignatureCapture(sigTypes[this.props.currentSig], sigString, scriptel);
          }
        } else {
          this.setState({ scriptelBlankSignatureModalOpened: true });
          // alert("Please sign using your Scriptel signature pad before continuing");
        }
      } else if (
        msg._class === 'ScriptelException' ||
        msg._class === 'RenderSettingsUpdateRequest'
      ) {
        this.commonClear();
      } else {
        // console.log("Scriptel pad sent a message:", msg);
      }
    };
  }

  componentWillUnmount() {
    // We are done here, close the websocket
    // Only clear the screen when a device is connected
    const checkDeviceConnected = this.state.deviceConnected;
    if (checkDeviceConnected === true) {
      this.setState({ blankSignature: true });
      this.commonClear();
      this.ws.close();
    }
    this.props.handleClose();
  }

  closeScriptelErrorMessageModal = () => {
    this.setState({ scriptelErrorMessageModalOpened: false });
    this.props.handleClose();
  };

  closeScriptelBlankSignatureErrorMessageModal = () => {
    this.setState({ scriptelBlankSignatureModalOpened: false });
  };

  closeScriptelCertificateMessageModal = () => {
    this.setState({ scriptelCertificateMessageModalOpened: false });
    this.props.handleClose();
    window.open('https://localhost:8443/', '_blank');
  };

  openCertificateModal = () => {
    this.setState({ scriptelCertificateMessageModalOpened: true });
    this.props.handleClose();
  };

  showScriptelNotConnected = () => {
    this.setState({ scriptelErrorMessageModalOpened: true });
  };

  Cancel = e => {
    e.preventDefault();
    // Only clear the screen when a device is connected
    const checkDeviceConnected = this.state.deviceConnected;
    if (checkDeviceConnected === true) {
      this.commonClear();
    }
    this.props.handleClose();
  };

  ClearSignature = e => {
    e.preventDefault();
    // Only clear the screen when a device is connected and we have a signature
    const checkBlankSignature = this.state.blankSignature;
    const checkDeviceConnected = this.state.deviceConnected;
    if (checkDeviceConnected === true && checkBlankSignature === false) {
      this.commonClear();
      this.setState({ blankSignature: true });
    }
  };

  sendSignature = () => {
    if (this.state.blankSignature === false) {
      const canvas = this.refs.cnv;
      const padType = 'screen';
      const scriptel = 2;

      // Check if we need to convert signature data to 1 pixel bmp file
      if (this.props.convertString === false) {
        // We send back the signature data back
        // PNG is the default
        const pngUrl = canvas.toDataURL();
        this.props.handleSignatureCapture(sigTypes[this.props.currentSig], pngUrl, scriptel);
      } else {
        // We can actually call the function to convert the data into the 1 pixel bmp file expected by calcserver here
        let sigString = toBMPDataURL(canvas, padType);
        sigString = sigString.replace('data:image/bmp;base64,', ''); // strip url src info

        // We are done here, close the websocket
        this.commonClear();

        // Close ScriptelSignatureCapture.jsx modal
        this.props.handleClose();

        // Send signature data to calcserver and insert signatures on pdf
        this.props.handleSignatureCapture(sigTypes[this.props.currentSig], sigString, scriptel);
      }

      this.setState({ blankSignature: true });
    } else {
      this.setState({ scriptelBlankSignatureModalOpened: true });
      // alert("Please sign using your Scriptel signature pad before continuing");
    }
  };

  commonClear = () => {
    // Clear the screen
    const canvas = this.refs.cnv;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    // Need to clear the Scriptel pad screen
    const obj = {
      _class: 'ClearScreenRequest',
      cid: 'Scriptel',
    };
    this.ws.send(JSON.stringify(obj));
  };

  showScriptelDrivers = () => {
    this.setState({ showSciptelDrivers: true });
  };

  hideScriptelDrivers = () => {
    this.setState({ showSciptelDrivers: false });
  };

  render() {
    const toolbarColor = { backgroundColor: '#444A59' };
    // 1=>TP, 2=>SP, 3=>PP, 4=>ERO, 5=>TP initial, 6=>SP initial, 8=>TP 2nd, 9=>SP 2nd, A=>PP if amended
    let toolbarTitle = '';
    switch (this.props.currentSig) {
      case '1':
        toolbarTitle = SIGNEE_TOOLBAR_TITLE.TAXPAYER;
        break;
      case '2':
        toolbarTitle = SIGNEE_TOOLBAR_TITLE.SPOUSE;
        break;
      case '3':
        toolbarTitle = SIGNEE_TOOLBAR_TITLE.PREPARER;
        break;
      case '4':
        toolbarTitle = SIGNEE_TOOLBAR_TITLE.ERO;
        break;
      case '5':
        toolbarTitle = SIGNEE_TOOLBAR_TITLE.TAXPAYER_INITIAL;
        break;
      case '6':
        toolbarTitle = SIGNEE_TOOLBAR_TITLE.SPOUSE_INITIAL;
        break;
      case '8':
        toolbarTitle = SIGNEE_TOOLBAR_TITLE.TAXPAYER_SECOND_SIGNATURE;
        break;
      case '9':
        toolbarTitle = SIGNEE_TOOLBAR_TITLE.SPOUSE_SECOND_SIGNATURE;
        break;
      case 'A':
        toolbarTitle = SIGNEE_TOOLBAR_TITLE.AMENDED_PAID_PREPARER_SIGNATURE;
        break;
      default:
        toolbarTitle = SIGNEE_TOOLBAR_TITLE.DEFAULT;
        break;
    }

    return (
      <div className="container-fluid">
        <Paper
          style={{
            width: '570px',
            height: 'auto',
            norderRadius: '4px',
            margin: 'auto',
            position: 'relative',
          }}
        >
          <AppBar position="static">
            <Toolbar style={toolbarColor}>
              <span style={{ fontFamily: 'Roboto', fontSize: '18px' }}>{toolbarTitle}</span>
            </Toolbar>
          </AppBar>
          <form style={{ marginLeft: '2vw', marginRight: '3vw', marginTop: '1vh' }}>
            <div style={{ marginTop: '3vh' }}>
              <div>
                <p style={{ fontSize: '15px', textAlign: 'justify' }}>
                  Please sign using your Scriptel signature pad, and press the "OK" button on screen
                  or on your Scriptel signature pad once you are done Signing.
                </p>
              </div>
              <div
                style={{
                  textAlign: 'left',
                  paddingTop: '0.5vh',
                  paddingBottom: '0.5vh',
                  marginLeft: '0vw',
                  marginTop: '0.5vh',
                }}
              >
                <p
                  id="btnDownloadScriptelDrivers"
                  style={{
                    display: 'inline',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    minWidth: '0px',
                    minHeight: '3.5vh',
                    textTransform: 'capitalize',
                    fontFamily: 'Roboto',
                    fontSize: '14px',
                    borderRadius: '4px',
                    marginRight: '1.5vw',
                    color: '#0077FF',
                  }}
                >
                  Need to download Scriptel Drivers?
                </p>
                <Button
                  id="btnDownloadScriptelDrivers"
                  onClick={this.showScriptelDrivers}
                  style={{
                    display: 'inline',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    minWidth: '0px',
                    minHeight: '3.5vh',
                    textTransform: 'capitalize',
                    fontFamily: 'Roboto',
                    fontSize: '14px',
                    borderRadius: '4px',
                    marginRight: '1.5vw',
                    border: 'none',
                  }}
                >
                  Yes
                </Button>
                <Button
                  id="btnDownloadScriptelDrivers"
                  onClick={this.hideScriptelDrivers}
                  style={{
                    display: 'inline',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    minWidth: '0px',
                    minHeight: '3.5vh',
                    textTransform: 'capitalize',
                    fontFamily: 'Roboto',
                    fontSize: '14px',
                    borderRadius: '4px',
                    marginRight: '1.5vw',
                    border: 'none',
                  }}
                >
                  No
                </Button>
              </div>
              {this.state.showSciptelDrivers === true ? (
                <div>
                  <div
                    style={{
                      float: 'left',
                      marginRight: 'auto',
                      marginLeft: '0em',
                      marginTop: '2vh',
                    }}
                  >
                    <p>
                      <a href={Global.DOWNLOAD_DRIVER_LINKS.SCRIPTEL_32_OLD}>
                        For Windows 32 bit (OLD VERSION), click here
                      </a>
                      .
                    </p>
                    <p>
                      <a href={Global.DOWNLOAD_DRIVER_LINKS.SCRIPTEL_32_NEW}>
                        For Windows 32 bit (NEW VERSION), click here
                      </a>
                      .
                    </p>
                    <p>
                      <a href={Global.DOWNLOAD_DRIVER_LINKS.SCRIPTEL_64}>
                        For Windows 64 bit, click here
                      </a>
                      .
                    </p>
                    <p>
                      <a href={Global.DOWNLOAD_DRIVER_LINKS.SCRIPTEL_LINUX}>
                        For Ubuntu, click here
                      </a>
                      .
                    </p>
                  </div>
                </div>
              ) : (
                <Fragment />
              )}
              <table border="1" cellPadding="0" width="500">
                <tbody>
                  <tr>
                    <td height="150" width="500">
                      <canvas id="cnv" name="cnv" ref="cnv" width="480" height="128" />
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
              <div
                style={{
                  textAlign: 'center',
                  padding: '0',
                  marginLeft: '0vw',
                  marginTop: '0.5vh',
                }}
              >
                <Button
                  id="btnOKScriptel"
                  onClick={this.sendSignature}
                  style={{
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    minWidth: '0px',
                    minHeight: '3.5vh',
                    textTransform: 'capitalize',
                    fontFamily: 'Roboto',
                    fontSize: '14px',
                    borderRadius: '4px',
                    marginRight: '1.5vw',
                  }}
                >
                  OK
                </Button>
                <Button
                  id="btnClearScriptel"
                  onClick={this.ClearSignature}
                  style={{
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    minWidth: '0px',
                    minHeight: '3.5vh',
                    textTransform: 'capitalize',
                    fontFamily: 'Roboto',
                    fontSize: '14px',
                    borderRadius: '4px',
                    marginRight: '1.5vw',
                  }}
                >
                  Clear
                </Button>
                <Button
                  id="btnCancelScriptel"
                  onClick={this.Cancel}
                  style={{
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    minWidth: '0px',
                    minHeight: '3.5vh',
                    textTransform: 'capitalize',
                    fontFamily: 'Roboto',
                    fontSize: '14px',
                    borderRadius: '4px',
                    marginRight: '1.5vw',
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
            <br />
          </form>
        </Paper>
        <Dialog
          disableAutoFocus={true}
          PaperProps={{ tabIndex: -1 }}
          open={this.state.scriptelBlankSignatureModalOpened}
          onClose={() => this.closeScriptelBlankSignatureErrorMessageModal()}
          aria-labelledby="confirm-scriptel-blank-signature-title"
          aria-describedby="confirm-scriptel-blank-signature-desc"
        >
          <DialogTitle
            id="confirm-scriptel-blank-signature-title"
            className={this.props.classes.styledHeader}
          >
            <Typography
              style={{
                color: 'white',
                fontFamily: 'Roboto',
                fontSize: '18px',
                marginTop: '23px',
              }}
            >
              {'Signature Missing'}
            </Typography>
          </DialogTitle>
          <DialogContent id="confirm-scriptel-connected-desc">
            <p
              style={{
                marginTop: '3vh',
                marginBottom: '1vh',
                fontSize: '15px',
                fontFamily: 'Roboto',
                textAlign: 'justify',
              }}
            >
              Please sign using your Scriptel signature pad before continuing.
            </p>
          </DialogContent>
          <DialogActions>
            <Button
              id="btnHideScriptelPadConnectedModal"
              style={{ marginRight: '1vw', marginBottom: '1vh' }}
              onClick={() => this.closeScriptelBlankSignatureErrorMessageModal()}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          disableAutoFocus={true}
          PaperProps={{ tabIndex: -1 }}
          open={
            !this.state.scriptelCertificateMessageModalOpened &&
            this.state.scriptelErrorMessageModalOpened
          }
          onClose={() => this.closeScriptelErrorMessageModal()}
          aria-labelledby="confirm-scriptel-connected-title"
          aria-describedby="confirm-scriptel-connected-desc"
        >
          <DialogTitle
            id="confirm-scriptel-connected-title"
            className={this.props.classes.styledHeader}
          >
            <Typography
              style={{
                color: 'white',
                fontFamily: 'Roboto',
                fontSize: '18px',
                marginTop: '23px',
              }}
            >
              {'Scriptel Signature Pad not connected'}
            </Typography>
          </DialogTitle>
          <DialogContent id="confirm-scriptel-connected-desc">
            <p
              style={{
                marginTop: '3vh',
                marginBottom: '1vh',
                fontSize: '15px',
                fontFamily: 'Roboto',
                textAlign: 'justify',
              }}
            >
              Please make sure your Scriptel signature pad is connected before continuing.
            </p>
          </DialogContent>
          <DialogActions>
            <Button
              id="btnHideScriptelPadConnectedModal"
              style={{ marginRight: '1vw', marginBottom: '1vh' }}
              onClick={() => this.closeScriptelErrorMessageModal()}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.scriptelCertificateMessageModalOpened}
          onClose={() => this.closeScriptelCertificateMessageModal()}
          aria-labelledby="confirm-scriptel-certificate-title"
          aria-describedby="confirm-scriptel-certificate-desc"
          disableAutoFocus={true}
          PaperProps={{ tabIndex: -1 }}
        >
          <DialogTitle
            id="confirm-scriptel-certificate-title"
            className={this.props.classes.styledHeader}
          >
            <Typography
              style={{
                color: 'white',
                fontFamily: 'Roboto',
                fontSize: '18px',
                marginTop: '23px',
              }}
            >
              {'Certificate Missing'}
            </Typography>
          </DialogTitle>
          <DialogContent id="confirm-scriptel-certificate-desc">
            <p
              style={{
                marginTop: '3vh',
                marginBottom: '1vh',
                fontSize: '15px',
                fontFamily: 'Roboto',
                textAlign: 'justify',
              }}
            >
              The certificate needed to establish a connection with your Scriptel pad is missing.
              Please click on the "OK" button below, which will open a new tab. Once on the new page
              "https:localhost:8443" opens, click on the "ADVANCED" and then click on "Proceed to
              localhost (unsafe)".
            </p>
          </DialogContent>
          <DialogActions>
            <Button
              id="btnHideScriptelCertificateModal"
              style={{ marginRight: '1vw', marginBottom: '1vh' }}
              onClick={() => this.closeScriptelCertificateMessageModal()}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(ScriptelSignatureCapture);

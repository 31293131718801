import { SIGNEE_TOOLBAR_TITLE } from '~/app/constants.js';

export const getSigneeTitle = signeeType => {
  let toolbarTitle = '';
  switch (signeeType) {
    case '1':
      toolbarTitle = SIGNEE_TOOLBAR_TITLE.TAXPAYER;
      break;
    case '2':
      toolbarTitle = SIGNEE_TOOLBAR_TITLE.SPOUSE;
      break;
    case '3':
      toolbarTitle = SIGNEE_TOOLBAR_TITLE.PREPARER;
      break;
    case '4':
      toolbarTitle = SIGNEE_TOOLBAR_TITLE.ERO;
      break;
    case '5':
      toolbarTitle = SIGNEE_TOOLBAR_TITLE.TAXPAYER_INITIAL;
      break;
    case '6':
      toolbarTitle = SIGNEE_TOOLBAR_TITLE.SPOUSE_INITIAL;
      break;
    case '7':
      toolbarTitle = SIGNEE_TOOLBAR_TITLE.OFFICER;
      break;
    case '8':
      toolbarTitle = SIGNEE_TOOLBAR_TITLE.TAXPAYER_SECOND_SIGNATURE;
      break;
    case '9':
      toolbarTitle = SIGNEE_TOOLBAR_TITLE.SPOUSE_SECOND_SIGNATURE;
      break;
    case 'A':
      toolbarTitle = SIGNEE_TOOLBAR_TITLE.AMENDED_PAID_PREPARER_SIGNATURE;
      break;
    default:
      toolbarTitle = SIGNEE_TOOLBAR_TITLE.DEFAULT;
      break;
  }
  return toolbarTitle;
};

// Wizard Estimator Module
const RESET_WIZARD_ESTIMATOR = 'xlinknonline/wizardEstimator/RESET_WIZARD_ESTIMATOR';
const SET_WIZARD_FORM_FIELDS = 'xlinknonline/wizardEstimator/SETWIZARDFORMFIELDS';
const SET_WIZARD_MAPPED_UI = 'xlinknonline/wizardEstimator/SETWIZARDMAPPEDUI';
const UPDATE_WIZARD_FORM_FIELDS = 'xlinknonline/wizardEstimator/UPDATEWIZARDFORMFIELDS';
const REMOVE_WIZARD_FORM_FIELD = 'xlinknonline/wizardEstimator/REMOVEWIZARDFORMFIELD';
const UPDATE_WIZARD_MAPPED_UI = 'xlinknonline/wizardEstimator/UPDATEWIZARDMAPPEDUI';
const UPDATE_WIZARD_DROPDOWN_OPTIONS = 'xlinknonline/wizardEstimator/UPDATEWIZARDDROPDOWNOPTIONS';
const UPDATE_WIZARD_DROPDOWN_MAP = 'xlinknonline/wizardEstimator/UPDATE_WIZARD_DROPDOWN_MAP';
const UPDATE_WIZARD_CURRENT_STEP = 'xlinknonline/wizardEstimator/UPDATEWIZARDCURRENTSTEP';
const SET_WIZARD_INITIAL_DYNAMIC_FIELDS =
  'xlinknonline/wizardEstimator/SETWIZARDINITIALDYNAMICFIELDS';
const UPDATE_WIZARD_DYNAMIC_FIELDS = 'xlinknonline/wizardEstimator/UPDATEWIZARDDYNAMICFIELDS';
const UPDATE_WIZARD_DYNAMIC_FIELDS_WITH_LIST =
  'xlinknonline/wizardEstimator/UPDATEWIZARDDYNAMICFIELDSWITHLIST';
const UPDATE_WIZARD_INITIAL_DYNAMIC_FIELDS =
  'xlinknonline/wizardEstimator/UPDATEWIZARDINITIALDYNAMICFIELDS';
const UPDATE_WIZARD_INITIAL_DYNAMIC_FIELD_COUNTER =
  'xlinknonline/wizardEstimator/UPDATEWIZARDINITIALDYNAMICFIELDCOUNTER';
const SET_WIZARD_DYNAMICS = 'xlinknonline/wizardEstimator/SET_WIZARD_DYNAMICS';
const SET_WIZARD_FORM_STATUS = 'xlinknonline/wizardEstimator/GET_WIZARD_FORM_STATUS';
const UPDATE_WIZARD_FORM_STATUS = 'xlinknonline/wizardEstimator/UPDATE_WIZARD_FORM_STATUS';
const SET_REQUIRED_FIELD = 'xlinknonline/wizardEstimator/SET_REQUIRED_FIELDS';

const initialState = {
  wizardFormFields: {
    // Each specific form is an object containing FieldID's and their values as the key/val
    US1W: {}, // Taxpayer Info
    US9W: {}, // Taxpayer Contact
    US2W: {}, // Children & Dependents
    US3W: {}, // Income
    US4W: {}, // Deductions
    US5W: {}, // Credits
    US6W: {}, // Misc
    US7W: {}, // State Income Tax
    US8W: {}, // Results
  },
  requiredFields: {
    US1W: [], // Taxpayer Info
    US9W: [], // Taxpayer Contact
    US2W: [], // Children & Dependents
    US3W: [], // Income
    US4W: [], // Deductions
    US5W: [], // Credits
    US6W: [], // Misc
    US7W: [], // State Income Tax
    US8W: [], // Results
  },
  wizardMappedUI: {
    // Inside each object is the entire response from a cs form_load response.
    // View wizardMappuedUI's contents to see how the UI will be mapped out ->
    // it should be an ordered Array of Objects/fields, that are sorted by their 'row' prop
    US1W: [], // Taxpayer Info
    US9W: [], // Taxpayer Contact
    US2W: [], // Children & Dependents
    US3W: [], // Income
    US4W: [], // Deductions
    US5W: [], // Credits
    US6W: [], // Misc
    US7W: [], // State Income Tax
    US8W: [], // Results
  },
  wizardDropdownOptions: {}, // contains dropdown options retrieved from 'return_choicelist' for dropdown inputs
  wizardDropdownMap: {}, // mapper of drop down values to be sent to calcserver for choice list
  wizardDynamicFields: {}, // keeps track of which fields are dynamic and how many fields have been added or removed
  wizardInitialDynamicFields: {}, // saves the initial dynamic fields as blueprints for adding dynamic fields
  // Keeps track of how many dynamic fields to create. This is form specific, reset this when we navigate to other forms.
  wizardInitialDynamicFieldCounters: {},
  wizardCurrentStep: 0, // consider changing this to null, so niether form viewer or wizard mode fetch pages, until the initial dispatch that starts either with 0 or 8
  wizardFormStatus: {
    taxpayerCompleted: false,
    contactCompleted: false,
    childrenCompleted: false,
    incomeCompleted: false,
    deductionsCompleted: false,
    creditsCompleted: false,
    stateCompleted: false,
    miscellaneousCompleted: false,
  },
};

export const actions = {
  resetWizardEstimator: () => ({
    type: RESET_WIZARD_ESTIMATOR,
  }),
  setWizardFormFields: (formName, form) => ({
    type: SET_WIZARD_FORM_FIELDS,
    formName,
    form,
  }),
  setWizardMappedUI: (formName, form) => ({
    type: SET_WIZARD_MAPPED_UI,
    formName,
    form,
  }),
  updateWizardFormFields: (formName, fieldName, value) => ({
    type: UPDATE_WIZARD_FORM_FIELDS,
    formName,
    fieldName,
    value,
  }),
  removeWizardFormField: (formName, fieldID) => ({
    type: REMOVE_WIZARD_FORM_FIELD,
    formName,
    fieldID,
  }),
  updateWizardMappedUI: (formName, updatedUI) => ({
    type: UPDATE_WIZARD_MAPPED_UI,
    formName,
    updatedUI,
  }),
  updateWizardDropdownOptions: (options, formName) => ({
    type: UPDATE_WIZARD_DROPDOWN_OPTIONS,
    options,
    formName,
  }),
  updateWizardDropdownMap: optionsMap => ({
    type: UPDATE_WIZARD_DROPDOWN_MAP,
    optionsMap,
  }),
  updateWizardCurrentStep: step => ({
    type: UPDATE_WIZARD_CURRENT_STEP,
    step,
  }),
  setWizardInitialDynamicFields: (formName, initialFields) => ({
    type: SET_WIZARD_INITIAL_DYNAMIC_FIELDS,
    formName,
    initialFields,
  }),
  updateWizardDynamicFields: (formName, fieldNames, updatedFields) => ({
    type: UPDATE_WIZARD_DYNAMIC_FIELDS,
    formName,
    fieldNames,
    updatedFields,
  }),
  updateWizardDynamicFieldsList: (formName, fieldNamesList) => ({
    type: UPDATE_WIZARD_DYNAMIC_FIELDS_WITH_LIST,
    formName,
    fieldNamesList,
  }),
  updateWizardInitialDynamicFields: initialFields => ({
    type: UPDATE_WIZARD_INITIAL_DYNAMIC_FIELDS,
    initialFields,
  }),
  updateWizardInitialDynamicFieldCounters: counters => ({
    type: UPDATE_WIZARD_INITIAL_DYNAMIC_FIELD_COUNTER,
    counters,
  }),
  setWizardFormFieldandCounters: (formName, form, counters) => ({
    type: SET_WIZARD_DYNAMICS,
    formName,
    form,
    counters,
  }),
  setWizardFormStatus: formStatus => ({
    type: SET_WIZARD_FORM_STATUS,
    formStatus,
  }),
  updateWizardFormStatus: formName => ({
    type: UPDATE_WIZARD_FORM_STATUS,
    formName,
  }),
  setRequiredFields: (formName, fieldName) => ({
    type: SET_REQUIRED_FIELD,
    formName,
    fieldName,
  }),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RESET_WIZARD_ESTIMATOR:
      return {
        ...initialState,
      };
    case SET_WIZARD_FORM_FIELDS:
      return {
        ...state,
        wizardFormFields: {
          ...state.wizardFormFields,
          [action.formName]: {
            ...state.wizardFormFields[action.formName],
            ...action.form,
          },
        },
      };
    case SET_WIZARD_DYNAMICS:
      return {
        ...state,
        wizardInitialDynamicFieldCounters: action.counters,
        wizardFormFields: {
          ...state.wizardFormFields,
          [action.formName]: {
            ...state.wizardFormFields[action.formName],
            ...action.form,
          },
        },
      };
    case SET_WIZARD_MAPPED_UI:
      return {
        ...state,
        wizardMappedUI: {
          ...state.wizardMappedUI,
          [action.formName]: [...action.form],
        },
      };
    case UPDATE_WIZARD_FORM_FIELDS:
      return {
        ...state,
        wizardFormFields: {
          ...state.wizardFormFields,
          [action.formName]: {
            ...state.wizardFormFields[action.formName],
            [action.fieldName]: action.value,
          },
        },
      };
    case REMOVE_WIZARD_FORM_FIELD:
      delete state.wizardFormFields[action.formName][action.fieldID];
      return {
        ...state,
      };
    case UPDATE_WIZARD_MAPPED_UI:
      return {
        ...state,
        wizardMappedUI: {
          ...state.wizardMappedUI,
          [action.formName]: [...action.updatedUI],
        },
      };
    case UPDATE_WIZARD_DROPDOWN_OPTIONS:
      return {
        ...state,
        wizardDropdownOptions: {
          ...state.wizardDropdownOptions,
          [action.formName]: [...action.options],
        },
      };
    case UPDATE_WIZARD_DROPDOWN_MAP:
      return {
        ...state,
        wizardDropdownMap: action.optionsMap,
      };
    case UPDATE_WIZARD_CURRENT_STEP:
      return {
        ...state,
        wizardCurrentStep: action.step,
        wizardInitialDynamicFieldCounters: {},
      };
    case UPDATE_WIZARD_INITIAL_DYNAMIC_FIELD_COUNTER:
      return {
        ...state,
        wizardInitialDynamicFieldCounters: {
          ...state.wizardInitialDynamicFieldCounters,
          ...action.counters,
        },
      };
    case SET_WIZARD_INITIAL_DYNAMIC_FIELDS:
      return {
        ...state,
        wizardDynamicFields: {
          ...state.wizardDynamicFields,
          [action.formName]: { ...action.initialFields },
        },
      };
    case UPDATE_WIZARD_DYNAMIC_FIELDS:
      return {
        ...state,
        wizardDynamicFields: {
          ...state.wizardDynamicFields,
          [action.formName]: {
            ...state.wizardDynamicFields[action.formName],
            [action.fieldNames]: [...action.updatedFields],
          },
        },
      };
    case UPDATE_WIZARD_DYNAMIC_FIELDS_WITH_LIST:
      return {
        ...state,
        wizardDynamicFields: {
          ...state.wizardDynamicFields,
          [action.formName]: {
            ...action.fieldNamesList,
          },
        },
      };
    case UPDATE_WIZARD_INITIAL_DYNAMIC_FIELDS:
      return {
        ...state,
        wizardInitialDynamicFields: {
          ...action.initialFields,
        },
      };
    case SET_WIZARD_FORM_STATUS:
      return {
        ...state,
        wizardFormStatus: action.formStatus,
      };
    case UPDATE_WIZARD_FORM_STATUS:
      return {
        ...state,
        wizardFormStatus: { ...state.wizardFormStatus, [action.formName]: true },
      };
    case SET_REQUIRED_FIELD:
      return {
        ...state,
        requiredFields: {
          ...state.requiredFields,
          [action.formName]: state.requiredFields[action.formName].concat(action.fieldName),
        },
      };
  }
  return state;
}

/**
 * @module appHelper
 * @category Utility
 */

/**
 * Show the chat button.
 * @function
 * @param {object} loggedInUser
 */
export const showChat = () => {
  const chatBtnEle = document.getElementById('launcher');
  // Hide the chat for cobrander since it may have already been rendered.
  if (chatBtnEle || (chatBtnEle && chatBtnEle.style.display === 'none')) {
    chatBtnEle.style.display = 'block';
  }
};

/**
 * Hide the chat button.
 * @function
 * @param {object} loggedInUser
 */
export const hideChat = () => {
  const chatBtnEle = document.getElementById('launcher');
  // Hide the chat for cobrander since it may have already been rendered.
  if (chatBtnEle || (chatBtnEle && chatBtnEle.style.display === 'block')) {
    chatBtnEle.style.display = 'none';
  }
};

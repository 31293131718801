import React, { Fragment } from 'react';
// External Imports
import {
  Paper,
  Button,
  Grid,
  Card,
  AppBar,
  Toolbar,
  Typography,
  withStyles,
} from '~/node_modules/@material-ui/core';
// Styling Imports
import InfoIcon from '@material-ui/icons/Info';
import '~/app/Pages/Setup/RestrictedFields/css/infoModal.css';
import { styles } from './css/infoModal.js';

const InfoModal = props => {
  const { classes } = props;
  return (
    <Paper className={classes.modal}>
      <AppBar position="static">
        <Toolbar className={classes.toolbarStyle}>
          <Typography className={`${classes.typographyStyle} ${classes.infoModalTitle}`}>
            Restricted Fields Information
          </Typography>
          <Typography className={classes.typographyStyle} onClick={props.toggleInfoModal}>
            X
          </Typography>
        </Toolbar>
      </AppBar>
      <Grid className="info-modal-grid-container" container spacing={1}>
        <Grid item md={4} sm={4} xs={4}>
          <Card className="info-modal-card">
            <Typography className={classes.typographyInfoModal} variant="subtitle1">
              An office owner can lock certain fields within a tax form for their office using the
              Restricted Fields feature.
            </Typography>
            <Typography className={classes.typographyInfoModal} variant="subtitle1">
              To restrict a field, navigate to any return and open the information side panel by
              clicking the blue button located on the right side of any opened return.
            </Typography>
            <Card className="info-modal-button-card">
              <Button classes={{ root: classes.fieldInfoBtn }}>
                <InfoIcon classes={{ root: classes.fieldInfoBtnIcon }} />
              </Button>
            </Card>
          </Card>
        </Grid>
        <Grid item md={4} sm={4} xs={4}>
          <Card className="info-modal-card">
            <Typography className={classes.typographyInfoModal} variant="subtitle1">
              Within the information side pane, a field can be locked/unlocked from within the
              return directly. It also displays the field description and field ID near the top.
            </Typography>

            <div className="info-modal-container">
              <div className={`info-modal-drawer`}>
                <section className="info-modal-description-container info-modal-body-container">
                  <h3 className="info-modal-heading">Field Description</h3>
                  {`Taxpayer's First Name`}
                </section>

                <Fragment>
                  <section className="info-modal-fieldID-container info-modal-body-container">
                    <h3 className="info-modal-heading">Field ID</h3>
                    {`US01PNMA`}
                  </section>
                  <div>
                    <Button classes={{ root: classes.worksheetBtn }}>{'Lock Field'}</Button>
                  </div>
                </Fragment>
              </div>
            </div>
          </Card>
        </Grid>
        <Grid item md={4} sm={4} xs={4}>
          <Card className="info-modal-card">
            <Typography
              className={classes.typographyInfoModal}
              variant="subtitle1"
              gutterBottom
              component="div"
            >
              A field can also be entered manually using the field ID. The proper format for the
              field ID is an 8 character-long key that combines both the form and field.
            </Typography>
            <div className="info-modal-fieldID-example">
              <Card className="info-modal-card">
                <span className="info-modal-form-example">Form</span>+
                <span className="info-modal-field-example">Field</span>
              </Card>
              <Card className="info-modal-card">
                <span className="info-modal-form-example">US01</span>
                <span className="info-modal-field-example">PNMA</span>
              </Card>
            </div>

            <Typography
              className={classes.typographyInfoModal}
              variant="subtitle1"
              gutterBottom
              component="div"
            >
              Restricted Fields can also be toggled on/off to the office owner&apos;s liking as well
              as deleted.
            </Typography>
          </Card>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default withStyles(styles)(InfoModal);

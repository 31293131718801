// External Imports
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Input } from '@material-ui/core';
// Internal Imports
import WebHelpers from '~/app/webHelpers.js';
import {
  removeHypen,
  suppressEnter,
  handleBlindInputChange,
  handleBlindBackSpace,
} from '~/app/Pages/TaxReturns/taxReturnsHelper.js';
// Redux Imports
import { useSetState } from '~/app/Utility/customHooks';
// Styling imports
import '~app/Pages/TaxReturns/components/AddNewReturn/css/addNewReturn.css';

const SSN_INVALID = 'Invalid Social Security Number';
const SSN_NO_MATCH = 'The Social Security Numbers entered do not match';
const ADD_BUTTON_NORMAL = 'Create Regular Return';
const ADD_BUTTON_WORKING = 'Checking...';
const W7SSN = '999-99-9999';

const TenFourtyReturn = props => {
  const payload = WebHelpers.getJWTPayload();

  const [state, setState] = useSetState({
    ssn: '',
    ssnConfirm: '',
    ssnBlind: '',
    ssnConfirmBlind: '',
    errorMessage: '',
    readyToSubmit: false,
    addButtonText: ADD_BUTTON_NORMAL,
    return: [],
  });

  useEffect(() => {
    if (state.ssn !== '' && state.ssnConfirm !== '') {
      validate();
    }
  }, [state.ssn, state.ssnConfirm]);

  const validate = () => {
    setState({
      errorMessage: '',
    });

    const ssn = removeHypen(state.ssn);
    const ssnConfirm = removeHypen(state.ssnConfirm);
    let isReady = false;
    let isContentValid = true;

    // only validate SSNs clientside in prod
    if (ENVIRONMENT === 'production' && payload.training_returns_only === false) {
      const area = ssn.substring(0, 3);
      const group = ssn.substring(3, 5);
      const serial = ssn.substring(5);
      const areaFlag = area && area.length === 3 && area[0] === '9';
      const groupFlag =
        group &&
        group.length === 2 &&
        ((group >= 50 && group <= 65) ||
          (group >= 70 && group <= 88) ||
          (group >= 90 && group <= 99)); // 93 == ATIN

      // Per pub 4164 13.6
      const isValidITIN = areaFlag && groupFlag;

      // SSN validate
      if (area === '000' || area === '666' || (area[0] === '9' && group.length === 2)) {
        isContentValid = false;
      } else if (group === '00') {
        isContentValid = false;
      } else if (serial === '0000') {
        isContentValid = false;
      }

      switch (ssn) {
        case '111111111':
        case '222222222':
        case '333333333':
        case '444444444':
        case '555555555':
        case '666666666':
        case '777777777':
        case '888888888':
        case '999999999':
          isContentValid = false;
      }

      // overwrite decision if the SSN is actually an ITIN
      if (!isContentValid) isContentValid = isValidITIN;

      if (!isContentValid) {
        setState({
          errorMessage: SSN_INVALID,
        });
        return;
      }
    }

    if (ssnConfirm.length === 9) {
      const ssnSub = ssn.substring(0, ssnConfirm.length);
      if (ssnConfirm !== ssnSub) {
        setState({
          errorMessage: SSN_NO_MATCH,
        });
      } else if (ssnSub.length === 9) {
        // SSN confirm has been fully entered and is not mismatched
        isReady = true;
      }
    }

    setState({
      readyToSubmit: isReady,
    });
  };

  const handleAdd = e => {
    const isEnterPress = e.type === 'keydown' && e.keyCode === 13;
    if (isEnterPress) {
      // enter key should do nothing if we're not readyToSubmit
      e.preventDefault();
    }

    if (state.readyToSubmit && (e.type === 'click' || isEnterPress)) {
      e.preventDefault();
      const ssn = removeHypen(state.ssnConfirm);
      setState({
        addButtonText: ADD_BUTTON_WORKING,
        readyToSubmit: false,
      });
      props.setSSNConfirm(ssn);
      props.onAddNewReturn(removeHypen(ssn), true);
    }
  };

  const handleW7Click = () => {
    setState({ ssn: W7SSN, ssnConfirm: W7SSN });
    props.setSSNConfirm(W7SSN);
    props.onAddNewReturn(removeHypen(W7SSN));
  };

  return (
    <>
      <div className="new-return-modal-heading">
        Double entry of the Social Security Number helps ensure accuracy.
      </div>
      <form
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <div className="container">
          <div className="row">
            <div style={{ paddingTop: '10px' }} className="col-md-5 new-return-modal-label">
              Social Security Number
            </div>
            <div className="col-md-7">
              <div data-private>
                <Input
                  id={'txtSSNAddNewReturn'}
                  name="ssn"
                  value={props.blindEntryNewReturn === '1' ? state.ssnBlind : state.ssn}
                  style={{ paddingLeft: '0.5vw' }}
                  disableUnderline
                  autoFocus
                  className="new-return-modal-form-field"
                  inputProps={{ maxLength: 11 }}
                  placeholder={'000-00-0000'}
                  onChange={e =>
                    handleBlindInputChange(
                      e,
                      props.blindEntryNewReturn,
                      setState,
                      state[e.target.name],
                      false,
                    )
                  }
                  onKeyDown={e => {
                    if (props.blindEntryNewReturn === '1') {
                      handleBlindBackSpace(e, state[e.target.name], setState, false);
                    }
                    suppressEnter(e);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div style={{ paddingTop: '10px' }} className="col-md-5 new-return-modal-label">
              Confirm Entry
            </div>
            <div className="col-md-7">
              <div data-private>
                <Input
                  id={'txtSSNConfirmAddNewReturn'}
                  name="ssnConfirm"
                  value={
                    props.blindEntryNewReturn === '1' ? state.ssnConfirmBlind : state.ssnConfirm
                  }
                  style={{ paddingLeft: '0.5vw' }}
                  disableUnderline
                  className="new-return-modal-form-field"
                  inputProps={{ maxLength: 11 }}
                  placeholder={'000-00-0000'}
                  onChange={e =>
                    handleBlindInputChange(
                      e,
                      props.blindEntryNewReturn,
                      setState,
                      state[e.target.name],
                      false,
                    )
                  }
                  onKeyDown={e => {
                    if (props.blindEntryNewReturn === '1') {
                      handleBlindBackSpace(e, state[e.target.name], setState, false);
                    }
                    handleAdd(e);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="new-return-modal-error-message">{state.errorMessage}</div>
        <div className="container d-flex justify-content-between mt-3 mb-2">
          <Button
            id="divW7FormAddNewReturn"
            style={{ border: 'none', marginRight: '1vw' }}
            onClick={() => handleW7Click()}
          >
            W7 Form
          </Button>

          <Button
            id="btnAddNewReturnAddNewReturn"
            type="submit"
            disabled={!state.readyToSubmit}
            onClick={e => handleAdd(e)}
          >
            {state.addButtonText}
          </Button>
        </div>
      </form>
    </>
  );
};

export default withRouter(TenFourtyReturn);

export const styles = {
  addAssetModal: {
    width: '900px',
    padding: '0',
    position: 'relative',
    margin: 'auto',
    top: '10%',
  },
  eventLogModal: {
    width: '56.25rem',
    position: 'relative',
    margin: 'auto',
    top: '10%',
  },
  errorRejectModal: {
    width: '80vw',
    padding: '0',
    position: 'relative',
    margin: 'auto',
    top: '10%',
  },
  k1ManagerModal: {
    width: '56.25rem',
    padding: '0',
    position: 'relative',
    margin: 'auto',
    top: '10%',
  },
  addFormModal: {
    width: '860px',
    padding: '0',
    position: 'relative',
    margin: 'auto',
    top: '20%',
  },
  addFormModalShortScreen: {
    width: '860px',
    padding: '0',
    position: 'relative',
    margin: 'auto',
    top: '0%',
  },
  printDialog: {
    padding: '0',
    position: 'relative',
    margin: 'auto',
    top: '20%',
  },
  printComponentsModal: {
    width: '25rem',
    padding: '0',
    position: 'relative',
    margin: 'auto',
    top: '20%',
  },
  printStateAcksModal: {
    width: '450px',
    padding: '0',
    position: 'relative',
    margin: 'auto',
    top: '20%',
  },
  choiceListModal: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'transparent',
  },
  addDocumentModal: {
    top: '50%',
    left: '50%',
    boxShadow: '0 0 20px 0 rgba(0,0,0,0.25)',
    height: 'auto',
    width: '860px',
    padding: '0',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
  },
  remoteInvoiceModal: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'transparent',
  },
  addReturnModals: {
    content: {
      top: '25%',
      left: '27%',
      right: 'auto',
      bottom: 'auto',
      height: '366px',
      width: '618px',
      padding: '0',
      boxShadow: '0 0 20px 0 rgba(0,0,0,0.25)',
    },
  },
  transferReturnModal: {
    height: 'auto',
    maxHeight: '400px',
    width: '500px',
    right: 'auto',
    bottom: 'auto',
    top: '25%',
    position: 'relative',
    margin: 'auto',
    borderRadius: '4px',
    boxShadow: '0 0 40px 0 rgba(0,0,0,0.1)',
  },
  queueReturnModal: {
    maxHeight: 'fit-content',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  lockedByMessage: {
    height: '52px',
    maxWidth: '108.5em',
    padding: '0.5em',
    color: 'black',
    backgroundColor: '#F8E0E0',
    border: '1px solid #FFB1BB',
    borderRadius: '4px',
    fontFamily: 'Roboto',
    fontSize: '15px',
    margin: '0px',
    marginBottom: '1em',
  },
  formStretch: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '1em',
    height: 'unset',
    maxHeight: 'unset',
  },
  formAuto: {
    display: 'grid',
    gridAutoColumns: 'max-content',
    gridAutoRows: 'min-content',
    paddingTop: '1em',
    height: 'unset',
    maxHeight: 'unset',
  },
  modalTitle: {
    paddingLeft: '1.5rem',
    color: 'white',
    backgroundColor: '#444A59',
    height: '70px',
    paddingTop: '1.5rem',
    paddingRight: '1.5rem',
  },
  modal: {
    top: '50%',
    left: '50%',
    boxShadow: '0 0 20px 0 rgba(0,0,0,0.25)',
    maxWidth: '80vw',
    minWidth: '30vw',
    padding: '0',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
  },
  alertModalBody: {
    fontSize: '1.3rem',
  },
  alertModalButtons: {
    textAlign: 'center',
  },
  alertModalButton: {
    fontFamily: 'Roboto',
    fontSize: '1rem',
    margin: '1rem',
  },
  modalSpacing: {
    marginTop: '3.7rem',
  },
};

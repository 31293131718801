export const styles = theme => ({
  tableRowRoot: {
    height: '35px',
    letterSpacing: '0.1px',
  },
  tableCellRoot: {
    maxWidth: '18rem',
    wordWrap: 'break-word',
  },
  tableHeadRoot: {
    height: '40px',
    letterSpacing: '0.1px',
  },
  containerPadding: {
    padding: '1rem',
  },
});

export const styles = {
  msgReturnProfile: { textDecoration: 'none' },
  msgReplyButton: {
    textTransform: 'capitalize',
    padding: '8px',
    marginTop: '0px',
    marginLeft: '2em',
  },
  msgDeleteBtn: {
    color: '#0077FF',
    backgroundColor: 'transparent',
    outline: 'none',
  },
};

// External imports
import React, { useEffect } from 'react';
import { Link, withRouter, useHistory } from 'react-router-dom';
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  Hidden,
  Input,
  InputLabel,
  Typography,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
// Internal imports
import { useSetState } from '~/app/Utility/customHooks';
import loginHero from '~/images/LoginPage/LoginHero.png';
import AuthAPI from '~/app/api/authAPI.js';
import WebHelpers from '~/app/webHelpers';
import ErrorHelpers from '~/app/errorHelpers.js';
import PerformanceMaintenanceMsg from '#/Common/MaintenanceRoutineMsg/PerformanceMaintenanceMsg.jsx';
import SimpleDialog from '#/Common/SimpleDialog.jsx';
import { displayLogo, formatUsername, checkStartEndDate } from '~/app/Utility/general';
import { webSocket } from '~/app/Utility/socketConnection.js';
import { getFingerprint } from '~/app/Pages/Login/loginHelper.js';
// Redux imports
import { useSelector, useDispatch } from 'react-redux';
import { actions as appActions } from '~/app/redux/modules/app';
import { actions as drilldownActions } from '~/app/redux/drilldown/duck';
import { actions as loginSetupActions } from '~/app/redux/loginSetup/duck';
// Styling imports
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import appTheme from '~/themes/GenericTheme.jsx';
import { styles } from '~/app/Pages/Login/css/login.js';
import '~/app/Pages/Login/css/login.css';

/**
 * Component that allows the user to log into the CrossLink Online App as well as navigates them
 * to the forgot username or password pages.
 *
 * @component
 * @category Login
 */
const Login = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { classes } = props;

  const { fingerprint, isAuthenticated, isAuthenticating, alert } = useSelector(state => ({
    fingerprint: state.app.browserFingerprint,
    isAuthenticated: state.app.isAuthenticated,
    isAuthenticating: state.loginSetup.isAuthenticating,
    alert: state.app.alert,
  }));

  const [state, setState] = useSetState({
    username: '',
    password: '',
    rememberMe: true,
    verified: false,
    executing: false,
    xlinkapiver: 'fetching...',
    passwordExpiredDialog: false,
    passwordExpiringSoonDialog: false,
    changePasswordChoice: false,
    maintenanceMode: false,
    maintenanceMsgheading: 'System Maintenance',
    maintenanceMsg:
      'On Saturday, November 4th, from 8pm to 10pm EST. Access to the application will not be available during this time.',
    isAdmin: false,
  });

  useEffect(() => {
    setMaintanenceMode();
    const isAdmin = localStorage.getItem('admin-login') === 'true';

    // Our redux middleware is adding the DD_TOKEN back `storeLatestDrilldownToken` upon any redux action.
    // This means our logout action is removing the token, but then the middleware is fetching it from the store and adding it back
    dispatch(drilldownActions.resetDrilldown());

    getFingerprint();
    setState({
      username: localStorage.getItem('username'),
      isAdmin,
    });
  }, []);

  /** Navigate the user to the dashboard page once they are authenticated. */
  useEffect(() => {
    if (isAuthenticated) {
      webSocket.services();
      history.push({ pathname: '/dashboard' });
    }
  }, [isAuthenticated]);

  useEffect(() => {
    state.changePasswordChoice && handleSubmit();
  }, [state.changePasswordChoice]);

  /** Handles whether to display the username when the user comes back to the app again. */
  const handleChange = e => {
    const name = e.target.name;

    if (name === 'rememberMe') {
      setState({ [name]: e.target.checked });
    } else if (name === 'username') {
      setState({ [name]: formatUsername(e.target.value) });
    } else setState({ [name]: e.target.value });
  };

  /**
   * Handles when the user clicks the submit button by setting the username
   * is local storage if the checkbox was clicked as well as submittig the credentials.
   *
   * @param {Object} e - event which takes place in the DOM.
   */
  const handleSubmit = async e => {
    e?.preventDefault();
    localStorage.setItem('username', state.username);
    if (!state.rememberMe) {
      localStorage.removeItem('username');
    }
    if (!fingerprint) {
      console.log('No fingerprint');
      await getFingerprint();
    }
    setState({ verified: true, executing: false });
    onSubmitCredentials();
  };

  /** Handles displaying the version info on the bottom of the page if the
   * environment is either local, DEV, or QA.
   *
   * @returns {JSX} Version information.
   */
  const renderVersionInfo = () => {
    const isFrontendLocal = window && window.host && window.host.includes('localhost');

    if (ENVIRONMENT === 'local' || ENVIRONMENT === 'development' || ENVIRONMENT === 'QA') {
      return (
        <div className="login-dev-info-block">
          <strong>{'Environment: '}</strong>
          <span>{ENVIRONMENT}</span>
          {isFrontendLocal ? '(local)' : ''}
          <br />
        </div>
      );
    }
  };

  const isDisabled = () => {
    if (!state.maintenanceMode) {
      // login functionality remains the same until release day
      return isAuthenticating;
    }

    return !state.isAdmin || isAuthenticating;
  };

  /**
   * Set when a system maintenance message is shown
   * in place of the login fields upon arrival to crosslinktaxonline.com.
   */
  const setMaintanenceMode = () => {
    // When to show the message.
    const startDate = '2023-11-04 20:00:00';
    // When to hide the message.
    const endDate = '2023-11-04 22:00:00';
    state.maintenanceMode = checkStartEndDate(startDate, endDate);
  };

  /**
   * Submits the credentials to the backend which then will be checked against the
   * password stored in AppServices. If the credentials are correct the user will be
   * navigated to the mfa-method page to start the two factor authentication process.
   *
   * @returns {undefined} To break out of the function.
   */
  const onSubmitCredentials = async () => {
    try {
      dispatch(loginSetupActions.setAuthenticationFlag(true));

      const res = await AuthAPI.submitCredentials(
        state.username.toLowerCase(),
        state.password,
        fingerprint,
      );

      if (res) {
        if (res.password_status && res.password_status === 'expired') {
          setState({ passwordExpiredDialog: true });
          return;
        }

        const payload = WebHelpers.getJWTPayload();
        const passwordExpiringAge = 10;

        payload.password_expiration <= passwordExpiringAge &&
          setState({
            passwordExpiringSoonDialog: true,
            passwordExpirationDays: payload.password_expiration,
          });
        // bring this data back in the response to use in second factor
        sessionStorage.text = '123-345-4564';
        sessionStorage.email = 'testrunner@gmail.com';

        if (state.changePasswordChoice || payload.password_expiration > passwordExpiringAge) {
          setState({
            passwordExpiringSoonDialog: false,
          });

          dispatch(appActions.setAuthStageAwaitSecondFactor());

          history.push({
            pathname: '/mfa-method',
          });
          return;
        }
      }
    } catch (error) {
      console.log('TEST2');
      ErrorHelpers.handleError('Login error', error);
    } finally {
      dispatch(loginSetupActions.setAuthenticationFlag(false));
    }
  };

  /**
   * Handles the navigation to the forgot password page if the user decides to reset their
   * password from the password expiring soon modal.
   */
  const goToForgotPassword = () => {
    setState({
      passwordExpiringSoonDialog: false,
      passwordExpiredDialog: false,
    });
    history.push({
      pathname: 'forgot-password',
    });
  };

  /** Hides the alert modal on confirmation. */
  const handleAlertConfirmation = () => {
    dispatch(loginSetupActions.setAuthenticationFlag(false));
    dispatch(appActions.hideError());
  };

  return (
    <MuiThemeProvider theme={appTheme}>
      <div id="loginContainer">
        <Grid container spacing={0} justify="center">
          <Hidden mdDown>
            <Grid item xs={5} zeroMinWidth>
              <img src={loginHero} />
            </Grid>
          </Hidden>
          <Grid item xs={7} className={classes.gridBlock}>
            <Grid container justify="center">
              <Grid item xs={12} className="login-crosslink-logo">
                <div className="login-form-container">
                  <img className="login-image" src={displayLogo()} />
                </div>
              </Grid>
              <Grid item xs={12} className="logInText">
                <PerformanceMaintenanceMsg
                  showMsg={state.maintenanceMode && isDisabled()}
                  heading={state.maintenanceMsgheading}
                  msg={state.maintenanceMsg}
                />
              </Grid>
              {(!state.maintenanceMode || !isDisabled()) && (
                <>
                  <Grid item xs={12}>
                    <div className="login-form-container">
                      <p className="login-log-in-btn">Log In</p>
                      <form className="login-form" onSubmit={handleSubmit} autoComplete="off">
                        <FormControl fullWidth required>
                          <InputLabel required={false} htmlFor="txtUsernameLogin" shrink>
                            Username
                          </InputLabel>
                          <Input
                            id="txtUsernameLogin"
                            name="username"
                            value={state.username || ''}
                            onChange={handleChange}
                            className={classes.input}
                            disabled={isAuthenticating}
                            disableUnderline
                          />
                        </FormControl>
                        <div id="divForgotUsernameLogin" className="forgotUsername">
                          <Link to={isAuthenticating ? '' : 'forgot-username'}>
                            <Typography id="typForgotUsernameLogin" color="primary">
                              Forgot Username?
                            </Typography>
                          </Link>
                        </div>
                        <FormControl fullWidth required>
                          <InputLabel required={false} htmlFor="txtPasswordLogin" shrink>
                            Password
                          </InputLabel>
                          <Input
                            id="txtPasswordLogin"
                            name="password"
                            type="password"
                            onChange={handleChange}
                            className={classes.input}
                            disableUnderline
                            disabled={isAuthenticating}
                          />
                        </FormControl>
                        <div id="divForgotPassLogin" className="forgotPass">
                          <Link to={isAuthenticating ? '' : 'forgot-password'}>
                            <Typography id="typForgotPassLogin" color="primary">
                              Forgot Password?
                            </Typography>
                          </Link>
                        </div>
                        <FormControl fullWidth>
                          <div className="login-form-control-block row">
                            <FormControlLabel
                              label="Remember Me"
                              control={
                                <Checkbox
                                  color="secondary"
                                  className={classes.checkbox}
                                  icon={
                                    <CheckBoxOutlineBlankIcon
                                      className={classes.checkboxOutlineBlankIcon}
                                      color="primary"
                                    />
                                  }
                                  checkedIcon={
                                    <CheckBoxIcon
                                      className={classes.checkboxIcon}
                                      color="primary"
                                    />
                                  }
                                  name="rememberMe"
                                  id="chkRememberMeLogin"
                                  checked={state.rememberMe}
                                  onChange={handleChange}
                                  disabled={isAuthenticating}
                                  inputProps={styles.checkboxInputProps}
                                />
                              }
                            />
                          </div>
                          <div className="login-btn-block" id="divLoginButtonLogin">
                            <Button
                              id="btnLoginButtonLogin"
                              variant="contained"
                              type="submit"
                              color="primary"
                              className={classes.button}
                              disabled={isAuthenticating}
                            >
                              {isAuthenticating ? (
                                <>
                                  <CircularProgress size={14} className="login-btn-spinner" />{' '}
                                  Logging In
                                </>
                              ) : (
                                'Log In'
                              )}
                            </Button>
                          </div>
                        </FormControl>
                      </form>
                    </div>
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <Grid container justify="center">
                  <div className="recommended-browser-container">
                    <Typography variant="subheading" align="left">
                      <span className="recommended-browser-note">** NOTE:</span> For best user
                      experience, the recommended browser is{' '}
                      <a href="https://www.google.com/chrome/" rel="noreferrer" target="_blank">
                        Google Chrome
                      </a>
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {renderVersionInfo()}
          </Grid>
        </Grid>
      </div>
      <SimpleDialog
        open={alert.show}
        onConfirm={handleAlertConfirmation}
        dialogTitle={alert.title}
        contentText={alert.body}
        contentTextTwo={alert.bodyTwo}
        confirmText="OK"
        styled
        errorMessage
      />
      <SimpleDialog
        open={state.passwordExpiringSoonDialog}
        onClose={() => {
          setState({
            changePasswordChoice: true,
            passwordExpiringSoonDialog: false,
          });
        }}
        onConfirm={goToForgotPassword}
        dialogTitle="Password Expiring Soon"
        contentText={`Your password will expire ${
          state.passwordExpirationDays !== 0
            ? 'in ' + state.passwordExpirationDays + ' day(s).'
            : 'today.'
        } Do you want to change it now?`}
        confirmText="Yes, Change My Password"
        cancelText="No, Log In"
        styled
        errorMessage
      />
      <SimpleDialog
        open={state.passwordExpiredDialog}
        onClose={() => setState({ passwordExpiredDialog: false })}
        onConfirm={goToForgotPassword}
        dialogTitle="Reset Password"
        contentText="Your password has expired. Do you want to reset it now?"
        confirmText="Yes, Reset My Password"
        cancelText="No, Not Now"
        styled
        errorMessage
      />
    </MuiThemeProvider>
  );
};

export default withRouter(withStyles(styles)(Login));

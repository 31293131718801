/* eslint-disable dot-notation */
/* eslint-disable no-case-declarations */
// Remote Signature Action Types
// Fetching Remote Signature Information

// PREPARER SIGNATURE
export const FETCH_PREPARER_SIGNATURE_CALL_REQUEST =
  'xlinkonline/remoteSign/FETCHPREPARERSIGNATUREREQUEST';
export const FETCH_PREPARER_SIGNATURE_CALL_SUCCESS =
  'xlinkonline/remoteSign/FETCHPREPARERSIGNATURESUCCESS';
export const FETCH_PREPARER_SIGNATURE_CALL_FAILURE =
  'xlinkonline/remoteSign/FETCHPREPARERSIGNATUREFAILED';
// ERO SIGNATURE
export const FETCH_ERO_SIGNATURE_CALL_REQUEST = 'xlinkonline/remoteSign/FETCHEROSIGNATUREREQUEST';
export const FETCH_ERO_SIGNATURE_CALL_SUCCESS = 'xlinkonline/remoteSign/FETCHEROSIGNATURESUCCESS';
export const FETCH_ERO_SIGNATURE_CALL_FAILURE = 'xlinkonline/remoteSign/FETCHEROSIGNATUREFAILED';
export const SET_ACTIVE_EFIN_CALL = 'xlinkonline/remoteSign/SETACTIVEEFINREQUEST';
export const SIGNATURES_NEEDED_SET = 'xlinkonline/remoteSign/SIGNATURESNEEDEDSET';
export const BANK_APP_ATTACHED_SET = 'xlinkonline/remoteSign/BANKAPPATTACHEDSET';
export const SEND_SIGNATURES_MODAL_SET = 'xlinkonline/remoteSign/SENDSIGNATURESMODALSET';
export const ERO_DATA_SET = 'xlinkonline/remoteSign/ERODATASET';
export const PREPARER_DATA_SET = 'xlinkonline/remoteSign/PREPARERDATASET';
export const AMENDED_PREPARER_DATA_SET = 'xlinkonline/remoteSign/AMENDEDPREPARERDATASET';
export const INITIAL_SIGNEE_DATA_SET = 'xlinkonline/remoteSign/INITIALSIGNEEDATASET';

// initialState
export const initialState = {
  fetching: false,
  finishedFetching: false,
  error: null,
  preparerSignatureData: {},
  fetchingERO: false,
  finishedFetchingERO: false,
  errorERO: null,
  EROSignatureData: {},
  selectedEfin: 0,
  signaturesNeeded: {},
  isBankAppAttached: false,
  isSendSignatureModalActive: false,
  eroData: {},
  preparerData: {},
  amendedPreparerData: {},
  initialSigneeData: {},
};

// Actions for updating input fields and checkboxes
export const actions = {
  setActiveEfin: activeEfin => ({
    type: SET_ACTIVE_EFIN_CALL,
    activeEfin,
  }),
  fetchPreparerSignatureData: () => ({
    type: FETCH_PREPARER_SIGNATURE_CALL_REQUEST,
  }),
  requestPreparerSignatureSuccess: preparerSignatureData => ({
    type: FETCH_PREPARER_SIGNATURE_CALL_SUCCESS,
    preparerSignatureData,
  }),
  requestPreparerSignatureError: error => ({
    type: FETCH_PREPARER_SIGNATURE_CALL_FAILURE,
    error,
  }),
  fetchEROSignatureData: activeEfin => ({
    type: FETCH_ERO_SIGNATURE_CALL_REQUEST,
    activeEfin,
  }),
  requestEROSignatureSuccess: EROSignatureData => ({
    type: FETCH_ERO_SIGNATURE_CALL_SUCCESS,
    EROSignatureData,
  }),
  requestEROSignatureError: error => ({
    type: FETCH_ERO_SIGNATURE_CALL_FAILURE,
    error,
  }),
  signaturesNeededSet: signaturesNeeded => ({
    type: SIGNATURES_NEEDED_SET,
    signaturesNeeded,
  }),
  bankAppAttachedSet: flag => ({
    type: BANK_APP_ATTACHED_SET,
    flag,
  }),
  sendSignatureModalSet: isActive => ({
    type: SEND_SIGNATURES_MODAL_SET,
    isActive,
  }),
  eroDataSet: data => ({
    type: ERO_DATA_SET,
    data,
  }),
  preparerDataSet: data => ({
    type: PREPARER_DATA_SET,
    data,
  }),
  amendedPreparerDataSet: data => ({
    type: AMENDED_PREPARER_DATA_SET,
    data,
  }),
  initialSigneeDataSet: data => ({
    type: INITIAL_SIGNEE_DATA_SET,
    data,
  }),
};

// Reducer with initial state
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_ACTIVE_EFIN_CALL:
      return {
        ...state,
        selectedEfin: action.activeEfin,
      };
    case FETCH_PREPARER_SIGNATURE_CALL_REQUEST:
      return {
        ...state,
        fetching: true,
        error: null,
      };
    case FETCH_PREPARER_SIGNATURE_CALL_SUCCESS:
      return {
        ...state,
        fetching: false,
        finishedFetching: true,
        preparerSignatureData: action.preparerSignatureData,
      };
    case FETCH_PREPARER_SIGNATURE_CALL_FAILURE:
      return {
        ...state,
        fetching: false,
        preparerSignatureData: {},
        error: action.error,
      };
    case FETCH_ERO_SIGNATURE_CALL_REQUEST:
      return {
        ...state,
        fetchingERO: true,
        errorERO: null,
      };
    case FETCH_ERO_SIGNATURE_CALL_SUCCESS:
      return {
        ...state,
        fetchingERO: false,
        finishedFetchingERO: true,
        EROSignatureData: action.EROSignatureData,
      };
    case FETCH_ERO_SIGNATURE_CALL_FAILURE:
      return {
        ...state,
        fetchingERO: false,
        EROSignatureData: {},
        errorERO: action.error,
      };
    case SIGNATURES_NEEDED_SET:
      const { signaturesNeeded } = action;
      const signatures = {};
      // These numbers are from calc server and map to the following signatures.
      signaturesNeeded.includes('1') && (signatures['taxpayer'] = true);
      signaturesNeeded.includes('2') && (signatures['spouse'] = true);
      signaturesNeeded.includes('3') && (signatures['preparer'] = false); // we require the prep sig be captured outside of the return
      signaturesNeeded.includes('7') && (signatures['officer'] = true); // This is only for business returns
      signaturesNeeded.includes('8') && (signatures['taxpayer second'] = false); // This is requested w/ Print Bank Docs with Final Return (Don't show in )
      signaturesNeeded.includes('9') && (signatures['spouse second'] = false); // This is requested w/ Print Bank Docs with Final Return
      signaturesNeeded.includes('A') && (signatures['amended preparer'] = true);

      return {
        ...state,
        signaturesNeeded: signatures,
      };
    case BANK_APP_ATTACHED_SET:
      return {
        ...state,
        isBankAppAttached: action.flag,
      };
    case SEND_SIGNATURES_MODAL_SET:
      return {
        ...state,
        isSendSignatureModalActive: action.isActive,
      };
    case ERO_DATA_SET:
      return {
        ...state,
        eroData: action.data,
      };
    case PREPARER_DATA_SET:
      return {
        ...state,
        preparerData: action.data,
      };
    case AMENDED_PREPARER_DATA_SET:
      return {
        ...state,
        amendedPreparerData: action.data,
      };
    case INITIAL_SIGNEE_DATA_SET:
      return {
        ...state,
        initialSigneeData: action.data,
      };
  }
  return state;
}
